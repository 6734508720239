import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { SaleType } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/order-management/order/sale-type.enum';
import { ProductBrandService } from 'projects/inventory/src/app/item-proxy/item-management/items/product-brand.service';
import { ProductType } from 'projects/inventory/src/app/item-proxy/item-management/items/product-type.enum';
import { ProductService } from 'projects/inventory/src/app/item-proxy/item-management/items/product.service';
import { BillingTypeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { BilledIntervalsDTO, ClaimNoteTypeDTO, EspdtIndicatorDTO, ItemSizeDTO, NonTaxReasonDTO, OrderMasterDropdownDTO, PriceCodeDTO, PriceOptionDTO, TransCodeDTO, TypeCodeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { LocationTypeDTO, PatientMasterDropdownDTO, TaxZoneDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/billing-dropdowns.enum';
import { BillingMasterDropdownDTO, ModifierDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/billing/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { PatientDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { RulesValidityDTO } from '../order-proxy/order-management/inventory/dto/models';
import { ItemEligibilityRulesService } from '../order-proxy/order-management/inventory/item-eligibility-rules.service';
import { ItemDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { getOrder, updateOrder } from '../order-store/order.action';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss'],
  providers: [ListService]
})
export class AddItemComponent implements OnInit, OnDestroy {
  billingType: string;
  drpSize: ItemSizeDTO[];
  drpLocation: LocationTypeDTO[] = [];
  drpInsurance: RulesValidityDTO[] = [];
  drpPriceCode: PriceCodeDTO[] = [];
  drpPriceOption: PriceOptionDTO[] = [];
  drpTaxZone: TaxZoneDTO[] = [];
  drpTaxReason: NonTaxReasonDTO[] = [];
  drpEClaimType: TypeCodeDTO[] = [];
  drpTrans: TransCodeDTO[] = [];
  drpItemClaimType: ClaimNoteTypeDTO[] = [];
  drpEspdtReferral: EspdtIndicatorDTO[] = [];
  itemForm: FormGroup;
  @Input() orderId: string = '';
  notesText ='';
  tblNotesReponse: any[] = [];
  drpitem: productInterface[] = [];
  location: string;
  priceCode: string;
  priceOption: string;
  taxReason: string;
  itemClaimType: string;
  eClaimType: string;
  trans: string;
  espdtReferral: string;
  size: string;
  drpLocationTypes: any[] = [];
  clientId: string;
  drpBillingType: BillingTypeDTO[] = [];
  drpBilingInterval: BilledIntervalsDTO[] = [];
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  drpPolicies: any[] = [];
  minDate: Date;
  maxDate: Date;
  orderStatus: boolean = true;
  patientID: string = "";
  tenantId: string = "";
  drpModifiers: ModifierDTO[] = [];
  modifiers1: string = '';
  modifiers2: string = '';
  modifiers3: string = '';
  modifiers4: string = '';
  subscription$: Subscription[] = [];
  constructor(
    formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddItemComponent>,
    private number: NumberValidators,
    private text: TextValidators,
    private branchService: OrganizationUnitService,
    private Table: TableService,
    private saleorderService: SaleorderService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    public userProfileService: UserService,
    private itemService: ItemEligibilityRulesService,
    private dashboardService: DashboardService,
    private store: Store,
    public list: ListService,
    private itemsService: ProductService,
    private brandService: ProductBrandService,
    private patientService: PatientService,
    private organizationUnitService: OrganizationUnitService,
    private billingDropdownService: BillingMasterDropdownService,
    private saleOrderService: SaleorderService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { orderId?: string, itemId?: string }) {
    this.parForm = formBuilder.group({
      parId: '',
      parNumber: '',
      status: '',
      expDate: ''
    })

    this.cmnForm = formBuilder.group({
      cmnId: '',
      status: '',
      expDate: '',
      form: ''
    })
    this.itemForm = formBuilder.group({
      itemData: new FormGroup({
        txtSearchFilter: new FormControl(""),
        txtItenId: new FormControl(null, [Validators.required]),
        txtItemName: new FormControl("", [Validators.required]),
        txtItemDescription: new FormControl(""),
        drpLocation: new FormControl(null),
        txtNote: new FormControl(""),
        drpInsurance: new FormControl(null),
        txtInsSearchFilter: new FormControl(""),
        drpSize: new FormControl(""),
        drpBillingType: new FormControl("", Validators.required),
        // newly added
        txtCategoryName: new FormControl(""),
        txtBrandName: new FormControl(""),
        txtDeliveryDate: new FormControl(""),
        drpBilledInterval: new FormControl(""),
        txtTimesToBill: new FormControl(""),
        chkAutoClaim: new FormControl(""),
        //! Item Follow Up Details with default value 30 days and checked
       // txtFollowUpDuration: new FormControl("30", [Validators.required, this.number.isNumberCheck, Validators.min(0), Validators.max(99), Validators.minLength(1), Validators.maxLength(3)]),
       // chkIsFollowUpNeeded: new FormControl(true),
      }),


      itemPurchase: new FormGroup({
        chkPricing: new FormControl(""),
        chkTaxable: new FormControl(""),
        chkPriceOveride: new FormControl(""),
        chkTaxRate: new FormControl(""),
        chkAssignment: new FormControl(""),
        chkConvertPurchase: new FormControl(""),
        chkAbn: new FormControl(""),
        chkAbnUpgrade: new FormControl(""),
        chkCbOverride: new FormControl(""),
        txtQty: new FormControl("", [Validators.required, this.number.isNumberCheck, Validators.min(1)]),
        txtBQty: new FormControl("", [this.number.isNumberCheck]),
        drpPriceCode: new FormControl(null),
        drpPriceOption: new FormControl(null),
        txtModifiers1: new FormControl(""),
        txtModifiers2: new FormControl(""),
        txtModifiers3: new FormControl(""),
        txtModifiers4: new FormControl(""),
        // Modifiers Serach Parameters
        txtModifiersFilter1: new FormControl(""),
        txtModifiersFilter2: new FormControl(""),
        txtModifiersFilter3: new FormControl(""),
        txtModifiersFilter4: new FormControl(""),
        txtPrice: new FormControl("", [Validators.required, this.number.isNumberCheck]),
        txtAllow: new FormControl("", [this.number.isNumberCheck]),
        txtTotalAmount: new FormControl(""),

      }),
      itemTaxInfo: new FormGroup({
        txtTaxRate: new FormControl(""),
        drpTaxZone: new FormControl(null),
        drpTaxReason: new FormControl(null),
        txtOrderQty: new FormControl("", [this.number.isNumberCheck]),
        txtBillQty: new FormControl("", [this.number.isNumberCheck]),
      }),
      itemDates: new FormGroup({
        chkSupply: new FormControl(""),
        chkSplit: new FormControl(""),
        txtDaysSupply: new FormControl(""),
        chkPatient: new FormControl(""),
        txtOriginalDOS: new FormControl("", [Validators.required]),
        txtDosTo: new FormControl(""),
        txtPreBill: new FormControl(""),
        txtNextBill: new FormControl(""),
        txtNextPeriod: new FormControl(""),
        txtNextSDos: new FormControl(""),
        chkPrimary: new FormControl(""),
        chkSecondary: new FormControl(""),
        chkTertiary: new FormControl(""),
        txtCoverageType: new FormControl(""),
        txtSubmission: new FormControl(""),
      }),
      itemClaimAttatchment: new FormGroup({
        txtAttatchment: new FormControl("", this.number.isNumberCheck),
        chkIncludeEclaim: new FormControl(""),
        drpEClaimType: new FormControl(null),
        drpTrans: new FormControl(null),
        drpItemClaimType: new FormControl(null),
        txtQuantity: new FormControl("", [this.number.isNumberCheck]),
        txtAdditional: new FormControl("", this.text.isTextCheck),
        drpEspdtReferral: new FormControl(null),
        chkDiagnosis9: new FormControl(""),
        chkDiagnosis10: new FormControl(""),

      }),
      // itemEspdt: new FormGroup({
      //   drpEspdtReferral: new FormControl(null),
      //   chkDiagnosis9: new FormControl(""),
      //   chkDiagnosis10: new FormControl(""),
      // }),
    });
  }

  displayedColumnsPar: string[] = ['parId', 'parNumber', 'status', 'expDate'];
  dataSourcePar = new MatTableDataSource();
  parForm: FormGroup;

  displayedColumnsCmn: string[] = ['cmnId', 'status', 'expDate', 'form'];
  dataSourceCmn = new MatTableDataSource();
  cmnForm: FormGroup;
  // orderId: string = "f1c3304b-524f-eaf3-9169-39fa1b9daa37";

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  ngOnInit(): void {


    this.tenantId = localStorage.getItem("tenantId") ?? "";
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 7, 9, 15);
    //   if (this.data.orderId !== "" && this.data.orderId !== null && this.data.orderId !== undefined)
    //   { this.getTableData(this.data.orderId); }
    this.orderId = this.data?.orderId;
    this.getDropdown();
    this.getInsuranceDropdown();
    this.branchPatch();
  }

  //  Automatically patch location if single content
  branchPatch() {
    if (this.data?.itemId == null || this.data?.itemId == "" || this.data?.itemId == undefined) {
      const organizationList = this.organizationUnitService.getOrganizationList(this.tenantId).subscribe(response => {
        response && response?.forEach(element => {
          if (response?.length == 1) {
            this.itemForm.patchValue({
              itemData: {
                drpLocation: element?.organizationUnitName,
              }
            })
          }
          else {
            this.itemForm.patchValue({
              itemData: {
                drpLocation: "",
              }
            })
          }
        })
      })
      this.subscription$.push(organizationList);
    }
  }


  hideRentalFields: boolean = false;


  // Billing type onChange
  onChanges(event) {
    const requiredDeliveryDate = this.itemForm?.get("itemData.txtDeliveryDate");
    const requiredBillingInterval = this.itemForm?.get("itemData.drpBilledInterval");
    const requiredTxtTimesToBill = this.itemForm?.get("itemData.txtTimesToBill");
    if (event?.value == SaleType.Rental) {
      this.hideRentalFields = true;
      requiredDeliveryDate.setValidators(Validators.required)
      requiredDeliveryDate.setErrors({ required: true });
      requiredDeliveryDate.markAsDirty();

      requiredBillingInterval.setValidators(Validators.required)
      requiredBillingInterval.setErrors({ required: true });
      requiredBillingInterval.markAsDirty();

      requiredTxtTimesToBill.setValidators(Validators.required)
      requiredTxtTimesToBill.setErrors({ required: true });
      requiredTxtTimesToBill.markAsDirty();

    } else {
      this.itemForm?.get("itemData.txtDeliveryDate").setErrors(null);
      this.itemForm?.get("itemData.drpBilledInterval").setErrors(null);
      this.itemForm?.get("itemData.txtTimesToBill").setErrors(null)

      this.hideRentalFields = false;
      requiredDeliveryDate.clearValidators();
      requiredDeliveryDate.markAsPristine();

      requiredBillingInterval.clearValidators();
      requiredBillingInterval.markAsPristine();

      requiredTxtTimesToBill.clearValidators();
      requiredTxtTimesToBill.markAsPristine();
    }
  }

  caluculate() {
    return this.itemForm.value.itemPurchase.txtPrice * this.itemForm.value.itemPurchase.txtQty
  }

  numberOnly(event: { which: any; keyCode: any; charCode: number; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    // return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    if (charCode > 31 && event.charCode == 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getOrderDetailsForSave() {
    let itemDetails: SaleorderDTO;
    if (this.data.orderId !== "" || this.data.orderId !== undefined || this.data.orderId !== null) {
      setTimeout(() => {
        const saleOrderGet = this.saleorderService.get(this.data.orderId).subscribe(orderResponse => {
          itemDetails = { ...orderResponse };
          this.SaveOrder(itemDetails);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(saleOrderGet);
      }, 1);
    }
  }
  public filteredInsTypes: Observable<RulesValidityDTO[]>;
  public filteredModifiers1: Observable<ModifierDTO[]>;
  public filteredModifiers2: Observable<ModifierDTO[]>;
  public filteredModifiers3: Observable<ModifierDTO[]>;
  public filteredModifiers4: Observable<ModifierDTO[]>;
  filterbrand;
  filterSearchbrand;
  drpBrand: any[] = [];
  // dropdown data //
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.ItemInsurances,
      OrderDropdowns.PriceCodes,
      OrderDropdowns.PriceOptions,
      OrderDropdowns.Itemsize,
      OrderDropdowns.TaxZones,
      OrderDropdowns.NonTaxReasons,
      OrderDropdowns.TypeCodes,
      OrderDropdowns.TransCodes,
      OrderDropdowns.ClaimNoteTypes,
      OrderDropdowns.EspdtIndicators,
      OrderDropdowns.lstBillingTypes,
      OrderDropdowns.lstBilledIntervals
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      const response: OrderMasterDropdownDTO = stateResponse;

      // this.drpLocation = response?.locationTypes;
      // this.drpInsurance = response?.itemInsurances;

      // this.filteredInsTypes = this.itemForm?.get("itemData.txtInsSearchFilter").valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => this.drpInsurance.filter(option => option?.itemInsuranceType?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
      //   );
      this.drpPriceCode = response?.priceCodes;
      this.drpPriceOption = response?.priceOptions;
      this.drpSize = response?.itemSize;
      // this.drpTaxZone = response?.taxZones;
      this.drpTaxReason = response?.nonTaxReasons;
      this.drpEClaimType = response?.typeCodes;
      this.drpTrans = response?.transCodes;
      this.drpItemClaimType = response?.claimNoteTypes;
      this.drpEspdtReferral = response?.espdtIndicators;
      this.drpBillingType = response?.lstBillingTypes;
      this.drpBilingInterval = response?.lstBilledIntervals;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(orderDropdown);
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.TaxZones
    ]

    const getPatientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.drpTaxZone = response?.taxZones;
    });
    this.subscription$.push(getPatientDropdown);
    //
    this.dashboardService.userProfile().subscribe(response => {

      this.clientId = response?.tenantId

      const getOrganizationList = this.branchService.getOrganizationList(this.clientId).subscribe(response => {
        response?.forEach(element => {
          this.drpLocationTypes.push({
            id: element.id,
            organizationUnitName: element.organizationUnitName,
            tenantId: element.tenantId,
          });
        });
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(getOrganizationList);
    })

    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Modifiers,
    ]
    // Get modifiers value from billing service
    const getBillingDropdown = this.billingDropdownService.getBillingDropdownsByInput(billingDropdownInput).subscribe((stateResponse) => {
      const response: BillingMasterDropdownDTO = stateResponse;
      this.drpModifiers = response?.modifiers;

      // filter for modifiers 1
      this.filteredModifiers1 = this.itemForm?.get("itemPurchase.txtModifiersFilter1").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpModifiers.filter(option =>
            option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      // filter for modifiers 2
      this.filteredModifiers2 = this.itemForm?.get("itemPurchase.txtModifiersFilter2").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpModifiers.filter(option =>
            option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      // filter for modifiers 3
      this.filteredModifiers3 = this.itemForm?.get("itemPurchase.txtModifiersFilter3").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpModifiers.filter(option =>
            option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      // filter for modifiers 3
      this.filteredModifiers4 = this.itemForm?.get("itemPurchase.txtModifiersFilter4").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpModifiers.filter(option =>
            option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );

    }, (err: HttpErrorResponse) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getBillingDropdown)
    //
    this.itemDropdown();
  }
  drpPayerLevel: any[] = [];
  // dropdown() {
  //   this.patientService.getList(new PagedAndSortedResultRequestDto()).subscribe(response => {
  //
  //     response?.items?.forEach(element => {
  //
  //       this.drpPayerLevel.push({
  //         name: element?.policies,
  //         id: element?.id
  //       })
  //     })
  //   })
  // }

  // Get Insruance Dropdwon
  orderResponse: SaleorderDTO;
  getInsuranceDropdown() {
    const storeSubscription = this.saleOrderService.get(this.orderId).subscribe(response => {
      const orderResponse: SaleorderDTO = response;
      this.orderResponse = orderResponse;
      this.patientID = orderResponse?.patientId;
      this.getInsurancePolicies();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(storeSubscription);
  }


  getInsurancePolicies() {
    const storeSubscription = this.patientService.get(this.patientID).subscribe(stateResponse => {
      // this.store.dispatch(new getPatient(this.patientID)).subscribe((stateResponse) => {
      const selectedPatient: PatientDTO = stateResponse;
      this.drpPolicies = [];
      if (selectedPatient?.policies?.primaryPolicy) {
        this.drpPolicies.push({
          insuranceType: selectedPatient?.policies?.primaryPolicy?.payorLevel,
          insuranceName: selectedPatient?.policies?.primaryPolicy?.insuranceType
        });
        this.itemForm.patchValue({
          itemData: {
            drpInsurance: selectedPatient?.policies?.primaryPolicy?.insuranceType
          }
        });
      }
      // if (selectedPatient?.policies?.secondaryPolicy) {
      //   this.drpPolicies.push({
      //     insuranceType: selectedPatient?.policies?.secondaryPolicy?.payorLevel,
      //     insuranceName:selectedPatient?.policies?.secondaryPolicy?.insuranceType
      //   });
      // }
      // if (selectedPatient?.policies?.tertiaryPolicy) {
      //   this.drpPolicies.push({
      //     insuranceType: selectedPatient?.policies?.tertiaryPolicy?.payorLevel,
      //     insuranceName:selectedPatient?.policies?.tertiaryPolicy?.insuranceType
      //   });
      // }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(storeSubscription);
  }



  inventoryItemId: string = "";
  onChange(value) {
    this.inventoryItemId = value;
    this.itemForm.patchValue({
      itemData: {
        txtItemName: value?.name,
        txtBrandName: value?.brandName,
        txtCategoryName: value?.categoryName,
        txtItemDescription: value?.productDescription
        // drpLocation: value?.locationId,
      },
      itemPurchase: {
        txtPrice: value?.price,
      },
    })
    // this.itemService.get(value).subscribe(response => {
    // this.itemForm.patchValue({
    //   itemData: {
    //     txtItemName: response?.itemName,
    //     drpLocation: response?.locationId,
    //   },
    //   itemPurchase: {
    //     txtPrice: response?.price,
    //   },

    // })

    this.drpInsurance = [];
    // response && response?.rulesValidities.forEach(element => {
    //   if (element.status === "Active") {
    //     this.drpInsurance.push({
    //       ruleId: element.ruleId,
    //       insuranceName: element.insuranceName,
    //       eligibleQuantity: element.eligibleQuantity,
    //       eligibleDays: element.eligibleDays,
    //       eligibleMonths: element.eligibleMonths,
    //       status: element.status,
    //       startDate: element.startDate,
    //       endDate: element.endDate,
    //     });
    //   }
    // })

    // })


  }


  onChangeIns(value) {
    const inventoryGet = this.itemService.get(this.inventoryItemId).subscribe(response => {
      response && response?.rulesValidities?.forEach(element => {
        if (element.insuranceName === value.value) {
          this.itemForm.patchValue({
            itemPurchase: {
              txtQty: element.eligibleQuantity
            },
          })
        }
      })
    })
    this.subscription$.push(inventoryGet);
  }
  public filteredItemTypes: Observable<productInterface[]>;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  itemDropdown(): void {
    const saleOrderGet = this.saleorderService.get(this.data?.orderId).subscribe(res => {
      if (res.isCashPayment === 1) {
        // this.itemForm.controls['itemData.drpInsurance'].disable();
        this.itemForm?.get('itemData.drpInsurance').disable();
      }
    })
    this.subscription$.push(saleOrderGet);
    this.drpitem = [];
    // getProductsByInput
    const getProduct = this.itemsService.getProductsByInput(ProductType.All).subscribe(response => {
      response?.forEach(element => {
        this.drpitem?.push({

          hcpcCode: element.hcpcCode,
          productDescription: element?.productDescription,
          productCode: element?.productCode,
          status: element?.status,
          price: element?.price,
          // productStockId:element?.productStockId,
          // lotId: element?.lotId,
          // currentAvailableStockCount:element?.currentAvailableStockCount,
          productId: element?.productId,
          productName: element?.productName,
          productType: element?.productType,
          productNames: element?.productName,
          brandName: element?.brandName,
          categoryName: element?.categoryName,
          name: element?.name
          // isAllSerialNumbersUpdated: element?.isAllSerialNumbersUpdated
        });
        this.filteredItemTypes = this.itemForm?.get("itemData.txtSearchFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpitem.filter(option => option?.productName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        // this.drpInsurance = element?.rulesValidities

      });
      if (this.data?.itemId != null && this.data?.itemId != undefined && this.data?.itemId !== "") {
        this.getOrderByid(this.data?.itemId);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    });
    this.subscription$.push(saleOrderGet);
  }
  txtItem: string = '';
  txtInsurance: string = '';
  clearItemType() {
    this.txtItem = '';
    this.itemForm.patchValue({
      itemData: {
        txtItenId: '',
        drpInsurance: ''
      },
      itemPurchase: {
        txtQty: ''
      },
    });
  }
  clearInsType() {
    this.txtInsurance = '';
    this.itemForm.patchValue({
      itemData: {
        drpInsurance: ''
      },
      itemPurchase: {
        txtQty: ''
      },
    });
  }

  clearBillingType() {
    this.billingType = '';
    this.itemForm.patchValue({ drpBillingType: '' });
  }
  // clear dropdown

  clearLocation() {
    this.location = '';
    this.itemForm.patchValue({
      itemData: {
        drpLocation: ''
      }
    });
  }
  clearPriceCode() {

    this.priceCode = '';
    this.itemForm.patchValue({
      itemPurchase: {
        drpPriceCode: ''
      }
    });
  }

  clearSize() {

    this.size = '';
    this.itemForm.patchValue({
      itemData: {
        drpSize: ''
      }
    });
  }

  clearPriceOption() {
    this.priceOption = '';
    this.itemForm.patchValue({
      itemPurchase: {
        drpPriceOption: ''
      }
    });
  }

  clearTaxReason() {
    this.taxReason = '';
    this.itemForm.patchValue({
      itemTaxInfo: {
        drpTaxReason: ''
      }
    });
  }

  clearItemClaimType() {
    this.itemClaimType = '';
    this.itemForm.patchValue({
      itemClaimAttatchment: {
        drpItemClaimType: ''
      }
    });
  }

  clearEClaimType() {
    this.eClaimType = '';
    this.itemForm.patchValue({
      itemClaimAttatchment: {
        drpEClaimType: ''
      }
    });
  }

  clearTrans() {
    this.trans = '';
    this.itemForm.patchValue({
      itemClaimAttatchment: {
        drpTrans: ''
      }
    });
  }

  clearEspdtReferral() {
    this.espdtReferral = '';
    this.itemForm.patchValue({
      itemClaimAttatchment: {
        drpEspdtReferral: ''
      }
    });
  }

  brand: string = '';
  clearbrand() {
    this.brand = '';
    this.itemForm.patchValue({ txtBrandName: "" });
  }
  billedInterval: string = '';
  clearBilledInterval() {
    this.billedInterval = '';
    this.itemForm.patchValue({
      itemData: {
        drpBilingInterval: ''
      }
    });
  }



  getTableData(orderId: string) {
    // let itemDetails: ItemDTO[] = [];
    const saleOrderList = this.saleorderService.get(orderId).subscribe(response => {
      //
      const itemDetails: ItemDTO[] = response?.items ?? [];
      this.tblNotesReponse = [];

      itemDetails?.forEach(element => {
        //
        this.tblNotesReponse.push({
          itemId: element?.itemId,
          saleType: element?.saleType,
          quickAddQuantity: element?.quickAddQuantity,
          itemIdOrBarCode: element?.itemIdOrBarCode,
          itemCodeId: element?.itemCodeId,
          itemSize: element?.itemSize,
          name: element?.name,
          description: element?.description,
          location: element?.location,
          note: element?.note,
          insuranceName: element?.insuranceName,
          qty: element?.qty,
          bQty: element?.billingQuantity,
          priceCode: element?.priceCode,
          priceOption: element?.priceOption,
          modifiers1: element?.modifiers1,
          dosTo: element?.dosTo,
          nextDOS: element?.nextDOS,
          // isSpanDateSplitBillingEnabled: number;
          // cmnId: string;
          // cmnStatus: string;
          // cmnExpDate?: string;
          // cmnForm: string;
          // itemClaimNoteType: string;
          // itemClaimNoteQuantity: string;
          // preBilling?: string;
          // nextBilling?: string;
          // nextPeriod: string;
          additionalModifiers1: element?.additionalModifiers1,
          modifiers2: "",
          modifiers3: "",
          modifiers4: "",
          billed: element?.charge,
          allow: element?.allow,
          // parId: element?.parId,
          // parNumber: element?.parNumber,
          // parStatus: element?.parStatus,
          // parExpDate: element?.parExpDate,
          taxRate: element?.taxRate,
          taxZone: element?.taxZone,
          nonTaxReason: element?.nonTaxReason,
          quantity: element?.quantity,
          billingQuantity: element?.billingQuantity,
          isPrimary: element?.isPrimary,
          coverageType: element?.coverageType,
          claimSubmission: element?.claimSubmission,
          isSecondary: element?.isSecondary,
          isTertiary: element?.isTertiary,
          isPatient: element?.isPatient,
          isDaysOfSupplyOverride: element?.isDaysOfSupplyOverride,
          daysSupply: element?.daysSupply,
          originalDos: element?.originalDos,
          additionalModifiers2: element?.additionalModifiers2,
          additionalModifiers3: element?.additionalModifiers3,
          additionalModifiers4: element?.additionalModifiers4,
          espdtIndicator: element?.espdtIndicator,
          isIncludeEclaimsAttachment: element?.isIncludeEclaimsAttachment,
          attachmentNo: element?.attachmentNo,
          typeCode: element?.typeCode,
          transCode: element?.transCode,
          isDiagnosisCodeICD9: element?.isDiagnosisCodeICD9,
          isDiagnosisCodeICD10: element?.isDiagnosisCodeICD10,

        });

      })
      this.Table.setOrderTable(this.tblNotesReponse.reverse());
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(saleOrderList);
  }
  // save order //
  itemId: string = "00000000-0000-0000-0000-000000000000";
  organizationUnitId = null;
  SaveOrder(response: SaleorderDTO) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let itemDetails: SaleorderDTO;
    let currentItems: ItemDTO[] = response?.items;
    const selectedItem: productInterface = this.drpitem?.filter(value => { return value && value?.productId === this.itemForm?.get("itemData.txtItenId").value })[0];
    if (this.itemId != "00000000-0000-0000-0000-000000000000") {
      let lotDetails: any;
      let totalPrice: number;
      currentItems = currentItems.filter(items => {
        lotDetails = items?.productLotDetails
        totalPrice = items?.totalPrice
        return items && items.itemId != this.itemId
      })
      currentItems = [...currentItems, {
        itemId: this.itemId,
        saleType: "",
        quickAddQuantity: 0,
        itemIdOrBarCode: this.itemForm?.get("itemData.txtItenId").value.hcpcCode,
        itemCodeId: this.itemForm?.get("itemData.txtItenId").value.productId,
        // txtItenId: this.itemForm?.get("itemData.txtItenId").value,
        name: this.itemForm?.get("itemData.txtItemName").value,
        description: this.itemForm?.get("itemData.txtItemDescription").value,
        location: this.itemForm?.get("itemData.drpLocation").value,
        itemSize: this.itemForm?.get("itemData.drpSize").value,
        billingType: this.itemForm?.get("itemData.drpBillingType").value,

        note: this.itemForm?.get("itemData.txtNote").value,
        insuranceName: this.itemForm?.get("itemData.drpInsurance").value,
        isSpecialPricing: +(this.itemForm?.get("itemPurchase.chkPricing").value) | 0,
        isTaxable: +(this.itemForm?.get("itemPurchase.chkTaxable").value) | 0,
        isPriceOverride: +(this.itemForm?.get("itemPurchase.chkPriceOveride").value) | 0,
        isSpecialTaxRate: +(this.itemForm?.get("itemPurchase.chkTaxRate").value) | 0,
        isAcceptAssignment: +(this.itemForm?.get("itemPurchase.chkAssignment").value) | 0,
        isManualConvertToPurchase: +(this.itemForm?.get("itemPurchase.chkConvertPurchase").value) | 0,
        isAbn: +(this.itemForm?.get("itemPurchase.chkAbn").value) | 0,
        isAbnUpgrade: +(this.itemForm?.get("itemPurchase.chkAbnUpgrade").value) | 0,
        isCbOverride: +(this.itemForm?.get("itemPurchase.chkCbOverride").value) | 0,
        qty: +(this.itemForm?.get("itemPurchase.txtQty").value),
        // bQty:this.itemForm?.get("itemPurchase.txtBQty").value,
        bQty: +(this.itemForm.value.itemPurchase.txtBQty),
        priceCode: this.itemForm?.get("itemPurchase.drpPriceCode").value,
        priceOption: this.itemForm?.get("itemPurchase.drpPriceOption").value,
        modifiers1: this.itemForm?.get("itemPurchase.txtModifiers1").value,
        modifiers2: this.itemForm?.get("itemPurchase.txtModifiers2").value,
        modifiers3: this.itemForm?.get("itemPurchase.txtModifiers3").value,
        modifiers4: this.itemForm?.get("itemPurchase.txtModifiers4").value,
        charge: this.itemForm?.get("itemPurchase.txtPrice").value,
        allow: this.itemForm?.get("itemPurchase.txtAllow").value,
        // parId: "",
        // parNumber: "",
        // parStatus: "",
        // parExpDate: "",
        taxRate: this.itemForm?.get("itemTaxInfo.txtTaxRate").value,
        taxZone: this.itemForm?.get("itemTaxInfo.drpTaxZone").value,
        nonTaxReason: this.itemForm?.get("itemTaxInfo.drpTaxReason").value,
        // quantity: this.itemForm?.get("itemTaxInfo.txtOrderQty").value,
        quantity: +(this.itemForm.value.itemTaxInfo.txtOrderQty),
        // billingQuantity: this.itemForm?.get("itemTaxInfo.txtBillQty").value,
        billingQuantity: +(this.itemForm.value.itemTaxInfo.txtBillQty),
        isPrimary: +(this.itemForm?.get("itemDates.chkPrimary").value) | 0,
        coverageType: this.itemForm?.get("itemDates.txtCoverageType").value,
        claimSubmission: this.itemForm?.get("itemDates.txtSubmission").value,
        isSecondary: +(this.itemForm?.get("itemDates.chkSecondary").value) | 0,
        isTertiary: +(this.itemForm?.get("itemDates.chkTertiary").value) | 0,
        isPatient: +(this.itemForm?.get("itemDates.chkPatient").value) | 0,
        isDaysOfSupplyOverride: +(this.itemForm?.get("itemDates.chkSupply").value) | 0,
        daysSupply: this.itemForm?.get("itemDates.txtDaysSupply").value,
        originalDos: this.itemForm?.get("itemDates.txtOriginalDOS").value,
        dosTo: this.itemForm?.get("itemDates.txtDosTo").value,
        preBilling: this.itemForm?.get("itemDates.txtPreBill").value,
        nextBilling: this.itemForm?.get("itemDates.txtNextBill").value,
        nextPeriod: this.itemForm?.get("itemDates.txtNextPeriod").value,
        nextDOS: this.itemForm?.get("itemDates.txtNextSDos").value,
        isSpanDateSplitBillingEnabled: +(this.itemForm?.get("itemDates.chkSplit").value) | 0,
        // cmnId: "",
        // cmnStatus: "",
        // cmnExpDate: "",
        // cmnForm: "",
        itemClaimNoteType: this.itemForm?.get("itemClaimAttatchment.drpItemClaimType").value,
        itemClaimNoteQuantity: +(this.itemForm?.get("itemClaimAttatchment.txtQuantity").value),
        additionalModifiers1: this.itemForm?.get("itemClaimAttatchment.txtAdditional").value,
        additionalModifiers2: "",
        additionalModifiers3: "",
        additionalModifiers4: "",
        espdtIndicator: this.itemForm?.get("itemClaimAttatchment.drpEspdtReferral").value,
        isIncludeEclaimsAttachment: this.itemForm?.get("itemClaimAttatchment.chkIncludeEclaim").value | 0,
        attachmentNo: this.itemForm?.get("itemClaimAttatchment.txtAttatchment").value,
        typeCode: this.itemForm?.get("itemClaimAttatchment.drpEClaimType").value,
        transCode: this.itemForm?.get("itemClaimAttatchment.drpTrans").value,
        isDiagnosisCodeICD9: +(this.itemForm?.get("itemClaimAttatchment.chkDiagnosis9").value) | 0,
        isDiagnosisCodeICD10: +(this.itemForm?.get("itemClaimAttatchment.chkDiagnosis10").value) | 0,
        // itemProcedureCode: selectedItem.equipmentBillingCode,
        sameItemFirstCreatedDateTime: "",
        isItemRuleCheck: 0,
        // lotId:this.itemForm?.get("itemData.txtItenId").value.lotId,
        //  currentAvailableStockCount:this.itemForm?.get("itemData.txtItenId").value.currentAvailableStockCount,
        productId: this.itemForm?.get("itemData.txtItenId").value.productId,
        productType: this.itemForm?.get("itemData.txtItenId").value.productType,
        productStockId: this.itemForm?.get("itemData.txtItenId").value.productStockId,
        productLotDetails: lotDetails,
        brandName: this.itemForm?.get("itemData.txtItenId").value.brandName,
        categoryName: this.itemForm?.get("itemData.txtItenId").value.categoryName,
        //  isAllSerialNumbersUpdated: this.itemForm?.get("itemData.txtItenId").value.isAllSerialNumbersUpdated,
        totalPrice,
        deliveryDate: this.itemForm?.get("itemData.txtDeliveryDate")?.value ?? null,
        billedInterval: this.itemForm?.get("itemData.drpBilledInterval")?.value ?? "",
        timesToBill: this.itemForm?.get("itemData.txtTimesToBill")?.value ?? "",
        autoClaim: +(this.itemForm.value.itemData.chkAutoClaim) | 0,
        isFollowupNeeded: false ,//this.itemForm.value?.itemData?.chkIsFollowUpNeeded ?? true,
        followUpDuration: 0,//+(Number(this.itemForm.value?.itemData?.txtFollowUpDuration) ?? 0) | 0,
      }]
    } else {
      currentItems = [...currentItems, {
        itemId: this.itemId,
        saleType: "",
        quickAddQuantity: 0,
        itemIdOrBarCode: this.itemForm?.get("itemData.txtItenId").value.hcpcCode,
        itemCodeId: this.itemForm?.get("itemData.txtItenId").value.productId,
        name: this.itemForm?.get("itemData.txtItemName").value,
        description: this.itemForm?.get("itemData.txtItemDescription").value,
        location: this.itemForm?.get("itemData.drpLocation").value,
        itemSize: this.itemForm?.get("itemData.drpSize").value,
        billingType: this.itemForm?.get("itemData.drpBillingType").value,
        note: this.itemForm?.get("itemData.txtNote").value,
        insuranceName: this.itemForm?.get("itemData.drpInsurance").value,
        isSpecialPricing: +(this.itemForm?.get("itemPurchase.chkPricing").value) | 0,
        isTaxable: +(this.itemForm?.get("itemPurchase.chkTaxable").value) | 0,
        isPriceOverride: +(this.itemForm?.get("itemPurchase.chkPriceOveride").value) | 0,
        isSpecialTaxRate: +(this.itemForm?.get("itemPurchase.chkTaxRate").value) | 0,
        isAcceptAssignment: +(this.itemForm?.get("itemPurchase.chkAssignment").value) | 0,
        isManualConvertToPurchase: +(this.itemForm?.get("itemPurchase.chkConvertPurchase").value) | 0,
        isAbn: +(this.itemForm?.get("itemPurchase.chkAbn").value) | 0,
        isAbnUpgrade: +(this.itemForm?.get("itemPurchase.chkAbnUpgrade").value) | 0,
        isCbOverride: +(this.itemForm?.get("itemPurchase.chkCbOverride").value) | 0,
        qty: +(this.itemForm?.get("itemPurchase.txtQty").value),
        bQty: + (this.itemForm?.get("itemPurchase.txtBQty").value),
        priceCode: this.itemForm?.get("itemPurchase.drpPriceCode").value,
        priceOption: this.itemForm?.get("itemPurchase.drpPriceOption").value,
        modifiers1: this.itemForm?.get("itemPurchase.txtModifiers1").value,
        modifiers2: this.itemForm?.get("itemPurchase.txtModifiers2").value,
        modifiers3: this.itemForm?.get("itemPurchase.txtModifiers3").value,
        modifiers4: this.itemForm?.get("itemPurchase.txtModifiers4").value,
        charge: this.itemForm?.get("itemPurchase.txtPrice").value,
        allow: this.itemForm?.get("itemPurchase.txtAllow").value,
        // parId: "",
        // parNumber: "",
        // parStatus: "",
        // parExpDate: "",
        taxRate: this.itemForm?.get("itemTaxInfo.txtTaxRate").value,
        taxZone: this.itemForm?.get("itemTaxInfo.drpTaxZone").value,
        nonTaxReason: this.itemForm?.get("itemTaxInfo.drpTaxReason").value,
        quantity: +this.itemForm?.get("itemTaxInfo.txtOrderQty").value,
        billingQuantity: +this.itemForm?.get("itemTaxInfo.txtBillQty").value,
        isPrimary: +(this.itemForm?.get("itemDates.chkPrimary").value) | 0,
        coverageType: this.itemForm?.get("itemDates.txtCoverageType").value,
        claimSubmission: this.itemForm?.get("itemDates.txtSubmission").value,
        isSecondary: +(this.itemForm?.get("itemDates.chkSecondary").value) | 0,
        isTertiary: +(this.itemForm?.get("itemDates.chkTertiary").value) | 0,
        isPatient: +(this.itemForm?.get("itemDates.chkPatient").value) | 0,
        isDaysOfSupplyOverride: +(this.itemForm?.get("itemDates.chkSupply").value) | 0,
        daysSupply: this.itemForm?.get("itemDates.txtDaysSupply").value,
        originalDos: this.itemForm?.get("itemDates.txtOriginalDOS").value,
        dosTo: this.itemForm?.get("itemDates.txtDosTo").value,
        preBilling: this.itemForm?.get("itemDates.txtPreBill").value,
        nextBilling: this.itemForm?.get("itemDates.txtNextBill").value,
        nextPeriod: this.itemForm?.get("itemDates.txtNextPeriod").value,
        nextDOS: this.itemForm?.get("itemDates.txtNextSDos").value,
        isSpanDateSplitBillingEnabled: +(this.itemForm?.get("itemDates.chkSplit").value) | 0,
        // cmnId: "",
        // cmnStatus: "",
        // cmnExpDate: "",
        // cmnForm: "",
        itemClaimNoteType: this.itemForm?.get("itemClaimAttatchment.drpItemClaimType").value,
        itemClaimNoteQuantity: +this.itemForm?.get("itemClaimAttatchment.txtQuantity").value,
        additionalModifiers1: this.itemForm?.get("itemClaimAttatchment.txtAdditional").value,
        additionalModifiers2: "",
        additionalModifiers3: "",
        additionalModifiers4: "",
        espdtIndicator: this.itemForm?.get("itemClaimAttatchment.drpEspdtReferral").value,
        isIncludeEclaimsAttachment: this.itemForm?.get("itemClaimAttatchment.chkIncludeEclaim").value | 0,
        attachmentNo: this.itemForm?.get("itemClaimAttatchment.txtAttatchment").value,
        typeCode: this.itemForm?.get("itemClaimAttatchment.drpEClaimType").value,
        transCode: this.itemForm?.get("itemClaimAttatchment.drpTrans").value,
        isDiagnosisCodeICD9: +(this.itemForm?.get("itemClaimAttatchment.chkDiagnosis9").value) | 0,
        isDiagnosisCodeICD10: +(this.itemForm?.get("itemClaimAttatchment.chkDiagnosis10").value) | 0,
        // itemProcedureCode: selectedItem.equipmentBillingCode,
        sameItemFirstCreatedDateTime: "",
        isItemRuleCheck: 0,
        // lotId:this.itemForm?.get("itemData.txtItenId").value.lotId,
        //  currentAvailableStockCount:this.itemForm?.get("itemData.txtItenId").value.currentAvailableStockCount,
        productId: this.itemForm?.get("itemData.txtItenId").value.productId,
        productType: this.itemForm?.get("itemData.txtItenId").value.productType,
        productStockId: this.itemForm?.get("itemData.txtItenId").value.productStockId,
        //  isAllSerialNumbersUpdated: this.itemForm?.get("itemData.txtItenId").value.isAllSerialNumbersUpdated,
        productLotDetails: null,
        brandName: this.itemForm?.get("itemData.txtItenId").value.brandName,
        categoryName: this.itemForm?.get("itemData.txtItenId").value.categoryName,
        totalPrice: 0,
        deliveryDate: this.itemForm?.get("itemData.txtDeliveryDate")?.value ?? null,
        billedInterval: this.itemForm?.get("itemData.drpBilledInterval")?.value ?? "",
        timesToBill: this.itemForm?.get("itemData.txtTimesToBill")?.value ?? "",
        autoClaim: +(this.itemForm.value.itemData.chkAutoClaim) | 0,
        isFollowupNeeded: false ,//this.itemForm.value?.itemData?.chkIsFollowUpNeeded ?? true,
        followUpDuration: 0,//+(Number(this.itemForm.value?.itemData?.txtFollowUpDuration) ?? 0) | 0,
      }]
    }
    itemDetails = {
      defaultClaimId: response?.defaultClaimId,
      orders: response?.orders,
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      items: currentItems,
      patients: response?.patients,
      saleorderId: response?.saleorderId,
      orderStatus: response?.orderStatus,
      organizationUnitId: response?.organizationUnitId,
      currentStage: response?.currentStage,
      currentStageStatus: response?.currentStageStatus,
      claimId: response?.claimId,
      patientId: response?.patientId,
      isShippingApprove: response?.isShippingApprove,
      isCashPayment: response?.isCashPayment,
      orderProcessStatus: response?.orderProcessStatus,
      patientLedgerReason: response?.patientLedgerReason,
      documentReason: response?.documentReason,
      paymentTransactionDetails: response?.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      claimHistoryStatus: response?.claimHistoryStatus,
      patientLedgerStatus: response?.patientLedgerStatus,
      orderBilling: response?.orderBilling,
      shippingCheckList: response?.shippingCheckList,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType
    }

    this.data.orderId != "" &&
      this.store.dispatch(new updateOrder(this.data.orderId, itemDetails)).subscribe(() => {
        // set orderid from save api
        this.Table.setSelectedOrderId(this.data.orderId);
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialogRef.close();
        this.itemId = "00000000-0000-0000-0000-000000000000";
        this.saveButtonHide = false;
        this.isShowSpinner = false;
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
  }
  billTypeValue: any;
  getOrderByid(itemId: string) {

    this.hideRentalFields = true;
    const orderGet = this.saleorderService.get(this.data?.orderId ?? "").pipe(
      map(response => {


        let selectedOrder: ItemDTO
        response?.items?.forEach((element: ItemDTO) => {
          if (element?.itemId === itemId) {
            const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
            this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (response?.orderBilling?.status)))
            //    this.orderStatus = (response?.orderBilling?.status === billingStatus.OrderPending || response?.orderBilling?.status === billingStatus.ReadyToBilling) ? true : false;
            selectedOrder = element;
          }
        });
        return selectedOrder ?? null;
      }
      )).subscribe(response => {

        const selectedItem: productInterface = this.drpitem?.filter(value => {
          return value && value?.productId === response?.itemCodeId
        })[0];

        if (response?.billingType == "Purchase" || response?.billingType == "") {
          this.hideRentalFields = false;
        }
        else {
          this.hideRentalFields = true;
        }

        setTimeout(() => {
          this.itemForm.patchValue({
            itemData: {
              // txtItenId: selectedItem.id,
              txtItemName: response?.name,
              txtBrandName: response?.brandName,
              txtCategoryName: response?.categoryName,
              txtItemDescription: response?.description,
              drpLocation: response?.location,
              txtNote: response?.note,
              drpInsurance: response?.insuranceName,
              txtItenId: selectedItem,
              drpSize: response?.itemSize,
              drpBillingType: response?.billingType,
              txtDeliveryDate: response?.deliveryDate,
              drpBilledInterval: response?.billedInterval,
              txtTimesToBill: response?.timesToBill,
              chkAutoClaim: response?.autoClaim,
         //     txtFollowUpDuration: response?.followUpDuration ?? 30,
          //    chkIsFollowUpNeeded: response?.isFollowupNeeded ?? true
            },
            itemPurchase: {
              chkPricing: response?.isSpecialPricing,
              chkTaxable: response?.isTaxable,
              chkPriceOveride: response?.isPriceOverride,
              chkTaxRate: response?.isSpecialTaxRate,
              chkAssignment: response?.isAcceptAssignment,
              chkConvertPurchase: response?.isManualConvertToPurchase,
              chkAbn: response?.isAbn,
              chkAbnUpgrade: response?.isAbnUpgrade,
              chkCbOverride: response?.isCbOverride,
              txtQty: response?.qty == 0 ? "" : response?.qty,
              txtBQty: response?.bQty == 0 ? "" : response?.bQty,
              drpPriceCode: response?.priceCode,
              drpPriceOption: response?.priceOption,
              txtModifiers1: response?.modifiers1,
              txtModifiers2: response?.modifiers2,
              txtModifiers3: response?.modifiers3,
              txtModifiers4: response?.modifiers4,
              txtPrice: response?.charge,
              txtAllow: response?.allow,
            },
            itemTaxInfo: {
              txtTaxRate: response?.taxRate,
              drpTaxZone: response?.taxZone,
              drpTaxReason: response?.nonTaxReason,
              txtOrderQty: response?.quantity == 0 ? "" : response?.quantity,
              txtBillQty: response?.billingQuantity == 0 ? "" : response?.billingQuantity,
            },
            itemDates: {
              chkSupply: response?.isDaysOfSupplyOverride,
              txtDaysSupply: response?.daysSupply,
              chkPrimary: response?.isPrimary,
              txtCoverageType: response?.coverageType,
              txtSubmission: response?.claimSubmission,
              chkSecondary: response?.isSecondary,
              chkTertiary: response?.isTertiary,
              chkPatient: response?.isPatient,

              txtOriginalDOS: response?.originalDos,
              txtDosTo: response?.dosTo,
              txtPreBill: response?.preBilling,
              txtNextBill: response?.nextBilling,
              txtNextPeriod: response?.nextPeriod,
              txtNextSDos: response?.nextDOS,
              chkSplit: response?.isSpanDateSplitBillingEnabled,
            },
            itemClaimAttatchment: {
              chkIncludeEclaim: response?.isIncludeEclaimsAttachment,
              txtAttatchment: response?.attachmentNo,
              drpEClaimType: response?.typeCode,
              drpTrans: response?.transCode,
              txtAdditional: response?.additionalModifiers1,
              txtQuantity: response?.itemClaimNoteQuantity == 0 ? "" : response?.itemClaimNoteQuantity,
              drpItemClaimType: response?.itemClaimNoteType,
              drpEspdtReferral: response?.espdtIndicator,
              chkDiagnosis9: response?.isDiagnosisCodeICD9,
              chkDiagnosis10: response?.isDiagnosisCodeICD10,
            },
            // itemEspdt: {
            //   drpEspdtReferral: response?.espdtIndicator,
            //   chkDiagnosis9: response?.isDiagnosisCodeICD9,
            //   chkDiagnosis10: response?.isDiagnosisCodeICD10
            // },
          })
        }, 100);
        this.itemId = response?.itemId;
        this.itemForm.updateValueAndValidity();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    this.subscription$.push(orderGet);
  }

  statusChange(event: any) {

  }
  // ! clear functions
  clearModifiers1() {
    this.modifiers1 = "";
    this.itemForm.patchValue({ itemPurchase: { drpModifiers1: "" } });
  }

  clearModifiers2() {
    this.modifiers2 = "";
    this.itemForm.patchValue({ itemPurchase: { drpModifiers2: "" } });

  }
  clearModifiers3() {
    this.modifiers3 = "";
    this.itemForm.patchValue({ itemPurchase: { drpModifiers3: "" } });

  }
  clearModifiers4() {
    this.modifiers4 = "";
    this.itemForm.patchValue({ itemPurchase: { drpModifiers4: "" } });

  }
}


export interface productInterface {
  productId?: string;
  productType: ProductType;
  productName?: string;
  productNames?: string;
  hcpcCode?: string;
  productDescription?: string;
  productCode: number;
  status?: string;
  price: number;
  productStockId?: string;
  brandName: string;
  categoryName: string;
  name: string;
}

export interface itemInterface {
  id: string;
  itemId: string;
  location: string;
  // insurance: string;
  charge: number;
  name?: string;
  equipmentBillingCode: string;
  size?: string
}
