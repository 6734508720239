<div class="row mr-3">
    <div class="col-12 table-responsive">
        <div
            style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
        </div>
        <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
            <form style="display: flex" [formGroup]="userSearchForm">


                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                    <td mat-cell *matCellDef="let data; let i = index">
                        {{ i+1 }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-form-field class="filter">
                            <input autocomplete="new-nameSearch" matInput formControlName="nameSearch" />
                            <mat-placeholder>Units</mat-placeholder>
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>

                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="phn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-form-field class="filter">
                            <input autocomplete="new-phnSearch" matInput formControlName="phnSearch" />
                            <mat-placeholder>Prod Code</mat-placeholder>
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-form-field class="filter">
                            <input autocomplete="new-dateSearch" matInput formControlName="dateSearch" />
                            <mat-placeholder>Prod Dec</mat-placeholder>
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>

                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="hcpc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-form-field class="filter">
                            <input autocomplete="new-txthcpc" matInput formControlName="txthcpc" />
                            <mat-placeholder>Prod Dec</mat-placeholder>
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>

                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-form-field class="filter">
                            <input autocomplete="new-statusSearch" matInput formControlName="statusSearch" />
                            <mat-placeholder>Status</mat-placeholder>
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Options">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                    <td mat-cell *matCellDef="let data">
                        <a class="editBtnColor">
                            <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To Edit"
                                ></i>
                        </a>
                        &nbsp;
                        <a class="deleteBtnColor">
                            <i class="fa fa-trash-o" matTooltip="Click To Delete" ></i>
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="noRecords">
                    <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="7">
                        <div>
                            {{"No Records Found"}}
                        </div>

                    </td>
                </ng-container>
                <ng-container matColumnDef="paginator">
                    <td mat-footer-cell *matFooterCellDef colspan="7">
                        <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                            [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                            [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator>
                    </td>
                </ng-container>
            </form>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
                [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }">
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
    </div>
</div>
