import type { PatientDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VerificationSearchByDateRangeService {
  apiName = 'Default';

  searchVerificationByDateRangeByDtFromDateAndDtToDate = (dtFromDate: string, dtToDate: string) =>
    this.restService.request<any, PagedResultDto<PatientDTO>>({
      method: 'POST',
      url: '/api/app/verification-search-by-date-range/search-verification-by-date-range',
      params: { dtFromDate, dtToDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
