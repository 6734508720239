<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{ strVisitNotesModalTitle }}</h2>
    </div>
  </div>
  <form [formGroup]="visitNoteForm">
    <div class="row">
      <mat-form-field class="col-lg-3">
        <mat-label>Note Type</mat-label>
        <input autocomplete="new-strNoteType" matInput formControlName="strNoteType" type="text" maxlength="40" />
        <mat-error *ngIf="
                      visitNoteForm?.get('strNoteType')?.touched &&
                      visitNoteForm?.get('strNoteType')?.errors?.sAlphabets
                    ">
          Enter only Alphabets!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <mat-label>Follow Up Date</mat-label>
        <input autocomplete="new-dtFollowUpDate" [min]="BirthdateMinDate" matInput formControlName="dtFollowUpDate" placeholder="(MM/DD/YYYY)"
          [matDatepicker]="dtFollowUpDate" maxlength="40" />
        <mat-datepicker-toggle matSuffix [for]="dtFollowUpDate"> </mat-datepicker-toggle>
        <mat-datepicker #dtFollowUpDate></mat-datepicker>
        <mat-error *ngIf="
                      visitNoteForm?.get('dtFollowUpDate')?.touched &&
                      visitNoteForm?.get('dtFollowUpDate')?.errors
                    ">
          Enter Valid Date!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <mat-label>Followup Date Untill</mat-label>
        <input autocomplete="new-dtFollowupDateUntill" matInput formControlName="dtFollowupDateUntill" placeholder="(MM/DD/YYYY)"
          [matDatepicker]="dtFollowupDateUntill" maxlength="40" />
        <mat-datepicker-toggle matSuffix [for]="dtFollowupDateUntill"> </mat-datepicker-toggle>
        <mat-datepicker #dtFollowupDateUntill></mat-datepicker>
        <mat-error *ngIf="
                      visitNoteForm?.get('dtFollowupDateUntill')?.touched &&
                      visitNoteForm?.get('dtFollowupDateUntill')?.errors
                    ">
          Enter Valid Date!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <mat-label>60+ Aging Note</mat-label>
        <textarea matInput formControlName="str60AgingNote" rows="1" type="text" maxlength="1000"></textarea>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <mat-label>Entry Date</mat-label>
        <input autocomplete="new-dtEntryDate" matInput formControlName="dtEntryDate" [min]="BirthdateMinDate" placeholder="(MM/DD/YYYY)"
          [matDatepicker]="dtEntryDate" maxlength="40" />
        <mat-datepicker-toggle matSuffix [for]="dtEntryDate"> </mat-datepicker-toggle>
        <mat-datepicker #dtEntryDate></mat-datepicker>
        <mat-error *ngIf="
                      visitNoteForm?.get('dtEntryDate')?.touched &&
                      visitNoteForm?.get('dtEntryDate')?.errors
                    ">
          Enter Valid Date!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <mat-label>Entered By</mat-label>
        <input autocomplete="new-strEnteredBy" matInput formControlName="strEnteredBy" type="text" maxlength="40" />
        <mat-error *ngIf="
                      visitNoteForm?.get('strEnteredBy')?.touched &&
                      visitNoteForm?.get('strEnteredBy')?.errors?.sAlphabets
                    ">
          Enter only Alphabets!
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-lg-3">
        <mat-label>Modified Date</mat-label>
        <input autocomplete="new-dtModifiedDate" matInput formControlName="dtModifiedDate" [min]="BirthdateMinDate" placeholder="(MM/DD/YYYY)"
          [matDatepicker]="dtModifiedDate" maxlength="40" />
        <mat-datepicker-toggle matSuffix [for]="dtModifiedDate"> </mat-datepicker-toggle>
        <mat-datepicker #dtModifiedDate></mat-datepicker>
        <mat-error *ngIf="
                      visitNoteForm?.get('dtModifiedDate')?.touched &&
                      visitNoteForm?.get('dtModifiedDate')?.errors
                    ">
          Enter Valid Date!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <mat-label>Modified By</mat-label>
        <input autocomplete="new-strModifiedBy" matInput formControlName="strModifiedBy" type="text" maxlength="40" />
        <mat-error *ngIf="
                      visitNoteForm?.get('strModifiedBy')?.touched &&
                      visitNoteForm?.get('strModifiedBy')?.errors?.sAlphabets
                    ">
          Enter only Alphabets!
        </mat-error>
      </mat-form-field>








      <div class="col-3">
        <mat-checkbox formControlName="chkExcludeFollowUp">Exclude Follow Up</mat-checkbox>
      </div>
      <div class="col-3">
        <mat-checkbox formControlName="chkPriorityFollowUp">Priority Follow Up</mat-checkbox>
      </div>
      <div class="col-3">
        <mat-checkbox formControlName="chkReceiveBill">Receive Bill</mat-checkbox>
      </div>
      <div class="col-3">
        <mat-checkbox formControlName="chkCollection">Collection</mat-checkbox>
      </div>
      <mat-form-field class="col-3">
        <mat-label>Pre-Collection Status</mat-label>
        <mat-select formControlName="drpPreCollectionStatus">
          <mat-option *ngFor="let data of drpPreCollectionStatus" [value]="data.preCollectionStatus">
            {{ data.preCollectionStatus }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-lg-9">
        <mat-label>Visit Notes</mat-label>
        <textarea matInput maxlength="1000" formControlName="strNote" rows="1" placeholder=""></textarea>
      </mat-form-field>
    </div>
  </form>

  <div class="row mt-1">
    <div class="col-lg-12">
      <form [formGroup]="patientVisitNotesTableForm">
        <!-- <div [hidden]="!isLoading"
          style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
          <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        <div class="table table-responsive">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- NoteType Column -->
            <ng-container matColumnDef="NoteType">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-noteType" matInput formControlName="noteType" />
                  <mat-placeholder>Note Type</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.noteType }}
              </td>
            </ng-container>
            <!-- FollowUpDate Column -->
            <ng-container matColumnDef="FollowUpDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label> FollowUp Date </mat-label>
                  <input autocomplete="new-followUpDate" matInput formControlName="followUpDate" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" patientVisitNotesTableForm?.get('followUpDate')?.touched && patientVisitNotesTableForm?.get('followUpDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{ element?.followUpLocalDate }}</td>
            </ng-container>
            <!-- Priority Column -->
            <ng-container matColumnDef="Priority">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Priority
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element?.isPriorityFollowUp === 1">
                  <mat-checkbox [disabled]="true" [checked]='true'>
                  </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="element?.isPriorityFollowUp === 0">
                  <mat-checkbox [disabled]="true" [checked]='false'>
                  </mat-checkbox>
                </ng-container>
              </td>
            </ng-container>
            <!-- ModifiedBy Column -->
            <ng-container matColumnDef="ModifiedBy">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-modifiedBy" matInput formControlName="modifiedBy" />
                  <mat-placeholder>Modified By</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.modifiedBy }}</td>
            </ng-container>
            <!-- ModifiedOn Column -->
            <ng-container matColumnDef="ModifiedOn">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label> Modified On </mat-label>
                  <input autocomplete="new-modifiedDate" matInput formControlName="modifiedDate" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" patientVisitNotesTableForm?.get('modifiedDate')?.touched && patientVisitNotesTableForm?.get('modifiedDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.modifiedLocalDate }}</td>
            </ng-container>
            <!-- PrecollectionStatus Column -->
            <ng-container matColumnDef="PreCollectionStatus">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-precollectionStatus" matInput formControlName="precollectionStatus" />
                  <mat-placeholder>PreCollection Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.preCollectionStatus }}</td>
            </ng-container>
            <ng-container matColumnDef="options">
              <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <a matTooltip="Click To View Patient Visit Notes">
                  <i class="fa fa-eye pointer editBtnColor pl-3"
                    (click)="viewPatientVisitNotes(element)"></i>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isLoading" >
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="9">
                <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                  [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{'make-green': row.LockStatus === 'Locked' && row.UserId === null}"></tr>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" [disabled]="saveButtonHide ||!(!visitNoteForm.invalid && visitNoteForm.dirty)"
    (click)="getClaimDetailsForSave()">
    Save
    <mat-icon *ngIf='isShowSpinner'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button mat-button class="resetclr buttonColor" (click)="resetVisitNoteForm()">Reset</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
