import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { MaskSizeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mask-size.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import Swal from 'sweetalert2';
import { Status } from '../../item-proxy/item-management/items';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-size-table-list',
  templateUrl: './size-table-list.component.html',
  styleUrls: ['./size-table-list.component.scss']
})
export class SizeTableListComponent implements OnInit {
  @Input() sizeList: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  sizeOptions: any = {
  responsive: true,
  dom: 'Bfrtip',

  paging: false,
  language: {
    emptyTable: 'No records',
    info: 'Total : _MAX_ records',
    infoEmpty: 'Total : _MAX_ records',
    infoFiltered: '(filtered : _TOTAL_ records)',
    loadingRecords: 'Loading...',
    zeroRecords: 'No matching records',
  },
  fixedHeader: true, // Fix the header

  scrollCollapse: true,
  scrollY: '400px',
  scrollX: true,
  order: [],
   columnDefs: [
   { targets: [4,5,], visible: false },
  ],
  search: {
    smart: false,
  },
  buttons: [
    {
      extend: 'excel',
      title: 'Size List',
      text: 'Export', // Change the text to 'Export'
      exportOptions: {
        columns: ':visible:not(:nth-child(1),:nth-child(2))',
        // columns: [1, 2,3,4,5,6], // Specify the columns you want to export (0-indexed)
      },
    },
     {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
  ],
};
  constructor(private communicationService: CommunicationService,private snackBar: MatSnackBar,private toastr: ToastrService,
    private masterService: MaskSizeService ,private datepipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.sizeOptions.buttons[0].filename =
    'Size List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    
    this.calculateScrollY();

    window.addEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.sizeOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Size Area will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          // let productCategoryDetails: CreateUpdateProductCategoryDTO = {
          //   productCategoryName: data?.productCategoryName,
          //   status: Status.Active
          // }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.masterService.updateMaskStatusByIdAndStatus(data?.id, true).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              // this.getTableData();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          // this.getTableData();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Size Area will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
     
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.masterService.updateMaskStatusByIdAndStatus(data?.id, false).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivated Successfully','Success')
                // this.getTableData();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            // this.getTableData();
          }
        });
      }
  }

  triggerToggle() {
    this.communicationService.triggerInsuranceAllowableToggle();
  }
  getInsAllowablesById(id: string) {
    this.communicationService.triggerInsuranceAllowableGetMethodCall(id);
  }
  statusToggleTrigger(toggle: MatSlideToggleChange,data: any){
    this.communicationService.triggerStatusGetMethodCall(toggle,data)
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
