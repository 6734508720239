<div class="fluid-container">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between m-0">
        <h4 class="font-size-18 m-0 pl-3">
          <b
            >Patient /
            {{
              strUrlPath === "saleorderCreateEdit" ? "New" : "View"
            }}
            SaleOrder</b
          >
        </h4>
        <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumbs m-0">
            <li class="blinking ml-2 mt-2 mr-2 breadcrumb-item">
              {{ currentOrderStatus }}
            </li>
            <li class="mb-1 mr-3">
              <button
                matTooltip="Complete Order"
                mat-button
                class="buttonColor font-size-10"
              >
                <mat-icon>check_circle_outline</mat-icon> Complete Order
              </button>
            </li>
            <li class="mb-1 mr-3">
              <button
                matTooltip="Read Only "
                *ngIf="!isEditButton"
                mat-button
                class="buttonColors"
                (click)="editPatient()"
              >
                <i
                  class="fa fa-pencil-square-o mr-1"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
                Edit
              </button>
            </li>
            <li class="mb-1">
              <button
                matTooltip="Back To Patient"
                mat-button
                class="buttonColor font-size-10"
                (click)="backToPatient()"
              >
                <i class="fa fa-backward mr-1"></i> Back To Patient
              </button>
            </li>
            <!-- <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">{{strUrlPath==="saleorderCreateEdit"?"New":"View"}} SaleOrder</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-2 pr-0 mb-3">
      <app-patient-common-summary-tab
        *ngIf="!!orderId"
        [isTemplate]="isTemplate"
        [orderId]="orderId"
        [patientId]="patientId"
      >
      </app-patient-common-summary-tab>
      <app-patient-common-summary-tab
        *ngIf="!orderId"
        [isTemplate]="isTemplate"
        [orderId]="orderId"
        [patientId]="patientId"
      >
      </app-patient-common-summary-tab>
    </div>
    <div class="col-xl-10 pr-0">
      <ul
        ngbNav
        #nav="ngbNav"
        justify="justified"
        class="alert alert-success nav nav-pills nav-fill"
      >
        <li ngbNavItem="Order" class="nav-item">
          <a ngbNavLink>Order</a>
          <ng-template ngbNavContent>
            <app-order-tab
              (orderIdEmitter)="getOrderIdfromChild($event)"
              [isTemplate]="isTemplate"
              [patientId]="patientId"
              [saleOrderId]="orderId"
            >
            </app-order-tab>
          </ng-template>
        </li>
        <li ngbNavItem="Item" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Item</a>
          <ng-template ngbNavContent>
            <app-item-tab
              (orderIdEmitter)="getOrderIdfromChild($event)"
              [isTemplate]="isTemplate"
              [patientId]="patientId"
              [orderId]="orderId"
            ></app-item-tab>
          </ng-template>
        </li>
        <li
          *ngIf="
            (routerPathObs | async)[1].path === 'OrderTemplateCreateEditTab'
          "
          ngbNavItem="schedule"
          class="nav-item"
          [disabled]="orderId === defaultGuid"
        >
          <a ngbNavLink>schedule</a>
          <ng-template ngbNavContent>
            <app-schedule-config
              (orderIdEmitter)="getOrderIdfromChild($event)"
              [templateId]="templateId"
              [isTemplate]="isTemplate"
              [orderId]="orderId"
            ></app-schedule-config>
          </ng-template>
        </li>

        <li ngbNavItem="Authorization" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Authorization</a>
          <ng-template ngbNavContent>
            <app-order-authorization-tab
              (orderIdEmitter)="getOrderIdfromChild($event)"
              [isTemplate]="isTemplate"
              [patientId]="patientId"
              [orderId]="orderId"
            ></app-order-authorization-tab>
          </ng-template>
        </li>

        <li ngbNavItem="Reminder" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Reminder</a>
          <ng-template ngbNavContent>
            <app-reminder-tab
              [patientId]="patientId"
              [isTemplate]="isTemplate"
              [orderId]="orderId"
            ></app-reminder-tab>
          </ng-template>
        </li>

        <li ngbNavItem="Notes" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Notes</a>
          <ng-template ngbNavContent>
            <app-patient-common-notes
              [isAddVisible]="true"
              [patientId]="patientId"
              [orderId]="orderId"
              [noteType]="noteType"
            ></app-patient-common-notes>
          </ng-template>
        </li>

        <li ngbNavItem="Financial" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Financial</a>
          <ng-template ngbNavContent>
            <app-finance-tab></app-finance-tab>
          </ng-template>
        </li>
        <li ngbNavItem="PickupExchange" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Pickup/Exchange</a>
          <ng-template ngbNavContent>
            <app-pickup-exchange-tab
              [patientId]="patientId"
              [orderId]="orderId"
            ></app-pickup-exchange-tab>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>

<button
  [matMenuTriggerFor]="menu"
  mat-button
  matTooltip="Add"
  class="clr mat-fab-bottom-right rightfloatfabbutton"
  mat-mini-fab
>
  <mat-icon class="icon">add</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button (click)="openRemindermodal()" mat-menu-item matTooltip="Add Reminder">
    Add Reminder
    <mat-icon class="icon">add</mat-icon>
  </button>
  <button (click)="openAddNotesPopUp()" mat-menu-item matTooltip="Add Notes">
    Add Notes
    <mat-icon class="icon">add</mat-icon>
  </button>
  <button (click)="openViewNotesPopup()" mat-menu-item matTooltip="View Notes">
    View Notes
    <mat-icon class="icon">remove_red_eye</mat-icon>
  </button>
</mat-menu>
<app-reminder-message-tab></app-reminder-message-tab>
