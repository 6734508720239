<div class="row mt-2">
  <div class="col-lg-12">
    <h4 class="customThemeClass"><b></b></h4>
    <!-- <mat-card> -->
    <!-- <div class="col-lg-12 text-left">
              <h4 > Patient Balance </h4>
              <h4> Insurance: </h4>
              <h4> Total: </h4>

            </div>  -->
    <!-- <div class="row">
      <div class="col-9">
        <span class="mr-2"><b>Status :</b></span>
        <button mat-button class="btn btn-sm red-dot mr-2"> <span> Hold </span></button>
        <button mat-button class="btn btn-sm green-dot mr-2"> <span> Ready </span></button>
        <button mat-button class="btn btn-sm dark-dot mr-2"> <span> Processed </span></button>
        <button mat-button class="btn btn-sm yellow-dot mr-2"> <span> Crossed-over </span></button>
        <button mat-button class="btn btn-sm blue-dot mr-2"> <span> Auth No </span></button>
        <button mat-button class="btn btn-sm pink-dot mr-2"> <span> >55days </span></button>

      </div>
      <div class="col-3 text-right">
        <mat-checkbox class="mr-3" (change)="showInt($event)">Show Int</mat-checkbox>
        <mat-checkbox (change)="showPaid($event)">Show Paid</mat-checkbox>
      </div>
    </div> -->
  </div>
  <div class="col-lg-12">
    <app-table [strPageType]="strPageType" [isLoading]="isLoading" (viewButton)="ViewClaim($event)">
    </app-table>
  </div>
  <br>

</div>
