<mat-dialog-content class="mat-typography">
    <div class="row">
        <div>
            <mat-panel-title class="customThemeClass mr-4 ml-3">
                <ng-container *ngIf="data.pickupCreationId===''; else elseTitleTemplate">
                    <h2>Add Pickup Details</h2>
                </ng-container>
                <ng-template #elseTitleTemplate>
                </ng-template>
                <h2>Edit Pickup Details</h2>
            </mat-panel-title>
        </div>
    </div>

    <form [formGroup]="AddWorkForm">
        <div class="row">
            <mat-form-field class="col-6">
                <mat-label>Respiratory Therapist <span class="asterisk">*</span></mat-label>
                <mat-select maxlength="40" formControlName="drpRespiratory">
                    <mat-option *ngFor="let work of userTableData" [value]="work.name">
                        {{ work.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="
                AddWorkForm?.get('drpRespiratory')?.touched &&
                AddWorkForm?.get('drpRespiratory').value === null">
                    Insurance is a required field!
                </mat-error>
            </mat-form-field>
            <!-- <div *ngIf="status" class="row ml-3" style="margin-top: 23px;">
                <div class="col-4"> <b>Status</b></div>
                <mat-slide-toggle class="ml-3" (change)="sundayStatus($event)" formControlName="statusToggle">
                    {{activeStatus}}</mat-slide-toggle>
            </div> -->
        </div>
        <table>
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class=" col-3 mb-2 mt-3" style="display: flex;">
                                SUNDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="sundaysStatus(AddWorkForm.status)"
                                    formControlName="acceptTerms">
                                    {{sunday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="sundayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-label>Week Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>
                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>
                                    <mat-select maxlength="40" formControlName="drpStartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>


                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>
                                    <mat-select (selectionChange)="onSunEndsChanges(AddWorkForm?.get('acceptTerms')?.value,
                                    AddWorkForm?.get('drpStartAt')?.value,
                                    AddWorkForm?.get('drpStartEnd')?.value)" formControlName="drpStartEnd">
                                        <mat-option *ngFor="let data of AddWorkForm?.get('drpStartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Select Office</mat-label>
                                        <mat-select formControlName="drpSelCov">
                                            <mat-option *ngFor="let user of arrUsersList" [value]="user.officeName">
                                                {{user.officeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>


                                </div>
                            </td>

                        </span>
                    </span>
                </tr>
            </div>
            <!-- Monday -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class=" col-3 mb-2 mt-3" style="display: flex;">
                                MONDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="MondayStatus(AddWorkForm.status)"
                                    formControlName="mondayWorkStatus">
                                    {{Monday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="mondayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-label>Week Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpMonWork">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>
                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>
                                    <mat-select maxlength="40" formControlName="drpMonStartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>
                                    <mat-select (selectionChange)="onMonEndsChanges(AddWorkForm?.get('mondayWorkStatus')?.value,
                                        AddWorkForm?.get('drpMonStartAt')?.value,
                                        AddWorkForm?.get('drpMonStartEnd')?.value)" formControlName="drpMonStartEnd">
                                        <mat-option
                                            *ngFor="let data of AddWorkForm?.get('drpMonStartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Select Office</mat-label>
                                        <mat-select formControlName="drpMonSelCov" #singleSelect>
                                            <mat-option *ngFor="let user of arrUsers1List" [value]="user.officeName">
                                                {{user.officeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                TUESDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="tuesdayStatus(AddWorkForm.status)"
                                    formControlName="tuesdayWorkStatus">
                                    {{Tuesday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="tuesdayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-label>Week Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpTueWork">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>


                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>

                                    <mat-select maxlength="40" formControlName="drpTueStartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>

                                    <mat-select (selectionChange)="onTueEndsChanges(AddWorkForm?.get('tuesdayWorkStatus')?.value,
                                        AddWorkForm?.get('drpTueStartAt')?.value,
                                        AddWorkForm?.get('drpTueStartEnd')?.value)" formControlName="drpTueStartEnd">
                                        <mat-option
                                            *ngFor="let data of AddWorkForm?.get('drpTueStartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>


                            </td>
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Select Office</mat-label>
                                        <mat-select formControlName="drpTueSelCov" #singleSelect>
                                            <mat-option *ngFor="let user of arrUsers2List" [value]="user.officeName">
                                                {{user.officeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
            <div>
                <tr>

                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                WEDNESDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle (click)="wednesStatus(AddWorkForm.status)"
                                    formControlName="wednesWorkStatus">
                                    {{Wednesday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span>
                        <span *ngIf="wednesdayLeaveHide">
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Week Option</mat-label>

                                        <mat-select multiple maxlength="40" formControlName="drpWedWork">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>


                                </div>
                            </td>
                            <td>


                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>

                                    <mat-select maxlength="40" formControlName="drpWedStartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>

                                    <mat-select (selectionChange)="onWedEndsChanges(AddWorkForm?.get('wednesWorkStatus')?.value,
                                        AddWorkForm?.get('drpWedStartAt')?.value,
                                        AddWorkForm?.get('drpWedStartEnd')?.value)" formControlName="drpWedStartEnd">
                                        <mat-option
                                            *ngFor="let data of AddWorkForm?.get('drpWedStartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </td>
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Select Office</mat-label>
                                        <mat-select formControlName="drpWedSelCov" #singleSelect>
                                            <mat-option *ngFor="let user of arrUsers3List" [value]="user.officeName">
                                                {{user.officeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
            <div>
                <tr>

                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                THURSDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-2" (click)="thursStatus(AddWorkForm.status)"
                                    formControlName="thursWorkStatus">
                                    {{Thursday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="thursdayLeaveHide">
                        <span>
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Week Option</mat-label>

                                        <mat-select multiple maxlength="40" formControlName="drpThursWork">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>


                                </div>
                            </td>
                            <td>
                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>
                                    <mat-select maxlength="40" formControlName="drpThursStartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>

                                    <mat-select (selectionChange)="onThuEndsChanges(AddWorkForm?.get('thursWorkStatus')?.value,
                                        AddWorkForm?.get('drpThursStartAt')?.value,
                                        AddWorkForm?.get('drpThursStartEnd')?.value)"
                                        formControlName="drpThursStartEnd">
                                        <mat-option
                                            *ngFor="let data of AddWorkForm?.get('drpThursStartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-label>Select Office</mat-label>
                                        <mat-select formControlName="drpThursSelCov" #singleSelect>
                                            <mat-option *ngFor="let user of arrUsers4List" [value]="user.officeName">
                                                {{user.officeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                FRIDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="fridayStatus(AddWorkForm.status)"
                                    formControlName="fridayWorkStatus">
                                    {{Friday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="fridayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-label>Week Option</mat-label>

                                        <mat-select multiple maxlength="40" formControlName="drpFriWork">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>


                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>

                                    <mat-select maxlength="40" formControlName="drpFriStartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>

                                    <mat-select (selectionChange)="onFriEndsChanges(AddWorkForm?.get('fridayWorkStatus')?.value,
                                        AddWorkForm?.get('drpFriStartAt')?.value,
                                        AddWorkForm?.get('drpFriStartEnd')?.value)" formControlName="drpFriStartEnd">
                                        <mat-option
                                            *ngFor="let data of AddWorkForm?.get('drpFriStartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>


                            </td>
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Select Office</mat-label>
                                        <mat-select formControlName="drpFriSelCov" #singleSelect>
                                            <mat-option *ngFor="let user of arrUsers5List" [value]="user.officeName">
                                                {{user.officeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>

            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                SATURDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-2" (click)="saturdayStatus(AddWorkForm.status)"
                                    formControlName="saturdayWorkStatus">
                                    {{Saturday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="saturdayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-label>Week Option</mat-label>
                                        <mat-select multiple multiple maxlength="40" formControlName="drpSatWork">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>
                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>

                                    <mat-select maxlength="40" formControlName="drpSatStartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>

                                    <mat-select (selectionChange)="onSatEndsChanges(AddWorkForm?.get('saturdayWorkStatus')?.value,
                                        AddWorkForm?.get('drpSatStartAt')?.value,
                                        AddWorkForm?.get('drpSatStartEnd')?.value)" formControlName="drpSatStartEnd">
                                        <mat-option
                                            *ngFor="let data of AddWorkForm?.get('drpSatStartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </td>
                            <td>
                                <div class="row">

                                    <mat-form-field class="col-12">
                                        <mat-label>Select Office</mat-label>
                                        <mat-select formControlName="drpSatSelCov" #singleSelect>
                                            <mat-option *ngFor="let user of arrUsers6List" [value]="user.officeName">
                                                {{user.officeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>

        </table>
        <br>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="buttonColor" [disabled]="!(!AddWorkForm.invalid && AddWorkForm.dirty)"
        (click)="getWorkingSave()">Save</button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
