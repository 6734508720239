import { mapEnumToOptions } from '@abp/ng.core';

export enum VerificationStatus {
  New = 'New',
  Done = 'Done',
  Pending = 'Pending',
  Unabletoverify = 'Unabletoverify',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
}

export const verificationStatusOptions = mapEnumToOptions(VerificationStatus);
