<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0 mt-2">
          <b class="customThemeClass">List / Orders / Hello Sign Report</b>
        </h3>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-2">
      <div class="card card-body p-2">
       <div class="row">
          <div class="col-6 " style="padding-left: 20px">
            <h1 ><b>All</b></h1>
            <h1 class="text-info">{{signCountData?.overallHelloSignCount? signCountData?.overallHelloSignCount:0}}</h1>
          </div>
          <div class="col-6 all-icon">
            <i class="fa fa-file all align-items-center"></i>
          </div>
      </div>
    </div>
    </div>
    <div class="col-2">
      <div class="card card-body p-2">
       <div class="row">
          <div class="col-6 " style="padding-left: 20px">
            <h1 ><b>Sent</b></h1>
            <h1 class="text-info">{{signCountData?.sentCount?signCountData?.sentCount:0 }}</h1>
          </div>
          <div class="col-6 all-icon">
            <i class="fa fa-paper-plane sent align-items-center"></i>
          </div>
      </div>
    </div>
    </div>
    <div class="col-2">
      <div class="card card-body p-2">
        <div class="row">
          <div class="col-6 " style="padding-left: 19px">
            <h1 ><b>Pending</b></h1>
            <h1 class="text-info">{{signCountData?.pendingHelloSignCount?signCountData?.pendingHelloSignCount:0}}</h1>
          </div>
          <div class="col-6 all-icon">
            <i class="fa fa-hourglass-half pending align-items-center"></i>
          </div>
      </div>
      </div>
    </div>
    <div class="col-2_5">
      <div class="card card-body p-2">
       <div class="row">
          <div class="col-8 " style="padding-left: 21px">
            <h1 ><b>Cancelled/Reverted</b></h1>
            <h1 class="text-info">{{signCountData?.cancellOrRevertCount?signCountData?.cancellOrRevertCount:0 }}</h1>
          </div>
          <div class="col-4 all-icon">
            <i class="fa fa-times cancel align-items-center"></i>
          </div>
      </div>
    </div>
    </div>
    <div class="col-2">
      <div class="card card-body p-2">
        <div class="row">
          <div class="col-6 " style="padding-left: 20px">
            <h1 ><b>Signed</b></h1>
            <h1 class="text-info">{{signCountData?.signedCount?signCountData?.signedCount:0 }}</h1>
          </div>
          <div class="col-6 all-icon">
            <i class="fa fa-check-circle sign align-items-center"></i>
          </div>
      </div>
      </div>
    </div>
  </div>

  <div class="card card-body mt-3">

    <div class="row">
        <div class="col-12">
            <div *ngIf="!isLoading" class="table table-responsive">
              <app-hello-sign-report-table [tableData]="helloSignReportData">
              </app-hello-sign-report-table>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isLoading">
        <div class="col-12">
            <div class="d-flex justify-content-center" *ngIf="isLoading">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
        </div>
    </div>
</div>
</div>
