<br />
<div class="card card-body">
  <div class="d-flex flex-row-reverse">
    <mat-radio-group [(ngModel)]="selectedOption" (change)="onRadioChange()">
      <mat-radio-button [value]="0" class="active"><b>All</b></mat-radio-button>
      <mat-radio-button [value]="1" class="active p-2" [checked]="true"><b>Active</b></mat-radio-button>
      <mat-radio-button [value]="2" class="active"><b>Inactive</b></mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Loader Section -->
  <div class="row" *ngIf="isLoading">
    <div class="col-12">
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
    </div>
  </div>
  <app-role-table [clientId]="clientId" [selectedOption]="selectedOption" [tableRole]="roleTableData" *ngIf="!isLoading"></app-role-table>
</div>
