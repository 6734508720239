<table
datatable
[dtOptions]="sizeOptions"

class="table row-border hover w-100 display"
>
<thead>
  <tr>
    <th class="cutomWidth"><mat-icon>apps</mat-icon></th>
    <th  [matTooltip]="'Status'" >Status</th>
    <th [matTooltip]="'Size'">Size</th>
    <th  [matTooltip]="'Created Date'" class="text-right">Created Date</th>
    <th [matTooltip]="'Created By'">Created By</th>
    <th [matTooltip]="'Modify By'">Modify By</th>
    <th [matTooltip]="'Modified Date'" class="text-right">Modified Date</th>
   

    <!-- <th>#</th> -->
  </tr>
</thead>
<tbody>
  <tr
    *ngFor="let data of sizeList"


  >
  <td class="cutomWidth">
    <a

      class="editBtnColor mr-2"
      (click)="getInsAllowablesById(data?.id)"
    >
      <i
        class="fa fa-pencil pointer editBtn"
        (click)="triggerToggle()"
        matTooltip="Click To Edit"
      ></i>
    </a>
  </td>
      <td [matTooltip]="data?.isActive ? 'Active' : 'InActive' || '-'">
        <ng-container *ngIf="data?.isActive===true">
        <mat-slide-toggle  (change)="statusToggleTrigger($event,data.id)" [checked]='true' class="ml-2"> {{ data?.isActive ? 'Active' : 'Inactive' }}
        </mat-slide-toggle>
      </ng-container>
      <ng-container *ngIf="data?.isActive===false">
        <mat-slide-toggle (change)="statusToggleTrigger($event,data.id)" [checked]='false' class="ml-2">{{ data?.isActive ? 'Active' : 'Inactive' }}
        </mat-slide-toggle>
      </ng-container>
    </td>

    <td [matTooltip]="data?.description"
        (dblclick)="onRowDoubleClickV1(data?.description)" >{{data?.description}} </td>

    <td [matTooltip]="data?.creationTime"
        (dblclick)="onRowDoubleClickV1(data?.creationTime)" class="text-right">
        {{ data?.creationTime }}
    </td>
    <td [matTooltip]="data?.createdByName || '-'"
        (dblclick)="onRowDoubleClickV1(data?.createdByName || '-')">{{
        data?.createdByName || '-' }}</td>


    <td [matTooltip]="data?.modifibyName|| '-'"
        (dblclick)="onRowDoubleClickV1(data?.modifibyName || '-')">{{
        data?.modifibyName || '-' }}</td>

    <td [matTooltip]="data?.lastModificationTime"
        (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" class="text-right">
        {{ data?.lastModificationTime }}
    </td>
    

  </tr>
</tbody>
<tfoot>
    <tr>
      <th class="cutomWidth"><mat-icon>apps</mat-icon></th>
      <th>
        <input matInput class="textbox-border-class " type="text"
            placeholder="Status" name="search-Status" />
    </th>
      <th><input
        matInput
        class="textbox-border-class"
        type="text"
        placeholder="Size Name"
        name="search-Size Name"
      /></th>
      <th>
      <input matInput class="textbox-border-class text-right" type="text"
            placeholder="Created Date" name="search-Created Date" />
   </th>
    <th>
      <input matInput class="textbox-border-class" type="text" placeholder="Created By"
          name="search-Created By" />
  </th>

   <th>
      <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
          name="search-Modify By" />
  </th>
    <th>
        <input matInput class="textbox-border-class text-right" type="text"
            placeholder="Modified Date" name="search-Modified Date" />
    </th>



    </tr>
</tfoot>

</table>
