import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';

import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientDocumentBlobDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';

import { ToastrService } from 'ngx-toastr';

import { map, startWith } from 'rxjs/operators';

import { MatDialogRef } from '@angular/material/dialog';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';

import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { Observable, Subscription } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
import { CommonService } from '../../services/common.service';
import { defaultGuid, DocumentMaxandTypeLimits } from '../../enums/allenums.enum';
import { LoaderService } from '../../services/loader-service';

// import type {
//   CreatePatientDocumentBlobDTO,
//   CreateUpdateMedicareDocDTO,
//   DocumentGroupDTO,
//   PatientDocumentBlobDTO,
//   UpdateDocumentBlobDTO,
// } from './dto/models';

let listOfDocuments: PatientDocumentBlobDTO[] = [];
@Component({
  selector: 'app-patient-common-document-medicare-upload-tab',
  templateUrl: './patient-common-document-medicare-upload-tab.component.html',
  styleUrls: ['./patient-common-document-medicare-upload-tab.component.scss'],
})
export class PatientCommonDocumentMedicareUploadTabComponent implements OnInit {
  isPageLoading: boolean = false;
  subscription$: Subscription[] = [];
  patientChartApiSubscription: Subscription;
  isSaveButtonDisabled: boolean = false;
  documentForm: FormGroup;
  maxFileSize = Number(DocumentMaxandTypeLimits.ManualUploadMaxSize);
  lstDocuments: any[] = [];
  filteredDefaultDocuments: any;
  lstDocumentTypes: any[] = [];
  filteredDocumentTypesList: any;
  // isPatientList: any;
  disableDocumentType: boolean = false;
  lstPatientChartNos: any[];
  documentFiles: File[] = [];
  fileDetails: customFileDetails[] = [];
  changesMade:boolean = false;
  constructor(
    private fb: FormBuilder,
    private patientDocumentService: PatientDocumentService,
    private documentBindService: DocumentBinService,
    // public dialog: MatDialog,
    private dialogRef: MatDialogRef<PatientCommonDocumentMedicareUploadTabComponent>,
    private loaderService: LoaderService,
    private mmOrderService: MmOrderService,
    private toastr: ToastrService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      constructorpatientId: string;
      constructortenantId: string;
      selectedWipId: string;
      isPatientList: boolean;
      isPatientDisable?: boolean;
      isDashboard?: boolean;
      defaultFaxId: string;
    } = {
      constructorpatientId: defaultGuid,
      constructortenantId: defaultGuid,
      selectedWipId: defaultGuid,
      isPatientList: false,
      isPatientDisable: false,
      isDashboard: false,
      defaultFaxId: defaultGuid,
    }
  ) {
    this.loaderService.getLoaderState().subscribe((isPageLoading) => {
      this.isPageLoading = isPageLoading;
    });
  }

  ngOnInit(): void {
    console.log(this.data?.isDashboard);
    console.log(this.data?.defaultFaxId);
    this.initializeForms();
    this.getDropdown();
  }

  initializeForms() {
    this.documentForm = this.fb.group({
      drpDocument: new FormControl(''),
      txtDocumentFilter: new FormControl(''),
      drpDocumentType: new FormControl(null, [Validators.required]),
      txtDocumentTypeFilter: new FormControl(''),
      drpPatient: new FormControl(this.data.constructorpatientId, [
        Validators.required,
      ]),
      txtPatientFilter: new FormControl(null),
      fileUpload: ['', Validators.required],
    });
  }
  getDropdown(): void {
    this.searchPatient('', this.data.constructorpatientId);

    const documentBinTypes = this.documentBindService
      // .documentTypeList()
      .getDocumentBinBasedOnFilterByStatusFilter(1)
      .subscribe(
        (response) => {
          if (response) {
            this.lstDocumentTypes = response.map((value) => ({
              docTypeId: value.id,
              documentType: value.documentType.toUpperCase(),
              documentPages: [],
              fileCount: 0,
            }));

            this.lstDocumentTypes.sort((a, b) =>
              a.documentType.localeCompare(b.documentType)
            );

            this.filteredDocumentTypesList = this.documentForm
              .get('txtDocumentTypeFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.lstDocumentTypes?.filter((option) =>
                    option?.documentType
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );
    this.subscription$.push(documentBinTypes);
    this.loadPatientDocumentsDropdown();
  }
  searchPatient(value: any, patientId: string) {
    this.loadPatientChartNos(value, patientId);
  }
  //To Load the Chart No of the Patients
  loadPatientChartNos(searchParameter, patientId) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      // .getPatientChartNoV2BySSearchParams(searchParameter)
      .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter, patientId)
      .subscribe(
        (response) => {
          this.lstPatientChartNos = response ?? [];

          this.documentForm.patchValue({
            drpPatient: this.data.constructorpatientId,
          });
        },
        (err) => {}
      );
  }
  //To load the Patient Documents Dropdown
  loadPatientDocumentsDropdown() {
    const defaultDocumentList = this.patientDocumentService
      .getpatientdocumentDetailsByGpatientId(this.data.constructorpatientId)
      .subscribe(
        (response) => {
          if (response && response.length > 0) {
            this.lstDocuments = response;
            this.filteredDefaultDocuments = this.documentForm
              .get('txtDocumentFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.lstDocuments?.filter((option) =>
                    option?.faxId
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
            // if(this?.data?.isDashboard){

            //   this.documentForm.patchValue({
            //     drpDocument: this.lstDocuments[0].faxId,
            //   });
            // }
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );
    this.subscription$.push(defaultDocumentList);
  }
  totalFilesSize: number = 0;
  totalFilesSizeReadable: string = '';
  //File Browse Event
  onFileChange(event: any): void {
    const files = event?.target?.files;
    if (files.length > 0) {
      this.mergeFileLists(files);
    }
  }
  isRefresh = false;
  calculateTotalSizeValue(files) {
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.totalFilesSize = this.totalFilesSize + totalSize;
    return this.commonService.convertBytesToReadable(this.totalFilesSize);
  }
  width: number = 0;
  filesProcessedV1: number = 1;
  //To merge the files
  async mergeFileLists(newFiles) {
    let filesProcessed = 0;
    this.totalFilesSizeReadable = this.calculateTotalSizeValue(newFiles);
    const totalFiles = newFiles.length;

    const onCompleteProcessing = () => {
      filesProcessed++;
      this.filesProcessedV1++;
      Observable.timer(0, totalFiles)
        .takeWhile(() => this.isWidthWithinLimit(filesProcessed, totalFiles))
        .subscribe(() => {
          this.isProcessBarShow = true;
          this.width = (this.filesProcessedV1 / newFiles.length) * 100;
        });
    };

    for (let i = 0; i < newFiles.length; i++) {
      filesProcessed = i;
      const file = newFiles[i];
      // Check if the file already exists in the array
      const exists = this.documentFiles.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );

      if (!exists) {
        this.documentFiles.push(file);
        this.fileDetails.push({
          fileName: file.name,
          fileSize: this.commonService.convertBytesToReadable(file.size),
          fileType: file.type,
          pageCount: await this.getPDFPageCount(file),
        });
        onCompleteProcessing();
      }
    }
  }
  isWidthWithinLimit(filesProcessed, totalFiles) {
    if (filesProcessed === totalFiles) {
      this.isProcessBarShow = false;
      this.filesProcessedV1 = 1;
      this.width = 1;
      return false;
    } else {
      this.isProcessBarShow = true;
      return true;
    }
  }
  async getPDFPageCount(file: File): Promise<number> {
    try {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes, {
        ignoreEncryption: true,
      });
      const pages = pdfDoc.getPages();
      return pages.length;
    } catch (error) {
      console.error('Error loading PDF document:', error);
      return 0;
    }
  }
  //Upload Documents Api Call
  insertDocuments() {
    this.isSaveButtonDisabled = true;
    const patientId = this.documentForm?.value?.drpPatient ?? defaultGuid;
    const documentTypeId =
      this.documentForm?.value?.drpDocumentType ?? defaultGuid;
    const faxId = this.documentForm?.value?.drpDocument?.docId ?? defaultGuid;
    this.loaderService.showLoader();
    this.patientDocumentService
      .insertPatientDocumentV1ByFileAndDocumentTypeIdAndPatientIdAndFaxId(
        this.documentFiles,
        documentTypeId,
        patientId,
        faxId
      )
      .subscribe(
        (response) => {
          this.isSaveButtonDisabled = false;
          this.changesMade = true;
          // this.dialogRef.closeAll();
          this.closeModal();
          this.loaderService.hideLoader();
          this.toastr.success('Document uploaded successfully');
        },
        (err) => {
          let error: HttpErrorResponse = err.error;
          this.isSaveButtonDisabled = false;
          this.loaderService.hideLoader();
          this.toastr.error(error?.error?.message);
        }
      );
  }
  //Change the Document Type Dropdown based on the Document Dropdown
  updateDocumentTypeDD() {
    let documentType = this.documentForm.value?.drpDocument?.isInbound ?? null;
    let documentTypeId =
      this.documentForm.value?.drpDocument?.documentTypeId ?? null;

    if (!documentType) {
      this.documentForm.patchValue({
        drpDocumentType: documentTypeId,
      });
      this.disableDocumentType = true;
    } else {
      this.disableDocumentType = false;
      this.documentForm.patchValue({
        drpDocumentType: null,
      });
    }
  }

  //Remove the file from the list
  removeFile(document) {
    const index = this.fileDetails.indexOf(document);
    if (index != -1) {
      this.documentFiles.splice(index, 1);
      this.fileDetails.splice(index, 1);
      this.totalFilesSize = 0;
      this.totalFilesSizeReadable = this.calculateTotalSizeValue(
        this.documentFiles
      );
      this.toastr.success('File removed successfully');
    }
  }
  resetForm() {
    let patientValue: any = this.documentForm.value?.drpPatient;
    this.documentForm.reset({
      drpPatient: patientValue,
    });
    this.documentForm.get('drpDocumentType').enable();
    this.fileDetails = [];
    this.documentFiles = [];
    this.isSaveButtonDisabled = false;
    this.totalFilesSize = 0;
    this.totalFilesSizeReadable = '';
  }
  isProcessBarShow: boolean = true;

  get checkFileSizeExceeded(): boolean {
    return this.totalFilesSize > this.maxFileSize;
  }

  closeModal(): void {
    this.dialogRef.close({ changesMade: this.changesMade });
  }
}
export interface customFileDetails {
  fileName: string;
  fileSize: string;
  fileType: string;
  pageCount: number;
}
