import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { OrderMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';



import { tap } from 'rxjs/operators';
import { getOrderDropdown } from './dropdown.action';




@State<DropdownStateModel>({
  name: 'dropdownStates',
  defaults: {
    Dropdown: [],
    selectedDropdown: null
  }
})
@Injectable()
export class orderdropdownState {
  constructor(private masterDropdown: OrderMasterDropdownService) { }

  @Action(getOrderDropdown)
  getDropdowns({ getState, patchState, setState }: StateContext<DropdownStateModel>) {
    // return this.masterDropdown.getByInput().pipe(tap((result) => {
    //   const state = getState();
    //   patchState({
    //     selectedDropdown: result
    //   });

    // }));
  }
}
export class DropdownStateModel {
  Dropdown: OrderMasterDropdownDTO[];
  selectedDropdown: OrderMasterDropdownDTO | null;
}
