import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-billing-deposits',
  templateUrl: './billing-deposits.component.html',
  styleUrls: ['./billing-deposits.component.scss']
})
export class BillingDepositsComponent implements OnInit {
  DataList: any[];
  depositsSearchForm: FormGroup;
  billingDepositsTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [{ targets: 0, orderable: false }],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Payment List',
      },
    ],
  };
  isLoading: boolean = true;
  constructor(private fb: FormBuilder, private datepipe: DatePipe) {}

  ngOnInit(): void {
    this.billingDepositsTable.buttons[0].filename =
      'Deposits List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.initializeForms();
    this.getTableData();
  }
  initializeForms() {
    this.depositsSearchForm = this.fb.group({
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
      drpMethod: new FormControl(''),
      txtMethodFilter: new FormControl(''),
      txtCreatedStartDate: new FormControl(''),
      txtCreatedEndDate: new FormControl(''),
      txtReferenceNo: new FormControl(''),
      txtRemittanceStartDate: new FormControl(''),
      txtRemittanceEndDate: new FormControl(''),
    });
  }
  clearStatus() {
    this.depositsSearchForm.patchValue({
      drpStatus: null,
    });
  }
  clearMethod() {
    this.depositsSearchForm.patchValue({
      drpMethod: null,
    });
  }
  clearCreatedDate() {
    this.depositsSearchForm.patchValue({
      txtCreatedStartDate: null,
      txtCreatedEndDate: null,
    });
  }
  clearRemittanceDate() {
    this.depositsSearchForm.patchValue({
      txtRemittanceStartDate: null,
      txtRemittanceEndDate: null,
    });
  }
  getTableData() {
    this.isLoading = true;
    this.DataList = [];
    const depositsDataArray: any[] = [
      {
        "depositId": "123123123123",
        "total": "3.44",
        "date": "08/22/2024",
        "createdBy": "Toli Kotsasthrou",
        "createdDate": "08/22/2024 05:45 PM",
        "reference": "99242301058479",
        "note": "Created By Transaction processing",
        "status": "Applied",
        "statusBgColor": "lightseagreen",
        "statusFgColor": "#FFFFFF",
        "modified": "08/25/2024 02:40 PM"
      },
      {
        "depositId": "867466546456",
        "total": "125.67",
        "date": "08/21/2024",
        "createdBy": "Sam Johnson",
        "createdDate": "08/21/2024 11:30 AM",
        "reference": "99242301058480",
        "note": "Manual Entry",
        "status": "Failed",
        "statusBgColor": "red",
        "statusFgColor": "#FFFFFF",
        "modified": "09/25/2024 05:15 PM"
      },
      {
        "depositId": "34534534",
        "total": "95.00",
        "date": "08/20/2024",
        "createdBy": "Emily Parker",
        "createdDate": "08/20/2024 02:15 PM",
        "reference": "99242301058481",
        "note": "Electronic Transfer",
        "status": "Pending",
        "statusBgColor": "orange",
        "statusFgColor": "#FFFFFF",
        "modified": "10/25/2024 03:00 PM"
      }
    ]

    this.DataList = depositsDataArray;
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

}
