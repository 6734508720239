import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GooglePlacesDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class GoogleDropdownService {
  apiName = 'DropdownManagement';

  getGooglePlacesBasedOnZipCodeBySZipCode = (sZipCode: string) =>
    this.restService.request<any, GooglePlacesDTO>({
      method: 'GET',
      url: '/api/DropdownApp/google-dropdown/google-places-based-on-zip-code',
      params: { sZipCode },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
