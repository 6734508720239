<div class="container-fluid">
    <!-- Headers -->
    <div class="modal-header">
        <h1 class="customFontClr font-weight-bolder">Order WorkSheet</h1>
        <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
    </div>

    <!-- Content-->
    <div class="row mt-2">
        <!--Row 1-->
        <div class="col-lg-12">
            <div class="card card-body px-2 py-2">
                <div class="d-flex justify-content-center" *ngIf="!loadTable">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>

                <div class="table table-responsive" *ngIf="loadTable">

                    <app-app-work-sheet-table [tableData]="workSheetData"></app-app-work-sheet-table>

                </div>
            </div>
        </div>
    </div>
</div>