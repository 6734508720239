
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CategoryDTO, PatientMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO } from '../billing-proxy/billing-management/rcm/billing-management/posting/dto/models';
import { PostingSearchService } from '../billing-proxy/billing-management/rcm/billing-management/posting/posting-search.service';


@Component({
  selector: 'app-posting-list',
  templateUrl: './posting-list.component.html',
  styleUrls: ['./posting-list.component.scss'],
  providers: [ListService, DatePipe]
})

export class PostingListComponent implements OnInit {
  public strPageType: string = "Positing";
  PATIENTDATA: any[] = [];
  drpCategories: CategoryDTO[] = [];
  claimTableData: any[] = [];
  organizationunitId: string = "";
  postingForm: FormGroup;
  verifyTableData: any[] = []
  isLoading: boolean = true;
  arrDisplayedColumns: string[] = ['options','patientId',  'patientName', 'dob', 'dos', 'planCode', 'insurance', 'createdDateTime',];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>
  organizationUnitId = null;
  constructor(
    private snackBar: MatSnackBar,
    private title: Title,
    private router: Router,
    private patientDropdownService: PatientMasterDropdownService,
    private store: Store,
    public list: ListService,
    private formBuilder: FormBuilder,
    public tableService: TableService,
    private dateValidator: DateValidator,
    private datepipe: DatePipe,
    private claimInformationService: ClaimInformationsService,
    private postingSearchService: PostingSearchService) { }

  ngOnInit(): void {
    this.postingForm = this.formBuilder.group({
      patientId: new FormControl(""),
      patientName: new FormControl(""),
      dob: new FormControl("", this.dateValidator.dateVaidator),
      dos: new FormControl("", this.dateValidator.dateVaidator),
      planCode: new FormControl(""),
      insurance: new FormControl(""),
      createdDateTime: new FormControl("", this.dateValidator.dateVaidator)
    })
    this.postingForm.valueChanges.subscribe((value: PostingList) => {
      let filter: PostingList = {
        patientId: value.patientId.trim().toLowerCase(),
        patientName: value.patientName.trim().toLowerCase(),
        dob: value.dob.trim().toLowerCase(),
        dos: value.dos.trim().toLowerCase(),
        planCode: value.planCode.trim().toLowerCase(),
        insurance: value.insurance.trim().toLowerCase(),
        createdDateTime: value.createdDateTime.trim().toLowerCase(),
      };
      if (this.postingForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.arrDisplayedColumns = ['options','patientId',  'patientName', 'dob', 'dos', 'planCode', 'insurance', 'createdDateTime'];
    this.tableService.getOrganizationUnitId().subscribe((Value) => {
      this.organizationUnitId = Value
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })


    this.loadPostingTable("", "", "", "", "", "", "");
    // this.postingForm.valueChanges.subscribe(value => {
    //   const patientId: string = value.patientId;
    //   const patientName: string = value.patientName;
    //   const dob: string = value.dob;
    //   const dos: string = value.dos;
    //   const planCode: string = value.planCode;
    //   const insurance: string = value.insurance;
    //   const createdDateTime: string = value.createdDateTime;
    //   this.loadPostingTable(patientId, patientName, dob, dos, planCode, insurance, createdDateTime);
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // })
    this.snackBar.dismiss();
    this.title.setTitle("Qsecure | Posting");

  }
  posting() {
    this.router.navigate(['billing/history'])

  }
  getdropdowns() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.Categories
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      let reponse: PatientMasterDropdownDTO = stateResponse;
      this.drpCategories = reponse?.lstCategories;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  ViewPosting(element: any) {
    //
    this.router.navigate(['billing/history/' + element])
  }
  //! Load Posting Charges function
  loadPostingTable(sPatientId: string, sPatientName: string, dtDOB: string, dtDOS: string, sPlanCode: string, sInsuranceName: string, dtCreatedDate: string) {
    this.isLoading = true;
    this.claimTableData = [];
    this.claimInformationService.getList(new PagedAndSortedResultRequestDto).pipe(map(v => v.items)).subscribe(response => {
      response?.forEach(element => {
        const patientDob = element?.saleOrder?.patients?.personals?.dateOfBirth === null ? "" : this.datepipe.transform(element?.saleOrder?.patients?.personals?.dateOfBirth, 'MM/dd/yyyy') ?? "";
        this.claimTableData.push({
          patientId: element?.defaultPatientId,
          patientName: element?.patientName,
          patientDob: patientDob,
          dos: element?.saleOrder?.items[0]?.originalDos === null ? "" : this.datepipe.transform(element?.saleOrder?.items[0]?.originalDos, 'MM/dd/yyyy') ?? "",
          planType: element?.saleOrder?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.plan,
          insurance: element?.saleOrder?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.planName,
          category: "",
          facility: "",
          formDescription: "default",
          process: false,
          id: element.patientId,
          createdDateTime: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
        });
      });
      this.dataSource = new MatTableDataSource(this.claimTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.dataSource = new MatTableDataSource(this.claimTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  filtertable(filter) {
    this.verifyTableData = []
    // const input: PostingSearchRequestDTO = {
    //   sPatientId: filter.patientId,
    //   sPatientName: filter.patientName,
    //   dtDOb: filter.dob,
    //   dtDos: filter.dos,
    //   sPlanCode: filter.planCode,
    //   sInsuranceName: filter.insurance,
    //   dtCreatedDate: filter.createdDateTime,
    // }
    let sDefaultPatientCode = filter.patientId;
    let sPatientName = filter.patientName;
    let dtDOb = filter.dob;
    let dtDos = filter.dos;
    let sPlanCode = filter.planCode;
    let sInsuranceName = filter.insurance;
    let dtCreatedDate = filter.createdDateTime
    this.postingSearchService.searchPostingBySDefaultPatientCodeAndSPatientNameAndDtDObAndDtDosAndSPlanCodeAndSInsuranceNameAndDtCreatedDate(sDefaultPatientCode, sPatientName, dtDOb, dtDos, sPlanCode, sInsuranceName, dtCreatedDate).subscribe(data => {
      data?.forEach((element: ClaimDTO) => {
        // this.verifyTableData.push({
        //   sPatientId: value?.defaultPatientId,
        //   sPatientName: value?.patientName,
        //   dob: value?.patientDob,
        //   dos: value?.dos,
        //   sSaleOrderId: value?.saleorderId,
        //   planCode: value?.planCode,
        //   insuranceName: value?.lstPostingCharges[0].insurance,
        //   createdDate: value?.creationTime,
        // });
        const primaryIns = element?.saleOrder?.patients?.insuranceVerifications?.primaryInsuranceVerifies;

        if (primaryIns !== null) {
          if (element?.saleOrder?.isShippingApprove == 1 && element?.saleOrder?.orders?.isCompleted == 'Done' &&
            element?.lstPostingCharges[element?.lstPostingCharges.length - 1]?.status === 'Ready') {
            this.verifyTableData.push({
              patientId: element?.defaultPatientId,
              patientDob: element?.saleOrder?.patients?.personals?.dateOfBirth === null ? "" : this.datepipe.transform(element?.saleOrder?.patients?.personals?.dateOfBirth, 'MM/dd/yyyy') ?? "",
              patientName: element?.patientName,
              dos: element?.saleOrder?.items[0]?.originalDos === null ? "" : this.datepipe.transform(element?.saleOrder?.items[0]?.originalDos, 'MM/dd/yyyy') ?? "",
              planType: element?.saleOrder?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.plan,
              insurance: element?.saleOrder?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.planName,
              createdDateTime: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            })
          }
        }
        //       if (secondaryIns !== null) {

        //         if (secondaryIns?.verificationStatus == 'Done' && secondaryAuth?.isCompleted == "Done" && value?.orders?.isCompleted == 1) {
        //           this.verifyTableData.push({
        //             patientId: data?.sPatientId,
        //             PatientName: data?.sPatientName,
        //             patientGuId: data?.sPatientId,
        //             createDate: value.creationTime == null ? undefined : new Date(value?.creationTime)?.toLocaleDateString("en-US"),
        //             plan: secondaryIns?.plan,
        //             VerifyName: secondaryIns?.planName,
        //             status: secondaryIns?.verificationStatus,

        //           })
        //         }
        //       }
        //       if (tertiaryIns !== null) {
        //         if (tertiaryIns?.verificationStatus == 'Done' && tertiaryAuth?.isCompleted == "Done" && value?.orders?.isCompleted == 1) {

        //           this.verifyTableData.push({
        //             patientId: data?.sPatientId,
        //             PatientName: data?.sPatientName,
        //             patientGuId: data?.sPatientId,
        //             createDate: value.creationTime == null ? undefined : new Date(value?.creationTime)?.toLocaleDateString("en-US"),
        //             plan: tertiaryIns?.plan,
        //             planCob: tertiaryIns?.planCob,
        //             VerifyName: tertiaryIns?.planName,
        //             status: tertiaryIns?.verificationStatus,

        //           })
        //         }
        //       }
        //     })
        const arrTableData = this.verifyTableData;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
}
export interface PostingList {
  patientId?: string,
  patientName?: string,
  dob?: string;
  dos?: string;
  planCode?: string;
  insurance?: string;
  createdDateTime?: string;

}
