import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SharedTableService } from './shared-table.service';
export type crudPremission = {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
};
@Component({
  selector: 'app-shared-table',
  templateUrl: './shared-table.component.html',
  styleUrls: ['./shared-table.component.scss'],
})
export class SharedTableComponent implements AfterContentInit, OnDestroy {
  @Input() strPageType: string = '';
  @Input() isLoading: boolean = false;
  arrTableData: Partial<any[]> = [];
  arrTableData$: Observable<any[]> = this.sharedTableService.getTableData();
  @Input() arrDisplayedColumns: string[] = [];
  @Input() crudPermisson: crudPremission = {
    create: false,
    read: false,
    update: false,
    delete: false,
  };
  selection = new SelectionModel<any>(true, [], true);
  @Output() viewClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @Output() arrTableDataEventEmitter: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  subscription$: Subscription[] = [];
  constructor(private sharedTableService: SharedTableService) {
    this.fetchTable();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((element) => {
      element && element?.unsubscribe();
    });
  }
  ngAfterContentInit(): void {
    const table$ = this.arrTableData$
      .pipe(distinctUntilChanged())
      .subscribe((response: any[]) => {
        this.arrTableData = response;
        this.fetchTable();
      });
    this.subscription$.push(table$);
  }
  getTooltip(column) {
    return column.payorId + ' / ' + column.payorName;
  }

  getTooltipNotes(data) {
    return data.note;
  }
  fetchTable() {
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 1);
  }
  getRowClass(item){

        if(item.status == 'Active'){
          return{
            'primary-row': item.payorLevel == 'primary',
            'secondary-row': item.payorLevel == 'secondary',
            'tertiary-row': item.payorLevel == 'tertiary',

          }
        } else if(item.status == 'Inactive'){
          return{
            'inactive-row': item.status === 'Inactive',
          }
        }

  }
  //! Document Viewer with Feilds side by side
  documnetViewerSideBySide(fileId?: string) {
    // this.patientId = "480e46cf-a90d-c2c0-feaa-3a0d2b6e51e1";
    // if (fileId && this.patientId) {
    //   const url = '/#/ViewMedicareDetailsComponent/' + [fileId?.toString()] + '/' + [this.patientId?.toString()];
    //   window.open(url, '_blank');
    // } else {
    //   const url = '/#/ViewMedicareDetailsComponent/' + [fileId?.toString()];
    //   window.open(url, '_blank');
    // }
  }
}
