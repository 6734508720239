import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from 'projects/shared/src/app/table.service';

@Component({
  selector: 'app-npi-details',
  templateUrl: './npi-details.component.html',
  styleUrls: ['./npi-details.component.scss']
})
export class NpiDetailsComponent implements OnInit {
  strPageType: string = "npiDetails";
  isLoading: boolean;
  npiDetail = []
    name :any;
    value: any;
    npiType: any;
    gender: any;
    mailAddress:any;
  dataSource = new MatTableDataSource();
  constructor( private Table: TableService,) { }

  ngOnInit(): void {
    this.npiDeatils()
  }

  npiDeatils(){
    this.name = 'Ms. JO NEL MARIE',
    this.value = '675767899',
    this.npiType = 'NPI-1 Individual',
    this.gender = 'Female',
    this.mailAddress = '845 HERITAGE, United States'
    // this.npiDetail.push({
    //   name : 'Ms. JO NEL MARIE',
    //   value: '675767899',
    //   npiType: 'NPI-1 Individual',
    //   gender: 'Female',
    //   mailAddress: '845 HERITAGE, United States' 
    // })
    // // this.Table.setNPIDetails(this.npiDetail);
    // this.dataSource = new MatTableDataSource(this.npiDetail);
  }
}
