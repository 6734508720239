<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18"><b>Fax Bin</b></h4>
        <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">Fax Bin</li>
          </ol>
        </div>
      </div>
      <mat-card-content>
        <app-table
          (viewButton)="previewDocumentById($event)"
          [isLoading]="isLoading"
          (deleteButton)="downloadFile($event)"
          (reminderButton)="processSelectedDocumentsFaxBin($event)"
          [strPageType]="strPageType"
        ></app-table>
      </mat-card-content>
    </div>
  </div>
</div>
