<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0">
        <h3 class="font-weight-bolder mb-0">Product Allowables List</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <!-- (click)="exportTableData()" -->
            <button mat-button class="mr-2 buttonColor"
            [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item ">Inventory</li>
            <li class="breadcrumb-item ">Product Allowables List</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12">
  <div class="card card-body">

    <mat-accordion style="border: 1px solid #dbd5d5de;border-radius: inherit !important;">
      <mat-expansion-panel [expanded]="step" style="display: contents">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <ng-container>
              <b>Add Product Allowables</b>
            </ng-container><br><br>
          </mat-panel-title>
        </mat-expansion-panel-header>
          <mat-card-content>

    <form [formGroup]="allowablesSearchForm">
      <!-- <div class="row"> -->
      <mat-form-field class="algin col-3">
        <mat-label>Select Products</mat-label>
        <mat-select #productSelect multiple formControlName="txtProductName" (selectionChange)="productOnChange()"
          [(value)]="productName">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search Products" noEntriesFoundLabel="No Records found"
              formControlName="txtProductFilter">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option #allProductsSelected [value]="0" (click)="toggleAllProductSelection()">Select All
            Products</mat-option>
          <mat-option *ngFor="let products of filterProducts | async" [value]="products.productName">
            {{ products.productName }}
          </mat-option>
        </mat-select>
        <button mat-button (click)="productName='';$event.stopPropagation()" *ngIf="productName" matSuffix
          (click)="clearProductName()" mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>Select Insurance</mat-label>
        <mat-select #insuranceSelect multiple formControlName="txtInsuranceName" (selectionChange)="insuranceOnChange()"
          [(value)]="insuranceName">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search Products" noEntriesFoundLabel="No Records found"
              formControlName="txtInsuranceFilter">
            </ngx-mat-select-search>
          </mat-option>
          <!-- <mat-option #allInsuranceSelected [value]="0" (click)="toggleAllInsuranceSelection()">Select All
                      Insurance</mat-option> -->
          <mat-option *ngFor="let insurance of filteredInsuranceTypes | async" [value]="insurance.payerName">
            {{ insurance.payerName }}
          </mat-option>
        </mat-select>
        <button mat-button (click)="insuranceName='';$event.stopPropagation()" *ngIf="insuranceName" matSuffix
          (click)="clearInsuranceName()" mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Select Date Range</mat-label>
        <input autocomplete="new-txtCreatedate" matInput (click)="dateOnChange()" type="text" name="daterange" ngxDaterangepickerMd
          [(ngModel)]="selectedRange" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
          [linkedCalendars]="false" [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale"
          [placeholder]="calendarPlaceholder" [keepCalendarOpeningWithRange]="true" [minDate]="minDate"
          [maxDate]="maxDate" formControlName="txtCreatedate" />
        <mat-icon matSuffix>date_range</mat-icon>
      </mat-form-field>
      <div class="col-4" style='top:3px' class="text-align-center">
        <button mat-button class="buttonColor mb-2 mr-2" (click)="search()">Search
        </button>
        <button mat-button class="resetclr" (click)="reset()">Reset
        </button>
      </div>
      <!-- </div> -->
    </form>
    </mat-card-content>
    </mat-expansion-panel>
  </mat-accordion>
  </div>
</div>


<div class="col-12">
  <div class="card card-body" >
    <h4 class="customThemeClass ml-3 mt-2"><b>Product Allowables List</b></h4>
    <div class="tabspace col-12 table-responsive" >
    <!-- <div [hidden]="!isLoading"
      style="display: flex; justify-content: center; align-items: center; background: transparent">
      <mat-progress-bar mode="indeterminate" value="66" diameter="45" class="lightseagreen">
      </mat-progress-bar>
    </div> -->
    <table table mat-table matSort [dataSource]="dataSource" class="border">
      <form style="display: flex">
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef >
            S.No
          </th>
          <td mat-cell *matCellDef="let data">
            {{ data?.sno }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Product Name
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data?.productName }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="insuranceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Insurance Name

          </th>
          <td mat-cell *matCellDef="let data">
            {{ data?.insuranceName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="eligibleQty">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            Eligible Units
          </th>
          <td mat-cell *matCellDef="let data">{{ data?.eligibleQty }}</td>
        </ng-container>
        <ng-container matColumnDef="eligibleMonths">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            Eligible Months
          </th>
          <td mat-cell *matCellDef="let data">{{ data?.eligibleMonths }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            Created Date
          </th>
          <td mat-cell *matCellDef="let data">{{ data?.createdDate }}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Options
          </th>
          <td mat-cell *matCellDef="let data">
            <a class="editBtnColor" (click)="openAllowablesEditModal(data)">
              <i class="fa fa-pencil" matTooltip="Click To Edit" ></i>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
            <div *ngIf="!isLoading">
              {{"No Records Found"}}
            </div>
            <div [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="11">
            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
              [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </td>
        </ng-container>
      </form>
      <tr mat-footer-row *matFooterRowDef="['noRecords']"
        [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
    </table>
  </div>
</div>
</div>
