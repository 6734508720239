<table id="tblPatientList" datatable [dtOptions]="dtViewPatientList" class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th [matTooltip]="'Patient Name'">Patient Name</th>
            <th [matTooltip]="'Fax Number'">Fax Number</th>
            <th [matTooltip]="'Fax Id'">Fax Id</th>
            <th [matTooltip]="'Last Fax Received Date'" class="text-center">Last Fax Received Date</th>>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of patienttbldata">
    
            <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'"> {{
                data?.patientName
                || '-' }} </td>
            <td (dblclick)="onRowDoubleClick(data?.faxNumber || '-')" [matTooltip]="data?.faxNumber || '-'"> {{ data?.faxNumber || '-' }}
            </td>
            <td (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')" [matTooltip]="data?.defaultFaxId || '-'">{{ data?.defaultFaxId || '-' }}</td>
            <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.lastSenddate))" [matTooltip]="getFormattedDate(data?.lastSenddate)" class="text-center">{{ getFormattedDate(data?.lastSenddate) }}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient Name"
                    name="search-PatientName" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Fax Number"
                    name="search-FaxNumber" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Fax Id" name="search-Fax Id" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center" type="text" placeholder="Last Fax Received Date"
                    name="search-LastFaxReceivedDate" />
            </th>
        </tr>
    </tfoot>
</table>