<!-- <br />
<div class="card card-body">
  <div class="row mb-3">
    <div class="col-md-6">
      <h4 class="customThemeClass"><b>Order History</b></h4>
    </div>
    <div *ngIf='isShownOrderButton' class="col-md-6 text-sm-right">
      <div class="text-sm-right col-lg-12 p-0">
        <button mat-button class="buttonColor font-size-14" (click)="newSaleorder()">
          <i class="fa fa-plus mr-1"></i>
          New SaleOrder
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 table-responsive">
      <app-shared-table [arrDisplayedColumns]="arrDisplayedColumns" (viewClick)="editSaleOrder($event)"
        [strPageType]="strPageType"></app-shared-table>
    </div>
  </div>
</div> -->
<br />
<div class="card card-body" style="margin-top: 2px !important;">
  <div class="row mb-1">
    <div class="col-md-6">
      <h4 class="customThemeClass"><b>Active Orders</b></h4>
    </div>
    <div class="col-md-6 text-sm-right">
      <div class="text-sm-right col-lg-12 p-0">
        <button mat-button [disabled]="!editFormValues" class="buttonColor font-size-14" (click)="newSaleorder()">
          <i class="fa fa-plus mr-1"></i>
          New SaleOrder
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="bg mb-5"> </mat-divider>
  <div class="row">
    <div class="col-12 table-responsive">
      <!-- <app-table (viewButton)="ViewOrders($event)"   [isLoading]="isLoading" [strPageType]="strPageType">
      </app-table> -->
      <app-shared-table [arrDisplayedColumns]="arrDisplayedColumns" (viewClick)="ViewOrders($event)"
        [isLoading]="isLoading" [strPageType]="strPageType"></app-shared-table>
    </div>

  </div>
  <div class="row mt-1">
    <div class="col-md-6">
      <h4 class="customThemeClass"><b>Order Templates</b></h4>
    </div>
    <div class="col-md-6 text-sm-right">
      <div class="text-sm-right col-lg-12 p-0">
        <button mat-button [disabled]="!editFormValues" class="buttonColor font-size-14 mr-2" (click)="newOrderTemplate()">
          <i class="fa fa-plus mr-1"></i>
          New Template
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="bg mb-5"> </mat-divider>
  <div class="row">
    <div class="col-12 pb-5">
      <div class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0">
        <table mat-table id="{{ strPageType }}" class="pt-0 mt-0" matTableExporter #exporter="matTableExporter"
          [hiddenColumns]="[14]" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="templateId">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center">Template Id</mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              <a matTooltip="Click to View" class="eyeCursorclass"
                [routerLink]="'/orders/OrderTemplateCreateEditTab/' + patientId +'/' + element?.saleOrderId + '/' +element?.id"
                target="_blank">
                {{ element?.templateCode }}
              </a>
            </td>
          </ng-container>



          <ng-container matColumnDef="startDate">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Start Date</mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.startDate | date : "MM/dd/yyyy" : "en_US" }}
            </td>
          </ng-container>


          <ng-container matColumnDef="rentalTypes">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Schedule Type </mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container [ngSwitch]="element?.rentalTypes">
                <ng-container *ngSwitchCase="'monthly'">Monthly
                </ng-container>
                <ng-container *ngSwitchCase="'daily'">Daily
                </ng-container>
                <ng-container *ngSwitchCase="'once'">Once
                </ng-container>
                <ng-container *ngSwitchCase="'weekly'">Weekly
                </ng-container>
                <ng-container *ngSwitchDefault>None</ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="defaultOrderId">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Sale Order Id</mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              <a matTooltip="Click to View Sale Order" class="eyeCursorclass" [routerLink]="
                  '/orders/saleorderCreateEditTab/' +
                  element?.patientId +
                  '/' +
                  element?.orderId
                " target="_blank">
                {{ element?.defaultOrderId }}
              </a>
            </td>
          </ng-container>



          <ng-container matColumnDef="endPeriod">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> End Period</mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.endPeriod }}
            </td>
          </ng-container>
          <ng-container matColumnDef="billedQty">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Billed Units </mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.billedQuantity }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Status </mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-slide-toggle matTooltip="Click to Disable" (change)="templateStatusUpdate(element?.id)"
                [checked]="element?.isEnabled===1">
              </mat-slide-toggle>
            </td>
          </ng-container>



          <ng-container matColumnDef="lastRun">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Last Run </mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element?.lastRun| date : "MM/dd/yyyy" : "en_US" }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="lstSaleOrderIds">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> No of SaleOrders </mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element?.lstSaleOrderIds?.length }} </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Options">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-label> Options</mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              <a *ngIf="element?.claimId" class="eyeCursorclass customThemeClass ml-2">
                <i [routerLink]="'/AddNewPosting/' + element?.claimId" target="_blank" class="fa fa-eye"
                  matTooltip="Click To View Manual Posting List">
                </i>
              </a>
            </td>
          </ng-container>
          <ng-container matColumnDef="nextRun">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Next Run </mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.nextRun| date : "MM/dd/yyyy" : "en_US" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="periodsRun">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-label class="center"> Periods Run </mat-label>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.periodsRun }}
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns2"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns2"></tr>
        </table>
        <div *ngIf=" !( dataSource && dataSource?.data && dataSource?.data?.length !== 0 ) "
          class="no-records text-align-center">
          <div class="message py-3" *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div class="py-3" [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </div>
      </div>
      <mat-paginator #MatPaginator [length]="dataSource?.data?.length" class="mat-elevation-z2" [pageSize]="10"
        [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
    </div>
  </div>
</div>
