<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="d-flex justify-content-between">
                <div class="page-title-box page-title-left pb-0">
                    <h3 class="mb-3 mt-2 font-size-18 customThemeClass"><b>MIS Report / Text Conversation</b></h3>
                </div>
                <div class="page-title-box page-title-right pb-0">
                    <form [formGroup]="fmTextConversation">
                        <div class="d-flex align-items-center">
                            <!-- CSR Dropdown -->
                            <mat-form-field class="pr-2 pt-1 mr-3">
                                <mat-label>
                                    <div class="select-placeholder-container" *ngIf="isUserLoading">
                                        <span>Loading...</span>
                                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                    </div>
                                    <span *ngIf="!isUserLoading">CSR </span>
                                </mat-label>
                                <mat-select formControlName="drpUsertype">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="UserTypeControl" placeholderLabel="Search"
                                            noEntriesFoundLabel="No Matches found" (keyup)="UserSearch($event.target.value)"
                                            formControlName="txtUserType"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let users of lstFilterUsers" [value]="users?.userId">
                                        {{ users?.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Date Picker -->
                            <mat-form-field class="pr-2 pt-1">
                                <input placeholder="Month" formControlName="txtDateValue" matInput [(value)]="dateValue"
                                    readonly (focus)="openDatePicker()" />
                                <input matInput maxlength="40" formControlName="txtDate" type="text" [hidden]="true"
                                    autocomplete="new-txtDateValue" matInput [(value)]="dateValue"
                                    [matDatepicker]="txtDateValue" [max]="maxDate" />
                                <mat-datepicker-toggle matSuffix [for]="txtDateValue"></mat-datepicker-toggle>
                                <mat-datepicker #txtDateValue startView="multi-year"
                                    (monthSelected)="chosenMonthHandler($event, txtDateValue)"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <div class="col-lg-12 pb-5">
            <div class="row">
                <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                    <div class="card d-flex justify-content-center elevated-div mytree p-2 overflow-hidden"
                        style="height: 75vh">
                        <div class="total-count-label">
                            <b class="card-content-medium">CSR List</b>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="scrollable-content mt-1">
                            <app-text-conversation-admin *ngIf="roleName === 'admin'"></app-text-conversation-admin>
                            <app-text-conversation-csr *ngIf="roleName === 'CSR'"></app-text-conversation-csr>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-md-8 col-lg-8 col-xl-8 overflow-auto" style="height: 75vh">
                    <app-text-conversation></app-text-conversation>
                </div>

                <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                    <div class="card d-flex justify-content-center elevated-div mytree p-2 overflow-hidden"
                        style="height: 75vh">
                        <div class="total-count-label">
                            <b class="card-content-medium">Patient Details</b>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="scrollable-content mt-1">
                            <app-text-conversation-patient-details></app-text-conversation-patient-details>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
