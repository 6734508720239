<mat-dialog-content class="mat-typography">
  <!-- cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle -->
  <div class="modal-header d-flex align-items-center justify-content-between">
    <h1 class="modal-title head" >Send Mail-{{data.chartId}}</h1>
    <div class="page-title-box page-title-right" style="padding-left: 500px;">
      <h3 class="text-right text-danger" ><i class="fa fa-exclamation-circle" ></i> Document is Required</h3>
    </div>
    <button class="text-right close" aria-label="Close" mat-dialog-close>
      <b> X </b>
    </button>
  </div>
  <form [formGroup]="sendMailForm">
    <div class="modal-body">
      <mat-card-content>
         <div class="row">
           <div class="col-6">
            <span class="asterisk" *ngIf="showErrorBasedOnMaxSize">
              {{ 'Max Total file`s size of 20MB or Less' }}
            </span>
            <div class="drop-area ">
              <!-- <span class="asterisk text-left" *ngIf="showErrorForFileType">
                  {{ "Select Only PDF`s" }}
                </span> -->


              <div class="file-upload-container " >
                  <div style="padding-bottom: 17px;">
                      Have file on your computer
                  </div>
                  &nbsp;
                  <button class="file-upload-button cursor" >
                    <div class="row" style="padding-left: 56px;">
                      <div class="brow-align mr-3">Browse</div>
                      <mat-icon class="cursor">cloud_upload</mat-icon>
                    </div>
                    <input autocomplete="new-documentFile" id="file-upload" type="file" formControlName="documentFile"
                      class="cursor" (change)="onFileChange($event)" [multiple]="true" #uploadFileReference
                      accept="application/pdf" (drop)="false"/>
                  </button>
              </div>
                 </div>
           </div>
           <div class="col-6">
             <div class="row">
                <mat-form-field class="col-12">
              <mat-label>Practitionar Mail<span
                class="asterisk"
                >*</span></mat-label>
              <input autocomplete="new-txtEmail" matInput maxlength="40"
                  formControlName="txtEmail" type="text"
                  pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  [textMask]="{ mask: emailMask }" />
              <mat-error
                  *ngIf="sendMailForm?.get('txtEmail')?.errors?.required">
                  Email is a required field!
              </mat-error>
              <mat-error
                  *ngIf="sendMailForm?.get('txtEmail')?.errors?.pattern">
                  This is not a valid Email!
              </mat-error>
                </mat-form-field>

            <mat-form-field class="col-12" style="margin-top:25px;">
              <mat-label>
                Document</mat-label
              >
              <input  class="doclist"
                matInput
                formControlName="document"
                type="text"
                [readonly]="true"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="
                  sendMailForm?.get('document')?.errors?.required
                "
              >
                Document is required!
              </mat-error>
              <button
                type="button"
                class="cls-rxbutton"
                mat-button
                [disabled]="documentId===defaultGuid|| documentId===null|| documentId===undefined"
                [matTooltip]="(documentId===defaultGuid|| documentId===null|| documentId===undefined)?'Document Unsorted':
                    'Click to Select RX Document'
                "
                matSuffix
                mat-icon-button
                (click)="selectRXDocument()"
              >
                <!-- <mat-icon class="clr-lightseagreen">info</mat-icon> -->
                {{ ltRXDocument?.length }}
              </button>
              <button mat-button   matSuffix mat-icon-button  (click)="removeDocument()">
                <mat-icon>close</mat-icon>
               </button>
            </mat-form-field>
              </div>
           </div>
         </div>

      </mat-card-content>
    </div>
    <div class="modal-footer">
      <div class="col-12 text-center">
        <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button
        [disabled]="sendMailForm?.invalid ||isSaveLoading"
         >
          <span (click)="sendRxMail()">Send Mail</span>
          <mat-icon  *ngIf="isSaveLoading">
            <mat-spinner class="spinner-border-sm ml-1" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
        <button mat-button class="resetclr mb-2 mr-2" (click)="resetForm()">Reset</button>
      </div>
    </div>
  </form>
</mat-dialog-content>
