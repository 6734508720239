<!-- Title -->
<div class="row align-items-center">

    <div class="col-md-5">
        <h2 class="customThemeClass">
            {{notesTitle}}
        </h2>
    </div>
    <div class="col-md-5">
    </div>
    <div class="col-md-2" style="text-align: right">
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
</div>

<!-- Title -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body pb-0 mb-1 mt-1">
                <!-- Notes Form -->
                <form [formGroup]="noteForm">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <mat-card-content>
                                    <div class="row">
                                        <mat-form-field class="col-12">
                                            <mat-label>Notes<span class="asterisk">*</span></mat-label>
                                            <textarea matInput rows="6" autocomplete="off" maxlength="5000"
                                                formControlName="txtDescription" type="text" [(ngModel)]="notesText" (input)="updateCharacterCount(5000)"></textarea>
                                                <div class="char-count">
                                                    {{ notesText?.length }} / 5000
                                                  </div>
                                            <mat-error class="spanAlignment"
                                                *ngIf="noteForm?.get('txtDescription')?.touched">
                                                <ng-container
                                                    *ngIf="noteForm?.get('txtDescription')?.hasError('required')">
                                                    Notes is a required field!
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="noteForm?.get('txtDescription')?.hasError('whitespace')">
                                                    Notes cannot be just whitespace!
                                                </ng-container>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </mat-card-content>

                                <mat-dialog-actions align="end">

                                    <div class="col-12 text-center mt-1">
                                        <button mat-button class="buttonColor mb-2 mr-2"
                                          [disabled]="isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty)" (click)="saveNotes()">
                                          {{isSave}}
                                          <mat-icon *ngIf="isShowSpinner">
                                            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                                          </mat-icon>
                                        </button>
                                        <button mat-button class="resetclr mb-2 mr-2" (click)="resetNotes()">Reset</button>
                                    </div>
                                </mat-dialog-actions>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>