import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdatePaymentSettingsDTO, TenantOfficialInformationDTO } from '../tenants/models';

@Injectable({
  providedIn: 'root',
})
export class PaymentSettingService {
  apiName = 'platformManagement';

  deletePaymentSettingsByTenantIdAndPaymentOptionId = (tenantId: string, paymentOptionId: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/PlatformApp/payment-setting/delete-payment-settings',
      params: { tenantId, paymentOptionId },
    },
     { apiName: this.apiName, skipHandleError: true });

  getPaymentSettings = (tenantId: string) =>
    this.restService.request<any, TenantOfficialInformationDTO>({
      method: 'GET',
      url: `/api/PlatformApp/payment-setting/get-payment-settings/${tenantId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  savePaymentSettings = (tenantId: string, input: CreateUpdatePaymentSettingsDTO) =>
    this.restService.request<any, TenantOfficialInformationDTO>({
      method: 'POST',
      url: `/api/PlatformApp/payment-setting/save-payment-settings/${tenantId}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updatePaymentSettings = (tenantId: string, input: CreateUpdatePaymentSettingsDTO) =>
    this.restService.request<any, TenantOfficialInformationDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/payment-setting/update-payment-settings/${tenantId}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
