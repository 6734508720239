import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {


  private _subject = new Subject<Reminder>();
  private _idx = 0;

  constructor() { }

  getObservable(): Observable<Reminder> {
    return this._subject.asObservable();
  }

  info(title: string, message: {patientName:string,subject:string}, timeout = 3000) {
    this._subject.next(new Reminder(this._idx++, ReminderType.info, title, message, timeout));
  }

  success(title: string, message: {patientName:string,subject:string}, timeout = 3000) {
    this._subject.next(new Reminder(this._idx++, ReminderType.success, title, message, timeout));
  }

  warning(title: string, message: {patientName:string,subject:string}, timeout = 3000) {
    this._subject.next(new Reminder(this._idx++, ReminderType.warning, title, message, timeout));
  }

  error(title: string, message: {patientName:string,subject:string}, timeout = 0) {
    this._subject.next(new Reminder(this._idx++, ReminderType.error, title, message, timeout));
  }

}
export class Reminder {

  constructor(
    public id: number,
    public type: ReminderType,
    public title: string,
    public message: {patientName:string,subject:string},
    public timeout: number,
  ) { }

}

export enum ReminderType {
  success = 0,
  warning = 1,
  error = 2,
  info = 3
}
