import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient';
import { ItemExportDatas, ItemExportDTO, PverifyPayersDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';

import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AllowablesEditComponent } from '../allowables-edit/allowables-edit.component';
import { ProductAllowablesService, ProductService, ProductType } from '../item-proxy/item-management/items';
import { ProductsDTO, SearchProductAllowablesDTO } from '../item-proxy/item-management/items/dto/models';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-allowables-list',
  templateUrl: './allowables-list.component.html',
  styleUrls: ['./allowables-list.component.scss'],
  providers: [ListService, DatePipe]
})
export class AllowablesListComponent implements OnInit , OnDestroy{
  allowablesSearchForm: FormGroup;
  insuranceName: string = '';
  productName: string = '';
  tableIndex: number = 0;
  isLoading: boolean = false;
  allowablesTableData: any[] = [];
  drpInsurance: PverifyPayersDTO[] = [];
  drpProducts: any[] = [];
  step: boolean = false;
  public filteredInsuranceTypes: Observable<PverifyPayersDTO[]> | undefined;
  public filterProducts: Observable<ProductsDTO[]> | undefined;
  @ViewChild('allProductsSelected') private allProductsSelected: MatOption;
  @ViewChild('productSelect') productSelect: MatSelect;
  @ViewChild('allInsuranceSelected') private allInsuranceSelected: MatOption;
  @ViewChild('insuranceSelect') insuranceSelect: MatSelect;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'options','productName', 'insuranceName', 'eligibleQty', 'eligibleMonths', 'createdDate', ];
  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';

  subscription$: Subscription[] = [];

  constructor(
    public title: Title,
    public allowablesService: ProductAllowablesService,
    public list: ListService,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public pverifyPayersService: PverifyPayersService,
    public productService: ProductService,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };
    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      //'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      //'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
  }

  ngOnInit(): void {
    this.allowablesSearchForm = this.formBuilder.group({
      txtProductName: new FormControl(""),
      txtProductFilter: new FormControl(""),
      txtInsuranceName: new FormControl(""),
      txtInsuranceFilter: new FormControl(""),
      txtCreatedate: new FormControl(""),
    });
    //Set Title
    this.title.setTitle("Qsecure | Product Allowables List");
    this.getTableData();
    this.getDropdowns();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //Load Allowables Table Data
  getTableData() {
    this.isLoading = true;
    this.allowablesTableData = [];
    const allowablesList = query => this.allowablesService.getList(query);
    const AllowableLists = this.list.hookToQuery(allowablesList).subscribe(response => {
      response && response?.items?.forEach(datas => {
        if (datas) {
          datas?.insuranceValidities.forEach(element => {
            this.tableIndex += 1;
            this.allowablesTableData.push({
              sno: this.tableIndex,
              id: datas?.id,
              productId: datas?.productId,
              productName: datas?.productName,
              ruleId: element?.ruleId,
              insuranceId: element?.insuranceId,
              insuranceName: element?.insuranceName,
              eligibleQty: element?.eligibleQuantity,
              eligibleMonths: element?.eligibleMonths,
              createdDate: element?.ruleCreatedDate === null ? "" : this.datepipe.transform(element?.ruleCreatedDate, 'MM/dd/yyyy') ?? "",
            });
          });
        }
      })
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.allowablesTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
      this.isLoading = false;
    });
    this.subscription$.push(AllowableLists);
  }

  //Allowables search
  search() {
    this.isLoading = true;
    let productNames: any[] = [];
    let insuranceNames: any[] = [];
    this.allowablesSearchForm.value.txtProductName !== "" &&
      this.allowablesSearchForm.value.txtProductName !== undefined &&
      this.allowablesSearchForm.value.txtProductName !== null &&
      [this.allowablesSearchForm.value.txtProductName].length !== 0 &&
      [this.allowablesSearchForm.value.txtProductName].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && productNames.push(element);
        });
      });

    this.allowablesSearchForm.value.txtInsuranceName !== "" &&
      this.allowablesSearchForm.value.txtInsuranceName !== undefined &&
      this.allowablesSearchForm.value.txtInsuranceName !== null &&
      [this.allowablesSearchForm.value.txtInsuranceName].length !== 0 &&
      [this.allowablesSearchForm.value.txtInsuranceName].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && insuranceNames.push(element);
        });
      });
    let input: SearchProductAllowablesDTO = {
      productNames: productNames,
      insuranceNames: insuranceNames,
      createdFromDate: this.selectedRange?.startDate?.format('YYYY-MM-DD'),
      createdToDate: this.selectedRange?.endDate?.format('YYYY-MM-DD')
    }

    const allowableSearch = this.allowablesService.searchProductAllowablesByInput(input).subscribe(response => {
      this.allowablesTableData = [];
      response && response?.items?.forEach(datas => {
        if (datas) {
          datas?.insuranceValidities.forEach(element => {
            this.tableIndex += 1;
            this.allowablesTableData.push({
              sno: this.tableIndex,
              id: datas?.id,
              productId: datas?.productId,
              productName: datas?.productName,
              ruleId: element?.ruleId,
              insuranceId: element?.insuranceId,
              insuranceName: element?.insuranceName,
              eligibleQty: element?.eligibleQuantity,
              eligibleMonths: element?.eligibleMonths,
              createdDate: element?.ruleCreatedDate === null ? "" : this.datepipe.transform(element?.ruleCreatedDate, 'MM/dd/yyyy') ?? "",
            });
          });
        }
      })
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.allowablesTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
      this.isLoading = false;
    })
    this.subscription$.push(allowableSearch);
  }

  //Get Search Dropdowns
  getDropdowns(): void {
    const pVerifyGetList = this.pverifyPayersService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpInsurance = [];
      response?.items?.forEach(element => {
        this.drpInsurance.push({
          id: element.id,
          payerName: element?.payerName
        });
      });

      this.filteredInsuranceTypes = this.allowablesSearchForm?.get("txtInsuranceFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpInsurance.filter(option => option?.payerName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$.push(pVerifyGetList);
    //
    const getProduct = this.productService.getProductsByInput(ProductType.All).subscribe(response => {
      this.drpProducts = [];
      response?.forEach(element => {
        this.drpProducts.push({
          productId: element?.productId,
          productName: element?.productName,
          name: element?.name,
          hcpcCode: element?.hcpcCode
        });
      });

      this.filterProducts = this.allowablesSearchForm?.get("txtProductFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpProducts.filter(option => option?.productName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    this.subscription$.push(getProduct);
  }

  //Open Allowables Edit Modal Popup
  openAllowablesEditModal(data: any) {
    const dialogRef = this.dialog.open(AllowablesEditComponent, {
      disableClose: true,
      data: { id: data?.id, ruleId: data?.ruleId, productName: data?.productName, productId: data?.productId }
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getTableData();
    });
    this.subscription$.push(closeModal);
  }

  //Clear Insurance Dropdown
  clearInsuranceName() {
    this.insuranceName = '';
    this.allowablesSearchForm.patchValue({ txtInsuranceName: "" });
  }

  //Clear Product Name
  clearProductName() {
    this.productName = '';
    this.allowablesSearchForm.patchValue({ txtProductName: "" });
  }

  //Select All Products
  toggleAllProductSelection() {
    if (this.allProductsSelected.selected) {
      this.productSelect.options.forEach((item: MatOption) => item.select());
      
    }
    else {
      this.productSelect.options.forEach((item: MatOption) => item.deselect());
      
    }
  }

  //Select All Insurances
  toggleAllInsuranceSelection() {
    if (this.allInsuranceSelected.selected) {
      this.insuranceSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.insuranceSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  //Product Dropdown on change
  productOnChange() {
    this.allowablesSearchForm.patchValue({
      // txtInsuranceName: "",
      // txtCreatedate: ""
      txtProductName: "",
      txtCreatedate: ""
    });
    
  }

  //Insurance dropdown on changes
  insuranceOnChange() {
    this.allowablesSearchForm.patchValue({
      // txtProductName: "",
      // txtCreatedate: ""
      txtInsuranceName: "",
      txtCreatedate: ""
    });
    
  }

  //Date on changes
  dateOnChange() {
    this.allowablesSearchForm.patchValue({
      // txtProductName: "",
      // txtInsuranceName: ""
    });
  }

  //Reset Search form
  reset() {
    this.allowablesSearchForm.reset();
    this.getTableData();
  }

  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  //export table data for Allowables-List
  exportTableData() {
    let datas: ItemExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        productName: element?.productName,
        insuranceName: element?.insuranceName,
        eligibleQty: String(element?.eligibleQty),
        eligibleMonths: String(element?.eligibleMonths),
        allowablesCreatedDate: element?.createdDate
      });
    })
    let itemExport: ItemExportDTO = {
      columns: ["Product Name", "Insurance Name", "Eligible Qunatity", "Eligible Months", "Allowables Created Date"],
      datas: datas
    };

    const exportItem = this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.AllowablesReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Allowables-List") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportItem);
  }
}
function dealy(arg0: number): import("rxjs").OperatorFunction<import("@abp/ng.core").PagedResultDto<import("../item-proxy/item-management/items/dto/models").ProductAllowablesDTO>, unknown> {
  throw new Error('Function not implemented.');
}

