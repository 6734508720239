<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-3 mb-0 font-size-18"><b>Product Brand</b></h4>
        <div class=" page-title-box  page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <!-- (click)="exportTableData()" -->
            <button mat-button class="mr-2 buttonColor"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item mt-2">Inventory</li>
            <li class="breadcrumb-item mt-2">Product Brand</li>
          </ol>
        </div>
      </div>
    </div>
    </div>
    <div>
      <div class="col-12">
    <div class="card card-body ">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container *ngIf="brandId === ''; else elseTitleTemplate">
                <b>Add Product Brand</b>
              </ng-container><br><br>
              <ng-template #elseTitleTemplate>
                <b>Edit Product Brand</b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
            <mat-card-content>
              <form #pBrandForm [formGroup]="productBrandForm">
                <div class="row">
                  <mat-form-field class="algin ">
                    <mat-label>Product Brand Name<span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtpbName" matInput maxlength="40" formControlName="txtpbName" placeholder="Enter Product Brand Name"
                      type="text" pattern="^[a-zA-Z0-9-_\s]*$" />
                    <mat-error
                      *ngIf="getPBForm()?.get('txtpbName')?.invalid && getPBForm()?.get('txtpbName')?.errors?.required">
                      Product Brand Name is a required field!
                    </mat-error>
                    <mat-error *ngIf="
                    getPBForm()?.get('txtpbName')?.touched &&
                    getPBForm()?.get('txtpbName')?.errors
                  ">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>

                  <!-- <div class="col-2 mr-4 mt-3">
                    <mat-checkbox formControlName="txtpbStatus">Product Brand Status </mat-checkbox>
                  </div> -->
                  <div class="col-5 text-lg-left">
                    <button mat-button class="buttonColor mb-2 mr-2"
                      [disabled]="saveButtonHide||!(!getPBForm()?.invalid && getPBForm()?.dirty)"
                      (click)="saveProductBrand()">
                      Save
                    </button>
                    <button mat-button (click)="$event.stopPropagation()" class="resetclr buttonColor mb-2 mr-2"
                      (click)="resetPBform()">
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </mat-card-content>
         
        </mat-expansion-panel>
      </mat-accordion>
      </div>
      </div>
    </div>
    <div class="col-12">
    <div class="borderSpace card card-body">
      <h4 class="customThemeClass ml-3 mt-2"><b>Product Brand List</b></h4>
      <div class="row ml-2">
      <div class="tabspace col-12 table-responsive">
        <!-- <div [hidden]="!isShowSpinner"
          style="display: flex; justify-content: center; align-items: center; background: transparent">
          <mat-progress-bar mode="indeterminate" value="66" diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        
          <table table mat-table matSort [dataSource]="dataSource" class="border">
            <form style="display: flex" [formGroup]="pBSearchForm">
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef >
                  S.No
                </th>
                <td mat-cell *matCellDef="let data; let i = index">
                  {{ i+1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="productBrandName">
                <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-pbName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="pbName">
                    <mat-placeholder>Product Brand Name</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    {{ data.productBrandName }}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="pbStatus">
                <th class="header" mat-header-cell *matHeaderCellDef >
                  <mat-form-field class="filter">
                    <input autocomplete="new-pbStatus" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="pbStatus">
                    <mat-placeholder>Product Brand Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    {{ data.status }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="creationTime">
                <th class="header" mat-sort-header class="w-125" mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <mat-label> Created Date </mat-label>
                    <input autocomplete="new-pbCreatedOn" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="pbCreatedOn" placeholder="(MM/DD/YYYY)" />
                    <mat-error
                      *ngIf=" getPBSForm()?.get('pbCreatedOn')?.touched && getPBSForm()?.get('pbCreatedOn')?.errors?.dateVaidator">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.creationTime | date: 'MM/dd/yyyy h:mm a':'en_US'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-Status" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="Status">
                    <mat-placeholder>Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container *ngIf="data?.status==='Active'">
                    <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true' class="ml-1">
                      {{data?.status}}</mat-slide-toggle>
                  </ng-container>
                  <ng-container *ngIf="data?.status==='Inactive'">
                    <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false' class="ml-1">
                      {{data?.status}}</mat-slide-toggle>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="Options">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Options
                </th>
                <td mat-cell *matCellDef="let data">
                  <a *ngIf="data?.status==='Active'" class="editBtnColor mr-2" (click)="Viewbrand(data?.id)">
                    <i class="fa fa-pencil pointer" matTooltip="Click To Edit" ></i>
                  </a> &nbsp;
                  <a [hidden]="true" *ngIf="data?.status==='Active'" class="deleteBtnColor"
                    (click)="deletebrand(data?.id)">
                    <i class="fa fa-trash pointer" matTooltip="Click To Delete" ></i>
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="noRecords">
                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                  <div *ngIf="!isShowSpinner">
                    {{"No Records Found"}}
                  </div>
                  <div [hidden]="!isShowSpinner" >
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container>
            </form>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
        </table>
        <mat-paginator #MatPaginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="10">
        </mat-paginator>
        </div>
        </div>
      </div>
    </div>
   
 
</div>
