<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <div class="d-flex justify-content-between">
          <p class="header-text mt-2">Patient Details</p>
          <button mat-icon-button mat-dialog-close class="close-button">
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <form [formGroup]="patientDetailsForm">
          <!--Chart No -->
          <mat-form-field class="col-3">
            <mat-label>Chart No</mat-label>
            <input matInput formControlName="txtChartNo" readonly />
          </mat-form-field>

          <!-- First Name -->
          <mat-form-field class="col-3">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="txtFirstName" readonly />
          </mat-form-field>

          <!-- Last Name -->
          <mat-form-field class="col-3">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="txtLastName" readonly />
          </mat-form-field>

          <!-- Middle Name -->
          <mat-form-field class="col-3">
            <mat-label>Middle Name</mat-label>
            <input matInput formControlName="txtMiddleName" readonly />
          </mat-form-field>

          <!-- Suffix -->
          <mat-form-field class="col-3">
            <mat-label> Suffix</mat-label>
            <mat-select formControlName="drpSuffix" [disabled]="true">
              <mat-option
                *ngFor="let type of drpSuffixes"
                [value]="type.suffixShortCodeId"
              >
                {{ type.suffixName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Birth Date-->
          <mat-form-field class="col-3">
            <mat-label> Birth Date </mat-label>
            <input
              [max]="BirthdateMaxDate"
              matInput
              readonly
              maxlength="40"
              formControlName="txtBirthdate"
              type="text"
              placeholder="(MM/DD/YYYY)"
              autocomplete="new-txtBirthdate"
              matInput
              [matDatepicker]="txtBirthdate"
              [min]="BirthdateMinDate"
            />
            <mat-datepicker-toggle
              [disabled]="true"
              matSuffix
              [for]="txtBirthdate"
            >
            </mat-datepicker-toggle>
            <mat-datepicker #txtBirthdate> </mat-datepicker>
            <mat-error
              *ngIf="
                patientDetailsForm?.get('txtBirthdate')?.touched &&
                patientDetailsForm?.get('txtBirthdate')?.errors &&
                !(
                  BirthdateMinDate <=
                  patientDetailsForm?.get('txtBirthdate')?.value
                )
              "
            >
              Enter Valid date
            </mat-error>
            <mat-error
              *ngIf="
                patientDetailsForm?.get('txtBirthdate')?.touched &&
                patientDetailsForm?.get('txtBirthdate')?.errors?.required
              "
            >
              Birth Date is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                BirthdateMinDate <=
                  patientDetailsForm?.get('txtBirthdate')?.value &&
                !(
                  patientDetailsForm?.get('txtBirthdate')?.touched &&
                  patientDetailsForm?.get('txtBirthdate')?.errors?.required
                )
              "
            >
              Enter Past Dates Only!
            </mat-error>
          </mat-form-field>

          <!-- Gender -->
          <mat-form-field class="col-3">
            <mat-label>Gender</mat-label>
            <mat-select
              formControlName="drpGender"
              [disabled]="true"
              autocomplete="off"
              maxlength="40"
              readonly
            >
              <mat-option
                *ngFor="let gender of drpGenderLoop"
                [value]="gender.genderShortCode"
              >
                {{ gender.genderName }}
              </mat-option>
            </mat-select>

            <mat-error
              class="spanAlignment"
              *ngIf="
                patientDetailsForm?.get('drpGender')?.touched &&
                patientDetailsForm?.get('drpGender')?.errors?.required
              "
            >
              Gender is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Fax -->
          <mat-form-field class="col-3">
            <mat-label> Fax</mat-label>
            <input
              formControlName="txtFax"
              matInput
              maxlength="40"
              prefix="{{ phoneCode }}-"
              mask="(000)-000-0000"
              type="text"
              autocomplete="new-txtFax"
              readonly
            />
            <mat-error
              *ngIf="
                patientDetailsForm?.get('txtFax')?.touched &&
                patientDetailsForm?.get('txtFax')?.errors
              "
            >
              Enter Minimum 10 Digits!
            </mat-error>
          </mat-form-field>

          <!-- Address -->
          <mat-form-field class="col-6">
            <mat-label>Address</mat-label>
            <textarea
              matInput
              rows="3"
              readonly
              autocomplete="new-txtAddress"
              maxlength="200"
              formControlName="txtAddress"
              type="text"
            ></textarea>
          </mat-form-field>

          <!-- Country-->
          <mat-form-field class="col-3">
            <mat-label>Country</mat-label>
            <mat-select
              formControlName="txtCountry"
              [(value)]="country"
              #singleSelect
              [disabled]="true"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtCountryFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let country of filteredCountries | async"
                [value]="country.countryShortName"
              >
                {{ country.countryName }}
              </mat-option>
            </mat-select>
            <!-- <button
              mat-button
              (click)="State = ''; $event.stopPropagation()"
              *ngIf="State"
              (click)="clearState()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button> -->
          </mat-form-field>
          <!-- State-->
          <mat-form-field class="col-3">
            <mat-label>State</mat-label>
            <mat-select
              [disabled]="true"
              formControlName="txtState"
              (selectionChange)="onChangeCity($event.value, country)"
              [(value)]="State"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtStateFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let state of filteredStates | async"
                [value]="state.stateShortName"
              >
                {{ state.stateName }}
              </mat-option>
            </mat-select>
            <!-- <button
              mat-button
              (click)="State = ''; $event.stopPropagation()"
              *ngIf="State"
              (click)="clearState()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button> -->
          </mat-form-field>
          <!-- City-->
          <mat-form-field class="col-3">
            <mat-label>City</mat-label>
            <mat-select
              formControlName="txtCity"
              [(value)]="City"
              [disabled]="true"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtCityFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let city of filteredCities | async"
                [value]="city.cityName"
              >
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <!-- <button
              mat-button
              (click)="City = ''; $event.stopPropagation()"
              *ngIf="City"
              matSuffix
              (click)="clearCity()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button> -->
          </mat-form-field>

          <!-- Zip Code -->
          <mat-form-field class="col-3">
            <mat-label> Zip Code </mat-label>
            <input
              matInput
              minlength="5"
              readonly
              maxlength="10"
              formControlName="txtPostalCode"
              type="text"
            />
            <mat-error
              *ngIf="
                patientDetailsForm?.get('txtPostalCode')?.touched &&
                patientDetailsForm?.get('txtPostalCode')?.errors?.pattern
              "
            >
              Enter only Numbers!
            </mat-error>
          </mat-form-field>

          <!-- Home Cell -->
          <mat-form-field class="col-3">
            <mat-label> Home Cell </mat-label>
            <input
              matInput
              maxlength="16"
              readonly
              formControlName="txtHomeNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
          </mat-form-field>

          <!-- Work Cell -->
          <mat-form-field class="col-3">
            <mat-label> Work Cell </mat-label>
            <input
              matInput
              readonly
              maxlength="16"
              formControlName="txtWorkNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
          </mat-form-field>

          <!-- Mobile No -->
          <mat-form-field class="col-3">
            <mat-label> Mobile No </mat-label>
            <input
              matInput
              readonly
              maxlength="16"
              formControlName="txtMobileNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
          </mat-form-field>
          <!-- Email-->
          <mat-form-field class="col-3">
            <mat-label> Email</mat-label>
            <input
              matInput
              readonly
              pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }"
              maxlength="40"
              type="text"
              formControlName="txtEmail"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                patientDetailsForm?.get('txtEmail')?.touched &&
                patientDetailsForm?.get('txtEmail')?.errors?.pattern
              "
            >
              Enter a valid email!
            </mat-error>
          </mat-form-field>
          <!-- SSN -->
          <mat-form-field class="col-3">
            <mat-label>SSN</mat-label>
            <input
              matInput
              readonly
              formControlName="txtSSN"
              type="text"
              mask="000-00-0000"
              [validation]="false"
              placeHolderCharacter=""
              autocomplete="new-txtSSN"
            />
            <mat-error
              *ngIf="
                patientDetailsForm?.get('txtSSN')?.touched &&
                patientDetailsForm?.get('txtSSN')?.errors
              "
            >
              Enter Minimum 4 Digits!
            </mat-error>
          </mat-form-field>

          <!-- Modified By -->
          <mat-form-field class="col-3">
            <mat-label>Modified By</mat-label>
            <mat-select formControlName="drpModifiedBy" [disabled]="true">
              <mat-option>New ChartId</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Modified On-->
          <mat-form-field class="col-3">
            <mat-label> Modified Date </mat-label>
            <input
              readonly
              formControlName="txtModifiedDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtModifiedDate"
            />
            <mat-datepicker-toggle
              [disabled]="true"
              matSuffix
              [for]="txtModifiedDate"
            >
            </mat-datepicker-toggle>
            <mat-datepicker [disabled]="true" #txtModifiedDate>
            </mat-datepicker>
          </mat-form-field>

          <!-- Active -->
          <mat-checkbox
            formControlName="chkActive"
            [disabled]="true"
            class="col-2"
          >
            <p class="mb-0 text-wrap">Active</p>
          </mat-checkbox>

          <!-- Never Call -->
          <mat-checkbox
            formControlName="chkNeverCall"
            [disabled]="true"
            class="col-2"
          >
            <p class="mb-0 text-wrap">Never Call</p>
          </mat-checkbox>

          <!-- Notes -->
          <mat-form-field class="col-5">
            <mat-label>Notes</mat-label>
            <textarea
              matInput
              readonly
              rows="3"
              autocomplete="new-txtNotes"
              maxlength="5000"
              formControlName="txtNotes"
              type="text"
            ></textarea>
          </mat-form-field>
        </form>
      </div>
      <!-- Insurance Table -->
      <div class="card mt-3">
        <div class="card-body">
          <p class="header-text">Insurance List</p>

          <div *ngIf="isInsuranceTableLoaded" class="table table-responsive">
            <table
              class="row-border hover w-100 display"
              datatable
              [dtOptions]="dtInsuranceTableOptions"
            >
              <thead>
                <tr>
                  <th [matTooltip]="'Insurance'">Insurance</th>
                  <th [matTooltip]="'Plan Order'">Plan Order</th>
                  <th [matTooltip]="'Relation'">Relation</th>
                  <th [matTooltip]="'Policy No'">Policy No</th>
                  <th [matTooltip]="'Group No'">Group No</th>
                  <th [matTooltip]="'Coverage Begins'">Coverage Begins</th>
                  <th [matTooltip]="'Coverage Ends'">Coverage Ends</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of insuranceList">
                  <td [matTooltip]="data?.payorName || '-'">{{ data.payorName }}</td>
                  <td [matTooltip]="data?.planOrderName || '-'"> {{ data.planOrderName }}</td>
                  <td [matTooltip]="data?.relationshipName || '-'">{{ data.relationshipName }}</td>
                  <td [matTooltip]="data?.policyNo || '-'">{{ data.policyNo }}</td>
                  <td [matTooltip]="data?.groupNo || '-'">{{ data.groupNo }}</td>
                  <td [matTooltip]="data?.coverageBegins || '-'">{{ data.coverageBegins }}</td>
                  <td [matTooltip]="data?.coverageEnds || '-'">{{ data.coverageEnds }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
