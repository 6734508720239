<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <!-- <h3 class="font-weight-bolder mb-0">Rx Report</h3> -->
        <h3 class="mb-3 mt-2 font-size-18 customThemeClass"><b>MIS Report / Rx Report</b></h3>
        <div class="page-title-box page-title-right mt-3">
          <form [formGroup]="reportForm">
            <div class="row d-flex justify-content-center align-items-center">
              <ng-container *ngIf="filterValue">
                <mat-form-field class="pr-3 dd-text">
                  <mat-label>Practitioner Name</mat-label>
                  <mat-select formControlName="drpPracticeManagement" multiple
                    (selectionChange)="PractitionerChange($event)">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                        formControlName="txtPracticeFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="option.id" *ngFor="let option of filteredPractices | async">
                      {{ option.practiceName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="pr-3 dd-text" *ngIf="!filterValue">
                  <mat-label>Rx Status</mat-label>
                  <mat-select     placeholder="Rx Status"
              >

              <mat-option>
                  Lee Glender
              </mat-option>
  
              <mat-option>Mark Martin</mat-option>
              <mat-option>Matter Kerry</mat-option>
              <mat-option>Hinder Hagrid</mat-option>
              <mat-option>Cramer James S</mat-option>
            </mat-select>
  
              </mat-form-field>

                <mat-form-field class="pr-3 dd-text">
                  <mat-label>Patient Search</mat-label>
                  <mat-select (selectionChange)="onChartIdChange($event)" formControlName="drpPatient" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" (keyup)="searchPatient($event.target.value)"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtPatientFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let chart of lstPatientChartNos" [value]="chart.patientId">
                      {{ chart.ddValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="!filterValue">
                <mat-form-field class="pr-3 dd-text"
                  [ngClass]="{'mat-form-field-invalid': reportForm.get('dtRxExpiryFrom').invalid || reportForm.get('dtRxExpiryTo').invalid}">
                  <mat-label>Expiry Date Range<span class="asterisk">*</span></mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate matInput [(value)]="dExpiryFromDate" placeholder="Start date"
                      formControlName="dtRxExpiryFrom" (keydown)="addSlashToDate($event)" maxlength="10" />
                    <input matEndDate matInput (dateChange)="onDateChange($event)" [(value)]="dExpiryToDate"
                      placeholder="End date" formControlName="dtRxExpiryTo" (keydown)="addSlashToDate($event)"
                      maxlength="10" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-error *ngIf="reportForm.get('dtRxExpiryFrom').hasError('required')">Start date is
                    required</mat-error>
                  <mat-error
                    *ngIf="reportForm.get('dtRxExpiryFrom').invalid && !reportForm.get('dtRxExpiryFrom').hasError('required')">Invalid
                    start date</mat-error>

                  <mat-error *ngIf="reportForm.get('dtRxExpiryTo').hasError('required')">End date is
                    required</mat-error>
                  <mat-error
                    *ngIf="reportForm.get('dtRxExpiryTo').invalid && !reportForm.get('dtRxExpiryTo').hasError('required')">Invalid
                    end date</mat-error>

                  <mat-error *ngIf="reportForm.hasError('dateRangeError')">From date must be less than or equal to To
                    date</mat-error>
                </mat-form-field>
              </ng-container>



              <app-rx-report-filter-switch class="h-100 mr-4" [onText]="'Name'" [offText]="'Others'"
                (changed)="filterChange($event)">
              </app-rx-report-filter-switch>
              <div class="mr-2" *ngIf="isShowMultipleRX">
                <a  routerLink="'/RxEmailPrintLetter'"
                target="_blank"  >
                <!-- <button mat-button class="buttonColor" (click)="openUploadPopUp()">Send Email</button> -->
                <button mat-button class="buttonColor" (click)="navigateToEmailPage()" >Send Email</button>

                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body p-2">
        <div class="d-flex justify-content-center" *ngIf="!isReportTableLoaded">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
        <div *ngIf="isReportTableLoaded" class="table table-responsive">
          <!-- (isShowMultipleRXLoad)="fnIsShowMultipleRX()" -->
          <app-rx-report-table-list [reportTableData]="reportList" [fileterValue]="filterValue"
            (updateCount)="updateCount($event)" (reloadRxReport)="reloadRxReport($event)" ></app-rx-report-table-list>
        </div>
      </div>
    </div>
  </div>
</div>