import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BillingDropdowns } from '../billing-dropdowns.enum';
import type { BillingMasterDropdownDTO } from '../dto/billing/models';

@Injectable({
  providedIn: 'root',
})
export class BillingMasterDropdownService {
  apiName = 'DropdownManagement';

  getBillingDropdownsByInput = (input: BillingDropdowns[]) =>
    this.restService.request<any, BillingMasterDropdownDTO>({
      method: 'POST',
      url: '/api/DropdownApp/billing-master-dropdown/get-billing-dropdowns',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
