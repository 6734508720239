import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import Swal from 'sweetalert2';

import { OrderMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { AuthorizationDTO, CreateUpdateAuthorizationsDTO, PatientDTO } from '../patient-proxy/patient/dto/models';
import { addAuthorization, getPatient, updatePatient } from '../patient-store/patient.action';
import { ItemDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto';
import { AuthCategoryDTO, AuthVisitsDTO, OrderMasterDropdownDTO, TypeOfPlanDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientMasterDropdownDTO, SaleOrRentDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-add-auth',
  templateUrl: './add-auth.component.html',
  styleUrls: ['./add-auth.component.scss']
})
export class AddAuthComponent implements OnInit, OnDestroy {
  authorizationForm: FormGroup;
  authVisit: string;
  category: string;
  sale: string;
  plan: string;
  itemCodes: string;
  notesText ='';
  drpAuthVisit: AuthVisitsDTO[] = [];
  drpCategory: AuthCategoryDTO[] = [];
  drpSaleOrRents: SaleOrRentDTO[] = [];
  //drpMarketingRef: MarketingReferralDTO[] = [];
  drpAuthItem: ItemDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpItemCdoes: string[] = [];
  authorizationId: string = "00000000-0000-0000-0000-000000000000";
  @Input() patientId: string = '';
  authId: string = "00000000-0000-0000-0000-000000000000";
  authModalTitle: string = '';
  BirthdateMinDate: Date;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  @Output() saveAuthorizationFormState: EventEmitter<any> = new EventEmitter<any>();
  saveButtonHide
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  constructor(private fb: FormBuilder,
    private text: TextValidators,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private store: Store,
    private table: TableService,
    private toastr: ToastrService,
    private saleOrderService: SaleorderService,
    public authDialogRef: MatDialogRef<AddAuthComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string, authDatas?: AuthorizationDTO }) { }



  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    const tableSubscription = this.table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    this.subscription$.push(tableSubscription);
    //
    this.createAuthorizationForm();
    if (this.data.authDatas != null && this.data.authDatas != undefined && this.data.authDatas.id !== "" && this.data.authDatas.id !== '00000000-0000-0000-0000-000000000000') {
      this.getAuthDatas(this.data.authDatas);
      this.patientId = this.data.patientId;
      this.authId = this.data.authDatas.id;
      this.authModalTitle = 'Edit Authorization';
    }
    else {
      this.patientId = this.data.patientId;
      this.authId = '00000000-0000-0000-0000-000000000000';
      this.authModalTitle = 'Add Authorization';
    }
    if ((this.patientId !== "" && this.patientId !== null && this.patientId !== undefined)) {
      const onInitSubscription = this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        const selectedPatient: PatientDTO = response?.patientState?.selectedPatient;
        //Patient is locked & userId is not equal to loginUserId or patient is unlocked
        this.isPatientLockedByCurrentUser = (selectedPatient?.patientLock?.isLocked == true &&
          selectedPatient?.patientLock?.userId == this.strLoginUserId) ||
          (selectedPatient?.patientLock?.isLocked == false) ? true : false;
      });
      this.subscription$.push(onInitSubscription);
    }
    this.getDropdowns();
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 2);

    if (this.authorizationForm.valid || this.authorizationForm.dirty) {
      this.saveAuthorizationFormState.emit(this.authorizationForm);
    }
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  //! Authorization form with validations
  createAuthorizationForm() {
    this.authorizationForm = this.fb.group({
      txtAuthId: new FormControl("", [Validators.required]),
      drpAuthVisit: new FormControl(null),
      txtAuthAmount: new FormControl(""),
      txtStartDate: new FormControl(""),
      txtEndDate: new FormControl(""),
      drpCategory: new FormControl(""),
      drpItem: new FormControl(null),
      txtNote: new FormControl(""),
      chkCompleted: new FormControl(""),
      txtCompletedBy: new FormControl("", [this.text.isTextCheck]),
      drpPlan: new FormControl(null, [Validators.required]),
      txtUnits: new FormControl(""),
      txtUsed: new FormControl(""),
      txtSaleOrRent: new FormControl(""),
      txtExpired: new FormControl(""),
      txtItemCodes: new FormControl(""),
      chkFollowUp: new FormControl("")
    });
  }

  //! Load Order Dropdowns
  getDropdowns() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.AuthVisits,
      OrderDropdowns.AuthCategories,
      //OrderDropdowns.MarketingReferrals,
      OrderDropdowns.TypeOfPlans
    ]
    const orderDropdownSubscription = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse
      this.drpAuthVisit = response.authVisits;
      this.drpCategory = response.authCategories;
      //this.drpMarketingRef = response.marketingReferrals;
      this.drpPlan = response.typeOfPlans;
    })
    this.subscription$.push(orderDropdownSubscription);
    //
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.SaleOrRent]
    const patientDropdownsSubscription = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((response: PatientMasterDropdownDTO) => {
      this.drpSaleOrRents = response.saleOrRents;
    });
    this.subscription$.push(patientDropdownsSubscription);
    //
    const saleOrderSubscription = this.saleOrderService.getAllItemCodesByPatientId(this.patientId).subscribe(response => {
      this.drpItemCdoes = response;
    });
    this.subscription$.push(saleOrderSubscription)
  }

  //! Get Patient Details for Auth Save
  getPatientDetailsForSave() {
    let personalDetails: PatientDTO;
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      personalDetails = response.patientState.selectedPatient;
      if (personalDetails.authorizations) {
        let savedData = {
          primaryAuthorizations: personalDetails.authorizations.primaryAuthorizations,
          secondaryAuthorizations: personalDetails.authorizations.secondaryAuthorizations,
          tertiaryAuthorizations: personalDetails.authorizations.tertiaryAuthorizations
        }
        return this.saveAuthorization(personalDetails, savedData);
      }
    })
  }

  //! Auth Save
  saveAuthorization(response, savedData) {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    let typeOfPlanName: string[] = this.drpPlan.map(v => v.typeOfPlanName);
    if (this.authorizationForm.value.drpPlan === typeOfPlanName[0]) {
      savedData.primaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.drpPlan,
        // planName: this.authorizationForm.value.txtPlanName,
        planName: "",
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired),
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp)
      }
    } else if (this.authorizationForm.value.drpPlan === typeOfPlanName[1]) {
      savedData.secondaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.drpPlan,
        // planName: this.authorizationForm.value.txtPlanName,
        planName: "",
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired),
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp)
      }
    } else if (this.authorizationForm.value.drpPlan === typeOfPlanName[2]) {
      savedData.tertiaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.drpPlan,
        // planName: this.authorizationForm.value.txtPlanName,
        planName: "",
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired),
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp)
      }
    }
    let insAuthorization: CreateUpdateAuthorizationsDTO;
    insAuthorization = {
      // personals: response.personals,
      // addresses: response.addresses,
      // contacts: response.contacts,
      // clinicals: response.clinicals,
      // insurances: response.insurances,
      // notes: response.notes,
      // policies: response.policies,
      // defaultPatientId: response.defaultPatientId,
      // insuranceVerifications: response.insuranceVerifications,
      // authorizations: {
      primaryAuthorizations: savedData.primaryAuthorizations,
      secondaryAuthorizations: savedData.secondaryAuthorizations,
      tertiaryAuthorizations: savedData.tertiaryAuthorizations,
      // patientDocuments: response.patientDocuments,
      // organizationUnitId: response.organizationUnitId,
      // isAutoIntake: response.isAutoIntake,
      // autoIntakeDocumentDetails: response.autoIntakeDocumentDetails,
      // chartId: response.chartId,
      // billingDetails: response.billingDetails,
      // patientLockDetails: response.patientLockDetails,
      // isSorted:response.isSorted

    }
    this.patientId !== "" && this.store.dispatch(new addAuthorization(this.patientId, insAuthorization)).subscribe(response => {
      let patientSaved: CreateUpdateAuthorizationsDTO = response?.patientState?.selectedPatient;
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      this.saveAuthorizationFormState.emit(this.authorizationForm.value);
      this.patientId = response?.patientState?.selectedPatient?.id
      this.table.setheaderTable(this.patientId);
      this.authDialogRef.close(patientSaved);
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  //! Get Auth Datas by Id
  getAuthDatas(response) {
    let patientDetails: PatientDTO;
    const getbyIdSubscription = this.store.dispatch(new getPatient(response?.patientId)).subscribe(patientResponse => {
      patientDetails = patientResponse?.patientState?.selectedPatient;
      const primaryAuth = patientDetails?.authorizations?.primaryAuthorizations
      const secondaryAuth = patientDetails?.authorizations?.secondaryAuthorizations
      const tertiaryAuth = patientDetails?.authorizations?.tertiaryAuthorizations
      if (response?.plan == "Primary") {
        this.authorizationForm.patchValue({
          txtAuthId: primaryAuth?.authId,
          drpAuthVisit: primaryAuth?.authVisits,
          txtAuthNo: primaryAuth?.authNo,
          txtAuthAmount: primaryAuth?.authAmount,
          txtStartDate: primaryAuth?.startDate,
          txtEndDate: primaryAuth?.endDate,
          drpCategory: primaryAuth?.category,
          drpItem: primaryAuth?.item,
          txtNote: primaryAuth?.note,
          chkCompleted: primaryAuth?.isCompleted == "Done" ? 1 : 0,
          txtCompletedBy: primaryAuth?.completedBy,
          drpPlan: primaryAuth?.plan,
          txtUnits: primaryAuth?.units,
          txtUsed: primaryAuth?.used,
          txtSaleOrRent: primaryAuth?.saleOrRent,
          txtExpired: primaryAuth?.isExpired,
          txtItemCodes: primaryAuth?.itemCodes,
          chkFollowUp: primaryAuth?.isFollowUp
        });
      }
      else if (response?.plan == "Secondary") {
        this.authorizationForm.patchValue({
          txtAuthId: secondaryAuth?.authId,
          drpAuthVisit: secondaryAuth?.authVisits,
          txtAuthNo: secondaryAuth?.authNo,
          txtAuthAmount: secondaryAuth?.authAmount,
          txtStartDate: secondaryAuth?.startDate,
          txtEndDate: secondaryAuth?.endDate,
          drpCategory: secondaryAuth?.category,
          drpItem: secondaryAuth?.item,
          txtNote: secondaryAuth?.note,
          chkCompleted: secondaryAuth?.isCompleted == "Done" ? 1 : 0,
          txtCompletedBy: secondaryAuth?.completedBy,
          drpPlan: secondaryAuth?.plan,
          txtUnits: secondaryAuth?.units,
          txtUsed: secondaryAuth?.used,
          txtSaleOrRent: secondaryAuth?.saleOrRent,
          txtExpired: secondaryAuth?.isExpired,
          txtItemCodes: secondaryAuth?.itemCodes,
          chkFollowUp: secondaryAuth?.isFollowUp
        });
      }
      else if (response?.plan == "Tertiary") {
        this.authorizationForm.patchValue({
          txtAuthId: tertiaryAuth?.authId,
          drpAuthVisit: tertiaryAuth?.authVisits,
          txtAuthNo: tertiaryAuth?.authNo,
          txtAuthAmount: tertiaryAuth?.authAmount,
          txtStartDate: tertiaryAuth?.startDate,
          txtEndDate: tertiaryAuth?.endDate,
          drpCategory: tertiaryAuth?.category,
          drpItem: tertiaryAuth?.item,
          txtNote: tertiaryAuth?.note,
          chkCompleted: tertiaryAuth?.isCompleted == "Done" ? 1 : 0,
          txtCompletedBy: tertiaryAuth?.completedBy,
          drpPlan: tertiaryAuth?.plan,
          txtUnits: tertiaryAuth?.units,
          txtUsed: tertiaryAuth?.used,
          txtSaleOrRent: tertiaryAuth?.saleOrRent,
          txtExpired: tertiaryAuth?.isExpired,
          txtItemCodes: tertiaryAuth?.itemCodes,
          chkFollowUp: tertiaryAuth?.isFollowUp
        });
      }
    })
    this.subscription$.push(getbyIdSubscription);
  }

  //! Clear dropdowns
  clearAuthVisit() {
    this.authVisit = '';
    this.authorizationForm.patchValue({ drpAuthVisit: "" });
  }
  clearCategory() {
    this.category = '';
    this.authorizationForm.patchValue({ drpCategory: "" });
  }
  clearSale() {
    this.sale = '';
    this.authorizationForm.patchValue({ txtSaleOrRent: "" });
  }
  clearItemCodes() {
    this.itemCodes = '';
    this.authorizationForm.patchValue({ txtItemCodes: "" });
  }
  clearPlan() {
    this.plan = '';
    this.authorizationForm.patchValue({ drpPlan: "" });
  }

  //! Reset Authorization Form
  clearData() {
    this.authorizationForm.reset();
    this.authorizationForm.markAsUntouched();
    this.authorizationForm.updateValueAndValidity();
  }

}
