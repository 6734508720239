import { Component, OnInit} from '@angular/core';
import { OrderShippingStatusService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/order-shipping-status.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-ordershippping',
  templateUrl: './ordershippping.component.html',
  styleUrls: ['./ordershippping.component.scss']
})
export class OrdershipppingComponent implements OnInit {
  isLoading : boolean = false;
  descriptionData: any;
  descriptionSort : any;

  constructor(
    private orderService : OrderShippingStatusService,
    public title: Title,
    private commonService : CommonService
  )
  { 
  }

  ngOnInit(): void {
   
    this.loadDescription();
  }

  loadDescription(){
    this.isLoading = true;
     this.orderService.getList(new PagedAndSortedResultRequestDto()).subscribe(
        (response) => {
          this.isLoading = false;      
          this.descriptionSort = response.items;            
          this.descriptionSort.sort((a, b) => (b?.lastModificationTime != null ? b?.lastModificationTime : b.creationTime).localeCompare(a?.lastModificationTime != null ? a?.lastModificationTime : a.creationTime));
          this.descriptionData = this.descriptionSort;
          this.descriptionData = this.descriptionData.map((data) => {
            return {
              ...data,
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                data.lastModificationTime
              ),
            };
          });

        },
        (err) => {
          this.isLoading = false;
           const data: HttpErrorResponse = err;
        }
      );
  }

}
