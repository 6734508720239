<div class="container-fluid">
    <div class="row bg">
        <div class="col-12 mt-3">
            <div class="d-flex align-item-center justify-content-between m-0">
                <h4 class="mt-4 mb-0 font-size-18"><b>Master City</b></h4>
                <div class=" page-title-box  page-title-right mt-1 mb-0">
                    <ol class="breadcrumbs m-0">
                        <li class="breadcrumb-item">Master Pages</li>
                        <li class="breadcrumb-item">Master City</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="col-12">
            <mat-accordion>
                <mat-expansion-panel [expanded]="step">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="customThemeClass">
                            <ng-container *ngIf="cityId === ''; else elseTitleTemplate">
                                <b>Add City</b>
                            </ng-container>
                            <ng-template #elseTitleTemplate>
                                <b>Edit City</b>
                            </ng-template>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form [formGroup]="cityForm">
                        <div class="col-lg-12">
                            <mat-card-content>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Country</mat-label>
                                        <mat-select formControlName="txtCountry"
                                            (selectionChange)='onChangeState($event.value)' [(value)]="Country"
                                            #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matches found"
                                                    formControlName="txtCountryFilter"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of filteredCountries | async"
                                                [value]="country.countryShortName">
                                                {{ country.countryName }}
                                            </mat-option>
                                        </mat-select>
                                        <button mat-button (click)="Country=undefined;$event.stopPropagation()"
                                            *ngIf="Country" (click)="clearCountry()" matSuffix mat-icon-button
                                            >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Country Phone Code<span class="asterisk">*</span></mat-label>
                                        <input autocomplete="new-txtCountryPhoneNumber" matInput formControlName="txtCountryPhoneNumber" [readonly]="true"
                                            placeholder="Enter Phone Number" type="text" />
                                        <mat-error *ngIf="
                              cityForm?.get('txtCountryPhoneNumber')?.touched &&
                              cityForm?.get('txtCountryPhoneNumber')?.errors?.required              ">
                                            Country Phone Code is a required field!
                                        </mat-error>
                                        <mat-error
                                            *ngIf=" cityForm?.get('txtCountryPhoneNumber')?.touched && cityForm?.get('txtCountryPhoneNumber')?.errors?.sAlphabets">
                                            Enter only Numbers!
                                        </mat-error>

                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>State</mat-label>
                                        <mat-select formControlName="txtStateName"
                                            (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
                                            #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matches found"
                                                    formControlName="txtStateFilter"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of filteredStates | async"
                                                [value]="state.stateShortName">
                                                {{ state.stateName }}
                                            </mat-option>
                                        </mat-select>
                                        <button mat-button (click)="State=undefined;$event.stopPropagation()"
                                            *ngIf="State" (click)="clearState()" matSuffix mat-icon-button
                                            >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>City</mat-label>
                                        <mat-select formControlName="txtCityName" [(value)]="City" #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matches found"
                                                    formControlName="txtCityFilter"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of filteredCities | async"
                                                [value]="city.cityName">
                                                {{ city.cityName }}
                                            </mat-option>
                                        </mat-select>
                                        <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City"
                                            matSuffix (click)="clearCity()" mat-icon-button >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>City Name <span class="asterisk">*</span></mat-label>
                                        <input autocomplete="new-txtNewCityName" matInput formControlName="txtNewCityName" placeholder="Enter City Name"
                                            type="text" />
                                        <mat-error
                                            *ngIf=" cityForm?.get('txtNewCityName')?.touched && cityForm?.get('txtNewCityName')?.errors?.required ">
                                            City Name is a required field!
                                        </mat-error>
                                        <mat-error
                                            *ngIf="cityForm?.get('txtNewCityName')?.touched && cityForm?.get('txtNewCityName')?.errors">
                                            Enter only Alphanumeric!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </div>
                    </form>
                    <br>
                    <div class="col-sm-12">
                        <div class="text-lg-center">
                            <button mat-button class="buttonColor mb-2 mr-2"
                                [disabled]="saveButtonHide||!(!cityForm.invalid && cityForm.dirty)"
                                (click)="saveCity()">
                                Save
                                <mat-icon *ngIf='saveButtonHide'>
                                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                                </mat-icon>
                            </button>
                            <button mat-button (click)="$event.stopPropagation()" class="resetclr buttonColor mb-2 mr-2"
                                (click)="resetCity()">
                                Reset
                            </button>

                        </div>
                    </div>
                    <br />
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="col-12">
            <div class="table-responsive">
                <table mat-table class="table" matSort [dataSource]="dataSource">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="countryName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Country Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.countryName }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="countryShortName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Country Short Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.countryShortName }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cityName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            City Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.cityName }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="stateName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>

                            State Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.stateName }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="stateShortName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>

                            State Short Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.stateShortName }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="countryPhoneCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Country Phone Code
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.cityPhoneCode }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="editBtnColor" (click)="viewCity(data?.id)">
                                <i class="fa fa-pencil editBtnColor mr-1" matTooltip="Click To Edit"></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deleteCity(data?.id)">
                                <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isShowSpinner">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isShowSpinner">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="9">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                    <tr mat-footer-row *matFooterRowDef="['noRecords']"
                        [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
