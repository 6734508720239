import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';

import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimInfoComponent } from '../claim-info/claim-info.component';

@Component({
  selector: 'app-billing-patient-visit',
  templateUrl: './billing-patient-visit.component.html',
  styleUrls: ['./billing-patient-visit.component.scss'],
  providers: [ListService]
})

export class BillingPatientVisitComponent implements OnInit {
  public strPageType: string = "paymentHistory";
  organizationunitId: string = "";
  claimTableData: any[] = [];
  isShowInt: boolean = false;
  isShowPaid: boolean = false;
  @Input() patientId: string = '';
  isLoading: boolean = false;

  constructor(
    public tableService: TableService,
    public dialog: MatDialog,
    private claimInformationService: ClaimInformationsService,
    public list: ListService,
  ) { }

  ngOnInit(): void {
    this.tableService.setBillingHistoryDataTable([]);

    this.tableService.getOrganizationUnitId().subscribe(val => {
      this.organizationunitId = val;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });


    this.loadPatientVisitsTable();

  }


  // ! Load Patient Visits Table function
  loadPatientVisitsTable() {
    this.isLoading = true;
    const patientList = (query: any) => this.claimInformationService.getList(query);
    this.list.hookToQuery(patientList).subscribe(response => {

      this.claimTableData = [];
      response?.items?.forEach((element, index) => {
        const data = element?.id
        this.tableService.setBillClaimListDataTable(element)
        if(this.patientId=== element.patientId){

          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            saleorderId: element?.saleOrder?.saleorderId,
            patientName: element?.patientName,
            // status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.saleOrder?.items[0]?.originalDos == null ? "" : new Date(element?.saleOrder?.items[0]?.originalDos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            totalBilledAmount: element?.saleOrder?.shippingCheckList?.totalItemPrice,
            totalPatientBal:element?.totalPatientBal,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            id: element?.id,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            // planCode: null,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount
          });
        }
        // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
      });
      this.isLoading = false;
      this.tableService.setBillingHistoryDataTable(this.claimTableData);
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  // Show only Ready status records
  showInt(event: MatCheckboxChange) {
    this.isShowInt = event.checked;
    if (event.checked == true && this.isShowPaid == false) {
      const status = "Ready";
      const isPostingCompleted = 0;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {

          this.tableService.setBillClaimListDataTable(element)
          if (this.patientId === element.patientId) {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientName: element?.patientName,
              patientId: element?.defaultPatientId,
              // status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),           // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element.patientResponsibilityCount,
              // planCode: null,
              processedCount: element.processedCount,
              readyCount: element.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount,
              saleorderId: element?.saleorderId
            });
          }
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else if (event.checked == true && this.isShowPaid == true) {
      const status = "Ready";
      const isPostingCompleted = 1;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          this.tableService.setBillClaimListDataTable(element)
          if (this.patientId === element.patientId) {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientName: element.patientName,
              patientId: element?.defaultPatientId,
              // status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),           // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element.patientResponsibilityCount,
              // planCode: null,
              processedCount: element.processedCount,
              readyCount: element.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount,
              saleorderId: element?.saleorderId
            });
          }
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });

    }
    else {
      if (this.isShowPaid == true) {
        const status = null;
        const isPostingCompleted = 1;
        this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
          this.claimTableData = [];
          response?.items?.forEach((element, index) => {
            this.tableService.setBillClaimListDataTable(element)
            if (this.patientId === element.patientId) {
              // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
              this.claimTableData.push({
                patientName: element?.patientName,
                saleOrderId: element?.saleorderId,
                patientId: element?.defaultPatientId,
                // status: element?.status,
                daySheet: "12/12/2020",
                dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),              // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
                provider: element?.lstPostingCharges[0]?.provider,
                data: "1",
                totalFee: element?.totalFee,
                patBal: element?.patBalanceSum,
                insBal: element?.totalInsBal,
                total: element?.totalBilled,
                Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
                lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
                claimid: element?.claimId,
                patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
                patientResponsibilityCount: element.patientResponsibilityCount,
                // planCode: null,
                processedCount: element.processedCount,
                readyCount: element.readyCount,
                holdCount: element?.holdCount,
                crossOverCount: element?.crossOverCount,

              });
            }
          })
          this.tableService.setBillingHistoryDataTable(this.claimTableData);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      else {
        this.loadPatientVisitsTable();
      }
    }
  }

  // Show only PostingCompleted records
  showPaid(event: MatCheckboxChange) {
    this.isShowPaid = event.checked;
    if (this.isShowInt == false && event.checked == true) {
      const status = null;
      const isPostingCompleted = 1;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          this.tableService.setBillClaimListDataTable(element)
          if (this.patientId === element.patientId) {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientName: element?.patientName,
              saleOrderId: element?.saleorderId,
              patientId: element?.defaultPatientId,
              // status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),            // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element.patientResponsibilityCount,
              // planCode: null,
              processedCount: element.processedCount,
              readyCount: element.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount
            });
          }
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else if (this.isShowInt == true && event.checked == true) {
      const status = "Ready";
      const isPostingCompleted = 1;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          this.tableService.setBillClaimListDataTable(element)
          if (this.patientId === element.patientId) {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientName: element?.patientName,
              saleOrderId: element?.saleorderId,
              patientId: element?.defaultPatientId,
              // status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),            // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element.patientResponsibilityCount,
              // planCode: null,
              processedCount: element.processedCount,
              readyCount: element.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount,
            });
          }
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      if (this.isShowInt == true) {
        const status = "Ready";
        const isPostingCompleted = 0;
        this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
          this.claimTableData = [];
          response?.items?.forEach((element, index) => {
            this.tableService.setBillClaimListDataTable(element)
            if (this.patientId === element.patientId) {
              // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
              this.claimTableData.push({
                patientName: element?.patientName,
                saleOrderId: element?.saleorderId,
                patientId: element?.defaultPatientId,
                // status: element?.status,
                daySheet: "12/12/2020",
                dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),             // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
                provider: element?.lstPostingCharges[0]?.provider,
                data: "1",
                totalFee: element?.totalFee,
                patBal: element?.patBalanceSum,
                insBal: element?.totalInsBal,
                total: element?.totalBilled,
                Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
                lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
                claimid: element?.claimId,
                patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
                patientResponsibilityCount: element.patientResponsibilityCount,
                // planCode: null,
                processedCount: element.processedCount,
                readyCount: element.readyCount,
                holdCount: element?.holdCount,
                crossOverCount: element?.crossOverCount
              });
            }
          })
          this.tableService.setBillingHistoryDataTable(this.claimTableData);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      else {
        this.loadPatientVisitsTable();
      }

    }
  }


  claimId: string = "";
  ViewClaim(event) {

    this.claimId = event;
    const dialogRef = this.dialog.open(ClaimInfoComponent, {
      data: { claimId: this.claimId }
    });

    dialogRef.afterClosed().subscribe(() => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

}
export interface billingList {
  id: string,
  status: string,
  daysheet: string,
  dos: string,
  provider: string,
  data: string,
  patBal: string,
  insBal: string,
  total: string,
  Fille: string,
  lastFilled: string,
  claimid: string,
}
