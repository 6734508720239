<mat-dialog-content class="mat-typography">
  <div class="modal-header">
    <h1 class="modal-title">Upload EOB

      <span class="customThemeClass" *ngIf="selectedFileCount > 0 ">
        ( Selected Files: {{ selectedFileCount }} - Total Size : {{ totalSizeforTable }} )
      </span>
      <span class="asterisk" *ngIf="showErrorForFileType">
        <!-- {{ "Select only one PDF's and 835 file" }} -->
        {{ "Select only one PDF or TXT file along with the 835 file" }}
      </span>
      <span class="asterisk" *ngIf="showErrorBasedOnMaxSize">
        {{ 'Max Total file`s size of 190MB or Less' }}
      </span>
    </h1>
    <button class="text-right close" aria-label="Close" (click)="closeEOBMdl()">
      <b> X </b>
    </button>
  </div>

  <form [formGroup]="claimUploadEOB">
    <div class="modal-body">


      <!-- *ngIf="!isRefresh" -->
      <div class="pt-2" >
        <div *ngIf="arrSelectedDocuments ">
          <!-- <hr> -->
          <div class="custom-card">
            <div class="row">
              <div class="col-lg-10">
                <div class="fileName nowrap_card">Add File</div>
                <div class="mb-0">
                  <p class="size_pagecount nowrap_card mb-0">Select only one PDF or TXT file along with the 835 file</p>
                </div>
              </div>
              <div class="col-lg-2 center"  >
                <input type="file" (change)="addMoreFile($event)" [multiple]="true" formControlName="addMoreFile" formControlName="documentFile"
                accept="" style="display: none;" #AddfileInput>
                <mat-icon (click)="AddfileInput.click()" matTooltip="Click to Upload" class="addMoreFileButton">add_circle</mat-icon>
              </div>
            </div>
          </div>
          <div style="max-height: 300px; overflow-y: auto;" >
            <ng-container *ngFor="let document of arrSelectedDocuments">
              <div class="custom-card">
                <div class="row">
                  <div class="col-lg-10">
                    <div [matTooltip]="document.fileName" class="fileName nowrap_card">{{ document.fileName }}.pdf</div>
                    <div class="mb-0">
                      <p class="size_pagecount nowrap_card mb-0">{{ document.fileSize }} - {{ document.pageCount }} Pages
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-2 center">
                    <mat-icon class="deleteFileButton" matTooltip="Click to Remove" (click)="deleteDocument(document)">delete</mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="col-12 text-center">
          <!-- [disabled]="inboundUploadForm.invalid || chkdisableButton || !arrSelectedDocuments || arrSelectedDocuments?.length === 0" -->
        <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button
          [disabled]="arrSelectedDocuments?.length === 0"
          (click)="savefuncation()">
          <span>Save</span>
          <!-- <mat-icon *ngIf="chkdisableButton">
            <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon> -->
        </button>
        <button mat-button [disabled]="chkdisableButton" class="resetclr mb-2 mr-2"
          (click)="resetInboundDocumentForm()">Reset</button>
      </div>
    </div>
  </form>
</mat-dialog-content>
