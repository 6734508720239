// import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
// import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
// import {
//   AbstractControl,
//   AsyncValidatorFn,
//   FormBuilder,
//   FormControl,
//   FormGroup,
//   Validators,
// } from '@angular/forms';
// import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
// import { MatTableDataSource } from '@angular/material/table';
// import { ToastrService } from 'ngx-toastr';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
// import {
//   CreateUpdateDocumentBinDTO,
//   DocumentBinDTO,
//   ValidateDocumentBinDTO,
// } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/dto/models';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
// import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { Observable, Subscription } from 'rxjs';
// import Swal from 'sweetalert2';
// import { MatSlideToggleChange } from '@angular/material/slide-toggle';
// import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-master-document-bin',
  templateUrl: './master-document-bin.component.html',
  styleUrls: ['./master-document-bin.component.scss'],
})
export class MasterDocumentBinComponent implements OnInit {
  // step: boolean = false;
  // documentBinId: string = defaultGuid;
  docType: string;
  selectedOption: number = ActiveStatus.Active;

  // selectedOption: number = 1;
  // defaultGuid: string = defaultGuid;
  // mstDocumentBinForm: FormGroup;
  isShowSpinner: boolean;
  documnetDataList: any;
  // dataSource: MatTableDataSource<any>;
  // tableData: DocumentBinDTO[] = [];
  // @ViewChild(MatSort, { static: false }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  saveButtonHide: boolean;
  // displayedColumns: string[] = ['options', 'documentType', 'creationTime'];
  subscription$: Subscription[] = [];
  // dtOptions: any;
  //Accordion Toggle Methods
  // toggle() {
  //   this.step = true;
  // }
  // toggleV2() {
  //   this.step = false;
  // }
  constructor(
    // private formBuilder: FormBuilder,
    private documentBinService: DocumentBinService,
    // private toaster: ToastrService,
    private communicationService: CommunicationService,
    private title : Title,
    private commonService : CommonService
  ) {
    this.communicationService.inventoryMasterDocumentBin$.subscribe(() => {
      this.getDocumentBinList();
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Document Bin');
    // this.dtOptions = {
    //   responsive: true,
    //   paging: false,
    //   scrollCollapse: true,
    //   scrollY: '400px',
    //   info: false,
    //   dom: 'Bfrtip',
    //   buttons: [
    //     {
    //       extend: 'colvis',
    //       columns: [2, 3]
    //     }
    //   ]
    // };
    // this.intializeForms();
    this.getDocumentBinList();
  }

  //Form Initialization
  // intializeForms() {
  //   this.mstDocumentBinForm = this.formBuilder.group({
  //     txtBinName: new FormControl('', [
  //       Validators.required,
  //       noWhitespaceValidator,
  //     ]),
  //   });
  // }
  // intializeForms() {
  //   this.mstDocumentBinForm = this.formBuilder.group({
  //     txtBinName: new FormControl('', [
  //       Validators.required,
  //       noWhitespaceValidator,
  //     ], this.checkDocumentBinUniqness()),
  //   });
  // }

  //Save and Update Method
  // saveBin() {
  //   this.isShowSpinner = true;
  //   let documentBin: CreateUpdateDocumentBinDTO = {
  //     documentType: this.mstDocumentBinForm.value.txtBinName.trim(),
  //     isDefault: false,
  //     TenantId: localStorage.getItem('tenantId').trim() ?? defaultGuid,
  //   };
  //   if (
  //     this.documentBinId === null ||
  //     this.documentBinId === defaultGuid ||
  //     this.documentBinId === ''
  //   ) {
  //     this.documentBinService.create(documentBin).subscribe(
  //       (response) => {
  //         this.isShowSpinner = true;
  //         this.mstDocumentBinForm.reset();
  //         this.toaster.success('Bin Name Saved Successfully');

  //         this.getDocumentBinList();
  //         this.resetBinForm();
  //         // this.step = false;
  //       },
  //       (err) => {
  //         this.isShowSpinner = true;
  //         this.getDocumentBinList();
  //         this.resetBinForm();
  //         // this.step = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   } else {
  //     this.documentBinService.update(this.documentBinId, documentBin).subscribe(
  //       (response) => {
  //         this.mstDocumentBinForm.reset();
  //         this.getDocumentBinList();
  //         this.resetBinForm();
  //         this.step = false;
  //         this.toaster.success('Bin Name Updated Successfully');
  //       },
  //       (err) => {
  //         this.getDocumentBinList();
  //         this.resetBinForm();
  //         this.step = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   }
  // }

  //Get the Document Bin Details by Id
  // viewDocumentBinById(docId: string) {
  //   this.documentBinId = docId ?? '';
  //   const documentDetails = this.documentBinService
  //     .get(docId)
  //     .subscribe((response) => {
  //       this.mstDocumentBinForm.patchValue({
  //         txtBinName: response?.documentType ?? '',
  //       });
  //       this.documentBinId = response?.id ?? '';
  //       this.docType = response?.documentType ?? '';
  //       this.step = true;
  //     });
  //   this.subscription$.push(documentDetails);
  // }

  // //Delete Bin Method
  // deleteBin(docId: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       this.documentBinService.delete(docId).subscribe(
  //         (response) => {
  //           this.getDocumentBinList();
  //           Swal.fire({
  //             title: 'Success',
  //             html: 'Document Bin Deleted Successfully!',
  //             icon: 'success',
  //           });
  //         },
  //         (err) => {
  //           this.getDocumentBinList();
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //     }
  //   });
  // }

  //Get List for Document Bin
  // getDocumentBinList() {
  //   const documentBinList = this.documentBinService
  //     .getList(new PagedAndSortedResultRequestDto())
  //     .subscribe((response) => {
  //       this.tableData = response?.items;
  //       this.isShowSpinner = false;
  //       this.dataSource = new MatTableDataSource(this.tableData);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //     });
  //   this.subscription$.push(documentBinList);
  // }
  getDocumentBinList() {
    this.isShowSpinner = true;
    const documentBinList = this.documentBinService
      .getDocumentBinBasedOnFilterByStatusFilter(this.selectedOption)
      .subscribe((response) => {
        this.documnetDataList = response;
        this.documnetDataList = this.documnetDataList.map((data) => {
          return {
            ...data,
            creationTime: this.commonService.getFormattedDateTimeZone(
              data.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              data.lastModificationTime
            ),
          };
});
        this.isShowSpinner = false;
      });
    this.subscription$.push(documentBinList);
  }
  //Reset Bin Form
  // resetBinForm() {
  //   this.documentBinId = defaultGuid;
  //   // this.documentBinId = null;
  //   this.mstDocumentBinForm.reset();
  //   this.mstDocumentBinForm.patchValue({
  //     txtBinName: ''
  //   });
  // }
  //On Destroy Function
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  // statusChange(event: MatSlideToggleChange, data: any) {
  //   if (event.checked == true) {
  //     Swal.fire({
  //       title: 'Do you want to activate?',
  //       text: 'By Changing Status, this Document Bin will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!'
  //     }).then(result => {
  //       if (result.value) {
  //         if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //           this.documentBinService.enableDisableDocumentBinByDocumentTypeID(data?.id).subscribe(response => {
  //             this.toaster.success('Activated Successfully', 'Success')
  //             this.isShowSpinner = false;
  //             // alert(data?.id);
  //             this.getDocumentBinList();
  //           }, err => {
  //             const data: HttpErrorResponse = err;
  //             Swal.fire({
  //               icon: 'info',
  //               text: data?.error?.error?.message,
  //             });
  //           });
  //         }
  //       }
  //       else {
  //         event.source.checked = false;
  //       }
  //     });
  //   }
  //   else
  //     if (event.checked == false) {
  //       Swal.fire({
  //         title: 'Do you want to deactivate?',
  //         text: 'By Changing Status, this Document Bin will be deactivated',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#34c38f',
  //         cancelButtonColor: '#f46a6a',
  //         confirmButtonText: 'Yes, deactivate it!'
  //       }).then(result => {
  //         if (result.value) {
  //           if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //             this.documentBinService.enableDisableDocumentBinByDocumentTypeID(data?.id).subscribe(response => {
  //               this.toaster.success('Deactivated Successfully', 'Success')
  //               this.isShowSpinner = false;
  //               // alert(data?.id);
  //               this.getDocumentBinList();
  //             }, err => {
  //               const data: HttpErrorResponse = err;
  //               Swal.fire({
  //                 icon: 'info',
  //                 text: data?.error?.error?.message,
  //               });
  //             });
  //           }

  //         }
  //         else {
  //           event.source.checked = true;
  //         }
  //       });
  //     }
  // }

  // checkDocumentBinUniqness(): AsyncValidatorFn {
  //   return (
  //     control: AbstractControl
  //   ): Observable<{ [key: string]: any } | null> => {
  //     const gid = this.documentBinId ? this.documentBinId : null;
  //     const input: ValidateDocumentBinDTO = {
  //       id:gid,
  //       documentType:
  //         String(control?.value ?? '')
  //           ?.toLowerCase()
  //           ?.trim() ?? ''
  //     };
  //     return this.documentBinService.addDocumentBinCheckByValidate(input).pipe(
  //       tap((x) => control?.setErrors({ isDocumentBinExists: true })),
  //       debounceTime(300),
  //       map((response) =>
  //         response=== true
  //           ? { isDocumentBinExists: true }
  //           : control?.setErrors(null) == null
  //             ? null
  //             : null
  //       )
  //     );
  //   };
  // }
}
