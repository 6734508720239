import type { CreateUpdatePatientMedicareDTO, FilterResult, LockOutput, MedicareChecklistDetailsDTO, PatientMedicareChecklistDTO, QuestionResult, checkListRequestDTO, finalSaveOutput, imageList, medicareCheckListResult, mmCMNonFileType } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { MedicareCheckListFilter, MedicareCheckListStatus } from '../patient/enumeration-data/medicare-check-list-status.enum';
import { InboundListDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { ImageType } from '../patient/enumeration-data/image-type.enum';


@Injectable({
  providedIn: 'root',
})
export class PatientMedicareCheckListDetailsService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientMedicareDTO) =>
    this.restService.request<any, PatientMedicareChecklistDTO>({
      method: 'POST',
      url: '/api/app/patient-medicare-check-list-details',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/patient-medicare-check-list-details/${id}`,
    },
    { apiName: this.apiName });

  get = (gMedicareId: string) =>
    this.restService.request<any, PatientMedicareChecklistDTO>({
      method: 'GET',
      url: `/api/app/patient-medicare-check-list-details/get-view-medicare/${gMedicareId}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientMedicareChecklistDTO>>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getPatientMedicareBasedOnStatusByStatus = (status: MedicareCheckListStatus) =>
    this.restService.request<any, PatientMedicareChecklistDTO[]>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details/patient-medicare-based-on-status',
      params: { status },
    },
    { apiName: this.apiName });

  getVerificationStatusByStatusID = (statusID: string) =>
    this.restService.request<any, InboundListDTO[]>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details/verification-status',
      params: { statusID },
    },
    { apiName: this.apiName });

    getMedicareListByStatusID = (ltBranch:string[]) =>
    this.restService.request<any, InboundListDTO[]>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details/medicare-list',
      params: { ltBranch },
    },
    { apiName: this.apiName });

  mapChecklistQuestions = () =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/patient-medicare-check-list-details/map-checklist-questions',
    },
    { apiName: this.apiName });

  mapQuestionBasedOnOptions = () =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/patient-medicare-check-list-details/map-question-based-on-options',
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePatientMedicareDTO) =>
    this.restService.request<any, PatientMedicareChecklistDTO>({
      method: 'PUT',
      url: `/api/app/patient-medicare-check-list-details/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getMedicareCheckListDetailsByGDocumentAndGPatient = (gDocument: string, gPatient: string) =>
    this.restService.request<any, medicareCheckListResult>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details/get-medicare-check-list-details',
      params: { gDocument, gPatient },
    },
    { apiName: this.apiName });

  getQuestionsByGCheckList = (gCheckList: checkListRequestDTO) =>
    this.restService.request<any, QuestionResult>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details/get-questions',
      params: { checkListId: gCheckList.checkListId, questionCode: gCheckList.questionCode, documentId: gCheckList.documentId, patientId: gCheckList.patientId, medicareChecklistId: gCheckList.medicareChecklistId, option: gCheckList.option },
    },
    { apiName: this.apiName });

  // updatePatientMedicareDetailsByIdAndInput = (id: string, input: finalSaveOutput) =>
  //   this.restService.request<any, string>({
  //     method: 'PUT',
  //     responseType: 'text',
  //     url: `/api/app/patient-medicare-check-list-details/${id}/update-patient-medicare-details`,
  //     body: input,
  //   },
  //   { apiName: this.apiName });

    mmcGetAPI = (status: MedicareCheckListFilter, FaxNo: string, ApproveOrDisapprove: MedicareCheckListStatus, patientName: string, chartNo: string, branch: string, patient: string, BranchName: string) =>
    this.restService.request<any, FilterResult[]>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details/patient-medicare-based-on-filter',
      params: { status, faxNo: FaxNo, approveOrDisapprove: ApproveOrDisapprove, patientName, chartNo, branch, patient, branchName: BranchName },
    },
    { apiName: this.apiName });

    updateIsStatByMedicareChecklisIdAndDocumentId = (MedicareChecklisId: string, DocumentId: string) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/app/patient-medicare-check-list-details/update-is-stat',
      params: { medicareChecklisId: MedicareChecklisId, documentId: DocumentId },
    },
    { apiName: this.apiName });

    updateLockedDataByMedicareChecklisIdAndDocumentId = (MedicareChecklisId: string, DocumentId: string) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/app/patient-medicare-check-list-details/update-locked-data',
      params: { medicareChecklisId: MedicareChecklisId, documentId: DocumentId },
    },
    { apiName: this.apiName });

    updateLockedDataByMedicareChecklisIdAndDocumentIdAndILockStatus = (MedicareChecklisId: string, DocumentId: string, iLockStatus: number) =>
      this.restService.request<any, LockOutput>({
        method: 'PUT',
        url: '/api/app/patient-medicare-check-list-details/update-locked-data',
        params: { medicareChecklisId: MedicareChecklisId, documentId: DocumentId, iLockStatus },
      },
      { apiName: this.apiName });
      
    updateApproveDisapprove = (MedicareChecklisId: string, disapprovedId: number,approveOrDisapprove: number,sNotes:string) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: `/api/app/patient-medicare-check-list-details/update-status-of-medicare/${MedicareChecklisId}`,
      params: { approveOrDisapprove:approveOrDisapprove,DisapproveStatusReason: disapprovedId,OtherDisapprovedReason:sNotes },
    },
    { apiName: this.apiName });

    getMedicareDocumentDetialsByCheckListIdAndPatientId = (checkListId: string, patientId: string) =>
    this.restService.request<any, MedicareChecklistDetailsDTO>({
      method: 'GET',
      url: '/api/app/patient-medicare-check-list-details/get-medicare-document-detials',
      params: { checkListId, patientId },
    },
    { apiName: this.apiName });

    getMedicareDocumentDetialsByCheckListAndPatientId = (checkList: string, patientId: string) =>
    this.restService.request<any, MedicareChecklistDetailsDTO>({
      method: 'POST',
      url: `/api/app/patient-medicare-check-list-details/get-medicare-document-detials/${patientId}`,
      params: { checkList },
    },
    { apiName: this.apiName });
    
    getCMNonFileTypeList = () =>
      this.restService.request<any, mmCMNonFileType[]>({
        method: 'GET',
        url: '/api/app/patient-medicare-check-list-details/get-cMNon-file-type-list',
      },
      { apiName: this.apiName });

      updatePatientMedicareDetailsByIdAndInput = (id: string, input: finalSaveOutput) =>
        this.restService.request<any, string>({
          method: 'PUT',
          responseType: 'text',
          url: `/api/app/patient-medicare-check-list-details/${id}/update-patient-medicare-details`,
          body: input,
        },
        { apiName: this.apiName });

        getImageResultByDocIdAndImgAndMedicareId = (DocId: string, img: ImageType, medicareId: string, OldPg: number[]) =>
          this.restService.request<any, imageList[]>({
            method: 'GET',
            url: '/api/app/patient-medicare-check-list-details/get-image-result',
            params: { docId: DocId, img, medicareId, oldPg: OldPg },
          },
          { apiName: this.apiName });


          getRXDocsSelectionListByDocIdAndPageNos = (docId: string, pageNos: number[]) =>
            this.restService.request<any, imageList[]>({
              method: 'GET',
              url: `/api/app/patient-medicare-check-list-details/get-rXDocs-selection-list/${docId}`,
              params: { pageNos },
            },
            { apiName: this.apiName });

            initiateDoMedicareResupplyByPatientId = (PatientId: string) =>
              this.restService.request<any, string>({
                method: 'POST',
                responseType: 'text',
                url: `/api/app/patient-medicare-check-list-details/initiate-do-medicare-resupply/${PatientId}`,
              },
              { apiName: this.apiName });



  constructor(private restService: RestService) {}
}
