import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { ToastrService } from "ngx-toastr";
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { ChartDTO, PayablePercentDTO, PreparedByDTO, TypeOfPlanDTO, VerifiedByDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import {
  AccountGroupDTO,
  AlternateCPTSDTO,
  AlternatePOSDTO,
  AlternateTOSDTO,
  AssignmentDTO,
  BranchOfficeDTO,
  CategoryDTO,
  ClaimFillingCodeDTO,
  ClaimFillingIndicatorDTO,
  ColorCodingDTO,
  CustomerTypeDTO,
  DunningMessageDTO,
  FacilityDTO,
  GenderDTO,
  HoldReasonDTO,
  InsEditsDTO,
  InsuranceGroupDTO,
  MaritalStatusDTO,
  PatientMasterDropdownDTO,
  PaymentCodeDTO,
  PayorLevelDTO,
  PlaceOfServiceDTO,
  PlanCategoryDTO,
  PrimaryFormDTO,
  PTSecurityGroupDTO,
  SecondaryDTO,
  SuffixDTO,
  TaxZoneDTO,
  WriteOffCodeDTO,
  ZoneDTO
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import { PatientService } from '../patient-proxy/patient';
import { AddressDTO, CreateUpdatePatientIntakeDTO, PatientDTO, PersonalDTO } from '../patient-proxy/patient/dto';
import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';
import { addPatientIntake, getPatient, updatePatient, updatePatientIntake } from '../patient-store/patient.action';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-patient-personal',
  templateUrl: './patient-personal.component.html',
  styleUrls: ['./patient-personal.component.scss'],
  providers: [ListService]
})
export class PatientPersonalComponent implements OnInit, AfterViewInit, OnDestroy {

  insuranceVerificationId: string = "00000000-0000-0000-0000-000000000000";
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  drpBranchOffices: BranchOfficeDTO[] = [];
  drpAccountGroups: AccountGroupDTO[] = [];
  drpZones: ZoneDTO[] = [];
  drpPTSecurityGroups: PTSecurityGroupDTO[] = [];
  drpCustomerTypes: CustomerTypeDTO[] = [];
  drpLocationTypes: { id: string, organizationUnitName: string, tenantId: string }[] = [];
  //drpCountries: CountryDTO[] = [];
  drpSuffixes: SuffixDTO[] = [];
  drpClaimFillingCodes: ClaimFillingCodeDTO[] = [];
  drpPlaceOfServices: PlaceOfServiceDTO[] = [];
  drpFacility: FacilityDTO[] = [];
  drpTaxZone: TaxZoneDTO[] = [];
  //drpCity: CityDTO[] = [];
  //drpState: StateDTO[] = [];
  // drpCounty: CountyDTO[] = [];
  personalForm: FormGroup;
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpVerified: VerifiedByDTO[] = [];
  drpPrepared: PreparedByDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  @Input() patientId: string = '';
  @Input() editable: boolean = false; gender: string;
  emailMask: any;
  matcher = new MyErrorStateMatcher();
  @Output() patientIdEmitter: EventEmitter<CreateUpdatePatientIntakeDTO> = new EventEmitter<CreateUpdatePatientIntakeDTO>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  organizationUnitId = null;
  @Input() clientId: string = '';
  drpGenderLoop: GenderDTO[] = [];
  // clientId = null;
  isPatientPoliciesPresent: boolean = false;
  payorLevel: PayorLevelDTO[] = [];
  drpmartailStatusLoop: MaritalStatusDTO[] = [];
  drpPolicies: any;
  drpColorCodings: ColorCodingDTO[] = [];
  drpCategories: CategoryDTO[] = [];

  drpProviders: any[] = [];
  drpLocation: any[] = [];
  drpDunnigMessages: DunningMessageDTO[] = [];
  drpHoldReasons: HoldReasonDTO[] = [];
  drpAssignment: AssignmentDTO[] = [];
  drpInsEdits: InsEditsDTO[] = [];
  drpPaymentCode: PaymentCodeDTO[] = [];
  drpWriteOffCode: WriteOffCodeDTO[] = [];
  drpAlternateCPTS: AlternateCPTSDTO[] = [];
  drpAlternatePOS: AlternatePOSDTO[] = [];
  drpAlternateTOS: AlternateTOSDTO[] = [];
  drpPrimaryForm: PrimaryFormDTO[] = [];
  drpSecondary: SecondaryDTO[] = [];
  drpPlanCategory: PlanCategoryDTO[] = [];
  drpClaimFillingIndicator: ClaimFillingIndicatorDTO[] = [];
  drpInsuranceGroup: InsuranceGroupDTO[] = [];
  @Output() savePersonalFormState: EventEmitter<any> = new EventEmitter<any>();
  billCountryName: string = "";
  deliveryCountryName: string = "";
  billStateName: string = "";
  deliveryStateName: string = "";
  branchTableData: any[] = [];
  maritalStatus: string;
  txtDiscountPercent: string = "";
  isChecked = true;
  public date = new Date();
  location: string;
  facility: string;
  customerType: string;
  suffixName: string;
  category: string;
  holdReason: string;
  provider: string;
  dunningMessage: string;
  taxZone: string;
  branchOffice: string;
  accountGroup: string;
  ptSecurityGroup: string;
  pos: string;
  county: string;
  zone: string;
  drpcounty: string;
  showChart: boolean = false;
  // clientId: string;
  zones: string;
  phoneCode: string;
  phoneDelCode: string;
  branchId: string = "";
  // BirthdateMinDate: Date;
  hideMoveToVerificationButton: boolean = false;
  saveButtonHide: boolean;
  fileId: string = '';
  batchId: string = "";
  isShowSpinner: boolean = false;
  tenantId: string = "";
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private textValidators: TextValidators,
    private Table: TableService,
    private providerList: MasterProviderService,
    private region: RegionDropdownService,
    public dialog: MatDialog,
    public list: ListService,
    private branchService: OrganizationUnitService,
    private patientDropdownService: PatientMasterDropdownService,
    private patientService: PatientService,
    public userProfileService: UserService,
    private numberValidators: NumberValidators,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private patientSearchService: PatientSearchService,
    private dashboardService: DashboardService,
    private router: Router,
    private organizationUnitService: OrganizationUnitService
  ) { }
  isPatientUpdatePermission: boolean = false;
  isPatientCreatePermission: boolean = false;
  isShownUpdateIconForPatient: boolean = true;
  isShownSaveButton: boolean = true;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    // this.Table.getPatientLockStatus().subscribe(val => {
    //   this.isPatientLockedByCurrentUser = val === true ? true : false;
    // })
    const getLoginUserId = this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    this.subscription$.push(getLoginUserId)
    //
    this.createPersonalForm();
    // this.organizationUnitId.replace('"',"").replace('"', "");
    const getPatientCreate = this.Table.getPatientCreate().subscribe(value => {
      this.isPatientCreatePermission = value;
      if (this.isPatientCreatePermission == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getPatientCreate);
    //
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.getdropdowns();

    if (this.personalForm.invalid || this.personalForm.dirty) {
      this.savePersonalFormState.emit(this.personalForm);
    }

    //  Automatically patch location if single content
    if (this.patientId == null || this.patientId == "" || this.patientId == undefined) {
      const organizationList = this.organizationUnitService.getOrganizationList(this.tenantId).subscribe(response => {
        response && response.forEach(element => {
          if (response.length == 1) {
            this.personalForm.patchValue({ drpLocation: element?.organizationUnitName })
          }
          else {
            this.personalForm.patchValue({ drpLocation: "" })
          }
        })
      })
      this.subscription$.push(organizationList)
    }
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //! Page Initialization funtions
  ngAfterViewInit() {
    this.patientId !== "" && this.patientId !== null && this.patientId !== undefined && this.getPatientDetailsByID();
    // this.patientId !== "" && this.patientId !== null && this.patientId !== undefined &&
    //   this.Table.getCountryTable().subscribe(value => {
    //     const selPatientValue: PatientDTO = value;
    //     if (selPatientValue && selPatientValue?.addresses[0].country) {
    //       this.billCountryName = selPatientValue?.addresses[0].country;
    //     }
    //     if (selPatientValue && selPatientValue?.addresses[0].state) {
    //       this.billStateName = selPatientValue?.addresses[0].state;
    //     }
    //     if (selPatientValue && selPatientValue?.addresses[1].country) {
    //       this.deliveryCountryName = selPatientValue?.addresses[1].country;
    //     }
    //     if (selPatientValue && selPatientValue?.addresses[1].state) {
    //       this.deliveryStateName = selPatientValue?.addresses[1].state;
    //     }
    //     //  this.billCountryName != null && this.billCountryName !== "" && this.billCountryName != undefined && this.billState(this.billCountryName)
    //     // this.billStateName != null && this.billStateName !== "" && this.billStateName != undefined && this.billCity(this.billStateName)
    //     //   this.deliveryCountryName != null && this.deliveryCountryName !== "" && this.deliveryCountryName != undefined && this.deliveryState(this.deliveryCountryName)
    //     //     this.deliveryStateName != null && this.deliveryStateName !== "" && this.deliveryStateName != undefined && this.deliveryCity(this.deliveryStateName)
    //   }, err => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   });

    // if (this.personalForm.invalid || this.personalForm.dirty) {
    //   this.savePersonalFormState.emit(this.personalForm);
    // }
  }


  //! Provider Mapping Based on  Patient location
  onChangeBillCityName(value: string | null | undefined) {
    // if (value !== null && value !== undefined && typeof value === "string") {
    //   const filterNameArray = this.drpProviders.filter((provider) => provider?.fullName?.toLowerCase().includes(value?.toLowerCase()))[0];
    //   filterNameArray && this.personalForm.patchValue({
    //     txtProvider: filterNameArray?.ShortCodeId ?? ""
    //   })
    // }
  }

  //! Form Creation and Few Validations
  createPersonalForm() {
    this.personalForm = this.formBuilder.group({
      txtChartId: new FormControl(""),
      drpLocation: new FormControl(null, Validators.required),
      txtFirstName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtLastName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtMiddleName: new FormControl("", [this.textValidators.isTextCheck]),
      //   drpGeneralGender: new FormControl(null),
      drpMarital: new FormControl(null),
      txtSuffixName: new FormControl("", [this.textValidators.isTextCheck]),
      txtSSN: new FormControl("", [Validators.minLength(4)]),
      txtHomePhoneNo: new FormControl(""),
      txtWorkPhoneNo: new FormControl(""),
      txtDeliveryPhone: new FormControl(""),
      txtAccountNumber: new FormControl("", [Validators.minLength(9)]),
      drpSuffix: new FormControl(null),
      drpCustomerType: new FormControl(null),
      txtPriorSystemKeyFacility: new FormControl("", [this.textValidators.isTextCheck]),
      drpFacility: new FormControl(null),
      txtAlternateIDType: new FormControl(""),
      txtEthinicity: new FormControl(""),
      txtRace: new FormControl(""),
      chkHoldAccount: new FormControl(""),
      chkHoldBillingStatement: new FormControl(""),
      chkHipaaSignature: new FormControl(""),
      txtDiscountPercent: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      txtTaxZone: new FormControl(null),
      drpBranchOffice: new FormControl(null),
      drpAccountGroup: new FormControl(null, Validators.required),
      drpPTSecurityGroup: new FormControl(null),
      txtUser1: new FormControl(""),
      txtUser2: new FormControl(""),
      txtUser3: new FormControl(""),
      txtUser4: new FormControl(""),
      txtBillingCountryFilter: new FormControl(""),
      txtBillingStateFilter: new FormControl(""),
      txtBillingCityFilter: new FormControl(""),
      txtBillingAddress: new FormControl(""),
      txtBillingCity: new FormControl(""),
      drpBillingCounty: new FormControl("", this.textValidators.isTextCheck),
      drpBillingCountry: new FormControl(""),
      txtBillingPostalCode: new FormControl(""),
      txtBillingPhone: new FormControl(""),
      txtBillingFax: new FormControl(""),
      drpBillingZone: new FormControl(null),
      txtBillingMobile: new FormControl(""),
      txtBillingState: new FormControl(null),
      txtBillingEmailAddress: new FormControl(""),
      txtDeliveryCountryFilter: new FormControl(""),
      txtDeliveryStateFilter: new FormControl(""),
      txtDeliveryCityFilter: new FormControl(""),
      txtDeliveryAddress: new FormControl(""),
      txtDeliveryCity: new FormControl(null),
      txtDeliveryState: new FormControl(null),
      drpDeliveryCounty: new FormControl(null, this.textValidators.isTextCheck),
      drpDeliveryCountry: new FormControl(null),
      txtDeliveryPostalCode: new FormControl(""),
      txtDeliveryFax: new FormControl(""),
      txtDeliveryMobile: new FormControl(""),
      txtDeliveryEmailAddress: new FormControl(""),
      txtBirthdate: new FormControl(null, [Validators.required]),
      txtAuditDateCreated: new FormControl(new Date()),
      txtPlaceOfServiceDateOfAdmission: new FormControl(new Date()),
      txtPlaceOfServiceDateOfDischarge: new FormControl(null),
      drpZone: new FormControl(null),
      drpPlaceOfService: new FormControl(null),
      txtAuditCreatedBy: new FormControl("", this.textValidators.isTextCheck),
      chkCashPayment: new FormControl(""),
      chkStat: new FormControl(""),
      // txtName: new FormControl(""),
      //! newly added
      drpColor: new FormControl(null),
      chkPatientIntrest: new FormControl(""),
      txtCatogery: new FormControl(""),
      txtProvider: new FormControl("", [Validators.required]),
      txtPatientBalance: new FormControl("", this.numberValidators.isNumberCheck),
      txtInsuranceBalance: new FormControl("", this.numberValidators.isNumberCheck),
      txtTotalBalance: new FormControl("", this.numberValidators.isNumberCheck),
      txtAlerts: new FormControl(""),
      chkReceiveBill: new FormControl(""),
      txtStatementTo: new FormControl("", this.textValidators.isTextCheck),
      drpHoldReason: new FormControl(""),
      chkCollection: new FormControl(""),
      chkHoldClaims: new FormControl(""),
      txtHoldNotes: new FormControl("", this.textValidators.isTextCheck),
      chkDoNotCall: new FormControl(""),
      drpDunningMessage: new FormControl(""),
      chkAutoDunning: new FormControl(""),

      phones: this.formBuilder.array([
        this.formBuilder.control(null)
      ]),
    })
    const valueChanges = this.personalForm.valueChanges.subscribe(() => {
      let firstname: AbstractControl = this.personalForm?.get('txtFirstName');
      if (firstname.dirty) {
        firstname?.value?.trim() === "" && firstname?.setErrors({ required: true })
        firstname?.markAsDirty();
        firstname?.markAsTouched();
        // firstname.markAsPristine();
      }

      let lastname: AbstractControl = this.personalForm?.get('txtLastName');
      if (lastname.dirty) {
        lastname?.value?.trim() === "" && lastname?.setErrors({ required: true })
        lastname?.markAsDirty();
        lastname?.markAsTouched();
        //   firstname.markAsPristine();
      }



    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(valueChanges)
    //
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.emailMask = emailMask;
    this.patientId === null && this.getByChartId();
    this.phoneCode = '';
    this.phoneDelCode = '';
  }

  // verify Rule Redirection
  verifyRule() {
    const url = '/#/ViewDocumentDetailsAndApprove';
    window.open(url, '_blank');
  }

  //! chartid get
  getByChartId() {
    // this.personalForm.controls['txtChartId'].disable()
    // this.showChart=true
    // const generateChartId = this.patientSearchService.generateChartId().subscribe(response => {
    this.personalForm.patchValue({ txtChartId: "PC52253117370" })
    // })
    //this.subscription$.push(generateChartId)
    // this.personalForm.controls['txtChartId'].disable({onlySelf: true})
  }

  addDiagnosis() {
    (this.personalForm?.get('phones') as FormArray).push(
      this.formBuilder.control(null)
    );
  }


  getPhonesFormControls(): AbstractControl[] {
    return (<FormArray>this.personalForm?.get('phones')).controls
  }

  //! Master Dropdown Funtion
  getdropdowns() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.BranchOffices,
      PatientDropdowns.AccountGroups,
      PatientDropdowns.Zones,
      PatientDropdowns.SecurityGroups,
      PatientDropdowns.CustomerTypes,
      PatientDropdowns.Suffixes,
      PatientDropdowns.Genders,
      PatientDropdowns.MaritalStatus,
      PatientDropdowns.ClaimFillingCodes,
      PatientDropdowns.PlaceOfServices,
      PatientDropdowns.Facilities,
      PatientDropdowns.TaxZones,
      PatientDropdowns.ColorCodings,
      PatientDropdowns.Categories,
      PatientDropdowns.DunningMessages,
      PatientDropdowns.HoldReasons,
      PatientDropdowns.Assignments,
      PatientDropdowns.InsEdits,
      PatientDropdowns.PaymentCodes,
      PatientDropdowns.WriteOffCodes,
      PatientDropdowns.AlternateCpts,
      PatientDropdowns.AlternatePos,
      PatientDropdowns.AlternateTos,
      PatientDropdowns.PrimaryForm,
      PatientDropdowns.Secondary,
      PatientDropdowns.PlanCategories,
      PatientDropdowns.ClaimFillingIndicators,
      PatientDropdowns.InsuranceGroups,
      PatientDropdowns.PayorLevels
    ]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      let reponse: PatientMasterDropdownDTO = stateResponse;
      this.drpBranchOffices = reponse?.branchOffices;
      this.drpAccountGroups = reponse?.accountGroups;
      this.drpZones = reponse?.zones;
      this.drpPTSecurityGroups = reponse?.ptSecurityGroups;
      this.drpCustomerTypes = reponse?.customerTypes;
      this.drpSuffixes = reponse?.suffixes;
      this.drpGenderLoop = reponse?.genders;
      this.drpmartailStatusLoop = reponse.maritalStatuses;
      //this.drpCountries = reponse?.countries;
      this.drpClaimFillingCodes = reponse?.claimFillingCodes;
      this.drpPlaceOfServices = reponse?.placeOfServices;
      this.drpFacility = reponse?.facilities;
      this.drpTaxZone = reponse?.taxZones;
      //this.drpCity = reponse?.cities;
      //this.drpState = reponse?.states;
      //this.drpCounty = reponse?.counties;
      //! New
      this.drpColorCodings = reponse?.lstColorCodings;
      this.drpCategories = reponse?.lstCategories;
      // this.drpProviders = reponse?.lstProviders;
      this.drpDunnigMessages = reponse?.lstDunnigMessages;
      this.drpHoldReasons = reponse?.lstHoldReasons;
      this.drpAssignment = reponse?.lstAssignment;
      this.drpInsEdits = reponse?.lstInsEdits;
      this.drpPaymentCode = reponse?.lstPaymentCode;
      this.drpWriteOffCode = reponse?.lstWriteOffCode;
      this.drpAlternateCPTS = reponse?.lstAlternateCPTS;
      this.drpAlternatePOS = reponse?.lstAlternatePOS;
      this.drpAlternateTOS = reponse?.lstAlternateTOS;
      this.drpPrimaryForm = reponse?.lstPrimaryForm;
      this.drpSecondary = reponse?.lstSecondary;
      this.drpPlanCategory = reponse?.lstPlanCategory;
      this.drpClaimFillingIndicator = reponse?.lstClaimFillingIndicator;
      this.drpInsuranceGroup = reponse?.lstInsuranceGroup;
      this.payorLevel = reponse?.payorLevels;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientDropdown);
    // let orderDropdownInput : OrderDropdowns[];
    // orderDropdownInput = [
    //   OrderDropdowns.Charts,
    //   OrderDropdowns.TypeOfPlans,
    //   OrderDropdowns.VerifiedBies,
    //   OrderDropdowns.PreparedBies,
    //   OrderDropdowns.PayablePercents
    // ]
    // this.orderMasterDropdown.getByInput(orderDropdownInput).subscribe((stateResponse) => {
    //   let response: OrderMasterDropdownDTO = stateResponse;
    //   this.drpChart = response?.charts;
    //   this.drpPlan = response?.typeOfPlans;
    //   this.drpVerified = response?.verifiedBies;
    //   this.drpPrepared = response?.preparedBies;
    //   this.drpPayable = response?.payablePercents;

    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    //! provider getlist
    const providerList = (query: any) => this.providerList.getList(query);
    const providerDropdown = this.list.hookToQuery(providerList).subscribe(response => {
      response?.items?.forEach(element => {
        this.drpProviders.push({
          id: element?.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi
        });
      });
    })
    this.subscription$.push(providerDropdown)
    //
    const userProfile = this.dashboardService.userProfile().subscribe(response => {
      this.clientId = response.tenantId;
      const organizationList = this.branchService.getOrganizationList(this.clientId).subscribe(response => {
        response?.forEach(element => {
          this.drpLocationTypes.push({
            id: element?.id,
            organizationUnitName: element?.organizationUnitName,
            tenantId: element?.tenantId,
          });
        });
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(userProfile);
      this.subscription$.push(organizationList);
    })

    //! branch getlist
    this.clearBillingCountry();
    this.clearDeliveryCountry();
    this.countryDropdown();
    this.countryDeliDropdown();

  }
  drpBillCountry: RegionCountriesDTO[] = [];
  drpDeliCountry: RegionCountriesDTO[] = [];
  drpBillStates: RegionStatesDTO[] = [];
  drpDeliStates: RegionStatesDTO[] = [];
  drpBillCities: RegionCitiesDTO[] = [];
  drpDeliCities: RegionCitiesDTO[] = [];

  public filteredBillingCountries: Observable<RegionCountriesDTO[]>;
  public filteredBillingStates: Observable<RegionStatesDTO[]>;
  public filteredBillingCities: Observable<RegionCitiesDTO[]>;
  public filteredDeliveryCountries: Observable<RegionCountriesDTO[]>;
  public filteredDeliveryStates: Observable<RegionStatesDTO[]>;
  public filteredDeliveryCities: Observable<RegionCitiesDTO[]>;
  @ViewChild('singleSelect') singleSelect: MatSelect;

  // billing Country Dropdown
  countryDropdown() {
    this.onChangeBillState(defaultCountry.US);
    this.onChangedeliState(defaultCountry.US);
    this.DeliveryCountry = defaultCountry.US;
    this.BillingCountry = defaultCountry.US;
    this.drpBillCountry = [];
    const countryDropdown = this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpBillCountry = response?.items;
      this.personalForm.patchValue({ "drpDeliveryCountry": defaultCountry.US })

      this.filteredBillingCountries = this.personalForm?.get("txtBillingCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpBillCountry.filter(option => option?.countryShortName === defaultCountry.US))
        )
      // .pipe(
      //   startWith(''),
      //   map(value => this.drpBillCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
      // );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(countryDropdown);
  }

  // delivery country //
  countryDeliDropdown() {
    this.drpDeliCountry = [];
    const deliCountryDropdown = this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpDeliCountry = response?.items;
      this.personalForm.patchValue({ "drpBillingCountry": defaultCountry.US })

      this.filteredDeliveryCountries = this.personalForm?.get("txtDeliveryCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpBillCountry.filter(option => option?.countryShortName === defaultCountry.US))
        )
    })
    this.subscription$.push(deliCountryDropdown);
  }

  // billing state city//
  onChangeBillState(value: string | null | undefined): boolean {
    this.personalForm.patchValue({
      txtBillingState: null,
      txtBillingCity: null
    })
    this.drpBillStates = [];
    this.drpBillCities = [];
    if (value !== null && value !== undefined) {
      const stateDropdown = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpBillStates = response.items;
        this.ref.detach();
        if (!response?.items) {
          this.personalForm.controls['txtHomePhoneNo'].disable();
          this.personalForm.controls['txtWorkPhoneNo'].disable();
          this.personalForm.controls['txtBillingPhone'].disable();
          this.personalForm.controls['txtBillingFax'].disable();
          this.personalForm.controls['txtBillingMobile'].disable();
          this.phoneCode = "";
        } else {
          this.personalForm.controls['txtHomePhoneNo'].enable();
          this.personalForm.controls['txtWorkPhoneNo'].enable();
          this.personalForm.controls['txtBillingPhone'].enable();
          this.personalForm.controls['txtBillingFax'].enable();
          this.personalForm.controls['txtBillingMobile'].enable();
          this.phoneCode = response?.items[0]?.countryPhoneCode;
        }

        this.ref.reattach();
        this.filteredBillingStates = this.personalForm?.get("txtBillingStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpBillStates.filter(option =>
              option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
            ))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(stateDropdown);
    }


    return this.drpBillStates && this.drpBillStates.length !== 0;
  }


  onChangeBillCity(state: string | null | undefined, country: string): boolean {
    this.personalForm.patchValue({
      txtBillingCity: null
    });
    country = (country == "") ? (this.personalForm.value.drpBillingCountry == (null || undefined) ? "" : (this.personalForm.value.drpBillingCountry)) : country;
    this.drpBillCities = [];
    if (state !== null && state !== undefined) {
      const cityDropdown = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpBillCities = response.items;
        this.filteredBillingCities = this.personalForm?.get("txtBillingCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpBillCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(cityDropdown);
    }
    return this.drpBillCities && this.drpBillCities.length !== 0;
  }

  // deli state city
  onChangedeliState(value: string | null | undefined): boolean {
    this.personalForm.patchValue({
      txtDeliveryState: null,
      txtDeliveryCity: null
    });
    this.drpDeliStates = [];
    this.drpDeliCities = [];
    if (value !== null && value !== undefined) {
      const stateDropdown = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpDeliStates = response.items;
        this.ref.detach();
        if (!response?.items) {
          this.personalForm.controls['txtDeliveryPhone'].disable();
          this.personalForm.controls['txtDeliveryFax'].disable();
          this.personalForm.controls['txtDeliveryMobile'].disable();
          this.phoneDelCode = "";
        } else {
          this.personalForm.controls['txtDeliveryPhone'].enable();
          this.personalForm.controls['txtDeliveryFax'].enable();
          this.personalForm.controls['txtDeliveryMobile'].enable();
          this.phoneDelCode = response?.items[0]?.countryPhoneCode;
        }
        this.ref.reattach();
        this.filteredDeliveryStates = this.personalForm?.get("txtDeliveryStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpDeliStates.filter(option => option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(stateDropdown);
    }

    return this.drpDeliStates && this.drpDeliStates.length !== 0;
  }

  onChangeDeliCity(state: string | null | undefined, country: string): boolean {
    this.personalForm.patchValue({
      txtDeliveryCity: null
    });
    country = (country == "") ? (this.personalForm.value.drpDeliveryCountry == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry)) : country;
    this.drpDeliCities = [];
    if (state !== null && state !== undefined) {
      const cityDropdown = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpDeliCities = response.items;
        this.filteredDeliveryCities = this.personalForm?.get("txtDeliveryCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpDeliCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(cityDropdown);
    }
    return this.drpDeliCities && this.drpDeliCities.length !== 0;
  }


  // billState(value: string | null | undefined) {

  //   value !== null && value !== undefined && this.ProviderDropdown.getAllStatesBasedonCountriesByCountryName(value).subscribe(response => {
  //     this.drpBillStates = response.items;

  //   }, err => {
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'error',
  //       text: data?.error?.error?.message,
  //     });
  //   })
  // }
  // billCity(value: string | null | undefined) {

  //   value !== null && value !== undefined && this.ProviderDropdown.getAllCitiesBasedonStatesByStateName(value).subscribe(response => {
  //     this.drpBillCities = response.items;
  //   }, err => {
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'error',
  //       text: data?.error?.error?.message,
  //     });
  //   })
  // }
  // deliveryState(value: string | null | undefined) {

  //   value !== null && value !== undefined && this.ProviderDropdown.getAllStatesBasedonCountriesByCountryName(value).subscribe(response => {
  //     this.drpDeliStates = response.items;
  //   }, err => {
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'error',
  //       text: data?.error?.error?.message,
  //     });
  //   })
  // }
  // deliveryCity(value: string | null | undefined) {

  //   value !== null && value !== undefined && this.ProviderDropdown.getAllCitiesBasedonStatesByStateName(value).subscribe(response => {
  //     this.drpDeliCities = response.items;
  //   }, err => {
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'error',
  //       text: data?.error?.error?.message,
  //     });
  //   })
  // }
  BillingCountry: string = '';
  BillingState: string = '';
  BillingCity: string = '';
  DeliveryCountry: string = '';
  DeliveryState: string = '';
  DeliveryCity: string = '';
  clearBillingCountry() {
    this.BillingCountry = '';
    this.phoneCode = '';
    this.personalForm.patchValue({ drpBillingCountry: "" });
    this.personalForm.controls['txtHomePhoneNo'].disable();
    this.personalForm.controls['txtWorkPhoneNo'].disable();
    this.personalForm.controls['txtBillingPhone'].disable();
    this.personalForm.controls['txtBillingFax'].disable();
    this.personalForm.controls['txtBillingMobile'].disable();
  }
  clearBillingState() {
    this.BillingState = '';
    this.personalForm.patchValue({ txtBillingState: "" });

  }
  clearBillingCity() {
    this.BillingCity = '';
    this.personalForm.patchValue({ txtBillingCity: "" });

  }
  clearDeliveryCountry() {
    this.DeliveryCountry = '';
    this.phoneDelCode = '';
    this.personalForm.patchValue({ drpDeliveryCountry: "" });
    this.personalForm.controls['txtDeliveryPhone'].disable();
    this.personalForm.controls['txtDeliveryFax'].disable();
    this.personalForm.controls['txtDeliveryMobile'].disable();
  }
  clearDeliveryState() {
    this.DeliveryState = '';
    this.personalForm.patchValue({ txtDeliveryState: "" });

  }
  clearDeliveryCity() {
    this.DeliveryCity = '';
    this.personalForm.patchValue({ txtDeliveryCity: "" });

  }

  savePersonal() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    // this.Table.getOrganizationUnitId().subscribe(value => {

    //   this.organizationUnitId = value;
    // });
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    // this.organizationUnitId = this.organizationUnitId === "null" ? null : this.organizationUnitId;
    let personalDetails: CreateUpdatePatientIntakeDTO;
    if (this.patientId === "" || this.patientId === null || this.patientId === undefined) {

      personalDetails = {
        personals: {
          locationType: this.personalForm.value.drpLocation,
          firstName: this.personalForm.value.txtFirstName,
          lastName: this.personalForm.value.txtLastName,
          middleName: this.personalForm.value.txtMiddleName,
          suffix: (this.personalForm.value.drpSuffix == (null || undefined) ? "" : (this.personalForm.value.drpSuffix)),// this.personalForm.value.txtSuffixName,
          dateOfBirth: this.personalForm?.get("txtBirthdate")?.value,
          ssn: this.personalForm.value.txtSSN,
          homePhoneNo: this.personalForm.value.txtHomePhoneNo,
          workPhoneNo: this.personalForm.value.txtWorkPhoneNo,
          accountNumber: this.personalForm.value.txtAccountNumber,
          customerType: (this.personalForm.value.drpCustomerType == (null || undefined) ? "" : (this.personalForm.value.drpCustomerType)),
          priorSystemKeyFacility: this.personalForm.value.txtPriorSystemKeyFacility,
          facility: (this.personalForm.value.drpFacility == (null || undefined) ? "" : (this.personalForm.value.drpFacility)),
          alternateIdType: this.personalForm.value.txtAlternateIDType,
          holdAccount: +this.personalForm.value.chkHoldAccount,
          holdBillingStatements: +this.personalForm.value.chkHoldBillingStatement,
          hippaSignatureOnFile: +this.personalForm.value.chkHipaaSignature,
          discountPercentage: +this.personalForm?.get('txtDiscountPercent')?.value | 0,
          taxZone: this.personalForm?.get("txtTaxZone")?.value,
          branchOffice: (this.personalForm.value.drpBranchOffice == (null || undefined) ? "" : (this.personalForm.value.drpBranchOffice)),
          accountGroupType: (this.personalForm.value.drpAccountGroup == (null || undefined) ? "" : (this.personalForm.value.drpAccountGroup)),
          ptSecurityGroupType: (this.personalForm.value.drpPTSecurityGroup == (null || undefined) ? "" : (this.personalForm.value.drpPTSecurityGroup)),
          user1: this.personalForm?.get("txtUser1")?.value,
          user2: this.personalForm?.get("txtUser2")?.value,
          user3: this.personalForm?.get("txtUser3")?.value,
          user4: this.personalForm?.get("txtUser4")?.value,
          placeOfService: (this.personalForm.value.drpPlaceOfService == (null || undefined) ? "" : (this.personalForm.value.drpPlaceOfService)),
          dateOfAdmission: this.personalForm?.get("txtPlaceOfServiceDateOfAdmission")?.value,
          dateOfDischarge: this.personalForm?.get("txtPlaceOfServiceDateOfDischarge")?.value,
          auditTrialCreatedBy: this.personalForm.value.txtAuditCreatedBy,
          auditTrialDateCreated: this.personalForm?.get("txtAuditDateCreated")?.value,
          colorCodingId: this.personalForm?.get("drpColor")?.value,
          catogeryId: this.personalForm?.get("txtCatogery")?.value,
          providerId: this.personalForm?.get("txtProvider")?.value,
          patientBalance: 0,
          insurnceBalance: 0,
          totalBalance: 0,
          // patientBalance: this.personalForm?.get("txtPatientBalance")?.value === "" ? 0 : +this.personalForm?.get("txtPatientBalance")?.value,
          // insurnceBalance: this.personalForm?.get("txtInsuranceBalance")?.value === "" ? 0 : +this.personalForm?.get("txtInsuranceBalance")?.value,
          // totalBalance: this.personalForm?.get("txtTotalBalance")?.value === "" ? 0 : +this.personalForm?.get("txtTotalBalance")?.value,
          patientAlerts: {
            patientAlertsId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            patientAlertsNotes: this.personalForm?.get("txtAlerts")?.value,
          },
          statementTo: this.personalForm?.get("txtStatementTo")?.value,
          isCollection: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkCollection").value === true ? 1 : 0,
          isDoNotCall: +this.personalForm?.get("chkPatientIntrest")?.value,// this.personalForm?.get("chkDoNotCall").value === true ? 1 : 0,
          isHoldClaims: +this.personalForm?.get("chkPatientIntrest")?.value,// this.personalForm?.get("chkHoldClaims").value === true ? 1 : 0,
          holdNotes: this.personalForm?.get("txtHoldNotes")?.value,
          holdReason: this.personalForm?.get("drpHoldReason")?.value,
          autoDunningId: this.personalForm?.get("drpDunningMessage")?.value,
          isAutoDunning: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkAutoDunning").value === true ? 1 : 0,
          isRecieveBill: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkReceiveBill").value === true ? 1 : 0,
          isPatientInterest: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkPatientIntrest").value === true ? 1 : 0,
          fullName: "",
          emailId: this.personalForm?.get("txtBillingEmailAddress")?.value.toLowerCase(),
          isCashPayment: +this.personalForm?.get("chkCashPayment")?.value,
          isStat: +this.personalForm?.get("chkStat")?.value,
          //   gender: (this.personalForm.value.drpGeneralGender == (null || undefined) ? "" : (this.personalForm.value.drpGeneralGender)),
          gender: "",
          maritalStatus: (this.personalForm.value.drpMarital == (null || undefined) ? "" : (this.personalForm.value.drpMarital)),
          ethinicity: this.personalForm?.get("txtEthinicity")?.value,
          race: this.personalForm?.get("txtRace")?.value,

        },
        addresses: [{
          addressType: 0,
          relationship: "",
          address: this.personalForm?.get("txtBillingAddress")?.value,
          city: this.personalForm?.get("txtBillingCity")?.value,
          state: this.personalForm?.get("txtBillingState")?.value == (null || undefined) ? "" : (this.personalForm?.get("txtBillingState")?.value),
          country: (this.personalForm.value.drpBillingCountry == (null || undefined) ? "" : (this.personalForm.value.drpBillingCountry)),
          county: (this.personalForm.value.drpBillingCounty == (null || undefined) ? "" : (this.personalForm.value.drpBillingCounty)),
          postalCode: this.personalForm?.get("txtBillingPostalCode")?.value,
          phone: this.personalForm?.get("txtBillingPhone")?.value,
          fax: this.personalForm?.get("txtBillingFax")?.value,
          mobile: this.personalForm?.get("txtBillingMobile")?.value,
          // emailId: this.personalForm?.get("txtBillingEmailAddress")?.value,
          zone: this.personalForm?.get("drpBillingZone")?.value,
        }
          , {
          addressType: 1,
          relationship: "",
          address: this.personalForm?.get("txtDeliveryAddress")?.value,
          city: this.personalForm?.get("txtDeliveryCity")?.value == (null || undefined) ? "" : (this.personalForm?.get("txtDeliveryCity").value),
          state: this.personalForm?.get("txtDeliveryState")?.value == (null || undefined) ? "" : (this.personalForm?.get("txtDeliveryState").value),
          country: (this.personalForm.value.drpDeliveryCountry == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry)),
          county: (this.personalForm.value.drpDeliveryCounty == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCounty)),
          postalCode: this.personalForm?.get("txtDeliveryPostalCode")?.value,
          phone: this.personalForm?.get("txtDeliveryPhone")?.value,
          fax: this.personalForm?.get("txtDeliveryFax")?.value,
          mobile: this.personalForm?.get("txtDeliveryMobile")?.value,
          // emailId: this.personalForm?.get("txtDeliveryEmailAddress")?.value,
          zone: this.personalForm?.get("drpZone")?.value,
        }
        ],
        contacts: [
          {
            contactType: 0,
            relationshipId: "",
            firstName: "",
            middleName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            county: "",
            country: defaultCountry.US,
            postalCode: "",
            phone: "",
            mobile: "",
            fax: "",
            emailAddress: "",
          },
          {
            contactType: 1,
            relationshipId: "",
            firstName: "",
            middleName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            county: "",
            country: defaultCountry.US,
            postalCode: "",
            phone: "",
            mobile: "",
            fax: "",
            emailAddress: "",
          }
        ],
        clinicals:
        {
          genderType: "",
          height: 0,
          weight: 0,
          dod: "",
          functionalAbility: "",
          generalPractitioner: "",
          infectiousConditon: 0,
          airborne: 0,
          droplet: 0,
          contact: 0,
          renderingProviderType: "",
          renderingProviderDoctor: "",
          renderingProviderFacility: "",
          referringProviderType: "",
          referringProviderDoctor: "",
          referringProviderFacility: "",
          orderingDoctor: "",
          primaryDoctor: "",
          marketingRepType: "",
          marketingReferralType: "",
          marketingReferralDoctor: "",
          marketingReferralFacility: "",
          marketingReferralContact: "",
          diagnosisCode9: [],
          diagnosisCode10: [],

          appointmentDate: null ?? "",
          branch: "",
          description: "",
          practitioner: "",
          status: "",
          isDoctorSigned: 0,
          previousHistoryDeclaration: 0,
          // createdByUserId: 0
        },
        insurances: [],
        notes: [],
        policies: {
          primaryPolicy: undefined,
          secondaryPolicy: undefined,
          tertiaryPolicy: undefined,
        },
        organizationUnitId: null,
        defaultPatientId: "",
        // insuranceVerifications: {
        //   primaryInsuranceVerifies: null,
        //   secondaryInsuranceVerifies: null,
        //   tertiaryInsuranceVerifies: null
        // },

        // authorizations: {
        //   primaryAuthorizations: null,
        //   secondaryAuthorizations: null,
        //   tertiaryAuthorizations: null
        // },
        isAutoIntake: 0,
        autoIntakeDocumentDetails: [],
        chartId: "PC52253117370",
        patientLock: null,
        // fileId: null,
      }

      this.saveOrUpdate(personalDetails);
    } else {
      let selectedPatient: PatientDTO;
      (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) && this.patientService.get(this.patientId).subscribe(response => {
        selectedPatient = response;
        personalDetails = {
          personals: {
            locationType: this.personalForm.value.drpLocation,
            firstName: this.personalForm.value.txtFirstName,
            lastName: this.personalForm.value.txtLastName,
            middleName: this.personalForm.value.txtMiddleName,
            suffix: (this.personalForm.value.drpSuffix == (null || undefined) ? "" : (this.personalForm.value.drpSuffix)), //this.personalForm.value.txtSuffixName,
            dateOfBirth: this.personalForm?.get("txtBirthdate")?.value,
            ssn: this.personalForm.value.txtSSN,
            homePhoneNo: this.personalForm.value.txtHomePhoneNo,
            workPhoneNo: this.personalForm.value.txtWorkPhoneNo,
            accountNumber: this.personalForm.value.txtAccountNumber,
            customerType: (this.personalForm.value.drpCustomerType == (null || undefined) ? "" : (this.personalForm.value.drpCustomerType)),
            priorSystemKeyFacility: this.personalForm.value.txtPriorSystemKeyFacility,
            facility: (this.personalForm.value.drpFacility == (null || undefined) ? "" : (this.personalForm.value.drpFacility)),
            alternateIdType: this.personalForm.value.txtAlternateIDType,
            holdAccount: +this.personalForm.value.chkHoldAccount,
            holdBillingStatements: +this.personalForm.value.chkHoldBillingStatement,
            hippaSignatureOnFile: +this.personalForm.value.chkHipaaSignature,
            discountPercentage: +this.personalForm?.get('txtDiscountPercent')?.value | 0,
            taxZone: this.personalForm?.get("txtTaxZone")?.value,
            branchOffice: (this.personalForm.value.drpBranchOffice == (null || undefined) ? "" : (this.personalForm.value.drpBranchOffice)),
            accountGroupType: (this.personalForm.value.drpAccountGroup == (null || undefined) ? "" : (this.personalForm.value.drpAccountGroup)),
            ptSecurityGroupType: (this.personalForm.value.drpPTSecurityGroup == (null || undefined) ? "" : (this.personalForm.value.drpPTSecurityGroup)),
            user1: this.personalForm?.get("txtUser1")?.value,
            user2: this.personalForm?.get("txtUser2")?.value,
            user3: this.personalForm?.get("txtUser3")?.value,
            user4: this.personalForm?.get("txtUser4")?.value,
            placeOfService: (this.personalForm.value.drpPlaceOfService == (null || undefined) ? "" : (this.personalForm.value.drpPlaceOfService)),
            dateOfAdmission: this.personalForm?.get("txtPlaceOfServiceDateOfAdmission")?.value,
            dateOfDischarge: this.personalForm?.get("txtPlaceOfServiceDateOfDischarge")?.value,
            auditTrialCreatedBy: this.personalForm.value.txtAuditCreatedBy,
            auditTrialDateCreated: this.personalForm?.get("txtAuditDateCreated")?.value,
            colorCodingId: this.personalForm?.get("drpColor")?.value,
            catogeryId: this.personalForm?.get("txtCatogery")?.value,
            providerId: this.personalForm?.get("txtProvider")?.value,
            patientBalance: 0,
            insurnceBalance: 0,
            totalBalance: 0,
            // patientBalance: this.personalForm?.get("txtPatientBalance")?.value === "" ? 0 : +this.personalForm?.get("txtPatientBalance")?.value,
            // insurnceBalance: this.personalForm?.get("txtInsuranceBalance")?.value === "" ? 0 : +this.personalForm?.get("txtInsuranceBalance")?.value,
            // totalBalance: this.personalForm?.get("txtTotalBalance")?.value === "" ? 0 : +this.personalForm?.get("txtTotalBalance")?.value,
            patientAlerts: {
              patientAlertsId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              patientAlertsNotes: this.personalForm?.get("txtAlerts")?.value,
            },
            statementTo: this.personalForm?.get("txtStatementTo")?.value,
            isCollection: +this.personalForm?.get("chkCollection")?.value,//this.personalForm?.get("chkCollection")?.value === true ? 1 : 0,
            isDoNotCall: +this.personalForm?.get("chkDoNotCall")?.value,// this.personalForm?.get("chkDoNotCall")?.value === true ? 1 : 0,
            isHoldClaims: +this.personalForm?.get("chkHoldClaims")?.value,// this.personalForm?.get("chkHoldClaims")?.value === true ? 1 : 0,
            holdNotes: this.personalForm?.get("txtHoldNotes")?.value,
            holdReason: this.personalForm?.get("drpHoldReason")?.value,
            autoDunningId: this.personalForm?.get("drpDunningMessage")?.value,
            isAutoDunning: +this.personalForm?.get("chkAutoDunning")?.value,//this.personalForm?.get("chkAutoDunning")?.value === true ? 1 : 0,
            isRecieveBill: +this.personalForm?.get("chkReceiveBill")?.value,//this.personalForm?.get("chkReceiveBill")?.value === true ? 1 : 0,
            isPatientInterest: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkPatientIntrest")?.value === true ? 1 : 0,
            fullName: "",
            emailId: this.personalForm?.get("txtBillingEmailAddress")?.value.toLowerCase(),
            isCashPayment: +this.personalForm?.get("chkCashPayment")?.value,
            isStat: +this.personalForm?.get("chkStat")?.value,
            //       gender: (this.personalForm.value.drpGeneralGender == (null || undefined) ? "" : (this.personalForm.value.drpGeneralGender)),
            gender: "",

            maritalStatus: (this.personalForm.value.drpMarital == (null || undefined) ? "" : (this.personalForm.value.drpMarital)),
            ethinicity: this.personalForm?.get("txtEthinicity")?.value,
            race: this.personalForm?.get("txtRace")?.value,
          },
          addresses: [{
            addressType: 0,
            relationship: "",
            address: this.personalForm?.get("txtBillingAddress")?.value,
            city: this.personalForm?.get("txtBillingCity")?.value,
            state: this.personalForm?.get("txtBillingState")?.value,
            country: (this.personalForm.value.drpBillingCountry == (null || undefined) ? "" : (this.personalForm.value.drpBillingCountry)),
            county: (this.personalForm.value.drpBillingCounty == (null || undefined) ? "" : (this.personalForm.value.drpBillingCounty)),
            postalCode: this.personalForm?.get("txtBillingPostalCode")?.value,
            phone: this.personalForm?.get("txtBillingPhone")?.value,
            fax: this.personalForm?.get("txtBillingFax")?.value,
            mobile: this.personalForm?.get("txtBillingMobile")?.value,
            // emailId: this.personalForm?.get("txtBillingEmailAddress")?.value,
            zone: this.personalForm?.get("drpBillingZone")?.value,
          }
            , {
            addressType: 1,
            relationship: "",
            address: this.personalForm?.get("txtDeliveryAddress")?.value,
            city: this.personalForm?.get("txtDeliveryCity")?.value,
            state: this.personalForm?.get("txtDeliveryState")?.value,
            country: (this.personalForm.value.drpDeliveryCountry == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry)),
            county: (this.personalForm.value.drpDeliveryCounty == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCounty)),
            postalCode: this.personalForm?.get("txtDeliveryPostalCode")?.value,
            phone: this.personalForm?.get("txtDeliveryPhone")?.value,
            fax: this.personalForm?.get("txtDeliveryFax")?.value,
            mobile: this.personalForm?.get("txtDeliveryMobile")?.value,
            //   emailId: this.personalForm?.get("txtDeliveryEmailAddress")?.value,
            zone: this.personalForm?.get("drpZone")?.value,
          }
          ],
          contacts: selectedPatient?.contacts,
          insurances: selectedPatient?.insurances,
          policies: selectedPatient?.policies,
          notes: selectedPatient?.notes,
          //  patientDocuments: selectedPatient?.patientDocuments,
          clinicals: selectedPatient?.clinicals,
          organizationUnitId: null,
          defaultPatientId: selectedPatient?.defaultPatientId,
          //  insuranceVerifications: selectedPatient?.insuranceVerifications,
          //  authorizations: selectedPatient?.authorizations,
          isAutoIntake: selectedPatient?.isAutoIntake,
          autoIntakeDocumentDetails: selectedPatient?.autoIntakeDocumentDetails,
          chartId: "PC52253117370",
          patientLock: selectedPatient?.patientLock,
          // fileId: [
          //   "00000000-0000-0000-0000-000000000000"
          // ],
          // fileId:null,

        }
        this.saveOrUpdate(personalDetails);

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }


  stateName: string = '';
  CountryName: string = '';
  DelstateName: string = '';
  DelCountryName: string = '';

  //! Perosnal Details Get By ID Functions
  getPatientDetailsByID() {
    // this.personalForm.controls['txtChartId'].disable()
    const patientDetailsById = this.Table.getPatientUpdate().subscribe(value => {
      this.isPatientUpdatePermission = value;
      if (this.isPatientUpdatePermission == true) {
        this.isShownUpdateIconForPatient = true;
      }
      else {
        this.isShownUpdateIconForPatient = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientDetailsById);
    //
    if ((this.patientId !== "" && this.patientId !== null && this.patientId !== undefined)) {
      const storeSubscription = this.patientService.get(this.patientId).subscribe(response => {
        //  const storeSubscription = this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        const selectedPatient: PatientDTO = response;
        this.fileId = selectedPatient?.fileId?.slice((selectedPatient?.fileId?.length - 1) - (selectedPatient?.fileId?.length | 0))[0]?.toString() ?? '';
        // this.batchId = selectedPatient?.batchId ?? "";
        this.Table.setCountryTable(selectedPatient);
        const selectedPatientPersonal: PersonalDTO = response.personals;
        const selectedPatientBAddress: AddressDTO = selectedPatient?.addresses[0];
        const selectedPatientDAddress: AddressDTO = selectedPatient?.addresses[1];
        this.drpPolicies = selectedPatient?.policies;
        this.stateName = selectedPatientBAddress?.state;
        this.CountryName = selectedPatientBAddress?.country;
        this.DelCountryName = selectedPatientDAddress?.country;
        this.DelstateName = selectedPatientDAddress?.state;
        this.phoneCode = '';
        this.phoneDelCode = '';
        this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
          this.onChangeBillState(this.CountryName) && this.personalForm.patchValue({
            drpBillingCountry: this.CountryName,
          });
        this.stateName != null && this.stateName !== "" && this.stateName != undefined && this.onChangeBillCity(this.stateName, this.CountryName) && this.personalForm.patchValue({
          txtBillingState: this.stateName,
          txtBillingCity: selectedPatientBAddress?.city
        });

        this.DelCountryName != null && this.DelCountryName !== "" && this.DelCountryName != undefined && this.onChangedeliState(this.DelCountryName) && this.personalForm.patchValue({
          drpDeliveryCountry: this.DelCountryName,
        });
        this.DelstateName != null && this.DelstateName !== "" && this.DelstateName != undefined &&
          this.onChangeDeliCity(this.DelstateName, this.DelCountryName) && this.personalForm.patchValue({
            txtDeliveryState: this.DelstateName,
            txtDeliveryCity: selectedPatientDAddress?.city
          });
        selectedPatientPersonal !== null && selectedPatientPersonal !== undefined && this.personalForm.patchValue({
          drpLocation: selectedPatientPersonal?.locationType,
          txtFirstName: selectedPatientPersonal?.firstName,
          txtLastName: selectedPatientPersonal?.lastName,
          txtMiddleName: selectedPatientPersonal?.middleName,
          drpSuffix: selectedPatientPersonal?.suffix,
          txtSSN: selectedPatientPersonal?.ssn,
          txtHomePhoneNo: selectedPatientPersonal?.homePhoneNo,
          txtWorkPhoneNo: selectedPatientPersonal?.workPhoneNo,
          txtAccountNumber: selectedPatientPersonal?.accountNumber,
          drpCustomerType: selectedPatientPersonal?.customerType,
          txtPriorSystemKeyFacility: selectedPatientPersonal?.priorSystemKeyFacility,
          drpFacility: selectedPatientPersonal?.facility,
          txtAlternateIDType: selectedPatientPersonal?.alternateIdType,
          chkHoldAccount: selectedPatientPersonal?.holdAccount,
          chkHoldBillingStatement: selectedPatientPersonal?.holdBillingStatements,
          chkHipaaSignature: selectedPatientPersonal?.hippaSignatureOnFile,
          txtDiscountPercent: selectedPatientPersonal?.discountPercentage === 0 ? "" : selectedPatientPersonal?.discountPercentage,
          txtTaxZone: selectedPatientPersonal?.taxZone,
          drpBranchOffice: selectedPatientPersonal?.branchOffice,
          drpAccountGroup: selectedPatientPersonal?.accountGroupType,
          drpPTSecurityGroup: selectedPatientPersonal?.ptSecurityGroupType,
          txtUser1: selectedPatientPersonal?.user1,
          txtUser2: selectedPatientPersonal?.user2,
          txtUser3: selectedPatientPersonal?.user3,
          txtUser4: selectedPatientPersonal?.user4,
          txtBillingAddress: selectedPatientBAddress?.address,
          txtBillingCity: selectedPatientBAddress?.city, //=== "" || undefined || null ? null : selectedPatientBAddress.city,
          drpBillingCounty: selectedPatientBAddress?.county,
          drpBillingCountry: selectedPatientBAddress?.country,
          txtBillingPostalCode: selectedPatientBAddress?.postalCode,
          txtBillingPhone: selectedPatientBAddress?.phone,
          txtBillingFax: selectedPatientBAddress?.fax,
          drpBillingZone: selectedPatientBAddress?.zone,
          txtBillingMobile: selectedPatientBAddress?.mobile,
          txtBillingState: selectedPatientBAddress?.state, //=== null || "" || undefined ? null : selectedPatientBAddress?.state,
          // txtBillingEmailAddress: selectedPatientBAddress?.emailId,
          // txtBillingEmailAddress: selectedPatientBAddress?.emailId,
          txtDeliveryAddress: selectedPatientDAddress?.address,
          txtDeliveryCity: selectedPatientDAddress?.city,//=== "" || undefined ? null : selectedPatientDAddress.city,
          txtDeliveryState: selectedPatientDAddress?.state,//=== "" || undefined ? null : selectedPatientDAddress.state,
          drpDeliveryCounty: selectedPatientDAddress?.county,
          drpDeliveryCountry: selectedPatientDAddress?.country,// === "" || undefined ? null : selectedPatientDAddress.country,
          txtDeliveryPostalCode: selectedPatientDAddress?.postalCode,
          txtDeliveryPhone: selectedPatientDAddress?.phone,
          txtDeliveryFax: selectedPatientDAddress?.fax,
          txtDeliveryMobile: selectedPatientDAddress?.mobile,
          drpZone: selectedPatientDAddress?.zone,
          //  txtDeliveryEmailAddress: selectedPatientDAddress?.emailId,
          txtBillingEmailAddress: selectedPatientPersonal?.emailId,
          txtBirthdate: selectedPatientPersonal?.dateOfBirth === null ? "" : selectedPatientPersonal?.dateOfBirth,
          txtAuditDateCreated: selectedPatientPersonal?.auditTrialDateCreated === null ? "" : selectedPatientPersonal?.auditTrialDateCreated,
          txtPlaceOfServiceDateOfAdmission: selectedPatientPersonal?.dateOfAdmission === null ? "" : selectedPatientPersonal?.dateOfAdmission,
          txtPlaceOfServiceDateOfDischarge: selectedPatientPersonal?.dateOfDischarge === null ? "" : selectedPatientPersonal?.dateOfDischarge,
          drpPlaceOfService: selectedPatientPersonal?.placeOfService,
          txtAuditCreatedBy: selectedPatientPersonal?.auditTrialCreatedBy,
          //! newly added
          drpColor: selectedPatient?.personals.colorCodingId,
          chkPatientIntrest: selectedPatient?.personals.isPatientInterest,
          txtCatogery: selectedPatient?.personals.catogeryId,
          txtProvider: selectedPatient?.personals.providerId,
          // txtPatientBalance: selectedPatient?.personals.patientBalance === 0 ? "" : selectedPatient?.personals.patientBalance,
          // txtInsuranceBalance: selectedPatient?.personals.insurnceBalance === 0 ? "" : selectedPatient?.personals.insurnceBalance,
          // txtTotalBalance: selectedPatient?.personals.totalBalance === 0 ? "" : selectedPatient?.personals.totalBalance,
          txtAlerts: selectedPatient?.personals?.patientAlerts?.patientAlertsNotes,
          chkReceiveBill: selectedPatient?.personals.isRecieveBill,
          txtStatementTo: selectedPatient?.personals.statementTo,
          drpHoldReason: selectedPatient?.personals.holdReason,
          chkCollection: selectedPatient?.personals.isCollection,
          chkHoldClaims: selectedPatient?.personals.isHoldClaims,
          txtHoldNotes: selectedPatient?.personals.holdNotes,
          chkDoNotCall: selectedPatient?.personals.isDoNotCall,
          drpDunningMessage: selectedPatient?.personals.autoDunningId,
          chkAutoDunning: selectedPatient?.personals.isAutoDunning,
          chkCashPayment: selectedPatient?.personals.isCashPayment,
          chkStat: selectedPatient?.personals.isStat,
          //  drpGeneralGender: selectedPatient?.personals.gender,
          drpMarital: selectedPatient?.personals.maritalStatus,
          txtEthinicity: selectedPatient?.personals.ethinicity,
          txtRace: selectedPatient?.personals.race,
          txtChartId: selectedPatient?.chartId
        });

        this.isPatientLockedByCurrentUser = (selectedPatient?.patientLock?.isLocked == true &&
          selectedPatient?.patientLock?.userId == this.strLoginUserId) ||
          (selectedPatient?.patientLock?.isLocked == false) ? true : false;


        (selectedPatient?.policies?.primaryPolicy !== null && selectedPatient?.policies?.primaryPolicy !== undefined) ? (this.isPatientPoliciesPresent = true) : (this.isPatientPoliciesPresent = false);

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(storeSubscription);
    }
  }
  showswal: boolean;

  //! Perosnal Save Or Update Functions
  saveOrUpdate(personalDetails: CreateUpdatePatientIntakeDTO) {
    if ((this.patientId !== "" && this.patientId !== null && this.patientId !== undefined)) {
      this.store.dispatch(new updatePatientIntake(this.patientId, personalDetails)).subscribe((stateResponse) => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Personal");
          }
        }, err => { });
        this.saveButtonHide = false;
        let patientSaved: CreateUpdatePatientIntakeDTO = stateResponse?.patientState?.selectedPatient;
        this.patientId = stateResponse?.patientState?.selectedPatient?.id;
        this.patientIdEmitter.emit(patientSaved);
        this.Table.setheaderTable(this.patientId);
        this.Table.setSelectedPatientId(this.patientId);
        this.savePersonalFormState.emit(this.personalForm.value);
        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        // if(data?.error?.error?.code === "PatientManagement:00026"){
        //   Swal.fire({
        //     icon: 'info',
        //     text: data?.error?.error?.message,
        //   });
        // }
        // else{
        Swal.fire({

          icon: 'info',
          text: data?.error?.error?.message,
        });
        //}
      })
    } else {
      this.store.dispatch(new addPatientIntake(personalDetails)).subscribe((stateResponse) => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Personal");
          }
        }, err => { });
        this.saveButtonHide = false;
        let patientSaved: CreateUpdatePatientIntakeDTO = stateResponse?.patientState?.selectedPatient;
        this.patientId = stateResponse?.patientState?.selectedPatient?.id;
        this.patientIdEmitter.emit(patientSaved);
        this.Table.setheaderTable(this.patientId);
        this.Table.setSelectedPatientId(this.patientId);
        this.savePersonalFormState.emit(this.personalForm.value);
        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }

  //! Copy Patient Address Info
  copyPatient() {
    this.personalForm.patchValue({
      txtDeliveryAddress: this.personalForm?.get("txtBillingAddress").value,
      drpDeliveryCounty: this.personalForm?.get("drpBillingCounty").value,
      drpDeliveryCountry: this.personalForm?.get("drpBillingCountry").value,
      txtDeliveryPostalCode: this.personalForm?.get("txtBillingPostalCode").value,
      txtDeliveryPhone: this.personalForm?.get("txtBillingPhone").value,
      txtDeliveryFax: this.personalForm?.get("txtBillingFax").value,
      txtDeliveryMobile: this.personalForm?.get("txtBillingMobile").value,
      txtDeliveryEmailAddress: this.personalForm?.get("txtBillingEmailAddress").value,
      drpZone: this.personalForm?.get("drpBillingZone").value,

    });
    this.phoneDelCode = this.phoneCode
    this.personalForm.value.drpDeliveryCountry != "" &&
      this.personalForm.value.drpDeliveryCountry != null &&
      this.personalForm.value.drpDeliveryCountry != undefined &&
      this.onChangedeliveryState(this.personalForm.value.drpDeliveryCountry)
  }


  onChangedeliveryState(value: string | null | undefined): boolean {
    this.personalForm.patchValue({
      txtDeliveryState: null,
      txtDeliveryCity: null
    });

    this.drpDeliStates = [];
    this.drpDeliCities = [];
    if (value !== null && value !== undefined) {
      const stateDropdown = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpDeliStates = response.items;
        this.filteredDeliveryStates = this.personalForm?.get("txtDeliveryStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpDeliStates.filter(option => option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        this.personalForm.patchValue({
          drpDeliveryCountry: this.personalForm?.get("drpBillingCountry").value,
          txtDeliveryState: this.personalForm?.get("txtBillingState").value,
        });
        this.onChangeDeliveryCity(this.personalForm.value.txtDeliveryState, this.personalForm.value.drpDeliveryCountry)
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(stateDropdown);
    }
    return this.drpDeliStates && this.drpDeliStates.length !== 0;

  }


  onChangeDeliveryCity(state: string | null | undefined, country: string): boolean {
    this.personalForm.patchValue({
      txtDeliveryCity: null
    });
    country = (country == "") ? (this.personalForm.value.drpDeliveryCountry == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry)) : country;
    this.drpDeliCities = [];
    if (state !== null && state !== undefined) {
      const cityDropdown = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpDeliCities = response.items;
        this.filteredDeliveryCities = this.personalForm?.get("txtDeliveryCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpDeliCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        this.personalForm.patchValue({
          txtDeliveryCity: this.personalForm?.get("txtBillingCity").value,

        });
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(cityDropdown);
    }
    return this.drpDeliCities && this.drpDeliCities.length !== 0;
  }


  //! Move to Verification
  movetoVerification() {
    let verificationData: PatientDTO;
    if ((this.patientId !== "" && this.patientId !== null && this.patientId !== undefined)) {
      this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        const selectedPatient: PatientDTO = response?.patientState?.selectedPatient;
        if (selectedPatient?.insuranceVerifications) {
          let savedData = {
            primaryInsuranceVerifies: selectedPatient?.insuranceVerifications.primaryInsuranceVerifies,
            secondaryInsuranceVerifies: selectedPatient?.insuranceVerifications.secondaryInsuranceVerifies,
            tertiaryInsuranceVerifies: selectedPatient?.insuranceVerifications.tertiaryInsuranceVerifies
          }
          if (selectedPatient?.policies.primaryPolicy !== null) {
            savedData.primaryInsuranceVerifies = {
              insuranceVerificationId: this.insuranceVerificationId,
              // chart: this.drpChart[0]?.chartType,
              name: selectedPatient?.personals?.firstName,
              address: selectedPatient?.addresses[0]?.address,
              dateofBirth: selectedPatient?.personals?.dateOfBirth,
              commentsOrNotes: "",
              //plan: patientData?.policies[0]?.policiesId,
              plan: selectedPatient?.policies?.primaryPolicy.payorLevel,
              planName: selectedPatient?.policies.primaryPolicy.insuranceType,
              planPhone: selectedPatient?.policies.primaryPolicy.phone,
              policy: selectedPatient?.policies.primaryPolicy.policyNo,
              groupName: selectedPatient?.policies.primaryPolicy.groupName,
              effectiveDate: selectedPatient?.policies.primaryPolicy.startDate,
              // typeOfPolicy: this.payorLevel.filter(v => v.payorLevelId === patientData?.policies[0]?.payorLevel)[0].payorLevelName,
              typeOfPolicy: "",
              fascalYearStart: "",
              end: selectedPatient?.policies.primaryPolicy.endDate,
              isMedicareCmn: 0,
              isPatientHome: 0,
              isFamilyDeductible: 0,
              cmnCode: "",
              typeOfPlan: "",
              //typeOfPlan: this.payorLevel.filter(v => v.payorLevelId === patientData?.policies[0]?.payorLevel)[0].payorLevelName,
              isIndividual: 0,
              isOutOfNetwork: 0,
              isFamily: 0,
              planDeductible: {
                individual: "",
                outOfNetwork: "",

                family: "",
              },

              deductibleMet: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              payablePercent: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketExpense: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketExpenseMet: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketIncludeDeductible: {
                individual: 0,
                outOfNetwork: 0,
                family: 0,
              },
              isPercertRequired: 0,
              phone: "",
              isReferralRequired: 0,
              pcp: "",
              isPredetermination: 0,
              preNotes: "",
              lifeTimeMax: "",
              lifeTimeMaxMet: "",
              isDmePartsCovered: 0,
              isDmeReplaceMentUnit: 0,
              dmeAnnualLimit: "",
              dmeLimitMet: "",
              requestedBy: "",
              primaryPayor: "",
              contact: "",
              callTrackingNumber: "",
              policyHolderName: "",
              policyHolderDob: "",
              isNetwork: 0,
              claimAddress: "",
              planCob: "",
              dedTotal: "",
              dedMet: "",
              oopTotal: "",
              oopMet: "",
              oop: "",
              dmeCoverage: "",
              dmeMax: "",
              hcpcs: "",
              coverageCriteria: "",
              qtyLimits: "",
              frequency: "",
              rent: "",
              paRequired: "",
              codesRequirePa: "",
              sameOrSimilarInfo: "",
              spokeWith: "",
              verifiedBy: "",
              dateVerified: "",
              preparedBy: "",
              preparedOn: "",
              verificationStatus: "Yet To Start",
              moveToAuthStatus: "",
              verifiedMethod: "",
              isActive: 0,
              coIns: "",
              reason: "",
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              chart: "",
              coInsurance: "",
              commentsToTech: "",
              deo: "",
              isBlueCardEligible: 0,
              groupNo: "",
              isClaimSubmit: 0,
              isDirectBill: 0,
              isPreAuthNeeded: 0,
              isReferenceNumberAvailable: 0,
              patientResposponsiblePaymentPercentage: "",
              payorId: "",
              referenceNumber: "",
              state: "",
              verifiedTypeMethod: "",


            }
          }
          if (selectedPatient?.policies.secondaryPolicy !== null) {
            savedData.secondaryInsuranceVerifies = {
              insuranceVerificationId: this.insuranceVerificationId,
              // chart: this.drpChart[0]?.chartType,
              name: selectedPatient?.personals?.firstName,
              address: selectedPatient?.addresses[0]?.address,
              dateofBirth: selectedPatient?.personals?.dateOfBirth,
              commentsOrNotes: "",
              //plan: patientData?.policies[0]?.policiesId,
              plan: selectedPatient?.policies?.secondaryPolicy.payorLevel,
              planName: selectedPatient?.policies.secondaryPolicy.insuranceType,
              planPhone: selectedPatient?.policies.secondaryPolicy.phone,
              policy: selectedPatient?.policies.secondaryPolicy.policyNo,
              groupName: selectedPatient?.policies.secondaryPolicy.groupName,
              effectiveDate: selectedPatient?.policies.secondaryPolicy.startDate,
              // typeOfPolicy: this.payorLevel.filter(v => v.payorLevelId === patientData?.policies[0]?.payorLevel)[0].payorLevelName,
              typeOfPolicy: "",
              fascalYearStart: "",
              end: selectedPatient?.policies.secondaryPolicy.endDate,
              isMedicareCmn: 0,
              isPatientHome: 0,
              isFamilyDeductible: 0,
              cmnCode: "",
              typeOfPlan: "",
              //typeOfPlan: this.payorLevel.filter(v => v.payorLevelId === patientData?.policies[0]?.payorLevel)[0].payorLevelName,
              isIndividual: 0,
              isOutOfNetwork: 0,
              isFamily: 0,
              planDeductible: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },

              deductibleMet: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              payablePercent: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketExpense: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketExpenseMet: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketIncludeDeductible: {
                individual: 0,
                outOfNetwork: 0,
                family: 0,
              },
              isPercertRequired: 0,
              phone: "",
              isReferralRequired: 0,
              pcp: "",
              isPredetermination: 0,
              preNotes: "",
              lifeTimeMax: "",
              lifeTimeMaxMet: "",
              isDmePartsCovered: 0,
              isDmeReplaceMentUnit: 0,
              dmeAnnualLimit: "",
              dmeLimitMet: "",
              requestedBy: "",
              primaryPayor: "",
              contact: "",
              callTrackingNumber: "",
              policyHolderName: "",
              policyHolderDob: "",
              isNetwork: 0,
              claimAddress: "",
              planCob: "",
              dedTotal: "",
              dedMet: "",
              oopTotal: "",
              oopMet: "",
              oop: "",
              dmeCoverage: "",
              dmeMax: "",
              hcpcs: "",
              coverageCriteria: "",
              qtyLimits: "",
              frequency: "",
              rent: "",
              paRequired: "",
              codesRequirePa: "",
              sameOrSimilarInfo: "",
              spokeWith: "",
              verifiedBy: "",
              dateVerified: "",
              preparedBy: "",
              preparedOn: "",
              verificationStatus: "Yet To Start",
              moveToAuthStatus: "",
              verifiedMethod: "",
              isActive: 0,
              coIns: "",
              reason: "",
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              chart: "",
              coInsurance: "",
              commentsToTech: "",
              deo: "",
              isBlueCardEligible: 0,
              groupNo: "",
              isClaimSubmit: 0,
              isDirectBill: 0,
              isPreAuthNeeded: 0,
              isReferenceNumberAvailable: 0,
              patientResposponsiblePaymentPercentage: "",
              payorId: "",
              referenceNumber: "",
              state: "",
              verifiedTypeMethod: "",
            }
          }
          if (selectedPatient?.policies.tertiaryPolicy !== null) {
            savedData.tertiaryInsuranceVerifies = {
              insuranceVerificationId: this.insuranceVerificationId,
              // chart: this.drpChart[0]?.chartType,
              name: selectedPatient?.personals?.firstName,
              address: selectedPatient?.addresses[0]?.address,
              dateofBirth: selectedPatient?.personals?.dateOfBirth,
              commentsOrNotes: "",
              //plan: patientData?.policies[0]?.policiesId,
              plan: selectedPatient?.policies?.tertiaryPolicy.payorLevel,
              planName: selectedPatient?.policies.tertiaryPolicy.insuranceType,
              planPhone: selectedPatient?.policies.tertiaryPolicy.phone,
              policy: selectedPatient?.policies.tertiaryPolicy.policyNo,
              groupName: selectedPatient?.policies.tertiaryPolicy.groupName,
              effectiveDate: selectedPatient?.policies.tertiaryPolicy.startDate,
              // typeOfPolicy: this.payorLevel.filter(v => v.payorLevelId === patientData?.policies[0]?.payorLevel)[0].payorLevelName,
              typeOfPolicy: "",
              fascalYearStart: "",
              end: selectedPatient?.policies.tertiaryPolicy.endDate,
              isMedicareCmn: 0,
              isPatientHome: 0,
              isFamilyDeductible: 0,
              cmnCode: "",
              typeOfPlan: "",
              //typeOfPlan: this.payorLevel.filter(v => v.payorLevelId === patientData?.policies[0]?.payorLevel)[0].payorLevelName,
              isIndividual: 0,
              isOutOfNetwork: 0,
              isFamily: 0,
              planDeductible: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              deductibleMet: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              payablePercent: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketExpense: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketExpenseMet: {
                individual: "",
                outOfNetwork: "",
                family: "",
              },
              outOfPocketIncludeDeductible: {
                individual: 0,
                outOfNetwork: 0,
                family: 0,
              },
              isPercertRequired: 0,
              phone: "",
              isReferralRequired: 0,
              pcp: "",
              isPredetermination: 0,
              preNotes: "",
              lifeTimeMax: "",
              lifeTimeMaxMet: "",
              isDmePartsCovered: 0,
              isDmeReplaceMentUnit: 0,
              dmeAnnualLimit: "",
              dmeLimitMet: "",
              requestedBy: "",
              primaryPayor: "",
              contact: "",
              callTrackingNumber: "",
              policyHolderName: "",
              policyHolderDob: "",
              isNetwork: 0,
              claimAddress: "",
              planCob: "",
              dedTotal: "",
              dedMet: "",
              oopTotal: "",
              oopMet: "",
              oop: "",
              dmeCoverage: "",
              dmeMax: "",
              hcpcs: "",
              coverageCriteria: "",
              qtyLimits: "",
              frequency: "",
              rent: "",
              paRequired: "",
              codesRequirePa: "",
              sameOrSimilarInfo: "",
              spokeWith: "",
              verifiedBy: "",
              dateVerified: "",
              preparedBy: "",
              preparedOn: "",
              verificationStatus: "Yet To Start",
              moveToAuthStatus: "",
              verifiedMethod: "",
              isActive: 0,
              coIns: "",
              reason: "",
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              chart: "",
              coInsurance: "",
              commentsToTech: "",
              deo: "",
              isBlueCardEligible: 0,
              groupNo: "",
              isClaimSubmit: 0,
              isDirectBill: 0,
              isPreAuthNeeded: 0,
              isReferenceNumberAvailable: 0,
              patientResposponsiblePaymentPercentage: "",
              payorId: "",
              referenceNumber: "",
              state: "",
              verifiedTypeMethod: "",
            }
          }
          verificationData = {
            personals: selectedPatient?.personals,
            addresses: selectedPatient?.addresses,
            contacts: selectedPatient?.contacts,
            clinicals: selectedPatient?.clinicals,
            insurances: selectedPatient?.insurances,
            notes: selectedPatient?.notes,
            policies: selectedPatient?.policies,
            defaultPatientId: selectedPatient?.defaultPatientId,
            insuranceVerifications: {
              primaryInsuranceVerifies: savedData.primaryInsuranceVerifies,
              secondaryInsuranceVerifies: savedData.secondaryInsuranceVerifies,
              tertiaryInsuranceVerifies: savedData.tertiaryInsuranceVerifies,
            },
            authorizations: selectedPatient?.authorizations,
            patientDocuments: selectedPatient?.patientDocuments,
            organizationUnitId: selectedPatient?.organizationUnitId,
            isAutoIntake: selectedPatient?.isAutoIntake,
            autoIntakeDocumentDetails: selectedPatient?.autoIntakeDocumentDetails,
            chartId: selectedPatient?.chartId,
            billingDetails: selectedPatient?.billingDetails,
            patientLock: selectedPatient?.patientLock,
            // isSorted: selectedPatient?.isSorted,
            fileId: selectedPatient?.fileId,

          }


        }
        this.store.dispatch(new updatePatient(this.patientId, verificationData)).subscribe(response => {

          // Swal.fire({ title: 'Success', html: 'Moved To Verification Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Moved To Verification Successfully','Success')
          this.hideMoveToVerificationButton = true
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }
  //! To View Rules page by  file id
  navigateToRulesPage() {
    if (this.fileId) {
      const url = '/#/ViewDocumentDetailsAndApprove/' + [this.fileId.toString()];
      window.open(url, '_blank');
    }
  }
  // clear dropdown
  clearLocation() {
    this.location = '';
    this.personalForm.patchValue({ drpLocation: '' });
  }
  clearFacility() {
    this.facility = '';
    this.personalForm.patchValue({ drpFacility: '' });

  }
  clearCustomer() {
    this.customerType = '';
    this.personalForm.patchValue({ drpCustomerType: '' });

  }
  clearSuffix() {
    this.suffixName = '';
    this.personalForm.patchValue({ drpSuffix: '' });

  }
  clearMaritalStatus() {
    this.maritalStatus = '';
    this.personalForm.patchValue({ drpMarital: "" });
  }
  // clear dropdown
  clearGender() {
    this.gender = "";
    this.personalForm.patchValue({ drpGeneralGender: '' });

  }

  clearCategory() {
    this.category = '';
    this.personalForm.patchValue({ txtCatogery: '' });

  }
  clearHoldReason() {
    this.holdReason = '';
    this.personalForm.patchValue({ drpHoldReason: '' });

  }

  clearProvider() {
    this.provider = '';
    this.personalForm.patchValue({ txtProvider: '' });
  }

  clearDunningMessage() {
    this.dunningMessage = "";
    this.personalForm.patchValue({ drpDunningMessage: '' });

  }

  clearTaxZone() {
    this.taxZone = '';
    this.personalForm.patchValue({ txtTaxZone: '' });

  }

  clearBranchOffice() {
    this.branchOffice = '';
    this.personalForm.patchValue({ drpBranchOffice: '' });

  }

  clearAccountGroup() {
    this.accountGroup = '';
    this.personalForm.patchValue({ drpAccountGroup: '' });

  }

  clearPtSecurityGroup() {
    this.ptSecurityGroup = '';
    this.personalForm.patchValue({ drpPTSecurityGroup: '' });

  }

  clearPos() {
    this.pos = '';
    this.personalForm.patchValue({ drpPlaceOfService: '' });

  }



  clearZone() {
    this.zone = '';
    this.personalForm.patchValue({ drpBillingZone: '' });

  }

  clearZones() {
    this.zones = '';
    this.personalForm.patchValue({ drpZone: '' });


  }

}

