<table
datatable
[dtOptions]="dtProductTableOptions"
class="row-border hover w-100 display"
>
<thead>
  <tr>
    <th  [matTooltip]="'Options'" class="text-center">Options</th>
    <th  [matTooltip]="'Status'" >Status</th>
    <th  [matTooltip]="'Category Name'" >Category Name</th>
    <th  [matTooltip]="'Machine Points'">Machine Points</th>
    <th  [matTooltip]="'Point Multiplier'" class="text-right">Point Multiplier</th>
    <th  [matTooltip]="'Default Points'" class="text-right">Default Points</th>
    <th  [matTooltip]="'CashRX Required'" class="text-right">CashRX Required</th>
    <th  [matTooltip]="'Serial Lot No Required'" class="text-right">Serial Lot No Required</th>
    <th  [matTooltip]="'PhysicalInv WeekOfMonth'" class="text-right">PhysicalInv WeekOfMonth</th>
    <th  [matTooltip]="'PhysicalInv DayOfWeek'" class="text-right">PhysicalInv DayOfWeek</th>
    <th  [matTooltip]="'Low'" class="text-right">Low</th>
    <th  [matTooltip]="'High'" class="text-right">High</th>
    <th  [matTooltip]="'Mid'" class="text-right">Mid</th>
    <th [matTooltip]="'CreatedBy'">Created By</th>
    <th  [matTooltip]="'Created Date'">Created Date</th>
    <th  [matTooltip]="'ModifiedBy'">Modified By</th>
    <th  [matTooltip]="'Modified Date'">Modified Date</th>
    <!-- <th class="text-center" matColumnDef="createdDate">Accessories</th> -->

    <!-- <th class="text-center" matColumnDef="createdDate">Created Date</th> -->
    <!-- <th class="text-center" matColumnDef="status">Status</th> -->
  </tr>
</thead>
<tbody>
  <tr *ngFor="let data of categoryTableData">
    <td  class="text-center">
      <a
        *ngIf="data?.status === 'Active'"
        class="editBtnColor mr-2"
        (click)="getInsAllowablesById(data.id)"
      >
      <!-- (click)="triggerToggle()" -->
        <i
          class="fa fa-pencil pointer"
          matTooltip="Click To Edit"
        ></i>
      </a>
      <!-- <a class="deleteBtnColor" (click)="deleteCategory(data.id)">
        <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
      </a> -->
    </td>
    <td [matTooltip]="data?.status">
        <ng-container *ngIf="data?.status === 'Active'">
            <mat-slide-toggle
            (change)="statusToggleTrigger($event, data)"
              [checked]="true"
              class="ml-1"
            >
              {{ data?.status }}</mat-slide-toggle
            >
          </ng-container>
          <ng-container *ngIf="data?.status === 'Inactive'">
            <mat-slide-toggle
            (change)="statusToggleTrigger($event, data)"
              [checked]="false"
              class="ml-1"
            >
              {{ data?.status }}</mat-slide-toggle
            >
          </ng-container>
    </td>
    <td [matTooltip]="data?.productCategoryName || '-'" >{{ data.productCategoryName || '-' }}</td>
    <td [matTooltip]="data?.machinePoints || '-'" class="text-right">{{ data.machinePoints || '-' }}</td>
    <td [matTooltip]="data?.pointMultiplier || '-'" class="text-right" >{{ data.pointMultiplier || '-' }}</td>
    <td [matTooltip]="data?.defaultPoints || '-'" class="text-right" >{{ data.defaultPoints || '-' }}</td>
    <td [matTooltip]="data?.cashRxRequired || '-'" class="text-right" >{{ data.cashRxRequired || '-' }}</td>
    <td [matTooltip]="data?.serialLotNoRequired || '-'" class="text-right">{{ data.serialLotNoRequired || '-' }}</td>
    <td [matTooltip]="data?.physicalInvWeekOfMonth || '-'" class="text-right" >{{ data.physicalInvWeekOfMonth || '-' }}</td>
    <td [matTooltip]="data?.physicalInvDayOfWeek || '-'" class="text-right">{{ data.physicalInvDayOfWeek || '-' }}</td>
    <td [matTooltip]="data?.low || '-'" class="text-right">{{ data.low || '-' }}</td>
    <td [matTooltip]="data?.high || '-'" class="text-right">{{ data.high || '-' }}</td>
    <td [matTooltip]="data?.mid || '-'" class="text-right">{{ data.mid || '-' }}</td>
    <td [matTooltip]="data?.createdBy || ''">{{data.createdBy || '-'}}</td>
    
    <td class="text-center">
      <span [matTooltip]="data?.creationTime">{{
        data?.creationTime }}</span>
    </td>

    <td [matTooltip]="data.lastModifyBy ? data.lastModifyBy : data.createdBy || '-'">
      {{ data.lastModifyBy ? data.lastModifyBy : data.createdBy || '-' }}
    </td>

    <td class="text-center">
      <span [matTooltip]="data?.lastModificationTime">{{
        data?.lastModificationTime }}</span>
    </td>

  </tr>
</tbody>
<tfoot>
    <tr>
      <th><input
        matInput
        hidden
        class="textbox-border-class"
        type="text"
        placeholder="Category Name"
      /></th>
      <th>
        <input
          matInput
          hidden
          class="textbox-border-class"
          type="text"
          placeholder="Category Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Category Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Machine Points"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Point Multiplier"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder=" Default Points"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="CashRX Required"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Serial Lot No Required"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="PhysicalInv WeekOfMonth"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="PhysicalInv DayOfWeek "
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Low"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="High"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Mid"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class "
          type="text"
          placeholder="Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class "
          type="text"
          placeholder="Modified Date"
        />
      </th>
    </tr>
  </tfoot>
</table>
