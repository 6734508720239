import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { crudPremission } from 'projects/shared/src/app/shared-table/shared-table.component';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddReminderTabComponent } from '../add-reminder-tab/add-reminder-tab.component';
import { ReminderDetailDTO } from '../order-proxy/order-management/order-optimization/dto';
import { ReminderDetailService } from '../order-proxy/order-management/order-optimization/reminder-detail.service';
import { CookieService } from 'ngx-cookie-service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-reminder-tab',
  templateUrl: './reminder-tab.component.html',
  styleUrls: ['./reminder-tab.component.scss']
})
export class ReminderTabComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  @Input()  isTemplate: boolean = false;

  strPageType: string = "reminder";
  editFormValues: boolean = false;
  isNewPatient: any;
  subscription$: Subscription[] = [];
  arrDisplayedColumns: string[] = [ "reminderSubject", "reminderDate", "createdDate", "options"];
  @Input() orderId: string = defaultGuid;
  @Input() patientId: string = defaultGuid;
  crudPermisson: crudPremission = { create: true, read: true, update: true, delete: true };
  constructor(
    public matDialog: MatDialog,
    public title: Title,
    private reminderDetailService: ReminderDetailService,
    private sharedTableService: SharedTableService,
    private cookieService: CookieService
  ) { }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Reminder");

    this.sharedTableService.setTableData([]);
    this.getReminderTableData();
    this.isNewPatient =this.cookieService.get('orderStatus');
    if(this.isNewPatient == 'true'){
      this.editFormValues = true
    } else{
      this.editFormValues = false
    }
  }
  editForm(){
    this.editFormValues = true
  }
  getReminderTableData() {
    this.isLoading = true;
    const getReminderTable = this.reminderDetailService.getRemindersByOrderIdByID(this.orderId).subscribe(response => {
      this.isLoading = false;
      this.sharedTableService.setTableData(response);
    }, err => {
      this.isLoading = false;
      this.sharedTableService.setTableData([]);
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getReminderTable);
  }
  deleteClick(value: ReminderDetailDTO) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',

    }).then(result => {
      if (result.value) {
        const deleteReminder = this.reminderDetailService.delete(value?.id).subscribe(response => {
          this.getReminderTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteReminder);
      }
    });
  }
  viewClick(value: ReminderDetailDTO) {
    const config: MatDialogConfig = {
      disableClose: true, data: {
        patientId: this.patientId??defaultGuid,
        orderId: this.orderId??defaultGuid,
        reminderId:value?.id??defaultGuid
      }
    }
    const dialogRef = this.matDialog.open(AddReminderTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getReminderTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }
}
