import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';
import type { JsonResult } from '../microsoft/asp-net-core/mvc/models';
import type { PatientDocumentBlobDTO, SavePatientDocumentBlobDTO, UpdateDocumentBlobDTO } from '../patient/dto/models';
import { sortedDocumentDetailsDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/sorting/dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  apiName = 'Default';

  deleteFileByPatientIdAndPatientDocumentId = (patientId: string, patientDocumentId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'DELETE',
      url: '/api/app/patientDocument/deleteFile',
      params: { patientId, patientDocumentId },
    },
     { apiName: this.apiName, skipHandleError: true });

  editFileByPatientDocumentBlobDTOAndPatientObjectID = (patientDocumentBlobDTO: UpdateDocumentBlobDTO, patientObjectID: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'PUT',
      url: '/api/app/patientDocument/EditFile',
      params: { fileName: patientDocumentBlobDTO.fileName, tags: patientDocumentBlobDTO.tags, documentTypeId: patientDocumentBlobDTO.documentTypeId, reviewReason: patientDocumentBlobDTO.reviewReason, reviewStatus: patientDocumentBlobDTO.reviewStatus, documentStartDate: patientDocumentBlobDTO.documentStartDate, documentEndDate: patientDocumentBlobDTO.documentEndDate, note: patientDocumentBlobDTO.note, patientDocumentId: patientDocumentBlobDTO.patientDocumentId, documentUploadType: patientDocumentBlobDTO.documentUploadType, parentPatientDocumentID: patientDocumentBlobDTO.parentPatientDocumentID, documentOrderNumber: patientDocumentBlobDTO.documentOrderNumber, isVerified: patientDocumentBlobDTO.isVerified, documentType: patientDocumentBlobDTO.documentType, approvalStatus: patientDocumentBlobDTO.approvalStatus, approvedByWhom: patientDocumentBlobDTO.approvedByWhom, approvedOn: patientDocumentBlobDTO.approvedOn, fileSize: patientDocumentBlobDTO.fileSize, isSorted: patientDocumentBlobDTO.isSorted, patientObjectID },
    },
     { apiName: this.apiName, skipHandleError: true });

  getPatientDocumentByBlobIDByBlobName = (blobName: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      url: '/api/app/patientDocument/download',
      params: { blobName },
    },
     { apiName: this.apiName, skipHandleError: true });

  mergeFilesByPatientIdAndParentPatientDocumentId = (patientId: string, parentPatientDocumentId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO[]>({
      method: 'POST',
      url: '/api/app/patientDocument/mergeFiles',
      params: { patientId, parentPatientDocumentId },
    },
     { apiName: this.apiName, skipHandleError: true });

  processFileUploadByPatientDocumentBlobDTOAndFileContentAsFileAndPatientObjectID = (patientDocumentBlobDTO: SavePatientDocumentBlobDTO, fileContentAsFile: IFormFile, patientObjectID: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'POST',
      url: '/api/app/patientDocument/processFileUpload',
      params: { patientObjectID },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateFileByLstPatientDocumentBlobDTOAndPatientObjectID = (lstPatientDocumentBlobDTO: UpdateDocumentBlobDTO[], patientObjectID: string) =>
    this.restService.request<any, PatientDocumentBlobDTO[]>({
      method: 'PUT',
      url: '/api/app/patientDocument/UpdateFileDetails',
      params: { patientObjectID },
      body: lstPatientDocumentBlobDTO,
    },
     { apiName: this.apiName, skipHandleError: true });

  fileUploadByPatientDocumentBlobDTOAndPatientObjectID = (patientDocumentBlobDTO: SavePatientDocumentBlobDTO, patientObjectID: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'POST',
      url: '/api/app/patientDocument/fileUpload',
      params: { patientObjectID },
      body: patientDocumentBlobDTO,
    },
     { apiName: this.apiName, skipHandleError: true });

  getFileAsByte64StringByInputFile = (inputFile: IFormFile) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/patientDocument/getFileAsByte64String',
    },
     { apiName: this.apiName, skipHandleError: true });

     saveSortedDocumentDetails = (documentSortingInput: sortedDocumentDetailsDTO) =>
     this.restService.request<any, any>({
       method: 'POST',
       responseType: 'text',
       url: `/api/app/patient-document/checklistwith-sorting-update`,
       body:documentSortingInput,
     },
     { apiName: this.apiName });

     completeSortingByGInboundId = (gInboundId: string) =>
     this.restService.request<any, string>({
       method: 'POST',
       responseType: 'text',
       url: `/api/app/patient-document/complete-sorting/${gInboundId}`,
     },
     { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
