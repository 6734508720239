import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { clsInvClaimsDTO, clsInvPatientsDTO, clsInvoiceDetailsDTO, clsInvoiceReportDetailsDTO, clsInvoiceReportSearch, clsInvoiceStatusDTO, clsSearch } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class InvoiceReportService {
  apiName = 'billingManagement';

  drpInvoiceStatus = () =>
    this.restService.request<any, PagedResultDto<clsInvoiceStatusDTO>>({
      method: 'POST',
      url: '/api/billingApp/invoice-report/drp-invoice-status',
    },
    { apiName: this.apiName });

    drpSearchInvoiceNumberBySearch = (search: clsSearch) =>
      this.restService.request<any, PagedResultDto<clsInvClaimsDTO>>({
        method: 'POST',
        url: '/api/billingApp/invoice-report/drp-search-invoice-number',
        body: search,
      },
      { apiName: this.apiName });

    drpSearchPatientChartBySearch = (search: clsSearch) =>
      this.restService.request<any, PagedResultDto<clsInvPatientsDTO>>({
        method: 'POST',
        url: '/api/billingApp/invoice-report/drp-search-patient-chart',
        body: search,
      },
      { apiName: this.apiName });

  getInvoiceReportDetailsBySearch = (search: clsInvoiceReportSearch) =>
    this.restService.request<any, PagedResultDto<clsInvoiceReportDetailsDTO>>({
      method: 'POST',
      url: '/api/billingApp/invoice-report/get-invoice-report-details',
      body: search,
    },
    { apiName: this.apiName });

  importInvoiceStatus = () =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/billingApp/invoice-report/import-invoice-status',
    },
    { apiName: this.apiName });

  importInvoiceStatusMapper = () =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/billingApp/invoice-report/import-invoice-status-mapper',
    },
    { apiName: this.apiName });

  getInvoiceDetailByIdByGInvoiceIdAndGInvoiceStatusId = (gInvoiceId: string, gInvoiceStatusId: string,gRentalOrderId:string) =>
    this.restService.request<any, clsInvoiceDetailsDTO>({
      method: 'GET',
      url: '/api/billingApp/invoice-report/get-invoice-detail-by-id',
      params: { gInvoiceId, gInvoiceStatusId ,gRentalOrderId},
    },
    { apiName: this.apiName });

  getInvoiceDetailByIdByGOrderIdAndGRentalOrderIdAndGClaimId = (gOrderId: string, gRentalOrderId: string, gClaimId: string) =>
    this.restService.request<any, clsInvoiceDetailsDTO>({
      method: 'GET',
      url: '/api/billingApp/invoice-report/get-invoice-detail-by-id',
      params: { gOrderId, gRentalOrderId, gClaimId },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
