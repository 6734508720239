import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

import type { CreateUpdatePayerDTO, PayerDTO } from './dto/models';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
@Injectable({
  providedIn: 'root',
})
export class PayerService {
  apiName = 'Default';

  create = (input: CreateUpdatePayerDTO) =>
    this.restService.request<any, PayerDTO>({
      method: 'POST',
      url: '/api/app/payer',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/payer/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, PayerDTO>({
      method: 'GET',
      url: `/api/app/payer/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PayerDTO>>({
      method: 'GET',
      url: '/api/app/payer',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdatePayerDTO) =>
    this.restService.request<any, PayerDTO>({
      method: 'PUT',
      url: `/api/app/payer/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchPayerBySPayerAndSPayerName = (sPayer: string, sPayerName: string) =>
    this.restService.request<any, PagedResultDto<PayerDTO>>({
      method: 'POST',
      url: '/api/app/payer/search-payer',
      params: { sPayer, sPayerName },
    },
     { apiName: this.apiName, skipHandleError: true });
     InsuranceCategoryGetList = () =>
     this.restService.request<any, any>({
       method: 'GET',
       url: '/api/app/insurance-category/get-all-insurance-cat'
     },
      { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
