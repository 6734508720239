import { Injectable } from '@angular/core';
import { CreateUpdateWIPHistoryDTO, WIPHistoryDTO, clsMultipleWIPUpdate } from './dto';
import { RestService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class WIPHistoryService {
  apiName = 'platformManagement';

  create = (CreateUpdateWIPHistoryDTO: CreateUpdateWIPHistoryDTO) =>
    this.restService.request<any, WIPHistoryDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/w-ipHistory',
        body: CreateUpdateWIPHistoryDTO,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/work-in-progress/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, WIPHistoryDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/work-in-progress/${id}`,
      },
      { apiName: this.apiName }
    );
    updateWIPDetails=(wipHistory: clsMultipleWIPUpdate) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/w-iPHistory/update-wIPDetails`,
        body: wipHistory,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
