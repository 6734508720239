import type { ApptStatusDTO, CreateUpdateScheduleDTO, ScheduledDetailsDTO, ScheduleDetailsDTO, ScheduleDTO, scheduleFilterDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateMessageAndMailResponseDTO } from '../smsresponse-history/models';
import { CalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';

@Injectable({
  providedIn: 'root',
})
export class ScheduleDetailsService {
  apiName = 'platformManagement';

  createScheduleByInput = (input: CreateUpdateScheduleDTO) =>
    this.restService.request<any, CalendarDTO>({
      method: 'POST',
      url: '/api/PlatformApp/schedule-details/schedule',
      body: input,
    },
    { apiName: this.apiName });

    getSchedulePatientDetailsByDTO = (DTO: ScheduleDetailsDTO) =>
      this.restService.request<any, ScheduleDTO>({
        method: 'GET',
        url: '/api/PlatformApp/schedule-details/schedule-patient-details',
        params: { docId: DTO.docId, patientID: DTO.patientID },
      },
      { apiName: this.apiName });

    updateScheduleByIdAndInput = (id: string, input: CreateUpdateScheduleDTO) =>
      this.restService.request<any, CalendarDTO>({
        method: 'PUT',
        url: `/api/PlatformApp/schedule-details/${id}/schedule`,
        body: input,
      },
      { apiName: this.apiName });

  deleteScheduleById = (id: string) =>
    this.restService.request<any, CalendarDTO>({
      method: 'DELETE',
      url: `/api/PlatformApp/schedule-details/${id}/delete-schedule`,
    },
    { apiName: this.apiName });

    getAppointmentStatus = () =>
      this.restService.request<any, PagedResultDto<ApptStatusDTO>>({
        method: 'GET',
        url: '/api/PlatformApp/schedule-details/get-appointment-status',
      },
      { apiName: this.apiName });

  // getSchedulesByFilterByDto = (dto: scheduleFilterDTO) =>
  //   this.restService.request<any, PagedResultDto<ScheduledDetailsDTO>>({
  //     method: 'GET',
  //     url: '/api/PlatformApp/schedule-details/get-schedules-by-filter',
  //     params: { startDate: dto.startDate, endDate: dto.endDate, type: dto.type, users: dto.users, location: dto.location, status: dto.status, patientName: dto.patientName, locationName: dto.locationName, chartNo: dto.chartNo, isMonth: dto.isMonth },
  //   },
  //   { apiName: this.apiName });

    getSchedulesByFilterByDto = (dto: scheduleFilterDTO) =>
      this.restService.request<any, PagedResultDto<ScheduledDetailsDTO>>({
        method: 'POST',
        url: '/api/PlatformApp/schedule-details/get-schedules-by-filter',
        body: dto,
      },
      { apiName: this.apiName });

  saveHistoryforMailByInput = (input: CreateUpdateMessageAndMailResponseDTO) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/PlatformApp/schedule-details/save-historyfor-mail',
      body: input,
    },
    { apiName: this.apiName });

  updateQsecureSchedules = () =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/PlatformApp/schedule-details/update-qsecure-schedules',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
