import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-update-unit-cost-and-allowable',
  templateUrl: './bulk-update-unit-cost-and-allowable.component.html',
  styleUrls: ['./bulk-update-unit-cost-and-allowable.component.scss']
})
export class BulkUpdateUnitCostAndAllowableComponent implements OnInit {

  loadTable: boolean = true;
  tableData:any;
  constructor() { }

  ngOnInit(): void {
    this.tableData=[
      {
        "id": 1,
        "product": "Test Unit 1",
        'hcpc': 'A1234',
        "cost": 100,
        "insurance_category": "Test",
        "allowable": 1000,
        "modified_date": new Date()
      },
      {
        "id": 2,
        "product": "Test Unit 2",
        'hcpc': 'A1235',
        "cost": 150,
        "insurance_category": "Test",
        "allowable": 1500,
        "modified_date": new Date()
      },
      {
        "id": 3,
        "product": "Test Unit 3",
        'hcpc': 'A1236',
        "cost": 200,
        "insurance_category": "Test",
        "allowable": 2000,
        "modified_date": new Date()
      }
     ,
    ]
  }
  onAddButtonClick(){
  }

}
