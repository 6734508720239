import type { CreateUpdateDiagnosisCode9DTO, DiagnosisCode9, DiagnosisCode9DTO } from './dto/patient/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisCode9Service {
  apiName = 'DropdownManagement';

  create = (input: CreateUpdateDiagnosisCode9DTO) =>
    this.restService.request<any, DiagnosisCode9DTO>({
      method: 'POST',
      url: '/api/DropdownApp/diagnosis-code9',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/DropdownApp/diagnosis-code9/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, DiagnosisCode9DTO>({
      method: 'GET',
      url: `/api/DropdownApp/diagnosis-code9/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<DiagnosisCode9DTO>>({
      method: 'GET',
      url: '/api/DropdownApp/diagnosis-code9',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateDiagnosisCode9DTO) =>
    this.restService.request<any, DiagnosisCode9DTO>({
      method: 'PUT',
      url: `/api/DropdownApp/diagnosis-code9/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getListdiagnosisCode9 = (sSearch: string, lstICDCode: string[]) =>
    this.restService.request<any, PagedResultDto<DiagnosisCode9>>({
      method: 'POST',
      url: '/api/DropdownApp/diagnosis-code9/get-list-async-v1',
      params: { sSearch },
      body: lstICDCode,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
