<mat-dialog-content class="mat-typography">
  <!-- 1'st Question -->
  <div *ngIf="isDefault">
    <div class="col-lg-12">
      <div class="row align-items-center">
        <div class="col-lg-1 align-left"  [style.pointer-events]="false">
          <mat-icon>arrow_back</mat-icon>
        </div>
        <div class="col-lg-10 align-center">
          <h4 class="customThemeClass">
            <b>{{preQuestionHeaderTitle}}</b>
          </h4>
        </div>
        <div class="col-lg-1 align-right">
          <mat-icon mat-dialog-close>close</mat-icon>
        </div>
      </div>
    </div>
    <div class="card card-body">
      <!-- <div class="row pt-2"> -->
        <form [formGroup]="medicarePreQuestionaireForm">
          <div class="col-lg-12">
            <mat-label>{{preQuestion}}<span class="asterisk">*</span> </mat-label>
            <hr>
            <mat-radio-group formControlName="rdPreQuestionaireList" class="question-option">
              <mat-radio-button *ngFor="let list of preQuestionaireOptionList" [value]="list?.id">
                {{ list?.checklist }}
              </mat-radio-button>
            </mat-radio-group>
            <mat-error class="spanAlignment" *ngIf="
          medicarePreQuestionaireForm?.get('rdPreQuestionaireList')?.touched &&
          medicarePreQuestionaireForm?.get('rdPreQuestionaireList')?.errors?.required
        "> {{preQuestionHeaderTitle}} is a required field! </mat-error>
          </div>
        </form>
      <!-- </div> -->
    </div>
  </div>
  <!-- Next Questions -->
  <div *ngIf="!isDefault">
    <div class="col-lg-12">
      <div class="row align-items-center" > <!-- style="flex-wrap: nowrap;" -->
        <div class="col-lg-1 align-left" style="cursor: pointer;" (click)="goBack()" >
          <mat-icon>arrow_back</mat-icon>
        </div>
        <div class="col-lg-10 align-center">
          <h4 class="customThemeClass">
            <b>{{questionHeaderTitle}}</b>
          </h4>
        </div>
        <div class="col-lg-1 align-right" >
          <mat-icon mat-dialog-close>close</mat-icon>
        </div>
      </div>
    </div>
    <div class="card card-body">
      <!-- <div class="row pt-2"> -->
        <form [formGroup]="medicareQuestionaireForm">
          <div class="col-lg-12">
            <mat-label>{{currrentQuestion}} <span class="asterisk">*</span> </mat-label>
            <hr>
            <mat-radio-group formControlName="rdQuestionaireList" class="question-option">
              <mat-radio-button *ngFor="let list of optionList[0]?.option" [value]="list">
                {{ list }}
              </mat-radio-button>
            </mat-radio-group>
            <mat-label>{{currrentQuestionNotes}}</mat-label>
            <mat-error class="spanAlignment" *ngIf="
            medicareQuestionaireForm?.get('rdQuestionaireList')?.touched &&
            medicareQuestionaireForm?.get('rdQuestionaireList')?.errors?.required
        "> {{questionHeaderTitle}} is a required field! </mat-error>
          </div>
        </form>
      <!-- </div> -->
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- Button Actions -->
  <button mat-button class="buttonColor" [disabled]="!isNextEnable" (click)="goToNext()">
    Next<mat-icon>navigate_next</mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
