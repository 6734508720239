<div class="col-12">
    <div class="d-flex align-item-center justify-content-between m-1">
        <h3 class="font-weight-bolder mb-0"><b>View Delivey Ticket</b></h3>
        <div class=" page-title-box  page-title-right pt-0">
            <ol class="breadcrumbs m-0">
                <li>
                    <button matTooltip="Click To Goback to Pack View" mat-button class="buttonColor font-size-10"
                        (click)="backToPack()">
                        <i class="fa fa-backward mr-1" ></i> Back To List
                    </button>
                </li>
                <li class="ml-2 mt-2 breadcrumb-item">Ready To Delivery Lists</li>
                <li class="mt-2 breadcrumb-item">Delivey Ticket View</li>
            </ol>
        </div>
    </div>
</div>
<!-- <div class="card card-body  ml-3 mr-3"> -->
<div class="row">
    <!-- <div class="col-lg-12">
        <h2>Product Shipping Label</h2>
    </div> -->
    <div class="col-12">
      <div class="container-fluid mt-1">
        <ngx-extended-pdf-viewer  [textLayer]="true" [showHandToolButton]="true"
          [src]="strSelectedPdfPath" [height]="'70vh'" [useBrowserLocale]="false"  [delayFirstView]='1000' [showFindButton]="false">
        </ngx-extended-pdf-viewer>
      </div>
    </div>

</div>
<!-- </div> -->
