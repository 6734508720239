<div class="row mt-1 container-fluid">
    <div class="col-12 d-flex justify-content-between">
        <h3 class="font-weight-bolder">Fax Id : <span class="app-custom-color font-weight-bolder">{{defaultFaxId ||
                '--'}}</span></h3>
        <h3 class="font-weight-bolder">Patient Name : <span class="app-custom-color font-weight-bolder">{{patientName
                ||'--'}}</span></h3>
        <h3 class="font-weight-bolder">Chart No : <span class="app-custom-color font-weight-bolder">{{chartID
                ||'--'}}</span></h3>
    </div>
</div>

<ngx-extended-pdf-viewer [base64Src]="strPdfViewString" [height]="pdfheight" [showSidebarButton]="false" [textLayer]="true" [showPagingButtons]="true" [showOpenFileButton]="false"
    [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false" [showPropertiesButton]="false"
    [showBookmarkButton]="false" [sidebarVisible]="true" [zoom]="'100%'" [enableDragAndDrop]="false"
    [showDownloadButton]="false"
    [showFindButton]="false">
</ngx-extended-pdf-viewer>


<app-loader-component *ngIf="isPdfViewerLoading"></app-loader-component>
