<!-- <div class="container-fluid"> -->
<div class="row bg">
  <div class="col-12">
    <div class="d-flex align-item-center justify-content-between">
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">Inventory Management / Category</b>
      </h3>
      <div class="page-title-box page-title-right mt-1 mb-0">
        <ol class="breadcrumbs m-0"></ol>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 mt-2">
    <!-- <div class="card card-body"> -->
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="step"
        (afterExpand)="toggle()"
        (closed)="toggleV2()"
      >
        <mat-expansion-panel-header>
          <div *ngIf="step == true" class="mr-3"></div>
          <mat-panel-title class="customThemeClass">
            <ng-container *ngIf="categoryId === ''; else elseTitleTemplate">
              <b class="pt-2 header-text"
                >Add Product Category</b
              > </ng-container
            ><br /><br />
            <ng-template #elseTitleTemplate>
              <b class="header-text">Edit Product Category</b>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card-content>
          <form [formGroup]="productCategoryForm">
            <div class="col-12 px-0">
              <!-- Category Name -->
              <mat-form-field class="col-4 dd-text">
                <mat-label
                  >Product Category Name<span class="asterisk"
                    >*</span
                  ></mat-label
                >
                <input
                  autocomplete="new-txtProductCategoryName"
                  matInput
                  maxlength="50"
                  formControlName="txtProductCategoryName"
                  placeholder="Enter Product Category Name"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    productCategoryForm?.get('txtProductCategoryName')
                      ?.touched &&
                    productCategoryForm?.get('txtProductCategoryName')?.errors
                      ?.required
                  "
                >
                  Product Category Name is a required field!
                </mat-error>
              </mat-form-field>

              <!-- Machine Points -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Machine Points</mat-label>
                <input
                  autocomplete="new-txtMachinePoints"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtMachinePoints"
                  placeholder="Enter Machine Points"
                  type="text"
                />
              </mat-form-field>

              <!-- Point Multiplier -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Point Multiplier</mat-label>
                <input
                  autocomplete="new-txtPointMultiplier"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtPointMultiplier"
                  placeholder="Enter Point Multiplier"
                  type="text"
                />
              </mat-form-field>
              <!-- Default Points -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Default Points </mat-label>
                <input
                  autocomplete="new-txtDefaultPoints"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtDefaultPoints"
                  placeholder="Enter Default Points"
                  type="text"
                />
              </mat-form-field>

              <!-- PhysicalInv WeekofMonth-->
              <mat-form-field class="col-4 dd-text">
                <mat-label>PhysicalInv WeekOfMonth </mat-label>
                <input
                  autocomplete="new-txtProductCategoryName"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtPhysicalInv_WeekOfMonth"
                  placeholder="Enter PhysicalInv WeekOfMonth"
                  type="text"
                />
              </mat-form-field>

              <!-- PhysicalInv DayOfWeek -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>PhysicalInv DayOfWeek</mat-label>
                <input
                  autocomplete="new-txtPhysicalInv_DayOfWeek"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtPhysicalInv_DayOfWeek"
                  placeholder="Enter PhysicalInv DayOfWeek"
                  type="text"
                />
              </mat-form-field>
              <!-- Low -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Low </mat-label>
                <input
                  autocomplete="new-txtLow"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtLow"
                  placeholder="Enter Low"
                  type="text"
                />
              </mat-form-field>
              <!--High -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>High </mat-label>
                <input
                  autocomplete="new-txtHigh"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtHigh"
                  placeholder="Enter High"
                  type="text"
                />
              </mat-form-field>

              <!-- MID -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Mid </mat-label>
                <input
                  autocomplete="new-txtMid"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="6"
                  formControlName="txtMid"
                  placeholder="Enter Mid"
                  type="text"
                />
              </mat-form-field>

              <!-- Accessories -->
              <mat-checkbox
                class="col-2 dd-text"
                [(ngModel)]="isChecked"
                [ngClass]="{ checked: isChecked, unchecked: !isChecked }"
                formControlName="chkAccessories"
                >Accessories
              </mat-checkbox>

              <!-- Show Settings -->
              <mat-checkbox
                class="col-2 dd-text"
                [(ngModel)]="isShowSettingsChecked"
                [ngClass]="{
                  checked: isShowSettingsChecked,
                  unchecked: !isShowSettingsChecked
                }"
                formControlName="chkShowSettings"
                >Show Settings
              </mat-checkbox>

              <!-- Show Min Pressure -->
              <mat-checkbox
                class="col-2 dd-text"
                [(ngModel)]="isShowMinPressureChecked"
                [ngClass]="{
                  checked: isShowMinPressureChecked,
                  unchecked: !isShowMinPressureChecked
                }"
                formControlName="chkShowMinPressure"
                >Show Min Pressure
              </mat-checkbox>

              <!-- Show Max Pressure -->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowMaxPressure"
                >Show Max Pressure
              </mat-checkbox>

              <!-- Show Setting Time -->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowSettlingTime"
                >Show Setting Time
              </mat-checkbox>

              <!-- Show IPAP-->
              <mat-checkbox class="col-2 dd-text" formControlName="chkShowIPAP"
                >Show IPAP
              </mat-checkbox>

              <!-- Show EPAP-->
              <mat-checkbox class="col-2 dd-text" formControlName="chkShowEPAP"
                >Show EPAP
              </mat-checkbox>

              <!-- Show Ramp Pressure -->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowRampPressure"
                >Show Ramp Pressure
              </mat-checkbox>

              <!-- Show Ramp Time -->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowRampTime"
                >Show Ramp Time
              </mat-checkbox>

              <!-- Show Bi Flex-->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowBiFlex"
                >Show Bi Flex
              </mat-checkbox>

              <!-- Show Set Pressure-->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowSetPressure"
                >Show Set Pressure
              </mat-checkbox>

              <!-- Show RRBPM-->
              <mat-checkbox class="col-2 dd-text" formControlName="chkShowRRBPM"
                >Show RRBPM
              </mat-checkbox>

              <!-- Show CFlex-->
              <mat-checkbox class="col-2 dd-text" formControlName="chkShowCFlex"
                >Show CFlex
              </mat-checkbox>

              <!-- Show IPAP min -->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowIPAPminM"
                >Show IPAP min
              </mat-checkbox>

              <!-- Show IPAP Max-->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkShowIPAPmax"
                >Show IPAP max
              </mat-checkbox>

              <!-- Show EEP -->
              <mat-checkbox class="col-2 dd-text" formControlName="chkShowEEP"
                >Show EEP
              </mat-checkbox>

              <!-- Show SetVT-->
              <mat-checkbox class="col-2 dd-text" formControlName="chkShowSetVT"
                >Show SetVT
              </mat-checkbox>

              <!-- Show Rate -->
              <mat-checkbox class="col-2 dd-text" formControlName="chkShowRate"
                >Show Rate
              </mat-checkbox>

              <!-- CashRX Required -->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="cashRxRequired"
                >CashRX Required
              </mat-checkbox>

              <!-- Serial Lot No Required -->
              <mat-checkbox
                class="col-2 dd-text"
                formControlName="chkSerialLotNo"
                >Serial Lot No Required
              </mat-checkbox>
            </div>
          </form>
          <!-- Buttons -->
          <div class="col-12 mt-2 text-center">
            <button
              mat-button
              class="buttonColor"
              (click)="saveProductCategory()"
              [disabled]="
                !(
                  !productCategoryForm?.invalid && productCategoryForm?.dirty
                ) ||
                saveButtonDisabled ||
                saveButtonHide
              "
            >
              Save
            </button>
            <button
              mat-button
              (click)="resetForm()"
              class="resetclr buttonColor"
            >
              Reset
            </button>
          </div>
        </mat-card-content>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- </div> -->
  </div>
  <br />
</div>

<div class="row mt-3">
  <div class="col-12 mb-4">
    <div class="card">
      <div class="card-header header-text">Product Category List</div>
      <div class="card-body p-1">
        <div class="d-flex justify-content-center" *ngIf="!loadTable">
          <!-- <mat-spinner></mat-spinner> -->
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div *ngIf="loadTable" class="table table-responsive">
          <!-- Table Start -->
          <app-product-category-table-list
            [categoryTableData]="categoryTableData"
          ></app-product-category-table-list>
        </div>
        <!-- Table End -->
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
