import type { tenantDetails } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BlobDTO, CreateTenantOfficialInformationDTO, TenantNameEmailValidationDto, TenantOfficialInformationDTO, UpdateTenantOfficialInformationDTO } from '../tenants/models';
import { ActiveStatus } from '../localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  apiName = 'platformManagement';

  getLogoByBlobNameByBlobDetails = (blobDetails: BlobDTO) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/PlatformApp/tenants/logo-by-blob-name',
      params: { tenantId: blobDetails.tenantId, blobName: blobDetails.blobName },
    },
      { apiName: this.apiName, skipHandleError: true });
  checkEmailIfExistsByInput = (input: TenantNameEmailValidationDto) =>
    this.restService.request<any, TenantNameEmailValidationDto>({
      method: 'POST',
      url: '/api/PlatformApp/tenants/check-email-if-exists',
      body: input,
    },
      { apiName: this.apiName });
      checkTaxIdIfExistsByInput= (taxId:string,tenantId:string) =>
      this.restService.request<any, any>({
        method: 'POST',
        url: '/api/PlatformApp/tenants/validate-tax-id',
        params: {sTaxId:taxId,gTenantId:tenantId},
      },
      { apiName: this.apiName });
      checkChartSuffixExistsByInput= (sChartId:string,gBranchId:string) =>
      this.restService.request<any, any>({
        method: 'POST',
        url: '/api/PlatformApp/organization-unit/validate-chart-suffix',
        params: {sChartId:sChartId,gBranchId:gBranchId},
      },
      { apiName: this.apiName });

  checkTenantNameExistsByInput = (input: TenantNameEmailValidationDto) =>
    this.restService.request<any, TenantNameEmailValidationDto>({
      method: 'POST',
      url: '/api/PlatformApp/tenants/check-tenant-name-exists',
      body: input,
    },
      { apiName: this.apiName });

  updateTenantToActive = (tenantId: string) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: `/api/PlatformApp/tenants/tenant-to-active/${tenantId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  deleteTenantInformation = (tenantId: string, sReasonForDeactive: string) =>
    this.restService.request<any, TenantOfficialInformationDTO>({
      method: 'DELETE',
      url: `/api/PlatformApp/tenants/delete-tenant-information/${tenantId}`,
      params: { sReasonForDeactive },
    },
      { apiName: this.apiName, skipHandleError: true });

  getTenantInformationById = (tenantId: string) =>
    this.restService.request<any, TenantOfficialInformationDTO>({
      method: 'GET',
      url: `/api/PlatformApp/tenants/get-tenant-information-by-id/${tenantId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getTenantListWithOfficialInformation = (statusFilter: ActiveStatus) =>
    this.restService.request<any, TenantOfficialInformationDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/tenants/get-tenant-list-with-official-information',
      params: { statusFilter },
    },
      { apiName: this.apiName, skipHandleError: true });

      saveTenantWithOfficialInformation = (tenant: CreateTenantOfficialInformationDTO) =>
      this.restService.request<any, any>({
        method: 'POST',
        responseType: 'text',
        url: '/api/PlatformApp/tenants/save-tenant-with-official-information',
        body: tenant,
      },
      { apiName: this.apiName });



  searchClientBySSearch = (sSearch: string) =>
    this.restService.request<any, tenantDetails>({
      method: 'POST',
      url: '/api/PlatformApp/tenants/search-client',
      params: { sSearch },
    },
      { apiName: this.apiName, skipHandleError: true });

  updateTenantWithOfficialInformation = (tenantId: string, userId: string, tenant: UpdateTenantOfficialInformationDTO) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/PlatformApp/tenants/update-tenant-with-official-information',
      params: { tenantId, userId },
      body: tenant,
    },
      { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) { }
}
