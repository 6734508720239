<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">List / Claim Dept / Patient Claims</b>
      </h3>
    </div>
  </div>

  <!-- Page Content-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body p-2">
          <form [formGroup]="patientForm">
            <p class="header-text col-12">Patient Details</p>
            <!-- Patient Name -->
            <mat-form-field class="col-3">
              <mat-label> First Name </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtPatientName"
                type="text"
                autocomplete="new-txtPatientName"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="
                  patientForm?.get('txtPatientName')?.touched &&
                  patientForm?.get('txtPatientName')?.errors?.required
                "
              >
                First Name is a required field!
              </mat-error>
            </mat-form-field>

            <!-- Chart Id -->
            <mat-form-field class="col-3">
              <mat-label> Chart Id </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtChartId"
                type="text"
                autocomplete="new-txtChartId"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="
                  patientForm?.get('txtChartId')?.touched &&
                  patientForm?.get('txtChartId')?.errors?.required
                "
              >
                First Name is a required field!
              </mat-error>
            </mat-form-field>

            <!-- Zip Code -->
            <mat-form-field class="col-3">
              <mat-label> Zip Code </mat-label>
              <input
                matInput
                minlength="5"
                maxlength="10"
                formControlName="txtPostalCode"
                type="text"
              />
              <mat-error
                *ngIf="
                  patientForm?.get('txtPostalCode')?.touched &&
                  patientForm?.get('txtPostalCode')?.errors?.pattern
                "
              >
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <!-- SSN -->
            <mat-form-field class="col-3">
              <mat-label>SSN</mat-label>
              <input
                matInput
                readonly
                formControlName="txtSSN"
                type="text"
                mask="000-00-0000"
                [validation]="false"
                placeHolderCharacter=""
                autocomplete="new-txtSSN"
              />
              <mat-error
                *ngIf="
                  patientForm?.get('txtSSN')?.touched &&
                  patientForm?.get('txtSSN')?.errors
                "
              >
                Enter Minimum 4 Digits!
              </mat-error>
            </mat-form-field>

            <!-- Birth Date -->
            <mat-form-field class="col-3">
              <mat-label> Birth Date</mat-label>
              <input
                autocomplete="new-txtBirthdate"
                formControlName="txtBirthdate"
                matInput
                maxlength="40"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtBirthdate"
              />
              <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtBirthdate> </mat-datepicker>
            </mat-form-field>

            <!-- Plans -->
            <mat-form-field class="col-3">
              <mat-label> Plan </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtPlan"
                type="text"
                autocomplete="new-txtPlan"
              />
            </mat-form-field>

            <!-- Phone -->
            <mat-form-field class="col-3">
              <mat-label> Phone </mat-label>
              <input
                matInput
                maxlength="40"
                formControlName="txtPhoneNo"
                type="text"
                mask="(000)-000-0000"
              />
            </mat-form-field>

            <!-- Policy No -->
            <mat-form-field class="col-3">
              <mat-label> Policy No </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtPolicyNo"
                type="text"
                autocomplete="new-txtPolicyNo"
              />
            </mat-form-field>

            <!-- Guarantor -->
            <mat-form-field class="col-3">
              <mat-label> Guarantor </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtGuarantor"
                type="text"
                autocomplete="new-txtGuarantor"
              />
            </mat-form-field>
            <!-- Employer -->
            <mat-form-field class="col-3">
              <mat-label> Employer </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtEmployer"
                type="text"
                autocomplete="new-txtEmployer"
              />
            </mat-form-field>

            <!-- Relation -->
            <mat-form-field class="col-3">
              <mat-label> Relation </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtRelation"
                type="text"
                autocomplete="new-txtRelation"
              />
            </mat-form-field>

            <p class="header-text col-12">Balances</p>

            <!-- Patient Balance -->
            <mat-form-field class="col-3">
              <mat-label> Patient($) </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtPatientBalance"
                type="text"
                autocomplete="new-txtPatientBalance"
              />
            </mat-form-field>

            <!-- Insurance Balance -->
            <mat-form-field class="col-3">
              <mat-label> Insurance($) </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtInsuranceBalance"
                type="text"
                autocomplete="new-txtInsuranceBalance"
              />
            </mat-form-field>

            <!-- Total Balance -->
            <mat-form-field class="col-3">
              <mat-label> Total($) </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtTotalBalance"
                type="text"
                autocomplete="new-txtTotalBalance"
              />
            </mat-form-field>

            <!-- Plans on File -->
            <mat-form-field class="col-3">
              <mat-label> Plans on File </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtPlansOnFile"
                type="text"
                autocomplete="new-txtPlansOnFile"
              />
            </mat-form-field>

            <!-- Notes -->
            <mat-form-field class="col-6">
              <mat-label>Notes</mat-label>
              <textarea
                matInput
                rows="4"
                readonly
                autocomplete="new-txtComments"
                maxlength="5000"
                formControlName="txtComments"
                type="text"
              ></textarea>
            </mat-form-field>

            <mat-label class="col-2"
              ><b>Patient ID : </b>
              <a
                target="_blank"
                [routerLink]="'/patientCreateEditTab/' + defaultGuid"
                >PT202301506452</a
              ></mat-label
            >

            <mat-checkbox class="col-1" formControlName="chkShowInt">
              <p class="text-wrap mb-0">Show Int</p>
            </mat-checkbox>
            <mat-checkbox class="col-1" formControlName="chkShowPaid">
              <p class="text-wrap mb-0">Show Paid</p>
            </mat-checkbox>

            <button mat-raised-button class="ml-2 buttonColor">
              Reminders
            </button>
          </form>
        </div>
      </div>

      <div class="card my-3">
        <div class="card-body p-2 mb-3">
          <div class="row">
            <div class="col-12 d-flex flex-row-reverse">
              <mat-label class="d-flex justify-content-center mr-4"
                ><span class="dot grt55days-color mr-1"></span> >55Days
              </mat-label>
              <mat-label class="d-flex justify-content-center mr-4"
                ><span class="dot authNo-color mr-1"></span>Auth No</mat-label
              >
              <mat-label class="d-flex justify-content-center mr-4"
                ><span class="dot crossedover-color mr-1"></span
                >Crossed-Over</mat-label
              >

              <mat-label class="d-flex justify-content-center mr-4"
                ><span class="dot processed-color mr-1"></span
                >Processed</mat-label
              >
              <mat-label class="d-flex justify-content-center mr-4"
                ><span class="dot ready-color mr-1"></span>Ready</mat-label
              >

              <mat-label class="d-flex justify-content-center mr-4"
                ><span class="dot hold-color mr-1"></span>Hold</mat-label
              >
            </div>
          </div>

          <mat-divider class="my-2"></mat-divider>
          <app-patient-claim-table-list></app-patient-claim-table-list>
        
        </div>
      </div>
    </div>
  </div>
</div>
