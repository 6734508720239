<mat-dialog-content class="mat-typography" [class.disabled]="true">
  <div class="modal-header p-0">
    <h1 class="modal-title">
      Upload Documents
      <ng-container *ngIf="totalFilesSize != 0"
        >- (Total Size -{{ totalFilesSizeReadable }})</ng-container
      >
    </h1>
    <div class="text-danger">
      <ng-container *ngIf="checkFileSizeExceeded">
        Max File size Exceeded(190 MB)</ng-container
      >
      <button class="close" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <form [formGroup]="documentForm">
    <div class="modal-body">
      <div class="row">
        <div class="col-6 py-1">
          <div class="card card-body file-list-container">
            <!-- Patient Search -->
            <mat-form-field class="col-12 px-0">
              <mat-label>Patient Search</mat-label>
              <mat-select
                formControlName="drpPatient"
                [disabled]="data.isPatientDisable"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    hideClearSearchButton="true"
                    (keyup)="
                      searchPatient(
                        $event.target.value,
                        data.constructorpatientId
                      )
                    "
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtPatientFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let chart of lstPatientChartNos"
                  [value]="chart.patientId"
                >
                  {{ chart.ddValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Fax -->
            <mat-form-field class="col-12 px-0">
              <mat-label>Fax Id / Type / Date / Bin </mat-label>
              <mat-select
                (selectionChange)="updateDocumentTypeDD()"
                formControlName="drpDocument"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search Default Fax Id"
                    formControlName="txtDocumentFilter"
                    noEntriesFoundLabel="No Matches found"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let dD of filteredDefaultDocuments | async"
                  [value]="dD"
                >
                  {{ dD?.faxId || "-" }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Document Type -->
            <mat-form-field class="col-12 px-0">
              <mat-label>
                Document Type <span class="asterisk">*</span>
              </mat-label>
              <mat-select
                [disabled]="disableDocumentType"
                maxlength="40"
                formControlName="drpDocumentType"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search Document Type"
                    formControlName="txtDocumentTypeFilter"
                    noEntriesFoundLabel="No Matches found"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let document of filteredDocumentTypesList | async"
                  [value]="document.docTypeId"
                >
                  {{ document.documentType }}
                </mat-option>
              </mat-select>
              <mat-error
                class="spanAlignment"
                *ngIf="
                  documentForm?.get('drpWIPList')?.touched &&
                  documentForm?.get('drpWIPList')?.errors?.required
                "
              >
                Document Type is a required field!
              </mat-error>
            </mat-form-field>

            <!-- File Upload -->
            <div class="file-upload-container mt-2">
              <button [disabled]="isSaveButtonDisabled" class="file-upload-button cursor">
                <div class="row" style="padding-left: 56px">
                  <div class="brow-align">
                    Browse<span class="asterisk">*</span>
                  </div>
                  <mat-icon class="cursor pt-1">cloud_upload</mat-icon>
                </div>
                <input
                  autocomplete="new-documentFile"
                  id="file-upload"
                  type="file"
                  formControlName="fileUpload"
                  class="cursor"
                  [multiple]="true"
                  (change)="onFileChange($event)"
                  #uploadFileReference
                  accept="application/pdf"
                  (drop)="(false)"
                />
              </button>
              &nbsp;
              <div style="padding-bottom: 17px">
                Select files from your computer
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 py-1">
          <div
            class="card card-body file-list-container pt-1"
            style="height: 350px !important"
          >
            <div class="row">
              <!-- No Files Selected-->
              <div
                *ngIf="!fileDetails || fileDetails?.length === 0"
                class="col-12 pt-5 d-flex align-items-center justify-content-center"
                style="height: 285px !important"
              >
                <img
                  class="img-responsive pr-1 mb-0 cursor"
                  src="assets/images/DashboardIcons/No file.png"
                  alt=""
                  height="27"
                />
                No Files Selected
              </div>

              <!-- Files -->
              <div
                class="col-12 px-1"
                *ngIf="fileDetails && fileDetails?.length !== 0"
              >
                <p class="mb-0 py-1 text-center file-list-header">Files List</p>
                <mat-divider class="mb-1"></mat-divider>
                <div class="scrollable-div py-1">
                  <ng-container *ngFor="let document of fileDetails">
                    <div class="custom-card">
                      <div class="row">
                        <div class="col-lg-10">
                          <div
                            [matTooltip]="document.fileName"
                            class="fileName nowrap_card"
                          >
                            {{ document.fileName }}
                            <span class="size_pagecount"
                              >({{ document.fileSize }} -
                              {{ document.pageCount }} Pages)
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-2 center">
                          <mat-icon
                            (click)="removeFile(document)"
                            matTooltip="Click to remove file"
                            class="deleteFileButton"
                            >delete</mat-icon
                          >
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="row">
                  <div class="col-12" *ngIf="!isRefresh">
                    <div class="meter" [hidden]="!isProcessBarShow">
                      <span class="meterV1" [style.width]="width + '%'"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="col-12 mt-2 text-center">
        <button
          [disabled]="
            isSaveButtonDisabled ||
            documentForm.invalid ||
            checkFileSizeExceeded || 
            !fileDetails || fileDetails?.length === 0
          "
          mat-button
          class="buttonColor mb-2 mr-2"
          mat-raised-button
          (click)="insertDocuments()"
        >
          <span>Save</span>
        </button>
        <button (click)="resetForm()" mat-button class="resetclr mb-2 mr-2">
          Reset
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
