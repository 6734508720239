import { mapEnumToOptions } from '@abp/ng.core';

export enum PatientDropdowns {
  All = 'All',
  LocationTypes = 'LocationTypes',
  Suffixes = 'Suffixes',
  Titles = 'Titles',
  CustomerTypes = 'CustomerTypes',
  BranchOffices = 'BranchOffices',
  AccountGroups = 'AccountGroups',
  SecurityGroups = 'SecurityGroups',
  PlaceOfServices = 'PlaceOfServices',
  TaxZones = 'TaxZones',
  Zones = 'Zones',
  RelationShipWithoutSelf = 'RelationShipWithoutSelf',
  RelationShipWithSelf = 'RelationShipWithSelf',
  Genders = 'Genders',
  FuntionalAbilities = 'FuntionalAbilities',
  Practitioners = 'Practitioners',
  Facilities = 'Facilities',
  RenderingProviders = 'RenderingProviders',
  ReferringProviders = 'ReferringProviders',
  MarketingReferralTypes = 'MarketingReferralTypes',
  MarketingReferralContacts = 'MarketingReferralContacts',
  MarketingRepTypes = 'MarketingRepTypes',
  MaritalStatus = 'MaritalStatus',
  Employments = 'Employments',
  StateOfAutoAccidents = 'StateOfAutoAccidents',
  InsuranceTypes = 'InsuranceTypes',
  InsuranceStatus = 'InsuranceStatus',
  NetworkBenefitTypes = 'NetworkBenefitTypes',
  PartBCoverageTypes = 'PartBCoverageTypes',
  PreAuthTypes = 'PreAuthTypes',
  DoctorGroups = 'DoctorGroups',
  PayorLevels = 'PayorLevels',
  PolicyInsurances = 'PolicyInsurances',
  ClaimFillingCodes = 'ClaimFillingCodes',
  DocumentTypes = 'DocumentTypes',
  ReviewStatus = 'ReviewStatus',
  ReviewReasons = 'ReviewReasons',
  SortingTypes = 'SortingTypes',
  DiagnosisCode9 = 'DiagnosisCode9',
  DiagnosisCode10 = 'DiagnosisCode10',
  AssignedTo = 'AssignedTo',
  NoteStatus = 'NoteStatus',
  NoteReasons = 'NoteReasons',
  NoteSeverities = 'NoteSeverities',
  NoteStates = 'NoteStates',
  NoteTypes = 'NoteTypes',
  ColorCodings = 'ColorCodings',
  Categories = 'Categories',
  Providers = 'Providers',
  DunningMessages = 'DunningMessages',
  HoldReasons = 'HoldReasons',
  Assignments = 'Assignments',
  InsEdits = 'InsEdits',
  PaymentCodes = 'PaymentCodes',
  WriteOffCodes = 'WriteOffCodes',
  AlternateCpts = 'AlternateCpts',
  AlternatePos = 'AlternatePos',
  AlternateTos = 'AlternateTos',
  PrimaryForm = 'PrimaryForm',
  Secondary = 'Secondary',
  ClaimFillingIndicators = 'ClaimFillingIndicators',
  InsuranceGroups = 'InsuranceGroups',
  PlanCategories = 'PlanCategories',
  PaymentTypes = 'PaymentTypes',
  PaymentTypeOptions = 'PaymentTypeOptions',
  PaymentDueDays = 'PaymentDueDays',
  MachineTypes = 'MachineTypes',
  SleepApneaType = 'SleepApneaType',
  Symptoms = 'Symptoms',
  VerificationStatus= 'VerificationStatus',
  IsActiveStatus= 'IsActiveStatus',

}

export const patientDropdownsOptions = mapEnumToOptions(PatientDropdowns);
