<br />
<div class="card card-body">
  <mat-card-content>
    <div class="row">
      <div class="col-6">
        <div class="text-left">
          <h4 class="customThemeClass"><b>Authorization</b></h4>
        </div>
      </div>
      <!-- Commented for Future Use -->
      <!-- <div class="col-6">
        <div class="text-right">
          <button mat-button class="buttonColor font-size-14" (click)="openAuthModal(defaultGuid)">
            <i class="fa fa-plus mr-1"></i>
            Add Authorization
          </button>
        </div>
      </div> -->
      <div class="col-lg-12 table table-responsive">
        <div class="mat-elevation-z4">
          <table mat-table [dataSource]="dataSource" matSort>
            <!--Auth Id -->
            <ng-container matColumnDef="authId">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Auth Id
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.authId }}
              </td>
            </ng-container>
            <!-- Item -->
            <ng-container matColumnDef="item">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Item Code
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.item }}
              </td>
            </ng-container>

            <!--Auth Begin Date-->
            <ng-container matColumnDef="authBeginDate">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Auth Begin Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.authBeginDate | date: "MM/dd/yyyy":"en_US" }}
              </td>
            </ng-container>

            <!--Auth End Date-->
            <ng-container matColumnDef="authEndDate">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Auth End Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.authEndDate | date: "MM/dd/yyyy":"en_US" }}
              </td>
            </ng-container>
            <!-- Days -->
            <ng-container matColumnDef="days">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Days
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.days }}
              </td>
            </ng-container>
            <!-- Auth Status -->
            <ng-container matColumnDef="status">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Auth Status
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.status }}
              </td>
            </ng-container>
            <!-- FollowUp -->
            <ng-container matColumnDef="isFollowUp">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Follow Up
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.isFollowUp === 1 ? "Required" : "Not Required" }}
              </td>
            </ng-container>
            <!-- Amount -->
            <ng-container matColumnDef="amount">
              <th class="header" mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element">
                {{ element?.amount === "" ? "-" : element?.amount }}
              </td>
            </ng-container>

            <!--Type (Sale or Rent)-->
            <ng-container matColumnDef="saleOrRent">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Type
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.saleOrRent === "" ? "-" : element?.saleOrRent }}
              </td>
            </ng-container>
            <!--Units-->
            <ng-container matColumnDef="units">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Units
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.units === "" ? "-" : element?.units }}
              </td>
            </ng-container>
            <!--Used-->
            <ng-container matColumnDef="used">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Used
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.used === "" ? "-" : element?.used }}
              </td>
            </ng-container>
            <!--Plan Name-->
            <ng-container matColumnDef="planName">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Plan Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.planName }}
              </td>
            </ng-container>

            <!--Plan Type-->
            <ng-container matColumnDef="planType">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                Plan Type
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.planType }}
              </td>
            </ng-container>

            <!-- Options -->
            <ng-container matColumnDef="options">
              <th class="header option" mat-header-cell mat-sort-header *matHeaderCellDef>
                Options
              </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <a matTooltip="Click To View Authorization" (click)="openAuthModal(element.authorizationId)">
                  <i class="fa fa-eye pointer editBtnColor pl-3"></i>
                </a>
                <a matTooltip="Click To Delete Authorization" (click)="deleteAuth(element.authorizationId)">
                  <i class="fa fa-trash text-danger pointer pl-3"></i>
                </a>
              </td>
            </ng-container>

            <!--No Records-->
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isShowProgress">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isShowProgress">
                  <mat-spinner [diameter]="150"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <!--Paginator-->
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="14">
                <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                  [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
                active:
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length !== 0
              }"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>



          
        </div>
      </div>

    </div>
  </mat-card-content>
</div>