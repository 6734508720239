import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import Swal from 'sweetalert2';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-mm-Order-Return-Exchange-Reason1',
  templateUrl: './mm-Order-Return-Exchange-Reason1.component.html',
  styleUrls: ['./mm-Order-Return-Exchange-Reason1.component.scss']
})
export class MmOrderReturnExchangeReason1Component implements OnInit {
  notesForm: FormGroup;
  // productDetails: { description: string, status: boolean }[] = [];
  isEditMode = false;
  editIndex: number;
  isLoading: boolean=false;
  dtOptions: any;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService) {

  }

  productDetails = [
    { sino: 1, Description: 'Lorem Ipsum' },
    { sino: 2, Description: 'Dolor Sit Amet' },
    { sino: 3, Description: 'Consectetur Adipiscing' },
    { sino: 4, Description: 'Sed Do Eiusmod' },
    { sino: 5, Description: 'Tempor Incididunt' },
    { sino: 6, Description: 'Labore Et Dolore' },
    { sino: 7, Description: 'Magna Aliqua' },
    { sino: 8, Description: 'Ut Enim Ad Minim' },
    { sino: 9, Description: 'Veniam Quis Nostrud' },
    { sino: 11, Description: 'Exercitation Ullamco' },
    { sino: 12, Description: 'Exercitation Ullamco' },
    { sino: 13, Description: 'Exercitation Ullamco' },
    { sino: 14, Description: 'Exercitation Ullamco' },
    { sino: 15, Description: 'Exercitation Ullamco' },
  ];

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // Initialize the form in the constructor
    this.notesForm = this.formBuilder.group({
      txtNotes: new FormControl('', [Validators.required,noWhitespaceValidator]),
    });

    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          filename: 'Fax log Info',
        },
      ]
    };
  }

  // Function to check if the form is valid
  isFormValid(): boolean {
    return this.notesForm.valid;
  }

  // Function to handle the form submission
  // tslint:disable-next-line:typedef
  onSubmit() {
    if (this.isEditMode) {
      // Handle edit mode logic
    } else {
      // Handle save logic for new entries
      if (this.isFormValid()) {

        const newEntry = {
          description: this.notesForm.value.txtNotes,
          status: false
        };

        // this.productDetails.push(newEntry);

        // Reset the form after saving
        this.resetForm();

        // Show success SweetAlert
        this.toastr.success('Your data has been Successfully Saved!', 'Success');
        // this.showSuccessAlert('Data Saved!', 'Your data has been successfully saved.');
      }
    }
  }

  // Function to show success SweetAlert
  // tslint:disable-next-line:typedef
  showSuccessAlert(title: string, text: string) {
    Swal.fire({
      icon: 'success',
      title,
      text,
    });
  }

  // Function to reset the form
  // tslint:disable-next-line:typedef
  resetForm() {
    this.notesForm.reset();
  }

  // Function to handle editing an entry
  // tslint:disable-next-line:typedef
  editEntry(index: number) {
    this.isEditMode = true;
    this.editIndex = index;
    // Populate the form with the data of the selected entry

    // this.notesForm.setValue({
    //   txtNotes: this.productDetails[index].description
    // });
  }

  // Function to handle deleting an entry
  // tslint:disable-next-line:typedef
  deleteEntry(index: number) {
    // Use the splice method to remove the entry at the given index
    this.productDetails.splice(index, 1);

    // Show SweetAlert after deleting
    this.showSuccessAlert('Data Deleted!', 'Your data has been successfully deleted.');
  }

}