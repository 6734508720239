import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-call-details-table',
  templateUrl: './call-details-table.component.html',
  styleUrls: ['./call-details-table.component.scss']
})
export class CallDetailsTableComponent implements OnInit {

  @Input() tableData: any;
  calllogTableData:any;
  dtCalllogTableOptions:any = {
    responsive: true,
    language: {
      emptyTable: 'No records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '250px',
    scrollX: true,
    info: false,
    bFilter: false
  };
  constructor(private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.calllogTableData=this.tableData;
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '-';
  }
}
