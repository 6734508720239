import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { OrderMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientAddPolicyComponent } from '../patient-add-policy/patient-add-policy.component';
import { PatientService } from '../patient-proxy/patient';
import { PatientDTO } from '../patient-proxy/patient/dto/models';
import { updatePatient } from '../patient-store/patient.action';
import { PatientVerificationComponent } from '../patient-verification/patient-verification.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-patient-eligibility-history',
  templateUrl: './patient-eligibility-history.component.html',
  styleUrls: ['./patient-eligibility-history.component.scss'],
  providers: [ListService]

})
export class PatientEligibilityHistoryComponent implements OnInit, OnDestroy {
  public strPageType: string = "eligiblityHistory";
  verifyTableData: any[] = [];
  isLoading: boolean = true;
  subscription$: Subscription[] = [];
  constructor(public dialog: MatDialog, private Table: TableService,
    public dialogRef: MatDialogRef<PatientEligibilityHistoryComponent>,
    public dialogReff: MatDialogRef<PatientAddPolicyComponent>,
    private store: Store,
    public list: ListService,
    private toastr: ToastrService,
    private patientService: PatientService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string, insuranceVerificationId?: string }) {

  }

  ngOnInit(): void {
    if (this.data.patientId != "" && this.data.patientId != null && this.data.patientId != undefined) { this.getTableData(this.data.patientId) }
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  getDropdown() {
    // let orderDropdownInput : OrderDropdowns[];
    // orderDropdownInput = [

    // ]
    // this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
    //   let response: OrderMasterDropdownDTO = stateResponse;
    //   // this.drpChart = response.charts;
    //   // this.drpPlan = response.typeOfPlans;
    //   // this.drpVerified = response.verifiedBies;
    //   // this.drpPrepared = response.preparedBies;
    //   // this.drpPayable = response.payablePercents;

    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    // setTimeout(() => {
    //   this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
    //     let patientDetails: PatientDTO;
    //     patientDetails = response.patientState.selectedPatient;
    //     this.drpPolicies = patientDetails?.policies;
    //   });
    // }, 300);
  }
  getTableData(patientId) {
    this.isLoading = true;
    let verifyDetails: PatientDTO;

    // const storeSubscription = this.store.dispatch(new getPatient(patientId)).subscribe(response => {
    const storeSubscription = this.patientService.get(patientId).subscribe(response => {
      verifyDetails = response;
      this.verifyTableData = [];
      const primaryIns = verifyDetails.insuranceVerifications.primaryInsuranceVerifies
      const secondaryIns = verifyDetails.insuranceVerifications.secondaryInsuranceVerifies
      const tertiaryIns = verifyDetails.insuranceVerifications.tertiaryInsuranceVerifies
      if (primaryIns) {
        this.verifyTableData.push({
          patientId: verifyDetails.id,
          address: primaryIns?.address,
          callTrackingNumber: primaryIns?.callTrackingNumber,
          chart: primaryIns?.chart,
          claimAddress: primaryIns?.claimAddress,
          cmnCode: primaryIns?.cmnCode,
          codesRequirePa: primaryIns?.codesRequirePa,
          commentsOrNotes: primaryIns?.commentsOrNotes,
          contact: primaryIns?.contact,
          coverageCriteria: primaryIns?.coverageCriteria,
          dateVerified: primaryIns?.dateVerified,
          dateofBirth: primaryIns?.dateofBirth,
          dedMet: primaryIns?.dedMet,
          dedTotal: primaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: primaryIns?.dmeAnnualLimit,
          dmeCoverage: primaryIns?.dmeCoverage,
          dmeLimitMet: primaryIns?.dmeLimitMet,
          dmeMax: primaryIns?.dmeMax,
          effectivedate: primaryIns?.effectiveDate,
          end: primaryIns?.end,
          fascalYearStart: primaryIns?.fascalYearStart,
          frequency: primaryIns?.frequency,
          group: primaryIns?.groupName,
          hcpcs: primaryIns?.hcpcs,
          insuranceVerificationId: primaryIns?.insuranceVerificationId,
          isDmePartsCovered: primaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: primaryIns?.isDmeReplaceMentUnit,
          isFamily: primaryIns?.isFamily,
          isFamilyDeductible: primaryIns?.isFamilyDeductible,
          isIndividual: primaryIns?.isIndividual,
          isMedicareCmn: primaryIns?.isMedicareCmn,
          isNetwork: primaryIns?.isNetwork,
          isOutOfNetwork: primaryIns?.isOutOfNetwork,
          isPatientHome: primaryIns?.isPatientHome,
          isPercertRequired: primaryIns?.isPercertRequired,
          isReferralRequired: primaryIns?.isReferralRequired,
          lifeTimeMax: primaryIns?.lifeTimeMax,
          lifeTimeMaxMet: primaryIns?.lifeTimeMaxMet,
          name: primaryIns?.name,
          oop: primaryIns?.oop,
          oopMet: primaryIns?.oopMet,
          oopTotal: primaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: primaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpense?.outOfNetwork,
            family: primaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: primaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: primaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: primaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: primaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: primaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: primaryIns?.paRequired,
          payablePercent: {
            individual: primaryIns?.payablePercent?.individual,
            outOfNetwork: primaryIns?.payablePercent?.outOfNetwork,
            family: primaryIns?.payablePercent?.family
          },
          pcp: primaryIns?.pcp,
          phone: primaryIns?.phone,
          plan: primaryIns?.plan,
          planCob: primaryIns?.planCob,
          planDeductible: {
            individual: primaryIns?.planDeductible?.individual,
            outOfNetwork: primaryIns?.planDeductible?.outOfNetwork,
            family: primaryIns?.planDeductible?.family
          },
          planName: primaryIns?.planName,
          planPhone: primaryIns?.planPhone,
          policy: primaryIns?.policy,
          policyHolderDob: primaryIns?.policyHolderDob,
          policyHolderName: primaryIns?.policyHolderName,
          preNotes: primaryIns?.preNotes,
          preparedBy: primaryIns?.preparedBy,
          preparedOn: primaryIns?.preparedOn,
          primaryPayor: primaryIns?.primaryPayor,
          qtyLimits: primaryIns?.qtyLimits,
          rent: primaryIns?.rent,
          requestedBy: primaryIns?.requestedBy,
          sameOrSimilarInfo: primaryIns?.sameOrSimilarInfo,
          spokeWith: primaryIns?.spokeWith,
          typeOfPlan: primaryIns?.typeOfPlan,
          typeOfPolicy: primaryIns?.typeOfPolicy,
          verificationStatus: primaryIns?.verificationStatus,
          verifiedBy: primaryIns?.verifiedBy,
          deo: primaryIns?.deo,
          coInsurance: primaryIns?.coInsurance,

          commentsToTech: primaryIns?.commentsToTech,
          verficationMethod: primaryIns?.verifiedMethod,
          isPredetermination: primaryIns?.isPredetermination,
          isActive: primaryIns?.isActive,
          // newly added
          isBlueCardEligible: primaryIns?.isBlueCardEligible,
          isDirectBill: primaryIns?.isDirectBill,
          isPreAuthNeeded: primaryIns?.isPreAuthNeeded,
          isClaimSubmit: primaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: primaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: primaryIns?.isReferenceNumberAvailable,
          referenceNumber: primaryIns?.referenceNumber,
        })
      }
      if (secondaryIns) {
        this.verifyTableData.push({
          patientId: verifyDetails.id,
          address: secondaryIns?.address,
          callTrackingNumber: secondaryIns?.callTrackingNumber,
          chart: secondaryIns?.chart,
          claimAddress: secondaryIns?.claimAddress,
          cmnCode: secondaryIns?.cmnCode,
          codesRequirePa: secondaryIns?.codesRequirePa,
          commentsOrNotes: secondaryIns?.commentsOrNotes,
          contact: secondaryIns?.contact,
          coverageCriteria: secondaryIns?.coverageCriteria,
          dateVerified: secondaryIns?.dateVerified,
          dateofBirth: secondaryIns?.dateofBirth,
          dedMet: secondaryIns?.dedMet,
          dedTotal: secondaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: secondaryIns?.dmeAnnualLimit,
          dmeCoverage: secondaryIns?.dmeCoverage,
          dmeLimitMet: secondaryIns?.dmeLimitMet,
          dmeMax: secondaryIns?.dmeMax,
          effectivedate: secondaryIns?.effectiveDate,
          end: secondaryIns?.end,
          fascalYearStart: secondaryIns?.fascalYearStart,
          frequency: secondaryIns?.frequency,
          group: secondaryIns?.groupName,
          hcpcs: secondaryIns?.hcpcs,
          insuranceVerificationId: secondaryIns?.insuranceVerificationId,
          isDmePartsCovered: secondaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: secondaryIns?.isDmeReplaceMentUnit,
          isFamily: secondaryIns?.isFamily,
          isFamilyDeductible: secondaryIns?.isFamilyDeductible,
          isIndividual: secondaryIns?.isIndividual,
          isMedicareCmn: secondaryIns?.isMedicareCmn,
          isNetwork: secondaryIns?.isNetwork,
          isOutOfNetwork: secondaryIns?.isOutOfNetwork,
          isPatientHome: secondaryIns?.isPatientHome,
          isPercertRequired: secondaryIns?.isPercertRequired,
          isReferralRequired: secondaryIns?.isReferralRequired,
          lifeTimeMax: secondaryIns?.lifeTimeMax,
          lifeTimeMaxMet: secondaryIns?.lifeTimeMaxMet,
          name: secondaryIns?.name,
          oop: secondaryIns?.oop,
          oopMet: secondaryIns?.oopMet,
          oopTotal: secondaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: secondaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpense?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: secondaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: secondaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: secondaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: secondaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: secondaryIns?.paRequired,
          payablePercent: {
            individual: secondaryIns?.payablePercent?.individual,
            outOfNetwork: secondaryIns?.payablePercent?.outOfNetwork,
            family: secondaryIns?.payablePercent?.family
          },
          pcp: secondaryIns?.pcp,
          phone: secondaryIns?.phone,
          plan: secondaryIns?.plan,
          planCob: secondaryIns?.planCob,
          planDeductible: {
            individual: secondaryIns?.planDeductible?.individual,
            outOfNetwork: secondaryIns?.planDeductible?.outOfNetwork,
            family: secondaryIns?.planDeductible?.family
          },
          planName: secondaryIns?.planName,
          planPhone: secondaryIns?.planPhone,
          policy: secondaryIns?.policy,
          policyHolderDob: secondaryIns?.policyHolderDob,
          policyHolderName: secondaryIns?.policyHolderName,
          preNotes: secondaryIns?.preNotes,
          preparedBy: secondaryIns?.preparedBy,
          preparedOn: secondaryIns?.preparedOn,
          primaryPayor: secondaryIns?.primaryPayor,
          qtyLimits: secondaryIns?.qtyLimits,
          rent: secondaryIns?.rent,
          requestedBy: secondaryIns?.requestedBy,
          sameOrSimilarInfo: secondaryIns?.sameOrSimilarInfo,
          spokeWith: secondaryIns?.spokeWith,
          typeOfPlan: secondaryIns?.typeOfPlan,
          typeOfPolicy: secondaryIns?.typeOfPolicy,
          verificationStatus: secondaryIns?.verificationStatus,
          verifiedBy: secondaryIns?.verifiedBy,
          deo: secondaryIns?.deo,
          coInsurance: secondaryIns?.coInsurance,

          commentsToTech: secondaryIns?.commentsToTech,
          verficationMethod: secondaryIns?.verifiedMethod,
          isPredetermination: secondaryIns?.isPredetermination,
          isActive: secondaryIns?.isActive,
          // newly added
          isBlueCardEligible: secondaryIns?.isBlueCardEligible,
          isDirectBill: secondaryIns?.isDirectBill,
          isPreAuthNeeded: secondaryIns?.isPreAuthNeeded,
          isClaimSubmit: secondaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: secondaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: secondaryIns?.isReferenceNumberAvailable,
          referenceNumber: secondaryIns?.referenceNumber,
        })
      }
      if (tertiaryIns) {
        this.verifyTableData.push({
          patientId: verifyDetails.id,
          address: tertiaryIns?.address,
          callTrackingNumber: tertiaryIns?.callTrackingNumber,
          chart: tertiaryIns?.chart,
          claimAddress: tertiaryIns?.claimAddress,
          cmnCode: tertiaryIns?.cmnCode,
          codesRequirePa: tertiaryIns?.codesRequirePa,
          commentsOrNotes: tertiaryIns?.commentsOrNotes,
          contact: tertiaryIns?.contact,
          coverageCriteria: tertiaryIns?.coverageCriteria,
          dateVerified: tertiaryIns?.dateVerified,
          dateofBirth: tertiaryIns?.dateofBirth,
          dedMet: tertiaryIns?.dedMet,
          dedTotal: tertiaryIns?.dedTotal,
          deductibleMet: {
            individual: tertiaryIns?.deductibleMet?.individual,
            outOfNetwork: tertiaryIns?.deductibleMet?.outOfNetwork,
            family: tertiaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: tertiaryIns?.dmeAnnualLimit,
          dmeCoverage: tertiaryIns?.dmeCoverage,
          dmeLimitMet: tertiaryIns?.dmeLimitMet,
          dmeMax: tertiaryIns?.dmeMax,
          effectivedate: tertiaryIns?.effectiveDate,
          end: tertiaryIns?.end,
          fascalYearStart: tertiaryIns?.fascalYearStart,
          frequency: tertiaryIns?.frequency,
          group: tertiaryIns?.groupName,
          hcpcs: tertiaryIns?.hcpcs,
          insuranceVerificationId: tertiaryIns?.insuranceVerificationId,
          isDmePartsCovered: tertiaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: tertiaryIns?.isDmeReplaceMentUnit,
          isFamily: tertiaryIns?.isFamily,
          isFamilyDeductible: tertiaryIns?.isFamilyDeductible,
          isIndividual: tertiaryIns?.isIndividual,
          isMedicareCmn: tertiaryIns?.isMedicareCmn,
          isNetwork: tertiaryIns?.isNetwork,
          isOutOfNetwork: tertiaryIns?.isOutOfNetwork,
          isPatientHome: tertiaryIns?.isPatientHome,
          isPercertRequired: tertiaryIns?.isPercertRequired,
          isReferralRequired: tertiaryIns?.isReferralRequired,
          lifeTimeMax: tertiaryIns?.lifeTimeMax,
          lifeTimeMaxMet: tertiaryIns?.lifeTimeMaxMet,
          name: tertiaryIns?.name,
          oop: tertiaryIns?.oop,
          oopMet: tertiaryIns?.oopMet,
          oopTotal: tertiaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: tertiaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpense?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: tertiaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: tertiaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: tertiaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: tertiaryIns?.paRequired,
          payablePercent: {
            individual: tertiaryIns?.payablePercent?.individual,
            outOfNetwork: tertiaryIns?.payablePercent?.outOfNetwork,
            family: tertiaryIns?.payablePercent?.family
          },
          pcp: tertiaryIns?.pcp,
          phone: tertiaryIns?.phone,
          plan: tertiaryIns?.plan,
          planCob: tertiaryIns?.planCob,
          planDeductible: {
            individual: tertiaryIns?.planDeductible?.individual,
            outOfNetwork: tertiaryIns?.planDeductible?.outOfNetwork,
            family: tertiaryIns?.planDeductible?.family
          },
          planName: tertiaryIns?.planName,
          planPhone: tertiaryIns?.planPhone,
          policy: tertiaryIns?.policy,
          policyHolderDob: tertiaryIns?.policyHolderDob,
          policyHolderName: tertiaryIns?.policyHolderName,
          preNotes: tertiaryIns?.preNotes,
          preparedBy: tertiaryIns?.preparedBy,
          preparedOn: tertiaryIns?.preparedOn,
          primaryPayor: tertiaryIns?.primaryPayor,
          qtyLimits: tertiaryIns?.qtyLimits,
          rent: tertiaryIns?.rent,
          requestedBy: tertiaryIns?.requestedBy,
          sameOrSimilarInfo: tertiaryIns?.sameOrSimilarInfo,
          spokeWith: tertiaryIns?.spokeWith,
          typeOfPlan: tertiaryIns?.typeOfPlan,
          typeOfPolicy: tertiaryIns?.typeOfPolicy,
          verificationStatus: tertiaryIns?.verificationStatus,
          verifiedBy: tertiaryIns?.verifiedBy,
          deo: tertiaryIns?.deo,
          coInsurance: tertiaryIns?.coInsurance,

          commentsToTech: tertiaryIns?.commentsToTech,
          verficationMethod: tertiaryIns?.verifiedMethod,
          isPredetermination: tertiaryIns?.isPredetermination,
          isActive: tertiaryIns?.isActive,
          // newly added
          isBlueCardEligible: tertiaryIns?.isBlueCardEligible,
          isDirectBill: tertiaryIns?.isDirectBill,
          isPreAuthNeeded: tertiaryIns?.isPreAuthNeeded,
          isClaimSubmit: tertiaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: tertiaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: tertiaryIns?.isReferenceNumberAvailable,
          referenceNumber: tertiaryIns?.referenceNumber,

        })
      }
      this.isLoading = false;
      this.Table.setOutVerifyTable(this.verifyTableData);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.isLoading = false;
    });
    this.subscription$.push(storeSubscription)

  }

  // displayedColumns: string[] = ['sno', 'insuranceVerifyId', 'insuranceVerifyName','policyId', 'policyCoverage','options'];
  ViewNotes(reord?: any) {

    const dialogRef = this.dialog.open(PatientVerificationComponent, {
      data: { patientId: reord.patientId, record: reord }
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      result !== "" && result !== null && result !== undefined && this.dialogReff.close(result);
    });
    this.subscription$.push(closeDialog)

  }

  // ! move to auth
  deleteInviteUsers(event) {

    let verificationData: PatientDTO;
    if ((event.patientId !== "" && event.patientId !== null && event.patientId !== undefined)) {
      const storeSubscription = this.patientService.get(event.patientId).subscribe(response => {
        const selectedPatient: PatientDTO = response;
        if (selectedPatient.authorizations) {
          const savedData = {
            primaryAuthorizations: selectedPatient.authorizations.primaryAuthorizations,
            secondaryAuthorizations: selectedPatient.authorizations.secondaryAuthorizations,
            tertiaryAuthorizations: selectedPatient.authorizations.tertiaryAuthorizations
          }
          if (event.plan === "Primary") {
            savedData.primaryAuthorizations = {
              authorizationId: "00000000-0000-0000-0000-000000000000",
              authId: "",
              authVisits: "",
              authAmount: "",
              startDate: "",
              endDate: "",
              category: "",
              item: [],
              note: "",
              isCompleted: "",
              completedBy: "",
              typeOfPolicy: event.typeOfPlan,
              plan: event.plan,
              planName: event.planName,
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              units: "",
              used: "",
              saleOrRent: "",
              isExpired: 0,
              itemCodes: "",
              isFollowUp: 0,
              expiryStatus: ""
            }
          } else if (event.plan === "Secondary") {
            savedData.secondaryAuthorizations = {
              authorizationId: "00000000-0000-0000-0000-000000000000",
              authId: "",
              authVisits: "",
              authAmount: "",
              startDate: "",
              endDate: "",
              category: "",
              item: [],
              note: "",
              isCompleted: "",
              completedBy: "",
              typeOfPolicy: event.typeOfPlan,
              plan: event.plan,
              planName: event.planName,
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              units: "",
              used: "",
              saleOrRent: "",
              isExpired: 0,
              itemCodes: "",
              isFollowUp: 0,
              expiryStatus: ""
            }
          } else if (event.plan === "Tertiary") {
            savedData.tertiaryAuthorizations = {

              authorizationId: "00000000-0000-0000-0000-000000000000",
              authId: "",
              authVisits: "",
              authAmount: "",
              startDate: "",
              endDate: "",
              category: "",
              item: [],
              note: "",
              isCompleted: "",
              completedBy: "",
              typeOfPolicy: event.typeOfPlan,
              plan: event.plan,
              planName: event.planName,
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              units: "",
              used: "",
              saleOrRent: "",
              isExpired: 0,
              itemCodes: "",
              isFollowUp: 0,
              expiryStatus: ""
            }
          }
          verificationData = {
            personals: selectedPatient.personals,
            addresses: selectedPatient.addresses,
            contacts: selectedPatient.contacts,
            clinicals: selectedPatient.clinicals,
            insurances: selectedPatient.insurances,
            notes: selectedPatient.notes,
            policies: selectedPatient.policies,
            defaultPatientId: selectedPatient.defaultPatientId,
            insuranceVerifications: selectedPatient.insuranceVerifications,
            authorizations: {
              primaryAuthorizations: savedData.primaryAuthorizations,
              secondaryAuthorizations: savedData.secondaryAuthorizations,
              tertiaryAuthorizations: savedData.tertiaryAuthorizations
            },
            patientDocuments: selectedPatient.patientDocuments,
            organizationUnitId: selectedPatient.organizationUnitId,
            isAutoIntake: selectedPatient.isAutoIntake,
            autoIntakeDocumentDetails: selectedPatient.autoIntakeDocumentDetails,
            chartId: selectedPatient.chartId,
            billingDetails: selectedPatient.billingDetails,
            patientLock: selectedPatient.patientLock,
            fileId: selectedPatient?.fileId,
            // isSorted:selectedPatient.isSorted
          }
        }
        this.store.dispatch(new updatePatient(event.patientId, verificationData)).subscribe(response => {


          // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Saved Successfully','Success')

        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(storeSubscription);
    }
  }

  dataSource = new MatTableDataSource();
  eligibilityHistoryForm: FormGroup;

}
