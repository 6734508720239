import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { noteTypeOptions } from '../warehouse-proxy/inventory/dto';

@Component({
  selector: 'app-add-patient-note',
  templateUrl: './add-patient-note.component.html',
  styleUrls: ['./add-patient-note.component.scss']
})
export class AddPatientNoteComponent implements OnInit {
  drpNoteType: { value: string; key: string }[] = [];
  notesForm: FormGroup
  notetype: string;
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.notesForm = this.formBuilder.group({
      drpNoteType: new FormControl('', ),
      txtReason: new FormControl('', [
       
      ]), //this.textValidators.isTextCheck,
    });

    this.drpNoteType = noteTypeOptions?.map((e) => {
      let x = {
        value: e.value,
        key: e.key,
      };
      return x;
    });
  }

}
