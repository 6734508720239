import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subject, Subscription } from 'rxjs';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-patient-par-report-table',
  templateUrl: './patient-par-report-table.component.html',
  styleUrls: ['./patient-par-report-table.component.scss']
})
export class PatientParReportTableComponent implements OnInit {

  @Input() tableData:any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  parreportTableData:any;
  dtPARReportTableOptions:any;
  verifyId:any;
  $subscription: Subscription[] = [];
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() paginationInfo: { startRecord: number, endRecord: number, overallTableCount:number };
  @Output() nevigatePage? = new EventEmitter<number>();
  constructor(
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.tableInitilization();
    this.parreportTableData = this.tableData;
    this.dtPARReportTableOptions.buttons[0].filename = "PAR Report" + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngAfterViewInit(): void {
    if (this.datatableElement2 && this.datatableElement2.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  // refreshDataTable(): void {
  //   this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Clear search input values
  //     $('input', dtInstance.table(0).footer()).val('');
  //     // Redraw the DataTable to load all records
  //     dtInstance.search('').columns().search('').draw();
  //     this.addPageNavigationControls();

  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }

  refreshDataTable(): void { 
    this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();

      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  addPageNavigationControls() {
    let totalPages = this.totalPages;
    let currentPage = this.currentPage;
    let buttonsElement = $('.dt-buttons')[0];
    $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }

    $("<span class=''> Page <span>").appendTo(buttonsElement);
    $("<input maxlength='6' style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);

    //Input Restrictions on pagination text box
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      //Invalid page number
      if ((value==''?currentPage:value) > totalPages || (value==''?currentPage:value) == currentPage) {
        $('#btnGoToPage').addClass('disabled');
      } else if(value!=currentPage && value <= totalPages) {
        $('#btnGoToPage').removeClass('disabled');
      }
      (this as HTMLInputElement).value = value;
    });
    //If the user leaves empty text box patch current page
    $('#txtGotoPage').on('focusout', function (e) {
      let value = (this as HTMLInputElement).value;
      if (value == '') {
        (this as HTMLInputElement).value = currentPage;
      }
    });

    $("<span class=''> of " + this.totalPages + ' <span>').appendTo(buttonsElement);
    $(
      "<button  class='dt-button' id='btnGoToPage' type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages)
    );
  }

  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }

  // Data Table Initialization
  tableInitilization() {
    this.dtPARReportTableOptions = {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      search: {
        smart: false,
      },
      language: {
        // search: '',
        // searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        // info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      columnDefs: [],
      order: [0],
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: '',
          exportOptions: {
            columns: ':visible:not(:first-child) ',
          },
        },
      ],
      // initComplete: function (this: any) {
      //   //Search Input Alignment
      //   const searchInput = document.querySelector(
      //     '.dataTables_wrapper .dataTables_filter'
      //   ) as HTMLElement;
      //   if (searchInput) {
      //     searchInput.style.float = 'left';
      //   }
      //   // // Search Input Design and Icon
      //   const searchFilterInput = document.querySelector(
      //     '.dataTables_filter input'
      //   ) as HTMLInputElement;
      //   if (searchFilterInput) {
      //     const searchContainer = document.createElement('div');
      //     searchContainer.className = 'search-container';
      //     searchFilterInput.style.paddingLeft = '25px';
      //     searchFilterInput.style.fontStyle = 'italic';
      //     searchFilterInput.style.fontSize = '12px';
      //     // Add event listeners for focusin and focusout
      //     searchFilterInput.addEventListener('focus', () => {
      //       searchFilterInput.style.border = '1px solid lightseagreen';
      //       searchFilterInput.style.outline = 'lightseagreen';
      //     });

      //     searchFilterInput.addEventListener('blur', () => {
      //       searchFilterInput.style.border = '';
      //       searchFilterInput.style.outline = '';
      //     });

      //     searchFilterInput.parentNode?.insertBefore(
      //       searchContainer,
      //       searchFilterInput
      //     );
      //     searchContainer.appendChild(searchFilterInput);

      //     const searchIcon = document.createElement('i');
      //     searchIcon.className = 'fa fa-search';
      //     searchIcon.style.position = 'absolute';
      //     searchIcon.style.left = '10px';
      //     searchIcon.style.top = '16px';
      //     searchIcon.style.transform = 'translateY(-50%)';
      //     searchIcon.style.pointerEvents = 'none';
      //     searchIcon.style.color = '#aaa';

      //     searchContainer.appendChild(searchIcon);
      //   }
      // },
    };
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  doprecert(patientId :string) : void {
    const url = '/#currentsupplies/masterverification/' + patientId;
    window.open(url, '_blank');
  }
  viewprecert(data) {
    if (data.primaryVerId === defaultGuid) {
      if (data.secondaryVerId === defaultGuid) {
        this.verifyId = data.secondaryVerId;
      } else {
        this.verifyId = data.tertiaryVerId;
      }
    } else {
      this.verifyId = data.primaryVerId;
    }
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
      data: {
        documentId: data.docId,
        defaultFaxId:data?.defaultFaxId
      },
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = data.patientId;
    componentInstance.verificationId = data.verificationId;
    componentInstance.authorizationId = data.id;
    componentInstance.docId = data.docId;
    componentInstance.faxId = data.faxId;
    componentInstance.isView = true;
    componentInstance.resethidden = true;
    componentInstance.isSupplies = true;
    componentInstance.chartId = data.chartId;
    componentInstance.authStatusName = data.authStatusName;
    componentInstance.savehide = true;

  }

  //Add Notes and Add Call Notes Modal
  openAddNotesPopUpV1(
    event: MouseEvent,
    data: any,
    isCallNote: boolean
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: data.patientId,
        defaultFaxId: data.defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: data.docId,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: true,
        patientNotes: data.patientNotes,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      (patientNotes: any) => {},
      (err) => {}
    );
    this.$subscription.push(closeDialog);
  }
  //View Notes Modal dialog
  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
  //View Patient Ledger Modal dialog
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

    //Copy Text When Double click On Row
    onRowDoubleClick(textToCopy) {
      this.copyTextToClipboard(textToCopy);
      this.showNotification('Text copied: ' + textToCopy);
    }
  
    showNotification(message: string): void {
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: 'end', // Positioning the notification horizontally
        verticalPosition: 'bottom', // Positioning the notification vertically
      });
    }
  
    copyTextToClipboard(text: string): void {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }
}
