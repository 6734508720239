<div class="container-fluid mt-3 card card-body">
  <form [formGroup]="RuleMasterForm">
    <div class="row col-12">
      <div class="example-action-buttons">
        <!-- <button class="mr-1 buttonColor" mat-button (click)="accordion?.closeAll()">
          Collapse All
        </button>
        <button class="buttonColor" mat-button (click)="accordion?.openAll()" (click)="expandAll()">
          Expand All</button>&nbsp; -->
          <button class="buttonColor" mat-button (click)="toggleAccordion()">
            {{ isExpanded ? 'Collapse All' : 'Expand All' }}
          </button>&nbsp;
        <mat-checkbox #select_All class="pt-2 px-1" formControlName="chkAllRules" (change)="selectAll($event)">Select
          All</mat-checkbox>
      </div>
    </div>
    <mat-accordion class="example-headers-align" [multi]='true'>
      <mat-expansion-panel [expanded]="step">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <span class="roundedCircle rule1 mr-2"></span> <b>Rule 001 : OSA Checklist</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion class="example-headers-align" [multi]='true'>
          <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                <b>OSA : New Setup</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule001')" formControlName="chkRule001">
                  Symptoms of sleep apnea is must in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule002')" formControlName="chkRule002">
                  Sleep
                  test recommendation is must in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule003')" formControlName="chkRule003">
                  Doctor
                  Signature in face to face is must
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule004')" formControlName="chkRule004">
                  Diagnostic Sleep study date is must after the Face to Face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule005')" formControlName="chkRule005">
                  Total
                  sleep time more than 120
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule006')" formControlName="chkRule006">
                  AHI value should be greater than or equal to 5
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule007')" formControlName="chkRule007">
                  Doctor Signature in diagnostic sleep study
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule008')" formControlName="chkRule008">
                  CPAP tried and fail must in titration sleep study for BIPAP product </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule009')" formControlName="chkRule009">
                  Respiratory breakdowns is must
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule010')" formControlName="chkRule010">
                  Doctor signature in titration study is must
                </mat-checkbox>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule011')" formControlName="chkRule011">
                  Patient Signature in Home Sleep test instruction sheet
                </mat-checkbox>
              </div>
            </div> -->
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule012')" formControlName="chkRule012">
                  RX date after sleep study date
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule044')" formControlName="chkRule044">
                  Face to face date is valid for 365 days
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule045')" formControlName="chkRule045">
                  Sleep study date should be valid for 365 days
                </mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                <b>OSA : Replacement Setup</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule013')" formControlName="chkRule013">
                  Current Face to Face date is valid within one year
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule014')" formControlName="chkRule014">
                  Usage and benefits of PAP needs to be mentioned is must in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule015')" formControlName="chkRule015">
                  Doctor signature is must in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule016')" formControlName="chkRule016">
                  Doctor signature in diagnostic sleep study is must
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule017')" formControlName="chkRule017">
                  RX after the current face to face notes
                </mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="step">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <span class="roundedCircle rule2 mr-2"></span>  <b>Rule 002 : CSA Checklist</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion class="example-headers-align" [multi]='true'>
          <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                <b>CSA : New Setup</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule018')" formControlName="chkRule018">
                  Symptoms of sleep apnea is must in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule019')" formControlName="chkRule019">
                  Sleep
                  test recommendation is must in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule020')" formControlName="chkRule020">
                  Doctor Signature in face to face is must
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule021')" formControlName="chkRule021">
                  Diagnostic sleep study is valid for 365 days
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule022')" formControlName="chkRule022">
                  AHI value is greater than or equal to 5
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule023')" formControlName="chkRule023">
                  Sum of central apneas and central hypopneas needs to be greater than 50% of total apneas and hypopneas
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule024')" formControlName="chkRule024">
                  Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule025')" formControlName="chkRule025">
                  RX date after sleep study date </mat-checkbox>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule049')" formControlName="chkRule049">
                  Face to face date should be valid for 365 days
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule050')" formControlName="chkRule050">
                  Doctor Signature in diagnostic sleep study is must
                </mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="step" >
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                <b>CSA : Replacement Setup</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule026')" formControlName="chkRule026">
                  Face to face notes valid only within 365 days
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule027')" formControlName="chkRule027">
                  Benefits and usange of PAP should be mentioned in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule028')" formControlName="chkRule028">
                  Doctor signature is must in face to face
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule029')" formControlName="chkRule029">
                  AHI value should be greater than or equal to 5
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule030')" formControlName="chkRule030">
                  Sum of central apneas and central hypopneas needs to be greater than 50% of total apneas and hypopneas
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule031')" formControlName="chkRule031">
                  Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule032')" formControlName="chkRule032">
                  Doctor signature is must in diagnostic sleep study
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule033')" formControlName="chkRule033">
                  RX date after face to face date
                </mat-checkbox>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="step">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <span class="roundedCircle rule3 mr-2"></span> <b>Rule 003 : CPAP to BIPAP Checklist</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule034')" formControlName="chkRule034">
              Titration sleep study date is valid for 365 days
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule035')" formControlName="chkRule035">
              CPAP tried and ineffective for patient mentioned in titration sleep study
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule036')" formControlName="chkRule036">
              Respiratory breakdowns is must in titration sleep study
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule037')" formControlName="chkRule037">
              Face to face date is only Valid for 365 days
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule038')" formControlName="chkRule038">
              Baseline file is must
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule047')" formControlName="chkRule047">
              RX date should be Valid for 365 days
            </mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule048')" formControlName="chkRule048">
              Face to face notes must prior to sleep study and RX
            </mat-checkbox>
          </div>
        </div>


      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="step">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <span class="roundedCircle rule4 mr-2"></span> <b>Rule 004 : CompSA Checklist</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule039')" formControlName="chkRule039">
              Titration sleep study date is valid for 365 days
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule040')" formControlName="chkRule040">
              AHI value is greater than or equal to 5 in titration study
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule041')" formControlName="chkRule041">
              Sum of central apneas and central hypopneas needs to be greater than 50% of total apneas and hypopneas in
              titration study

            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule042')" formControlName="chkRule042">
              Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5 in
              titration study
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule043')" formControlName="chkRule043">
              Obstructive apnea should be less than 5 in titration study
            </mat-checkbox>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <i class="fa fa-plus-circle addIcon mr-2"></i> <b>Add New Rule Checklist</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="col-3">
              <mat-label>DX Type
              </mat-label>
              <mat-select>
                <mat-option [value]="'OSA'">
                  OSA
                </mat-option>
                <mat-option [value]="'CSA'">
                  CSA
                </mat-option>
                <mat-option [value]="'CPAP to BIPAP'">
                  CPAP to BIPAP
                </mat-option>
                <mat-option [value]="'CompSA'">
                  CompSA
                </mat-option>
              </mat-select>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Product Type
              </mat-label>
              <mat-select>
                <mat-option [value]="'New'">
                  New
                </mat-option>
                <mat-option [value]="'Replacement'">
                  Replacement
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Rule Description </mat-label>
              <input autocomplete="new-Description" matInput maxlength="40"  type="text" />

            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>

    </mat-accordion>

  </form>
  <div class="col-sm-12 mt-2">
    <div class="text-lg-center">
      <button mat-button class="buttonColor mr-2" (click)="saveRuleMaster()"
        [disabled]="isEnableSaveButton || !(!RuleMasterForm?.invalid && RuleMasterForm?.dirty )">
        Save
        <mat-icon *ngIf='isShowSpinner'>
          <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
      </mat-icon>
      </button>
    </div>
  </div>
</div>


<!-- <div class="container-fluid mt-3 card card-body">
  <form [formGroup]="RuleMasterForm">
    <div class="row col-12">
      <div class="example-action-buttons">
        <button class="mr-1 buttonColor" mat-button (click)="accordion?.closeAll()">
          Collapse
        </button>
        <button class="buttonColor" mat-button (click)="accordion?.openAll()">
          Expand </button>&nbsp;
        <mat-checkbox #select_All class="pt-2 px-1" formControlName="chkAllRules" (change)="selectAll($event)">Select
          All</mat-checkbox>
      </div>
    </div>
    <mat-accordion class="example-headers-align"   [multi]='true'>
      <mat-expansion-panel [expanded]="true" >
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <b>Rules Checklist</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule001')" formControlName="chkRule001">
              Symptoms of sleep apnea is must in face to face
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule002')" formControlName="chkRule002">
              Sleep
              test recommendation is must in face to face
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule003')" formControlName="chkRule003">
              Doctor
              Signature in face to face is must
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule004')" formControlName="chkRule004">
              Diagnostic Sleep study date is must after the Face to Face
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule005')" formControlName="chkRule005">
              Total
              sleep time more than 120
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule006')" formControlName="chkRule006">
              AHI value should be greater than or equal to 5
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule007')" formControlName="chkRule007">
              Doctor Signature in diagnostic sleep study
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule012')" formControlName="chkRule012">
              RX date after sleep study date
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule044')" formControlName="chkRule044">
              Face to face date is valid for 365 days
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-checkbox (change)="changeRuleMasterCheckBox($event,'chkRule045')" formControlName="chkRule045">
              Sleep study date should be valid for 365 days
            </mat-checkbox>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
  <div class="col-sm-12 mt-2">
    <div class="text-lg-center">
      <button mat-button class="buttonColor mr-2" (click)="saveRuleMaster()"
        [disabled]="isEnableSaveButton || !(!RuleMasterForm?.invalid && RuleMasterForm?.dirty )">
        Save
      </button>
    </div>
  </div>
</div> -->
