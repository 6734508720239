import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subscription } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import Swal from 'sweetalert2';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { NewVerificationComponent } from '../newVerification/newVerification.component';
import { PatientInsuranceHistoryComponent } from '../patient-insurance-history/patient-insurance-history.component';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { PatientVerificationDTO } from '../patient-proxy/patient-optimization/dto/models';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { OndemandOnlineVerificationComponent } from '../ondemand-online-verification/ondemand-online-verification.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patient-new-insurance-table',
  templateUrl: './patient-new-insurance-table.component.html',
  styleUrls: ['./patient-new-insurance-table.component.scss']
})
export class PatientNewInsuranceTableComponent implements OnInit {
  @Input() tableData: any;
  @Output() reloadTableEvent = new EventEmitter<string>();
  @Output() isLoadTbl? = new EventEmitter<void>();
  @Output() patientIdEmitters: EventEmitter<string> =
    new EventEmitter<string>()
  eventtoggle: boolean = false;
  newInsuranceListdata: any;
  myTable: DataTables.Api;
  $subscription: Subscription[] = [];
  tableData$: Subscription;
  subscription$: Subscription[] = [];
  isShowSpinner: boolean;
  policyId:string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  defaultGuid = defaultGuid;
  verifyId: any;
  verifyStatus: any;
  dtNewInsuranceTableOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '450px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [7,8,9,10,14,15,16,17,21,22,23,24], visible: false },
      { targets: [0, 1], orderable: false },
      { targets: [0, 1, -1], className: 'dt-fixed-column' }
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'New Insurance Review',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3),:eq(4),:eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  chartId: string;
  patientId: string;
  documentId: string;
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private datepipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.newInsuranceListdata = this.tableData;

    this.dtNewInsuranceTableOptions.buttons[0].filename =
    'New Insurance Review' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    if (this.$subscription) {
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.tableData$?.unsubscribe();
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  tblItemSelectedRow(patientId: string, documentId: string, chartId: string) {
    this.patientId = patientId;
    this.documentId = documentId;
    this.chartId = chartId;
  }

  //Add Patient Notes
  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: this.patientId,
        defaultFaxId: this.chartId,
        isCallNote: false,
        isPending: false,
        docId: this.documentId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //View Patient Notes
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //View Document Image
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  //View Patient Ledger
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }

  //Ondemand Verification Request
  openNewVerification(patientId: string, isOnline: boolean) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientId: patientId,
        isOnline: isOnline,
      },
    };
    const dialogRef = this.dialog.open(NewVerificationComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  // Patient Insurance History Popup
  viewInsuranceHistory(patientId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientId: patientId,
      },
    };
    const dialogRef = this.dialog.open(PatientInsuranceHistoryComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => { },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //View Verification
  viewVerificationById(patentID: string, verificatnId: string, verificationStus: string, policystsId:string): void {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: { patientId: patentID, verificationId: verificatnId, savehidden: false, verificationStatus: verificationStus, isNewInsurance: true, policyId: policystsId, }
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      this.reloadTableEvent.emit(this.patientId);
      dialogRef?.close(result);
    });
    this.subscription$.push(closeDialog);
  }

  //To Add Policy Primary
  addPolicy(policy: any, checkstatus: number) {
    if (checkstatus == 1) {
      this.policyId = policy?.primary[0]?.policyId;

    } else if (checkstatus == 2) {
      this.policyId = policy?.secondary[0]?.policyId;

    } else if (checkstatus == 3) {
      this.policyId = policy?.tertiary[0]?.policyId;

    }
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      data: {
        patientId: policy.patientId,
        policyId: this.policyId,
        documentId: policy?.documentId,
        wipId: policy.wipId ?? defaultGuid,
        // selectedNode: this.selectedNode,
        verificationId: policy.verificationId,
        verificationStatus: policy.vStatus,
        defaultFaxId:policy?.defaultFaxId
      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        this.patientIdEmitters.next(policy.patientId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(aftClosed);
  }

  // Ondemand/Online Verification Request
  ondemandOnlineVerification(policy:any){
    if (policy.priPolicyId === defaultGuid) {
      if (policy.secPolicyId === defaultGuid) {
        this.policyId = policy.secPolicyId;
      } else {
        this.policyId = policy.terPolicyId;
      }
    } else {
      this.policyId = policy.priPolicyId;
    }
    if (policy.priVerificationId === defaultGuid) {
      if (policy.secVerificationId === defaultGuid) {
        this.verifyId = policy.secVerificationId;
      } else {
        this.verifyId = policy.terVerificationId;
      }
    } else {
      this.verifyId = policy.priVerificationId;
    }
    if (policy.priVerificationStatus === null ||policy.priVerificationStatus === '' ) {
      if (policy.secVerificationStatus === null ||policy.secVerificationStatus === '') {
        this.verifyStatus= policy.secVerificationStatus;
      } else {
        this.verifyStatus = policy.terVerificationStatus;
      }
    } else {
      this.verifyStatus = policy.priVerificationStatus;
    }
    const dialogRef = this.dialog.open(OndemandOnlineVerificationComponent, {
      data: {
        patientId: policy.patientId,
        policyId: policy.primary[0]?.policyId || policy.secondary[0]?.policyId || policy.tertiary[0]?.policyId,
        documentId: policy.documentId,
        wipId: policy.wipId ?? defaultGuid,
        // selectedNode: this.selectedNode,
        verificationId: this.verifyId,
        verificationStatus:this.verifyStatus,

      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        // this.patientIdEmitters.next(policy.patientId);
        this.isLoadTbl.emit();
        // policy.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }
}
