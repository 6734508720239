<mat-dialog-content class="mat-typography">


    
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button class="buttonColor">Save
        </button>
     
        <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
      
      </mat-dialog-actions>