import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { PatientDTO } from '../patient-proxy/patient/dto/models';
import { VerificationSearchByDateRangeService } from '../patient-proxy/patient/verification-search-by-date-range.service';

@Component({
  selector: 'app-patient-verify-search',
  templateUrl: './patient-verify-search.component.html',
  styleUrls: ['./patient-verify-search.component.scss']
})

export class PatientVerifySearchComponent implements OnInit , OnDestroy{
  searchForm: FormGroup
  pipe: DatePipe;
  searchData: any;
  organizationUnitId: string = "";
  dateRangeVerificationData: PatientDTO[] = [];
  subscription$: Subscription[] = [];
  // @ViewChild(PatientInsuranceVerificationComponent) patientInsuranceVerificationComponent :Component;

  constructor(
    private formBuilder: FormBuilder,
    private Table: TableService,
    private verificationSearchByDateRangeService: VerificationSearchByDateRangeService,
    private dialogRef: MatDialogRef<PatientVerifySearchComponent>
  ) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      txtFromDate: new FormControl("", [Validators.required]),
      txtToDate: new FormControl("", [Validators.required])
    })
  const verificationTable =  this.Table.getpatientInsuranceVerficationTable().subscribe(value => {
      this.searchData = value
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(verificationTable);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  get txtFromDate() { return this.searchForm?.get('txtFromDate'); }
  get txtToDate() { return this.searchForm?.get('txtToDate'); }

  searchFilter() {
    this.dialogRef.close();
    const txtFromDate = this.txtFromDate?.value === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(this.txtFromDate?.value));
    const txtToDate = this.txtToDate?.value === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(this.txtToDate?.value));
    this.Table.setdateFilterTable(txtFromDate, txtToDate);
    this.Table.setdateFilterTable(txtFromDate, txtToDate);
    this.Table.setdateFilterTable(txtFromDate, txtToDate);
    const searchVerification = this.verificationSearchByDateRangeService.searchVerificationByDateRangeByDtFromDateAndDtToDate(txtFromDate, txtToDate).subscribe(response => {
      this.dateRangeVerificationData = response?.items ?? [];
      this.Table.setpatientInsuranceVerficationTable(this.dateRangeVerificationData);

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(searchVerification);
  }
  resetFilter() {
    this.searchForm.patchValue({
      txtFromDate: "",
      txtToDate: ""
    })
    //this.dialogRef.close({ data: 'you cancelled' })
    const txtFromDate = this.txtFromDate?.value === undefined || this.txtFromDate?.value === "" ? null : new Date(this.txtFromDate?.value);
    const txtToDate = this.txtToDate?.value === undefined || this.txtToDate?.value === "" ? null : new Date(this.txtToDate?.value);
  }


}
