import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UpdateDamagedProductDTO, UpdateSerialNoDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { OrderMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/order-dropdowns.enum';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-serialno-edit',
  templateUrl: './serialno-edit.component.html',
  styleUrls: ['./serialno-edit.component.scss']
})
export class SerialnoEditComponent implements OnInit, OnDestroy {
  shippingEditForm: FormGroup;
  showButton: boolean = false;
  showEditData: boolean = false;
  showSerialNo: boolean = false;
  serialNoText: boolean = false;
  roleName: string = "";
  showChk: boolean = false;
  constructor(public dialogRef: MatDialogRef<SerialnoEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      productLotDetails: any, orderId: string, productName: string,
      productId: string, itemId: string, status: string
    },
    private dashboardService: DashboardService,
    private fb: FormBuilder, private orderDropdownService: OrderMasterDropdownService,
    private toastr: ToastrService,
    private orderService: SaleorderService,) { }
  productTable: any[] = [];

  subscription$: Subscription[] = [];



  ngOnInit(): void {
    this.dashboardService.userProfile().subscribe((response) => {
      if (
        response?.tenantId != null &&
        response?.roleName == 'Warehouse Manager'

      ) {
        this.roleName = 'Warehouse Manager'
        // this.showButton = false;
      }
      if (
        response?.tenantId != null &&
        response?.roleName == 'Warehouse Executive'
      ) {
        this.roleName = 'Warehouse Executive'
        // this.showButton = true;
      }

    })
    this.shippingEditForm = this.fb.group({
      txtSerialNo: new FormControl(""),
      txtProductName: new FormControl(""),
      txtSerialType: new FormControl(""),
      txtLotNo: new FormControl(""),
      txtSerialNoFilter: new FormControl(""),
      chkDamage: new FormControl(""),
    })
    this.getDropdown();

  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  serialType: any[] = [];
  drpSerial: any[] = [];
  filterSerialNo;
  // get all dropdowns
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.ProductSerialNoStatus
    ]
    const getOrderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.serialType = response.productSerialNoStatuses
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getOrderDropdown);
    this.getTableData();
  }

  /// get table datas
  getTableData() {
    this.productTable = [];
    this.data?.productLotDetails?.forEach(element => {
      element?.productDetails?.forEach(e => {
        this.productTable.push({
          serialNo: e?.serialNo,
          lotNo: element?.lotNo,
          isSerialNumberMatch: e?.isSerialNumberMatch,
          lotId: element?.lotId,
          productDetailsId: e?.productDetailsId,
          status: this.data?.status,
          isDamaged: e?.isDamaged
        })
      });
    });
  }
  initialSerialNo: string = "";
  oldProductDetailsId: string = "";
  oldLotId: string = "";
  oldSerialNo: string = "";
  showDamageBtn: boolean = false;

  /// get by id for view
  editDetails(data) {

    if (data?.status === "Return Approved") {
      this.showChk = true
      if (data?.isDamaged == 0) {
        this.showDamageBtn = true;
      }
    } else {
      this.showChk = false
      this.showDamageBtn = false;
    }
    this.showSerialNo = false


    /// onchage values
    this.onEndsChanges(data?.isSerialNumberMatch)
    this.showEditData = true
    this.initialSerialNo = data?.serialNo,
      this.oldProductDetailsId = data?.productDetailsId,
      this.oldSerialNo = data?.serialNo
    this.oldLotId = data?.lotId

    this.shippingEditForm.patchValue({
      txtSerialNo: data?.serialNo,
      txtProductName: this.data?.productName,
      txtSerialType: data?.isSerialNumberMatch,
      txtLotNo: data?.lotNo,
      chkDamage: data?.isDamaged
    })

  }

  //// returm damage save
  saveReturnSerialNo() {
    if (this.shippingEditForm.value.chkDamage == 0) {
      this.updateProductDamage();
    }
    else {
      Swal.fire({
        title: 'Are You Confirm to update the status?',
        text: 'By clicking yes, you will not be able to update anymore',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
        cancelButtonText: 'No',
      }).then(result => {
        if (result.value) {
          this.updateProductDamage();
        } else {
        }
      });
    }

  }

  updateProductDamage() {
    let CreateUpdateDamage: UpdateDamagedProductDTO = {
      productId: this.data.productId,
      orderId: this.data.orderId,
      itemId: this.data.itemId,
      lotId: this.oldLotId,
      productDetailsId: this.oldProductDetailsId,
      isDamaged: +(this.shippingEditForm.value.chkDamage) | 0
    }
    this.orderService.updateProductAsDamagedByInput(CreateUpdateDamage).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.dialogRef.close()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  ///// onchage validation for serial no dropdowns
  onEndsChanges(event) {
    if (event === "SerialNo MisMatched" && this.roleName === 'Warehouse Executive' && this.data.status === "Shipping Approved") {
      //   this.orderService.getSerialNumbersByProductIdAndSStatus(this.data.productId, "UnSold").subscribe((stateResponse) => {
      //     stateResponse?.forEach(ele => {
      //       this.drpSerial.push({
      //         lotId: ele?.lotId,
      //         lotNo: ele?.lotNo,
      //         newSerialNo: ele?.newSerialNo,
      //         productDetailsId: ele?.productDetailsId,
      //       })

      //     })
      //     this.filterSerialNo = this.shippingEditForm?.get("txtSerialNoFilter").valueChanges
      //       .pipe(
      //         startWith(''),
      //         map(value => this.drpSerial.filter(option => option?.newSerialNo?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
      //       );

      //   })
      //   this.showSerialNo = true

      this.showButton = true
      this.serialNoText = true
    } else {
      //   this.shippingEditForm.patchValue({
      //     txtSerialNo: this.initialSerialNo
      //   })
      //   this.showSerialNo = false
      this.showButton = false
      this.serialNoText = false
    }

  }

  /// save serial no if mis match
  saveSerialNo() {
    let SerialNo = this.drpSerial?.filter(value => { return value && value?.newSerialNo === this.shippingEditForm?.get("txtSerialNo").value })[0];

    let CreateUpdateShipping: UpdateSerialNoDTO = {
      productId: this.data.productId,
      orderId: this.data.orderId,
      itemId: this.data.itemId,
      // oldSerialNoDetails: {
      //   lotId: this.oldLotId,
      //   lotNo: this.shippingEditForm.value.txtLotNo,
      //   productDetailsId: this.oldProductDetailsId,
      //   oldSerialNo: this.oldSerialNo,
      // },
      // newSerialNoDetails: {
      //   lotId: SerialNo?.lotId,
      //   lotNo: SerialNo?.lotNo,
      //   productDetailsId: SerialNo?.productDetailsId,
      //   newSerialNo: SerialNo?.newSerialNo,
      // }

      oldLotId: this.oldLotId,
      oldLotNo: this.shippingEditForm.value.txtLotNo,
      oldProductDetailsId: this.oldProductDetailsId,
      oldSerialNo: this.oldSerialNo,
      newSerialNo: this.shippingEditForm.value.txtSerialNo

    }
    this.orderService.updateSerialnoByInput(CreateUpdateShipping).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.dialogRef.close()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  type: string = "";
  cleartype() {

  }
  SerialNo: string = "";
  clearSerialNo() {

  }
}
