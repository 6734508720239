import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import {
  ProductAllowablesService,
  ProductService,
} from '../item-proxy/item-management/items';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import { Title } from '@angular/platform-browser';
import {
  ProductAllowableDetailsService,
  ProductDetailsService,
} from '../item-proxy/item-management/optimization';
import { Observable, Subscription } from 'rxjs';
import { PverifyPayersDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ProductsDTO } from '../item-proxy/item-management/items/dto';
import { Moment } from 'moment';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import {
  CreateUpdateInsuranceAllowablesDTO,
  CreateUpdateProductAllowableDetailsDTO,
  MultipleInsuranceAllowablesDTO,
} from '../item-proxy/item-management/optimization/dto';
import { ProductHcpcCodeService } from '../item-proxy/item-management/optimization/product-hcpc-code.service';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { InsuranceAllowablesService } from '../item-proxy/item-management/optimization/insurance-allowables.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { AddInsuranceCategoryComponent } from 'projects/shared/src/app/components/add-insurance-category/add-insurance-category.component';

@Component({
  selector: 'app-insurance-allowables',
  templateUrl: './insurance-allowables.component.html',
  styleUrls: ['./insurance-allowables.component.scss'],
})
export class InsuranceAllowablesComponent implements OnInit {
  allowablesForm: FormGroup;
  subscription$: Subscription[] = [];
  tableIndex: number = 0;
  isLoading: boolean = false;
  loadTable: boolean = true;
  productName: string = '';
  hcpcArray: string[] = [];
  productId = [];
  insuranceId = [];
  allowablesTableData: any[] = [];
  drpInsurance: PverifyPayersDTO[] = [];
  drpHcpcCode: any[] = [];
  drpInsCategory: any[] = [];
  insuranceName: string = '';
  step: boolean = false;
  filterMainProduct: any;
  insuranceTableData: any;
  saveButtonHide: boolean = false;
  loadHcpcCode: any;
  public filteredInsuranceTypes: Observable<PverifyPayersDTO[]> | undefined;
  public filterProducts: Observable<ProductsDTO[]> | undefined;
  filterInsCategory: any;
  dtProductTableOptions: any = {
    responsive: true,
    paging: false,
    // scrollX: true,
    scrollCollapse: true,
    scrollY: '300px',
    info: true,
    search: {
      smart: false, // Set to "none" for full exact match
    },
    // buttons: [
    //   {
    //     extend: 'excel',
    //     text: 'Export', // Change the text to 'Export'
    //     exportOptions: {
    //       columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], // Specify the columns you want to export (0-indexed)
    //     },
    //   },
    // ],
  };
  selectedRange: { startDate: Moment; endDate: Moment };
  @ViewChild('productSelect') productSelect: MatSelect;
  @ViewChild('allProductsSelected') private allProductsSelected: MatOption;
  @ViewChild('allInsuranceSelected') private allInsuranceSelected: MatOption;
  @ViewChild('insuranceSelect') insuranceSelect: MatSelect;
  product: string;
  hcpc: string;
  tenantId: string;
  insuranceAllowablesId = '';
  buttonName = 'Save'; //added

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public title: Title,
    private hpcpCodeService: ProductHcpcCodeService,
    private insuranceCategoryService: InsuranceCategoryService,
    private insuranceAllowablesService: InsuranceAllowablesService,
    private communicationService: CommunicationService,
    private commonService: CommonService
  ) {
    const comService1 =
      this.communicationService.insuranceAllowableToggleMethodCall$.subscribe(
        () => {
          this.toggleV2();
        }
      );
    this.subscription$.push(comService1);
    const comService2 =
      this.communicationService.insuranceAllowableGetMethodCall$.subscribe(
        (allowableId) => {
          this.getInsAllowablesById(allowableId);
        }
      );
    this.subscription$.push(comService2);
  }

  ngOnInit(): void {
    this.allowablesForm = this.formBuilder.group({
      txtHcpcCode: new FormControl('', [Validators.required]),
      txtInsCategory: new FormControl('', [Validators.required]),
      txtInsuranceFilter: new FormControl(''),
      drpInsuranceName: new FormControl(''),
      txtMaxQty: new FormControl('', [
        Validators.required,
        this.maxValidator(),
      ]),
      txtMinQty: new FormControl('', [
        Validators.required,
        this.minValidator(),
      ]),
      txtAllowables: new FormControl('', [Validators.required]),
      txtQtyDays: new FormControl('', [Validators.required]),

      filterHcpcCode: new FormControl(''),
      filterCategory: new FormControl(''),
      txtProductType: new FormControl(''),
    });
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.title.setTitle('Qsecure | Product Insurance Allowables List');
    this.loadAllowablesList();
    this.getDropdowns();
  }

  openAddInsuranceCategoryModal() {
    const dialogRef = this.dialog.open(AddInsuranceCategoryComponent, {
      disableClose: true,

      data: {},
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        console.log('claimDetails');
        console.log(claimDetails);
        if (claimDetails) {
          const existingItemIndex = this.drpInsCategory.findIndex(
            (item) => item.id === claimDetails.id
          );
          console.log('existingItemIndex');
          console.log(existingItemIndex);
          if (existingItemIndex === -1) {
            this.drpInsCategory.push(claimDetails);
            this.drpInsCategory.sort((a, b) =>
              a.insuranceCategory.localeCompare(b.insuranceCategory)
            );
          }
          console.log('this.drpInsCategory');
          console.log(this.drpInsCategory);

          // this.InsCategFilterControl.valueChanges.subscribe((FilterText) => {
          //   FilterText = FilterText.toLowerCase();
          //   this.InsurenceCategoryList = this.lstInsurenceCategory.filter((dx) =>
          //     dx.insuranceCategory.toLowerCase().includes(FilterText)
          //   );
          // });

          // this.drpInsCategory = response;
          this.filterInsCategory = this.allowablesForm
            ?.get('filterCategory')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpInsCategory.filter((option) =>
                  option?.insuranceCategory
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  //Get Search Dropdowns

  validateKeyPress(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue =
      event.key === '.' && inputElement.value.indexOf('.') === -1
        ? event.key // Allow dot only if there is no dot in the input yet
        : /[0-9]/.test(event.key)
        ? event.key
        : ''; // Allow only numeric digits

    if (inputValue === '') {
      event.preventDefault(); // Block the key press if it doesn't meet the conditions
    }
  }

  //To Load the Allowables List
  loadAllowablesList() {
    this.loadTable = false;
    this.allowablesTableData = [];

    this.insuranceAllowablesService
      .getAllInsuranceAllowablesListWithHCPC()
      .subscribe(
        (response) => {
          this.insuranceTableData = response;
          this.insuranceTableData = this.insuranceTableData.map((product) => {
            return {
              ...product,
              createdDate: this.commonService.getFormattedDateTimeZone(
                product.createdDate
              ),
              modifyDate: this.commonService.getFormattedDateTimeZone(
                product.modifyDate
              ),
            };
          });
          this.loadTable = true;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.loadTable = true;
        }
      );
  }

  //To Save and Update the Allowable Data
  saveAllowables() {
    this.saveButtonHide = true;
    if (
      this.insuranceAllowablesId == undefined ||
      this.insuranceAllowablesId == null ||
      this.insuranceAllowablesId == ''
    ) {
      let saveDetails: MultipleInsuranceAllowablesDTO = {
        insuranceCategoryId: this.allowablesForm.value.txtInsCategory,
        hcpcCodeId: defaultGuid,
        allowable: this.allowablesForm.value.txtAllowables,
        minimumQuantity: this.allowablesForm.value.txtMinQty,
        maximumQuantity: this.allowablesForm.value.txtMaxQty,
        quantityDays: this.allowablesForm.value.txtQtyDays,
        hcpCcode: this.allowablesForm.value.txtHcpcCode,
        tenantId: this.tenantId,
      };

      this.insuranceAllowablesService
        .createMultipleAllowablesV1(saveDetails)
        .subscribe(
          (response) => {
            if (response == 'Saved Successfully') {
              this.toastr.success('Saved Successfully', 'Success');
              this.buttonName = 'Save';
              this.saveButtonHide = false;
              this.resetForm();
              this.loadAllowablesList();
            } else {
              // this.toastr.info(response);
              this.saveButtonHide = false;
              this.buttonName = 'Save';
              Swal.fire({
                icon: 'info',
                text: response,
              });
            }
          },
          (err) => {
            const errorMessageObject = JSON.parse(err.error);
            const specificErrorMessage = errorMessageObject.error.message;
            this.saveButtonHide = false;
            this.buttonName = 'Save';
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: specificErrorMessage,
            });
          }
        );
    } else {
      let saveDetails: CreateUpdateInsuranceAllowablesDTO = {
        insuranceCategoryId: this.allowablesForm.value.txtInsCategory,
        hcpcCodeId: defaultGuid,
        allowable: this.allowablesForm.value.txtAllowables,
        minimumQuantity: this.allowablesForm.value.txtMinQty,
        quantityDays: this.allowablesForm.value.txtQtyDays,
        maximumQuantity: this.allowablesForm.value.txtMaxQty,
        HCPCcode: this.allowablesForm.value.txtHcpcCode[0],
        tenantId: this.tenantId,
      };
      this.insuranceAllowablesService
        .update(this.insuranceAllowablesId, saveDetails)
        .subscribe(
          (response) => {
            this.saveButtonHide = false;
            this.loadAllowablesList();
            this.resetForm();
            this.buttonName = 'Save';
            this.toastr.success('Updated Successfully', 'Success');
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.saveButtonHide = false;
            this.buttonName = 'Save';
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }

  //To get the Dropdowns
  getDropdowns() {
    const getHcpcCode = this.hpcpCodeService
      .getAllProductHCPCCodeList()
      .subscribe(
        (response) => {
          this.drpHcpcCode = [];
          // this.drpHcpcCode = response.filter(
          //   (hcpcCode) => hcpcCode.status === 'Active'
          // );
          this.drpHcpcCode = response;
          this.filterProducts = this.allowablesForm
            ?.get('filterHcpcCode')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpHcpcCode.filter((option) =>
                  option?.hcpcCode
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );
    this.subscription$.push(getHcpcCode);

    const getCategory = this.insuranceCategoryService
      .getAllInsuranceCategoryListV1()
      .subscribe(
        (response) => {
          this.drpInsCategory = [];
          this.drpInsCategory = response;
          this.filterInsCategory = this.allowablesForm
            ?.get('filterCategory')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpInsCategory.filter((option) =>
                  option?.insuranceCategory
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );
    this.subscription$.push(getCategory);
  }

  //To get the Insurance Allowables Id
  getInsAllowablesById(allowableId) {
    this.hcpcArray = [];
    this.step = true;
    this.insuranceAllowablesId = allowableId;
    this.buttonName = 'Update';
    this.insuranceAllowablesService.get(allowableId).subscribe((response) => {
      this.hcpcArray.push(response.hcpCcode);
      this.allowablesForm.patchValue({
        txtInsCategory: response?.insuranceCategoryId,
        txtHcpcCode: this.hcpcArray,
        txtMaxQty: response?.maximumQuantity,
        txtMinQty: response?.minimumQuantity,
        txtAllowables: response?.allowable,
        txtQtyDays: response?.quantityDays,
      });
      this.allowablesForm.controls['txtMinQty'].updateValueAndValidity();
    });
  }

  //Numbers only
  numberOnly(event): boolean {
    // var charCode = event.which ? event.which : event.keyCode;
    // // Only Numbers 0-9
    // if (charCode < 48 || charCode > 57) {
    //   event.preventDefault();
    //   return false;
    // } else {
    //   return true;
    // }
    const isNumber = /[0-9.]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
      return false;
    } else {
      return true;
    }
  }
  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    // const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers
    const isNumber = /^\d+(\.\d+)?$/.test(pastedData); //Should contain only whole numbers and one decimel point(.)
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  toggleV2() {
    this.step = false;
  }
  resetForm() {
    // this.allowablesForm.patchValue({
    //   txtInsCategory: '',
    //   txtHcpcCode: '',
    //   txtAllowables: '',
    //   txtMinQty: '',
    //   txtQtyDays: '',
    //   txtMaxQty: '',
    // });
    this.buttonName = 'Save';
    this.insuranceAllowablesId = '';
    this.allowablesForm.reset();
    // this.clearValidationErrors();
  }
  clearValidationErrors() {
    Object.keys(this.allowablesForm.controls).forEach((key) => {
      this.allowablesForm.get(key).setErrors(null);
    });
  }
  clearMainProduct() {
    this.product = '';
    this.allowablesForm.patchValue({ txtInsCategory: '' });
  }
  clearHcpc() {
    this.hcpc = '';
    this.allowablesForm.patchValue({ txtHcpcCode: '' });
  }

  //Allowables Validation
  get checkAllowableValidation(): boolean {
    return (
      this.insuranceAllowablesId !== defaultGuid &&
      this.insuranceAllowablesId !== undefined &&
      this.insuranceAllowablesId !== ''
    );
  }

  minValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value ?? 0;
      if (
        value === null ||
        value === undefined ||
        Validators.required(control) !== null
      ) {
        // If the field is required or empty, don't perform decimal validation
        return null;
      }
      if (Number(value) > Number(this.allowablesForm.value.txtMaxQty ?? 0)) {
        return { minValidation: true };
      } else {
        this.allowablesForm.get('txtMaxQty').setErrors(null);
      }
      // else if( (Number(value) == Number(this.allowablesForm.value.txtMaxQty ?? 0)) ){
      //   return { minValidation: true };
      // }

      return null;
    };
  }

  maxValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value ?? 0;
      if (
        value === null ||
        value === undefined ||
        Validators.required(control) !== null
      ) {
        // If the field is required or empty, don't perform decimal validation
        return null;
      }

      if (Number(value) < Number(this.allowablesForm.value.txtMinQty ?? 0)) {
        return { maxValidation: true };
      } else {
        this.allowablesForm.get('txtMinQty').setErrors(null);
      }
      // else if( (Number(value) == Number(this.allowablesForm.value.txtMinQty ?? 0)) ){
      //   return { maxValidation: true };
      // }

      return null;
    };
  }
}
