import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order';
import { CheckoutOrderService } from 'projects/order/src/app/order-proxy/order-management/pay-pal/checkout-order.service';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cash-payment-view',
  templateUrl: './cash-payment-view.component.html',
  styleUrls: ['./cash-payment-view.component.scss']
})
export class CashPaymentViewComponent implements OnInit , OnDestroy {
  orderId: string = "";
  saleOrderId: string = "";
  Name: string = "";
  dob: string;
  transactionAmount: string = "";
  // itemName: string = "";
  // amount: number;
  items: any[] = [];
  subscription$: Subscription[] = [];
  constructor(private formBuilder: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private paymentService: CheckoutOrderService,
    private saleOrderService: SaleorderService
  ) { }
  CashForm: FormGroup;
  ngOnInit(): void {
    this.CashForm = this.formBuilder.group({
      txtNewPassword: new FormControl(""),
      //txtRepeatPassword: new FormControl(""),
    });
    this.activatedRoute.paramMap.subscribe(response => {
      this.orderId = response.get('id');
    })

    this.getPaymentCapture();

    // this.getPaymentView();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  //! capture api
  getPaymentCapture() {
    const getPayments = this.paymentService.getPaymentCapture(this.orderId).subscribe(response => {
      let orderData = response;
      this.saleOrderId = orderData.saleorderId;
      this.dob = orderData?.patients?.personals?.dateOfBirth == null ? undefined : new Date(orderData?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US");
      this.Name = orderData?.patients?.personals?.fullName;
      this.transactionAmount = orderData?.paymentTransactionDetails?.payPalCheckOutOrderPayments[0]?.totalPaidAmount;
      orderData.items.forEach(element => {
        this.items.push({
          itemName: element.name,
          amount: element.charge,
          code: element.itemProcedureCode
        })
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })
    this.subscription$.push(getPayments)
  }

  //! payment view
  getPaymentView() {
    const orderGet = this.saleOrderService.get(this.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      let orderData = response;
      this.saleOrderId = orderData.saleorderId;
      this.dob = orderData?.patients?.personals?.dateOfBirth == null ? undefined : new Date(orderData?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US");
      this.Name = orderData?.patients?.personals?.fullName
      orderData.items.forEach(element => {
        this.items.push({
          itemName: element.name,
          amount: element.charge,
          code: element.itemProcedureCode
        })
      });

    })
    this.subscription$.push(orderGet);
  }
}
