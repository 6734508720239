import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { dtInboundOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';

import {
  PatientSaleOrderService,
  SaleOrderItemService,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization';
import {
  OrderDetailsDTO,
  PrintTicketDetails,
  SaleOrderItemDTO,
  ShippingApprovalDTO,
  ShippingPatientBalanceNotesDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import {
  AddressService,
  PatientClinicalService,
  PatientPersonalService,
  PatientPolicyService,
  PatientVerificationService,
} from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { MasterProviderDTO } from 'projects/admin/src/app/warehouse-proxy/inventory/dto/models';
import {
  PatientClinicalDTO,
  PatientPersonalDTO,
  PatientPolicyDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import {
  Observable,
  Subscription,
  forkJoin,
  SubscribableOrPromise,
} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  AddressDTO,
  DoctorDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import { InsuranceType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data';
import pdfMake from 'pdfmake/build/pdfmake';
import { AdminDashboardPatientManualVerificationComponent } from 'projects/patient/src/app/admin-dashboard-patient-manual-verification/admin-dashboard-patient-manual-verification.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ToastrService } from 'ngx-toastr';
import { InsuranceCheckListComponent } from 'projects/admin/src/app/insurance-check-list/insurance-check-list.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { shippingTypeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-type.enum';
import { NotesReasonComponent } from 'projects/inventory/src/app/notes-reason/notes-reason.component';
import { NotesType } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
import { ShippingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-list.service';
import { DatePipe } from '@angular/common';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { OrderHistoryModalComponent } from 'projects/order/src/app/order-history-modal/order-history-modal.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-shipping-list-tab',
  templateUrl: './shipping-list-tab.component.html',
  styleUrls: ['./shipping-list-tab.component.scss'],
})
export class ShippingListTabComponent implements OnInit, OnDestroy {
  isShowSpinner: boolean = false;
  shippingTableData: any;
  sInsuranceType: any;
  selectedShippingData: any;
  printTicketData: PrintTicketDetails;
  patientBalanceandNotesDetails: ShippingPatientBalanceNotesDTO;
  itemTableData: any = [];
  hcpcTableData: any = [];
  authTableData: any = [];
  loadItmTbl = true;
  dtShippingOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollX: true,
    // info: false,
    scrollY: '300px',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [0], visible: false },
      { orderable: true, targets: '_all' },
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' }, // Ad
      { targets: [9, 10], visible: false },
    ],
  };
  dtItemOptions: any = {
    responsive: true,
    paging: false,
    filter: false,
    info: true,
    scrollY: '225px',
    scrollCollapse: true,
    columnDefs: [
      { targets: [0], visible: false },
      { orderable: true, targets: '_all' },
    ],

    // order: [],
    //pageLength: 5,
    //lengthMenu: [10, 25, 50, 100, 200],
    // info: false,
    // columns: [
    //   { width: '10%' },
    //   { width: '70%' },
    //   { width: '10%' },
    //   { width: '10%' },
    // ],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
  };
  dtHCPCOptions: any = {
    responsive: true,
    bFilter: false,
    paging: false,
    scrollCollapse: true,
    scrollY: '215px',
    info: false,
    language: {
      emptyTable: 'No Records', // Customize the 'No data available in table' text
    },
  };
  dtAuthOptions: any = {
    responsive: true,
    bFilter: false,
    paging: false,
    scrollCollapse: true,
    scrollY: '215px',
    info: false,
    language: {
      emptyTable: 'No Records', // Customize the 'No data available in table' text
    },
  };
  disableBtn: boolean = false;
  organizationUnitName: string;
  public drpBranch: Branch[] = [];
  public branchList: Observable<Branch[]> | undefined;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchForm: FormGroup;
  shippingForm: FormGroup;
  tenantId: string;
  defaultGuid = defaultGuid;
  isShippingTableLoaded = false;
  subscription$: Subscription[] = [];
  ShippingListUnSubscription: Subscription;
  orderListSubscription: Subscription;
  notesListSubscription: Subscription;
  patientId: string = defaultGuid;
  priVerificationId: string = defaultGuid;
  secVerificationId: string = defaultGuid;
  authorizationId: string = defaultGuid;
  checkListId: string = defaultGuid;
  isCashOrder: boolean = false;
  isExchangeOrder: boolean = false;
  documentId: string;
  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private branchService: OrganizationUnitService,
    private shippingApprovalService: ShippingApprovalService,
    public patientSaleOrderService: PatientSaleOrderService,
    public patientAddressService: AddressService,
    public patientPersonalService: PatientPersonalService,
    public saleOrderItemService: SaleOrderItemService,
    public patientPolicyService: PatientPolicyService,
    public patientClinicalService: PatientClinicalService,
    private toaster: ToastrService,
    public dialog: MatDialog,
    public masterProviderService: MasterProviderService,
    private shippingListService: ShippingListService,
    private commonService: CommonService,
    private datepipe: DatePipe,
    public doctorService: DoctorService // @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.title.setTitle('Qsecure | Shipping Approval List');
    this.initializeForms();
    this.getBranchList();
    this.getShippingList();
  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //Intialize Forms
  initializeForms() {
    this.branchForm = this.formBuilder.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
    });

    this.shippingForm = this.formBuilder.group({
      txtPatientBalance: new FormControl(''),
      txtINSBalance: new FormControl(''),
      txtTotalBalance: new FormControl(''),
      txtBillingNotes: new FormControl(''),
      txtShippingNotes: new FormControl(''),
    });
    // this.shippingForm.patchValue({
    //   txtPatientBalance: this.patientBalance,
    //   txtINSBalance: this.insBalance,
    //   txtTotalBalance: this.totalBalance,
    //   txtBillingNotes: this.billingNotes,
    //   txtShippingNotes: this.shippingNotes,
    // });
  }

  openVerificationModal(element) {
    if (element == 'Primary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.selectedShippingData.patientId,
            verificationId: this.selectedShippingData.priVerificationId,
            policyId: this.selectedShippingData.policyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    } else if (element == 'secondary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,

          data: {
            patientId: this.selectedShippingData.patientId,
            verificationId: this.selectedShippingData.secVerificationId,
            policyId: this.selectedShippingData.secpolicyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  viewPatientLedger() {
    const dialogRef = this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: this.selectedShippingData.patientId ?? defaultGuid,
        chartNo: this.selectedShippingData.chartno ?? '',
      },
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {});
  }

  //Branch List Api Call and Dropdown Binding Method
  getBranchList() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });

          this.branchList = this.branchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }
  allBranchSelect() {
    if (this.selectAllBranch.selected) {
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getShippingList() {
    if (this.orderListSubscription) {
      this.orderListSubscription.unsubscribe();
    }
    this.isShippingTableLoaded = false;
    this.orderListSubscription = this.shippingApprovalService
      .getCompletedOrderDetailsV1()
      .subscribe(
        (response) => {
          this.shippingTableData = response;
          this.shippingTableData = this.shippingTableData.map((shipping) => {
            return {
              ...shipping,
              suppliesdate: this.commonService.getFormattedDateZone(
                shipping.suppliesdate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                shipping.modifiedDate
              ),
              enteredon: this.commonService.getFormattedDateTimeZone(
                shipping.createdDate
              ),
            };
          });
          this.isShippingTableLoaded = true;
          // this.shippingTableData.length > 0 &&
          this.tblShpSelectedRow(
            this.shippingTableData?.[0]?.orderId,
            this.shippingTableData?.[0]
          );
          if (this.shippingTableData?.length <= 0) {
            this.getOrderItems(this.shippingTableData[0]?.orderId);
            this.shippingForm.reset();
          }
        },
        (err) => {
          this.isShippingTableLoaded = true;
        }
      );
    this.subscription$.push(this.orderListSubscription);
  }
  //! Download Order template , Generate Template
  getOrderTemplate(orderValue: ShippingApprovalDTO) {
    if (orderValue?.orderId) {
      const patientObs$ = this.patientPersonalService.get(
        orderValue?.patientId
      );
      const orderObs$ = this.patientSaleOrderService.get(orderValue?.orderId);
      const patientAddressObs$ =
        this.patientAddressService.getAddressByPatientIdByPatientId(
          orderValue?.patientId
        );
      const orderItemObs$ =
        this.saleOrderItemService.getItemsListByOrderIdByOrderId(
          orderValue?.orderId
        );
      const providerObs$ = this.masterProviderService
        .getList(new PagedAndSortedResultRequestDto())
        .pipe(map((x) => x?.items));
      const doctorObs$ = this.doctorService
        .getList(new PagedAndSortedResultRequestDto())
        .pipe(
          map((x) => x?.items),
          map((doctors) => {
            let data: doctorInterface[] = [];
            doctors.forEach((x) => {
              data?.push({
                name: `${
                  (x?.npiNumber ?? '') +
                  ' / ' +
                  (x?.firstName ?? '') +
                  ' ' +
                  (x?.middleName ?? '') +
                  ' ' +
                  (x?.lastName ?? '')
                }`,
                id: x?.id ?? '',
                ...x,
              });
            });
            return data ?? [];
          })
        );
      const clinicalObs$ =
        this.patientClinicalService.getClinicalDetailsByGPatientId(
          orderValue?.patientId
        );
      const polciyObs$ =
        this.patientPolicyService.getActivePolicyByPatientIdByPatientId(
          orderValue?.patientId
        );

      const orderTemplate = forkJoin([
        patientObs$,
        clinicalObs$,
        polciyObs$,
        orderObs$,
        patientAddressObs$,
        orderItemObs$,
        providerObs$,
        doctorObs$,
      ])
        .pipe((x) => {
          return x;
        })
        .subscribe(
          (response: any) => {
            const patientData: PatientPersonalDTO = response?.[0];
            const clinicalData: PatientClinicalDTO = response?.[1];
            const policyData: PatientPolicyDTO[] = response?.[2] ?? [];
            const orderData: OrderDetailsDTO = response?.[3];
            const addressData: AddressDTO = response?.[4]?.[0];
            const itemData: SaleOrderItemDTO[] = response?.[5] ?? [];
            const providerData: MasterProviderDTO[] = response?.[6] ?? [];
            const doctorData: doctorInterface[] = response?.[7] ?? [];

            const selDoctorData: doctorInterface =
              doctorData?.filter(
                (x) => x.name === clinicalData?.referringProviderDoctor
              )?.[0] ?? null;
            const selProvider =
              providerData?.filter(
                (x) => x?.shortCodeId === patientData?.providerId
              )?.[0] ?? null;

            let docDefinition: TDocumentDefinitions = {
              header: [
                {
                  margin: [0, 10, 0, 0],
                  text: 'DELIVERY/PICKUP TICKET',
                  fontSize: 16,
                  alignment: 'center',
                  color: '#047886',
                  height: 10,
                },
              ],
              content: [
                {
                  margin: [90, 0, 0, 0],
                  alignment: 'center', // add this property to center the table
                  table: {
                    headerRows: 1,

                    widths: ['auto', 'auto'],
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          text: `Provider Name :`,
                          fontSize: 12,
                          alignment: 'left',
                          bold: true,
                        },
                        {
                          border: [false, false, false, false],
                          text: `${selProvider?.fullName ?? ''}`,
                          fontSize: 12,
                          alignment: 'left',
                        },
                      ],
                      [
                        {
                          border: [false, false, false, false],

                          text: `Address :`,
                          fontSize: 12,
                          alignment: 'left',

                          bold: true,
                        },
                        {
                          border: [false, false, false, false],

                          text: `${selProvider?.homeAddress ?? ''}`,
                          fontSize: 12,
                          alignment: 'left',
                        },
                      ],
                      [
                        {
                          border: [false, false, false, false],

                          text: `City, State & Zip code :`,
                          fontSize: 12,
                          bold: true,
                          alignment: 'left',
                        },
                        {
                          border: [false, false, false, false],

                          text: `${
                            (selProvider?.city ?? '') +
                              (selProvider?.city ?? '') ===
                            ''
                              ? ''
                              : ' ' +
                                  (selProvider?.state ?? '') +
                                  (selProvider?.state ?? '') ===
                                ''
                              ? ''
                              : ' ' + (selProvider?.zip ?? '')
                          }`,
                          fontSize: 12,
                          alignment: 'left',
                        },
                      ],
                      [
                        {
                          border: [false, false, false, false],

                          text: `Ph# : `,
                          fontSize: 12,
                          bold: true,
                          alignment: 'left',
                        },
                        {
                          border: [false, false, false, false],

                          text: `${selProvider?.phone ?? ''}`,
                          fontSize: 12,
                          alignment: 'left',
                        },
                      ],
                    ],
                  },
                },
                {
                  margin: [0, 20, 0, 0],
                  table: {
                    widths: [10, 90, 10, 90, 10, 90, 30, 70],
                    heights: 5,
                    body: [
                      [
                        { text: '', colSpan: 1 },
                        {
                          text: 'Delivery',
                          border: [false, false, false, false],
                          colSpan: 1,
                        },
                        { text: '', colSpan: 1 },
                        {
                          text: 'Pickup',
                          border: [false, false, false, false],
                          colSpan: 1,
                        },
                        { text: '', colSpan: 1 },
                        {
                          text: 'Service',
                          border: [false, false, false, false],
                          colSpan: 1,
                        },
                        {
                          text: 'Date:',
                          border: [false, false, false, false],
                          colSpan: 1,
                        },
                        {
                          text: '____________',
                          border: [false, false, false, false],
                          colSpan: 1,
                        },
                      ],
                    ],
                  },
                },
                {
                  alignment: 'justify',
                  margin: [0, 20, 0, 0],
                  table: {
                    widths: [100, 100, 100, 100, 100],
                    body: [
                      [
                        {
                          text: `Patient Name: ${patientData?.fullName ?? ''}`,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                        {
                          bold: true,
                          text: `Acc#: ${patientData?.accountNumber}`,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          bold: true,
                          width: '20',
                          text: `Address: ${addressData?.address}`,
                          colSpan: 2,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {},
                        {
                          bold: true,
                          text: `City: ${addressData?.city}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `Zip: ${addressData?.postalCode}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `State: ${addressData?.state}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                      ],
                      [
                        {
                          bold: true,
                          text: `Ph#: ${addressData?.mobile}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `Sex: ${
                            patientData?.gender === 'M'
                              ? 'Male'
                              : patientData?.gender === 'F'
                              ? 'Female'
                              : 'Unknown'
                          }`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `Height: ${(
                            clinicalData?.height ?? ''
                          )?.toString()}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `Weight: ${(
                            clinicalData?.weight ?? ''
                          )?.toString()}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `DOB: ${
                            (patientData?.dateOfBirth ?? '') === ''
                              ? ''
                              : new Date(
                                  patientData?.dateOfBirth
                                )?.toLocaleDateString('en-US') ?? ''
                          }`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                      ],
                      [
                        {
                          bold: true,
                          text: `Primary Insurance: ${
                            policyData?.filter(
                              (x) =>
                                x?.payorLevel ===
                                  InsuranceType.Primary.toLowerCase() &&
                                x?.isActive === true
                            )?.[0]?.zirmedPayorName ?? ''
                          }`,
                          colSpan: 5,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {},
                        {},
                        {},
                        {},
                      ],
                      [
                        {
                          bold: true,
                          text: `Doctor: ${
                            (selDoctorData?.firstName ?? '') +
                            ' ' +
                            (selDoctorData?.lastName ?? '')
                          }`,
                          colSpan: 2,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {},
                        {
                          bold: true,
                          text: `Ph#: ${selDoctorData?.phoneNo ?? ''}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `NPI: ${selDoctorData?.npiNumber ?? ''}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {
                          bold: true,
                          text: `Fax#: ${selDoctorData?.faxNo ?? ''}`,
                          colSpan: 1,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                      ],
                      [
                        {
                          bold: true,
                          text: `Address: ${selDoctorData?.address ?? ''}`,
                          colSpan: 5,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                        },
                        {},
                        {},
                        {},
                        {},
                      ],
                    ],
                  },
                },
                {
                  alignment: 'justify',
                  margin: [0, 20, 0, 0],
                  table: {
                    widths: [100, 100, 100, 100, 100],
                    body: [
                      [
                        {
                          text: `Qty`,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `HCPCS Code`,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                          colSpan: 1,
                          bold: true,
                        },

                        {
                          text: `Item Name`,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `Description`,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `Modifiers`,
                          preserveLeadingSpaces: false,
                          preserveTrailingSpaces: false,
                          noWrap: false,
                          colSpan: 1,
                          bold: true,
                        },
                      ],
                      ...itemData?.map((p) => [
                        `${p?.orderingQuantity ?? ''}`,
                        `${p?.itemProcedureCode ?? ''}`,
                        `${p?.itemName ?? ''}`,
                        `${p?.description ?? ''}`,
                        `${
                          (p?.modifiers1 ?? '') +
                          ((p?.modifiers1 ?? '') === '' ? '' : ' ') +
                          (p?.modifiers2 ?? '') +
                          ((p?.modifiers2 ?? '') === '' ? '' : ' ') +
                          (p?.modifiers3 ?? '') +
                          ((p?.modifiers3 ?? '') === '' ? '' : ' ') +
                          (p?.modifiers4 ?? '')
                        }`,
                      ]),
                    ],
                  },
                },
                {
                  margin: [0, 20, 0, 0],
                  text: 'Comments / Instructions',
                },
                {
                  table: { widths: '*', heights: 50, body: [[{ text: '' }]] },
                },
                {
                  margin: [0, 20, 0, 0],
                  text: 'I authorize the release of any medical or other information necessary to process this claim.I authorize payment of medical benefits to the undersigned physician or supplier of services described.I also request payment of government benefits either to myself or to the party who accepts assignment below.',
                },
                {
                  margin: [0, 40, 0, 0],

                  alignment: 'center',
                  table: {
                    widths: '*',

                    heights: 5,
                    body: [
                      [
                        {
                          alignment: 'center',
                          border: [false, false, false, false],
                          text: '_________________________',
                        },
                        {
                          alignment: 'center',
                          border: [false, false, false, false],
                          text: '_________________________',
                        },
                        {
                          alignment: 'center',
                          border: [false, false, false, false],
                          text: '_________________________',
                        },
                      ],
                      [
                        {
                          alignment: 'center',
                          border: [false, false, false, false],
                          text: 'Responsible Party / Agent',
                        },
                        {
                          alignment: 'center',
                          border: [false, false, false, false],
                          text: 'Received By',
                        },
                        {
                          alignment: 'center',
                          border: [false, false, false, false],
                          text: 'Date',
                        },
                      ],
                    ],
                  },
                },
              ],
              styles: {
                //`[left, top, right, bottom]`
                sectionHeader: {
                  bold: true,
                  decoration: 'underline',
                  fontSize: 14,
                  margin: [0, 0, 0, 15],
                },
                header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 5],
                },
                tableExample: {
                  margin: [0, 5, 10, 15],
                },
                tableHeader: {
                  bold: true,
                  fontSize: 13,
                  color: 'black',
                },
                defaultStyle: {
                  // alignment: 'justify'
                },
              },
            };
            const action: string = 'open';
            if (action === 'download') {
              pdfMake?.createPdf(docDefinition)?.download();
            } else if (action === 'print') {
              pdfMake?.createPdf(docDefinition)?.print();
            } else {
              pdfMake?.createPdf(docDefinition)?.open();
            }
            this.shippingForm.patchValue({
              txtSaleorderId: '',
              txtPatientId: '',
              txtPatientName: '',
              txtCsrName: '',
              txtStatus: '',
              txtDos: '',
            });
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(orderTemplate);
    }
  }
  tblShpSelectedRowId: string = defaultGuid;
  tblItemSelectedRowId: string = defaultGuid;
  //Select Shipping Details
  tblShpSelectedRow(id: string, data) {
    this.isCashOrder = data?.isCashOrder ?? null;
    this.isExchangeOrder = data?.isExchangeOrder ?? false;
    this.selectedShippingData = data ?? null;
    this.priVerificationId = data?.priVerificationId ?? defaultGuid;
    this.secVerificationId = data?.secVerificationId ?? defaultGuid;
    this.authorizationId = data?.authorizationId ?? defaultGuid;
    this.checkListId = data?.medicareCheckList ?? defaultGuid;
    this.patientId = data?.patientId ?? defaultGuid;
    this.tblShpSelectedRowId = id ?? defaultGuid;
    this.documentId = data?.faxDocumentId ?? defaultGuid;
    // this.tblShpSelectedRowId === id ? defaultGuid : id;

    this.tblShpSelectedRowId != defaultGuid && this.getOrderItems(id);
    this.tblShpSelectedRowId != defaultGuid &&
      this.getNotesDetails(data?.orderId);
  }
  //To get the Order Items
  getOrderItems(orderId: string) {
    this.itemTableData = [];
    this.hcpcTableData = [];
    this.loadItmTbl = false;
    if (this.ShippingListUnSubscription) {
      this.ShippingListUnSubscription.unsubscribe();
    }
    this.ShippingListUnSubscription = this.shippingApprovalService
      .getShippingItemDetailsByGOrderId(orderId)
      .subscribe(
        (response) => {
          this.itemTableData = response;

          this.loadItmTbl = true;
          this.tblItemSelectedRow(
            this.itemTableData?.[0]?.productId,
            this.itemTableData?.[0]?.hcpcList
          );
          this.getApprovalNoandAmnt(orderId);
        },
        (err) => {
          this.loadItmTbl = true;
        }
      );
    this.subscription$.push(this.ShippingListUnSubscription);
  }
  tblItemSelectedRow(id: string, data: any) {
    this.tblItemSelectedRowId = this.tblItemSelectedRowId === id ? '' : id;
    this.tblItemSelectedRowId != '' && this.getHCPCCodes(data);
  }
  getHCPCCodes(data) {
    this.hcpcTableData =
      this.hcpcTableData.length > 0 ? [] : this.hcpcTableData;

    this.hcpcTableData = data;
  }
  getNotesDetails(orderId: string) {
    if (this.notesListSubscription) {
      this.notesListSubscription.unsubscribe();
    }
    this.notesListSubscription = this.shippingApprovalService
      .getPatientBalNotesDetailsV1ByOrderId(orderId)
      .subscribe(
        (response) => {
          this.patientBalanceandNotesDetails = response;
          // this.shippingForm.patchValue({
          //   txtPatientBalance: this.patientBalance,
          //   txtINSBalance: this.insBalance,
          //   txtTotalBalance: this.totalBalance,
          //   txtBillingNotes: this.billingNotes,
          //   txtShippingNotes: this.shippingNotes,
          // });
        },
        (err) => {}
      );
    this.subscription$.push(this.notesListSubscription);
  }

  notesReason() {
    const dialogRef = this.dialog.open(NotesReasonComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      disableClose: true,
      data: {
        patientId: this.selectedShippingData.patientId,
        ticketId: this.selectedShippingData.mmRefereceOrderId,
        notesType: NotesType.shipping,
        orderId: this.selectedShippingData.orderId,
        type: 'shippingApproval',
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      this.getShippingList();
    });
  }

  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  openAddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean = false,
    chartno: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        chartNo: chartno,
        docId: docId,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: false,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  openPendingNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean = false,
    chartno: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        chartNo: chartno,
        docId: docId,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: false,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //To Disapprove the Shipping Order
  approveDisapproveShipping(orderId: string, status: boolean) {
    this.isShowSpinner = true;
    this.shippingApprovalService
      .updateShippingApprovalStatusByGOrderIdAndStatusAndType(
        orderId,
        status,
        shippingTypeService.SHIPPINGAPPROVAL,
        null
      )
      .subscribe(
        (response) => {
          this.isShowSpinner = false;
          this.getShippingList();
          !status && this.toaster.success('Shipping Disapproved!');
          status && this.toaster.success('Shipping Approved!');
        },
        (err) => {
          this.isShowSpinner = false;
        }
      );
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  //Approval No and Amount
  getApprovalNoandAmnt(orderId: string) {
    this.shippingListService.getApprovalNoByOrderId(orderId).subscribe(
      (response) => {
        this.authTableData = response;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /* #region PRINT TICKET */
  //Method to call the Print Ticket Data Details
  getPrintTicketDetails() {
    const shippingTicketDetails = this.shippingListService
      .orderTicketDetailsV1ByOrderId(this.tblShpSelectedRowId)
      .subscribe(
        (response) => {
          this.printTicketData = response ?? null;
          this.getPrintTicket(this.printTicketData);
        },
        (err) => {}
      );

    this.subscription$.push(shippingTicketDetails);
  }
  getPrintTicket(printTicketData: PrintTicketDetails) {
    const tbodyStyle = {
      fontSize: 10,
      margin: [0, 2],
    };

    this.printTicketData.productDetailsDTO =
      this.printTicketData?.productDetailsDTO?.map((a) => ({
        ...a,
        style: tbodyStyle,
      }));

    let docDefinition = {
      /* #region HEADER */
      header: function (currentPage, pageCount, pageSize) {
        return {
          columns: [
            // {
            //   image: localStorage.getItem('LogoDataUrl'),
            //   width: 100, // Adjust the width as needed
            //   alignment: 'left', // Adjust the alignment as needed
            // },
            // {
            //   width: '10%',
            //   text:{

            //       text: '844498797',
            //       fontSize: 18,
            //       bold: true,
            //       alignment: 'center',
            //       color: '#000000',

            //   }
            // },
            {
              width: '80%',
              text: [
                { text: '\n' },
                {
                  // margin:[15,0,0,0],
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  color: '#000000',
                },
                {
                  text: '\t\t\t\t\t\t\t\t\t\tConfirm receipt of shipment online at www.monitormedical.com\n\t\t\t\t',
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  color: '#91ab5b',
                },
                {
                  text: '\t\t\t\t\t\tPATIENT CARE DELIVERY TICKET',
                  fontSize: 16,
                  bold: true,
                  alignment: 'center',
                  color: '#800000',
                },
              ],
              margin: [15, 0, 0, 0],
            },
            {
              image: localStorage.getItem('LogoDataUrl'),
              width: 100, // Adjust the width as needed
              // alignment: 'right', // Adjust the alignment as needed
              margin: [0, 10, 0, 0],
            },
          ],
        };
      },

      /* #endregion */

      /* #region FOOTER */
      footer: (currentPage, pageCount, pageSize) => {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 3,
              lineColor: '#800000',
            },
          ],
          columns: [
            {
              width: '70%',
              text: [
                {
                  text: 'Patient Signature:',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: '_____________________________________________________________________\n',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: 'Monitor Medical, Inc. \t',
                  fontSize: 16,
                  italics: true,
                  bold: true,
                },
                {
                  text: 'The C-PAP Co. \t',
                  fontSize: 12,
                  italics: true,
                },
                {
                  text: 'Toll Free: 866-634-0177 \n',
                  fontSize: 10,
                  italics: true,
                  bold: true,
                },
                {
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 14,
                  italics: true,
                  alignment: 'right',
                  bold: true,
                  color: '#000000',
                },
              ],
              // ,
              bold: true,
              color: '#800000',
            },
            {
              width: '30%',
              text: [
                // {
                //   text: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ \n\n',
                //   fontSize: 15,
                //   bold: true,
                //   italics: true,
                //   alignment: 'left',
                // },
                { text: 'Date:', fontSize: 10, italics: true },
                { text: '___________________________\n' },
                {
                  text:
                    this.datepipe.transform(new Date(), 'fullDate') + '\n\n',
                  alignment: 'center',
                  fontSize: 8,
                  italics: true,
                  bold: false,
                },
                {
                  text:
                    printTicketData.shippingMethodName +
                    '\t' +
                    printTicketData.location,
                  fontSize: 8,
                  italics: true,
                  alignment: 'center',
                  color: '#000000',
                },
              ],
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#800000',
            },

            // {
            //   text:
            //     printTicketData.shippingMethodName +
            //     ' ' +
            //     printTicketData.location,
            //   fontSize: 8,
            //   italics: true,
            // },
          ],
          margin: [15, 0, 15, 0],
          alignment: 'left',
          // fontSize: 10,
        };
      },
      /*#endregion*/

      /* #region CONTENT */

      content: [
        /* #region Section 1 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          columns: [
            {
              width: '33%',
              text: 'Order No:\t' + printTicketData.mmRefOrderId ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'INS:\t' + printTicketData.insuranceName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'H:\t' + this.formatPhoneNumber(printTicketData?.homePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 10, 0, 3], // [left, top, right, bottom]
        },
        //Row 2
        {
          columns: [
            {
              width: '33%',
              text: 'Account No:\t' + printTicketData.chartNo ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'DOB:\t' +
                (printTicketData?.dateOfBirth != null
                  ? this.datepipe.transform(
                      printTicketData?.dateOfBirth,
                      'MM/dd/yyyy'
                    )
                  : ''),
              fontSize: 10,
              style: 'columnHeader',
            },
            {
              width: '33%',
              text:
                'W:\t' + this.formatPhoneNumber(printTicketData?.workPhone) ??
                '',
              fontSize: 10,
              style: 'columnHeader',
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Patient Name:\t' + printTicketData.patientName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'e-mail:\t' + (printTicketData?.emailId ?? ''),
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'M:\t' + this.formatPhoneNumber(printTicketData?.mobilePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 4
        {
          columns: [
            {
              width: '50%',
              text: 'Address:\t' + printTicketData?.fullAddress ?? '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 10],
        },

        /* #endregion*/
        /* #region Section 2 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          text:
            'Contact ' + printTicketData?.technicianName + ' at 281-637-2286',
          fontSize: 16,
          bold: true,
          alignment: 'center',
          color: '#800000',
          margin: [0, 5],
        },
        //Row 2
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        /* #endregion*/
        /* #region Section 3 */
        //Row 1
        {
          width: '33%',
          text: 'Pressure Settings',
          italics: true,
          fontSize: 8,
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        //Row 2
        {
          table: {
            headerRows: 1,
            widths: [
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
            ],
            body: [
              [
                {
                  text: 'Min Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Max Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Setting',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'IPAP', fontSize: 6, alignment: 'center', bold: true },
                { text: 'EPAP', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'Ramp Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Ramp Time',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'BiFlex',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Set Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'CFlex', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'RR/BPM',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Min',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Max',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'SetVt', fontSize: 6, alignment: 'center', bold: true },
                { text: 'Rate', fontSize: 6, alignment: 'center', bold: true },
              ],
              [
                {
                  text:
                    this.printTicketData?.settingsDetails?.minPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.maxPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.settlingTime ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.epap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.rampPressure ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.rampTime ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.biFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.setPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.cFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rrbpm ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmin ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmax ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.setVT ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rate ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Technician: ' + printTicketData?.technicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text: 'Physician: ' + printTicketData?.physicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text:
                'Referral Practice: ' + printTicketData?.referralPractice ?? '',
              fontSize: 8,
              alignment: 'center',
            },
          ],
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        /* #endregion */

        /*#region Section 4 */
        {
          table: {
            headerRows: 1,
            // widths: ['15%', '5%', '10%', '45%', '10%', '15%'],
            widths: ['10%', '12%', '44%', '10%', '9%', '15%'],
            body: [
              [
                // {
                //   text: 'Location',
                //   bold: true,
                //   fontSize: 10,
                //   color: '#800000',
                // },
                { text: 'Qty', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: 'Product Code',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Product Description',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'HCPC',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                // { text: 'Size', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: printTicketData?.isOrderComplete ? 'Allowable' : 'Exch',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Serial #/Lot #',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
              ],
              ...this.printTicketData?.productDetailsDTO?.map((item) => [
                // item.location,
                item.quantity,
                item.productCode,
                item.productName,
                // item.sizeName,
                item.hcpc,
                printTicketData?.isOrderComplete
                  ? item.allowables
                  : {
                      image:
                        'data:image/png;base64,' +
                        this.getSquareImageBase64(item.exchange),
                      width: 10,
                      height: 10,
                      alignment: 'center',
                    },
                item.serialLotNo,
              ]),
            ],
          },
          style: {
            fontSize: 10,
            margin: [0, 2],
          },
          margin: [0, 5, 0, 0],
        },
        /* #endregion */

        /* #region Section 5 */
        {
          columns: [
            {
              width: '60%',
              text: [
                {
                  text: '\nComments to Patients:\n',
                  color: '#800000',
                  fontSize: 12,
                },
                {
                  text: printTicketData?.commentsToPatient ?? '',
                  fontSize: 8,
                },
              ],
              fontSize: 14,
              bold: true,
              alignment: 'left',
            },

            {
              width: '40%',
              table: {
                headerRows: 1,
                // widths: ['80%', '20%'],
                widths: ['72%', '28%'],
                body: [
                  [
                    {
                      text: 'Est Allowable:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAllowable ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      border: [false, false, false, false],
                      alignment: 'left',
                    },
                  ],
                  [
                    {
                      text: 'Est Copay:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estCopay ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Total Accessory:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.totalAccessory ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Shipping Fee:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.shippingFee ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],

                  [
                    {
                      text: 'Est Amount Due:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAmountDue ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Amount Paid:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.amountPaid ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],
                  [
                    {
                      text: 'Balance:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.patientBalance ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 4 || i === 6 ? 1 : 0; // Thickness of the dotted line for the second row bottom border
                },
                hLineColor: function (i, node) {
                  return '#800000';
                },
                hLineStyle: function (i, node) {
                  if (i === 4 || i === 6) {
                    return { dash: { length: 5, space: 5 } }; // Dotted style for the second row bottom border
                  }
                  return null;
                },
              },
            },
          ],
        },
        /* #endregion*/
      ],
      /* #endregion */

      // pageMargins: [15, 50, 15, 40],
      pageMargins: [15, 60, 15, 60],
      defaultStyle: {
        font: 'Roboto',
      },
    };

    this.displayPdf(docDefinition, 'Ticket');
  }

  private getSquareImageBase64(excahngeItem): string {
    if (excahngeItem === true) {
      // Convert the image to Base64 format (using a base64 string or FileReader)
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');

      // Draw outer border with light sea green color
      ctx.fillStyle = '#000000'; // Light Sea Green color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with light sea green

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas

      // Draw check mark (using a simple path)
      ctx.strokeStyle = '#20B2AA'; // Black color
      ctx.beginPath();
      ctx.moveTo(2, 4);
      ctx.lineTo(4, 6);
      ctx.lineTo(8, 2);
      ctx.stroke();

      return canvas.toDataURL('image/png').split(',')[1];
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#000000'; // Border color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // Square color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
      return canvas.toDataURL('image/png').split(',')[1];
    }
  }
  // private getSquareImageBase64(): string {
  //   // Convert the image to Base64 format (using a base64 string or FileReader)
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 10;
  //   canvas.height = 10;
  //   const ctx = canvas.getContext('2d');
  //   ctx.fillStyle = '#000000'; // Border color
  //   ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

  //   // Draw inner white square (to create border effect)
  //   ctx.fillStyle = '#FFFFFF'; // Square color
  //   ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
  //   return canvas.toDataURL('image/png').split(',')[1];
  // }
  //Method to display the PDF Content
  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition); //.download(filename);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }
  private formatPhoneNumber(value: string): string {
    if (value === null) return '';
    const phoneNumberRegex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberRegex.exec(value);

    if (!matches) return value;

    // Format the phone number with hyphens
    const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
    return formattedNumber;
  }
  /* #endregion */

  /* #region Order History */

  getOrderHistory(
    orderId: string,
    mmOrderId: string,
    chartId: string,
    patientName: string
  ) {
    const config: MatDialogConfig = {
      height: '70vh',
      width: '40vw',
      disableClose: true,
      data: {
        orderId: orderId,
        mmOrderId: mmOrderId,
        patientName: patientName,
        chartId: chartId,
      },
    };
    const dialogRef = this.dialog.open(OrderHistoryModalComponent, config);
  }

  /* #endregion */

  /* #region  Authorization*/

  viewAuthorization() {
    // console.log(this.orderForm.value.drpAuthorization);
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent, {
      data: {
        documentId: this.documentId,
        // defaultFaxId:data?.defaultFaxId
      },
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = this.patientId;
    componentInstance.verificationId = this.priVerificationId;
    componentInstance.docId = this.selectedShippingData?.faxDocumentId;
    componentInstance.faxId = ''; //this.selectedShippingData?.defaultFaxId;
    componentInstance.isView = false;
    componentInstance.isSupplies = true;
    componentInstance.authorizationId = this.authorizationId;
    componentInstance.chartId = this.selectedShippingData?.chartno;
  }

  /* #endregion */
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
export interface doctorInterface extends DoctorDTO {
  id: string;
  name: string;
}
