<div class="row">
    <div class="col-lg-12" *ngIf="shippingRejectedHide">
        <h2 class="customThemeClass">Shipping Rejected Reason</h2>
    </div>
    <div class="col-lg-12" *ngIf="returnRejectedHide">
        <h2 class="customThemeClass"> Shipping Return Rejected Reason</h2>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-12 text-align-center">
            <mat-card-content >
               <h3><b><span class="customThemeClass"> Reason :  </span>{{data.rejectedReason}}</b></h3> 
            </mat-card-content>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">    


    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>