import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { defer } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';
import { PDFDocument } from 'pdf-lib';
import {
  concatMap,
  groupBy,
  mergeMap,
  takeLast,
  toArray,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import {
  CreateUpdateInboundDocumentDTO,
  InboundDocumentDTO,
} from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import {
  inboundDocumentStatus,
  inboundDocumentStatusOptions,
} from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/inbound-document-status.enum';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-inbound-documents-tab',
  templateUrl: './add-inbound-documents-tab.component.html',
  styleUrls: ['./add-inbound-documents-tab.component.scss'],
})
export class AddInboundDocumentsTabComponent implements OnInit {
  fileId: string = '';
  addDocumentbinModalTitle: string = 'Upload Inbound Documents';
  inboundUploadForm: FormGroup;
  showErrorBasedOnSize: boolean = false;
  showErrorForFileType: boolean = false;
  showErrorForFileLength: boolean = false;
  selectedFileUploadsData: Partial<InboundDocumentDTO> | null = null;
  arrSelectedDocuments: any[] = [];
  arrPageCount: number[] = [];
  noErrors: boolean = false;
  chkdisableButton: boolean = false;
  constructor(
    private fb: FormBuilder,
    private table: TableService,
    public dialog: MatDialog,
    public inboundDocUploadService: InboundDocumentService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.inboundUploadForm = this.fb.group({
      documentFile: new FormControl(null),
      txtComments: new FormControl(''),
      chkStat: new FormControl(""),
    });
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
  }

  async getSelectedFilesDetails(files: any) {
    this.arrSelectedDocuments = [];
    this.table.setSelectedDocumentList([]);
    let fileResult: {
      base64Value: string;
      fileName: string;
      fileType: string;
      fileSize: string;
      pageCount?: number;
    }[] = [];
    this.noErrors = true;
    for (let index = 0; index < files.length; index++) {
      if (files[index].size > DocumentMaxandTypeLimits.documentFileSizeLimit) {
        this.showErrorBasedOnSize = true;
        this.showErrorForFileType = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];

        return;
      }
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorBasedOnSize = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];

        return;
      }

      if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.showErrorBasedOnSize = false;
        this.arrSelectedDocuments = [];

        return;
      }
      if (files[index].size > DocumentMaxandTypeLimits.documentFileSizeLimit) {
        this.showErrorBasedOnSize = true;
        this.showErrorForFileType = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];
        return;
      }
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = false;
      this.showErrorForFileLength = false;
      let fileResultValue: string = '';
      const fileName: string = files[index].name;
      const m1fileName: string = fileName.replace('.pdf', '');
      const m2fileName: string = m1fileName.replace('.pdf', '');
      let reader = new FileReader();
      reader.readAsDataURL(files[index]);

      let modifiedElement: {
        base64Value: string;
        fileName: string;
        fileType: string;
        fileSize: string;
        pageCount?: number;
      };
      reader.onload = () => {
        fileResultValue = reader?.result?.slice(28)?.toString() ?? '';
             // Define a function to get the PDF page count
        const getPDFPageCount = async (file: File) => {
          const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
          const pages = pdfDoc.getPages();
          return pages.length;
        };

       getPDFPageCount(files[index]).then(pageCount => {
        modifiedElement = {
          base64Value: fileResultValue,
          fileName: m2fileName,
          fileType: files[index].type === 'application/pdf' ? 'PDF' : '',
          fileSize: this.formatFileSize(files[index].size, 3),
          pageCount: pageCount,
        };
        this.arrPageCount.push(pageCount);
        fileResult.push(modifiedElement);
      });
    };

      this.arrSelectedDocuments = fileResult;
    }
    this.noErrors = false;
  }
  deleteDocument(e) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const index = this.arrSelectedDocuments.indexOf(e);
        if (index !== -1) {
          this.arrSelectedDocuments.splice(index, 1);
        }
      }
    });
  }

  formatFileSize(bytes: number, decimalPoint: number) {
    if (bytes == 0) return '0 Bytes';
    if (isNaN(bytes)) return '0 Bytes';
    const k = 1024;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async getPDFPageCount(){

  }
  resetInboundDocumentForm() {
    this.inboundUploadForm.reset({
      documentFile: null,
      txtComments: '',
    });
    this.selectedFileUploadsData = null;
    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    this.showErrorBasedOnSize = false;
    this.arrSelectedDocuments = [];
    this.chkdisableButton = false;
    this.addDocumentbinModalTitle = 'Upload Inbound Documents';
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
  }
  saveInboundDocuments(files: any) {
    if (this.fileId === '') {
      if (files.length === 0) {
        return;
      }
      this.saveFunction();
    }
  }
  saveFunction() {
    this.chkdisableButton = true;

    let FileUploadsInput: CreateUpdateInboundDocumentDTO[] = [];
    const uploadedById: string = localStorage.getItem('userId') ?? '';

    this.arrSelectedDocuments?.forEach((element,index) => {
      FileUploadsInput.push({
        defaultDocumentId: '',
        fileContentAsBase64String: element?.base64Value,
        uploadedDate: new Date().toISOString(),
        uploadedBy: uploadedById,
        documentName: element?.fileName ?? '',
        blobName: '',
        comments: String(
          this.inboundUploadForm?.value?.txtComments ?? ''
        ).trim(),
        documentStatus: <inboundDocumentStatus>(
          inboundDocumentStatusOptions?.filter(
            (i) => i.key === 'ProcessPending'
          )?.[0]?.key
        ),
        isExistingPatient: false,
        isStat: this.inboundUploadForm.value.chkStat === true ? 1 : 0,
        docPageCount : this.arrPageCount[index]
      });
    });
    from(FileUploadsInput)
      .pipe(
        groupBy((v) => v.documentName),
        mergeMap((group) => group.pipe(toArray())),
        mergeMap((group) =>
          from(group).pipe(
            concatMap((v) =>
              defer(() => this.inboundDocUploadService.create(v))
            )
          )
        ),
        takeLast(1)
      )
      .subscribe(
        (value) => {
          this.toastr.success('Saved Successfully', 'Suceess');

          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // }).finally(() => {
          // this.resetInboundDocumentForm();
          this.dialog.closeAll();
          // });
          this.chkdisableButton = false;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.dialog.closeAll();
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.chkdisableButton = false;
        }
      );
  }
}
