import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-era-posting',
  templateUrl: './era-posting.component.html',
  styleUrls: ['./era-posting.component.scss']
})
export class ERAPostingComponent implements OnInit {
public strPageType: string="eraPayment";
public strPageType2: string="eraPayment2";
  constructor(private title:Title) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | ERA Posting");

  }

}
