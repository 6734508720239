import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-complaince-call-details',
  templateUrl: './complaince-call-details.component.html',
  styleUrls: ['./complaince-call-details.component.scss']
})
export class ComplainceCallDetailsComponent implements OnInit {

  @Input() tableData: any;
  callLogTableData: any[] = [];
  dtCallLogOptions:any;
  constructor(
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.dtCallLogOptions = {
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 100],
      bFilter: false,
      paging: false,
      info: false,
      scrollCollapse: true,
      scrollY: '21vh',
    };
    this.callLogTableData = this.tableData;
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      : '-';
  }
}
