<mat-dialog-content class="mat-typography">
  <div class="modal-header">
    <h1 class="modal-title head" >Disapproval Notes</h1>
    <button class="text-right close" aria-label="Close" mat-dialog-close>
      <b> X </b>
    </button>
  </div>
  <form [formGroup]="notesForm">
    <div class="modal-body">
        <div class="row">
          <mat-form-field class="col-12">
            <mat-label>Notes<span class="asterisk">*</span></mat-label>
            <textarea
              formControlName="txtComment"
              type="text"
              rows="6"
              maxlength="5000"
              autocomplete="off"
              matInput
              [(ngModel)]="notesText"
              (input)="updateCharacterCount(5000)"
            ></textarea>
            <mat-error
              *ngIf="notesForm?.get('txtComment')?.errors?.required">
               Note is a required field!
            </mat-error>
          </mat-form-field>
          <div class="char-count">{{ notesText?.length }} / 5000</div>
        </div>
    </div>
  </form>
  <div class="modal-footer">
   <!-- Buttons -->
   <div class="col-lg-12  d-flex justify-content-center btncls">
    <ng-container class="col-12" *ngIf="!notesForm?.disabled">
      <button mat-button
      [disabled]="notesForm.invalid||saveButton"
        class="buttonColor"  (click)="saveNotes()"
      >
        Save
      </button>
      <button mat-button class="resetclr ml-2" (click)="Reset()">Reset</button>
    </ng-container>
  </div>
  </div>

</mat-dialog-content>
