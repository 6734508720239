import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { arAgingInsuranceWiseReportDTO, arAgingPatientWiseReportDTO, arAgingReportDTO, billingReportDTO } from '../dto/models';
import { arAgingFilter } from '../x12/enumeration-data/ar-aging-filter.enum';

@Injectable({
  providedIn: 'root',
})
export class MISReportsService {
  apiName = 'ClaimProcessingManagement';

  arAgingReport = () =>
    this.restService.request<any, arAgingReportDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/m-iSReports/ar-aging-report',
    },
    { apiName: this.apiName });

  billingReport = () =>
    this.restService.request<any, billingReportDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/m-iSReports/billing-report',
    },
    { apiName: this.apiName });
    arAgingReportInsuranceWiseByFilter = (filter: arAgingFilter) =>
    this.restService.request<any, arAgingInsuranceWiseReportDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/m-iSReports/ar-aging-report-insurance-wise',
      params: { filter },
    },
    { apiName: this.apiName });

  arAgingReportPatientWiseByFilter = (filter: arAgingFilter) =>
    this.restService.request<any, arAgingPatientWiseReportDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/m-iSReports/ar-aging-report-patient-wise',
      params: { filter },
    },
    { apiName: this.apiName });


    patientAgingSummaryByPatientId = (patientId: string) =>
    this.restService.request<any, arAgingPatientWiseReportDTO>({
      method: 'POST',
      url: `/api/claimProcessingApp/m-iSReports/patient-aging-summary/${patientId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
