<mat-dialog-content class="mat-typography">
    <div class="modal-header">
        <h1 class="modal-title">Insurance History</h1>
        <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
    </div>
    <div class="card card-body">
        <!-- Table Section -->
        <div class="row mt-3">
            <!-- Loader Section -->
            <div class="col-12" *ngIf="isLoading">
                <div class="d-flex justify-content-center">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
            <div class="col-12" *ngIf="!isLoading">
                <app-patient-insurance-history-table
                    [tableData]="insuranceHistorydata"></app-patient-insurance-history-table>
            </div>
        </div>
    </div>
</mat-dialog-content>