<div class="col-12">
    <div class="row">
<h1 mat-dialog-title class="col-10 tittle"><b>Add Notes</b></h1>
<div class="col-2" style="text-align-last: right">
    <button mat-icon-button mat-dialog-close=" close-button ">
        <mat-icon class="mr-2">close</mat-icon>
      </button>
</div>
</div>
</div>
<mat-dialog-content class="mat-typography" style="height:70% !important">
    <div class="container-fluid">

    <div class="col-12">
        <div class="card card-body">
        <form [formGroup]="notesForm">
            <div class="col-lg-12">

                <div class="row">
                  <!-- Notes Type-->
                  <mat-form-field class="col-6">
                    <mat-label>
                      Notes Type <span class="asterisk">*</span>
                    </mat-label>
                    <mat-select
                      maxlength="40"
                      [(value)]="notetype"
                      formControlName="drpNoteType"
                    >
                      <mat-option
                        *ngFor="let note of drpNoteType"
                        [value]="note.key"
                      >
                        {{ note.value }}
                      </mat-option>
                    </mat-select>




                  </mat-form-field>

                  <div class="col-6">
                    <br />
                    <mat-checkbox formControlName="chkPrecertRequired"
                      >patient Call</mat-checkbox
                    >
                  </div>

                  <mat-form-field  class="col-6">
                    <mat-label>Notes</mat-label>
                    <textarea formControlName="txtNote" rows="2" maxlength="5000"
                     matInput></textarea> <div class="char-count">

                    </div>
                  </mat-form-field>
                  <!-- Notes Reason-->

                </div>

            </div>
          </form><br>
          <br />
          <!-- <div class="col-sm-12">
            <div class="text-lg-center">
              <button
                mat-button
                class="buttonColor mb-2 mr-2"


              >
                Save

              </button>
              <button
                mat-button
                class="resetclr buttonColor mb-2 mr-2"

              >
                Reset
              </button>
              </div>
              </div> -->
            </div>
    </div>
</div>
</mat-dialog-content>
<mat-dialog-actions  align="end">
    <button mat-button  mat-raised-button class="buttonColor">Save</button>
    <button mat-button  mat-raised-button class="resetclr buttonColor">Reset</button>
    <!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
</mat-dialog-actions>
