import type { CreateUpdateDocumentSortingDto, DocumentSortingDto, SaveDocumentDto } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentSortingService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateDocumentSortingDto) =>
    this.restService.request<any, DocumentSortingDto>({
      method: 'POST',
      url: '/api/PlatformApp/document-sorting',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/document-sorting/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, DocumentSortingDto>({
      method: 'GET',
      url: `/api/PlatformApp/document-sorting/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<DocumentSortingDto>>({
      method: 'GET',
      url: '/api/PlatformApp/document-sorting',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateDocumentSortingDto) =>
    this.restService.request<any, DocumentSortingDto>({
      method: 'PUT',
      url: `/api/PlatformApp/document-sorting/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  saveSortedDocumentsByInboundDocumentIdAndDocumentSortingInput = (inboundDocumentId: string, documentSortingInput: SaveDocumentDto[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/PlatformApp/document-sorting/save-sorted-documents/${inboundDocumentId}`,
      body: documentSortingInput,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
