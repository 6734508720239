import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';

@Component({
  selector: 'app-return-orders-table',
  templateUrl: './return-orders-table.component.html',
  styleUrls: ['./return-orders-table.component.scss'],
})
export class ReturnOrdersTableComponent implements OnInit {
  @Input() ltReturnOrders: any;
  dtReturnTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    // fixedHeader: true, // Fix the header

    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Return Orders List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
          // columns: [1, 2, 3, 4, 5, 6, 7],
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle

      },
    ],
    columnDefs: [
      { targets: [0, 1, 2, 3,  -1], className: 'dt-fixed-column' }, 
    ]
    // columnDefs: [
    //   { targets: 0, orderable: false }, // Targets the first column (index 0)
    //   // Add other columnDefs as needed
    // ],
  };

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(private communicationService: CommunicationService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.dtReturnTableOptions.buttons[0].filename =
    'Return Orders List' +  '-' + this.datePipe.transform(new Date(),'MM-dd-yyyy') ;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.60; // Adjust the percentage as needed
    this.dtReturnTableOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  getReturnOrderItems(orderId: string) {
    this.communicationService.triggerReturnItemsList(orderId);
  }
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
}
