import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { DocumentTypeDTO, ReviewReasonDTO, ReviewStatusDTO, SortingTypeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { DocumentsService } from 'projects/patient/src/app/patient-proxy/controllers/document.service';
import { DocumentService, PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { PatientDocumentDTO, PatientDTO, SavePatientDocumentBlobDTO, UpdateDocumentBlobDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { billingStatus, DocumentMaxandTypeLimits, DocumentUploadTypes, OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { combineLatest, defer, from, Observable, of, SubscribableOrPromise, Subscription } from 'rxjs';
import { concatMap, groupBy, map, mergeMap, reduce, takeLast, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { SaleorderService } from '../order-proxy/order-management/order';
import { SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { updateOrder } from '../order-store/order.action';

let listOfDocuments: PatientDocumentDTO[] = [];
@Component({
  selector: 'app-order-document',
  templateUrl: './order-document.component.html',
  styleUrls: ['./order-document.component.scss'],
  providers: [ListService]
})
export class OrderDocumentComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  isShowSpinner: boolean = false;
  @Input() orderId: string = '';
  @Input() orderStatus: boolean = true;
  @Input() patientID: string = '';
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  drpDocumentUploadType: SortingTypeDTO[] = [];
  showReason: boolean = false;
  show: boolean = false;
  @Input() strPageType: string = '';
  intCheckedDocuments: number = 0;
  strSelectedPdfPath: string = "";
  documentForm: FormGroup;
  documentGroupedByType: documentGroupIngListType[] = [];
  isCheckAllDocuments: boolean = false;
  isDocumentEditSaveButtonHidden: boolean = true;
  arrUnsortedDocumentList: PatientDocumentDTO[] = [];
  arrSortedDocumentList: documentListType[] = [];
  drpDocumentTypeLoop: DocumentTypeDTO[] = [];
  drpReviewStatusLoop: ReviewStatusDTO[] = [];
  drpReviewReasonLoop: ReviewReasonDTO[] = [];
  patientDataSubcription$: Observable<PatientDTO>
  isShown: boolean = false;
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  billingDocumentId: string = "";
  dropdownDataSubcription$: Observable<{ reviewReasons: ReviewReasonDTO[]; reviewStatuses: ReviewStatusDTO[]; documentTypes: DocumentTypeDTO[]; }>
  showFileList: any[] = [];
  showErrorForFileType: boolean;
  showExtensionInfo: string = "";
  showErrorForExtension: boolean = false;
  isDocumentForButtonDisable: boolean = false;
  nameAndSizeArray: any[] = [];
  fileLength: number = 0;
  filesname: string[] = [];
  showErrorForFileLength: boolean = false;
  showErrorBasedOnSize: boolean = false;
  patientDocumentId: string = "";
  parentPatientDocumentID: string = "";
  isPatientUpdatePermission: boolean = false;
  isShownUpdateIconForPatient: boolean = true;
  isShownSaveButton: boolean = true;
  selectedDocumentforUpdate: PatientDocumentDTO = null;
  subscription$: Subscription[] = [];
  public arrDisplayedColumns: string[] = [];
  isShownDocumentDeleteIcon = true;
  documebntslist: PatientDTO;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>
  @Input() strPdfSource: string = '';
  constructor(
    private documentService: DocumentService,
    private documentServices: DocumentsService,
    private patientService: PatientService,
    private ngZone: NgZone,
    private patientDropdownService: PatientMasterDropdownService,
    private fb: FormBuilder,
    public list: ListService,
    private table: TableService,
    private saleOrderService: SaleorderService,
    private toastr: ToastrService,
    private store: Store,
  ) {
    this.getDropdown();
  }
  // alert for reload
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    return false;
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  createDocumentForm() {
    this.documentForm = this.fb.group({
      drpReviewStatus: new FormControl(null),
      documentFile: new FormControl(null),
      drpReviewReason: new FormControl(null),
      drpDocumentType: new FormControl(null),
      txtDocumentStartDate: new FormControl("", [Validators.required]),
      txtDocumentEndDate: new FormControl("", [Validators.required]),
      txtNote: new FormControl(""),
      rbtnDocument: new FormControl(""),
      txtReason: new FormControl(""),
      drpDocumentUploadType: new FormControl(null, [Validators.required]),
    })


    const getDocumentFormData = this.table.getDocumentFormData().pipe().subscribe(resposne => {
      if (resposne && resposne.filenames && resposne.value) {
        this.documentForm.enable();
        this.filesname = resposne.filenames;
        this.isShowSpinner = true;
        this.isDocumentEditSaveButtonHidden = false;
        this.documentForm.patchValue({
          drpReviewStatus: resposne.value.drpReviewStatus,
          drpReviewReason: resposne.value.drpReviewReason,
          drpDocumentType: resposne.value.drpDocumentType,
          txtDocumentStartDate: resposne?.value.txtDocumentStartDate,
          txtDocumentEndDate: resposne?.value.txtDocumentEndDate,
          txtNote: resposne.value.txtNote,
          drpDocumentUploadType: resposne.value.drpDocumentUploadType,
        })
      } else {
        this.resetDocumentForm();
      }

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getDocumentFormData);
    this.docTypeform()?.valueChanges?.subscribe(value => {
      const documentType: string = value ?? "";
      if (documentType === 'RX' || documentType === "FACE TO FACE") {

        this.documentForm?.controls?.txtDocumentStartDate?.setValidators([Validators.required]);
        this.documentForm?.controls?.txtDocumentEndDate?.setValidators([Validators.required]);
        this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
        this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();


      } else {
        this.documentForm?.controls?.txtDocumentStartDate?.setValidators([Validators.nullValidator]);
        this.documentForm?.controls?.txtDocumentEndDate?.setValidators([Validators.nullValidator]);

        this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
        this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();


      }
    })
  }

  docTypeform(): AbstractControl {
    return this.documentForm?.get("drpDocumentType")
  }
  documentTypeDropdownChange() {
    if (this.documentForm?.get('drpDocumentUploadType').value === DocumentUploadTypes.AutoUploadType) {
      this.documentForm.patchValue({ drpDocumentType: null });
      this.documentForm?.get('drpDocumentType').setValidators([]);
      this.documentForm?.get('drpDocumentType').disable({ onlySelf: true });
      this.documentForm.updateValueAndValidity();
    }
    else if (this.documentForm?.get('drpDocumentUploadType').value === DocumentUploadTypes.ManualUploadType) {
      this.documentForm?.get('drpDocumentType').setValidators([Validators.required]);
      this.documentForm?.get('drpDocumentType').enable({ onlySelf: true });
      this.documentForm.updateValueAndValidity();
    }
  }
  getDocumentsByOrderId() {

    let patientDetails: SaleorderDTO;
    if (this.orderId !== "" && this.orderId !== null && this.orderId !== undefined) {
      const saleOrderGet = this.saleOrderService.get(this.orderId).subscribe(response => {
        const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
        this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (response?.orderBilling?.status)))
        //  this.orderStatus = (response?.orderBilling?.status === billingStatus.ReadyToBilling || response?.orderBilling?.status === billingStatus.OrderPending) ? true: false;
        patientDetails = response;
        this.patientID = patientDetails?.patientId;
        this.patientIdEmitter.emit(this.patientID);
        this.getDocumentsByPatientId();
        if (patientDetails?.currentStageStatus === "Disapproved") {
          this.showReason = true
        }
        if (this.strPageType === "shippingApproval") {
          this.documentForm.patchValue({
            rbtnDocument: patientDetails?.currentStageStatus,
            txtReason: patientDetails?.documentReason
          })

        }
        // this.documebntslist = patientDetails?.patients;

        // this.patientDataSubcription$ = of(patientDetails?.patients);
        //  this.getDocumentsListByPatientId(patientDetails?.patients);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(saleOrderGet);
    }
  }
  getfilename(files: FileList): void {
    this.filesname = [];
    for (let index = 0; index < files.length; index++) {
      if (files[index].size > DocumentMaxandTypeLimits.documentFileSizeLimit) {
        this.showErrorBasedOnSize = true;
        this.showErrorForFileType = false;
        this.showErrorForFileLength = false;
        this.filesname = [];
        this.documentForm.patchValue({
          docPopUpFiles: null
        })
        return;
      };
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorBasedOnSize = false;
        this.showErrorForFileLength = false;

        this.filesname = [];
        this.documentForm.patchValue({
          docPopUpFiles: null
        })
        return;
      };

      if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.showErrorBasedOnSize = false;
        this.filesname = [];
        this.documentForm.patchValue({
          docPopUpFiles: null
        })
        return;
      };



      if (files[index].name.length > 15) {
        this.filesname.push(files[index].name.slice(0, 20) + "...");
      } else {
        this.filesname.push(files[index].name);
      }
    }
    this.intCheckedDocuments = files.length;
    this.isDocumentEditSaveButtonHidden = false;
    this.documentForm.enable();
    this.isShown = true;

  }
  upload(files: any) {
    const startDateValue = this.documentForm?.get("txtDocumentStartDate")?.value;
    const endDateValue = this.documentForm?.get("txtDocumentEndDate")?.value;
    const startDate: string | null = (startDateValue === '') ? null : String(new Date(startDateValue).toISOString());
    const endDate: string | null = (endDateValue === '') ? null : String(new Date(endDateValue).toISOString());
    if (this.patientDocumentId === "" && this.selectedDocumentforUpdate === null) {
      if (files.length === 0) {
        return;
      }
      //! Manual Sort Save Upload Part//
      let fileResult: { name: File, value: string, savedOrNot: boolean }[] = [];
      for (let file of files) {
        let reader = new FileReader();
        this.ngZone.runOutsideAngular(() => {
          reader.readAsDataURL(file);
        })
        reader.onload = () => {
          fileResult.push({ name: file, value: reader.result.slice(28).toString(), savedOrNot: false });
          if (fileResult.length === files.length) {
            this.table.setDocumentFormData({ value: this.documentForm.value, filenames: this.filesname });
            this.saveManualDocuments(fileResult, startDate, endDate);
          }
        }
      }

    } else {
      this.isShowSpinner = true;
      //! Document edit Part//
      let patientDocumentBlobDTO: UpdateDocumentBlobDTO = {
        fileName: this.selectedDocumentforUpdate.fileName,
        patientDocumentId: this.patientDocumentId,
        documentTypeId: this.patientID,
        documentUploadType: this.documentForm?.get("drpDocumentUploadType").value,
        parentPatientDocumentID: null,
        tags: this.selectedDocumentforUpdate.tags,
        documentStartDate: startDate,
        documentEndDate: endDate,
        documentType: this.documentForm?.get("drpDocumentType").value == null ? "" : this.documentForm?.get("drpDocumentType").value,
        reviewStatus: this.documentForm?.get("drpReviewStatus").value == null ? "" : this.documentForm?.get("drpReviewStatus").value,
        reviewReason: this.documentForm?.get("drpReviewReason").value == null ? "" : this.documentForm?.get("drpReviewReason").value,
        note: this.documentForm?.get("txtNote").value,
        approvalStatus: this.documentForm.value.rbtnDocument,
        approvedByWhom: "",
        approvedOn: "",
        isVerified: 0,
        documentOrderNumber: 0,
        isSorted: this.selectedDocumentforUpdate.isSorted,

      };
      if (this.parentPatientDocumentID !== "") {
        patientDocumentBlobDTO = { ...patientDocumentBlobDTO, parentPatientDocumentID: [this.parentPatientDocumentID] };
      }
      this.documentServices.editFileByPatientDocumentBlobDTOAndPatientObjectID(patientDocumentBlobDTO, this.patientID).subscribe(() => {
        this.patientDocumentId = "";
        this.selectedDocumentforUpdate = null;
        this.parentPatientDocumentID = "";
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.resetDocumentForm();
        this.getDocumentsByPatientId();
        this.isShowSpinner = false;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isShowSpinner = false;
      })
    }
  }
  saveManualDocuments(fileResult: { name: File, value: string, savedOrNot: boolean }[], startDate: string | null, endDate: string | null) {
    this.isShowSpinner = true;
    let documentArrayToBeSaved: { patientDocumentBlobDTO: SavePatientDocumentBlobDTO; patientObjectID: string; }[] = [];
    fileResult.forEach((file, index) => {
      if (!file.savedOrNot) {
        let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO = {
          patientID: this.patientID,
          fileName: file.name.name,
          fileContentAsBase64String: file.value,
          documentTypeId: this.patientID,
          documentUploadType: this.documentForm?.get("drpDocumentUploadType").value,
          parentPatientDocumentID: null,
          documentOrderNumber: index,
          documentType: this.documentForm?.get("drpDocumentType").value == null ? "" : this.documentForm?.get("drpDocumentType").value,
          reviewStatus: this.documentForm?.get("drpReviewStatus").value == null ? "" : this.documentForm?.get("drpReviewStatus").value,
          reviewReason: this.documentForm?.get("drpReviewReason").value == null ? "" : this.documentForm?.get("drpReviewReason").value,
          note: this.documentForm?.get("txtNote").value,
          approvalStatus: this.documentForm.value.rbtnDocument,
          approvedByWhom: "",
          approvedOn: "",
          isVerified: 0,
          isSorted: 0,
          documentStartDate: startDate,
          documentEndDate: endDate,
        };
        if (this.parentPatientDocumentID !== "") {
          savePatientDocumentBlobDTO = { ...savePatientDocumentBlobDTO, parentPatientDocumentID: [this.parentPatientDocumentID] };
        }
        let documentToBeSaved = { patientDocumentBlobDTO: savePatientDocumentBlobDTO, patientObjectID: this.patientID };
        documentArrayToBeSaved.push(documentToBeSaved);
      }
    })

    from(documentArrayToBeSaved).pipe(
      groupBy(v => v.patientObjectID),
      mergeMap(group => group.pipe(toArray())),
      mergeMap(group => from(group).pipe(
        concatMap((v) => defer(() => this.documentServices.fileUploadByPatientDocumentBlobDTOAndPatientObjectID(v.patientDocumentBlobDTO, v.patientObjectID))))
      ), takeLast(1)).subscribe(() => {
        this.parentPatientDocumentID = "";
        this.table.setDocumentFormData(null);
        this.isShowSpinner = false;
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Document");
          }
        }, () => { });
        this.resetDocumentForm();
        this.getDocumentsByPatientId();
        this.isShowSpinner = false;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isShowSpinner = false;
      })
  }
  ngOnInit(): void {
    this.arrDisplayedColumns = [ 'options','OrderId', 'ItemId', 'documentStartDate', 'documentEndDate', 'documentStatus',];
    const getDocumentDelete = this.table.getDocumentDelete().subscribe(value => {
      this.isPatientUpdatePermission = value;
      if (this.isPatientUpdatePermission == true) {
        this.isShownUpdateIconForPatient = true;
      }
      else {
        this.isShownUpdateIconForPatient = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getDocumentDelete);
    //
    const getPatientId = this.table.getSelectedPatientId().subscribe(resposne => {
      this.patientID = resposne;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getPatientId);
    //
    this.createDocumentForm();
    this.resetDocumentForm();
    (this.orderId !== "" && this.orderId !== null && this.orderId !== undefined) && this.getDocumentsByOrderId();
    const documentDelete = this.table.getDocumentDelete().subscribe(value => {
      if (value == true) {
        this.isShownDocumentDeleteIcon = true;
      }
      else {
        this.isShownDocumentDeleteIcon = false;
      }
    }, () => {
    });
    this.subscription$.push(documentDelete);
    //
  }

  getDropdown(): void {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.ReviewReasons,
      PatientDropdowns.ReviewStatus,
      PatientDropdowns.DocumentTypes,
      PatientDropdowns.SortingTypes
    ]
    this.dropdownDataSubcription$ = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).pipe(map(value => {
      let data = { reviewReasons: value?.reviewReasons, reviewStatuses: value?.reviewStatuses, documentTypes: value?.documentTypes, sortingTypes: value?.sortingTypes }
      this.drpDocumentUploadType = value.sortingTypes;
      return data
    }))
    this.dropdownDataSubcription$.subscribe(response => {
      if (response && response.reviewStatuses && response.reviewReasons && response.documentTypes) {
        this.drpDocumentTypeLoop = response.documentTypes;
        this.drpReviewStatusLoop = response.reviewStatuses;
        this.drpReviewReasonLoop = response.reviewReasons;
        if (this.patientID !== "" && this.patientID !== null && this.patientID !== undefined) { this.getDocumentsByPatientId(); }
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })

  }
  getDocumentsByPatientId(): void {
    let patientDetails: PatientDTO;
    if (this.patientID !== "" && this.patientID !== null && this.patientID !== undefined) {
      const patientGet = this.patientService.get(this.patientID).subscribe(response => {
        patientDetails = response;
        this.patientID = patientDetails?.id;
        this.patientIdEmitter.emit(this.patientID);
        this.documebntslist = patientDetails;
        this.patientDataSubcription$ = of(patientDetails);
        let patientDocuments = patientDetails?.patientDocuments?.filter(value => value && value?.documentTypeId !== ("44c5c789-2cee-f54c-ff4d-39fb0f4d5b92" || "00000000-0000-0000-0000-000000000000"));
        this.getDocumentsListByPatientId(patientDocuments);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(patientGet);
    }
  }
  getDocumentsListByPatientId(patientDocuments: PatientDocumentDTO[]): void {
    this.isLoading = true;
    const combinedData = combineLatest([this.patientDataSubcription$, this.dropdownDataSubcription$])
    combinedData.pipe(map(response => {
      this.arrUnsortedDocumentList = [];
      patientDocuments?.forEach((element: PatientDocumentDTO) => {
        if (element.documentUploadType === DocumentUploadTypes.ConsolidatedUpload || element.documentUploadType === DocumentUploadTypes.ManualUploadType) {
          element = this.extractShortNameAndDate(element);
          this.arrUnsortedDocumentList.push({ ...element });
          this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      });
      return response;
    })).subscribe(() => {
      this.documentGroupingFunction();
      listOfDocuments = patientDocuments;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.isLoading = false
  }

  documentGroupingFunction(): void {
    this.documentGroupedByType = [];
    const groupedDocument$ = from(this.arrUnsortedDocumentList).pipe(
      groupBy(v => v.documentType),
      mergeMap(group => group.pipe(
        reduce((acc, cur) => { acc.groupItem.push(cur); return acc; },
          { documentType: group.key, documentGroupedByType: this.drpDocumentTypeLoop.filter(v => v?.documentType === group.key)[0]?.documentType, groupItem: [] }))), toArray());

  }
  private extractShortNameAndDate(element: PatientDocumentDTO): PatientDocumentDTO {
    let resultDocStartDate: string = "";
    let resultDocEndDate: string = "";
    if (typeof element?.documentStartDate === "string") {
      const date = `${(new Date(element?.documentStartDate))?.toLocaleDateString("en-US")}`;
      const hours = `${(new Date(element?.documentStartDate))?.getHours()}`;
      const time = `${(new Date(element?.documentStartDate))?.getMinutes()}`;
      resultDocStartDate = date + "  " + hours + ":" + time;
    }
    if (typeof element?.documentEndDate === "string") {
      const date = `${(new Date(element?.documentEndDate))?.toLocaleDateString("en-US")}`;
      const hours = `${(new Date(element?.documentEndDate))?.getHours()}`;
      const time = `${(new Date(element?.documentEndDate))?.getMinutes()}`;
      resultDocEndDate = date + "  " + hours + ":" + time;
    }
    let resultedfileName = element.fileName;
    element = {
      patientDocumentID: element.patientDocumentID,
      patientID: element.patientID,
      blobName: element.blobName,
      tags: element.tags,
      documentTypeId: element.documentTypeId,
      reviewStatus: element.reviewStatus,
      reviewReason: element.reviewReason,
      note: element.note,
      documentUploadType: element.documentUploadType,
      parentPatientDocumentID: element.parentPatientDocumentID,
      documentType: element.documentType,
      approvalStatus: element.approvalStatus,
      approvedByWhom: element.approvedByWhom,
      approvedOn: element.approvedOn,
      fileName: resultedfileName,
      documentStartDate: resultDocStartDate,
      documentEndDate: resultDocEndDate,
      documentOrderNumber: element.documentOrderNumber,
      isVerified: element.isVerified,
      isSorted: element.isSorted,
      ...element
    };
    return element;
  }

  downloadFile(blobName: string, fileName: string): void {
    const documentGet = this.documentService.getPatientDocumentByBlobIDByBlobName(blobName).subscribe(response => {
      if (response && response !== null && response?.length !== 0) {
        let filelocation: String = String("data:application/pdf;base64," + response);
        let link = document.createElement("a");
        link.download = fileName;
        link.href = filelocation.toString();
        link.click();
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })
    this.subscription$.push(documentGet);
    //
  }
  viewPdfFile(selectedBlobName: string, patientDocumentId: string): void {
    this.show = false;
    this.patientDocumentId = patientDocumentId;
    this.getpatientDocumentByBlobID(selectedBlobName, patientDocumentId);
  }

  getpatientDocumentByBlobID(selectedBlobName: string, patientDocumentId: string): void {
    const documentGet = this.documentService.getPatientDocumentByBlobIDByBlobName(selectedBlobName).subscribe(response => {
      if (response && response !== null && response?.length !== 0) {
        this.strSelectedPdfPath = String("data:application/pdf;base64," + response);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })
    this.subscription$.push(documentGet);
    let selectedDocument: PatientDocumentDTO = listOfDocuments.filter((document) => { return document && (document?.patientDocumentID === patientDocumentId) })[0];
    this.selectedDocumentforUpdate = selectedDocument;
    (selectedDocument !== null || selectedDocument !== undefined) && this.documentForm.patchValue({
      drpDocumentUploadType: "Manual Sorting",
      txtDocumentStartDate: selectedDocument.documentStartDate == null ? "" : (selectedDocument.documentStartDate),
      txtDocumentEndDate: selectedDocument.documentEndDate == null ? "" : (selectedDocument.documentEndDate),
      drpDocumentType: selectedDocument.documentType == "" ? null : selectedDocument.documentType,
      drpReviewStatus: selectedDocument.reviewStatus == "" ? null : selectedDocument.reviewStatus,
      drpReviewReason: selectedDocument.reviewReason == "" ? null : selectedDocument.reviewReason,
      txtNote: selectedDocument.note,
    })
    if (selectedDocument !== null || selectedDocument !== undefined) {
      this.documentForm.enable();
      this.documentForm.controls['documentFile'].disable();
      this.documentForm.controls['drpDocumentUploadType'].disable();
      this.isDocumentForButtonDisable = true;
      this.isDocumentEditSaveButtonHidden = false;
    }


  }
  resetDocumentForm(): void {
    this.show = true;
    this.isShown = false;
    this.documentForm.patchValue({
      drpReviewStatus: null,
      drpReviewReason: null,
      drpDocumentType: null,
      txtDocumentStartDate: "",
      txtDocumentEndDate: "",
      documentFile: null,
      txtNote: "",
      drpDocumentUploadType: "",
    })
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.showErrorForFileLength = false;
    this.filesname = [];
    this.isDocumentForButtonDisable = false;
    this.intCheckedDocuments = 0;
    this.documentForm.disable();
    this.documentForm.controls['drpDocumentType'].disable();
    this.documentForm.controls['rbtnDocument'].enable();
    this.documentForm.controls['txtReason'].enable();
    this.documentForm.controls['documentFile'].enable();
    this.documentForm.markAsUntouched();
    this.documentForm.updateValueAndValidity();
    this.isDocumentEditSaveButtonHidden = true;
    this.strPdfSource = "";
    this.strSelectedPdfPath = "";
    if (this.patientID !== "" && this.patientID !== null && this.patientID !== undefined) { this.getDocumentsByPatientId(); }
  }
  deleteFileById(patientDocumentId: string): void {

    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const deleteDocument = this.documentService.deleteByPatientIdAndPatientDocumentId(this.patientID, patientDocumentId).subscribe(() => {
          this.getDocumentsByPatientId();
          this.resetDocumentForm();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }, () => {
        })
        this.subscription$.push(deleteDocument);
      }
    });
  }
  savedocumentloop(status: string) {
    let orderData: SaleorderDTO;
    this.patientService.get(this.patientID).subscribe(response => {
      let patientDetails = response;
      this.saleOrderService.get(this.orderId).subscribe(response => {
        orderData = response;
        if (status === "Approved") {
          orderData = {
            defaultClaimId: orderData?.defaultClaimId,
            patients: {
              personals: orderData?.patients?.personals,
              addresses: orderData?.patients?.addresses,
              contacts: orderData?.patients?.contacts,
              clinicals: orderData?.patients?.clinicals,
              insurances: orderData?.patients?.insurances,
              notes: orderData?.patients?.notes,
              policies: orderData?.patients?.policies,
              patientDocuments: patientDetails?.patientDocuments,
              insuranceVerifications: orderData?.patients?.insuranceVerifications,
              authorizations: orderData?.patients?.authorizations,
              defaultPatientId: orderData?.patients?.defaultPatientId,
              isAutoIntake: orderData?.patients?.isAutoIntake,
              autoIntakeDocumentDetails: orderData?.patients?.autoIntakeDocumentDetails,
              tenantId: orderData?.patients?.tenantId,
              organizationUnitId: orderData?.organizationUnitId,
              chartId: orderData.patients.chartId,
              billingDetails: orderData.patients.billingDetails,
              patientLock: orderData.patients.patientLock,
              fileId: orderData?.patients?.fileId,
            },
            currentStage: orderData.currentStage,
            currentStageStatus: this.documentForm.value.rbtnDocument,
            orders: { ...orderData.orders, isCompleted: "Done" },
            clinicals: orderData.clinicals,
            insurances: orderData.insurances,
            items: orderData.items,
            orderStatus: orderData.orderStatus,
            saleorderId: orderData.saleorderId,
            organizationUnitId: orderData.organizationUnitId,
            shippingApprovalStatus: orderData.currentStageStatus,
            claimId: orderData.claimId,
            patientId: orderData.patientId,
            isShippingApprove: orderData.isShippingApprove,
            isCashPayment: orderData.isCashPayment,
            orderProcessStatus: orderData?.orderProcessStatus,
            documentReason: this.documentForm.value.txtReason,
            patientLedgerReason: orderData?.patientLedgerReason,
            patientLedgerStatus: orderData?.patientLedgerStatus,
            paymentTransactionDetails: orderData?.paymentTransactionDetails,
            payPalPaymentMethodType: orderData?.payPalPaymentMethodType,
            payPalPaymentStatus: orderData?.payPalPaymentStatus,
            payPalMailResendCount: orderData?.payPalMailResendCount,
            orderBilling: orderData?.orderBilling,
            shippingCheckList: orderData?.shippingCheckList,
            deliveryTicket: orderData?.deliveryTicket,
            claimHistoryStatus: orderData?.claimHistoryStatus,
            authType: orderData?.authType

          }
        } else {
          orderData = {
            defaultClaimId: orderData?.defaultClaimId,
            patients: {
              personals: orderData?.patients?.personals,
              addresses: orderData?.patients?.addresses,
              contacts: orderData?.patients?.contacts,
              clinicals: orderData?.patients?.clinicals,
              insurances: orderData?.patients?.insurances,
              notes: orderData?.patients?.notes,
              policies: orderData?.patients?.policies,
              patientDocuments: patientDetails?.patientDocuments,
              insuranceVerifications: orderData?.patients?.insuranceVerifications,
              authorizations: orderData?.patients?.authorizations,
              defaultPatientId: orderData?.patients?.defaultPatientId,
              isAutoIntake: orderData?.patients?.isAutoIntake,
              autoIntakeDocumentDetails: orderData?.patients?.autoIntakeDocumentDetails,
              tenantId: orderData?.patients?.tenantId,
              organizationUnitId: orderData?.organizationUnitId,
              chartId: orderData?.patients?.chartId,
              billingDetails: orderData?.patients?.billingDetails,
              patientLock: orderData?.patients?.patientLock,
              fileId: orderData?.patients?.fileId,
            },
            currentStage: orderData.currentStage,
            currentStageStatus: this.documentForm.value.rbtnDocument,
            orders: { ...orderData.orders, isCompleted: "Shipping Disapproved" },
            clinicals: orderData.clinicals,
            insurances: orderData.insurances,
            items: orderData.items,
            orderStatus: "Shipping Disapproved",
            saleorderId: orderData.saleorderId,
            organizationUnitId: orderData.organizationUnitId,
            shippingApprovalStatus: orderData.currentStageStatus,
            claimId: orderData.claimId,
            patientId: orderData.patientId,
            isShippingApprove: orderData.isShippingApprove,
            isCashPayment: orderData.isCashPayment,
            orderProcessStatus: orderData?.orderProcessStatus,
            documentReason: this.documentForm.value.txtReason,
            patientLedgerReason: orderData?.patientLedgerReason,
            patientLedgerStatus: orderData?.patientLedgerStatus,
            paymentTransactionDetails: orderData?.paymentTransactionDetails,
            payPalPaymentMethodType: orderData?.payPalPaymentMethodType,
            payPalPaymentStatus: orderData?.payPalPaymentStatus,
            payPalMailResendCount: orderData?.payPalMailResendCount,
            orderBilling: orderData?.orderBilling,
            shippingCheckList: orderData?.shippingCheckList,
            deliveryTicket: orderData?.deliveryTicket,
            claimHistoryStatus: orderData?.claimHistoryStatus,
            authType: orderData?.authType

          }
        }
        setTimeout(() => {
          this.store.dispatch(new updateOrder(this.orderId, orderData)).subscribe(res => {

            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully','Success')
            this.orderId = res?.orderState?.selectedOrder?.id;
            this.table.setSelectedOrderId(this.orderId);
            this.getDocumentsByPatientId();
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          })
        }, 10);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! show reason
  onChangeGetReason($event: MatRadioChange) {
    if ($event.value === "Disapproved") {
      this.showReason = true
    } else {
      this.showReason = false
    }
  }
}
export interface documentGroupIngListType { documentType: string, documentGroupedByType: string, groupItem: PatientDocumentDTO[]; };
export interface documentListType { strDocumentName: string, dtDocumentDate: string, strDocumentPath: string, isSelected: boolean };
