import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { InsuranceType } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing';
import { OrdersDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import { BillingMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { PatientDTO, PoliciesDTO, PolicyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TransactioncodesListComponent } from 'projects/shipping/src/app/transactioncodes-list/transactioncodes-list.component';
import { concat } from 'rxjs';
import Swal from 'sweetalert2';

import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO, InsurancePaymentDetailsDTO, ItemPaymentDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { InsurancePaymentService } from '../billing-proxy/billing-management/posting/insurance-payment.service';
import { getBilling } from '../billing-store/billing.action';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/claim-process.service';
import { InsurancePaymentModalComponent } from '../insurance-payment-modal/insurance-payment-modal.component';
import { ToastrService } from "ngx-toastr";

// import { RemitsEobHistoryService } from './../../proxy/claim-processing-management/x12/remits-eob-history.service';



@Component({
  selector: 'app-insurance-payments',
  templateUrl: './insurance-payments.component.html',
  styleUrls: ['./insurance-payments.component.scss']
})
export class InsurancePaymentsComponent implements OnInit, OnChanges {

  txtSource: string = "";
  strSelectedPdfPath: string = "";

  public strPageType2: string = "insPayment2";
  public paymentForm: FormGroup;
  patientID: string = "";
  orderID: string = "";
  organizationUnitId = null;
  drpCode: any[] = [];
  drpPayment: any[] = [];
  drpReason: any[] = [];
  drpPolicies: PolicyDTO[] = [];
  insuranceType: string[] = [];
  lstItemPayment: ItemPaymentDTO[] = [];
  IdBasedlstItemPayment: string[] = [];
  Title: string = "Insurance Payment";
  tenantId: string = "";
  claimId: string = '';
  @Input() arrTableData: ItemPaymentDTO[] = [];
  @Input() arrTempTableData: ItemPaymentDTO[] = [];
  arrDisplayedColumns: string[] = ['Code', 'Dos', 'charge', 'allowed', 'deduct', 'copay', 'payment', 'writeOff', 'insBal', 'patBal', 'claim'];

  billingResponse: ClaimDTO;
  lstItemPayment1: ItemPaymentDTO[] = [];
  lstItemPayment2: ItemPaymentDTO[] = [];
  lstItemPayment3: ItemPaymentDTO[] = [];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>
  strInsuranceType: string = "";
  mask: string = "";
  discountPercent: string = "";
  source: string;
  code: string;
  reason: string;
  type: string;
  BirthdateMinDate: Date;
  saveButtonHide: boolean;
  constructor(
    private fb: FormBuilder,
    private title: Title,
    private number: NumberValidators,
    public dialog: MatDialog,
    private store: Store,
    private insurancePaymentService: InsurancePaymentService,
    private router: Router,
    private claimInformationsService: ClaimInformationsService,
    private billingDropdownService: BillingMasterDropdownService,
    private claimProcessService: ClaimProcessService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId?: string, orderId?: string, claimId: string, insuranceType: string }

  ) { }
  ngOnChanges(changes: SimpleChanges): void {

  }
  updatePaymentDataUsingModal(itemPayment: ItemPaymentDTO, selectedInsurance: string) {
    let insuranceType: string[] = this.insuranceType;
    insuranceType[0] === selectedInsurance && this.openPrimaryUpdatePaymentDataUsingModal(itemPayment, 1);
    insuranceType[1] === selectedInsurance && this.openSecondaryUpdatePaymentDataUsingModal(itemPayment, 2);
    insuranceType[2] === selectedInsurance && this.openTeritaryUpdatePaymentDataUsingModal(itemPayment, 3);
  }


  openPrimaryUpdatePaymentDataUsingModal(itemPayment: ItemPaymentDTO, selectedInsurance: number) {
    const dialogRef = this.dialog.open(InsurancePaymentModalComponent, {
      data:
        { itemPayment: itemPayment, selectedInsuranceType: selectedInsurance, claimId: this.claimId }
    });
    dialogRef.afterClosed().subscribe(() => {
      //    this.getInsurancePolicies();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  openSecondaryUpdatePaymentDataUsingModal(itemPayment: ItemPaymentDTO, selectedInsurance: number) {
    const dialogRef = this.dialog.open(InsurancePaymentModalComponent, {
      data:
        { itemPayment: itemPayment, selectedInsuranceType: selectedInsurance, claimId: this.claimId }
    });
    dialogRef.afterClosed().subscribe(() => {
      //    this.getInsurancePolicies();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  openTeritaryUpdatePaymentDataUsingModal(itemPayment: ItemPaymentDTO, selectedInsurance: number) {
    const dialogRef = this.dialog.open(InsurancePaymentModalComponent, {
      data:
        { itemPayment: itemPayment, selectedInsuranceType: selectedInsurance, claimId: this.claimId }
    });
    dialogRef.afterClosed().subscribe(() => {
      //    this.getInsurancePolicies();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  updatePaymentData(arrTableData: ItemPaymentDTO[]) {

    let insuranceType: string[] = this.insuranceType;

    let data: ItemPaymentDTO;
    this.arrTableData = [];
    arrTableData.forEach((element, index) => {
      if (element?.claim === "Transfer to patient") {
        data = {
          itemPaymentId: element?.itemPaymentId == undefined && element?.itemPaymentId == null ? '' : element?.itemPaymentId,
          codes: element?.codes == undefined && element?.codes == null ? '' : element?.codes,
          dos: element?.dos == undefined && element?.dos == null ? '' : element?.dos,
          billed: +element?.billed??0,
          allowed: +element?.allowed??0,
          coinsurance: +(element?.coinsurance)??0,
          insurancePaid: +(element?.insurancePaid)??0,
          writeOff: +element?.writeOff??0,
          insBalance: 0,
          patBalance: (+element?.patBalance) + (+element?.insBalance),
          claim: element?.claim??"",
          //isCompleted: element?.isCompleted,
        };
        element = data;
      } else if (element?.claim === 'Moved to next ins') {
        let tempInsBalance: number | 0 = (+element?.insBalance) - (+element?.insurancePaid??0);


        data = {
          itemPaymentId: element?.itemPaymentId == undefined && element?.itemPaymentId == null ? '' : element?.itemPaymentId,
          codes: element?.codes == undefined && element?.codes == null ? '' : element?.codes,
          dos: element?.dos == undefined && element?.dos == null ? '' : element?.dos,
          billed: +element?.billed??0,
          allowed: +element?.allowed??0,
          coinsurance: +(element?.coinsurance)??0,
          insurancePaid: +(element?.insurancePaid)??0,
          writeOff: +element?.writeOff??0,
          patBalance: +element?.patBalance??0,
          claim: element?.claim??"",

          insBalance: tempInsBalance == undefined && tempInsBalance == null ? 0 : tempInsBalance
        };
        element = data;
      } else {

        data = {
          itemPaymentId: element?.itemPaymentId == undefined && element?.itemPaymentId == null ? '' : element?.itemPaymentId,
          codes: element?.codes == undefined && element?.codes == null ? '' : element?.codes,
          dos: element?.dos == undefined && element?.dos == null ? '' : element?.dos,
          billed: +element?.billed??0,
          allowed: +element?.allowed??0,
          coinsurance: +(element?.coinsurance)??0,
          insurancePaid: +(element?.insurancePaid)??0,
          writeOff: +element?.writeOff??0,
          insBalance: +element?.insBalance??0,
          patBalance: +element?.patBalance??0,
          claim: element?.claim??"",
          //isCompleted: element?.isCompleted,
        };
        element = data;

      }
      this.arrTableData.push(element);
    })


  }
  ngOnInit(): void {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.tenantId = localStorage.getItem('tenantId')??"";
    this.data.insuranceType = this.data?.insuranceType ?? '';


    this.data.patientId = this.data?.patientId ?? "";
    this.data.claimId = this.data?.claimId ?? "";
    this.data.orderId = this.data?.orderId ?? "";
    this.claimId = this.data?.claimId??"";
    this.title.setTitle("Qsecure | Insurance Payment");
    this.paymentForm = this.fb.group({
      txtSource: new FormControl(""),
      txtCode: new FormControl(""),
      txtCheckNo: new FormControl("", [this.number.isNumberCheck]),
      txtDate: new FormControl(""),
      txtAmount: new FormControl("", [this.number.isNumberCheck]),
      drpReason: new FormControl(""),
      drpType: new FormControl("")
    })
    this.claimId && this.getDropdown();
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
  }
  // clear dropdown
  clearSource() {
    this.source = '';
  }

  clearCode() {
    this.code = '';
  }

  clearReason() {
    this.reason = '';
  }

  clearType() {
    this.type = '';
  }

  getDropdown() {
    this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {
      const billingResponse: ClaimDTO = response?.billingState.selectedBilling;
      this.billingResponse = billingResponse;
      this.patientID = billingResponse?.patientId;
      this.orderID = billingResponse?.orderId;
      this.getInsurancePolicies();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Codes,
      BillingDropdowns.PaymentTypes,
      BillingDropdowns.Reasons
    ]
    this.billingDropdownService.getBillingDropdownsByInput(billingDropdownInput).subscribe((stateResponse) => {
      let response: BillingMasterDropdownDTO = stateResponse;
      this.drpCode = response?.codes;
      this.drpPayment = response?.paymentTypes;
      this.drpReason = response?.reasons;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  getInsurancePolicies() {
    this.store.dispatch(new getPatient(this.patientID)).subscribe((stateResponse) => {
      const selectedPatient: PatientDTO = stateResponse?.patientState?.selectedPatient;
      this.drpPolicies = [];
      let drpPolicies: PoliciesDTO = selectedPatient?.policies;
      let insuranceType: string[] = [];
      if (drpPolicies?.primaryPolicy) {
        insuranceType.push(drpPolicies?.primaryPolicy?.insuranceType + '/' + drpPolicies?.primaryPolicy?.payorLevel);
        this.paymentForm.patchValue({ txtSource: insuranceType[0] });
        this.drpPolicies.push(drpPolicies?.primaryPolicy);
      }
      if (drpPolicies?.secondaryPolicy) {
        insuranceType.push(drpPolicies?.secondaryPolicy?.insuranceType + '/' + drpPolicies?.secondaryPolicy?.payorLevel);
        this.drpPolicies.push(drpPolicies?.secondaryPolicy);

      }
      if (drpPolicies?.tertiaryPolicy) {
        insuranceType.push(drpPolicies?.tertiaryPolicy?.insuranceType + '/' + drpPolicies?.tertiaryPolicy?.payorLevel);
        this.drpPolicies.push(drpPolicies?.tertiaryPolicy);
      }
      this.insuranceType = insuranceType;

      this.loadTableDataOnDropdwonChange();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  totalBilled: number = 0;
  totalAllowed: number = 0;
  totalDeduct: number = 0;
  totalCoinsurance: number = 0;
  totalInsPaid: number = 0;
  totalPatientAmount: number = 0;
  totalWriteOffAmount: number = 0;



  loadTableDataOnDropdwonChange(value?: any) {
    let tableData: ItemPaymentDTO[] = [];
    this.arrTableData = [];
    let insuranceType: string[] = this.insuranceType;
    let insurance: string = '';
    let eobBlobName: string = "";
    if (this.claimId && this.claimId !== "" && this.claimId !== null && this.claimId !== undefined) {
      this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {
        const claimResponseData: ClaimDTO = response?.billingState.selectedBilling;
        const pItemPayment = claimResponseData?.insurancePayments?.primaryInsurancePaymentDetails?.lstItemPayment;
        const sItemPayment = claimResponseData?.insurancePayments?.secondaryInsurancePaymentDetails?.lstItemPayment;
        const tItemPayment = claimResponseData?.insurancePayments?.tertiaryInsurancePaymentDetails?.lstItemPayment;
        const primaryInsurancePayments = claimResponseData?.insurancePayments?.primaryInsurancePaymentDetails;
        const secondaryInsurancePayments = claimResponseData?.insurancePayments?.secondaryInsurancePaymentDetails;
        const tertiaryInsurancePayments = claimResponseData?.insurancePayments?.tertiaryInsurancePaymentDetails;
        if (this.data?.insuranceType === InsuranceType.Primary) {
          eobBlobName = claimResponseData?.insurancePayments?.primaryInsurancePaymentDetails?.eobDocuments?.blobName;
        }
        if (this.data?.insuranceType === InsuranceType.Secondary) {
          eobBlobName = claimResponseData?.insurancePayments?.secondaryInsurancePaymentDetails?.eobDocuments?.blobName;
        }
        if (this.data?.insuranceType === InsuranceType.Tertiary) {
          eobBlobName = claimResponseData?.insurancePayments?.tertiaryInsurancePaymentDetails?.eobDocuments?.blobName;
        }
        this.totalBilled = primaryInsurancePayments?.totalBilled;
        this.totalAllowed = primaryInsurancePayments?.totalAllowed;
        this.totalDeduct = primaryInsurancePayments?.totalDeduct;
        this.totalCoinsurance = primaryInsurancePayments?.totalCoInsurance;
        this.totalInsPaid = primaryInsurancePayments?.totalInsBal;
        this.totalPatientAmount = primaryInsurancePayments?.totalPatientBal;
        this.totalWriteOffAmount = primaryInsurancePayments?.totalWriteOff;
        setTimeout(() => {
          if (pItemPayment !== [] && pItemPayment.length !== 0 && this.txtSource === insuranceType[0]) {
            pItemPayment?.forEach(element => {
              let data: any;
              data = {
                itemPaymentId: element?.itemPaymentId,
                codes: element?.itemProcedureCode,
                dos: element?.dos,
                billed: element?.billed??"",
                allowed: element?.allowed,
                deduct: element?.deduct,
                coinsurance: (element?.coinsurance)??0,
                insurancePaid: (element?.insurancePaid)??0,
                writeOff: element?.writeOff,
                insBalance: element?.insBalance,
                patBalance: element?.patBalance,
                claim: element?.claim??"",
              }
              tableData.push(data)
            });
            this.arrTableData = tableData;
            this.paymentForm.patchValue({
              txtCode: primaryInsurancePayments.code,
              txtCheckNo: primaryInsurancePayments.checkNo,
              txtDate: primaryInsurancePayments.date,
              txtAmount: primaryInsurancePayments.amount,
              drpReason: primaryInsurancePayments.reason,
              drpType: primaryInsurancePayments.paymentType
            });


          } else if (pItemPayment.length == 0 && this.paymentForm.value.txtSource === insuranceType[0]) {

            insurance = insuranceType[0];
            this.claimInformationsService.getListBasedOnInsuranceByClaimIdAndInsurance(this.claimId, insurance).pipe(
            ).subscribe(response => {
              response?.items?.forEach(element => {



                let data: any = {
                  codes: element?.item,
                  dos: element?.dos,
                  charge: element?.fee,
                  allowed: "",
                  deduct: "",
                  coinsurance: element?.copay,
                  insurancePaid: "",
                  writeOff: "",
                  insBalance: "",
                  patBalance: "",
                  claim: "",
                  isCompleted: 0,

                }
                tableData.push(data)
              });
              this.arrTableData = tableData;
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })


          }


          else if (sItemPayment !== [] && sItemPayment.length !== 0 && this.paymentForm.value.txtSource === insuranceType[1]) {
            sItemPayment?.forEach(element => {

              let data: any = {
                itemPaymentId: element?.itemPaymentId,
                codes: element?.itemProcedureCode,
                dos: element?.dos,
                billed: element?.billed,
                allowed: element?.allowed,
                deduct: element?.deduct,
                coinsurance: (element?.coinsurance)??0,
                insurancePaid: (element?.insurancePaid)??0,
                writeOff: element?.writeOff,
                insBalance: element?.insBalance,
                patBalance: element?.patBalance,
                claim: element?.claim??"",

              }
              tableData.push(data);
            });
            this.arrTableData = tableData;
            this.paymentForm.patchValue({
              txtCode: secondaryInsurancePayments.code,
              txtCheckNo: secondaryInsurancePayments.checkNo,
              txtDate: secondaryInsurancePayments.date,
              txtAmount: secondaryInsurancePayments.amount,
              drpReason: secondaryInsurancePayments.reason,
              drpType: secondaryInsurancePayments.paymentType
            });


          }
          else if (sItemPayment.length == 0 && this.paymentForm.value.txtSource === insuranceType[1]) {
            if (this.paymentForm.value.txtSource === insuranceType[1]) {
              insurance = insuranceType[1];
            }
            this.claimInformationsService.getListBasedOnInsuranceByClaimIdAndInsurance(this.claimId, insurance).pipe(
            ).subscribe(response => {
              response?.items?.forEach(element => {
                let data: any = {
                  codes: element?.item,
                  dos: element?.dos,
                  charge: element?.fee,
                  allowed: "",
                  deduct: "",
                  coinsurance: element?.copay,
                  insurancePaid: "",
                  writeOff: "",
                  insBalance: "",
                  patBalance: "",
                  claim: "",
                }
                tableData.push(data)
              });
              this.arrTableData = tableData;
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });


          }

          else if (tItemPayment !== [] && tItemPayment.length !== 0 && this.paymentForm.value.txtSource === insuranceType[2]) {
            tItemPayment?.forEach(element => {
              let data: any = {
                itemPaymentId: element?.itemPaymentId,
                codes: element?.itemProcedureCode,
                dos: element?.dos,
                billed: element?.billed,
                allowed: element?.allowed,
                deduct: element?.deduct,
                coinsurance: (element?.coinsurance)??0,
                insurancePaid: (element?.insurancePaid)??0,
                writeOff: element?.writeOff,
                insBalance: element?.insBalance,
                patBalance: element?.patBalance,
                claim: element?.claim??"",
              }
              tableData.push(data);
            });
            this.arrTableData = tableData;
            this.paymentForm.patchValue({
              txtCode: tertiaryInsurancePayments.code,
              txtCheckNo: tertiaryInsurancePayments.checkNo,
              txtDate: tertiaryInsurancePayments.date,
              txtAmount: tertiaryInsurancePayments.amount,
              drpReason: tertiaryInsurancePayments.reason,
              drpType: tertiaryInsurancePayments.paymentType
            });


          }
          else if (tItemPayment.length == 0 && this.paymentForm.value.txtSource === insuranceType[2]) {
            if (this.paymentForm.value.txtSource === insuranceType[2]) {
              insurance = insuranceType[2];
            }
            this.claimInformationsService.getListBasedOnInsuranceByClaimIdAndInsurance(this.claimId, insurance).pipe(
            ).subscribe(response => {
              response?.items?.forEach(element => {
                let data: any = {
                  codes: element?.item,
                  dos: element?.dos,
                  charge: element?.fee,
                  allowed: "",
                  deduct: "",
                  coinsurance: element?.copay,
                  insurancePaid: "",
                  writeOff: "",
                  insBalance: "",
                  patBalance: "",
                  claim: "",
                }
                tableData.push(data)
              });
              this.arrTableData = tableData;
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }


          setTimeout(() => {
            this.updateSecondaryAfterPrimarySave(claimResponseData, insuranceType, tableData);
          }, 400);
        }, 1);

        if (eobBlobName && eobBlobName !== "") {
          const sClaimNumber: string = this.claimId;
          const blobName: string = eobBlobName;
          sClaimNumber && blobName && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + blobName + '/' + 2 + "/" + sClaimNumber, "_blank");
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })

    }


    if (this.orderID && this.orderID !== "" && this.orderID !== null && this.orderID !== undefined) {
      this.store.dispatch(new getOrder(this.orderID)).subscribe(response => {
        if (response && response?.orderState?.selectedOrder && response?.orderState?.selectedOrder?.orders && response?.orderState?.selectedOrder?.orders[0]) {
          const selectedOrderDeatils: OrdersDTO = response?.orderState?.selectedOrder?.orders[0];
          this.discountPercent = selectedOrderDeatils?.discountPct;
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }


  }


  private updateSecondaryAfterPrimarySave(claimResponseData: ClaimDTO, insuranceType: string[], tableData: ItemPaymentDTO[]) {
    this.arrTempTableData = [];
    if (this.paymentForm.value.txtSource === insuranceType[0]) {
      this.strInsuranceType = "Primary";

    }
    if (this.paymentForm.value.txtSource === insuranceType[1]) {
      this.strInsuranceType = "Secondary";

    }
    if (this.paymentForm.value.txtSource === insuranceType[2]) {
      this.strInsuranceType = "Tertiary";
    }
    const pItemPayment = claimResponseData?.insurancePayments?.primaryInsurancePaymentDetails?.lstItemPayment;
    const sItemPayment = claimResponseData?.insurancePayments?.secondaryInsurancePaymentDetails?.lstItemPayment;
    const tItemPayment = claimResponseData?.insurancePayments?.tertiaryInsurancePaymentDetails?.lstItemPayment;

    if (this.paymentForm.value.txtSource === insuranceType[0]) {
      let tempElement: ItemPaymentDTO;


      pItemPayment.forEach(element => {

        tableData.forEach(element1 => {


          if (element?.codes === element1.codes && +element?.billed === +element1.billed) {
            element1.itemPaymentId = element?.itemPaymentId;
            element1.codes = element?.codes;
            element1.dos = element?.dos;
            element1.billed = element?.billed;
            element1.allowed = element?.allowed;
            element1.deduct = element?.deduct;
            element1.coinsurance = element?.coinsurance??0;
            element1.insBalance = element?.insBalance;

            element = {
              billed: element?.billed,
              allowed: element?.allowed,
              coinsurance: (element?.coinsurance)??0,
              insurancePaid: (element?.insurancePaid)??0,
              writeOff: element?.writeOff,
              insBalance: element?.insBalance,
              patBalance: element?.patBalance,
              claim: element?.claim??"",
              deduct: element?.deduct,
              dos: element?.dos,
              codes: element?.itemProcedureCode,
              itemPaymentId: element?.itemPaymentId
            };
            tempElement = element;
          } else {
            element = {
              billed: element?.billed,
              allowed: element?.allowed,
              coinsurance: (element?.coinsurance)??0,
              insurancePaid: (element?.insurancePaid)??0,
              writeOff: element?.writeOff,
              insBalance: element?.insBalance,
              patBalance: element?.patBalance,
              claim: element?.claim??"",
              deduct: element?.deduct,
              dos: element?.dos,
              codes: element?.itemProcedureCode,
              itemPaymentId: element?.itemPaymentId
            };
            tempElement = element;
          }


        });
      });
      this.arrTempTableData.push(tempElement);
    }
    if (this.paymentForm.value.txtSource === insuranceType[1]) {
      let tempElement: ItemPaymentDTO;


      pItemPayment.forEach(element => {

        tableData.forEach(element1 => {
          if (element?.codes === element1.codes && +element?.billed === +element1.billed) {
            element1.itemPaymentId = element?.itemPaymentId;
            element1.codes = element?.codes;
            element1.dos = element?.dos;
            element1.billed = element?.billed;
            element1.allowed = element?.allowed;
            element1.deduct = element?.deduct;
            element1.coinsurance = element?.coinsurance??0;
            element1.insBalance = element?.insBalance;

            element = {
              billed: element?.billed,
              allowed: element?.allowed,
              coinsurance: (element?.coinsurance)??0,
              insurancePaid: (element?.insurancePaid)??0,
              writeOff: element?.writeOff,
              insBalance: element?.insBalance,
              patBalance: element?.patBalance,
              claim: element?.claim??"",
              deduct: element?.deduct,
              dos: element?.dos,
              codes: element?.itemProcedureCode,
              itemPaymentId: element?.itemPaymentId
            };
            tempElement = element;
          } else {
            element = {
              billed: element?.billed,
              allowed: element?.allowed,
              coinsurance: (element?.coinsurance)??0,
              insurancePaid: (element?.insurancePaid)??0,
              writeOff: element?.writeOff,
              insBalance: element?.insBalance,
              patBalance: element?.patBalance,
              claim: element?.claim??"",
              deduct: element?.deduct,
              dos: element?.dos,
              codes: element?.itemProcedureCode,
              itemPaymentId: element?.itemPaymentId
            };
            tempElement = element;
          }


        });
      });
      this.arrTempTableData.push(tempElement);
    }
    if (this.paymentForm.value.txtSource === insuranceType[2]) {
      let tempElement: ItemPaymentDTO;

      sItemPayment.forEach(element => {
        tableData.forEach(element1 => {

          if (element?.codes === element1.codes && element?.billed === element1.billed) {
            element1.itemPaymentId = element?.itemPaymentId;
            element1.codes = element?.codes;
            element1.dos = element?.dos;
            element1.billed = element?.billed;
            element1.allowed = element?.allowed;
            element1.deduct = element?.deduct;
            element1.coinsurance = element?.coinsurance??0;
            element1.insBalance = element?.insBalance;
            //element1.isCompleted = 1;
            element = {
              // isCompleted: 1,
              billed: element?.billed,
              allowed: element?.allowed,
              coinsurance: (element?.coinsurance)??0,
              insurancePaid: (element?.insurancePaid)??0,
              writeOff: element?.writeOff,
              insBalance: element?.insBalance,
              patBalance: element?.patBalance,
              claim: element?.claim??"",
              deduct: element?.deduct,
              dos: element?.dos,
              codes: element?.itemProcedureCode,
              itemPaymentId: element?.itemPaymentId
            };
            tempElement = element;

          } else {
            element = {
              //  isCompleted: 1,
              billed: element?.billed,
              allowed: element?.allowed,
              coinsurance: (element?.coinsurance)??0,
              insurancePaid: (element?.insurancePaid)??0,
              writeOff: element?.writeOff,
              insBalance: element?.insBalance,
              patBalance: element?.patBalance,
              claim: element?.claim??"",
              deduct: element?.deduct,
              dos: element?.dos,
              codes: element?.itemProcedureCode,
              itemPaymentId: element?.itemPaymentId
            };
            tempElement = element;
          }

        });
      });
      this.arrTempTableData.push(tempElement);
    }
    this.arrTableData = tableData;
  }

  eraPayment() {
    const dialogRef = this.dialog.open(TransactioncodesListComponent);

    dialogRef.afterClosed().subscribe(() => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  backToPayment() {
    this.router.navigate(['billing/history'])
  }

  getCalucations(value: any[]) {
    let data: ItemPaymentDTO[] = [];
    value?.forEach(element => {
      this.lstItemPayment.some(v => {
        return v.itemPaymentId === element?.postingChargesId;
      })
      if (this.lstItemPayment.some(v => v.itemPaymentId === element?.postingChargesId)) {
        let index = data.findIndex(v => { v.itemPaymentId === element?.postingChargesId });

        data.splice(index, 1,
          {
            itemPaymentId: element?.postingChargesId,
            codes: element?.item,
            dos: element?.dos,
            billed: +element?.billed??0,
            allowed: +element?.allowed??0,
            coinsurance: +(element?.coinsurance)??0,
            insurancePaid: +(element?.insurancePaid)??0,
            writeOff: +element?.writeOff??0,
            insBalance: +element?.insBal,
            patBalance: +element?.patBal,
            claim: element?.claim??"",
          })
      } else {
        data.push({
          itemPaymentId: element?.postingChargesId,
          codes: element?.item,
          dos: element?.dos,
          billed: +element?.billed??0,
          allowed: +element?.allowed??0,
          coinsurance: +(element?.coinsurance)??0,
          insurancePaid: +(element?.insurancePaid)??0,
          writeOff: +element?.writeOff??0,
          insBalance: +element?.insBal,
          patBalance: +element?.patBal,
          claim: element?.claim??"",
        })
      }


    });
    this.lstItemPayment = [...data];
    let Array$ = concat([this.lstItemPayment]);

  }





  savePaymentBasedOnInsurance() {
    let insuranceType: string[] = this.insuranceType;

    if (this.paymentForm.value.txtSource === insuranceType[0]) {
      this.arrTableData.map(v => {
        if (v.itemPaymentId === '') {
          v.itemPaymentId = "00000000-0000-0000-0000-000000000000";

          v.coinsurance = (+v?.coinsurance??0);
          v.writeOff = (+v?.writeOff??0);
          v.codes = v?.codes??"";
          v.dos = v?.dos??"";
          v.billed = +v?.billed??0;
          v.allowed = +v?.allowed??0;
          v.deduct = v?.deduct??"";
          v.insurancePaid = +v?.insurancePaid??0;
          v.patBalance = +v?.patBalance??0;
          v.claim = v?.claim??"";
          v.insBalance = (+v?.insBalance??0);
          //v.isCompleted = 0;
          return v
        } else {
          v.coinsurance = (+v?.coinsurance??0);
          v.writeOff = (+v?.writeOff??0);
          v.itemPaymentId = v?.itemPaymentId??"";
          v.codes = v?.codes??"";
          v.dos = v?.dos??"";
          v.billed = +v?.billed??0;
          v.allowed = +v?.allowed??0;
          v.deduct = v?.deduct??"";
          v.insurancePaid = +v?.insurancePaid??0;
          v.patBalance = +v?.patBalance??0;
          v.claim = v?.claim??"";
          v.insBalance = (+v?.insBalance??0);
          // v.isCompleted = 0;
          return v
        }

      });
      this.savePrimaryInsurancePayments();

    } else if (this.paymentForm.value.txtSource === insuranceType[1]) {
      this.arrTableData.map(v => {
        if (v.itemPaymentId === "") {
          v.itemPaymentId = "00000000-0000-0000-0000-000000000000";
          v.coinsurance = (+v?.coinsurance??0);
          v.writeOff = (+v?.writeOff??0);
          v.codes = v?.codes??"";
          v.dos = v?.dos??"";
          v.billed = +v?.billed??0;
          v.allowed = +v?.allowed??0;
          v.deduct = v?.deduct??"";
          v.insurancePaid = +v?.insurancePaid??0;
          v.patBalance = +v?.patBalance??0;
          v.claim = v?.claim??"";
          v.insBalance = v?.insBalance??0;
        } else {
          v.coinsurance = (+v?.coinsurance??0);
          v.writeOff = (+v?.writeOff??0);
          v.itemPaymentId = v?.itemPaymentId??"";
          v.codes = v?.codes??"";
          v.dos = v?.dos??"";
          v.billed = +v?.billed??0;
          v.allowed = +v?.allowed??0;
          v.deduct = v?.deduct??"";
          v.insurancePaid = +v?.insurancePaid??0;
          v.patBalance = +v?.patBalance??0;
          v.claim = v?.claim??"";
          v.insBalance = v?.insBalance??0;
        }


        return v
      });
      this.saveSecondaryInsurancePayments();
    } else {
      this.arrTableData.map(v => {
        if (v.itemPaymentId === '') {
          v.itemPaymentId = "00000000-0000-0000-0000-000000000000";

          v.coinsurance = (+v?.coinsurance??0);
          v.writeOff = (+v?.writeOff??0);
          v.codes = v?.codes??"";
          v.dos = v?.dos??"";
          v.billed = +v?.billed??0;
          v.allowed = +v?.allowed??0;
          v.deduct = v?.deduct??"";
          v.insurancePaid = +v?.insurancePaid??0;
          v.patBalance = +v?.patBalance??0;
          v.claim = v?.claim??"";
          v.insBalance = v?.insBalance??0;
        } else {

          v.coinsurance = (+v?.coinsurance??0);
          v.writeOff = (+v?.writeOff??0);
          v.itemPaymentId = v?.itemPaymentId??"";
          v.codes = v?.codes??"";
          v.dos = v?.dos??"";
          v.billed = +v?.billed??0;
          v.allowed = +v?.allowed??0;
          v.deduct = v?.deduct??"";
          v.insurancePaid = +v?.insurancePaid??0;
          v.patBalance = +v?.patBalance??0;
          v.claim = v?.claim??"";
          v.insBalance = v?.insBalance??0;
        }

        return v
      });
      this.saveTertiaryInsurancePayments();


    }

  }


  //! save Primary Insurance payments
  savePrimaryInsurancePayments() {

    this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {
      const claimResponseData: ClaimDTO = response?.billingState.selectedBilling ?? null;
      const primaryPaymentDetails: InsurancePaymentDetailsDTO = claimResponseData.insurancePayments.primaryInsurancePaymentDetails ?? null;
      const primaryInsurnacePayemntData: InsurancePaymentDetailsDTO = {
        source: this.paymentForm.value.txtSource,
        code: this.paymentForm.value.txtCode,
        checkNo: this.paymentForm.value.txtCheckNo,
        date: this.paymentForm.value.txtDate,
        amount: this.paymentForm.value.txtAmount,
        //  patientId: this.patientID,
        // defaultPatientId: primaryPaymentDetails.defaultPatientId,
        // orderId: this.orderID,
        // currentStage: primaryPaymentDetails.currentStage,
        // saleorderId: primaryPaymentDetails.saleorderId,
        //saleorderId: 0,
        //  tenantId: this.tenantId,
        //    organizationUnitId: this.organizationUnitId,
        reason: this.paymentForm.value.drpReason,
        paymentType: this.paymentForm.value.drpType,
        lstItemPayment: this.arrTableData,
        //  primaryInsurancePaymentId: primaryPaymentDetails.primaryInsurancePaymentId,
        description: primaryPaymentDetails.description,
        plansOfFiles: primaryPaymentDetails.plansOfFiles,
        statementNote: primaryPaymentDetails.statementNote,
        //patientBalance: primaryPaymentDetails.patientBalance,
        // insuranceBalance: primaryPaymentDetails.insuranceBalance,
        // totalBalance: primaryPaymentDetails.totalBalance,
        icn: primaryPaymentDetails.icn,
        eobDocuments: undefined,
        totalBilled: primaryPaymentDetails.totalBilled,
        totalAllowed: primaryPaymentDetails.totalAllowed,
        totalDeduct: primaryPaymentDetails.totalDeduct,
        totalCoInsurance: primaryPaymentDetails.totalCoInsurance,
        totalWriteOff: primaryPaymentDetails.totalWriteOff,
        totalInsurancePaid: primaryPaymentDetails.totalInsurancePaid,
        totalInsBal: primaryPaymentDetails.totalInsBal,
        totalPatientBal: primaryPaymentDetails.totalPatientBal,
      }
      this.insurancePaymentService.updatePrimary(primaryInsurnacePayemntData, this.claimId).subscribe(value => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialog.closeAll();
        this.saveButtonHide = false;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! save Secondary Insurance payments
  saveSecondaryInsurancePayments() {

    this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {
      const claimResponseData: ClaimDTO = response?.billingState.selectedBilling;
      const secondaryInsurnacePayemntData: InsurancePaymentDetailsDTO = {
        source: this.paymentForm.value.txtSource,
        code: this.paymentForm.value.txtCode,
        checkNo: this.paymentForm.value.txtCheckNo,
        date: this.paymentForm.value.txtDate,
        amount: this.paymentForm.value.txtAmount,
        // patientId: this.patientID,
        // defaultPatientId: claimResponseData.defaultPatientId,
        // orderId: this.orderID,
        // currentStage: claimResponseData.currentStage,
        // saleorderId: claimResponseData.saleorderId,
        // saleorderId: 0,
        // tenantId: this.tenantId,
        // organizationUnitId: this.organizationUnitId,
        // reason: this.paymentForm.value.drpReason,
        // paymentType: this.paymentForm.value.drpType,
        lstItemPayment: this.arrTableData,
        eobDocuments: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.eobDocuments,
        totalBilled: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalBilled,
        totalAllowed: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalAllowed,
        totalDeduct: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalDeduct,
        totalCoInsurance: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalCoInsurance,
        totalWriteOff: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalWriteOff,
        totalInsurancePaid: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalInsurancePaid,
        totalInsBal: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalInsBal,
        totalPatientBal: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalPatientBal
      }
      this.insurancePaymentService.updateSecondary(secondaryInsurnacePayemntData, this.claimId).subscribe(value => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.updatePrimaryAfterSecondarySave(claimResponseData);
        this.saveButtonHide = false;
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })


  }
  updatePrimaryAfterSecondarySave(claimResponseData: ClaimDTO) {
    const primaryInsurnacePayemntData: InsurancePaymentDetailsDTO = {
      source: this.paymentForm.value.txtSource,
      code: this.paymentForm.value.txtCode,
      checkNo: this.paymentForm.value.txtCheckNo,
      date: this.paymentForm.value.txtDate,
      amount: this.paymentForm.value.txtAmount,
      // patientId: this.patientID,
      // defaultPatientId: claimResponseData.defaultPatientId,
      // orderId: this.orderID,
      // currentStage: claimResponseData.currentStage,
      // saleorderId: claimResponseData.saleorderId,
      //saleorderId: 0,
      // tenantId: this.tenantId,
      // organizationUnitId: this.organizationUnitId,
      reason: this.paymentForm.value.drpReason,
      paymentType: this.paymentForm.value.drpType,
      lstItemPayment: this.arrTempTableData,
      eobDocuments: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.eobDocuments,
      totalBilled: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalBilled,
      totalAllowed: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalAllowed,
      totalDeduct: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalDeduct,
      totalCoInsurance: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalCoInsurance,
      totalWriteOff: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalWriteOff,
      totalInsurancePaid: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalInsurancePaid,
      totalInsBal: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalInsBal,
      totalPatientBal: claimResponseData.insurancePayments.primaryInsurancePaymentDetails.totalPatientBal

    };
    this.insurancePaymentService.updatePrimary(primaryInsurnacePayemntData, this.claimId).subscribe(value => {


    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //! save Tertiary Insurance payments
  saveTertiaryInsurancePayments() {
    this.saveButtonHide = true;
    this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {
      const claimResponseData: ClaimDTO = response?.billingState.selectedBilling;
      const tertiaryInsurnacePayemntData: InsurancePaymentDetailsDTO = {
        source: this.paymentForm.value.txtSource,
        code: this.paymentForm.value.txtCode,
        checkNo: this.paymentForm.value.txtCheckNo,
        date: this.paymentForm.value.txtDate,
        amount: this.paymentForm.value.txtAmount,
        // patientId: this.patientID,
        // defaultPatientId: claimResponseData.defaultPatientId,
        // orderId: this.orderID,
        // currentStage: claimResponseData.currentStage,
        //  saleorderId: 0,
        // saleorderId: claimResponseData.saleorderId,
        // tenantId: this.tenantId,
        // organizationUnitId: this.organizationUnitId,
        reason: this.paymentForm.value.drpReason,
        paymentType: this.paymentForm.value.drpType,
        lstItemPayment: this.arrTableData,
        eobDocuments: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.eobDocuments,
        totalBilled: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalBilled,
        totalAllowed: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalAllowed,
        totalDeduct: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalDeduct,
        totalCoInsurance: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalCoInsurance,
        totalWriteOff: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalWriteOff,
        totalInsurancePaid: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalInsurancePaid,
        totalInsBal: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalInsBal,
        totalPatientBal: claimResponseData.insurancePayments.tertiaryInsurancePaymentDetails.totalPatientBal
        //  icn: claimResponseData.insurancePayment.tertiaryInsurancePayments.icn,
        //    tertiaryInsurancePostingStatus: claimResponseData.insurancePayment.tertiaryInsurancePayments.tertiaryInsurancePostingStatus
      }
      this.insurancePaymentService.updateTertiary(tertiaryInsurnacePayemntData, this.claimId).subscribe(value => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.updateSecondaryAfterTeritarySave(claimResponseData);
        this.saveButtonHide = false;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });



    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  updateSecondaryAfterTeritarySave(claimResponseData: ClaimDTO) {

    const secondaryInsurnacePayemntData: InsurancePaymentDetailsDTO = {
      source: this.paymentForm.value.txtSource,
      code: this.paymentForm.value.txtCode,
      checkNo: this.paymentForm.value.txtCheckNo,
      date: this.paymentForm.value.txtDate,
      amount: this.paymentForm.value.txtAmount,
      // patientId: this.patientID,
      // defaultPatientId: claimResponseData.defaultPatientId,
      // orderId: this.orderID,
      //    currentStage: claimResponseData.currentStage,
      // saleorderId: claimResponseData.saleorderId,
      //saleorderId: 0,
      // tenantId: this.tenantId,
      // organizationUnitId: this.organizationUnitId,
      reason: this.paymentForm.value.drpReason,
      paymentType: this.paymentForm.value.drpType,
      lstItemPayment: this.arrTempTableData,
      eobDocuments: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.eobDocuments,
      totalBilled: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalBilled,
      totalAllowed: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalAllowed,
      totalDeduct: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalDeduct,
      totalCoInsurance: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalCoInsurance,
      totalWriteOff: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalWriteOff,
      totalInsurancePaid: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalInsurancePaid,
      totalInsBal: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalInsBal,
      totalPatientBal: claimResponseData.insurancePayments.secondaryInsurancePaymentDetails.totalPatientBal
      //   icn: claimResponseData.insurancePayment.secondaryInsurancePayments.icn,
      //   secondaryInsurancePostingStatus: claimResponseData.insurancePayment.secondaryInsurancePayments.secondaryInsurancePostingStatus
    };
    this.insurancePaymentService.updateSecondary(secondaryInsurnacePayemntData, this.claimId).subscribe(value => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }



}





