import type {
  CreateUpdatePickUpExchangeDTO,
  PickUpExchangeDTO,
  PickUpExchangeListDTO,
  PickUpExchangeSearchDTO,
  PickupExchangeStatus,
  PickupSaleOrderItemDTO,
  PickupUpdateRequestDTO,
  ReturnAllItemsInputDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PickUpExchangeService {
  apiName = 'orderManagement';

  create = (input: CreateUpdatePickUpExchangeDTO) =>
    this.restService.request<any, PickUpExchangeDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/pick-up-exchange',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/orderApp/pick-up-exchange/${id}`,
      },
      { apiName: this.apiName }
    );
  DeletePickupItem = (exchangeReturnItemListId: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/orderApp/pick-up-exchange/pickup-item/${exchangeReturnItemListId}`,
      },
      { apiName: this.apiName }
    );
  get = (id: string) =>
    this.restService.request<any, PickUpExchangeDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/pick-up-exchange/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PickUpExchangeDTO>>(
      {
        method: 'GET',
        url: '/api/orderApp/pick-up-exchange',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdatePickUpExchangeDTO) =>
    this.restService.request<any, PickUpExchangeDTO>(
      {
        method: 'PUT',
        url: `/api/orderApp/pick-up-exchange/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  returnAllItemOrders = (input: ReturnAllItemsInputDTO) =>
    this.restService.request<any, PickUpExchangeDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/pick-up-exchange/return-all-item-orders',
        body: input,
      },
      { apiName: this.apiName }
    );

  getPickUpExchangeListByOrderId = (orderId: string) =>
    this.restService.request<any, PickupSaleOrderItemDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/pick-up-exchange/get-pick-up-exchange-list-by-order-id/${orderId}`,
      },
      { apiName: this.apiName }
    );

    updatePickUpDateByIdAndInput = (id: string, input: PickupUpdateRequestDTO) =>
    this.restService.request<any, PickUpExchangeDTO>({
      method: 'PUT',
      url: `/api/orderApp/pick-up-exchange/${id}/pick-up-date`,
      body: input,
    },
    { apiName: this.apiName });
    
    downloadPickUpDoc = (blobName: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/orderApp/pick-up-exchange/download-pick-up-doc`,
        params: { blobName },
      },
      { apiName: this.apiName }
    );

    getPickUpExchangeList = (
      pickupExchangeId: string,
      patientId: string,
      orderId: string,
      patientName: string,
      orderDate: string,
      pickupStatus: string,
      completedDate: string
    ) =>
      this.restService.request<any, PickUpExchangeListDTO[]>(
        {
          method: 'GET',
          url: `/api/orderApp/pick-up-exchange/pick-up-exchange-list`,
          params: {
            pickupExchangeId,
            patientId,
            orderId,
            patientName,
            orderDate,
            pickupStatus,
            completedDate,
          },
        },
        { apiName: this.apiName }
      );

  constructor(private restService: RestService) {}
}
