<mat-dialog-content class="mat-typography">

    <div class="row">
        <div class="col-lg-6">
            <h2 mat-dialog-title>{{ itemPaymentTitle }}</h2>
        </div>
    </div>
    <!-- Insurance Payment Form Start -->
    <form [formGroup]="paymentForm">
        <mat-card-content>
            <div class="row">
                <mat-form-field class="col-3">
                    <mat-label>Plan</mat-label>
                    <mat-select maxlength="40" formControlName="drpPlan">
                        <mat-option *ngFor="let code of drpInsurance" [value]="code?.payorLevelName">
                            {{ code?.payorLevelName }}
                        </mat-option>
                    </mat-select>
                    <button mat-button (click)="clearInsurance();" *ngIf="paymentForm?.value?.drpPlan" matSuffix
                        mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                    <mat-label>Check No</mat-label>
                    <input autocomplete="new-txtCheckNo" matInput formControlName="txtCheckNo" type="text" maxlength="40" />
                    <mat-error *ngIf="paymentForm?.get('txtCheckNo')?.touched &&
                          paymentForm?.get('txtCheckNo')?.errors?.number">
                        Enter only Numbers!
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                    <mat-label>Check Date</mat-label>
                    <input autocomplete="new-txtCheckDate" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtCheckDate"
                        [matDatepicker]="myPicker1">
                    <mat-datepicker-toggle matSuffix [for]="myPicker1"></mat-datepicker-toggle>
                    <mat-datepicker #myPicker1></mat-datepicker>
                    <mat-error *ngIf="paymentForm?.get('txtCheckDate')?.touched &&
                            paymentForm?.get('txtCheckDate')?.errors">
                        Enter Valid Date!
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                    <mat-label>Description</mat-label>
                    <textarea autocomplete="new-txtDescription" formControlName="txtDescription" rows="2" maxlength="200" matInput></textarea>
                </mat-form-field>
                <div class="col-3">
                    <input id="file-upload" formControlName="documentFile"
                        (change)="eftImportFormFile(uploadFileReference?.files)" [multiple]="true" #uploadFileReference
                        type="file" />
                    <button *ngIf="selFileNameList?.length===0" matTooltip="Browse Files"
                        (click)="uploadFileReference?.click()" mat-button class="buttonColor font-size-14 pl-2 ">
                        <i class="fa fa-file-import pl-2 pr-2"></i> Select Remits
                    </button>
                    <button *ngIf="selFileNameList?.length!==0" matTooltip="Browse Files"
                        (click)="uploadFileReference?.click()" mat-button class="resetclr font-size-14 pl-2 ">
                        <i class="fa fa-file-import pl-2 pr-2"></i> Change
                    </button>
                    <mat-error *ngIf="selFileNameList?.length===0">
                        EOB PDF Reqiured
                    </mat-error>
                </div>
            </div>
        </mat-card-content>
    </form>
    <!-- Insurance Payment Form END -->

    <div class="row mt-3">
        <div class="col-12">
            <table class="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th scope="col">File</th>
                        <th scope="col">Type</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let files of selFileNameList">
                        <tr *ngIf="files?.name">
                            <th *ngIf="files?.type==='PDF'" scope="row">EOB PDF File</th>
                            <th *ngIf="files?.type==='Text'" scope="row">EOB 835 File</th>
                            <td>{{ files?.type }}</td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <ng-container *ngIf="selFileNameList && selFileNameList?.length===0">
                        <tr>
                            <td colspan="3" class="center-align">No Records Found</td>
                        </tr>
                    </ng-container>
                </tfoot>
            </table>
        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="buttonColor" [disabled]='paymentForm?.invalid || selFileNameList?.length===0 ||isLoading'
        (click)="savePayments()" mat-button>Save</button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
