import { PurchaseOrderSummaryTableComponent } from './purchase-order-tab/purchase-order-summary-table/purchase-order-summary-table.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatNativeDateModule,
  MatOptionModule,
  MatPseudoCheckboxModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HorizontolModule } from 'projects/layout/src/app/app.module';
import { PatientState } from 'projects/patient/src/app/patient-store/patient.state';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { MatSortModule } from '@angular/material/sort';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InventoryItemComponent } from './inventory-item/inventory-item.component';
import { InventoryNotesComponent } from './inventory-notes/inventory-notes.component';
import { InventoryPayorComponent } from './inventory-payor/inventory-payor.component';
import { InventoryProviderDetailsComponent } from './inventory-provider-details/inventory-provider-details.component';
import { InventoryProviderComponent } from './inventory-provider/inventory-provider.component';
import { ProductBrandComponent } from './product-brand/product-brand.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { InventoryProductComponent } from './inventory-product/inventory-product.component';
import { AddProductModalComponent } from './add-product-modal/add-product-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { VendorComponent } from './vendor/vendor.component';
import { ProductStockComponent } from './product-stock/product-stock.component';
import { StockViewComponent } from './stock-view/stock-view.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { AddAllowanceComponent } from './add-allowance/add-allowance.component';
import { AddPurchaseOrderComponent } from './add-purchase-order/add-purchase-order.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AllowablesListComponent } from './allowables-list/allowables-list.component';
import { AllowablesEditComponent } from './allowables-edit/allowables-edit.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MasterCityComponent } from './master-city/master-city.component';
import { MasterStateComponent } from './master-state/master-state.component';
import { MasterModifiersComponent } from './master-modifiers/master-modifiers.component';
import { MatTableExporterModule } from 'mat-table-exporter';
// import { ProductBrandTabComponent } from './product-brand-tab.component/product-brand-tab.component';
import { ProductVendorTabComponent } from './product-vendor-tab.component/product-vendor-tab.component';
import { ProductCategoryTabComponent } from './product-category-tab.component/product-category-tab.component';
import { AllowablesTabComponent } from './allowables-tab/allowables-tab.component';
import { StockListTabComponent } from './stock-list-tab/stock-list-tab.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { MasterDocumentBinComponent } from './master-document-bin/master-document-bin.component';
// import { ZeroStockListComponent } from './zero-stock-list/zero-stock-list.component';
import { MasterWipStatusComponent } from './master-wip-status/master-wip-status.component';
import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { RequisitionApprovalComponent } from './requisition-approval/requisition-approval.component';
import { DataTablesModule } from 'angular-datatables';
import { RequisitionListComponent } from './requisition-list/requisition-list.component';
import { RequisitionItemsListComponent } from './requisition-items-list/requisition-items-list.component';
import { AdjustmentComponent } from './adjustment/adjustment.component';
import { StockListComponent } from './stock-list/stock-list.component';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { InventoryTransferComponent } from './inventory-transfer/inventory-transfer.component';
import { RequistionViewerComponent } from './requistion-viewer/requistion-viewer.component';
import { RequisitionFormComponent } from './requisition-form/requisition-form.component';
import { RequisitionApprovalTabComponent } from './requisition-approval-tab/requisition-approval-tab.component';
import { PurchaseOrderTabComponent } from './purchase-order-tab/purchase-order-tab.component';
import { NotesReasonComponent } from './notes-reason/notes-reason.component';
import { AllowablesCalculationComponent } from './allowables-calculation/allowables-calculation.component';
import { InsuranceAllowablesComponent } from './insurance-allowables/insurance-allowables.component';
import { MasterMminsuranceComponent } from './master-mminsurance/master-mminsurance.component';
import { MasterMaskComponent } from './master-mask/master-mask.component';
import { MmOrderReturnExchangeReason1Component } from './mm-Order-Return-Exchange-Reason1/mm-Order-Return-Exchange-Reason1.component';
import { InsuranceAllowablesTableComponent } from './insurance-allowables/insurance-allowables-table/insurance-allowables-table.component';
import { MasterPayorComponent } from './master-payor/master-payor.component';
import { InventoryNotesTableComponent } from './inventory-notes-table/inventory-notes-table.component';
import { PurchaseOrderTableComponent } from './purchase-order-tab/purchase-order-table/purchase-order-table.component';
import { MasterDocumentBinTableComponent } from './master-document-bin-table/master-document-bin-table.component';
import { MasterMminsuranceTableComponent } from './master-mminsurance-table/master-mminsurance-table.component';
import { InventoryProviderTableComponent } from './inventory-provider-table/inventory-provider-table.component';
import { ProductCategoryTableListComponent } from './product-category-tab.component/product-category-table-list/product-category-table-list.component';
import { ProductVendorTableListComponent } from './product-vendor-tab.component/product-vendor-table-list/product-vendor-table-list.component';
import { ProductTableListComponent } from './product-tab.component/product-table-list/product-table-list.component';
import { ProductTabComponent } from './product-tab.component/product-tab.component';
import { SizeComponent } from './size/size.component';
import { SizeTableListComponent } from './size/size-table-list/size-table-list.component';
import { MasterModifierV1Component } from './master-modifiers/master-modifier-v1/master-modifier-v1.component';
import { MaterDiscountComponent } from './mater-discount/mater-discount.component';
import { MasterDiscountTableComponent } from './mater-discount/master-discount-table/master-discount-table.component';
import { WriteOffNotesComponent } from './writeOff-notes/writeOff-notes.component';
import { InsuranceAllowablesUpdateComponent } from './insurance-allowables-update/insurance-allowables-update.component';
import { InsuranceAllowablesTableV1Component } from './insurance-allowables-update/insurance-allowables-tableV1/insurance-allowables-tableV1.component';
import { ProductUpdateUnitCostComponent } from './product-update-unit-cost/product-update-unit-cost.component';
import { ProductUpdateUnitCostTableComponent } from './product-update-unit-cost/product-update-unit-cost-table/product-update-unit-cost-table.component';
import { ProductHCPCCodeComponent } from './product-update-unit-cost/product-hcpc-code/product-hcpc-code.component';
import { BulkUpdateUnitCostAndAllowableComponent } from './bulk-update-unit-cost-and-allowable/bulk-update-unit-cost-and-allowable.component';
import { BulkUpdateUnitCostAndAllowableTableComponent } from './bulk-update-unit-cost-and-allowable/bulk-update-unit-cost-and-allowable-table/bulk-update-unit-cost-and-allowable-table.component';

const providers: any[] = [];
@NgModule({
  declarations: [
    AppComponent,
    InventoryNotesComponent,
    InventoryItemComponent,
    InventoryPayorComponent,
    InventoryProviderComponent,
    InventoryProviderDetailsComponent,
    ProductBrandComponent,
    ProductCategoryComponent,
    InventoryProductComponent,
    AddProductModalComponent,
    VendorComponent,
    ProductStockComponent,
    StockViewComponent,
    PurchaseOrderComponent,
    AddAllowanceComponent,
    AddPurchaseOrderComponent,
    AllowablesListComponent,
    AllowablesEditComponent,
    MasterCityComponent,
    MasterStateComponent,
    MasterModifiersComponent,
    MasterMminsuranceComponent,
    // ProductBrandTabComponent,
    ProductVendorTabComponent,
    ProductCategoryTabComponent,
    AllowablesTabComponent,
    StockListTabComponent,
    AddStockComponent,
    MasterDocumentBinComponent,
    // ZeroStockListComponent,
    MasterWipStatusComponent,
    RequisitionApprovalComponent,
    RequisitionListComponent,
    RequisitionItemsListComponent,
    AdjustmentComponent,
    RequisitionApprovalTabComponent,
    CustomDropdownComponent,
    StockListComponent,
    InventoryTransferComponent,
    RequistionViewerComponent,
      RequisitionFormComponent,
      PurchaseOrderTabComponent,
      NotesReasonComponent,
      AllowablesCalculationComponent,
      InsuranceAllowablesComponent,
      MasterMaskComponent,
      MmOrderReturnExchangeReason1Component,
      InsuranceAllowablesTableComponent,
      InsuranceAllowablesTableV1Component,
      MasterPayorComponent,
      InventoryNotesTableComponent,
      PurchaseOrderTableComponent,
      PurchaseOrderSummaryTableComponent,
      MasterDocumentBinTableComponent,
      MasterMminsuranceTableComponent,
      InventoryProviderTableComponent,
      ProductCategoryTableListComponent,
      ProductVendorTableListComponent,
      ProductTableListComponent,
      ProductTabComponent,
      SizeComponent,
      SizeTableListComponent,
      MasterModifierV1Component,
      MaterDiscountComponent,
      MasterDiscountTableComponent,
      WriteOffNotesComponent,
      InsuranceAllowablesUpdateComponent,
      ProductUpdateUnitCostComponent,
      ProductUpdateUnitCostTableComponent,
      ProductHCPCCodeComponent,
      BulkUpdateUnitCostAndAllowableComponent,
      BulkUpdateUnitCostAndAllowableTableComponent
   ],
  imports: [
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    HorizontolModule.forRoot(),
    NgbModule,
    NgbNavModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatTableExporterModule,
    ReactiveFormsModule,
    NgxMatColorPickerModule,
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatPaginatorModule,
    MatTooltipModule,
    DataTablesModule,
    MatTableModule,
    SharedModule,
    MatSortModule,
    MatSnackBarModule,
    MatDialogModule,
    MatRadioModule,
    MatNativeDateModule,
    // BackButtonDisableModule.forRoot(),
    MatSelectModule,
    RouterModule,
    NgxsFormPluginModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDatepickerModule,
    MatCardModule,
    MatPseudoCheckboxModule,
    MatButtonModule,
    MatIconModule,
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    TextMaskModule,
    NgxsModule.forRoot([PatientState]),
    MatDividerModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    NgxDaterangepickerMd
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add the schema here
})
export class AppModule {}
@NgModule({})
export class InventoryModule {
  static forRoot(): ModuleWithProviders<InventoryModule> {
    return {
      ngModule: AppModule,
      providers,
    };
  }
}
