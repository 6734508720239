import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { addOrder, getOrder, updateOrder } from './order.action';


@State<orderStateModel>({
  name: 'orderState',
  defaults: {
    orders: [],
    selectedOrder: null
  }
})
@Injectable()
export class orderState {
  constructor(
    private orderService: SaleorderService,
  ) { }
  // @Selector()
  // static getOrderList(state: orderStateModel) {
  //   return state.orders;
  // }
  @Action(addOrder)
  addOrder({ patchState }: StateContext<orderStateModel>, { payload }: addOrder) {
    return this.orderService.create(payload).pipe(tap((result) => {
      //
      patchState({
        selectedOrder: result
      });
    }));
  }

  @Action(updateOrder)
  updateOrder({ patchState }: StateContext<orderStateModel>, { id, payload }: updateOrder) {
    return this.orderService.update(id, payload).pipe(tap((result) => {
      patchState({
        selectedOrder: result
      });
    }));
  }
  @Action(getOrder)
  getSelectedOrderId({ patchState }: StateContext<orderStateModel>, { id }: getOrder) {
    return this.orderService.get(id).pipe(tap((result) => {
      patchState({
        selectedOrder: result
      });
    }));
  }

}
export class orderStateModel {
  orders: SaleorderDTO[];
  selectedOrder: SaleorderDTO | null;
}
