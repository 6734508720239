import { ApplicationConfigurationService, ListService } from '@abp/ng.core';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import {  OAuthService } from 'angular-oauth2-oidc';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { PermissionService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/permission/permission.service';
import {
  MyUserService,
  UserService,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/volo/abp/identity/models';
import { PermissionsService } from 'projects/admin/src/app/admin-proxy/volo/abp/permission-management/permissions.service';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient/patient.service';
import {
  permissionsDTO,
  TableService,
} from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { PushNotificationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/push-notification';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwPush } from '@angular/service-worker';
import { Store } from '@ngxs/store';
import { FetchNotifications } from '../notiifcation/notifications.actions';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
const defaultUrl = '../../../assets/images/user.png';
export const profileImgUrl = 'assets/images/user.png';
//import { authCodeFlowConfig } from 'projects/admin/src/app/identityserver-login/identityserver-login.component';

@Component({
  selector: 'app-horizontoltopbar',
  templateUrl: './horizontoltopbar.component.html',
  styleUrls: ['./horizontoltopbar.component.scss'],
  providers: [ListService],
})
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {
  isShownRolesCreate: boolean = true;
  toggleNotifyCompnent: boolean = true;
  profileImgUrl: string = profileImgUrl;
  isShownRolesUpdate: boolean = true;
  isShownRolesDelete: boolean = true;
  isShownRolesView: boolean = true;
  isShownRolesMenu: boolean = true;
  isShownUserCreate: boolean = true;
  isShownUserUpdate: boolean = true;
  isShownUserDelete: boolean = true;
  isShownUserView: boolean = true;
  isShownUsersMenu: boolean = true;
  isShownTenantCreate: boolean = true;
  isShownTenantUpdate: boolean = true;
  isShownTenantDelete: boolean = true;
  isShownTenantView: boolean = true;
  isShownTenantMenu: boolean = true;
  isShownItemCreate: boolean = true;
  isShownItemUpdate: boolean = true;
  isShownItemDelete: boolean = true;
  isShownItemView: boolean = true;
  isShownItemMenu: boolean = true;
  isShownInsuranceCreate: boolean = true;
  isShownInsuranceUpdate: boolean = true;
  isShownInsuranceDelete: boolean = true;
  isShownInsuranceView: boolean = true;
  isShownInsuranceMenu: boolean = true;
  isShownNotesCreate: boolean = true;
  isShownNotesUpdate: boolean = true;
  isShownNotesDelete: boolean = true;
  isShownNotesView: boolean = true;
  isShownNotesMenu: boolean = true;
  isShownOrderCreate: boolean = true;
  isShownOrderUpdate: boolean = true;
  isShownOrderDelete: boolean = true;
  isShownOrderView: boolean = true;
  isShownVerifyAuthOrderMenu: boolean = true;
  providerName: string = '';
  providerKey: string = '';
  LoginUserName: string = '';
  tenantId: string = '';
  roleName: string = '';
  tenantHide: boolean = false;
  adminLoginHide: boolean = false;
  roleId: string = '';
  userId: string = '';
  defaultGuid=defaultGuid;
  organizationUnitId: string = '';
  tenantUserHide: boolean = false;
  executiveHide: boolean = false;
  clientLoginlogoHide: boolean = false;
  clientHide: boolean = false;
  isShownSettingMenu: boolean = false;
  LoginUserTenenatName: string = '';
  isInventoryNeeded:boolean = false;
  LoginUserorganizationUnitName: string = '';
  arrOrganizationUnit: OrganizationUnitDTO[] = [];
  isShownFileUploadsViewButton: boolean = false;
  isShownFileUploadsCreateButton: boolean = false;
  isShownFileUploadsUpdateButton: boolean = false;
  isShownFileUploadsDeleteButton: boolean = false;
  isShownFileUploadsMenu: boolean = false;
  element: any;
  isShownCalendarList: boolean = true;
  toggle = true;
  status = 'Enable';
  showWarehouse: boolean = false;
  isShownWarehouseViewButton: boolean = false;
  isShownWarehouseCreateButton: boolean = false;
  isShownWarehouseUpdateButton: boolean = false;
  isShownWarehouseDeleteButton: boolean = false;
  navBarData: string;
  defauldGuids: string;
  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any,
    public router: Router,
    private tableService: TableService,
    public userProfileService: UserService,
    private permissionService: PermissionsService,
    private oauthService: OAuthService,
    public organizationService: OrganizationUnitService,
    public list: ListService,
    private permission: PermissionService,
    private patientService: PatientService,
    private dashboardService: DashboardService,
    private userService: MyUserService,
    private appConfigService: ApplicationConfigurationService,
    private orderService: SaleorderService,
    private pushNotificationService: PushNotificationService,
    private angularFireMessaging: AngularFireMessaging,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private swPush:SwPush

  ) {
    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.activateMenu();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    swPush.messages.subscribe(x=>{
    });
;
  }

  @ViewChildren('rla') links: QueryList<RouterLinkActive>;
  get isActive() {
    return this.links ? this.links.find((x) => x.isActive) != null : false;
  }

  ngOnInit() {
    //! get notifications list and counst initally
    this.angularFireMessaging.messages.subscribe((e) => {
      this.userId && this.store.dispatch(new FetchNotifications(this.userId));
    });
    this.tableService.setclientLoginView(null);
    if (!this.oauthService.hasValidAccessToken()) {
      this.oauthService.loadDiscoveryDocumentAndLogin();
    }


    this.oauthService.events
      .pipe(filter(e => e.type === 'session_terminated'))
      .subscribe(e => {
        console.warn('Your session has been terminated!');
      });

    this.oauthService.events
      .pipe(filter(e => e.type === 'token_expires'))
      .subscribe(e => {
        console.warn('received token_expires event', e);
        this.oauthService.silentRefresh();
      });
    // } else {
    //   this.oauthService.tryLoginImplicitFlow();
    // }
    //this.preventBack();
    // this.appConfigService.getConfiguration().subscribe(response => {
    //   (!response?.currentUser?.isAuthenticated) && this.router.navigate(['/']);
    // })
    this.loadProfile();
    this.loadPermissions();
    //this.loadUserProfile();
      this.defauldGuids = defaultGuid
    // get color navbar data
    this.tableService.getNavBarData().subscribe((value) => {
      this.navBarData = value;
    });

    const oauthEvents = this.oauthService?.events?.subscribe((response: any) => {
      const tokenRefreshError = 'token_refresh_error';
      const tokenInvalidGrantError = 'invalid_grant';
      const refreshTokenNotFound = 'refresh_token_not_found';
      if (response?.type === tokenRefreshError) {
        // If auth error is invalid grant or refresh error or refresh token not found
        if (
          (response?.reason && response?.reason?.error) ||
          response?.reason?.error?.error === tokenInvalidGrantError ||
          response?.reason?.error?.error_reason === refreshTokenNotFound
        ) {

        }
      }
    })
  }

  private updateFirePushNotifyToken(userId: string, tokenId: string) {
    return this.pushNotificationService.updateTokenByUserIdAndToken(
      userId,
      tokenId
    );
  }
  onNavigate(){
    window.open("https://patientportal.rndsoftech.com/", "_blank");
  }
  enableDisableRule() {
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
  }
  leaveApproval() {
    this.router.navigate(['userLeaveApply']);
  }

  calender() {
    this.router.navigate(['settings/calender']);
  }

  preventBack() {
    window.history.forward();
    setTimeout('preventBack()', -100000);
    window.onunload = function () {
      null;
    };
  }

  itemSupply() {
    this.router.navigate(['inventory/item/import'], { fragment: 'import' });
  }

  //! Document Viewer with Feilds side by side
  documnetViewerSideBySide() {
    const url = '/#/ViewDocumentDetailsAndApprove';
    window.open(url, '_blank');
  }

  // loadUserProfile() {
  //   //! User Profile API to get OrganizationUnitId, TenantId, RoleName, RoleId and UserId
  //   this.userProfileService.userProfile().subscribe(
  //     response => {
  //       //Organization Unit Id
  //       this.organizationUnitId = response?.organizationUnitId;
  //       this.tableService.setOrganizationUnitId(this.organizationUnitId);
  //       localStorage.setItem('initialOrganizationUnitId', this.organizationUnitId);

  //       //Role Id
  //       this.roleId = response?.roleId;
  //       localStorage.setItem('roleId', this.roleId);

  //       //User Id
  //       this.userId = response?.userId;
  //       this.tableService.setLoginUserId(this.userId);
  //       localStorage.setItem('userId', this.userId);

  //       //Tenant Id
  //       this.tenantId = response?.tenantId;
  //       localStorage.setItem('tenantId', this.tenantId);
  //       this.tableService.setTenantId(this.tenantId);

  //       this.roleName = response?.roleName;
  //       localStorage.setItem('roleName', this.roleName);
  //       this.tableService.setRoleName(this.roleName);

  //       this.LoginUserName = response?.userName;
  //       //  this.LoginUserTenenatName = response['tenantName'];
  //       this.LoginUserorganizationUnitName = this.arrOrganizationUnit?.filter(
  //         v => v?.id === response?.organizationUnitId
  //       )[0]?.organizationUnitName;
  //     },
  //     err => {}
  //   );
  // }
  id: string | undefined;
  isRtOrSchedulerLogin: boolean = false;
  showInventory: boolean = false;
  showExecutive: boolean = false;
  loadProfile() {
    this.dashboardService
      .userProfile()
      .pipe(
        map((response) => {
          this.tableService.setclientLoginView(response);
          this.id = response?.tenantId ?? '';
          this.providerKey = response?.roleName ?? '';
          this.LoginUserName = response?.userName ?? '';
          this.LoginUserTenenatName = response['tenantName'] ?? '';
          this.isInventoryNeeded = response?.isInventoryNeeded ?? false;
          this.tableService.setLoginUserName(this.LoginUserName);
          if (
            response.userId != null &&
            response.roleName != 'admin' &&
            response.organizationUnitId != null &&
            response.tenantId != null
          ) {
            this.isShownCalendarList = false;
          } else {
            this.isShownCalendarList = true;
          }

          //User Id
          this.userId = response?.userId ?? '';
          this.tableService.setLoginUserId(this.userId);

          if (this.userId !== '' && this.userId) {
            this.getUserProfileData(this.userId);
            this.getLogo();
          }
          //TenantId
          this.tenantId = response?.tenantId;
          this.tableService.setTenantId(this.tenantId);

          //OrganizationUnitId
          this.organizationUnitId = response?.organizationUnitId;
          this.tableService.setOrganizationUnitId(this.organizationUnitId);

          this.roleName = response?.roleName;
          this.tableService.setRoleName(this.roleName);
          this.roleId = response?.roleId;

          localStorage.setItem(
            'initialOrganizationUnitId',
            this.organizationUnitId
          );
          localStorage.setItem('roleId', this.roleId);
          localStorage.setItem('userId', this.userId);
          localStorage.setItem('tenantId', this.tenantId);
          localStorage.setItem('roleName', this.roleName);
          localStorage.setItem('name', this.capitalizeFirstLetter(response.name));
          localStorage.setItem('organizationId', this.organizationUnitId);
          localStorage.setItem('isInventoryNeeded', String(this.isInventoryNeeded));
          // localStorage.setItem('isInventoryNeeded', String(false));
          localStorage.setItem(
            'smrtlkDls',
            JSON.stringify({
              name: response?.userName ?? '',
              email: response?.email ?? '',
            })
          );

          // warehouse permisssion
          this.showWarehouse = response?.roleName === 'Warehouse Manager';
          this.showInventory =
            response?.roleName === 'Warehouse Manager' ||
            (response?.roleName == 'admin' && response?.tenantId !== null) ||
            response?.roleName === 'Warehouse Executive';
          this.isRtOrSchedulerLogin =
            response.roleName === 'Respiratory Therapist' ||
              response.roleName === 'Scheduler'
              ? true
              : false;

          this.showExecutive = response?.roleName === 'Warehouse Executive';
          // if (response) {
          //! super admin login
          if (response?.tenantId === null && response?.roleName == 'admin') {
            this.tableService.setAdminLogin(true);
            this.tenantUserHide = true;
            this.tenantHide = true;
            this.executiveHide = true;
            this.clientLoginlogoHide = false;
            this.clientHide = false;
            this.loadPermissions();
            return;
          }
          // Tenant Login
          if (
            response?.tenantId != null &&
            response?.roleName == 'admin' &&
            response?.organizationUnitId == null
          ) {
            this.tableService.setClientLogin(true);
            this.adminLoginHide = true;
            this.tenantUserHide = false;
            this.tenantHide = true;
            this.executiveHide = false;
            this.clientHide = true;
            this.clientLoginlogoHide = true;
            // this.tableService.setofficialName(response);
            this.loadPermissions();
            return;
          }

          //! executive
          if (response?.tenantId === null && response?.roleName != 'admin') {
            this.tenantUserHide = true;
            this.tenantHide = true;
            this.executiveHide = false;
            this.clientHide = true;
            this.loadPermissions();
            return;
          }
          //TenantUser Login
          else {
            this.tableService.setClientLogin(true);
            // this.tenantUserHide = false;
            this.tenantHide = true;
            this.adminLoginHide = true;
            this.clientLoginlogoHide = true;
            this.clientHide = true;
            // this.isRtOrSchedulerLogin = true;

            this.loadPermissions();

            return;
          }
          return response;
        })
      )
      .pipe(
        switchMap((usrPrfResponse) =>
          this.angularFireMessaging.requestToken.pipe(
            concatMap((response) => {
              localStorage.removeItem('fbToken');

              typeof response === 'string' &&
                localStorage.setItem('fbToken', response);
              return (
                this.userId &&
                typeof response === 'string' &&
                this.updateFirePushNotifyToken(this.userId, response)
              );
            })
          )
        )
      )
      .subscribe((response) => {
        (err: HttpErrorResponse) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        };
      });
  }

  tenantLogo: any;
  fileAs64Value: string = defaultUrl;
  //// logo get from api
  getLogo() {
    this.orderService.getTenantLogo().subscribe((response) => {
      // this.tenantLogo = response
      if (response) {
        this.fileAs64Value = String('data:image/jpeg;base64,' + response);
      } else {
        this.fileAs64Value = defaultUrl;
      }
    });
  }

  capitalizeFirstLetter(inputString: string | null | undefined): string {
    if (!inputString) {
        return '';
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

  getUserProfileData(userId: string) {
    this.userService.get(this.userId).subscribe(
      (response) => {
        const currentUser: IdentityUserDto = response;
        const profileImgData =
          currentUser?.extraProperties?.profileImageAsBase64String ?? '';
        const profileUrl =
          profileImgData === ''
            ? profileImgUrl
            : String('data:image/jpeg;base64,' + profileImgData) ??
            profileImgUrl;
        this.profileImgUrl = profileUrl;
      },
      (err) => {
        const data: HttpErrorResponse = err;

        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  clientView() {
    this.router.navigate(['clientView/' + this.id]);
    // this.userProfileService.userProfile().subscribe(response => {
    //   this.tableService.setclientLoginView(response);
    // })
  }
  loadPermissions() {
    this.permission.getCurrentUserPermission().subscribe((response) => {
      let WarehousePermssion: permissionsDTO = {};
      response &&
        response?.forEach((elements) => {
          switch (elements?.name) {
            //Roles
            case 'AbpIdentity.Roles.Create': {
              this.tableService.setRolesCreate(elements?.isGranted);
              this.isShownRolesCreate = elements?.isGranted;
              break;
            }
            case 'AbpIdentity.Roles.Update': {
              this.tableService.setRolesUpdate(elements?.isGranted);
              this.isShownRolesUpdate = elements?.isGranted;
              break;
            }
            case 'AbpIdentity.Roles': {
              this.tableService.setRolesView(elements?.isGranted);
              this.isShownRolesView = elements?.isGranted;
              break;
            }
            case 'AbpIdentity.Roles.Delete': {
              this.tableService.setRolesDelete(elements?.isGranted);
              this.isShownRolesDelete = elements?.isGranted;
              break;
            }
            case 'AbpIdentity.Roles.ManagePermissions': {
              this.tableService.setRolesManagePermission(elements?.isGranted);
              this.isShownRolesDelete = elements?.isGranted;
              break;
            }
            //Users
            case 'AbpIdentity.Users': {
              this.tableService.setUsersView(elements?.isGranted);
              this.isShownUserView = elements?.isGranted;
              break;
            }
            case 'AbpIdentity.Users.Create': {
              this.tableService.setUsersCreate(elements?.isGranted);
              this.isShownUserCreate = elements?.isGranted;
              break;
            }
            case 'AbpIdentity.Users.Update': {
              this.tableService.setUsersUpdate(elements?.isGranted);
              this.isShownUserUpdate = elements?.isGranted;
              break;
            }
            case 'AbpIdentity.Users.Delete': {
              this.tableService.setUsersDelete(elements?.isGranted);
              this.isShownUserDelete = elements?.isGranted;
              break;
            }
            //Tenants
            case 'AbpTenantManagement.Tenants': {
              this.tableService.setTenantView(elements?.isGranted);
              this.isShownTenantView = elements?.isGranted;
              break;
            }
            case 'AbpTenantManagement.Tenants.Create': {
              this.tableService.setTenantCreate(elements?.isGranted);
              this.isShownTenantCreate = elements?.isGranted;
              break;
            }
            case 'AbpTenantManagement.Tenants.Update': {
              this.tableService.setTenantUpdate(elements?.isGranted);
              this.isShownTenantUpdate = elements?.isGranted;
              break;
            }
            case 'AbpTenantManagement.Tenants.Delete': {
              this.tableService.setTenantDelete(elements?.isGranted);
              this.isShownTenantDelete = elements?.isGranted;
              break;
            }
            //Branch
            case 'PlatformManagement.Organizations': {
              this.tableService.setBranchView(elements?.isGranted);
              this.isShownTenantView = elements?.isGranted;
              break;
            }
            case 'PlatformManagement.Organizations.Create': {
              this.tableService.setBranchCreate(elements?.isGranted);
              this.isShownTenantCreate = elements?.isGranted;
              break;
            }
            case 'PlatformManagement.Organizations.Edit': {
              this.tableService.setBranchUpdate(elements?.isGranted);
              this.isShownTenantUpdate = elements?.isGranted;
              break;
            }
            case 'PlatformManagement.Organizations.Delete': {
              this.tableService.setBranchDelete(elements?.isGranted);
              this.isShownTenantDelete = elements?.isGranted;
              break;
            }

            //Patients
            case 'PatientManagement.Patients': {
              this.tableService.setPatientView(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Patients.Create': {
              this.tableService.setPatientCreate(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Patients.Edit': {
              this.tableService.setPatientUpdate(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Patients.Delete': {
              this.tableService.setPatientDelete(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Document.Delete': {
              this.tableService.setDocumentDelete(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Plans.Create': {
              this.tableService.setPlanCreate(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Plans.Edit': {
              this.tableService.setPlanUpdate(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Plans': {
              this.tableService.setPlanView(elements?.isGranted);
              break;
            }
            case 'PatientManagement.Plans': {
              this.tableService.setPlanDelete(elements?.isGranted);
              break;
            }
            //Orders
            case 'OrderManagement.Orders': {
              this.isShownOrderView = elements?.isGranted;
              this.tableService.setOrderView(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Orders.Create': {
              this.isShownOrderCreate = elements?.isGranted;
              this.tableService.setOrderCreate(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Orders.Edit': {
              this.isShownOrderUpdate = elements?.isGranted;
              this.tableService.setOrderUpdate(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Orders.Delete': {
              this.isShownOrderDelete = elements?.isGranted;
              this.tableService.setOrderDelete(elements?.isGranted);
              break;
            }
            //Item
            case 'OrderManagement.Items': {
              this.isShownItemView = elements?.isGranted;
              this.tableService.setItemView(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Items.Create': {
              this.isShownItemCreate = elements?.isGranted;
              this.tableService.setItemCreate(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Items.Edit': {
              this.isShownItemUpdate = elements?.isGranted;
              this.tableService.setItemUpdate(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Items.Delete': {
              this.isShownItemDelete = elements?.isGranted;
              this.tableService.setItemDelete(elements?.isGranted);
              break;
            }
            //Insurance
            case 'OrderManagement.Insurances': {
              this.isShownInsuranceView = elements?.isGranted;
              this.tableService.setInsuranceView(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Insurances.Create': {
              this.isShownInsuranceCreate = elements?.isGranted;
              this.tableService.setInsuranceCreate(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Insurances.Edit': {
              this.isShownInsuranceUpdate = elements?.isGranted;
              this.tableService.setInsuranceUpdate(elements?.isGranted);
              break;
            }
            case 'OrderManagement.Insurances.Delete': {
              this.isShownInsuranceDelete = elements?.isGranted;
              this.tableService.setInsuranceDelete(elements?.isGranted);
              break;
            }

            //Notes
            // case 'OrderManagement.Notes': {
            //   this.isShownNotesView = elements?.isGranted;
            //   this.tableService.setNotesView(elements?.isGranted);
            //   break;
            // }
            // case 'OrderManagement.Notes.Create': {
            //   this.isShownNotesCreate = elements?.isGranted;
            //   this.tableService.setNotesCreate(elements?.isGranted);
            //   break;
            // }
            // case 'OrderManagement.Notes.Edit': {
            //   this.isShownNotesUpdate = elements?.isGranted;
            //   this.tableService.setNotesUpdate(elements?.isGranted);
            //   break;
            // }
            // case 'OrderManagement.Notes.Delete': {
            //   this.isShownNotesDelete = elements?.isGranted;
            //   this.tableService.setNotesDelete(elements?.isGranted);
            //   break;
            // }

            //! FileUpload
            case 'PlatformManagement.FileUploads': {
              this.isShownFileUploadsViewButton = elements?.isGranted;
              this.tableService.setFileUploadCreate(elements?.isGranted);
              break;
            }
            case 'PlatformManagement.FileUploads.Create': {
              this.isShownFileUploadsCreateButton = elements?.isGranted;
              this.tableService.setFileUploadCreate(elements?.isGranted);
              break;
            }
            case 'PlatformManagement.FileUploads.Edit': {
              this.isShownFileUploadsUpdateButton = elements?.isGranted;
              this.tableService.setFileUploadUpdate(elements?.isGranted);
              break;
            }
            case 'PlatformManagement.FileUploads.Delete': {
              this.isShownFileUploadsDeleteButton = elements?.isGranted;
              this.tableService.setFileUploadDelete(elements?.isGranted);
              break;
            }

            default: {
              //statements;
              break;
            }
          }
          //! settigns menu hide

          //ItemMenu Show/Hide
          if (
            this.isShownItemView == false &&
            this.isShownItemCreate == false &&
            this.isShownItemUpdate == false &&
            this.isShownItemDelete == false
          ) {
            this.isShownItemMenu = false;
          } else {
            this.isShownItemMenu = true;
          }
          if (
            this.isShownNotesView == false &&
            this.isShownNotesCreate == false &&
            this.isShownNotesUpdate == false &&
            this.isShownNotesDelete == false
          ) {
            this.isShownNotesMenu = false;
          } else {
            this.isShownNotesMenu = true;
          }

          // settings show hide
          if (
            this.isShownRolesCreate == true ||
            this.isShownRolesUpdate == true ||
            this.isShownRolesView == true ||
            this.isShownRolesDelete == true ||
            this.isShownUserView == true ||
            this.isShownUserCreate == true ||
            this.isShownUserUpdate == true ||
            this.isShownUserDelete == true
          ) {
            this.isShownSettingMenu = true;
          } else {
            this.isShownSettingMenu = false;
          }
          if (
            this.tenantUserHide == false &&
            this.isShownRolesCreate == false &&
            this.isShownRolesUpdate == false &&
            this.isShownRolesDelete == false &&
            this.isShownUserCreate == false &&
            this.isShownUserUpdate == false &&
            this.isShownUserDelete == false
          ) {
            this.isShownSettingMenu = false;
          }
        });
    });
  }
  // loadPermissions() {
  //   const canCreate = this.test.getGrantedPolicy('PatientManagement.Patients.Create');

  // }

  /**
   * Logout the user
   */

  logout() {
    const fbToken = localStorage.getItem('fbToken') ?? '';
    Swal.fire({
      title: 'Are you sure you want to Logout?',
      //text: 'You won\'t be able to retrieve this data!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#50a5f1',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Logout!',
    }).then((result) => {
      if (result.value) {
        //unclock all locked patients by cuurent login user id when logout
        // this.patientService
        //   .unlockAllLockedPatientsByUserId(this.userId)
        //   .subscribe((response) => {
        //     fbToken &&
        // this.pushNotificationService
        //   .deleteTokenByUserIdAndToken(this.userId, fbToken)
        //   .subscribe(
        //     (response) => {
        //       fbToken &&
        //         this.angularFireMessaging.deleteToken(fbToken).subscribe(
        //           (response) => {
        //             localStorage.removeItem('fbToken');
        //           },
        //           (err) => {
        //             const data: HttpErrorResponse = err;
        //             Swal.fire({
        //               icon: 'error',
        //               text: data?.error?.error?.message,
        //             });
        //           }
        //         );
        //     },
        //     (err) => {
        //       const data: HttpErrorResponse = err;
        //       Swal.fire({
        //         icon: 'error',
        //         text: data?.error?.error?.message,
        //       });
        //     }
        //   );
        //  if (response != null && response?.items) {
        this.oauthService.logOut();
        localStorage.clear();
        sessionStorage.clear();
        this.oauthService.loadDiscoveryDocument();
        //  }
        //  });

      }
    });
  }

  /**
   * On menu click
   */
  onMenuClick(event: any) {
    const nextEl = event.target.nextSibling;
    if (nextEl && !nextEl.classList.contains('open')) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('open');
      }

      nextEl.classList.add('open');
    } else if (nextEl) {
      nextEl.classList.remove('open');
    }
    return false;
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: string) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0]?.classList.remove(className);
    }
  }

  /**
   * Activates the menu
   */
  private activateMenu() {
    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem: Element = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem?.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Topbar light
   */
  topbarLight() {
    document.body.setAttribute('data-topbar', 'light');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Set boxed width
   */
  boxedWidth() {
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-topbar', 'dark');
  }

  /**
   * Colored header
   */
  coloredHeader() {
    document.body.setAttribute('data-topbar', 'colored');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    // this.eventService.broadcast('changeLayout', layout);
    window.location.reload();
  }
}

function preventBack() {
  window.history.forward();
  setTimeout('preventBack()', -100000);
  window.onunload = function () {
    null;
  };
}
