import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateMasterRulesDTO, MasterRulesDTO, UpdateRuleDTO } from '../tenants/dto/models';

@Injectable({
  providedIn: 'root',
})
export class MasterRulesService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateMasterRulesDTO) =>
    this.restService.request<any, MasterRulesDTO>({
      method: 'POST',
      url: '/api/PlatformApp/master-rules',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/master-rules/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, MasterRulesDTO>({
      method: 'GET',
      url: `/api/PlatformApp/master-rules/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MasterRulesDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/master-rules',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateMasterRulesDTO) =>
    this.restService.request<any, MasterRulesDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/master-rules/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateMasterRuleValidationsByLstMasterRules = (lstMasterRules: UpdateRuleDTO[]) =>
    this.restService.request<any, MasterRulesDTO[]>({
      method: 'PUT',
      url: '/api/PlatformApp/master-rules/update-master-rule-validations',
      body: lstMasterRules,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
