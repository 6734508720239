<mat-card class="mt-3">
    <!-- <div class="card card-body"> -->
        <div class="row mb-1">
            <div class="col-md-6">
                <h4 class="customThemeClass"><b>Claim</b></h4>
            </div>
            <div class="col-md-6 text-sm-right">
                <div class="text-sm-right col-lg-12 p-0">
                    <form [formGroup]="commonClaimForm">
                        <mat-checkbox class="mt-2 mr-3" (change)="showIntOptions($event)" [checked]="showInt"
                            formControlName="chkInt">
                            Show Int
                        </mat-checkbox>
                        <mat-checkbox class="mt-2 mr-5" (change)="showPaidOptions($event)" [checked]="showPaid"
                            formControlName="chkShow">
                            Show Paid
                        </mat-checkbox>
                    </form>
                </div>
            </div>
        </div>
        <mat-divider class="bg mb-5"> </mat-divider>
        <!-- <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between">
                        <mat-card-title class="customThemeClass"><b>Claim</b></mat-card-title>
                        <div class="page-title-box page-title-right">
                            <ol class="breadcrumbs m-0 p-0 align-items-center">
                                <form [formGroup]="commonClaimForm">
                                    <div class="row align-items-center">

                                        <mat-checkbox class=" mt-2" (change)="showIntOptions($event)"  formControlName="chkInt">Show Int</mat-checkbox>
                                    <mat-checkbox class=" mt-2" (change)="showPaidOptions($event)" showOptionsformControlName="chkShow">Show
                                        Paid</mat-checkbox>
                                    </div>
                                </form>
                            </ol>
                        </div>
                    </div>
                </div>
            </div> -->
        <!-- 
            <hr class="p-0 m-0 "> -->

        <div class="row">
            <div class="col-12" *ngIf="!isLoading">
                <app-patient-common-claim-table [tableDataList]="claimDetailsList"></app-patient-common-claim-table>
            </div>
        </div>

        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
    <!-- </div> -->
</mat-card>