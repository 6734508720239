import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory';
import { MasterNotesDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { AddNotesComponent } from '../add-notes/add-notes.component';
import {
  NotesSeverityDTO,
  NotesStatusDTO,
  NotesTypeDTO,
  PatientMasterDropdownDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import {
  PatientDTO,
  PatientLockDTO,
} from '../patient-proxy/patient/dto/models';
import { NotesSearchService } from '../patient-proxy/patient/notes-search.service';
import { PatientService } from '../patient-proxy/patient/patient.service';
import { WindowRef } from '../patient-search/patient-search.component';

@Component({
  selector: 'app-patient-notes',
  templateUrl: './patient-notes.component.html',
  styleUrls: ['./patient-notes.component.scss'],
  providers: [WindowRef, DatePipe],
})
export class PatientNotesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() patientIdEmitter: EventEmitter<PatientDTO> = new EventEmitter<PatientDTO>(null);
  public strPageType: string = 'note';
  subscription$: Subscription[] = [];
  @Input() patientID: string = '';
  statuses: NotesStatusDTO[] = [];
  drpseverity: NotesSeverityDTO[] = [];
  tblNotesReponse: any[] = [];
  window: Window;
  NotesData: searchNotesDTO[] = [];
  notesForm: FormGroup;
  isLoading: boolean = true;
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  lstNotesType: any[] = [];
  dataSource = new MatTableDataSource(this.NotesData);
  @ViewChild('noteTbSort', { static: false }) noteTbSort: MatSort;

  isShownUpdateIconForNotes: boolean = true;
  isDeleteForNotes: boolean = true;
  displayedColumns: string[] = ['Options',,'defaultNotesId', 'createdBy', 'dateCreated', 'subject',  'noteReason', 'noteType', 'dateNeeded', 'actualDate', 'status',];
  drpState: MasterNotesDTO[] = [];
  constructor(
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private Table: TableService,
    private deleteSerivice: PatientService,
    private formBuilder: FormBuilder,
    private windowRef: WindowRef,
    private dateValidator: DateValidator,
    private patientDropdownService: PatientMasterDropdownService,
    private notesSearchService: NotesSearchService,
    private patientService: PatientService,
    private notesService: MasterNotesService
  ) {
    this.window = this.windowRef.nativeWindow;

    this.notesForm = this.formBuilder.group({
      notesId: '',
      defaultNotesId: '',
      createdBy: '',
      dateCreated: new FormControl('', this.dateValidator.dateVaidator),
      actualDate: new FormControl('', this.dateValidator.dateVaidator),
      noteType: '',
      noteReason: '',
      dateNeeded: new FormControl('', this.dateValidator.dateVaidator),
      status: '',
      subject: '',
    });

    const valueChanges: Subscription = this.notesForm.valueChanges.subscribe(
      (value: searchNotesDTO) => {
        const filter: searchNotesDTO = {
          notesId: value.defaultNotesId.trim().toLowerCase(),
          defaultNotesId: value.defaultNotesId.trim().toLowerCase(),
          createdBy: value.createdBy.trim().toLowerCase(),
          dateCreated: value.dateCreated.trim().toLowerCase(),
          actualDate: value.actualDate.trim().toLowerCase(),
          dateNeeded: value.dateNeeded.trim().toLowerCase(),
          noteType: value.noteType.trim().toLowerCase(),
          noteReason: value.noteReason.trim().toLowerCase(),
          status: value.status.trim().toLowerCase(),
        };
        if (this.notesForm.valid) {
          this.dataSource.filter = JSON.stringify(filter);
          this.filtertable(filter);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(valueChanges);
  }
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  ngOnInit(): void {
    this.isLoading = true;
    const getLoginUserId = this.Table.getLoginUserId().subscribe((val) => {
      this.strLoginUserId = val;
    });
    this.subscription$?.push(getLoginUserId);
    //
    this.getDropdown();
  }
  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.sort.disableClear = true;
    this.dataSource.sort = this.noteTbSort;
    this.dataSource.sortingDataAccessor = (row: any, columnName: string): string => {
      if (columnName == "project.name") return row.project.name;
      var columnValue = row[columnName as keyof any] as string;
      return columnValue;
    }
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  filtertable(filter) {
    const notes = {
      patientId: this.patientID,
      notesId: filter.defaultNotesId,
      defaultNotesId: filter.defaultNotesId,
      createdBy: filter.createdBy,
      dateCreated: filter.dateCreated,
      actualDate: filter.actualDate,
      dateNeeded: null,
      noteType: filter.noteType,
      noteReason: filter.noteReason,
      status: filter.status,
    };
    this.NotesData = [];
    const notesFilter = this.notesSearchService
      .searchNotesByInput(notes)
      .subscribe(
        (data) => {
          this.NotesData = [];
          data &&
            data?.items?.forEach(
              (element) => {
                this.NotesData.push({
                  notesId: element?.notesId,
                  createdBy: element?.createdBy,
                  defaultNotesId: element.defaultNotesId,
                  dateCreated:
                    element?.dateCreated == null
                      ? ''
                      : new Date(element?.dateCreated)?.toLocaleDateString(
                        'en-US'
                      ),
                  actualDate:
                    element?.actualDate == null
                      ? ''
                      : new Date(element?.actualDate)?.toLocaleDateString(
                        'en-US'
                      ),
                  noteType: element.noteType,
                  status: element?.status,
                  noteReason: element?.noteReason,
                  dateNeeded: element?.dateNeeded == null ? '' : new Date(element?.dateNeeded)?.toLocaleDateString('en-US'),
              //    subject: this.drpState?.filter(n => n?.id === element?.subject)[0]?.subject ?? ""
                });

                // })
                this.isLoading = false;
                this.NotesData = this.NotesData.sort((a, b) => new Date(b?.dateCreated)?.getTime() - new Date(a?.dateCreated)?.getTime());
                this.dataSource = new MatTableDataSource(this.NotesData ?? []);

                this.sort.disableClear = true;
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
              },
              (error) => {
                this.isLoading = false;
                this.dataSource = new MatTableDataSource(this.NotesData);
                this.sort.disableClear = true;
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
              }
            );
        },
        (err) => {
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.subscription$?.push(notesFilter);
    this.dataSource = new MatTableDataSource(this.NotesData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  getTableData(patientID: string) {
    this.isLoading = true;
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.NoteTypes];
    const PatientTable = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe((stateResponse) => {
        const response: PatientMasterDropdownDTO = stateResponse;
        this.lstNotesType = response.lstNotesType;
      });
    this.subscription$.push(PatientTable);

    const notesListSubscription = this.notesService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      response && response?.totalCount && response?.items && response?.items?.forEach(element => {
      })
      this.drpState = response.items;
      let notesDetails: PatientDTO;
      this.NotesData = [];

      const storeSubscription = this.patientService
        .get(this.patientID)
        .subscribe(
          (response) => {
            notesDetails = response;
            const patientLock: PatientLockDTO = response?.patientLock;
            // Patient is locked & userId is not equal to loginUserId or patient is unlocked
            this.isPatientLockedByCurrentUser =
              (patientLock?.isLocked == true &&
                patientLock?.userId == this.strLoginUserId) ||
                patientLock?.isLocked == false
                ? true
                : false;
            this.NotesData = [];

            notesDetails?.notes?.forEach((element) => {
              // if (element?.id !== "00000000-0000-0000-0000-000000000000") {
              this.NotesData.push({
                notesId: element?.notesId,
                createdBy: element?.createdBy,
                defaultNotesId: element.defaultNotesId,
                dateCreated: element?.dateCreated,
                // element?.dateCreated == null
                //   ? ''
                //   : new Date(element?.dateCreated)?.toLocaleDateString(
                //     'en-US'
                //   ),
                actualDate: element?.actualDate ,
                  // element?.actualDate == null
                  //   ? ''
                  //   : new Date(element?.actualDate)?.toLocaleDateString(
                  //     'en-US'
                  //   ),
                noteType: element.noteType,
                status: element?.status,
                noteReason: element?.noteReason,
                dateNeeded: element?.dateNeeded,
                  // element?.dateNeeded === null
                  //   ? ''
                  //   : this.datepipe.transform(
                  //     element?.dateNeeded,
                  //     'MM/dd/yyyy'
                  //   ) ?? '',
               // subject: this.drpState?.filter(n => n?.id === element?.subject)[0]?.subject ?? ""

              });

              // }
            });
            this.isLoading = false;

            this.NotesData = this.NotesData.sort((a, b) => new Date(b?.dateCreated)?.getTime() - new Date(a?.dateCreated)?.getTime());

            this.dataSource = new MatTableDataSource(this.NotesData);
            this.sort.disableClear = true;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
            this.isLoading = false;
            this.dataSource = new MatTableDataSource(this.NotesData);
            this.sort.disableClear = true;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }
        );
      this.subscription$.push(storeSubscription);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(notesListSubscription);



  }

  // !! delete note
  // deleteNote(notesId?: any) {
  //   //
  //   this.deleteNotes(notesId)
  // }

  // deleteNotes(noteId: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.deleteSerivice.deleteNotes(this.patientID, noteId).subscribe(response => {

  //         this.NotesData = [];
  //         response?.notes.forEach(element => {
  //           this.NotesData.push({
  //             notesId: element?.notesId,
  //             createdBy: element?.createdBy,
  //             defaultNotesId: element.defaultNotesId,
  //             dateCreated: element?.dateCreated == null ? "" : new Date(element?.dateCreated)?.toLocaleDateString("en-US"),
  //             actualDate: element?.actualDate == null ? "" : new Date(element?.actualDate)?.toLocaleDateString("en-US"),
  //             noteType: this.drpNoteType?.filter(value => { return value && value?.shortCodeId === element?.noteType })[0]?.notesType ?? "",
  //             status: element?.status === "001" ? "Open" : element?.status === "002" ? "Closed" : element?.status === "003" ? "None" : "",
  //             noteReason: element?.noteReason,
  //             dateNeeded: element?.dateNeeded == null ? "" : new Date(element?.dateNeeded)?.toLocaleDateString("en-US"),
  //           });
  //         });
  //         this.isLoading = false;
  //         this.dataSource = new MatTableDataSource(this.NotesData);
  //             this.sort.disableClear = true;
  //         this.dataSource.paginator = this.paginator;

  //       }, err => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'error',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //     }
  //   });
  // }

  deleteNotes(noteId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        //
        const deleteNotes = this.deleteSerivice
          .deleteNotes(this.patientID, noteId)
          .subscribe(
            (response) => {
              this.NotesData.forEach((element) => {
                if (noteId == element?.notesId) {
                  const index = this.NotesData.indexOf(element, 0);
                  this.NotesData.splice(index, 1);
                }
              });
              this.getTableData(this.patientID);
              // this.resetUsers();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        this.subscription$.push(deleteNotes);
      }
    });
  }

  drpNoteType: NotesTypeDTO[];
  getDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.NoteTypes,
      PatientDropdowns.NoteStatus,
      PatientDropdowns.NoteSeverities,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.drpNoteType = response?.lstNotesType ?? [];
          this.statuses = response?.notesStatuses ?? [];
          this.drpseverity = response?.notesSeverities ?? [];
          this.patientID = this.patientID ?? '';
          if (this.patientID !== '') {
            this.getTableData(this.patientID);
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);
  }

  openNoteModal() {
    const dialogRef = this.dialog.open(AddNotesComponent, {
      disableClose: true,
      data: { patientID: this.patientID },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (result) => {
        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.patientIdEmitter.emit(result);
        this.getTableData(this.patientID);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  ViewNotes(notesid?: any) {
    const dialogRef = this.dialog.open(AddNotesComponent, {
      data: { patientID: this.patientID, notesId: notesid },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (result) => {
        this.getTableData(this.patientID);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
}

export interface searchNotesDTO {
  notesId?: string;
  defaultNotesId?: string;
  createdBy?: string;
  dateCreated?: string;
  actualDate?: string;
  dateNeeded?: string;
  noteType?: string;
  noteReason?: string;
  status?: string;
  subject?: string;
}
