import { PatientName } from './../../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto/models';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { UploadEOBComponent } from './upload-eob/upload-eob.component';
import { InsuranceRecoopComponent } from './insurance-recoop/insurance-recoop.component';
import {
  ClaimFormDetailsDTO,
  ClaimStatusListDTO,
} from '../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/dto/models';
import { ClaimViewTabComponent } from '../../claim-view-tab/claim-view-tab.component';
import { PreviewDocumentComponent } from 'projects/order/src/app/preview-document/preview-document.component';
import { UpdateClaimStatusComponent } from './update-claim-status/update-claim-status.component';
import { MoveToSecondaryModelComponent } from '../../auto-posting-era/move-to-secondary-model/move-to-secondary-model.component';
import { PaymentMethodsModalComponent } from 'projects/order/src/app/payment-methods-modal/payment-methods-modal.component';
import { ClearingHouseUpdateBatchesService } from '../../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { ErrorDetails277DTO } from '../../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';
import { WriteOffModelComponent } from '../../writeOff-Model/writeOff-Model.component';
import { WriteOffNotesComponent } from 'projects/inventory/src/app/writeOff-notes/writeOff-notes.component';
import { ClaimFormDetailsService } from '../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-form-details.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { nameToState } from '@ngxs/store/src/internal/internals';

@Component({
  selector: 'app-claim-status-list-table',
  templateUrl: './claim-status-list-table.component.html',
  styleUrls: ['./claim-status-list-table.component.scss'],
})
export class ClaimStatusListTableComponent implements OnInit {
  @Input() claimsTableData: ClaimStatusListDTO[];
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Output() nevigatePage? = new EventEmitter<number>();
  @Input() paginationInfo: {
    startRecord: number;
    endRecord: number;
    overallTableCount: number;
  };

  @Output() reloadClaimTable = new EventEmitter();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  $subscription: Subscription[] = [];
  private apiSubscription: Subscription | null = null;
  @HostBinding('style.font') font =
    '400 13px/20px Roboto, "Helvetica Neue", sans-serif';
  @HostBinding('style.letter-spacing') letterSpacing = 'normal';
  dtClaimTableOptions: any;
  ltErrors: ErrorDetails277DTO[] = [];
  defaultGuid = defaultGuid;
  myTable: DataTables.Api;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private clearingHouseUpdatesService: ClearingHouseUpdateBatchesService,
    private communicationService: CommunicationService,
    private datePipe: DatePipe,
    private claimFormDetailsService: ClaimFormDetailsService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.dtClaimTableOptions = {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${
          this.paginationInfo?.endRecord ?? 0
        } of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      fixedHeader: true,

      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      order: [],
      search: {
        smart: false,
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Claim Status List',
          className: 'custom-dt-export-button',
          exportOptions: {
            columns: ':visible:not(:first-child):not(:eq(19))',
          },
        },
        {
          extend: 'colvis',
          className: 'custom-dt-export-button',
          columns:
            ':not(:eq(0), :eq(1), :eq(2),:eq(3),:eq(4),:eq(-3),:eq(-2),:eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        {
          text: 'Show Default Column',
          className: 'custom-dt-export-button',
          action: function (e, dt, node, config) {
            dt.columns(':not(:lt(4),:last-child)').visible(false);
          },
        },
        {
          text: 'Show All Column',
          className: 'custom-dt-export-button',
          action: function (e, dt, node, config) {
            dt.columns().visible(true);
          },
        },
      ],
      initComplete: function (this: any) {
        //Search Input Alignment
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }

        //Search Input Design and Icon
        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          searchContainer.className = 'search-container';
          searchFilterInput.style.paddingLeft = '25px';
          searchFilterInput.style.fontStyle = 'italic';
          searchFilterInput.style.fontSize = '12px';

          // Add event listeners for focusin and focusout
          searchFilterInput.addEventListener('focus', () => {
            searchFilterInput.style.border = '1px solid lightseagreen';
            searchFilterInput.style.outline = 'lightseagreen';
          });

          searchFilterInput.addEventListener('blur', () => {
            searchFilterInput.style.border = '';
            searchFilterInput.style.outline = '';
          });

          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);

          const searchIcon = document.createElement('i');
          searchIcon.className = 'fa fa-search';
          searchIcon.style.position = 'absolute';
          searchIcon.style.left = '10px';
          searchIcon.style.top = '16px';
          searchIcon.style.transform = 'translateY(-50%)';
          searchIcon.style.pointerEvents = 'none';
          searchIcon.style.color = '#aaa';

          searchContainer.appendChild(searchIcon);
        }
      },
      columnDefs: [
        {
          targets: [0, 1, 2, 3, 4, 21, 22, 23, -1],
          className: 'dt-fixed-column',
        }, // Ad
        { targets: 0, orderable: false }, // Targets the first column (index 0)
        // { targets: 23, visible: false }, // Targets the first column (index 0)
      ],
    };

    this.dtClaimTableOptions.buttons[0].filename =
      'Claim Status List' +
      '-' +
      this.datePipe.transform(new Date(), 'MM-dd-yyyy');

    this.calculateScrollY();
    // console.log(this.claimsTableData);
    window.addEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    if (this.$subscription) {
      // Iterate through each subscription and unsubscribe
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    // return luminance > 0.5 ? '#000000' : '#FFFFFF';
    return luminance > 0.5 ? '#000000' : '#000000';
  }

  updateTextColor(colorCode: string) {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black
    }
  }
  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  getFormattedDateAndTime(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '-';
  }

  getFormattedDateTime(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // 12-hour format with AM/PM
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.7; // Adjust the percentage as needed
    this.dtClaimTableOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();

      var index = 0;
      dtInstance.columns().every(function () {
        const that = this;

        index++;

        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  openClaimForm(
    orderId: string,
    patientId: string,
    chartNo: string,
    patientName: string,
    defaultSaleOrderId: string,
    claimId: string,
    isClaimBatch: boolean,
    isResubmit?: boolean
  ) {
    const dialogRef = this.dialog.open(ClaimViewTabComponent, {
      disableClose: true,
      data: {
        orderId: orderId,
        patientId: patientId,
        defaultSaleOrderId: defaultSaleOrderId,
        claimId: claimId,
        isClaimBatch: isClaimBatch,
        fullName: patientName,
        chartNo: chartNo,
        isResubmit: isResubmit ?? false,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        if (isResubmit) {
          this.communicationService.functionLoadClaimStatusList();
        }
        // console.log('claimDetails')
        // console.log(claimDetails)
        // // && item.policyId === claimDetails.insurancePlanName
        // const existingItemIndex = this.lstClaimProcessList.findIndex(
        //   (item) => item.mmOrderId === claimDetails.orderId
        // );
        // if (existingItemIndex !== -1) {
        //   // Item with the flag already exists, update it
        //   this.lstClaimProcessList[existingItemIndex].claimId=claimDetails.id;
        //   this.lstClaimProcessList[existingItemIndex].status='No Pending Data';
        //   this.lstClaimProcessList[existingItemIndex].selected=true;
        //   let x = this.lstClaimProcessList.filter((dx) =>
        //     dx.selected === true
        //   );
        //   this.totalSelectedOrders++;
        //   this.claimIdArray.push(claimDetails.id)
        //   this.countsUpdate.emit({ claimId : claimDetails.id,status:true,total:null});
        // }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    this.$subscription.push(closeDialog);

    // dialogRef.afterClosed().subscribe(() => {
    //   // this.loadClaimProcessList()

    //   //  this.lstClaimProcessList.forEach(x => {
    //   //  if(x.claimId != defaultGuid){
    //   //   this.claimIdArray.push(x.claimId)
    //   //   x.selected = true

    //   //  }
    //   // })

    // });
  }

  openEOBPDF(data: any) {
    const dialogRef = this.dialog.open(PreviewDocumentComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentData: data.eobBlobName,
        isClaim: true,
      },
    });

    // Subscribe to the closed event
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      // dialogRef.componentInstance.addNotes();
    });
    this.$subscription.push(closeDialog);
  }

  openAddNotesPopUpV1(
    // defaultFaxId: string,
    // patientId: string,
    // docId: string,
    // isCallNote: boolean
    event: MouseEvent,
    data: any,
    isCallNote: boolean
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        claimId: data.claimId,
        // defaultFaxId: data.defaultFaxId,
        // isCallNote: isCallNote,
        // isPending: false,
        // docId: data.docId,
        // inboundStatusShortCodeId: defaultGuid,
        // selectedNode: null,
        addnotes: false,
        patientNotes: data.patientNotes,
        // orderId: data.orderId,
        patientId: data.patientId,
        chartNo: data.chartId,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      (patientNotes: any) => {
        if (patientNotes) {
          data.patientNotes = patientNotes;
          this.collapseAllRows();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.showNotification(data?.error?.error?.message);
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    this.$subscription.push(closeDialog);
  }

  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        this.showNotification(data?.error?.error?.message);
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    this.$subscription.push(closeDialog);
  }

  viewPatientLedger(patientId: string) {
    const dialogRef = this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    // this.dialog.afterAllClosed.subscribe((resposne) => {});

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          this.reloadClaimTable.emit();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }

  uploadEOB(cliamData: any, isSaveEnable: boolean) {
    const dialogRef = this.dialog.open(UploadEOBComponent, {
      // width: '100vw',
      // height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: cliamData,
        isSaveEnable: isSaveEnable,
      },
    });
    // this.dialog.afterAllClosed.subscribe((resposne) => {

    // });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          this.reloadClaimTable.emit();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }

  insuranceRecoop(cliamData: any) {
    this.dialog.open(InsuranceRecoopComponent, {
      // width: '100vw',
      // height: '100vh',
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: cliamData,
      },
    });
    const closeDialog = this.dialog.afterAllClosed.subscribe((resposne) => {
      // this.reloadClaimTable.emit({ claimId : claimDetails.id,status:true,total:null});
      this.reloadClaimTable.emit();
    });
    this.$subscription.push(closeDialog);
  }

  UpdateStatus(cliamData: any) {
    this.dialog.open(UpdateClaimStatusComponent, {
      disableClose: true,
      data: {
        cliamData: cliamData,
      },
    });
    const closeDialog = this.dialog.afterAllClosed.subscribe((resposne) => {
      this.reloadClaimTable.emit();
    });
    this.$subscription.push(closeDialog);
  }

  OpenMoveToSecondaryModel(claimData: any) {
    const dialogRef = this.dialog.open(MoveToSecondaryModelComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
        claimData: claimData,
      },
      disableClose: true,
    });

    const closeDialog = dialogRef.afterClosed().subscribe((isSaved: any) => {
      if (isSaved) {
        this.reloadClaimTable.emit();
      }
    });
    this.$subscription.push(closeDialog);
  }

  OpenPatientResponsibilityModel(claimId: string, claimData: any) {
    const dialogRef = this.dialog.open(PaymentMethodsModalComponent, {
      data: { orderId: defaultGuid, claimId: claimId, claimData: claimData }, // this.orderId
      disableClose: true,
      // You can pass data to your dialog if needed
    });
    // Handle the dialog result if needed
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadClaimTable.emit();
      }
    });
    this.$subscription.push(closeDialog);
  }
  OpenWriteOff(claimId) {
    const dialogRef = this.dialog.open(WriteOffModelComponent, {
      width: '70vw',
      maxWidth: '70vw',
      // height: '40vw',
      // maxHeight: '40vw',
      data: { claimId: claimId }, // this.orderId
      // disableClose: true,
      // You can pass data to your dialog if needed
    });
    // Handle the dialog result if needed
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadClaimTable.emit();
      }
    });
    this.$subscription.push(closeDialog);
  }

  openWriteOffNotes(claimData: any) {
    if (claimData?.writeOffAmount && claimData?.writeOffAmount != 0) {
      const dialogRef = this.dialog.open(WriteOffNotesComponent, {
        width: '88vw',
        maxWidth: '88vw',
        data: { claimId: claimData.claimId, patientId: claimData.patientId }, // this.orderId
        disableClose: true,
        // You can pass data to your dialog if needed
      });
      // Handle the dialog result if needed
      const closeDialog = dialogRef.afterClosed().subscribe(
        (isSaved: any) => {
          if (isSaved) {
            this.reloadClaimTable.emit();
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          console.warn(data?.error?.error?.message);
        }
      );
      this.$subscription.push(closeDialog);
    }
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  // By Manikandan For collapse All Rows
  collapseAllRows(): void {
    const rows = this.myTable.rows({ page: 'current' }); // Get all rows on the current page
    rows.every((idx, data, node) => {
      const rowData = this.myTable.row(node).data();
      if (rowData) {
        this.myTable.row(node).child.hide(); // Hide the child row if it's expanded
      }
      return true; // Continue to the next row
    });
  }
  spinnerEnabled: boolean = false;
  showError(claimId: string) {
    this.spinnerEnabled = true;
    this.ltErrors = [];
    const data = this.clearingHouseUpdatesService
      .get277ErrorDetailsByClaimId(claimId)
      .subscribe(
        (response) => {
          this.ltErrors = response ?? [];
          this.spinnerEnabled = false;
        },
        (err) => {
          this.spinnerEnabled = false;
          this.ltErrors = [];
        }
      );
  }
  //Move the Order to Billing
  enableClaimResubmission(id: string, name: string) {
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
    this.apiSubscription = this.claimFormDetailsService
      .enableResubmission(id)
      .subscribe(
        (response: boolean) => {
          if (response) {
            // Show a confirmation alert before reloading the claim table
            Swal.fire({
              icon: 'success',
              text:
                'Resubmit has been enabled successfully and file moved to Claim process for ' +
                name +
                '',
              confirmButtonText: 'OK',
            }).then(() => {
              // Reload the claim table after "OK"
              this.reloadClaimTable.emit();
            });
          } else {
            this.toaster.error(
              'Failed to enable resubmit, check the status of the claim',
              'Error'
            );
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          console.warn(data?.error?.error?.message);
        }
      );
  }





  addPageNavigationControls() {
    let buttonsElement = $('.dt-buttons')[0];
    let totalPages = this.totalPages;
    let currentPage = this.currentPage;

    // $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button dt-custom-pagination-buttons disabled' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button dt-custom-pagination-buttons' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button dt-custom-pagination-buttons' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }
    // style='float:left !important;'
    $("<span class='dt-pagination-input-span' > Page <span>").appendTo(
      buttonsElement
    );
    $(
      "<input class='dt-pagination-input' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text'/>"
    ).appendTo(buttonsElement);



    ////Input Restrictions on pagination text box
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      //Invalid page number
      if ((value==''?currentPage:value) > totalPages || (value==''?currentPage:value) == currentPage) {
        $('#btnGoToPage').addClass('disabled');
      } else if(value!=currentPage && value <= totalPages) {
        $('#btnGoToPage').removeClass('disabled');
      }
      (this as HTMLInputElement).value = value;
    });
    //If the user leaves empty text box patch current page
    $('#txtGotoPage').on('focusout', function (e) {
      let value = (this as HTMLInputElement).value;
      if (value == '') {
        (this as HTMLInputElement).value = currentPage;
      }
    });



    $(
      "<span class='dt-pagination-input-span pl-1'> of " +
        this.totalPages +
        ' <span>'
    ).appendTo(buttonsElement);
    $(
      "<button  class='dt-button dt-custom-pagination-buttons-goto disabled' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button dt-custom-pagination-buttons disabled' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button dt-custom-pagination-buttons' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button dt-custom-pagination-buttons' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) =>
      this.nevigatePage.emit(this.totalPages)
    );

    // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
  }
  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }









}
