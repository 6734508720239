<table id="tblMyVerfication" datatable [dtOptions]="dtMyVerficationListOptions" class="tbl hover row-border hover w-100 display">
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'" >Chart No</th>
      <th [matTooltip]="'Name'">Name</th>
      <th [matTooltip]="'State'">State</th>
      <th [matTooltip]="'Rank'">Rank</th>
      <th [matTooltip]="'Insurance'">Insurance</th>
      <th [matTooltip]="'Policy No'">Policy No</th>
      <th [matTooltip]="'Group No'">Group No</th>
      <th [matTooltip]="'Entered By'">Entered By</th>
      <th [matTooltip]="'Location'">Location</th>
      <!-- <th [matTooltip]="'Locked By'">Locked By</th>
      <th [matTooltip]="'Locked At'" class="text-center">Locked At</th> -->
      <th [matTooltip]="'Days'" class="text-right">Days</th>
      <th [matTooltip]="'Entered On'" class="text-center">Entered On</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
      <th [matTooltip]="'Modify By'">Modify By</th>
      <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
      <th [matTooltip]="'Status'" >Status & Validity</th>
    </tr>
  </thead>
  <tbody>
    <!-- <tr *ngFor="let data of myverification" (click)="tblItemSelectedRow(data.patientId,data.chartNo,data.policyId)"> -->
    <tr [ngClass]="
    tblItemSelectedRowId === data.patientId
      ? 'row-highlighted'
      : ''
  " *ngFor="let data of myverification"
      (click)="tblItemSelectedRow(data.patientId, data.chartNo, data.policyId,data.docId)">

      <!-- -->
      <td [matTooltip]="'Open Options'" >
        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>

        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add Patient
            Note</button>

          <button mat-menu-item [disabled]="data.patientId === defaultGuid"
            (click)="openViewNotesPopup(data.patientId,data.docId,data.docId)">View Patient Note</button>

        <a [class.disabled]="isEmpty(data.blobname) || data?.isSorted === false" [routerLink]="'/view_document_tiff/' + data.docId +'/'+ true"
            target="_blank" class="preview-link">
            <button [disabled]="isEmpty(data.blobname) || data?.isSorted === false" mat-menu-item>View Document Images</button>
          </a>

        <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>

          <button mat-menu-item [disabled]="
          (data?.isExpired===1 || (data?.verificationStatus!==VerificationStatus.new)&&(data?.verificationStatus!==VerificationStatusV1.new)&&
          (data?.verificationStatus!==VerificationStatus.pending)&&(data?.verificationStatus!==VerificationStatusV1.pending))
          "  (click)="cancelVerificationStatus(data?.verificationId)">
            Cancel Verification</button>
            <button mat-menu-item (click)="eligibilityHistory(data.patientId,data.policyId,data.verificationStatus)">Eligibility History</button>

        </mat-menu>
      </td>
      <td (dblclick)="onRowDoubleClick(data?.chartNo || '-')" [matTooltip]="data?.chartNo || '-'">
        <a [class.disabled]="isEmpty(data.chartNo) || isEmpty(data.patientId)"
          [routerLink]="'/patientCreateEditTab/' + data.patientId + '/' + data.docId" target="_blank"
          class="preview-link">{{data?.chartNo || '-'}}
        </a>
      </td>

      <td (dblclick)="onRowDoubleClick(data?.name || '-')" [matTooltip]="data?.name || '-'">
        {{ data?.name || '-' }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.stateName || '-')" [matTooltip]="data?.stateName || '-'">
        {{ data?.stateName || '-' }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.plan || '-')" [matTooltip]="data?.plan || '-'">
        <span *ngIf="data?.plan===InsuranceType.primary">Primary</span>
        <span *ngIf="data?.plan===InsuranceType.secondary">Secondary</span>
        <span *ngIf="data?.plan===InsuranceType.tertiary">Tertiary</span>
      </td>
      <td (dblclick)="onRowDoubleClick(data?.payerName || '-')" [matTooltip]="data?.payerName || '-'">
        {{ data?.payerName || '-'        }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.policyNo || '-')" [matTooltip]="data?.policyNo || '-'">
        {{ data?.policyNo || '-'}}
      </td>

      <td (dblclick)="onRowDoubleClick(data?.groupNo || '-')" [matTooltip]="data?.groupNo || '-'">
        {{ data?.groupNo || '-'}}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.enteredBy || '-')" [matTooltip]="data?.enteredBy || '-'">
        {{ data?.enteredBy || '-' }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.locationName || '-')" [matTooltip]="data?.locationName || '-'">
        {{ data?.locationName || '-' }}
      </td>
      <!-- <td (dblclick)="onRowDoubleClick(data?.lockedBy || '-')" [matTooltip]="data?.lockedBy || '-'">
        {{ data?.lockedBy || '-' }}
      </td>
      <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.lockedAt))" [matTooltip]="getFormattedDate(data?.lockedAt)">{{ getFormattedDate(data?.lockedAt) }}</td> -->
      <td (dblclick)="onRowDoubleClick(data?.days || '-')" [matTooltip]="data?.days || '-'" class="text-right"> {{data?.days || '-'}}</td>
      <td (dblclick)="onRowDoubleClick(data?.enteredOn)" [matTooltip]="data?.enteredOn" class="text-center">{{ data?.enteredOn }}
      </td>
      <td [matTooltip]="data?.createdBy || '-'"
      (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
      data?.createdBy || '-' }}</td>

      <td class="text-right" [matTooltip]="data?.createdDate"
          (dblclick)="onRowDoubleClick(data?.createdDate)" >
          {{ data?.createdDate }}
      </td>
      <td [matTooltip]="data?.modifyBy|| '-'"
          (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
          data?.modifyBy || '-' }}</td>

      <td class="text-right" [matTooltip]="data?.modifiedDate"
          (dblclick)="onRowDoubleClick(data?.modifiedDate)" >
          {{ data?.modifiedDate }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.verificationStatus || '-')" >
        <i class="fa fa-check checkicon" [matTooltip]="data?.verificationStatus || '-'" *ngIf="(data?.isExpired===0 && ((data?.verificationStatus===VerificationStatus.done)||(data?.verificationStatus===VerificationStatusV1.done)))"></i>
        <i class="fa fa-clock-o pendingicon" [matTooltip]="data?.verificationStatus || '-'"*ngIf="(data?.isExpired===0 && ((data?.verificationStatus===VerificationStatus.new)||(data?.verificationStatus===VerificationStatusV1.new)))
        ||(data?.isExpired===0 &&((data?.verificationStatus===VerificationStatus.pending)||(data?.verificationStatus===VerificationStatusV1.pending)))"></i>
        <i class="fa fa-exclamation expicon" [matTooltip]="data?.verificationStatus || '-'"*ngIf="(data?.isExpired===0 &&((data?.verificationStatus===VerificationStatus.unabletoverify)||(data?.verificationStatus===VerificationStatusV1.unabletoverify)))"></i>
        <i class="fa fa-ban Cancelled" [matTooltip]="data?.verificationStatus || '-'"*ngIf="(data?.isExpired===0 &&((data?.verificationStatus===VerificationStatus.Cancelled)||(data?.verificationStatus===VerificationStatusV1.Cancelled)))"></i>
        <i class="fa fa-times cansicon" matTooltip="Expired" *ngIf="data?.isExpired===1"></i>
       <i (dblclick)="onRowDoubleClick(data?.validity || '-')" [matTooltip]="data?.validity || '-'" *ngIf="(data?.isExpired===0 && ((data?.verificationStatus===VerificationStatus.done)||(data?.verificationStatus===VerificationStatusV1.done)))">{{data?.validity}}</i>
    </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Chart No" name="search-ChartNo" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Name" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="State" name="search-State" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Rank" name="search-Rank" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Insurance" name="search-Insurance" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Policy No" name="search-PolicyNo"/>
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Group No" name="search-GroupNo"/>
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Entered By" name="search-EnteredBy" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-Location" />
      </th>
      <!-- <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Locked By" name="search-LockedBy" />
      </th>
      <th >
        <input matInput class="textbox-border-class text-center" type="text" placeholder="Locked At" name="search-LockedAt" />
      </th> -->
      <th >
        <input matInput class="textbox-border-class text-right" type="text" placeholder="Days" name="search-Days" />
      </th>
      <th >
        <input matInput class="textbox-border-class text-center" type="text" placeholder="Entered On" name="search-EnteredOn" />
      </th>

  <th>
    <input matInput class="textbox-border-class" type="text" placeholder="Created By"
        name="search-Created By" />
</th>
<th class="text-right">
    <input matInput class="textbox-border-class " type="text"
          placeholder="Created Date" name="search-Created Date" />
 </th>
 <th>
    <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
        name="search-Modify By" />
</th>
  <th class="text-right">
      <input matInput class="textbox-border-class " type="text"
          placeholder="Modified Date" name="search-Modified Date" />
  </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" />
      </th>
    </tr>
  </tfoot>
</table>
