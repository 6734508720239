import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MasterNotesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { ItemPaymentService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { AddNotesComponent } from 'projects/patient/src/app/add-notes/add-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-writeOff-notes',
  templateUrl: './writeOff-notes.component.html',
  styleUrls: ['./writeOff-notes.component.scss'],
})
export class WriteOffNotesComponent implements OnInit {
  // public noteForm: FormGroup;
  // drpNotetype: any;
  // notesText = '';
  // isdrpLoading: boolean;
  // listNoteType: any;
  // NoteTypeControl = new FormControl();

  dtNotesOptionsv1 = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    // dom: 'lifrtp',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1], orderable: false },
      {
        targets: [2],
        type: 'date',
        render: function (data, type, row) {
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
      { targets: [8], visible: false },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'WriteOff Notes',
        // exportOptions: {
        //   columns: ':visible:not(:first-child,:eq(0)) ',
        // },
        exportOptions: {
          columns: [0, 1, 2, 3, 4, 5, 6, 7, 8], // Include the hidden column (Comments) in the export
        },
      },
      // {
      //   extend: 'colvis',
      //   columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      // }
    ],
  };

  PatientNotesDetailsV1: any;
  subscription$: Subscription[] = [];
  isLoading: boolean = false;
  // @Input()
  vDescription: string = '';
  selectedRowIndex: number;
  constructor(
    private commonService: CommonService,
    private itemPaymentService: ItemPaymentService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      claimId: string;
      patientId: string;
    } = {
      claimId: defaultGuid,
      patientId: defaultGuid,
    }
  ) {}

  ngOnInit() {
    this.getNotesList();
  }

  //Open Description(Notes) Model
  openDescription(description: string) {
    this.vDescription = description;
    document.getElementById('scrolltxt').scrollIntoView({ behavior: 'smooth' });
  }

  setSelectedRow(index: number): void {
    this.selectedRowIndex = index;
  }

  getNotesList() {
    this.isLoading = true;
    const patientDetails = this.itemPaymentService
      .getClaimWriteOffDetailsByClaimIdAndPatientId(
        this.data.claimId,
        this.data.patientId
      )
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.PatientNotesDetailsV1 = response;
          this.PatientNotesDetailsV1 = this.PatientNotesDetailsV1.map(
            (data) => {
              // Merging reasonCodes
              let mergedReasonCode='-';
              if(data.adjustmentsPayment){
                mergedReasonCode = data.adjustmentsPayment
                .map((payment) => payment.reasonCode)
                .join(', ');
                console.log('mergedReasonCode = ' + mergedReasonCode)
              }


              return {
                ...data,
                creationTme: this.commonService.getFormattedDateTimeZone(
                  data.creationTme
                ),
                isPatBlance: data.isPatBlance === true ? 'Patient Balance' : 'Insurance Balance',
                mergedReasonCode,
              };
            }
          );
          console.log('this.PatientNotesDetailsV1')
          console.log(this.PatientNotesDetailsV1)

          // this.defaultFaxIdV1 = response?.defaultFaxId;
          // this.chartNoV1 = response?.chartNo;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    this.subscription$.push(patientDetails);
  }

  // initializationForm(){
  //    //Form Builder
  //    this.noteForm = this.fb.group({
  //     drpNotetype: new FormControl('', [Validators.required]),
  //     txtDescription: new FormControl('test notes', [
  //       Validators.required,
  //       noWhitespaceValidator,
  //     ]),
  //   });
  //   this.noteForm.patchValue({
  //     txtDescription:'test notes'
  //   });
  // }
  // noteTypeDetails() {
  //   this.isdrpLoading = true;
  //   const NoteTypes = this.notesService.getDrpNoteTypes().subscribe(
  //     (response) => {
  //       this.isdrpLoading = false;
  //       this.listNoteType = response;
  //       this.drpNotetype= this.listNoteType;
  //     },
  //     (err) => {
  //       this.isdrpLoading = false;
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  // }
  //   updateCharacterCount(maxlength: number) {
  //   // Ensure the text length does not exceed the maximum length
  //   if (this.notesText?.length > maxlength) {
  //     this.notesText = this.notesText?.substr(0, maxlength);
  //   }
  // }


}
