import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { BillingMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { claimAcceptedStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import Swal from 'sweetalert2';
import { ClaimInformationsService } from '../billing-proxy/billing-management/posting';
import { ClaimDTO, InsurancePaymentDetailsDTO, ItemPaymentDTO } from '../billing-proxy/billing-management/posting/dto';
import { InsurancePaymentService } from '../billing-proxy/billing-management/posting/insurance-payment.service';
import { InsuranceType } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing';
import { InsurancePaymentModalComponent } from '../insurance-payment-modal/insurance-payment-modal.component';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-teritary-payment',
  templateUrl: './teritary-payment.component.html',
  styleUrls: ['./teritary-payment.component.scss']
})
export class TeritaryPaymentComponent implements OnInit {
  Title: string = "Teritary Insurance Payment";
  strSelectedPdfPath: string = "";
  public paymentForm: FormGroup;
  saveButtonHide: boolean;
  @Input() arrTableData: ItemPaymentDTO[] = [];
  totalBilled: number = 0;
  totalAllowed: number = 0;
  totalDeduct: number = 0;
  totalCoinsurance: number = 0;
  totalInsPaid: number = 0;
  totalPatientAmount: number = 0;
  totalWriteOffAmount: number = 0;
  claimId: string = "";
  insuranceType: string = "";
  patientId: string = '';
  orderId: string = '';
  drpCode: any[] = [];
  drpPayment: any[] = [];
  drpReason: any[] = [];
  discountPercent: number = 0;
  source: string = '';
  code: string = '';
  reason: string = '';
  type: string = '';
  BirthdateMinDate: Date;
  tenantId: string = '';
  billingResponse: ClaimDTO = null;
  isCompleted: boolean;
  constructor(
    private fb: FormBuilder,
    private title: Title,
    private number: NumberValidators,
    public dialog: MatDialog,
    private insurancePaymentService: InsurancePaymentService,
    private router: Router,
    private claimInformationsService: ClaimInformationsService,
    private billingDropdownService: BillingMasterDropdownService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId?: string, orderId?: string, claimId: string, insuranceType: string }
  ) { }
  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      txtSource: new FormControl(""),
      txtCode: new FormControl(""),
      txtCheckNo: new FormControl("", [this.number.isNumberCheck]),
      txtDate: new FormControl(""),
      txtAmount: new FormControl("", [this.number.isNumberCheck]),
      drpReason: new FormControl(""),
      drpType: new FormControl(""),
      iscompleted: new FormControl(""),
    })
    this.tenantId = localStorage.getItem('tenantId') ?? "";
    this.title.setTitle("Qsecure | Insurance Payment");
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.patientId = this.data?.patientId;
    this.orderId = this.data?.orderId;
    this.claimId = this.data?.claimId;
    this.claimId && this.getDropdown();
    this.insuranceType = this.data?.insuranceType ?? "";
  }

  //! save teritary Insurance payments
  saveTeritaryInsurancePayments() {
    this.saveButtonHide = true;
    this.arrTableData?.map(v => {
      if (v.itemPaymentId === '') {
        v.itemPaymentId = "00000000-0000-0000-0000-000000000000";
        v.coinsurance = (+v?.coinsurance ?? 0);
        v.writeOff = (+v?.writeOff ?? 0);
        v.codes = v?.codes ?? "";
        v.itemProcedureCode = v?.itemProcedureCode ?? "";
        v.dos = v?.dos ?? "";
        v.billed = +v?.billed ?? 0;
        v.allowed = +v?.allowed ?? 0;
        v.deduct = v?.deduct ?? "";
        v.insurancePaid = +v?.insurancePaid ?? 0;
        v.patBalance = +v?.patBalance ?? 0;
        v.claim = v?.claim ?? "";
        v.insBalance = (+v?.insBalance ?? 0);
        return v
      } else {
        v.coinsurance = (+v?.coinsurance ?? 0);
        v.writeOff = (+v?.writeOff ?? 0);
        v.itemPaymentId = v?.itemPaymentId ?? "";
        v.codes = v?.codes ?? "";
        v.itemProcedureCode = v?.itemProcedureCode ?? "";
        v.dos = v?.dos ?? "";
        v.billed = +v?.billed ?? 0;
        v.allowed = +v?.allowed ?? 0;
        v.deduct = v?.deduct ?? "";
        v.insurancePaid = +v?.insurancePaid ?? 0;
        v.patBalance = +v?.patBalance ?? 0;
        v.claim = v?.claim ?? "";
        v.insBalance = (+v?.insBalance ?? 0);
        return v
      }
    });
    const claimResponseData: ClaimDTO = this.billingResponse ?? null;
    const teritaryPaymentDetails: InsurancePaymentDetailsDTO = claimResponseData?.insurancePayments?.tertiaryInsurancePaymentDetails ?? null;

    const teritaryInsurnacePayemntData: InsurancePaymentDetailsDTO = {
      source: this.paymentForm?.value?.txtSource ?? "",
      code: this.paymentForm?.value?.txtCode ?? "",
      checkNo: this.paymentForm?.value?.txtCheckNo ?? "",
      date: this.paymentForm?.value?.txtDate ?? "",
      reason: this.paymentForm?.value?.drpReason ?? "",
      paymentType: this.paymentForm?.value?.drpType ?? "",
      amount: this.paymentForm?.value?.txtAmount ?? "",
      lstItemPayment: this.arrTableData ?? [],
      description: teritaryPaymentDetails?.description ?? "",
      plansOfFiles: teritaryPaymentDetails?.plansOfFiles ?? "",
      statementNote: teritaryPaymentDetails?.statementNote ?? "",
      icn: teritaryPaymentDetails?.icn ?? "",
      eobDocuments: teritaryPaymentDetails?.eobDocuments ?? null,
      totalBilled: teritaryPaymentDetails?.totalBilled ?? 0,
      totalAllowed: teritaryPaymentDetails?.totalAllowed ?? 0,
      totalDeduct: teritaryPaymentDetails?.totalDeduct ?? 0,
      totalCoInsurance: teritaryPaymentDetails?.totalCoInsurance ?? 0,
      totalWriteOff: teritaryPaymentDetails?.totalWriteOff ?? 0,
      totalInsurancePaid: teritaryPaymentDetails?.totalInsurancePaid ?? 0,
      totalInsBal: teritaryPaymentDetails?.totalInsBal ?? 0,
      totalPatientBal: teritaryPaymentDetails?.totalPatientBal ?? 0,
      insurancePaymentId: teritaryPaymentDetails?.insurancePaymentId ?? "",
      payer: teritaryPaymentDetails?.payer ?? "",
      payee: teritaryPaymentDetails?.payee ?? "",
      status: this.paymentForm?.value?.iscompleted === true ? claimAcceptedStatus.TertiaryPostingCompleted : teritaryPaymentDetails.status ?? "",

    }
    this.claimInformationsService.updatePayemnts(this.claimId, this.insuranceType, teritaryInsurnacePayemntData,).subscribe(value => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.dialog.closeAll();
      this.saveButtonHide = false;
    }, err => {
      this.saveButtonHide = false;

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //!  is Compelted Confirmation
  isCompeltedConfirmation() {
    Swal.fire({
      title: 'Are you sure you want to complete?',
      text: 'Payment details cannot be modifed in further',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Confirm!'
    }).then(result => {
      if (result.value) {
        this.paymentForm.patchValue({
          iscompleted: true
        })
      } else {
        this.paymentForm.patchValue({
          iscompleted: false
        })
      }
    });
  }

  //! update Payment Data in Modal
  updatePaymentDataUsingModal(itemPayment: ItemPaymentDTO) {
    const dialogRef = this.dialog.open(InsurancePaymentModalComponent, {
      data:
        { itemPayment: itemPayment, selectedInsuranceType: 1, claimId: this.claimId }
    });
    dialogRef.afterClosed().subscribe(() => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //! get dropdowns
  getDropdown() {
    this.claimInformationsService.get(this.claimId).subscribe(response => {
      const billingResponse: ClaimDTO = response;
      this.billingResponse = billingResponse;
      this.patientId = billingResponse?.patientId;
      this.orderId = billingResponse?.orderId;
      this.loadData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Codes,
      BillingDropdowns.PaymentTypes,
      BillingDropdowns.Reasons
    ]
    this.billingDropdownService.getBillingDropdownsByInput(billingDropdownInput).subscribe((stateResponse) => {
      let response: BillingMasterDropdownDTO = stateResponse;
      this.drpCode = response?.codes;
      this.drpPayment = response?.paymentTypes;
      this.drpReason = response?.reasons;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! load table data and form datas
  loadData() {
    let tableData: ItemPaymentDTO[] = [];
    this.arrTableData = [];
    let eobBlobName: string = "";
    if (this.claimId && this.claimId !== "" && this.claimId !== null && this.claimId !== undefined) {
      this.claimInformationsService.get(this.claimId).subscribe(response => {
        const claimResponseData: ClaimDTO = response;
        const selectedOrderDeatils: SaleorderDTO = response?.saleOrder
        const teritaryInsurancePayments = claimResponseData?.insurancePayments?.tertiaryInsurancePaymentDetails;
        const pItemPayment = teritaryInsurancePayments?.lstItemPayment;
        if (this.data?.insuranceType === InsuranceType.Tertiary) {
          eobBlobName = teritaryInsurancePayments?.eobDocuments?.blobName;
        }
        this.totalBilled = (+ teritaryInsurancePayments?.totalBilled ?? 0) || 0;
        this.totalAllowed = (+teritaryInsurancePayments?.totalAllowed ?? 0) || 0;
        this.totalDeduct = (+teritaryInsurancePayments?.totalDeduct ?? 0) || 0;
        this.totalCoinsurance = (+ teritaryInsurancePayments?.totalCoInsurance ?? 0) || 0;
        this.totalInsPaid = (+teritaryInsurancePayments?.totalInsurancePaid ?? 0) || 0;
        this.totalPatientAmount = (+ teritaryInsurancePayments?.totalPatientBal ?? 0) || 0;
        this.totalWriteOffAmount = (+teritaryInsurancePayments?.totalWriteOff ?? 0) || 0;
        this.discountPercent = (+selectedOrderDeatils?.orders?.discountPct ?? 0) || 0;
        setTimeout(() => {
          if (pItemPayment !== [] && pItemPayment?.length !== 0) {
            pItemPayment?.forEach(element => {
              let data: ItemPaymentDTO;
              data = {
                itemPaymentId: (element?.itemPaymentId) ?? "",
                codes: (element?.itemProcedureCode) ?? "",
                itemProcedureCode: (element?.itemProcedureCode) ?? "",
                dos: (element?.dos) ?? "",
                billed: (+element?.billed || 0) ?? 0,
                allowed: (+element?.allowed || 0) ?? 0,
                deduct: (element?.deduct || "") ?? "",
                coinsurance: (element?.coinsurance) ?? 0,
                insurancePaid: (element?.insurancePaid) ?? 0,
                writeOff: (+element?.writeOff || 0) ?? 0,
                insBalance: (+element?.insBalance || 0) ?? 0,
                patBalance: (+element?.patBalance || 0) ?? 0,
                claim: (element?.claim) ?? "",
              }
              tableData?.push(data)
            });
            this.arrTableData = tableData ?? [];
            const priInsPayStatus = teritaryInsurancePayments?.status;
            this.paymentForm.patchValue({
              txtCode: teritaryInsurancePayments?.code,
              txtCheckNo: teritaryInsurancePayments?.checkNo,
              txtDate: teritaryInsurancePayments?.date,
              txtAmount: teritaryInsurancePayments?.amount,
              drpReason: teritaryInsurancePayments?.reason,
              drpType: teritaryInsurancePayments?.paymentType,
              iscompleted: (priInsPayStatus === claimAcceptedStatus.TertiaryPostingCompleted)
            });
            this.isCompleted = (priInsPayStatus === claimAcceptedStatus.TertiaryPostingCompleted);
            this.isCompleted && this.paymentForm?.disable();
          }
          if (eobBlobName && eobBlobName !== "") {
            const sClaimNumber: string = this.claimId;
            const blobName: string = eobBlobName;
            sClaimNumber && blobName && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + blobName + '/' + 2 + "/" + sClaimNumber, "_blank");
          }
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }

  // clear dropdown
  clearSource() { this.source = ''; }
  clearCode() { this.code = ''; }
  clearReason() { this.reason = ''; }
  clearType() { this.type = ''; }

}
