import { ClaimsDTO } from './../../../../patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/billing/models';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from '@ngxs/store';
import { ClaimInformationsService } from 'projects/billing/src/app/billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO } from 'projects/billing/src/app/billing-proxy/billing-management/posting/dto';
import { RegionCountriesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import {
  AddressDTO,
  PatientDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { baseUrl } from 'projects/patient/src/environments/environment';
import Swal from 'sweetalert2';
import { CheckoutOrderService } from '../billing-proxy/billing-management/pay-pal/checkout-order.service';
import { Amount } from '../billing-proxy/billing-management/pay-pal/dto/models';
import {
  CreateUpdateCheckOutOrderCreateDTO,
  CreateUpdatePayPalInvoiceDTO,
  Item,
  Phone,
} from '../billing-proxy/billing-management/rcm/billing-management/pay-pal/dto/models';
import { InvoiceService } from '../billing-proxy/billing-management/rcm/billing-management/pay-pal/invoice.service';
import {
  ItemDTO,
  PayPalInvoicePaymentsDTO,
  SaleorderDTO,
} from '../billing-proxy/billing-management/rcm/order-management/order/dto/models';
import { paypalPaymentMethodType } from '../billing-proxy/billing-management/rcm/order-management/order/paypal-payment-method-type.enum';
import { getBilling } from '../billing-store/billing.action';
import { ClaimInfoDetailsService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { PatientSaleOrderService, SaleOrderItemService } from 'projects/order/src/app/order-proxy/order-management/order-optimization';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { map, switchMap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
type PatientDemo = { fullName: string; defaultPatientId: string; dateOfBirth: string; emailId: string; defaultSaleOrderId: string }
@Component({
  selector: 'app-billing-paypal',
  templateUrl: './billing-paypal.component.html',
  styleUrls: ['./billing-paypal.component.scss'],
})
export class BillingPaypalComponent implements OnInit {
  isShowItemData: number = 0;
  paymentCompletedDateTime: string = '';
  paymentCreatedDateTime: string = '';
  totalPaidAmount: string = '';
  totalPaypalAmount: string = '';
  totalTransferredAmount: string = '';
  checkOutOrderId: string = '';
  items: any[] = [];
  paymentData: any[] = [];
  showCheckOutProceedButton: boolean = false;
  showInvoiceProceedButton: boolean = false;

  orderId: string = defaultGuid;
  checkOutNumber: string = '';
  payPalInvoiceMailResendCount: number = 0;
  claimId: string = defaultGuid;
  patientId: string = defaultGuid;
  totalItemPrice: string = '';
  paypalPaymentForm: FormGroup;
  drpCountry: RegionCountriesDTO[] = [];
  invoiceOrderId: string = '';
  invoiceNumber: string = '';
  paypalPaymentMethodType: string = '';
  patientData: PatientDemo = { fullName: "", emailId: "", dateOfBirth: "", defaultPatientId: "", defaultSaleOrderId: "" };
  constructor(
    public dialogRef: MatDialogRef<BillingPaypalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { orderId: string; claimId: string; patientId: string; isShowItemData: number } = { orderId: defaultGuid, patientId: defaultGuid, claimId: defaultGuid, isShowItemData: 0 },
    private store: Store,
    private paymentService: CheckoutOrderService,
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,

    private claimInformationService: ClaimInformationsService,
    private region: RegionDropdownService,
    private claimInfoDetailsService: ClaimInfoDetailsService,
    private patientSaleOrderService: PatientSaleOrderService,
    private patientPersonalService: PatientPersonalService,
    private saleOrderItemService: SaleOrderItemService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.paypalPaymentForm = this.formBuilder.group({
      rbtnPayPalPaymentType: new FormControl('', [Validators.required]),
    });
    this.orderId = this.data?.orderId ?? defaultGuid;
    this.claimId = this.data?.claimId ?? defaultGuid;
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.isShowItemData = this.data?.isShowItemData ?? 0;

    this.orderId && this.orderId !== defaultGuid && this.getPaymentView();
    this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        this.drpCountry = response?.items ?? [];
      });
  }

  isShowCheckOutOrder: boolean = true;
  isShowInvoice: boolean = false;

  // ! to get verify option
  onChangePayPalPaymentType($event: MatRadioChange) {
    if ($event.value === 'CheckOutOrder') {
      this.isShowCheckOutOrder = true;
      this.showCheckOutProceedButton = true;
      this.isShowInvoice = false;
      this.showInvoiceProceedButton = false;
    }
    if ($event.value === 'Invoice') {
      this.isShowInvoice = true;
      this.showInvoiceProceedButton = true;
      this.isShowCheckOutOrder = false;
      this.showCheckOutProceedButton = false;
    }
  }

  totalPatPrice: any;
  // ! payment view
  getPaymentView() {
    // this.store
    //   .dispatch(new getBilling(this.data.claimId))
    //   .subscribe((response) => {

    this.claimInfoDetailsService.get(this.claimId)
    this.patientPersonalService.get(this.patientId).pipe(
      map(response => {
        this.patientData = {
          fullName: response?.fullName,
          defaultPatientId: response?.defaultPatientId,
          dateOfBirth: response?.dateOfBirth,
          emailId: response?.emailId,
          defaultSaleOrderId: ""
        };
      }), switchMap(x => this.patientSaleOrderService.get(this.orderId)),
      map(x => {
        this.patientData.defaultSaleOrderId = x?.defaultSaleOrderId ?? "";
        this.paypalPaymentForm.patchValue({
          rbtnPayPalPaymentType: paypalPaymentMethodType.CheckOutOrder,
        });
      }),
      switchMap(x => this.saleOrderItemService.getItemsListByOrderIdByOrderId(this.orderId))

    ).subscribe(response => {
      response?.forEach((element) => {
        this.items.push({
          itemName: element.itemName,
          amount: ((element.charge ?? 0) * (element.orderingQuantity ?? 0)) ?? 0,
          code: element.itemProcedureCode,
        });
      });
      this.showCheckOutProceedButton = true;
      this.showInvoiceProceedButton = true;
      this.totalItemPrice = (this.items?.reduce((prvsValue, curValue) => { return prvsValue + +curValue?.amount; }, 0) ?? 0) ?? '';
      //const orderData: SaleorderDTO = response?.billingState?.selectedBilling?.saleOrder;
      //const claimData: ClaimDTO = response?.billingState?.selectedBilling;
      //this.paypalPaymentMethodType = orderData.payPalPaymentMethodType;
      // this.paypalPaymentForm.patchValue({
      //   rbtnPayPalPaymentType:
      //     orderData.payPalPaymentMethodType == null ||
      //       orderData.payPalPaymentMethodType == undefined ||
      //       orderData.payPalPaymentMethodType == ''
      //       ? 'CheckOutOrder'
      //       : orderData.payPalPaymentMethodType,
      // });
      // if (this.paypalPaymentMethodType === 'Invoice') {
      //   this.orderId &&
      //     this.orderId !== '' &&
      //     this.orderId !== null &&
      //     this.orderId !== undefined &&
      //     this.getInvoicePaymentView();
      //   this.isShowInvoice = true;
      //   this.isShowCheckOutOrder = false;
      // } else {
      //   if (
      //     orderData?.payPalPaymentStatus === 'Initiate Payment' ||
      //     orderData?.payPalPaymentStatus === 'Link Expired' ||
      //     orderData?.payPalPaymentStatus === null
      //   ) {
      //     this.showCheckOutProceedButton = true;
      //     this.showInvoiceProceedButton = false;
      //   } else {
      //     this.showCheckOutProceedButton = false;
      //   }
      //   this.saleOrderId = orderData?.saleorderId ?? '';
      //   this.dob =
      //     orderData?.patients?.personals?.dateOfBirth == null
      //       ? undefined
      //       : new Date(
      //         orderData?.patients?.personals?.dateOfBirth
      //       )?.toLocaleDateString("en-US") ?? '';
      //   this.Name = orderData?.patients?.personals?.fullName ?? '';

      //   response?.billingState?.selectedBilling?.insurancePayment?.primaryInsurancePayments?.lstItemPayment?.forEach(
      //     (element) => {
      //       this.items.push({
      //         itemName: element.name,
      //         amount: element.allowed,
      //         code: element.itemProcedureCode,
      //         insAmount: element?.payment,
      //         patBlns: element?.totalCoInsuranceAmount,
      //       });
      //     }
      //   );
      //   this.patientBalance = claimData?.totalPatientBal ?? 0;

      //   const OrderPayments =
      //     orderData?.paymentTransactionDetails
      //       ?.payPalCheckOutOrderPayments?.[0] || null;
      //   this.paymentCompletedDateTime =
      //     OrderPayments?.paymentCompletedDateTime ?? '';
      //   this.paymentCreatedDateTime =
      //     OrderPayments?.paymentCreatedDateTime ?? '';
      //   this.totalPaidAmount = OrderPayments?.totalPaidAmount ?? '';
      //   this.totalPaypalAmount =
      //     OrderPayments?.totalPaypalTransactionChargeAmount ?? '';
      //   this.checkOutOrderId = OrderPayments?.checkOutOrderId ?? '';
      //   this.totalTransferredAmount =
      //     OrderPayments?.totalTransferredAmount ?? '';
      //   this.payPalInvoiceMailResendCount =
      //     orderData?.payPalMailResendCount ?? 0;
      //   this.totalPatPrice =
      //     response?.billingState?.selectedBilling?.totalPatientAmount ?? 0;
      // }
    });
  }
  // ! paypal save
  paypalProceed() {
    // this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
    // if (response?.orderState?.selectedOrder?.payPalCheckOutOrderPaymentStatus === "Initiate Payment") {
    let payment: CreateUpdateCheckOutOrderCreateDTO;
    this.store
      .dispatch(new getBilling(this.data.claimId))
      .subscribe((response) => {
        let orderData = response?.billingState?.selectedBilling?.saleOrder;
        if (
          response?.billingState?.selectedBilling?.saleOrder
            ?.payPalPaymentStatus === 'Link Expired'
        ) {
          Swal.fire({
            title: 'Are you sure want to resend Mail again?',
            text: 'Mail will send to registered mail again!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#34c38f',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'Yes, Resend!',
          }).then((result) => {
            if (result.value === true) {
              let orderItem =
                response?.billingState?.selectedBilling?.saleOrder.items;
              let orderId = orderData.id;
              this.paymentData = [];
              orderItem?.forEach((element: { itemId: any; charge: any }) => {
                this.paymentData.push({
                  itemId: element?.itemId,
                  itemCurrenyCode: 'USD',
                  itemAmount: String(element?.charge),
                });
              });
              payment = {
                paypalPaymentMethodType: paypalPaymentMethodType.CheckOutOrder,
                // saleOrderId: orderData?.id,
                returnUrl: baseUrl + '#/cashBillPayment/' + this.data.claimId,
                itemDetails: this.paymentData,
                status: 'Link Expired',
                claimId: this.data?.claimId,
                // clientMailAddress: "sb-pddu46631598@business.example.com",
                // clientMerchantId: "VN995TY99ZFHC"
              };
              this.paymentService.createCheckOutOrder(payment).subscribe(
                (response) => {
                  this.getClaimDataById();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
            }
          });
        } else {
          let orderItem =
            response?.billingState?.selectedBilling?.saleOrder.items;
          let orderId = orderData.id;
          this.paymentData = [];
          orderItem?.forEach((element: { itemId: any; charge: any }) => {
            this.paymentData.push({
              itemId: element?.itemId,
              itemCurrenyCode: 'USD',
              itemAmount: String(element?.charge),
            });
          });
          payment = {
            paypalPaymentMethodType: paypalPaymentMethodType.CheckOutOrder,
            // saleOrderId: orderData?.id,
            returnUrl: baseUrl + '#/cashBillPayment/' + this.data.claimId,
            itemDetails: this.paymentData,
            status: '',
            claimId: this.data?.claimId,
            // clientMailAddress: "sb-pddu46631598@business.example.com",
            // clientMerchantId: "VN995TY99ZFHC"
          };
          this.paymentService.createCheckOutOrder(payment).subscribe(
            (response) => {
              this.getClaimDataById();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        }
      });
  }

  // ! Get Claim Data By Id
  getClaimDataById() {
    if (
      this.claimId &&
      this.claimId !== '' &&
      this.claimId !== undefined &&
      this.claimId !== null
    ) {
      this.claimInformationService.get(this.claimId).subscribe(
        (claimResponse: ClaimDTO) => {
          this.updateClaimDataOnOrderUpdate(claimResponse);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      // Swal.fire({
      //   title: 'Success',
      //   html: 'Mail Sent Successfully',
      //   icon: 'success',
      //   timer: 3000,
      //   timerProgressBar: true,
      // });
      this.toastr.success('Mail Sent Successfully','Success')
      this.dialogRef.close();
    }
  }

  // ! Update Claim Order Data
  updateClaimDataOnOrderUpdate(claimResponse) {
    if (
      this.claimId &&
      this.claimId !== '' &&
      this.claimId !== undefined &&
      this.claimId !== null
    ) {
      const input = {
        lstClaimInfo: claimResponse?.lstClaimInfo,
        lstPostingCharges: claimResponse?.lstPostingCharges,
        insurancePayments: claimResponse?.insurancePayments,
        saleOrder: claimResponse?.saleOrder,
        lstPatientVisitNotes: claimResponse?.lstPatientVisitNotes,
        lstClearingHouseUpdates: claimResponse?.lstClearingHouseUpdates,
        readyCount: claimResponse?.readyCount,
        processedCount: claimResponse?.processedCount,
        crossOverCount: claimResponse?.crossOverCount,
        holdCount: claimResponse?.holdCount,
        patientResponsibilityCount: claimResponse?.patientResponsibilityCount,
        patientResponsibilityAfterInsCount:
          claimResponse?.patientResponsibilityAfterInsCount,
        patientId: claimResponse?.patientId,
        defaultPatientId: claimResponse?.defaultPatientId,
        patientName: claimResponse?.patientName,
        patientDob: claimResponse?.patientDob,
        orderId: claimResponse?.orderId,
        saleorderId: claimResponse?.saleorderId,
        claimStatus: claimResponse?.claimStatus,
        claimId: claimResponse?.claimId,
        totalBilled: claimResponse?.totalBilled,
        totalInsBal: claimResponse?.totalInsBal,
        totalPatientBal: claimResponse?.totalPatientBal,
        lstReminders: claimResponse?.lstReminders,
        claimProcessMethod: claimResponse?.claimProcessMethod
      };

      this.claimInformationService.update(this.claimId, input).subscribe(
        (claimResponse) => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Mail Sent Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Mail Sent Successfully','Success')
          this.dialogRef.close();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  // ! Invoice Paypal Proceed
  paypalInvoiceProceed() {
    this.store.dispatch(new getBilling(this.data.claimId)).subscribe(
      (response) => {
        const orderData =
          response?.billingState?.selectedBilling?.saleOrder || null;
        const claimData: ClaimDTO =
          response?.billingState?.selectedBilling || null;
        const orderItem: ItemDTO[] =
          response?.billingState?.selectedBilling?.saleOrder?.items ?? [];
        const orderId: string = orderData?.id ?? null;
        const patientData = orderData?.patients;
        const bAddress: AddressDTO = patientData?.addresses?.[0] || null;
        const dAddress: AddressDTO = patientData?.addresses?.[1] || null;
        let invoiceItemsData: Item[] = [];
        invoiceItemsData.push({
          name: orderItem[0].name,
          description: orderItem[0].description,
          quantity: String(orderItem[0].qty),
          unit_amount: {
            currency_code: 'USD',
            value:
              this.isShowItemData === 1
                ? String(+claimData?.totalPatientBal || 0)
                : String(
                  response?.billingState?.selectedBilling?.totalPatientAmount
                ),
          },
          tax: null,
          discount: null,
          unit_of_measure: 'QUANTITY',
        });
        let patientPhones: Phone[];
        if (
          bAddress?.country !== '' &&
          bAddress?.country !== null &&
          bAddress?.country !== undefined &&
          bAddress?.phone !== '' &&
          bAddress?.phone !== null &&
          bAddress?.phone !== undefined
        ) {
          patientPhones = [
            {
              country_code:
                this.drpCountry.filter(
                  (v) => v?.countryShortName === bAddress?.country
                )?.[0]?.countryPhoneCode ?? null,
              national_number: bAddress?.phone ?? null,
              phone_type: 'MOBILE',
            },
          ];
        }

        const amount: Amount = {
          breakdown: null,
          currency_code: 'USD',
          value: String(+claimData?.totalPatientBal || 0),
        };
        const input: CreateUpdatePayPalInvoiceDTO = {
          paypalPaymentMethodType: paypalPaymentMethodType.Invoice,
          // saleOrderId: orderId,
          detail: null,
          invoicer: null,
          primary_recipients: [
            {
              billing_info: {
                name: {
                  given_name: patientData?.personals?.firstName ?? '',
                  surname: patientData?.personals?.lastName ?? '',
                },
                address: {
                  address_line_1: bAddress?.address ?? '',
                  admin_area_1: bAddress?.city ?? '',
                  postal_code: bAddress?.postalCode ?? '',
                  country_code:
                    bAddress?.country === '' ? null : bAddress?.country,
                },
                email_address:
                  patientData?.personals?.emailId == null ||
                    patientData?.personals?.emailId == undefined ||
                    patientData?.personals?.emailId == ''
                    ? 'sb-cvmiv6652198@business.example.com'
                    : patientData?.personals?.emailId, // patientData?.personals?.emailId ?? null,
                phones: patientPhones ?? null, // !
                additional_info_value: null,
              },
              shipping_info: {
                name: {
                  given_name: patientData?.personals?.firstName ?? '',
                  surname: patientData?.personals?.lastName ?? '',
                },
                address: {
                  address_line_1: dAddress?.address ?? '',
                  admin_area_1: dAddress?.city ?? '',
                  postal_code: dAddress?.postalCode ?? '',
                  country_code:
                    dAddress?.country === '' ? null : dAddress?.country,
                },
              },
            },
          ],
          items: invoiceItemsData,
          configuration: null,
          amount: null,
          claimId: this.data?.claimId,
        };

        this.invoiceService.createDraftInvoice(input).subscribe(
          (response) => {
            this.getClaimDataById();
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  // ! Invoice payment view
  getInvoicePaymentView() {
    this.store.dispatch(new getBilling(this.data.claimId)).subscribe(
      (response) => {
        const claimData: ClaimDTO = response?.billingState?.selectedBilling;
        response?.billingState?.selectedBilling
        const orderData: SaleorderDTO =
          response?.billingState?.selectedBilling?.saleOrder;
        this.showInvoiceProceedButton = true;
        if (
          orderData?.payPalPaymentStatus === 'Initiate Payment' ||
          orderData?.payPalPaymentStatus === null
        ) {
          this.showInvoiceProceedButton = true;
          this.showCheckOutProceedButton = false;
        } else {
          this.showInvoiceProceedButton = false;
        }
        // this.saleOrderId = orderData?.saleorderId ?? '';
        // this.dob =
        //   orderData?.patients?.personals?.dateOfBirth == null
        //     ? ''
        //     : new Date(
        //       orderData?.patients?.personals?.dateOfBirth
        //     )?.toLocaleDateString("en-US") ?? '';
        // this.Name = orderData?.patients?.personals?.fullName ?? '';

        response?.billingState?.selectedBilling?.insurancePayment?.primaryInsurancePayments?.lstItemPayment?.forEach(
          (element) => {
            this.items?.push({
              itemName: element.name,
              amount: element.allowed,
              code: element.itemProcedureCode,
              // amount: element.allowed,
              //  code: element.itemProcedureCode,
              insAmount: element?.payment,
              patBlns: element?.totalCoInsuranceAmount,
            });
          }
        );
        //this.patientBalance = claimData?.totalPatientBal ?? 0;
        const lastInvoiceDetails: PayPalInvoicePaymentsDTO | null =
          orderData?.paymentTransactionDetails?.payPalInvoicePayments?.slice(
            orderData?.paymentTransactionDetails?.payPalInvoicePayments
              ?.length - 1,
            orderData?.paymentTransactionDetails?.payPalInvoicePayments?.length
          )?.[0] || null;
        this.paymentCompletedDateTime = lastInvoiceDetails?.payments
          ?.transactions?.[0]?.payment_date
          ? lastInvoiceDetails.detail?.metadata?.last_update_time
          : '';
        this.paymentCreatedDateTime =
          lastInvoiceDetails?.detail?.metadata?.create_time ?? '';
        this.totalPaidAmount = lastInvoiceDetails?.['totalPaidAmount'] ?? '';
        this.totalPaypalAmount =
          lastInvoiceDetails?.['totalPaypalTransactionChargeAmount'] ?? '';
        this.invoiceOrderId = lastInvoiceDetails?.id ?? '';
        this.totalTransferredAmount =
          lastInvoiceDetails?.['totalTransferredAmount'] ?? '';
        this.invoiceNumber = lastInvoiceDetails?.detail?.invoice_number ?? '';
        this.payPalInvoiceMailResendCount =
          orderData?.payPalMailResendCount ?? 0;
        this.totalPatPrice =
          response?.billingState?.selectedBilling?.totalPatientAmount ?? 0;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
