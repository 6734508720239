<mat-card class="mr-3 mt-4">

  <mat-card-title class="customThemeClass">
    <div class="row">
      <div class="col-md-6">
        <h4 class="customThemeClass"><b>Sorting</b></h4>
      </div>
      <div class="col-md-6 text-sm-right">
        <div class="text-sm-right col-lg-12 p-0">
          <button mat-button class="buttonColor mr-1 font-size-13 btn-sm"
          (click)="addDocumentType()" [disabled]="!editFormValues">
            <!-- <i class="fa fa-plus mr-1"></i> Add New Patient Document </button> -->
            <mat-icon>cloud_upload</mat-icon> Upload Document </button>
        </div>
      </div>
    </div>
  </mat-card-title>

  <!-- <mat-card-title class="customThemeClass"><b>Sorting</b>
    <div class="text-right">
    <button mat-button class="buttonColor"> Add New Patient Document</button>
    </div>
  </mat-card-title> -->
  <mat-divider class="bg mb-5"> </mat-divider>
  <mat-card-content>
    <form [formGroup]="fgGetSortingView">
      <div class="row pt-2">

        <!-- <mat-form-field class="col-lg-4">
          <mat-label>Default Fax Id<span class="asterisk">*</span> </mat-label>
          <mat-select formControlName="drpdefaultDocument" #drpdefaultDocumentSelect
            (selectionChange)="onSelectChange($event)" [disabled]="isDisabled">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search Default Fax Id"
                [formControl]="drpdefaultDocumentFilterControl"
                noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let dD of filteredDefaultDocuments" [value]="dD">
              {{ dD?.defaultFaxId || '-' }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="col-lg-4">
          <!-- <mat-label>Default Fax Id<span class="asterisk">*</span> </mat-label> -->
          <mat-label>Fax Id / Type / Date / Bin <span class="asterisk"> *</span> </mat-label>
          <mat-select formControlName="drpdefaultDocument" #drpdefaultDocumentSelect
            (selectionChange)="onSelectChange($event)" [disabled]="isDisabled">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search Default Fax Id"
                [formControl]="drpdefaultDocumentFilterControl"
                noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let dD of filteredDefaultDocuments" [value]="dD">
              {{ dD?.faxId || '-' }}
            </mat-option>
          </mat-select>

        <button [disabled]="!editFormValues || isInbound" mat-button matSuffix (click)="deleteManualUploadDocument();$event.stopPropagation()" mat-icon-button 
        matTooltip="Delete Document" [matTooltipDisabled]="!editFormValues || isInbound">
          <mat-icon [ngStyle]="{'color':'#ca1515'}" [ngClass]="{ 'disabled-link': !editFormValues || isInbound }">delete</mat-icon>
        </button>
        </mat-form-field>

        <div class="col-lg-3 pt-2">
          <a [routerLink]="'/view_document_tiff_sorting/'+ documentId +'/'+ blobName+'/'+ true + '/' + this.isInbound"
            target="_blank" class="buttonColor buttonStyle p-2 mr-2 dd-text"  [hidden]="isHidden">
              View Document Image
          </a>
        </div>

        <div class="col-lg-5 pt-2 text-right">
          <button class="buttonColor p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isEmpty(documentId) || isShowSpinner" mat-raised-button matTooltip="Download Patient Document" [matTooltipDisabled]="isEmpty(blobName) || isEmpty(documentId)" (click)="patientDocPdfDownload()">
            <i class="fa fa-download" aria-hidden="true"></i> <span *ngIf="!isShowSpinner" class="dd-text ml-1">Document</span> <span *ngIf="isShowSpinner" class="dd-text ml-1">Downloading</span>
            <mat-icon *ngIf="isShowSpinner">
                <mat-spinner class="spinner-border-sm ml-1" diameter="20"></mat-spinner>
            </mat-icon>
        </button>

        <button class="buttonColor p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isEmpty(documentId) || isShowSpinner" mat-raised-button matTooltip="View Document in PDF" [matTooltipDisabled]="isEmpty(blobName) || isEmpty(documentId)" (click)="viewPatientBlobPDF()">
          <i class="fa fa-eye" aria-hidden="true"></i> <span class="dd-text ml-1">View Document in PDF</span>
      </button>
          </div>
      </div>
    </form>

    <mat-divider></mat-divider>

    <div class="p-2">
      <app-common-sorting [docId]="documentId" [sortingblobName]="blobName" [isInbound]="isInbound"  [defaultFaxShortCodeId]="defaultFaxShortCodeId" [signatureId]="signatureId" *ngIf="!isLoading" (prevent_unsaved_sorting)="prevent_unsaved_sortingFlagEmit()" (prevent_unsaved_annotations)="prevent_unsaved_annotations($event)" (headerText)="BindHearderText($event)"></app-common-sorting>
    </div>


  </mat-card-content>
</mat-card>
