import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ToastrService } from 'ngx-toastr';
import { CCSettlementService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/ccsettlement.service';
import { ClaimCCSettlementListDTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cc-settlement',
  templateUrl: './cc-settlement.component.html',
  styleUrls: ['./cc-settlement.component.scss'],
})
export class CcSettlementComponent implements OnInit {
  settlementForm: FormGroup;
  isTableLoaded: boolean = true;
  itemsList: any;
  fromDate: string = '';
  CCSettlementArray : ClaimCCSettlementListDTO[] = [];
  // CCSettlementArrayItem = CCSettlementArrayItem;
  settlementData: any;
  // isLoaded: boolean = true;
  dtRentalListOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '60vh',
    scrollX: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    // info: false,
    // buttons: [
    //   {
    //     extend: 'excel',
    //     text: 'Export', // Change the text to 'Export'
    //     filename: 'Rental  List',

    //   },
    // ],
  };

  dtRentalItemListOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '60vh',
    dom: 'Bfrtip',
    scrollX: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'CC Settlement Item List',
        exportOptions: {
          columns: [1, 2, 3, 4, 5, 6, 7],
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        className: 'ccItem',
      },
    ],
    columnDefs: [
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' }, // Add other columnDefs as needed
    ],
    // info: false,
    // buttons: [
    //   {
    //     extend: 'excel',
    //     text: 'Export', // Change the text to 'Export'
    //     filename: 'Rental  Item List',

    //   },
    // ],
  };
  isXMLLoading: boolean = false;
  dOrderFromDate: Date;
  dOrderToDate: Date;
  isLoadCCTableData: boolean = false;
  loadCCTableDataAPICall: Subscription;
  UpdateCCTableDataAPICall: Subscription;
  isUpdateCCTableData: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private ccSettlementService : CCSettlementService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {

    this.dtRentalListOptions.buttons[0].filename =
    'CC Settlement Item List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dOrderFromDate = this.getCTStartOfMonth();
    this.dOrderToDate = this.ctDateNow();
    this.settlementForm = this.formBuilder.group({
      // dtDate: new FormControl(new Date().toISOString()),
      txtStartDateValue: new FormControl('', [Validators.required]),
      txtEndDateValue: new FormControl('', [Validators.required]),
      fileUpload: new FormControl(''),
    });
    this.loadCCTableData();
  }

  ngOnDestroy(): void {
    if (this.loadCCTableDataAPICall) {
      this.loadCCTableDataAPICall.unsubscribe();
    }

    if (this.UpdateCCTableDataAPICall) {
      this.UpdateCCTableDataAPICall.unsubscribe();
    }
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }  
  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    return dateObj;
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.settlementForm.value.txtStartDateValue &&
      this.settlementForm.value.txtEndDateValue
    ) {
      this.loadCCTableData();
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  loadCCTableData() {
    this.isLoadCCTableData = true;
    try {
      if (this.loadCCTableDataAPICall) {
        this.loadCCTableDataAPICall.unsubscribe();
      }

      let fromDate: string = this.dOrderFromDate
          ? this.dOrderFromDate.toString()
          : null,
        toDate: string = this.dOrderToDate
          ? this.dOrderToDate.toString()
          : null;
      if (fromDate) {
        fromDate = this.convert(fromDate).toString();
      } else {
        fromDate = null;
      }
      if (toDate) {
        toDate = this.convert(toDate).toString();
      } else {
        toDate = null;
      }

      this.loadCCTableDataAPICall = this.ccSettlementService
        .getCompletedCCSettlementDetailsByDtFromDateAndDtToDate(
          fromDate,
          toDate
        )
        .subscribe(
          (response) => {
            this.CCSettlementArray = response;
            setTimeout(() => {
              this.isLoadCCTableData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            setTimeout(() => {
              this.isLoadCCTableData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadCCTableData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  UpdateCCTableData() {
    this.isUpdateCCTableData = true;
    try {
      if (this.UpdateCCTableDataAPICall) {
        this.UpdateCCTableDataAPICall.unsubscribe();
      }

      let fromDate: string = this.dOrderFromDate
          ? this.dOrderFromDate.toString()
          : null,
        toDate: string = this.dOrderToDate
          ? this.dOrderToDate.toString()
          : null;
      if (fromDate) {
        fromDate = this.convert(fromDate).toString();
      } else {
        fromDate = null;
      }
      if (toDate) {
        toDate = this.convert(toDate).toString();
      } else {
        toDate = null;
      }
      let lstClaimId: string[]=[];
      
      this.UpdateCCTableDataAPICall = this.ccSettlementService
        .updateClaimSettlementFlagByLstClaimId(
          lstClaimId
        )
        .subscribe(
          (response) => {
            setTimeout(() => {
              this.isUpdateCCTableData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            setTimeout(() => {
              this.isUpdateCCTableData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isUpdateCCTableData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  onFileChangeVo(event: any): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      const fileName = file.name;
    }

    // Check if a file was selected and if it is a valid PDF
    // if (
    //   file &&
    //   (file.type === 'application/pdf' ||
    //     file.name.toLowerCase().endsWith('.pdf'))
    // ) {
    //   this.isPdfLoad = false; // Hide the PDF viewer initially

    //   this.getPDFPageCount(file)
    //     .then((pageCount) => {
    //       if (pageCount > 0) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //           const base64String = btoa(reader.result as string); // Encode using btoa()
    //           this.strPdfString = base64String;
    //           setTimeout(() => (this.isPdfLoad = true), 500);
    //           target.value = '';
    //         };
    //         reader.readAsBinaryString(file); // Read as binary string for proper encoding
    //       } else {
    //         this.toastr.warning(
    //           'Error loading PDF file. Please try again.',
    //           'Warning'
    //         );
    //         this.strPdfString = null;
    //         target.value = ''; // Clear the file input value
    //         setTimeout(() => (this.isPdfLoad = true), 500);
    //       }
    //     })
    //     .catch((error) => {
    //       console.warn(error);
    //       this.toastr.warning(
    //         'Error loading PDF file. Please try again.',
    //         'Warning'
    //       );
    //       target.value = ''; // Clear the file input value
    //       setTimeout(() => (this.isPdfLoad = true), 500);
    //     });
    // } else {
    //   this.toastr.warning(
    //     'Invalid file format. Please select a valid PDF file.',
    //     'Warning'
    //   );
    //   // Optionally clear the file input value to reset it
    //   target.value = '';
    // }
  }
  onFileChange(event: any): void {
    this.isXMLLoading = true;
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    // Check if a file was selected and if it is a valid XML file
    if (
      file &&
      (file.type === 'text/xml' || // Check for XML MIME type
        file.name.toLowerCase().endsWith('.xml')) // Check if file extension is .xml
    ) {
      const fileName = file.name;
      setTimeout(() => (this.isXMLLoading = false), 500);
      // Proceed with handling the XML file
    } else {
      // Display an error message or perform other actions for invalid file types
      setTimeout(() => (this.isXMLLoading = false), 500);
      this.toastr.warning(
        'Invalid file format.Please select a valid XML file',
        'Warning'
      );
    }
  }
}

// const CCSettlementArray: any[] = [
//   {
//     id: 'C001',
//     firstName: 'Chris',
//     lastName: 'Chris',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     date: '23/02/2024',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'C001',
//     firstName: 'Chris',
//     lastName: 'Chris',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     date: '23/02/2024',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'C001',
//     firstName: 'Chris',
//     lastName: 'Chris',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     date: '23/02/2024',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'C001',
//     firstName: 'Chris',
//     lastName: 'Chris',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     date: '23/02/2024',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'C001',
//     firstName: 'Chris',
//     lastName: 'Chris',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     date: '23/02/2024',
//     CSR: 'Enrique',
//   },
// ];
// const CCSettlementArrayItem: any[] = [
//   {
//     id: 'C001',
//     date: '23/02/2024',
//     type: 'payment',
//     firstName: 'Chris',
//     acct: '-',
//     result: 'Match',
//     mesg: 'BIPAP Device Rental',
//     paypalId: 'NL645374638',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'T798001',
//     date: '23/02/2024',
//     type: 'payment',
//     firstName: 'Chris',
//     acct: '-',
//     result: 'Match',
//     mesg: 'BIPAP Device Rental',
//     paypalId: 'NL645374638',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'T798001',
//     date: '23/02/2024',
//     type: 'payment',
//     firstName: 'Chris',
//     acct: '-',
//     result: 'Match',
//     mesg: 'BIPAP Device Rental',
//     paypalId: 'NL645374638',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'T798001',
//     date: '23/02/2024',
//     type: 'payment',
//     firstName: 'Chris',
//     acct: '-',
//     result: 'Match',
//     mesg: 'BIPAP Device Rental',
//     paypalId: 'NL645374638',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'T798001',
//     date: '23/02/2024',
//     type: 'payment',
//     firstName: 'Chris',
//     acct: '-',
//     result: 'Match',
//     mesg: 'BIPAP Device Rental',
//     paypalId: 'NL645374638',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     CSR: 'Enrique',
//   },
//   {
//     id: 'T798001',
//     date: '23/02/2024',
//     type: 'payment',
//     firstName: 'Chris',
//     acct: '-',
//     result: 'Match',
//     mesg: 'BIPAP Device Rental',
//     paypalId: 'NL645374638',
//     orderId: 'OR123455',
//     amount: '$435',
//     approveId: 'A64873438',
//     CSR: 'Enrique',
//   },
// ];
