import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items';
import { CreateUpdateVendorDetailsDTO } from 'projects/inventory/src/app/item-proxy/item-management/optimization/dto/models';
import { VendorDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/vendor-details.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-add-manufacturer',
  templateUrl: './add-manufacturer.component.html',
  styleUrls: ['./add-manufacturer.component.scss'],
})
export class AddManufacturerComponent implements OnInit {
  productVendorForm: FormGroup;
  saveButtonHide: boolean = false;
  constructor(
    public addManufacturerModelRef: MatDialogRef<AddManufacturerComponent>,
    private formBuilder: FormBuilder,
    private productVendorService: VendorDetailsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initializeForms();
  }

  //Initialize Forms
  initializeForms() {
    this.productVendorForm = this.formBuilder.group({
      txtVendorName: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtVendorCode: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
    });
  }

  onCloseClick(): void {
    this.addManufacturerModelRef.close([]);
  }

  //To Save the Vendor Details
  saveVendorDetails() {
    this.saveButtonHide = true;
    let input: CreateUpdateVendorDetailsDTO = {
      vendorName: this.productVendorForm?.value?.txtVendorName ?? '',
      vendorCode: this.productVendorForm?.value?.txtVendorCode ?? '',
      addressLine1: '',
      primaryPhoneNumber: '',
      productQuantity: 0,
      status: Status.Active,
    };
    this.productVendorService.create(input).subscribe(
      (response) => {
        console.log(response);
        this.toastr.success('Saved Successfully', 'Success');
        this.addManufacturerModelRef.close(response);
      },
      (err) => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        this.toastr.warning(data?.error?.error?.message, 'Warning');
      }
    );
  }
}
