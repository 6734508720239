<mat-dialog-content class="mat-typography">
  <div class="modal-header">
    <h1 class="modal-title" >Call Back</h1>
    <button class="text-right close" aria-label="Close" mat-dialog-close>
      <b> X </b>
    </button>
  </div>

  <form [formGroup]="CallbackForm">
  <div class="modal-body">
      <mat-card-content>
        <div class="row">
          <!-- <div class="mt-3 col-5"> -->
              <mat-form-field class="col-6 mt-3">
                  <mat-label>Days<span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="drpBranchType" [(ngModel)]="seletedBranchValue">
                    <mat-option value="15">15</mat-option>
                    <mat-option value="30">30</mat-option>
                    <mat-option value="45">45</mat-option>
                    <mat-option value="60">60</mat-option>
                    <mat-option value="75">75</mat-option>
                    <mat-option value="90">90</mat-option>
                    <mat-option value="105">105</mat-option>
                    <mat-option value="120">120</mat-option>
                    <mat-option value="135">135</mat-option>
                    <mat-option value="150">150</mat-option>
                    <mat-option value="165">165</mat-option>
                    <mat-option value="180">180</mat-option>
                  </mat-select>
                </mat-form-field>
          <!-- </div> -->
          <!-- <mat-form-field class="col-6 mt-3">
            <mat-label>Note Type <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpNotetype"
              #singleSelect >
              <mat-option>
                <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found" formControlName="txtNoteType"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let note of drpNotetype" [value]="note.id+ '/' +note.noteType">
                {{ note.noteType }}
              </mat-option>
            </mat-select>
            <mat-error class="spanAlignment" *ngIf="
            CallbackForm?.get('drpNotetype')?.touched &&
            CallbackForm?.get('drpNotetype')?.errors?.required
            ">
              Note Type is a required field!
            </mat-error>
          </mat-form-field> -->

          <mat-form-field class="col-6 mt-3">
            <mat-select formControlName="drpNotetype">
              <mat-option>
                <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtNoteType"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let note of drpNotetype" [value]="note.id+ '/' +note.noteType">
                {{ note.noteType }}
              </mat-option>
            </mat-select>
            <mat-label>
              <div class="select-placeholder-container" *ngIf="isdrpLoading">
                <span>Loading...</span>
                <mat-spinner class="spinner" diameter="20"></mat-spinner>
              </div>
              <span *ngIf="!isdrpLoading">Note Type <span class="asterisk">*</span></span>
            </mat-label>
            <mat-error class="spanAlignment" *ngIf="
        CallbackForm?.get('drpNotetype')?.touched &&
        CallbackForm?.get('drpNotetype')?.errors?.required
      ">
              Note Type is a required field!
            </mat-error>
          </mat-form-field>

          <!--Comments-->
          <!-- <mat-form-field  class="col-12">
            <mat-label>Notes<span class="asterisk">*</span></mat-label>
            <textarea formControlName="txtComments" rows="4" maxlength="500" matInput></textarea>
          </mat-form-field> -->
          <mat-form-field class="col-12">
            <mat-label>Notes<span class="asterisk">*</span></mat-label>
            <textarea matInput rows="6" autocomplete="off" maxlength="5000" formControlName="txtComments"  [(ngModel)]="notesText" (input)="updateCharacterCount(5000)"
              type="text"></textarea>
              <div class="char-count">
                {{ notesText?.length }} / 5000
              </div>
            <mat-error class="spanAlignment" *ngIf="CallbackForm?.get('txtComments')?.touched">
              <ng-container *ngIf="CallbackForm?.get('txtComments')?.hasError('required')">
                Description is a required field!
              </ng-container>
              <ng-container *ngIf="CallbackForm?.get('txtComments')?.hasError('whitespace')">
                Description cannot be just whitespace!
              </ng-container>
            </mat-error>
          </mat-form-field>

        </div>
      </mat-card-content>
  </div>
  <div class="modal-footer">
    <div class="col-12 text-center">
      <!-- <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button
      [disabled]="CallbackForm.get('drpBranchType').value === '' ||CallbackForm.get('drpBranchType').value === defaultGuid ||CallbackForm.invalid||savebtn "
         (click)="saveCallback()">Save
      </button> -->
      <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button
      [disabled]="CallbackForm.get('drpBranchType').value === '' ||CallbackForm.get('drpBranchType').value === defaultGuid ||!(!CallbackForm?.invalid && CallbackForm?.dirty)||savebtn ||isShowSpinner" (click)="saveCallback()">
      Save
      <mat-icon *ngIf="isShowSpinner">
        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
      </mat-icon>
    </button>

      <button mat-button class="resetclr mb-2 mr-2" (click)="resetCallBack()">Reset</button>
    </div>
  </div>
</form>
</mat-dialog-content>
