<br />
<div class="card card-body">
  <mat-accordion #firstAccordion="matAccordion">
    <mat-expansion-panel [expanded]="step">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="userId===''; else elseTitleTemplate">
            <b>Add OnTime User</b>
          </ng-container>
          <ng-template #elseTitleTemplate>
            <b>Edit OnTime User</b>
          </ng-template>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <form [formGroup]="OnTimeUserForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label> User Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtUserName" matInput maxlength="40" formControlName="txtUserName" type="text" />
            <mat-error
              *ngIf="OnTimeUserForm?.get('txtUserName')?.touched && OnTimeUserForm?.get('txtUserName')?.errors?.required">
              User Name is a required field!
            </mat-error>
          </mat-form-field>



          <mat-form-field class="col-3">
            <mat-label>Branch Name <span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" [(value)]="branch" formControlName="txtBranchName">
              <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                {{ organizations.organizationUnitName }}

              </mat-option>

            </mat-select>
            <button mat-button (click)="clearBranch()" (click)="branch='';$event.stopPropagation()" *ngIf="branch"
              matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf=" OnTimeUserForm?.get('txtBranchName')?.touched && OnTimeUserForm?.get('txtBranchName')?.errors?.required">
              Branch Name is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Role Name <span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" [(value)]="role" formControlName="txtRole">
              <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                {{ roles.name }}
              </mat-option>

            </mat-select>
            <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role" matSuffix
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf=" OnTimeUserForm?.get('txtRole')?.touched && OnTimeUserForm?.get('txtRole')?.errors?.required">
              Role Name is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Phone No<span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtPhnNo" matInput prefix="+1-" mask="(000)-000-0000" maxlength="40" formControlName="txtPhnNo" type="text" />
            <mat-error
              *ngIf=" OnTimeUserForm?.get('txtPhnNo')?.touched && OnTimeUserForm?.get('txtPhnNo')?.errors?.required">
              Phone No is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Address 1 <span class="asterisk">*</span> </mat-label>
            <input autocomplete="new-txtAddress1" matInput maxlength="40" formControlName="txtAddress1" type="text" />
            <mat-error
              *ngIf=" OnTimeUserForm?.get('txtAddress1')?.touched && OnTimeUserForm?.get('txtAddress1')?.errors?.required">
              Address1 is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Address 2 </mat-label>
            <input autocomplete="new-txtAddress2" matInput maxlength="40" formControlName="txtAddress2" type="text" />
          </mat-form-field>


          <mat-form-field class="col-3">
            <mat-label>Country <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="txtCountry" (selectionChange)='onChangeState($event.value)'
              [(value)]="Country">
              <!-- <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCountryFilter"></ngx-mat-select-search>
                </mat-option> -->
              <mat-option *ngFor="let country of drpCountry" [value]="country.countryShortName">
                {{ country.countryName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="Country='';$event.stopPropagation()" *ngIf="Country" (click)="clearCountry()"
              matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="OnTimeUserForm?.get('drpCountry')?.touched &&
            OnTimeUserForm?.get('drpCountry')?.errors?.required ">
              Address line -1 is a required field!
            </mat-error>
          </mat-form-field>



          <mat-form-field class="col-3">
            <mat-label>State <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="txtState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
              #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtStateFilter">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                {{ state.stateName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
              matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="OnTimeUserForm?.get('drpState')?.touched &&
            OnTimeUserForm?.get('drpState')?.errors?.required ">
              Address line -1 is a required field!
            </mat-error>
          </mat-form-field>



          <mat-form-field class="col-3">
            <mat-label>City <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="txtCity" [(value)]="City" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtCityFilter">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="OnTimeUserForm?.get('drpCity')?.touched &&
            OnTimeUserForm?.get('drpCity')?.errors?.required ">
              City is a required field!
            </mat-error>
          </mat-form-field>


          <mat-form-field class="col-3">
            <mat-label> Zip Code<span class="asterisk">*</span> </mat-label>
            <input autocomplete="new-txtZipCode" matInput maxlength="40" formControlName="txtZipCode" type="text" />
            <mat-error
              *ngIf=" OnTimeUserForm?.get('txtZipCode')?.touched && OnTimeUserForm?.get('txtZipCode')?.errors?.required">
              Zip Code is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Email <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }" />

            <mat-error *ngIf="OnTimeUserForm?.get('txtEmail')?.touched &&
            OnTimeUserForm?.get('txtEmail')?.errors?.required ">
              Email Address is a required field!
            </mat-error>
            <mat-error *ngIf="
            OnTimeUserForm?.get('txtEmail')?.touched &&
            OnTimeUserForm?.get('txtEmail')?.errors?.pattern
                                  ">
              Please Enter a Valid Email Address
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Password <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-password" matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtPassword"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" type="text" />
            <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
            <mat-error *ngIf="
            OnTimeUserForm?.get('txtPassword')?.touched &&
            OnTimeUserForm?.get('txtPassword')?.errors?.required
                ">
              Password is a required field!
            </mat-error>
            <mat-error *ngIf="
            OnTimeUserForm?.get('txtPassword')?.touched &&
            OnTimeUserForm?.get('txtPassword')?.errors?.pattern
              ">
              Passwords must contain at least one non alphanumeric character,one uppercase,one digit
              and at
              least 8 or more characters
            </mat-error>
          </mat-form-field>
          <!-- <div class="col-2"> <b>OnTime User Status</b></div>
          <div class="col-5" style="display: flex;">
           : Inactive
            <mat-slide-toggle class="ml-2" (click)="sundayStatus(OnTimeUserForm.status)"  formControlName="statusToggle">
              active</mat-slide-toggle>

          </div>
          <div class="col-5"></div> -->
          <div *ngIf="status" class="col-2"> <b>OnTime Branch Status</b></div>
          <div *ngIf="status" class="col-5" style="display: flex;">
            <mat-slide-toggle (change)="sundayStatus($event)" class="ml-2" formControlName="statusToggle">
              {{activeStatus}}</mat-slide-toggle>
          </div>
          <div class="col-2"></div>
        </div>
      </form>
      <br />
      <div class="row">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <button *ngIf="addWorkingButton" mat-button class="buttonColor mr-2" (click)="openAddWorkingModel()">
              Add Working Hours</button>
            <button [disabled]="!(!OnTimeUserForm.invalid)" mat-button class="buttonColor mr-2" (click)="mapOpen()">
              Check</button>
            <button mat-button class="buttonColor mr-2" [disabled]="!(!OnTimeUserForm.invalid && OnTimeUserForm.dirty )"
              (click)="saveOnTimeUser()">
              Save
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="80"> </mat-spinner>
            </mat-icon>
            </button>
            <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">Reset</button>
          </div>
        </div>
      </div>
      <div *ngIf="mapHide">
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="18">
          <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
        </agm-map>

      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- <h4 class="customThemeClass ml-3 mt-2 mb-0"><b>Appontment Type Details</b></h4> -->

  <!-- <div class="row">
    <div class="col-12 table-responsive">
      <app-table [strPageType]="strPageType" (viewButton)="ViewUser($event)" (deleteButton)="deleteUser($event)">
      </app-table>
    </div>

  </div> -->

  <div class="row">
    <div class="col-12 table-responsive">
      <!-- <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
        <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
        </mat-progress-bar>
      </div> -->
      <table mat-table class="table ml-4 border" matSort [dataSource]="dataSource">
        <form style="display: flex" [formGroup]="userSearchForm">


          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{ i+1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-nameSearch" matInput formControlName="nameSearch" />
                <mat-placeholder>User Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.name}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="phn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-phnSearch" matInput formControlName="phnSearch" />
                <mat-placeholder>Phone Number</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.phoneNumber}}
              </ng-container>
            </td>
          </ng-container>



          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-dateSearch" matInput formControlName="dateSearch" placeholder="(MM/DD/YYYY)" />
                <mat-error
                  *ngIf=" userSearchForm?.get('dateSearch')?.touched && userSearchForm?.get('dateSearch')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.createDate}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-statusSearch" matInput formControlName="statusSearch" />
                <mat-placeholder>Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.status}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor" (click)="ViewUser(data)">
                <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To Edit" ></i>
              </a>
              &nbsp;
              <a class="deleteBtnColor" (click)="deleteUser(data)">
                <i class="fa fa-trash-o" matTooltip="Click To Delete" ></i>
              </a>
            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
              <div *ngIf="!isLoading">
                {{"No Records Found"}}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="6">
              <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
        </form>
        <tr mat-footer-row *matFooterRowDef="['noRecords']"
          [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>
  </div>
</div>
