<div class="col-12">
  <div class="card-body">
    <h3>Advanced Filters</h3>
    <form [formGroup]="fgBillingPaymentFilter">
      <mat-form-field class="col-12">
        <mat-label> Payer: </mat-label>
        <input matInput  formControlName="txtPayer" type="text" />
      </mat-form-field>
    </form>

  </div>
</div>

