import { ItemPaymentService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CommonDocumentViewerType } from '../../enums/allenums.enum';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-common-doc-viewer-modal',
  templateUrl: './common-doc-viewer-modal.component.html',
  styleUrls: ['./common-doc-viewer-modal.component.scss'],
})
export class CommonDocViewerModalComponent implements OnInit {
  strPdfString: string = '';
  pdfheight = '80vh';
  strSelectedPdfPath: string = '';
  blobName: any;
  defaultDocId: any;
  defaultFaxId: any;

  documentType: CommonDocumentViewerType;

  subscription$: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private itemPaymentService: ItemPaymentService,
    private inboundDocumentService: InboundDocumentService,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Document Viewer | Qsecure');
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.blobName = response.get('blobName');
        this.defaultDocId = response.get('defaultId') ?? '-';
        this.documentType = Number(response.get('documentType')) ?? 0;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);

    this.loadDocuments();
  }
  loadDocuments() {
    if (this.documentType == CommonDocumentViewerType.FaxDocument) {
      this.loadInboundDocument();
    } else if (this.documentType == CommonDocumentViewerType.EOB) {
      this.loadEOBDocument();
    }
  }

  loadEOBDocument() {
    this.itemPaymentService
      .getEOBDocumentByBlobIDByBlobName(this.blobName)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strPdfString = String(response);
          }
        },
        (err) => {
          console.log('File not found');
        }
      );
  }

  loadInboundDocument() {
    this.inboundDocumentService
      // .downloadInboundDocumentByBlobName(this.data.blobName)
      .downloadInboundDocumentByBlobName(this.blobName)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strPdfString = String(response);
          }
        },
        (err) => {
          this.strSelectedPdfPath = '';

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
}
