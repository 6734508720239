<div class="row">
  <div class="col-lg-6">
    <h2 class="customThemeClass">Edit Claim</h2>
  </div>
  <!-- <div class="col-lg-6 text-right">
    <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
    </button>
  </div> -->
</div>
<mat-dialog-content class="mat-typography"><br>
  <!-- <form [formGroup]="claimInfoForm">
  <div class="row">
    <div class="col-12">
      <h4 class="customThemeClass"><b>Claim Info</b></h4>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field class="col-4">
        <mat-label> Claim type</mat-label>
        <mat-select maxlength="40" formControlName="drpClaimType">

        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-4">
        <mat-label> Payor Control Number</mat-label>
        <input matInput type="text"  maxlength="10" formControlName="txtPayorControlNumber">
      </mat-form-field>
      <mat-form-field class="col-4">
        <mat-label> Special Program</mat-label>
        <mat-select maxlength="40" formControlName="drpSpecialProgram">

        </mat-select>
      </mat-form-field>
    </div>
    <br />
  </div>

  <div class="row">

    <div class="col-lg-12">
      <mat-form-field class="col-4">
        <mat-label>Delay Reason</mat-label>
        <mat-select maxlength="40" formControlName="drpDelayReason">

        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-4">
        <mat-label>Service Authorization Exception</mat-label>
        <mat-select maxlength="40" formControlName="drpServiceAuthException">

        </mat-select>
      </mat-form-field>


    </div>
    <br />
  </div>
</form> -->

  <div class="row">
    <div class="col-12">
      <h4 class="customThemeClass"><b>Transactions</b></h4>
    </div>
  </div>
  <app-table [strPageType]="strPageType">  </app-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
