import { Component, Inject, OnInit } from '@angular/core';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { CreateUpdateMMOrderItemDTO } from 'projects/inventory/src/app/item-proxy/item-management/optimization/dto';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Toast, ToastrService } from 'ngx-toastr';
import { Route, Router } from '@angular/router';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { GeneratePdfService } from 'projects/shared/src/app/services/generate-pdf.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-items-modal-exchange-pickup',
  templateUrl: './order-items-modal-exchange-pickup.component.html',
  styleUrls: ['./order-items-modal-exchange-pickup.component.scss'],
})
export class OrderItemsModalExchangePickupComponent implements OnInit {
  isTableLoaded: boolean = true;
  dtTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    buttons: [],
    columnDefs: [
      {
        targets: [0],
        orderable: false,
        searchable: false,
      },
    ],
  };
  isPageLoading: boolean;
  subscription$: Subscription[] = [];
  ltItems: any[] = [];
  constructor(
    private mmOrderService: MmOrderService,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private generatePdfService: GeneratePdfService,
    private dialog: MatDialogRef<OrderItemsModalExchangePickupComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      mmOrderId: string;
      exchangeOrReturn: string;
      patientId: string;
    } = {
      orderId: defaultGuid,
      mmOrderId: '',
      exchangeOrReturn: '',
      patientId: defaultGuid,
    }
  ) {
    this.loaderService.getLoaderState().subscribe((isPageLoading) => {
      this.isPageLoading = isPageLoading;
    });
  }

  ngOnInit() {
    console.log(this.data.exchangeOrReturn);
    this.getOrderItemsList();
  }

  getOrderItemsList() {
    this.isTableLoaded = false;
    this.mmOrderService.getPatientOrderItemList(this.data.orderId).subscribe(
      (response) => {
        this.ltItems = response?.items ?? [];

        this.ltItems = response?.items?.map((x) => {
          return {
            ...x,
            checked: false,
          };
        });

        this.isTableLoaded = true;
        console.log(this.ltItems);
      },
      (err) => {}
    );
  }
  checkAll: boolean = false;
  checkAllItems(event: any) {
    console.log(event);
    this.ltItems = this.ltItems.map((x) => {
      return {
        ...x,
        checked: event.checked,
      };
    });
  }

  checkAllItemsSelected() {
    this.checkAll = this.ltItems.every((x) => x.checked);
  }

  get checkValidation(): boolean {
    return this.ltItems.every((x) => !x.checked);
  }

  samplePickupTicket: string = '';
  // pickupOrder(isExchange: boolean) {
  //   let itemIds = this.ltItems
  //     .filter((a) => a.checked)
  //     .map((x) => x.mmOrderItemId);
  //   this.loaderService.showLoader();
  //   let inputParams: any = {
  //     gOrderId: this.data.orderId,
  //     gOrderItemId: itemIds,
  //     isPickup: true,
  //     isExchange: isExchange,
  //     sBase64File: this.samplePickupTicket,
  //   };
  //   console.log(inputParams);
  //   this.mmOrderService.orderPickupExchangeByOInput(inputParams).subscribe(
  //     (response) => {
  //       isExchange
  //         ? this.toastr.success('Exchange Order Created & Pickup Initiated!')
  //         : this.toastr.success('Pickup Initiated successfully!');
  //       this.dialog.close(true);
  //       this.loaderService.hideLoader();
  //       if (isExchange) {
  //         this.router.navigate(
  //           ['/orders/saleOrder', response.patientId, response.gOrderId, 0],
  //           { queryParamsHandling: 'merge' }
  //         );
  //       }
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       this.toastr.error(data?.error?.error?.message);
  //       this.loaderService.hideLoader();
  //     }
  //   );
  // }

  pickupOrder(isExchange: boolean) {
    this.loaderService.showLoader();
    let itemIds = this.ltItems
      .filter((a) => a.checked)
      .map((x) => x.mmOrderItemId);
    let inputParams = {
      gOrderId: this.data.orderId,
      gOrderItemId: itemIds,
      isPickup: true,
      isExchange: isExchange,
      sBase64File: '',
    };

    const pickupDetails = this.mmOrderService
      .getPrintPickupOrderByGOrderIdAndLtItemIdAndGPatientId(
        this.data.orderId,
        inputParams.gOrderItemId,
        this.data.patientId
      )
      .subscribe(
        (response) => {
          console.log(response);
          //inputParams.sBase64File =
          //this.generatePdfService.getPickupTicketPdf(response);
          this.generatePdfService
            .getPickupTicketPdf(response)
            .then((base64) => {
              inputParams.sBase64File = base64;
              this.savePickupExchangeDetails(inputParams);
            });
        },
        (err) => {
          this.loaderService.hideLoader();
        }
      );
    this.subscription$.push(pickupDetails);
  }

  savePickupExchangeDetails(inputParams: any) {
    this.mmOrderService.orderPickupExchangeByOInput(inputParams).subscribe(
      (response) => {
        inputParams.isExchange
          ? this.toastr.success('Exchange Order Created & Pickup Initiated!')
          : this.toastr.success('Pickup Initiated successfully!');
        this.dialog.close(true);
        this.loaderService.hideLoader();
        if (inputParams.isExchange) {
          this.router.navigate(
            ['/orders/saleOrder', response.patientId, response.gOrderId, 0],
            { queryParamsHandling: 'merge' }
          );
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message);
        this.loaderService.hideLoader();
      }
    );
  }
}
