import type { CreateUpdateDeliveryTicketDTO, CreateUpdateSaleorderDTO, CreateUpdateShippingCheckListDTO, ItemDTO, NewSerialNoDetailsDTO, SaleorderDTO, SearchSaleorderDTO, UpdateSerialNoDTO, WarehouseFilterDropdownsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SaleorderService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateSaleorderDTO) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'POST',
      url: '/api/orderApp/saleorder',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/saleorder/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteAuthorization = (orderId: string, authorizationId: string) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'DELETE',
      url: '/api/orderApp/saleorder/authorization',
      params: { orderId, authorizationId },
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteItem = (saleOrderId: string, itemId: string) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'DELETE',
      url: '/api/orderApp/saleorder/item',
      params: { saleOrderId, itemId },
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'GET',
      url: `/api/orderApp/saleorder/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<SaleorderDTO>>({
      method: 'GET',
      url: '/api/orderApp/saleorder',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateSaleorderDTO) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'PUT',
      url: `/api/orderApp/saleorder/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  frequentOrderItemsByPatientId = (PatientId: string) =>
    this.restService.request<any, ItemDTO[]>({
      method: 'POST',
      url: `/api/orderApp/saleorder/frequent-order-items/${PatientId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getOrderList = (sStatus: string) =>
    this.restService.request<any, PagedResultDto<SaleorderDTO>>({
      method: 'GET',
      url: '/api/orderApp/saleorder/get-order-list',
      params: { sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  getSerialNumbersByProductIdAndSStatus = (productId: string, sStatus: string) =>
    this.restService.request<any, NewSerialNoDetailsDTO[]>({
      method: 'GET',
      url: `/api/orderApp/saleorder/get-serial-numbers/${productId}`,
      params: { sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  getTenantLogo = () =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/orderApp/saleorder/get-tenant-logo',
    },
     { apiName: this.apiName, skipHandleError: true });

  getWarehouseDropdowns = () =>
    this.restService.request<any, WarehouseFilterDropdownsDTO>({
      method: 'GET',
      url: '/api/orderApp/saleorder/get-warehouse-dropdowns',
    },
     { apiName: this.apiName, skipHandleError: true });

  searchSaleorderByInput = (input: SearchSaleorderDTO) =>
    this.restService.request<any, PagedResultDto<SaleorderDTO>>({
      method: 'GET',
      url: '/api/orderApp/saleorder/search-saleorder',
      params: { patientNames: input.patientNames, userIds: input.userIds, patientIds: input.patientIds, orderIds: input.orderIds, ticketIds: input.ticketIds, status: input.status, ticketCreatedFromDate: input.ticketCreatedFromDate, ticketCreatedToDate: input.ticketCreatedToDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateDeliveryTicketByOrderIdAndInput = (orderId: string, input: CreateUpdateDeliveryTicketDTO) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'PUT',
      url: `/api/orderApp/saleorder/update-delivery-ticket/${orderId}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateSerialnoByInput = (input: UpdateSerialNoDTO) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'PUT',
      url: '/api/orderApp/saleorder/update-serialno',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateShippingCheckListByOrderIdAndInput = (orderId: string, input: CreateUpdateShippingCheckListDTO) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'PUT',
      url: `/api/orderApp/saleorder/update-shipping-check-list/${orderId}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
