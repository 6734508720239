<div class="card card-body">
    <!-- <div class="row"> -->
        <!-- <div class="col-12">
            <mat-label><b class="customThemeClass custom-tab-header ml-3">Fax Number Info</b></mat-label>
        </div>
        <div class="col-8">
            <form [formGroup]="faxNumberForm">
                <mat-form-field class="col-5 mt-3">
                    <mat-label>Fax No <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtFax" matInput mask="(000)-000-0000" maxlength="40"
                        placeholder="Enter FAX Number" formControlName="txtFax" type="text" prefix="+1-" />
                </mat-form-field>
            </form>
        </div>
        <div class="col-4">
            <div class="col-12 text-right mt-1">
                <button mat-button class="buttonColor mr-2"
                    [disabled]="isShowSpinner || !(!faxNumberForm?.invalid && faxNumberForm?.dirty)"
                    (click)="saveFaxNumber()">
                    <span>{{ faxnumberid ? 'Update' : 'Save' }}</span>
                    <mat-icon *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon>
                </button>
                <button mat-button class="resetclr mr-2" (click)="resetFaxNumber()">Reset</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">

            <div *ngIf="!isLoading" class="table table-responsive">
                <table id="tbl" datatable [dtOptions]="dtOptions" class="hover nowrap_th_td w-100">
                    <thead>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th [matTooltip]="'Fax Number'">Fax Number</th>
                            <th [matTooltip]="'Status'">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of FaxNumberdetails">
                            <td>
                                <ng-container>
                                    <a (click)="updatefaxNumber(data.id,data.faxNumber)"
                                        [ngClass]="{'disabled-link': data?.isActive===false}"
                                        matTooltip="Click To Edit">
                                        <i class="fa fa-pencil pointer editBtnColor mr-3"></i>
                                    </a>
                                </ng-container>
                            </td>
                            <td [matTooltip]="data.faxNumber || '-'">{{ data.faxNumber || '-' }}</td>
                            <td [matTooltip]="data?.isActive ? 'Active' : 'Inactive' || '-'">
                                <ng-container>
                                    <mat-slide-toggle (change)="statusChange($event, data)" [checked]="data?.isActive"
                                        class="ml-2">
                                        {{ data?.isActive ? 'Active' : 'Inactive' }}
                                    </mat-slide-toggle>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
            <!-- Loader Section -->
        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>

    <app-practice-fax-number-table [tableData]="FaxNumberdetails" [practiceNotesSesId]="practiceNotesId" *ngIf="!isLoading"></app-practice-fax-number-table>
    <!-- </div> -->
</div>

