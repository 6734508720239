import { Component, Inject, OnInit } from '@angular/core';
import { MMPayPalPaymentsService } from '../order-proxy/order-management/order-optimization/mmpay-pal-payments.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { HttpErrorResponse } from '@angular/common/http';
import { CreditCardModalComponent } from './credit-card-modal/credit-card-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-methods-modal',
  templateUrl: './payment-methods-modal.component.html',
  styleUrls: ['./payment-methods-modal.component.scss'],
})
export class PaymentMethodsModalComponent implements OnInit {
  paymentMethod = paymentMethod;
  selectedMethod: any = null;
  isOrderPageLoading: any;
  isProcessButtonEnabled: boolean = true;
  constructor(
    private paypalPaymentService: MMPayPalPaymentsService,
    private toaster: ToastrService,
    private matDialog: MatDialog,
    private Paypaldialog: MatDialogRef<PaymentMethodsModalComponent>,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId:string;orderId: string; orderData: any; claimId: string ,claimData:any} = {
      patientId:defaultGuid,
      orderId: defaultGuid,
      orderData: null,
      claimId: defaultGuid,
      claimData:[],
    }
  ) {}

  ngOnInit(): void {
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  //To select the payment method
  selectPaymentMethod(method: any) {
    this.selectedMethod = method;
  }

  //To process the Payment
  processPayment() {
    /* #region Need to UnComment after Demo */
    if (this.selectedMethod == paymentMethod.invoiceLink) {
      if (!this.isEmpty(this.data.orderId)) {
        this.isProcessButtonEnabled = false;
        this.loaderService.showLoader();

        this.paypalPaymentService
          .createPayPalInvoiceByOrderID(this.data.orderId)
          .subscribe(
            (response) => {
              this.Paypaldialog.close(true);
              this.loaderService.hideLoader();
              this.toaster.success('Payment Link Sent');

            },
            (err) => {

              this.loaderService.hideLoader();
              this.isProcessButtonEnabled = true;
              // this.toaster.error('Something Went Wrong!');
              let data = JSON.parse(err?.error);
              // this.toaster.error(data?.error?.message);
        Swal.fire({
          icon: 'info',
          text: data?.error?.message,
        });
            }
          );
      } else if (!this.isEmpty(this.data.claimId)) {
        this.isProcessButtonEnabled = false;
        this.loaderService.showLoader();

        this.paypalPaymentService
          .sendPatientRespInvoiceByClaimId(this.data.claimId)
          .subscribe(
            (response) => {
              this.isProcessButtonEnabled = true;
              this.Paypaldialog.close(true);
              this.loaderService.hideLoader();
              this.toaster.success('Payment Link Sent');
            },
            (err) => {
              this.isProcessButtonEnabled = true;
              this.loaderService.hideLoader();
              // this.toaster.error('Something Went Wrong!');
              let data = JSON.parse(err?.error);
              Swal.fire({
                icon: 'info',
                text: data?.error?.message,
              });
            }
          );
      } else {
      }
    } else if (this.selectedMethod == paymentMethod.creditOrDebit) {
      const dialogRef = this.matDialog.open(CreditCardModalComponent, {
        data: { patientId:this.data.patientId,orderId: this.data.orderId, orderData: this.data.orderData },
        width:'30vw',
        disableClose: true,
      });
      // Handle the dialog result if needed
      dialogRef.afterClosed().subscribe((result) => {});
    }

    /* #endregion */
  }
}
export enum paymentMethod {
  creditOrDebit = 0,
  invoiceLink = 1,
}
