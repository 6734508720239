<br />
<div class="card card-body">
  <div class="row">
    <div class="col-12 table-responsive" style="padding-top: 6px">
      <table class="table table-bordered">
        <thead>
          <th>Invoice Id</th>
          <th>Status</th>
          <th>DOS</th>
          <th>Payer</th>
          <th>Invoice Type</th>
          <th>Billed</th>
          <th>Paid</th>
          <th>Balance</th>
          <th>Claim Date</th>
        </thead>
        <tbody>
          <tr *ngFor="let record of tblInvoices; let i = index">
            <td>
              <a
                matTooltip="Click To Download"
                class="fa fa-file-pdf-o pointer editBtnColor pl-3"
                (click)="generatePDF(record?.id, 'download')"
              >
                {{ record?.invoiceCode }}
              </a>
            </td>
            <td>
              <!-- {{ record?.itemName }} -->
              --
            </td>
            <td>
              <!-- {{ record?.itemProcedureCode }} -->
              --
            </td>
            <td>
              <!-- $ {{ +record?.charge | number : ".2-2" }} -->
              --
            </td>
            <td>
              <div [ngSwitch]="record?.invoiceType">
                <div *ngSwitchCase="'primary'">Primary</div>
                <div *ngSwitchCase="'secondary'">Secondary</div>
                <div *ngSwitchCase="'tertiary'">Tertiary</div>
                <div *ngSwitchCase="'Patient_Response'">Patient Response</div>
                <div *ngSwitchDefault></div>
              </div>
            </td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
          </tr>
        </tbody>
        <tfoot>
          <ng-container
            *ngIf="tblInvoices && tblInvoices?.length === 0 && !isLoading"
          >
            <td class="text-align-center font-italic text-danger" colspan="12">
              Shipment Approval in Process
            </td>
          </ng-container>
          <ng-container *ngIf="isLoading">
            <td class="text-align-center" colspan="12">
              <mat-spinner [diameter]="220"></mat-spinner>
            </td>
          </ng-container>
        </tfoot>
      </table>
    </div>
  </div>
</div>
