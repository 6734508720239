import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { chequeClaimDTO, claimChecksDTO } from './dto';

@Injectable({
  providedIn: 'root',
})
export class EOBHistoryService {
  apiName = 'ClaimProcessingManagement';

  getChequeWiseClaimsByDtFromDateAndDtToDateAndSClaimNumberAndSCheckNumber = (dtFromDate: string, dtToDate: string, sClaimNumber: string, sCheckNumber: string) =>
    this.restService.request<any, PagedResultDto<chequeClaimDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/e-oBHistory/get-cheque-wise-claims',
      params: { dtFromDate, dtToDate, sClaimNumber, sCheckNumber },
    },
    { apiName: this.apiName });
  getChequeWiseClaimsByDtFromDateAndDtToDateAndSClaimNumberAndSCheckNumberV1 =
  (isDateRange:boolean,dtFromDate: string, dtToDate: string,sChartNo?:string,sPatientName?:string, sClaimNumber?: string, sCheckNumber?: string) =>
    this.restService.request<any, PagedResultDto<chequeClaimDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/e-oBHistory/get-cheque-wise-claims-v1',
      params: { isDateRange,dtFromDate, dtToDate,sChartNo,sPatientName, sClaimNumber, sCheckNumber },
    },
    { apiName: this.apiName });

  getClaimChecksBySCheckNumber = (sCheckNumber: string) =>
    this.restService.request<any, PagedResultDto<claimChecksDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/e-oBHistory/get-claim-checks',
      params: { sCheckNumber },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
