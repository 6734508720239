import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PatientVerificationService } from '../patient-proxy/patient-optimization/patient-verification.service';
import { PatientVerificationDTO } from '../patient-proxy/patient-optimization/dto';
import { crudPremission } from 'projects/shared/src/app/shared-table/shared-table.component';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-patient-eligibility-list-tab',
  templateUrl: './patient-eligibility-list-tab.component.html',
  styleUrls: ['./patient-eligibility-list-tab.component.scss']
})
export class PatientEligibilityListTabComponent implements OnInit {
  public strPageType: string = "eligiblityHistory";
  verifyTableData: PatientVerificationDTO[] = [];
  isLoading: boolean = false;
  arrDisplayedColumns = ['Options', "plan", "planName",'planType', "policy", "verificationStatus","eligibilityMode",'verifiedByUser','verifiedMethod','dateVerified', ];
  crudPermisson: crudPremission = { create: true, read: true, update: true, delete: false }

  subscription$: Subscription[] = [];
  patientId: string = defaultGuid;
  policyId: string = defaultGuid;
  constructor(public dialog: MatDialog,
    private sharedTableService: SharedTableService,
    private patientVerificationService: PatientVerificationService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string ,policyId: string,verificationStatus:string}
    = { patientId: defaultGuid,policyId: defaultGuid,verificationStatus:null }
  ) { }
  ngOnInit(): void {
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.policyId = this.data?.policyId ?? defaultGuid;
    this.patientId !== defaultGuid && this.getTableDataBasedOnPatientId(this.patientId);
  }
  getTableDataBasedOnPatientId(patientId: string) {

    this.isLoading = true;

    this.verifyTableData = [];
    this.sharedTableService.setTableData(this.verifyTableData);
    const getVerificationDetails = this.patientVerificationService.getVerificationByPatientIdpolicyIdByPatientIdAndPolicyId(patientId,this.policyId).subscribe(response => {
      this.isLoading = false;
      this.verifyTableData = response;
      // this.sharedTableService.setTableData(this.verifyTableData);
    }, (err) => {
      // this.sharedTableService.setTableData(this.verifyTableData);
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getVerificationDetails);
  }
  viewVerificationById(verification: PatientVerificationDTO): void {

    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: { patientId: this.patientId, verificationId: verification?.id, policyId: verification?.policyId,savehidden:false ,verificationStatus:this.data.verificationStatus}
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      this.getTableDataBasedOnPatientId(this.patientId);
      dialogRef?.close(result);
    });
    this.subscription$.push(closeDialog);
  }
  deleteVerificationById(verificationId: any): void {
    this.isLoading = true;
    this.patientVerificationService.get(verificationId).subscribe(resposne => {
      this.patientId !== defaultGuid && this.getTableDataBasedOnPatientId(this.patientId);
    }, (err) => {
      this.patientId !== defaultGuid && this.getTableDataBasedOnPatientId(this.patientId);
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  reloatTable(patientId:string){
    this.getTableDataBasedOnPatientId(patientId);
  }
}
