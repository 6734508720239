import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { GeneratePdfService } from 'projects/shared/src/app/services/generate-pdf.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { Subscription } from 'rxjs';
import { MmOrderService } from '../../order-proxy/order-management/order-optimization/mm-order.service';

@Component({
  selector: 'app-exchange-list-table',
  templateUrl: './exchange-list-table.component.html',
  styleUrls: ['./exchange-list-table.component.scss'],
})
export class ExchangeListTableComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @Input() ltExchangeOrders: any;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Output() nevigatePage? = new EventEmitter<number>();
  @Input() paginationInfo: {
    startRecord: number;
    endRecord: number;
    overallTableCount: number;
  };
  subscription$: Subscription[] = [];
  dtExchangeItemOptions: any;
  ngOnInit(): void {
    this.dtExchangeItemOptions = {
      responsive: true,
      dom: 'Bfrtip',
      search: {
        smart: false, // Set to "none" for full exact match
      },
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${
          this.paginationInfo?.endRecord ?? 0
        } of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      paging: false,
      scrollCollapse: true,
      scrollY: '60vh',
      scrollX: true,
      info: true,
      order: [],
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Exchange Orders List',
          className: 'custom-dt-export-button',
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          },
        },
      ],
      initComplete: function (this: any) {
        //Search Input Alignment
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }

        //Search Input Design and Icon
        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          searchContainer.className = 'search-container';
          searchFilterInput.style.paddingLeft = '25px';
          searchFilterInput.style.fontStyle = 'italic';
          searchFilterInput.style.fontSize = '12px';

          // Add event listeners for focusin and focusout
          searchFilterInput.addEventListener('focus', () => {
            searchFilterInput.style.border = '1px solid lightseagreen';
            searchFilterInput.style.outline = 'lightseagreen';
          });

          searchFilterInput.addEventListener('blur', () => {
            searchFilterInput.style.border = '';
            searchFilterInput.style.outline = '';
          });

          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);

          const searchIcon = document.createElement('i');
          searchIcon.className = 'fa fa-search';
          searchIcon.style.position = 'absolute';
          searchIcon.style.left = '10px';
          searchIcon.style.top = '16px';
          searchIcon.style.transform = 'translateY(-50%)';
          searchIcon.style.pointerEvents = 'none';
          searchIcon.style.color = '#aaa';

          searchContainer.appendChild(searchIcon);
        }
      },
    };

    this.dtExchangeItemOptions.buttons[0].filename =
      'Exchange Orders List' +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid

    );
  }
  constructor(
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private loaderService: LoaderService,
    public generatePdfService: GeneratePdfService,
    private mmOrderService: MmOrderService
  ) {}
  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  getExchangeItemsList(
    originalOrderId: string,
    newOrderId: string,
    originalOrderNo: string,
    newOrderNo: string,
    isPickup: boolean
  ) {
    let orderIds = {
      originalOrderId: originalOrderId,
      originalOrderNo: originalOrderNo,
      newOrderId: newOrderId,
      newOrderNo: newOrderNo,
      isPickup: isPickup,
    };
    this.communicationService.triggerExchangeItemsList(orderIds);
  }
  //US Format Date and Time
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();

      var index = 0;
      dtInstance.columns().every(function () {
        const that = this;

        index++;

        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  addPageNavigationControls() {
    let buttonsElement = $('.dt-buttons')[0];
    let totalPages = this.totalPages;
    let currentPage = this.currentPage;

    // $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button dt-custom-pagination-buttons disabled' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button dt-custom-pagination-buttons' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button dt-custom-pagination-buttons' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }
    // style='float:left !important;'
    $("<span class='dt-pagination-input-span' > Page <span>").appendTo(
      buttonsElement
    );
    $(
      "<input class='dt-pagination-input' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text'/>"
    ).appendTo(buttonsElement);

    ////Input Restrictions on pagination text box
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      //Invalid page number
      if (
        (value == '' ? currentPage : value) > totalPages ||
        (value == '' ? currentPage : value) == currentPage
      ) {
        $('#btnGoToPage').addClass('disabled');
      } else if (value != currentPage && value <= totalPages) {
        $('#btnGoToPage').removeClass('disabled');
      }
      (this as HTMLInputElement).value = value;
    });
    //If the user leaves empty text box patch current page
    $('#txtGotoPage').on('focusout', function (e) {
      let value = (this as HTMLInputElement).value;
      if (value == '') {
        (this as HTMLInputElement).value = currentPage;
      }
    });

    $(
      "<span class='dt-pagination-input-span pl-1'> of " +
        this.totalPages +
        ' <span>'
    ).appendTo(buttonsElement);
    $(
      "<button  class='dt-button dt-custom-pagination-buttons-goto disabled' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button dt-custom-pagination-buttons disabled' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button dt-custom-pagination-buttons' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button dt-custom-pagination-buttons' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) =>
      this.nevigatePage.emit(this.totalPages)
    );

    // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
  }
  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }
  downloadTicket(orderId: string, patientId: string) {
    const pickupDetails = this.mmOrderService
      .getPrintPickupOrderByGOrderIdAndLtItemIdAndGPatientId(
        orderId,
        [],
        patientId
      )
      .subscribe(
        (response) => {
          // inputParams.sBase64File =
          this.generatePdfService
            .downloadPickupTicketPdf(response)
            .then(() => {});
        },
        (err) => {}
      );
  }
}
