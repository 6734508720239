import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AddReminderComponent } from 'projects/admin/src/app/add-reminder/add-reminder.component';
import Swal from 'sweetalert2';
import { ReminderService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/remainder/reminder.service';
import { CommunicationService } from '../../services/communication.service';
import { DataTableDirective } from 'angular-datatables';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patient-reminder-v1',
  templateUrl: './patient-reminder-v1.component.html',
  styleUrls: ['./patient-reminder-v1.component.scss']
})
export class PatientReminderV1Component implements OnInit {

  // dtReminderTableOptionsv2: any = {
  //   responsive: true,
  //   paging: false,
  //   scrollCollapse: true,
  //   scrollY: '300px',
  //   scrollX: true,
  //   info: false
  //   // dom: 'Bfrtip',
  //   // buttons: [
  //   //   {
  //   //     extend: 'colvis',
  //   //     columns: [3, 4, 5, 6]
  //   //   }
  //   // ]
  // };

  dtReminderTableOptionsv2: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    // fixedColumns: {
    //   leftColumns: 3, // Number of columns to fix on the left
    //   rightColumns: 1, // Number of columns to fix on the right
    // },
    columnDefs: [
      { targets: [0, 1,], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
      {
        targets: [3],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        filename: 'Reminders',
        text: 'Export',
        exportOptions: {
          columns: ':visible:not(:nth-child(1))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      }
      // {
      //   text: 'Show Default Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns(':not(:lt(3),:last-child)').visible(false);
      //   },
      // },
      // {
      //   text: 'Show All Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns().visible(true);
      //   },
      // },
    ],
  };
  @Input() tableData: any;
  @Input() selectedNode: any;
  @Input() lblDefaultPatientId: any;
  @Input() vDescription: string = '';
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  remindertbldata: any;
  selectedRowIndex: number;
  $subscription: any;
  constructor(
    private dialog: MatDialog,
    public matDialog: MatDialog,
    private toastr: ToastrService,
    private reminderService: ReminderService,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.remindertbldata = this.tableData;
    this.dtReminderTableOptionsv2.buttons[0].filename =
    'Reminders' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  // ngAfterViewInit(): void {
  //   // if (this.datatableElement && this.datatableElement.dtInstance) {
  //   //   this.refreshDataTable();
  //   // } else {
  //   //   setTimeout(() => this.refreshDataTable(), 500);
  //   // }
  //   setTimeout(() => {
  //     $('#tblReminder').DataTable().columns.adjust().draw();
  //   }, 200);
  // }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tblReminder').DataTable().columns.adjust().draw();
    }, 200);
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  // refreshDataTable(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     $('input', dtInstance.table(0).footer()).val('');
  //     dtInstance.search('').columns().search('').draw();
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }
  isPastDue(date: string): boolean {
    const currentDate = new Date();
    const dueDate = new Date(date);
    dueDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    return dueDate < currentDate;
  }
  //Open Description(Notes) Model
  openDescription(description: string) {
    this.vDescription = description;
  }
  setSelectedRow(index: number): void {
    this.selectedRowIndex = index;
  }

  //Update Remainder Model
  UpdateReminder(reminderid: string, status: boolean) {

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        noteType: 'patient',
        reminderid: reminderid,
        remindertitle: 'Update Reminder',
        status: status,
        selectedNode: this.selectedNode,
        lblDefaultPatientId: this.lblDefaultPatientId,
        isdisable: false,
      },
    };

    const dialogRef = this.dialog.open(AddReminderComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.communicationService.triggerGetListReminderPatient();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }

  ///Delete Reminder
  DeleteReminder(id: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.reminderService.enableDisableReminderById(id).subscribe(response => {

          this.toastr.success('Deleted Successfully', 'Success');
          // this.communicationService.triggerReminderList();
          this.communicationService.triggerGetListReminderPatient();
          // this.GetReminderFilterList();
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }


  ///Complete Reminder
  completeReminder(id: string) {
    Swal.fire({
      title: 'Are you sure you want to Complete Reminder?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Completed it!'
    }).then(result => {
      if (result.value) {
        this.reminderService.updateReminderStatus(id).subscribe(response => {
          this.toastr.success('Reminder Completed Successfully', 'Success');
          // this.GetReminderFilterList();
          // this.communicationService.triggerReminderList();
          this.communicationService.triggerGetListReminderPatient();
        }, (err) => {
          const data: HttpErrorResponse = err;
          const message = JSON.parse(data?.error);
          Swal.fire({
            icon: 'info',
            text: message?.error?.message,
          });
        });
      }
    });
  }

  //Copy Text When Double click On Row
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      : '-';
  }
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
    }
}
