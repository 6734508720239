<br />
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="text-left">
            <h4 class="customThemeClass"><b>View Patient Ledger</b></h4>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">


      <div class="row">

        <div class="col-10 content table-responsive">
          <div class="card card-body">
            <div class="d-flex justify-content-center" *ngIf="!isLoadedMain">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>

            <table *ngIf="isLoadedMain" datatable [dtOptions]="dtPLOptions" class="row-border hover">
              <thead>
                <tr>
                  <th>Options</th>
                  <th>Ticket Id</th>
                  <th>Supplies Date</th>
                  <th>Chart No</th>
                  <th>PT_Name</th>
                  <th>Insurance</th>
                  <th>Order Status</th>
                  <th>CSR</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of completedOrderDetails" [ngClass]="
                this.tblShpSelectedRowId === data.id
                  ? 'row-highlighted'
                  : ''
              " (click)="tblShpSelectedRow(data.id)">
                  <td>
                    <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                    <mat-menu class="custom-mat-menu" #menu="matMenu">
                      <button mat-menu-item (click)="addNotes()">Add Patient Notes</button>
                      <button mat-menu-item (click)="viewNotes()"> View Patient Notes</button>
                      <button mat-menu-item (click)="viewDocument()">View Document Image</button>
                    </mat-menu>

                  </td>
                  <td>{{ data?.defaultSaleOrderId || '-' }}</td>
                  <td>{{ data?.creationTime
                    ? (data?.creationTime | date : "MM/dd/yyyy" : "en_US")
                    : "-" }}</td>
                  <td>{{ data?.chartId || '-' }}</td>
                  <td>{{ data?.patientName || '-' }}</td>
                  <td>{{ data?.policyName || '-' }}</td>
                  <td>{{ data?.isCompleted ? 'Completed' : 'Pending' }}</td>
                  <td>{{ data?.csrName || '-' }}</td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-2">
          <div class="card card-body">
            <div class="col-12">
              <form [formGroup]="viewPatientledger ">
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>CSR</mat-label>
                    <input autocomplete="new-txtCsr" matInput maxlength="50" formControlName="txtCsr"
                      placeholder="Enter CSR" type="text" />

                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label>Phone</mat-label>
                    <input autocomplete="new-txtPhone" matInput maxlength="50" formControlName="txtPhone"
                      placeholder="Enter Phone" type="number" />

                  </mat-form-field>
                  <mat-form-field class="algin col-12">
                    <mat-label>Email</mat-label>
                    <input autocomplete="new-txtEmail" matInput maxlength="50" formControlName="txtEmail"
                      placeholder="Enter Email" type="text" />

                  </mat-form-field>
                  <mat-form-field class="algin col-12">
                    <mat-label>Next Call</mat-label>
                    <input autocomplete="new-txtNextCall" matInput maxlength="50" formControlName="txtNextCall"
                      placeholder="Enter Next Call" type="text" />

                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label>Billing</mat-label>
                    <textarea formControlName="txtBilling" rows="4" maxlength="5000" matInput></textarea>

                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label>Shipping</mat-label>
                    <textarea formControlName="txtShipping" rows="4" maxlength="5000" matInput></textarea>

                  </mat-form-field>


                </div>
              </form>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-12">
        <div class="card card-body">
          <div class="container-fluid">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button class="pl-3" (click)="openVerificationModal()" [value]="1">Primary
                Ins</mat-radio-button>
              <mat-radio-button class="pl-3" (click)="openVerificationModal()" [value]="2">Secondary
                Ins</mat-radio-button>
              <mat-radio-button class="pl-3" (click)="checkListModel()" [value]="3">CheckList</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div class="col-12">
      <div class="row">

        <div class="col-2">
          <div class="card elevated-div card-body p-0 mb-0">
            <div class="table-container">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="custom-th">Approval No</th>
                    <th class="custom-th">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of authTableData">
                    <td>{{ data.authId }}</td>
                    <td>{{ data.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div class="card card-body elevated-div">
            <div class="d-flex justify-content-center" *ngIf="!isloadItmTbl">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
            <table *ngIf="isloadItmTbl" datatable [dtOptions]="dtPLOptions" class="row-border hover">
              <thead>
                <tr>
                  <th>Prod Code</th>
                  <th>Description</th>
                  <th>Size</th>
                  <th>Qty</th>
                  <th>Back Order Since</th>
                  <th>Serial/Lot No</th>
                  <th>Tracking</th>
                  <th>Exch</th>
                  <th>Shipping Date</th>
                  <th>Packed</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of lastCompletedITemDetails" (click)="tblItemSelectedRow(data.id)">
                  <td>{{ data?.itemProcedureCode || '-' }}</td>
                  <td>{{ data?.description || '-' }}</td>
                  <td>{{ data?.pages || '-' }}</td>
                  <td>{{ data?.orderingQuantity || '-' }}</td>
                  <td>{{ data?.primaryIns || '-' }}</td>
                  <td>{{ data?.csr }}</td>
                  <td>{{ data?.csr }}</td>
                  <td>{{ data?.csr }}</td>
                  <td>{{ data?.csr }}</td>
                  <td>{{ data?.csr }}</td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-2">
          <div class="card elevated-div card-body p-0 mb-0" style="text-align: center;">
            <div *ngIf="!isloadHCPCTbl">
              <img class="img-responsive hcpc-image-style" src="assets/gifs/Infinity-loader.gif" />
            </div>

            <table *ngIf="isloadHCPCTbl" class="table table-bordered mb-0">
              <thead>
                <tr>
                  <th class="custom-th">HCPC</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of hcpcTableData">
                  <td>{{ data.code }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
