<mat-dialog-content class="mat-typography">
  <div class="row">
    <div>
      <h2 class="customThemeClass mr-4 ml-3">Add Workings Hours And Area</h2>
    </div>
  </div>
  <form [formGroup]="AddWorkForm">
    <table>
      <!-- sunday -->

      <!-- <div class="col-11 mr-2" style="display: flex;">
              SUN : <mat-slide-toggle (click)="sundayStatus(AddWorkForm.status)" formControlName="acceptTerms">
                {{sunday}}</mat-slide-toggle>
            </div> -->
      <div>
        <tr>
          <td>
            <div class="row">
              <div class="col-12 mr-2" style="display: flex;">
                SUN <div class="ml-2">:</div>
                <mat-slide-toggle class="ml-5" (click)="sundayStatus(AddWorkForm.status)"
                  formControlName="AddSundayStatus">
                  {{sunday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of t.controls; let i = index; last as isLast">
            <span *ngIf="sundayLeaveHide" [formGroup]="ticket">
              <td>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" (selectionChange)="onChange($event.value)"
                      formControlName="drpWorkOptn">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>

                <mat-form-field class="col-6">
                  <mat-label>Start At</mat-label>
                  <mat-select maxlength="40" formControlName="drpStartAt">
                    <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-6">
                  <mat-label>Start End</mat-label>
                  <mat-select (selectionChange)="onEndsChanges(AddWorkForm?.get('sundayForm')?.value[i]?.drpStartAt,
                  AddWorkForm?.get('sundayForm')?.value[i]?.drpWorkOptn,
                  AddWorkForm?.get('sundayForm')?.value[i]?.drpStartEnd)" formControlName="drpStartEnd">
                    <mat-option *ngFor="let data of AddWorkForm?.get('sundayForm')?.value[i]?.drpStartAt?.endTimes"
                      [value]="data.endTime">
                      {{data.endTime}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-select formControlName="drpUser" placeholder="Users" ngModelOptions>
                      <mat-option *ngFor="let user of arrUsersList" [value]="user?.id + '/' + user?.userName">
                        {{user.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Sun More </b></span>

                  </div>

                </div>
              </td>

            </span>
          </span>


          
        </tr>
      </div>
      <!-- Monday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-12 mr-2" style="display: flex;">
                MON <div class="ml-1">:</div>
                <mat-slide-toggle class="ml-5" (click)="MondayStatus(AddWorkForm.status)"
                  formControlName="mondayWorkStatus">
                  {{Monday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of b.controls; let i = index; last as isLast">
            <span *ngIf="mondayLeaveHide" [formGroup]="ticket">
              <td>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" formControlName="drpMonWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>

              <td>
                <mat-form-field class="col-6">
                  <mat-label>Start At</mat-label>
                  <mat-select maxlength="40" formControlName="drpMonStartAt">
                    <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field class="col-6">
                  <mat-label>Start End</mat-label>
                  <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpMonStartEnd">
                    <mat-option *ngFor="let data of AddWorkForm?.get('mondayForm')?.value[i]?.drpMonStartAt?.endTimes"
                      [value]="data.endTimeShortCodeId">
                      {{data.endTime}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>

              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-select placeholder="Users" fomrcontrolName="drpMonUser" ngModelOptions>
                      <mat-option *ngFor="let user of arrUsersList" [value]="user?.id + '/' + user?.userName">
                        {{user.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Monday More </b></span>

                  </div>

                </div>
              </td>

            </span>
          </span>
        </tr>
      </div>
      <!-- Tuesday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                TUE <div class="ml-2 margin-right">:</div>
                <mat-slide-toggle class="ml-5" (click)="tuesdayStatus(AddWorkForm.status)"
                  formControlName="tuesdayWorkStatus">
                  {{Tuesday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of d.controls; let i = index; last as isLast">
            <span *ngIf="tuesdayLeaveHide" [formGroup]="ticket">
              <td>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" formControlName="drpTueWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>

                <mat-form-field class="col-6">
                  <mat-label>Start At</mat-label>
                  <mat-select maxlength="40" formControlName="drpTueStartAt">
                    <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field class="col-6">
                  <mat-label>Start End</mat-label>
                  <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpTueStartEnd">
                    <mat-option *ngFor="let data of AddWorkForm?.get('tuesForm')?.value[i]?.drpTueStartAt?.endTimes"
                      [value]="data.endTimeShortCodeId">
                      {{data.endTime}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-select placeholder="Users" fomrcontrolName="drpTueUser" ngModelOptions>
                      <mat-option *ngFor="let user of arrUsersList" [value]="user?.id + '/' + user?.userName">
                        {{user.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Monday More </b></span>

                  </div>

                </div>
              </td>

            </span>
          </span>
        </tr>
      </div>
      <!-- Wednesday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-12 mr-2 " style="display: flex;">
                WED <div class="ml-1 mar-right">:</div>
                <mat-slide-toggle class=" ml-5" (click)="wednesStatus(AddWorkForm.status)"
                  formControlName="wednesWorkStatus">
                  {{Wednesday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of h.controls; let i = index; last as isLast">
            <span *ngIf="wednesdayLeaveHide" [formGroup]="ticket">
              <td>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" formControlName="drpWedWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>

                <mat-form-field class="col-6">
                  <mat-label>Start At</mat-label>
                  <mat-select maxlength="40" formControlName="drpWedStartAt">
                    <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field class="col-6">
                  <mat-label>Start End</mat-label>
                  <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpWedStartEnd">
                    <mat-option *ngFor="let data of AddWorkForm?.get('wdnesdayForm')?.value[i]?.drpWedStartAt?.endTimes"
                      [value]="data.endTimeShortCodeId">
                      {{data.endTime}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-select placeholder="Users" fomrcontrolName="drpWedUser" ngModelOptions>
                      <mat-option *ngFor="let user of arrUsersList" [value]="user?.id + '/' + user?.userName">
                        {{user.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Monday More </b></span>

                  </div>

                </div>
              </td>

            </span>
          </span>
        </tr>
      </div>
      <!-- Thursday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                THU <div class="ml-1 ma-right">:</div>
                <mat-slide-toggle class="ml-5" (click)="thursStatus(AddWorkForm.status)"
                  formControlName="thursWorkStatus">
                  {{Thursday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of j.controls; let i = index; last as isLast">
            <span *ngIf="thursdayLeaveHide" [formGroup]="ticket">
              <td>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" formControlName="drpThursWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>

                <mat-form-field class="col-6">
                  <mat-label>Start At</mat-label>
                  <mat-select maxlength="40" formControlName="drpThursStartAt">
                    <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field class="col-6">
                  <mat-label>Start End</mat-label>
                  <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpThursStartEnd">
                    <mat-option
                      *ngFor="let data of AddWorkForm?.get('thursdayForm')?.value[i]?.drpThursStartAt?.endTimes"
                      [value]="data.endTimeShortCodeId">
                      {{data.endTime}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-select placeholder="Users" fomrcontrolName="drpThursUser" ngModelOptions>
                      <mat-option *ngFor="let user of arrUsersList" [value]="user?.id + '/' + user?.userName">
                        {{user.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Monday More </b></span>

                  </div>

                </div>
              </td>

            </span>
          </span>
        </tr>
      </div>
      <!-- Friday -->
      <div>
        <tr>
          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                FRI <div class="ml-1 m-r">:</div>
                <mat-slide-toggle class="ml-5" (click)="fridayStatus(AddWorkForm.status)"
                  formControlName="fridayWorkStatus">
                  {{Friday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of l.controls; let i = index; last as isLast">
            <span *ngIf="fridayLeaveHide" [formGroup]="ticket">
              <td>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" formControlName="drpFriWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>

                <mat-form-field class="col-6">
                  <mat-label>Start At</mat-label>
                  <mat-select maxlength="40" formControlName="drpFriStartAt">
                    <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field class="col-6">
                  <mat-label>Start End</mat-label>
                  <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpFriStartEnd">
                    <mat-option *ngFor="let data of AddWorkForm?.get('fridayForm')?.value[i]?.drpFriStartAt?.endTimes"
                      [value]="data.endTimeShortCodeId">
                      {{data.endTime}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-select placeholder="Users" fomrcontrolName="drpFriUser" ngModelOptions>
                      <mat-option *ngFor="let user of arrUsersList" [value]="user?.id + '/' + user?.userName">
                        {{user.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Monday More </b></span>

                  </div>

                </div>
              </td>
            </span>
          </span>
        </tr>
      </div>

      <!-- Saturday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                SAT <div class="ml-1 m-rt">:</div>
                <mat-slide-toggle class="ml-5" (click)="saturdayStatus(AddWorkForm.status)"
                  formControlName="saturdayWorkStatus">
                  {{Saturday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of n.controls; let i = index; last as isLast">
            <span *ngIf="saturdayLeaveHide" [formGroup]="ticket">
              <td>
                <div class="row">
                  <mat-form-field class="col-12">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" formControlName="drpSatWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>

                <mat-form-field class="col-6">
                  <mat-label>Start At</mat-label>
                  <mat-select maxlength="40" formControlName="drpSatStartAt">
                    <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field class="col-6">
                  <mat-label>Start End</mat-label>
                  <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpSatStartEnd">
                    <mat-option *ngFor="let data of AddWorkForm?.get('satdayForm')?.value[i]?.drpSatStartAt?.endTimes"
                      [value]="data.endTimeShortCodeId">
                      {{data.endTime}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-select placeholder="Users" fomrcontrolName="drpSatUser" ngModelOptions>
                      <mat-option *ngFor="let user of arrUsersList" [value]="user?.id + '/' + user?.userName">
                        {{user.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Saturday More </b></span>

                  </div>

                </div>
              </td>

            </span>
          </span>
        </tr>
      </div>

    </table>




  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" (click)="getWorkingSave()">Save</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>