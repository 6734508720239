<table
  id="tblCurrentSuppliesList"
  datatable
  [dtOptions]="dtOptions"
  class="tbl hover row-border hover w-100 display"
>
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Cell No'">Cell No</th>
      <th [matTooltip]="'DOS'">DOS</th>
      <th [matTooltip]="'Next Call Date'">Next Call Date</th>
      <!-- <th [matTooltip]="'Next Order Date'">Next Order Date</th> -->
      <th [matTooltip]="'Allowable Amount($)'" class="text-right">
        Allowable Amount($)
      </th>
      <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
      <th [matTooltip]="'CSR Name'">CSR Name</th>
      <th [matTooltip]="'Primary Verification'">Primary Verification</th>
      <th [matTooltip]="'Last Verified On'">Last Verified On</th>
      <!-- <th [matTooltip]="'Secondary Verification'">Secondary Verification</th> -->
      <th [matTooltip]="'Authorization'">Authorization</th>
      <th [matTooltip]="'Auth Expire Date'">Auth Expire Date</th>
      <th [matTooltip]="'Medicare'">Medicare</th>
      <!-- <th width="30%" [matTooltip]="'Compliance Status'">Compliance Status</th> -->
      <th [matTooltip]="'RX Validity Days'">RX Validity Days</th>
      <th [matTooltip]="'RX Mail Status'">RX Mail Status</th>
      <!-- <th [matTooltip]="'SMS Status'">SMS Status</th> -->
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of currentSuppliesData"
      (click)="tblItemSelectedRow(data)"
      [ngClass]="{
        'row-highlighted': patientId === data?.patientId
      }"
    >
      <td>
        <mat-icon
          style="cursor: pointer !important"
          [matTooltip]="'Open Options'"
          [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <!-- [routerLink]="
        '/orders/saleOrder/' +
        data?.patientId +
        '/' +
        defaultGuid +
        '/' +
        '0'
      " -->
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <a
            [routerLink]="
              '/orders/saleOrder/' +
              data?.patientId +
              '/' +
              data?.orderId +
              '/' +
              true +
              '/' +
              2 +
              '/' + true
            "
            target="_blank"
            class="preview-link"
            [ngClass]="{
              'disabled-link':
              (data?.authStatus !== 'Approved' && (data?.verificationStatus !== lstVerificationSttausV2.Done || data?.medicareStatus !== MedicareStatusV1.Approved)) || isEmpty(data.patientId) || data?.rXvalidityDays <= 0
            }"
          >
            <button mat-menu-item>Create Order</button>
          </a>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [disabled]="isEmpty(data.patientId)"
            (click)="openAddNotesPopUp()"
          >
            Add Notes
          </button>
          <button
            mat-menu-item
            (click)="openAddNotesPopUpV1($event, data, true)"
            [disabled]="isEmpty(data.patientId)"
          >
            Add Call Note
          </button>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [disabled]="isEmpty(data.patientId)"
            (click)="openViewNotesPopup()"
          >
            View Notes
          </button>
          <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
            View Patient Ledger
          </button>

          <a
            [routerLink]="'/view_document_tiff/' + data.documentId + '/' + true"
            target="_blank"
            class="preview-link"
            [class.disabled]="isEmpty(data.documentId)"
          >
            <button [disabled]="isEmpty(data.documentId)" mat-menu-item>
              View Document Image
            </button>
          </a>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="openLastVerification('Primary')"
            [disabled]="isEmpty(data?.primaryVerification)"
          >
            View Primary Insurance
          </button>

          <button
            mat-menu-item
            (click)="openLastVerification('Secondary')"
            [disabled]="isEmpty(data?.secVerification)"
          >
            View Secondary Insurance
          </button>

        <button mat-menu-item (click)="viewprecert(data)" [disabled]="isEmpty(data?.patientId) || data.authStatus === 'Yet to Start' ||
          data.authStatus === null ||
          data.authStatus === ''">
          View Pre-Cert
        </button>

          <a
            [routerLink]="
              '/view_checklist/' +
              data.patientId +
              '/' +
              true +
              '/' +
              data.medicareId
            "
            target="_blank"
            class="preview-link"
            [ngClass]="{
              'disabled-link':
                data.medicareId === null ||
                isRoleName !== 'admin' ||
                data.medicareStatus === MedicareStatusV1.New ||
                isEmptyStatus(data?.medicareStatus)
            }"
          >
            <button mat-menu-item>View Checklist</button>
          </a>

          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [disabled]="
              data?.verificationStatus === lstVerificationSttausV2.Done ||
              isEmptyStatus(data?.verificationStatus)
            "
            (click)="doVerfication(data)"
          >
            Do Verification
          </button>

          <button
            mat-menu-item
            [disabled]="
              data?.authStatus === 'Approved' || isEmptyStatus(data?.authStatus)
            "
            (click)="doprecert(data?.patientId)"
          >
            Pre-cert Request
          </button>

          <button
            mat-menu-item
            [disabled]="
              isEmpty(data?.patientId) ||
              data?.medicareStatus !== MedicareStatusV1.New
            "
            (click)="isDoChekList(data?.patientId)"
          >
            Medicare Request
          </button>

          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="rxMailSend(data?.patientId, data?.orderId, data?.chartId)"
          >
            RX Mail
          </button>
          <button (click)="openTextConversation()" mat-menu-item>
            Text Conversation
          </button>
          <button mat-menu-item>Patient Call</button>
          <mat-divider></mat-divider>
          <button mat-menu-item [matMenuTriggerFor]="supply">
            Supply Inactivation
          </button>
          <button mat-menu-item (click)="supplyInactive(3, data?.chartId,data?.nextCallDate)">
            Move to Archive Files
          </button>
        </mat-menu>
        <mat-menu #supply="matMenu">
          <ng-container>
            <button
              mat-menu-item
              value="Inactive Current Month"
              (click)="supplyInactive(1, data?.chartId,data?.nextCallDate)"
            >
              Inactive Current Month
            </button>
            <button
              mat-menu-item
              value="Inactive Entire Supply"
              (click)="supplyInactive(2, data?.chartId,data?.nextCallDate)"
            >
              Inactive Entire Supply
            </button>
          </ng-container>
        </mat-menu>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.chartId || '-')"
        [matTooltip]="data?.chartId || '-'"
      >
        <a
          [class.disabled]="isEmpty(data?.chartId) || isEmpty(data?.patientId)"
          [routerLink]="'/patientCreateEditTab/' + data?.patientId"
          target="_blank"
          class="preview-link"
          >{{ data?.chartId || "-" }}
        </a>
      </td>
      <td
        [matTooltip]="data?.patientname || '-'"
        (dblclick)="onRowDoubleClick(data?.patientname || '-')"
      >
        {{ data?.patientname || "-" }}
      </td>
      <td
        [matTooltip]="data?.cellNo || '-'"
        (dblclick)="onRowDoubleClick(data?.cellNo || '-')"
      >
        {{ data.cellNo || "-" }}
      </td>
      <td
        [matTooltip]="data?.dos || '-'"
        (dblclick)="onRowDoubleClick(data?.dos || '-')"
        [matTooltip]="data.dos"
      >
        {{ data.dos || "-" }}
      </td>
      <td
        [matTooltip]="data?.nextCallDate || '-'"
        (dblclick)="onRowDoubleClick(data?.nextCallDate || '-')"
        [matTooltip]="data.nextCallDate"
      >
        {{ data.nextCallDate || "-" }}
      </td>
      <!-- <td
        [matTooltip]="data?.nextOrderDate || '-'"
        (dblclick)="onRowDoubleClick(data?.nextOrderDate || '-')"
        [matTooltip]="data.nextOrderDate"
      >
        {{ data.nextOrderDate || "-" }}
      </td> -->
      <td
        [matTooltip]="data?.allowableAmt || '-'"
        (dblclick)="onRowDoubleClick(data?.allowableAmt || '-')"
        class="text-right"
      >
        {{ data.allowableAmt || "-" }}
      </td>
      <td
        [matTooltip]="data?.agingDays || '-'"
        (dblclick)="onRowDoubleClick(data?.agingDays || '-')"
        class="text-right"
      >
        {{ data.agingDays || "0" }}
      </td>
      <td [matTooltip]="data?.csrName || '-'" (dblclick)="onRowDoubleClick(data?.csrName || '-')">
        {{ data.csrName || "-" }}
      </td>
      <td
        [matTooltip]="data?.verificationStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.verificationStatus || '-')"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.New"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Done"
        >
          <span class="w-100 text-center p-1 insurance-type-label statusDone">
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.verificationStatus === lstVerificationSttausV2.Unabletoverify
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            Unable To Verify
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Cancelled"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.verificationStatus === lstVerificationSttausV2.Yettoverify
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Pending"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Expired"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <ng-container *ngIf="!data?.verificationStatus">
          <span>-</span>
        </ng-container>
      </td>
      <td
      [matTooltip]="data?.priVerExpireDate || '-'"
      (dblclick)="onRowDoubleClick(data?.priVerExpireDate || '-')"
      [matTooltip]="data.priVerExpireDate"
    >
      {{ data.priVerExpireDate || "-" }}
    </td>
      <!-- <td
        [matTooltip]="data?.secVerificationStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.secVerificationStatus || '-')"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.secVerificationStatus === lstVerificationSttausV2.Done"
        >
          <span class="w-100 text-center p-1 insurance-type-label statusDone">
            {{ data.secVerificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.secVerificationStatus ===
            lstVerificationSttausV2.Unabletoverify
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            Unable To Verify
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.secVerificationStatus === lstVerificationSttausV2.Cancelled
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.secVerificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.secVerificationStatus === lstVerificationSttausV2.Yettoverify
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.secVerificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.secVerificationStatus === lstVerificationSttausV2.New"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.secVerificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.secVerificationStatus === lstVerificationSttausV2.Pending
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.secVerificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.secVerificationStatus === lstVerificationSttausV2.Expired
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.secVerificationStatus || "-" }}
          </span>
        </div>
        <ng-container *ngIf="!data?.secVerificationStatus">
          <span>-</span>
        </ng-container>
      </td> -->

      <td (dblclick)="onRowDoubleClick(data?.authStatus || '-')">
        <ng-container [ngSwitch]="data?.authStatus">
          <div
            class="d-flex align-items-center justify-content-between"
            *ngSwitchCase="'Approved'"
            [matTooltip]="data?.authStatus || '-'"
          >
            <span class="w-100 text-center p-1 insurance-type-label statusDone">
              {{ data.authStatus || "-" }}
            </span>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngSwitchCase="'To be Expired'"
          >
            <span
              class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
            >
              <i
                class="fa fa-info-circle mr-2"
                [matTooltip]="'Expiry Date : ' + data?.expireDate || '-'"
              ></i>
              {{ data.authStatus || "-" }}
            </span>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngSwitchDefault
            [matTooltip]="data?.authStatus || '-'"
          >
            <span
              class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
              *ngIf="
                data?.authStatus !== 'Approved' &&
                data?.authStatus !== 'To be Expired' &&
                data?.authStatus !== '' &&
                data?.authStatus !== null
              "
            >
              {{ data.authStatus || "-" }}
            </span>
            <span *ngIf="!data?.authStatus">-</span>
          </div>
        </ng-container>
      </td>
      <td
      [matTooltip]="data?.expireDate || '-'"
      (dblclick)="onRowDoubleClick(data?.expireDate || '-')"
      [matTooltip]="data.expireDate"
    >
      {{ data.expireDate || "-" }}
    </td>
      <td
        [matTooltip]="data?.medicareStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.medicareStatus || '-')"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === MedicareStatusV1.New"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === MedicareStatusV1.Approved"
        >
          <span class="w-100 text-center p-1 insurance-type-label statusDone">
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === MedicareStatusV1.Disapproved"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === MedicareStatusV1.Submitted"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === MedicareStatusV1.Pending"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <ng-container *ngIf="!data?.medicareStatus">
          <span>-</span>
        </ng-container>
      </td>
      <!-- <td
        [matTooltip]="data?.compianceStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.compianceStatus || '-')"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.compianceStatus === 'Complaincemet'"
        >
          <span class="w-100 text-center p-1 insurance-type-label statusDone">
            <i class="fa fa-info-circle mr-2"></i>Complaince met
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.compianceStatus === 'Complaincenotmet'"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            <i class="fa fa-info-circle mr-2"></i>Complaince Not met
          </span>
        </div>

        <ng-container *ngIf="!data?.compianceStatus">
          <span>-</span>
        </ng-container>
      </td> -->
      <td
        [matTooltip]="data?.rXvalidityDays + ' days' || '-'"
        (dblclick)="onRowDoubleClick(data?.rXvalidityDays + '  days' || '-')"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.rXvalidityDays <= 0; else validDays"
        >
          <span
            class="text-center p-1 insurance-type-label rxValidityDays statusYetToVerify"
          >
            {{ data?.rXvalidityDays + "  days" || "-" }}
          </span>
        </div>

        <ng-template #validDays>
          <div class="d-flex align-items-center justify-content-between">
            <span
              class="text-center p-1 insurance-type-label statusDone rxValidityDays"
            >
              {{ data?.rXvalidityDays + "  days" || "-" }}
            </span>
          </div>
        </ng-template>
      </td>
      <td
        [matTooltip]="data?.rXmailStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.rXmailStatus || '-')"
      >
        {{
          data.rXmailStatus == null || data.rXmailStatus == ""
            ? "-"
            : data.rXmailStatus
        }}
      </td>
      <!-- <td
        [matTooltip]="data?.rxSmsStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.rxSmsStatus || '-')"
      >
        {{
          data.rxSmsStatus == null || data.rxSmsStatus == ""
            ? "-"
            : data.rxSmsStatus
        }}
      </td> -->
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <mat-icon>apps</mat-icon>
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart Id"
          name="search-chartId"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
          name="search-patientName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Cell No"
          name="search-cellNo"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
          name="search-dos"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Next Call Date"
          name="search-nextCallDate"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Next Order Date"
          name="search-nextOrderDate"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Allowable Amount"
          name="search-allowableAmount"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Aging Days"
          name="search-agingDays"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="CSR Name"
          name="search-CSRName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Primary Verification"
          name="search-primaryverification"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Last Verified On"
          name="search-primaryExpireDate"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Secondary Verification"
          name="search-secondaryverification"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Authorization"
          name="search-authorization"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Auth Expire Date"
          name="search-authExpireDate"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Medicare"
          name="search-medicare"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Compliance Status"
          name="search-complianceStatus"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="RX Validity Days"
          name="search-rxValidityDays"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="RX Mail Status"
          name="search-rxMailStatus"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="SMS Status"
          name="search-smsStatus"
        />
      </th> -->
    </tr>
  </tfoot>
</table>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img
        class="img-responsive pb-2 mr-1"
        src="assets/gifs/Infinity-loader.gif"
      />
    </div>
  </div>
</div>
