import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PracticeManagementService } from '../patient-proxy/practice-management';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-practice-management-view-patient-list',
  templateUrl: './practice-management-view-patient-list.component.html',
  styleUrls: ['./practice-management-view-patient-list.component.scss']
})
export class PracticeManagementViewPatientListComponent implements OnInit {

  isLoading: boolean;
  patinetListDate: any;
  $subscription: Subscription[] = [];
  tableData$: Subscription;

  practiceIdv1:string;
  selectedMonthv3:number;
  selectedYearv3:number

  constructor(
    private practiceManagementService: PracticeManagementService,

    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      practiceId: string;
      selectedMonthv2: number;
      selectedYearv2: number;
    } = {
        practiceId: defaultGuid,
        selectedMonthv2: null,
        selectedYearv2: null,
      }
  ) { }

  ngOnInit(): void {

    if ((this.data?.practiceId ?? defaultGuid) !== defaultGuid) {
      this.practiceIdv1 = this.data?.practiceId ?? defaultGuid;
      this.selectedMonthv3=this.data?.selectedMonthv2;
      this.selectedYearv3=this.data?.selectedYearv2;
    }

    if(this.data.practiceId){
    this.getPracticeListTableData(this.selectedMonthv3,this.selectedYearv3,this.practiceIdv1);
    }
  }

  ngOnDestroy(): void {
    if (this.$subscription) {
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.tableData$?.unsubscribe();
  }
  getPracticeListTableData(selectedMonth: number, selectedYear: number, practiceIdv1: string): void {
    this.tableData$?.unsubscribe();
    this.isLoading = true;
    const getPatientListTableData = this.practiceManagementService.patientWiseFaxReportByIMonthAndIYearAndPracticeId(selectedMonth, selectedYear, practiceIdv1).subscribe(
      (response) => {
        this.isLoading = false;
        this.patinetListDate = response;
      },
      (error) => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
        this.patinetListDate = [];
      }
    );
    this.tableData$ = getPatientListTableData;
  }


}
