<div class="container-fluid mt-3">

    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="font-weight-bolder mb-0">
                    <b class="header-class">Claim Dept / CC Settlement</b>
                </h3>

                <div class="page-title-box page-title-right">
                    <ol class="breadcrumbs m-0 align-items-center">
                        <form [formGroup]="settlementForm">

                            <button *ngIf="activeTab === CCSettlementTab.Pending" [disabled]="pendingSelectedCount >= 0"
                                mat-button class="buttonColor mr-3 ml-3"><mat-icon
                                    style="margin-right: 5px">check</mat-icon>
                                Check Settlement
                            </button>

                            <mat-form-field class="pr-3 dd-text"
                                [ngClass]="{'mat-form-field-invalid': settlementForm.get('txtStartDateValue').invalid || settlementForm.get('txtEndDateValue').invalid}">
                                <mat-label>Order Date Range<span class="asterisk">*</span></mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate matInput [(ngModel)]="dOrderFromDate" placeholder="Start date"
                                        formControlName="txtStartDateValue" (keydown)="addSlashToDate($event)"
                                        maxlength="10" />
                                    <input matEndDate matInput (dateChange)="onDateChange($event)"
                                        [(ngModel)]="dOrderToDate" placeholder="End date"
                                        formControlName="txtEndDateValue" (keydown)="addSlashToDate($event)"
                                        maxlength="10" />
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                                <mat-error *ngIf="settlementForm.get('txtStartDateValue').hasError('required')">Start
                                    date is
                                    required</mat-error>
                                <mat-error
                                    *ngIf="settlementForm.get('txtStartDateValue').invalid && !settlementForm.get('txtStartDateValue').hasError('required')">Invalid
                                    start date</mat-error>

                                <mat-error *ngIf="settlementForm.get('txtEndDateValue').hasError('required')">End date
                                    is
                                    required</mat-error>
                                <mat-error
                                    *ngIf="settlementForm.get('txtEndDateValue').invalid && !settlementForm.get('txtEndDateValue').hasError('required')">Invalid
                                    end date</mat-error>
                                <mat-error *ngIf="settlementForm.hasError('dateRangeError')">From date must be less than
                                    or equal to
                                    To date</mat-error>
                            </mat-form-field>
                        </form>

                    </ol>
                </div>

            </div>
        </div>
    </div>

    <div class="card mt-2">
        <div class="row">
            <div class="col-xl-12 pb-2">
                <ul class="card card-body" ngbNav #nav="ngbNav" justify="justified"
                    class="alert-success nav nav-pills nav-fill" style="border-bottom: 3px solid rgb(201, 201, 201)">
                    <li ngbNavItem="FaxProcess" [class.selected-tab]="nav.activeId === CCSettlementTab.Pending">
                        <a ngbNavLink (click)="changeTab(CCSettlementTab.Pending)"><b>Pending</b></a>
                    </li>
                    <li ngbNavItem="On-Demand" [class.selected-tab]="nav.activeId === CCSettlementTab.Completed">
                        <a ngbNavLink (click)="changeTab(CCSettlementTab.Completed)"><b>Completed</b></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table table-responsive" *ngIf="activeTab === CCSettlementTab.Pending">
                                <app-cc-settlement-tab-pending [DataList]="CCSettlementArray"
                                    *ngIf="!isLoadCCTableData"></app-cc-settlement-tab-pending>
                            </div>
                            <div class="table table-responsive" *ngIf="activeTab === CCSettlementTab.Completed">
                                <app-cc-settlement-tab-completed [DataList]="CCSettlementArray"
                                    *ngIf="!isLoadCCTableData"></app-cc-settlement-tab-completed>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="isLoadCCTableData">
                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>