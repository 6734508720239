<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <!-- <h3 class="font-weight-bolder my-2 d-flex align-items-center">
        Patient / Shipping Disapproved List
      </h3> -->
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass"
          >List / Shipping Dept / Shipping Disapproved List</b
        >
      </h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <!-- Row 1-->
      <div class="row">
        <div class="col-lg-10">
          <div class="card elevated-div">
            <!-- <div class="card-header">Shipping List</div> -->
            <div class="card-body pb-0 mb-1 mt-1">
              <div
                class="d-flex justify-content-center"
                *ngIf="!isShippingTableLoaded"
              >
                <!-- <mat-spinner></mat-spinner> -->
                <img
                  class="img-responsive pb-2 mr-1"
                  src="assets/gifs/Infinity-loader.gif"
                />
              </div>

              <div *ngIf="isShippingTableLoaded" class="table table-responsive">
                <app-shipping-disapproved-orders-table
                  [tableData]="ltShippingDisapproved"
                ></app-shipping-disapproved-orders-table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="card elevated-div card-body pt-4 pb-4">
            <form [formGroup]="shippingForm">
              <mat-form-field class="w-100">
                <mat-label>Patient Balance</mat-label>
                <input
                  readonly
                  matInput
                  maxlength="40"
                  formControlName="txtPatientBalance"
                  type="text"
                  [value]="patientBalanceandNotesDetails?.patientBalance"
                  placeholder="Patient Balance"
                  autocomplete="off"
                />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>INS Balance</mat-label>
                <input
                  matInput
                  readonly
                  maxlength="40"
                  [value]="patientBalanceandNotesDetails?.insBalance"
                  formControlName="txtINSBalance"
                  type="text"
                  placeholder="INS Balance"
                  autocomplete="off"
                />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Total Balance</mat-label>
                <input
                  matInput
                  readonly
                  maxlength="40"
                  [value]="patientBalanceandNotesDetails?.totalBalance"
                  formControlName="txtTotalBalance"
                  type="text"
                  placeholder="Total Balance"
                  autocomplete="off"
                />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Billing</mat-label>
                <textarea
                  readonly
                  [value]="patientBalanceandNotesDetails?.billingNotes"
                  formControlName="txtBillingNotes"
                  rows="5"
                  placeholder="Billing"
                  maxlength="5000"
                  matInput
                ></textarea>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Shipping</mat-label>
                <textarea
                  readonly
                  formControlName="txtShippingNotes"
                  rows="5"
                  [value]="patientBalanceandNotesDetails?.shippingNotes"
                  placeholder="Shipping"
                  maxlength="5000"
                  matInput
                ></textarea>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>

      <!-- Row 2 -->
      <div class="row mt-2">
        <div class="col-lg-2">
          <div class="card elevated-div card-body p-0 mb-3">
            <div class="table-container">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th [matTooltip]="'Approval No'" class="custom-th">
                      Approval No
                    </th>
                    <th [matTooltip]="'Amount'" class="custom-th">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of authTableData">
                    <td [matTooltip]="data.authId">
                      {{ data.approvalNo ? data.approvalNo : "-" }}
                    </td>
                    <td [matTooltip]="data.amount">{{ data.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-lg-9">
          <div class="card card-body elevated-div mb-3 pt-0 px-2 pb-1 mb-3">
            <div
              class="d-flex justify-content-center"
              *ngIf="!isItemTableLoaded"
            >
              <!-- <mat-spinner></mat-spinner> -->
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>

            <table
              datatable
              *ngIf="isItemTableLoaded"
              [dtOptions]="dtItemOptions"
              class="table table-sm row-border hover display"
            >
              <thead>
                <tr>
                  <th [matTooltip]="'Prod Code'">Prod Code</th>
                  <th [matTooltip]="'Description'">Description</th>
                  <th [matTooltip]="'Qty'" class="text-right">Qty</th>
                  <!-- <th>Exchange</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  [ngClass]="
                    this.tblItemSelectedRowId === data.productId
                      ? 'row-highlighted'
                      : ''
                  "
                  (click)="tblItemSelectedRow(data.productId, data.hcpcList)"
                  *ngFor="let data of itemTableData"
                >
                  <td [matTooltip]="data?.productCode || '-'">
                    {{ data.productCode }}
                  </td>
                  <td [matTooltip]="data?.productDescription || '-'">
                    {{ data.productDescription }}
                  </td>
                  <td [matTooltip]="data?.quantity || '-'" class="text-right">
                    {{ data.quantity }}
                  </td>
                  <!-- <td><mat-checkbox></mat-checkbox></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- HCPC Table -->
        <div class="col-lg-1">
          <div class="card elevated-div card-body p-0 mb-3">
            <div class="table-container">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th [matTooltip]="'HCPC'" class="custom-th">HCPC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of hcpcTableData">
                    <td [matTooltip]="data.hcpcCode">{{ data.hcpcCode }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
