import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MmOrderItemReturnService } from '../order-proxy/order-management/order-optimization/mm-order-item-return.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MmOrderReturnExchangeItemConditionService } from '../order-proxy/order-management/order-optimization/mm-order-return-exchange-item-condition.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { ToastrService } from 'ngx-toastr';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-return-order-list',
  templateUrl: './return-order-list.component.html',
  styleUrls: ['./return-order-list.component.scss'],
})
export class ReturnOrderListComponent implements OnInit {
  frmReturnList: FormGroup;
  ltItemReturnCondition: any[] = [];
  isReturnItemsLoaded: boolean = true;
  fromDate: any;
  toDate: any;
  loadReturnOrdersTable: boolean = true;
  returnOrderId: string;
  ltReturnOrders: any[] = [];
  ltReturnOrderItems: any[] = [];
  subscription$: Subscription[] = [];

  @ViewChild('returnItemDialog')
  returnItemDialogTemplate!: TemplateRef<any>;
  dtReturnOrderTableOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    search: {
      smart: false, // Set to "none" for full exact match
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '200px',
    scrollX: true,
    order: [],
    // info: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [],
  };
  dialogRef: MatDialogRef<any> | null = null;
  constructor(
    private datepipe: DatePipe,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private orderItemReturnService: MmOrderItemReturnService,
    private returnExchangeConditionService: MmOrderReturnExchangeItemConditionService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private title: Title
  ) {
    const comService =
      this.communicationService.loadReturnItemsMethodCall$.subscribe(
        (orderId) => {
          this.openReturnItemsDialog(orderId);
        }
      );
    this.subscription$.push(comService);
  }

  ngOnInit() {
    this.initializeForms();
    this.loadDropdowns();
    this.title.setTitle('Qsecure | Return Orders');
    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
    this.fromDate = this.datepipe.transform(firstDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(lastDate, 'MM/dd/yyyy');
    this.frmReturnList.patchValue({
      txtDateValue: firstDate,
      txtDate: lastDate,
    });
    this.getReturnOrdersList();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  loadDropdowns() {
    const returnCondition = this.returnExchangeConditionService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltItemReturnCondition = response?.items ?? [];
        },
        (err) => {}
      );

    this.subscription$.push(returnCondition);
  }
  //To initialize Forms
  initializeForms() {
    this.frmReturnList = this.fb.group({
      txtDateValue: new FormControl(),
      txtDate: new FormControl(),
    });
  }
  //DatePicker onChange Method
  openDatePicker() {
    const startDate = this.frmReturnList.get('txtDateValue').value;
    const endDate = this.frmReturnList.get('txtDate').value;
    this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    if (startDate != null && endDate != null) {
      this.getReturnOrdersList();
    }
  }
  //To get the Return Order List
  getReturnOrdersList() {
    this.loadReturnOrdersTable = false;

    const returnItemsList = this.orderItemReturnService
      .getReturnItemsListByDtFromDateAndDtToDate(this.fromDate, this.toDate)
      .subscribe(
        (response) => {
          this.ltReturnOrders = response ?? [];
          this.loadReturnOrdersTable = true;
        },
        (err) => {
          this.loadReturnOrdersTable = true;
        }
      );
    this.subscription$.push(returnItemsList);
  }
  // Function to get the first and last dates of the current month
  getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { firstDate, lastDate };
  }
  //To close the Dialog
  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  //Open Return Items List Dialog
  openReturnItemsDialog(orderId: string) {

    this.dialogRef = this.dialog.open(this.returnItemDialogTemplate, {
      disableClose: true,
    });
    const dialog = this.dialogRef.afterClosed().subscribe(() => {
      this.getReturnOrdersList();
      this.editableRowIndex = null;
    });
    this.subscription$.push(dialog);
    this.getReturnOrderItems(orderId);
  }

  //To get the Return Order Items
  getReturnOrderItems(orderId: string) {
    this.isReturnItemsLoaded = false;
    const returnOrderItems = this.orderItemReturnService
      .getReturnedItemsDetailsByIdByGOrderId(orderId)
      .subscribe(
        (response) => {
          this.ltReturnOrderItems = response;

          this.ltReturnOrderItems = this.ltReturnOrderItems?.map((item) => ({
            ...item,
            txtReceivedQty: item.recQuantity,
            drpCondition: item.itemConditionId,
          }));
          this.returnOrderId = response?.[0]?.mmRefOrderId.toString() ?? '';
          this.isReturnItemsLoaded = true;
        },
        (err) => {
          this.isReturnItemsLoaded = true;
        }
      );

    this.subscription$.push(returnOrderItems);
  }
  editableRowIndex: number | null = null;

  toggleEdit(rowIndex: number, data: any): void {
    if (this.editableRowIndex === rowIndex) {
      // Save changes
      this.updateStockDetails(data);
      this.editableRowIndex = null;
    } else {
      // Start editing
      this.editableRowIndex = rowIndex;
    }
  }
  //To update the Stock Details
  updateStockDetails(data: any) {
    data.recQuantity = Number(data.txtReceivedQty);
    data.itemConditionId = data.drpCondition;

    this.orderItemReturnService
      .updateReasonIncreaseStockByInput(data)
      .subscribe(
        (response) => {
          this.getReturnOrderItems(data?.orderId);
        },
        (err) => {
          this.toaster.error('Something Went Wrong!');
        }
      );
  }
  isSaveButtonEnabled(returnId: string): boolean {
    // Check both form validity and items list condition
    return this.ltReturnOrderItems
      .filter((a) => a.returnId === returnId)
      .every(
        (item) =>
          item.drpCondition === '' ||
          item.drpCondition === null ||
          item.drpCondition === undefined ||
          item.drpCondition === defaultGuid ||
          item.txtReceivedQty === 0 ||
          item.txtReceivedQty === '0' ||
          item.txtReceivedQty > item.balQuantity
      );
  }
}
