import { mapEnumToOptions } from '@abp/ng.core';

export enum WonaceOrderTab {
  Resupply = 1,
  Order = 2,
  Shipping = 3,
  Billing = 4,
}

export const wonaceOrderTabOptions = mapEnumToOptions(WonaceOrderTab);
