import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { RxPreviewDocumentComponent } from 'projects/order/src/app/rx-preview-document/rx-preview-document.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-patient-cnm-report-table',
  templateUrl: './patient-cnm-report-table.component.html',
  styleUrls: ['./patient-cnm-report-table.component.scss']
})
export class PatientCnmReportTableComponent implements OnInit {

  @Input() tableData:any;
  cnmreportTableData:any;
  dtCNMReportTableOptions:any;
  constructor(
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private toaster: ToastrService,
  ) { }

  ngOnInit() {
    this.cnmreportTableData = this.tableData;
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  
  previewRXDocument(){
    const dialogRef = this.dialog.open(RxPreviewDocumentComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        viewType: false,
        isRxView: true
      },
    });
  }
}
