<!-- Title -->
<div class="row align-items-center">
  <!-- <div class="col-lg-12"> -->

  <div class="col-md-10">

    <h2 class="customThemeClass">
      Send Mail
    </h2>


  </div>

  <!-- <div class="col-md-2" style="text-align: right">
    <mat-icon mat-dialog-close>close</mat-icon>
  </div> -->
  <div class="col-md-2">
    <button class="text-right close" aria-label="Close" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  

  
</div>

<!-- <mat-divider></mat-divider> -->
<!-- Title -->
<mat-dialog-content>
  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="card elevated-div"> -->
      <!-- <div class="card-header">Shipping List</div> -->
      <div class="card">
        <div class="card-body p-0 mb-1 mt-1">
          <!-- Notes Form -->
          <form [formGroup]="reportForm">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <!-- <mat-card-content> -->
                  <!--Row 1-->
                  <div class="row">

                    <!-- <div class="col-12"> -->
                    <mat-form-field class="col-6 dd-text">
                      <mat-label>Practitioner Name <span class="asterisk">*</span></mat-label>
                      <!-- <span class="asterisk">*</span> -->
                      <mat-select formControlName="drpPracticeManagement" #singleSelect
                        (selectionChange)="patchEmail($event)">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                            formControlName="txtPracticeFilter"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="option" *ngFor="let option of filteredPractices | async">
                          {{ option.practiceName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-6 dd-text">
                      <mat-label>Email<span class="asterisk">*</span></mat-label>
                      <input matInput pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                        [textMask]="{ mask: emailMask }" maxlength="40" type="text" formControlName="txtEmail" />
                      <mat-error class="spanAlignment" *ngIf="
                          reportForm?.get('txtEmail')?.touched &&
                          reportForm?.get('txtEmail')?.errors?.pattern
                          ">
                        Enter a valid email!
                      </mat-error>
                    </mat-form-field>


                    <!-- </mat-card-content> -->
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="col-12 text-center mt-1">
    <button *ngIf="!saveButtonHide" mat-button class="buttonColor mb-2 mr-2"
      [disabled]="isShowSpinner || !(!reportForm.invalid && reportForm.dirty) " (click)="rxReport()">
      Save
      <mat-icon *ngIf="isShowSpinner">
        <mat-spinner class="spinner-border  spinner-border-sm" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
    <button  [disabled]="isShowSpinner" mat-button (click)="reset()" class="resetclr mb-2 mr-2">Reset</button>
  </div>
</mat-dialog-actions>