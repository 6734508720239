<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between ">
                <h3 class="mb-0 font-size-18"><b>Denial Report</b></h3>
                <div class="page-title-box page-title-right  pt-2 pb-0">
                    <ol class="breadcrumb m-0">
                        <li class="ml-2 mt-2 breadcrumb-item">MIS Report</li>
                        <li class="mt-2 breadcrumb-item muted">Denial Report</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <div class="row">
            <form [formGroup]="denialForm">
                <div class="col-12">
                    <mat-radio-group class="rg" (change)="onChangeGetValue($event)" formControlName="rbtnReportType">
                        <mat-radio-button [checked]='true' value="Denied">Denial Report</mat-radio-button>
                        &nbsp;&nbsp;&nbsp;
                        <mat-radio-button value="ClaimProcessed">Claim Report</mat-radio-button>&nbsp;&nbsp;&nbsp;
                    </mat-radio-group>
                </div><br>

                <mat-form-field class="col-6">
                    <mat-label>From Date <span class="asterisk">*</span> </mat-label>
                    <input autocomplete="new-txtFromDate" maxlength="40" formControlName="txtFromDate" matInput [matDatepicker]="txtFromDate"
                        placeholder="(MM/DD/YYYY)" />
                    <mat-datepicker-toggle matSuffix [for]="txtFromDate"></mat-datepicker-toggle>
                    <mat-datepicker #txtFromDate></mat-datepicker>
                    <mat-error *ngIf="denialForm?.get('txtFromDate')?.touched &&
                  denialForm?.get('txtFromDate')?.errors">
                        Enter Valid Date!
                    </mat-error>
                    <mat-error
                        *ngIf="denialForm?.get('txtToDate')?.value >= denialForm?.get('txtFromDate')?.value 
                        &&!(denialForm?.get('txtFromDate')?.touched &&denialForm?.get('txtFromDate')?.errors?.required)">
                        Enter Future Dates Only!
                    </mat-error>
                    <mat-error *ngIf="denialForm?.get('txtFromDate')?.touched &&
                  denialForm?.get('txtFromDate')?.errors?.required">
                        From Date is a required field!
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-6 ">
                    <mat-label>To Date <span class="asterisk">* </span></mat-label>
                    <input autocomplete="new-txtToDate" [min]=" denialForm?.get('txtFromDate')?.value" maxlength="40" formControlName="txtToDate"
                        matInput [matDatepicker]="txtToDate" placeholder="(MM/DD/YYYY)" />
                    <mat-datepicker-toggle matSuffix [for]="txtToDate"></mat-datepicker-toggle>
                    <mat-datepicker #txtToDate></mat-datepicker>
                    <mat-error *ngIf="denialForm?.get('txtToDate')?.touched &&
                  denialForm?.get('txtToDate')?.errors">
                        Enter Valid Date!
                    </mat-error>
                    <mat-error *ngIf="denialForm?.get('txtToDate')?.value >= denialForm?.get('txtFromDate')?.value &&
                  !(denialForm?.get('txtToDate')?.touched &&
                    denialForm?.get('txtToDate')?.errors?.required)">
                        Enter Future Dates Only!
                    </mat-error>
                    <mat-error *ngIf="denialForm?.get('txtToDate')?.touched &&
                  denialForm?.get('txtToDate')?.errors?.required">
                        To Date is a required field!
                    </mat-error>
                </mat-form-field>
            </form>
            <div class="col-4 mt-4">
                <!-- <button mat-button class="mr-2 buttonColor" [disabled]="denialForm?.invalid"
                    (click)="getTableDataBasedOnDates()">
                    Submit
                </button> -->
                <button mat-button class="mr-2 buttonColor" (click)="denialYearReport()">
                    Denial Year Report
                </button>
                <button mat-button class="mr-2 buttonColor" (click)="exportDataToExcel()">
                    Export
                </button>
                <button mat-button class="resetclr" (click)="resetForm()">
                    Reset
                </button>
            </div>
        </div>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="patientId">
                <th class="header" mat-header-cell *matHeaderCellDef>
                    Patient ID
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.defaultPatientId }}
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th class="header" mat-header-cell *matHeaderCellDef>
                    Patient Name
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.patientName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="claimId">
                <th class="header" mat-header-cell *matHeaderCellDef>
                    Claim ID
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.claimId }}
                </td>
            </ng-container>

            <ng-container matColumnDef="NDate">
                <th class="header" mat-header-cell *matHeaderCellDef>Notification Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.dueDate | date: 'MM/dd/yyyy':'en_US' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="notification">
                <th mat-header-cell *matHeaderCellDef>
                    Notification Status
                </th>
                <td mat-cell *matCellDef="let data">
                    <ng-container>
                        {{ data?.notifyStatus }}
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="CreatedOn">
                <th mat-header-cell *matHeaderCellDef>
                    Created Date
                </th>
                <td mat-cell *matCellDef="let data">
                    <ng-container>
                        {{ data?.creationTime | date: 'MM/dd/yyyy':'en_US' }}
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                    <div *ngIf="!isLoading">
                        {{ "No Records Found" }}
                    </div>
                    <div [hidden]="!isLoading">
                        <mat-spinner [diameter]="220"></mat-spinner>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
                <td mat-footer-cell *matFooterCellDef colspan="12">
                    <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                        [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
                [ngClass]="{active: dataSource && dataSource?.data && dataSource?.data?.length !== 0}"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
    </div>
</div>