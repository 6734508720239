import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ClaimDTO } from './dto/models';

@Injectable({
  providedIn: 'root',
})
export class InsurancePaymentService {
  apiName = 'billingManagement';

  create = (input: any, id: string) =>
    this.restService.request<any, ClaimDTO>({
      method: 'POST',
      url: `/api/billingApp/insurance-payment/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (input: any, id: string) =>
    this.restService.request<any, ClaimDTO>({
      method: 'PUT',
      url: `/api/billingApp/insurance-payment/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updatePrimary = (input: any, id: string) =>
    this.restService.request<any, any>({
      method: 'PUT',
      url: `/api/billingApp/insurance-payment/${id}/primary`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateSecondary = (input: any, id: string) =>
    this.restService.request<any, any>({
      method: 'PUT',
      url: `/api/billingApp/insurance-payment/${id}/secondary`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateTertiary = (input: any, id: string) =>
    this.restService.request<any, any>({
      method: 'PUT',
      url: `/api/billingApp/insurance-payment/${id}/tertiary`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
