import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CreateUpdateMaskSizeDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { MaskSizeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mask-size.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-add-size',
  templateUrl: './add-size.component.html',
  styleUrls: ['./add-size.component.scss']
})
export class AddSizeComponent implements OnInit {

  sizeForm: FormGroup;
  constructor(
    public addSizeModelRef: MatDialogRef<AddSizeComponent>,
    private formBuilder: FormBuilder,
    private maskSizeService: MaskSizeService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.initializeForms();
    this.tenantId = localStorage.getItem('tenantId') ?? '';
  }
  //Initialize Forms
  initializeForms() {
    this.sizeForm = this.formBuilder.group({
      txtSize: new FormControl("",[Validators.required,noWhitespaceValidator])
    });
  }

  onCloseClick(): void {
    this.addSizeModelRef.close([]);
  }

  saveButtonHide: boolean = false;
  tenantId: string;

  saveSizedetails(){

    this.saveButtonHide = true
    let saveDetails: CreateUpdateMaskSizeDTO = {
      maskSizeId: 0,
      description: this.sizeForm.value.txtSize,
      ddOrder: 0,
      isActive: true,
      tenantId: this.tenantId

    }

      this.maskSizeService.create(saveDetails).subscribe(response => {
        this.toastr.success(' Saved Successfully', 'Success');
        this.addSizeModelRef.close(response);
        // this.SizeId = ''
        // this.saveButtonHide = false;
        // this.loadSizeList()
        // this.resetForm()
      },(err) => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        this.toastr.warning(data?.error?.error?.message, 'Warning');
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      })



  }

}
