<div class="row pb-2">
  <div class="col-lg-9 d-flex align-items-center">
    <h2 class="mb-2 header-text" mat-dialog-title>
      <B>Add Category</B>
    </h2>
  </div>
  <div class="col-lg-2 text-right">
    <button type="button" mat-icon-button class="close-button" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="card card-body">
    <div class="row">


      <form [formGroup]="productCategoryForm">
        <mat-form-field class="col-12 dd-text">
          <mat-label>Product Category Name<span class="asterisk">*</span></mat-label>
          <input autocomplete="off" matInput maxlength="50" formControlName="txtProductCategoryName"
            placeholder="Enter Product Category Name" type="text" />
          <mat-error *ngIf="
            productCategoryForm?.get('txtProductCategoryName')
              ?.touched &&
            productCategoryForm?.get('txtProductCategoryName')?.errors
              ?.required
          ">
            Product Category Name is a required field!
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" (click)="saveProductCategory()" [disabled]="
                !(
                  !productCategoryForm?.invalid && productCategoryForm?.dirty
                ) ||
                saveButtonDisabled
              ">
    Save
  </button>
  <button class="buttonCancelColor" mat-button (click)="onCloseClick()">Close</button>

  <!-- <button mat-button (click)="check()" >Check</button> -->
  <!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
</mat-dialog-actions>
