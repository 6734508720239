import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MasterNotesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { NoteType } from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { shippingTypeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-type.enum';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notes-reason',
  templateUrl: './notes-reason.component.html',
  styleUrls: ['./notes-reason.component.scss']
})
export class NotesReasonComponent implements OnInit {
  noteForm: FormGroup;
  saveButtonHide: boolean = false;
  isShowSpinner: boolean = false;
  // drpNotetype: any;
  listNoteType : any ;
  shipping = 'shipping'
  subscription$: Subscription[] = [];
  NoteTypeControl = new FormControl();
  drpDisable: boolean = false;
  constructor(private fb: FormBuilder,
    private shippingApprovalService: ShippingApprovalService,
    private toaster: ToastrService,
    public dialog: MatDialog,
    private mmOrderService: MmOrderService,
    private notesService: MasterNotesService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      notesId?: string;
      orderId: string;
      ticketId: string;
      notesType: string;
      type: string

    } = {
        patientId: defaultGuid,
        notesId: defaultGuid,
        orderId: defaultGuid,
        ticketId: '',
        notesType: 'shipping',
        type: ''
      },) { }

  ngOnInit(): void {

    this.noteForm = this.fb.group({
      drpNotetype: new FormControl((this.data.notesType===''?null:this.data.notesType), [Validators.required]),
      txtNoteType:new FormControl(''),
      // drpNoteReason: new FormControl('', [Validators.required]),
      txtCreatedBy: new FormControl('',),
      txtDateCreated: new FormControl(new Date()),
      txtActualDate: new FormControl(''),
      txtDateNeeded: new FormControl(''),
      drpSeverity: new FormControl(''),
      drpState: new FormControl(''),
      drpStatus: new FormControl(''),
      txtDateCompleted: new FormControl(''),
      // txtSubject: new FormControl(null, [Validators.required,noWhitespaceValidator]),
      txtDescription: new FormControl('', [Validators.required],),
      chkReminder: new FormControl(''),
    })
    this.noteForm.value.drpNotetype = this.data.notesType
    this.noteTypeDetails()
    // this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
    //   FilterText = FilterText.toLowerCase();
    //   this.drpNotetype = this.listNoteType.filter((dx) =>
    //     dx.noteType.toLowerCase().includes(FilterText)
    //   );
    // });
  }

  saveNotes(){
    if(this.data.type == 'shippingApproval'){
      this.approveDisapproveShipping(this.data.orderId,false,shippingTypeService.SHIPPINGAPPROVAL)

    } else if(this.data.type == 'shipping'){
      this.approveDisapproveShipping(this.data.orderId,false,shippingTypeService.SHIPPINGLIST)
    }
    else if(this.data.type == 'Order Related'){
      this.approveDisapproveApiCall(this.data.orderId,false,)
    }
  }

  resetNotes() {
    this.noteForm.reset({
      drpNotetype: this.data.notesType,
      txtDescription: ''
    });
  }
  noteTypeDetails(){
    const NoteType = this.notesService
    .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.listNoteType=response;
          // this.drpNotetype=this.listNoteType;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.subscription$.push(NoteType);
        }
      );
  }
  approveDisapproveShipping(orderId: string, status: boolean,type: any) {
    this.shippingApprovalService
      .updateShippingApprovalStatusByGOrderIdAndStatusAndType(
        orderId,
        status,
        type,
        this.noteForm.value.txtDescription,
      )
      .subscribe(
        (response) => {
          this.toaster.success('Shipping Disapproved!');
          this.dialog.closeAll();
        },
        (err) => {}
      );
  }

  approveDisapproveApiCall(orderId: string, status: boolean) {
    this.mmOrderService
      .updateOverrideStatusV1ByGOrderIdAndStatusAndSComments(
        orderId,
        status,
        this.noteForm.value.txtDescription
      )
      .subscribe(
        (response) => {
          status
            ? this.toaster.success('Override Approved!')
            : this.toaster.success('Disapproved!');

          this.communicationService.triggerOverriddenOrdersList();
          this.dialog.closeAll();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toaster.error(data?.error?.error?.message);
          this.communicationService.triggerOverriddenOrdersList();
          this.dialog.closeAll();
        }
      );
  }

}
