import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClaimProcessService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-patient-claims',
  templateUrl: './patient-claims.component.html',
  styleUrls: ['./patient-claims.component.scss'],
})
export class PatientClaimsComponent implements OnInit {
  @Input() patientId: string = ''; //To get patient Id from parent page
  isLoading: boolean = false; //Loader Variable
  patientClaimsData: any[] = []; //To get the Patient Claims
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort=new MatSort();
  public arrDisplayedColumns: string[] = [
    'claimNumber',
    'saleOrderId',
    'claimStatus',
    'waystarClaimStatus',
    'dateOfService',
    'claimProcessMethod',
    'claimProcessedDate',
  ];
  constructor(private claimProcessService: ClaimProcessService) {}

  ngOnInit(): void {
    (this.patientId ?? '') !== '' && this.getTabledata();
  }
  getTabledata() {
    this.isLoading = true;
    this.patientClaimsData = [];
    this.setTableData();
    this.claimProcessService.getClaimListbyPatientId(this.patientId).subscribe(
      (response) => {
        this.isLoading = false;
        this.patientClaimsData = response;
        this.setTableData();
      },
      (err) => {
        this.isLoading = false;
        this.setTableData();
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  setTableData() {
    this.dataSource = new MatTableDataSource(this.patientClaimsData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
 }
}
