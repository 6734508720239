<mat-card class="mr-3 mt-3">
  <mat-card-title class="customThemeClass">
    <div class="row">
      <div class="col-md-6">
        <h4 class="customThemeClass"><b>Policy List</b></h4>
      </div>
      <div class="col-md-6 text-sm-right">
        <div class="text-sm-right col-lg-12 p-0">
          <button mat-button [disabled]="!editFormValues" class="buttonColor mr-1 font-size-13 btn-sm"
            (click)="openPolicyModal()">
            <i class="fa fa-plus mr-1"></i> Add Policy </button>
        </div>
      </div>
    </div>
  </mat-card-title>
  <mat-divider class="bg mb-5"> </mat-divider>
  <mat-card-content>

    <!-- <div class="col-12 table-responsive">
      <app-shared-table [arrDisplayedColumns]="arrDisplayedColumns"  [isLoading]="isLoading" [strPageType]="strPageType"
        (viewClick)="ViewPolicy($event)" (deleteClick)="deletePolicy($event)"
        (statusChange)="setIsActiveStatusPolicy($event)">
      </app-shared-table>
    </div> -->
    <div class="row float-right mt-3" style="display: flex; justify-content: flex-end;">
      <mat-radio-group class="mr-3" [(ngModel)]="selectedOption" (change)="onRadioChange()">
        <mat-radio-button class="active" [value]='0'><b>All</b></mat-radio-button>
        <mat-radio-button class="active" [checked]="true" [value]='1'><b>Active</b></mat-radio-button>
        <mat-radio-button class="active" [value]='2'><b>Inactive</b></mat-radio-button>
      </mat-radio-group>
    </div>
    <br />
      <div class="col-lg-12 content table-responsive">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <!-- <mat-spinner></mat-spinner> -->
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <app-patient-insurance-tab-table [tableData]="policyDetails" [patientId]="patientId" [selectedOption]="selectedOption" [defaultFaxId]="defaultFaxId" [documentId]="documentId" *ngIf="!isLoading"></app-patient-insurance-tab-table>
        <!-- <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" *ngIf="!isLoading"
          class="hover">
          <thead>
            <tr>
              <th class="text-center">Option</th>
              <th class="text-center">Payor Level</th>
              <th class="text-center">Payer Code/Name</th>
              <th class="text-center">PolicyNo</th>
              <th class="text-center">Plantype</th>
              <th class="text-center">Eligibility Mode</th>
              <th class="text-center">Eligibility Type</th>
              <th class="text-center">Eligibility Status</th>
              <th class="text-center">Precert</th>
              <th class="text-center">Allowed %</th>
              <th class="text-center">Verified date</th>
              <th class="text-center">VerifiedBy</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of policyDetails" [ngClass]="{stat_highlight:data.isStat == true}">
              <td class="text-center">
                <a class="eyeCursorclass">
                  <i matTooltip="Click to Edit" (click)="EditPolicy(data)" target="_blank"
                    class="fa fa-pencil  editBtnColor"></i>
                </a>
                <a>
                  <i matTooltip="Click to View Verification" (click)="viewVerificationById(data)"
                    class="fa fa-eye pointer editBtnColor " style="padding-left: 25px;"></i>
                </a>
              </td>
              <td class="text-center">{{data?.payorLevel?data?.payorLevel:'-'}}</td>
              <td class="text-center"> {{data?.payorName?data?.payorName:'-'}}</td>
              <td class="text-center"> {{data?.policyNo?data?.policyNo:'-'}}</td>
              <td class="text-center">{{data?.typeofPlan?data?.typeofPlan:'-'}}</td>
              <td class="text-center"> {{ data?.eligibilityMode === 1 ? 'pverify' : (data?.eligibilityMode === 2 ?
                'Zermid' : 'change health care') }}</td>
              <td class="text-center"> {{data?.verificationMethod?data?.verificationMethod:'-'}}</td>
              <td class="text-center"> {{data?.isEligibilityDone?data?.isEligibilityDone:'-'}}</td>
              <td class="text-center"> {{data?.isPrecertRequired==1?'Yes':'No'}}</td>
              <td class="text-center"> {{data?.allowedPercentage?data.allowedPercentage:'-'}}</td>
              <td class="text-center">{{ data?.lastVerifiedDate | date:'MM/dd/yyyy HH:mm':'en_US' }}</td>
              <td class="text-center"> {{data?.lastVerificationName?data?.lastVerificationName:'-'}}</td>
              <td class="text-center">
                <ng-container *ngIf="data?.isActive===true">
                  <mat-slide-toggle (change)="StatusChange($event, data)" [checked]='true' class="ml-2">
                  </mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="data?.isActive===false">
                  <mat-slide-toggle (change)="StatusChange($event, data)" [checked]='false' class="ml-2">
                  </mat-slide-toggle>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>

  </mat-card-content>
</mat-card>
