<div class="notification-container" (clickOutside)="onClickOutside()">
    <button class="button buttonColor " type="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen">
        <span *ngIf="(unReadCount$ | async) > 0; then withBadge; else withOutBadge"></span>
        <ng-template #withBadge>
            <mat-icon *ngIf="swEnabled" matBadge="{{ unReadCount$ | async }}" matBadgePosition="above after"
                matBadgeColor="accent">
                notifications_active</mat-icon>
            <mat-icon *ngIf="!swEnabled" matBadgePosition="above after" matBadgeColor="accent">
                notifications_active</mat-icon>
        </ng-template>
        <ng-template #withOutBadge>
            <mat-icon *ngIf="swEnabled">notifications_none</mat-icon>
            <mat-icon *ngIf="!swEnabled">notifications_none</mat-icon>
        </ng-template>

    </button>
    <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
        <ng-container *ngIf="swEnabled; else elseTemplate">
            <div class="card">
                <div class="header">
                    <div class="row">
                        <div class="col-12 title">
                            <div class="name">Notifications</div>
                            <div class="extra">You have {{ unReadCount$ | async }} new notifications.</div>
                        </div>
                        <!-- <div class="col-2">
                            <button type="button" class="nocolor-button" mat-icon-button>
                                <mat-icon class="icon">settings</mat-icon>
                            </button>
                        </div> -->
                    </div>
                </div>
                <div *ngIf="( notifications$ | async)?.length!==0" class="message-card-body">

                    <ng-container
                        *ngFor="let notifications of notifications$ | async ; last as isLast; trackBy: trackById">
                        <ng-container *ngIf="notifications?.length !== 0; then thenBlock; else elseBlock">
                        </ng-container>
                        <ng-template #thenBlock>
                            <div class="row pl-2 pr-2">
                                <div class="col-12">
                                    <div class="notification" [ngClass]="notifications?.color"
                                    [class.unread]="!notifications?.isViewed"    [class.read]="notifications?.isViewed"  matRipple>
                                        <div class="row">
                                            <div class="col-2 align-self-center">
                                                <mat-icon class="icon">notifications</mat-icon>
                                            </div>
                                            <div class="col-8 align-self-center">
                                                <div class="title">
                                                    <div class="name pt-1">{{ notifications?.message }}</div>
                                                    <div class="time pt-2 pb-1">{{ notifications?.creationTime |
                                                        date: 'MM/dd/yyyy h:mm a':'en_US' }}</div>
                                                </div>
                                            </div>
                                            <div class="col-2  align-self-center">
                                                <button class="nocolor-button"
                                                    (click)="markAsRead(notifications)" type="button"
                                                    mat-icon-button>
                                                    <mat-icon class="close">close</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="divider" *ngIf="!isLast"></div>
                        </ng-template>
                        <ng-template #elseBlock>
                            <div class="empty">
                                All fine! No notifications right now.
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
                <!-- <div class="footer pt-2">
                    <div class="action" (click)="markAllAsRead()">Mark all as read</div>
                </div> -->
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="card">
                <div class="header">
                    <div class="row">
                        <div class="col-12 title">
                            <div class="name">Notifications</div>
                            <div class="extra">You have to enable browser notifications to receive notifications.</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>