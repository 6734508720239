import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import Swal from 'sweetalert2';
import { PatientStatementHistoryComponent } from '../../patient-statement-history/patient-statement-history.component';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-patient-statement-table',
  templateUrl: './patient-statement-table.component.html',
  styleUrls: ['./patient-statement-table.component.scss'],
})
export class PatientStatementTableComponent implements OnInit {
  @Input() patientStatementTable: any;
  defaultGuid = defaultGuid;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(private dialog: MatDialog, private datepipe: DatePipe) {}
  dtTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Patient Statement',
        exportOptions: {
          columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        },
      },
    ],
  };
  ngOnInit() {
    this.dtTableOptions.buttons[0].filename =
      'Patient Statement ' +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngAfterViewInit() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
      // dtInstance.columns().every(function () {
      //   const that = this;
      //   $('input', this.footer()).on('keyup change', function () {
      //     if (that.search('^(?!' + this['value'] + '$)', true)) {
      //       that.search(this['value']).draw();
      //     }
      //   });
      // });

      // // Attach the event listener for filtering
      // dtInstance.columns().every(function () {
      //   const that = this;
      //   $('input', this.footer()).on('keyup change', function () {
      //     const searchTerms = this['value'].toLowerCase().split(' ').filter(term => term);

      //     if (searchTerms.length === 0) {
      //       that.search('').draw();
      //     } else {
      //       $.fn.dataTable.ext.search.push((settings, data) => {
      //         return searchTerms.every(term => data.join(' ').toLowerCase().includes(term));
      //       });

      //       that.draw();
      //       $.fn.dataTable.ext.search.pop();
      //     }
      //   });
      // });

    });
  }
  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //To Open the Patient Statement History
  openPatientStatementHistory(patientId: string, chartId: string) {
    const dialogRef = this.dialog.open(PatientStatementHistoryComponent, {
      data: {
        patientId: patientId,
        chartNo: chartId,
      },
      width: '100%',
      disableClose: false,
    });
  }
}
