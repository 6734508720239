<mat-accordion>
    <mat-expansion-panel (toggle)="(step)"
    [expanded]="step"
    (afterExpand)="toggle()"
    (closed)="toggleV2()">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="
          mainStatusId === emptyguid;
              else elseTitleTemplate
            ">
            <b>Add Bin</b>
          </ng-container>
          <ng-template #elseTitleTemplate>
            <b>Edit Bin</b>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="AppontmentForm">
        <div class="row">
        <!-- <mat-form-field class="col-3">
          <mat-label>Sorted Id<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtSortedId" matInput maxlength="40" step="1" min="1"
            formControlName="txtSortedId" type="number" />
          <mat-error *ngIf="
            AppontmentForm?.get('txtSortedId')?.touched &&
            AppontmentForm?.get('txtSortedId')?.errors?.required
          ">
            Sorted Id is a required field!
          </mat-error>
        </mat-form-field> -->

        <mat-form-field class="col-4">
          <mat-label> Bin Name<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtStatusName" matInput maxlength="40" formControlName="txtStatusName" type="text"
          [readonly]="isFieldDisabled" />
          <mat-error *ngIf="
              AppontmentForm?.get('txtStatusName')?.touched &&
              AppontmentForm?.get('txtStatusName')?.errors?.required
            ">
            Bin Name is a required field!
          </mat-error>
        </mat-form-field>

      <div class="col-7"style="margin-left: 5%;">
        <!-- <div class="text-lg-right center"> -->
          <!-- *ngIf="isShownSaveButton" -->
          <button  [disabled]="saveButtonHide || AppontmentForm.invalid || issaving" mat-button
            class="buttonColor mr-2" (click)="saveStatus()">
            {{ mainStatusId === emptyguid ? 'Save' : 'Update' }}
            <mat-icon *ngIf="isShowSpinner">
              <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
              </mat-spinner>
            </mat-icon>
          </button>
          <button mat-button class="buttonColor mr-2 resetclr" (click)="reset()">
            Reset
          </button>
        </div>
      </div>
    </form>
    </mat-expansion-panel>
  </mat-accordion>
  <br>
  <div class="row">
    <div class="col-12">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
      <div class="table table-responsive mt-3" *ngIf="!isLoading">
        <table id="tblwipstatus" datatable [dtOptions]="dtOptionsWipstatus" class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th class="customWidth"><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'Status'">Status</th>
              <th [matTooltip]="'Bin'">Bin</th>
              <th [matTooltip]="'Created By'">Created By</th>
              <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
              <th [matTooltip]="'Modify By'">Modify By</th>
              <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of wipTableData" [ngClass]="{stat_highlight:data.isStat == true}">
              <td class="customWidth">
                <ng-container >
                  <a (click)="viewStatus(data.id,data.isDefault)"  *ngIf="data?.name !== 'admin'||data?.isDefault === 1" [ngClass]="{ 'disabled-link': data?.isDefault === 1 }"><i
                      class="fa fa-pencil pointer editBtnColor mr-3"  matTooltip="Click To View Role"></i>
                  </a>
                </ng-container>
              </td>

              <td>

                <ng-container *ngIf="data?.isAlreadyInUse==false">
                  <mat-slide-toggle (change)="statusChange($event, data)" [checked]="data?.isActive===1"
                  [disabled]="data?.isDefault === 1" class="ml-2">
                  <span [matTooltip]="data?.isActive ? 'Active' : 'Inactive' || '-'"> {{data?.isActive ? 'Active' : 'Inactive'}}</span>
                </mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="data?.isAlreadyInUse==true">
                <span [matTooltip]="'In Use'">In Use</span>
                </ng-container>
              </td>

              <!-- <td class="text-center">{{ data?.iSortOrder?data?.iSortOrder:'-'}}</td> -->
              <td [matTooltip]="data.inboundStatus || '-'"
              (dblclick)="onRowDoubleClickV1(data?.inboundStatus || '-')">
                {{ data.inboundStatus !== null && data.inboundStatus !== undefined ? data.inboundStatus : '-' }}
              </td>
              <td [matTooltip]="data.createdBy || '-'"
              (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">
                {{ data.createdBy !== null && data.createdBy !== undefined ? data.createdBy : '-' }}
              </td>
              <!-- <td class="text-center">{{ (data?.lastModificationTime ? (data.lastModificationTime | date:'MM/dd/yyyy HH:mm':'en_US') : '-') }}
              </td> -->
              <td class="text-right" [matTooltip]="data?.creationTime"
              (dblclick)="onRowDoubleClickV1(data?.creationTime)">{{ data?.creationTime }}
              </td>
              <td [matTooltip]="data.modifyBy || '-'"
              (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">
                {{ data.modifyBy !== null && data.modifyBy !== undefined ? data.modifyBy : '-' }}
              </td>
              <!-- <td class="text-center">{{ (data?.lastModificationTime ? (data.lastModificationTime | date:'MM/dd/yyyy HH:mm':'en_US') : '-') }}
              </td> -->
              <td class="text-right" [matTooltip]="data?.lastModificationTime"
              (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)">{{ data?.lastModificationTime }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
                <th class="customWidth"><mat-icon>apps</mat-icon></th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Status"
                      name="search-Status" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Bin"
                        name="search-Bin" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                      name="search-Created By" />
              </th>
                <th class="text-right">
                    <input matInput type="text" class="textbox-border-class " placeholder="Created Date"
                        name="search-Created Date" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                      name="search-Modify By" />
              </th>
                <th class="text-right">
                    <input matInput type="text" class="textbox-border-class " placeholder="Modified Date"
                        name="search-Modified Date" />
                </th>
            </tr>
        </tfoot>
        </table>
      </div>
    </div>
  </div>
