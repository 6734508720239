<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <p class="header-text">Patient Address List - {{ data.chartId }}</p>
      <div>
        <button type="button" (click)="addNewAddress()" mat-raised-button class="buttonColor mr-4">
          <mat-icon>add</mat-icon>New
        </button>
        <button type="button" mat-icon-button mat-dialog-close class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Page Content -->
  <div class="row mt-3">
    <div class="col-12">
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex justify-content-center" *ngIf="!isAddressTableLoaded">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>

          <div class="table table-responsive" *ngIf="isAddressTableLoaded">
           <app-patient-address-list-table [addressData]="data" [tableData]="addressList"></app-patient-address-list-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
