<div class="row align-items-center">
  <div class="col-lg-9">
    <h4 class="customThemeClass">
      <b>WIP- {{ data.defaultWipId }}</b>
    </h4>
  </div>

  <div class="col-lg-3" style="text-align-last: right">
    <!-- <button mat-icon-button mat-dialog-close class="close-button"> -->
    <mat-icon mat-dialog-close>close</mat-icon>
    <!-- </button> -->
  </div>
</div>

<div class="col-12 pb-5">
  <div class="table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      matTableExporter
      matSort
      class="pt-0 mt-0"
    >
      <ng-container matColumnDef="assignedToName" sticky>
        <th
          class="header mat-header-cell"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          Assigned To
        </th>
        <td class="mat-cell center-align" mat-cell *matCellDef="let element">
          {{ element?.assignedToName ? element?.assignedToName : "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assignedByName" sticky>
        <th
          class="header mat-header-cell"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          Assigned By
        </th>
        <td class="mat-cell center-align" mat-cell *matCellDef="let element">
          {{ element?.assignedByName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="wipProcessId" sticky>
        <th
          class="header mat-header-cell"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          ProcessId
        </th>
        <td class="mat-cell center-align" mat-cell *matCellDef="let element">
          {{ element?.wipProcessId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prevWIPStatus" sticky>
        <th
          class="header mat-header-cell"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          Previous Status
        </th>
        <td class="mat-cell center-align" mat-cell *matCellDef="let element">
          {{ element?.prevWIPStatus ? element?.prevWIPStatus : "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="currentWIPStatus" sticky>
        <th
          class="header mat-header-cell"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          Current Status
        </th>
        <td class="mat-cell center-align" mat-cell *matCellDef="let element">
          {{ element?.currentWIPStatus ? element?.currentWIPStatus : "-" }}

        </td>
      </ng-container>

      <ng-container matColumnDef="creationTime" sticky>
        <th
          class="header mat-header-cell"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          Creation Time
        </th>
        <td class="mat-cell center-align" mat-cell *matCellDef="let element">
          {{
            element?.creationTime
              ? (element?.creationTime | date : "MM/dd/yyyy hh:mm a" : "en_US")
              : "-"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="completedDate" sticky>
        <th
          class="header mat-header-cell"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          Completed Date
        </th>
        <td class="mat-cell center-align" mat-cell *matCellDef="let element">
          {{
            element?.completedDate
              ? (element?.completedDate | date : "MM/dd/yyyy" : "en_US")
              : "-"
          }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
    </table>
    <div
      *ngIf="
        !(dataSource && dataSource?.data && dataSource?.data?.length !== 0)
      "
    >
      <div
        class="message py-3 center-text"
        style="text-align: center"
        *ngIf="!isLoading"
      >
        {{ "No Records Found" }}
      </div>
      <div class="py-3 loader-container" [hidden]="!isLoading">
        <mat-spinner class="small-spinner"></mat-spinner>
      </div>
    </div>

    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
