import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-check-filter-switch',
  templateUrl: './check-filter-switch.component.html',
  styleUrls: ['./check-filter-switch.component.scss']
})
export class CheckFilterSwitchComponent implements OnInit {

  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }
  @Input() onText: string = "";
  @Input() offText: string = "";
  @Input() chkValue: boolean = false;
  ngOnInit(): void {
  }
  //! change event emit
  change($event: boolean | undefined) {
    this.changed.emit($event)
  }

}
