import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdatePverifyClaimDTO, CreateUpdatePverifyDTO, PverifyDTO } from '../patient/dto/models';

@Injectable({
  providedIn: 'root',
})
export class PverifyService {
  apiName = 'Default';

  getPverifyAllPayers = () =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/app/pVerify/getAllPayers',
    },
     { apiName: this.apiName, skipHandleError: true });

  getPverifyDetailsByInput = (input: CreateUpdatePverifyDTO) =>
    this.restService.request<any, PverifyDTO>({
      method: 'POST',
      url: '/api/app/patientInsurance/pVerify',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getVerifyClaimByInput = (input: CreateUpdatePverifyClaimDTO) =>
    this.restService.request<any, PverifyDTO>({
      method: 'POST',
      url: '/api/app/patientInsurance/getVerifyClaim',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
