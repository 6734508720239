import type { CreateUpdateSaleOrderItemDTO, ExchangeItemsDto, SaleOrderItemDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SaleOrderItemService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateSaleOrderItemDTO) =>
    this.restService.request<any, SaleOrderItemDTO>({
      method: 'POST',
      url: '/api/orderApp/sale-order-item',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/sale-order-item/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, SaleOrderItemDTO>({
      method: 'GET',
      url: `/api/orderApp/sale-order-item/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<SaleOrderItemDTO>>({
      method: 'GET',
      url: '/api/orderApp/sale-order-item',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateSaleOrderItemDTO) =>
    this.restService.request<any, SaleOrderItemDTO>({
      method: 'PUT',
      url: `/api/orderApp/sale-order-item/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getItemsListByOrderIdByOrderId = (orderId: string) =>
    this.restService.request<any, SaleOrderItemDTO[]>({
      method: 'GET',
      url: `/api/orderApp/sale-order-item/get-items-list-by-order-id/${orderId}`,
    },
    { apiName: this.apiName });

    getExchangeItemsByOrderId = (orderId: string) =>
    this.restService.request<any, ExchangeItemsDto[]>(
      {
        method: 'GET',
        url: `/api/orderApp/sale-order-item/exchange-itemsby-order-id/${orderId}`,
      },
      { apiName: this.apiName });

  getOrderedItemsByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/orderApp/sale-order-item/get-ordered-items-by-patient-id/${patientId}`,
    },
    { apiName: this.apiName });


    getOrderItemsByOrderId = (orderId: string) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/orderApp/sale-order-item/product-details-order-id/${orderId}`
    },
    { apiName: this.apiName });

    getHcpcCodesByProductId = (productId: string) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/orderApp/sale-order-item/hcpc-codes-by-product-id/${productId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}

