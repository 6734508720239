import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup, FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory/master-provider.service';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { ClinicalDTO } from 'projects/order/src/app/order-proxy/order-management/rcm/patient-management/patient/dto/models';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import {
  BillingDropdowns,
  DiagnosisCode10Service
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import {
  ActualManifestDTO,
  BillingMasterDropdownDTO,
  Block19DTO,
  ClaimTypeDTO,
  CurrentIllnessDTO,
  DelayReasonDTO,
  FirstTreatmentDTO,
  HrDTO,
  LastMenstrualDTO,
  LastWorkedDTO,
  LastXrayDTO,
  PatConditionCodeDTO,
  PcpDTO,
  ReturnToWorkDTO,
  ServiceAuthExceptionDTO,
  SimilarIllnessDTO,
  SpecialProgramDTO,
  StatusDTO,
  SupervisingProvDTO,
  XraysMaintainedDTO
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing/models';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { DiagnosisCode9Service } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/diagnosis-code9.service';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient/doctor.service';
import {
  PatientDTO,
  PersonalDTO
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  ClaimDTO,
  ClaimInfoDTO
} from '../billing-proxy/billing-management/posting/dto/models';
import {
  addBilling,
  getBilling,
  updateBilling
} from '../billing-store/billing.action';
import { claimProcessMethod } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-process-method.enum';
import { ToastrService } from "ngx-toastr";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-billing-claim-info',
  templateUrl: './billing-claim-info.component.html',
  styleUrls: ['./billing-claim-info.component.scss'],
  providers: [ListService],
})
export class BillingClaimInfoComponent implements OnInit {
  @Output() saveClaimInfoFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>(null);
  txtIcd10: string[] = [];
  public filteredIcd9Types: Observable<any[]>;
  public filteredIcd10Types: Observable<any[]>;
  txtIcd9: string[] = [];
  claimId: string = '';
  drpDoctor: doctorInterface[] = [];
  drpDiagnosisICD9: DiagnosisCode9DTO[];
  drpDiagnosisICD10: DiagnosisCode10DTO[];
  public drpClaimForm: FormGroup;
  public drpClaimTypes: ClaimTypeDTO[] = [];
  public drpSpecialPrograms: SpecialProgramDTO[] = [];
  public drpDelayReasons: DelayReasonDTO[] = [];
  public drpServiceAuthExceptions: ServiceAuthExceptionDTO[] = [];
  public drpStatuses: StatusDTO[] = [];
  public drpHrs: HrDTO[] = [];
  public drpCurrentIllnesses: CurrentIllnessDTO[] = [];
  public drpSimilarIllnesses: SimilarIllnessDTO[] = [];
  public drpLastMenstruals: LastMenstrualDTO[] = [];
  public drpFirstTreatments: FirstTreatmentDTO[] = [];
  public drpLastXrays: LastXrayDTO[] = [];
  public drpActualManifests: ActualManifestDTO[] = [];
  public drpXraysMaintained: XraysMaintainedDTO[] = [];
  public drpLastWorked: LastWorkedDTO[] = [];
  public drpReturnToWorks: ReturnToWorkDTO[] = [];
  public drpPatConditionCodes: PatConditionCodeDTO[] = [];
  public drpBlock19: Block19DTO[] = [];
  public drpPcps: PcpDTO[] = [];
  public drpPcpLastSeen: PcpDTO[] = [];
  public drpSupervisingProv: SupervisingProvDTO[] = [];
  claimForm: FormGroup;
  claiminfoId: string = '';
  matcher = new MyErrorStateMatcher();
  @Input() billingId: string = '';
  // @Input() orderId: string = '';
  @Input() orderId: string = '';

  @Input() patientId = '';
  organizationUnitId = null;
  tenantId: string = '';
  isIcd9Shown: boolean = false;
  isIcd10Shown: boolean = false;
  icd9Event: string = '';
  icd10Event1: string = '';
  icd10Event2: string = '';
  icd10Event3: string = '';
  @Input() claimOrderId: string = '00000000-0000-0000-0000-000000000000';
  BirthdateMinDate: Date;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  provider: string;
  drpProviders: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public store: Store,
    private doctorService: DoctorService,
    private Table: TableService,
    private titleService: Title,
    public list: ListService,
    private billingDropdownService: BillingMasterDropdownService,
    private activatedRoute: ActivatedRoute,
    private saleOrderService: SaleorderService,
    private diagnosisCode10Service: DiagnosisCode10Service,
    private diagnosisCode9Service: DiagnosisCode9Service,
    private providerList: MasterProviderService,
    private toastr: ToastrService,
  ) { }
  @Output() claimIdEmitter: EventEmitter<ClaimDTO> = new EventEmitter<ClaimDTO>(
    null
  );

  ngOnInit(): void {


    this.patientId = this.patientId ?? '';

    this.claimForm = this.formBuilder.group({
      drpReferringProvider: new FormControl(null, Validators.required),
      drpPcp: new FormControl(null),
      drpOrderingProvider: new FormControl(null, Validators.required),
      drpSupervisingProv: new FormControl(null),
      txtReferralDate: new FormControl(''),
      drpPcpLast: new FormControl(null),
      txtDiagnosisCodes9: new FormControl(''),
      txtdiagnosisCode10Id: new FormControl('', Validators.required),
      drpCurrent: new FormControl(null),
      drpSimilar: new FormControl(null),
      drpMenstrual: new FormControl(null),
      txtInjury: new FormControl(''),
      drpFirstTreatment: new FormControl(null),
      chkPregnant: new FormControl(''),
      txtConsultDate: new FormControl(''),
      drpLastXray: new FormControl(null),
      drpXrayMaintaine: new FormControl(null),
      txtEstimatedDob: new FormControl(''),
      drpDisabledFrom: new FormControl(null),
      drpDisabledTo: new FormControl(null),
      drpManifest: new FormControl(null),
      drpHospitalizedFrom: new FormControl(null),
      drpHospitalizedTo: new FormControl(null),
      drpLastWorked: new FormControl(null),
      drpReturnToWork: new FormControl(null),
      drpPatCondn: new FormControl(null),
      txtAuth: new FormControl(''),
      txtCondn1: new FormControl(''),
      txtBlock10d: new FormControl(''),
      txtCondn2: new FormControl(''),
      drpBlock19: new FormControl(null),
      chkAutoAccident: new FormControl(''),
      chkAutoAccidentState: new FormControl(''),
      chkOtherAccident: new FormControl(''),
      chkEmployment: new FormControl(''),
      // rbtnClaim: new FormControl(""),
      txtIcd10Filter: new FormControl(null),
      txtIcd9Filter: new FormControl(null),
      txtreferrindProviderFilter: new FormControl(''),
      txtorderingProviderFilter: new FormControl(''),
    });
    this.orderId = this.orderId ?? '';

    // this.Table.getOrderId().subscribe(val => {




    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    this.patientId = this.patientId ?? '';

    if (this.claimForm.valid || this.claimForm.dirty) {
      this.saveClaimInfoFormState.emit(this.claimForm);
    }

    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    this.getMasterDropdowns();
    this.icd10Dropdown();
    this.icd9Dropdown();
  }
  getDataByRouterId() {
    this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.orderId = response?.get('id') ?? '';
        if (this.orderId !== '') {
          this.saleOrderService.get(this.orderId).subscribe(
            (selectedOrder) => {
              const orderResp: SaleorderDTO = selectedOrder;
              this.patientId = orderResp?.patientId ?? '';
              this.claimId =
                orderResp?.claimId ?? '00000000-0000-0000-0000-000000000000';
              this.claimOrderId =
                this.claimOrderId ?? '00000000-0000-0000-0000-000000000000';
              const selectedPatientClinical: ClinicalDTO =
                orderResp?.patients?.clinicals;
              this.orderId = orderResp?.id;
              this.defaultSaleOrderId = orderResp?.saleorderId;
              this.patientId = orderResp?.patientId;
              const diagnosisCode9 = selectedPatientClinical?.diagnosisCode9;
              const diagnosisCode10 = selectedPatientClinical?.diagnosisCode10;
              this.claimForm.patchValue({
                drpReferringProvider:
                  selectedPatientClinical?.referringProviderDoctor === ''
                    ? null
                    : selectedPatientClinical?.referringProviderDoctor ?? '',
                drpOrderingProvider:
                  (orderResp?.patients?.personals?.providerId ?? '') === ''
                    ? null
                    : orderResp?.patients?.personals?.providerId ?? '',
                txtDiagnosisCodes9: diagnosisCode9 && diagnosisCode9?.length !== 0 ? diagnosisCode9 ?? [] : null,
                txtdiagnosisCode10Id: diagnosisCode10 && diagnosisCode10?.length !== 0 ? diagnosisCode10 ?? [] : null,
              });

              this.referrindProvider =
                selectedPatientClinical?.referringProviderDoctor ?? '';
              this.provider = selectedPatientClinical?.orderingDoctor ?? '';
              this.txtIcd9 = diagnosisCode9 ?? [];
              this.txtIcd10 = diagnosisCode10 ?? [];
              if (
                this.claimOrderId &&
                this.claimOrderId !== '00000000-0000-0000-0000-000000000000'
              ) {
                this.getClaimById();
                this.titleService.setTitle('Qsecure | View Billing');
              }
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({ icon: 'info', text: data?.error?.error?.message });
            }
          );

          if (
            this.orderId &&
            this.claimOrderId === '00000000-0000-0000-0000-000000000000'
          ) {
            // this.doctorDropdown();
            this.titleService.setTitle('Qsecure | Create Billing');
            this.patientId && this.getPatientData();
          }
        } else {
          if (this.claimOrderId === '00000000-0000-0000-0000-000000000000') {
            this.titleService.setTitle('Qsecure | Create Billing');
            // this.doctorDropdown();
            this.patientId && this.getPatientData();
          }
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  icd10Dropdown() {
    this.drpDiagnosisICD10 = [];
    const itemList = (query: any) => this.diagnosisCode10Service.getList(query);
    this.list.hookToQuery(itemList).subscribe(
      (response) => {
        response?.items?.forEach((element: DiagnosisCode10DTO) => {
          const resultString =
            element.diagnosisCode + ' ' + element.diagnosisCodeTypeData;
          this.drpDiagnosisICD10?.push({
            diagnosisCode: element?.diagnosisCode ?? '',
            diagnosisCode10ShortCodeId:
              element?.diagnosisCode10ShortCodeId ?? '',
            diagnosisCodeTypeData: resultString,
            diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId ?? '',
            id: element?.id ?? '',
            creationTime: element?.creationTime ?? '',
            creatorId: element?.creatorId ?? '',
            isActive: element?.isActive,
            isExist: element?.isExist,
            isBillable: element?.isBillable,
          });
        });
        this.filteredIcd10Types = this.claimForm
          .get('txtIcd10Filter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDiagnosisICD10.filter((option) =>
                option?.diagnosisCodeTypeData
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  icd9Dropdown() {
    this.drpDiagnosisICD9 = [];
    const itemList = (query: any) => this.diagnosisCode9Service.getList(query);
    this.list.hookToQuery(itemList).subscribe(
      (response) => {
        response?.items?.forEach((element: DiagnosisCode9DTO) => {
          const resultString =
            element?.diagnosisCode + ' ' + element?.diagnosisCodeTypeData;
          this.drpDiagnosisICD9.push({
            diagnosisCode: element.diagnosisCode ?? '',
            diagnosisCode9ShortCodeId: element.diagnosisCode9ShortCodeId ?? '',
            diagnosisCodeTypeData: resultString ?? '',
            diagnosisCodeTypeDataId: element.diagnosisCodeTypeDataId ?? '',
            id: element.id ?? '',
            creationTime: element.creationTime ?? '',
            creatorId: element.creatorId ?? '',
            isActive: element.isActive,
            isExist: element.isExist,
          });
        });
        this.filteredIcd9Types = this.claimForm
          .get('txtIcd9Filter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDiagnosisICD9.filter((option) =>
                option?.diagnosisCodeTypeData
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  // ! provider getlist
  getProvidersDropdown() {
    const providerList = (query: any) => this.providerList.getList(query);
    this.list.hookToQuery(providerList).subscribe((response) => {
      response?.items?.forEach((element) => {
        this.drpProviders.push({
          id: element.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi,
        });
      });
      this.getDataByRouterId();
    });
  }

  clearIcd9() {
    this.txtIcd9 = [];
    this.claimForm.patchValue({ txtDiagnosisCodes9: [] });
  }
  clearIcd10() {
    this.txtIcd10 = [];
    this.claimForm.patchValue({ txtdiagnosisCode10Id: [] });
  }
  patientName: string = '';
  patientDOB: string = '';
  defaultId: string = '';
  defaultSaleOrderId: string = '';
  getPatientData() {
    this.store
      .dispatch(new getPatient(this.patientId))
      .pipe(
        map((value: any) => {
          return value?.patientState?.selectedPatient;
        })
      )
      .subscribe(
        (response: PatientDTO) => {
          this.claimForm.patchValue({
            drpReferringProvider: response?.clinicals?.referringProviderDoctor,
            drpOrderingProvider: response?.personals?.providerId,
          });

          this.referrindProvider =
            response?.clinicals?.referringProviderDoctor ?? '';
          this.provider = response?.personals?.providerId ?? '';
          this.txtIcd9 = response?.clinicals?.diagnosisCode9 ?? [];
          this.txtIcd10 = response?.clinicals?.diagnosisCode10 ?? [];
          this.patientName =
            (response?.personals?.firstName ?? '') +
            ' ' +
            (response?.personals?.lastName ?? '');
          this.patientDOB = response?.personals?.dateOfBirth ?? '';
          this.defaultId = response?.defaultPatientId ?? '';
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  // !patch claim detatis
  getClaimById() {
    this.store.dispatch(new getBilling(this.claimOrderId)).subscribe(
      (response) => {
        const claimDetail: ClaimDTO = response?.billingState?.selectedBilling;
        this.orderId = claimDetail?.orderId;
        this.defaultSaleOrderId = claimDetail?.saleorderId;
        this.patientId = claimDetail?.patientId;

        const lstClaimInfo: ClaimInfoDTO[] = claimDetail?.lstClaimInfo ?? null;
        if (lstClaimInfo && lstClaimInfo[0]) {
          const element = lstClaimInfo[0];
          // claimDetail.forEach(element => {
          this.referrindProvider = element?.referringProvider ?? '';
          this.provider = element?.orderingProvider ?? '';
          this.txtIcd9 = element?.diagnosisCode9 ?? [];
          this.txtIcd10 = element?.diagnosisCode10 ?? [];

          this.claimForm.patchValue({
            drpReferringProvider: element?.referringProvider,
            drpPcp: element?.pcp,
            drpOrderingProvider: element?.orderingProvider,
            drpSupervisingProv: element?.supervisingProv,
            txtReferralDate: element?.referralDate,
            drpPcpLast: element?.pcpLastSeen,
            txtDiagnosisCodes9: element?.diagnosisCode9,
            txtdiagnosisCode10Id: element?.diagnosisCode10,

            drpCurrent: element?.currentIllness,
            drpSimilar: element?.similarIllness,
            drpMenstrual: element?.lastMenstrual,
            txtInjury: element?.injuryDate,
            drpFirstTreatment: element?.firstTreatment,
            chkPregnant: element?.isPregnant,
            txtConsultDate: element?.consultDate,
            drpLastXray: element?.lastXray,
            drpXrayMaintaine: element?.xraysMaintained,
            txtEstimatedDob: element?.estimatedDob,
            drpDisabledFrom: element?.disabledFrom,
            drpDisabledTo: element?.disabledTo,
            drpManifest: element?.acuteManifest,
            drpHospitalizedFrom: element?.hospitalizedFrom,
            drpHospitalizedTo: element?.hospitalizedTo,
            drpLastWorked: element?.lastWorked,
            drpReturnToWork: element?.returnToWork,
            drpPatCondn: element?.patConditionCode,
            txtAuth: element?.auth,
            txtCondn1: element?.patConditionCode1,
            txtBlock10d: element?.block10d,
            txtCondn2: element?.patConditionCode2,
            drpBlock19: element?.block19,
            chkAutoAccident: element?.isAutoAccident,
            chkAutoAccidentState: element?.isAutoAccidentState,
            chkOtherAccident: element?.isOtherAccident,
            chkEmployment: element?.isEmploymentRelated,
          });
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // })
  }
  // ! for save button enable disable

  onIcd9Change(event) {
    this.icd9Event = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd10Event1 == undefined ||
        this.icd10Event1 == null ||
        this.icd10Event1 == '') &&
      (this.icd10Event2 == undefined ||
        this.icd10Event2 == null ||
        this.icd10Event2 == '') &&
      (this.icd10Event3 == undefined ||
        this.icd10Event3 == null ||
        this.icd10Event3 == '')
    ) {
      this.isIcd9Shown = false;
      this.isIcd10Shown = false;
    } else {
      this.isIcd9Shown = true;
      this.isIcd10Shown = true;
    }
  }
  onIcd10Change1(event) {
    this.icd10Event1 = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd9Event == undefined ||
        this.icd9Event == null ||
        this.icd9Event == '') &&
      (this.icd10Event2 == undefined ||
        this.icd10Event2 == null ||
        this.icd10Event2 == '') &&
      (this.icd10Event3 == undefined ||
        this.icd10Event3 == null ||
        this.icd10Event3 == '')
    ) {
      // this.isIcd10Shown = false;
      // this.isIcd9Shown = false;
    } else {
      // this.isIcd10Shown = true;
      // this.isIcd9Shown = true;
    }
  }

  onIcd10Change2(event) {
    this.icd10Event2 = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd9Event == undefined ||
        this.icd9Event == null ||
        this.icd9Event == '') &&
      (this.icd10Event1 == undefined ||
        this.icd10Event1 == null ||
        this.icd10Event1 == '') &&
      (this.icd10Event3 == undefined ||
        this.icd10Event3 == null ||
        this.icd10Event3 == '')
    ) {
      // this.isIcd10Shown = false;
      // this.isIcd9Shown = false;
    } else {
      // this.isIcd10Shown = true;
      // this.isIcd9Shown = true;
    }
  }
  onIcd10Change3(event) {
    this.icd10Event3 = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd9Event == undefined || this.icd9Event == '') &&
      (this.icd10Event1 == undefined || this.icd10Event1 == '') &&
      (this.icd10Event2 == undefined || this.icd10Event2 == '')
    ) {
      // this.isIcd10Shown = false;
      // this.isIcd9Shown = false;
    } else {
      // this.isIcd10Shown = true;
      // this.isIcd9Shown = true;
    }
  }

  public filteredreferrindProvider: Observable<doctorInterface[]>;
  public filteredorderingProvider: Observable<doctorInterface[]>;
  // ! dropdown list
  getMasterDropdowns() {



    const itemList = (query: any) => this.doctorService.getList(query);
    this.list.hookToQuery(itemList).pipe(map(response => {
      response?.items?.forEach((element) => {
        this.drpDoctor.push({
          name:
            element.npiNumber +
            ' / ' +
            element?.firstName +
            ' ' +
            element?.middleName +
            ' ' +
            element?.lastName,
          id: element?.id,
        });

      });
      return this.drpDoctor
    })).subscribe(
      (response) => {
        this.filteredreferrindProvider = this.claimForm
          .get('txtreferrindProviderFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredorderingProvider = this.claimForm
          .get('txtorderingProviderFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.getProvidersDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    const billingDropdownInput: BillingDropdowns[] = [
      BillingDropdowns.ClaimTypes,
      BillingDropdowns.SpecialPrograms,
      BillingDropdowns.DelayReasons,
      BillingDropdowns.ServiceAuthExceptions,
      BillingDropdowns.Status,
      BillingDropdowns.Hrs,
      BillingDropdowns.CurrentIllness,
      BillingDropdowns.SimilarIllness,
      BillingDropdowns.LastMenstruals,
      BillingDropdowns.FirstTreatments,
      BillingDropdowns.LastXRays,
      BillingDropdowns.ActualManifest,
      BillingDropdowns.XraysMaintained,
      BillingDropdowns.LastWorked,
      BillingDropdowns.ReturnToWorks,
      BillingDropdowns.PatConditionCodes,
      BillingDropdowns.Block19,
      BillingDropdowns.Pcps,
      BillingDropdowns.SupervisingProvs,
    ];
    this.billingDropdownService
      .getBillingDropdownsByInput(billingDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: BillingMasterDropdownDTO = stateResponse;

          // this.drpClaimForm = response;
          this.drpClaimTypes = response?.claimTypes;
          this.drpSpecialPrograms = response?.specialPrograms;
          this.drpDelayReasons = response?.delayReasons;
          this.drpServiceAuthExceptions = response?.serviceAuthExceptions;
          this.drpStatuses = response?.statuses;
          this.drpHrs = response?.hrs;
          this.drpCurrentIllnesses = response?.currentIllnesses;
          this.drpSimilarIllnesses = response?.similarIllnesses;
          this.drpLastMenstruals = response?.lastMenstruals;
          this.drpFirstTreatments = response?.firstTreatments;
          this.drpLastXrays = response?.lastXrays;
          this.drpActualManifests = response?.actualManifests;
          this.drpXraysMaintained = response?.xraysMaintained;
          this.drpLastWorked = response?.lastWorked;
          this.drpReturnToWorks = response?.returnToWorks;
          this.drpPatConditionCodes = response?.patConditionCodes;
          this.drpBlock19 = response?.block19;
          this.drpPcps = response?.pcps;
          this.drpPcpLastSeen = response?.pcps;
          this.drpSupervisingProv = response?.supervisingProvs;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  doctorDropdown(): void {
    if (
      this.orderId !== '' &&
      this.orderId !== null &&
      this.orderId !== undefined
    ) {
      this.store.dispatch(new getOrder(this.orderId)).subscribe(
        (response) => {
          const salectedOrders: SaleorderDTO =
            response?.orderState?.selectedOrder;
          const selectedPatientClinical: ClinicalDTO =
            salectedOrders?.patients?.clinicals;
          const selectedPatientPerosnal: PersonalDTO =
            salectedOrders?.patients?.personals;
          this.orderId = salectedOrders?.id;
          this.defaultSaleOrderId = salectedOrders?.saleorderId;
          this.patientId = salectedOrders?.patientId;
          this.referrindProvider =
            selectedPatientClinical?.referringProviderDoctor ?? '';
          this.provider = selectedPatientClinical?.orderingDoctor ?? '';
          const diagnosisCode9 = selectedPatientClinical?.diagnosisCode9;
          const diagnosisCode10 = selectedPatientClinical?.diagnosisCode10;
          this.txtIcd9 = diagnosisCode9 ?? [];
          this.txtIcd10 = diagnosisCode10 ?? [];

          this.claimForm.patchValue({
            drpReferringProvider:
              selectedPatientClinical?.referringProviderDoctor === ''
                ? null
                : selectedPatientClinical?.referringProviderDoctor,
            drpOrderingProvider:
              selectedPatientPerosnal?.providerId === ''
                ? null
                : selectedPatientPerosnal?.providerId,
            txtDiagnosisCodes9: diagnosisCode9 && diagnosisCode9?.length !== 0 ? diagnosisCode9 : null,
            txtdiagnosisCode10Id: diagnosisCode10 && diagnosisCode10?.length !== 0 ? diagnosisCode10 : null,
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
  // ! save claimk info
  getPatientDetailsForSave() {
    // let billingDetails: PostingDTO;
    // if (this.billingId !== "" && this.billingId !== null && this.billingId !== undefined) {
    //   this.store.dispatch(new getBilling(this.billingId)).subscribe(response => {
    //
    //     billingDetails = response.billingInfoState.selectedbillInfo;
    // this.saveDefaultClaimInfo(billingDetails);
    //   })
    // }
  }

  // ! save claimk info

  saveDefaultClaimInfo() {
    this.saveButtonHide = true;
    this.isShowSpinner = true;

    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.claimOrderId =
      this.claimOrderId ?? '00000000-0000-0000-0000-000000000000';
    this.orderId = this.orderId ?? '';
    this.tenantId = localStorage.getItem('tenantId');

    if (this.claimOrderId == '00000000-0000-0000-0000-000000000000') {
      if (this.orderId !== '' && this.orderId) {
        this.store.dispatch(new getOrder(this.orderId)).subscribe(
          (response) => {
            const selectedOrder: SaleorderDTO =
              response?.orderState?.selectedOrder;

            const claimInfoData: ClaimDTO = {
              lstClaimInfo: [
                {
                  referringProvider:
                    this.claimForm.value.drpReferringProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpReferringProvider,
                  pcp:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  orderingProvider:
                    this.claimForm.value.drpOrderingProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpOrderingProvider,
                  supervisingProv:
                    this.claimForm.value.drpSupervisingProv === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpSupervisingProv,
                  referralDate: this.claimForm.value.txtReferralDate,
                  pcpLastSeen:
                    this.claimForm.value.drpPcpLast === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcpLast,
                  diagnosisCode9:
                    this.claimForm.value.txtDiagnosisCodes9 === ''
                      ? []
                      : this.claimForm.value.txtDiagnosisCodes9,
                  diagnosisCode10: this.claimForm.value.txtdiagnosisCode10Id,
                  currentIllness:
                    this.claimForm.value.drpCurrent === null || undefined
                      ? ''
                      : this.claimForm.value.drpCurrent,
                  similarIllness:
                    this.claimForm.value.drpSimilar === null || undefined
                      ? ''
                      : this.claimForm.value.drpSimilar,
                  lastMenstrual:
                    this.claimForm.value.drpMenstrual === null || undefined
                      ? ''
                      : this.claimForm.value.drpMenstrual,
                  injuryDate: this.claimForm.value.txtInjury,
                  firstTreatment:
                    this.claimForm.value.drpFirstTreatment === null || undefined
                      ? ''
                      : this.claimForm.value.drpFirstTreatment,
                  consultDate: this.claimForm.value.txtConsultDate,
                  lastXray:
                    this.claimForm.value.drpLastXray === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastXray,
                  estimatedDob: this.claimForm.value.txtEstimatedDob,
                  disabledFrom:
                    this.claimForm.value.drpDisabledFrom === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledFrom,
                  disabledTo:
                    this.claimForm.value.drpDisabledTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledTo,
                  acuteManifest:
                    this.claimForm.value.drpManifest === null || undefined
                      ? ''
                      : this.claimForm.value.drpManifest,
                  hospitalizedFrom:
                    this.claimForm.value.drpHospitalizedFrom === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedFrom,
                  hospitalizedTo:
                    this.claimForm.value.drpHospitalizedTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedTo,
                  xraysMaintained:
                    this.claimForm.value.drpXrayMaintaine === null || undefined
                      ? ''
                      : this.claimForm.value.drpXrayMaintaine,
                  lastWorked:
                    this.claimForm.value.drpLastWorked === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastWorked,
                  returnToWork:
                    this.claimForm.value.drpReturnToWork === null || undefined
                      ? ''
                      : this.claimForm.value.drpReturnToWork,
                  patConditionCode:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  auth: this.claimForm.value.txtAuth,
                  patConditionCode1: this.claimForm.value.txtCondn1,
                  block10d: this.claimForm.value.txtBlock10d,
                  patConditionCode2: this.claimForm.value.txtCondn2,
                  block19:
                    this.claimForm.value.drpBlock19 === null || undefined
                      ? ''
                      : this.claimForm.value.drpBlock19,
                  isPregnant: +this.claimForm.value.chkPregnant,
                  isAutoAccident: +this.claimForm.value.chkAutoAccident,
                  isAutoAccidentState:
                    +this.claimForm.value.chkAutoAccidentState,
                  isOtherAccident: +this.claimForm.value.chkOtherAccident,
                  isEmploymentRelated: +this.claimForm.value.chkEmployment,
                },
              ],
              lstPostingCharges: selectedOrder[0],
              // saleOrder:null,
              insurancePayments: {
                primaryInsurancePaymentDetails: null,
                secondaryInsurancePaymentDetails: null,
                tertiaryInsurancePaymentDetails: null,
              },
              saleOrder: selectedOrder,
              patientId: this.patientId,
              orderId: this.orderId,
              saleorderId: this.defaultSaleOrderId,
              defaultPatientId: this.defaultId,
              patientName: this.patientName,
              patientDob: this.patientDOB,
              readyCount: 0,
              processedCount: 0,
              crossOverCount: 0,
              holdCount: 0,
              patientResponsibilityCount: 0,
              patientResponsibilityAfterInsCount: 0,
              claimId: this.claimOrderId,
              lstClearingHouseUpdates: [],
              lstPatientVisitNotes: [],
              totalBilled: 0,
              totalInsBal: 0,
              totalPatientBal: 0,
              lstReminders: null,
              claimStatus: null,
              isVoided : false,
              claimProcessMethod: claimProcessMethod.Manual,
            };

            this.saveClaimInfo(claimInfoData);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    } else {
      if (
        this.claimOrderId &&
        this.claimOrderId !== '00000000-0000-0000-0000-000000000000'
      ) {
        this.store.dispatch(new getBilling(this.claimOrderId)).subscribe(
          (response) => {
            const selectedBill: ClaimDTO =
              response?.billingState?.selectedBilling;
            const claimInfoData: ClaimDTO = {
              lstClaimInfo: [
                {
                  referringProvider:
                    this.claimForm.value.drpReferringProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpReferringProvider,
                  pcp:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  orderingProvider:
                    this.claimForm.value.drpOrderingProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpOrderingProvider,
                  supervisingProv:
                    this.claimForm.value.drpSupervisingProv === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpSupervisingProv,
                  referralDate: this.claimForm.value.txtReferralDate,
                  pcpLastSeen:
                    this.claimForm.value.drpPcpLast === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcpLast,
                  diagnosisCode9:
                    this.claimForm.value.txtDiagnosisCodes9 === ''
                      ? []
                      : this.claimForm.value.txtDiagnosisCodes9,
                  diagnosisCode10: this.claimForm.value.txtdiagnosisCode10Id,
                  currentIllness:
                    this.claimForm.value.drpCurrent === null || undefined
                      ? ''
                      : this.claimForm.value.drpCurrent,
                  similarIllness:
                    this.claimForm.value.drpSimilar === null || undefined
                      ? ''
                      : this.claimForm.value.drpSimilar,
                  lastMenstrual:
                    this.claimForm.value.drpMenstrual === null || undefined
                      ? ''
                      : this.claimForm.value.drpMenstrual,
                  injuryDate: this.claimForm.value.txtInjury,
                  firstTreatment:
                    this.claimForm.value.drpFirstTreatment === null || undefined
                      ? ''
                      : this.claimForm.value.drpFirstTreatment,
                  consultDate: this.claimForm.value.txtConsultDate,
                  lastXray:
                    this.claimForm.value.drpLastXray === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastXray,
                  estimatedDob: this.claimForm.value.txtEstimatedDob,
                  disabledFrom:
                    this.claimForm.value.drpDisabledFrom === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledFrom,
                  disabledTo:
                    this.claimForm.value.drpDisabledTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledTo,
                  acuteManifest:
                    this.claimForm.value.drpManifest === null || undefined
                      ? ''
                      : this.claimForm.value.drpManifest,
                  hospitalizedFrom:
                    this.claimForm.value.drpHospitalizedFrom === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedFrom,
                  hospitalizedTo:
                    this.claimForm.value.drpHospitalizedTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedTo,
                  xraysMaintained:
                    this.claimForm.value.drpXrayMaintaine === null || undefined
                      ? ''
                      : this.claimForm.value.drpXrayMaintaine,
                  lastWorked:
                    this.claimForm.value.drpLastWorked === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastWorked,
                  returnToWork:
                    this.claimForm.value.drpReturnToWork === null || undefined
                      ? ''
                      : this.claimForm.value.drpReturnToWork,
                  patConditionCode:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  auth: this.claimForm.value.txtAuth,
                  patConditionCode1: this.claimForm.value.txtCondn1,
                  block10d: this.claimForm.value.txtBlock10d,
                  patConditionCode2: this.claimForm.value.txtCondn2,
                  block19:
                    this.claimForm.value.drpBlock19 === null || undefined
                      ? ''
                      : this.claimForm.value.drpBlock19,
                  isPregnant: +this.claimForm.value.chkPregnant,
                  isAutoAccident: +this.claimForm.value.chkAutoAccident,
                  isAutoAccidentState:
                    +this.claimForm.value.chkAutoAccidentState,
                  isOtherAccident: +this.claimForm.value.chkOtherAccident,
                  isEmploymentRelated: +this.claimForm.value.chkEmployment,
                },
              ],
              lstPostingCharges: selectedBill?.lstPostingCharges,
              insurancePayments: {
                primaryInsurancePaymentDetails: null,
                secondaryInsurancePaymentDetails: null,
                tertiaryInsurancePaymentDetails: null,
              },
              saleOrder: selectedBill?.saleOrder,
              patientId: selectedBill?.patientId,
              orderId: selectedBill?.orderId,
              defaultPatientId: selectedBill?.defaultPatientId,
              patientName: selectedBill?.patientName,
              patientDob: selectedBill?.patientDob,
              readyCount: 0,
              processedCount: 0,
              crossOverCount: 0,
              holdCount: 0,
              patientResponsibilityCount: 0,
              patientResponsibilityAfterInsCount: 0,
              claimId: selectedBill?.claimId,
              lstClearingHouseUpdates: selectedBill?.lstClearingHouseUpdates,
              totalPatientBal: selectedBill?.totalPatientBal,
              lstPatientVisitNotes: selectedBill?.lstPatientVisitNotes,
              totalBilled: 0,
              totalInsBal: 0,
              claimStatus: selectedBill?.claimStatus,
              isVoided : selectedBill?.isVoided,
              lstReminders: selectedBill?.lstReminders,
              claimProcessMethod: selectedBill?.claimProcessMethod,
            };
            this.saveClaimInfo(claimInfoData);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    }
  }
  saveClaimInfo(claimInfoData: ClaimDTO) {
    this.claimOrderId =
      this.claimOrderId ?? '00000000-0000-0000-0000-000000000000';

    if (
      this.claimOrderId &&
      this.claimOrderId != '00000000-0000-0000-0000-000000000000'
    ) {
      this.store
        .dispatch(new updateBilling(this.claimOrderId, claimInfoData))
        .subscribe(
          (value) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            const claimDetail = value.billingState.selectedBilling;
            this.claimId = value?.billingState?.selectedBilling?.id;
            this.Table.setBillingData(this.claimId);
            this.claimIdEmitter.emit(claimDetail);
            this.saveClaimInfoFormState.emit(this.claimForm.value);
            this.nextTabMoveOnSaveEmitter.emit('claimInfo');
            this.saveButtonHide = false;
            this.isShowSpinner = false;
          },
          (err) => {
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    } else {
      this.store.dispatch(new addBilling(claimInfoData)).subscribe(
        (value) => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully','Success')
          const claimDetail = value.billingState.selectedBilling;
          this.claimId = value?.billingState?.selectedBilling?.id;
          this.Table.setBillingData(this.claimId);
          this.claimIdEmitter.emit(claimDetail);
          this.saveClaimInfoFormState.emit(this.claimForm.value);
          this.nextTabMoveOnSaveEmitter.emit('claimInfo');
          this.saveButtonHide = false;
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  referrindProvider: string = '';
  pcp: string = '';
  SupervisingProv: string = '';
  pcpLast: string = '';
  currentIllness: string = '';
  SimilarIllness: string = '';
  Menstrual: string = '';
  firstTreatment: string = '';
  lastXray: string = '';
  Manifest: string = '';
  xrayMaintained: string = '';
  lastWorked: string = '';
  returnToWork: string = '';
  patCondn: string = '';
  block19: string = '';
  orderingProvider: string = '';
  clearreferrindProvider() {
    this.referrindProvider = '';
    this.claimForm.patchValue({ drpReferringProvider: '' });
  }
  clearorderingProvider() {
    this.orderingProvider = '';
    this.claimForm.patchValue({ drpOrderingProvider: '' });
  }

  clearpcp() {
    this.pcp = '';
    this.claimForm.patchValue({ drpPcp: '' });
  }
  clearSupervisingProv() {
    this.SupervisingProv = '';
    this.claimForm.patchValue({ drpSupervisingProv: '' });
  }
  clearpcpLast() {
    this.pcpLast = '';
    this.claimForm.patchValue({ drpPcpLast: '' });
  }
  clearcurrentIllness() {
    this.currentIllness = '';
    this.claimForm.patchValue({ drpCurrent: '' });
  }
  clearSimilarIllness() {
    this.SimilarIllness = '';
    this.claimForm.patchValue({ drpSimilar: '' });
  }
  clearMenstrual() {
    this.Menstrual = '';
    this.claimForm.patchValue({ drpMenstrual: '' });
  }
  clearfirstTreatment() {
    this.firstTreatment = '';
    this.claimForm.patchValue({ drpFirstTreatment: '' });
  }
  clearlastXray() {
    this.lastXray = '';
    this.claimForm.patchValue({ drpLastXray: '' });
  }
  clearManifest() {
    this.Manifest = '';
    this.claimForm.patchValue({ drpManifest: '' });
  }
  clearxrayMaintained() {
    this.xrayMaintained = '';
    this.claimForm.patchValue({ drpXrayMaintaine: '' });
  }
  clearlastWorked() {
    this.lastWorked = '';
    this.claimForm.patchValue({ drpLastWorked: '' });
  }
  clearreturnToWork() {
    this.returnToWork = '';
    this.claimForm.patchValue({ drpReturnToWork: '' });
  }
  clearpatCondn() {
    this.patCondn = '';
    this.claimForm.patchValue({ drpPatCondn: '' });
  }
  clearblock19() {
    this.block19 = '';
    this.claimForm.patchValue({ drpBlock19: '' });
  }

  // clear Provider
  clearProvider() {
    this.provider = '';
    this.claimForm.patchValue({ drpOrderingProvider: '' });
  }
}

export interface doctorInterface {
  id: string;
  name: string;
}
