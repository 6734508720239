<br>
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="font-weight-bolder mb-0 head"><b>Schedule Appoinment</b></h2>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-3">
    <form [formGroup]="scheduleForm">
    <div class="card card-body" style="margin-top: 15px;">
      <h1 style="color: #20b2aa;"><b>Scheduler Location</b></h1>
        <!-- Location Dropdown -->
        <mat-form-field class="col-12">
          <mat-label>Branch</mat-label>
          <mat-select
            #selectBranch
            formControlName="txtBranchName"
          >
            <mat-option
              *ngFor="let branch of filteredBranchTypeList"
              [value]="branch.id"
            >
              {{ branch.organizationUnitName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <h1 style="color: #20b2aa;"><b>New setup Appoinment</b></h1>
        <!-- Chart No -->
        <mat-form-field class="col-12">
          <mat-label> Chart </mat-label>
          <!-- <span class="asterisk">*</span> -->
          <input matInput  maxlength="40" formControlName="txtChart" type="text"
            autocomplete="new-txtChart" />
          <mat-error class="spanAlignment" *ngIf="
          scheduleForm?.get('txtChart')?.touched &&
          scheduleForm?.get('txtChart')?.errors?.required
          ">
            First Name is a required field!
          </mat-error>
        </mat-form-field>
        <!-- Name -->
        <mat-form-field class="col-12" >
          <mat-label>Name </mat-label>
          <input autocomplete="new-txtName" matInput maxlength="40"   formControlName="txtName" type="text" pattern="[a-zA-Z 0-9]*" />
          <mat-error
            *ngIf="scheduleForm?.get('txtName')?.touched && scheduleForm?.get('txtName')?.errors?.required">
            Enter  Name!
          </mat-error>
        </mat-form-field>
        <!--  Date -->
        <mat-form-field class="col-12">
          <mat-label> Date
          </mat-label>
          <input maxlength="40" autocomplete="new-txtDate" formControlName="txtDate" matInput [matDatepicker]="txtDate"
              [min]="minDate" placeholder="MM/DD/YYYY"
              [formControl]="scheduleForm?.controls.txtDate" />
          <mat-datepicker-toggle matSuffix [for]="txtDate"></mat-datepicker-toggle>
          <mat-datepicker #txtDate></mat-datepicker>
          <mat-error *ngIf="
          scheduleForm?.get('txtDate')?.touched ||
             (scheduleForm?.get('txtDate')?.errors?.required &&
             scheduleForm?.get('txtDate')?.dirty)
             ">
               Date is a required field!
          </mat-error>
          <mat-error *ngIf="
          scheduleForm?.get('txtDate')?.touched &&
          scheduleForm?.get('txtDate')?.errors?.matDatepickerParse
              ">
              Enter Valid Date!
          </mat-error>
          <mat-error *ngIf="
            minDate >= scheduleForm?.get('txtDate')?.value &&
            !(
              scheduleForm?.get('txtDate')?.touched &&
              scheduleForm?.get('txtDate')?.errors
            )
            ">
              Enter Future Dates Only!
          </mat-error>
      </mat-form-field>
      <!-- start -->
        <div class="col-12 time">
          <mat-label> From</mat-label>
          <div class="ngx-timepicker-field-example">
            <ngx-timepicker-field
              formControlName="txtFromTime"
            ></ngx-timepicker-field>
          </div>
        </div>
      <!-- End -->
      <div class="col-12 time">
        <mat-label> To </mat-label>
        <div class="ngx-timepicker-field-example">
            <ngx-timepicker-field formControlName="txtToTime"></ngx-timepicker-field>
            <mat-error *ngIf="scheduleForm?.get('txtToTime')?.touched && scheduleForm?.get('txtToTime')?.errors?.required">
              To is a required field!
            </mat-error>
        </div>
    </div>
     <!-- Tech -->
     <mat-form-field class="col-12">
      <mat-label>Tech</mat-label>
      <mat-select
        #selectBranch
        formControlName="txtTech"
      >
        <mat-option
          *ngFor="let branch of filteredBranchTypeList"
          [value]="branch.id"
        >
          {{ branch.organizationUnitName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!--  Type -->
    <mat-form-field class="col-12">
          <mat-label>Type</mat-label>
          <mat-select
            #selectBranch
            formControlName="txtType"
          >
            <mat-option
              *ngFor="let branch of filteredBranchTypeList"
              [value]="branch.id"
            >
              {{ branch.organizationUnitName }}
            </mat-option>
          </mat-select>
    </mat-form-field>
    <!-- Resource -->
    <mat-form-field class="col-12">
          <mat-label>Resource</mat-label>
          <mat-select
            #selectBranch
            formControlName="txtResource"
          >
            <mat-option
              *ngFor="let branch of filteredBranchTypeList"
              [value]="branch.id"
            >
              {{ branch.organizationUnitName }}
            </mat-option>
          </mat-select>
    </mat-form-field>
    <!-- notes -->
    <mat-form-field  class="col-12">
      <mat-label>Notes</mat-label>
      <textarea formControlName="txtNote" rows="2" maxlength="5000"  matInput></textarea>
    </mat-form-field>
    <!-- Home -->
    <mat-form-field class="col-12"  >
      <mat-label>Home </mat-label>
      <input
        formControlName="txtHome"
        matInput
        maxlength="40"
        prefix="+1-"
        mask="(000)-000-0000"
        type="text"
        autocomplete="new-txtHome"
      />
      <mat-error
        *ngIf="scheduleForm?.get('txtHome')?.touched && scheduleForm?.get('txtHome')?.errors?.required">
        Enter Minimum 10 Digits!
      </mat-error>
    </mat-form-field>
    <!-- cell -->
    <mat-form-field class="col-12"  >
      <mat-label>Cell </mat-label>
      <input
        formControlName="txtCell"
        matInput
        maxlength="40"
        prefix="+1-"
        mask="(000)-000-0000"
        type="text"
        autocomplete="new-txtCell"
      />
      <mat-error
        *ngIf="scheduleForm?.get('txtCell')?.touched && scheduleForm?.get('txtCell')?.errors?.required">
        Enter Minimum 10 Digits!
      </mat-error>
    </mat-form-field>
   <!-- Work -->
    <mat-form-field class="col-12"  >
      <mat-label>Work</mat-label>
      <input
        formControlName="txtWork"
        matInput
        maxlength="40"
        prefix="+1-"
        mask="(000)-000-0000"
        type="text"
        autocomplete="new-txtWork"
      />
      <mat-error
        *ngIf="scheduleForm?.get('txtWork')?.touched && scheduleForm?.get('txtWork')?.errors?.required">
        Enter Minimum 10 Digits!
      </mat-error>
    </mat-form-field>
    <!-- Email Id -->
    <mat-form-field class="col-12">
      <mat-label> Email Id </mat-label>
      <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
        pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
        [textMask]="{ mask: emailMask }" />

      <mat-error *ngIf="scheduleForm?.get('txtEmail')?.touched &&
      scheduleForm?.get('txtEmail')?.errors?.required ">
        Email Address is a required field!
      </mat-error>
      <mat-error *ngIf="
      scheduleForm?.get('txtEmail')?.touched &&
      scheduleForm?.get('txtEmail')?.errors?.pattern
                    ">
        Please Enter a Valid Email Address
      </mat-error>
      <mat-error *ngIf="scheduleForm?.get('txtEmail')?.errors?.isEmailAddressExists">
        Email is already taken!
      </mat-error>
    </mat-form-field>
    <!-- Text -->
    <mat-form-field class="col-12">
      <mat-label>Text</mat-label>
      <mat-select
        #selectBranch
        formControlName="txtText"
      >
        <mat-option
          *ngFor="let branch of filteredBranchTypeList"
          [value]="branch.id"
        >
          {{ branch.organizationUnitName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-radio-button class="col-12" value="Setup in home">Setup in home</mat-radio-button>
    <div class="col--12">
      <div class="text-lg-center">
        <button mat-button
          class="buttonColor mr-2" >
          Save
        </button>
      </div>
    </div>
    </div>
    </form>
  </div>
  <div class="col-9">
    <div class="card card-body" style="margin-top: 15px;">
       <div class="col-lg-12 mar-bottom">
    <!-- <mat-card> -->
    <div class="row">
      <div class="col-2" style="margin-right: 4%;">
        <h1><b>Information Status :</b></h1>
      </div>
      <span class="col-2 align">Scheduled <span class="dot sea-dot"></span></span>
      <span class="col-2 align">Rescheduled <span class="dot orange-dot"></span> </span>
      <span class="col-2 align">Completed <span class="dot green-dot"></span></span>
      <span class="col-2 align">Cancelled <span class="dot red-dot"></span></span>
      <span class="col-2 align"></span>
    </div>
    <!-- </mat-card> -->
       </div>
       <!-- <button  ejs-button cssClass='e-info' > Get events </button> -->
       <div class="ml-3 mr-3">
    <!-- <div class="card-body"> -->
    <ejs-schedule #scheduleObj height="650px" [selectedDate]="selectedDate" [eventSettings]="eventSettings"
      currentView="Week"   (popupOpen)="onPopupOpen($event)" (actionComplete)="onActionComplete($event)"
      (eventRendered)="applyCategoryColor($event)" (eventClick)="onEventClick($event)" [showQuickInfo]="showQuickInfo"
      (cellClick)="onCellClick($event)">

      <!-- [workHours]="workHours"
    [startHour]="startHour"
    [endHour]="endHour" -->
      <!-- <e-views>
      <e-view option='Week' startHour='09:30' endHour='18:00' [timeScale]="timeScaleOptions"></e-view>
    </e-views> -->
      <e-resources>
        <e-resource *ngIf="show" field="OwnerId" title="Status" name="Schedule" [dataSource]="roomDataSource"
          textField='OwnerText' idField='OwnerText'>
        </e-resource>
      </e-resources>


      <ng-template #eventSettingsTemplate let-data>
        <div>
          <div class="subject">{{data?.Subject}}</div>
          <div class="time">
            Status: {{data?.status}}
          </div>
        </div>
      </ng-template>

      <ng-template #groupHeaderTooltipTemplate let-data>
        <div class='template-wrap'>
          <div class="res-text">Name: {{data?.resourceData?.OwnerText}} </div>
        </div>
      </ng-template>
    </ejs-schedule>
    <!-- </div> -->
       </div>
    </div>
  </div>
</div>
