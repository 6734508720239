
<div class="col-12">
    <div class="row">
<h1 mat-dialog-title class="col-10 tittle"><b>View Documents</b></h1>
<div class="col-2" style="text-align-last: right">
      <button mat-icon-button mat-dialog-close=" close-button ">
        <mat-icon class="mr-2">close</mat-icon>
      </button>
    </div>
    </div>
  </div>
<mat-dialog-content class="mat-typography" style="height:90% !important">
    
<div class="container-fluid">
    <div class="col-12">
    <div class="row">
       
           
            <div class="col-2">
                <div class="col-12">
                    <mat-card class="overflow-hidden pr-0" style="height:75vh;">
                        <mat-card-header class="sticky-top">
                          <div class="total-count-label">Documents</div>
                        </mat-card-header>
                        <mat-divider class="pt-1"></mat-divider>
                        <mat-card-content>
                          
                          <mat-tree [dataSource]="treeDatasource" [treeControl]="treeControl" style="height:70vh;" class="overflow-auto">
                            <!-- This is the tree node template for leaf nodes -->
                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                              <!-- use a disabled button to provide padding for tree leaf -->
                              <button mat-icon-button disabled></button>
                              {{ node.name }}
                            </mat-tree-node>
                            <!-- This is the tree node template for expandable nodes -->
                            <mat-tree-node
                              *matTreeNodeDef="let node; when: hasChild"
                              matTreeNodePadding
                            >
                              <button
                                mat-icon-button
                                matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.name"
                              >
                                <mat-icon class="mat-icon-rtl-mirror">
                                  {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
                                </mat-icon>
                              </button>
                              {{ node.name }}
                            </mat-tree-node>
                          </mat-tree>
                        </mat-card-content>
                      </mat-card>
                </div>
                
              </div>
              <div class="col-10 content table-responsive">
                <div class="card card-body">
                <table
                  id="tbl"
                  datatable
                  style="width: 100%"
                  [dtOptions]="dtInboundOptions"
                  class="row-border hover"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Chart No</th>
                      <th>Last Name</th>
                      <th>First Name</th>
                      <th>Mi</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Zip</th>
                      <th>Home No</th>
                      <th>Mobile No</th>
                      <th>Active</th>
                      <th>DOB</th>
                      
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td
                        
                      ></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>                   
                      <td></td>  
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>#</th>
                     
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="Chart No"
                          name="search-ChartNo"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          type="text"
                          class="custom-search-input"
                          placeholder="Last Name"
                          name="search-LastName"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          type="text"
                          class="custom-search-input"
                          placeholder="First Name"
                          name="search-FirstName"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          type="text"
                          class="custom-search-input"
                          placeholder="Mi"
                          name="search- Mi"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="City"
                          name="search-City"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="State"
                          name="search-State"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="Zip"
                          name="search-Zip"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="Home No"
                          name="search-HomeNo"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="Mobile No"
                          name="search-MobileNo"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="Active"
                          name="search-active"
                        />
                      </th>
                      <th>
                        <input
                          matInput
                          class="custom-search-input"
                          type="text"
                          placeholder="DOB"
                          name="search-DOB"
                        />
                      </th>
                     
                    </tr>
                  
                  </tfoot>
                </table>                    
            </div>
              </div><br><br>

    </div>
</div>
</div>
</mat-dialog-content>
