import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ItemExportDatas, ItemExportDTO, PatientExportDatas, PatientExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { CreateUpdateProductBrandDTO, ProductBrandDTO, ProductBrandSearchDTO } from '../item-proxy/item-management/items/dto/models';
import { ProductBrandService } from '../item-proxy/item-management/items/product-brand.service';
import { Status } from '../item-proxy/item-management/items/status.enum';


@Component({
  selector: 'app-product-brand',
  templateUrl: './product-brand.component.html',
  styleUrls: ['./product-brand.component.scss'],
  providers: [ListService]
})
export class ProductBrandComponent implements OnInit , OnDestroy {
  productBrandForm: FormGroup;
  pBSearchForm: FormGroup;
  brandId: string = "";
  step: boolean = false;
  isShown: boolean = false;
  dataSource: MatTableDataSource<any>;
  @ViewChild('pBrandForm', { read: ElementRef, static: false }) pBrandForm: ElementRef;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = ['productBrandName', 'creationTime', 'status', 'Options', ];
  public strPageType: string = "InventoryItem";
  subscription$: Subscription[] = [];

  isShowSpinner: boolean;
  saveButtonHide: boolean;
  tableData: ProductBrandDTO[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public title: Title,
    private Table: TableService,
    public list: ListService,
    private prodBrandService: ProductBrandService,
    private dateValidator: DateValidator,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) { }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.productBrandForm = this.formBuilder.group({
      txtpbName: new FormControl(null, Validators.required),
      txtpbStatus: new FormControl(""),
    })
    this.pBSearchForm = this.formBuilder.group({
      pbName: new FormControl(""),
      pbStatus: new FormControl(""),
      pbCreatedOn: new FormControl("", this.dateValidator.dateVaidator),
      Status: new FormControl(""),
    })
    this.title.setTitle("Qsecure | Product Brand");


    this.getpCTable();
    const valueChanges = this.getPBForm()?.valueChanges.subscribe(() => {
      const txtpbName: AbstractControl = this.getPBForm()?.get('txtpbName');
      if (txtpbName.dirty) {
        txtpbName?.value?.trim() === "" && txtpbName?.setErrors({ required: true })
        txtpbName?.markAsDirty();
        txtpbName?.markAsTouched();
      }
    })
    this.subscription$.push(valueChanges);
    //
    this.getPBSForm()?.valueChanges?.subscribe(resposne => {
      this.getPBSForm()?.valid && this.getSearchedpCTable()
    })
  }
  //! Save Or Update Product Catgorey
  saveProductBrand() {
    this.saveButtonHide = true;

    let input: CreateUpdateProductBrandDTO = {
      productBrandName: this.getPBForm()?.value?.txtpbName ?? "",
      status: Status.Active,
    }
    this.brandId = this.brandId ?? "";
    if (this.brandId === "") {
      this.prodBrandService.create(input).subscribe(response => {
        this.brandId = "";
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.saveButtonHide = false;
        this.resetPBform();
        this.getpCTable();
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.prodBrandService.update(this.brandId, input).subscribe(response => {
        this.brandId = "";
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
        this.resetPBform();
        this.getpCTable();
        this.saveButtonHide = false;
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }
  //! Reset  Product Catgorey Form
  resetPBform() {
    this.getPBForm().patchValue({
      txtpbName: "",
      txtpbStatus: false
    });
    this.brandId = "";
    this.getPBForm()?.markAsUntouched();
    this.getPBForm()?.updateValueAndValidity();
    this.getPBForm()?.reset();
  }
  //! get Product brand form instance
  getPBForm(): FormGroup {
    return this.productBrandForm
  }
  //! get Product brand Search form instance
  getPBSForm(): FormGroup {
    return this.pBSearchForm
  }
  //! get Product category Table
  getpCTable() {
    this.isShowSpinner = true;
    this.tableData = [];
    const prodBandGetList = this.prodBrandService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.tableData = response?.items ?? [];
      this.isShowSpinner = false;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      const data: HttpErrorResponse = error;
      this.isShowSpinner = false;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
    this.subscription$.push(prodBandGetList);
  }

  //Product Category Status change
  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Brand will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          let productCategoryDetails = {
            ...data ?? null,
            status: Status.Active,
          }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.prodBrandService.update(data?.id, productCategoryDetails).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getpCTable();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          this.getpCTable();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Brand will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            let productCategoryDetails = {
              ...data ?? null,
              status: Status.Inactive,
            }
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.prodBrandService.update(data?.id, productCategoryDetails).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivated Successfully','Success')
                this.getpCTable();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            this.getpCTable();
          }
        });
      }
  }



  //! Get product brand details by id
  Viewbrand(value: string) {
    if (value) {
      const prodBandGet = this.prodBrandService.get(value).subscribe(resposne => {
        this.getPBForm().patchValue({
          txtpbName: resposne?.productBrandName ?? "",
          txtpbStatus: resposne?.status === Status.Active ? true : false,
        })
        this.brandId = resposne?.id ?? "";
        this.step = true;
      })
      this.subscription$.push(prodBandGet);
    }
  }
  //! Delete product brand details by id
  deletebrand(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        if (value) {
          const prodBandDelete = this.prodBrandService.delete(value).subscribe(resposne => {
            this.resetPBform();
            this.getpCTable();
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
          this.subscription$.push(prodBandDelete);
        }

      }
    });
  }
  //! get By Search Product Catgorey Table
  getSearchedpCTable() {
    this.isShowSpinner = true;
    this.tableData = [];
    const input: ProductBrandSearchDTO = {
      productBrandName: this.getPBSForm().value?.pbName,
      status: this.getPBSForm().value?.Status,
      createdDate: this.getPBSForm().value?.pbCreatedOn,

    }
    const prodBandSearch = this.prodBrandService.searchProductBrandByInput(input).subscribe(response => {
      this.tableData = response?.items ?? [];
      this.isShowSpinner = false;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      const data: HttpErrorResponse = error;
      this.isShowSpinner = false;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
    this.subscription$.push(prodBandSearch);
  }
  //export excel
  exportTableData() {
    let datas: ItemExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        brandName: element?.productBrandName,
        brandCreatedDate: element?.creationTime,
        brandStatus: element?.status
      });
    })
    let itemExport: ItemExportDTO = {
      columns: ["Product Brand Name", "Created Date", "Status"],
      datas: datas
    };
    const exportReport = this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.ProductBrandReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Product-Brand") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportReport);
  }
}
