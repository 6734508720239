<div class="py-2" style="border: 2px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 d-inline-flex align-items-center">
        <h2 class="mb-0"><b>MM Order Return Exchange & Reason</b></h2>
      </div>
      <div class="col-md-6 d-inline-flex justify-content-end">
        <a class="btn d-inline-flex justify-content-end align-items-end"
          style="background-color: rgb(32, 178, 170); color: white" href="#">Export</a>
      </div>
    </div>
  </div>
</div>

<!-- accordion -->

<div class="py-2 px-2 mx-4 border">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="color: rgb(32, 178, 170)">
                <b>Add MM Order / Return / Exchange / Reason</b>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-12">
                <form [formGroup]="notesForm">
                  <mat-form-field class="col-6">
                    <mat-label>Description</mat-label>

                    <input autocomplete="new-txtNotes" matInput maxlength="5000" formControlName="txtNotes" type="text">

                  </mat-form-field>
                  <button mat-button class="buttonColor mb-2 mr-2" [disabled]="!isFormValid()" (click)="onSubmit()">
                    {{ isEditMode ? 'Save' : 'Save' }}
                  </button>
                  <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetForm()">
                    Reset
                  </button>

                </form>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div> <br>

<!-- table -->

<!-- Table -->
<div class="py-4 px-2 mx-4 border">
  <div class="container-fluid">
    <div class="row">
      <!-- <div class="col-md-12" style="color: rgb(32, 178, 170)">
        <b>Description lists</b>
        <div class="table-responsive shadow p-3">
          <table class="table">
            <thead class="mb-3">
              <tr>
                <th>S.No</th>
                <th>Description</th>
                <th>Option</th>
              </tr>
            </thead>
            <tbody *ngIf="productDetails.length > 0; else noRecordsFound">
              <tr *ngFor="let productDetail of productDetails; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ productDetail.description }}</td>



                <td>

                  <mat-icon class="icon" style="color: rgb(32, 178, 170);" (click)="editEntry(i)">edit</mat-icon>

                  <mat-icon class="icon" style="color: rgb(178, 32, 32);" (click)="deleteEntry(i)">delete</mat-icon>

                </td>

              </tr>

            </tbody>

          </table>
          <ng-template #noRecordsFound>
            <div class="p-3">No records found</div>
          </ng-template>


        </div>
      </div> -->


      <div class="col-lg-12 mt-3">
        <!-- Loader Section -->
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <div class="d-flex justify-content-center" *ngIf="isLoading">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
          </div>
        </div>
        <div *ngIf="!isLoading" class="table table-responsive">
          <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="hover nowrap_th_td">
            <thead>
              <tr>
                <th class="text-center">Si.No</th>
                <th class="text-center">Description</th>
                <th class="text-center">Option</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of productDetails">
                <td class="text-center">{{ data.sino || '-' }}</td>
                <td class="text-center">{{ data.Description || '-' }}</td>
                <td class="text-center"><mat-icon class="icon" style="color: rgb(32, 178, 170);">edit</mat-icon>
                  <mat-icon class="icon" style="color: rgb(178, 32, 32);">delete</mat-icon> </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>

    </div>
  </div>
</div>