import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
// import { dateFormatter } from 'projects/admin/src/app/sub-batch-list/sub-batch-list.component';
import { dateFormatter } from 'projects/shared/src/app/services/common.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthorizationDTO, PatientDTO, PatientLockDTO } from '../patient-proxy/patient/dto/models';
import { PatientService } from '../patient-proxy/patient/patient.service';
import { AddAuthComponent } from './../add-auth/add-auth.component';


@Component({
  selector: 'app-patient-auth',
  templateUrl: './patient-auth.component.html',
  styleUrls: ['./patient-auth.component.scss'],
  providers: [ListService]
})
export class PatientAuthComponent implements OnInit ,OnDestroy {
  @Input() patientId: string = '';
  public strPageType: string = "auth";
  tblAuthReponse: any[] = [];
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  isLoading: boolean;
  @Output() patientIdEmitter: EventEmitter<PatientDTO> = new EventEmitter<PatientDTO>(null);
  subscription$: Subscription[] = [];

  constructor(
    private store: Store,
    public list: ListService,
    private Table: TableService,
    public dialog: MatDialog,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    const loginUserId = this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    this.subscription$.push(loginUserId);
    // this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getAuthTableData();
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub.unsubscribe();
     });
    }


  //! Open Authorization ModalPopup
  openAuthModal() {
    const dialogRef = this.dialog.open(AddAuthComponent, {
      disableClose: true,
      data: { patientId: this.patientId }
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      result !== "" && result !== null && result !== undefined && this.patientIdEmitter.emit(result);
      this.patientId !== "" && this.patientId !== null && this.patientId !== undefined && this.getAuthTableData();
    });
    this.subscription$.push(closeDialog)
  }

  //! View Authorization ModalPopup By Id
  viewAuth(auth: AuthorizationDTO) {
    const dialogRef = this.dialog.open(AddAuthComponent, {
      disableClose: true,
      data: { patientId: this.patientId, authDatas: auth }
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      result !== "" && result !== null && result !== undefined && this.patientIdEmitter.emit(result);
      this.patientId !== "" && this.patientId !== null && this.patientId !== undefined && this.getAuthTableData();
    });
    this.subscription$.push(closeDialog);
  }

  //! Load Authorization datas
  getAuthTableData() {
    this.isLoading = true;
    // let patientDetails: PatientDTO;
    const storeSubscription = this.patientService.get(this.patientId).subscribe(response => {
      // patientDetails = response?.patientState?.selectedPatient;
      const patientLock: PatientLockDTO = response?.patientLock;
      //Patient is locked & userId is not equal to loginUserId or patient is unlocked
      this.isPatientLockedByCurrentUser = (patientLock?.isLocked == true &&
        patientLock?.userId == this.strLoginUserId) ||
        (patientLock?.isLocked == false) ? true : false;
      this.tblAuthReponse = [];
      const primaryAuth = response?.authorizations?.primaryAuthorizations;
      const secondaryAuth = response?.authorizations?.secondaryAuthorizations;
      const tertiaryAuth = response?.authorizations?.tertiaryAuthorizations;

      if (primaryAuth != null) {
        primaryAuth.startDate = primaryAuth?.startDate ?? "";
        primaryAuth.endDate = primaryAuth?.endDate ?? "";
        const toDate: Date | null = primaryAuth?.endDate == "" ? null : new Date(primaryAuth?.endDate);
        const fromDate: Date | null = primaryAuth?.startDate == "" ? null : new Date(primaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);

        this.tblAuthReponse.push({
          authId: primaryAuth?.authId,
          authVisits: primaryAuth?.authVisits,
          authNo: primaryAuth?.authNo,
          authAmount: primaryAuth?.authAmount,
          item: primaryAuth?.item,
          note: primaryAuth?.note,
          typeOfPolicy: primaryAuth?.typeOfPolicy,
          startDate: dateFormatter(primaryAuth?.startDate),
          endDate: dateFormatter(primaryAuth?.endDate),
          category: primaryAuth?.category,
          completed: primaryAuth?.isCompleted,
          completedBy: primaryAuth?.completedBy,
          plan: primaryAuth?.plan,
          patientId: response?.id,
          authorizationId: primaryAuth?.authorizationId,
          //  daysDifference: daysDifference,
          expiryStatus: primaryAuth?.expiryStatus,
          isFollowUp: primaryAuth?.isFollowUp
        });
      }
      if (secondaryAuth != null) {
        secondaryAuth.startDate = secondaryAuth?.startDate ?? "";
        secondaryAuth.endDate = secondaryAuth?.endDate ?? "";
        const toDate: Date | null = secondaryAuth?.endDate == "" ? null : new Date(secondaryAuth?.endDate);
        const fromDate: Date | null = secondaryAuth?.startDate == "" ? null : new Date(secondaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({
          authId: secondaryAuth?.authId,
          authVisits: secondaryAuth?.authVisits,
          authNo: secondaryAuth?.authNo,
          authAmount: secondaryAuth?.authAmount,
          item: secondaryAuth?.item,
          note: secondaryAuth?.note,
          typeOfPolicy: secondaryAuth?.typeOfPolicy,
          startDate: dateFormatter(secondaryAuth?.startDate),
          endDate: dateFormatter(secondaryAuth?.endDate),
          category: secondaryAuth?.category,
          completed: secondaryAuth?.isCompleted,
          completedBy: secondaryAuth?.completedBy,
          plan: secondaryAuth?.plan,
          patientId: response?.id,
          authorizationId: secondaryAuth?.authorizationId,
          //  daysDifference: daysDifference
          expiryStatus: secondaryAuth?.expiryStatus,
          isFollowUp: secondaryAuth?.isFollowUp
        });
      }
      if (tertiaryAuth != null) {
        tertiaryAuth.startDate = tertiaryAuth?.startDate ?? "";
        tertiaryAuth.endDate = tertiaryAuth?.endDate ?? "";
        const toDate: Date | null = tertiaryAuth?.endDate == "" ? null : new Date(tertiaryAuth?.endDate);
        const fromDate: Date | null = tertiaryAuth?.startDate == "" ? null : new Date(tertiaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({
          authId: tertiaryAuth?.authId,
          authVisits: tertiaryAuth?.authVisits,
          authNo: tertiaryAuth?.authNo,
          authAmount: tertiaryAuth?.authAmount,
          item: tertiaryAuth?.item,
          note: tertiaryAuth?.note,
          typeOfPolicy: tertiaryAuth?.typeOfPolicy,
          startDate: dateFormatter(tertiaryAuth?.startDate),
          endDate: dateFormatter(tertiaryAuth?.endDate),
          category: tertiaryAuth?.category,
          completed: tertiaryAuth?.isCompleted,
          completedBy: tertiaryAuth?.completedBy,
          plan: tertiaryAuth?.plan,
          patientId: response?.id,
          authorizationId: tertiaryAuth?.authorizationId,
          daysDifference: daysDifference,
          expiryStatus: tertiaryAuth?.expiryStatus,
          isFollowUp: tertiaryAuth?.isFollowUp
        });
      }

      this.Table.setAuthTable(this.tblAuthReponse);
      this.isLoading = false;

    }, err => {
      this.isLoading = false
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(storeSubscription)
  }

  //! Delete Authorization By Id
  deleteAuth(datas?: any) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const deleteAuthorization = this.patientService.deleteAuthorization(datas?.patientId, datas?.authorizationId).subscribe(() => {
          this.tblAuthReponse.forEach(element => {
            if (datas?.authorizationId == element.authorizationId) {
              let index = this.tblAuthReponse.indexOf(element, 0)
              this.tblAuthReponse.splice(index, 1)
            }
          });
          this.Table.setAuthTable(this.tblAuthReponse);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteAuthorization)
      }
    });
  }

}
