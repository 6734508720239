<div #tableContainer class="p-4">

  <table datatable [dtOptions]="dtOptions" id="faxfindertbl" class="faxfindertbl row-border hover"
    dtLoadingSpinner>
    <thead>
      <tr style="font: 600 14px/20px Roboto, Helvetica Neue, sans-serif;">

        <th [matTooltip]="'Fax Id'">Fax Id</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Chart No'">Chart No</th>
        <th [matTooltip]="'Date of Birth'">DOB</th>
        <th [matTooltip]="'Referral Source Name'">Referral Source Name</th>
        <th [matTooltip]="'Location'">Location</th>
        <th [matTooltip]="'Fax Folder'">Fax Folder</th>
        <th [matTooltip]="'Insurance'">Insurance</th>
      </tr>
    </thead>
    <tbody style="font: 400 13px/20px Roboto, Helvetica Neue, sans-serif !important;">

    </tbody>
    <tfoot>
      <tr>

        <!-- @ViewChild('faxIdInput') faxIdInput!: ElementRef;
        @ViewChild('patientNameInput') patientNameInput!: ElementRef;
        @ViewChild('dateOfBirthInput') dateOfBirthInput!: ElementRef;
        @ViewChild('chartNoInput') chartNoInput!: ElementRef;
        @ViewChild('referralSourceInput') referralSourceInput!: ElementRef;
        @ViewChild('locationInput') locationInput!: ElementRef;
        @ViewChild('folderInput') folderInput!: ElementRef;
        @ViewChild('insuranceInput') insuranceInput!: ElementRef; -->


        <th><input #faxIdInput matInput type="text" class="textbox-border-class" placeholder="Fax Id" /></th>
        <th>
          <input #patientNameInput matInput type="text" class="textbox-border-class" placeholder="Patient Name" />
        </th>
        <th>
          <input #chartNoInput matInput type="text" class="textbox-border-class" placeholder="Chart No" />
        </th>
        <th>
          <input #dateOfBirthInput matInput type="text" class="textbox-border-class"
            placeholder="DOB" />
        </th>
        <th>
          <input #referralSourceInput matInput class="textbox-border-class" type="text"
            placeholder="Referral Source Name" />
        </th>
        <th>
          <input #locationInput matInput class="textbox-border-class" type="text" placeholder="Location" />
        </th>
        <th>
          <input #folderInput matInput class="textbox-border-class" type="text" placeholder="Fax Folder" />
        </th>
        <th>
          <input #insuranceInput matInput class="textbox-border-class" type="text" placeholder="Insurance" />
        </th>
      </tr>
    </tfoot>
  </table>
</div>
