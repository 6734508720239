<ng-container>
    <mat-accordion class="example-headers-align" [multi]='true'>
        <mat-expansion-panel class="mt-1">
            <mat-expansion-panel-header class="customThemeClass custom-header-text-sorting custom-accordion-border">
                <mat-panel-title class="customThemeClassV1">
                    Tinna Harris (5231)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content>
                <div class="row mt-3 messageRow" (click)="selectRow(1)" [ngClass]="{'selected': selectedRow === 1}">
                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(2)" [ngClass]="{'selected': selectedRow === 2}">
                    <!-- <div class="col-12">
                        <div class="d-flex justify-content-between">
                            <div class="page-title-box page-title-left pb-0 dateElips">
                                    <span class="customfontSize">Aubrey Lucas</span>
                            </div>

                            <div class="page-title-box page-title-right pb-0">
                                <span class="messageDate">04/25/2024 12:15 PM</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="d-flex justify-content-between">
                            <div class="page-title-box page-title-left pb-0 dateElips">
                                    <span class="customfontSize">Please Confirm his message</span>
                            </div>

                            <div class="page-title-box page-title-right pb-0">
                                <span class="msgCount">5</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div> -->

                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>


                <div class="row mt-3 messageRow" (click)="selectRow(3)" [ngClass]="{'selected': selectedRow === 3}">
                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(4)" [ngClass]="{'selected': selectedRow === 4}">
                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

            </mat-card-content>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>

<ng-container>
    <mat-accordion class="example-headers-align" [multi]='true'>
        <mat-expansion-panel class="mt-1">
            <mat-expansion-panel-header class="customThemeClass custom-header-text-sorting custom-accordion-border">
                <mat-panel-title class="customThemeClassV1">
                    Martin Wilson M (2531)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content>
                <div class="row mt-3 messageRow" (click)="selectRow(5)" [ngClass]="{'selected': selectedRow === 5}">
                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(6)" [ngClass]="{'selected': selectedRow === 6}">
                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>


                <div class="row mt-3 messageRow" (click)="selectRow(7)" [ngClass]="{'selected': selectedRow === 7}">
                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(8)" [ngClass]="{'selected': selectedRow === 8}">
                    <div class="col-5 dateElips">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-7 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span class="customfontSize">Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

            </mat-card-content>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>

<ng-container>
    <mat-accordion class="example-headers-align" [multi]='true'>
        <mat-expansion-panel class="mt-1">
            <mat-expansion-panel-header class="customThemeClass custom-header-text-sorting custom-accordion-border">
                <mat-panel-title class="customThemeClassV1">
                    Jessica Lauren G (25291)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content>
                <div class="row mt-3 messageRow" (click)="selectRow(9)" [ngClass]="{'selected': selectedRow === 9}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(10)" [ngClass]="{'selected': selectedRow === 10}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>


                <div class="row mt-3 messageRow" (click)="selectRow(11)" [ngClass]="{'selected': selectedRow === 11}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(12)" [ngClass]="{'selected': selectedRow === 12}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

            </mat-card-content>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>

<ng-container>
    <mat-accordion class="example-headers-align" [multi]='true'>
        <mat-expansion-panel class="mt-1">
            <mat-expansion-panel-header class="customThemeClass custom-header-text-sorting custom-accordion-border">
                <mat-panel-title class="customThemeClassV1">
                    Isabella Miller P (63231)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content>
                <div class="row mt-3 messageRow" (click)="selectRow(1)" [ngClass]="{'selected': selectedRow === 1}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(2)" [ngClass]="{'selected': selectedRow === 2}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>


                <div class="row mt-3 messageRow" (click)="selectRow(3)" [ngClass]="{'selected': selectedRow === 3}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>


            </mat-card-content>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>

<ng-container>
    <mat-accordion class="example-headers-align" [multi]='true'>
        <mat-expansion-panel class="mt-1">
            <mat-expansion-panel-header class="customThemeClass custom-header-text-sorting custom-accordion-border">
                <mat-panel-title class="customThemeClassV1">
                    Robert Smith Q (36231)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content>
                <div class="row mt-3 messageRow" (click)="selectRow(1)" [ngClass]="{'selected': selectedRow === 1}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(2)" [ngClass]="{'selected': selectedRow === 2}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>


                <div class="row mt-3 messageRow" (click)="selectRow(3)" [ngClass]="{'selected': selectedRow === 3}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row mt-3 messageRow" (click)="selectRow(4)" [ngClass]="{'selected': selectedRow === 4}">
                    <div class="col-7">
                        <span class="customfontSize">Aubrey Lucas</span>
                    </div>
                    <div class="col-5 dateElips mt-1">
                        <span class="messageDate">04/25/2024 12:15 PM</span>
                    </div>
                    <div class="col-8 dateElips">
                        <span>Please Confirm his message</span>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <span class="msgCount">5</span>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-divider></mat-divider>
                    </div>
                </div>

            </mat-card-content>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>