<div class="row">
  <div class="col-12">
    <div class="card card-body px-2 pb-1 pt-2">
      <form [formGroup]="invoiceSearchForm">
        <!-- <button mat-icon-button>
            <mat-icon class="btn-filter-color">filter_list</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>refresh</mat-icon>
          </button> -->
          <!-- (selectionChange)="onSelectPatient($event)" -->
        <mat-form-field class="col-3">
          <mat-label>Patient / Chart No</mat-label>
          <mat-select formControlName="drpPatient"
            (openedChange)="onPatinetDropdownOpenChange($event)" multiple [(value)]="drpPatientId" (selectionChange)="onSelectionChartNoChange($event)">
            <mat-option>
              <ngx-mat-select-search ngDefaultControl placeholderLabel="Patient / Chart No - Type Minimum 3 Characters"
                hideClearSearchButton="true" [noEntriesFoundLabel]="drpDownMessage()" formControlName="txtPatientFilter"
                (keyup)="loadPatientDropDown($event.target.value,$event.key)">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!chartNoSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
            <mat-option *ngIf="chartNoSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
            <mat-option *ngFor="let patient of filteredPatient | async " [value]="patient?.patientId"
              [title]="patient?.patientChart">
              {{ patient?.patientChart }} 
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="
          invoiceSearchForm.get('drpPatient').value != '' &&
          invoiceSearchForm.get('drpPatient').value != null &&
          invoiceSearchForm.get('drpPatient').value != undefined
        " (click)="clearPatient(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label>Invoice ID</mat-label>
          <mat-select formControlName="drpInvoice" (openedChange)="onInvoiceDropdownOpenChange($event)" multiple [(value)]="drpInvoiceId" (selectionChange)="onSelectionInvoiceChange($event)">
            <mat-option>
              <ngx-mat-select-search ngDefaultControl placeholderLabel="Invoice - Type Minimum 3 Characters"
                hideClearSearchButton="true" [noEntriesFoundLabel]="drpInvoiceDownMessage()"
                formControlName="txtInvoiceFilter" (keyup)="loadInvoiceDropDown($event.target.value,$event.key)">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!invoiceIdSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
            <mat-option *ngIf="invoiceIdSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
            <mat-option *ngFor="let invoice of filteredInvoice | async " [value]="invoice?.claimId"
              [title]="invoice?.defaultClaimId">
              {{ invoice?.defaultClaimId }} 
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="
          invoiceSearchForm.get('drpInvoice').value != '' &&
          invoiceSearchForm.get('drpInvoice').value != null &&
          invoiceSearchForm.get('drpInvoice').value != undefined
        " (click)="clearInvoice(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Status</mat-label>
          <mat-select formControlName="drpStatus" multiple [(value)]="drpStatusId" (selectionChange)="onSelectionStatusIdChange($event)">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                hideClearSearchButton="true" formControlName="txtStatusFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!statusIdSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
            <mat-option *ngIf="statusIdSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
            <mat-option *ngFor="let status of filteredStatus | async" [value]="status.statusId">
              {{ status.statusName }}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="
              invoiceSearchForm.get('drpStatus').value != '' &&
              invoiceSearchForm.get('drpStatus').value != null &&
              invoiceSearchForm.get('drpStatus').value != undefined
            " (click)="clearStatus(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2 range-picker">
          <mat-label>Date of Service</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate matInput placeholder="Start date" formControlName="txtDOSFromDate" maxlength="10"
              placeholder="(MM/DD/YYYY)" (keydown)="addSlashToDate($event)" (focusout)="focusOutDOS()" />

            <input matEndDate matInput placeholder="End date" formControlName="txtDOSEndDate" maxlength="10"
              placeholder="(MM/DD/YYYY)" (keydown)="addSlashToDate($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <button mat-button *ngIf="
              (invoiceSearchForm.get('txtDOSFromDate').value != '' &&
                invoiceSearchForm.get('txtDOSFromDate').value != null &&
                invoiceSearchForm.get('txtDOSFromDate').value != undefined) ||
              (invoiceSearchForm.get('txtDOSEndDate').value != '' &&
                invoiceSearchForm.get('txtDOSEndDate').value != null &&
                invoiceSearchForm.get('txtDOSEndDate').value != undefined)
            " (click)="clearDOS(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button type="button" matTooltip="Search" mat-raised-button (click)="getTableData(); $event.stopPropagation;"
          class="search-icon-button mr-2 px-2 mat-raised-small-button">
          <!-- <mat-icon class="fg-white" >Search</mat-icon> -->
            <img  class="img-responsive"
                    src="assets/Billing Icons/searchV1.png" height="20" />
        </button>
        &nbsp;
        <button type="button" matTooltip="Reset" mat-raised-button (click)="clearAll(); $event.stopPropagation;"
          class="reset-icon-button mr-2 px-2 mat-raised-small-button">
          <mat-icon class="fg-white" >refresh</mat-icon>
        </button>
      </form>
    </div>
  </div>
</div>
<div class="row mt-2" *ngIf="!isLoading">
  <div class="col-12">
    <div class="card card-body p-1">
      <div class="table table-responsive">
        <table datatable [dtOptions]="billingInvoiceTable" class="row-border hover w-100 display">
          <thead>
            <tr>
              <th [hidden]="true">*</th>
              <th><mat-icon>apps</mat-icon></th>
              <th>Chart ID</th>
              <th>Patient Name</th>
              <th>Invoice ID</th>
              <th>Ticket ID</th>
              <th>DOS</th>
              <th>Created</th>
              <th>Modified</th>
              <th class="text-right">Charge($)</th>
              <th class="text-right">Balance($)</th>
              <th>Service Location</th>
              <th>Processing Method </th>
              <th>Bill To</th>
              <th>Payer Name</th>
              <th>Plan Name</th>
              <th class="cwidth" >Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of DataList">
              <td [hidden]="true">
                {{ invoice.id }}
              </td>
              <td [matTooltip]="'Open Options'">
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item [disabled]="isEmpty(invoice.patientId)"
                    (click)="openAddNotesPopUp($event, invoice, false)">
                    Add Patient Notes
                  </button>
                  <button mat-menu-item [disabled]="isEmpty(invoice.patientId)"
                    (click)="openViewNotesPopup(invoice.patientId)">
                    View Patient Notes
                  </button>
                  <button mat-menu-item [disabled]="isEmpty(invoice.patientId)"
                    (click)="viewPatientLedger(invoice.patientId)">
                    Patient Ledger
                  </button>
                  <button mat-menu-item (click)="uploadEOB(invoice, true)" [disabled]="isEmpty(invoice.claimId) || !invoice.isClaimPostingAllowed">
                    Upload EOB
                  </button>
                </mat-menu>
              </td>
              <td>
                <a [class.disabled]="
                    isEmpty(invoice.chartId) || isEmpty(invoice.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + invoice.patientId" target="_blank"
                  [matTooltip]="invoice.chartId || '-'">{{ invoice.chartId || "-" }}</a>
              </td>
              <td>
                <!-- Displaying Name -->
                <div [matTooltip]="invoice.patientName || '-'" >{{ invoice.patientName || '-'}}</div>

                <!-- Displaying DOB -->
                <div>
                  <span class="splSpan" [matTooltip]="invoice.dtDOB || '-'" >{{ invoice.dtDOB || '-'}}</span>
                  <i [matTooltip]="'Purchase'" *ngIf="invoice.isPurchase" class="fa fa-shopping-cart mt-1" aria-hidden="true"></i>
                  <img [matTooltip]="'Rental'" *ngIf="invoice.isRental" style="cursor: pointer" class="img-responsive pl-1"
                    src="assets/Billing Icons/Rental.png" height="14" />
                </div>
              </td>
              <td>

                <!-- isEmpty(invoice.claimId) || isEmpty(invoice.patientId) ||  -->
                <!-- <a class="preview-link"  [class.disabled]="isEmpty(invoice.invoiceId) || isEmpty(invoice.invoiceNumber) || isEmpty(invoice.invoiceStatusId) " [routerLink]="
                    '/orders/invoiceview/' + invoice.invoiceId + '/' + invoice.invoiceStatusId + '/' + (invoice.rentalOrderId || defaultGuid)
                  " target="_blank" class="preview-link" [matTooltip]="invoice.invoiceNumber || '-'" >
                  {{ invoice.invoiceNumber || "-" }}
                </a> -->

                <a class="preview-link"  [class.disabled]="isEmpty(invoice.orderId) || isEmpty(invoice.invoiceNumber) || isEmpty(invoice.invoiceStatusId) " [routerLink]="
                    '/orders/invoiceview/' + (invoice.orderId || defaultGuid) + '/' + (invoice.claimId || defaultGuid) + '/' + (invoice.rentalOrderId || defaultGuid)
                  " target="_blank" class="preview-link" [matTooltip]="invoice.invoiceNumber || '-'" >
                  {{ invoice.invoiceNumber || "-" }}
                </a>

              </td>
              <td>
                <a [class.disabled]="
                isEmpty(invoice.orderId) || isEmpty(invoice.patientId) || isEmpty(invoice.orderTicketId)
              " class="preview-link" [routerLink]="
                    '/orders/saleOrderview/' + invoice.patientId + '/' + invoice.orderId
                  " target="_blank" class="preview-link" [matTooltip]="invoice.orderTicketId || '-'">{{ invoice.orderTicketId || "-" }}
                </a>
              </td>
              <td [matTooltip]="invoice.dtDOS || '-'" >{{ invoice.dtDOS || '-'}}</td>
              <td [matTooltip]="invoice.creationTime || '-'" >{{ invoice.creationTime || '-'}}</td>
              <td [matTooltip]="invoice.modifiedTime || '-'" >{{ invoice.modifiedTime || '-'}}</td>
              <td [matTooltip]="invoice.charges || '-'"  class="text-right">{{ invoice.charges  || '-'}}</td>
              <td [matTooltip]="invoice.balance || '-'" class="text-right">{{ invoice.balance || '-'}}</td>
              <td [matTooltip]="invoice.serviceLocationName || '-'" >{{ invoice.serviceLocationName ||'-' }}</td>
              <td [matTooltip]="invoice.processingMethod || '-'" >{{ invoice.processingMethod   ||'-' }}</td>
              <td [matTooltip]="invoice.billTo || '-'" >{{ invoice.billTo || '-'}}</td>
              <td>
                <!-- Check if the payor level is Primary -->
                <span [matTooltip]="invoice.priPayerName || '-'"   class="splSpan" *ngIf="invoice.payorLevel === 'Primary'; else check_Secondary">
                  {{ invoice.priPayerName || '-' }}
                </span>

                <!-- Check if the payor level is Secondary (this acts like 'else if') -->
                <ng-template #check_Secondary>
                  <span  [matTooltip]="invoice.secPayerName || '-'" class="splSpan" *ngIf="invoice.payorLevel === 'Secondary'; else show_Default">
                    {{ invoice.secPayerName || '-' }}
                  </span>
                </ng-template>

                <!-- Else block (this acts like 'else') -->
                <ng-template #show_Default  >
                  <span class="splSpan" [matTooltip]="'-'">
                    -
                  </span>
                </ng-template>
                <br />
                <div  class="d-flex align-items-center justify-content-between">
                  <span [matTooltip]="invoice.payorLevel || 'Other'" class="rounded-border w-100 text-center p-1 insurance-type-label">
                    {{ invoice.payorLevel || 'Other' }}
                  </span>
                  <span *ngIf="invoice.isPriAcceptElectronicClaim || invoice.isSecAcceptElectronicClaim"
                    matTooltip="Accepts Electronic Claims" class="text-center">
                    <!-- <mat-icon mat-icon-button class="ico-small"
                      >mail_outline</mat-icon
                    > -->
                    <img class="img-responsive pl-1 mb-1" src="assets/Billing Icons/Mail.png" alt="" height="11" />
                  </span>
                </div>
              </td>
              <td>
                <!-- Check if the payor level is Primary -->
                <span  [matTooltip]="invoice.priPlanTypeName || '-'" class="splSpan" *ngIf="invoice.payorLevel === 'Primary'; else checkSecondary">
                  {{ invoice.priPlanTypeName || '-' }}
                </span>

                <!-- Check if the payor level is Secondary (this acts like 'else if') -->
                <ng-template #checkSecondary>
                  <span [matTooltip]="invoice.secPlanTypeName || '-'" class="splSpan" *ngIf="invoice.payorLevel === 'Secondary'; else showDefault">
                    {{ invoice.secPlanTypeName || '-' }}
                  </span>
                </ng-template>

                <!-- Else block (this acts like 'else') -->
                <ng-template #showDefault>
                  <span class="splSpan" [matTooltip]="'-'">
                    -
                  </span>
                </ng-template>
              </td>
              <td class="text-center cwidth">
                <div class="d-flex align-items-center">
                  <span [ngStyle]="{
                      'background-color': invoice.colorCode,
                      color: updateTextColor(invoice.colorCode)
                    }" class="rounded-border ml-1 w-100 p-1 claim-status-label" [matTooltip]="invoice?.invoiceStatusName || '-'"  >
                    {{ invoice?.invoiceStatusName || "-" }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
  </div>
</div>
