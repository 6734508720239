<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 ml-2 fweight">Process Credit Card Payment</p>
        <button mat-icon-button mat-dialog-close class="close-button ml-2">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <form [formGroup]="creditCardForm">
        <div class="card card-body px-0">
          <div class="row">
            <div class="col-12">
              <!-- Order Number -->
              <mat-form-field class="col-4">
                <mat-label> Order Number </mat-label>
                <input
                  matInput
                  formControlName="txtOrderNumber"
                  type="text"
                  readonly
                />
              </mat-form-field>
              <!--Total Due-->
              <mat-form-field class="col-4">
                <mat-label> Total Due </mat-label>

                <input
                  matInput
                  formControlName="txtTotalDue"
                  type="text"
                  readonly
                />
              </mat-form-field>

              <!--Charge -->
              <mat-form-field class="col-4">
                <mat-label> Charge </mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="txtCharge"
                  readonly
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="card card-body mt-2 px-0">
          <div class="row">
            <div class="col-12">
              <!-- Card Type -->
              <mat-form-field class="col-4">
                <mat-label>Card Type <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpCardType">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtCardTpe"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let cardType of filteredCardTypes | async"
                    [value]="cardType.cardType"
                  >
                    {{ cardType.cardType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- Card No-->
              <mat-form-field class="col-8">
                <mat-label>Card No <span class="asterisk">*</span></mat-label>
                <input
                  maxlength="20"
                  [textMask]="{ mask: mask, guide: false }"
                  formControlName="txtCardNo"
                  type="text"
                  matInput
                />
              <mat-error *ngIf="this.creditCardForm.get('txtCardNo').invalid && (this.creditCardForm.get('txtCardNo').dirty || this.creditCardForm.get('txtCardNo').touched)">
                Invalid card number.
              </mat-error>
              </mat-form-field>
              <!-- Expires Month -->
              <mat-form-field class="col-4">
                <mat-label
                  >Expires Month <span class="asterisk">*</span></mat-label
                >
                <input
                  formControlName="txtExpiryMonth"
                  maxlength="2"
                  (keypress)="numberOnly($event)"
                  matInput
                  type="text"
                />
                <mat-error
                  *ngIf="
                    creditCardForm.get('txtExpiryMonth').hasError('min') ||
                    creditCardForm.get('txtExpiryMonth').hasError('max')
                  "
                >
                  Month must be between 1 and 12
                </mat-error>
              </mat-form-field>
              <!-- Expires Year -->
              <mat-form-field class="col-4 mb-1">
                <mat-label
                  >Expires Year <span class="asterisk">*</span></mat-label
                >

                <input
                  maxlength="4"
                  formControlName="txtExpiryYear"
                  matInput
                  type="text"
                />
                <mat-error
                  *ngIf="
                    creditCardForm.get('txtExpiryYear').hasError('min') ||
                    creditCardForm.get('txtExpiryYear').hasError('max')
                  "
                >
                  Year must be between {{ currentYear }} and {{ maxYear }}
                </mat-error>
              </mat-form-field>
              <!-- CVV2 -->
              <mat-form-field class="col-4">
                <mat-label>CVV2 <span class="asterisk">*</span></mat-label>
                <input
                  formControlName="txtCVV2"
                  maxlength="4"
                  matInput
                  type="text"
                />
                <mat-error
                  *ngIf="
                    this.creditCardForm.get('txtCVV2').invalid &&
                    (this.creditCardForm.get('txtCVV2').dirty ||
                      this.creditCardForm.get('txtCVV2').touched)
                  "
                >
                  Invalid CVV2
                </mat-error>
              </mat-form-field>
              <div
                class="col-12 mt-2 d-flex align-items-center justify-content-center"
              >
                <button
                  [disabled]="creditCardForm.invalid || isProcessButtonEnabled"
                  mat-raised-button
                  class="buttonColor"
                  (click)="processPayment()"
                >
                  Process Payment

                  <mat-icon *ngIf="isProcessButtonEnabled">
                    <!-- spinner-border    -->
                    <mat-spinner
                      class="spinner-border-sm"
                      diameter="20"
                    ></mat-spinner>
                  </mat-icon>
                </button>
              </div>
              <mat-divider class="col-12 my-2"></mat-divider>
              <mat-form-field class="col-12">
                <mat-label> Status </mat-label>
                <textarea matInput type="text" rows="3"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
