<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <form [formGroup]="clinicalForm">
    <div class="row" formGroupName="clinicalGeneral">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>General</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>DOD</mat-label>
              <!-- <input matInput formControlName="txtDOD" maxlength="40" type="text"> -->
              <input autocomplete="new-txtDOD" formControlName="txtDOD" placeholder="MM/DD/YYYY" matInput [matDatepicker]="myPicker1"
                maxlength="40" />
              <mat-datepicker-toggle matSuffix [for]="myPicker1"> </mat-datepicker-toggle>
              <mat-datepicker #myPicker1></mat-datepicker>
              <mat-error *ngIf="
            clinicalForm?.get('clinicalGeneral.txtDOD')?.touched &&
            clinicalForm?.get('clinicalGeneral.txtDOD')?.errors
              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
              <mat-label>Height(feet' inches'')</mat-label>
              <input matInput maxlength="5" mask="0'00''" formControlName="txtHeight" type="text">
              <mat-error *ngIf="
            clinicalForm?.get('clinicalGeneral.txtHeight')?.touched &&
            clinicalForm?.get('clinicalGeneral.txtHeight')?.errors
            ">
                Enter Minimum 3 Digits!
              </mat-error>
            </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Height(In Feet)</mat-label>
              <input autocomplete="new-txtGeneralHeightFeet" matInput type="text" formControlName="txtGeneralHeightFeet" maxlength="1" minlength="1" />
              <mat-error *ngIf="
                  clinicalForm?.get('clinicalGeneral.txtGeneralHeightFeet')?.touched &&
                  clinicalForm?.get('clinicalGeneral.txtGeneralHeightFeet')?.errors
                ">
                Enter Minimum 1 Digit!
              </mat-error>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Height(In Inches)</mat-label>
              <input autocomplete="new-txtGeneralHeightInches" matInput type="text" formControlName="txtGeneralHeightInches" maxlength="2" />
              <mat-error *ngIf="
              clinicalForm?.get('clinicalGeneral.txtGeneralHeightInches')?.touched &&
              clinicalForm?.get('clinicalGeneral.txtGeneralHeightInches')?.errors">
                Inches greater than 11 not allowed!
              </mat-error>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Weight(lbs)</mat-label>
              <input autocomplete="new-txtWeight" matInput maxlength="3" (keypress)="numberOnly($event)" formControlName="txtWeight" type="text">
              <mat-error *ngIf="
              clinicalForm?.get('clinicalGeneral.txtWeight')?.touched &&
              clinicalForm?.get('clinicalGeneral.txtWeight')?.errors?.number
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>


            <!-- <ng-select class="col-3" formControlName="txtPractioner" placeholder="Practitioner" maxlength="40">
              <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}
              </ng-option>
            </ng-select> -->


            <mat-form-field class="col-3">
              <mat-label>Practitioner</mat-label>
              <mat-select formControlName="txtPractioner" [(value)]="Practitioner" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtPractitionerFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredPractitioner | async" [value]="doctor.id">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPractitioner()" (click)="Practitioner=undefined;$event.stopPropagation()"
                *ngIf="Practitioner" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
    <br>
    <div class="row" formGroupName="clinicalOrder">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Ordering Doctor</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <!-- <button type="button" *ngIf="clinicalForm?.get('clinicalOrder.txtOrderingDoctor').value!==null"
            class="buttonColor btn-sm ml-2 mr-2"
            (click)="openDoctorModal(clinicalForm?.get('clinicalOrder.txtOrderingDoctor').value)">
            <i class="fa fa-pen"></i>
          </button> -->
            <button type="button" class="buttonColor btn-sm" (click)="openDoctorModal()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <mat-card-content>

          <div class="row">
            <!-- <ng-select class="col-12" formControlName="txtOrderingDoctor"
            placeholder="Ordering Doctor" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}
            </ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Ordering Doctor</mat-label>
              <mat-select formControlName="txtOrderingDoctor" [(value)]="OrderingDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtOrdSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredOrderingDoctorTypes | async" [value]="doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearOrderingDoctor()"
                (click)="OrderingDoctor=undefined;$event.stopPropagation()" *ngIf="OrderingDoctor" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
          </div>
        </mat-card-content>
        <!-- <mat-card-content>
        <div class="row">
            <mat-form-field class="col-12">
                <mat-label>Ordering Doctor</mat-label>
                <input matInput  maxlength="40" formControlName="txtOrderingDoctor" type="text">
              </mat-form-field>
        </div>
      </mat-card-content> -->

      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Marketing Rep</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <!-- <button type="button text-center" align="middle"
                  class="btn btn-primary waves-effect right-bar-toggle waves-light mb-2 mr-2">
                  <i class="bx bx-copy-alt font-size-16 align-middle mr-2"></i>Same as Referral
                </button> -->
            <button mat-button class="buttonColor mr-1 font-size-12 btn-sm" (click)='sameAsReferalDoctor()'>
              <i class="bx bx-copy-alt font-size-16 mr-1"></i>Same as Referral</button>
          </div>
        </div>
        <!-- <mat-card-content>
        <div class="row">
            <mat-form-field class="col-12">
                <mat-label>Marketing Rep</mat-label>
                <input matInput  maxlength="40" formControlName="txtMarketingRep" type="text">
              </mat-form-field>
        </div>
      </mat-card-content> -->
        <mat-card-content>
          <div class="row">
            <!-- <mat-form-field class="col-12">
            <mat-label>Marketing Rep</mat-label>
            <mat-select formControlName="txtMarketingRep">
              <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

            <!-- <ng-select class="col-12" formControlName="txtMarketingRep" placeholder="Marketing Rep" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Marketing Rep</mat-label>
              <mat-select formControlName="txtMarketingRep" [(value)]="MarRepDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtMarRepSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredMarRepDoctorTypes | async" [value]="doctor.id">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearMarRepDoctor()" (click)="MarRepDoctor=undefined;$event.stopPropagation()"
                *ngIf="MarRepDoctor" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>

    <br>
    <div class="row">
      <!-- <div class="col-lg-4" formGroupName="clinicalRender">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Rendering Provider</b></h4>
          </div>
        </div>
        <mat-card-content> -->
          <!-- <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Type</mat-label>
              <mat-select formControlName="drpRenderType" [(value)]="renderingtype"> -->
          <!-- <mat-option *ngFor="let render of drpRenderType" [value]="render.renderingProviderShortCodeId">
                {{ render.renderingProviderType }}
              </mat-option> -->

          <!-- <mat-option *ngFor="let test of drpRenderingTypeLoop" [value]="test.renderingProviderShortCodeId">
                  {{ test.renderingProviderName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearRenderingType()"
                (click)="renderingtype=undefined;$event.stopPropagation()" *ngIf="renderingtype" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
          <!-- <ng-select class="col-6" formControlName="txtRenderDoctor" placeholder="Doctor" maxlength="40">
              <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}</ng-option>
            </ng-select> -->
          <!-- <mat-form-field class="col-6">
              <mat-label>Doctor</mat-label>
              <mat-select formControlName="txtRenderDoctor" [(value)]="RenderingDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtRenDoctorFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredRenDoctorTypes | async" [value]="doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearRenderingDoctor()"
                (click)="RenderingDoctor=undefined;$event.stopPropagation()" *ngIf="RenderingDoctor" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->

          <!-- Provider  -->
          <!-- <mat-form-field class="col-6">
              <mat-label>Provider</mat-label>
              <mat-select maxlength="40" [(value)]="provider" formControlName="txtRenderDoctor">
                <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                  {{ providers.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="provider='';$event.stopPropagation()" *ngIf="provider" matSuffix
                (click)="clearProvider()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
          <!--  -->

          <!-- <mat-form-field class="col-6">
            <mat-label>Doctor</mat-label>
            <mat-select formControlName="txtRenderDoctor">
              <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <!-- <mat-form-field class="col-6">
              <mat-label>Facility</mat-label> -->
          <!-- <input matInput  maxlength="40" formControlName="txtRenderFacility" type="text"> -->
          <!-- <mat-select formControlName="txtRenderFacility" [(value)]="renderFacility">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select> -->
          <!-- <button mat-button (click)="clearRenderFacility()"
                (click)="renderFacility=undefined;$event.stopPropagation()" *ngIf="renderFacility" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button> -->
          <!-- </mat-form-field>
          </div> -->
        <!-- </mat-card-content>
      </div> -->
      <!-- <div class="row" > -->
      <div class="col-lg-6" formGroupName="clinicalRefer">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Referring Provider</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Type</mat-label>
              <mat-select formControlName="drpReferring" [(value)]="referringType">
                <!-- <mat-option *ngFor="let referring of drpReferring" [value]="referring.referringProviderShortCodeId">
                {{referring.referringProviderType}}
              </mat-option> -->
                <mat-option *ngFor="let marketingReferralType of drpMarketingtypeLoop"
                  [value]="marketingReferralType.marketingReferralShortCodeId">
                  {{ marketingReferralType.marketingReferralTypeName }}

                </mat-option>
              </mat-select>
              <button mat-button (click)="clearReferringType()"
                (click)="referringType=undefined;$event.stopPropagation()" *ngIf="referringType" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- <mat-form-field class="col-6">
            <mat-label>Doctor</mat-label>
            <mat-select formControlName="txtReferringDoctor">
              <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

            <!-- <ng-select class="col-6" formControlName="txtReferringDoctor" placeholder="Doctor" maxlength="40">
              <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}</ng-option>
            </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>Doctor</mat-label>
              <mat-select formControlName="txtReferringDoctor" [(value)]="ReferringDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtRefDoctorFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredRefDoctorTypes | async" [value]="doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearReferringDoctor()"
                (click)="ReferringDoctor=undefined;$event.stopPropagation()" *ngIf="ReferringDoctor" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Facility</mat-label>
              <!-- <input matInput  maxlength="40" formControlName="txtReferringFacility" type="text"> -->
              <mat-select formControlName="txtReferringFacility" [(value)]="referringFacility">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearReferringFacility()"
                (click)="referringFacility=undefined;$event.stopPropagation()" *ngIf="referringFacility" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6" formGroupName="clinicalMarketing">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Marketing Referral</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <!-- <button type="button" *ngIf="clinicalForm?.get('clinicalMarketing.drpMarketingRef').value!==null"
            class="buttonColor btn-sm ml-2 mr-2"
            (click)="openDoctorModal(clinicalForm?.get('clinicalMarketing.drpMarketingRef').value)">
            <i class="fa fa-pen"></i>
          </button> -->
            <button type="button" class="buttonColor btn-sm" (click)="openDoctorModal()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Type</mat-label>
              <mat-select formControlName="drpMarketingRef" [(value)]="markettingRefType" maxlength="40">
                <!-- <mat-option *ngFor="let marketing of drpMarketingRef" [value]="marketing.marketingReferralTypeId">
                {{marketing.marketingReferralType}}
              </mat-option> -->
                <mat-option *ngFor="let marketingReferralType of drpMarketingtypeLoop"
                  [value]="marketingReferralType.marketingReferralShortCodeId">
                  {{ marketingReferralType.marketingReferralTypeName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearMarketingRefType()"
                (click)="markettingRefType=undefined;$event.stopPropagation()" *ngIf="markettingRefType" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- <mat-form-field class="col-6">
            <mat-label>Doctor</mat-label>
            <mat-select formControlName="txtMarketingDoctor">
              <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

            <!-- <ng-select class="col-6" formControlName="txtMarketingDoctor" placeholder="Doctor" maxlength="40">
              <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}</ng-option>
            </ng-select> -->

            <mat-form-field class="col-6">
              <mat-label>Doctor</mat-label>
              <mat-select formControlName="txtMarketingDoctor" [(value)]="MarketingDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtMarDoctorFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredMarDoctorTypes | async" [value]="doctor.id">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearMarketingDoctor()"
                (click)="MarketingDoctor=undefined;$event.stopPropagation()" *ngIf="MarketingDoctor" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>


            <mat-form-field class="col-6">
              <mat-label>Facility</mat-label>
              <!-- <input matInput  maxlength="40" formControlName="txtMarketingFacility" type="text"> -->
              <mat-select formControlName="txtMarketingFacility" [(value)]="marketingFacility">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearMarketingFacility()"
                (click)="marketingFacility=undefined;$event.stopPropagation()" *ngIf="marketingFacility" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <!-- </div> -->
    </div>

    <br>
    <div class="row" formGroupName="clinicalDiagnosis">
      <div class="col-lg-4">
        <div class="row">
          <div class="col-md-9">
            <!-- <h4 *ngIf='!isIcd9Shown' class="customThemeClass"><b>Diagnosis Codes-ICD-9 <span
                  class="asterisk">*</span></b>
            </h4> -->
            <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-9</b></h4>
          </div>
          <div class="col-md-3 text-right">
            <button type="button" class="buttonColor btn-sm" (click)="openIcd9Modal()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <mat-form-field class="col-12">
            <mat-label>Diagnosis Codes-ICD-9</mat-label>
          <input matInput formControlName="txtDiagnosis9" type="text">
           <mat-select formControlName="txtDiagnosis9">
              <mat-option *ngFor="let diagnosis9 of drpDiagnosis9" [value]="diagnosis9.diagnosisCodeIcd9Id">
                {{ diagnosis9.diagnosisCodeIcd9Type }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
            <!-- <ng-select class="col-12" (change)='onIcd9Change($event)' formControlName="txtDiagnosis9"
            placeholder="Diagnosis Code-ICD-9" maxlength="40">
            <ng-option *ngFor="let diagnosis of drpDiagnosisICD9" [value]="diagnosis.diagnosisCode9ShortCodeId">
              {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Diagnosis Code ICD-9</mat-label>
              <mat-select multiple formControlName="txtDiagnosis9" [(value)]="Icd9" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd9Filter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let diagnosis9 of filteredIcd9Types | async"
                  [value]="diagnosis9.diagnosisCode9ShortCodeId">
                  {{ diagnosis9.diagnosisCodeTypeData }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearIcd9()" (click)="Icd9=[];$event.stopPropagation()"
                *ngIf="Icd9?.length!==0" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-10 <span class="asterisk">*</span></b></h4>
            <!-- <h4 *ngIf='isIcd10Shown' class="customThemeClass"><b>Diagnosis Codes-ICD-10</b></h4> -->
          </div>
          <div class="col-md-6 text-right">
            <button type="button" class="buttonColor btn-sm btn-circle" (click)="openIcd10Modal()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <ng-select class="col-4" (change)='onIcd10Change1($event)' formControlName="txtDiagnosis10Id1"
              placeholder="Diagnosis Code-ICD-10" maxlength="40">
              <ng-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="diagnosis.diagnosisCode10ShortCodeId">
                {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
            </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Diagnosis Code-ICD-10</mat-label>
              <mat-select multiple formControlName="txtDiagnosis10Id1" [(value)]="txt1Icd10" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd10Filter1"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let diagnosis of filtered1Icd10Types | async"
                  [value]="diagnosis.diagnosisCode10ShortCodeId">
                  {{ diagnosis.diagnosisCodeTypeData }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="cleartxt1Icd10()" (click)="txt1Icd10=[];$event.stopPropagation()"
                *ngIf="txt1Icd10!==[]" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
              clinicalForm?.get('clinicalDiagnosis.txtDiagnosis10Id1')?.touched &&
              clinicalForm?.get('clinicalDiagnosis.txtDiagnosis10Id1')?.errors
            ">
                Diagnosis Code-ICD-10 is a required field!
              </mat-error>
            </mat-form-field>

            <!-- <ng-select class="col-4" (change)='onIcd10Change2($event)' formControlName="txtDiagnosis10Id2"
              placeholder="Diagnosis Code-ICD-10" maxlength="40">
              <ng-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="diagnosis.diagnosisCode10ShortCodeId">
                {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
            </ng-select> -->
            <!-- <mat-form-field class="col-4">
              <mat-label>Diagnosis Code-ICD-10</mat-label>
              <mat-select formControlName="txtDiagnosis10Id2" (selectionChange)='onIcd10Change2($event.value)'
                [(value)]="txt2Icd10"
                #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd10Filter2"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let diagnosis of filtered2Icd10Types | async" [value]="diagnosis.diagnosisCode10ShortCodeId">
                  {{ diagnosis.diagnosisCodeTypeData }}
                </mat-option>
              </mat-select>
              <button mat-button  (click)="cleartxt2Icd10()" (click)="txt2Icd10=undefined;$event.stopPropagation()" *ngIf="txt2Icd10" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
            <!-- <ng-select class="col-4" (change)='onIcd10Change3($event)' formControlName="txtDiagnosis10Id3"
              placeholder="Diagnosis Code-ICD-10" maxlength="40">
              <ng-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="diagnosis.diagnosisCode10ShortCodeId">
                {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
            </ng-select> -->
            <!-- <mat-form-field class="col-4">
              <mat-label>Diagnosis Code-ICD-10</mat-label>
              <mat-select formControlName="txtDiagnosis10Id3" (selectionChange)='onIcd10Change3($event.value)'
                [(value)]="txt3Icd10"
                #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd10Filter3"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let diagnosis of filtered3Icd10Types | async" [value]="diagnosis.diagnosisCode10ShortCodeId">
                  {{ diagnosis.diagnosisCodeTypeData }}
                </mat-option>
              </mat-select>
              <button mat-button  (click)="cleartxt3Icd10()" (click)="txt3Icd10=undefined;$event.stopPropagation()" *ngIf="txt3Icd10" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->


          </div>
        </mat-card-content>
      </div>

    </div>

    <br>
    <div class="row" formGroupName="clinicalEpsdt">
      <div class="col-lg-4">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>EPSDT Referral</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Certification Condition Indictor</mat-label>
              <mat-select formControlName="drpCertification" [(value)]="certification" maxlength="40">
                <mat-option *ngFor="let certification of drpCertification" [value]="certification.shortCodeId">
                  {{certification.certificationIndicatorType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCertification()"
                (click)="certification=undefined;$event.stopPropagation()" *ngIf="certification" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Condition Code</mat-label>
              <mat-select formControlName="drpCondition" [(value)]="conditionCode">
                <mat-option *ngFor="let condition of drpCondition" [value]="condition.shortCodeId">
                  {{condition.conditionCodeType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearConditionCode()"
                (click)="conditionCode=undefined;$event.stopPropagation()" *ngIf="conditionCode" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <br>
  <div class="row">
    <div class="col-sm-12">
      <div class="text-lg-center ">
        <button *ngIf="orderStatus" mat-button class="buttonColor" (click)="getPatientDetailsForSave()"
          [disabled]=" saveButtonHide|| !(!clinicalForm.invalid )">
          Save
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
