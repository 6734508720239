import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MailTemplateDetailsService, MailTemplateMasterService } from '../admin-proxy/platform-app-management/rcm/platform-management/email-template';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpErrorResponse } from '@angular/common/http';
import emailMask from 'text-mask-addons/dist/emailMask';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CreateUpdateMailTemplateDetailsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/email-template/dto';
import { RxPreviewDocumentComponent } from 'projects/order/src/app/rx-preview-document/rx-preview-document.component';
import { MatDialog } from '@angular/material/dialog';
import { PracticeManagementService } from 'projects/patient/src/app/patient-proxy/practice-management';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MatSelectChange } from '@angular/material/select';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-rx-email-print-letter',
  templateUrl: './rx-email-print-letter.component.html',
  styleUrls: ['./rx-email-print-letter.component.scss']
})
export class RxEmailPrintLetterComponent implements OnInit {
  EmailForm: FormGroup;
  emailMask: any;
  editorContent: string;
  saveButtonHide: boolean;
  patientId: string;
  documentId: string;
  tenantId:string;
  subscription$: Subscription[] = [];
  filteredTemplate: any;
  lstTemplate: any;
  templateId: any;
  lstPractices: any;
  isRxView: boolean = false;
  filteredPractices: any;
  practiceSubscription:Subscription;
  constructor(private title: Title, private formBuilder: FormBuilder,
    public InboundDocumentEmailService: InboundDocumentService,
     private toastr: ToastrService,
     private activatedRoute: ActivatedRoute,
     private mailTemplateMasterService: MailTemplateMasterService,
     private dialog: MatDialog,
     private mailTemplateDetailsService:  MailTemplateDetailsService,
     private router: Router,
     private practiceManagementService: PracticeManagementService
    ) { }
     name = 'Email/PrintLetter';
     htmlContent = '';
     config: AngularEditorConfig = {
       editable: true,
       spellcheck: true,
       height: '35rem',
       minHeight: '5rem',
       placeholder: 'Enter text here...',
       translate: 'no',
       defaultParagraphSeparator: 'p',
       defaultFontName: 'Arial',
       toolbarHiddenButtons: [
         [ 'insertImage','insertVideo'], // hide  video icons
       ],
       // toolbarHiddenButtons: [
       //   ['bold']
       // ],
       customClasses: [
         {
           name: "quote",
           class: "quote",
         },
         {
           name: 'redText',
           class: 'redText'
         },
         {
           name: "titleText",
           class: "titleText",
           tag: "h1",
         },
       ]
     };
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Email-PrintLetter');
    this.emailMask = emailMask;
    this.EmailForm = this.formBuilder.group({
      txtEmail: new FormControl("", [Validators.required]),
      editorContent: new FormControl("", [Validators.required]),
      drpTemplate: new FormControl("",[Validators.required]),
      txtSubject: new FormControl('',[Validators.required]),
      txtTemplate: new FormControl(''),
      txtPracticeFilter: new FormControl(''),
      drpPracticeManagement: new FormControl('',[Validators.required])
    });

    this.loadTemplate()
    this.loadPracticeManagements()
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId =  response.get('patientId') || defaultGuid;
        this.documentId =  response.get('docId') || defaultGuid;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);

    if (this.patientId && this.patientId !== defaultGuid) {
        this.getRxReportDetails();
    }
  }
  // ngAfterViewInit(): void {
  //   this.addCustomButton();
  // }
  // addCustomButton() {
  //   // Create a style element
  //   const style = document.createElement('style');
  //   style.type = 'text/css';
  //   style.innerHTML = `
  //     .custom-attach-button {
  //       color: black;
  //       cursor: pointer;
  //       background-color: #fff;
  //       vertical-align: middle;
  //       border: 1px solid #ddd;
  //       padding: .4rem;
  //       min-width: 2rem;
  //       float: left;
  //       margin-left: 2px;
  //       margin-right: 3px;
  //     }
  //     .custom-attach-button:hover {
  //       background-color: #e7e8ea;
  //     }
  //   `;
  //   document.head.appendChild(style);
  //   // Create the custom button
  //   const toolbar = document.querySelector('.angular-editor-toolbar');
  //   if (toolbar) {
  //     const button = document.createElement('button');
  //     button.innerHTML = '<i class="fa fa-paperclip"></i>';
  //     button.className = 'angular-editor-button custom-attach-button';
  //     button.title = 'Attachment';
  //     button.onclick = () => this.attachFile();
  //     toolbar.appendChild(button);
  //   }
  // }
  // attachFile() {
  //   const input = document.createElement('input');
  //   input.type = 'file';
  //   input.onchange = (event: any) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //       // Handle the file attachment logic here
  //       console.log('File attached:', file);
  //     }
  //   };
  //   input.click();
  // }
  fnSendEmail() {
    this.saveButtonHide = true;
    // const patientID = this.patientId;
    const patientID= defaultGuid;
    const sContent = this.EmailForm.get('editorContent').value;
    const sToAddress = this.EmailForm.get('txtEmail').value;
    if (!sContent) {
      console.error('Content is empty');
      return;
    }
    const templateDetails: CreateUpdateMailTemplateDetailsDTO ={
      templateId: this.templateId.id,
      toEmail: this.EmailForm.value.txtEmail.toLowerCase(),
      subject: this.EmailForm.value.txtSubject?? '',
      isActive: 0,
      isMailSent: true,
      tenantId: this.tenantId,
      documentId: this.documentId,
      patientId: this.patientId,
      mailContent: this.EmailForm.value.editorContent,
      mailstatusReason: ''
    }

    this.mailTemplateDetailsService.create(templateDetails)
      .subscribe(
        (response) => {
          this.saveButtonHide = false;
          if (response) {
            this.toastr.success('Successfully Send Mail', 'Success');
            this.EmailForm.reset();
          } else {
            this.toastr.error('Failed to send email', 'Error');
            this.EmailForm.reset();
          }
        },
        (error) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = error;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }


  loadTemplate(){
    const template = this.mailTemplateMasterService.getStatusBasedMailTemplateList(1).subscribe(response => {
      this.lstTemplate = response;
      console.log(response);
      this.filteredTemplate = this.EmailForm
      .get('txtTemplate')
      .valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.lstTemplate?.filter((option) =>
            option?.templateName
              ?.toLowerCase()
              ?.includes(value?.toLowerCase() ?? '')
          )
        )
      );
  },
  (err) => {}
);

  }

  selectTemaplate(event:MatSelectChange):void {
    this.isRxView = true;
    this.templateId = event.value;
    const getTemplate = {
      templateId: this.templateId.id,
      patientId: (this.patientId === null ? defaultGuid : this.patientId )
    }

    this.mailTemplateMasterService.getMailContentByDTO(getTemplate).subscribe(response => {
      this.EmailForm.patchValue({
        editorContent: response.mailContent,
        // txtEmail: response.email ?? '',
      });

    },(err) => {
      const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
    })
  }
  selectPractitioner(event: MatSelectChange): void {
    const practiceId = event.value;
    if (practiceId) {
      if(this.practiceSubscription){
          this.practiceSubscription.unsubscribe();
      }
      this.practiceSubscription = this.mailTemplateDetailsService.getPracticeEmailByPracticeId(practiceId).subscribe(
        (response) => {
          this.EmailForm.patchValue({
            txtEmail: response || '',
          });
        },
        (err: HttpErrorResponse) => {
          Swal.fire({
            icon: 'info',
            text: err?.error?.error?.message,
          });
        }
      );
    }
  }

  backToRxReport(){
    this.router.navigate(['/rx-report']);
  }
    //Method to load the Practice Managements
    loadPracticeManagements() {
      const practiceList = this.practiceManagementService
        .getListV1(new PagedAndSortedResultRequestDto())
        .subscribe(
          (response) => {
            this.lstPractices = response;
            this.filteredPractices = this.EmailForm
              .get('txtPracticeFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.lstPractices?.filter((option) =>
                    option?.practiceName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {}
        );
    }

    reset(){
      this.isRxView = false;
      this.EmailForm.reset()
      this.EmailForm.patchValue({
        txtEmail: '',
        drpTemplate: '',
        txtSubject: '',
        editorContent: ''
      })
      this.clearValidationErrors()
    }

      // Clear validation errors without triggering validation.
  clearValidationErrors() {
    Object.keys(this.EmailForm.controls).forEach((key) => {
      this.EmailForm.get(key).setErrors(null);
    });
  }

  previewRXDocument(){
    const dialogRef = this.dialog.open(RxPreviewDocumentComponent, {
      height: '80%',
      width: '80%',
      disableClose: true,
      data: {
        patientId:this.patientId,
        docId: this.documentId,
        viewType: false,
        isRxView: true

      },
    });
  }
  getTemplateObjectById(id: string): any {
    if (this.lstTemplate) {
      return this.lstTemplate.find((temp) => temp?.id === id);
    } else {
      return null;
    }
  }
  getRxReportDetails() {
    this.mailTemplateDetailsService.getPracticeDetailsByPatientId(this.patientId).subscribe(
      (response) => {
        if (response) {
          const selectedTemplate = this.getTemplateObjectById(response?.templateId);
          this.EmailForm.patchValue({
            drpTemplate: selectedTemplate,
            drpPracticeManagement: response?.practiceId,
            txtEmail: response?.practiceEmail
          });
          this.selectTemaplate({ value : selectedTemplate } as MatSelectChange);
        }
      },
      (error: HttpErrorResponse) => {
      }
    );
  }
}
