import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medicare-check-final-model',
  templateUrl: './medicare-check-final-model.component.html',
  styleUrls: ['./medicare-check-final-model.component.scss']
})
export class MedicareCheckFinalModelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
