<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <div class="row mar">
          <!-- <mat-form-field class="col-3">
                    <mat-label>Default Price Option Name</mat-label>
                    <input matInput type="text">
                </mat-form-field>
                <div class="col-md-3">
                    <button mat-button class="buttonColor font-size-14">Update Price Option</button>
                </div> -->
          <div class="col-12 text-right">
            <button mat-button class="buttonColor font-size-14" (click)="openItemModal()">
              <!-- <i class="mdi mdi-plus mr-1"></i> -->
              <i class="fa fa-plus mr-1" ></i>
              Add Item
            </button>
          </div>
        </div>
      </mat-card-content>
    </div>
    <div class="col-12 table-responsive" style="padding-top: 6px;">
      <!-- <app-table (viewButton)="ViewOrder($event)" (deleteButton)="deleteItem($event)" [isLoading]="isLoading" [strPageType]="strPageType">
      </app-table> -->
      <table class="table table-bordered ">
        <thead>
          <th>DOS</th>
          <th>Item Name</th>
          <th>Item code</th>
          <th>Price Per Unit</th>
          <th>Units</th>
          <th>Price</th>
          <th>Auto Claim</th>
          <!-- <th>Follow Up</th> -->
          <th>Options</th>
        </thead>
        <tbody>
          <tr *ngFor="let record of tblNotesReponse; let i=index">
            <td>
              {{record?.originalDos }}
            </td>
            <td>
              {{record?.name }}
            </td>
            <!-- Newly added based on Icc Requirement -->
            <td>
              {{ record?.itemIdOrBarCode}}
            </td>
            <td>
              $ {{+record?.billed | number : '.2-2' }}
            </td>
            <td>
              {{+record?.qty }}
            </td>
            <td>
              $ {{+record?.indTotalPrice | number : '.2-2'}}
            </td>
            <td>
              <ng-container *ngIf="record?.AutoClaim==1">
                <mat-slide-toggle class="mat-slide-toggle-thumb" [disabled]="true" [checked]='true' class="ml-2"
                  readonly>
                  {{"Active"}}</mat-slide-toggle>
              </ng-container>
              <ng-container *ngIf="record?.AutoClaim==0">
                <mat-slide-toggle class="mat-slide-toggle-thumb" [disabled]="true" [checked]='false' class="ml-2"
                  readonly>
                  {{"InActive"}}</mat-slide-toggle>
              </ng-container>
            </td>
            <!-- <td>
              <ng-container >
                <mat-slide-toggle class="mat-slide-toggle-thumb" [disabled]="true" [checked]='record?.isFollowupNeeded' class="ml-2"
                  readonly>{{record?.isFollowupNeeded ? record.followUpDuration+ " days": ""}}</mat-slide-toggle>
              </ng-container>
            </td> -->
            <td>
              <a (click)="ViewOrder(record)" class="eyeCursorclass" target="_blank"><i
                  class="fa fa-pencil mr-3 pencilColor"></i> </a>
              <a class="deleteBtnColor">
                <i *ngIf="orderStatus" (click)="deleteItem(record)" class="fa fa-trash" ></i>
              </a>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right"><b>Total</b></td>
            <td colspan="1"><b>{{totalQty}}</b> </td>
            <td colspan="1"><b>$ {{+totalPrice | number : '.2-2'}}</b> </td>
            <td colspan="4"></td>
          </tr>
        </tbody>
        <tfoot>
          <ng-container>
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="12">
              No Records Found
            </td>
          </ng-container>
        </tfoot>
      </table>
    </div>
  </div>
  <br>
  <!-- <app-table  [strPageType]="strPageType" >
</app-table> -->
  <br>
  <form [formGroup]="itemForm">
    <div class="row">
      <div class="col-lg-12">
        <div class="row mar-top">
          <div class="col-md-6" [hidden]="true">
            <h4 class="customThemeClass"><b>Quick Add</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">

            <mat-form-field [hidden]="true" class="col-3">
              <mat-label>Sale Type</mat-label>
              <mat-select formControlName="drpSaleType" [(value)]="saleType">
                <mat-option *ngFor="let saleType of drpSaleType" [value]="saleType.shortCodeId">
                  {{saleType.saleTypeName}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearSaleType()" (click)="saleType='';$event.stopPropagation()"
                *ngIf="saleType" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3" [hidden]="true">
              <mat-label>Units <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtQty" matInput formControlName="txtQty" maxlength="2" type="text">
              <mat-error *ngIf="itemForm?.get('txtQty')?.touched &&
                    itemForm?.get('txtQty')?.errors?.number">
                Enter only Numbers!
              </mat-error>
              <mat-error *ngIf="itemForm?.get('txtQty')?.touched &&
            itemForm?.get('txtQty')?.errors?.required">
                Units is a required field.
              </mat-error>
              <mat-error *ngIf="
                itemForm?.get('txtQty')?.touched &&
                itemForm?.get('txtQty')?.errors
              ">
                Enter Greater than 1!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6" [hidden]="true">
              <mat-label>Item Id / Bar Code<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="txtBarcode" [(value)]="txtItem" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let code of filteredItemTypes | async" [value]="code">
                  {{code.productNames}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="itemForm?.get('txtBarcode')?.touched &&
                itemForm?.get('txtBarcode')?.errors?.required">
                Item Id/ Bar Code is Required Field
              </mat-error>
              <button mat-button (click)="clearItemType()" (click)="txtItem='';$event.stopPropagation()" *ngIf="txtItem"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
</div>
