import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { RoleService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { IdentityUserCreateUsingTenantIdDto, IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { SearchUserDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/users/dto/models';
import { IdentityUserUpdateDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/volo/abp/identity/models';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { OntimeUserWorkHoursComponent } from '../ontime-user-work-hours/ontime-user-work-hours.component';
import { ToastrService } from "ngx-toastr";
import PlaceResult = google.maps.places.PlaceResult;
import { Title } from '@angular/platform-browser';
// import { any } from 'projects/billing/src/app/claim-proxy/claim-processing-management/system';

declare const google: any

@Component({
  selector: 'app-ontime-user',
  templateUrl: './ontime-user.component.html',
  styleUrls: ['./ontime-user.component.scss'],
  providers: [DatePipe]

})
export class OntimeUserComponent implements OnInit {
  mapHide: boolean = false;
  maps: boolean = false;
  isShowSpinner: boolean = false;
  OnTimeUserForm: FormGroup;
  userSearchForm: FormGroup;
  phoneCode: string = '';
  drpRoles: any[] = [];
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  appointTypeId: string = "";
  panelOpenState = false;
  step: boolean = false;
  public strPageType: string = "onTimeUser";
  userId: string = "";
  concurrencyStamp: string = "";
  hide = true;
  displayedColumns: string[] = ['name', 'phn', 'date', 'Status','Options', ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @Output() emitTenantFormState: EventEmitter<any> = new EventEmitter<any>();
  isLoading: boolean;
  activeStatus: string = "";
  status: boolean = false;

  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  addWorkingButton = false
  Country: string = "";
  State: string = "";
  City: string = "";
  role: string = "";
  branch: string = "";
  tenanatId: string = "";
  drpOrganization: OrganizationUnitDTO[] = [];
  emailMask: any;
  userTableData: any[] = [];
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  userData
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private regionDropdownService: RegionDropdownService,
    private roleService: RoleService,
    private userService: UserService,
    private tableService: TableService,
    private branchService: OrganizationUnitService,
    private dateValidator: DateValidator,
    private datepipe: DatePipe,
    private mapsAPILoader: MapsAPILoader,
    private toastr: ToastrService,
    public title: Title,


  ) { }

  ngOnInit(): void {

    this.title.setTitle("Qsecure | OnTime User");
    this.OnTimeUserForm = this.formBuilder.group({
      txtUserName: new FormControl("", Validators.required),
      txtCountry: new FormControl("", Validators.required),
      txtState: new FormControl("", Validators.required),
      txtCity: new FormControl(""),
      txtAddress1: new FormControl("", Validators.required),
      txtAddress2: new FormControl(""),
      txtPhnNo: new FormControl("", Validators.required),
      txtZipCode: new FormControl("", Validators.required),
      txtCityFilter: new FormControl(""),
      txtCountryFilter: new FormControl(""),
      txtStateFilter: new FormControl(""),
      txtRole: new FormControl("", Validators.required),
      txtBranchName: new FormControl("", Validators.required),
      txtEmail: new FormControl("", Validators.required),
      txtPassword: new FormControl("", Validators.required),
      statusToggle: new FormControl(""),
      txtMap: new FormControl("")

    });
    this.userSearchForm = this.formBuilder.group({
      // appointmentTypeSearch: new FormControl(""),
      nameSearch: new FormControl(""),
      phnSearch: new FormControl(""),
      dateSearch: new FormControl("", this.dateValidator.dateVaidator),
      statusSearch: new FormControl(""),
    });

    // valueChanges for filter
    this.userSearchForm.valueChanges.subscribe((value) => {
      let filter = {
        nameSearch: value?.nameSearch?.trim()?.toLowerCase(),
        phnSearch: value?.phnSearch?.trim()?.toLowerCase(),
        statusSearch: value?.statusSearch?.trim()?.toLowerCase(),
        dateSearch: value?.dateSearch?.trim()?.toLowerCase(),
      };


      if (this.userSearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
    });
    this.tableService.getTenantId().subscribe(value => {
      this.tenanatId = value;
    });
    this.emailMask = emailMask;
    this.countryDropdown();
    this.OnTimeUserForm.patchValue({
      txtMap: this.OnTimeUserForm.value.txtAddress1 + ', ' + this.OnTimeUserForm.value.txtCountry + ', ' +
        this.OnTimeUserForm.value.txtZipCode
    })
    this.OnTimeUserForm.patchValue({
      txtMap: this.OnTimeUserForm.value.txtAddress1 + ', ' + this.OnTimeUserForm.value.txtCountry + ', ' +
        this.OnTimeUserForm.value.txtZipCode
    })
    this.mapHide = false


  }



  latitude: number;
  longitude: number;
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;
  // latitude: number;
  // longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  // onLocationSelected(location: Location) {
  //   // this.latitude = location.latitude;
  //   // this.longitude = location.longitude;
  // }
  place: string = '';

  //! map confirm location
  mapOpen() {
    this.mapHide = true

    this.address = this.OnTimeUserForm.value.txtCity + ', ' + this.OnTimeUserForm.value.txtState + ' ' + this.OnTimeUserForm.value.txtZipCode + ', ' +
      this.OnTimeUserForm.value.txtCountry + ', ' + this.OnTimeUserForm.value.txtAddress1
    let geocoder = new google.maps.Geocoder();
    // return Observable.create(observer => {
    geocoder?.geocode({
      'address': this.address
    }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        this.latitude = results[0]?.geometry?.location.lat();
        this.longitude = results[0]?.geometry?.location.lng();
        //       this.longitude = place.geometry.location.lng();
        // observer.next(results[0].geometry.location);
        // observer.complete();
      } else {
        Swal.fire({
          icon: 'info',
          text: "Please Enter Valid Address",
        });
      }
    });
  }

  /// save user functionality
  saveOnTimeUser() {
    this.getenablefields();
    this.isShowSpinner = true;

    if (this.userId == null || this.userId == "" || this.userId == undefined) {
      let user: IdentityUserCreateUsingTenantIdDto = {
        tenantId: this.tenanatId,
        userName: this.OnTimeUserForm.value.txtUserName,
        name: "",
        surname: "",
        email: this.OnTimeUserForm.value.txtEmail.toLowerCase(),
        phoneNumber: this.OnTimeUserForm.value.txtPhnNo,
        lockoutEnabled: true,
        roleNames: [
          this.OnTimeUserForm.value.txtRole
        ],
        password: this.OnTimeUserForm.value.txtPassword,
        extraProperties: {
          organizationUnitId: this.OnTimeUserForm.value.txtBranchName,
          profileImageAsBase64String: "",
          address1: this.OnTimeUserForm.value.txtAddress1,
          address2: this.OnTimeUserForm.value.txtAddress2,
          country: this.OnTimeUserForm.value.txtCountry,
          state: this.OnTimeUserForm.value.txtState,
          city: this.OnTimeUserForm.value.txtCity,
          zipCode: this.OnTimeUserForm.value.txtZipCode,
          status: this.OnTimeUserForm.value.statusToggle === true ? "Active" : "Inactive",
          userWorkingHoursId: "00000000-0000-0000-0000-000000000000"
        }
      };

      this.userService.createUser(user).subscribe(() => {
        this.addWorkingButton = false
        this.getTableData();
        this.reset();
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      let user: IdentityUserUpdateDto = {
        userName: this.OnTimeUserForm.value.txtUserName,
        name: "",
        surname: "",
        email: this.OnTimeUserForm.value.txtEmail.toLowerCase(),
        phoneNumber: this.OnTimeUserForm.value.txtPhnNo,
        lockoutEnabled: true,
        roleNames: [
          this.OnTimeUserForm.value.txtRole
        ],
        password: this.OnTimeUserForm.value.txtPassword,
        extraProperties: {
          organizationUnitId: this.OnTimeUserForm.value.txtBranchName,
          profileImageAsBase64String: "",
          address1: this.OnTimeUserForm.value.txtAddress1,
          address2: this.OnTimeUserForm.value.txtAddress2,
          country: this.OnTimeUserForm.value.txtCountry,
          state: this.OnTimeUserForm.value.txtState,
          city: this.OnTimeUserForm.value.txtCity,
          zipCode: this.OnTimeUserForm.value.txtZipCode,
          status: this.OnTimeUserForm.value.statusToggle === true ? "Active" : "Inactive",
          // userWorkingHoursId:
        },
        concurrencyStamp: this.concurrencyStamp,

      };

      this.userService.updateUser(this.tenanatId, this.userId, user).subscribe(() => {
        this.getTableData();
        this.reset();
        this.addWorkingButton = false
        this.isShowSpinner = false;

        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  openAddWorkingModel() {
    const dialogRef = this.dialog.open(OntimeUserWorkHoursComponent, {
      height: "80%", width: "80%",
      disableClose: true,
      data: { userId: this.userId, addWorkingId: this.addWorkingId, userName: this.userName }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.reset()
    });
  }


  //! Search table data
  filtertable(filter) {
    let Date = filter.dateSearch;
    // let DateFormat = Date === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(Date));
    let searchInput: SearchUserDTO = {
      // tenantId?: string;
      // userName?: string;
      // phoneNumber?: string;
      // roleName?: string;
      // status?: string;
      // createdDate?: string;
      tenantId: this.tenanatId,
      userName: filter.nameSearch,
      phoneNumber: filter.phnSearch,
      status: filter.statusSearch,
      branch: "",
      email: "",
      roleName: "",
      createdDate: dateYYYYMMDDtoMMDDYYYY(Date)
    }
    this.userTableData = [];
    this.userService.searchUsersByInput(searchInput).subscribe(data => {
      this.userTableData = [];
      data?.forEach(element => {
        if (element.roleName === "Respiratory Therapist" || element.roleName === "Scheduler") {
          this.userTableData.push({
            name: element?.userName,
            phoneNumber: element?.phoneNumber,
            status: element?.extraProperties.status,
            createDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? ""
          });
          this.dataSource = new MatTableDataSource(this.userTableData);
          this.dataSource.sort = this.sort;
          setTimeout(() => this.dataSource.paginator = this.paginator);
        }
      })
    })
  }

  // Get Country Dropdown
  countryDropdown() {
    this.OnTimeUserForm.patchValue({ "txtCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;

    this.drpCountry = [];
    this.regionDropdownService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      //
      this.drpCountry = response?.items.filter(x => x.countryShortName == "US") ?? [];
      this.filteredCountries = this.OnTimeUserForm?.get("txtCountryFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.getTableData();
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.OnTimeUserForm.patchValue({
      drpState: null,
      drpCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    value !== null && value !== undefined && this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {

      this.drpStates = response?.items ?? [];

      if (response?.items?.length === 0) {
        this.phoneCode = "";
        this.OnTimeUserForm.controls['txtPhnNo'].disable();
      } else {
        this.OnTimeUserForm.controls['txtPhnNo'].enable();
        this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? "";
      }
      this.filteredStates = this.OnTimeUserForm?.get("txtStateFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpStates.filter(option =>
            option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
          ))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpStates.length !== 0;
  }
  // On Change City Dropdown

  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.OnTimeUserForm.patchValue({
      drpCity: null
    });
    country = (country == "") ? (this.OnTimeUserForm.value.txtCountry == (null || undefined) ? "" : (this.OnTimeUserForm.value.txtCountry)) : country;
    this.drpCities = [];
    state !== null && state !== undefined && this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {

      this.drpCities = response?.items ?? [];

      this.filteredCities = this.OnTimeUserForm?.get("txtCityFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpCities.length !== 0;
  }
  //!Get Role Dropdown
  loadRoleDropdown() {
    // let roleList = {
    // }
    // this.roleService.getRoleList(roleList).subscribe(response => {
    //   response.forEach(element => {
    //     if (element.Name === "Respiratory Therapist" || element.Name === "Scheduler") {
    //       this.drpRoles.push({
    //         id: element?.Id,
    //         name: element.Name
    //       })
    //     }

    //   });

      // this.drpRoles = response?.items??[];

    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'info',
    //     text: data?.error?.error?.message,
    //   });
    // })
  }


  //! get table data
  getTableData() {
    let filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.tenanatId
    }
    this.emitTenantFormState.emit(this.tenanatId);
    this.userService.getUserList(filters).subscribe(response => {
      this.userTableData = [];
      response && response?.forEach(element => {
        if (element.roleName === "Respiratory Therapist" || element.roleName === "Scheduler") {
          this.userTableData.push({
            name: element?.userName,
            email: element?.email,
            branch: this.drpOrganization?.filter(v => v?.id === element?.extraProperties.organizationUnitId)[0]?.organizationUnitName,
            role: element?.roleName,
            id: element?.id,
            phoneNumber: element?.phoneNumber,
            createDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
            status: element?.extraProperties?.status
          });
        }
      })
      // this.tableService.setOnTimeUserListTable(this.userTableData);
      const arrTableData = this.userTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  /// table view
  ViewUser($event) {

    this.step = !this.step;
    this.status = true;
    this.OnTimeUserForm.controls['txtPassword'].disable()
    this.getUserById($event.id)
  }


  //! getby Id
  stateName: string = "";
  CountryName: string = "";
  addWorkingId: string = "";
  userName: string = "";
  getUserById(id) {
    this.mapHide = false
    this.userService.getUserById(this.tenanatId, id).subscribe(response => {
      this.addWorkingButton = true
      this.userId = response.id;
      this.activeStatus = response.extraProperties.status
      this.userName = response?.userName
      this.addWorkingId = response.extraProperties.userWorkingHoursId;
      this.stateName = response?.extraProperties.state;
      this.CountryName = response?.extraProperties.country;

      this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
        this.onChangeState(this.CountryName) && this.OnTimeUserForm.patchValue({
          drpCountry: this.CountryName,
        });
      this.stateName != null && this.stateName !== "" && this.stateName != undefined &&
        this.onChangeCity(this.stateName, this.CountryName) && this.OnTimeUserForm.patchValue({
          drpState: this.stateName,
          drpCity: response?.extraProperties.city
        });
      this.concurrencyStamp = response?.concurrencyStamp ?? "";
      if (response?.extraProperties.status == "Active") {
        this.OnTimeUserForm.patchValue({
          txtUserName: response?.userName,
          txtEmail: response?.email,
          txtBranchName: response?.extraProperties.organizationUnitId,
          txtRole: response?.roleName,
          txtCountry: response?.extraProperties.country,
          txtState: response?.extraProperties.state,
          txtCity: response?.extraProperties.city,
          txtAddress1: response?.extraProperties.address1,
          txtAddress2: response?.extraProperties.address2,
          txtPhnNo: response?.phoneNumber,
          txtZipCode: response?.extraProperties.zipCode,
          statusToggle: response?.extraProperties.status == "Active" ? true : false,

        })
      }
      if (response?.extraProperties.status == "Inactive") {
        this.OnTimeUserForm.patchValue({
          txtUserName: response?.userName,
          txtEmail: response?.email,
          txtBranchName: response?.extraProperties.organizationUnitId,
          txtRole: response?.roleName,
          txtCountry: response?.extraProperties.country,
          txtState: response?.extraProperties.state,
          txtCity: response?.extraProperties.city,
          txtAddress1: response?.extraProperties.address1,
          txtAddress2: response?.extraProperties.address2,
          txtPhnNo: response?.phoneNumber,
          txtZipCode: response?.extraProperties.zipCode,
          statusToggle: response?.extraProperties.status == "Inactive" ? false : true,

        })
        this.addWorkingButton = false

        this.getdisablefields()
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  //! delete user from list
  deleteUser($event) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        //
        this.userService.deleteUser(this.tenanatId, $event.id).subscribe(response => {
          this.userTableData.forEach(element => {
            if ($event.id == element?.id) {
              let index = this.userTableData.indexOf(element, 0)
              this.userTableData.splice(index, 1)
            }
          });
          this.getTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
    this.reset();
  }


  //! reset ontimeuserForm
  reset() {
    this.OnTimeUserForm.patchValue({
      txtUserName: "",
      txtCountry: "",
      txtState: "",
      txtCity: "",
      txtAddress1: "",
      txtAddress2: "",
      txtPhnNo: "",
      txtZipCode: "",
      txtRole: "",
      txtBranchName: "",
      txtEmail: "",
      txtPassword: "",
    })
    this.OnTimeUserForm.reset();

    this.OnTimeUserForm.markAsUntouched();
    this.OnTimeUserForm.updateValueAndValidity();
    this.userId = "";
    this.addWorkingButton = false
    this.OnTimeUserForm.controls['txtPassword'].enable()
    this.status = false;
    this.getenablefields();
  }

  //! Load Organization Dropdown Function
  loadOrganizationDropdown() {
    this.branchService.getOrganizationList(this.tenanatId).subscribe(response => {
      this.drpOrganization = response
    })
  }

  clearCountry() {
    // this.OnTimeUserForm.controls['txtPhnNo'].disable();
    this.Country = "";
    this.OnTimeUserForm.patchValue({ drpCountry: "" })
  }

  clearState() {
    this.State = "";
    this.OnTimeUserForm.patchValue({ txtState: "" })
  }

  clearCity() {
    this.City = "";
    this.OnTimeUserForm.patchValue({ txtCity: "" })
  }

  clearRole() {
    this.role = '';
    this.OnTimeUserForm.patchValue({ txtRole: '' });
  }

  clearBranch() {
    this.branch = '';
    this.OnTimeUserForm.patchValue({ txtBranchName: '' });
  }

  sundayStatus(event: MatSlideToggleChange) {

    if (event.checked == true) {
      this.activeStatus = "Active"
      this.getenablefields();
    }
    if (event.checked === false) {
      Swal.fire({
        title: 'Are you sure you want to Inactive?',
        // text: 'You won\'t be able to retrieve this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes!'
      }).then(result => {
        if (result.isConfirmed === true) {
          this.OnTimeUserForm.patchValue({
            statusToggle: false
          })
          this.activeStatus = "Inactive"
          this.getdisablefields();
        }
        if (result.isConfirmed === false) {
          this.OnTimeUserForm.patchValue({
            statusToggle: true
          })
          this.getenablefields();
        }
      });
    }
  }

  getdisablefields() {
    this.OnTimeUserForm.controls['txtUserName'].disable()
    this.OnTimeUserForm.controls['txtCountry'].disable()
    this.OnTimeUserForm.controls['txtState'].disable()
    this.OnTimeUserForm.controls['txtCity'].disable()
    this.OnTimeUserForm.controls['txtAddress1'].disable()
    this.OnTimeUserForm.controls['txtAddress2'].disable()
    this.OnTimeUserForm.controls['txtPhnNo'].disable()
    this.OnTimeUserForm.controls['txtZipCode'].disable()
    this.OnTimeUserForm.controls['txtRole'].disable()
    this.OnTimeUserForm.controls['txtBranchName'].disable()
    this.OnTimeUserForm.controls['txtEmail'].disable()
  }

  getenablefields() {
    this.OnTimeUserForm.controls['txtUserName'].enable()
    this.OnTimeUserForm.controls['txtCountry'].enable()
    this.OnTimeUserForm.controls['txtState'].enable()
    this.OnTimeUserForm.controls['txtAddress1'].enable()
    this.OnTimeUserForm.controls['txtAddress2'].enable()
    this.OnTimeUserForm.controls['txtPhnNo'].enable()
    this.OnTimeUserForm.controls['txtZipCode'].enable()
    this.OnTimeUserForm.controls['txtRole'].enable()
    this.OnTimeUserForm.controls['txtBranchName'].enable()
    this.OnTimeUserForm.controls['txtEmail'].enable()
    this.OnTimeUserForm.controls['txtCity'].enable()
  }
}

function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}

// toggle view
