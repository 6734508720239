import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { OrganizationUnitService } from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TableService } from 'projects/shared/src/app/table.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { CreateUpdateFTPUnitDTO, OrganizationUnitDTO } from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { TenantsService } from '../../admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import { UserProfileDTO } from '../../admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-ftp-table',
  templateUrl: './ftp-table.component.html',
  styleUrls: ['./ftp-table.component.scss']
})
export class FtpTableComponent implements OnInit {
  @Input() tableFtp: any;
  @Input() ftpStatusView: number;
  @Input() clientId: string;
  FTPDetails:any;
  @ViewChild('thirdAccordion') thirdAccordion: MatAccordion;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  step: boolean = false;
  panelOpenState = false;
  panelOpenState1 = false;
  panelOpenState2 = false;
  addWorkingButton: boolean = false;
  saveButtonHide: boolean = false;
  isShowSpinner: boolean = false;
  inActiveHide: boolean = true;
  isLoading:boolean=false;
  ftpid: string = '';
  tenantId: string = '';
  isActive: number;
  FTPForm: FormGroup;
  selectedOption: string;
  isConfirmBranchLocation: boolean = false;
  param: number;
  param1: number;
  param2: number;
  seletedBranchValues: string = defaultGuid;
  filteredBranchTypeList: OrganizationUnitDTO[];
  lstBranch: OrganizationUnitDTO[] = [];
  loginResponse: UserProfileDTO;
  hide = true;
  BranchTypeControl = new FormControl();
  branchDrpData: any;
  drpBranchTypes:any;
  dtFTPListOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    columnDefs: [
      // { targets: [0], orderable: false },
      { targets: [8,9,10], visible: false },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'FTP List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(-1))',
      }
    ],
  };

    constructor(private formBuilder: FormBuilder,
    private branchService: OrganizationUnitService, private Table: TableService,
    private officialService: TenantsService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private commonService:CommonService
  ) {}

  ngOnInit(): void {

    this.dtFTPListOptions.buttons[0].filename =
    'FTP List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.loadDropdown();
    this.FTPForm = this.formBuilder.group({
      drpBranchTypes: new FormControl("", [Validators.required]),
      txtServerIP: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtPortNumber: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtUserName: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtUserPassword: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtSourceFolder: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtDesFolder: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtBranchType: new FormControl(""),
    });
    this.FTPDetails=this.tableFtp;
    this.Table.getclientLoginView().subscribe(response => {
      if (response?.tenantId != null && response?.roleName == 'admin' && response?.organizationUnitId == null) {
        this.clientId = response?.tenantId;
        this.loginResponse = response;
      } else {
        this.clientId != null && this.clientId != "" && this.clientId != undefined && this.getBranch()
      }
    });
    this.fnselectedFtpOption();

    //Branch Search Type
    this.BranchTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.filteredBranchTypeList = this.drpBranchTypes.filter((dx) =>
        dx.organizationUnitName.toLowerCase().includes(searchTerm)
      );
    });
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      dtInstance.search('').columns().search('').draw();
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  ViewFtp(event: { id: string; }) {
    window.scrollTo({ top: 0, behavior: 'smooth' });    // Scroll to the top of the page
    this.step = !this.step;
    this.thirdAccordion.id;
    this.panelOpenState = true;
    this.getFtpById(event.id)
  }
  getFtpById(id: string) {

    this.ftpid == '' ? this.addWorkingButton = true : this.addWorkingButton = false;
    this.branchService.getFtp(id).subscribe(data => {
      this.tenantId = this.tenantId,
        this.isActive = data?.isActive,
        this.ftpid = data?.id;
      this.FTPForm.patchValue({
        drpBranchTypes: data?.branchId,
        txtPortNumber: data?.portNumber,
        txtServerIP: data?.server_IP,
        txtUserName: data?.userName,
        txtUserPassword: data?.passWord,
        txtSourceFolder: data?.faxSourceFolder,
        txtDesFolder: data?.destinationFolder,
      });
    })
  }
  saveFTP() {
    this.saveButtonHide = true;
    this.isShowSpinner=true;
    this.isLoading=true;
    if ((this.ftpid == null || this.ftpid == "" || this.ftpid == undefined)) {
      let ftp: CreateUpdateFTPUnitDTO = {
        branchId: this.FTPForm.value.drpBranchTypes,
        tenantId: this.clientId,
        isActive: 1,
        portNumber: this.FTPForm.value.txtPortNumber,
        server_IP: this.FTPForm.value.txtServerIP,
        userName: this.FTPForm.value.txtUserName,
        passWord: this.FTPForm.value.txtUserPassword,
        faxSourceFolder: this.FTPForm.value.txtSourceFolder,
        destinationFolder: this.FTPForm.value.txtDesFolder,
      };
      this.branchService.createFtp(ftp).subscribe(response => {

        this.resetFTP();
        if (this.selectedOption === 'All') {
          this.param2 = 0;
        } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
          this.param2 = 2;
        }
        if(this.FTPDetails.length===0){
          this.communicationService.triggerLoadFtpList(this.param2);
         } else{
           if(this.selectedOption !== 'Inactive'){
        //  this.FTPDetails.push(response);
        const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
        response.lastModificationTime = userTimezonelastModificationTime;
        const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
        response.creationTime = userTimezonecreationTime;
        this.FTPDetails.unshift(response);
        // this.FTPDetails = this.FTPDetails.map((data) => {
        //   return {
        //     ...data,
        //     creationTime: this.commonService.getFormattedDateTimeZone(
        //       data.creationTime
        //     ),
        //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
        //       data.lastModificationTime
        //     ),
        //   };
        // });

           }
         }
         this.toastr.success('Saved Successfully', 'Success')
         this.saveButtonHide = false;
         this.isShowSpinner=false;
         this.isLoading = false;
        // this.FTPStatusFilter(this.param2);--
        // this.communicationService.triggerLoadFtpList(this.param2);
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner=false;
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      let ftp: CreateUpdateFTPUnitDTO = {
        branchId: this.FTPForm.value.drpBranchTypes,
        tenantId: this.clientId,
        isActive: 1,
        portNumber: this.FTPForm.value.txtPortNumber,
        server_IP: this.FTPForm.value.txtServerIP,
        userName: this.FTPForm.value.txtUserName,
        passWord: this.FTPForm.value.txtUserPassword,
        faxSourceFolder: this.FTPForm.value.txtSourceFolder,
        destinationFolder: this.FTPForm.value.txtDesFolder,
      };
      this.branchService.updateFtp(this.ftpid, ftp).subscribe(response => {

        this.isConfirmBranchLocation = false;
        //}, err => { });

        this.resetFTP();
        if (this.selectedOption === 'All') {
          this.param2 = 0;
        } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
          this.param2 = 2;
        }
        // this.FTPStatusFilter(this.param2);--
        // let index = this.FTPDetails.findIndex(d => d.id === response.id);
        //   this.FTPDetails.splice(index,1);
        //   this.FTPDetails.push(response);
        const index = this.FTPDetails.findIndex(
          (obj) => obj.id === response?.id
        );
        if (index !== -1) {
          this.FTPDetails[index] = response; //Replace the Object
          const objectToMove = this.FTPDetails.splice(index, 1)[0]; // Remove and get the object
          const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
          response.lastModificationTime = userTimezonelastModificationTime;
          const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
          response.creationTime = userTimezonecreationTime;
          this.FTPDetails.unshift(objectToMove);
          // this.FTPDetails = this.FTPDetails.map((data) => {
          //   return {
          //     ...data,
          //     creationTime: this.commonService.getFormattedDateTimeZone(
          //       data.creationTime
          //     ),
          //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
          //       data.lastModificationTime
          //     ),
          //   };
          // });
        }
        this.toastr.success('Updated Successfully', 'Success')
        //   }
        this.saveButtonHide = false;
        this.isShowSpinner=false;
        this.isLoading = false;
        // this.communicationService.triggerLoadFtpList(this.param2);
        this.ftpid = '';
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner=false;
        this.isLoading = false;
        this.isConfirmBranchLocation = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  loadDropdown() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        this.lstBranch = response;
        this.filteredBranchTypeList = [...this.lstBranch];
        this.drpBranchTypes=this.lstBranch;

      });
  }

  resetFTP() {
    this.FTPForm.patchValue({
      drpBranchType: "",
      txtPortNumber: "",
      txtUserName: "",
      txtUserPassword: "",
      txtSourceFolder: "",
      txtDesFolder: "",
      txtServerIP: "",
    })
    this.ftpid = '';
    this.FTPForm.reset();
    this.FTPForm.markAsUntouched();
    this.FTPForm.updateValueAndValidity();
    // this.getCompanyName(this.loginResponse);
    this.addWorkingButton = false;
  }

  getBranch() {
    // this.BranchForm.controls['txtCompanyName'].disable()
    this.officialService.getTenantInformationById(this.clientId).subscribe(response => {

      if (response?.status == "InActive") {
        this.inActiveHide = false;
      }
    })
  }

  ftpStatusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this FTP will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          this.isLoading=true;
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.branchService.updateFtpEnableDisable(data?.id).subscribe(response => {
              if (this.selectedOption === 'All') {
                this.param2 = 0;
              } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
                this.param2 = 2;
              }
              // this.FTPStatusFilter(this.param2);--
              if(this.selectedOption !=='All'){
                let index = this.FTPDetails.findIndex(d => d.id === data?.id);
                this.FTPDetails.splice(index,1);
              }else{
                this.FTPDetails.find(item => item.id == data?.id).status = 'Active';
              }
              this.toastr.success('Activated Successfully', 'Success')
              this.isLoading=false;
            }, err => {
              const data: HttpErrorResponse = err;
              this.isLoading=false;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this FTP will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            this.isLoading=true;
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.branchService.updateFtpEnableDisable(data?.id).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                if (this.selectedOption === 'All') {
                  this.param2 = 0;
                } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
                  this.param2 = 2;
                }
                if(this.selectedOption !=='All'){
                  let index = this.FTPDetails.findIndex(d => d.id === data?.id);
                  this.FTPDetails.splice(index,1);
                }else{
                  this.FTPDetails.find(item => item.id == data?.id).status = 'Inactive';
                }
                this.isLoading=false;
                this.toastr.success('Deactivated Successfully', 'Success')
                // this.FTPStatusFilter(this.param2);--
                // this.communicationService.triggerLoadFtpList(this.param2);
              }, err => {
                const data: HttpErrorResponse = err;
                this.isLoading=false;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              });
            }

          }
          else {
            event.source.checked = true;
          }
        });
      }
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  FTPStatusFilterv1(statusidv:number){
    this.communicationService.triggerLoadFtpList(statusidv);
  }

  fnselectedFtpOption() {
    if (this.ftpStatusView == 0) {
      this.selectedOption = "All";
    } else if (this.ftpStatusView == 1) {
      this.selectedOption = "Active";
    } else if (this.ftpStatusView == 2) {
      this.selectedOption = "InActive";
    }
  }
}
