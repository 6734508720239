import type {
  IdentityUserCreateUsingTenantIdDto,
  IdentityUserUsingTenantIdDto,
  IdentityUserWithRoleDto,
} from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetIdentityUsersInput,
  IdentityUserDto,
  IdentityUserUpdateDto,
  UserByRoleDTO,
  userDetailsDTO,
} from '../../../identity/models';
import type { BlobDTO } from '../tenants/models';
import type {
  SearchUserDTO,
  UsernameEmailValidationDto,
} from '../users/dto/models';
import { tap } from 'rxjs/operators';
import { REF_VersionIdentification } from 'projects/billing/src/app/claim-proxy/claim-processing-management/edi-fabric/templates/hipaa5010';
import { ActiveStatus } from '../localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiName = 'platformManagement';

  checkUserNameIfExistsByInput = (input: UsernameEmailValidationDto) =>
    this.restService.request<any, UsernameEmailValidationDto>(
      {
        method: 'POST',
        url: '/api/PlatformApp/user/check-user-name-exists',
        body: input,
      },
      { apiName: this.apiName }
    );
    checkUserNameIfExistsByInputV1 = (input: UsernameEmailValidationDto) =>
    this.restService.request<any, UsernameEmailValidationDto>(
      {
        method: 'POST',
        url: '/api/PlatformApp/user/check-user-name-exists_v1',
        body: input,
      },
      { apiName: this.apiName }
    );
  checkEmailIfExistsByInput = (input: UsernameEmailValidationDto) =>
    this.restService.request<any, UsernameEmailValidationDto>(
      {
        method: 'POST',
        url: '/api/PlatformApp/user/check-email-if-exists',
        body: input,
      },
      { apiName: this.apiName }
    );
checkEmailIfExistsByInputV1 = (input: UsernameEmailValidationDto) =>
    this.restService.request<any, UsernameEmailValidationDto>(
      {
        method: 'POST',
        url: '/api/PlatformApp/user/check-email-if-exists_v1',
        body: input,
      },
      { apiName: this.apiName }
    );
    checkFaxIfExistsByInput = (sFaxNumber:string) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/PlatformApp/fax-mail/check-fax-number-exist',
        params:{sFaxNumber}
      },
      { apiName: this.apiName }
    );
  createUser = (input: IdentityUserCreateUsingTenantIdDto) =>
    this.restService.request<any, IdentityUserDto>(
      {
        method: 'POST',
        url: '/api/PlatformApp/user/create-user',
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  deleteUser = (tenantId: string, userId: string) =>
    this.restService.request<any, any>(
      {
        method: 'DELETE',
        url: '/api/PlatformApp/user/delete-user',
        params: { tenantId, userId },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getIdentityUser = () =>
    this.restService.request<any, IdentityUserDto[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/user/get-identity-user',
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getUserById = (tenantId: string, userId: string) =>
    this.restService.request<any, IdentityUserWithRoleDto>(
      {
        method: 'GET',
        url: '/api/PlatformApp/user/get-user-by-id',
        params: { tenantId, userId },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getUserList = (input: IdentityUserUsingTenantIdDto) =>
    this.restService.request<any, IdentityUserWithRoleDto[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/user/get-user-list',
        params: { tenantId: input.tenantId },
      },
      { apiName: this.apiName, skipHandleError: true }
    );


  getUserListV1 = (input: IdentityUserUsingTenantIdDto) =>
  this.restService.request<any, IdentityUserWithRoleDto[]>(
    {
      method: 'GET',
      url: '/api/PlatformApp/user/get-user-list-dropdown-data',
      params: { tenantId: input.tenantId },
    },
    { apiName: this.apiName, skipHandleError: true }
  );

  getUserListBasedOnOrganization = (
    tenantId: string,
    organizationUnitId: string,
    input: GetIdentityUsersInput
  ) =>
    this.restService.request<any, IdentityUserWithRoleDto[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/user/get-user-list-based-on-organization',
        params: {
          tenantId,
          organizationUnitId,
          filter: input.filter,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getUserProfileByBlobNameByBlobDetails = (blobDetails: BlobDTO) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: '/api/PlatformApp/user/get-user-profile-by-blob-name',
        params: {
          tenantId: blobDetails.tenantId,
          blobName: blobDetails.blobName,
        },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getUsersListByLstRolesAndLstUsersAndTenantId = (
    lstRoles: string[],
    lstUsers: string[],
    tenantId: string
  ) =>
    this.restService.request<any, IdentityUserWithRoleDto[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/get-users-list/${tenantId}`,
        params: { lstRoles, lstUsers },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  searchUsersByInput = (input: SearchUserDTO) =>
    this.restService.request<any, IdentityUserWithRoleDto[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/user/search-users',
        params: {
          tenantId: input.tenantId,
          userName: input.userName,
          phoneNumber: input.phoneNumber,
          roleName: input.roleName,
          status: input.status,
          createdDate: input.createdDate,

          branch: input.branch,
          email: input.email,
        },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  updateUser = (
    tenantId: string,
    userId: string,
    input: IdentityUserUpdateDto
  ) =>
    this.restService.request<any, IdentityUserDto>(
      {
        method: 'PUT',
        url: '/api/PlatformApp/user/update-user',
        params: { tenantId, userId },
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getUserByRole = (gRoleId: string) =>
    this.restService.request<any, UserByRoleDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/get-user-by-role/${gRoleId}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
    getUserByStatus = (gStatusId: string) =>
    this.restService.request<any, UserByRoleDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/get-user-by-status/${gStatusId}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
  getCSRUsers = (sSearch:string) =>
    this.restService.request<any, UserByRoleDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/get-cSRUsers`,
        params:{sSearch}
      },
      { apiName: this.apiName, skipHandleError: true }
    );
    getCSRUsersV1 = () =>
    this.restService.request<any, UserByRoleDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/get-cSRUsers-drop-down-data `,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
  getUsersByTenant = () =>
    this.restService.request<any, UserByRoleDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/get-users-by-tenant`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

    getUsersByTenantV1BySParamsAndUser = (sParams: string, user: string) =>
      this.restService.request<any, UserByRoleDTO[]>({
        method: 'GET',
        url: '/api/PlatformApp/user/get-users-by-tenant-v1',
        params: { sParams, user },
      },
      { apiName: this.apiName });

    getUsersByTenantWithoutAdmin = () =>
    this.restService.request<any, UserByRoleDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/get-reset-pass-users`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
    StatusFilter = (userStatus:number,gTenantId :string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/PlatformApp/user/users-by-status/${gTenantId}`,
        params: { userStatus},
      },
      { apiName: this.apiName, skipHandleError: true }
    );
    getUsersByStatusByUserStatusAndGTenant = (userStatus: ActiveStatus, gTenant: string) =>
      this.restService.request<any, IdentityUserWithRoleDto[]>({
        method: 'GET',
        url: '/api/PlatformApp/user/users-by-status',
        params: { userStatus, gTenant },
      },
      { apiName: this.apiName });
      getUsersByStatusByUserStatusAndGTenantV1 = (userStatus: ActiveStatus, gTenant: string,
        roleId?:string[],locationId?:string[],emailId?:string,userName?:string
      ) =>
        this.restService.request<any, IdentityUserWithRoleDto[]>({
          method: 'GET',
          url: '/api/PlatformApp/user/users-by-status-v1',
          params: { userStatus, gTenant, roleId,locationId,emailId,userName},
        },
        { apiName: this.apiName });
    updateUserStatusByGUserId = (gUserId: string) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: `/api/PlatformApp/user/update-user-status/${gUserId}`,
    },
    { apiName: this.apiName });

    resetPassword= (userDetails: userDetailsDTO) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/PlatformApp/user/reset-user-password`,
      body:userDetails
    },
    { apiName: this.apiName });

    getUsersByTenantV1 = () =>
      this.restService.request<any, UserByRoleDTO[]>({
        method: 'GET',
        url: '/api/PlatformApp/user/get-users-by-tenant-v1',
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
