<div class="container-fluid">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-item-center justify-content-between m-0">
          <h4 class="mt-2 mb-0 font-size-18"><b>Navigator / My Verification</b></h4>
          <div class="page-title-box page-title-right mt-1 mb-0">
            <ol class="breadcrumbs m-0">
              <button
                class="mr-2 mt-0 buttonColor"   [routerLink]="['/navigation/masterverification']"
                routerLinkActive="active" matTooltip="Go to Master Verification"
                mat-raised-button>
                Go to Master Verification
              </button>

              <button
                class="mr-2 mt-0 buttonColor"
                mat-raised-button>
                Export
              </button>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pt-2">
        <div class="borderSpace card card-body table-container">
                    <!-- Table Start -->
                <table mat-table  class="row-border hover" matTableExporter #exporter="matTableExporter" matSort [dataSource]="verificationList"
                    class="mat-elevation-z8" (contextmenu)="onRowContextMenu($event)">
                  <ng-container matColumnDef="ChartNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="ChartNo"
                        />
                        <mat-placeholder class="center">Chart No</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.ChartNo}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="Name"
                        />
                        <mat-placeholder class="center">Name</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.Name}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="state"
                        />
                        <mat-placeholder class="center">State</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.state}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Insurance">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="Insurance"
                        />
                        <mat-placeholder class="center">Insurance</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.Insurance}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="EnteredBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="EnteredBy"
                        />
                        <mat-placeholder class="center">Entered By</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.EnteredBy}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="Location"
                        />
                        <mat-placeholder class="center">Location</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.Location}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="LockedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="LockedBy"
                        />
                        <mat-placeholder class="center">Locked By</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.LockedBy}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="LockedAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="LockedAt"
                        />
                        <mat-placeholder class="center">Locked At</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.LockedAt}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Days">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="Days"
                        />
                        <mat-placeholder class="center">Days</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.Days}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="EnteredOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="EnteredOn"
                        />
                        <mat-placeholder class="center">Entered On</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.EnteredOn}}
                        </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <mat-form-field class="filter">
                        <input
                          matInput
                          formControlName="Status"
                        />
                        <mat-placeholder class="center">Status</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let data">
                        <ng-container>
                          {{data.Status}}
                        </ng-container>
                    </td>
                  </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <div *ngIf="showContextMenu" class="custom-mat-menu show-menu" [style.top.px]="contextMenuPosition.top" [style.left.px]="contextMenuPosition.left">
                    <button (click)="openAddNotesPopUp()">Add Patient Note</button>
                    <button  (click)="openViewNotesPopup()">View Patient Notes</button>
                    <button (click)="viewDocument(
                      '00000000-0000-0000-0000-000000000000', '4b7cb906-8b74-bb2a-c98e-3a0edf9d9c1b', 'DOC3120231114161641',true
                    )">View Document Images</button>
                    <button (click)="viewPatientLedger('00000000-0000-0000-0000-000000000000')">View Patient Ledger</button>
                    <div class="menu-divider"></div>
                    <button (click)="CancelVerification()">Cancel Verification</button>
                    </div>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>
