import { CircleManager, DataLayerManager, GoogleMapsAPIWrapper, InfoWindowManager, KmlLayerManager, LatLng, LatLngBounds, MapsAPILoader, MarkerManager, PolygonManager, PolylineManager } from '@agm/core';
import { PathEvent } from '@agm/core/directives/polyline';
import { Appearance, Location } from '@angular-material-extensions/google-maps-autocomplete';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { CoverageAreaService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule';
import { CoverageAreaLocationDTO, CreateUpdateCoverageAreaDTO, CreateUpdateCoverageAreaLocationDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';

import PlaceResult = google.maps.places.PlaceResult;



@Component({
  selector: 'app-add-coverage-area',
  templateUrl: './add-coverage-area.component.html',
  styleUrls: ['./add-coverage-area.component.scss'],
  providers: [
    CircleManager,
    DataLayerManager,
    DataLayerManager,
    GoogleMapsAPIWrapper,
    InfoWindowManager,
    KmlLayerManager,
    MarkerManager,
    PolygonManager,
    PolylineManager,

  ]
})
export class AddCoverageAreaComponent implements AfterViewInit, OnInit {
  agmmap: google.maps.Map;
  public appearance = Appearance;
  showMap: boolean = true;


  selectedArea = 0;
  latitude: number = 0;
  longitude: number = 0;
  markers: marker[] = [];
  AddCoverageAreaForm: FormGroup
  coverageAreaId: string = "";
  currentSearchedAdd: PlaceResult;
  selectedPath: any;
  userDetails: string = '';
  status: string = '';
  title: string = '';
  isShowStatus: boolean = false;
  getCoverageAreaById() {
    this.isShowStatus = true;
    this.coverageAreaService.get(this.coverageAreaId).subscribe(response => {
      this.deleteSelectedShape();
      this.AddCoverageAreaForm.patchValue({
        txtCovAreaName: response?.coverageAreaName ?? "",
      });

      this.userDetails = response?.userDetails;
      this.status = response?.status;
      this.selectedArea = response?.squareMile != null || undefined || "" ? Number(response?.squareMile.replace(" m²", "").trim()) : Number(response?.squareMile);
      // this.selectedArea = response?.squareMile;
      const polygonCoords: google.maps.LatLng[] = [];
      response?.coverageAreaLocation?.forEach((e: CoverageAreaLocationDTO, i: number) => {
        this.markers?.push({ lat: (Number(e?.latitude)), lng: (Number(e?.longitude)) })
        polygonCoords?.push(new google.maps.LatLng((Number(e?.latitude)), (Number(e?.longitude))))
      })
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      for (let i = 0; i < polygonCoords.length; i++) {
        bounds?.extend(polygonCoords[i]);
      }
      this.latitude = bounds?.getCenter()?.lat();
      this.longitude = bounds?.getCenter()?.lng();
    }, (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }


  constructor(private mapsApi: MapsAPILoader, @Inject(MAT_DIALOG_DATA) public data: { value: any },
    private formBuilder: FormBuilder,
    private _elem: ElementRef,
    public dialog: MatDialog,
    private toastr: ToastrService,

    private coverageAreaService: CoverageAreaService,
  ) {

  }
  ngOnInit(): void {
    this.coverageAreaId = this.data?.value?.id ?? "";
    if (this.coverageAreaId && this.coverageAreaId !== "" && this.coverageAreaId !== null && this.coverageAreaId !== undefined) {
      this.getCoverageAreaById();
      this.title = "Edit Coverage Area"

    }
    else {
      this.title = "Add Coverage Area"
    }
    this.AddCoverageAreaForm = this.formBuilder.group({
      txtCovAreaName: new FormControl("", [Validators.required])
    })
  }
  ngAfterViewInit(): void {
    this.mapsApi?.load()?.then(() => {
      this.coverageAreaId = this.coverageAreaId ?? "";
      if (this.coverageAreaId === "") {
        this.setCurrentPosition();
      }
    });
  }
  mapClicked($event: MouseEvent) {
  }


  setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator?.geolocation?.getCurrentPosition((position: GeolocationPosition) => {
        this.latitude = position?.coords?.latitude;
        this.longitude = position?.coords?.longitude;
      });
    }
    this.initDrawingManager(this.agmmap);
  }
  onDragEnd($event: PathEvent) {

  }

  initDrawingManager(map: any) {
    this.agmmap = map;
    const options: any = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };
    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
    google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (event) => {

        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  this.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                this.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                this.updatePointList(event.overlay.getPath());
              }
            );
          }
          this.updatePointList(event.overlay.getPath());
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          drawingManager.setDrawingMode(null);
          drawingManager.setOptions({
            drawingControl: false,
          });
        }
      });
  }
  updatePointList(path) {
    this.markers = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.markers.push(
        path.getAt(i).toJSON()
      );
    }
    this.selectedPath = path;
    this.selectedArea = google.maps.geometry.spherical.computeArea(
      path
    );


  }
  deleteSelectedShape() {
    // this.isShowStatus = false;
    this.selectedArea = 0;
    this.latitude = null;
    this.longitude = null;
    this.markers = [];
    this.showMap = !this.showMap;
    // this.AddCoverageAreaForm.reset();
    // this.AddCoverageAreaForm.markAsUntouched();
    // this.title = "Add Coverage Area";
  }

  reset() {
    this.isShowStatus = false;
    this.selectedArea = 0;
    this.latitude = null;
    this.longitude = null;
    this.markers = [];
    this.showMap = !this.showMap;
    this.AddCoverageAreaForm.reset();
    this.AddCoverageAreaForm.markAsUntouched();
    this.AddCoverageAreaForm.updateValueAndValidity();
    this.title = "Add Coverage Area";
    this.coverageAreaId = "";
  }



  onAutocompleteSelected(result: PlaceResult) {
  }

  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }
  saveCoverageValue() {
    if (!this.AddCoverageAreaForm.value.txtCovAreaName ?? "") {
      const messageToDisplayArea = 'Please Enter Name Of Coverage Area';
      Swal.fire({
        icon: 'info',
        text: messageToDisplayArea,
      });
      return
    }
    if (this.markers?.length === 0) {
      const messageToDisplayMap = 'Please Enter Coverage Area On Map';
      Swal.fire({
        icon: 'info',
        text: messageToDisplayMap,
      });
      return
    }

    const locationsPins: CreateUpdateCoverageAreaLocationDTO[] = [];
    this.markers.forEach(m => locationsPins.push({ latitude: String(m.lat), longitude: String(m.lng) }))
    const input: CreateUpdateCoverageAreaDTO = {
      coverageAreaName: this.AddCoverageAreaForm.value.txtCovAreaName,
      squareMile: String(this.selectedArea + ' ' + 'm²'),
      userDetails: this.userDetails,
      status: this.status,
      coverageAreaLocation: locationsPins ?? []
    }
    if (this.coverageAreaId && this.coverageAreaId !== "" && this.coverageAreaId !== null && this.coverageAreaId !== undefined) {
      this.coverageAreaService.update(this.coverageAreaId, input).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Saved Successfully','Success')
        this.deleteSelectedShape();
        this.dialog.closeAll();
      }, (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    } else {
      this.coverageAreaService.create(input).subscribe(response => {

        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Saved Successfully','Success')
        this.deleteSelectedShape();
        this.dialog.closeAll();
      }, (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }
}
// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable?: boolean;
}
