import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiff-viewer',
  templateUrl: './tiff-viewer.component.html',
  styleUrls: ['./tiff-viewer.component.scss']
})
export class TiffViewerComponent implements OnInit {
  base64TiffData =
    'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.shiksha.com%2Fonline-courses%2Farticles%2Fwhat-is-the-full-form-of-url-unlocking-the-meaning-behind-url%2F&psig=AOvVaw1X2FYIuMLkAF1Jvor-PLsj&ust=1704796573294000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCLCG76PMzYMDFQAAAAAdAAAAABAD';


  constructor() { }

  ngOnInit(): void {
  }

}
