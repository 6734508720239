<div class="container-fluid main-content">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="font-weight-bolder mb-0">Leave Apply</h3>
          <div class=" page-title-box page-title-right">
            <ol class="breadcrumbs m-0 align-items-center">
              <li class="breadcrumb-item">Appointment</li>
              <li class="breadcrumb-item ">Leave Approval</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
<br />
<div class="card card-body">
    <mat-accordion>
        <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container *ngIf="leaveApprovalId===''; else elseTitleTemplate">
                        <b>Add Leave Apply</b>
                    </ng-container>
                    <ng-template #elseTitleTemplate>
                        <b>Edit Leave Apply</b>
                    </ng-template>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="leaveApplyForm">
                <div class="row">
                    <div class="col-lg-12">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field class="col-4">
                                    <mat-label>Leave Type<span class="asterisk">*</span></mat-label>
                                    <mat-select formControlName="drpLeaveType"
                                        (selectionChange)="onLeaveChange($event)">
                                        <mat-option *ngFor="let apply of drpLeaveType" [value]="apply.leaveType">
                                            {{apply.leaveType}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf=" leaveApplyForm?.get('drpLeaveType')?.touched && leaveApplyForm?.get('drpLeaveType')?.errors?.required">
                                        Leave Type is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field *ngIf="commentsHide" class="col-4">
                                    <mat-label> From Date <span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtFromDate" [max]="BirthdateMaxDate" formControlName="txtFromDate" matInput
                                        [formControl]="leaveApplyForm.controls.txtFromDate" maxlength="40" type="text"
                                        placeholder="(MM/DD/YYYY)" [matDatepicker]="txtFromDate" />
                                    <mat-datepicker-toggle matSuffix [for]="txtFromDate"> </mat-datepicker-toggle>
                                    <mat-datepicker #txtFromDate> </mat-datepicker>
                                    <mat-error
                                        *ngIf=" leaveApplyForm?.get('txtFromDate')?.touched && leaveApplyForm?.get('txtFromDate')?.errors?.required">
                                        From Date is a required field!
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="vationFieldsHide" class="col-4">
                                    <mat-label> To Date </mat-label>
                                    <input autocomplete="new-txtToDate" matInput formControlName="txtToDate" maxlength="40"
                                        placeholder="(MM/DD/YYYY)" [formControl]="leaveApplyForm.controls.txtToDate"
                                        [min]="leaveApplyForm.controls.txtFromDate.value" type="text"
                                        [matDatepicker]="txtToDate" />
                                    <mat-datepicker-toggle matSuffix [for]="txtToDate"> </mat-datepicker-toggle>
                                    <mat-datepicker #txtToDate> </mat-datepicker>
                                    <mat-error
                                        *ngIf=" leaveApplyForm?.get('txtToDate')?.touched && leaveApplyForm?.get('txtToDate')?.errors">
                                        Enter valid date
                                    </mat-error>

                                </mat-form-field>

                                <mat-form-field *ngIf="commentsHide" class="col-4">
                                    <mat-label>Comments <span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtComments" matInput formControlName="txtComments" placeholder="Enter the Comments"
                                        type="text" />
                                    <mat-error
                                        *ngIf=" leaveApplyForm?.get('txtComments')?.touched && leaveApplyForm?.get('txtComments')?.errors?.required">
                                        Comments is a required field!
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mb-2 mr-2"
                            [disabled]="!( isShownSaveButton ||!leaveApplyForm.invalid && leaveApplyForm.dirty )"
                            (click)="saveLeaveApprovalForm()">
                            Save
                            <mat-icon *ngIf='isShownSaveButton'>
                                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                            </mat-icon>
                        </button>
                        <button mat-button class="resetclr mb-2 mr-2" (click)="reset()">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            <br />
        </mat-expansion-panel>
    </mat-accordion>

    <div class="row">
        <div class="col-12 table-responsive">
            <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
            </div> -->
            <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="leaveApplySearchForm">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data">
                            {{ data?.sno}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="LeaveType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-leaveType" matInput formControlName="leaveType" />
                                <mat-placeholder>Leave Type</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.leaveType}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="FromDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-fromDate" matInput formControlName="fromDate" />
                                <mat-placeholder>From Date</mat-placeholder>
                                <mat-error
                                    *ngIf=" leaveApplySearchForm?.get('fromDate')?.touched && leaveApplySearchForm?.get('fromDate')?.errors?.dateVaidator">
                                    Enter Valid date
                                </mat-error>
                            </mat-form-field>

                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.fromDate}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ToDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-toDate" matInput formControlName="toDate" />
                                <mat-placeholder>To Date</mat-placeholder>
                                <mat-error
                                    *ngIf=" leaveApplySearchForm?.get('toDate')?.touched && leaveApplySearchForm?.get('toDate')?.errors?.dateVaidator">
                                    Enter Valid date
                                </mat-error>
                            </mat-form-field>

                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.toDate}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-comments" matInput formControlName="comments" />
                                <mat-placeholder>Comments</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.comments}}
                            </ng-container>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-status" matInput formControlName="status" />
                                <mat-placeholder>Status</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.status}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="editBtnColor pointer" pointer (click)="ViewLeaveApproval(data)">
                                <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To Edit"
                                    ></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deleteLeaveApproval(data)">
                                <i class="fa fa-trash-o" matTooltip="Click To Delete" ></i>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="8">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>
</div>
