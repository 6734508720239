import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs';
import { defaultGuid } from '../../claim-status-tab/claim-status-tab.component';
import { EditClaimInfoComponent } from '../../edit-claim-info/edit-claim-info.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-patient-claim-table-list',
  templateUrl: './patient-claim-table-list.component.html',
  styleUrls: ['./patient-claim-table-list.component.scss']
})
export class PatientClaimTableListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  $subscription: Subscription[] = [];
  claimDataArray = claimDataArray;
  dtPatientClaimOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '60vh',
    scrollX: true,
    order: [],
    dom: 'lBfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Patient Claims List',
        exportOptions: {
          // columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle

      },
    ],
    columnDefs: [
      { targets: [0, 1, 2, 3,  -1], className: 'dt-fixed-column' }, 
    ]
  };
  constructor( private dialog: MatDialog , private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.dtPatientClaimOptions.buttons[0].filename =
    'Patient Claims List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.calculateScrollY()
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.47; // Adjust the percentage as needed
    this.dtPatientClaimOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  
  editClaimInfo() {
    const dialogRef = this.dialog.open(EditClaimInfoComponent, {
      disableClose: true
      // data: { orderId: defaultGuid },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  //To refresh the Datatable
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


}
const claimDataArray: any[] = [
  {
    id: 'C001',
    status: 2,
    auth: 10,
    daySheet: 'Sheet001',
    dos: '2023-01-05',
    provider: 'Dr. Smith',
    selected: false,
    patientBalance: 50.0,
    insuranceBalance: 75.0,
    total: 125.0,
    firstFilled: '2023-01-01',
    lastFilled: '2023-01-20',
    claimId: 'Claim001',
  },
  {
    id: 'C002',
    status: 2,
    auth: 10,
    daySheet: 'Sheet002',
    dos: '2023-02-10',
    provider: 'Dr. Johnson',
    selected: false,
    patientBalance: 30.0,
    insuranceBalance: 45.0,
    total: 75.0,
    firstFilled: '2023-02-01',
    lastFilled: '2023-02-18',
    claimId: 'Claim002',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
  {
    id: 'C003',
    status: 2,
    auth: 10,
    daySheet: 'Sheet003',
    dos: '2023-03-15',
    provider: 'Dr. Williams',
    selected: false,
    patientBalance: 20.0,
    insuranceBalance: 30.0,
    total: 50.0,
    firstFilled: '2023-03-01',
    lastFilled: '2023-03-20',
    claimId: 'Claim003',
  },
];