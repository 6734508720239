import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { OrderMasterDropdownDTO } from '../dto/order/models';
import type { OrderDropdowns } from '../order-dropdowns.enum';

@Injectable({
  providedIn: 'root',
})
export class OrderMasterDropdownService {
  apiName = 'DropdownManagement';

  getOrderDropdownsByInput = (input: OrderDropdowns[]) =>
    this.restService.request<any, OrderMasterDropdownDTO>({
      method: 'POST',
      url: '/api/DropdownApp/order-master-dropdown/get-order-dropdowns',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
