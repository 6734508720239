<br />
<div class="card card-body">
  <div>
    <mat-card-content>
      <div class="row mar-top">
        <div class="col-6">
          <div class="text-left">
            <h4 class="customThemeClass"><b>Authorization</b></h4>
          </div>
        </div>
        <div class="col-6">
          <div class="text-right">
            <button *ngIf='isPatientLockedByCurrentUser' mat-button (click)="openAuthModal()"
              class="buttonColor font-size-14">
              <i class="fa fa-plus mr-1" ></i>
              Add Authorization
            </button>
          </div>
        </div>
      </div>
      <app-table [strPageType]="strPageType" [isLoading]="isLoading" (viewButton)="viewAuth($event)"
        (deleteButton)="deleteAuth($event)">
      </app-table>
    </mat-card-content>
  </div>
</div>