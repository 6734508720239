<div class="card">
    <div class="card-body">

        <div class="row messageRow" (click)="selectRow(1)" [ngClass]="{'selected': selectedRow === 1}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <span class="msgCount mr-2">5</span>
            </div>
            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>
        </div>


        <div class="row messageRow mt-2" (click)="selectRow(2)" [ngClass]="{'selected': selectedRow === 2}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <span class="msgCount mr-2">5</span>
            </div>

            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>

        </div>
        <div class="row messageRow mt-2" (click)="selectRow(3)" [ngClass]="{'selected': selectedRow === 3}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <span class="msgCount mr-2">5</span>
            </div>


            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>
        </div>
        <div class="row messageRow mt-2" (click)="selectRow(4)" [ngClass]="{'selected': selectedRow === 4}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <span class="msgCount mr-2">5</span>
            </div>


            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>
        </div>


        <div class="row messageRow mt-2" (click)="selectRow(5)" [ngClass]="{'selected': selectedRow === 5}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <span class="msgCount mr-2">5</span>
            </div>


            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>
        </div>
        <div class="row messageRow mt-2" (click)="selectRow(6)" [ngClass]="{'selected': selectedRow === 6}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <!-- <span class="msgCount mr-2">5</span> -->
            </div>


            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>

        </div>


        <div class="row messageRow mt-2" (click)="selectRow(7)" [ngClass]="{'selected': selectedRow === 7}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <!-- <span class="msgCount mr-2">5</span> -->
            </div>
            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>
        </div>

        <div class="row messageRow mt-2" (click)="selectRow(8)" [ngClass]="{'selected': selectedRow === 8}">
            <div class="col-7">
                <span class="customfontSize">Aubrey Lucas</span>
            </div>
            <div class="col-5 dateElips mt-1">
                <span class="messageDate">04/25/2024 12:15 PM</span>
            </div>
            <div class="col-8 dateElips">
                <span>Please Confirm his message</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <!-- <span class="msgCount mr-2">5</span> -->
            </div>
            <div class="col-12 mt-2">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
</div>