<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex my-1 align-items-center justify-content-between">
      <h3 class="col-6 font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">List / Claim Dept / Claim Process</b>
      </h3>

      <!-- <h3 class="font-weight-bolder mb-0"><b class="header-class">Claim Process</b></h3> -->
      <div class="col-6 d-flex justify-content-end align-items-center">
        <!-- Payment Provider -->
        <form [formGroup]="paymentProviderFilterForm">
          <mat-form-field class="col-12">
            <mat-label>Manual Payment Provider</mat-label>
            <mat-select
              [(ngModel)]="selectedPaymentProvider"
              formControlName="drpPaymentProvider"
              placeholder="Manual Payment Provider"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  formControlName="txtPaymentProvider"
                  noEntriesFoundLabel="No Matches found"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let data of filteredPaymentProviders | async"
                [value]="data.id"
              >
                {{ data.providerName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <!-- Manual Process -->
        <button
          [disabled]="
            totalSelectedOrders === 0 ||
            isShowSpinner ||
            isZermidProcessing ||
            selectedPaymentProvider == null ||
            selectedPaymentProvider == undefined ||
            selectedPaymentProvider == ''
          "
          mat-raised-button
          class="buttonColor mr-2"
          (click)="ManualProcessEDI()"
          [matTooltip]="
            totalSelectedOrders === 0
              ? 'Select checkbox to process Claim'
              : 'Click to Process Claim Manually'
          "
        >
          <img
            class="img-responsive pr-1 mb-1"
            src="assets/Claim Icons/ManualClaim.png"
            alt=""
            height="16"
          />

          Manual Process ({{ totalSelectedOrders }})

          <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon>
        </button>

        <!-- Change Healthcare -->
        <button
          [matTooltip]="
            totalSelectedOrders === 0
              ? 'Select checkbox to process Claim'
              : 'Click to Process Claim with Change Healthcare'
          "
          [disabled]="
            totalSelectedOrders === 0 || isZermidProcessing || isShowSpinner
          "
          mat-raised-button
          class="buttonColor mr-2"
        >
          <img
            class="img-responsive pr-1 mb-1"
            src="assets/Claim Icons/CH.png"
            alt=""
            height="16"
          />
          Change Healthcare ({{ totalSelectedOrders }})
        </button>

        <!-- Zirmed Process -->
        <button
          [matTooltip]="
            totalSelectedOrders === 0
              ? 'Select checkbox to process Claim'
              : 'Click to Process Claim with Zirmed'
          "
          [disabled]="
            totalSelectedOrders === 0 || isZermidProcessing || isShowSpinner
          "
          mat-raised-button
          (click)="zirmedProcess()"
          class="buttonColor"
        >
          <img
            class="img-responsive pr-1 my-1"
            src="assets/Claim Icons/Waystar.png"
            alt=""
            height="16"
          />
          Zirmed ({{ totalSelectedOrders }})

          <mat-icon *ngIf="isZermidProcessing">
            <mat-spinner
              class="spinner-border-sm color-white"
              diameter="20"
            ></mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Page Content -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body p-2">
          <!-- <div class="col-12 d-flex justify-content-between mb-2">


          </div> -->
          <div class="row"></div>
          <div class="row">
            <div class="col-12">
              <form [formGroup]="claimProcessFilterForm">
                <!-- Provider -->
                <mat-form-field class="col-3">
                  <mat-label>
                    <div
                      class="select-placeholder-container"
                      *ngIf="isLoadProvider"
                    >
                      <span>Provider<span class="asterisk">*</span></span>
                      <mat-spinner class="spinner" diameter="20"></mat-spinner>
                    </div>
                    <span *ngIf="!isLoadProvider"
                      >Provider <span class="asterisk">*</span></span
                    >
                  </mat-label>
                  <mat-select formControlName="drpProvider">
                    <!-- <mat-option [value]="0">All</mat-option> -->
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        [formControl]="txtProviderControl"
                        formControlName="txtProviderFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let providers of lstProviders"
                      [value]="providers.providerId"
                    >
                      {{ providers?.provider }}
                      <!-- {{ providers?.fullName || '-' }} ({{ providers?.fileCount || 0 }}) -->
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- Claim Rank -->
                <mat-form-field class="col-2">
                  <mat-label> Claim Rank </mat-label>
                  <mat-select formControlName="drpClaimRank">
                    <mat-option
                      *ngFor="let payor of lstPayorLevel"
                      [value]="payor.payorLevelName"
                    >
                      {{ payor.payorLevelName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- DOS From -->
                <!-- <mat-form-field class="col-2">
                  <mat-label> DOS From</mat-label>
                  <input
                    autocomplete="new-dtDOSFrom"
                    formControlName="dtDOSFrom"
                    matInput
                    readonly
                    type="text"
                    [(value)]="fromDate"
                    placeholder="(MM/DD/YYYY)"
                    [matDatepicker]="dtDOSFrom"
                  />
                  <mat-datepicker-toggle matSuffix [for]="dtDOSFrom">
                  </mat-datepicker-toggle>
                  <mat-datepicker #dtDOSFrom> </mat-datepicker>
                </mat-form-field> -->

                <mat-checkbox
                  class="m-2 dd-text"
                  (change)="selectCheckBox($event)"
                  formControlName="chkDateRequired"
                >
                  Filter By Date
                </mat-checkbox>

                <mat-form-field
                  class="col-4 customWidth"
                  [class.disabled]="
                    !claimProcessFilterForm.get('chkDateRequired').value
                  "
                >
                  <mat-label
                    >Select Date Range
                    <span
                      *ngIf="
                        claimProcessFilterForm.get('chkDateRequired').value
                      "
                      class="asterisk"
                      >*</span
                    >
                    <i
                      class="fa fa-calendar ml-2"
                      style="color: rgb(0, 0, 0)"
                      matSuffix
                      align="end"
                    ></i>
                  </mat-label>
                  <input
                    autocomplete="new-dtDOSTo"
                    matInput
                    type="text"
                    name="daterange"
                    ngxDaterangepickerMd
                    [showCustomRangeLabel]="true"
                    [alwaysShowCalendars]="true"
                    [autoApply]="true"
                    [(ngModel)]="selectedRangeDate"
                    [linkedCalendars]="false"
                    [ranges]="ranges"
                    [showClearButton]="false"
                    [locale]="calendarLocale"
                    [keepCalendarOpeningWithRange]="true"
                    formControlName="dtDOSTo"
                    (datesUpdated)="datesUpdateds($event)"
                    [readonly]="true"
                    (keyup)="datesUpdateds($event)"
                  />
                  <!-- (keydown)="addSlashToDate($event)"  -->
                  <mat-error
                    *ngIf="
                      claimProcessFilterForm?.get('dtDOSTo')?.touched &&
                      (claimProcessFilterForm?.get('dtDOSTo')?.errors
                        ?.required ||
                        claimProcessFilterForm.get('dtDOSTo')?.errors?.invalid)
                    "
                  >
                    ToDate is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- Claim Rank -->
                <!-- <mat-form-field class="col-2">
                  <mat-label>Quick Options</mat-label>
                  <mat-select formControlName="drpQuickOptions" (selectionChange)="scQuickOption($event)">
                    <mat-option
                      *ngFor="let quickOptions of lstQuickOptions"
                      [value]="quickOptions.id"
                    >
                      {{ quickOptions.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->

                <!-- DOS To -->
                <!-- <mat-form-field class="col-2">
                  <mat-label> DOS To</mat-label>
                  <input
                    autocomplete="new-dtDOSTo"
                    formControlName="dtDOSTo"
                    matInput
                    readonly
                    [(value)]="toDate"
                    type="text"
                    placeholder="(MM/DD/YYYY)"
                    [matDatepicker]="dtDOSTo"
                  />
                  <mat-datepicker-toggle matSuffix [for]="dtDOSTo">
                  </mat-datepicker-toggle>
                  <mat-datepicker #dtDOSTo> </mat-datepicker>
                </mat-form-field> -->
                <!-- Date Filter-->
                <!-- <mat-radio-group
                  (change)="loadDates($event)"
                  formControlName="rbtnDate"
                >
                  <mat-radio-button class="mr-4 ml-3" value="1"
                    >Yesterday</mat-radio-button
                  >
                  <mat-radio-button class="mr-4 ml-3" value="2"
                    >Today</mat-radio-button
                  >
                  <mat-radio-button class="mr-4 ml-3" value="3"
                    >Tomorrow</mat-radio-button
                  >
                </mat-radio-group> -->

                <!-- <div class="row">
                  <div class="col-12 text-center">

                  </div>
                </div> -->

                <button
                  class="buttonColor mr-2"
                  mat-raised-button
                  [disabled]="claimProcessFilterForm?.invalid || isShowSpinner"
                  (click)="loadClaimProcessList()"
                  type="button"
                >
                  Search
                </button>
                <button
                  type="button"
                  class="resetclr"
                  [disabled]="isShowSpinner"
                  mat-raised-button
                  (click)="resetForm()"
                >
                  Reset
                </button>
                <!-- <button (click)="check()">Check</button> -->
              </form>
            </div>
          </div>

          <mat-divider class="my-2"></mat-divider>

          <div
            class="d-flex justify-content-center"
            *ngIf="!isClaimTableLoaded"
          >
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <div class="table table-responsive py-0">
            <app-claim-process-table
              (countsUpdate)="countsUpdate($event)"
              (loadtable)="loadClaimProcessList()"
              [lstClaimProcessList]="lstClaimProcessList"
              *ngIf="isClaimTableLoaded"
            ></app-claim-process-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container-fluid main-content " style="overflow: inherit;">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3" style="margin-top:-15px;">
        <h3 class="font-weight-bolder mb-0">Patient / Claim Process</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <form [formGroup]="branchForm">
              <mat-form-field class="pr-3">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>

                  <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button class="mr-2 buttonColor" mat-raised-button
                [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
                (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'Claim Process List',
                    sheet: 'Claim Process List',
                    Props: { Author: 'QSecure' }
                  })
                ">
                  Export
              </button>
            </form>



          </ol>
        </div>
      </div>
    </div>
    <div class="col-12" style="margin-top: -19px;">
      <mat-card>
        <form [formGroup]="claimProcessForm">
          <div class="row">
            <div class="col-12">
              <h4 class="customThemeClass"><b>Claim Process Filter</b></h4>
            </div>
          </div>
          <div class="row">
            <mat-form-field class="col-2">
              <mat-label>Select Provider <span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpProviderType" maxlength="40">
                <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                  {{ providers.fullName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <div class="row pt-2">
          <div class="col-12 bottomReduce">
            <h4 class="customThemeClass"><b>Claim Process List</b></h4>
          </div>
          <div class="col-12 table-responsive">
            <table mat-table class="table table-bordered" matTableExporter #exporter="matTableExporter"
              [hiddenColumns]="[0,6]" [dataSource]="dataSource" matSort>
              <form [formGroup]="claimSearchForm">
                <ng-container matColumnDef="defaultPatientId">
                  <th class=" header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-patientId" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="patientId">
                      <mat-placeholder class="center">Patient Id</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a matTooltip="Click to View Patient" class="eyeCursorclass"
                      [routerLink]="'/patientCreateEditTab/' + element?.patientId" target="_blank">
                      {{ element?.defaultPatientId }}
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="defaultSaleOrderId">
                  <th class=" header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-saleOrderId" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="saleOrderId">
                      <mat-placeholder class="center"> SaleOrder Id
                      </mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a matTooltip="Click to View Sale Order" class="eyeCursorclass" [routerLink]="
                    '/orders/saleorderCreateEditTab/' +element?.patientId + '/' + element?.orderId
                  " target="_blank">
                      {{ element?.defaultSaleOrderId }}
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="patientName">
                  <th class="header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-patientName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="patientName">
                      <mat-placeholder class="center">Patient Name
                      </mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element?.patientName }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="branch">
                  <th class="header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-branch" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="branch">
                      <mat-placeholder class="center">Branch
                      </mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element"> -
                  </td>
                </ng-container>

                <ng-container matColumnDef="totalBilled">
                  <th class=" header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-billedAmount" matInput  (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"formControlName="billedAmount">
                      <mat-placeholder class="center">
                        Total Billed
                      </mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element?.totalBilled  | currency:'USD':'symbol'}} </td>
                </ng-container>

                <ng-container matColumnDef="insuranceName">
                  <th class=" header" mat-header-cell mat-sort-header  *matHeaderCellDef>

                    <mat-form-field class="filter">
                      <input autocomplete="new-payerName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="payerName">
                      <mat-placeholder class="center"> Insurance Name
                      </mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element?.insuranceName}} </td>
                </ng-container>


                <ng-container matColumnDef="mitemDateOfService">
                  <th class="header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    DOS
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element?.mitemDateOfService}} </td>
                </ng-container>


                <ng-container matColumnDef="item">
                  <th class="header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    Item
                  </th>

                  <td mat-cell *matCellDef="let element"> {{element?.itemDetails[0]?.itemProcedureCodeId}} </td>
                </ng-container>
                <ng-container matColumnDef="insurance">
                  <th class="header" mat-header-cell mat-sort-header  *matHeaderCellDef>
                    Insurance
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element?.provider}} </td>
                </ng-container>


                <ng-container matColumnDef="options">
                  <th mat-header-cell mat-sort-header  *matHeaderCellDef >
                    Options
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a (click)="viewOrderDetailsById(element?.orderId,element?.patientId)" class="eyeCursorclass"
                      target="_blank">
                      <i class="fa fa-eye"></i>
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="select">
                  <th mat-header-cell mat-sort-header  *matHeaderCellDef>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection?.toggle(element) : null" [checked]="selection?.isSelected(element)">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
              </form>
            </table>
            <div *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              ) " class="text-align-center">
              <div class="message py-3" *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isLoading">
                <mat-spinner class="table-spinner" [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-toolbar>
              <mat-toolbar-row>
                <button class="buttonColor" [disabled]="isManualProcessingLoading || isZirmedProcessingLoading || isChangeHealthCareProcess"
                  *ngIf="!selection?.isEmpty()" (click)="manualProcessSelectedClaim()" mat-button>Manual Process

                  Selected {{selection?.selected?.length}} Claims
                  <mat-icon *ngIf="isManualProcessingLoading" class='mr-2 ml-2'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                  </mat-icon>
                </button>
                <button class="buttonColor ml-2" (click)="ProcessSelectedClaim()"
                  [disabled]="isZirmedProcessingLoading || isManualProcessingLoading || isChangeHealthCareProcess"  *ngIf="(!selection?.isEmpty() && isBothClearingHosue == 'Both' )|| (!selection?.isEmpty() && isWaystar == 'Waystar' && isChangeHealthCare != 'ChangeHealthCare' ) "
                  mat-button>Zirmed Process
                  Selected {{selection?.selected?.length}} Claims
                  <mat-icon *ngIf="isZirmedProcessingLoading" class='mr-2 ml-2'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                  </mat-icon>
                </button>
                <button class="buttonColor ml-2" (click)="ProcessSelectedClaimCH()"
                  [disabled]="isZirmedProcessingLoading || isManualProcessingLoading || isChangeHealthCareProcess" *ngIf="(!selection?.isEmpty() && isBothClearingHosue == 'Both' )|| (!selection?.isEmpty() && isWaystar != 'Waystar'&&isChangeHealthCare == 'ChangeHealthCare')"
                  mat-button>ChangeHealthCare
                  Selected {{selection?.selected?.length}} Claims
                  <mat-icon *ngIf="isChangeHealthCareProcess" class='mr-2 ml-2'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                  </mat-icon>
                </button>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div> -->
