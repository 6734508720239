<div class="table table-responsive">
    <table id="tblMasterReupply" datatable [dtOptions]="dtOptionsResupply"
        class="tbl hover row-border hover w-100 display">
        <thead>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th [matTooltip]="'Insurance Category'">Insurance Category</th>
                <th [matTooltip]="'Product Category'">Product Category</th>
                <th [matTooltip]="'Quantity'">Quantity</th>
                <th [matTooltip]="'Due Days'">Due Days</th>
                <th [matTooltip]="'Created By'">Created By</th>
                <th [matTooltip]="'Created Date'">Created Date</th>
                <th [matTooltip]="'Modified By'">Modified By</th>
                <th [matTooltip]="'Modified Date'">Modified Date</th>
                <th [matTooltip]="'Status'">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of resupplyTableData">
                <td>

                    <a class="eyeCursorclass" [class.disabled]="data?.isActive != true" target="_blank"
                        (click)="editResuplly(data?.id)" [ngClass]="{ 'disabled-link': data?.isActive === false }"><i
                            class="fa fa-pencil editBtnColor"></i>
                    </a>
                </td>

                <td (dblclick)="onRowDoubleClick(data?.insCatName || '-')" [matTooltip]="data?.insCatName">
                    {{data?.insCatName ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.productCatName || '-')" [matTooltip]="data?.productCatName">
                    {{data?.productCatName ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.quantity || '-')" [matTooltip]="data?.quantity">
                    {{data?.quantity ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.dueDays || '-')" [matTooltip]="data?.dueDays">
                    {{data?.dueDays ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.createdBy || '-')" [matTooltip]="data?.createdBy">
                    {{data?.createdBy ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.creationTime || '-')" [matTooltip]="data?.creationTime">
                    {{data?.creationTime ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.modifiedBy || '-')" [matTooltip]="data?.modifiedBy">
                    {{data?.modifiedBy ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.lastModificationTime || '-')" [matTooltip]="data?.lastModificationTime">
                    {{data?.lastModificationTime ||'-' }}
                </td>

                <td>
                    <ng-container *ngIf="data?.isActive === true">
                        <mat-slide-toggle (change)="statusChange($event, data.id)" [checked]="true" class="ml-2">
                            {{ data?.isActive ? "Active" : "Inactive" }}
                        </mat-slide-toggle>
                    </ng-container>
                    <ng-container *ngIf="data?.isActive === false">
                        <mat-slide-toggle (change)="statusChange($event, data.id)" [checked]="false" class="ml-2">{{
                            data?.isActive ? "Active" : "Inactive" }}
                        </mat-slide-toggle>
                    </ng-container>
                </td>

            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Insurance Category"
                        name="search-InsuranceCategory" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Product Category"
                        name="search-ProductCategory" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Quantity"
                        name="search-Quantity" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Due Days"
                        name="search-DueDays" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                        name="search-Created By" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Created Date"
                        name="search-CreatedDate" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                        name="search-ModifiedBy" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Modified Date"
                        name="search-ModifiedDate" />
                </th>
                <th class="text-center">
                    Status
                </th>
            </tr>
        </tfoot>
    </table>
</div>