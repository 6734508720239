import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexMarkers, ApexPlotOptions, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { ChartDatasDTO, MonthSummaryReportsDTO, WeekSummaryReportsDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ChatReports } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/chat-reports.enum';
import { QuarterYear } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/quarter-year.enum';
import { ReportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/report-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { SourceMDPatientService } from 'projects/patient/src/app/patient-proxy/source-mdpatient';
import { ChartDataDTO, MonthSummaryDataReportsDTO, SourceMDCustomReportDTO, SourceMDCustomReportsDTO, TrendSourceMDChartReportDTO, WeekSummaryDataReportsDTO } from 'projects/patient/src/app/patient-proxy/source-mdpatient/dto';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { ReportsMailSenderComponent } from '../reports-mail-sender/reports-mail-sender.component';
import { TableService } from './../../../../shared/src/app/table.service';
export const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  colors: [],
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
};

export type reportsType = "WeeklyReports" | "MonthlyReports" | 'WeeklyTrends' | 'MonthlyTrends' | 'QuaterlyReport';
export type reportPageType = "MISReports" | "SourceMd";
@Component({
  selector: 'app-ngx-chart',
  templateUrl: './ngx-chart.component.html',
  styleUrls: ['./ngx-chart.component.scss']
})
export class NgxChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  emailMask: any = emailMask;
  customViewTable: any[] = [];
  public chartOptions: Partial<ChartOptions>;
  weeklyData: WeekSummaryReportsDTO;
  monthlyData: MonthSummaryReportsDTO;
  dataType: reportsType;
  trendsTitle: string = '';
  pageType: reportPageType = null;
  weeklySMDData: WeekSummaryDataReportsDTO = { weekItemData: null, weekVerificationData: null };
  monthlySMDData: MonthSummaryDataReportsDTO = { monthItemData: null, monthVerificationData: null };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pagetype: reportPageType, type: reportsType, typeofquery: QuarterYear, year: string },
    private reportService: ReportService,
    private sourceMdservice: SourceMDPatientService,
    private table: TableService,
    public dialog: MatDialog,

  ) {

  }

  ngOnInit(): void {

    this.dataType = this.data?.type;
    this.pageType = this.data?.pagetype;
    this.trendsTitle = '';

    if (this.pageType == 'SourceMd') {
      if (this.dataType === "MonthlyTrends" || this.dataType === "WeeklyTrends") {
        this.trendsTitle = (this.dataType === "MonthlyTrends") ? "Four Months Verification Trends" : "Four Weeks Verification Trends";
        this.getChartToRender();
      }
      if (this.dataType === "MonthlyReports" || this.dataType === "WeeklyReports") {
        this.sourceMdservice.getSummaryDataByInput(this.dataType === "WeeklyReports" ? ReportType.Week : ReportType.Month).subscribe(response => {
          this.weeklySMDData.weekVerificationData = response?.weekSummaryReport?.weekVerificationData ?? null;
          this.monthlySMDData.monthVerificationData = response?.monthSummaryReport?.monthVerificationData ?? null;


        })
        this.getChartToRender();
      }

      if (this.dataType === "QuaterlyReport") {
        this.getChartToRender();
      }
    } else {
      if (this.dataType === "MonthlyReports" || this.dataType === "WeeklyReports" || this.dataType === "QuaterlyReport") {
        this.getChartToRender();
      }
      if (this.dataType === "MonthlyTrends" || this.dataType === "WeeklyTrends") {
        this.trendsTitle = (this.dataType === "MonthlyTrends") ? "Four Months Branch Trends" : "Four Weeks Branch Trends";
        this.getChartToRender();
      }
    }

  }
  //! get chart data to render based on type
  getChartToRender() {
    this.customViewTable = [];
    if (this.pageType === 'SourceMd') {
      if (this.dataType === "WeeklyReports") {
        const WeekOrMonth: string = "Week";
        const periods: string[] = ["Previous Week", "Current Week"];
        this.sourceMdservice.getSourceMDChartDataByInput(ReportType.Week).subscribe(value => {
          const branches: string[] = value?.chartDetails?.lstVerifications ?? [];
          let modifedAry: { name: string, data: number[] }[] = [];
          value?.chartDetails?.chartData?.forEach((element: ChartDataDTO, index: number) => {
            const isCurtOrPrev = ((+(value?.chartDetails?.chartData[index]?.numberOfWeekOrMonth) ?? 0) >= (+(value?.chartDetails?.chartData[index + 1]?.numberOfWeekOrMonth) ?? 0));
            const strElement: string = isCurtOrPrev ? periods[0] : periods[1];
            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: element?.count?.map((e: number) => e = (+(+e) ?? 0))
            };
            modifedAry?.push(modfiedData);
          });
          this.setChartData(modifedAry, branches, WeekOrMonth);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "MonthlyReports") {
        const periods: string[] = ["Previous Month", "Current Month"];
        const WeekOrMonth: string = "Month";
        this.sourceMdservice.getSourceMDChartDataByInput(ReportType.Month).subscribe(value => {
          const branches: string[] = value?.chartDetails?.lstVerifications ?? [];
          let modifedAry: { name: string, data: number[] }[] = [];
          value?.chartDetails?.chartData?.forEach((element: ChartDatasDTO, index: number) => {
            const isCurtOrPrev = ((+(value?.chartDetails?.chartData[index]?.numberOfWeekOrMonth) ?? 0) >= (+(value?.chartDetails?.chartData[index + 1]?.numberOfWeekOrMonth) ?? 0));
            const strElement: string = isCurtOrPrev ? periods[0] : periods[1];
            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: element?.count?.map((e: number) => e = (+(+e) ?? 0))
            };
            modifedAry?.push(modfiedData);
          });
          this.setChartData(modifedAry, branches, WeekOrMonth);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "WeeklyTrends") {
        const WeekOrMonth: string = "Week";
        this.sourceMdservice.getTrendChartReportByInput(ReportType.Week).subscribe(value => {
          let modifedAry: { name: string, data: number[] }[] = [];
          const periods: string[] = value?.lstVerification ?? [];
          value?.chartData?.forEach((element: TrendSourceMDChartReportDTO) => {
            const strElement: string = WeekOrMonth + " - " + String(element?.numberOfWeekOrMonth);
            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: element?.count?.map((e: number) => e = (+(+e) ?? 0))
            };
            modifedAry?.push(modfiedData);
          });
          this.setChartData(modifedAry, periods, WeekOrMonth);

        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "MonthlyTrends") {
        const WeekOrMonth: string = "Month";
        this.sourceMdservice.getTrendChartReportByInput(ReportType.Month).subscribe(value => {
          let modifedAry: { name: string, data: number[] }[] = [];
          const branches: string[] = value?.lstVerification ?? [];
          value?.chartData?.forEach((element: TrendSourceMDChartReportDTO) => {
            const strElement: string = WeekOrMonth + " - " + String(element?.numberOfWeekOrMonth);
            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: element?.count?.map((e: number) => e = (+(+e) ?? 0))
            };
            modifedAry?.push(modfiedData);
          });
          this.setChartData(modifedAry, branches, WeekOrMonth);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "QuaterlyReport") {
        this.sourceMdservice.getCustomReportByYearAndQuarterYear((+this.data?.year), this.data.typeofquery).subscribe((value: SourceMDCustomReportsDTO) => {
          let data: SourceMDCustomReportDTO[] = value?.customReports;
          this.customViewTable = data?.map(e => {
            let data = { verificationStatus: e?.verificationStatus, month: month[e?.month - 1 ?? 0], totalCount: e?.count }
            return data;
          });
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }

    } else {
      if (this.dataType === "MonthlyReports") {
        const periods: string[] = ["Previous Month", "Current Month"];
        const WeekOrMonth: string = "Month";
        this.reportService.getChartDatasByInput(ChatReports.MonthlyReport).subscribe(value => {
          const branches: string[] = value?.monthlyChart?.lstBranches ?? [];
          let modifedAry: { name: string, data: number[] }[] = [];
          value?.monthlyChart?.chartDatas?.forEach((element: ChartDatasDTO, index: number) => {
            const isCurtOrPrev = ((+(value?.monthlyChart?.chartDatas[index + 1]?.period) ?? 0) >= (+(value?.monthlyChart?.chartDatas[index]?.period) ?? 0));
            const strElement: string = isCurtOrPrev ? periods[0] : periods[1];
            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: element?.count?.map((e: number) => e = (+(+e) ?? 0))
            };
            modifedAry?.push(modfiedData);
          });
          this.chartReportData(modifedAry, branches, WeekOrMonth);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "WeeklyReports") {
        const WeekOrMonth: string = "Week";
        const periods: string[] = ["Previous Week", "Current Week"];
        this.reportService.getChartDatasByInput(ChatReports.WeeklyReport).subscribe(value => {
          const branches: string[] = value?.weeklyChart?.lstBranches ?? [];
          let modifedAry: { name: string, data: number[] }[] = [];
          value?.weeklyChart?.chartDatas?.forEach((element: ChartDatasDTO, index: number) => {
            const isCurtOrPrev = ((+(value?.weeklyChart?.chartDatas[index + 1]?.period) ?? 0) >= (+(value?.weeklyChart?.chartDatas[index]?.period) ?? 0));
            const strElement: string = isCurtOrPrev ? periods[0] : periods[1];

            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: element?.count
            };
            modifedAry?.push(modfiedData);
          });
          this.chartReportData(modifedAry, branches, WeekOrMonth);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "MonthlyTrends") {
        const WeekOrMonth: string = "Month";
        this.reportService.getTrendChartReportByInput(ReportType.Month).subscribe(value => {
          let modifedAry: { name: string, data: number[] }[] = [];
          value?.count?.forEach((element: number, index: number) => {
            const strElement: string = WeekOrMonth + " - " + String(value?.period[index]);
            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: value?.count,
            };
            modifedAry?.push(modfiedData);
          });
          this.setChartData(modifedAry, value?.period, WeekOrMonth);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "WeeklyTrends") {
        const WeekOrMonth: string = "Week";
        this.reportService.getTrendChartReportByInput(ReportType.Week).subscribe(value => {
          let modifedAry: { name: string, data: number[] }[] = [];
          value?.count?.forEach((element: number, index: number) => {
            const strElement: string = WeekOrMonth + " - " + String(value?.period[index]);
            let modfiedData: { name: string, data: number[] } = {
              name: strElement,
              data: value?.count,
            };
            modifedAry?.push(modfiedData);
          });
          this.setChartData(modifedAry, value?.period, WeekOrMonth);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      if (this.dataType === "QuaterlyReport") {
        this.reportService.getCustomReportByYearAndQuarterYear((+this.data?.year), this.data.typeofquery).subscribe(value => {
          this.customViewTable = value['customReports']
          this.customViewTable = this.customViewTable?.map(e => {
            let data = { branchName: e?.branchName, month: month[e?.month - 1 ?? 0], totalBilled: e?.totalBilled }
            return data;
          });
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }

    }

  }
  //! Set chart data to render based on values
  setChartData(value: { name: string, data: number[] }[], periods: string[], WeekOrMonth: string) {

    this.chartOptions = {
      series: value ?? [],
      chart: {
        type: "line",
        zoom: { enabled: false },
        toolbar: {
          tools: {
            download: false,
            selection: false,
          }
        }
      },

      dataLabels: {
        enabled: true
      },
      stroke: {
        width: 5,
        curve: "smooth",
        dashArray: [0, 8, 5]
      },
      colors: [],

      title: {
        text: "",
        align: "left"
      },
      legend: {
        floating: true,
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        labels: {
          trim: false
        },
        categories: periods ?? []
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          }
        ]
      },
      grid: {
        borderColor: "#f1f1f1"
      },

    };
  }
  //! Send Reports as Mail
  sendReportsToMailUsingPopUp(data: string) {
    const dialogRef = this.dialog.open(ReportsMailSenderComponent, {
      disableClose: true,
      data: { reportType: data }
    });
    dialogRef.afterClosed().subscribe(() => {

    });
  }

  chartReportData(value: { name: string, data: number[] }[], periods: string[], WeekOrMonth: string) {
    this.chartOptions = {
      series: value ?? [],
      chart: {
        type: "bar",
        height: 500,
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: periods ?? []
      }
    };
  }
}
