import type { CreateUpdateProductAllowablesDTO, InsuranceValidityDTO, ProductAllowablesDTO, SearchProductAllowablesDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductAllowablesService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductAllowablesDTO) =>
    this.restService.request<any, ProductAllowablesDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-allowables',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-allowables/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, ProductAllowablesDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-allowables/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductAllowablesDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-allowables',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateProductAllowablesDTO) =>
    this.restService.request<any, ProductAllowablesDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-allowables/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getInsuranceRules = (id: string, ruleId: string) =>
    this.restService.request<any, InsuranceValidityDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-allowables/${id}/get-insurance-rules/${ruleId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getInsuranceRulesList = (id: string) =>
    this.restService.request<any, PagedResultDto<InsuranceValidityDTO>>({
      method: 'GET',
      url: `/api/ItemApp/product-allowables/${id}/get-insurance-rules-list`,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchProductAllowablesByInput = (input: SearchProductAllowablesDTO) =>
    this.restService.request<any, PagedResultDto<ProductAllowablesDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-allowables/search-product-allowables',
      params: { productNames: input.productNames, insuranceNames: input.insuranceNames, createdFromDate: input.createdFromDate, createdToDate: input.createdToDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
