<!-- accordion -->
<div class="card card-body">
<mat-accordion>
    <mat-expansion-panel (toggle)="(step)" [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
        <mat-expansion-panel-header>
            <mat-panel-title clss="customThemeClass">
                <ng-container *ngIf="
                InsCategoryId === defaultGuid;
                    else elseTitleTemplate
                  ">
                    <b>Add Insurance details</b>
                </ng-container>
                <ng-template #elseTitleTemplate>
                    <b>Edit Insurance details</b>
                </ng-template>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="insuranceDetailsForm">
            <div class="row">
                <mat-form-field class="col-3" style="width:395px">
                    <mat-label>Name<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" maxlength="40" formControlName="txtname">
                    <mat-error *ngIf="(insuranceDetailsForm?.get('txtname')?.touched &&
               insuranceDetailsForm?.get('txtname')?.errors)">
                        Name is a required field!
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3" style="width:395px">
                    <mat-label>Mask Call Increment<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" class="no-spinners" formControlName="maskCallIncrement" step="1"
                        min="1" maxlength="8" pattern="^0*(\d{1,7}?|10000000?)$" (keydown.ArrowUp)="$event.preventDefault()"
                        (keydown.ArrowDown)="$event.preventDefault()"  (keypress)="onKeyPress($event)"
                        (paste)="onPaste($event)">
                    <mat-error *ngIf="(insuranceDetailsForm?.get('maskCallIncrement')?.touched &&
                        insuranceDetailsForm?.get('maskCallIncrement')?.errors?.pattern)"
                        >
                       Enter number must be between 0 and 10000000
                    </mat-error>
                    <mat-error *ngIf="(insuranceDetailsForm?.get('maskCallIncrement')?.touched &&
                        insuranceDetailsForm?.get('maskCallIncrement')?.errors?.required)">
                        Mask Call Increment is a required field!
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3" style="width:395px">
                    <mat-label>Supplies Call Increment <span class="asterisk">*</span></mat-label>
                    <input matInput type="text" class="no-spinners" formControlName="suppliesCallIncrement" step="1"
                        min="1" maxlength="8" pattern="^0*(\d{1,7}?|10000000?)$" (keydown.ArrowUp)="$event.preventDefault()"
                        (keydown.ArrowDown)="$event.preventDefault()" (keypress)="onKeyPress($event)"
                        (paste)="onPaste($event)">
                    <mat-error *ngIf="(insuranceDetailsForm?.get('suppliesCallIncrement')?.touched &&
                        insuranceDetailsForm?.get('suppliesCallIncrement')?.errors?.pattern)">
                        Enter number must be between 0 and 10000000!
                    </mat-error>
                    <mat-error *ngIf="(insuranceDetailsForm?.get('suppliesCallIncrement')?.touched &&
                        insuranceDetailsForm?.get('suppliesCallIncrement')?.errors?.required)">
                        Supplies Call Increment is a required field!
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3" style="width:395px">
                    <mat-label>Order Machine Points<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" class="no-spinners" formControlName="orderMachinePoints" step="1"
                        min="1" maxlength="8" pattern="^0*(\d{1,7}?|10000000?)$" (keydown.ArrowUp)="$event.preventDefault()"
                        (keydown.ArrowDown)="$event.preventDefault()"(keypress)="onKeyPress($event)"
                        (paste)="onPaste($event)">
                    <mat-error *ngIf="(insuranceDetailsForm?.get('orderMachinePoints')?.touched &&
                        insuranceDetailsForm?.get('orderMachinePoints')?.errors?.pattern)">
                        Enter number must be between 0 and 10000000!
                    </mat-error>
                    <mat-error *ngIf="(insuranceDetailsForm?.get('orderMachinePoints')?.touched &&
              insuranceDetailsForm?.get('orderMachinePoints')?.errors?.required)">
                        Order Machine Points is a required field!
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3" style="width:395px">
                    <mat-label>Default deductible<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" class="no-spinners" formControlName="defaultDeduct" step="0.01" min="1"
                         pattern="^0*(\d{1,7}(\.\d{1,2})?|10000000(\.0{1,2})?)$" (keydown.ArrowUp)="$event.preventDefault()"
                        (keydown.ArrowDown)="$event.preventDefault()"(keypress)="onKeyPressV1($event)"
                        (paste)="onPaste($event)">
                    <mat-error *ngIf="(insuranceDetailsForm?.get('defaultDeduct')?.touched &&
              insuranceDetailsForm?.get('defaultDeduct')?.errors?.pattern)">
                       Enter number must be between 0 and 10000000 or two digits only after the decimal!
                    </mat-error>
                    <mat-error *ngIf="(insuranceDetailsForm?.get('defaultDeduct')?.touched &&
              insuranceDetailsForm?.get('defaultDeduct')?.errors?.required)">
                        Default deductible is a required field!
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                    <mat-label>Compliance machine points <span class="asterisk">*</span> </mat-label>
                    <input autocomplete="new-complianceMachine" class="no-spinners" step="0.01" min="1" type="text"
                    pattern="^0*(\d{1,7}(\.\d{1,2})?|10000000(\.0{1,2})?)$" formControlName="complianceMachine" matInput
                        (keydown.ArrowUp)="$event.preventDefault()" (keydown.ArrowDown)="$event.preventDefault()" (keypress)="numbersAndfloatOnly($event)">
                    <mat-error *ngIf="(insuranceDetailsForm?.get('complianceMachine')?.touched &&
                        insuranceDetailsForm?.get('complianceMachine')?.errors?.pattern)"
                        (paste)="onPaste($event)">
                        Enter number must be between 0 and 10000000 or two digits only after the decimal!
                    </mat-error>
                    <mat-error *ngIf="(insuranceDetailsForm?.get('complianceMachine')?.touched &&
                        insuranceDetailsForm?.get('complianceMachine')?.errors?.required)">
                        Compliance machine points is a required field!
                    </mat-error>
                </mat-form-field>

                <mat-slide-toggle class="col-2" style="margin-top: 1%;"
                    formControlName="disabled">Disabled</mat-slide-toggle>
                <mat-slide-toggle class="col-2" style="margin-top: 1%;" formControlName="precertRequired">Pre cert
                    required
                </mat-slide-toggle>
                <mat-slide-toggle class="col-2" style="margin-top: 1%;" formControlName="hideAllowed">Hide
                    allowed</mat-slide-toggle>
            </div>

            <div class="mt-3 row">
                <mat-slide-toggle class="col-2" formControlName="requriedFunctionality">Requires functionality
                    questions</mat-slide-toggle>
                <mat-slide-toggle class="col-2" formControlName="NoallowedAmount">No allowed amount </mat-slide-toggle>
                <mat-slide-toggle class="col-2" formControlName="isMedicare">Is medicare </mat-slide-toggle>
                <mat-slide-toggle class="col-2" formControlName="requiresPreAuth">Requires pre authorization
                </mat-slide-toggle>
                <mat-slide-toggle class="col-2" formControlName="electronicVerification">Electronic
                    verification</mat-slide-toggle>
            </div>

            <div class="mt-3 row">
                <mat-slide-toggle class="col-2" formControlName="requriedMachineCompliance">Requires machine compliance
                </mat-slide-toggle>
                <mat-slide-toggle class="col-2" formControlName="docChecklistRequired">Doc checklist required
                </mat-slide-toggle>
                <mat-slide-toggle class="col-2" formControlName="autoReminder">Auto reminder </mat-slide-toggle>
                <mat-slide-toggle class="col-2" formControlName="assignPatient">Assign patients to customer service
                </mat-slide-toggle>
            </div>
        </form>
        <br />
        <div class="col-7">
            <div class="text-lg-right center">
                <!-- *ngIf="isShownSaveButton" -->
                <button mat-button (click)="saveInsCategory()" class="buttonColor mr-2"
                    [disabled]="isSaveButton||insuranceDetailsForm.invalid">

                    Save
                    <!-- <mat-icon *ngIf="isShowSpinner">
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
                  </mat-spinner>
                </mat-icon> -->
                </button>
                <button mat-button class="buttonColor mr-2 resetclr" (click)="reset()">
                    Reset
                </button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<br>
<div class="card card-body">
    <!-- table -->
    <div class="row">
        <div class="col-12">

            <div class="table table-responsive mt-3">
                <table id="tbl" datatable [dtOptions]="dtFrequentItemTableOptions"
                    class="tbl hover row-border hover w-100 display">
                    <thead>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th [matTooltip]="'Status'">Status</th>
                            <th [matTooltip]="'Insurance Group'">Insurance Group</th>
                            <th class="text-right"[matTooltip]="'Mask Call Increment'">Mask Call Increment</th>
                            <th class="text-right"[matTooltip]="'Supplies Call Increment'">Supplies Call Increment</th>
                            <th class="text-right"[matTooltip]="'Order Machine Points'">Order Machine Points</th>
                            <th class="text-right"[matTooltip]="'Default Deductible'">Default Deductible</th>
                            <th class="text-right"[matTooltip]="'Compliance Machine Points'">Compliance Machine Points</th>
                            <th [matTooltip]="'Disabled'">Disabled</th>
                            <th [matTooltip]="'Precert Required'">Precert Required</th>
                            <th [matTooltip]="'Hide Allowed'">HideAllowed</th>
                            <th [matTooltip]="'No Allowed Amount'">No Allowed Amount</th>
                            <th [matTooltip]="'Requires Functionality Questions'">Requires Functionality Questions</th>
                            <th [matTooltip]="'Is Medicare'">Is Medicare</th>
                            <th [matTooltip]="'Requires PreAuthorization'">Requires PreAuthorization</th>
                            <th [matTooltip]="'Electronic Verification'">Electronic Verification</th>
                            <th [matTooltip]="'Requires Machine Compliance'">Requires Machine Compliance</th>
                            <th [matTooltip]="'Checklist required '">Checklist required </th>
                            <th [matTooltip]="'Created By'">Created By</th>
                            <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
                            <th [matTooltip]="'Modify By'">Modify By</th>
                            <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of insuranceDetailsData">
                            <td>
                                <a class="eyeCursorclass" target="_blank"
                                    [ngClass]="{ 'disabled-link': data?.status===false }"
                                    (click)="InsCategGetId(data.id)" [matTooltip]="'Click to Edit Insurance'"><i
                                        class="fa fa-pencil editBtnColor mr-1"></i>
                                </a>
                            </td>

                            <td [matTooltip]="data?.status ? 'Active' : 'Inactive' || '-'">
                                <ng-container *ngIf="data?.status==true">
                                    <mat-slide-toggle (change)="statusChange($event,data.id)" [checked]='true'
                                        class="ml-2">
                                        Active</mat-slide-toggle>
                                </ng-container>
                                <ng-container *ngIf="data?.status==false">
                                    <mat-slide-toggle (change)="statusChange($event,data.id)" [checked]='false'
                                        class="ml-2">
                                        Inactive</mat-slide-toggle>
                                </ng-container>
                            </td>

                            <td [matTooltip]="data?.insuranceCategory || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.insuranceCategory || '-')">{{
                                data.insuranceCategory ===null?'-':data?.insuranceCategory}}
                            </td>
                                <td class="text-right"[matTooltip]="data?.maskCallIncrement || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.maskCallIncrement || '-')">{{
                                data.maskCallIncrement ===null?'-':data?.maskCallIncrement}}
                              </td>
                                <td class="text-right"[matTooltip]="data?.suppliesCallIncrement || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.suppliesCallIncrement || '-')">{{
                                data.suppliesCallIncrement ===null?'-':data?.suppliesCallIncrement}}
                              </td>
                                <td class="text-right"[matTooltip]="data?.orderMachinePoints || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.orderMachinePoints || '-')">{{
                                data.orderMachinePoints ===null?'-':data?.orderMachinePoints}}
                              </td>
                                <td class="text-right"[matTooltip]="data?.defaultDeductible || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.defaultDeductible || '-')">{{
                                data.defaultDeductible ===null?'-':data?.defaultDeductible}}
                              </td>
                                <td class="text-right"[matTooltip]="data?.complianceMachinePoints || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.complianceMachinePoints || '-')">{{
                                data.complianceMachinePoints ===null?'-':data?.complianceMachinePoints}}
                              </td>
                              <td [matTooltip]="data?.disabled || '-'"
                              (dblclick)="onRowDoubleClickV1(data?.disabled || '-')">
                              {{data?.disabled===null?'-':data?.disabled}}
                          </td>
                            <td [matTooltip]="(data?.preCertRequired == true ? 'Yes' : 'No') || '-'"
                                (dblclick)="onRowDoubleClickV1((data?.preCertRequired == true ? 'Yes' : 'No') || '-')">
                                {{data?.preCertRequired===null?'-': (data?.preCertRequired == true ? 'Yes' : 'No')}}
                            </td>
                            <td [matTooltip]="data?.hideAllowed || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.hideAllowed || '-')">
                            {{data?.hideAllowed===null?'-':data?.hideAllowed}}
                        </td>
                        <td [matTooltip]="data?.noAllowedAmount || '-'"
                        (dblclick)="onRowDoubleClickV1(data?.noAllowedAmount || '-')">
                        {{data?.noAllowedAmount===null?'-':data?.noAllowedAmount}}
                    </td>
                    <td [matTooltip]="data?.requiresFunctionalityQuestions || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.requiresFunctionalityQuestions || '-')">
                                {{data?.requiresFunctionalityQuestions===null?'-':data?.requiresFunctionalityQuestions}}
                            </td>
                            <td [matTooltip]="data?.isMedicare || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.isMedicare || '-')">
                                {{data?.isMedicare===null?'-':data?.isMedicare}}
                            </td>
                            <td [matTooltip]="data?.requiresPreAuthorization || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.requiresPreAuthorization || '-')">
                                {{data?.requiresPreAuthorization===null?'-':data?.requiresPreAuthorization}}
                            </td>
                            <td [matTooltip]="data?.electronicVerification || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.electronicVerification || '-')">
                                {{data?.electronicVerification===null?'-':data?.electronicVerification}}
                            </td>
                            <td [matTooltip]="data?.requiresMachineCompliance || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.requiresMachineCompliance || '-')">
                            {{data?.requiresMachineCompliance===null?'-':data?.requiresMachineCompliance}}
                        </td>
                            <td [matTooltip]="(data?.docCheckListRequired == true ? 'Yes' : 'No')  || '-'"
                                (dblclick)="onRowDoubleClickV1((data?.docCheckListRequired == true ? 'Yes' : 'No') || '-')">
                                {{data?.docCheckListRequired===null?'-':(data?.docCheckListRequired == true ? 'Yes' : 'No')}}
                            </td>
                            <td [matTooltip]="data?.createdBy || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{
                            data?.createdBy || '-' }}</td>

                        <td class="text-right" [matTooltip]="data?.creationTime"
                            (dblclick)="onRowDoubleClickV1(data?.creationTime)" >
                            {{ data?.creationTime }}
                        </td>
                        <td [matTooltip]="data?.modifyBy|| '-'"
                            (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{
                            data?.modifyBy || '-' }}</td>

                        <td class="text-right" [matTooltip]="data?.lastModificationTime"
                            (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" >
                            {{ data?.lastModificationTime }}
                        </td>
                            
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Status"
                                    name="search-Status" />
                            </th>
                            <th>
                                <input matInput type="text" class="textbox-border-class" placeholder="Insurance Group"
                                    name="search-InsuranceGroup" />
                            </th>
                            <th class="text-right">
                              <input matInput type="text" class="textbox-border-class" placeholder="Mask Call Increment"
                                    name="search-Mask Call Increment" />
                                </th>
                                <th class="text-right">
                                  <input matInput type="text" class="textbox-border-class" placeholder="Supplies Call Increment"
                                      name="search-Supplies Call Increment" />
                              </th>
                              <th class="text-right">
                                <input matInput type="text" class="textbox-border-class" placeholder="Order Machine Points"
                                    name="search-Order Machine Points" />
                            </th >
                            <th class="text-right">
                              <input matInput type="text" class="textbox-border-class" placeholder="Default Deductible"
                                  name="search-Default Deductible" />
                          </th>
                          <th class="text-right">
                            <input matInput type="text" class="textbox-border-class" placeholder="Compliance Machine Points"
                                name="search-Compliance Machine Points" />
                        </th>
                        <th>
                          <input matInput class="textbox-border-class" type="text" placeholder="Disabled"
                              name="search-Disabled" />
                      </th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Pre-cert Required"
                                    name="search-Pre-certRequired" />
                            </th>
                            <th>
                              <input matInput class="textbox-border-class" type="text" placeholder="Hide Allowed"
                                  name="search-Hide Allowed" />
                          </th>
                          <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="No Allowed Amount"
                                name="search-No Allowed Amount" />
                        </th>
                        <th>
                           <input matInput class="textbox-border-class" type="text"
                                placeholder="Requires Functionality Questions" name="search-Requires Functionality Questions" />
                           </th>
                           <th>
                             <input matInput class="textbox-border-class" type="text"
                                 placeholder="Is Medicare" name="search-Is Medicare" />
                         </th>
                         <th>
                           <input matInput class="textbox-border-class" type="text"
                               placeholder="Requires PreAuthorization" name="search-Requires PreAuthorization" />
                       </th>
                       <th>
                         <input matInput class="textbox-border-class" type="text"
                             placeholder="Electronic Verification" name="search-Electronic Verification" />
                     </th>
                     <th>
                      <input matInput class="textbox-border-class" type="text"
                          placeholder="Requires Machine Compliance" name="search-Requires Machine Compliance" />
                  </th>
                            <th>
                                <input matInput class="textbox-border-class" type="text"
                                    placeholder="Checklist required" name="search-Checklistrequired" />
                            </th>

                      <th>
                        <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                            name="search-Created By" />
                    </th>
                    <th class="text-right">
                        <input matInput class="textbox-border-class " type="text"
                              placeholder="Created Date" name="search-Created Date" />
                     </th>
                     <th>
                        <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                            name="search-Modify By" />
                    </th>
                      <th class="text-right">
                          <input matInput class="textbox-border-class " type="text"
                              placeholder="Modified Date" name="search-Modified Date" />
                      </th>

                            
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
