<!-- <div class="card card-body">
        <div class="row bg">
            <div class="col-12">
                <mat-label><b class="customThemeClass custom-tab-header">Fax Log Info</b></mat-label>
            </div>
            <div class="col-lg-12 mt-3">
                <div class="row" *ngIf="isLoading">
                    <div class="col-12">
                        <div class="d-flex justify-content-center" *ngIf="isLoading">
                            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                        </div>
                    </div>
                </div> -->
<!-- <div *ngIf="!isLoading" class="table table-responsive"> -->
<table id="tbl" datatable [dtOptions]="dtOptions" class="hover nowrap_th_td w-100 display">
    <thead>
        <tr>
            <th [matTooltip]="'Fax Number'">Fax Number</th>
            <th [matTooltip]="'Fax Id'">Fax Id</th>
            <th [matTooltip]="'Pages'" class="text-right">Pages</th>
            <th [matTooltip]="'Location'">Location</th>
            <th [matTooltip]="'Patient Name'">Patient Name</th>
            <th [matTooltip]="'Chart ID'">Chart ID</th>
            <th [matTooltip]="'Physician Name'">Physician Name</th>
            <th [matTooltip]="'Fax Received Date'">Fax Received Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of practiceFaxlog">
            <td [matTooltip]="data.faxNumber || 'N/A-(Manaul Upload)'" (dblclick)="onRowDoubleClickV1(data?.faxNumber || 'N/A')">
                {{ data.faxNumber || 'N/A'}}
            </td>
            <td [matTooltip]="data.faxId || '-'" (dblclick)="onRowDoubleClickV1(data?.faxId || '-')">
                <a [routerLink]="'/patient_preview_tiff_document/'+ data.faxId+ '/' + data.id" target="_blank"
                    [class.disabled]="isEmpty(data.faxId)" class="preview-link">{{
                    data.faxId ?
                    data.faxId :
                    '-'}}</a>
            </td>
            <td [matTooltip]="data.pageCount || '-'" (dblclick)="onRowDoubleClickV1(data?.pageCount || '-')" class="text-right">{{
                data.pageCount || '-' }}</td>
            <td [matTooltip]="data.location || '-'" (dblclick)="onRowDoubleClickV1(data?.location || '-')">{{
                data.location || '-' }}</td>
            <td [matTooltip]="data.patientName || '-'" (dblclick)="onRowDoubleClickV1(data?.patientName || '-')">{{
                data.patientName || '-' }}</td>
            <!-- <td [matTooltip]="data.patientId || '-'" (dblclick)="onRowDoubleClickV1(data?.patientId || '-')">{{
                data.patientId || '-' }}</td> -->
            <td (dblclick)="onRowDoubleClickV1(data?.chartID || '-')">
                <a [class.disabled]="isEmpty(data?.patientId)" class="preview-link"
                    [routerLink]="'/patientCreateEditTab/' + data?.patientId" target="_blank"
                    [matTooltip]="data?.chartID || '-'">{{ data?.chartID || '-'}}</a>
            </td>
            <td [matTooltip]="data.doctorName || '-'" (dblclick)="onRowDoubleClickV1(data?.doctorName || '-')">{{
                data.doctorName || '-' }}</td>
            <td [matTooltip]="getFormattedDate(data?.uplodedDate)"
                   (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.uplodedDate))" class="text-center">
                   {{ getFormattedDate(data?.uplodedDate) }}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Fax Number" name="search-FaxNumber" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Fax Id" name="search-FaxId" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class text-right" placeholder="Pages" name="search-Pages" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Location"
                    name="search-Location" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Patient Name"
                    name="search-PatientName" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Chart ID"
                    name="search-Chart" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Physician Name"
                    name="search-PhysicianName" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class text-center" placeholder="Fax Received Date"
                    name="search-Faxreceiveddate" />
            </th>
        </tr>
    </tfoot>
</table>

<!-- </div> -->
<!-- </div>
        </div>
    </div> -->