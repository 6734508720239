import type { CreateUpdateOrderDetailsDTO, OrderCompletedList, OrderDetailsDTO, orderSearchDTO, ShippingApprovalDTO  } from './dto/models';
import type { ShippingApproval } from './entity/models';
import type { ShippingApprovalStatus } from './shipping-approval-status.enum';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientSaleOrderService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateOrderDetailsDTO) =>
    this.restService.request<any, OrderDetailsDTO>({
      method: 'POST',
      url: '/api/orderApp/patient-sale-order',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/patient-sale-order/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, OrderDetailsDTO>({
      method: 'GET',
      url: `/api/orderApp/patient-sale-order/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<OrderDetailsDTO>>({
      method: 'GET',
      url: '/api/orderApp/patient-sale-order',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateOrderDetailsDTO) =>
    this.restService.request<any, OrderDetailsDTO>({
      method: 'PUT',
      url: `/api/orderApp/patient-sale-order/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getOrderStatusByOrderId = (orderId: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/orderApp/patient-sale-order/get-order-status/${orderId}`,
    },
    { apiName: this.apiName });

  getSaleOrdersByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, OrderDetailsDTO[]>({
      method: 'GET',
      url: `/api/orderApp/patient-sale-order/get-sale-orders-by-patient-id/${patientId}`,
    },
    { apiName: this.apiName });

  getShippingApprovalStatusByShipping = (shipping: ShippingApproval) =>
    this.restService.request<any, ShippingApprovalStatus>({
      method: 'GET',
      url: '/api/orderApp/patient-sale-order/get-shipping-approval-status',
    },
    { apiName: this.apiName });

  orderSearchBySDefaultPatientIdAndSSaleOrderIdAndSPatientNameAndSCSRNameAndDtDOSAndSStatus = (patientId:string,sDefaultPatientId: string, sSaleOrderId: string, sPatientName: string, sCSRName: string, dtDOS: string, sStatus: string , isOrderComplete: boolean=false ) =>
    this.restService.request<any, orderSearchDTO[]>({
      method: 'POST',
      url: '/api/orderApp/patient-sale-order/order-search',
      params: { patientId,sDefaultPatientId, sSaleOrderId, sPatientName, sCSRName, dtDOS, sStatus, isOrderComplete },
    },
    { apiName: this.apiName });

  searchShippingApporvalListBySSaleOrderIdAndSDefaultPatientIdAndSPatientNameAndSUserNameAndStatusAndDtDOS = (sSaleOrderId: string, sDefaultPatientId: string, sPatientName: string, sUserName: string, Status: string, dtDOS: string) =>
    this.restService.request<any, ShippingApprovalDTO[]>({
      method: 'POST',
      url: '/api/orderApp/patient-sale-order/search-shipping-apporval-list',
      params: { sSaleOrderId, sDefaultPatientId, sPatientName, sUserName, status: Status, dtDOS },
    },
    { apiName: this.apiName });

    orderApp_patient_sale_orderget_order_details = (patientId: string) =>
    this.restService.request<any, OrderCompletedList>({
      method: 'GET',
      url: `/api/orderApp/patient-sale-order/get-order-details/${patientId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
