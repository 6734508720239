import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ChartDTO, OrderMasterDropdownDTO, PayablePercentDTO, PlanTypeDTO, PreparedByDTO, PriceCodeDTO, TypeOfPlanDTO, VerifiedByDTO, YearTypeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownDTO, PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient/patient.service';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { drpPolicesDTO } from 'projects/shipping/src/app/shipping-verification/shipping-verification.component';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { SaleOrderSearchService } from '../order-proxy/order-management/order';
import { ToastrService } from "ngx-toastr";
import { SaleorderDTO } from '../order-proxy/order-management/order/dto';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { getOrder, updateOrder } from '../order-store/order.action';

@Component({
  selector: 'app-add-verification',
  templateUrl: './add-verification.component.html',
  styleUrls: ['./add-verification.component.scss']
})
export class AddVerificationComponent implements OnInit , OnDestroy {
  verificationForm: FormGroup;
  patientids: string;
  orderStatus: boolean = true;
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpVerified: VerifiedByDTO[] = [];
  drpPrepared: PreparedByDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  @Input() patientId: string = '';
  drpPolices: drpPolicesDTO[] = [];
  drpPriceCode: PriceCodeDTO[] = [];
  drpPolicies: any;
  drpPlanType: PlanTypeDTO[] = [];
  drpYearType: YearTypeDTO[] = [];
  verifyTableData: any[] = [];
  plan: string;
  notesText ='';
  type: string;
  typeOfPlan: string;
  payableIndividual: string;
  payableOutOfNetwork: string;
  payableFamily: string;
  BirthdateMinDate: Date;
  payorLevel: PayorLevelDTO[] = [];
  insuranceVerificationId: string = "00000000-0000-0000-0000-000000000000";
  saveButtonHide: boolean
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  constructor(
    private store: Store,
    private orderDropdownService: OrderMasterDropdownService,
    private activatedRoute: ActivatedRoute,
    private frequentItem: SaleorderService,
    private textValidators: TextValidators,
    private Table: TableService,
    private numberValidators: NumberValidators,
    private formBuilder: FormBuilder,
    private patientDropdownService: PatientMasterDropdownService,
    public dialog: MatDialog,
    public patientService: PatientService,
    private toastr: ToastrService,
    private saleOrderService: SaleorderService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId?: string, orderId?: string, verifyData?: any }) { }

  ngOnInit(): void {
    this.createVerificationForm();
    this.getDropdowns();
    const savedRecord = this.data.verifyData;
    if (savedRecord != null) {
      this.getVerifyDatas(this.data.verifyData)
    }
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  // ! Verification Form with Validations
  createVerificationForm() {
    this.verificationForm = this.formBuilder.group({
      //     drpChart: new FormControl("", [Validators.required]),
      txtName: new FormControl("", [this.textValidators.isTextCheck]),
      //   txtAddress: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateOfBirth: new FormControl("", [Validators.required]),
      txtCommentsNotes: new FormControl(""),
      txtPlan: new FormControl(null, [Validators.required]),
      txtPlanName: new FormControl("", [Validators.required]),
      txtPlanPhone: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl("", [Validators.required]),
      txtGroup: new FormControl(""),
      txtEffectiveDate: new FormControl("", [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(""),
      txtEnd: new FormControl(""),
      chkMedicare: new FormControl(""),
      txtVerfnAMethod: new FormControl(""),
      chkPatient: new FormControl(""),
      chkFamily: new FormControl(""),
      txtCmnCode: new FormControl("", [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(""),
      chkOutOfNetwork: new FormControl(""),
      chkFamilyDeductible: new FormControl(""),
      txtDeductibleIndividual: new FormControl(""),
      txtDeductibleOutOfNetwork: new FormControl(""),
      txtDeductibleFamily: new FormControl(""),
      txtDeductibleMetIndividual: new FormControl(""),
      txtDeductibleMetOutOfNetwork: new FormControl(""),
      txtDeductibleMetFamily: new FormControl(""),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(""),
      txtExpenseOutOfNetwork: new FormControl(""),
      txtExpenseFamily: new FormControl(""),
      txtExpenseMetIndividual: new FormControl(""),
      txtExpenseMetOutOfNetwork: new FormControl(""),
      txtExpenseMetFamily: new FormControl(""),
      chkExpenseIndividual: new FormControl(""),
      chkExpenseOutOfNetwork: new FormControl(""),
      chkExpenseFamily: new FormControl(""),
      chkPrecertRequired: new FormControl(""),
      txtPhoneCall: new FormControl("", [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(""),
      txtPcp: new FormControl("", [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(""),
      txtPreNotes: new FormControl(""),
      txtLifetimeMax: new FormControl(""),
      txtLifetimeMaxMet: new FormControl(""),
      chkDmePartsCovered: new FormControl(""),
      chkDmeReplacementUnit: new FormControl(""),
      txtDmeAnnualLimit: new FormControl(""),
      txtDmeLimitMet: new FormControl(""),
      txtRequestedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl("", [this.textValidators.isTextCheck]),
      txtContact: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(""),
      txtPolicyHolderName: new FormControl("", [this.textValidators.isTextCheck]),
      txtPolicyHolderDob: new FormControl(""),
      chkNetwork: new FormControl(""),
      txtClaimAddress: new FormControl(""),
      txtPlanCob: new FormControl(""),
      txtDedTotal: new FormControl(""),
      txtDedMet: new FormControl(""),
      txtOopTotal: new FormControl(""),
      txtOopMet: new FormControl(""),
      txtOopDeductible: new FormControl(""),
      txtDmeCoverage: new FormControl(""),
      txtDmeMax: new FormControl(""),
      txtHcpcs: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtState: new FormControl("", [this.textValidators.isTextCheck]),
      txtCoverageCriteria: new FormControl("", [this.textValidators.isTextCheck]),
      txtQtyLimits: new FormControl("", [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl("", [this.textValidators.isTextCheck]),
      txtRent: new FormControl("", [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl("", [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl("", [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl("", [this.textValidators.isTextCheck]),
      txtSpokeWith: new FormControl("", [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateVerified: new FormControl(""),
      drpPreparedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPreparedOn: new FormControl(""),
      rbtnVerificationStatus: new FormControl("", [Validators.required]),
      txtDeo: new FormControl("", this.numberValidators.isNumberCheck),
      txtCoInsurance: new FormControl(""),
      txtcommentsToTech: new FormControl(""),
      txtReason: new FormControl(""),
      chkActive: new FormControl(""),
      // newly added
      chkIsBlueCardEligible: new FormControl(""),
      chkIsDirectBill: new FormControl(""),
      chkIsPreAuthNeeded: new FormControl(""),
      chkIsClaimSubmit: new FormControl(""),
      chkIsReferenceNumberAvailable: new FormControl(""),
      chkClaimForHCPC: new FormControl(""),
      txtPatientResposponsiblePaymentPercentage: new FormControl(""),
      txtReferenceNumber: new FormControl(""),

      // rbtnPending:new FormControl(""),
      // rbtnUnableToVerify:new FormControl(""),
    })
  }

  // ! Load Order Dropdowns
  getDropdowns() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.VerifiedBies,
      OrderDropdowns.PreparedBies,
      OrderDropdowns.PayablePercents,
      OrderDropdowns.PriceCodes,
      OrderDropdowns.PlanTypes,
      OrderDropdowns.YearTypes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      const response: OrderMasterDropdownDTO = stateResponse;
      this.drpChart = response?.charts;
      this.drpPlan = response?.typeOfPlans;
      this.drpVerified = response?.verifiedBies;
      this.drpPrepared = response?.preparedBies;
      this.drpPayable = response?.payablePercents;
      this.drpPriceCode = response?.priceCodes;
      this.drpPlanType = response?.planTypes;
      this.drpYearType = response?.yearTypes;
    });
    this.subscription$.push(orderDropdown)

    // ! Get Patient Common details based on PatientId
    setTimeout(() => {
      const getPatient = this.patientService.get(this.data.patientId).subscribe(response => {
        // this.store.dispatch(new getPatient(this.data.patientId)).subscribe(response => {
        // let patientDetails: PatientDTO;
        //  let patientDetails = response;
        this.patientids = response?.defaultPatientId;
        this.drpPolicies = response?.policies;
        this.verificationForm.patchValue({
          txtName: response?.personals?.firstName,
          txtAddress: response?.addresses?.length !== 0 ? response?.addresses[0]?.address : "",
          txtDateOfBirth: response?.personals?.dateOfBirth,
        })
      });
      this.subscription$.push(getPatient)
    }, 300);

    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.PayorLevels]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response?.payorLevels;
    });
  }

  // ! Get Order Details for Save Verification
  getOrderDetailsForSave() {
    let personalDetails: SaleorderDTO;
    const getPatient = this.saleOrderService.get(this.data.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
      personalDetails = response;
      this.verifyTableData = [];
      const savedData = {
        primaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.primaryInsuranceVerifies,
        secondaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.secondaryInsuranceVerifies,
        tertiaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.tertiaryInsuranceVerifies
      }
      return this.saveInsuranceVerify(personalDetails, savedData);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getPatient);
  }

  // ! Save Verification
  saveInsuranceVerify(response, savedData) {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    const payorLevelName: string[] = this.payorLevel.map(v => v.payorLevelName);
    if (this.verificationForm.value.txtPlan === payorLevelName[0]) {
      savedData.primaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        groupName: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.verificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.verificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.verificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod,
        reason: this.verificationForm.value?.txtReason,
        isActive: +this.verificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.verificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.verificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.verificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.verificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.verificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.verificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.verificationForm.value?.txtReferenceNumber

      }

    } else if (this.verificationForm.value.txtPlan === payorLevelName[1]) {
      savedData.secondaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        group: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },
        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.verificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.verificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.verificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod,
        reason: this.verificationForm.value?.txtReason,
        isActive: +this.verificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.verificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.verificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.verificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.verificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.verificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.verificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.verificationForm.value?.txtReferenceNumber


      }
    } else if (this.verificationForm.value.txtPlan === payorLevelName[2]) {
      savedData.tertiaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        group: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },
        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.verificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.verificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.verificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod,
        reason: this.verificationForm.value?.txtReason,
        isActive: +this.verificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.verificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.verificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.verificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.verificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.verificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.verificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.verificationForm.value?.txtReferenceNumber

      }
    }
    let insVerification: SaleorderDTO;
    const verifyData = savedData
    insVerification = {
      defaultClaimId: response?.defaultClaimId,
      orders: response?.orders,
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      items: response?.items,
      patients: {
        personals: response?.patients.personals,
        addresses: response?.patients.addresses,
        contacts: response?.patients.contacts,
        clinicals: response?.patients.clinicals,
        insurances: response?.patients.insurances,
        notes: response?.patients.notes,
        policies: response?.patients.policies,
        defaultPatientId: response?.patients.defaultPatientId,
        insuranceVerifications: {
          primaryInsuranceVerifies: verifyData.primaryInsuranceVerifies,
          secondaryInsuranceVerifies: verifyData.secondaryInsuranceVerifies,
          tertiaryInsuranceVerifies: verifyData.tertiaryInsuranceVerifies
        },
        authorizations: response?.patients.authorizations,
        patientDocuments: response?.patients.patientDocuments,
        organizationUnitId: response?.patients.organizationUnitId,
        isAutoIntake: response?.patients.isAutoIntake,
        autoIntakeDocumentDetails: response?.patients.autoIntakeDocumentDetails,
        chartId: response.chartId,
        billingDetails: response?.patients.billingDetails,
        patientLock: response?.patients.patientLock,
        isSorted: response.patients.isSorted,
        fileId: response?.patients?.fileId,
      },

      saleorderId: response?.saleorderId,
      orderStatus: response?.orderStatus,
      organizationUnitId: response?.organizationUnitId,
      currentStage: response?.currentStage,
      currentStageStatus: response?.currentStageStatus,
      claimId: response?.claimId,
      patientId: response?.patientId,
      isShippingApprove: response?.isShippingApprove,
      isCashPayment: response?.isCashPayment,
      orderProcessStatus: response?.orderProcessStatus,
      patientLedgerReason: response.patientLedgerReason,
      documentReason: response.documentReason,
      paymentTransactionDetails: response?.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      claimHistoryStatus: response.claimHistoryStatus,
      patientLedgerStatus: response.patientLedgerStatus,
      shippingCheckList: response.shippingCheckList,
      orderBilling: response.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType

    }
    this.data.patientId !== "" && this.store.dispatch(new updateOrder(this.data.orderId, insVerification)).subscribe(response => {
      // set orderid from save api
      this.Table.setSelectedOrderId(this.data.orderId);
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      this.dialog.closeAll();
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // ! Get Verification Datas
  getVerifyDatas(response) {

    this.insuranceVerificationId = response?.insuranceVerificationId;
    this.patientids = response?.defaultPatientId;
    this.orderStatus = response?.orderStatus;
    this.verificationForm.patchValue({
      insuranceVerificationId: response?.insuranceVerificationId,
      txtName: response?.chart,
      txtDateOfBirth: response?.patientDob,
      txtCommentsNotes: response?.commentsOrNotes,
      txtPlan: response?.plan,
      txtPlanName: response?.planName,
      txtPlanPhone: response?.planPhone,
      txtPolicy: response?.policy,
      txtGroup: response?.group,
      txtEffectiveDate: response?.effectivedate,
      txtType: response?.typeOfPolicy,
      txtFascalYearStart: response?.fascalYearStart,
      txtEnd: response?.end,
      chkMedicare: response?.isMedicareCmn,
      chkPatient: response?.isPatientHome,
      chkFamilyDeductible: response?.isFamilyDeductible,
      txtCmnCode: response?.cmnCode,
      drpTypeOfPlan: response?.typeOfPlan,
      chkIndividual: response?.isIndividual,
      chkOutOfNetwork: response?.isOutOfNetwork,
      chkFamily: response?.isFamily,
      txtDeductibleIndividual: response?.planDeductible?.individual,
      txtDeductibleOutOfNetwork: response?.planDeductible?.outOfNetwork,
      txtDeductibleFamily: response?.planDeductible?.family,
      txtDeductibleMetIndividual: response?.deductibleMet?.individual,
      txtDeductibleMetOutOfNetwork: response?.deductibleMet?.outOfNetwork,
      txtDeductibleMetFamily: response?.deductibleMet?.family,
      drpPayableIndividual: response?.payablePercent?.individual,
      drpPayableOutOfNetwork: response?.payablePercent?.outOfNetwork,
      drpPayableFamily: response?.payablePercent?.family,
      txtExpenseIndividual: response?.outOfPocketExpense?.individual,
      txtExpenseOutOfNetwork: response?.outOfPocketExpense?.outOfNetwork,
      txtExpenseFamily: response?.outOfPocketExpense?.family,
      txtExpenseMetIndividual: response?.outOfPocketExpenseMet?.individual,
      txtExpenseMetOutOfNetwork: response?.outOfPocketExpenseMet?.outOfNetwork,
      txtExpenseMetFamily: response?.outOfPocketExpenseMet?.family,
      chkExpenseIndividual: response?.outOfPocketIncludeDeductible?.individual,
      chkExpenseOutOfNetwork: response?.outOfPocketIncludeDeductible?.outOfNetwork,
      chkExpenseFamily: response?.outOfPocketIncludeDeductible?.family,
      chkPrecertRequired: response?.isPercertRequired,
      txtPhoneCall: response?.phone,
      chkReferralRequired: response?.isReferralRequired,
      txtPcp: response?.pcp,
      chkPredetermination: response?.isPredetermination,
      txtPreNotes: response?.preNotes,
      txtLifetimeMax: response?.lifeTimeMax,
      txtLifetimeMaxMet: response?.lifeTimeMaxMet,
      chkDmePartsCovered: response?.isDmePartsCovered,
      chkDmeReplacementUnit: response?.isDmeReplaceMentUnit,
      txtDmeAnnualLimit: response?.dmeAnnualLimit,
      txtDmeLimitMet: response?.dmeLimitMet,
      txtRequestedBy: response?.requestedBy,
      txtPrimaryPayor: response?.primaryPayor,
      txtContact: response?.contact,
      txtCallTrackingNumber: response?.callTrackingNumber,
      txtPolicyHolderName: response?.policyHolderName,
      txtPolicyHolderDob: response?.policyHolderDob,
      chkNetwork: response?.isNetwork,
      txtClaimAddress: response?.claimAddress,
      txtPlanCob: response?.planCob,
      txtDedTotal: response?.dedTotal,
      txtDedMet: response?.dedMet,
      txtOopTotal: response?.oopTotal,
      txtOopMet: response?.oopMet,
      txtOopDeductible: response?.oop,
      txtDmeCoverage: response?.dmeCoverage,
      txtDmeMax: response?.dmeMax,
      txtHcpcs: response?.hcpcs,
      txtCoverageCriteria: response?.coverageCriteria,
      txtQtyLimits: response?.qtyLimits,
      txtFrequency: response?.frequency,
      txtRent: response?.rent,
      txtPaRequired: response?.paRequired,
      txtCodesRequirePa: response?.codesRequirePa,
      txtSameOrSimilarInfo: response?.sameOrSimilarInfo,
      txtSpokeWith: response?.spokeWith,
      drpVerifiedBy: response?.verifiedBy,
      txtDateVerified: response?.dateVerified,
      drpPreparedBy: response?.preparedBy,
      txtPreparedOn: response?.preparedOn,
      rbtnVerificationStatus: response?.verificationStatus,
      txtDeo: response?.deo,
      txtCoInsurance: response?.coInsurance,

      txtcommentsToTech: response?.commentsToTech,
      txtVerfnAMethod: response?.verficationMethod,
      txtReason: response?.reason,
      chkActive: response?.isActive,
      // newly added
      chkIsBlueCardEligible: response?.isBlueCardEligible,
      chkIsDirectBill: response?.isDirectBill,
      chkIsPreAuthNeeded: response?.isPreAuthNeeded,
      chkIsClaimSubmit: response?.isClaimSubmit,
      txtPatientResposponsiblePaymentPercentage: response?.patientResposponsiblePaymentPercentage,
      chkIsReferenceNumberAvailable: response?.isReferenceNumberAvailable,
      txtReferenceNumber: response?.referenceNumber
    })

  }

  // ! Load Policy details based on dropdown change
  valueChangeLoadDropdown(type: string) {
    const getPatient = this.patientService.get(this.data.patientId).subscribe(response => {
      // this.store.dispatch(new getPatient(this.data.patientId)).subscribe(response => {
      const savedPolicy = response?.policies;
      let primaryInsurance: string;
      let policy: any;
      let phone: any;
      let group: any;
      if (savedPolicy.primaryPolicy != null && savedPolicy.primaryPolicy.payorLevel === type) {
        primaryInsurance = savedPolicy.primaryPolicy.insuranceType;
        policy = savedPolicy.primaryPolicy.policyNo;
        phone = savedPolicy.primaryPolicy.phone;
        group = savedPolicy.primaryPolicy.groupName
      }
      if (savedPolicy.secondaryPolicy != null && savedPolicy.secondaryPolicy.payorLevel === type) {
        primaryInsurance = savedPolicy.secondaryPolicy.insuranceType;
        policy = savedPolicy.secondaryPolicy.policyNo;
        phone = savedPolicy.secondaryPolicy.phone;
        group = savedPolicy.secondaryPolicy.groupName
      }
      if (savedPolicy.tertiaryPolicy != null && savedPolicy.tertiaryPolicy.payorLevel === type) {
        primaryInsurance = savedPolicy.tertiaryPolicy.insuranceType;
        policy = savedPolicy.tertiaryPolicy.policyNo;
        phone = savedPolicy.tertiaryPolicy.phone;
        group = savedPolicy.tertiaryPolicy.groupName
      }
      this.verificationForm.patchValue({
        txtPlanName: primaryInsurance,
        txtPolicy: policy,
        txtPlanPhone: phone,
        txtGroup: group
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getPatient);
  }

  // ! Clear dropdowns
  clearPlan() {
    this.plan = '';
    this.verificationForm.patchValue({
      txtPlan: ''
    });
  }
  clearType() {
    this.type = '';
    this.verificationForm.patchValue({
      txtType: ''
    });
  }
  clearTypeOfPlan() {
    this.typeOfPlan = '';
    this.verificationForm.patchValue({
      drpTypeOfPlan: ''
    });
  }
  clearPayableIndividual() {
    this.payableIndividual = '';
    this.verificationForm.patchValue({
      drpPayableIndividual: ''
    });
  }
  clearPayableOutOfNetwork() {
    this.payableOutOfNetwork = '';
    this.verificationForm.patchValue({
      drpPayableOutOfNetwork: ''
    });
  }
  clearPayableFamily() {
    this.payableFamily = '';
    this.verificationForm.patchValue({
      drpPayableFamily: ''
    });
  }
  // ! Reset Verification Form
  clearData() {
    this.verificationForm.patchValue({
      //  drpChart: "",
      txtName: "",
      //  txtAddress: "",
      txtDateOfBirth: "",
      txtCommentsNotes: "",
      txtPlan: "",
      txtPlanName: "",
      txtPlanPhone: "",
      txtPolicy: "",
      txtGroup: "",
      txtEffectiveDate: "",
      txtType: "",
      txtFascalYearStart: "",
      txtEnd: "",
      chkMedicare: "",
      chkPatient: "",
      chkFamily: "",
      txtCmnCode: "",
      drpTypeOfPlan: "",
      chkIndividual: "",
      chkOutOfNetwork: "",
      chkFamilyDeductible: "",
      txtDeductibleIndividual: "",
      txtDeductibleOutOfNetwork: "",
      txtDeductibleFamily: "",
      txtDeductibleMetIndividual: "",
      txtDeductibleMetOutOfNetwork: "",
      txtDeductibleMetFamily: "",
      drpPayableIndividual: "",
      drpPayableOutOfNetwork: "",
      drpPayableFamily: "",
      txtExpenseIndividual: "",
      txtExpenseOutOfNetwork: "",
      txtExpenseFamily: "",
      txtExpenseMetIndividual: "",
      txtExpenseMetOutOfNetwork: "",
      txtExpenseMetFamily: "",
      chkExpenseIndividual: "",
      txtVerfnAMethod: "",
      chkExpenseOutOfNetwork: "",
      chkExpenseFamily: "",
      chkPrecertRequired: "",
      txtPhoneCall: "",
      chkReferralRequired: "",
      txtPcp: "",
      chkPredetermination: "",
      txtPreNotes: "",
      txtLifetimeMax: "",
      txtLifetimeMaxMet: "",
      chkDmePartsCovered: "",
      chkDmeReplacementUnit: "",
      txtDmeAnnualLimit: "",
      txtDmeLimitMet: "",
      txtRequestedBy: "",
      txtPrimaryPayor: "",
      txtContact: "",
      txtCallTrackingNumber: "",
      txtPolicyHolderName: "",
      txtPolicyHolderDob: "",
      chkNetwork: "",
      txtClaimAddress: "",
      txtPlanCob: "",
      txtDedTotal: "",
      txtDedMet: "",
      txtOopTotal: "",
      txtOopMet: "",
      txtOopDeductible: "",
      txtDmeCoverage: "",
      txtDmeMax: "",
      txtHcpcs: "",
      txtState: "",
      txtCoverageCriteria: "",
      txtQtyLimits: "",
      txtFrequency: "",
      txtRent: "",
      txtPaRequired: "",
      txtCodesRequirePa: "",
      txtSameOrSimilarInfo: "",
      txtSpokeWith: "",
      drpVerifiedBy: "",
      txtDateVerified: "",
      drpPreparedBy: "",
      txtPreparedOn: "",
      rbtnVerificationStatus: "",
      txtDeo: "",
      txtCoInsurance: "",
      txtcommentsToTech: "",
      txtReason: ""
      // rbtnPending:new FormControl(""),
      // rbtnUnableToVerify:new FormControl(""),
    })

    this.verificationForm.markAsUntouched();
    this.verificationForm.updateValueAndValidity();
    this.insuranceVerificationId = "00000000-0000-0000-0000-000000000000";
    // this.verificationForm.markAsUntouched();
    // this.verificationForm.markAsPristine();

    // this.verificationForm?.get('drpChart').updateValueAndValidity();
    this.verificationForm?.get('txtDateOfBirth').updateValueAndValidity();

    this.verificationForm?.get('txtPlan').updateValueAndValidity();
    this.verificationForm?.get('txtPlanName').updateValueAndValidity();
    this.verificationForm?.get('txtPolicy').updateValueAndValidity();
    this.verificationForm?.get('txtEffectiveDate').updateValueAndValidity();
    this.verificationForm?.get('txtType').updateValueAndValidity();
    this.verificationForm?.get('drpTypeOfPlan').updateValueAndValidity();
    this.verificationForm?.get('rbtnVerificationStatus').updateValueAndValidity();
    this.verificationForm.updateValueAndValidity();

  }
  // coInsurance Value patches
  onSelection(value) {
    this.verificationForm.patchValue({
      txtCoInsurance: value
    });
  }

}
