<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-3 mt-1"><b class="customThemeClass"> Tools / My Orders </b></h3>

        <div class="page-title-box page-title-right mt-3">
          <form [formGroup]="orderForm">
            <div class="row d-flex justify-content-center align-items-center">
              <ng-container *ngIf="filterValue">
                <mat-form-field class="pr-3 dd-text">
                  <mat-label>Patient Search</mat-label>
                  <mat-select
                    (selectionChange)="onChartIdChange($event)"
                    formControlName="drpPatient"
                    #singleSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        (keyup)="searchPatient($event.target.value)"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtPatientFilter"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <!-- <mat-option
                      *ngFor="let chart of filteredChartIds | async"
                      [value]="chart.patientId"
                    >
                      {{ chart.idAndNameAndDOB }}
                    </mat-option> -->
                    <mat-option
                    *ngFor="let chart of lstPatientChartNos"
                    [value]="chart.patientId"
                  >
                    {{ chart.ddValue }}
                  </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!filterValue">
                <mat-form-field class="pr-3 dd-text">
                  <mat-label>Order Date</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      placeholder="Start date"
                      formControlName="txtDateValue"
                    />

                    <input
                      matEndDate
                      matInput
                      placeholder="End date"
                      formControlName="txtDate"
                      (dateChange)="onDateRangeChange()"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <!-- <mat-form-field class="pr-3 dd-text">
                  <mat-label>CSR</mat-label>
                  <mat-select
                    formControlName="drpCSR"
                    (selectionChange)="onDateRangeChange()"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCSRFilter"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <mat-option
                      *ngFor="let users of filteredCSRList | async"
                      [value]="users.userId"
                    >
                      {{ users.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->

                <mat-form-field class="pr-3 dd-text">
                  <mat-label>Order Status</mat-label>
                  <mat-select
                    formControlName="drpOrderStatus"
                    (selectionChange)="onDateRangeChange()"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtStatusFilter"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <mat-option
                      *ngFor="let status of filteredOrderStatusList | async"
                      [value]="status.id"
                    >
                      {{ status.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <app-order-filter-switch
                class="h-100"
                [onText]="'Patient'"
                [offText]="'Others'"
                (changed)="filterChange($event)"
              >
              </app-order-filter-switch>

              <button
                mat-button
                class="buttonColor mx-2"
                (click)="navigateToOrderPage(defauldGuid, defauldGuid)"
              >
                <mat-icon>add</mat-icon>
                New Order
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body p-2">
        <div class="d-flex justify-content-center" *ngIf="!isOrderTableLoaded">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div *ngIf="isOrderTableLoaded" class="table table-responsive">
          <app-order-search-tab-table
            [orderTableData]="ltOrders"
            [pageType]="pageType"
            (getVisibleColumns)="fnGetVisibleColumns($event)"
            (nevigatePage)="changePage($event)" 
             [visibleColumns]="visibleColumns" 
             [currentPage]="currentPage" 
             [totalPages]="totalPages">

          ></app-order-search-tab-table>
        </div>
      </div>
    </div>
  </div>
</div>
