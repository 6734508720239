import type { CreateUpdateUserWorkingHoursDTO, GetOfficesDTO, UserWorkingHoursDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserWorkingHoursService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdateUserWorkingHoursDTO) =>
    this.restService.request<any, UserWorkingHoursDTO>({
      method: 'POST',
      url: '/api/OnTimeApp/user-working-hours',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/user-working-hours/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, UserWorkingHoursDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/user-working-hours/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<UserWorkingHoursDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/user-working-hours',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateUserWorkingHoursDTO) =>
    this.restService.request<any, UserWorkingHoursDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/user-working-hours/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getOfficeBasedOnTimingsByInput = (input: GetOfficesDTO) =>
    this.restService.request<any, GetOfficesDTO>({
      method: 'GET',
      url: '/api/OnTimeApp/user-working-hours/get-office-based-on-timings',
      params: { weekDays: input.weekDays, startsAt: input.startsAt, endsAt: input.endsAt, officeDetails: input.officeDetails },
    },
     { apiName: this.apiName, skipHandleError: true });

  getWorkEndTimeBySEndsAt = (sEndsAt: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/OnTimeApp/user-working-hours/get-work-end-time',
      params: { sEndsAt },
    },
     { apiName: this.apiName, skipHandleError: true });

  getWorkStartTimeBySStartsAt = (sStartsAt: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/OnTimeApp/user-working-hours/get-work-start-time',
      params: { sStartsAt },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
