<!-- <div class="card card-body"> -->
    <div class="col-lg-12 content table-responsive">
        <ng-container>
            <table id="tblPhysician" datatable [dtOptions]="physicianTabledetails"
                class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th [matTooltip]="'Practice Name'">Practice Name</th>
                        <th [matTooltip]="'Fax Number'">Fax Number</th>
                        <th [matTooltip]="'Physician Name'">Physician Name</th>
                        <th [matTooltip]="'Last Sent Date'" class="text-center">Last Sent Date</th>
                        <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of physicianTableData">
                        <td [matTooltip]="data.practiceName || '-'"
                        (dblclick)="onRowDoubleClickV1(data?.practiceName || '-')">
                        <a [routerLink]="'/addpracticemanagement/' + data.practiceId" class="preview-link"> {{
                            (data.practiceName !== null && data.practiceName !== ' ') ? data.practiceName : '-'
                            }}</a>
                        </td>
                        <td [matTooltip]="data.faxNumber || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.faxNumber || '-')">{{ data.faxNumber ?
                            data.faxNumber :
                            '-'}}</td>
                        <td [matTooltip]="data.doctorName || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.doctorName || '-')">{{ data.doctorName ?
                            data.doctorName :
                            '-'}}</td>
                        <td [matTooltip]="data?.lastSenddate"
                            (dblclick)="onRowDoubleClickV1(data?.lastSenddate)" class="text-center">{{ data?.lastSenddate }}
                        </td>
                        <td [matTooltip]="data.aging || '-'" (dblclick)="onRowDoubleClickV1(data?.aging || '-')" class="text-right">
                            {{ data.aging ?
                            data.aging :
                            '-'}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Practice Name"
                                name="search-PracticeName" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Fax Number"
                                name="search-FaxNumber" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Physician Name"
                                name="search-PhysicianName" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class text-center" placeholder="Last Sent Date"
                                name="search-LastSentDate" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class text-right" placeholder="Aging Days"
                                name="search-AgingDays" />
                        </th>
                    </tr>
                </tfoot>

            </table>
        </ng-container>
    </div>
<!-- </div> -->