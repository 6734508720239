import type { ClaimDTO, CreateUpdateClaimDTO, InsurancePaymentDetailsDTO, ItemPaymentDTO, PatientVisitNotesDTO, PostingChargesDTO, SearchPatientVisitNotesDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClaimInformationsService {
  apiName = 'billingManagement';

  create = (input: CreateUpdateClaimDTO) =>
    this.restService.request<any, ClaimDTO>({
      method: 'POST',
      url: '/api/billingApp/claim-informations',
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/claim-informations/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, ClaimDTO>({
      method: 'GET',
      url: `/api/billingApp/claim-informations/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ClaimDTO>>({
      method: 'GET',
      url: '/api/billingApp/claim-informations',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName, skipHandleError: true });

  getListBasedOnClaimByClaimIdAndInsuranceAndClaim = (claimId: string, insurance: string, claim: string) =>
    this.restService.request<any, PagedResultDto<ItemPaymentDTO>>({
      method: 'GET',
      url: `/api/billingApp/claim-informations/based-on-claim/${claimId}`,
      params: { insurance, claim },
    },
      { apiName: this.apiName, skipHandleError: true });

  getListBasedOnInsuranceByClaimIdAndInsurance = (claimId: string, insurance: string) =>
    this.restService.request<any, PagedResultDto<PostingChargesDTO>>({
      method: 'GET',
      url: `/api/billingApp/claim-informations/based-on-insurance/${claimId}`,
      params: { insurance },
    },
      { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateClaimDTO) =>
    this.restService.request<any, ClaimDTO>({
      method: 'PUT',
      url: `/api/billingApp/claim-informations/${id}`,
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  deletePostingCharges = (claimId: string, postingChagesId: string) =>
    this.restService.request<any, ClaimDTO>({
      method: 'DELETE',
      url: '/api/billingApp/claim-informations/delete-posting-charges',
      params: { claimId, postingChagesId },
    },
      { apiName: this.apiName, skipHandleError: true });

  searchPatientVisitNotesByInput = (input: SearchPatientVisitNotesDTO) =>
    this.restService.request<any, PagedResultDto<PatientVisitNotesDTO>>({
      method: 'GET',
      url: '/api/billingApp/claim-informations/search-patient-visit-notes',
      params: { claimId: input.claimId, noteType: input.noteType, followUpDate: input.followUpDate, modifiedBy: input.modifiedBy, modifiedDate: input.modifiedDate, precollectionStatus: input.precollectionStatus },
    },
      { apiName: this.apiName, skipHandleError: true });

  getListBasedOnStatus = (...input: any) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: '/api/billingApp/claim-informations/search-patient-visit-notes',
    },
      { apiName: this.apiName, skipHandleError: true });


  updatePayemnts = (claimId: string, insurance: string, input: InsurancePaymentDetailsDTO) =>
    this.restService.request<any, ClaimDTO>({
      method: 'PUT',
      url: `/api/billingApp/claim-informations/update-payments/${claimId}`,
      params: { claimId, insuranceType: insurance },
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });
  constructor(private restService: RestService) { }
}
