import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';

@Component({
  selector: 'app-inbound-batch-sorting-tiff',
  templateUrl: './inbound-batch-sorting-tiff.component.html',
  styleUrls: ['./inbound-batch-sorting-tiff.component.scss']
})
export class InboundBatchSortingTiffComponent implements OnInit {

  defaultFaxId: string = '-';
  docId: string;
  defaultPatientId: string = '-';
  chartId: string = '-';
  patientName: string = '-';


headerText: string;
patientId: string = defaultGuid;
batchSortingForm: FormGroup;
defaultGuid = defaultGuid;
lstChartNos: any;
isLoading = false;
currentlockDoc: number = 0;
// lockDocListDTO: LockDocListDTO[];
lenlockDocListDTO: number = 0;
private inboundisLockUpdateAPICall: Subscription;
subscription$: Subscription[] = [];

patientChartApiSubscription: Subscription;
  constructor( private patientSearchService: PatientSearchService,private formBuilder: FormBuilder,) { }

  ngOnInit(): void {

    this.batchSortingForm = this.formBuilder.group({
      drpSearchPatient: new FormControl(null, [Validators.required]),
      txtPatientFilter: new FormControl(''),
      drpSearchStatus: new FormControl(''),
    });


    this. searchPatient('')
  }

  BindHearderText(event: any) {
    this.defaultPatientId = event?.defaultPatientId || '-';
    this.patientName = event?.fullName || '-';
    this.chartId = event?.chartId || '-';
    this.defaultFaxId = event?.defaultFaxId || '-';
  }

  // lockUpdateAPICall(flag: number) {
  //   // 1 Skip Go to Next // 2 Save Go to next
  //   try {
  //     if (this.inboundisLockUpdateAPICall) {
  //       this.inboundisLockUpdateAPICall.unsubscribe();
  //     }

  //     if (this.currentlockDoc <= this.lenlockDocListDTO) {
  //       this.inboundisLockUpdateAPICall = this.inboundDocumentService
  //         .updateLockstatusByDocumentId(
  //           this.lockDocListDTO[this.currentlockDoc]?.documentId
  //         )
  //         .subscribe(
  //           (response) => {

  //             // 1 Skip Go to Next // 2 Save Go to next
  //             if (flag === 1) {
  //               this.skipBatch();
  //             }

  //             this.batchSortingForm.patchValue({
  //               drpSearchPatient:this.defaultGuid
  //             });
  //             this.updatePageHeaderText();

  //             this.currentlockDoc++;
  //             if (this.currentlockDoc >= this.lenlockDocListDTO) {
  //               this.currentlockDoc = this.lenlockDocListDTO - 1;
  //               this.isBTNdisable = true;
  //               // window.close();
  //             } else {
  //               this.docId =
  //                 this.lockDocListDTO[this.currentlockDoc].documentId;
  //               this.loadInboundDocument(this.docId);
  //               this.sortingDocView();
  //               this.isBTNdisable = false;
  //             }
  //           },
  //           (error) => {
  //             this.toastr.error('Failed', 'Error');
  //             // this.reloadTable.emit();
  //             console.error('Error:', error);
  //           }
  //         );
  //     }
  //   } catch (error) {
  //     // this.toastr.error('Lock Update Failed', 'Error');
  //     // this.reloadTable.emit('Mani');
  //     console.error('Unexpected error:', error);
  //   } finally {
  //   }
  // }

  searchPatient(value: any) {
    this.loadPatientChartNos(value);
  }

  //To Load the Chart No of the Patients
  loadPatientChartNos(searchParameter) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.patientSearchService
      .getNewSetupPatientSearch(searchParameter,this.patientId)
      .subscribe(
        (response) => {
          this.lstChartNos = response;
          return this.lstChartNos;
        },
        (err) => {}
      );
  }

  ClearSearchPatient(event:Event){
    event.stopPropagation();
    this.batchSortingForm.patchValue({
      drpSearchPatient:''
    })
    this.searchPatient('');
  }

}
