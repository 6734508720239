import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { userDetailsDTO } from '../admin-proxy/platform-app-management/identity';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss']
})
export class UserResetPasswordComponent implements OnInit {
  subscription$: Subscription[] = [];
  UserForm:FormGroup;
  UserFilterControl=new FormControl();
  selectedUserList: any;
  drpUser:any;
  UserSelectAllOption: boolean;
  UserList: any;
  isSaveButton:boolean=false;
  userIdlist:string[]=[];
  hide = true;
  userDetail: userDetailsDTO;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId?: string }= {
      userId: defaultGuid,
    },
    public userService: UserService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getUserList();
    this.UserForm = this.formBuilder.group({
      drpUser: new FormControl('' ,[Validators.required]),
      txtUser: new FormControl(''),
      txtUserPassword: new FormControl("", [Validators.required, noWhitespaceValidator]),
    });
    this.UserFilterControl.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.UserList = this.drpUser.filter((dx) =>
        dx.name.toLowerCase().includes(dxTypeFilterText)
      );
    });

    if(this.data.userId!==defaultGuid){
      this.userIdlist.push(this.data.userId)
      this.UserForm.patchValue({
        drpUser:this.userIdlist,
      })
      const user =  document.getElementById('userDrp');
      user.classList.add('readonly');
    }
  }
  getUserList() {
    const getBranchList = this.userService.getUsersByTenantWithoutAdmin().subscribe(response => {
        this.drpUser=response;
        this.UserList=this.drpUser;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
  onUserSelectionChange(event: MatSelectChange): void {
    this.selectedUserList = event.value;
    if (this.selectedUserList.includes('selectAll')) {
      // this.BranchSelectAllOption = !this.BranchSelectAllOption;
      this.UserSelectAllOption=true;
      if (this.UserSelectAllOption) {
        // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
        this.selectedUserList = this.drpUser.map(user => user.userId);
        this.UserForm
        .get('drpUser')
        .patchValue(this.selectedUserList);

      }
    }else if (this.selectedUserList.includes('DeselectAll')) {
      this.selectedUserList=[];
      this.UserSelectAllOption=false;
      this.UserForm
      .get('drpUser')
      .patchValue([]);
    }
    else{
      this.UserSelectAllOption =
      this.selectedUserList.length === this.drpUser.length;
    }
  }
  changePassword(){
    this.isSaveButton=true;
    if(this.data.userId===defaultGuid){
      this.userDetail={
        lstUsers:this.UserForm.value.drpUser,
        sPassword:this.UserForm.value.txtUserPassword,
       }
    }else{
      this.userDetail={
        lstUsers:this.userIdlist,
        sPassword:this.UserForm.value.txtUserPassword,
       }
    }
     Swal.fire({
      title: 'Are you sure you want to reset password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if(result.value){
    try {
      const isStatUpdate = this.userService.resetPassword(this.userDetail).subscribe(
          (response) => {
            this.toastr.success('Updated Successfully', 'Success');
            this.dialog.closeAll();
          },
          (error) => {
            const data: HttpErrorResponse = error;
            if(data.status===200){
              this.toastr.success('Updated Successfully', 'Success');
              this.dialog.closeAll();
            }else{
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.isSaveButton=false;
        }
          },
          () => {
            // This block will be executed on completion
            setTimeout(() => {

            }, 1000);
          }
        );
    } catch (error) {
      this.toastr.error('Update Failed', 'Error');
      console.error('Unexpected error:', error);
      this.isSaveButton=false;
    } finally {
    }
  }
  });
  this.isSaveButton=false;
  }

}
