<table
  id="tblpatientManagement"
  datatable
  [dtOptions]="dtPatientManagementOptions"
  class="tbl hover row-border hover w-100 display"
>
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'DOB(Age)'" class="text-right">DOB(Age)</th>
      <th [matTooltip]="'Email'">Email</th>
      <th style=" word-wrap: break-word !important;white-space :break-spaces !important" [matTooltip]="'Primary Insurance'">Primary Insurance</th>
      <th [matTooltip]="'CSR Name'">CSR Name</th>
      <th [matTooltip]="'Next Call Date'" class="text-center">
        Next Call Date
      </th>
      <th [matTooltip]="'Rx Date'" class="text-center">Rx Date</th>
      <th [matTooltip]="'Setup Date'" class="text-center">Setup Date</th>
      <th [matTooltip]="'Checklist Date'" class="text-center">
        Checklist Date
      </th>
      <th  style=" word-wrap: break-word !important;" [matTooltip]="'Last Mask Process Date'" class="text-center">
        Last Mask Date
      </th>
      <th [matTooltip]="'Referral Practice'">Referral Practice</th>
      <th [matTooltip]="'Referring Physician'">Referring Physician</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th class="text-right" [matTooltip]="'Created Date'">Created Date</th>
      <th [matTooltip]="'Modify By'">Modify By</th>
      <th class="text-right" [matTooltip]="'Modified Date'">Modified Date</th>
      <th [matTooltip]="'Reminder Email'">Reminder Email</th>
      <th class="text-center" [matTooltip]="'Reminder Off'">Reminder Off</th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="
        tblItemSelectedRowId === data.patientId ||
        defaultSelectedRow === data.patientId
          ? 'bg-lightseagreen'
          : ''
      "
      *ngFor="let data of PatientDetails"
      (click)="
        tblItemSelectedRow(data.patientId, data.documentId, data.chartId)
      "
    >
      <td>
        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
        [matTooltip]="'Click to View Menus'">more_vert</mat-icon
        >
        <mat-icon
          style="cursor: pointer !important; color: lightseagreen"
          [matTooltip]="'Upload Document'"
          [class.disabled]="isEmpty(data?.patientId)"
          (click)="addDocumentType(data?.patientId, data?.wipId)"
        >
          cloud_upload</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button
            mat-menu-item
            [disabled]="data.patientId === defaultGuid"
            (click)="openAddNotesPopUp()"
          >
            Add Patient Note
          </button>
          <button
            mat-menu-item
            [disabled]="data.patientId === defaultGuid"
            (click)="openViewNotesPopup()"
          >
            View Patient Notes
          </button>
          <a
            [class.disabled]="
              isEmpty(data.blobname) || isEmpty(data.documentId) || data?.isSorted === false
            "
            [routerLink]="'/view_document_tiff/' + data.documentId + '/' + true"
            target="_blank"
            class="preview-link"
          >
            <button
              [disabled]="isEmpty(data.blobname) || data?.isSorted === false"
              mat-menu-item
            >
              View Document Images
            </button>
          </a>
          <button mat-menu-item (click)="viewPatientLedger(data.patientId)">
            View Patient Ledger
          </button>
          <a
            [routerLink]="['/emailprintletter/' + data.patientId]"
            target="_blank"
          >
            <button mat-menu-item>Email supplies Reminder</button>
          </a>
          <mat-divider></mat-divider>
          <!-- <a
            [routerLink]="
              '/orders/saleOrder/' +
              data?.patientId +
              '/' +
              defaultGuid +
              '/' +
              '0'
            "
            target="_blank"
            class="preview-link"
          >
            <button mat-menu-item>Start New Order</button>
          </a> -->
          <a [routerLink]="'/orders/saleOrder/' + data?.patientId" target="_blank" class="preview-link">
            <button mat-menu-item>Start New Order</button>
          </a>
          <mat-divider></mat-divider>
          <a
            [routerLink]="'/settings/calender/' + data.patientId"
            target="_blank"
            class="preview-link"
          >
            <button mat-menu-item>Schedule Appointment</button>
          </a>
          <mat-divider></mat-divider>
          <!-- <button mat-menu-item (click)="openNewVerification(data.patientId,false)">Ondemand Verification
            Request</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="openNewVerification(data.patientId,true)">Online Verification</button> -->
          <button mat-menu-item (click)="ondemandOnlineVerification(data)">
            Ondemand/Online Verification Request
          </button>
          <mat-divider></mat-divider>
          <a
            [class.disabled]="
              data.isMedicarePolicy === 0 ||
              isEmpty(data.blobname) ||
              isEmpty(data.documentId || data.documentId === defaultGuid)
            "
            [routerLink]="'/do_checklist/' + data.patientId + '/' + '/' + null"
            target="_blank"
            class="preview-link"
          >
            <button
              [disabled]="
                isEmpty(data.blobname) ||
                isEmpty(data.documentId) ||
                data.isMedicarePolicy === 0 ||
                data.documentId === defaultGuid
              "
              mat-menu-item
            >
              New Checklist
            </button>
          </a>
          <button [disabled]="true" mat-menu-item>Text Conversation</button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="Reason"
            [disabled]="callReason && callReason.length === 0"
          >
            Assign Do Not Call Reason
          </button>
          <button
            mat-menu-item
            (click)="openDatepicker(data.patientId, data.nextCallDate)"
          >
            Set Next Call Date
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [disabled]="true">Email RX Request</button>
          <mat-divider></mat-divider>
          <button mat-menu-item   [disabled]="isEmpty(data?.blobname) || isEmpty(data?.documentId)" (click)="patientDocPdfDownload(data)">
            Download Patient Document
          </button>
        </mat-menu>
        <mat-menu #Reason="matMenu">
          <ng-container *ngFor="let reason of callReason">
            <button
              (click)="assignDoNotCall(data.patientId, reason.id)"
              mat-menu-item
              [disabled]="reason.id === data.branchId"
              [value]="reason.id"
            >
              {{ reason.reason }}
            </button>
          </ng-container>
        </mat-menu>

        <div>
          <mat-form-field style="display: none">
            <input
              matInput
              [matDatepicker]="datepicker"
              placeholder="Choose a date"
              (dateChange)="onDateSelected($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #datepicker
              [xPosition]="'before'"
              [yPosition]="'above'"
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.chartId || '-')"
        [matTooltip]="'Click to View Patient Details'"
      >
        <a
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data.patientId + '/' + data.documentId
          "
          target="_blank"
          class="preview-link"
          >{{ data?.chartId || "-" }}
        </a>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.patientName || '-')"
        [matTooltip]="data?.patientName || '-'"
      >
        {{ data?.patientName || "-" }}
      </td>
      <td
      (dblclick)="onRowDoubleClick(data?.dob)"
      [matTooltip]="data?.dob + ' (' + data?.patientAge + ')'"
      class="text-right"
    >
      {{ data?.dob }} ({{ data?.patientAge}})
    </td>
      <td
        (dblclick)="onRowDoubleClick(data?.emailId || '-')"
        [matTooltip]="data?.emailId || '-'"
      >
        {{ data.emailId || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.primaryInsuranceName || '-')"
        [matTooltip]="data?.primaryInsuranceName || '-'"
      >
        {{ data.primaryInsuranceName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.csrName || '-')"
        [matTooltip]="data?.csrName || '-'"
      >
        {{ data.csrName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.nextCallDate)"
        [matTooltip]="data?.nextCallDate"
        class="text-center"
      >
        {{ data?.nextCallDate }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.rxDate)"
        [matTooltip]="data?.rxDate"
        class="text-center"
      >
        {{ data?.rxDate }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.setupDate)"
        [matTooltip]="data?.setupDate"
        class="text-center"
      >
        {{ data?.setupDate }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.checkListDate)"
        [matTooltip]="data?.checkListDate"
        class="text-center"
      >
        {{ data?.checkListDate }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.lastMask)"
        [matTooltip]="data?.lastMask"
        class="text-center"
      >
        {{ data?.lastMask }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.practiceName || '-')"
        [matTooltip]="data?.practiceName || '-'"
      >
        {{ data.practiceName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.referringPhysician || '-')"
        [matTooltip]="data?.referringPhysician || '-'"
      >
        {{ data.referringPhysician || "-" }}
      </td>
      <td
        [matTooltip]="data?.createdBy || '-'"
        (dblclick)="onRowDoubleClick(data?.createdBy || '-')"
      >
        {{ data?.createdBy || "-" }}
      </td>

      <td
        class="text-right"
        [matTooltip]="data?.createdDate"
        (dblclick)="onRowDoubleClick(data?.createdDate)"
      >
        {{ data?.createdDate }}
      </td>
      <td
        [matTooltip]="data?.modifyBy || '-'"
        (dblclick)="onRowDoubleClick(data?.modifyBy || '-')"
      >
        {{ data?.modifyBy || "-" }}
      </td>

      <td
        class="text-right"
        [matTooltip]="data?.modifiedDate"
        (dblclick)="onRowDoubleClick(data?.modifiedDate)"
      >
        {{ data?.modifiedDate }}
      </td>

      <td
        (dblclick)="onRowDoubleClick(data?.reminderEmail || '-')"
        [matTooltip]="data?.reminderEmail || '-'"
      >
        {{ data.reminderEmail || "-" }}
      </td>
      <td class="text-center">
        <mat-checkbox
          color="lightseagreen"

        ></mat-checkbox>
               <!-- [(ngModel)]="data.isStat"
          (change)="isStatUpdate($event, data)" -->
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
          name="search-ChartNo"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Patient Name"
          name="search-PatientName"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="DOB(Age)"
          name="search-DOB"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Email Id"
          name="search-EmailId"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Primary Insurance Name"
          name="search-PrimaryInsuranceName"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="CSR Name"
          name="search-CSR Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="NextCallDate"
          name="search-NextCallDate"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="RxDate"
          name="search-RxDate"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="SetupDate"
          name="search-SetupDate"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class text-center"
          placeholder="CheckListDate"
          name="search-checkListDate"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="LastMask"
          name="search-lastMask"
        />
      </th>

      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Referral Practice"
          name="search-ReferralPractice"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Referring Physician"
          name="search-ReferringPhysician"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modify By"
          name="search-Modify By"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified Date"
          name="search-Modified Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="ReminderEmail"
          name="search-ReminderEmail"
        />
      </th>
      <th class="text-center">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="ReminderOff"
          name="search-ReminderOff"
        />
      </th>
    </tr>
  </tfoot>
</table>
