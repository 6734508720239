import { Component, OnInit, Input, Inject, ViewChild, } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable, Subject, Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from '@angular/common/http';
import { AddReminderComponent } from 'projects/admin/src/app/add-reminder/add-reminder.component';
import { reminderTableData, dtReminderTableOptions, dtReminderTableOptionsv1 } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ReminderService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/remainder/reminder.service';
import { DataTableDirective } from 'angular-datatables';
import { ReminderFilter } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/reminder-filter.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'console';
import { CommunicationService } from '../../services/communication.service';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { defaultGuid } from '../../enums/allenums.enum';
@Component({
  selector: 'app-patient-reminder-dashboard',
  templateUrl: './patient-reminder-dashboard.component.html',
  styleUrls: ['./patient-reminder-dashboard.component.scss']
})
export class PatientReminderDashboardComponent implements OnInit {
  @Input() patientId: string = defaultGuid;
  @Input() lblDefaultPatientId: string = '';
  @Input() selectedNode: any;
  @Input() reminderid: any;
  myTable: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  getRemindertbleDataSubcription: Subscription;
  private destroyReminderget$ = new Subject<void>();
  // dtReminderTableOptions: any;
  selectedOption: ReminderFilter;
  ReminderFilter = ReminderFilter;
  isLoading: boolean;
  $subscription: any;
  remindertbldata: any;
  totalCount: number;
  tableData$: Subscription;
  dtReminderTableOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    // fixedColumns: {
    //   leftColumns: 3, // Number of columns to fix on the left
    //   rightColumns: 1, // Number of columns to fix on the right
    // },
    columnDefs: [
      { targets: [0, 1,], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
      {
        targets: [5, 10],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        filename: 'Reminders',
        text: 'Export',
        exportOptions: {
          columns: ':visible:not(:nth-child(1),:nth-child(2))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      }
      // {
      //   text: 'Show Default Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns(':not(:lt(3),:last-child)').visible(false);
      //   },
      // },
      // {
      //   text: 'Show All Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns().visible(true);
      //   },
      // },
    ],
  };
  vDescription: any;

  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  visibleColumns: number[] ;

  // selectedRowIndex: number;
  // reminderTableData = reminderTableData;
  constructor(
    private dialog: MatDialog,
    public matDialog: MatDialog,
    private reminderService: ReminderService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reminderid: any;
    } = {
        reminderid: defaultGuid,
      },
  ) {
    this.communicationService.reminderGetFunctionCall$.subscribe((selectedNode) => {
      this.GetReminderFilterList();
    });
  }

  ngOnInit(): void {
    this.GetReminderFilterList();
    this.selectedOption = ReminderFilter.AllReminder;
    this.dtReminderTableOptions.buttons[0].filename =
    'Reminders' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngAfterViewInit(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    this.destroyReminderget$.next();
    this.destroyReminderget$.complete();
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.1; // Adjust the percentage as needed
    this.dtReminderTableOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };

  // isPastDue(date: string): boolean {
  //   const currentDate = new Date();
  //   const dueDate = new Date(date);
  //   dueDate.setHours(0, 0, 0, 0);
  //   currentDate.setHours(0, 0, 0, 0);
  //   return dueDate < currentDate;
  // }
  //Add Remainder Model
  OpenAddReminderMdl(status: boolean) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        noteType: 'patient',
        remindertitle: 'Add Reminder',
        status: status,
        reminderid: defaultGuid,
        selectedNode: this.selectedNode,
        lblDefaultPatientId: this.lblDefaultPatientId,
        isdisable: true,
      },
    };
    const dialogRef = this.dialog.open(AddReminderComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.GetReminderFilterList();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // this.$subscription.push(closeDialog);
  }

  //Update Remainder Model
  // UpdateReminder(reminderid: string, status: boolean) {

  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     minHeight: '30vh',
  //     minWidth: '35vw',
  //     data: {
  //       noteType: 'patient',
  //       reminderid: reminderid,
  //       remindertitle: 'Update Reminder',
  //       status: status,
  //       selectedNode: this.selectedNode,
  //       lblDefaultPatientId: this.lblDefaultPatientId,
  //     },
  //   };

  //   const dialogRef = this.dialog.open(AddReminderComponent, config);

  //   const closeDialog = dialogRef.afterClosed().subscribe(
  //     () => {
  //       this.GetReminderFilterList();
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.$subscription.push(closeDialog);
  // }


  //GET Reminder List In Table
  GetReminderFilterList() {
    this.isLoading = true;
    if(this.getRemindertbleDataSubcription){
      this.getRemindertbleDataSubcription.unsubscribe();
    }
    this.getRemindertbleDataSubcription = this.reminderService
      .getRemainderList(
        this.selectedOption,
        this.currentPage,
        this.pageOffset
      )
      .pipe(takeUntil(this.destroyReminderget$))
      .subscribe(
        (response) => {
          //this.remindertbldata = response;
          let pagedResponse = response as any;
          if (pagedResponse == null)
          {
            this.isLoading = false;
            return null;
          }
          this.remindertbldata = pagedResponse.items;
          this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);

          this.totalCount = this.remindertbldata.length;
          // this.vDescription = response['description'] ?? '';
          if (response && response.length > 0) {
            this.vDescription = response[0].description ?? 'N/A';
          } else {
            this.vDescription = '';
          }
          this.isLoading = false;
          setTimeout(() => this.refreshDataTable(), 500);
        },
        (err) => {
          this.isLoading = false;
          this.remindertbldata = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  ///Delete Reminder
  // DeleteReminder(id: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.reminderService.enableDisableReminderById(id).subscribe(response => {

  //         this.toastr.success('Deleted Successfully', 'Success');

  //         this.GetReminderFilterList();
  //       }, err => {
  //         const data: HttpErrorResponse = err;

  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //     }
  //   });
  // }

  ///Complete Reminder
  // completeReminder(id: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Complete Reminder?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, Completed it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.reminderService.updateReminderStatus(id).subscribe(
  //         (response) => {
  //         this.toastr.success('Reminder Completed Successfully', 'Success');
  //         this.GetReminderFilterList();
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         const message = JSON.parse(data?.error);
  //         Swal.fire({
  //           icon: 'info',
  //           text: message?.error?.message,
  //         });
  //       });
  //     }
  //   });
  // }
  //Open Description(Notes) Model
  // openDescription(description: string) {
  //   this.vDescription = description;
  // }
  // setSelectedRow(index: number): void {
  //   this.selectedRowIndex = index;
  // }

  //Row Click Event UnProcessed Table
  // handleTableCellClick(e: Event, notesData: any): void {
  //   const target = e.target as HTMLElement;
  //   const tr = target.closest('tr');
  //   this.getDataTableData(tr, notesData);
  // }
  // //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  // async getDataTableData(tr: any, notesData: any): Promise<void> {
  //   this.myTable = await this.datatableElement.dtInstance;

  //   if (tr) {
  //     const row = this.myTable.row(tr);

  //     if (row.child.isShown()) {
  //       row.child.hide();
  //     } else {
  //       row.child(this.format(row.data(), notesData)).show();
  //     }
  //   }
  // }

  // format(d: any, notesData: any): string {
  //   let returnString: string = '';

  //   if (Array.isArray(notesData)) {
  //     notesData.forEach(function (value) {
  //       returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + value + '</td></tr>';
  //     });
  //   } else if (typeof notesData === 'string') {
  //     returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + notesData + '</td></tr>';
  //   } else {
  //     console.error('notesData is neither an array nor a string:', notesData);
  //   }

  //   return returnString;
  // }

  // //Copy Text When Double click On Row
  // onRowDoubleClick(textToCopy) {
  //   this.copyTextToClipboard(textToCopy);
  //   this.showNotification('Text copied: ' + textToCopy);
  // }

  // showNotification(message: string): void {
  //   this.snackBar.open(message, 'Close', {
  //     duration: 3000, // Duration in milliseconds
  //     horizontalPosition: 'end', // Positioning the notification horizontally
  //     verticalPosition: 'bottom', // Positioning the notification vertically
  //   });
  // }

  // copyTextToClipboard(text: string): void {
  //   const textArea = document.createElement('textarea');
  //   textArea.value = text;
  //   document.body.appendChild(textArea);
  //   textArea.select();
  //   document.execCommand('Copy');
  //   textArea.remove();
  // }

  // getFormattedDate(date: any): string {
  //   return date
  //     ? new Date(date).toLocaleString('en-US', {
  //         month: '2-digit',
  //         day: '2-digit',
  //         year: 'numeric',
  //         hour: 'numeric',
  //         minute: 'numeric',
  //         hour12: true,
  //       })
  //     : '-';
  // }
  // isEmpty(value: any): boolean {
  //   return (
  //     value === null ||
  //     value === undefined ||
  //     value === '' ||
  //     value === defaultGuid
  //   );
  // }

  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];
    console.log('$addPageNavigationControls : current page = {currentPage}' + this.currentPage)
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

    if(this.currentPage >= this.totalPages)
    {
      $("<button  disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button  disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.MovePage(true));
    $('#btnPrevPage').on('click', (event) => this.MovePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.changePage(1));
    $('#btnLastPage').on('click', (event) => this.changePage(this.totalPages));
   }

   fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.changePage(pageno);
    }
  }

  MovePage(isNext:boolean)
  {
    console.log('MovePage called....' + this.currentPage);
    let pageNo = this.currentPage;
    if(isNext)
      pageNo++;
    else
    pageNo--;
    //this.nevigatePage.emit(this.currentPage);
    this.changePage(pageNo);
  }

  changePage(pageNo : number){
    console.log('changePage PageNo : ' + pageNo + 'this.currentPage :' + this.currentPage + 'this.totalPages :' + this.totalPages);
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo;
      this.GetReminderFilterList();
    }
  }

  refreshDataTable(): void {

      this.addPageNavigationControls();
            var visColumns =  this.visibleColumns;
      var index = 0;

  }

}
