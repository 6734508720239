import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NoteType, PatientNotesDTO, noteTypeOptions } from '../patient-proxy/patient-optimization/dto';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientNotesService } from '../patient-proxy/patient-optimization/patient-notes.service';
import { Observable } from 'rxjs';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-common-notes-search-v1',
  templateUrl: './common-notes-search-v1.component.html',
  styleUrls: ['./common-notes-search-v1.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CommonNotesSearchV1Component implements OnInit {
  isLoading: boolean;
  public brnachList: Observable<Branch[]> | undefined;
  arrTableData: PatientNotesDTO[] = [];
  dataSource = new MatTableDataSource(this.arrTableData);
  public arrDisplayedColumns: string[] = [
    'options',
    'defaultNotesId',
    'noteType',
    'noteReason',
    'subject',
    'description',
    'createdBy',
    'status',
    'createdDate',
  ];
  expandedElement: PatientNotesDTO | null;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  ELEMENT_DATA: PatientNotesDTO[];
  @Input() noteType: NoteType = NoteType.general;
  // @Input() patientId: string = defaultGuid;
  patientId: string = defaultGuid;


  constructor(
    private patientNotesService: PatientNotesService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
    patientId: string;
    pageType: NoteType;
    isAddVisible: boolean;
    orderId: string;
  } = {
    patientId: defaultGuid,
    orderId: defaultGuid,
    pageType: NoteType.general,
    isAddVisible: true,
  }
) {}

  ngOnInit(): void {
    this.apiCall()
  }

  getToolTip(data) {
    return data.description;
  }

  apiCall(){
    this.isLoading = true;
  this.patientNotesService.notesSearchByGPatientIdAndGOrderIdAndGClaimIdAndSNoteIdAndSPatientNameAndSDefaultPatientIdAndSNoteTypeAndSReasonAndSSubjectAndSDescriptionAndSCreatedByAndDtCreatedDateAndSStatusAndStart_DateAndEnd_Date(
    '6f2fbe33-775d-c7cd-a49e-3a02e3debd6c', //this.patientId, more data
    // '46d9c3d6-0ca4-6bae-cec4-3a0c56f3c0b0', //this.patientId, no data
    // '3021a456-d267-90e8-9be8-3a0e2567c816', // One data
    defaultGuid,
    defaultGuid,
    '', //sValue?.defaultNotesId,
    '',
    '',
    '', //sValue?.noteType,
    '', //sValue?.noteReason,
    '', //sValue?.subject,
    '', //sValue?.description,
    '', //sValue?.createdBy,
    '', //sValue?.dateCreated,
    '', //sValue?.status,
    '',
    ''
  )
.subscribe(
(response) => {
  response = response?.map((x, i = 1) => {
    const y = {
      sNo: ++i,
      customNoteType: noteTypeOptions?.filter(
        (a) => a?.key === x?.noteType?.toString()
      )?.[0]?.value,
      ...x,
    };
    return y;
  });
  response && response && this.setTableData(response);
  this.isLoading = false;
});
  }

  setTableData(response: any[]) {
    this.arrTableData = response;
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}


export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
