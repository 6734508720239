import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  IdentityUserDto,
  UserByRoleDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import {
  CommentsList,
  CreateUpdateWIPHistoryDTO,
  WIPList,
  clsMultipleWIPUpdate,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
import { IdentityUserService } from 'projects/admin/src/app/admin-proxy/volo/abp/identity/identity-user.service';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Observable, Subscription, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientNotesService } from '../patient-proxy/patient-optimization';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { WIPProcessDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { WIPHistoryService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/wip-history.service';
import { ToastrService } from 'ngx-toastr';
import { noteTypeOptions } from '../patient-proxy/patient-optimization/dto';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { WipStatusDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-wip-allocation-modal',
  templateUrl: './wip-allocation-modal.component.html',
  styleUrls: ['./wip-allocation-modal.component.scss'],
})
export class WipAllocationModalComponent implements OnInit {
  arrDisplayedCommentsColumns: string[] = [
    'agent',
    'noteType',
    'description',
    'createdDate',
  ];
  subscription$: Subscription[] = [];
  wipAllocationForm: FormGroup;
  noteSearchForm: FormGroup;
  dataSourceNotes: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  filteredIdentityUsers: Observable<UserByRoleDTO[]>;
  public filteredWipStatus: Observable<any[]> | undefined;
  defaultWIPIds:string;
  lstWipStatus: WipStatusDTO[];
  lstUsers: UserByRoleDTO[] = [];
  drpProcessLoop: WIPProcessDTO[];
  isLoading: boolean = false;
  dateValidator: any;
  process: string;
  wipStatus:string;
  showText = 'Show More';
  notesText = '';
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      wipId: string[];
      patientId: string[];
      defaultWipId: string[];
    } = {
      wipId: [],
      patientId: [],
      defaultWipId: [],
    },
    private formBuilder: FormBuilder,
    private patientDropdownService: PatientMasterDropdownService,
    private patientNotesService: PatientNotesService,
    private userService: UserService,
    private wipHistoryService: WIPHistoryService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private dialog: MatDialogRef<WipAllocationModalComponent>
  ) {}

  ngOnInit(): void {
    this.defaultWIPIds=this.data.defaultWipId.join(', ');
    this.initializeForms();
    this.loadDropdowns();
    this.data.patientId.length === 1 && this.loadComments();
  }
  initializeForms() {
    this.wipAllocationForm = this.formBuilder.group({
      //drpProcess: new FormControl('', [Validators.required]),
      //drpProcessLoop: new FormControl(''),
      drpStatus: new FormControl('', [Validators.required]),
      txtStatusFilter: new FormControl(''),
      agent: new FormControl('', [Validators.required]),
      txtAgentFilter: new FormControl(''),
      txtComment: new FormControl('', [Validators.required]),
    });

    this.noteSearchForm = this.formBuilder.group({
      txtAgent: new FormControl(''),
      txtNoteType: new FormControl(''),
      txtDescription: new FormControl(''),
      txtCreatedDate: new FormControl('', this.dateValidator?.dateVaidator),
    });
  }
  loadDropdowns() {
    //getUserByRole
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.WIPProcess,
      PatientDropdowns.WipStatus,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          this.drpProcessLoop = response.lstWipProcess;
          this.lstWipStatus = response.lstWipStatus;

          this.filteredWipStatus = this.wipAllocationForm
            .get('txtStatusFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstWipStatus?.filter((option) =>
                  option?.wipStatus
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);
  }

  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  showMoreBtn(data) {
    const dialogRef = this.matDialog.open(AddNoteTabComponent, {
      disableClose: true,
      data: {
        notesDes: data?.description,
        notesId: data?.notesId,
        noteType: data?.customNoteType,
        pageType: data?.customNoteType,
        claimId: defaultGuid,
        orderId: defaultGuid,
        patientId: this.data?.patientId,
      },
    });

    const closeDialog$ = dialogRef.afterClosed().subscribe(
      (result) => {
        this.loadComments();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog$);
  }

  getTooltip(data) {
    return data.description;
  }

  //Load Users Dropdown based on Role
  loadUsersbyStatus() {
    const roleId = this.wipAllocationForm.value.drpStatus;
    const userList = this.userService
      .getUserByStatus(roleId)
      .subscribe((response) => {
        this.lstUsers = response;

        this.filteredIdentityUsers = this.wipAllocationForm
          ?.get('txtAgentFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUsers.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );

        //Condition for Pending and Completed Statuses
        // if (
        //   this.drpProcessLoop.filter(
        //     (a) => a.id == this.wipAllocationForm.value.drpProcess
        //   )[0].roleId == defaultGuid
        // ) {
        //   this.wipAllocationForm.value.agent = this.lstUsers.filter(
        //     (a) => a.userId == defaultGuid
        //   )[0].userId;
        //   this.wipAllocationForm.patchValue({
        //     agent: this.drpProcessLoop.filter(
        //       (a) => a.id == this.wipAllocationForm.value.drpProcess
        //     )[0].roleId,
        //   });
        // }
        // this.subscription$.push(userList);
      });
  }

  loadComments() {
    this.isLoading = true;
    const patientNotes = this.noteSearchForm?.valueChanges
      ?.pipe(
        startWith({
          agent: '',
          noteType: '',
          description: '',
          createdDate: '',
        }),
        filter((e) => e && this.noteSearchForm?.valid),
        map((response: any) => {
          this.isLoading = true;
          const sValue = {
            agent: response?.txtAgent?.trim()?.toLowerCase(),
            noteType: response?.txtNoteType?.trim()?.toLowerCase(),
            description: response?.txtDescription?.trim()?.toLowerCase(),
            dateCreated: response?.txtCreatedDate?.trim()?.toLowerCase(),
          };
          return sValue;
        }),
        switchMap((sValue) =>
          // gPatientId, gOrderId, gClaimId, sNoteId, sPatientName, sDefaultPatientId,
          // sNoteType, sReason, sSubject, sDescription, sCreatedBy, dtCreatedDate, sStatus, start_Date, end_Date
          this.patientNotesService.notesSearchByGPatientIdAndGOrderIdAndGClaimIdAndSNoteIdAndSPatientNameAndSDefaultPatientIdAndSNoteTypeAndSReasonAndSSubjectAndSDescriptionAndSCreatedByAndDtCreatedDateAndSStatusAndStart_DateAndEnd_Date(
            this.data.patientId[0],
            defaultGuid,
            defaultGuid,
            '',
            '',
            '',
            sValue?.noteType,
            '',
            '',
            sValue?.description,
            sValue?.agent,
            sValue?.dateCreated,
            '',
            '',
            ''
          )
        )
      )
      .subscribe(
        (response) => {
          this.isLoading = false;
          response = response?.map((x, i = 1) => {
            const y = {
              sNo: ++i,
              customNoteType: noteTypeOptions?.filter(
                (a) => a?.key === x?.noteType?.toString()
              )?.[0]?.value,
              ...x,
            };
            return y;
          });
          this.setTableData(response ?? []);
        },
        (err) => {
          this.setTableData([]);
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.subscription$?.push(patientNotes);
  }
  setTableData(response: any[] = []) {
    //!  Listed the Claim processed records in claim proces table
    this.dataSourceNotes = new MatTableDataSource(response);
    this.dataSourceNotes.sort = this.sort;
    this.dataSourceNotes.sort.disableClear = true;
    this.dataSourceNotes.paginator = this.paginator;
  }
  toggleSkil() {
    this.showText = 'show Less';
  }
  assignTask() {
    // const wipHistory: CreateUpdateWIPHistoryDTO = {
    //   WIPId: this.data.wipId,
    //   assignedTo: this.wipAllocationForm.value.agent,
    //   assignedBy: localStorage?.getItem('userId') ?? defaultGuid,
    //   WIPProcessId: this.wipAllocationForm.getRawValue().drpProcess,
    //   comments: this.wipAllocationForm.getRawValue().txtComment,
    //   TenantId: localStorage?.getItem('tenantId'),
    // };
    const wipHistory: clsMultipleWIPUpdate = {
      lstWipId: this.data.wipId,
      wipProcessId:null,
      wipStatusId: this.wipAllocationForm.getRawValue().drpStatus,
      agentId: this.wipAllocationForm.value.agent,
      notes: this.wipAllocationForm.getRawValue().txtComment,
    };

    const wipHistoryDetails = this.wipHistoryService
      .updateWIPDetails(wipHistory)
      .subscribe(
        (response) => {
          this.loadComments();
          this.dialog.close();
          this.toastr.success('Assigned Successfully', 'Success');
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(wipHistoryDetails);
  }
}
