<!-- Title -->
<div class="row align-items-center">

  <div class="col-md-10">
    <!-- <h2 class="customThemeClass">{{ notesModalTitle }} - {{data.defaultFaxId}}</h2>
    <h2 class="customThemeClass">{{ notesModalTitle }} - {{data.chartNo}}</h2> -->
    <h2  class="customThemeClass">
      Write Off Notes
    </h2>

  </div>
  <div class="col-md-2" style="text-align: right">
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
</div>

<!-- <mat-divider></mat-divider> -->
<!-- Title -->
<mat-dialog-content>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pb-0 mb-2 mt-1">
          <div class="table table-responsive" *ngIf="!isLoading">

            <table id="tblNotesMdl" datatable [dtOptions]="dtNotesOptionsv1" class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th [matTooltip]="'Created By'">Created By</th>
                        <th [matTooltip]="'Created At'" class="cus-column-width">Created At</th>
                        <th [matTooltip]="'Note Type'">Note Type</th>
                        <th [matTooltip]="'Type'">Type</th>
                        <th [matTooltip]="'HCPC Code'" >HCPC Code</th>
                        <th [matTooltip]="'Adjustment'" >Adjustment</th>
                        <th [matTooltip]="'Writeoff'">Writeoff </th>
                        <th [matTooltip]="'Writeoff Amount'" class="text-right" >Writeoff Amount($) </th>
                        <th [matTooltip]="'Comments'">Comments</th> <!-- Add Comments column -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of PatientNotesDetailsV1; let i = index"
                        (click)="openDescription(data.notes[0].comments); setSelectedRow(i)"
                        [ngClass]="{'selected-row': i === selectedRowIndex}">
                        <td [matTooltip]=" data.creatorName || '-'">{{ data.creatorName || '-' }}</td>
                        <td >
                            <span [matTooltip]="data?.creationTme  || '-'">{{
                              data?.creationTme  || '-' }}</span>
                        </td>
                        <td [matTooltip]="data.notes[0].noteType || '-'">{{ data.notes[0].noteType || '-' }}</td>
                        <td [matTooltip]=" data.isPatBlance || '-'">{{ data.isPatBlance || '-' }}</td>
                        <td [matTooltip]=" data.hcpcCode || '-'">{{ data.hcpcCode || '-' }}</td>
                        <td [matTooltip]=" data.mergedReasonCode || '-'">{{ data.mergedReasonCode || '-' }}</td>
                        <td [matTooltip]=" data.wrirteoffText || '-'">{{ data.wrirteoffText || '-' }}</td>
                        <td [matTooltip]=" data.adjustmentAmount || '-'" class="text-right">{{ data.adjustmentAmount || '-' }}</td>
                        <td [matTooltip]="data.notes[0]?.comments || '-'">{{ data.notes[0]?.comments || '-' }}</td> <!-- Add Comments in the body -->
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Entered By Name"
                                name="search-EnteredByName" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class text-center" type="text" placeholder="Entered"
                                name="search-Entered" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Note Type" name="search-NoteType" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Modified By" name="search-ModifiedBy" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class text-center" type="text" placeholder="Modified"
                                name="search-Modified" />
                        </th>
                    </tr>
                </tfoot> -->
            </table>
        </div>

        <mat-dialog-actions *ngIf="PatientNotesDetailsV1 && PatientNotesDetailsV1.length > 0">
            <textarea [disabled]="true" cols="132" rows="6" style="resize: none;"
                class="txtnotescls" id="scrolltxt">{{ vDescription }}</textarea>
        </mat-dialog-actions>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
