import { ListService } from '@abp/ng.core';
// import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
// import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { environment } from 'projects/patient/src/environments/environment';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';


@Component({
  selector: 'app-identityserver-login',
  templateUrl: './identityserver-login.component.html',
  styleUrls: ['./identityserver-login.component.scss'],
  providers: [ListService]

})
export class IdentityserverLoginComponent implements OnInit {

  constructor(
    private oauthService: OAuthService,
    // private HttpClient: HttpClient,
    public list: ListService,
    public userProfileService: UserService,) { }

  //private oidcSecurityService : OidcSecurityService

  ngOnInit(): void {
    this.oauthService.configure(environment.oAuthConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  // getClient() {
  //   this.HttpClient.get<any>('https://localhost:44368/api/abp/multi-tenancy/tenants/by-name/test').subscribe(res => {
  //   });
  // }
}
