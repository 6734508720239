import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { AuditLogDTO } from 'projects/patient/src/app/patient-proxy/audit-logs/dto';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PostingAuditLogService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/posting-audit-logs';

@Component({
  selector: 'app-posting-history',
  templateUrl: './posting-history.component.html',
  styleUrls: ['./posting-history.component.scss']
})
export class PostingHistoryComponent implements OnInit {
  strPageType: string = 'History';
  historyData: {
    type: 'Create' | 'Delete' | 'Update',
    ActionComment: string
    UserName: string,
    dateTime: string | Date
  }[] = [];
  historyObservable$: Observable<AuditLogDTO[]>;
  @Input() patientId: string = "";
  arrUsersList: IdentityUserDto[] = [];
  tableList: any[] = [];
  tenantId: any = "";
  subscription$: Subscription[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public arrDisplayedColumns: string[] = [];
  isLoading: boolean = true;
  claimId: string = "";
  claimTableData: billingList[] = [];
  lblPatientName: string;
  userId: string = "";
  strClaimId: string = "";
  strClaimStatus: string = "";
  constructor(
    public tableService: TableService,
    public title: Title,
    public router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private postingHistory: PostingAuditLogService
  ) {
    this.getUsersList();
  }

  ngOnInit(): void {

    this.activatedRoute?.queryParams?.subscribe((v) => {
      this.strClaimStatus = v?.id ?? 'All';
    });

    this.activatedRoute.params?.subscribe((v) => {
      this.claimId = v?.id ?? "";
      this.getHistory();
    });
  }
  // Get posting changes history by claim id
  getHistory() {
    this.isLoading = true;
    const patientHistory = this.postingHistory.getPostingAuditLogReportByClaimId(this.claimId).subscribe((response) => {
      response?.forEach((element,index) => {
        this.tableList.push({
          i:index+1,
          Action: element?.operation,
          dateTime: element?.changedDateTime,
          UserName: this.getUserName(element.creatorId ?? ""),
          ActionComment: element?.comments
        })
      });
      this.isLoading = false;
      this.tableService.setPatientHistoryDataTable(this.tableList);
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(patientHistory);
  }
  private getUserName(value: string) {
    if (value && typeof value === "string") {
      return this.arrUsersList?.filter(u => u?.id === value)[0]?.userName ?? "";
    }
  }

  private getUsersList() {
    const identityUser = this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.subscription$.push(identityUser);
    });
  }
}



export interface billingList {
  id: string,
  cashPayment: number,
  defaultPatientId: string,
  patientName: string,
  payPalPaymentStatus: string,
  claimStatus: string,
  processedCount: number,
  readyCount: number,
  holdCount: number,
  crossOverCount: number,
  saleorderId: string,
  dos: string,
  totalAllowed: number,
  totalBilled: number,
  totalWriteOff: number,
  totalInsPaid: number,
  totalPatientBal: number,
  lastFilled: string,
  patientId: string,
  orderId: string,
  claimId: string,
  creationTime: string,
  InsuranceType: string
}
