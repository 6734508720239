<mat-dialog-content class="mat-typography">
  <div class="modal-header">
    <h1 class="modal-title"><b>Fax Finder</b></h1>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>

  <div class="card card-body">
    <form [formGroup]="fgFaxFinder">
      <div class="row">

        <!--Fax Id-->
        <mat-form-field class="form-field col-2">
          <input matInput type="text" formControlName="FaxId" placeholder="FaxId" (ngModelChange)="onKeyPressSearch()"/>
        </mat-form-field>
        <!--Chart No-->
        <mat-form-field class="form-field col-2">
          <input matInput type="text" formControlName="ChartNo" placeholder="ChartNo" (ngModelChange)="onKeyPressSearch()"/>
        </mat-form-field>
        <!--Patient Name-->
        <mat-form-field class="form-field col-3">
          <input matInput type="text" formControlName="PatientName" placeholder="PatientName" (ngModelChange)="onKeyPressSearch()"/>
        </mat-form-field>
        <!-- Birth Date -->
        <mat-form-field class="col-2">
          <mat-label>
            Birth Date </mat-label>
          <input autocomplete="new-txtBirthdate" [max]="BirthdateMaxDate" formControlName="txtBirthdate" matInput
            maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtBirthdate"
            [min]="BirthdateMinDate" (ngModelChange)="onKeyPressSearch()"/>
          <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtBirthdate> </mat-datepicker>
          <mat-error *ngIf="fgFaxFinder.get('txtBirthdate').errors?.invalidDate||
            BirthdateMinDate <=
            fgFaxFinder?.get('txtBirthdate')?.value &&
            !(
              fgFaxFinder?.get('txtBirthdate')?.touched &&
              fgFaxFinder?.get('txtBirthdate')?.errors
                ?.required
            )||fgFaxFinder?.get('txtBirthdate')?.errors?.required
          "> Enter Past Date!
          </mat-error>
        </mat-form-field>

        <div class="col-3 text-right mt-3">

          <button mat-button class="buttonColor mr-2"
            [disabled]="!(!fgFaxFinder?.invalid)" (click)="fnSearchFax()">
            <span>Search</span>
            <!-- <mat-icon *ngIf="isShowSpinner">
              <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
            </mat-icon> -->
          </button>
          <button mat-raised-button class="resetclr mb-2 mr-2" (click)="resetFaxFinder()">Reset</button>
        </div>
      </div>


    </form>
  <!-- </div> -->
  <!-- <mat-divider></mat-divider> -->
  <!-- <div class="card card-body mt-3"> -->
    <div class="col-lg-12 mt-3" *ngIf="isShown">
      <mat-divider></mat-divider>
      <!-- Loader Section -->
      <div class="row" *ngIf="isLoading">
        <div class="col-12">
          <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div *ngIf="!isLoading" class="table table-responsive">
          <app-faxFinderTable [tableData]="faxFinderDataList"></app-faxFinderTable>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>