<div class="py-2" style="border: 2px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5 d-inline-flex align-items-center">
          <h2 class="mb-0"><b>Master / Discount</b></h2>
        </div>
        <div class="col-md-7">
          <div class="d-flex flex-row-reverse" style="margin-right: 8%;">
            <mat-radio-group [(ngModel)]="selectedOption" (change)="getDiscounttblList()">
              <mat-radio-button [value]="0" class="active"><b>All</b></mat-radio-button>
              <mat-radio-button [value]="1" class="active p-2" [checked]="true"><b>Active</b></mat-radio-button>
              <mat-radio-button [value]="2" class="active"><b>Inactive</b></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-12 " >
    <app-master-discount-table [tableData]="discountDataList" *ngIf="!isShowSpinner"></app-master-discount-table>
    <div class="row" *ngIf="isShowSpinner">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isShowSpinner">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
    </div>
  </div>