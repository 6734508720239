<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 align-items-center d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-2">
        <b class="customThemeClass">List / Orders / Pickup/Exchange Orders</b>
      </h3>
      <div>
        <form [formGroup]="frmExchangeListForm">
          <mat-form-field>
            <mat-label>Pickup/Exchange Date</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                placeholder="Start date"
                formControlName="txtDateValue"
              />
              <input
                matEndDate
                matInput
                placeholder="End date"
                formControlName="txtDate"
                (dateChange)="onDateRangeChange()"
              />
              <!-- (click)="getOrderDetails('data')" -->
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <!-- Page Content -->
  <div class="row">
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-body p-2">
          <!-- <div class="col-12 d-flex justify-content-between mb-2 pr-0">
            <p class="header-text mt-2">Exchange List</p>

          </div> -->
          <!-- <mat-divider class="my-3"></mat-divider> -->
          <div class="d-flex justify-content-center" *ngIf="!loadExchangeTable">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <div *ngIf="loadExchangeTable" class="table table-responsive">
            <app-exchange-list-table
              (nevigatePage)="changePage($event)"
              [currentPage]="currentPage"
              [totalPages]="totalPages"
              [paginationInfo]="paginationInfo"
              [ltExchangeOrders]="ltExchangeOrders"
            ></app-exchange-list-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Exchange Items List Dialog -->
<ng-template #exchangeItemsDialog>
  <div class="row">
    <!-- Header -->
    <div class="col-12 mb-2">
      <div class="d-flex flex-row-reverse align-items-center">
        <!-- <p class="header-text mb-0">Exchange Order</p> -->
        <button mat-icon-button (click)="closeDialog()" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body p-2">
          <!-- Exchange Order -->
          <div>
            <p class="header-text">
              Pickup/Exchange Order - {{ exchangeOrderId }}
            </p>
            <div class="table table-responsive">
              <table
                datatable
                class="row-border hover w-100"
                [dtOptions]="dtExchangeOrderTableOptions"
              >
                <thead>
                  <tr>
                    <th *ngIf="isPickup" [matTooltip]="'Options'">Options</th>
                    <th [matTooltip]="'Product Code'">Product Code</th>
                    <th [matTooltip]="'Description'">Description</th>
                    <th [matTooltip]="'Size'">Size</th>
                    <th [matTooltip]="'Serial No'">Serial No</th>
                    <th [matTooltip]="'Quantity'">Quantity</th>
                    <th *ngIf="isPickup" [matTooltip]="'Condition'">
                      Condition<span class="asterisk">*</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of ltExchangeOrderItems">
                    <td
                      *ngIf="isPickup && !data.isPickupItemsReturned"
                      class="text-center"
                      [matTooltip]="'Update Pickup'"
                    >
                      <ng-container
                        *ngIf="
                          data.exchangeConditionId === defaultGuid ||
                          data.exchangeConditionId === null
                        "
                      >
                        <button
                          type="button"
                          (click)="updatePickupItems(data)"
                          [disabled]="
                            data.returnTempConditionId === defaultGuid
                          "
                          mat-icon-button
                        >
                          <mat-icon
                            [ngClass]="{
                              'disabled-btn':
                                data.returnTempConditionId === defaultGuid
                            }"
                            class="lightseagreen"
                            >check_circle</mat-icon
                          >
                        </button>
                      </ng-container>

                      <ng-container
                        *ngIf="
                          data.exchangeConditionId !== defaultGuid &&
                          data.exchangeConditionId !== null
                        "
                      >
                        Returned
                      </ng-container>
                    </td>
                    <td [matTooltip]="data?.productCode || '-'">
                      {{ data.productCode || "-" }}
                    </td>
                    <td [matTooltip]="data?.productDescription || '-'">
                      {{ data.productDescription || "-" }}
                    </td>
                    <td [matTooltip]="data?.size || '-'">{{ data.size }}</td>
                    <td [matTooltip]="data?.serialLotNo || '-'">
                      {{ data.serialLotNo }}
                    </td>
                    <td [matTooltip]="data?.quantity || '-'">
                      {{ data.quantity || "-" }}
                    </td>
                    <td
                      [matTooltip]="
                        data.exchangeConditionId === defaultGuid ||
                        data.exchangeConditionId === null
                          ? 'Condition'
                          : data.exchangeConditionName
                      "
                      *ngIf="isPickup && !data.isPickupItemsReturned"
                    >
                      <ng-container
                        *ngIf="
                          data.exchangeConditionId === defaultGuid ||
                          data.exchangeConditionId === null
                        "
                      >
                        <mat-select
                          class="line-height"
                          [placeholder]="'Condition'"
                          #returnItemCondition="ngModel"
                          maxlength="20"
                          [(ngModel)]="data.returnTempConditionId"
                        >
                          <mat-option
                            [value]="data.id"
                            *ngFor="let data of ltItemReturnCondition"
                            >{{ data.description }}</mat-option
                          >
                        </mat-select>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          data.exchangeConditionId !== defaultGuid &&
                          data.exchangeConditionId !== null
                        "
                        >{{ data.exchangeConditionName || "-" }}</ng-container
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <mat-divider class="py-2"></mat-divider>
          <!-- New Order -->
          <div *ngIf="ltNewOrderItems?.length > 0">
            <p class="header-text">New Order - {{ newOrderId }}</p>
            <div class="table table-responsive">
              <table
                datatable
                class="row-border hover w-100"
                [dtOptions]="dtNewOrderTableOptions"
              >
                <thead>
                  <tr>
                    <th [matTooltip]="'Product Code'">Product Code</th>
                    <th [matTooltip]="'Description'">Description</th>
                    <th [matTooltip]="'Size'">Size</th>
                    <th [matTooltip]="'Serial No'">Serial No</th>
                    <th [matTooltip]="'Quantity'">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of ltNewOrderItems">
                    <td [matTooltip]="data?.productCode || ''">
                      {{ data.productCode }}
                    </td>
                    <td [matTooltip]="data?.productDescription || ''">
                      {{ data.productDescription }}
                    </td>
                    <td [matTooltip]="data?.size || ''">{{ data.size }}</td>
                    <td [matTooltip]="data?.serialLotNo || ''">
                      {{ data.serialLotNo }}
                    </td>
                    <td [matTooltip]="data?.quantity || ''">
                      {{ data.quantity }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-loader-component *ngIf="pageLoading"></app-loader-component>
