import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { ClaimBatchService } from '../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-batch.service';
import { ClaimProcessingService } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-claim-batch',
  templateUrl: './claim-batch.component.html',
  styleUrls: ['./claim-batch.component.scss'],
})
export class ClaimBatchComponent implements OnInit {
  claimBatchForm: FormGroup;
  isLoading: boolean=false;
  filteredStatusList: any;
  isLoadProvider: boolean=false;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  fromDate: string;
  toDate: string;
  ranges: any;
  calendarLocale: LocaleConfig;
  iscolorCode : boolean = false;
  ClaimBatchList: any;
  claimbatchLoader: boolean;
  lstProviders: any;
  txtProviderControl = new FormControl();
  txtStatusControl=new FormControl();
  filterProvider: any;
  lstStatus: any;
  selectedRange: any = { startDate: null, endDate: null };
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private claimBatchService: ClaimBatchService,
    private claimProcessingService: ClaimProcessingService,
    private title: Title,
    private datePipe: DatePipe,
    private providerList: MasterProviderService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('QSecure | Claim Batch');
    this.fromDate = this.convertISODateStringToCustomFormat(
      this. getCTStartOfMonth()
     )
       this.toDate = this.convertISODateStringToCustomFormat(
         this.ctDateNow()
       );
    this.initializeForms();
    this.claimBatchForm.patchValue({
      txtDateValue:
        this. getCTStartOfMonth()
        +'-'+
        this.ctDateNow()
     });
    this.selectedRange.startDate=this. getCTStartOfMonth(),
    this.selectedRange.endDate= this.ctDateNow()


    this.convertISODateStringToCustomFormat(
      this. getCTStartOfMonth()
     ) +'-'+ this.convertISODateStringToCustomFormat(
      this.ctDateNow()
    );
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'MM/DD/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };

    this.getProviderAndStatus();
      //Provider search
      this.txtProviderControl.valueChanges.subscribe((searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        this.lstProviders = this.filterProvider.filter((dx) =>
          dx.fullName.toLowerCase().includes(searchTerm)
        );
      });
        //Status search
        this.txtStatusControl.valueChanges.subscribe((searchTerm) => {
          searchTerm = searchTerm.toLowerCase();
          this.filteredStatusList = this.lstStatus.filter((dx) =>
            dx.batchStatus.toLowerCase().includes(searchTerm)
          );
        });
  }
    //To initialize the Forms
    initializeForms() {
      this.claimBatchForm = this.fb.group({
        txtDateValue: new FormControl(''),
        // txtDate: new FormControl(''),
        drpProvider: new FormControl(''),
        txtProviderFilter: new FormControl(''),
        drpStatus: new FormControl(defaultGuid),
        txtStatusFilter: new FormControl(''),
      });
    }
  getProviderAndStatus(){
    this.isLoadProvider = true;
    // const providers = this.claimProcessingService
    //   .getMasterProviderWithFileCount()
    const providerList = this.providerList
    .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.lstProviders = response?.items || [];
          this.filterProvider=this.lstProviders;
          this.isLoadProvider = false;
          if (this.lstProviders.length !== 0) {
            this.claimBatchForm.patchValue({
              // drpProvider: response?.items?.[0].shortCodeId || null
              drpProvider: this.lstProviders?.[0].id || null,
            });
          }
          this.getClaimBatchdetails();
        },
        (err) => {
          this.isLoadProvider = false;
        }
      );
      const status = this.claimBatchService
      .claimBatchstatus().subscribe(
        (response) => {
          this.lstStatus = response || [];
          const allstatusOption = {
            id: '00000000-0000-0000-0000-000000000000',
            batchStatus: 'All',
          };
          this.filteredStatusList = [allstatusOption, ...this.lstStatus];
        },
        (err) => {
          // this.isLoadProvider = false;
        }
      );
  }
  getClaimBatchdetails(){
    this.claimbatchLoader=true;
   const getClaimBatchdeatils=this.claimBatchService
   .getClaimBatchDetailsByFromDateAndToDateAndGProviderIdAndGClaimBatchStatusId(
    this.fromDate,this.toDate, this.claimBatchForm.value.drpProvider,this.claimBatchForm.value.drpStatus
   )
   .subscribe(
     (response) => {
       this.ClaimBatchList=response;
       this.ClaimBatchList = this.ClaimBatchList.map((data) => {
        return {
          ...data,
          claimSentDate: this.commonService.getFormattedDateTimeZone(
            data.claimSentDate
          ),
        };
});
       this.claimbatchLoader=false;
     },
     (error) => {
      const data: HttpErrorResponse = error;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
       this.claimbatchLoader=false;
     },
   );
  }
  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);
    return dateObj;
  }
  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    //    const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);

    return dateObj;
  }
  convertISODateStringToCustomFormat(isoDateString: any): string {
    const inputDate = new Date(isoDateString);

    // Extracting date components
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();

    // Formatted date string in MM/DD/YYYY format
    const formattedDateString = `${month}/${day}/${year}`;

    return formattedDateString;
  }

  datesUpdateds(e){
    if(e.startDate !=null || e.endDate !=null){
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datePipe.transform(startDate,"MM/dd/yyyy")
      this.toDate = this.datePipe.transform(endDate,"MM/dd/yyyy")
      this.getClaimBatchdetails();
    }else{
      this.fromDate= null;
      this.toDate=null;
    }
  }

  OpenColorDiv(){
    this.iscolorCode = !this.iscolorCode
  }
  onDateRangeChange() {
    const startDate = this.claimBatchForm.get('txtDateValue').value;
    const endDate = this.claimBatchForm.get('txtDate').value;
    this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    if (startDate != null && endDate != null) {
    }
  }

  onChangeStatus(){

  }
}
