import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PracticeManagementService } from 'projects/patient/src/app/patient-proxy/practice-management';
import { map, startWith } from 'rxjs/operators';
import emailMask from 'text-mask-addons/dist/emailMask';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import {
  RXInputDTO,
  RXUploadFilesDTO,
} from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rx-report-send-mail',
  templateUrl: './rx-report-send-mail.component.html',
  styleUrls: ['./rx-report-send-mail.component.scss'],
})
export class RxReportSendMailComponent implements OnInit {
  reportForm: FormGroup;
  filteredPractices: any;
  lstPractices: any;
  isShowSpinner: boolean = false;
  saveButtonHide: boolean;
  emailMask: any;
  
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public RxSendMailDialogRef: MatDialogRef<RxReportSendMailComponent>,
    private practiceManagementService: PracticeManagementService,
    private inboundDocumentService: InboundDocumentService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      input: RXInputDTO[];
    } = {
      input: [],
    }
  ) {
    this.emailMask = emailMask;
  }

  ngOnInit(): void {
    this.reportForm = this.formBuilder.group({
      txtPracticeFilter: new FormControl(''),
      drpPracticeManagement: new FormControl('', [Validators.required]),
      txtEmail: new FormControl('', [Validators.required, this.isValidEmail])
    });
    this.loadPracticeManagements();
  }

  onCloseClick(){
    this.RxSendMailDialogRef.close([]);
  }

  isValidEmail(control: FormControl): { [key: string]: any } | null {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(control.value)) {
      return { 'invalidEmail': true };
    }
    return null;
  }

  patchEmail(event: any) {
    let x=this.reportForm.value.drpPracticeManagement;
    this.reportForm.patchValue({
      txtEmail: x?.practiceEmail || '',
    });
  }

  reset() {
    this.reportForm.reset();
  }

  rxReport() {
    if (this.reportForm.valid) {
      this.isShowSpinner = true;
      let RxData: RXUploadFilesDTO = {
        input: this.data.input,
        gPractitionerId: this.reportForm.value.drpPracticeManagement.id,
        sPracEmailAddress: this.reportForm.value.txtEmail.toLowerCase(),
      };

      this.inboundDocumentService
        // .downloadInboundDocumentByBlobName(this.data.blobName)
        .sendRXDocumentsByRxData(RxData)
        .subscribe(
          (response) => {
            this.toastr.success('RX Date Renewal mail has been sent','Success');
            this.RxSendMailDialogRef.close(response);
            this.isShowSpinner = false;
          },
          (err) => {
            this.toastr.error('RX Date Renewal - mail send has been failed','Error');
            this.isShowSpinner = false;
          }
        );
    }
  }

  
  //Method to load the Practice Managements
  loadPracticeManagements() {
    const practiceList = this.practiceManagementService
      .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.lstPractices = response;
          this.filteredPractices = this.reportForm
            .get('txtPracticeFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstPractices?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }
}
