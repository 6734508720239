import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import {
  EmploymentDTO,
  MaritalStatusDTO,
  PatientMasterDropdownDTO,
  StateOfAutoAccidentDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientAddPolicyComponent } from '../patient-add-policy/patient-add-policy.component';
import { PatientService } from '../patient-proxy/patient';
import {
  InsuranceDTO,
  PatientDTO,
  PatientLockDTO,
  PolicyDTO,
} from '../patient-proxy/patient/dto';
import { updatePatientIntake } from '../patient-store/patient.action';

@Component({
  selector: 'app-patient-insurnace',
  templateUrl: './patient-insurnace.component.html',
  styleUrls: ['./patient-insurnace.component.scss'],
})
export class PatientInsurnaceComponent implements OnInit, OnDestroy {
  @Output() patientContact: EventEmitter<string> = new EventEmitter();
  @Output() saveInsuranceFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() patientIdEmitter: EventEmitter<PatientDTO> =
    new EventEmitter<PatientDTO>();

  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;

  InsuranceForm: FormGroup;
  @Input() patientId: string = '';
  policyList: any = [];
  strPageType: string = 'policy';
  tblPolicesReponse: any[] = [];
  public bOpenNewPolicy: boolean;
  stateofAutoAccident: string;
  maritalStatus: string;
  employment: string;
  subscription$: Subscription[] = [];
  isLoading: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private Table: TableService,
    private store: Store,
    private patientService: PatientService,
    private patientDropdownService: PatientMasterDropdownService,
    public dialog: MatDialog
  ) {}

  isPatientUpdatePermission: boolean = false;
  isPatientCreatePermission: boolean = false;
  isShownUpdateIconForPatient: boolean = true;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  isShowSpinner: boolean = false;
  isShownSaveButton: boolean = true;
  saveButtonHide: boolean;

  ngOnInit(): void {
    const getUserId = this.Table.getLoginUserId().subscribe((val) => {
      this.strLoginUserId = val;
    });
    this.subscription$.push(getUserId);
    //
    const getPatientId = this.Table.getPatientCreate().subscribe(
      (value) => {
        this.isPatientCreatePermission = value;
        if (this.isPatientCreatePermission == true) {
          this.isShownSaveButton = true;
        } else {
          this.isShownSaveButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getPatientId);
    //
    this.InsuranceForm = this.formBuilder.group({
      chkInjuryEmployment: new FormControl(''),
      chkInjuryAutoAccident: new FormControl(''),
      chkInjuryOtherAccident: new FormControl(''),
      drpInsStateofAutoAccident: new FormControl(null),
      drpInsMarital: new FormControl(null),
      drpInsEmployment: new FormControl(null),
      chkInsEnableMedicare: new FormControl(''),
      chkInsPrintAmounts: new FormControl(''),
      chkInsHardshipPatient: new FormControl(''),
      txtDateOnSet: new FormControl(''),
      txtDiscount: new FormControl(''),
      txtStartDate: new FormControl(''),
      txtReviewDate: new FormControl(''),
    });
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.getPolicyDropdown();
    this.patientId !== '' &&
      this.patientId !== null &&
      this.patientId !== undefined &&
      this.getPolicyDatas(this.patientId);
    this.getPatientDetailsByID();

    if (this.InsuranceForm.valid || this.InsuranceForm.dirty) {
      this.saveInsuranceFormState.emit(this.InsuranceForm);
    }
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() + 0);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //clear dropdown
  clearStateofAutoAccident() {
    this.stateofAutoAccident = '';
    this.InsuranceForm.patchValue({ drpInsStateofAutoAccident: '' });
  }
  clearMaritalStatus() {
    this.maritalStatus = '';
    this.InsuranceForm.patchValue({ drpInsMarital: '' });
  }
  clearEmployment() {
    this.employment = '';
    this.InsuranceForm.patchValue({ drpInsEmployment: '' });
  }

  drpmartailStatusLoop: MaritalStatusDTO[] = [];
  drpEmploymentLoop: EmploymentDTO[] = [];
  drpStateLoop: StateOfAutoAccidentDTO[] = [];
  getPolicyDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.MaritalStatus,
      PatientDropdowns.Employments,
      PatientDropdowns.StateOfAutoAccidents,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: PatientMasterDropdownDTO = stateResponse;
          this.drpmartailStatusLoop = response?.maritalStatuses;
          this.drpEmploymentLoop = response?.employments;
          this.drpStateLoop = response?.stateOfAutoAccidents;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);
  }

  getPolicyDatas(patientId: string) {
    this.isLoading = true;
    let patientDetails: PatientDTO;
    const patientGet = this.patientService.get(this.patientId).subscribe(
      (response) => {
        patientDetails = response ?? null;
        this.tblPolicesReponse = [];
        const primaryPolicy = patientDetails?.policies?.primaryPolicy;
        const secondaryPolicy = patientDetails?.policies?.secondaryPolicy;
        const tertiaryPolicy = patientDetails?.policies?.tertiaryPolicy;
        if (primaryPolicy) {
          this.tblPolicesReponse.push({
            policiesId: primaryPolicy?.policiesId,
            payorLevel: primaryPolicy?.payorLevel,
            insuranceType: primaryPolicy?.insuranceType,
            coverageType: primaryPolicy?.coverageType,
            insuranceId: primaryPolicy?.insuranceId,
            payorContact: primaryPolicy?.payorContact,
            startDate:
              primaryPolicy?.startDate == null
                ? ''
                : new Date(primaryPolicy?.startDate)?.toLocaleDateString(
                    'en-US'
                  ),
            endDate:
              primaryPolicy?.endDate == null
                ? ''
                : new Date(primaryPolicy?.endDate)?.toLocaleDateString('en-US'),
            payPct: primaryPolicy?.payPct,
            deductible: primaryPolicy?.deductible,
            deductibleMet: primaryPolicy?.deductibleMet,
            outOfPocketExpense: primaryPolicy?.outOfPocketExpense,
            outOfPocketExpenseMet: primaryPolicy?.outOfPocketExpenseMet,
            tplCode: primaryPolicy?.tplCode,
            tplName: primaryPolicy?.tplName,
            insuredId: primaryPolicy?.insuredId,
            assignmentId: primaryPolicy?.assignmentId,
            copay: primaryPolicy?.copay,
            note: primaryPolicy?.note,
            policyNo: primaryPolicy?.policyNo,
            groupNo: primaryPolicy?.groupNo,
            groupName: primaryPolicy?.groupName,
            user1: primaryPolicy?.user1,
            user2: primaryPolicy?.user2,
            user3: primaryPolicy?.user3,
            user4: primaryPolicy?.user4,
            relationShipType: primaryPolicy?.relationShipType,
            payorId: primaryPolicy?.payorId,
            additionalPtId: primaryPolicy?.additionalPtId,
            claimFillingCode: primaryPolicy?.claimFillingCode,
            tPLName: primaryPolicy?.tplName,
            tPLCode: primaryPolicy?.tplCode,
            propertyAndCasualtyAgency: primaryPolicy?.propertyAndCasualtyAgency,
            carrierId: primaryPolicy?.carrierId,
            alwaysCrossover: primaryPolicy?.alwaysCrossover,
            copyPatientInfo: primaryPolicy?.copyPatientInfo,
            lastName: primaryPolicy?.lastName,
            fisrtName: primaryPolicy?.fisrtName,
            middleName: primaryPolicy?.middleName,
            suffix: primaryPolicy?.suffix,
            birthdate: primaryPolicy?.birthdate,
            gender: primaryPolicy?.gender,
            sSN: primaryPolicy?.ssn,
            address1: primaryPolicy?.address1,
            address2: primaryPolicy?.address2,
            city: primaryPolicy?.city,
            state: primaryPolicy?.state,
            country: primaryPolicy?.country,
            postalCode: primaryPolicy?.postalCode,
            phone: primaryPolicy?.phone,
            mobile: primaryPolicy?.mobile,
            employer: primaryPolicy?.employer,
            employerContact: primaryPolicy?.employerContact,
            employerAddress1: primaryPolicy?.employerAddress1,
            employerAddress2: primaryPolicy?.employerAddress2,
            employerCity: primaryPolicy?.employerCity,
            employerSate: primaryPolicy?.employerSate,
            employerCountry: primaryPolicy?.employerCountry,
            employerPostalCode: primaryPolicy?.employerPostalCode,
            employerPhone: primaryPolicy?.employerPhone,
            box10d: primaryPolicy?.box10d,
            box19: primaryPolicy?.box19,
            added: primaryPolicy?.added,
            lastEdit: primaryPolicy?.lastEdit,
            employerId: primaryPolicy?.employerId,
          });
        }
        if (secondaryPolicy) {
          this.tblPolicesReponse.push({
            policiesId: secondaryPolicy?.policiesId,
            payorLevel: secondaryPolicy?.payorLevel,
            insuranceType: secondaryPolicy?.insuranceType,
            coverageType: secondaryPolicy?.coverageType,
            insuranceId: secondaryPolicy?.insuranceId,
            payorContact: secondaryPolicy?.payorContact,
            startDate:
              secondaryPolicy?.startDate == null
                ? ''
                : new Date(secondaryPolicy?.startDate)?.toLocaleDateString(
                    'en-US'
                  ),
            endDate:
              secondaryPolicy?.endDate == null
                ? ''
                : new Date(secondaryPolicy?.endDate)?.toLocaleDateString(
                    'en-US'
                  ),
            payPct: secondaryPolicy?.payPct,
            deductible: secondaryPolicy?.deductible,
            deductibleMet: secondaryPolicy?.deductibleMet,
            outOfPocketExpense: secondaryPolicy?.outOfPocketExpense,
            outOfPocketExpenseMet: secondaryPolicy?.outOfPocketExpenseMet,
            tplCode: secondaryPolicy?.tplCode,
            tplName: secondaryPolicy?.tplName,
            insuredId: secondaryPolicy?.insuredId,
            assignmentId: secondaryPolicy?.assignmentId,
            copay: secondaryPolicy?.copay,
            note: secondaryPolicy?.note,
            policyNo: secondaryPolicy?.policyNo,
            groupNo: secondaryPolicy?.groupNo,
            groupName: secondaryPolicy?.groupName,
            user1: secondaryPolicy?.user1,
            user2: secondaryPolicy?.user2,
            user3: secondaryPolicy?.user3,
            user4: secondaryPolicy?.user4,
            relationShipType: secondaryPolicy?.relationShipType,
            payorId: secondaryPolicy?.payorId,
            additionalPtId: secondaryPolicy?.additionalPtId,
            claimFillingCode: secondaryPolicy?.claimFillingCode,
            tPLName: secondaryPolicy?.tplName,
            tPLCode: secondaryPolicy?.tplCode,
            propertyAndCasualtyAgency:
              secondaryPolicy?.propertyAndCasualtyAgency,
            carrierId: secondaryPolicy?.carrierId,
            alwaysCrossover: secondaryPolicy?.alwaysCrossover,
            copyPatientInfo: secondaryPolicy?.copyPatientInfo,
            lastName: secondaryPolicy?.lastName,
            fisrtName: secondaryPolicy?.fisrtName,
            middleName: secondaryPolicy?.middleName,
            suffix: secondaryPolicy?.suffix,
            birthdate: secondaryPolicy?.birthdate,
            gender: secondaryPolicy?.gender,
            sSN: secondaryPolicy?.ssn,
            address1: secondaryPolicy?.address1,
            address2: secondaryPolicy?.address2,
            city: secondaryPolicy?.city,
            state: secondaryPolicy?.state,
            country: secondaryPolicy?.country,
            postalCode: secondaryPolicy?.postalCode,
            phone: secondaryPolicy?.phone,
            mobile: secondaryPolicy?.mobile,
            employer: secondaryPolicy?.employer,
            employerContact: secondaryPolicy?.employerContact,
            employerAddress1: secondaryPolicy?.employerAddress1,
            employerAddress2: secondaryPolicy?.employerAddress2,
            employerCity: secondaryPolicy?.employerCity,
            employerSate: secondaryPolicy?.employerSate,
            employerCountry: secondaryPolicy?.employerCountry,
            employerPostalCode: secondaryPolicy?.employerPostalCode,
            employerPhone: secondaryPolicy?.employerPhone,
            box10d: secondaryPolicy?.box10d,
            box19: secondaryPolicy?.box19,
            added: secondaryPolicy?.added,
            lastEdit: secondaryPolicy?.lastEdit,
            employerId: primaryPolicy?.employerId,
          });
        }
        if (tertiaryPolicy) {
          this.tblPolicesReponse.push({
            payorLevel: tertiaryPolicy?.payorLevel,
            insuranceType: tertiaryPolicy?.insuranceType,
            coverageType: tertiaryPolicy?.coverageType,
            insuranceId: tertiaryPolicy?.insuranceId,
            payorContact: tertiaryPolicy?.payorContact,
            startDate:
              tertiaryPolicy?.startDate == null
                ? ''
                : new Date(tertiaryPolicy?.startDate)?.toLocaleDateString(
                    'en-US'
                  ),
            endDate:
              tertiaryPolicy?.endDate == null
                ? ''
                : new Date(tertiaryPolicy?.endDate)?.toLocaleDateString(
                    'en-US'
                  ),
            payPct: tertiaryPolicy?.payPct,
            deductible: tertiaryPolicy?.deductible,
            deductibleMet: tertiaryPolicy?.deductibleMet,
            outOfPocketExpense: tertiaryPolicy?.outOfPocketExpense,
            outOfPocketExpenseMet: tertiaryPolicy?.outOfPocketExpenseMet,
            tplCode: tertiaryPolicy?.tplCode,
            tplName: tertiaryPolicy?.tplName,
            insuredId: tertiaryPolicy?.insuredId,
            assignmentId: tertiaryPolicy?.assignmentId,
            copay: tertiaryPolicy?.copay,
            note: tertiaryPolicy?.note,
            policyNo: tertiaryPolicy?.policyNo,
            groupNo: tertiaryPolicy?.groupNo,
            groupName: tertiaryPolicy?.groupName,
            user1: tertiaryPolicy?.user1,
            user2: tertiaryPolicy?.user2,
            user3: tertiaryPolicy?.user3,
            user4: tertiaryPolicy?.user4,
            relationShipType: tertiaryPolicy?.relationShipType,
            payorId: tertiaryPolicy?.payorId,
            additionalPtId: tertiaryPolicy?.additionalPtId,
            claimFillingCode: tertiaryPolicy?.claimFillingCode,
            tPLName: tertiaryPolicy?.tplName,
            tPLCode: tertiaryPolicy?.tplCode,
            propertyAndCasualtyAgency:
              tertiaryPolicy?.propertyAndCasualtyAgency,
            carrierId: tertiaryPolicy?.carrierId,
            alwaysCrossover: tertiaryPolicy?.alwaysCrossover,
            copyPatientInfo: tertiaryPolicy?.copyPatientInfo,
            lastName: tertiaryPolicy?.lastName,
            fisrtName: tertiaryPolicy?.fisrtName,
            middleName: tertiaryPolicy?.middleName,
            suffix: tertiaryPolicy?.suffix,
            birthdate: tertiaryPolicy?.birthdate,
            gender: tertiaryPolicy?.gender,
            sSN: tertiaryPolicy?.ssn,
            address1: tertiaryPolicy?.address1,
            address2: tertiaryPolicy?.address2,
            city: tertiaryPolicy?.city,
            state: tertiaryPolicy?.state,
            country: tertiaryPolicy?.country,
            postalCode: tertiaryPolicy?.postalCode,
            phone: tertiaryPolicy?.phone,
            mobile: tertiaryPolicy?.mobile,
            employer: tertiaryPolicy?.employer,
            employerContact: tertiaryPolicy?.employerContact,
            employerAddress1: tertiaryPolicy?.employerAddress1,
            employerAddress2: tertiaryPolicy?.employerAddress2,
            employerCity: tertiaryPolicy?.employerCity,
            employerSate: tertiaryPolicy?.employerSate,
            employerCountry: tertiaryPolicy?.employerCountry,
            employerPostalCode: tertiaryPolicy?.employerPostalCode,
            employerPhone: tertiaryPolicy?.employerPhone,
            box10d: tertiaryPolicy?.box10d,
            box19: tertiaryPolicy?.box19,
            added: tertiaryPolicy?.added,
            lastEdit: tertiaryPolicy?.lastEdit,
            employerId: primaryPolicy?.employerId,
          });
        }
        // patientDetails?.policies && patientDetails?.policies !== [] && patientDetails?.policies.forEach(element => {
        //   if (element.id !== "00000000-0000-0000-0000-000000000000") {
        //     this.tblPolicesReponse.push({
        //       policiesId: element.policiesId,
        //       payorLevel: element.payorLevel,
        //       insuranceType: 0,
        //       coverageType: element.coverageType,
        //       insuranceId: element.insuranceId,
        //       payorContact: element.payorContact,
        //       startDate: element.startDate == null ? "" : new Date(element.startDate)?.toLocaleDateString("en-US"),
        //       endDate: element.endDate == null ? "" : new Date(element.endDate)?.toLocaleDateString("en-US"),
        //       payPct: element.payPct,
        //       deductible: element.deductible,
        //       policyNo: element.policyNo,
        //       groupNo: element.groupNo,
        //       groupName: element.groupName,
        //       user1: element.user1,
        //       user2: element.user2,
        //       user3: element.user3,
        //       user4: element.user4,
        //       relationShipType: element.relationShipType,
        //       payorId: element.payorId,
        //       additionalPtId: element.additionalPtId,
        //       claimFillingCode: element.claimFillingCode,
        //       tPLName: element.tplName,
        //       tPLCode: element.tplCode,
        //       propertyAndCasualtyAgency: element.propertyAndCasualtyAgency,
        //       carrierId: element.carrierId,
        //       alwaysCrossover: element.alwaysCrossover,
        //       copyPatientInfo: element.copyPatientInfo,
        //       lastName: element.lastName,
        //       fisrtName: element.fisrtName,
        //       middleName: element.middleName,
        //       suffix: element.suffix,
        //       birthdate: element.birthdate,
        //       gender: element.gender,
        //       sSN: element.ssn,
        //       address1: element.address1,
        //       address2: element.address2,
        //       city: element.city,
        //       state: element.state,
        //       country: element.country,
        //       postalCode: element.postalCode,
        //       phone: element.phone,
        //       mobile: element.mobile,
        //       employer: element.employer,
        //       employerContact: element.employerContact,
        //       employerAddress1: element.employerAddress1,
        //       employerAddress2: element.employerAddress2,
        //       employerCity: element.employerCity,
        //       employerSate: element.employerSate,
        //       employerCountry: element.employerCountry,
        //       employerPostalCode: element.employerPostalCode,
        //       employerPhone: element.employerPhone,
        //       box10d: element.box10d,
        //       box19: element.box19,
        //     });
        //   }

        // })
        this.isLoading = false;
        this.Table.setPolicyTable(this.tblPolicesReponse);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
      },
      () => {
        //
      }
    );
    this.subscription$.push(patientGet);
    this.isLoading = false;
  }
  getPatientDetailsForSave() {
    let patientDetails: PatientDTO;
    if (
      this.patientId !== '' &&
      this.patientId !== null &&
      this.patientId !== undefined
    ) {
      this.patientService.get(this.patientId).subscribe(
        (response) => {
          patientDetails = response;
          this.SaveInsurance(patientDetails);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {
          //
        }
      );
    }
  }
  organizationUnitId: any;
  SaveInsurance(response: PatientDTO) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.Table.getOrganizationUnitId().subscribe(value => {
    //   this.organizationUnitId = value;
    // });
    this.organizationUnitId =
      localStorage.getItem('initialOrganizationUnitId') ?? '';
    let insuranceDetails: PatientDTO;
    this.patientId = response?.id ?? '';
    insuranceDetails = {
      addresses: response?.addresses,
      personals: response?.personals,
      contacts: response?.contacts,
      clinicals: response?.clinicals,
      insurances: [
        {
          id: this.patientId,
          stateOfAutoAccident:
            this.InsuranceForm.value.drpInsStateofAutoAccident ==
            (null || undefined)
              ? ''
              : this.InsuranceForm.value.drpInsStateofAutoAccident,
          injuryRelatedToEmployment:
            +this.InsuranceForm.value.chkInjuryEmployment | 0,
          injuryRelatedToAutoAccident:
            +this.InsuranceForm.value.chkInjuryAutoAccident | 0,
          injuryRelatedToOtherAccident:
            +this.InsuranceForm.value.chkInjuryOtherAccident | 0,
          dataOnset: this.InsuranceForm.value.txtDateOnSet,
          martialStatus:
            this.InsuranceForm.value.drpInsMarital == (null || undefined)
              ? ''
              : this.InsuranceForm.value.drpInsMarital,
          employment:
            this.InsuranceForm.value.drpInsEmployment == (null || undefined)
              ? ''
              : this.InsuranceForm.value.drpInsEmployment,
          printAmountsOnDeliveryTickets:
            +this.InsuranceForm.value.chkInsPrintAmounts | 0,
          patientFinancialHardship:
            +this.InsuranceForm.value.chkInsHardshipPatient | 0,
          discountPct: this.InsuranceForm.value.txtDiscount,
          startDate: this.InsuranceForm.value.txtStartDate,
          reviewDate: this.InsuranceForm.value.txtReviewDate,
          // createdByUserId: 0,
        },
      ],
      notes: response?.notes,
      policies: response?.policies,
      defaultPatientId: response?.defaultPatientId,
      insuranceVerifications: response?.insuranceVerifications,
      authorizations: response?.authorizations,
      patientDocuments: response?.patientDocuments,
      organizationUnitId: response?.organizationUnitId,
      isAutoIntake: response?.isAutoIntake,
      autoIntakeDocumentDetails: response?.autoIntakeDocumentDetails,
      chartId: response?.chartId,
      billingDetails: response?.billingDetails,
      patientLock: response?.patientLock,
      fileId: response?.fileId,
    };
    if (
      this.patientId !== '' ||
      this.patientId !== undefined ||
      this.patientId !== null
    ) {
      this.store
        .dispatch(new updatePatientIntake(this.patientId, insuranceDetails))
        .subscribe(
          (response: PatientDTO) => {
            let patientSaved: PatientDTO = response;
            this.patientIdEmitter.emit(patientSaved);
            Swal.fire({
              title: 'Success',
              html: 'Saved Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            }).then(
              (result) => {
                if (result.value) {
                  //!change next tab
                  this.nextTabMoveOnSaveEmitter.emit('Insurance');
                }
                this.saveButtonHide = true;
              },
              (err) => {}
            );
            this.isShowSpinner = false;
            this.saveInsuranceFormState.emit(this.InsuranceForm.value);
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }
  openPolicyModal() {
    const dialogRef = this.dialog.open(PatientAddPolicyComponent, {
      // height: '60%',
      // width:'80%',
      maxWidth: '80%',
      minHeight: '60%',
      disableClose: true,

      data: { patientId: this.patientId },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      (result) => {
        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.patientIdEmitter.emit(result);
        this.patientId !== '' &&
          this.patientId !== null &&
          this.patientId !== undefined &&
          this.getPolicyDatas(this.patientId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  ViewPolciy(policy: PolicyDTO) {
    this.bOpenNewPolicy = false;
    const dialogRef = this.dialog.open(PatientAddPolicyComponent, {
      // height: '50%',
      // width:'80%',
      // maxWidth: '80%',
      // minHeight:'50%',
      // disableClose: true,
      data: { patientId: this.patientId, policyId: policy },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (result) => {
        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.patientIdEmitter.emit(result);
        this.patientId !== '' &&
          this.patientId !== null &&
          this.patientId !== undefined &&
          this.getPolicyDatas(this.patientId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //! delete policy
  deletePolicy(datas?: any) {
    //
    this.deletePolicies(datas.policiesId);
  }

  deletePolicies(policyId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deletePolicy = this.patientService
          .deletePolicy(this.patientId, policyId)
          .subscribe(
            (response) => {
              this.tblPolicesReponse.forEach((element) => {
                if (policyId == element.policiesId) {
                  let index = this.tblPolicesReponse.indexOf(element, 0);
                  this.tblPolicesReponse.splice(index, 1);
                }
              });
              this.Table.setPolicyTable(this.tblPolicesReponse);
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        this.subscription$.push(deletePolicy);
      }
    });
  }

  //! Perosnal Details Get By ID Functions
  getPatientDetailsByID() {
    const patientUpdate = this.Table.getPatientUpdate().subscribe(
      (value) => {
        this.isPatientUpdatePermission = value;
        if (this.isPatientUpdatePermission == true) {
          this.isShownUpdateIconForPatient = true;
        } else {
          this.isShownUpdateIconForPatient = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(patientUpdate);
    //
    const patientGet =
      this.patientId !== '' &&
      this.patientId !== null &&
      this.patientId !== undefined &&
      this.patientService.get(this.patientId).subscribe(
        (response) => {
          const patientLock: PatientLockDTO = response?.patientLock;
          //Patient is locked & userId is not equal to loginUserId or patient is unlocked
          this.isPatientLockedByCurrentUser =
            (patientLock?.isLocked == true &&
              patientLock?.userId == this.strLoginUserId) ||
            patientLock?.isLocked == false
              ? true
              : false;
          const selectedPatient: PatientDTO = response ?? null;
          let selectedInsurance: InsuranceDTO =
            selectedPatient?.insurances[0] ?? null;
          if (selectedPatient && selectedInsurance) {
            this.InsuranceForm.patchValue({
              chkInjuryEmployment: selectedInsurance?.injuryRelatedToEmployment,
              chkInjuryAutoAccident:
                selectedInsurance.injuryRelatedToAutoAccident,
              chkInjuryOtherAccident:
                selectedInsurance.injuryRelatedToOtherAccident,
              drpInsStateofAutoAccident: selectedInsurance.stateOfAutoAccident,
              drpInsMarital: selectedInsurance.martialStatus,
              drpInsEmployment: selectedInsurance.employment,
              // chkInsEnableMedicare: selectedInsurance,
              chkInsPrintAmounts:
                selectedInsurance.printAmountsOnDeliveryTickets,
              chkInsHardshipPatient: selectedInsurance.patientFinancialHardship,
              txtDateOnSet: selectedInsurance.dataOnset,
              txtDiscount: selectedInsurance.discountPct,
              txtStartDate: selectedInsurance.startDate,
              txtReviewDate: selectedInsurance.reviewDate,
            });
          }
        },
        (err) => {
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.subscription$.push(patientGet);
  }
}
