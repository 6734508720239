<meta http-equiv="cache-control" content="no-cache, must-revalidate, post-check=0, pre-check=0">
<meta http-equiv="expires" content="0">
<meta http-equiv="pragma" content="no-cache">
<div class="container-fluid">
<div [hidden]="lenlockDocListDTO === 0">
  <div class="row mt-2">
    <div class="col-lg-6">
      <h3 class="customThemeClass">
        <b> Fax ID : {{faxId || '--'}} </b> /
        <b> {{ headerText}} </b>
      </h3>

    </div>
    <div class="col-lg-6 pr-0 d-flex justify-content-end">


      <form class="col-lg-12 pr-0 d-flex justify-content-end" [formGroup]="batchSortingForm">
        <!-- *ngIf="TiffError" -->
          <button [hidden]="true" mat-raised-button class="buttonColor  mb-2 mr-1"
          [matTooltip]="TiffError ? 'Refresh Document' : 'Document already loading successfully'"
          (click)="fnRefreshButtonClicked()" [disabled]="!TiffNotLoadingError || refreshButtonClicked">
          <mat-icon *ngIf="TiffNotLoadingError" [ngClass]="TiffError ? 'rotate-btn' : ''">refresh</mat-icon>
          <mat-icon *ngIf="!TiffNotLoadingError">done_outline</mat-icon>

        </button>

        <!-- Patient Search -->
        <mat-form-field class="col-lg-8 mr-6" id="newPatient">
          <mat-label>Patient <span class="asterisk">*</span></mat-label>
          <mat-select (selectionChange)="updatePageHeaderText()" formControlName="drpSearchPatient"
            [disabled]="isBTNdisable">
            <mat-option>
              <ngx-mat-select-search hideClearSearchButton="true" placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found" (keyup)="searchPatient($event.target.value)"
                formControlName="txtPatientFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="defaultGuid">New Patient</mat-option>
            <mat-option *ngFor="let chart of lstChartNos" [value]="chart.patientId">
              {{ chart.ddValue }}
            </mat-option>
          </mat-select>


        </mat-form-field>
      </form>
    </div>
    <!-- <div class="col-lg-2 d-flex justify-content-end">
      <a [routerLink]="'/inbound_sorting_pending'" class="preview-link">
        <button matTooltip="Click Go To Inbound" mat-button class="buttonColor font-size-10">
          Back To Inbound Page
        </button>
      </a>
    </div> -->
  </div>

  <!-- <div class="row p-2"> -->
  <!-- <div class="col-4"><h2 class="font-weight-bolder">Fax ID : <span class="app-custom-color">{{faxId || '--'}}</span></h2></div>
    <div class="col-4"><h2 class="font-weight-bolder">Patient Name : <span class="app-custom-color">{{patientName || '--'}}</span></h2></div>
    <div class="col-4"><h2 class="font-weight-bolder">Chart No : <span class="app-custom-color">{{chartID || '--'}}</span></h2></div> -->
  <!-- <div class="col-3"><h2 class="font-weight-bolder">Patient Id : <span class="app-custom-color">{{defaultPatientId || '--'}}</span></h2></div> -->
  <!-- </div> -->

  <!--  *ngIf="!isLoading"  -->
  <!-- *ngIf="isLoadSortingView" -->
  <div class="row">
    <!-- <div class="card card-body p-0">
    <div class="col-12 px-0">
      <div class="card py-3 card-body">
        <b class="">Sorting</b>
      </div>
    </div>
  </div> -->

    <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          <div class="card card-body p-0">
            <div class="col-12 px-0">
              <div class="card py-3 card-body">
                <div class="d-flex justify-content-between">
                  <b (click)="loadTotalDocument()" class=" pl-2 ml-2 header-text-v1 cursor-pointer"
                    [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Total}">
                    NO OF PAGES :
                    <ng-container *ngIf="overAllPages === overAllPagesBG; else TshowAllPages">
                      {{ overAllPages }}&nbsp;
                    </ng-container>
                    <ng-template #TshowAllPages>
                      <span class="header-text-v1 cursor-pointer"
                        [ngClass]="{ 'blink-text': true, 'selected-option': selectedOption === sotingSelectedCSS.Total }">
                        {{ overAllPages }}
                      </span>/{{ overAllPagesBG }}&nbsp;
                    </ng-template>
                  </b>
                  <b (click)="PendingDocumentImageList?.length && loadPendingDocument()"
                    class=" pl-2 ml-2 header-text-v1 "
                    [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Pending}"
                    [ngClass]="{'cursor-pointer': PendingDocumentImageList?.length > 0 }">
                    UNSORTED : {{ PendingDocumentImageList?.length || 0 }}&nbsp;
                  </b>
                  <b (click)="SortedDocumentImageList?.length && loadSortedDocument()"
                    class=" pl-2 ml-2 header-text-v1 cursor-pointer"
                    [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Sorted}"
                    [ngClass]="{'cursor-pointer': SortedDocumentImageList?.length > 0 }">
                    SORTED : {{ SortedDocumentImageList?.length || 0 }}&nbsp;
                  </b>
                </div>
              </div>
            </div>

            <div class="card card-body p-0">
              <div class="col-12 px-0">
                <div class="card py-2 card-body" style="padding: 0.5rem;">
                  <form [formGroup]="mstDocumentBinForm">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center">

                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex pl-0 align-items-center">
                        <b class=" header-text-v1 cursor-pointer upperText"
                          matTooltip="Add Document Bin">
                          Add Document Bin
                        </b>
                      </div>
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div class="running-placeholder-container">
                          <input formControlName="txtBinName" class="textbox-border-class" maxlength="50"
                            placeholder="Enter Document Bin Name" type="text" title="Enter Document Bin Name" oninput="this.value = this.value.replace(/[^a-zA-Z0-9-\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);"/>
                            <mat-error *ngIf="mstDocumentBinForm?.get('txtBinName')?.errors?.isDocumentBinExists" class="text-font-size">
                              Bin Name is already taken!
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ml-3 pl-1">
                        <button [disabled]="mstDocumentBinForm.invalid || DocumentBinSaveBtn" (click)="saveDocumentBin()" mat-icon-button
                          class="mr-2 buttonColor ml-3 d-flex align-items-center justify-content-center"
                          color="lightseagreen">
                          <mat-icon style="font-size: 20px">add</mat-icon>
                        </button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>


          <div class="card-body mb-0  scrollable-card p-0" style="max-height: 59vh">
            <ng-container *ngIf="!isLoadSortingView">
              <ng-container *ngFor="let document of documentTypes">
                <!-- <div class="scrollable-card" style="max-height: 64vh"> -->
                  <mat-accordion>
                    <mat-expansion-panel [hidden]="document.shortCode == SortingShortCode.All"
                    [hideToggle]="true" [disabled]="true" class="mt-1">
                      <mat-expansion-panel-header
                        class="customThemeClass custom-header-text-sorting custom-accordion-border">

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center"
                          (click)="$event.stopPropagation();">
                          <!-- d-flex -->
                          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6  pl-0 align-items-center"
                            (click)="document.pageCount===document.docPageContent?.length && loadBinDocument(document.shortCode,document.pageCount,document.documentTypeId)"
                            [ngClass]="{
                              'selected-option': selectedOption === sotingSelectedCSS.Bin && document_documentTypeId == document.documentTypeId,
                              'no-drop': document.pageCount !== document.docPageContent?.length
                            }"

                            >
                            {{ document.documentType | uppercase}} ({{document.pageCount}})
                          </div>
                          <!-- d-flex flex-row-reverse -->
                          <div class="col-4 ">

                            <!-- || this.overAllPages != this.overAllPagesBG  -->



                            <div class="running-placeholder-container">
                              <input #myInputRefs class="textbox-border-class" maxlength="20"
                                (keypress)="numberOnly($event)" placeholder="1 (or) 1-5 (or) 1,3,4 (or) leave it empty"
                                type="text" title="Enter a single number or a range (e.g., 1, 1-5) or leave it empty"
                                [disabled]="document.shortCode == SortingShortCode.All" />
                            </div>



                          </div>

                          <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <button (click)="moveDocument(myInputRefs.value,document)" mat-icon-button
                            [disabled]="document.shortCode == SortingShortCode.All || isDisabled || TiffError || document_shortCode === document.shortCode || overAllPagesBG === 0 || !(isTotalAPIcallGoingOnCount > 1) || overAllPages != overAllPagesBG || overAllPages !== SortedDocumentImageList.length+PendingDocumentImageList.length || document.shortCode == SortingShortCode.SIGNED_DOCUMENT"
                            [ngClass]="{
                          'mr-2': !document.isMedicareBin
                        }" class="buttonColor ml-4 d-flex align-items-center justify-content-center"
                            color="lightseagreen">
                            <mat-icon style="font-size: 20px">arrow_back</mat-icon>
                          </button>
                          </div>

                        </div>
                      </mat-expansion-panel-header>
                    </mat-expansion-panel>
                  </mat-accordion>
                <!-- </div> -->
              </ng-container>

            </ng-container>
            <ng-container *ngIf="isLoadSortingView">
              <div class="d-flex justify-content-center">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
              </div>
            </ng-container>
          </div>
          </div>




    <div class="card card-body p-0">
      <div class="justify-content-center">
        <div class="row p-2 justify-content-center align-items-center">
          <div class="col-3 d-flex justify-content-start " style="font:500 13px/20px Roboto, 'Helvetica Neue', sans-serif">
            Batch {{ currentlockDoc+1 }} of {{ lenlockDocListDTO }}
          </div>

          <div class="col-9 d-flex justify-content-end">
            <button  [disabled]="true" mat-raised-button class="defaultButton saveButton mr-1 pl-1" (click)="check()">
              <mat-icon>check</mat-icon> New
            </button>
            <button mat-raised-button class="defaultButton saveButton mr-1 pl-1" [disabled]="true">
              <mat-icon>description</mat-icon> Forms
            </button>
            <button mat-raised-button class="defaultButton saveButton  mr-1 pl-1" (click)="lockUpdateAPICall(1)"
              [disabled]="isSkipped || isSave || isDisabled">
              <mat-icon *ngIf="!isSkipped">skip_next</mat-icon>

              <mat-icon *ngIf="isSkipped">
                <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
              </mat-icon>

              Skip
            </button>
            <!-- class="buttonColor" -->
            <button class="defaultButton saveButton  mr-1 pl-1" mat-raised-button
              [disabled]="isSave || isSkipped || isDisabled" (click)="saveSortedDetails()">
              <mat-icon *ngIf="!isSave">save</mat-icon>
              <mat-icon *ngIf="isSave">
                <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
              </mat-icon>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4">

    </div>



    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">

      <div class="card card-body align-items-center justify-content-center p-0">
        <!-- <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }" *ngIf="isLoading || TiffError">
          {{ loadingMessage }}
        </h1> -->
        <div *ngIf="!TiffError">
          <div class="zoom-buttons" *ngIf="!isLoading">

            <a [matTooltip]="'Clear All Annotation'" class="ml-2 mt-2" (click)="clearAllAnnotation()"
                [class.disabled]="this.textAnnotations.length === 0 || disableAnnotation" href="javascript:void(0)">
                <mat-icon>clear</mat-icon>
                <!-- restore -->
              </a>

              <a [matTooltip]="'Rotate Clockwise'" class="ml-2 mt-2" (click)="rotateClockwise()"
                [class.disabled]="isLoading || disableAnnotation || true" href="javascript:void(0)">
                <mat-icon>rotate_left</mat-icon>
              </a>

              <a [matTooltip]="'Rotate Counter Clock Wise'" class="ml-2 mt-2" (click)="rotateCounterclockwise()"
                [class.disabled]="isLoading || disableAnnotation || true" href="javascript:void(0)">
                <mat-icon>rotate_right</mat-icon>
              </a>

              <a [matTooltip]="'Zoom In Page'" class="ml-2 mt-2" (click)="zoomIn()"
                [class.disabled]="isLoading || disableAnnotation || zoomLevel === MAX_ZOOM_LEVEL" href="javascript:void(0)">
                <mat-icon>add</mat-icon>
              </a>

              <a [matTooltip]="'Zoom Out Page'" class="ml-2 mt-2" (click)="zoomOut()"
                [class.disabled]="isLoading || disableAnnotation || zoomLevel === MIN_ZOOM_LEVEL" href="javascript:void(0)">
                <mat-icon>remove</mat-icon>
              </a>

              <a [matTooltip]="'Go to First Page'" class="ml-2 mt-2" (click)="firstImage()"
                [class.disabled]="isLoading || disableAnnotation || pageNumber === 1" href="javascript:void(0)">
                <mat-icon>first_page</mat-icon>
              </a>
              <a [matTooltip]="'Go to Previous Page'" class="ml-2 mt-2" (click)="previousImage()"
                [class.disabled]="isLoading || disableAnnotation || pageNumber === 1" href="javascript:void(0)">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </a>

              <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number"
                [(ngModel)]="pageNumber" min="1" max="{{totalPages}}" (focusout)="goToPage()"
                [readonly]="isLoading || disableAnnotation" />

              <span> of {{ totalPages }}</span>

              <a [matTooltip]="'Go to Next Page'" class="ml-2 mt-2" (click)="nextImage()"
                [class.disabled]="isLoading || disableAnnotation || pageNumber === existingDocumentImageList.length" href="javascript:void(0)">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </a>
              <a [matTooltip]="'Go to Last Page'" class="ml-2 mt-2" (click)="lastImage()"
                [class.disabled]="isLoading || disableAnnotation || pageNumber === existingDocumentImageList.length" href="javascript:void(0)">
                <mat-icon>last_page</mat-icon>
              </a>

              <a [matTooltip]="'Save Annotation'" class="ml-2 mt-2" (click)="saveAnnotation()"
                [class.disabled]="this.textAnnotations.length === 0 || disableAnnotation" href="javascript:void(0)">
                <mat-icon *ngIf="!disableAnnotation">save</mat-icon>

                <mat-icon *ngIf="disableAnnotation">
                  <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
                </mat-icon>
              </a>

              <mat-icon class="ml-4" style="cursor: pointer !important" [matTooltip]="'Open Annotation Option'"
                [matMenuTriggerFor]="settings">more_vert</mat-icon>

              <mat-menu #settings="matMenu">
                <button mat-menu-item (click)="annotationMode(tiffPointerMode.crosshair)"
                  [ngClass]="{'active-annotation-button': annotationModeFlag === tiffPointerMode.crosshair}">
                  <mat-icon>photo_size_select_small</mat-icon> Draw
                </button>
                <button [disabled]="true" mat-menu-item (click)="annotationMode(tiffPointerMode.text)"
                  [ngClass]="{'active-annotation-button': annotationModeFlag === tiffPointerMode.text}">
                  <mat-icon>text_rotation_none</mat-icon> Text
                </button>
                <!-- -->
                <button [disabled]="true"  mat-menu-item (click)="annotationMode(tiffPointerMode.grab)"
                  [ngClass]="{'active-annotation-button': annotationModeFlag === tiffPointerMode.grab}">
                  <mat-icon>colorize</mat-icon> Marker
                </button>
              </mat-menu>

          </div>
        </div>
      </div>

      <div [hidden]="isHideTiffViewer" class="card card-body align-items-center justify-content-center scrollable-card p-0 backgroundclr">
        <div class="scrollable-card-image" [hidden]="disableAnnotation" [ngClass]="annotationModeFlag === tiffPointerMode.grab ? 'circle_cursor' : ''"
        [ngClass]="annotationModeFlag === tiffPointerMode.help ? 'delete_cursor' : ''"
        [style.cursor]="annotationModeFlag === tiffPointerMode.grab ? '' : cursorStyle" >
        <!-- (contextmenu)="deleteText($event)" -->
          <canvas #canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMoveVVV($event)"
            (mouseup)="onMouseUpVVV($event)" (mouseleave)="onMouseLeave($event)"
            (click)="addText($event)" (dblclick)="deleteTextV4($event)"></canvas>
          <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
        </div>
      </div>

      <div *ngIf="isHideTiffViewer" class="pdf-container pb-4">
        <ng-container *ngIf="showPdfViwer">
          <ngx-extended-pdf-viewer [base64Src]="strPdfString" [height]="pdfheight" [showSidebarButton]="false" [textLayer]="true" [showPagingButtons]="true" [showOpenFileButton]="false"
            [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
            [showPropertiesButton]="false" [showBookmarkButton]="false" [zoom]="'page-width'"
            [enableDragAndDrop]="false" [showFindButton]="false">
          </ngx-extended-pdf-viewer>
        </ng-container>

        <ng-container *ngIf="!showPdfViwer">
          <div class="d-flex justify-content-center">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </ng-container>

      </div>


    </div>

    <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 p-0" [hidden]="isHideTiffViewer">
      <div class="card card-body align-items-center scrollable-card p-0" style="height: 80vh">
        <ng-container *ngFor="let image of existingDocumentImageList">
            <div class="card my-2 elevated-div custom-thumbnail-view">
                <img class="card-img-top img-fluid custom-thumbnail-image" (click)="loadExistingImage(image)"
                    [ngClass]="{
            'custom-thumbnail-view-selected':
              selectedExistingDocument?.pageNo === image?.pageNo
          }" [src]="image?.src" [alt]="image?.pageNo" />
            </div>
        </ng-container>
    </div>
    </div> -->
  </div>
</div>
<!-- <div [hidden]="lenlockDocListDTO !== 0 ">
  <div class="row p-2">
    <div class="col-lg-12">
      <a [routerLink]="'/inbound_sorting_pending'"  class="preview-link">
        <h3 class="customThemeClass">
          <b>A locked Inbound Document has not been found. <u>Please Click to go to Inbound Lock Page</u></b>
        </h3>
      </a>
    </div>
  </div>
</div> -->
</div>
<app-loader-component *ngIf="isOrderPageLoading"></app-loader-component>
