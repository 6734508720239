// import { subBatchDetails } from './../../../../../admin/src/app/sub-batch-list-temp/sub-batch-list-temp.component';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MMPayPalPaymentsService } from '../../order-proxy/order-management/order-optimization/mmpay-pal-payments.service';
import { ToastrService } from 'ngx-toastr';
import { PrintTicketDetails } from '../../order-proxy/order-management/order-optimization/dto';
import { DatePipe } from '@angular/common';
import { ShippingListService } from '../../order-proxy/order-management/order-optimization/shipping-list.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderHistoryModalComponent } from '../../order-history-modal/order-history-modal.component';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { OrderItemsModalExchangePickupComponent } from '../../order-items-modal-exchange-pickup/order-items-modal-exchange-pickup.component';

@Component({
  selector: 'app-order-search-tab-table',
  templateUrl: './order-search-tab-table.component.html',
  styleUrls: ['./order-search-tab-table.component.scss'],
})
export class OrderSearchTabTableComponent implements OnInit {
  @Input() orderTableData: any;
  @Input() pageType: any = 0;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Output() nevigatePage? = new EventEmitter<Number>();
  @Output() getVisibleColumns? = new EventEmitter<number[]>();
  @Input() visibleColumns: number[];
  defaultGuid = defaultGuid;
  tblSelectedRow: string = '';
  isOrderTableLoaded: boolean = false;
  printTicketData: PrintTicketDetails;
  orderTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Orders List',
        exportOptions: {
          // columns: [
          //   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          // ],
          columns: ':visible:not(:first-child)',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3),:eq(7) :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [15], visible: false }, //,16,17,18
      { targets: 0, orderable: false }, // Targets the first column (index 0)
      {
        targets: [4, 8, 9, 16, 18],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
      { targets: [0, 1, 2, 3, 7, -1], className: 'dt-fixed-column' }, // Ad
      // Add other columnDefs as needed
    ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  $subscription: Subscription[] = [];
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    //private mmOrderService: MmOrderService,
    private communicationService: CommunicationService,
    private mmPaypalInvoiceService: MMPayPalPaymentsService,
    private toaster: ToastrService,
    private datepipe: DatePipe,
    private mmOrderService: MmOrderService,
    private shippingListService: ShippingListService,
    public commonService: CommonService
  ) {}
  yourDate: Date;
  roleName: string;
  ngOnInit(): void {
    this.orderTableOptions.buttons[0].filename =
      'Orders List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.yourDate = new Date('0001-01-01T00:00:00+05:53');
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
    this.roleName = localStorage.getItem('roleName').toLocaleLowerCase() ?? '';
    console.log('roleName = ' + this.roleName);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    this.$subscription.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  viewPatientLedgerPopup(patientId, chartNo) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
        viewLedger: true,
        chartNo: chartNo,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  openAddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean,
    chartNo: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        chartNo: chartNo,
        docId: docId,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: false,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }
  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //To navigate to the Order Page
  navigateToOrderPage(patientId: string, orderId: string) {
    this.router.navigate([
      '/orders/saleOrder/' + patientId + '/' + orderId + '/' + this.pageType,
    ]);
  }
  navigateToStartNewOrder(patientId: string) {
    this.router.navigate(['/orders/saleOrder/' + patientId]);
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.55; // Adjust the percentage as needed
    this.orderTableOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  //To refresh the Datatable
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Page navigationControls
      this.addPageNavigationControls();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  //To Resend the Paypal Invoice Link to the Patient Mail
  resendPaypalInvoice(invoiceId: string, orderId: string) {
    this.mmPaypalInvoiceService.getInvoice(invoiceId, orderId).subscribe(
      (response) => {
        this.toaster.success('Invoice Resent Successfully!');
        this.communicationService.triggerLoadOrderList();
      },
      (err) => {
        this.toaster.error('Something Went Wrong!');
      }
    );
  }
  //To cancel the sent paypal invoice
  cancelPaypalInvoice(invoiceId: string) {
    this.mmPaypalInvoiceService.cancelSentInvoice(invoiceId).subscribe(
      (response) => {
        this.toaster.success('Invoice Cancelled Successfully!');
        this.communicationService.triggerLoadOrderList();
      },
      (err) => {
        this.toaster.error('Something Went Wrong!');
      }
    );
  }
  //Selected Order
  tblItemSelectedRow(orderId: string) {
    this.tblSelectedRow = orderId;
  }
  // orderId

  /* #region PRINT TICKET */
  //Method to call the Print Ticket Data Details
  getPrintTicketDetails(orderId) {
    const shippingTicketDetails = this.shippingListService
      .orderTicketDetailsV1ByOrderId(orderId)
      .subscribe(
        (response) => {
          this.printTicketData = response ?? null;
          this.getPrintTicket(this.printTicketData);
        },
        (err) => {}
      );

    this.$subscription.push(shippingTicketDetails);
  }
  getPrintTicket(printTicketData: PrintTicketDetails) {
    const tbodyStyle = {
      fontSize: 10,
      margin: [0, 2],
    };

    this.printTicketData.productDetailsDTO =
      this.printTicketData?.productDetailsDTO?.map((a) => ({
        ...a,
        style: tbodyStyle,
      }));

    let docDefinition = {
      /* #region HEADER */
      header: function (currentPage, pageCount, pageSize) {
        return {
          columns: [
            // {
            //   image: localStorage.getItem('LogoDataUrl'),
            //   width: 100, // Adjust the width as needed
            //   alignment: 'left', // Adjust the alignment as needed
            // },
            // {
            //   width: '10%',
            //   text:{

            //       text: '844498797',
            //       fontSize: 18,
            //       bold: true,
            //       alignment: 'center',
            //       color: '#000000',

            //   }
            // },
            {
              width: '80%',
              text: [
                { text: '\n' },
                {
                  // margin:[15,0,0,0],
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  color: '#000000',
                },
                {
                  text: '\t\t\t\t\t\t\t\t\t\tConfirm receipt of shipment online at www.monitormedical.com\n\t\t\t\t',
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  color: '#91ab5b',
                  margin: [0, 15, 0, 0],
                },
                {
                  text: '\t\t\t\t\t\tPATIENT CARE DELIVERY TICKET',
                  fontSize: 16,
                  bold: true,
                  alignment: 'center',
                  color: '#800000',
                },
              ],
              margin: [15, 0, 0, 0],
            },
            {
              image: localStorage.getItem('LogoDataUrl'),
              width: 100, // Adjust the width as needed
              // alignment: 'right', // Adjust the alignment as needed
              margin: [0, 10, 0, 0],
            },
          ],
        };
      },

      /* #endregion */

      /* #region FOOTER */
      footer: (currentPage, pageCount, pageSize) => {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 3,
              lineColor: '#800000',
            },
          ],
          columns: [
            {
              width: '70%',
              text: [
                {
                  text: 'Patient Signature:',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: '_____________________________________________________________________\n',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: 'Monitor Medical, Inc. \t',
                  fontSize: 16,
                  italics: true,
                  bold: true,
                },
                {
                  text: 'The C-PAP Co. \t',
                  fontSize: 12,
                  italics: true,
                },
                {
                  text: 'Toll Free: 866-634-0177 \n',
                  fontSize: 10,
                  italics: true,
                  bold: true,
                },
                {
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 14,
                  italics: true,
                  alignment: 'right',
                  bold: true,
                  color: '#000000',
                },
              ],
              // ,
              bold: true,
              color: '#800000',
            },
            {
              width: '30%',
              text: [
                // {
                //   text: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ \n\n',
                //   fontSize: 15,
                //   bold: true,
                //   italics: true,
                //   alignment: 'left',
                // },
                { text: 'Date:', fontSize: 10, italics: true },
                { text: '___________________________\n' },
                {
                  text:
                    this.datepipe.transform(
                      new Date(printTicketData?.orderDate),
                      'fullDate'
                    ) + '\n\n',
                  alignment: 'center',
                  fontSize: 8,
                  italics: true,
                  bold: false,
                },
                {
                  text:
                    printTicketData.shippingMethodName +
                    '\t' +
                    printTicketData.location,
                  fontSize: 8,
                  italics: true,
                  alignment: 'center',
                  color: '#000000',
                },
              ],
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#800000',
            },

            // {
            //   text:
            //     printTicketData.shippingMethodName +
            //     ' ' +
            //     printTicketData.location,
            //   fontSize: 8,
            //   italics: true,
            // },
          ],
          margin: [15, 0, 15, 0],
          alignment: 'left',
          // fontSize: 10,
        };
      },
      /*#endregion*/

      /* #region CONTENT */

      content: [
        /* #region Section 1 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          columns: [
            {
              width: '33%',
              text: 'Order No:\t' + printTicketData.mmRefOrderId ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'INS:\t' + printTicketData.insuranceName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'H:\t' + this.formatPhoneNumber(printTicketData?.homePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 10, 0, 3], // [left, top, right, bottom]
        },
        //Row 2
        {
          columns: [
            {
              width: '33%',
              text: 'Account No:\t' + printTicketData?.chartNo,
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'DOB:\t' +
                (printTicketData?.dateOfBirth != null
                  ? this.datepipe.transform(
                      printTicketData?.dateOfBirth,
                      'MM/dd/yyyy'
                    )
                  : ''),
              fontSize: 10,
              style: 'columnHeader',
            },
            {
              width: '33%',
              text:
                'W:\t' + this.formatPhoneNumber(printTicketData?.workPhone) ??
                '',
              fontSize: 10,
              style: 'columnHeader',
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Patient Name:\t' + printTicketData.patientName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'e-mail:\t' + (printTicketData?.emailId ?? ''),
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'M:\t' + this.formatPhoneNumber(printTicketData?.mobilePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 4
        {
          columns: [
            {
              width: '50%',
              text: 'Address:\t' + printTicketData?.fullAddress ?? '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 10],
        },

        /* #endregion*/
        /* #region Section 2 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          text:
            'Contact ' + printTicketData?.technicianName + ' at 281-637-2286',
          fontSize: 16,
          bold: true,
          alignment: 'center',
          color: '#800000',
          margin: [0, 5],
        },
        //Row 2
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        /* #endregion*/
        /* #region Section 3 */
        //Row 1
        {
          width: '33%',
          text: 'Pressure Settings',
          italics: true,
          fontSize: 8,
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        //Row 2
        {
          table: {
            headerRows: 1,
            widths: [
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
            ],
            body: [
              [
                {
                  text: 'Min Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Max Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Setting',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'IPAP', fontSize: 6, alignment: 'center', bold: true },
                { text: 'EPAP', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'Ramp Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Ramp Time',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'BiFlex',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Set Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'CFlex', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'RR/BPM',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Min',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Max',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'SetVt', fontSize: 6, alignment: 'center', bold: true },
                { text: 'Rate', fontSize: 6, alignment: 'center', bold: true },
              ],
              [
                {
                  text:
                    this.printTicketData?.settingsDetails?.minPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.maxPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.settlingTime ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.epap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.rampPressure ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.rampTime ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.biFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.setPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.cFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rrbpm ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmin ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmax ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.setVT ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rate ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Technician: ' + printTicketData?.technicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text: 'Physician: ' + printTicketData?.physicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text:
                'Referral Practice: ' + printTicketData?.referralPractice ?? '',
              fontSize: 8,
              alignment: 'center',
            },
          ],
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        /* #endregion */

        /*#region Section 4 */
        {
          table: {
            headerRows: 1,
            // widths: ['15%', '5%', '10%', '45%', '10%', '15%'],
            widths: ['10%', '12%', '44%', '10%', '9%', '15%'],
            body: [
              [
                // {
                //   text: 'Location',
                //   bold: true,
                //   fontSize: 10,
                //   color: '#800000',
                // },
                { text: 'Qty', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: 'Product Code',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Product Description',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'HCPC',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                // { text: 'Size', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: printTicketData?.isOrderComplete ? 'Allowable' : 'Exch',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Serial #/Lot #',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
              ],
              ...this.printTicketData?.productDetailsDTO?.map((item) => [
                // item.location,
                item.quantity,
                item.productCode,
                item.productName,
                item.hcpc,
                printTicketData?.isOrderComplete
                  ? item.allowables
                  : {
                      image:
                        'data:image/png;base64,' +
                        this.getSquareImageBase64(item.exchange),
                      width: 10,
                      height: 10,
                      alignment: 'center',
                    },
                // {
                //   image: 'data:image/png;base64,' + this.getSquareImageBase64(item.exchange),
                //   width: 10,
                //   height: 10,
                //   alignment: 'center',
                // }, // Insert square image
                item.serialLotNo,
              ]),
            ],
          },
          style: {
            fontSize: 10,
            margin: [0, 2],
          },
          margin: [0, 5, 0, 0],
        },
        /* #endregion */

        /* #region Section 5 */
        {
          columns: [
            {
              width: '60%',
              text: [
                {
                  text: '\nComments to Patients:\n',
                  color: '#800000',
                  fontSize: 12,
                },
                {
                  text: printTicketData?.commentsToPatient ?? '',
                  fontSize: 8,
                },
              ],
              fontSize: 14,
              bold: true,
              alignment: 'left',
            },

            {
              width: '40%',
              table: {
                headerRows: 1,
                widths: ['72%', '28%'],
                body: [
                  [
                    {
                      text: 'Est Allowable:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAllowable ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      border: [false, false, false, false],
                      alignment: 'left',
                    },
                  ],
                  [
                    {
                      text: 'Est Copay:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estCopay ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Total Accessory:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.totalAccessory ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Shipping Fee:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.shippingFee ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],

                  [
                    {
                      text: 'Est Amount Due:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAmountDue ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Amount Paid:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.amountPaid ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],
                  [
                    {
                      text: 'Balance:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.patientBalance ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 4 || i === 6 ? 1 : 0; // Thickness of the dotted line for the second row bottom border
                },
                hLineColor: function (i, node) {
                  return '#800000';
                },
                hLineStyle: function (i, node) {
                  if (i === 4 || i === 6) {
                    return { dash: { length: 5, space: 5 } }; // Dotted style for the second row bottom border
                  }
                  return null;
                },
              },
            },
          ],
        },
        /* #endregion*/
      ],
      /* #endregion */

      // pageMargins: [15, 50, 15, 40],
      pageMargins: [15, 60, 15, 60],
      defaultStyle: {
        font: 'Roboto',
      },
    };

    this.displayPdf(docDefinition, 'Ticket');
  }
  private getSquareImageBase64(excahngeItem): string {
    if (excahngeItem === true) {
      // Convert the image to Base64 format (using a base64 string or FileReader)
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');

      // Draw outer border with light sea green color
      ctx.fillStyle = '#000000'; // Light Sea Green color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with light sea green

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas

      // Draw check mark (using a simple path)
      ctx.strokeStyle = '#20B2AA'; // Black color
      ctx.beginPath();
      ctx.moveTo(2, 4);
      ctx.lineTo(4, 6);
      ctx.lineTo(8, 2);
      ctx.stroke();

      return canvas.toDataURL('image/png').split(',')[1];
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#000000'; // Border color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // Square color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
      return canvas.toDataURL('image/png').split(',')[1];
    }
  }

  //Method to display the PDF Content
  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition); //.download(filename);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }
  private formatPhoneNumber(value: string): string {
    if (value === null) return '';
    const phoneNumberRegex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberRegex.exec(value);

    if (!matches) return value;

    // Format the phone number with hyphens
    const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
    return formattedNumber;
  }
  /* #endregion */

  /* #region Menu Methods */

  /* #region Cancel Order */
  orderCancel(orderId: string, orderStatusNo: any) {
    this.isOrderTableLoaded = true;
    this.mmOrderService
      .orderCancelByOrderIdAndStatus(orderId, orderStatusNo)
      .subscribe(
        (response) => {
          this.mmOrderService.getSingleOrderByGOrderId(orderId).subscribe(
            (response) => {
              this.isOrderTableLoaded = false;
              const index = this.orderTableData.findIndex(
                (obj) => obj.id === response?.id
              );
              if (index !== -1) {
                this.orderTableData[index] = response; //Replace the Object
                const objectToMove = this.orderTableData.splice(index, 1)[0]; // Remove and get the object
                this.orderTableData.unshift(objectToMove);
              }
              this.communicationService.triggerReloadOrderTable();
              this.toaster.success('Order Cancelled!');
              this.tblItemSelectedRow(orderId);
            },
            (err) => {
              this.isOrderTableLoaded = false;
              const data: HttpErrorResponse = err;
              this.toaster.error(data?.error?.error?.message);
            }
          );
        },
        (err) => {
          this.isOrderTableLoaded = false;
          const data: HttpErrorResponse = err;
          this.toaster.error(data?.error?.error?.message);
        }
      );
  }
  /* #endregion */

  //Copy Order - To Redirect to the Sale Order Page with Copy Order Params
  copyOrder(patientId: string, orderId: string) {
    this.router.navigate([
      '/orders/saleOrder/' +
        patientId +
        '/' +
        orderId +
        '/' +
        true +
        '/' +
        this.pageType,
    ]);
  }
  getFormattedDate(date: string): string {
    if (!date) return null;

    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    return `${month}/${day}/${year}`;
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  /* #endregion */

  isDefaultDate(data): boolean {
    const suppliesDate = this.datepipe.transform(data, 'MM/dd/yyyy');
    const defaultDate = this.datepipe.transform(this.yourDate, 'MM/dd/yyyy');
    return suppliesDate === defaultDate;
  }
  //To Open the Order History Popup
  getOrderHistory(
    orderId: string,
    mmOrderId: string,
    chartId: string,
    patientName: string
  ) {
    const config: MatDialogConfig = {
      height: '70vh',
      width: '40vw',
      disableClose: true,
      data: {
        orderId: orderId,
        mmOrderId: mmOrderId,
        patientName: patientName,
        chartId: chartId,
      },
    };
    const dialogRef = this.dialog.open(OrderHistoryModalComponent, config);
  }

  addPageNavigationControls() {
    let buttonsElement = $('.dt-buttons')[0];

    $('<span> : <span>').appendTo(buttonsElement);
    console.log(
      '$addPageNavigationControls : current page = {currentPage}' +
        this.currentPage
    );
    if (this.currentPage == 1) {
      $(
        "<button  disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button  disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }

    $('<span> Page <span>').appendTo(buttonsElement);
    $(
      "<input style='width:50px;' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text'/>"
    ).appendTo(buttonsElement);
    $('<span> of ' + this.totalPages + ' <span>').appendTo(buttonsElement);
    $(
      "<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button  disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button  disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) =>
      this.nevigatePage.emit(this.totalPages)
    );
  }

  changePage(isNext: boolean) {
    console.log('Child changepage called....');
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }

  fnArchiveOrder(order: any) {
    console.log('fnArchiveOrder called....');
    console.log(order);
    Swal.fire({
      title:
        'Are you sure you want to Archive Order - ' +
        (order?.mmRefereceOrderId || '-') +
        '? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.mmOrderService.deleteOrderByGOrderId(order.id).subscribe(
          (response) => {
            this.toaster.success('Order Archived Successfully!');
            this.communicationService.triggerLoadOrderList();
          },
          (err: HttpErrorResponse) => {
            const data: HttpErrorResponse = err;
            this.toaster.error(data?.error?.error?.message);
            this.communicationService.triggerLoadOrderList();
          }
        );
      }
    });
  }

  openOrderItemsForPickup(orderId: string, mmOrderId: string,exchangeOrReturn:string,patientId:string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '60vw',
      maxWidth: '60vw',
      // height: '40vw',
      // maxHeight: '40vw',
      data: {
        orderId: orderId,
        mmOrderId: mmOrderId,
        exchangeOrReturn:exchangeOrReturn,
        patientId:patientId
      },
    };
    const dialogRef = this.dialog.open(
      OrderItemsModalExchangePickupComponent,
      config
    );

    dialogRef.afterClosed().subscribe(
      (isPickupCompleted) => {
        if (isPickupCompleted) {
          this.communicationService.triggerLoadOrderList();
        }
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
