<mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-lg-6">
        <h2>NPI Details</h2>
      </div>
      <!-- <div class="col-lg-6 text-right">
        <button class="text-right close" aria-label="Close" mat-dialog-close><b> X </b></button>
      </div> -->
    </div>
  
    <div class="row">
      <div class="col-lg-12">
       
      </div>
  
      <div class="col-lg-12">
        <div class="table-responsive mb-0">
            <!-- <app-table [strPageType]="strPageType" [isLoading]="isLoading" (viewButton)="npiDeatils()"></app-table> -->

            <table >
                <tr >
                  <td class="mt-2"><b>Name</b></td>
                  <!-- <td>:</td> -->
                  <td>
                    <b>: {{name}} </b>
                  </td> <br>
                    </tr>
                    <tr> <td class="mt-2" ><b>NPI No</b></td>
                        <!--<td><b>:</b></td> -->
                        <td >
                          <b>: {{value}}</b>
                        </td> <br>
                    </tr>
                  <tr>
                    <td class="mt-2"><b>NPI Type</b></td>
                  <td>
                    <b>: {{npiType}}</b>
                  </td><br>
                  </tr>
                 
                  <tr><td class="mt-2"><b>Gender</b></td>
                    <td>
                      <b>: {{gender}}</b>
                    </td><br></tr>
                  
                  <tr>
                    <td class="mt-2"><b>Mailing Address</b></td>
                    <td>
                      <b>: {{mailAddress}}</b>
                    </td><br></tr>
                
          
               
              </table >
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
  
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
