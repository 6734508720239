import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-UserNewComponent',
  templateUrl: './UserNewComponent.component.html',
  styleUrls: ['./UserNewComponent.component.scss']
})
export class UserNewComponentComponent implements OnInit {
  @Input() clientId: string = '';
  constructor(
    private title : Title
  )  {

  }

  ngOnInit() {
    this.title.setTitle('Qsecure | User');
  }


}
