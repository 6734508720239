import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { MmOrderReturnExchangeReasonService } from '../order-proxy/order-management/order-optimization/mm-order-return-exchange-reason.service';
import { Subscription } from 'rxjs';
import {
  CreateUpdateMmOrderItemReturnDTO,
  MmOrderReturnExchangeReasonDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MmOrderItemReturnService } from '../order-proxy/order-management/order-optimization/mm-order-item-return.service';
import { ToastrService } from 'ngx-toastr';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';

@Component({
  selector: 'app-sale-order-return-modal',
  templateUrl: './sale-order-return-modal.component.html',
  styleUrls: ['./sale-order-return-modal.component.scss'],
})
export class SaleOrderReturnModalComponent implements OnInit {
  returnForm: FormGroup;
  ltReturnReasons: MmOrderReturnExchangeReasonDTO[] = [];
  descriptionNotes: string = '';
  isOrderPageLoading:any;
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialogRef<SaleOrderReturnModalComponent>,
    private orderReturnExchangeReasonService: MmOrderReturnExchangeReasonService,
    private mmorderItemReturnService: MmOrderItemReturnService,
    private communicationService: CommunicationService,
    private toaster: ToastrService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      patientId: string;
      orderDto?: any;
    } = {
      orderId: defaultGuid,
      patientId: defaultGuid,
      orderDto: null,
    }
  ) {}

  ngOnInit() {
    this.initializeForms();
    this.loadDropdowns();
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
  }
  initializeForms() {
    this.returnForm = this.fb.group({
      drpReturnReason: new FormControl('', [Validators.required]),
      txtDescription: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
    });
  }
  //To load the dropdowns in the page
  loadDropdowns() {
    const reasonList = this.orderReturnExchangeReasonService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {

          this.ltReturnReasons = response?.items ?? [];
        },
        (err) => {}
      );
    this.subscription$.push(reasonList);
  }
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.descriptionNotes?.length > maxlength) {
      this.descriptionNotes = this.descriptionNotes?.substr(0, maxlength);
    }
  }
  resetForm() {
    this.returnForm.reset();
  }
  //To save the Return Order
  saveReturnOrder() {
    this.loaderService.showLoader();
    let returnOrderData: CreateUpdateMmOrderItemReturnDTO[] = [];

    let orderItems = this.data?.orderDto?.orderItems.filter(
      (a) => a.return == true
    );
    orderItems.forEach((element) => {
      let data: CreateUpdateMmOrderItemReturnDTO = {
        orderId: this.data.orderId,
        orderItemId: element?.mmOrderItemId,
        returnReasonId: this.returnForm.value.drpReturnReason,
        reasonDescription: this.returnForm.value.txtDescription,
        status: Status.Active,
      };

      returnOrderData.push(data);
    });

    this.mmorderItemReturnService
      .createMultipleMmOrderItemReturn(this.data.orderId, returnOrderData)
      .subscribe(
        (response) => {

          this.loaderService.hideLoader();
          this.toaster.success('Return Order Created Successfully!');
          this.communicationService.triggerLoadOrderDetails({
            patientId: response?.patientId ?? defaultGuid,
            orderId: response?.id ?? defaultGuid,
          });
          this.dialog.close();
        },
        (err) => {
          this.loaderService.hideLoader();
          this.toaster.error('Something Went Wrong!');
        }
      );
  }
}
