import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
// import type { Saleorder } from '../../order/entity/models';

@Injectable({
  providedIn: 'root',
})
export class FedExService {
  apiName = 'orderManagement';

  // createShipment = (orderData: Saleorder) =>
  //   this.restService.request<any, any<string,string>>({
  //     method: 'POST',
  //     url: '/api/orderApp/fed-ex/create-shipment',
  //     body: orderData,
  //   },
  //    { apiName: this.apiName, skipHandleError: true });

  getShippingLabelDocuments = (orderId: string) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      // responseType: 'text',
      url: `/api/orderApp/fed-ex/get-shipping-label-documents/${orderId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
