<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2 mat-dialog-title>{{ itemPaymentTitle }}</h2>
    </div>
  </div>
  <mat-card-content>
    <form [formGroup]="itemPaymentForm">
      <div class="row">
        <div class="col-3">
          <input id="file-upload" (change)="eftImportFormFile(uploadFileReference?.files)" [multiple]="true"
            #uploadFileReference type="file" />
          <button *ngIf="selFileNameList?.length === 0" matTooltip="Browse Files" (click)="uploadFileReference?.click()"
            mat-button class="buttonColor font-size-14 pl-2">
            <i class="fa fa-file-import pl-2 pr-2"></i> Select Remits
          </button>
          <button *ngIf="selFileNameList?.length !== 0" matTooltip="Browse Files" (click)="uploadFileReference?.click()"
            mat-button class="resetclr font-size-14 pl-2">
            <i class="fa fa-file-import pl-2 pr-2"></i> Change
          </button>
          <mat-error *ngIf="selFileNameList?.length === 0">
            EOB PDF Reqiured
          </mat-error>
        </div>
        <mat-form-field class="col-3">
          <mat-label>Check No<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtCheckNo" matInput formControlName="txtCheckNo" type="text" maxlength="40" />
          <mat-error *ngIf="
              itemPaymentForm?.get('txtCheckNo')?.touched &&
              itemPaymentForm?.get('txtCheckNo')?.errors?.number
            ">
            Enter only Numbers!
          </mat-error>
          <mat-error *ngIf="
              itemPaymentForm?.get('txtCheckNo')?.touched &&
              itemPaymentForm?.get('txtCheckNo')?.errors?.required
            ">
            Check No is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Check Date<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtCheckDate" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtCheckDate"
            [matDatepicker]="myPicker1" />
          <mat-datepicker-toggle matSuffix [for]="myPicker1"></mat-datepicker-toggle>
          <mat-datepicker #myPicker1></mat-datepicker>
          <mat-error *ngIf="
              itemPaymentForm?.get('txtCheckDate')?.touched &&
              itemPaymentForm?.get('txtCheckDate')?.errors
            ">
            Enter Valid Date!
          </mat-error>
          <mat-error *ngIf="
              itemPaymentForm?.get('txtCheckDate')?.touched &&
              itemPaymentForm?.get('txtCheckDate')?.errors?.required
            ">
            Check Date is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Plan</mat-label>
          <input autocomplete="new-txtPlanType" matInput formControlName="txtPlanType" type="text" maxlength="40" />
          <mat-error *ngIf="
              itemPaymentForm?.get('txtPlanType')?.touched &&
              itemPaymentForm?.get('txtPlanType')?.errors?.required
            ">
            Plan is a required field!
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-6">
          <mat-label>Item Id / HCPC Code<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpItem" #singleSelect>
            <mat-option *ngFor="let code of drpItems" [value]="code?.itemProcedureCode">
              {{ code?.itemProcedureCode }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
        itemPaymentForm?.get('drpItem')?.touched &&
        itemPaymentForm?.get('drpItem')?.errors?.required
      ">
            Item Id is a required field!
          </mat-error>
          <button (click)="clearItem()" mat-button *ngIf="itemPaymentForm?.value?.drpItem" matSuffix mat-icon-button
            >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Reason Code</mat-label>
          <input autocomplete="new-txtReasonCode" matInput formControlName="txtReasonCode" type="text" maxlength="40" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> Billed Amount <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtBilled" matInput formControlName="txtBilled" type="text" maxlength="40" />
          <mat-error *ngIf="
        itemPaymentForm?.get('txtBilled')?.touched &&
        itemPaymentForm?.get('txtBilled')?.errors?.number
      ">
            Enter only Numbers!
          </mat-error>
          <mat-error *ngIf="
        itemPaymentForm?.get('txtBilled')?.touched &&
        itemPaymentForm?.get('txtBilled')?.errors?.required
      ">
            Billed Amount is a required field!
          </mat-error>
        </mat-form-field>

      </div>
      <div class="row">
        <mat-form-field class="col-3">
          <mat-label>Allowed</mat-label>
          <input autocomplete="new-txtAllowed" matInput formControlName="txtAllowed" type="text" maxlength="40" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Paid</mat-label>
          <input autocomplete="new-txtPaid" matInput formControlName="txtPaid" type="text" maxlength="40" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Adjustment</mat-label>
          <input autocomplete="new-txtAdjustment" matInput formControlName="txtAdjustment" type="text" maxlength="40" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Deduct</mat-label>
          <input autocomplete="new-txtDeductAmount" matInput formControlName="txtDeductAmount" type="text" maxlength="40" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Co-Ins</mat-label>
          <input autocomplete="new-txtCoInsurance" matInput formControlName="txtCoInsurance" type="text" maxlength="40" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Current Balalnce</mat-label>
          <input autocomplete="new-txtCurrentBalalnce" matInput formControlName="txtCurrentBalalnce" type="text" maxlength="40" />

        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Check Amount<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtCheckAmount" matInput formControlName="txtCheckAmount" type="text" maxlength="40" />
          <mat-error *ngIf="
          itemPaymentForm?.get('txtCheckAmount')?.touched &&
          itemPaymentForm?.get('txtCheckAmount')?.errors?.required
        ">
        Check Amount is a required field!
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Posting Date<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtPostingDate" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtPostingDate"
            [matDatepicker]="myPicker2" />
          <mat-datepicker-toggle matSuffix [for]="myPicker2"></mat-datepicker-toggle>
          <mat-datepicker #myPicker2></mat-datepicker>
          <mat-error *ngIf="
              itemPaymentForm?.get('txtPostingDate')?.touched &&
              itemPaymentForm?.get('txtPostingDate')?.errors
            ">
            Enter Valid Date!
          </mat-error>
          <mat-error *ngIf="
              itemPaymentForm?.get('txtPostingDate')?.touched &&
              itemPaymentForm?.get('txtPostingDate')?.errors?.required
            ">
            Posting Date is a required field!
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonColor" [disabled]="itemPaymentForm?.invalid || selFileNameList?.length===0 || saveButtonHide"
    (click)="saveItemPayment()" mat-button>
    Save
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
