import type { CreateUpdateItemEligibilityRulesDTO, ItemEligibilityRulesDTO, RulesValidityDTO, UploadItemsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { itemRulesStatusType } from '../order/item-rules-status-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ItemEligibilityRulesService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateItemEligibilityRulesDTO) =>
    this.restService.request<any, ItemEligibilityRulesDTO>({
      method: 'POST',
      url: '/api/orderApp/item-eligibility-rules',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/item-eligibility-rules/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, ItemEligibilityRulesDTO>({
      method: 'GET',
      url: `/api/orderApp/item-eligibility-rules/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ItemEligibilityRulesDTO>>({
      method: 'GET',
      url: '/api/orderApp/item-eligibility-rules',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateItemEligibilityRulesDTO) =>
    this.restService.request<any, ItemEligibilityRulesDTO>({
      method: 'PUT',
      url: `/api/orderApp/item-eligibility-rules/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteItemRules = (id: string, ruleId: string) =>
    this.restService.request<any, ItemEligibilityRulesDTO>({
      method: 'DELETE',
      url: `/api/orderApp/item-eligibility-rules/${id}/delete-item-rules/${ruleId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  downloadItemTemplate = () =>
    this.restService.request<any, string>({
      method: 'GET',
      // responseType: 'text',
      url: '/api/orderApp/item-eligibility-rules/download-item-template',
    },
     { apiName: this.apiName, skipHandleError: true });

  getItemRules = (id: string, itemRulesStatusType: itemRulesStatusType) =>
    this.restService.request<any, PagedResultDto<RulesValidityDTO>>({
      method: 'GET',
      url: `/api/orderApp/item-eligibility-rules/${id}/get-item-rules`,
      params: { itemRulesStatusType },
    },
     { apiName: this.apiName, skipHandleError: true });

  importItems = (input: UploadItemsDTO) =>
    this.restService.request<any, PagedResultDto<ItemEligibilityRulesDTO>>({
      method: 'POST',
      url: '/api/orderApp/item-eligibility-rules/import-items',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchItemBySItemCodeAndSItemName = (sItemCode: string, sItemName: string) =>
    this.restService.request<any, PagedResultDto<ItemEligibilityRulesDTO>>({
      method: 'GET',
      url: '/api/orderApp/item-eligibility-rules/search-item',
      params: { sItemCode, sItemName },
    },
     { apiName: this.apiName, skipHandleError: true });

  searchItemRulesByIdAndSInsuranceNameAndIEligibleQuantityAndIEligibleDaysAndSStatus = (id: string, sInsuranceName: string, iEligibleQuantity: number, iEligibleDays: number, sStatus: string) =>
    this.restService.request<any, PagedResultDto<RulesValidityDTO>>({
      method: 'GET',
      url: `/api/orderApp/item-eligibility-rules/${id}/search-item-rules`,
      params: { sInsuranceName, iEligibleQuantity, iEligibleDays, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
