import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  ProductCategoryService,
  Status,
} from '../item-proxy/item-management/items';
import {
  CreateUpdateProductCategoryDetailsDTO,
  ProductCategoryDetailsDTO,
} from '../item-proxy/item-management/optimization/dto';
import { ProductCategoryDetailsService } from '../item-proxy/item-management/optimization';
import { Title } from '@angular/platform-browser';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import {
  dtInboundOptions,
  dtProductTableOptions,
} from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import * as XLSX from 'xlsx';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-product-category-tab',
  templateUrl: './product-category-tab.component.html',
  styleUrls: ['./product-category-tab.component.scss'],
})
export class ProductCategoryTabComponent implements OnInit {
  productCategoryForm: FormGroup;

  dataSource: any;
  categoryId: string = '';
  tableData: any[];
  categoryTableData: any;
  isChecked: boolean = false;
  isShowSettingsChecked: boolean = false;
  isShowMinPressureChecked: boolean = false;
  loadTable: boolean = true;
  step: boolean = false;
  isShowSpinner: boolean = false;
  saveButtonHide: boolean = false;
  saveButtonDisabled: boolean = false;
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  dtProductTableOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '400px',
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Product Category', // Change the text to 'Export'
        exportOptions: {
          // columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          columns: ':visible:not(:first-child) ',
        },
      },
    ],
  };
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'options',
    'categoryName',
    'status',
    'createdDate',
  ];
  subscription$: Subscription[] = [];
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private productCategoryService: ProductCategoryDetailsService,
    public dateValidators: DateValidator,
    public title: Title,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private commonService: CommonService
  ) {
    // const comService1 =
    //   this.communicationService.insuranceAllowableToggleMethodCall$.subscribe(
    //     () => {
    //       this.toggleV2();
    //     }
    //   );
    // this.subscription$.push(comService1);
    const comService2 =
      this.communicationService.productCategoryGetMethodCall$.subscribe(
        (categoryId) => {
          this.toggleV2();
          this.getCategoryDetailsById(categoryId);
        }
      );
    this.subscription$.push(comService2);

    const comService3 =
      this.communicationService.functionReloadProductTable$.subscribe(() => {
        this.reloadTable();
      });
    this.subscription$.push(comService3);
    // const comService3 = this.communicationService.statusMethodCall$
    //   .pipe(map((data: any) => [data.event, data.productId]))
    //   .subscribe(([event, productId]) => {
    //     this.statusChange(event, productId);
    //     // Use statusId and param2 here
    //   });
    // this.subscription$.push(comService3);
  }

  ngOnInit(): void {

    this.dtProductTableOptions.buttons[0].filename =
    'Product Category' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.step = false;
    this.title.setTitle('Qsecure | Product Category');
    this.initializeForms();
    this.getCategoryListV2();
  }
  initializeForms() {
    this.productCategoryForm = this.formBuilder.group({
      txtProductCategoryName: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtMachinePoints: new FormControl(''),
      txtPointMultiplier: new FormControl(''),
      txtDefaultPoints: new FormControl(''),
      txtPhysicalInv_WeekOfMonth: new FormControl(''),
      txtPhysicalInv_DayOfWeek: new FormControl(''),
      txtLow: new FormControl(''),
      txtHigh: new FormControl(''),
      txtMid: new FormControl(''),
      chkAccessories: new FormControl('', [Validators.required]),
      chkShowSettings: new FormControl('', [Validators.required]),
      chkShowMinPressure: new FormControl('', [Validators.required]),
      chkShowMaxPressure: new FormControl(''),
      chkShowSettlingTime: new FormControl(''),
      chkShowIPAP: new FormControl(''),
      chkShowEPAP: new FormControl(''),
      chkShowRampPressure: new FormControl(''),
      chkShowRampTime: new FormControl(''),
      chkShowBiFlex: new FormControl(''),
      chkShowSetPressure: new FormControl(''),
      chkShowRRBPM: new FormControl(''),
      chkShowCFlex: new FormControl(''),
      chkShowIPAPminM: new FormControl(''),
      chkShowIPAPmax: new FormControl(''),
      chkShowEEP: new FormControl(''),
      chkShowSetVT: new FormControl(''),
      chkShowRate: new FormControl(''),
      cashRxRequired: new FormControl(''),
      chkSerialLotNo: new FormControl(''),
    });
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  //Save & Update Product Category
  saveProductCategory() {
    this.loadTable = false;
    const categoryForm = this.productCategoryForm.value;
    let productCategoryDetails: CreateUpdateProductCategoryDetailsDTO = {
      productCategoryName:
        this.productCategoryForm?.value?.txtProductCategoryName?.trim(),
      status: Status.Active,
      mmRefferenceId: 0,
      accessories: categoryForm.chkAccessories == true ? 1 : 0,
      showSettings: categoryForm.chkShowSettings == true ? 1 : 0,
      ddOrder: 0,
      showMinPressure: categoryForm.chkShowMinPressure == true ? 1 : 0,
      showMaxPressure: categoryForm.chkShowMaxPressure == true ? 1 : 0,
      showSettlingTime: categoryForm.chkShowSettlingTime == true ? 1 : 0,
      showIPAP: categoryForm.chkShowIPAP == true ? 1 : 0,
      showEPAP: categoryForm.chkShowEPAP == true ? 1 : 0,
      showRampPressure: categoryForm.chkShowRampPressure == true ? 1 : 0,
      showRampTime: categoryForm.chkShowRampTime == true ? 1 : 0,
      showBiFlex: categoryForm.chkShowBiFlex == true ? 1 : 0,
      showSetPressure: categoryForm.chkShowSetPressure == true ? 1 : 0,
      showCFlex: categoryForm.chkShowCFlex == true ? 1 : 0,
      showRRBPM: categoryForm.chkShowRRBPM == true ? 1 : 0,
      showIPAPmin: categoryForm.chkShowIPAPminM == true ? 1 : 0,
      showIPAPmax: categoryForm.chkShowIPAPmax == true ? 1 : 0,
      showEEP: categoryForm.chkShowEEP == true ? 1 : 0,
      showSetVT: categoryForm.chkShowSetVT == true ? 1 : 0,
      showRate: categoryForm.chkShowRate == true ? 1 : 0,
      machinePoints: this.productCategoryForm.value.txtMachinePoints,
      pointMultiplier: this.productCategoryForm.value.txtPointMultiplier,
      defaultPoints: this.productCategoryForm.value.txtDefaultPoints,
      cashRxRequired:
        this.productCategoryForm.value.cashRxRequired == true ? '1' : '0',
      serialLotNoRequired:
        this.productCategoryForm.value.chkSerialLotNo == true ? '1' : '0',
      physicalInvWeekOfMonth:
        this.productCategoryForm.value.txtPhysicalInv_WeekOfMonth,
      physicalInvDayOfWeek:
        this.productCategoryForm.value.txtPhysicalInv_DayOfWeek,
      low: this.productCategoryForm.value.txtLow,
      high: this.productCategoryForm.value.txtHigh,
      mid: this.productCategoryForm.value.txtMid,
      updatedDate: null,
    };
    if (
      this.categoryId === '' ||
      this.categoryId === undefined ||
      this.categoryId === null
    ) {
      if (!this.saveButtonDisabled) {
        this.productCategoryService.create(productCategoryDetails).subscribe(
          (response) => {
            this.loadTable = true;
   
            const userTimezoneCreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
            const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
            // Update the response with the local time zone creation time
            response.creationTime = userTimezoneCreationTime;
            response.lastModificationTime = userTimezonelastModificationTime;

            this.categoryTableData.unshift(response);
            this.categoryId = '';
            localStorage.removeItem('checkNewTabData');
            localStorage.setItem('checkNewTabData', 'NewServiceDrpDataSaved');
            this.toastr.success('Saved Successfully', 'Success');
            this.resetForm();
            setTimeout(() => {
              this.saveButtonDisabled = false;
            }, 2000);
            // this.loadTable = true;
          },
          (err) => {
            this.loadTable = true;
            const data: HttpErrorResponse = err;
            this.toastr.info(data?.error?.error?.message, 'Error');
          }
        );
      }
    } else {
      this.productCategoryService
        .update(this.categoryId, productCategoryDetails)
        .subscribe(
          (response) => {
            localStorage.removeItem('checkNewTabData');
            localStorage.setItem('checkNewTabData', 'NewServiceDrpDataSaved');
            this.toastr.success('Updated Successfully', 'Success');
            this.loadTable = true;
            const index = this.categoryTableData.findIndex(
              (obj) => obj.id === response?.id
            );
            if (index !== -1) {
              this.categoryTableData[index] = response; //Replace the Object
              const objectToMove = this.categoryTableData.splice(index, 1)[0]; // Remove and get the object
              const userTimezoneCreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
              const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
              // Update the response with the local time zone creation time
              response.creationTime = userTimezoneCreationTime;
              response.lastModificationTime = userTimezonelastModificationTime;
              this.categoryTableData.unshift(objectToMove);
            }
            this.resetForm();
            // this.loadTable = true;
          },
          (err) => {
            this.loadTable = true
            const data: HttpErrorResponse = err;
            this.toastr.info(data?.error?.error?.message, 'Error');
          }
        );
    }
    // this.categoryId = '';
  }

  getCategoryListV2() {
    this.loadTable = false;
    this.productCategoryService.getListV2().subscribe(
      (response) => {
        this.loadTable = true;

        this.categoryTableData = response;

        this.categoryTableData = this.categoryTableData.map((inbound) => {
          return {
            ...inbound,
            creationTime: this.commonService.getFormattedDateTimeZone(
              inbound.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              inbound.lastModificationTime
            ),
          };
        });
      },
      (err) => {
        this.loadTable = true;
      }
    );
  }
  getCategoryDetailsById(categoryId: string) {
    // if (this.step == false) {
    this.step = true;
    // }

    if (categoryId) {
      const ProductBrandGet = this.productCategoryService
        .get(categoryId)
        .subscribe((response) => {
          this.productCategoryForm.patchValue({
            txtProductCategoryName: response?.productCategoryName ?? '',
            txtMachinePoints: response?.machinePoints ?? '',
            txtPointMultiplier: response?.pointMultiplier ?? '',
            txtDefaultPoints: response?.defaultPoints ?? '',
            txtPhysicalInv_WeekOfMonth: response?.physicalInvWeekOfMonth ?? '',
            txtPhysicalInv_DayOfWeek: response?.physicalInvDayOfWeek ?? '',
            txtLow: response?.low ?? '',
            txtHigh: response?.high ?? '',
            txtMid: response?.mid ?? '',
            chkAccessories: response.accessories == 1 ? true : false,
            chkShowSettings: response?.showSettings == 1 ? true : false,
            chkShowMinPressure: response?.showMinPressure == 1 ? true : false,
            chkShowMaxPressure: response?.showMaxPressure == 1 ? true : false,
            chkShowSettlingTime: response?.showSettlingTime == 1 ? true : false,
            chkShowIPAP: response?.showIPAP == 1 ? true : false,
            chkShowEPAP: response?.showEPAP == 1 ? true : false,
            chkShowRampPressure: response?.showRampPressure == 1 ? true : false,
            chkShowRampTime: response?.showRampTime == 1 ? true : false,
            chkShowBiFlex: response?.showBiFlex == 1 ? true : false,
            chkShowSetPressure: response?.showSetPressure == 1 ? true : false,
            chkShowCFlex: response?.showCFlex == 1 ? true : false,
            chkShowRRBPM: response?.showRRBPM == 1 ? true : false,
            chkShowIPAPminM: response?.showIPAPmin == 1 ? true : false,
            chkShowIPAPmax: response?.showIPAPmax == 1 ? true : false,
            chkShowEEP: response?.showEEP == 1 ? true : false,
            chkShowSetVT: response?.showSetVT == 1 ? true : false,
            chkShowRate: response?.showRate == 1 ? true : false,
            cashRxRequired: response?.cashRxRequired == '1' ? true : false,
            chkSerialLotNo: response?.serialLotNoRequired == '1' ? true : false,
          });
          this.categoryId = response?.id ?? '';
          this.step = true;
        });
      this.subscription$.push(ProductBrandGet);
    }
  }

  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  //Delete Product Category Details by id
  deleteCategory(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        if (value) {
          const prodBandDelete = this.productCategoryService
            .delete(value)
            .subscribe(
              (resposne) => {
                this.resetForm();
                // this.getCategoryList();
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          this.subscription$.push(prodBandDelete);
        }
      }
    });
  }
  //Reset Form
  resetForm() {
    this.productCategoryForm?.reset({
      txtProductCategoryName: '',
    });
    this.categoryId = '';
    this.productCategoryForm.markAsUntouched();
    this.productCategoryForm.updateValueAndValidity();
    this.clearValidationErrors();
  }
  reloadTable() {
    this.getCategoryListV2();
    // this.loadTable = false;
    // setTimeout(() => {
    //   this.loadTable = true;
    // }, 100);
  }
  // Clear validation errors without triggering validation.
  clearValidationErrors() {
    Object.keys(this.productCategoryForm.controls).forEach((key) => {
      this.productCategoryForm.get(key).setErrors(null);
    });
  }
  exportToExcel(): void {
    // Filter out any unwanted properties or modify data as needed
    const dataToExport = this.dataSource.map(
      ({ productCategoryName, creationTime, status }) => ({
        productCategoryName,
        creationTime,
        status,
      })
    );

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'CategoryList');

    // Save the file
    XLSX.writeFile(wb, 'CategoryList.xlsx');
  }
}
