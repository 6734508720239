import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InventoryItemComponent } from './inventory-item/inventory-item.component';
import { InventoryNotesComponent } from './inventory-notes/inventory-notes.component';
import { NumericDirectiveDirective } from './numeric-directive.directive';


const routes: Routes = [
 { path: '',
    children: [
      {
        path: 'notes',
        component: InventoryNotesComponent
      },
      {
        path: 'items',
        component: InventoryItemComponent
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{enableTracing: true})],
  exports: [RouterModule],
   declarations: [
    NumericDirectiveDirective
  ]
})
export class AppRoutingModule { }
