import type { CreateUpdatePatientNotesDTO, PatientNotesDTO, PatientNotesSearchDTO, PatientNotesV1DTO, } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientNotesService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientNotesDTO) =>
    this.restService.request<any, PatientNotesDTO>({
      method: 'POST',
      url: '/api/app/patient-notes',
      body: input,
    },
      { apiName: this.apiName });

  creatingPatientNotesByInput = (input: CreateUpdatePatientNotesDTO) =>
  this.restService.request<any, string[]>({
    method: 'POST',
    url: '/api/app/patient-notes/creating-patient-notes',
    body: input,
  },
  { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/patient-notes/${id}`,
    },
      { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PatientNotesDTO>({
      method: 'GET',
      url: `/api/app/patient-notes/${id}`,
    },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientNotesDTO>>({
      method: 'GET',
      url: '/api/app/patient-notes',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePatientNotesDTO) =>
    this.restService.request<any, PatientNotesDTO>({
      method: 'PUT',
      url: `/api/app/patient-notes/${id}`,
      body: input,
    },
      { apiName: this.apiName });

      
  updatingPatientNotesByIdAndInput = (id: string, input: CreateUpdatePatientNotesDTO) =>
  this.restService.request<any, string[]>({
    method: 'POST',
    url: `/api/app/patient-notes/${id}/updating-patient-notes`,
    body: input,
  },
  { apiName: this.apiName });

  getPatientNotesByPatientId = (patientId: string) =>
    this.restService.request<any, PatientNotesDTO[]>({
      method: 'GET',
      url: `/api/app/patient-notes/get-patient-notes/${patientId}`,
    },
      { apiName: this.apiName });

  getPatientNotesByPatientIdV1 = (patientId: string) =>
    this.restService.request<any, PatientNotesV1DTO[]>({
      method: 'GET',
      url: `/api/app/patient-notes/patient-notes-by-id-v1?patientID=${patientId}`,
      params: { patientId },
    },
      { apiName: this.apiName });

  // notesSearchByGPatientIdAndSNoteIdAndSNoteTypeAndSDescriptionAndSSeverityAndSSubjectAndSReasonAndSCreatedByAndDtCreatedDateAndDtActualDateAndDtDateNeededAndSStatusAndDtDateCompleted =
  //   (gPatientId: string, gOrderId: string, gClaimId: string, sNoteId: string, sPatientName: string, sDefaultPatientId: string, sNoteType: string, sReason: string,
  //     sSubject: string, sDescription: string, sCreatedBy: string, dtCreatedDate: string, sStatus: string) =>
  //     this.restService.request<any, PatientNotesSearchDTO[]>({
  //       method: 'POST',
  //       url: '/api/app/patient-notes/notes-search',
  //       params: { gPatientId, gOrderId, gClaimId, sNoteId, sPatientName, sDefaultPatientId, sNoteType, sReason, sSubject, sDescription, sCreatedBy, dtCreatedDate, sStatus },
  //     },
  //       { apiName: this.apiName });

  notesSearchByGPatientIdAndGOrderIdAndGClaimIdAndSNoteIdAndSPatientNameAndSDefaultPatientIdAndSNoteTypeAndSReasonAndSSubjectAndSDescriptionAndSCreatedByAndDtCreatedDateAndSStatusAndStart_DateAndEnd_Date = (gPatientId: string, gOrderId: string, gClaimId: string, sNoteId: string, sPatientName: string, sDefaultPatientId: string, sNoteType: string, sReason: string, sSubject: string, sDescription: string, sCreatedBy: string, dtCreatedDate: string, sStatus: string, start_Date: string, end_Date: string) =>
    this.restService.request<any, PatientNotesSearchDTO[]>({
      method: 'POST',
      url: '/api/app/patient-notes/notes-search',
      params: { gPatientId, gOrderId, gClaimId, sNoteId, sPatientName, sDefaultPatientId, sNoteType, sReason, sSubject, sDescription, sCreatedBy, dtCreatedDate, sStatus, start_Date, end_Date },
    },
      { apiName: this.apiName });


  constructor(private restService: RestService) { }
}
