<div
  class="col-12"
  style="margin-top: -17px; margin-bottom: -30px"
  *ngIf="!userShow"
>
  <div class="d-flex align-items-center justify-content-between m-0">
    <h4 class="mb-0 font-size-18"><b>Inventory</b></h4>
    <div class="page-title-box page-title-right mt-4 mb-0">
      <ol class="breadcrumbs m-0">
        <li class="breadcrumb-item">Inventory</li>
        <li class="breadcrumb-item">Item</li>
      </ol>
    </div>
  </div>
</div>
<br />
<div class="card card-body">
  <mat-accordion>
    <mat-expansion-panel [expanded]="step">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="itemId === ''; else elseTitleTemplate">
            <b>Add Items</b>
          </ng-container>
          <ng-template #elseTitleTemplate>
            <b>Edit Items</b>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="itemForm">
        <div class="row">
          <div class="col-lg-12">
            <mat-card-content>
              <div class="row">
                <mat-form-field class="col-4">
                  <mat-label
                    >Item Id / HCPC Code<span class="asterisk"
                      >*</span
                    ></mat-label
                  >
                  <input
                    autocomplete="new-txtItemId"
                    matInput
                    maxlength="40"
                    formControlName="txtItemId"
                    placeholder="Enter Item Id"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtItemId')?.touched &&
                      itemForm?.get('txtItemId')?.errors?.required
                    "
                  >
                    Item Id is a required field!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-4">
                  <mat-label
                    >Item Name <span class="asterisk">*</span></mat-label
                  >
                  <input
                    autocomplete="new-txtItemName"
                    matInput
                    formControlName="txtItemName"
                    placeholder="Enter Item Name"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtItemName')?.touched &&
                      itemForm?.get('txtItemName')?.errors?.required
                    "
                  >
                    Item Name is a required field!
                  </mat-error>

                  <mat-error
                    *ngIf="
                      itemForm?.get('txtItemName')?.touched &&
                      itemForm?.get('txtItemName')?.errors?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-4">
                  <mat-label>Price</mat-label>
                  <input
                    autocomplete="new-txtPrice"
                    matInput
                    (keypress)="keyPressNumbersDecimal($event)"
                    formControlName="txtPrice"
                    placeholder="Enter Price"
                    minlength="1"
                    maxlength="15"
                    type="text"
                  />

                  <mat-error
                    *ngIf="
                      itemForm?.get('txtPrice')?.touched &&
                      itemForm?.get('txtPrice')?.errors?.number
                    "
                  >
                    Enter only numbers!</mat-error
                  >
                </mat-form-field>
              </div>
            </mat-card-content>
          </div>
        </div>
      </form>
      <br />
      <div class="row">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <button
              mat-button
              class="buttonColor mb-2 mr-2"
              [disabled]="
                saveButtonHide || !(!itemForm.invalid && itemForm.dirty)
              "
              (click)="saveItem()"
            >
              Save
              <mat-icon *ngIf="isShowSpinner">
                <mat-spinner
                  class="spinner-border spinner-border-sm"
                  diameter="20"
                >
                </mat-spinner>
              </mat-icon>
            </button>
            <button
              mat-button
              class="resetclr buttonColor mb-2 mr-2"
              (click)="resetItem()"
            >
              Reset
            </button>
            <!-- <button (click)="importItems(inputFiles)" #uploadFileReference mat-button class="buttonColor font-size-14 pl-0">
                            <i class="fa fa-file-import pl-2 pr-2"></i>Import Items
                        </button> -->
            <input
              id="file-upload"
              [hidden]="true"
              (change)="importItems(uploadFileReference?.files)"
              #uploadFileReference
              type="file"
            />
            <button
              (click)="uploadFileReference.click()"
              mat-button
              *ngIf="userShow"
              class="buttonColor font-size-14 pl-2"
            >
              <i class="fa fa-file-import pl-2 pr-2"></i> Import Items
            </button>
            <input
              type="file"
              hidden
              #inputFiles
              (change)="importItemsFormFile(inputFiles?.files)"
              accept=".xlsx, .xls, .csv"
              [multiple]="false"
            />

            <button
              (click)="downloadTemplate()"
              mat-button
              *ngIf="userShow"
              class="buttonColor font-size-14 pl-0"
            >
              <i class="fa fa-cloud-download pl-2 pr-2"></i>Downlaod Template
            </button>
          </div>
        </div>
      </div>
      <br />
    </mat-expansion-panel>
  </mat-accordion>
  <div class="row">
    <div class="col-12 table-responsive">
      <!-- <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
        <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
        </mat-progress-bar>
      </div> -->
      <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
        <form style="display: flex" [formGroup]="itemSearchForm">
          <!-- <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{ i+1 }}
            </td>
          </ng-container> -->
          <ng-container matColumnDef="ItemId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input
                  autocomplete="new-itemIdSearch"
                  matInput
                  formControlName="itemIdSearch"
                />
                <mat-placeholder>Item Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.itemCode }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="itemName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input
                  autocomplete="new-itemNameSearch"
                  matInput
                  formControlName="itemNameSearch"
                />
                <mat-placeholder>Item Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.itemName }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input
                  autocomplete="new-priceSearch"
                  matInput
                  formControlName="priceSearch"
                />
                <mat-placeholder>Price</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.price }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor" (click)="ViewItem(data)">
                <i
                  class="fa fa-pencil editBtnColor mr-3"
                  matTooltip="Click To Edit"
                ></i>
              </a>
              &nbsp;
              <a class="deleteBtnColor" (click)="deleteItem(data)">
                <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
              </a>
              <button
                mat-button
                class="btn btn-sm lightSeaGreen-dot ml-5"
                (click)="insuranceItem(data)"
              >
                Insurance Edit
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td
              class="text-align-center"
              mat-footer-cell
              *matFooterCellDef
              colspan="9"
            >
              <div *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="8">
              <mat-paginator
                class="table table-responsive mat-table-class"
                #MatPaginator
                [length]="dataSource?.data?.length"
                [pageSize]="10"
                [pageIndex]="0"
                showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator>
            </td>
          </ng-container>
        </form>
        <tr
          mat-footer-row
          *matFooterRowDef="['noRecords']"
          [ngClass]="{
            active:
              dataSource && dataSource?.data && dataSource?.data?.length !== 0
          }"
        ></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>
  </div>
</div>
