<!-- <p>patient-claim works!</p> -->


<div class="container-fluid">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <h3 class="font-weight-bolder mb-0">  {{patientIds?.defaultPatientId ? patientClaim + patientIds?.defaultPatientId  : 'Patient Claim' }}</h3>
          <div class="page-title-box page-title-right">
            <ol class="breadcrumbs m-0">
                
                <div class="input-group ">
                    <input autocomplete="new-Username" type="text" class="form-control" placeholder="Username" [ngStyle]="{'border-color':dateRangStatus == true ? '#bdb4b4' : 'white' }"
                     aria-label="Username" aria-describedby="basic-addon1" name="daterange" ngxDaterangepickerMd [(ngModel)]="selectedRange"  id="calendar-input"
                     [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [linkedCalendars]="true"
                     [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale" [placeholder]="calendarPlaceholder"
                     [keepCalendarOpeningWithRange]="true" [minDate]="minDate" [maxDate]="maxDate" (datesUpdated)="datesUpdateds($event)" >
                     <span class="input-group-text bg-color" id="basic-addon1"><label style="margin: auto;" for="calendar-input" ><i  class="fa fa-filter cursor"  aria-hidden="true"></i></label></span>
      
                  </div>
                  
                  <button mat-button class="mr-2 buttonColor" [disabled]="
                  dataSource && dataSource?.data && dataSource?.data?.length === 0"
                  mat-raised-button (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'Patient Claim List',
                    sheet: 'PatientClaimList',
                    Props: { Author: 'QSecure' }
                  })
                " >
                  Export
                  </button>

              <li class="breadcrumb-item mt-2 pl-2">Claim List</li>
              <li class="breadcrumb-item mt-2">Patient Claim </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12 pb-5">
      
      <!-- <div class="example-container mat-elevation-z4"> -->
        <form [formGroup]="claimProcessingForm">
        <div class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0">
        <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort 
        class="pt-0 mt-0" style="margin-left: 15px !important;">
          <!-- Document Id -->
          <ng-container matColumnDef="claimIdSearch" sticky>
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="defaultClaimId" autocomplete="new-defaultClaimId" />
                <mat-placeholder class="center"> Claim Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
             {{element?.claimNumber }}
            </td>
          </ng-container>
          <!-- WIP ID-->
          <ng-container matColumnDef="saleOrderIdSearch" >
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <!-- WIP Id -->
              <mat-form-field class="filter">
                <input matInput formControlName="saleOrderId" autocomplete="new-saleOrderId" />
                <mat-placeholder class="center">Sale Order Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.saleOrderId}}
            </td>
          </ng-container>

          <ng-container matColumnDef="claimStatusSearch" >
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="claimStatus" autocomplete="new-claimStatus" />
                <mat-placeholder class="center">Claim Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.claimStatus}}
              <!-- {{ element?.patientName }} -->
            </td>
          </ng-container>
          <!-- Assigned By-->
          <!-- <ng-container matColumnDef="patientIdSearch">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="patientIdSearch" autocomplete="off" />
                <mat-placeholder class="center"> patientIdSearch</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
             
            </td>
          </ng-container> -->

          <ng-container matColumnDef="insuranceNameSearch">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="insuranceName" autocomplete="new-insuranceName" />
                <mat-placeholder class="center"> Insurance Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.insuranceName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="insuranceTypeSearch">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="insuranceType" autocomplete="new-insuranceType" />
                <mat-placeholder class="center"> Insurance Type</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.insuranceType}}
            </td>
          </ng-container>

          <ng-container matColumnDef="claimProcessedDateSearch">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="claimProcessedDateSearch" autocomplete="new-claimProcessedDateSearch" />
                <mat-placeholder class="center"> Claim Processed Date</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.claimProcessedDate | date: "MM/dd/yyyy":"en_US"}}
            </td>
          </ng-container>

          <!-- No Records-->
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
              <div *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div> 
              <div [hidden]="!isLoading">
                <!-- <mat-spinner [diameter]="220"></mat-spinner> -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
        </table>
       
        <div *ngIf="
        !(
          dataSource &&
          dataSource?.data &&
          dataSource?.data?.length !== 0
        )
      " class="text-align-center">
      <div class="message py-3" *ngIf="!isLoading">
        {{ "No Records Found" }}
      </div>
      <div class="py-3" [hidden]="!isLoading">
        <mat-spinner [diameter]="220"></mat-spinner>
      </div>
    </div>
         
        </div>
        </form>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
    
    </div>
 
  
  
