<div class="row">
  <div class="col-lg-6">
    <h2 class="font-weight-bolder">Eligibility History</h2>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <!-- <app-shared-table (viewClick)="viewVerificationById($event)" (deleteClick)="deleteVerificationById($event)"
    [strPageType]="strPageType" [isLoading]="isLoading" [crudPermisson]="crudPermisson"
    [arrDisplayedColumns]="arrDisplayedColumns">
  </app-shared-table> -->

  <div class="row">
    <div class="col-12">
      <div class="card-body">
        <div class="row" *ngIf="!isLoading">
          <div class="col-12">
            <div class="table table-responsive" >
              <app-patient-eligibility-history-list  [DataList]="verifyTableData" (reloadTableEvent)="reloatTable($event)">
              </app-patient-eligibility-history-list>
             </div>
          </div>
        </div>
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
