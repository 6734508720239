

export class getOrderDropdown {
  static readonly type = '[MasterDropdownDTO] GetMasterDropdowns]';
  constructor() { }
}










