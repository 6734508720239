<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2> {{ reminderId=="00000000-0000-0000-0000-000000000000"?"Add Reminders":"Edit Reminder"}}</h2>
    </div>
    <!-- <div class="col-lg-6 text-right">
          <button class="text-right close" aria-label="Close" mat-dialog-close><b> X </b></button>
        </div> -->
  </div>
  <hr />
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <form [formGroup]="reminderForm">
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label>Scheduled Date <span class="asterisk">*</span> </mat-label>
              <input autocomplete="new-txtDate" maxlength="40" formControlName="txtDate" matInput [matDatepicker]="txtDate" [min]="minDate"
                placeholder="MM/DD/YYYY" [formControl]="reminderForm.controls.txtDate" />
              <mat-datepicker-toggle matSuffix [for]="txtDate"></mat-datepicker-toggle>
              <mat-datepicker #txtDate></mat-datepicker>
              <mat-error *ngIf="reminderForm?.get('txtDate').touched || reminderForm?.get('txtDate')?.errors?.required &&
                  (reminderForm?.get('txtDate').dirty)">
                Scheduled Date is a required field!
              </mat-error>
              <mat-error
                *ngIf="   reminderForm?.get('txtDate')?.touched && reminderForm?.get('txtDate')?.errors?.matDatepickerParse">
                Enter Valid Date!
              </mat-error>

              <mat-error
                *ngIf=" minDate>=reminderForm?.get('txtDate')?.value && !(reminderForm?.get('txtDate')?.touched && reminderForm?.get('txtDate')?.errors)">
                Enter Future Dates Only!
              </mat-error>


            </mat-form-field>
            <!-- <ng-select class="col-4" formControlName="drpAssignedTo" placeholder="Assigned To " maxlength="40">
              <ng-option *ngFor="let user of drpRoles" [value]="user.id">
                {{ user.name }}</ng-option>
            </ng-select> -->

            <mat-form-field class="col-4">
              <mat-label>User Role <span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpAssignedTo" [(value)]="role" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtRoleFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let user of filterRole | async" [value]="user.id">
                  {{user.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="role='';$event.stopPropagation()" *ngIf="role" matSuffix (click)="clearRole()"
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <div [hidden]="true" class="col-4 chk custom-control custom-checkbox mb-3">
              <br>
              <mat-checkbox formControlName="chkAllUsers">All Users</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Patient</mat-label>
              <input autocomplete="new-txtPatient" matInput formControlName="txtPatient" type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Completed By</mat-label>
              <input autocomplete="new-txtCompletedBy" matInput formControlName="txtCompletedBy" type="text">
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Completed On</mat-label>
              <input autocomplete="new-dtCompletedOn" maxlength="40" formControlName="dtCompletedOn" matInput [matDatepicker]="dtCompletedOn"
                placeholder="MM/DD/YYYY" [formControl]="reminderForm.controls.dtCompletedOn"
                [min]="reminderForm.controls.txtDate.value" />
              <mat-datepicker-toggle matSuffix [for]="dtCompletedOn"></mat-datepicker-toggle>
              <mat-datepicker #dtCompletedOn></mat-datepicker>
              <mat-error *ngIf="
                  reminderForm?.get('dtCompletedOn').touched || reminderForm?.get('dtCompletedOn')?.errors?.required &&
                  (reminderForm?.get('dtCompletedOn').dirty)">
                Completed On is a required field!
              </mat-error>
              <mat-error
                *ngIf="   reminderForm?.get('dtCompletedOn')?.touched && reminderForm?.get('dtCompletedOn')?.errors?.matDatepickerParse">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Entered Date</mat-label>
              <input autocomplete="new-dtEnteredDateTime" maxlength="40" formControlName="dtEnteredDateTime" matInput [matDatepicker]="dtEnteredDateTime"
                placeholder="MM/DD/YYYY" />
              <mat-datepicker-toggle matSuffix [for]="dtEnteredDateTime"></mat-datepicker-toggle>
              <mat-datepicker #dtEnteredDateTime></mat-datepicker>
              <mat-error *ngIf="
                  reminderForm?.get('dtEnteredDateTime').touched || reminderForm?.get('dtEnteredDateTime')?.errors?.required &&
                  (reminderForm?.get('dtEnteredDateTime').dirty)">
                Entered Date is a required field!
              </mat-error>
              <mat-error
                *ngIf="   reminderForm?.get('dtEnteredDateTime')?.touched && reminderForm?.get('dtEnteredDateTime')?.errors?.matDatepickerParse">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>



            <mat-form-field class="col-12">
              <mat-label>Subject <span class="asterisk">*</span></mat-label>
              <textarea matInput rows="2" maxlength="400" formControlName="txtSubject"></textarea>
              <mat-error *ngIf="
                      reminderForm?.get('txtSubject')?.touched && reminderForm?.get('txtSubject')?.errors?.required
                    ">
                Subject is a required field!
              </mat-error>
              <!-- <mat-error *ngIf="
                  reminderForm?.get('txtSubject')?.touched &&
                  reminderForm?.get('txtSubject')?.errors?.pattern
                ">
                  Enter Only Alphanumeric !
                </mat-error> -->
            </mat-form-field>

            <div class="col-12" *ngIf='isShown'>
              <br>
              <mat-checkbox formControlName="chkDontShowAgain">Dont Remind Again</mat-checkbox>&nbsp;&nbsp;
              <mat-checkbox formControlName="chkCompleted">Completed</mat-checkbox>

              <!-- <mat-radio-button class="mb-2 mr-2" value="Done">Dont Remind Again</mat-radio-button> &nbsp;&nbsp;
                    <mat-radio-button class="mb-2 mr-2" value="Pending">Completed</mat-radio-button> &nbsp;&nbsp; -->
            </div>
            <!-- <div class="col-12" *ngIf = 'isShown'>
                  <br>
                  <mat-checkbox formControlName="chkDontShowAgain">Dont Remind Again</mat-checkbox>&nbsp;&nbsp;
                  <mat-checkbox formControlName="chkCompleted">Completed</mat-checkbox>
                </div> -->

          </div>
        </form>
      </mat-card-content>
    </div>
  </div>

  <div class="row mt-1">
    <h3 class="font-weight-bolder mb-0 ml-2 customThemeClass">Reminder List</h3>
    <div class="col-lg-12">
      <form [formGroup]="patientVisitNotesTableForm">
        <!-- <div [hidden]="!isLoading"
          style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
          <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        <div class="table table-responsive">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- NoteType Column -->
            <ng-container matColumnDef="name">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="name" />
                  <mat-placeholder>Patient Name</mat-placeholder>
                </mat-form-field> --> Patient Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>
            <!-- FollowUpDate Column -->

            <ng-container matColumnDef="scheduleDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <!-- <mat-form-field class="filter">
                  <mat-label> Scheduled Date </mat-label>
                  <input matInput formControlName="scheduleDate" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" patientVisitNotesTableForm?.get('scheduleDate')?.touched && patientVisitNotesTableForm?.get('scheduleDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field> -->Scheduled Date
              </th>
              <td mat-cell *matCellDef="let element"> {{ element?.dueDate | date: 'MM/dd/yyyy':'en_US' }}</td>
            </ng-container>

            <ng-container matColumnDef="role">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="role" />
                  <mat-placeholder>Role</mat-placeholder>
                </mat-form-field> --> User Role
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.roleName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="completedOn">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <!-- <mat-form-field class="filter">
                  <mat-label> Completed On </mat-label>
                  <input matInput formControlName="completedOn" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" patientVisitNotesTableForm?.get('completedOn')?.touched && patientVisitNotesTableForm?.get('completedOn')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field> -->Completed On
              </th>
              <td mat-cell *matCellDef="let element"> {{ element?.completedOn | date: 'MM/dd/yyyy':'en_US'}}</td>
            </ng-container>



            <ng-container matColumnDef="options">
              <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <a matTooltip="Click To View Patient Visit Notes">
                  <i class="fa fa-eye pointer editBtnColor pl-3"
                    (click)="viewPatientVisitNotes(element)"></i>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isLoading" >
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="9">
                <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                  [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{'make-green': row.LockStatus === 'Locked' && row.UserId === null}"></tr>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </form>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)='getClaimInfo()'
    [disabled]="saveButtonHide || !(!reminderForm.invalid && (reminderForm.dirty ))" class="buttonColor">Save
    <mat-icon *ngIf='saveButtonHide'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button mat-button (click)='resetForm()' class="resetclr buttonColor">Reset</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
