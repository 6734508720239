<table datatable class="row-border hover w-100 display" [dtOptions]="dtOptions">
  <thead>
    <tr>
      <th [matTooltip]="'HCPC Codes'">HCPC Codes</th>
      <th [matTooltip]="'Options'">Options</th>
      <th [matTooltip]="'Product Code'">Product Code</th>
      <th [matTooltip]="'Description'">Description</th>
      <th [matTooltip]="'Manufacturer'">Manufacturer</th>
      <th [matTooltip]="'Category'">Category</th>
      <th [matTooltip]="'Unit Cost ($)'" class="text-right">Unit Cost ($)</th>
      <th [matTooltip]="'Cash($)'" class="text-right">Cash($)</th>
      <th [matTooltip]="'Minimum Quantity'" class="text-right">
        Minimum Quantity
      </th>
      <th [matTooltip]="'Size'">Size</th>
      <th [matTooltip]="'Hide'">Hide/Show</th>
      <th [matTooltip]="'Location Bin'">Location Bin</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th [matTooltip]="'Created Date'">Created Date</th>
      <th [matTooltip]="'Modified By'">Modified By</th>
      <th [matTooltip]="'Modified Date'">Modified Date</th>
      <th [matTooltip]="'HCPC Codes'">HCPC Codes</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of productListValue">
      <td
        [matTooltip]="'Toggle to View HCPC Codes'"
        (click)="handleTableCellClick($event, data.id)"
        class="dt-control text-center"
      ></td>
      <td>
        <i
          *ngIf="data.status === 'Active'"
          class="fa fa-pencil pointer editBtnColor"
          (click)="getInsAllowablesById(data.id)"
          matTooltip="Click To Edit"
        ></i>
        <ng-container>
          <mat-slide-toggle
            (change)="statusChange($event, data)"
            matTooltip="Toggle to Change Product Status"
            [checked]="data.status == 'Active' ? true : false"
            class="ml-2"
          >
          </mat-slide-toggle>
        </ng-container>
      </td>
      <td [matTooltip]="data?.productCode || '-'">{{ data.productCode || '-'}}</td>
      <td [matTooltip]="data?.productDescription || '-'">
        {{ data.productDescription || '-'}}
      </td>
      <td [matTooltip]="data?.manufatureName || '-'">
        {{ data.manufatureName || '-'}}
      </td>
      <td [matTooltip]="data?.categoryName || '-'">{{ data.categoryName || '-'}}</td>
      <td [matTooltip]="data?.unitCost || '-'" class="text-right">
        {{ data.unitCost || '-'}}
      </td>
      <td [matTooltip]="data.cash || '-'" class="text-right">{{data.cash ? data.cash : '-'}}</td>
      <td [matTooltip]="data?.minimumQuantity || '-'" class="text-right">
        {{ data.minimumQuantity ? data.minimumQuantity : "-" }}
      </td>
      <td [matTooltip]="data?.sizeName || '-'">{{ data.sizeName || '-'}}</td>
      <td [matTooltip]="data?.hide==1?'Hide':'Show'">{{ data?.hide==1?'Hide':'Show' }}</td>
      <td [matTooltip]="data?.binLocation || '-'">{{ data.binLocation || '-'}}</td>


      <td [matTooltip]="data?.createdBy || '-'">{{ data.createdBy || '-'}}</td>

      <td class="text-center">
        <span [matTooltip]="data?.creationTime">{{
          data?.creationTime }}</span>
      </td>

      <td [matTooltip]="data?.modifiedBy || '-'">{{ data.modifiedBy || '-'}}</td>

      <td class="text-center">
        <span [matTooltip]="data?.lastModificationTime">{{
          data?.lastModificationTime }}</span>
      </td>
      <td [matTooltip]="data?.hcpcCode || '-'">
        {{ data.hcpcCode ? data.hcpcCode : "-" }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <!-- <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="HCPC Codes"
            /> -->
      </th>
      <th></th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Product Code"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Description"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Manufacturer"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Category"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Unit Cost ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Cash ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Minimum Quantity"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Size "
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Hide/Show"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Location Bin"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
        />
      </th>

      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="ModifiedBy"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified Date"
        />
      </th>

      <th></th>
    </tr>
  </tfoot>
</table>
