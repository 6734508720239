import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { PatientsDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { CreateUpdateItemsDTO } from '../../patient/dto/models';



@Injectable({
  providedIn: 'root',
})
export class CommonService {
  apiName = 'orderManagement';

  commonUpdate = (patientsId: string, patientId: string, saleOrderId: string, input: CreateUpdateItemsDTO) =>
    this.restService.request<any, PatientsDTO>({
      method: 'POST',
      url: '/api/orderApp/common/common-update',
      params: { patientsId, patientId, saleOrderId },
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
