import { Component, OnInit } from '@angular/core';
import { TextFileService } from 'projects/shared/src/app/services/text-file.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // set the currenr year
  year: number = new Date().getFullYear();
  currentVersion = '';
  constructor(private textFileService: TextFileService) {}

  ngOnInit(): void {
    this.getVersion();
  }

  getVersion() {
    this.textFileService.getTextFile('assets/version/Version.txt').subscribe(
      (data) => {
        this.currentVersion = data;
      },
      (error) => {
        console.error('There was an error!', error);
      }
    );
  }
}
