import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { TimeLineComponent } from '../../time-line/time-line.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PreviewDocumentComponent } from '../../preview-document/preview-document.component';

@Component({
  selector: 'app-hello-sign-report-table',
  templateUrl: './hello-sign-report-table.component.html',
  styleUrls: ['./hello-sign-report-table.component.scss']
})
export class HelloSignReportTableComponent implements OnInit {
@Input() tableData:any;

@ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;dthelloSignOptions: any = {
  responsive: true,
  // stateSave: true,
  paging: false,
  dom: 'Bfrtip',
  language: {
    emptyTable: 'No records',
    info: 'Total : _MAX_ records',
    infoEmpty: 'Total : _MAX_ records',
    infoFiltered: '(filtered : _TOTAL_ records)',
    loadingRecords: 'Loading...',
    zeroRecords: 'No matching records',
  },
  scrollCollapse: true,
  scrollY: '500px',
  scrollX: true,
  fixedHeader: true,
  order:[],
  columnDefs: [
    { targets: [9,10], visible: false },
    { targets: [0, 1, -1], orderable: false },
    { targets: [0, 1, 2,3, -1], className: 'dt-fixed-column' },
    // { type: 'date', targets: [10] },
    // {
    //   targets: [10],
    //   type: 'date',
    //   render: function (data, type, row) {
    //     // Assuming data is in the format MM/DD/YYYY
    //     if (type === 'sort' || type === 'type') {
    //       var dateParts = data.split('/');
    //       return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
    //     } else {
    //       return data;
    //     }
    //   },
    // },
  ],
  search: {
    smart: false, // Set to "none" for full exact match
  },
  buttons: [
    {
      extend: 'colvis',
      columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
    }
  ],
};
  helloSignReportData: any;
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.helloSignReportData = this.tableData;
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tbl').DataTable().columns.adjust().draw();
    }, 200);
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  viewSortedDoc(doc){
    const dialogRef = this.dialog.open(PreviewDocumentComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentData: doc,
        viewType: false
        
      },
    });
  }
  openTimelinePopup(data:any){
    const config: MatDialogConfig = {
      disableClose: true,
      height: '60vh',
      width: '40vw',
      data: {
         orderId:data.orderId,
         signatureId:data.id
      },
    };
    const dialogRef = this.dialog.open(TimeLineComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
