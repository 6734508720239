import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatReports } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/chat-reports.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { NgxChartComponent, reportsType } from '../ngx-chart/ngx-chart.component';
import { QuarterYear } from './../../../../patient/src/app/patient-proxy/patient/enumeration-data/quarter-year.enum';

@Component({
  selector: 'app-mis-reports-popup',
  templateUrl: './mis-reports-popup.component.html',
  styleUrls: ['./mis-reports-popup.component.scss']
})
export class MisReportsPopupComponent implements OnInit {
  // Declarations
  saveButtonHide: boolean;
  currentPeriod: string;
  totalBilling: number;
  percent: string;
  previousWeekTotalBilling: number;
  currentMonthPeriod: string;
  totalMonthBilling: number;
  previousMonthTotalBilling: number;
  monthPercent: string;
  hideQuarter1button: boolean = false;
  hideQuarter2button: boolean = false;
  hideInitialButton: boolean = true;
  hide2022YearButton: boolean = true;
  hide2021YearButton: boolean = true;
  hide2022YearButton2: boolean = false;
  hide2021YearButton2: boolean = false;
  detailsHide: boolean = false;
  hideJanButton: boolean = false;
  hideAprButton: boolean = false;
  hideJulyButton: boolean = false;
  hideOctButton: boolean = false;
  selectQuarter: boolean = false;
  selDyear: number;
  previousYear: number;
  currentYear: number = new Date().getFullYear();


  constructor(
    private reportService: ReportService,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    // get Previous Year
    this.previousYear = this.currentYear - 1
  }

  // Bind Weekly Reports
  getWeeklyReports() {
    this.reportService.getChatReportsByInput(ChatReports.WeeklyReport).subscribe(response => {
      this.currentPeriod = response?.weeklyReports?.currentWeekPeriod;
      this.totalBilling = response?.weeklyReports?.currentWeekTotalBilling;
      this.previousWeekTotalBilling = response?.weeklyReports?.previousWeekTotalBilling;
      this.percent = response?.weeklyReports?.percent;
    })
  }

  // Bind Monthly Report
  getMonthlyReports() {
    this.reportService.getChatReportsByInput(ChatReports.MonthlyReport).subscribe(response => {
      this.currentMonthPeriod = response?.monthlyReports?.currentMonthPeriod;
      this.totalMonthBilling = response?.monthlyReports?.currentMonthTotalBilling;
      this.previousMonthTotalBilling = response?.monthlyReports?.previousMonthTotalBilling;
      this.monthPercent = response?.monthlyReports?.percent;
    })
  }


  // OnChange Based On Condition
  onChange21(year: string|number) {
    if (+year == this.previousYear) {
      this.hideInitialButton = false;
      this.hide2021YearButton2 = true;
      this.hide2022YearButton2 = false;
      this.hideQuarter1button = true;
    }
    if (+year == this.currentYear) {
      this.hideInitialButton = false;
      this.hide2022YearButton2 = true;
      this.hide2021YearButton2 = false;
      this.hideQuarter1button = true;
    }
    this.selDyear = +year;
  }

  // OnChange Based On Quarter
  onChangeQuarter(month: string) {
    let quatarlyType:QuarterYear;
    if (month == 'Jan') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = true;
      this.hideAprButton = false;
      this.hideJulyButton = false;
      this.hideOctButton = false;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType=QuarterYear.JanuaryToMarch;
    }
    if (month == 'Apr') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = false;
      this.hideAprButton = true;
      this.hideJulyButton = false;
      this.hideOctButton = false;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType=QuarterYear.AprilToJune;

    }
    if (month == 'July') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = false;
      this.hideAprButton = false;
      this.hideJulyButton = true;
      this.hideOctButton = false;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType=QuarterYear.JulyToSeptember;

    }
    if (month == 'Oct') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = false;
      this.hideAprButton = false;
      this.hideJulyButton = false;
      this.hideOctButton = true;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType=QuarterYear.OctoberToDecember;

    }
    setTimeout(() => {
      this.openReportsSummary('QuaterlyReport', quatarlyType, this.selDyear);
    }, 1000);
  }
  openReportsSummary(typeOfReport: reportsType, quatarlyType:QuarterYear,year:number) {
    const dialogRef = this.dialog.open(NgxChartComponent, {
      disableClose: true,
      maxHeight: '81vh',
      data: { pagetype:"MISReports",type: typeOfReport, typeofquery: quatarlyType, year: year }
    });
    dialogRef.afterClosed().subscribe(() => {

      this.hideQuarter1button = false;
      this.hideQuarter2button = false;
      this.hideInitialButton = true;
      this.hide2022YearButton = true;
      this.hide2021YearButton = true;
      this.hide2022YearButton2 = false;
      this.hide2021YearButton2 = false;
      this.detailsHide = false;
      this.hideJanButton = false;
      this.hideAprButton = false;
      this.hideJulyButton = false;
      this.hideOctButton = false;
      this.selectQuarter = false;
      this.reportService.getChatReportsByInput(ChatReports.WeeklyReport).subscribe(response => {
        this.currentPeriod = response?.weeklyReports?.currentWeekPeriod;
        this.totalBilling = response?.weeklyReports?.currentWeekTotalBilling;
        this.percent = response?.weeklyReports?.percent;
        this.previousWeekTotalBilling = response?.weeklyReports?.previousWeekTotalBilling

      })
    });
  }
}
