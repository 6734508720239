<!-- Check List Page -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div
        class="d-flex align-items-center justify-content-between mb-3"
        style="margin-top: -15px"
      >
        <h3 class="font-weight-bolder mb-0">Claim / Cheque List Page</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <form [formGroup]="branchForm">
              <mat-form-field class="pr-3">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"
                    ></ngx-mat-select-search>
                  </mat-option>
  
                  <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>
  
                  <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                class="mr-2 buttonColor"
                [disabled]="
                  dataSource && dataSource?.data && dataSource?.data?.length === 0
                "
                mat-raised-button
                (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'Cheque List',
                    sheet: 'ChequeList',
                    Props: { Author: 'QSecure' }
                  })
                "
              >
                Export
              </button>
            </form>
            

            <!-- <li class="breadcrumb-item mt-2 pl-2">Claim</li>
            <li class="breadcrumb-item mt-2">Cheque List Page</li> -->
          </ol>
        </div>
      </div>
    </div>
    <div class="col-lg-12 pb-5">
      <form [formGroup]="chequeForm">
        <div
          class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0"
        >
          <table
            mat-table
            [dataSource]="dataSource"
            matTableExporter
            #exporter="matTableExporter"
            matSort
            class="pt-0 mt-0"
          >
            <ng-container matColumnDef="checkNumber">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-chequeNumber"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="chequeNumber"
                  />
                  <mat-placeholder class="center"
                    >Cheque Number</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.checkNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="defaultClaimId">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-defaultClaimId"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="defaultClaimId"
                  />
                  <mat-placeholder class="center">Claim Number</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to View Patient"
                  class="eyeCursorclass"
                  [queryParams]="{ id: 'All' }"
                  [routerLink]="'/AddNewPosting/' + element?.claimId"
                  target="_blank"
                >
                  {{ element?.defaultClaimId }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="checkAmount">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-checkAmount"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="checkAmount"
                  />
                  <mat-placeholder class="center"
                    >Cheque Amount</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.checkAmount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="defaultPatientId">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-defaultPatientId"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="defaultPatientId"
                  />
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to View Patient"
                  class="eyeCursorclass"
                  [routerLink]="'/patientCreateEditTab/' + element?.patientId"
                  target="_blank"
                >
                  {{ element?.defaultPatientId }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="postingDate">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-postingDate"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="postingDate"
                  />
                  <mat-placeholder class="center">Posting Date</mat-placeholder>
                  <mat-error
                    *ngIf="
                      chequeForm?.get('postingDate')?.touched &&
                      chequeForm?.get('postingDate')?.errors?.dateVaidator
                    "
                  >
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                  element?.postingDate === " "
                    ? " "
                    : (element?.postingDate | date : "MM/dd/yyyy" : "en_US")
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="patientName">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-patientName"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="patientName"
                  />
                  <mat-placeholder class="center">Patient Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="branch">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="branch" />
                  <mat-placeholder class="center">Branch</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                -
              </td>
            </ng-container>
            <ng-container matColumnDef="clearingHouse">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-ClearHouse"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                  />
                  <mat-placeholder class="center"
                    >Clearing House</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">Waystar</td>
            </ng-container>

            <ng-container matColumnDef="billed">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-billedAmount"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="billedAmount"
                  />
                  <mat-placeholder class="center"
                    >Billed Amount</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                ${{ element?.billedAmount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="currentBalance">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-currentBalance"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="currentBalance"
                  />
                  <mat-placeholder class="center"
                    >Current Balance</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                ${{
                  element?.currentBalance === " " ||
                  element?.currentBalance === null
                    ? 0
                    : element?.currentBalance
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="checkDate">
              <th
                class="header"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-checkDate"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="checkDate"
                  />
                  <mat-placeholder class="center">Cheque Date</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.checkDate | date : "MM/dd/yyyy" : "en_US" }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          </table>
          <div
            *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              )
            "
            class="text-align-center"
          >
            <div class="message py-3" *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
          <mat-paginator
            [pageSizeOptions]="[10, 20, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </form>
    </div>
  </div>
</div>
