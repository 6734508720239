import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { claimProcessMethodOptions } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing';
import { ProductType } from 'projects/inventory/src/app/item-proxy/item-management/items/product-type.enum';
import { ProductService } from 'projects/inventory/src/app/item-proxy/item-management/items/product.service';
import { OrderMasterDropdownDTO, PriceCodeDTO, SaleTypeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { billingStatus, OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { AddItemComponent, productInterface } from '../add-item/add-item.component';
import { ItemEligibilityRulesService } from '../order-proxy/order-management/inventory/item-eligibility-rules.service';
import { SaleorderService } from '../order-proxy/order-management/order';
import { ItemDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { updateOrder } from '../order-store/order.action';
import { ToastrService } from "ngx-toastr";

// import { InsuranceProviderProductEligibilityRulesService } from '../order-proxy/order-management/inventory';
@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
  providers: [ListService]
})
export class OrderItemComponent implements OnInit, OnDestroy {
  @Input() orderId: string = '';
  drpitem: productInterface[] = [];
  drpPriceCode: PriceCodeDTO[] = [];
  public itemForm: FormGroup = new FormGroup({});
  viewOrEdit: string = "";
  @Output() saveItemFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>();
  saleType: string = "";
  drpSaleType: SaleTypeDTO[] = [];
  public filteredItemTypes: Observable<productInterface[]>;
  tblNotesReponse: any[] = [];
  public strPageType: string = "item";
  isLoading: boolean = true;
  saveButtonHide: boolean;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  orderStatus: boolean = true;
  itemId: string = "00000000-0000-0000-0000-000000000000";
  organizationUnitId = null;
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private saleorderService: SaleorderService,
    private itemService: ItemEligibilityRulesService,
    private itemsService: ProductService,
    public listService: ListService,
    private orderDropdownService: OrderMasterDropdownService,
    public dialog: MatDialog,
    private number: NumberValidators,
    private Table: TableService,
    // private itemService: InsuranceProviderProductEligibilityRulesService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.orderId = this.orderId ?? "";
    this.activatedRoute.paramMap.subscribe(response => {
      this.viewOrEdit = response.get('editOrView')
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.itemForm = this.formBuilder.group({
      txtSearchFilter: new FormControl(""),
      drpSaleType: new FormControl(""),
      txtQty: new FormControl("", [this.number.isNumberCheck, Validators.min(1)]),
      txtBarcode: new FormControl(null, Validators.required),

    });
    if (this.viewOrEdit == "false") {
      this.itemForm.disable();
    } else {
      this.itemForm.enable();
    }
    this.getDropdown();
    this.clearData();
    if (this.itemForm.invalid || this.itemForm.dirty) {
      this.saveItemFormState.emit(this.itemForm);
    }
  }



  // clear Dropdown
  clearSaleType() {
    this.saleType = '';
    this.itemForm.patchValue({
      drpSaleType: ''
    });
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }



  // Dropdown data //
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.SaleTypes,
      OrderDropdowns.PriceCodes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.drpSaleType = response.saleTypes;
      this.drpPriceCode = response.priceCodes;
      if (this.orderId !== "") { this.getTableData(this.orderId); }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(orderDropdown);
    this.itemDropdown();
  }
  getPatientDetailsForSave() {
    let itemOrderDetails: SaleorderDTO;
    const saleOrderGet = this.saleorderService.get(this.orderId).subscribe(response => {
      itemOrderDetails = response;
      this.quickAdd(itemOrderDetails);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
      //
    });
    this.subscription$.push(saleOrderGet);
  }



  itemDropdown(): void {
    this.drpitem = [];
    // const itemList = (query: any) => this.itemService.getList(query);
    // this.listService.hookToQuery(itemList).subscribe(response => {
    //   response?.items?.forEach(element => {
    //     this.drpitem.push({
    //       equipmentBillingCode: element?.equipmentBillingCode,
    //       itemId: element?.equipmentBillingCode + ' / ' + element?.itemName,
    //       id: element?.id,
    //       name: element?.itemName,
    //       location: element?.locationId,
    //       insurance: element?.insuranceId,
    //       charge: element?.price
    //     });
    //     this.filteredItemTypes = this.itemForm?.get("txtSearchFilter").valueChanges
    //       .pipe(
    //         startWith(''),
    //         map(value => this.drpitem.filter(option => option?.itemId?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
    //       );
    //   });
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // }, () => {
    // });
    // this.drpitem = [];
    // this.itemService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
    //   response?.items?.forEach(element => {
    //     this.drpitem.push({
    //       equipmentBillingCode: element?.itemName,
    //       itemId: element?.itemCode + ' / ' + element?.itemName,
    //       id: element?.id,
    //       name: element?.itemName,
    //       location: element?.locationId,
    //       // insurance: element?.insuranceId,
    //       charge: element?.price
    //     });
    //     this.filteredItemTypes = this.itemForm?.get("txtSearchFilter").valueChanges
    //       .pipe(
    //         startWith(''),
    //         // map(value => this.drpitem.filter(option => option?.itemId?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
    //       );
    //   });
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // }, () => {
    // });

    this.drpitem = [];
    const getItemProduct = this.itemsService.getProductsByInput(ProductType.All).subscribe(response => {


      response?.forEach(element => {


        this.drpitem.push({
          hcpcCode: element.hcpcCode,
          productDescription: element?.productDescription,
          productCode: element?.productCode,
          status: element?.status,
          price: element?.price,
          // productStockId:element?.productStockId,
          // lotId: element?.lotId,
          // currentAvailableStockCount:element?.currentAvailableStockCount,
          productId: element?.productId,
          productName: element?.productName,
          productType: element?.productType,
          productNames: element?.productName,
          brandName: element?.brandName,
          categoryName: element?.categoryName,
          name: element?.name
          // isAllSerialNumbersUpdated: element?.isAllSerialNumbersUpdated
        });
        // this.drpInsurance = element?.rulesValidities
      })

      this.filteredItemTypes = this.itemForm?.get("txtSearchFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpitem.filter(option => option?.productId?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getItemProduct);

  }
  txtItem: string = '';
  clearItemType() {
    this.txtItem = '';
    this.itemForm.patchValue({

      txtBarcode: ''

    });
  }
  totalPrice: number = 0;
  totalQty: number = 0;
  getTableData(orderId: string) {
    this.isLoading = true;
    let itemDetails: SaleorderDTO;
    const saleOrderGet = this.saleorderService.get(orderId).subscribe(response => {
      itemDetails = response;
      this.tblNotesReponse = [];
      const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
      this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (response?.orderBilling?.status)))
      this.totalPrice = itemDetails?.shippingCheckList?.totalItemPrice;
      this.totalQty = (+String(itemDetails?.items?.reduce((prvsValue, curValue) => { return prvsValue + (+curValue?.qty) }, 0) ?? 0));
      this.totalPrice = (+String(itemDetails?.items?.reduce((prvsValue, curValue) => { return prvsValue + (+curValue?.totalPrice) }, 0) ?? 0)) ?? 0;
      itemDetails?.items?.forEach(element => {
        if (element?.itemId !== "00000000-0000-0000-0000-000000000000") {
          this.tblNotesReponse.push({
            totalPrice: response?.shippingCheckList?.totalItemPrice,
            totalItemQty: response?.shippingCheckList?.totalItemQty,
            indTotalPrice: element?.totalPrice,
            // indTotalPrice: 20,
            itemId: element?.itemId,
            saleType: element?.saleType,
            quickAddQuantity: element?.quickAddQuantity,
            itemIdOrBarCode: element?.itemIdOrBarCode,
            itemCodeId: element?.itemCodeId,
            name: element?.name,
            description: element?.description,
            location: element?.location,
            note: element?.note,
            insuranceName: element?.insuranceName,
            qty: element?.qty,
            bQty: element?.billingQuantity,
            priceCode: element?.priceCode,
            itemSize: element?.itemSize,
            //this.drpPriceCode.filter(value => { return value.priceCodeId === element?.priceCode })[0]?.priceCodeType,
            priceOption: element?.priceOption,
            modifiers1: element?.modifiers1,
            dosTo: element?.dosTo,
            nextDOS: element?.nextDOS !== null && element?.nextDOS !== "" ? new Date(element?.nextDOS)?.toLocaleDateString("en-US") : "",
            // isSpanDateSplitBillingEnabled: number;
            // cmnId: string;
            // cmnStatus: string;
            // cmnExpDate?: string;
            // cmnForm: string;
            // itemClaimNoteType: string;
            // itemClaimNoteQuantity: string;
            // preBilling?: string;
            // nextBilling?: string;
            // nextPeriod: string;
            additionalModifiers1: element?.additionalModifiers1,
            modifiers2: element?.modifiers2,
            modifiers3: element?.modifiers3,
            modifiers4: element?.modifiers4,
            billed: element?.charge,
            allow: element?.allow,
            // parId: element?.parId,
            // parNumber: element?.parNumber,
            // parStatus: element?.parStatus,
            // parExpDate: element?.parExpDate,
            taxRate: element?.taxRate,
            taxZone: element?.taxZone,
            nonTaxReason: element?.nonTaxReason,
            quantity: element?.quantity,
            billingQuantity: element?.billingQuantity,
            isPrimary: element?.isPrimary,
            coverageType: element?.coverageType,
            claimSubmission: element?.claimSubmission,
            isSecondary: element?.isSecondary,
            isTertiary: element?.isTertiary,
            isPatient: element?.isPatient,
            isDaysOfSupplyOverride: element?.isDaysOfSupplyOverride,
            daysSupply: element?.daysSupply,
            originalDos: element?.originalDos !== null && element?.originalDos !== "" ? new Date(element?.originalDos)?.toLocaleDateString("en-US") : "",
            additionalModifiers2: element?.additionalModifiers2,
            additionalModifiers3: element?.additionalModifiers3,
            additionalModifiers4: element?.additionalModifiers4,
            espdtIndicator: element?.espdtIndicator,
            isIncludeEclaimsAttachment: element?.isIncludeEclaimsAttachment,
            attachmentNo: element?.attachmentNo,
            typeCode: element?.typeCode,
            transCode: element?.transCode,
            isDiagnosisCodeICD9: element?.isDiagnosisCodeICD9,
            isDiagnosisCodeICD10: element?.isDiagnosisCodeICD10,
            isSpecialPricing: element?.isSpecialPricing,
            isTaxable: element?.isTaxable,
            isPriceOverride: element?.isPriceOverride,
            isSpecialTaxRate: element?.isSpecialTaxRate,
            isAcceptAssignment: element?.isAcceptAssignment,
            isManualConvertToPurchase: element?.isManualConvertToPurchase,
            isAbn: element?.isAbn,
            isAbnUpgrade: element?.isAbnUpgrade,
            isCbOverride: element?.isCbOverride,
            isSpanDateSplitBillingEnabled: element?.isSpanDateSplitBillingEnabled,
            itemClaimNoteQuantity: element?.itemClaimNoteQuantity,
            // lotId:element?.lotId,
            // currentAvailableStockCount:element?.currentAvailableStockCount,
            productId: element?.productId,
            productType: element?.productType,
            productStockId: element?.productStockId,
            // isAllSerialNumbersUpdated: element?.isAllSerialNumbersUpdated,
            productLotDetails: null,
            isItemRuleCheck: 0,
            patientId: response?.patientId,
            AutoClaim: element?.autoClaim,
            followUpDuration: element?.followUpDuration ?? 0,
            isFollowupNeeded: element?.isFollowupNeeded ?? false
          });

        }
      })
      this.isLoading = false;
      this.Table.setOrderTable(this.tblNotesReponse.reverse());
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
      //
    });
    this.subscription$.push(saleOrderGet);

  }

  //! delete Item
  deleteItem(datas?: any) {
    this.deleteItems(datas.itemId)
  }
  deleteItems(itemId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const deleteItem = this.saleorderService.deleteItem(this.orderId, itemId).subscribe(response => {
          this.orderId !== "" && this.getTableData(this.orderId);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteItem);
      }
    });
  }



  // Quick add items //
  quickAdd(response: SaleorderDTO) {
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let itemDetails: SaleorderDTO;
    let currentItems: ItemDTO[] = response?.items;
    const selectedItem: productInterface = this.drpitem?.filter(value => { return value && value?.productId === this.itemForm.value?.txtBarcode?.productId })[0];
    if (this.itemId != "00000000-0000-0000-0000-000000000000") {
      currentItems = currentItems.filter(items => { return items && items.itemId != this.itemId })
      currentItems = [...currentItems, {
        itemId: this.itemId,
        saleType: this.itemForm.value.drpSaleType,
        quickAddQuantity: +(this.itemForm.value.txtQty) | 0,
        itemIdOrBarCode: selectedItem?.hcpcCode,
        itemCodeId: selectedItem?.productId,
        name: selectedItem?.productName,
        brandName: selectedItem?.brandName,
        categoryName: selectedItem?.categoryName,
        description: "",
        location: selectedItem?.brandName,
        note: "",
        insuranceName: selectedItem?.brandName,
        isSpecialPricing: 0,
        isTaxable: 0,
        isPriceOverride: 0,
        isSpecialTaxRate: 0,
        isAcceptAssignment: 0,
        isManualConvertToPurchase: 0,
        isAbn: 0,
        isAbnUpgrade: 0,
        isCbOverride: 0,
        qty: +(this.itemForm.value.txtQty) | 0,
        bQty: 0,
        priceCode: "",
        priceOption: "",
        modifiers1: "",
        modifiers2: "",
        modifiers3: "",
        modifiers4: "",
        charge: (selectedItem.price) ? Number(selectedItem.price) : 0,
        allow: "",
        // parId: "",
        // parNumber: "",
        // parStatus: "",
        // parExpDate: "",
        taxRate: "",
        taxZone: "",
        nonTaxReason: "",
        quantity: 0,
        billingQuantity: 0,
        isPrimary: 0,
        coverageType: "",
        claimSubmission: "",
        isSecondary: 0,
        isTertiary: 0,
        isPatient: 0,
        isDaysOfSupplyOverride: 0,
        daysSupply: "",
        originalDos: "",
        dosTo: "",
        preBilling: "",
        nextBilling: "",
        nextPeriod: "",
        nextDOS: "",
        isSpanDateSplitBillingEnabled: 0,
        // cmnId: "",
        // cmnStatus: "",
        // cmnExpDate: "",
        // cmnForm: "",
        itemClaimNoteType: "",
        itemClaimNoteQuantity: 0,
        additionalModifiers1: "",
        additionalModifiers2: "",
        additionalModifiers3: "",
        additionalModifiers4: "",
        espdtIndicator: "",
        isIncludeEclaimsAttachment: 0,
        attachmentNo: "",
        typeCode: "",
        transCode: "",
        isDiagnosisCodeICD9: 0,
        isDiagnosisCodeICD10: 0,
        itemProcedureCode: "",
        sameItemFirstCreatedDateTime: "",
        // lotId:[],
        //  currentAvailableStockCount:10,
        productId: selectedItem?.productId,
        productType: selectedItem?.productType,
        productStockId: selectedItem?.productStockId,
        //  isAllSerialNumbersUpdated:true,
        productLotDetails: null,
        isItemRuleCheck: 0,
        totalPrice: 0,
        isFollowupNeeded: true,
        followUpDuration: 30

      }]
    } else {
      currentItems = [...currentItems, {
        itemId: this.itemId,
        saleType: this.itemForm.value.drpSaleType,
        quickAddQuantity: +(this.itemForm.value.txtQty) | 0,
        itemIdOrBarCode: selectedItem?.hcpcCode,
        itemCodeId: selectedItem?.productId,
        name: selectedItem?.productName,
        brandName: selectedItem?.brandName,
        categoryName: selectedItem?.categoryName,
        description: "",
        location: selectedItem?.brandName,
        note: "",
        insuranceName: selectedItem?.brandName,
        isSpecialPricing: 0,
        isTaxable: 0,
        isPriceOverride: 0,
        isSpecialTaxRate: 0,
        isAcceptAssignment: 0,
        isManualConvertToPurchase: 0,
        isAbn: 0,
        isAbnUpgrade: 0,
        isCbOverride: 0,
        qty: +(this.itemForm.value.txtQty) | 0,
        bQty: 0,
        priceCode: "",
        priceOption: "",
        modifiers1: "",
        modifiers2: "",
        modifiers3: "",
        modifiers4: "",
        charge: (selectedItem?.price) ? Number(selectedItem?.price) : 0,

        allow: "",
        //parId: "",
        // parNumber: "",
        // parStatus: "",
        // parExpDate: "",
        taxRate: "",
        taxZone: "",
        nonTaxReason: "",
        quantity: 0,
        billingQuantity: 0,
        isPrimary: 0,
        coverageType: "",
        claimSubmission: "",
        isSecondary: 0,
        isTertiary: 0,
        isPatient: 0,
        isDaysOfSupplyOverride: 0,
        daysSupply: "",
        originalDos: "",
        dosTo: "",
        preBilling: "",
        nextBilling: "",
        nextPeriod: "",
        nextDOS: "",
        isSpanDateSplitBillingEnabled: 0,
        // cmnId: "",
        // cmnStatus: "",
        // cmnExpDate: "",
        // cmnForm: "",
        itemClaimNoteType: "",
        itemClaimNoteQuantity: 0,
        additionalModifiers1: "",
        additionalModifiers2: "",
        additionalModifiers3: "",
        additionalModifiers4: "",
        espdtIndicator: "",
        isIncludeEclaimsAttachment: 0,
        attachmentNo: "",
        typeCode: "",
        transCode: "",
        isDiagnosisCodeICD9: 0,
        isDiagnosisCodeICD10: 0,
        itemProcedureCode: "",
        sameItemFirstCreatedDateTime: "",
        // lotId:[],
        // currentAvailableStockCount:10,
        productId: selectedItem?.productId,
        productType: selectedItem?.productType,
        productStockId: selectedItem?.productStockId,
        // isAllSerialNumbersUpdated:true,
        productLotDetails: null,
        isItemRuleCheck: 0,
        totalPrice: 0,
        isFollowupNeeded: true,
        followUpDuration: 30
      }]
    }
    itemDetails = {
      defaultClaimId: response.defaultClaimId,
      orders: response.orders,
      clinicals: response.clinicals,
      insurances: response.insurances,
      items: currentItems,
      patients: response.patients,
      saleorderId: response.saleorderId,
      organizationUnitId: response.organizationUnitId,
      claimId: response.claimId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      orderStatus: response.orderStatus,
      patientId: response.patientId,
      isShippingApprove: response.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      patientLedgerReason: response.patientLedgerReason,
      documentReason: response.documentReason,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      payPalMailResendCount: response?.payPalMailResendCount,
      claimHistoryStatus: response.claimHistoryStatus,
      patientLedgerStatus: response.patientLedgerStatus,
      shippingCheckList: response?.shippingCheckList,
      orderBilling: response?.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType

      // saleorderId: response.saleorderId
    }

    this.orderId != "" &&
      this.store.dispatch(new updateOrder(this.orderId, itemDetails)).subscribe(() => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.itemId = "00000000-0000-0000-0000-000000000000";
        this.getTableData(this.orderId)
        this.clearData();
        this.saveItemFormState.emit(this.itemForm.value);
        this.saveButtonHide = false;
        //!
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });

  }

  openItemModal() {
    const dialogRef = this.dialog.open(AddItemComponent, {
      disableClose: true,
      data: { orderId: this.orderId }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getTableData(this.orderId)
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
  }

  ViewOrder(reord?: any) {
    const dialogRef = this.dialog.open(AddItemComponent, {
      disableClose: true,
      data: { orderId: this.orderId, itemId: reord?.itemId }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getTableData(this.orderId);

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
  }

  clearData() {
    this.itemForm.reset({
      drpSaleType: "",
      txtQty: "",
      txtBarcode: ""
    })
  }


  //Active and Inactive Functionality
  // statusChange(event: MatSlideToggleChange, record: any) {
  //   if (event.checked == true) {
  //     Swal.fire({
  //       title: 'Do you want to Activate Auto Claim?',
  //       text: 'By Changing Status, this branch will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!'
  //     }).then(result => {
  //       if (result.value) {
  //         let itemOrderDetails: SaleorderDTO;
  //         this.saleorderService.get(this.orderId).subscribe(response => {
  //           itemOrderDetails = response;
  //           let itemDetails: SaleorderDTO;
  //           let currentItems: ItemDTO[] = response?.items;
  //           const selectedItem: productInterface = this.drpitem?.filter(value => { return value && value?.productId === this.itemForm.value?.txtBarcode?.productId })[0];
  //           currentItems = [...currentItems, {
  //             itemId: record?.itemId,
  //             saleType: record?.saleType,
  //             quickAddQuantity: record?.quickAddQuantity,
  //             itemIdOrBarCode: record?.itemIdOrBarCode,
  //             itemCodeId: record?.itemCodeId,
  //             name: record?.name,
  //             brandName: record?.brandName,
  //             categoryName: record?.categoryName,
  //             description: record?.description,
  //             location: record?.description,
  //             note: record?.note,
  //             insuranceName: record?.insuranceName,
  //             isSpecialPricing: 0,
  //             isTaxable: 0,
  //             isPriceOverride: 0,
  //             isSpecialTaxRate: 0,
  //             isAcceptAssignment: 0,
  //             isManualConvertToPurchase: 0,
  //             isAbn: 0,
  //             isAbnUpgrade: 0,
  //             isCbOverride: 0,
  //             qty: record?.qty,
  //             bQty: 0,
  //             priceCode: "",
  //             priceOption: "",
  //             modifiers1: "",
  //             modifiers2: "",
  //             modifiers3: "",
  //             modifiers4: "",
  //             charge: record?.charge,

  //             allow: "",
  //             //parId: "",
  //             // parNumber: "",
  //             // parStatus: "",
  //             // parExpDate: "",
  //             taxRate: "",
  //             taxZone: "",
  //             nonTaxReason: "",
  //             quantity: 0,
  //             billingQuantity: 0,
  //             isPrimary: 0,
  //             coverageType: "",
  //             claimSubmission: "",
  //             isSecondary: 0,
  //             isTertiary: 0,
  //             isPatient: 0,
  //             isDaysOfSupplyOverride: 0,
  //             daysSupply: "",
  //             originalDos: "",
  //             dosTo: "",
  //             preBilling: "",
  //             nextBilling: "",
  //             nextPeriod: "",
  //             nextDOS: "",
  //             isSpanDateSplitBillingEnabled: 0,
  //             // cmnId: "",
  //             // cmnStatus: "",
  //             // cmnExpDate: "",
  //             // cmnForm: "",
  //             itemClaimNoteType: "",
  //             itemClaimNoteQuantity: 0,
  //             additionalModifiers1: "",
  //             additionalModifiers2: "",
  //             additionalModifiers3: "",
  //             additionalModifiers4: "",
  //             espdtIndicator: "",
  //             isIncludeEclaimsAttachment: 0,
  //             attachmentNo: "",
  //             typeCode: "",
  //             transCode: "",
  //             isDiagnosisCodeICD9: 0,
  //             isDiagnosisCodeICD10: 0,
  //             itemProcedureCode: "",
  //             sameItemFirstCreatedDateTime: "",
  //             // lotId:[],
  //             // currentAvailableStockCount:10,
  //             productId: record?.productId,
  //             productType: record?.productType,
  //             productStockId: record?.productStockId,
  //             // isAllSerialNumbersUpdated:true,
  //             productLotDetails: null,
  //             isItemRuleCheck: 0,
  //             totalPrice: 0,
  //             autoClaim: 1
  //           }]
  //           itemDetails = {
  //             defaultClaimId: response.defaultClaimId,
  //             orders: response.orders,
  //             clinicals: response.clinicals,
  //             insurances: response.insurances,
  //             items: currentItems,
  //             patients: response.patients,
  //             saleorderId: response.saleorderId,
  //             organizationUnitId: response.organizationUnitId,
  //             claimId: response.claimId,
  //             currentStage: response.currentStage,
  //             currentStageStatus: response.currentStageStatus,
  //             orderStatus: response.orderStatus,
  //             patientId: response.patientId,
  //             isShippingApprove: response.isShippingApprove,
  //             isCashPayment: response.isCashPayment,
  //             orderProcessStatus: response.orderProcessStatus,
  //             patientLedgerReason: response.patientLedgerReason,
  //             documentReason: response.documentReason,
  //             paymentTransactionDetails: response.paymentTransactionDetails,
  //             payPalPaymentMethodType: response?.payPalPaymentMethodType,
  //             payPalPaymentStatus: response?.payPalPaymentStatus,
  //             payPalMailResendCount: response?.payPalMailResendCount,
  //             claimHistoryStatus: response.claimHistoryStatus,
  //             patientLedgerStatus: response.patientLedgerStatus,
  //             shippingCheckList: response?.shippingCheckList,
  //             orderBilling: response?.orderBilling,
  //             deliveryTicket: response?.deliveryTicket
  //             // saleorderId: response.saleorderId
  //           }
  //           this.orderId != "" &&
  //             this.store.dispatch(new updateOrder(this.orderId, itemDetails)).subscribe(() => {
  //               Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
  //               this.getTableData(this.orderId)
  //               this.clearData();
  //               this.saveItemFormState.emit(this.itemForm.value);
  //               this.saveButtonHide = false;
  //               //!
  //             }, err => {
  //               this.saveButtonHide = false;
  //               const data: HttpErrorResponse = err;
  //               Swal.fire({
  //                 icon: 'error',
  //                 text: data?.error?.error?.message,
  //               });
  //             });
  //         });
  //       }
  //     })
  //   }
  //   if (event.checked == false) {
  //     Swal.fire({
  //       title: 'Do you want to Deactivate Auto Claim?',
  //       text: 'By Changing Status, this branch will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!'
  //     }).then(result => {
  //       if (result.value) {
  //         let itemOrderDetails: SaleorderDTO;
  //         this.saleorderService.get(this.orderId).subscribe(response => {
  //           itemOrderDetails = response;
  //           let itemDetails: SaleorderDTO;
  //           let currentItems: ItemDTO[] = response?.items;
  //           const selectedItem: productInterface = this.drpitem?.filter(value => { return value && value?.productId === this.itemForm.value?.txtBarcode?.productId })[0];
  //           currentItems = [...currentItems, {
  //             itemId: record?.itemId,
  //             saleType: record?.saleType,
  //             quickAddQuantity: record?.quickAddQuantity,
  //             itemIdOrBarCode: record?.itemIdOrBarCode,
  //             itemCodeId: record?.itemCodeId,
  //             name: record?.name,
  //             brandName: record?.brandName,
  //             categoryName: record?.categoryName,
  //             description: record?.description,
  //             location: record?.description,
  //             note: record?.note,
  //             insuranceName: record?.insuranceName,
  //             isSpecialPricing: 0,
  //             isTaxable: 0,
  //             isPriceOverride: 0,
  //             isSpecialTaxRate: 0,
  //             isAcceptAssignment: 0,
  //             isManualConvertToPurchase: 0,
  //             isAbn: 0,
  //             isAbnUpgrade: 0,
  //             isCbOverride: 0,
  //             qty: record?.qty,
  //             bQty: 0,
  //             priceCode: "",
  //             priceOption: "",
  //             modifiers1: "",
  //             modifiers2: "",
  //             modifiers3: "",
  //             modifiers4: "",
  //             charge: record?.charge,

  //             allow: "",
  //             //parId: "",
  //             // parNumber: "",
  //             // parStatus: "",
  //             // parExpDate: "",
  //             taxRate: "",
  //             taxZone: "",
  //             nonTaxReason: "",
  //             quantity: 0,
  //             billingQuantity: 0,
  //             isPrimary: 0,
  //             coverageType: "",
  //             claimSubmission: "",
  //             isSecondary: 0,
  //             isTertiary: 0,
  //             isPatient: 0,
  //             isDaysOfSupplyOverride: 0,
  //             daysSupply: "",
  //             originalDos: "",
  //             dosTo: "",
  //             preBilling: "",
  //             nextBilling: "",
  //             nextPeriod: "",
  //             nextDOS: "",
  //             isSpanDateSplitBillingEnabled: 0,
  //             // cmnId: "",
  //             // cmnStatus: "",
  //             // cmnExpDate: "",
  //             // cmnForm: "",
  //             itemClaimNoteType: "",
  //             itemClaimNoteQuantity: 0,
  //             additionalModifiers1: "",
  //             additionalModifiers2: "",
  //             additionalModifiers3: "",
  //             additionalModifiers4: "",
  //             espdtIndicator: "",
  //             isIncludeEclaimsAttachment: 0,
  //             attachmentNo: "",
  //             typeCode: "",
  //             transCode: "",
  //             isDiagnosisCodeICD9: 0,
  //             isDiagnosisCodeICD10: 0,
  //             itemProcedureCode: "",
  //             sameItemFirstCreatedDateTime: "",
  //             // lotId:[],
  //             // currentAvailableStockCount:10,
  //             productId: record?.productId,
  //             productType: record?.productType,
  //             productStockId: record?.productStockId,
  //             // isAllSerialNumbersUpdated:true,
  //             productLotDetails: null,
  //             isItemRuleCheck: 0,
  //             totalPrice: 0,
  //             autoClaim: 0
  //           }]
  //           itemDetails = {
  //             defaultClaimId: response.defaultClaimId,
  //             orders: response.orders,
  //             clinicals: response.clinicals,
  //             insurances: response.insurances,
  //             items: currentItems,
  //             patients: response.patients,
  //             saleorderId: response.saleorderId,
  //             organizationUnitId: response.organizationUnitId,
  //             claimId: response.claimId,
  //             currentStage: response.currentStage,
  //             currentStageStatus: response.currentStageStatus,
  //             orderStatus: response.orderStatus,
  //             patientId: response.patientId,
  //             isShippingApprove: response.isShippingApprove,
  //             isCashPayment: response.isCashPayment,
  //             orderProcessStatus: response.orderProcessStatus,
  //             patientLedgerReason: response.patientLedgerReason,
  //             documentReason: response.documentReason,
  //             paymentTransactionDetails: response.paymentTransactionDetails,
  //             payPalPaymentMethodType: response?.payPalPaymentMethodType,
  //             payPalPaymentStatus: response?.payPalPaymentStatus,
  //             payPalMailResendCount: response?.payPalMailResendCount,
  //             claimHistoryStatus: response.claimHistoryStatus,
  //             patientLedgerStatus: response.patientLedgerStatus,
  //             shippingCheckList: response?.shippingCheckList,
  //             orderBilling: response?.orderBilling,
  //             deliveryTicket: response?.deliveryTicket
  //             // saleorderId: response.saleorderId
  //           }
  //           this.orderId != "" &&
  //             this.store.dispatch(new updateOrder(this.orderId, itemDetails)).subscribe(() => {
  //               Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
  //               // this.getTableData(record.orderId)
  //               // this.clearData();
  //               //!
  //             }, err => {
  //               this.saveButtonHide = false;
  //               const data: HttpErrorResponse = err;
  //               Swal.fire({
  //                 icon: 'error',
  //                 text: data?.error?.error?.message,
  //               });
  //             });
  //         });
  //       }
  //     })
  //   }
  // }
}
export interface itemInterface {
  id: string;
  itemId: string;
  name: string;
  location: string;
  insurance?: string;
  charge: number;
  equipmentBillingCode: string;
}
