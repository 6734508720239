import type { CreateUpdateInventoryTransferDTO, InventoryTransferDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InventoryTransferService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateInventoryTransferDTO) =>
    this.restService.request<any, InventoryTransferDTO>({
      method: 'POST',
      url: '/api/ItemApp/inventory-transfer',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/inventory-transfer/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, InventoryTransferDTO>({
      method: 'GET',
      url: `/api/ItemApp/inventory-transfer/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InventoryTransferDTO>>({
      method: 'GET',
      url: '/api/ItemApp/inventory-transfer',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

    getInventoryTranferItemDetailsByGInventory = (gInventory: string[]) =>
    this.restService.request<any, CreateUpdateInventoryTransferDTO>({
      method: 'GET',
      url: '/api/ItemApp/inventory-transfer/get-inventory-tranfer-item-details',
      params: { gInventory },
    },
    { apiName: this.apiName });
  update = (id: string, input: CreateUpdateInventoryTransferDTO) =>
    this.restService.request<any, InventoryTransferDTO>({
      method: 'PUT',
      url: `/api/ItemApp/inventory-transfer/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
