import { mapEnumToOptions } from '@abp/ng.core';

export enum CalendarStatus {
  Scheduled = 'Scheduled',
  Rescheduled = 'Rescheduled',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  All = 'All',
  AwaitingCheckIn = 'AwaitingCheckIn',
  Confirmed = 'Confirmed',
  CheckedIn = 'CheckedIn',
  CheckedOut = 'CheckedOut',
  NoShow = 'NoShow',
}

export const calendarStatusOptions = mapEnumToOptions(CalendarStatus);
