import type { CreateUploadFilesDTO, SearchUploadFilesDTO, UpdateUploadFilesDTO, UploadFilesDTO } from './dto/models';
import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  apiName = 'platformManagement';

  getPatientDocumentByBlobNameByBlobName = (blobName: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/PlatformApp/upload-files/get-patient-document-by-blob-name',
      params: { blobName },
    },
      { apiName: this.apiName, skipHandleError: true });

  getAssignnedFilesByUserId = (userId: string) =>
    this.restService.request<any, UploadFilesDTO[]>({
      method: 'GET',
      url: `/api/PlatformApp/upload-files/get-assignned-files/${userId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getUploadedFileByIdByFileId = (fileId: string) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'GET',
      url: `/api/PlatformApp/upload-files/get-uploaded-file-by-id/${fileId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getUploadedFileList = () =>
    this.restService.request<any, UploadFilesDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/upload-files/get-uploaded-file-list',
    },
      { apiName: this.apiName, skipHandleError: true });

  updateFileDetailsByFileIdAndOUpdateFileUploads = (fileId: string, oUpdateFileUploads: UpdateUploadFilesDTO) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/upload-files/update-file-details/${fileId}`,
      body: oUpdateFileUploads,
    },
      { apiName: this.apiName, skipHandleError: true });

  updateSortedDataByFileId = (fileId: string) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/upload-files/update-sorted-data/${fileId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  updateSortedIndexByFileIdAndSortedFileIdAndDocumentType = (fileId: string, sortedFileId: string, documentType: string) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/upload-files/update-sorted-index',
      params: { fileId, sortedFileId, documentType },
    },
      { apiName: this.apiName, skipHandleError: true });

  uploadFileByOCreateFileUploads = (oCreateFileUploads: CreateUploadFilesDTO) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'POST',
      url: '/api/PlatformApp/upload-files/upload-file',
      body: oCreateFileUploads,
    },
      { apiName: this.apiName, skipHandleError: true });


  searchPatientsByInput = (input: SearchUploadFilesDTO) =>
    this.restService.request<any, UploadFilesDTO[]>({
      method: 'POST',
      url: `/api/PlatformApp/upload-files/search-files`,
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  deleteFilesByFileId = (fileId: string) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: `/api/PlatformApp/upload-files/delete-files/${fileId}`,
    },
      { apiName: this.apiName,skipHandleError:true });

      updateArchiveStatusByFileIdAndArchiveStatus = (fileId: string, archiveStatus: boolean) =>
      this.restService.request<any, UploadFilesDTO>({
        method: 'PUT',
        url: `/api/PlatformApp/upload-files/update-archive-status/${fileId}`,
        params: { archiveStatus },
      },
      { apiName: this.apiName,skipHandleError:true });

  searchFilesBasedOnStatusByArchiveStatusAndOSearchUploadFiles = (archiveStatus: boolean, oSearchUploadFiles: SearchUploadFilesDTO) =>
      this.restService.request<any, UploadFilesDTO[]>({
        method: 'POST',
        url: '/api/PlatformApp/upload-files/search-files-based-on-status',
        params: { archiveStatus },
        body: oSearchUploadFiles,
      },
      { apiName: this.apiName,skipHandleError:true });

  getUploadFilesByArchiveStatusByArchiveStatus = (archiveStatus: boolean) =>
      this.restService.request<any, UploadFilesDTO[]>({
        method: 'GET',
        url: '/api/PlatformApp/upload-files/get-upload-files-by-archive-status',
        params: { archiveStatus },
      },
      { apiName: this.apiName,skipHandleError:true });



  constructor(private restService: RestService) { }
}
