import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-master-mask',
  templateUrl: './master-mask.component.html',
  styleUrls: ['./master-mask.component.scss']
})
export class MasterMaskComponent implements OnInit {notesForm: FormGroup;
  maskDetails: { description: string, status: boolean }[] = [];
  isEditMode = false;
  editIndex: number;
dtFrequentItemTableOptions: any;
frequentBoughtItems: any;
$: any;

  constructor(private formBuilder: FormBuilder) {
    this.notesForm = this.formBuilder.group({
      txtNotes: ['', Validators.required]
    });
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
  }

  get isSaveButtonDisabled(): boolean {
    return !this.notesForm.valid || (!this.isEditMode && this.notesForm.get('txtNotes').pristine);
  }

  onSave(): void {
    const newDescription = this.notesForm.value.txtNotes;
    const newMaskDetail = { description: newDescription, status: false };

    if (this.isEditMode) {
      // Update existing mask detail in edit mode
      this.maskDetails[this.editIndex] = newMaskDetail;
      this.isEditMode = false;
    } else {
      // Add the new mask detail to the array
      this.maskDetails.push(newMaskDetail);
    }

    // Clear the form after saving
    this.notesForm.reset();
  }

  editMaskDetail(index: number): void {
    // Set edit mode and populate the form with the existing details
    this.isEditMode = true;
    this.editIndex = index;
    const { description, status } = this.maskDetails[index];
    this.notesForm.setValue({ txtNotes: description });
  }

  deleteMaskDetail(index: number): void {
    // Display confirmation dialog
    const isConfirmed = window.confirm('Are you sure you want to delete this item?');

    // If confirmed, proceed with deletion
    if (isConfirmed) {
      // Handle delete logic here
      this.maskDetails.splice(index, 1);
      this.isEditMode = false; // Reset edit mode after deletion
    }
  }
}
