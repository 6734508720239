<div class="container-fluid main-content " style="overflow: inherit;">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Claim Process</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <button mat-button class="mr-2 buttonColor"
            [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item ">Billing</li>
            <li class="breadcrumb-item ">Claim Process</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12" style="margin-top: -19px;">
  <mat-card>
    <form [formGroup]="claimProcessForm">
      <div class="row">
        <div class="col-12">
          <h4 class="customThemeClass"><b>Claim Process Filter</b></h4>
        </div>
      </div>
      <div class="row">
        <mat-form-field class="col-2">
          <mat-label>Select Provider <span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpForms" (selectionChange)="onProviderChange($event)" maxlength="40">
            <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
              {{ providers.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="row pt-2">
      <div class="col-12 bottomReduce">
        <h4 class="customThemeClass"><b>Claim Process List</b></h4>
      </div>
      <div class="col-12 table-responsive">
        <!-- <app-table [strPageType]="strPageType" [isLoading]="isLoading" [isLoadings]="isLoadings"
                    [saveButtonHide]="saveButtonHide" (viewButton)="getProccessSelectedClaimProcess($event)"
                    (viewsButton)="getElectronicProcess($event)" (viewClaimButton)="viewClaimDetailsById($event)"
                    (claimSearchValueChanges)="claimSearchValueBased($event)"

                    >
                </app-table> -->
        <table id="{{ strPageType }}" mat-table class="table table-bordered" [dataSource]="dataSource" matSort>
          <form [formGroup]="claimSearchForm">
            <ng-container matColumnDef="pateintId">
              <th class=" header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientId" matInput formControlName="patientId">
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.patientId}} </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th class=" header" mat-header-cell *matHeaderCellDef>
                Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.id}} </td>
            </ng-container>

            <ng-container matColumnDef="claimid">
              <th class=" header" mat-header-cell *matHeaderCellDef>
                Claim Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.claimNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="saleorderId">
              <th class=" header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-saleOrderId" matInput formControlName="saleOrderId">
                  <mat-placeholder class="center"> SaleOrder Id
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.orderId}} </td>
            </ng-container>

            <ng-container matColumnDef="patientName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientName" matInput formControlName="patientName">
                  <mat-placeholder class="center">Patient Name
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.patientFirstName + " " + element?.patientLastName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="patientDob">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Patient DOB
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.mpatientDob}} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th class=" header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-billedAmount" matInput formControlName="billedAmount">
                  <mat-placeholder class="center">
                    Total Billed
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.totalItemBilledAmount}} </td>
            </ng-container>

            <ng-container matColumnDef="primaryInsuranceName">
              <th class=" header" mat-header-cell *matHeaderCellDef>

                <mat-form-field class="filter">
                  <input autocomplete="new-payerName" matInput formControlName="payerName">
                  <mat-placeholder class="center"> Insurance Name
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.primaryInsuranceName}} </td>
            </ng-container>
            <ng-container matColumnDef="clrhouse">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Clearing House
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.clearingHouseType}}
              </td>
            </ng-container>

            <ng-container matColumnDef="formDescripition">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.claimStatus}} </td>
            </ng-container>

            <ng-container matColumnDef="billingType">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-billingType" matInput formControlName="billingType">
                  <mat-placeholder class="center"> Billing Type
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf='element.billingType===1'>
                  <span> {{ "Purchase"
                    }}</span>
                </ng-container>
                <ng-container *ngIf='element.billingType===2'>
                  <span> {{ "Rental"
                    }}</span>
                </ng-container>
                <ng-container *ngIf='element.billingType ===3 '>
                  <span> {{ "Purchase / Rental"
                    }}</span>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="DOS">
              <th class="header" mat-header-cell *matHeaderCellDef>
                DOS
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.mitemDateOfService}} </td>
            </ng-container>


            <ng-container matColumnDef="item">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Item
              </th>

              <td mat-cell *matCellDef="let element"> {{element?.itemDetails[0]?.itemProcedureCodeId}} </td>
            </ng-container>
            <ng-container matColumnDef="insurance">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Insurance
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.provider}} </td>
            </ng-container>

            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Options
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- <a (click)="viewClaimDetailsById(element?.claimId,element?.id,element)" class="eyeCursorclass"
                        target="_blank"><i class="fa fa-eye"></i> </a> -->
                <a (click)="viewClaimDetailsById(element)" class="eyeCursorclass" target="_blank"><i
                    class="fa fa-eye"></i> </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection?.toggle(element) : null"
                  [disabled]="!element.clearingHouseType" [checked]="selection?.isSelected(element)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="14">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="14">
                <div class="row">
                  <div class="col-6 mt-3">

                    <button class="buttonColor" [disabled]="saveButtonHide" *ngIf="!selection?.isEmpty()"
                      (click)="getProccessSelectedClaimProcess(selection?.selected)" mat-button>Manual Process
                      Selected {{selection?.selected?.length}} Claims
                      <mat-icon *ngIf="isLoading" class='mr-2 ml-2'>
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                      </mat-icon>
                    </button>
                    <button class="buttonColor ml-2" [disabled]="saveButtonHide" *ngIf="!selection?.isEmpty()"
                      (click)="getElectronicProcess(selection?.selected)" mat-button>zermid Process
                      Selected {{selection?.selected?.length}} Claims
                      <mat-icon *ngIf="isLoadings" class='mr-2 ml-2'>
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                      </mat-icon>
                    </button>
                  </div>
                  <div class="col-6">
                    <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                      [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                      [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }">
            </tr>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </form>
        </table>


      </div>
    </div>
  </mat-card>
</div>
