<mat-accordion>
    <mat-expansion-panel (toggle)="(step)" [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
        <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
                <ng-container *ngIf="documentBinId === defaultGuid; else elseTitleTemplate">
                    <b>Add New Bin</b>
                </ng-container>
                <ng-template #elseTitleTemplate>
                    <b>Edit Bin</b>
                </ng-template>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="mstDocumentBinForm">
            <div class="">

                <div class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Bin Name<span class="asterisk">*</span></mat-label>
                        <input matInput formControlName="txtBinName" placeholder="Enter Document Bin Name"
                            maxlength="50" autocomplete="new-txtBinName" type="text" oninput="this.value = this.value.replace(/[^a-zA-Z0-9-\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);"/>
                        <mat-error *ngIf="
                mstDocumentBinForm?.get('txtBinName')?.touched &&
                mstDocumentBinForm?.get('txtBinName')?.errors?.required
              ">
                            Bin Name is a required field!
                        </mat-error>
                        <mat-error *ngIf="mstDocumentBinForm?.get('txtBinName')?.errors?.isDocumentBinExists">
                            Bin Name is already taken!
                        </mat-error>
                    </mat-form-field>
                    <button style="height: 35px" mat-button class="buttonColor mt-1 mr-1" (click)="saveBin()"
                        [disabled]="
              saveButtonHide ||
              !(!mstDocumentBinForm.invalid && mstDocumentBinForm.dirty)
            ">
                        Save
                        <mat-icon *ngIf="isShowSpinner">
                            <mat-spinner class="spinner-border spinner-border-sm" diameter="90">
                            </mat-spinner>
                        </mat-icon>
                    </button>
                    <button mat-button style="height: 35px" class="resetclr bg-danger buttonColor mt-1"
                        (click)="resetBinForm()">
                        Reset
                    </button>
                </div>

            </div>
        </form>
        <br />
    </mat-expansion-panel>
</mat-accordion>
<br>
<div class="card card-body">
    <div class="row">
        <div class="col-12">
            <div class="table table-responsive">
                <table id="tbl" datatable [dtOptions]="dtOptions" class="tbl hover row-border hover w-100 display">
                    <thead>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th [matTooltip]="'Status'">Status</th>
                            <th [matTooltip]="'Document Bin Name'">Document Bin Name</th>
                            <th [matTooltip]="'Created By'">Created By</th>
                            <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
                            <th [matTooltip]="'Modify By'">Modify By</th>
                            <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of documnetDataList " [ngClass]="{stat_highlight:data.isStat == true}">

                            <td>
                                <ng-container>
                                    <a (click)="viewDocumentBinById(data?.id)"
                                        [ngClass]="{'disabled-link': data?.isDefault}" matTooltip="Click To Edit Bin">
                                        <i class="fa fa-pencil pointer editBtnColor mr-1"></i>
                                    </a>
                                </ng-container>
                            </td>

                            <td [matTooltip]="data?.isAlreadyInUse ? 'Bin In Use' : 'Not Used' || '-'">
                                <ng-container *ngIf="data?.isActive==1">
                                    <ng-container *ngIf="data?.isAlreadyInUse==false">
                                        <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true'>
                                            {{data?.status}} Not Used</mat-slide-toggle>
                                    </ng-container>
                                    <ng-container *ngIf="data?.isAlreadyInUse==true">
                                        Bin In Use
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="data?.isActive==0">
                                    <ng-container *ngIf="data?.isAlreadyInUse==false">
                                        <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false'>
                                            {{data?.status}} Not Used</mat-slide-toggle>
                                    </ng-container>
                                    <ng-container *ngIf="data?.isAlreadyInUse==true">
                                        Bin In Use
                                    </ng-container>
                                </ng-container>
                            </td>

                            <td [matTooltip]="data?.documentType || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.documentType || '-')">
                                {{ data?.documentType }}
                            </td>
                            <td [matTooltip]="data?.createdBy || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{
                            data?.createdBy || '-' }}</td>

                        <td [matTooltip]="data?.creationTime"
                            (dblclick)="onRowDoubleClickV1(data?.creationTime)" class="text-right">
                            {{ data?.creationTime }}
                        </td>
                        <td [matTooltip]="data?.modifyBy|| '-'"
                            (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{
                            data?.modifyBy || '-' }}</td>

                        <td [matTooltip]="data?.lastModificationTime"
                            (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" class="text-right">
                            {{ data?.lastModificationTime }}
                        </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Status"
                                    name="search-Status" />
                            </th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Document Bin Name"
                                    name="search-DocumentBinName" />
                            </th>
                            <th>
                              <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                                  name="search-Created By" />
                          </th>
                          <th class="text-right">
                              <input matInput class="textbox-border-class t" type="text"
                                    placeholder="Created Date" name="search-Created Date" />
                           </th>
                           <th>
                              <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                                  name="search-Modify By" />
                          </th>
                            <th class="text-right">
                                <input matInput class="textbox-border-class " type="text"
                                    placeholder="Modified Date" name="search-Modified Date" />
                            </th>
                            
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
