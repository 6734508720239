import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { IdentityUserUsingTenantIdDto, IdentityUserWithRoleDto, UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { OrderNotesComponent } from 'projects/order/src/app/order-notes/order-notes.component';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddNotesComponent } from '../add-notes/add-notes.component';

import { ConfirmationDialogService } from '../confirmation-dialog.service';
import { AuthVisitsDTO, TypeOfPlanDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { AccountGroupDTO, BranchOfficeDTO, ClaimFillingCodeDTO, CustomerTypeDTO, FacilityDTO, GenderDTO, LocationTypeDTO, PlaceOfServiceDTO, PTSecurityGroupDTO, TaxZoneDTO, ZoneDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { PatientService } from '../patient-proxy/patient';
import { ClinicalDTO, CreateUpdatePatientIntakeDTO, PatientDTO, PatientLockDTO, PersonalDTO } from '../patient-proxy/patient/dto';
import { addPatientIntake, getPatient, updatePatient, updatePatientIntake } from '../patient-store/patient.action';
import { ToastrService } from "ngx-toastr";

// import { OrderDropdowns } from 'projects/order/src/app/order-proxy/order-management/order/order-dropdowns.enum';


@Component({
  selector: 'app-patient-create-edit',
  templateUrl: './patient-create-edit.component.html',
  styleUrls: ['./patient-create-edit.component.scss']
})
export class PatientCreateEditComponent implements OnInit, OnDestroy {
  savedPersonalData: any;
  savedContactData: any;
  savedClinicalData: any;
  savedDocumentData: any;
  savedInsuranceData: any;
  savedAuthorizationData: any;
  personalForm: FormGroup;
  contactsForm: FormGroup;
  clinicalForm: FormGroup;
  insuranceForm: FormGroup;
  authorizationForm: FormGroup;
  @ViewChild('nav') nav: NgbNav;
  strUrlPath: string = "";
  patientId: string = "";
  isOrderViewPermission: boolean = true;
  isOrderCreatePermission: boolean = true;
  isShownUnlockButton: boolean = false;
  isPatientLocked: boolean = true;
  isShownAuthTab: boolean = true;
  isShownOrderTab: boolean = true;
  forBlink: boolean = true
  lblDefaultPatientId: string = "";
  lblLastUpdatedDateTime: string | Date;
  lblPatientName: string = "";
  lblPatientGender: string = "";
  lblPatientDOB: string = "";
  lblPatientHeight: string = "";
  lblBillingAmount: string = "";
  lblBillingStatus: string = "";
  lblPatientWeight: string = "";
  lblBranchOffice: string = "";
  lblAccountNumber: string = "";
  lblPtCurrentStatus: string = "";
  lblSsnNumber: string = "";
  drpBranchOffices: BranchOfficeDTO[] = [];
  drpGenderLoop: GenderDTO[] = [];
  strLocation: string = "";
  patientStatus: string = "";
  strBillingAddress: string = "";
  strBillingState: string = "";
  strBillingCity: string = "";
  strBillingCountry: string = "";
  strPostalCode: string = "";
  strMobile: string = "";
  strEmailAddress: string = "";
  drpAccountGroups: AccountGroupDTO[];
  drpZones: ZoneDTO[];
  drpPTSecurityGroups: PTSecurityGroupDTO[];
  drpCustomerTypes: CustomerTypeDTO[];
  drpLocationTypes: LocationTypeDTO[];
  // drpCountries: CountryDTO[];
  drpClaimFillingCodes: ClaimFillingCodeDTO[];
  drpPlaceOfServices: PlaceOfServiceDTO[];
  drpFacility: FacilityDTO[];
  drpTaxZone: TaxZoneDTO[];
  // drpCity: CityDTO[];
  // drpState: StateDTO[];
  // drpCounty: CountyDTO[];
  @Output() patientIdEmitter: EventEmitter<CreateUpdatePatientIntakeDTO> = new EventEmitter<CreateUpdatePatientIntakeDTO>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() savePersonalFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveClinicalFormState: EventEmitter<any> = new EventEmitter<any>();
  showswal = true;
  organizationUnitId = null;
  drpPlan: TypeOfPlanDTO[] = [];
  authorizationId: string = "00000000-0000-0000-0000-000000000000";
  tblNotesReponse: any[] = [];
  drpAuthVisit: AuthVisitsDTO[] = [];
  strLoginUserId: string = '';
  strLoginUserName: string = '';
  subscription$: Subscription[] = []
  lbllastModifier: string = '';
  lstUsers: IdentityUserWithRoleDto[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    public title: Title,
    public dialog: MatDialog,
    private store: Store,
    private Table: TableService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private confirmationDialogService: ConfirmationDialogService,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private region: RegionDropdownService,
    private text: TextValidators,
    private orderDropdownService: OrderMasterDropdownService,
    private patientService: PatientService,
    private toastr: ToastrService,
    private userService: UserService,

  ) {
    this.getdropdowns();
  }
  changeNextTab(value: string) {
    value === "Personal" && this.nav?.select("Contact");
    value === "Contact" && this.nav?.select("Clinical");
    value === "Clinical" && this.nav?.select("Document");
    value === "Document" && this.nav?.select("Insurance");
    value === "Insurance" && this.nav?.select("Authorization");
    value === "Authorization" && this.nav?.select("Notes");
    value === "Notes" && this.nav?.select("OrderStatus");
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    //   this.oauthService.loadDiscoveryDocument()



    //   // 1. HASH LOGIN:
    //   // Try to log in via hash fragment after redirect back
    //   // from IdServer from initImplicitFlow:
    //   .then(() => this.oauthService.tryLogin())

    //   .then(() => {
    //     if (!this.oauthService.hasValidAccessToken()) {
    //

    //       // 2. SILENT LOGIN:
    //       // Try to log in via silent refresh because the IdServer
    //       // might have a cookie to remember the user, so we can
    //       // prevent doing a redirect:
    //       this.oauthService.silentRefresh()
    //         .catch(result => {
    //           // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
    //           // Only the ones where it's reasonably sure that sending the
    //           // user to the IdServer will help.
    //           const errorResponsesRequiringUserInteraction = [
    //             'interaction_required',
    //             'login_required',
    //             'account_selection_required',
    //             'consent_required',
    //           ];

    //           if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

    //             // 3. ASK FOR LOGIN:
    //            // At this point we know for sure that we have to ask the
    //             // user to log in, so we redirect them to the IdServer to
    //             // enter credentials:
    //             this.oauthService.initImplicitFlow();
    //           }
    //         });
    //     }
    // });
    const getLogin = this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    });
    this.subscription$.push(getLogin);
    //
    const getLoginUser = this.Table.getLoginUserName().subscribe(val => {
      this.strLoginUserName = val;
    })
    this.subscription$.push(getLoginUser)
    this.snackBar.dismiss();
    //
    const getOrderView = this.Table.getOrderView().subscribe(value => {
      this.isOrderViewPermission = value;
      if (this.isOrderViewPermission == true) {
        this.isShownOrderTab = true;
      }
      else {
        this.isShownOrderTab = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getOrderView);
    //
    const orderCreate = this.Table.getOrderCreate().subscribe(value => {
      this.isOrderCreatePermission = value;
      if (this.isOrderCreatePermission == true) {
        this.isShownAuthTab = true;
      }
      else {
        this.isShownAuthTab = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getOrderView);
    //
    const activatedRoute = this.activatedRoute.paramMap.subscribe(response => {
      this.patientId = response.get('patientId');
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(activatedRoute);
    //
    const activatedRoute1 = this.activatedRoute.url.subscribe(value => {
      if (value[0].path === "patientCreateEdit") {
        this.strUrlPath = "patientCreateEdit";
        this.title.setTitle("Qsecure | Create Patient");
      } else {
        setTimeout(() => {
          //     this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getDetailsByPatientID(this.patientId);
        }, 400);
        this.strUrlPath = "patientViewEdit";

        this.title.setTitle("Qsecure | View Patient");
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(activatedRoute1);
    //
    const hearderData = this.Table.getheaderData().subscribe(value => {
      // this.patientId = value;
      setTimeout(() => {

        this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getDetailsByPatientID(this.patientId);
      }, 400);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(hearderData);
    //
    this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getDetailsByPatientID(this.patientId);
    this.createPatientForm();
    this.getDropdownForAuthorization();
  }


  // create formgroup for tab change save
  createPatientForm() {
    this.personalForm = this.formBuilder.group({
      txtChartId: new FormControl(""),
      drpLocation: new FormControl(null, Validators.required),
      txtFirstName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtLastName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtMiddleName: new FormControl("", [this.textValidators.isTextCheck]),
      drpGeneralGender: new FormControl(null),
      drpMarital: new FormControl(null),
      txtSuffixName: new FormControl("", [this.textValidators.isTextCheck]),
      txtSSN: new FormControl("", [Validators.minLength(4)]),
      txtHomePhoneNo: new FormControl(""),
      txtWorkPhoneNo: new FormControl(""),
      txtDeliveryPhone: new FormControl(""),
      txtAccountNumber: new FormControl("", [Validators.minLength(9)]),
      drpSuffix: new FormControl(null),
      drpCustomerType: new FormControl(null),
      txtPriorSystemKeyFacility: new FormControl("", [this.textValidators.isTextCheck]),
      drpFacility: new FormControl(null),
      txtAlternateIDType: new FormControl(""),
      txtEthinicity: new FormControl(""),
      txtRace: new FormControl(""),
      chkHoldAccount: new FormControl(""),
      chkHoldBillingStatement: new FormControl(""),
      chkHipaaSignature: new FormControl(""),
      txtDiscountPercent: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      txtTaxZone: new FormControl(null),
      drpBranchOffice: new FormControl(null),
      drpAccountGroup: new FormControl(null, Validators.required),
      drpPTSecurityGroup: new FormControl(null),
      txtUser1: new FormControl(""),
      txtUser2: new FormControl(""),
      txtUser3: new FormControl(""),
      txtUser4: new FormControl(""),
      txtBillingCountryFilter: new FormControl(""),
      txtBillingStateFilter: new FormControl(""),
      txtBillingCityFilter: new FormControl(""),
      txtBillingAddress: new FormControl(""),
      txtBillingCity: new FormControl(null),
      drpBillingCounty: new FormControl(null, this.textValidators.isTextCheck),
      drpBillingCountry: new FormControl(null),
      txtBillingPostalCode: new FormControl(""),
      txtBillingPhone: new FormControl(""),
      txtBillingFax: new FormControl(""),
      drpBillingZone: new FormControl(null),
      txtBillingMobile: new FormControl(""),
      txtBillingState: new FormControl(null),
      txtBillingEmailAddress: new FormControl(""),
      txtDeliveryCountryFilter: new FormControl(""),
      txtDeliveryStateFilter: new FormControl(""),
      txtDeliveryCityFilter: new FormControl(""),
      txtDeliveryAddress: new FormControl(""),
      txtDeliveryCity: new FormControl(null),
      txtDeliveryState: new FormControl(null),
      drpDeliveryCounty: new FormControl(null, this.textValidators.isTextCheck),
      drpDeliveryCountry: new FormControl(null),
      txtDeliveryPostalCode: new FormControl(""),
      txtDeliveryFax: new FormControl(""),
      txtDeliveryMobile: new FormControl(""),
      txtDeliveryEmailAddress: new FormControl(""),
      txtBirthdate: new FormControl(null, [Validators.required]),
      txtAuditDateCreated: new FormControl(new Date()),
      txtPlaceOfServiceDateOfAdmission: new FormControl(new Date()),
      txtPlaceOfServiceDateOfDischarge: new FormControl(null),
      drpZone: new FormControl(null),
      drpPlaceOfService: new FormControl(null),
      txtAuditCreatedBy: new FormControl("", this.textValidators.isTextCheck),
      chkCashPayment: new FormControl(""),
      chkStat: new FormControl(""),
      // txtName: new FormControl(""),
      //! newly added
      drpColor: new FormControl(null),
      chkPatientIntrest: new FormControl(""),
      txtCatogery: new FormControl(""),
      txtProvider: new FormControl("", [Validators.required]),
      txtPatientBalance: new FormControl("", this.numberValidators.isNumberCheck),
      txtInsuranceBalance: new FormControl("", this.numberValidators.isNumberCheck),
      txtTotalBalance: new FormControl("", this.numberValidators.isNumberCheck),
      txtAlerts: new FormControl(""),
      chkReceiveBill: new FormControl(""),
      txtStatementTo: new FormControl("", this.textValidators.isTextCheck),
      drpHoldReason: new FormControl(""),
      chkCollection: new FormControl(""),
      chkHoldClaims: new FormControl(""),
      txtHoldNotes: new FormControl("", this.textValidators.isTextCheck),
      chkDoNotCall: new FormControl(""),
      drpDunningMessage: new FormControl(""),
      chkAutoDunning: new FormControl(""),

      phones: this.formBuilder.array([
        this.formBuilder.control(null)
      ]),
    })
    this.contactsForm = this.formBuilder.group({
      ContactsGroup: new FormGroup({
        drpEmergencyRelationshipType: new FormControl(null),
        txtEmergencyFirstName: new FormControl("", [this.textValidators.isTextCheck]),
        txtEmergencyMiddleName: new FormControl("", [this.textValidators.isTextCheck]),
        txtEmergencyLastName: new FormControl("", [this.textValidators.isTextCheck]),
        txtEmergencyAddress: new FormControl(""),
        txtEmergencyCity: new FormControl(null),
        txtEmergencyState: new FormControl(null),
        txtEmergencyCounty: new FormControl(null),
        txtEmergencyCountry: new FormControl(null),
        txtEmergencyPostalCode: new FormControl(""),
        txtEmergencyPhone: new FormControl("", this.numberValidators.isNumberCheck),
        txtEmergencyMobile: new FormControl("", this.numberValidators.isNumberCheck),
        txtEmergencyEmail: new FormControl("", Validators.email),
        txtEmergencyFax: new FormControl("", this.numberValidators.isNumberCheck),
        drpResponsibleRelationshipType: new FormControl(null),
        txtResponsibleFirstName: new FormControl("", [this.textValidators.isTextCheck]),
        txtResponsibleMiddleName: new FormControl("", [this.textValidators.isTextCheck]),
        txtResponsibleLastName: new FormControl("", [this.textValidators.isTextCheck]),
        txtResponsibleAddress: new FormControl(""),
        txtResponsibleCity: new FormControl(null),
        txtResponsibleState: new FormControl(null),
        txtResponsibleCountry: new FormControl(null),
        txtResponsibleCounty: new FormControl(null),
        txtResponsiblePostalCode: new FormControl(""),
        txtResponsiblePhone: new FormControl(""),
        txtResponsibleMobile: new FormControl("", this.numberValidators.isNumberCheck),
        txtResponsibleEmail: new FormControl("", Validators.email),
        txtResponsibleFax: new FormControl("", this.numberValidators.isNumberCheck),
      }),
    });
    this.clinicalForm = this.formBuilder.group({
      ClinicalGeneral: new FormGroup({
        drpGeneralGender: new FormControl(null, [Validators.required]),
        // txtGeneralHeight: new FormControl(""),
        txtGeneralHeightInches: new FormControl("", [Validators.min(0), Validators.max(11), this.numberValidators.isNumberCheck],),
        txtGeneralHeightFeet: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtGeneralWeight: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtGeneralCondition: new FormControl(""),
        txtAirborne: new FormControl(""),
        txtContact: new FormControl(""),
        txtDroplet: new FormControl(""),
        txtGeneralFunctionalAbility: new FormControl(null),
        txtGeneralPractioner: new FormControl(null),
        txtGeneralDod: new FormControl(""),
        txtRenderingType: new FormControl(null),
        txtRenderingDoctor: new FormControl(null),
        txtRenderingFacility: new FormControl(null),
        txtReferringType: new FormControl(null),
        txtReferringDoctor: new FormControl(null),
        txtReferringFacility: new FormControl(""),
        txtReferringContact: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtReferringPhone: new FormControl(""),
        txtOrderingDoctor: new FormControl(null),
        txtPrimaryDoctor: new FormControl(null),
        txtPrimaryDoctorSearchFilter: new FormControl(null),
        txtMarketingRep: new FormControl(null),
        txtReferralType: new FormControl(null),
        txtReferralDoctor: new FormControl(null),
        txtReferralFacility: new FormControl(""),
        txtOrdSearchFilter: new FormControl(""),
        txtMarRepSearchFilter: new FormControl(""),
        txtRenDoctorFilter: new FormControl(null),
        txtRefDoctorFilter: new FormControl(""),
        txtMarDoctorFilter: new FormControl(""),
        txtPractitionerSearchFilter: new FormControl(""),
      }),
      ClinicalDiagnosisGroup: new FormGroup({
        txtDiagnosisCodes9: new FormControl(null),
        txtdiagnosisCode10Id1: new FormControl(null, [Validators.required]),
        txtdiagnosisCode10Id2: new FormControl(null),
        txtdiagnosisCode10Id3: new FormControl(null),
        txtIcd10Filter1: new FormControl(null),
        txtIcd10Filter2: new FormControl(null),
        txtIcd10Filter3: new FormControl(null),
        txtIcd9Filter: new FormControl(null),
      }),
      ClinicalAppointmentGroup: new FormGroup({
        txtAppointmentdate: new FormControl(""),
        txtAppointmentBranch: new FormControl(""),
        txtAppointmentDescription: new FormControl(""),
        txtAppointmentPractioner: new FormControl(""),
        txtAppointmentStatus: new FormControl(""),
      })
    });
    this.insuranceForm = this.formBuilder.group({
      InsuranceGeneral: new FormGroup({
        chkInjuryEmployment: new FormControl(""),
        chkInjuryAutoAccident: new FormControl(""),
        chkInjuryOtherAccident: new FormControl(""),
        drpInsStateofAutoAccident: new FormControl(""),
        drpInsMarital: new FormControl(""),
        drpInsEmployment: new FormControl(""),
        chkInsEnableMedicare: new FormControl(""),
        chkInsPrintAmounts: new FormControl(""),
        chkInsHardshipPatient: new FormControl(""),
        txtDateOnSet: new FormControl(""),
        txtDiscount: new FormControl(""),
        txtStartDate: new FormControl(""),
        txtReviewDate: new FormControl(""),
      }),
      InsAlertsAndPolicies: new FormGroup({
        //   chkActivePolicies: new FormControl(""),
      }),
    });
    this.authorizationForm = this.formBuilder.group({
      txtAuthId: new FormControl("", [Validators.required]),
      drpAuthVisit: new FormControl(null),
      txtAuthAmount: new FormControl(""),
      txtStartDate: new FormControl(""),
      txtEndDate: new FormControl(""),
      drpCategory: new FormControl(""),
      drpItem: new FormControl(null),
      txtNote: new FormControl(""),
      chkCompleted: new FormControl(""),
      txtCompletedBy: new FormControl("", [this.text.isTextCheck]),
      drpPlan: new FormControl(null, [Validators.required]),

    });
  }

  //! Unlock Patient Function
  unlockPatient() {
    const patientUnlock = this.patientService.unlockPatientByPatientId(this.patientId).subscribe(response => {
      if (response != null) {
        // Swal.fire({ title: 'Success', html: 'Unlocked Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Unlocked Successfully','Success')
        this.isShownUnlockButton = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientUnlock);
  }
  getdropdowns() {
    // let patientDropdownInput : PatientDropdowns[];
    // patientDropdownInput = [
    //   PatientDropdowns.BranchOffices,
    //   PatientDropdowns.Genders,
    // ]
    // this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
    //   let reponse: PatientMasterDropdownDTO = stateResponse;
    //   this.drpBranchOffices = reponse?.branchOffices;
    //   this.drpGenderLoop = reponse?.genders;
    //   //this.drpBranchOffices = reponse?.branchOffices;
    //   //this.drpAccountGroups = reponse?.accountGroups;
    //   //this.drpZones = reponse?.zones;
    //   //this.drpPTSecurityGroups = reponse['ptSecurityGroups'];
    //   // this.drpCustomerTypes = reponse?.customerTypes;
    //   //this.drpLocationTypes = reponse?.locationTypes;
    //   // this.drpCounty = reponse?.counties;
    //   // this.drpClaimFillingCodes = reponse?.claimFillingCodes;
    //   // this.drpPlaceOfServices = reponse?.placeOfServices;
    //   // this.drpFacility = reponse?.facilities;
    //   // this.drpTaxZone = reponse['taxZones'];
    //   //this.drpCity = reponse?.cities;
    //   //this.drpState = reponse?.states;
    //   //this.drpCounty = reponse?.counties;
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // })
    const filterUserByTenant: IdentityUserUsingTenantIdDto = {
      tenantId: localStorage?.getItem("tenantId") ?? ""
    }

    this.userService.getUserList(filterUserByTenant).subscribe(response => {
      this.lstUsers = response ?? [];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }

  //// sidebar data binding
  countryShortName: string = '';
  getDetailsByPatientID(patientId: string) {
    const storeSubscription = this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.store.dispatch(new getPatient(patientId)).subscribe(responseData => {

      let personalResponse: PersonalDTO = responseData?.patientState?.selectedPatient?.personals;

      // To Show Unlock button based on lockStatus and userId
      let patientLockResponse: PatientLockDTO = responseData?.patientState?.selectedPatient?.patientLock;
      if (patientLockResponse !== null || patientLockResponse !== undefined) {
        if (patientLockResponse?.isLocked === true &&
          ((patientLockResponse?.userId === this.strLoginUserId) ||
            this.strLoginUserName === 'admin')) {
          this.isShownUnlockButton = true;
        }
        // else if(patientLockResponse?.status === PatientLockStatus.Locked &&
        //   patientLockResponse?.userId !== this.strLoginUserId){
        //     this.isPatientLocked = false;
        //     this.Table.setPatientLockStatus(this.isPatientLocked);
        //   }
        // else if(patientLockResponse?.status === PatientLockStatus.UnLocked){
        //   this.isPatientLocked = true;
        //   this.Table.setPatientLockStatus(this.isPatientLocked);
        // }

      }
      let patientResponse: PatientDTO = responseData?.patientState?.selectedPatient;
      let clinicalResponse: ClinicalDTO = responseData?.patientState?.selectedPatient?.clinicals;
      this.lblLastUpdatedDateTime = patientResponse?.lastModificationTime;
      this.lbllastModifier = patientResponse?.lastModifierId ?? "";
      this.lbllastModifier = this.lstUsers?.filter((u: { id: string; }) => u?.id === patientResponse?.lastModifierId)[0]?.userName ?? "";

      if (personalResponse !== null || personalResponse !== undefined) {
        this.lblPtCurrentStatus = patientResponse?.currentStatus;
        this.lblDefaultPatientId = responseData?.patientState?.selectedPatient?.defaultPatientId?.toString();
        this.patientStatus = responseData?.patientState?.selectedPatient?.currentStatus;
        this.lblPatientName = personalResponse?.fullName;
        // this.lblPatientGender = clinicalResponse?.genderType == null ? "" : clinicalResponse?.genderType,
        this.lblPatientGender = responseData?.patientState.selectedPatient?.clinicals?.genderType === 'M' ? "Male" : responseData?.patientState.selectedPatient?.clinicals?.genderType === 'F' ? "Female" : responseData?.patientState.selectedPatient?.clinicals?.genderType === "U" ? "Unknown" : '',
          this.lblPatientDOB = personalResponse?.dateOfBirth == null ? "" : new Date(personalResponse?.dateOfBirth)?.toLocaleDateString("en-US");
        this.lblPatientHeight = clinicalResponse?.height === 0 ? "" : clinicalResponse?.height.toString();
        this.lblPatientWeight = clinicalResponse?.weight === 0 ? "" : clinicalResponse?.weight.toString();
        //  this.lblBranchOffice = personalResponse?.branchOffice == null ? "" : personalResponse?.branchOffice
        this.lblAccountNumber = personalResponse?.accountNumber;
        this.lblSsnNumber = personalResponse?.ssn;
        this.patientId = responseData?.patientState?.selectedPatient?.id;
        this.lblBranchOffice = personalResponse?.branchOffice;
        if (patientResponse?.billingDetails != null) {
          this.lblBillingAmount = patientResponse?.billingDetails.amount;
          this.lblBillingStatus = patientResponse?.billingDetails.status;
        }
        if (patientResponse?.billingDetails?.status === "Processed") {
          this.forBlink = false;
        }


        //this.lblBranchOffice = this.drpBranchOffices?.filter(value => { return value && value?.branchOfficeShortCodeId === personalResponse?.branchOffice })[0]?.branchOffice;
        // this.strLocation = this.drpLocationTypes?.filter(value => { return value && value?.locationTypeShortCodeId === patientResponse?.personals?.locationType })[0].locationType;
        // this.strLocation = this.drpLocationTypes?.filter(value => { return value && value?.locationTypeShortCodeId === patientResponse?.personals?.locationType })[0].locationType;
        this.strLocation = personalResponse?.locationType;
        this.strBillingAddress = patientResponse?.addresses[0].address;

        this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
          this.strBillingCountry = response?.items?.filter(value => { return value && value?.countryShortName === patientResponse?.addresses[0]?.country })[0]?.countryName;
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });


        patientResponse?.addresses[0]?.country != null && patientResponse?.addresses[0]?.country !== "" && patientResponse?.addresses[0]?.country != undefined &&
          this.region.getAllStatesByCountryNameByCountryShortName(patientResponse?.addresses[0]?.country).subscribe(response => {
            this.strBillingState = response?.items?.filter(value => { return value && value?.stateShortName === patientResponse?.addresses[0]?.state })[0]?.stateName
          }, err => {
            const data: HttpErrorResponse = err;
            // Swal.fire({
            //   icon: 'error',
            //   text: data?.error?.error?.message,
            // });
          });

        //this.strBillingState = patientResponse?.addresses[0].state; //this.drpState?.filter(value => { return value && value?.stateId === patientResponse?.addresses[0].state })[0]?.stateType;
        this.strBillingCity = patientResponse?.addresses[0].city;//this.drpCity?.filter(value => { return value && value?.cityId === patientResponse?.addresses[0].city })[0]?.cityType;
        //this.drpCountries?.filter(value => { return value && value?.countryTypeId === patientResponse?.addresses[0].country })[0]?.countryType;

        this.strPostalCode = patientResponse?.addresses[0]?.postalCode;
        this.strMobile = patientResponse?.addresses[0]?.mobile;
        //this.strEmailAddress = patientResponse?.addresses[0]?.emailId;
        this.strEmailAddress = patientResponse?.personals?.emailId;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(storeSubscription);
  }

  //// to get PatientId
  patientDetailsAfterSave($event: PatientDTO) {
    if (!($event === null || $event === undefined || $event.id === "")) {
      this.patientId = $event.id;
      //To show unlock button only if lockStatus is locked
      if ($event?.patientLock?.isLocked === true) {
        this.isShownUnlockButton = true;
      }
    }
  }

  // tab change alert codings

  onSavePersonalData(value: any) {
    this.savedPersonalData = value;
  }
  onSaveContactData(value: any) {
    this.savedContactData = value;
  }
  onSaveClinicalData(value: any) {
    this.savedClinicalData = value;
  }
  onSaveDocumentData(value: any) {
    this.savedDocumentData = value;
  }
  onSaveInsuranceData(value: any) {
    this.savedInsuranceData = value;
  }
  onSaveAuthorizationData(value: any) {
    this.savedAuthorizationData = value;
  }
  // getConfirmDialog
  beforeUnloadHander(event) {
    // this.PostCall();
    this.getConfirmationDialog(event);
    // return event.returnValue = "something..."
  }
  getConfirmationDialog(event: any) {
    event.returnValue = "something...."
    if ((this.savedPersonalData && this.savedPersonalData.dirty) || (this.savedContactData && this.savedContactData.dirty) || (this.savedClinicalData && this.savedClinicalData.dirty) || (this.savedInsuranceData && this.savedInsuranceData.dirty)) {
      this.confirmationDialogService.confirm('Please confirm..', 'Do you want to save the changes ... ?')
        .then((confirmed) => {

          if ((confirmed == true) && this.savedPersonalData && this.savedPersonalData.dirty) {
            this.savePersonalForm();
          } else {
            this.personalForm.reset();
          }

          if ((confirmed == true) && this.savedContactData && this.savedContactData.dirty) {
            this.saveContactForm();
          } else {
            this.contactsForm.reset();
          }
          if ((confirmed == true) && this.savedClinicalData && this.savedClinicalData.dirty) {
            this.saveclinicalForm();
          } else {
            this.clinicalForm.reset();
          }
          if ((confirmed == true) && this.savedInsuranceData && this.savedInsuranceData.dirty) {
            this.saveInsuranceForm();
          } else {
            this.insuranceForm.reset();
          }
          if ((confirmed == true) && this.savedAuthorizationData && this.savedAuthorizationData.dirty) {
            this.saveInsuranceForm();
          } else {
            this.savedAuthorizationData.reset();
          }
        })
        .catch((e) => e);
    }
  }


  onNavChange($event: NgbNavChangeEvent) {
    // if (this.savedPersonalData && this.showswal && this.savedPersonalData.dirty) {
    //   if (($event.nextId === "Clinical") || ($event.nextId === "Contact") || ($event.nextId === 'Document') || ($event.nextId === 'Order') || ($event.nextId === 'Insurance') || ($event.nextId === 'Authorization') || ($event.nextId === 'Notes') || ($event.nextId === 'OrderStatus') || ($event.nextId === 'History')) {
    //     Swal.fire({
    //       title: 'Changes Has Been Done?',
    //       text: 'Would You Like To Save The Changes!',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#34c38f',
    //       cancelButtonColor: '#f46a6a',
    //       confirmButtonText: 'Yes!',
    //       cancelButtonText: 'No',
    //       allowOutsideClick: false,


    //     }).then(result => {
    //       if (result.value) {
    //         this.savePersonalForm();
    //       } else {
    //         this.savedPersonalData = [];
    //         this.showswal = false
    //       }
    //     });
    //   }
    //   this.showswal = false;
    // }
    // this.showswal = true;
    // if (this.savedContactData && this.showswal && this.savedContactData.dirty) {
    //   if (($event.nextId === "Personal") || ($event.nextId === "Clinical") || ($event.nextId === 'Document') || ($event.nextId === 'Order') || ($event.nextId === 'Insurance') || ($event.nextId === 'Authorization') || ($event.nextId === 'Notes') || ($event.nextId === 'OrderStatus') || ($event.nextId === 'History')) {
    //     Swal.fire({
    //       title: 'Changes Has Been Done?',
    //       text: 'Would You Like To Save The Changes!',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#34c38f',
    //       cancelButtonColor: '#f46a6a',
    //       confirmButtonText: 'Yes!',
    //       cancelButtonText: 'No',
    //       allowOutsideClick: false,
    //     }).then(result => {
    //       if (result.value) {
    //         this.showswal = false;
    //         this.saveContactForm();
    //       } else {
    //         this.showswal = false;
    //         this.savedContactData = [];
    //       }
    //     })

    //   }
    //   this.showswal = true;
    // }
    // this.showswal = true;
    //clinical
    if (this.savedClinicalData && this.showswal && this.savedClinicalData.dirty) {
      if (($event.nextId === "Personal") || ($event.nextId === "Contact") || ($event.nextId === 'Document') || ($event.nextId === 'Order') || ($event.nextId === 'Insurance') || ($event.nextId === 'Authorization') || ($event.nextId === 'Notes') || ($event.nextId === 'OrderStatus') || ($event.nextId === 'History')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.saveclinicalForm();
          } else {
            this.savedClinicalData = [];
            this.showswal = false
            //  this.clinicalForm.reset(this.clinicalForm.value);
          }
        });
      }
      this.showswal = true;
    }
    if (this.savedDocumentData && this.showswal && this.savedDocumentData.dirty) {
      $event.nextId = "tab-5"
      if (($event.nextId === "Personal") || ($event.nextId === "Contact") || ($event.nextId === 'Clinincal') || ($event.nextId === 'Order') || ($event.nextId === 'Insurance') || ($event.nextId === 'Authorization') || ($event.nextId === 'Notes') || ($event.nextId === 'OrderStatus') || ($event.nextId === 'History')) {
        Swal.fire({
          title: 'Would You Like To Save The Changes ?',
          text: 'Please Save before tab change ',
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          // cancelButtonText: 'No',
        }).then(result => {
          if (result.value) {
            // this.saveDocument();
          } else {
            this.savedDocumentData = [];
            this.showswal = false
            //  this.clinicalForm.reset(this.clinicalForm.value);
          }
        });
      }
      this.showswal = true;
    }
    if (this.savedInsuranceData && this.showswal && this.savedInsuranceData.dirty) {
      if (($event.nextId === "Personal") || ($event.nextId === "Contact") || ($event.nextId === 'Document') || ($event.nextId === 'Order') || ($event.nextId === 'Clinical') || ($event.nextId === 'Authorization') || ($event.nextId === 'Notes') || ($event.nextId === 'OrderStatus') || ($event.nextId === 'History')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.saveInsuranceForm();
          } else {
            this.savedInsuranceData = [];
            this.showswal = false
            //  this.clinicalForm.reset(this.clinicalForm.value);
          }
        });
      }
      this.showswal = true;
    }
    if (this.savedAuthorizationData && this.showswal && this.savedAuthorizationData.dirty) {
      if (($event.nextId === "Clinical") || ($event.nextId === "Contact") || ($event.nextId === 'Document') || ($event.nextId === 'Order') || ($event.nextId === 'Insurance') || ($event.nextId === 'Personal') || ($event.nextId === 'Notes') || ($event.nextId === 'OrderStatus') || ($event.nextId === 'History')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.saveAuthorizationForm();
          } else {
            this.savedAuthorizationData = [];
            this.showswal = false;
          }
        });
      }
      this.showswal = true;
    }
    this.showswal = true;
  }
  savePersonalForm() {
    if (this.savedPersonalData && this.savedPersonalData) {
      this.personalForm.patchValue({
        txtChartId: this.savedPersonalData['controls'].txtChartId.value,
        drpLocation: this.savedPersonalData['controls'].drpLocation.value,
        txtFirstName: this.savedPersonalData['controls'].txtFirstName.value,
        txtLastName: this.savedPersonalData['controls'].txtLastName.value,
        txtMiddleName: this.savedPersonalData['controls'].txtMiddleName.value,
        txtSuffixName: this.savedPersonalData['controls'].txtSuffixName.value,
        txtSSN: this.savedPersonalData['controls'].txtSSN.value,
        txtAccountNumber: this.savedPersonalData['controls'].txtAccountNumber.value,
        drpCustomerType: this.savedPersonalData['controls'].drpCustomerType.value,
        txtPriorSystemKeyFacility: this.savedPersonalData['controls'].txtPriorSystemKeyFacility.value,
        drpFacility: this.savedPersonalData['controls'].drpFacility.value,
        txtAlternateIDType: this.savedPersonalData['controls'].txtAlternateIDType.value,
        chkHoldAccount: this.savedPersonalData['controls'].chkHoldAccount.value,
        chkHoldBillingStatement: this.savedPersonalData['controls'].chkHoldBillingStatement.value,
        chkHipaaSignature: this.savedPersonalData['controls'].chkHipaaSignature.value,
        txtDiscountPercent: this.savedPersonalData['controls'].txtDiscountPercent.value,
        txtTaxZone: this.savedPersonalData['controls'].txtTaxZone.value,
        drpBranchOffice: this.savedPersonalData['controls'].drpBranchOffice.value,
        drpAccountGroup: this.savedPersonalData['controls'].drpAccountGroup.value,
        drpPTSecurityGroup: this.savedPersonalData['controls'].drpPTSecurityGroup.value,
        txtUser1: this.savedPersonalData['controls'].txtUser1.value,
        txtUser2: this.savedPersonalData['controls'].txtUser2.value,
        txtUser3: this.savedPersonalData['controls'].txtUser3.value,
        txtUser4: this.savedPersonalData['controls'].txtUser4.value,
        txtBillingAddress: this.savedPersonalData['controls'].txtBillingAddress.value,
        txtBillingCity: this.savedPersonalData['controls'].txtBillingCity.value,
        drpBillingCounty: this.savedPersonalData['controls'].drpBillingCounty.value,
        drpBillingCountry: this.savedPersonalData['controls'].drpBillingCountry.value,
        txtBillingPostalCode: this.savedPersonalData['controls'].txtBillingPostalCode.value,
        txtBillingPhone: this.savedPersonalData['controls'].txtBillingPhone.value,
        txtBillingFax: this.savedPersonalData['controls'].txtBillingFax.value,
        drpBillingZone: this.savedPersonalData['controls'].drpBillingZone.value,
        txtBillingMobile: this.savedPersonalData['controls'].txtBillingMobile.value,
        txtBillingState: this.savedPersonalData['controls'].txtBillingState.value,
        txtBillingEmailAddress: this.savedPersonalData['controls'].txtBillingEmailAddress.value,
        txtDeliveryAddress: this.savedPersonalData['controls'].txtDeliveryAddress.value,
        txtDeliveryCity: this.savedPersonalData['controls'].txtDeliveryCity.value,
        txtDeliveryState: this.savedPersonalData['controls'].txtDeliveryState.value,
        drpDeliveryCounty: this.savedPersonalData['controls'].drpDeliveryCounty.value,
        drpDeliveryCountry: this.savedPersonalData['controls'].drpDeliveryCountry.value,
        txtDeliveryPostalCode: this.savedPersonalData['controls'].txtDeliveryPostalCode.value,
        txtDeliveryPhone: this.savedPersonalData['controls'].txtDeliveryPhone.value,
        txtDeliveryFax: this.savedPersonalData['controls'].txtDeliveryFax.value,
        txtDeliveryMobile: this.savedPersonalData['controls'].txtDeliveryMobile.value,
        txtDeliveryEmailAddress: this.savedPersonalData['controls'].txtDeliveryEmailAddress.value,
        txtBirthdate: this.savedPersonalData['controls'].txtBirthdate.value,
        txtAuditDateCreated: this.savedPersonalData['controls'].txtAuditDateCreated.value,
        txtPlaceOfServiceDateOfAdmission: this.savedPersonalData['controls'].txtPlaceOfServiceDateOfAdmission.value,
        txtPlaceOfServiceDateOfDischarge: this.savedPersonalData['controls'].txtPlaceOfServiceDateOfDischarge.value,
        drpZone: this.savedPersonalData['controls'].drpZone.value,
        drpPlaceOfService: this.savedPersonalData['controls'].drpPlaceOfService.value,
        txtAuditCreatedBy: this.savedPersonalData['controls'].txtAuditCreatedBy.value,
        chkCashPayment: this.savedPersonalData['controls'].chkCashPayment.value,
        chkStat: this.savedPersonalData['controls'].chkStat.value,
        //! newly added
        drpColor: this.savedPersonalData['controls'].drpColor.value,
        txtEthinicity: this.savedPersonalData['controls'].txtEthinicity,
        chkPatientIntrest: this.savedPersonalData['controls'].chkPatientIntrest.value,
        txtCatogery: this.savedPersonalData['controls'].txtCatogery.value,
        txtProvider: this.savedPersonalData['controls'].txtProvider.value,
        txtPatientBalance: this.savedPersonalData['controls'].txtPatientBalance.value,
        txtInsuranceBalance: this.savedPersonalData['controls'].txtInsuranceBalance.value,
        txtTotalBalance: this.savedPersonalData['controls'].txtTotalBalance.value,
        txtAlerts: this.savedPersonalData['controls'].txtAlerts.value,
        chkReceiveBill: this.savedPersonalData['controls'].chkReceiveBill.value,
        txtStatementTo: this.savedPersonalData['controls'].txtStatementTo.value,
        drpHoldReason: this.savedPersonalData['controls'].drpHoldReason.value,
        chkCollection: this.savedPersonalData['controls'].chkCollection.value,
        chkHoldClaims: this.savedPersonalData['controls'].chkHoldClaims.value,
        txtHoldNotes: this.savedPersonalData['controls'].txtHoldNotes.value,
        chkDoNotCall: this.savedPersonalData['controls'].chkDoNotCall.value,
        drpDunningMessage: this.savedPersonalData['controls'].drpDunningMessage.value,
        chkAutoDunning: this.savedPersonalData['controls'].chkAutoDunning.value,

      })
      this.savePersonal();
      this.savedPersonalData = []
    }
  }
  saveContactForm() {
    if (this.savedContactData && this.savedContactData) {
      this.contactsForm.patchValue({
        ContactsGroup: {
          drpEmergencyRelationshipType: this.savedContactData['controls'].drpEmergencyRelationshipType.value,

          txtEmergencyFirstName: this.savedContactData['controls'].txtEmergencyFirstName.value,
          txtEmergencyMiddleName: this.savedContactData['controls'].txtEmergencyMiddleName.value,
          txtEmergencyLastName: this.savedContactData['controls'].txtEmergencyLastName.value,
          txtEmergencyAddress: this.savedContactData['controls'].txtEmergencyAddress.value,
          txtEmergencyCity: this.savedContactData['controls'].txtEmergencyCity.value,
          txtEmergencyState: this.savedContactData['controls'].txtEmergencyState.value,
          txtEmergencyCountry: this.savedContactData['controls'].txtEmergencyCountry.value,
          txtEmergencyCounty: this.savedContactData['controls'].txtEmergencyCounty.value,
          txtEmergencyPostalCode: this.savedContactData['controls'].txtEmergencyPostalCode.value,
          txtEmergencyPhone: this.savedContactData['controls'].txtEmergencyPhone.value,
          txtEmergencyMobile: this.savedContactData['controls'].txtEmergencyMobile.value,
          txtEmergencyFax: this.savedContactData['controls'].txtEmergencyFax.value,
          txtEmergencyEmail: this.savedContactData['controls'].txtEmergencyEmail.value,
          drpResponsibleRelationshipType: this.savedContactData['controls'].drpResponsibleRelationshipType.value,
          txtResponsibleFirstName: this.savedContactData['controls'].txtResponsibleFirstName.value,
          txtResponsibleMiddleName: this.savedContactData['controls'].txtResponsibleMiddleName.value,
          txtResponsibleLastName: this.savedContactData['controls'].txtResponsibleLastName.value,
          txtResponsibleAddress: this.savedContactData['controls'].txtResponsibleAddress.value,
          txtResponsibleCity: this.savedContactData['controls'].txtResponsibleCity.value,
          txtResponsibleState: this.savedContactData['controls'].txtResponsibleState.value,
          txtResponsibleCountry: this.savedContactData['controls'].txtResponsibleCountry.value,
          txtResponsibleCounty: this.savedContactData['controls'].txtResponsibleCounty.value,
          txtResponsiblePostalCode: this.savedContactData['controls'].txtResponsiblePostalCode.value,
          txtResponsiblePhone: this.savedContactData['controls'].txtResponsiblePhone.value,
          txtResponsibleMobile: this.savedContactData['controls'].txtResponsibleMobile.value,
          txtResponsibleFax: this.savedContactData['controls'].txtResponsibleFax.value,
          txtResponsibleEmail: this.savedContactData['controls'].txtResponsibleEmail.value,
        },
      });
    }
    this.getPatientDetailsForSave();
    this.savedContactData = [];
  }
  saveclinicalForm() {
    if (this.savedClinicalData && this.savedClinicalData) {
      this.clinicalForm.patchValue({
        ClinicalGeneral: {
          drpGeneralGender: this.savedClinicalData['controls'].ClinicalGeneral['controls'].drpGeneralGender.value,
          txtGeneralHeightInches: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtGeneralHeightInches.value,
          txtGeneralHeightFeet: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtGeneralHeightFeet.value,
          txtGeneralWeight: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtGeneralWeight.value,
          txtGeneralCondition: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtGeneralCondition.value,
          txtGeneralFunctionalAbility: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtGeneralFunctionalAbility.value,
          txtGeneralPractioner: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtGeneralPractioner.value,
          txtGeneralDod: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtGeneralDod.value,
          txtAirborne: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtAirborne.value,
          txtContact: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtContact.value,
          txtDroplet: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtDroplet.value,
          txtRenderingType: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtRenderingType.value,
          txtRenderingDoctor: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtRenderingDoctor.value,
          txtRenderingFacility: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtRenderingFacility.value,
          txtReferringType: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferringType.value,
          txtReferringDoctor: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferringDoctor.value,
          txtReferringFacility: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferringFacility.value,
          txtReferringContact: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferringContact.value,
          txtReferringPhone: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferringPhone.value,
          txtOrderingDoctor: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtOrderingDoctor.value,
          txtPrimaryDoctor: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtPrimaryDoctor.value,
          txtMarketingRep: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtMarketingRep.value,
          txtReferralType: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferralType.value,
          txtReferralDoctor: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferralDoctor.value,
          txtReferralFacility: this.savedClinicalData['controls'].ClinicalGeneral['controls'].txtReferralFacility.value,
        },
        ClinicalDiagnosisGroup: {
          txtDiagnosisCodes9: this.savedClinicalData['controls'].ClinicalDiagnosisGroup['controls'].txtDiagnosisCodes9.value,
          txtdiagnosisCode10Id1: this.savedClinicalData['controls'].ClinicalDiagnosisGroup['controls'].txtdiagnosisCode10Id1.value,
          txtdiagnosisCode10Id2: this.savedClinicalData['controls'].ClinicalDiagnosisGroup['controls'].txtdiagnosisCode10Id2.value,
          txtdiagnosisCode10Id3: this.savedClinicalData['controls'].ClinicalDiagnosisGroup['controls'].txtdiagnosisCode10Id3.value,
        },
        ClinicalAppointmentGroup: {
          txtAppointmentdate: this.savedClinicalData['controls'].ClinicalAppointmentGroup['controls'].txtAppointmentdate.value,
          txtAppointmentBranch: this.savedClinicalData['controls'].ClinicalAppointmentGroup['controls'].txtAppointmentBranch.value,
          txtAppointmentDescription: this.savedClinicalData['controls'].ClinicalAppointmentGroup['controls'].txtAppointmentDescription.value,
          txtAppointmentPractioner: this.savedClinicalData['controls'].ClinicalAppointmentGroup['controls'].txtAppointmentPractioner.value,
          txtAppointmentStatus: this.savedClinicalData['controls'].ClinicalAppointmentGroup['controls'].txtAppointmentStatus.value,
        }
      });
    }
    this.showswal = false;
    this.getPatientDetailsForClinicalSave()
    this.savedClinicalData = [];
  }
  saveInsuranceForm() {
    if (this.savedInsuranceData && this.savedInsuranceData) {
      this.insuranceForm.patchValue({
        InsuranceGeneral: {
          chkInjuryEmployment: this.savedInsuranceData['controls'].chkInjuryEmployment.value,
          chkInjuryAutoAccident: this.savedInsuranceData['controls'].chkInjuryAutoAccident.value,
          chkInjuryOtherAccident: this.savedInsuranceData['controls'].chkInjuryOtherAccident.value,
          drpInsStateofAutoAccident: this.savedInsuranceData['controls'].drpInsStateofAutoAccident.value,
          drpInsMarital: this.savedInsuranceData['controls'].drpInsMarital.value,
          drpInsEmployment: this.savedInsuranceData['controls'].drpInsEmployment.value,
          chkInsEnableMedicare: this.savedInsuranceData['controls'].chkInsEnableMedicare.value,
          chkInsPrintAmounts: this.savedInsuranceData['controls'].chkInsPrintAmounts.value,
          chkInsHardshipPatient: this.savedInsuranceData['controls'].chkInsHardshipPatient.value,
          txtDateOnSet: this.savedInsuranceData['controls'].txtDateOnSet.value,
          txtDiscount: this.savedInsuranceData['controls'].txtDiscount.value,
          txtStartDate: this.savedInsuranceData['controls'].txtStartDate.value,
          txtReviewDate: this.savedInsuranceData['controls'].txtReviewDate.value,
        }
      });
    }
    this.getPatientDetailsForInsuranceSave();
    this.savedInsuranceData = [];
  }

  // saveAuthorizationForm() {
  //   if (this.savedAuthorizationData && this.savedPersonalData !== []) {
  //     this.authorizationForm.patchValue({
  //       txtAuthId: this.savedAuthorizationData['controls'].txtAuthId.value,
  //       txtAuthAmount: this.savedAuthorizationData['controls'].txtAuthAmount.value,
  //       txtStartDate: this.savedAuthorizationData['controls'].txtStartDate.value,
  //       txtEndDate: this.savedAuthorizationData['controls'].txtEndDate.value,
  //       drpCategory: this.savedAuthorizationData['controls'].drpCategory.value,
  //       drpItem: this.savedAuthorizationData['controls'].drpItem.value,
  //       txtNote: this.savedAuthorizationData['controls'].txtNote.value,
  //       chkCompleted: this.savedAuthorizationData['controls'].chkCompleted.value,
  //       txtCompletedBy: this.savedAuthorizationData['controls'].txtCompletedBy.value,
  //       drpPlan: this.savedAuthorizationData['controls'].drpPlan.value,
  //     })
  //     this.getPatientDetailsForAuthorizationSave();
  //     this.savedAuthorizationData = []
  //   }

  // }
  // get patient details for save
  getPatientDetailsForSave() {
    let personalDetails: PatientDTO;
    if (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
      this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        personalDetails = response.patientState.selectedPatient;
        this.saveContact(personalDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }
  //! Get Patient Clinical For Save Functions
  getPatientDetailsForClinicalSave() {
    let clinicalDetails: PatientDTO;
    if (this.patientId !== "" || this.patientId !== undefined || this.patientId !== null) {
      this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        clinicalDetails = response.patientState.selectedPatient;
        this.SaveClinical(clinicalDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }
  getPatientDetailsForInsuranceSave() {
    let patientDetails: PatientDTO;
    if (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
      this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        patientDetails = response.patientState.selectedPatient;
        this.SaveInsurance(patientDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      })
    }
  }
  // getPatientDetailsForAuthorizationSave() {
  //   let personalDetails: PatientDTO;
  //   this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {

  //     personalDetails = response.patientState.selectedPatient;
  //     if (personalDetails.authorizations) {
  //       let savedData = {
  //         primaryAuthorizations: personalDetails.authorizations.primaryAuthorizations,
  //         secondaryAuthorizations: personalDetails.authorizations.secondaryAuthorizations,
  //         tertiaryAuthorizations: personalDetails.authorizations.tertiaryAuthorizations
  //       }
  //       return this.saveAuthorization(personalDetails, savedData);
  //     }
  //   })
  // }

  //! Patient Personal Save Funtion
  savePersonal() {
    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    // this.Table.getOrganizationUnitId().subscribe(value => {

    //   this.organizationUnitId = value;
    // });

    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    // this.organizationUnitId = this.organizationUnitId === "null" ? null : this.organizationUnitId;
    let personalDetails: CreateUpdatePatientIntakeDTO;
    if (this.patientId === "" || this.patientId === null || this.patientId === undefined) {

      personalDetails = {
        personals: {
          locationType: this.personalForm.value.drpLocation,
          firstName: this.personalForm.value.txtFirstName,
          lastName: this.personalForm.value.txtLastName,
          middleName: this.personalForm.value.txtMiddleName,
          suffix: (this.personalForm.value.drpSuffix == (null || undefined) ? "" : (this.personalForm.value.drpSuffix)),// this.personalForm.value.txtSuffixName,
          dateOfBirth: this.personalForm?.get("txtBirthdate")?.value,
          ssn: this.personalForm.value.txtSSN,
          homePhoneNo: this.personalForm.value.txtHomePhoneNo,
          workPhoneNo: this.personalForm.value.txtWorkPhoneNo,
          accountNumber: this.personalForm.value.txtAccountNumber,
          customerType: (this.personalForm.value.drpCustomerType == (null || undefined) ? "" : (this.personalForm.value.drpCustomerType)),
          priorSystemKeyFacility: this.personalForm.value.txtPriorSystemKeyFacility,
          facility: (this.personalForm.value.drpFacility == (null || undefined) ? "" : (this.personalForm.value.drpFacility)),
          alternateIdType: this.personalForm.value.txtAlternateIDType,
          holdAccount: +this.personalForm.value.chkHoldAccount,
          holdBillingStatements: +this.personalForm.value.chkHoldBillingStatement,
          hippaSignatureOnFile: +this.personalForm.value.chkHipaaSignature,
          discountPercentage: +this.personalForm?.get('txtDiscountPercent')?.value | 0,
          taxZone: this.personalForm?.get("txtTaxZone")?.value,
          branchOffice: (this.personalForm.value.drpBranchOffice == (null || undefined) ? "" : (this.personalForm.value.drpBranchOffice)),
          accountGroupType: (this.personalForm.value.drpAccountGroup == (null || undefined) ? "" : (this.personalForm.value.drpAccountGroup)),
          ptSecurityGroupType: (this.personalForm.value.drpPTSecurityGroup == (null || undefined) ? "" : (this.personalForm.value.drpPTSecurityGroup)),
          user1: this.personalForm?.get("txtUser1")?.value,
          user2: this.personalForm?.get("txtUser2")?.value,
          user3: this.personalForm?.get("txtUser3")?.value,
          user4: this.personalForm?.get("txtUser4")?.value,
          placeOfService: (this.personalForm.value.drpPlaceOfService == (null || undefined) ? "" : (this.personalForm.value.drpPlaceOfService)),
          dateOfAdmission: this.personalForm?.get("txtPlaceOfServiceDateOfAdmission")?.value,
          dateOfDischarge: this.personalForm?.get("txtPlaceOfServiceDateOfDischarge")?.value,
          auditTrialCreatedBy: this.personalForm.value.txtAuditCreatedBy,
          auditTrialDateCreated: this.personalForm?.get("txtAuditDateCreated")?.value,
          colorCodingId: this.personalForm?.get("drpColor")?.value,
          catogeryId: this.personalForm?.get("txtCatogery")?.value,
          providerId: this.personalForm?.get("txtProvider")?.value,
          patientBalance: 0,
          insurnceBalance: 0,
          totalBalance: 0,
          // patientBalance: this.personalForm?.get("txtPatientBalance")?.value === "" ? 0 : +this.personalForm?.get("txtPatientBalance")?.value,
          // insurnceBalance: this.personalForm?.get("txtInsuranceBalance")?.value === "" ? 0 : +this.personalForm?.get("txtInsuranceBalance")?.value,
          // totalBalance: this.personalForm?.get("txtTotalBalance")?.value === "" ? 0 : +this.personalForm?.get("txtTotalBalance")?.value,
          patientAlerts: {
            patientAlertsId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            patientAlertsNotes: this.personalForm?.get("txtAlerts")?.value,
          },
          statementTo: this.personalForm?.get("txtStatementTo")?.value,
          isCollection: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkCollection").value === true ? 1 : 0,
          isDoNotCall: +this.personalForm?.get("chkPatientIntrest")?.value,// this.personalForm?.get("chkDoNotCall").value === true ? 1 : 0,
          isHoldClaims: +this.personalForm?.get("chkPatientIntrest")?.value,// this.personalForm?.get("chkHoldClaims").value === true ? 1 : 0,
          holdNotes: this.personalForm?.get("txtHoldNotes")?.value,
          holdReason: this.personalForm?.get("drpHoldReason")?.value,
          autoDunningId: this.personalForm?.get("drpDunningMessage")?.value,
          isAutoDunning: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkAutoDunning").value === true ? 1 : 0,
          isRecieveBill: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkReceiveBill").value === true ? 1 : 0,
          isPatientInterest: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkPatientIntrest").value === true ? 1 : 0,
          fullName: "",
          emailId: this.personalForm?.get("txtBillingEmailAddress")?.value,
          isCashPayment: +this.personalForm?.get("chkCashPayment")?.value,
          isStat: +this.personalForm?.get("chkStat")?.value,
          gender: (this.personalForm.value.drpGeneralGender == (null || undefined) ? "" : (this.personalForm.value.drpGeneralGender)),
          maritalStatus: (this.personalForm.value.drpMarital == (null || undefined) ? "" : (this.personalForm.value.drpMarital)),
          ethinicity: this.personalForm?.get("txtEthinicity")?.value,
          race: this.personalForm?.get("txtRace")?.value,

        },
        addresses: [{
          addressType: 0,
          relationship: "",
          address: this.personalForm?.get("txtBillingAddress")?.value,
          city: this.personalForm?.get("txtBillingCity")?.value,
          state: this.personalForm?.get("txtBillingState")?.value == (null || undefined) ? "" : (this.personalForm?.get("txtBillingState")?.value),
          country: (this.personalForm.value.drpBillingCountry == (null || undefined) ? "" : (this.personalForm.value.drpBillingCountry)),
          county: (this.personalForm.value.drpBillingCounty == (null || undefined) ? "" : (this.personalForm.value.drpBillingCounty)),
          postalCode: this.personalForm?.get("txtBillingPostalCode")?.value,
          phone: this.personalForm?.get("txtBillingPhone")?.value,
          fax: this.personalForm?.get("txtBillingFax")?.value,
          mobile: this.personalForm?.get("txtBillingMobile")?.value,
          // emailId: this.personalForm?.get("txtBillingEmailAddress")?.value,
          zone: this.personalForm?.get("drpBillingZone")?.value,
        }
          , {
          addressType: 1,
          relationship: "",
          address: this.personalForm?.get("txtDeliveryAddress")?.value,
          city: this.personalForm?.get("txtDeliveryCity")?.value == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry),
          state: this.personalForm?.get("txtDeliveryState")?.value == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry),
          country: (this.personalForm.value.drpDeliveryCountry == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry)),
          county: (this.personalForm.value.drpDeliveryCounty == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCounty)),
          postalCode: this.personalForm?.get("txtDeliveryPostalCode")?.value,
          phone: this.personalForm?.get("txtDeliveryPhone")?.value,
          fax: this.personalForm?.get("txtDeliveryFax")?.value,
          mobile: this.personalForm?.get("txtDeliveryMobile")?.value,
          // emailId: this.personalForm?.get("txtDeliveryEmailAddress")?.value,
          zone: this.personalForm?.get("drpZone")?.value,
        }
        ],
        contacts: [
          {
            contactType: 0,
            relationshipId: "",
            firstName: "",
            middleName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            county: "",
            country: defaultCountry.US,
            postalCode: "",
            phone: "",
            mobile: "",
            fax: "",
            emailAddress: "",
          },
          {
            contactType: 1,
            relationshipId: "",
            firstName: "",
            middleName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            county: "",
            country: defaultCountry.US,
            postalCode: "",
            phone: "",
            mobile: "",
            fax: "",
            emailAddress: "",
          }
        ],
        clinicals:
        {
          genderType: "",
          height: 0,
          weight: 0,
          dod: "",
          functionalAbility: "",
          generalPractitioner: "",
          infectiousConditon: 0,
          airborne: 0,
          droplet: 0,
          contact: 0,
          renderingProviderType: "",
          renderingProviderDoctor: "",
          renderingProviderFacility: "",
          referringProviderType: "",
          referringProviderDoctor: "",
          referringProviderFacility: "",
          orderingDoctor: "",
          primaryDoctor: "",
          marketingRepType: "",
          marketingReferralType: "",
          marketingReferralDoctor: "",
          marketingReferralFacility: "",
          marketingReferralContact: "",
          diagnosisCode9: [],
          diagnosisCode10: [],

          appointmentDate: null ?? "",
          branch: "",
          description: "",
          practitioner: "",
          status: "",
          // createdByUserId: 0
        },
        insurances: [],
        notes: [],
        policies: {
          primaryPolicy: undefined,
          secondaryPolicy: undefined,
          tertiaryPolicy: undefined,
        },
        organizationUnitId: null,
        defaultPatientId: "",
        // insuranceVerifications: {
        //   primaryInsuranceVerifies: null,
        //   secondaryInsuranceVerifies: null,
        //   tertiaryInsuranceVerifies: null
        // },

        // authorizations: {
        //   primaryAuthorizations: null,
        //   secondaryAuthorizations: null,
        //   tertiaryAuthorizations: null
        // },
        isAutoIntake: 0,
        autoIntakeDocumentDetails: [],
        chartId: (this.personalForm.value.txtChartId).toLocaleUpperCase(),
        patientLock: null,
        // fileId:null,
      }

      this.saveOrUpdate(personalDetails);
    } else {
      let selectedPatient: PatientDTO;
      (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) && this.patientService.get(this.patientId).subscribe(response => {
        selectedPatient = response;
        personalDetails = {
          personals: {
            locationType: this.personalForm.value.drpLocation,
            firstName: this.personalForm.value.txtFirstName,
            lastName: this.personalForm.value.txtLastName,
            middleName: this.personalForm.value.txtMiddleName,
            suffix: (this.personalForm.value.drpSuffix == (null || undefined) ? "" : (this.personalForm.value.drpSuffix)), //this.personalForm.value.txtSuffixName,
            dateOfBirth: this.personalForm?.get("txtBirthdate")?.value,
            ssn: this.personalForm.value.txtSSN,
            homePhoneNo: this.personalForm.value.txtHomePhoneNo,
            workPhoneNo: this.personalForm.value.txtWorkPhoneNo,
            accountNumber: this.personalForm.value.txtAccountNumber,
            customerType: (this.personalForm.value.drpCustomerType == (null || undefined) ? "" : (this.personalForm.value.drpCustomerType)),
            priorSystemKeyFacility: this.personalForm.value.txtPriorSystemKeyFacility,
            facility: (this.personalForm.value.drpFacility == (null || undefined) ? "" : (this.personalForm.value.drpFacility)),
            alternateIdType: this.personalForm.value.txtAlternateIDType,
            holdAccount: +this.personalForm.value.chkHoldAccount,
            holdBillingStatements: +this.personalForm.value.chkHoldBillingStatement,
            hippaSignatureOnFile: +this.personalForm.value.chkHipaaSignature,
            discountPercentage: +this.personalForm?.get('txtDiscountPercent')?.value | 0,
            taxZone: this.personalForm?.get("txtTaxZone")?.value,
            branchOffice: (this.personalForm.value.drpBranchOffice == (null || undefined) ? "" : (this.personalForm.value.drpBranchOffice)),
            accountGroupType: (this.personalForm.value.drpAccountGroup == (null || undefined) ? "" : (this.personalForm.value.drpAccountGroup)),
            ptSecurityGroupType: (this.personalForm.value.drpPTSecurityGroup == (null || undefined) ? "" : (this.personalForm.value.drpPTSecurityGroup)),
            user1: this.personalForm?.get("txtUser1")?.value,
            user2: this.personalForm?.get("txtUser2")?.value,
            user3: this.personalForm?.get("txtUser3")?.value,
            user4: this.personalForm?.get("txtUser4")?.value,
            placeOfService: (this.personalForm.value.drpPlaceOfService == (null || undefined) ? "" : (this.personalForm.value.drpPlaceOfService)),
            dateOfAdmission: this.personalForm?.get("txtPlaceOfServiceDateOfAdmission")?.value,
            dateOfDischarge: this.personalForm?.get("txtPlaceOfServiceDateOfDischarge")?.value,
            auditTrialCreatedBy: this.personalForm.value.txtAuditCreatedBy,
            auditTrialDateCreated: this.personalForm?.get("txtAuditDateCreated")?.value,
            colorCodingId: this.personalForm?.get("drpColor")?.value,
            catogeryId: this.personalForm?.get("txtCatogery")?.value,
            providerId: this.personalForm?.get("txtProvider")?.value,
            patientBalance: 0,
            insurnceBalance: 0,
            totalBalance: 0,
            // patientBalance: this.personalForm?.get("txtPatientBalance")?.value === "" ? 0 : +this.personalForm?.get("txtPatientBalance")?.value,
            // insurnceBalance: this.personalForm?.get("txtInsuranceBalance")?.value === "" ? 0 : +this.personalForm?.get("txtInsuranceBalance")?.value,
            // totalBalance: this.personalForm?.get("txtTotalBalance")?.value === "" ? 0 : +this.personalForm?.get("txtTotalBalance")?.value,
            patientAlerts: {
              patientAlertsId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              patientAlertsNotes: this.personalForm?.get("txtAlerts")?.value,
            },
            statementTo: this.personalForm?.get("txtStatementTo")?.value,
            isCollection: +this.personalForm?.get("chkCollection")?.value,//this.personalForm?.get("chkCollection")?.value === true ? 1 : 0,
            isDoNotCall: +this.personalForm?.get("chkDoNotCall")?.value,// this.personalForm?.get("chkDoNotCall")?.value === true ? 1 : 0,
            isHoldClaims: +this.personalForm?.get("chkHoldClaims")?.value,// this.personalForm?.get("chkHoldClaims")?.value === true ? 1 : 0,
            holdNotes: this.personalForm?.get("txtHoldNotes")?.value,
            holdReason: this.personalForm?.get("drpHoldReason")?.value,
            autoDunningId: this.personalForm?.get("drpDunningMessage")?.value,
            isAutoDunning: +this.personalForm?.get("chkAutoDunning")?.value,//this.personalForm?.get("chkAutoDunning")?.value === true ? 1 : 0,
            isRecieveBill: +this.personalForm?.get("chkReceiveBill")?.value,//this.personalForm?.get("chkReceiveBill")?.value === true ? 1 : 0,
            isPatientInterest: +this.personalForm?.get("chkPatientIntrest")?.value,//this.personalForm?.get("chkPatientIntrest")?.value === true ? 1 : 0,
            fullName: "",
            emailId: this.personalForm?.get("txtBillingEmailAddress")?.value,
            isCashPayment: +this.personalForm?.get("chkCashPayment")?.value,
            isStat: +this.personalForm?.get("chkStat")?.value,
            gender: (this.personalForm.value.drpGeneralGender == (null || undefined) ? "" : (this.personalForm.value.drpGeneralGender)),
            maritalStatus: (this.personalForm.value.drpMarital == (null || undefined) ? "" : (this.personalForm.value.drpMarital)),
            ethinicity: this.personalForm?.get("txtEthinicity")?.value,
            race: this.personalForm?.get("txtRace")?.value,
          },
          addresses: [{
            addressType: 0,
            relationship: "",
            address: this.personalForm?.get("txtBillingAddress")?.value,
            city: this.personalForm?.get("txtBillingCity")?.value,
            state: this.personalForm?.get("txtBillingState")?.value,
            country: (this.personalForm.value.drpBillingCountry == (null || undefined) ? "" : (this.personalForm.value.drpBillingCountry)),
            county: (this.personalForm.value.drpBillingCounty == (null || undefined) ? "" : (this.personalForm.value.drpBillingCounty)),
            postalCode: this.personalForm?.get("txtBillingPostalCode")?.value,
            phone: this.personalForm?.get("txtBillingPhone")?.value,
            fax: this.personalForm?.get("txtBillingFax")?.value,
            mobile: this.personalForm?.get("txtBillingMobile")?.value,
            // emailId: this.personalForm?.get("txtBillingEmailAddress")?.value,
            zone: this.personalForm?.get("drpBillingZone")?.value,
          }
            , {
            addressType: 1,
            relationship: "",
            address: this.personalForm?.get("txtDeliveryAddress")?.value,
            city: this.personalForm?.get("txtDeliveryCity")?.value,
            state: this.personalForm?.get("txtDeliveryState")?.value,
            country: (this.personalForm.value.drpDeliveryCountry == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCountry)),
            county: (this.personalForm.value.drpDeliveryCounty == (null || undefined) ? "" : (this.personalForm.value.drpDeliveryCounty)),
            postalCode: this.personalForm?.get("txtDeliveryPostalCode")?.value,
            phone: this.personalForm?.get("txtDeliveryPhone")?.value,
            fax: this.personalForm?.get("txtDeliveryFax")?.value,
            mobile: this.personalForm?.get("txtDeliveryMobile")?.value,
            //   emailId: this.personalForm?.get("txtDeliveryEmailAddress")?.value,
            zone: this.personalForm?.get("drpZone")?.value,
          }
          ],
          contacts: selectedPatient?.contacts,
          insurances: selectedPatient?.insurances,
          // policies: selectedPatient?.policies,
          notes: selectedPatient?.notes,
          //  patientDocuments: selectedPatient?.patientDocuments,
          clinicals: selectedPatient?.clinicals,
          organizationUnitId: null,
          defaultPatientId: selectedPatient.defaultPatientId,
          //  insuranceVerifications: selectedPatient.insuranceVerifications,
          //  authorizations: selectedPatient.authorizations,
          isAutoIntake: selectedPatient.isAutoIntake,
          autoIntakeDocumentDetails: selectedPatient.autoIntakeDocumentDetails,
          //chartId: selectedPatient.chartId,
          chartId: (this.personalForm.value.txtChartId).toLocaleUpperCase(),
          patientLock: selectedPatient?.patientLock,

          // fileId: [
          //   "00000000-0000-0000-0000-000000000000"
          // ],
        }
        this.saveOrUpdate(personalDetails);

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }
  //! Perosnal Save Or Update Functions
  saveOrUpdate(personalDetails: CreateUpdatePatientIntakeDTO) {
    if ((this.patientId !== "" && this.patientId !== null && this.patientId !== undefined)) {
      this.store.dispatch(new updatePatientIntake(this.patientId, personalDetails)).subscribe((stateResponse) => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Personal");
          }
        }, err => { });

        let patientSaved: CreateUpdatePatientIntakeDTO = stateResponse?.patientState?.selectedPatient;
        this.patientId = stateResponse?.patientState?.selectedPatient?.id;
        this.patientIdEmitter.emit(patientSaved);
        this.Table.setheaderTable(this.patientId);
        this.Table.setSelectedPatientId(this.patientId);
        this.savePersonalFormState.emit(this.personalForm.value);
      }, err => {
        const data: HttpErrorResponse = err;
        // if(data?.error?.error?.code === "PatientManagement:00026"){
        //   Swal.fire({
        //     icon: 'info',
        //     text: data?.error?.error?.message,
        //   });
        // }
        // else{
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        //}
      })
    } else {
      this.store.dispatch(new addPatientIntake(personalDetails)).subscribe((stateResponse) => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Personal");
          }
        }, err => { });
        let patientSaved: CreateUpdatePatientIntakeDTO = stateResponse?.patientState?.selectedPatient;
        this.patientId = stateResponse?.patientState?.selectedPatient?.id;
        this.patientIdEmitter.emit(patientSaved);
        this.Table.setheaderTable(this.patientId);
        this.Table.setSelectedPatientId(this.patientId);
        this.savePersonalFormState.emit(this.personalForm.value);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }
  saveContact(response: PatientDTO) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let contactAddress: PatientDTO;
    contactAddress = {
      personals: response.personals,
      addresses: response.addresses,
      contacts: [
        {
          id: this.patientId,
          relationshipId: (this.contactsForm.value.ContactsGroup.drpEmergencyRelationshipType == (null || undefined) ? "" : (this.contactsForm.value.ContactsGroup.drpEmergencyRelationshipType)),
          contactType: 0,
          firstName: this.contactsForm.value.ContactsGroup.txtEmergencyFirstName,
          middleName: this.contactsForm.value.ContactsGroup.txtEmergencyMiddleName,
          lastName: this.contactsForm.value.ContactsGroup.txtEmergencyLastName,
          address: this.contactsForm.value.ContactsGroup.txtEmergencyAddress,
          city: this.contactsForm.value.ContactsGroup.txtEmergencyCity,
          state: this.contactsForm.value.ContactsGroup.txtEmergencyState,
          country: this.contactsForm.value.ContactsGroup.txtEmergencyCountry,
          county: this.contactsForm.value.ContactsGroup.txtEmergencyCounty,
          postalCode: this.contactsForm.value.ContactsGroup.txtEmergencyPostalCode,
          phone: this.contactsForm.value.ContactsGroup.txtEmergencyPhone,
          fax: this.contactsForm.value.ContactsGroup.txtEmergencyFax,
          mobile: this.contactsForm.value.ContactsGroup.txtEmergencyMobile,
          emailAddress: this.contactsForm.value.ContactsGroup.txtEmergencyEmail
          // createdByUserId: 1,
        },
        {
          id: this.patientId,
          relationshipId: (this.contactsForm.value.ContactsGroup.drpResponsibleRelationshipType == (null || undefined) ? "" : (this.contactsForm.value.ContactsGroup.drpResponsibleRelationshipType)),
          contactType: 1,
          firstName: this.contactsForm.value.ContactsGroup.txtResponsibleFirstName,
          lastName: this.contactsForm.value.ContactsGroup.txtResponsibleLastName,
          middleName: this.contactsForm.value.ContactsGroup.txtResponsibleMiddleName,
          address: this.contactsForm.value.ContactsGroup.txtResponsibleAddress,
          city: this.contactsForm.value.ContactsGroup.txtResponsibleCity,
          state: this.contactsForm.value.ContactsGroup.txtResponsibleState,
          country: this.contactsForm.value.ContactsGroup.txtResponsibleCountry,
          county: this.contactsForm.value.ContactsGroup.txtResponsibleCounty,
          postalCode: this.contactsForm.value.ContactsGroup.txtResponsiblePostalCode,
          phone: this.contactsForm.value.ContactsGroup.txtResponsiblePhone,
          fax: this.contactsForm.value.ContactsGroup.txtResponsibleFax,
          mobile: this.contactsForm.value.ContactsGroup.txtResponsibleMobile,
          emailAddress: this.contactsForm.value.ContactsGroup.txtResponsibleEmail,
          // createdByUserId: 1,
        }
      ],
      clinicals: response.clinicals,
      insurances: response.insurances,
      notes: response.notes,
      policies: response.policies,
      defaultPatientId: response.defaultPatientId,
      insuranceVerifications: response.insuranceVerifications,
      authorizations: response.authorizations,
      patientDocuments: response.patientDocuments,
      organizationUnitId: response.organizationUnitId,
      isAutoIntake: response.isAutoIntake,
      autoIntakeDocumentDetails: response.autoIntakeDocumentDetails,
      chartId: response.chartId,
      billingDetails: response.billingDetails,
      patientLock: response.patientLock,
      isSorted: response.isSorted,
      fileId: response?.fileId,
    };
    this.patientId !== "" && this.store.dispatch(new updatePatient(this.patientId, contactAddress)).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    });



  }
  str: string[];
  height: string = '';
  //! Patient Clinical Save Functions
  SaveClinical(PateintDetails) {

    if ((this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet == "" ||
      this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet == null ||
      this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet == undefined) &&
      (this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightInches == "" ||
        this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightInches == undefined ||
        this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightInches == null)) {
      this.height = "0"
    }
    else {
      this.height = this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet + '.' +
        this.clinicalForm.value.ClinicalGeneral.txtGeneralHeightInches;

      parseFloat(this.height);
    }
    // this.Table.getOrganizationUnitId().subscribe(value => {
    //   this.organizationUnitId = value;
    // });
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let clinicalDetails: CreateUpdatePatientIntakeDTO;

    clinicalDetails = {
      addresses: PateintDetails.addresses,
      personals: PateintDetails?.personals,
      contacts: PateintDetails.contacts,
      clinicals: {
        // id: this.patientId,
        genderType: this.clinicalForm?.get("ClinicalGeneral.drpGeneralGender")?.value,
        height: this.height == "0" ? 0 : parseFloat(this.height),
        weight: +(this.clinicalForm.value.ClinicalGeneral.txtGeneralWeight) | 0,
        dod: this.clinicalForm.value.ClinicalGeneral.txtGeneralDod,
        functionalAbility: this.clinicalForm.value.ClinicalGeneral.txtGeneralFunctionalAbility,
        generalPractitioner: this.clinicalForm.value.ClinicalGeneral.txtGeneralPractioner,
        infectiousConditon: +this.clinicalForm?.get("ClinicalGeneral.txtGeneralCondition")?.value,
        airborne: +this.clinicalForm?.get("ClinicalGeneral.txtAirborne")?.value,
        contact: +this.clinicalForm?.get("ClinicalGeneral.txtContact")?.value,
        droplet: +this.clinicalForm?.get("ClinicalGeneral.txtDroplet")?.value,
        renderingProviderType: this.clinicalForm?.get("ClinicalGeneral.txtRenderingType")?.value,
        renderingProviderDoctor: this.clinicalForm.value.ClinicalGeneral.txtRenderingDoctor,
        renderingProviderFacility: this.clinicalForm.value.ClinicalGeneral.txtRenderingFacility,
        referringProviderType: this.clinicalForm?.get("ClinicalGeneral.txtReferringType")?.value,
        referringProviderDoctor: this.clinicalForm.value.ClinicalGeneral.txtReferringDoctor,
        referringProviderFacility: this.clinicalForm.value.ClinicalGeneral.txtReferringFacility,
        orderingDoctor: this.clinicalForm.value.ClinicalGeneral.txtOrderingDoctor,
        primaryDoctor: this.clinicalForm.value.ClinicalGeneral.txtPrimaryDoctor,
        marketingRepType: this.clinicalForm.value.ClinicalGeneral.txtMarketingRep,
        marketingReferralType: this.clinicalForm?.get("ClinicalGeneral.txtReferralType")?.value,
        marketingReferralDoctor: this.clinicalForm.value.ClinicalGeneral.txtReferralDoctor,
        marketingReferralFacility: this.clinicalForm.value.ClinicalGeneral.txtReferralFacility,
        marketingReferralContact: this.clinicalForm?.get("ClinicalGeneral.txtReferringContact")?.value,
        diagnosisCode9: this.clinicalForm.value.ClinicalDiagnosisGroup.txtDiagnosisCodes9 == "" ? [] : this.clinicalForm.value.ClinicalDiagnosisGroup.txtDiagnosisCodes9,
        diagnosisCode10: this.clinicalForm.value.ClinicalDiagnosisGroup.txtdiagnosisCode10Id1,
        // diagnosisCode10Id2: this.clinicalForm.value.ClinicalDiagnosisGroup.txtdiagnosisCode10Id2,
        // diagnosisCode10Id3: this.clinicalForm.value.ClinicalDiagnosisGroup.txtdiagnosisCode10Id3,
        appointmentDate: this.clinicalForm.value.ClinicalAppointmentGroup.txtAppointmentdate,
        branch: this.clinicalForm.value.ClinicalAppointmentGroup.txtAppointmentBranch,
        description: this.clinicalForm.value.ClinicalAppointmentGroup.txtAppointmentDescription,
        practitioner: this.clinicalForm.value.ClinicalAppointmentGroup.txtAppointmentPractioner,
        status: this.clinicalForm.value.ClinicalAppointmentGroup.txtAppointmentStatus,
      },
      insurances: PateintDetails.insurances,
      notes: PateintDetails.notes,
      policies: PateintDetails.policies,
      defaultPatientId: PateintDetails.defaultPatientId,
      // insuranceVerifications: PateintDetails.insuranceVerifications,
      // authorizations: PateintDetails.authorizations,
      // patientDocuments: PateintDetails.patientDocuments,
      organizationUnitId: PateintDetails.organizationUnitId,
      isAutoIntake: PateintDetails.isAutoIntake,
      autoIntakeDocumentDetails: PateintDetails.autoIntakeDocumentDetails,
      chartId: PateintDetails.chartId,
      patientLock: PateintDetails?.patientLock,
      // fileId: PateintDetails?.fileId,
    };
    if (this.patientId !== "" || this.patientId !== undefined || this.patientId !== null) {
      this.store.dispatch(new updatePatientIntake(this.patientId, clinicalDetails)).subscribe((response) => {
        //
        let patientSaved: CreateUpdatePatientIntakeDTO = response?.patientState?.selectedPatient;
        this.patientIdEmitter.emit(patientSaved);
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Clinical");
          }
        }, err => { });
        this.Table.setheaderTable(this.patientId);
        this.saveClinicalFormState.emit(this.clinicalForm.value);

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      });
    }
  }

  SaveInsurance(response: PatientDTO) {
    // this.Table.getOrganizationUnitId().subscribe(value => {
    //   this.organizationUnitId = value;
    // });
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let insuranceDetails: PatientDTO;
    this.patientId = response.id;
    insuranceDetails = {
      addresses: response.addresses,
      personals: response.personals,
      contacts: response.contacts,
      clinicals: response.clinicals,
      insurances: [{
        id: this.patientId,
        stateOfAutoAccident: (this.insuranceForm.value.InsuranceGeneral.drpInsStateofAutoAccident == (null || undefined) ? "" : (this.insuranceForm.value.InsuranceGeneral.drpInsStateofAutoAccident)),
        injuryRelatedToEmployment: +(this.insuranceForm.value.InsuranceGeneral.chkInjuryEmployment) | 0,
        injuryRelatedToAutoAccident: +(this.insuranceForm.value.InsuranceGeneral.chkInjuryAutoAccident) | 0,
        injuryRelatedToOtherAccident: +(this.insuranceForm.value.InsuranceGeneral.chkInjuryOtherAccident) | 0,
        dataOnset: this.insuranceForm.value.InsuranceGeneral.txtDateOnSet,
        martialStatus: (this.insuranceForm.value.InsuranceGeneral.drpInsMarital == (null || undefined) ? "" : (this.insuranceForm.value.InsuranceGeneral.drpInsMarital)),
        employment: (this.insuranceForm.value.InsuranceGeneral.drpInsEmployment == (null || undefined) ? "" : (this.insuranceForm.value.InsuranceGeneral.drpInsEmployment)),
        printAmountsOnDeliveryTickets: +(this.insuranceForm.value.InsuranceGeneral.chkInsPrintAmounts) | 0,
        patientFinancialHardship: +(this.insuranceForm.value.InsuranceGeneral.chkInsHardshipPatient) | 0,
        discountPct: this.insuranceForm.value.InsuranceGeneral.txtDiscount,
        startDate: this.insuranceForm.value.InsuranceGeneral.txtStartDate,
        reviewDate: this.insuranceForm.value.InsuranceGeneral.txtReviewDate,
        // createdByUserId: 0,
      }],
      notes: response.notes,
      policies: response.policies,
      defaultPatientId: response.defaultPatientId,
      insuranceVerifications: response.insuranceVerifications,
      authorizations: response.authorizations,
      patientDocuments: response.patientDocuments,
      organizationUnitId: response.organizationUnitId,
      isAutoIntake: response.isAutoIntake,
      autoIntakeDocumentDetails: response.autoIntakeDocumentDetails,
      chartId: response.chartId,
      billingDetails: response.billingDetails,
      patientLock: response.patientLock,
      isSorted: response.isSorted,
      fileId: response?.fileId,
    };
    if (this.patientId !== "" || this.patientId !== undefined || this.patientId !== null) {
      this.store.dispatch(new updatePatient(this.patientId, insuranceDetails)).subscribe(() => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  // authoriztion tab save functions
  getDropdown() {
    // let orderDropdownInput : OrderDropdowns[];
    // orderDropdownInput = [
    //   OrderDropdowns.TypeOfPlans
    // ]
    // this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
    //   let response: OrderMasterDropdownDTO = stateResponse;
    //   this.drpPlan = response.typeOfPlans;
    //   // this.drpAuthItem = response.authItems;
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // })
  }
  saveAuthorizationForm() {

    // if (this.savedAuthorizationData && this.savedAuthorizationData !== []) {
    //   this.authorizationForm.patchValue({
    //     txtAuthId: this.savedAuthorizationData['controls'].txtAuthId.value,
    //     drpAuthVisit: this.savedAuthorizationData['controls'].drpAuthVisit.value,
    //     txtAuthAmount: this.savedAuthorizationData['controls'].txtAuthAmount.value,
    //     txtStartDate: this.savedAuthorizationData['controls'].txtStartDate.value,
    //     txtEndDate: this.savedAuthorizationData['controls'].txtEndDate.value,
    //     drpCategory: this.savedAuthorizationData['controls'].drpCategory.value,
    //     drpItem: this.savedAuthorizationData['controls'].drpItem.value,
    //     txtNote: this.savedAuthorizationData['controls'].txtNote.value,
    //     chkCompleted: this.savedAuthorizationData['controls'].chkCompleted.value,
    //     txtCompletedBy: this.savedAuthorizationData['controls'].txtCompletedBy.value,
    //     drpPlan: this.savedAuthorizationData['controls'].drpPlan.value,
    //   })
    //   this.getPatientDetailsForAuthorizationSave();
    //   this.savedAuthorizationData = []
    // }
  }
  getPatientDetailsForAuthorizationSave() {
    let personalDetails: PatientDTO;
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {

      personalDetails = response.patientState.selectedPatient;
      if (personalDetails.authorizations) {
        let savedData = {
          primaryAuthorizations: personalDetails.authorizations.primaryAuthorizations,
          secondaryAuthorizations: personalDetails.authorizations.secondaryAuthorizations,
          tertiaryAuthorizations: personalDetails.authorizations.tertiaryAuthorizations
        }
        return this.saveAuthorization(personalDetails, savedData);
      }
    })
  }
  saveAuthorization(response, savedData) {
    let typeOfPlanName: string[] = this.drpPlan.map(v => v.typeOfPlanName);
    if (this.authorizationForm.value.drpPlan === typeOfPlanName[0]) {
      savedData.primaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.drpPlan,
        // planName: this.authorizationForm.value.txtPlanName,
        planName: "",
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: ""

      }
    } else if (this.authorizationForm.value.drpPlan === typeOfPlanName[1]) {
      savedData.secondaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.drpPlan,
        // planName: this.authorizationForm.value.txtPlanName,
        planName: "",
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",

      }
    } else if (this.authorizationForm.value.drpPlan === typeOfPlanName[2]) {
      savedData.tertiaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.drpPlan,
        // planName: this.authorizationForm.value.txtPlanName,
        planName: "",
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",

      }
    }
    let insAuthorization: PatientDTO;
    insAuthorization = {
      personals: response.personals,
      addresses: response.addresses,
      contacts: response.contacts,
      clinicals: response.clinicals,
      insurances: response.insurances,
      notes: response.notes,
      policies: response.policies,
      defaultPatientId: response.defaultPatientId,
      insuranceVerifications: response.insuranceVerifications,
      authorizations: {
        primaryAuthorizations: savedData.primaryAuthorizations,
        secondaryAuthorizations: savedData.secondaryAuthorizations,
        tertiaryAuthorizations: savedData.tertiaryAuthorizations,
      },
      patientDocuments: response.patientDocuments,
      organizationUnitId: response.organizationUnitId,
      isAutoIntake: response.isAutoIntake,
      autoIntakeDocumentDetails: response.autoIntakeDocumentDetails,
      chartId: response.chartId,
      billingDetails: response.billingDetails,
      patientLock: response.patientLock,
      isSorted: response.isSorted,
      fileId: response?.fileId,
    }
    this.patientId !== "" && this.store.dispatch(new updatePatient(this.patientId, insAuthorization)).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')

      this.getTableData();
      this.clearData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true, 
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        patientID: this.patientId,
        notesId: ""
      }
    }
    const dialogRef = this.dialog.open(AddNotesComponent, config);

    dialogRef.afterClosed().subscribe(() => {
      //this.getReminderTableData();
    }, (err: HttpErrorResponse) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true, 
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientID: this.patientId,
      }
    }
    const dialogRef = this.dialog.open(OrderNotesComponent, config);

    dialogRef.afterClosed().subscribe(() => {
      //this.getReminderTableData();
    }, (err: HttpErrorResponse) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  getTableData() {
    let itemDetails: PatientDTO;
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      itemDetails = response?.patientState?.selectedPatient;
      this.tblNotesReponse = [];
      const primaryAuth = itemDetails.authorizations.primaryAuthorizations
      const secondaryAuth = itemDetails.authorizations.secondaryAuthorizations
      const tertiaryAuth = itemDetails.authorizations.tertiaryAuthorizations
      if (primaryAuth != null) {
        this.tblNotesReponse.push({
          authId: primaryAuth?.authId,
          authVisits: primaryAuth?.authVisits,
          authNo: primaryAuth?.authNo,
          authAmount: primaryAuth?.authAmount,
          item: primaryAuth?.item,
          note: primaryAuth?.note,
          typeOfPolicy: primaryAuth?.typeOfPolicy,
          startDate: primaryAuth?.startDate == null ? "" : new Date(primaryAuth?.startDate)?.toLocaleDateString("en-US"),
          endDate: primaryAuth?.endDate == null ? "" : new Date(primaryAuth?.endDate)?.toLocaleDateString("en-US"),
          category: primaryAuth?.category,
          completed: primaryAuth?.isCompleted,
          completedBy: primaryAuth?.completedBy,
          plan: primaryAuth?.plan,
          patientId: response?.patientState?.selectedPatient?.id,
          authorizationId: primaryAuth?.authorizationId
        });
      }
      if (secondaryAuth != null) {
        this.tblNotesReponse.push({
          authId: secondaryAuth?.authId,
          authVisits: secondaryAuth?.authVisits,
          authNo: secondaryAuth?.authNo,
          authAmount: secondaryAuth?.authAmount,
          item: secondaryAuth?.item,
          note: secondaryAuth?.note,
          typeOfPolicy: secondaryAuth?.typeOfPolicy,
          startDate: secondaryAuth?.startDate == null ? "" : new Date(secondaryAuth?.startDate)?.toLocaleDateString("en-US"),
          endDate: secondaryAuth?.endDate == null ? "" : new Date(secondaryAuth?.endDate)?.toLocaleDateString("en-US"),
          category: secondaryAuth?.category,
          completed: secondaryAuth?.isCompleted,
          completedBy: secondaryAuth?.completedBy,
          plan: secondaryAuth?.plan,
          patientId: response?.patientState?.selectedPatient?.id,
          authorizationId: secondaryAuth?.authorizationId

        });
      }
      if (tertiaryAuth != null) {
        this.tblNotesReponse.push({
          authId: tertiaryAuth?.authId,
          authVisits: tertiaryAuth?.authVisits,
          authNo: tertiaryAuth?.authNo,
          authAmount: tertiaryAuth?.authAmount,
          item: tertiaryAuth?.item,
          note: tertiaryAuth?.note,
          typeOfPolicy: tertiaryAuth?.typeOfPolicy,
          startDate: tertiaryAuth?.startDate == null ? "" : new Date(tertiaryAuth?.startDate)?.toLocaleDateString("en-US"),
          endDate: tertiaryAuth?.endDate == null ? "" : new Date(tertiaryAuth?.endDate)?.toLocaleDateString("en-US"),
          category: tertiaryAuth?.category,
          completed: tertiaryAuth?.isCompleted,
          completedBy: tertiaryAuth?.completedBy,
          plan: tertiaryAuth?.plan,
          patientId: response?.patientState?.selectedPatient?.id,
          authorizationId: tertiaryAuth?.authorizationId

        });
      }

      this.Table.setAuthTable(this.tblNotesReponse);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  clearData() {
    // this.authorizationForm.patchValue({
    //   txtAuthId: null,
    //   drpAuthVisit: "",
    //   txtAuthNo: "",
    //   txtAuthAmount: "",
    //   txtStartDate: "",
    //   txtEndDate: "",
    //   drpCategory: "",
    //   drpItem: "",
    //   txtNote: "",
    //   chkCompleted: "",
    //   txtCompletedBy: "",
    //   drpPlan: "",
    //   txtPlan: null,
    //   rbtnAuth: "",
    // })
    this.authorizationForm.reset();
    this.authorizationForm.markAsUntouched();
    this.authorizationForm.updateValueAndValidity();
  }
  getDropdownForAuthorization() {
    //   let orderDropdownInput : OrderDropdowns[];
    //   orderDropdownInput = [
    //     OrderDropdowns.AuthVisits,
    //     OrderDropdowns.TypeOfPlans
    //   ]
    //   this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
    //     ;
    //     let response: OrderMasterDropdownDTO = stateResponse;
    //     this.drpAuthVisit = response.authVisits;
    //     // this.drpCategory = response.authCategories;
    //     // this.drpMarketingRef = response.marketingReferrals;
    //     this.drpPlan = response.typeOfPlans;
    //     //this.getByData(this.patientId);
    //     // this.drpAuthItem = response.authItems;
    // })
  }
}
