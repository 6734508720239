<!-- Breadcrumbs -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0">
        <h3 class="font-weight-bolder mb-0">Insurance Report</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <li class="breadcrumb-item">MIS Report</li>
            <li class="breadcrumb-item">Insurance Report</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Title -->
<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <form [formGroup]="insuranceReportForm">
        <mat-form-field class="col-3">
          <mat-label>Insurance Name</mat-label>
          <mat-select #productSelect multiple formControlName="txtProductName">
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>

<!-- Table -->
<div class="row">
  <div class="col-12 table-responsive" style="margin-top: -25px">
    <div class="card card-body mt-5">
      <table table mat-table matSort [dataSource]="dataSource">
        <form style="display: flex">
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
            <td mat-cell *matCellDef="let data"></td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="insurnceName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              insuranceName Name
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container> </ng-container>
            </td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="dateOfService">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Date of Service
            </th>
            <td mat-cell *matCellDef="let data"></td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="patientName">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Patient Name
            </th>
            <td mat-cell *matCellDef="let data"></td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="itemCode">
            <th class="header" mat-header-cell *matHeaderCellDef>Item Code</th>
            <td mat-cell *matCellDef="let data"></td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="itemQuantity">
            <th class="header" mat-header-cell *matHeaderCellDef>Item Units</th>
            <td mat-cell *matCellDef="let data"></td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor" (click)="openAllowablesEditModal(data)">
                <i
                  class="fa fa-pencil"
                  matTooltip="Click To Edit"
                  aria-hidden="true"
                ></i>
              </a>
            </td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="noRecords">
            <td
              class="text-align-center"
              mat-footer-cell
              *matFooterCellDef
              colspan="9"
            >
              <div *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <!-- Paginator -->
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="11">
              <mat-paginator
                class="table table-responsive mat-table-class"
                #MatPaginator
                [length]="dataSource?.data?.length"
                [pageSize]="10"
                [pageIndex]="0"
                showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator>
            </td>
          </ng-container>
        </form>
        <tr
          mat-footer-row
          *matFooterRowDef="['noRecords']"
          [ngClass]="{
            active:
              dataSource && dataSource?.data && dataSource?.data?.length !== 0
          }"
        ></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>
  </div>
</div>
