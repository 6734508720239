import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ClaimInformationsService } from 'projects/billing/src/app/billing-proxy/billing-management/posting/claim-informations.service';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleOrderSearchService } from 'projects/order/src/app/order-proxy/order-management/order/sale-order-search.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { updateOrder } from 'projects/order/src/app/order-store/order.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-shipping-history',
  templateUrl: './shipping-history.component.html',
  styleUrls: ['./shipping-history.component.scss'],
  providers: [ListService]
})

export class ShippingHistoryComponent implements OnInit , OnDestroy {

  @Output() saveHistoryFormState: EventEmitter<any> = new EventEmitter<any>();

  public strPageType: string = "paymentHistory";
  @Input() orderId: string = '';
  claimTableData: any[] = [];
  organizationunitId: string = "";
  @Input() patientId: string = '';
  isShowInt: boolean = false;
  isShowPaid: boolean = false;
  show: boolean = false;
  isLoading: boolean = true;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];

  constructor(public tableService: TableService,
    public title: Title, public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store, private orderService: SaleorderService,
    private claimInformationService: ClaimInformationsService,
    private orderSearchService: SaleOrderSearchService,
    public list: ListService,
    private toastr: ToastrService,
  ) {


  }
  public claimHistoryForm: FormGroup;

  ngOnInit(): void {
    this.claimHistoryForm = this.fb.group({
      rbtnClaimHistory: new FormControl("", Validators.required),
      chkShippingApproved: new FormControl(""),
      txtReason: new FormControl(""),

    });
    this.getClaimHistoryById();
    const getOrganizationId = this.tableService.getOrganizationUnitId().subscribe(val => {
      this.organizationunitId = val;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getOrganizationId)
    //

    if (this.claimHistoryForm.invalid || this.claimHistoryForm.dirty) {
      this.saveHistoryFormState.emit(this.claimHistoryForm);
      this.loadPatientVisitsTable();
    }
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //! reason show
  onChangeGetInsuranceVerify($event: MatRadioChange) {

    if ($event.value === "Disapproved") {
      this.show = true
    } else {
      this.show = false
    }

  }


  //! save history
  getPatientDetailsForSave(status) {
    let authOrderDetails: SaleorderDTO;
    this.orderService.get(this.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      //
      authOrderDetails = response;
      this.saveClaimHistory(authOrderDetails, status);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  loadPatientVisitsTable() {
    this.isLoading = true;
    const patientList = (query: any) => this.claimInformationService.getList(query);
    const patientLists = this.list.hookToQuery(patientList).subscribe(response => {
      this.claimTableData = [];
      if (response.items.length != 0) {
        response?.items?.forEach((element) => {
          this.tableService.setBillClaimListDataTable(element)
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          // if (this.patientId === element.patientId) {
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            //status: element?.status,
            saleorderId: element?.saleorderId,
            patientName: element?.patientName,
            daySheet: "12/12/2020",
            dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            // totalBilledAmount: element?.totalBilled,
            patBal: element?.totalPatientBal,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            id: element?.id,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            // planCode: null,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount
          });
          // }

        })
      } else {
        const itemList = (query: any) => this.orderSearchService.getList(query);
        this.list.hookToQuery(itemList).subscribe(response => {
          response?.items?.forEach(value => {
            if (value?.orders?.isCompleted == "Done" && value?.orderProcessStatus == "Ready For Order Complete Process" && value?.isShippingApprove == 0) {
              this.claimTableData.push({
                totalBilledAmount: value?.shippingCheckList?.totalItemPrice,
                totalPatientBal: 0,
                totalPatientAmount: 0,
                patientId: value?.patients?.defaultPatientId,
                patientName: value?.patients?.personals?.fullName,
                createDate: value?.creationTime == null ? undefined : new Date(value?.creationTime)?.toLocaleDateString("en-US"),
                dos: value?.items[0]?.originalDos == null ? "" : new Date(value?.items[0]?.originalDos)?.toLocaleDateString("en-US"),
                VerifyName: value?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.planName,
                Status: value?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.verificationStatus,
                saleorderId: value?.saleorderId,
                location: value?.orders?.invLocationId,
                supplyDate: value?.orders?.scheduledDate == null ? undefined : new Date(value?.orders?.scheduledDate)?.toLocaleDateString("en-US"),
                email: value?.patients?.personals?.emailId,
                data: value?.id,
                readyCount: "Ready",
                daySheet: "12/12/2020",
              })
            }
          })
          this.tableService.setBillingHistoryDataTable(this.claimTableData);

        })

      }

      this.isLoading = false;
      this.tableService.setBillingHistoryDataTable(this.claimTableData);
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(patientLists);
  }
  saveClaimHistory(response: SaleorderDTO, status) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    let authDetails: SaleorderDTO
    if (status === "Approved") {
      authDetails = {
        orders: {
          ...response.orders, isCompleted: "Done"
        },
        insurances: response.insurances,
        clinicals: response.clinicals,
        items: response.items,
        orderStatus: response.orderStatus,
        saleorderId: response.saleorderId,
        currentStage: this.claimHistoryForm.value.chkShippingApproved === true ? "shippingToBilling" : "orderProcessing",
        currentStageStatus: response.currentStageStatus,
        orderWipStatus: response.orderWipStatus,
        patientLedgerStatus: response.patientLedgerStatus,
        claimHistoryStatus: this.claimHistoryForm.value.rbtnClaimHistory,
        shippingApprovalStatus: this.claimHistoryForm.value.chkShippingApproved === true ? "shippingToBilling" : "orderProcessing",
        patients: response.patients,
        organizationUnitId: response.organizationUnitId,
        patientId: response.patientId,
        isShippingApprove: response.isShippingApprove,
        isCashPayment: response.isCashPayment,
        patientHistoryReason: this.claimHistoryForm.value.txtReason,
        patientLedgerReason: response.patientLedgerReason,
        documentReason: response.documentReason,
        paymentTransactionDetails: response.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        orderProcessStatus: response?.orderProcessStatus,
        authType: response?.authType

      }
    } else {
      authDetails = {
        orders: {
          ...response.orders, isCompleted: "Shipping Disapproved"
        },
        insurances: response.insurances,
        clinicals: response.clinicals,
        items: response.items,
        orderStatus: "Shipping Disapproved",
        saleorderId: response.saleorderId,
        currentStage: this.claimHistoryForm.value.chkShippingApproved === true ? "shippingToBilling" : "orderProcessing",
        currentStageStatus: response.currentStageStatus,
        orderWipStatus: response.orderWipStatus,
        patientLedgerStatus: response.patientLedgerStatus,
        claimHistoryStatus: this.claimHistoryForm.value.rbtnClaimHistory,
        shippingApprovalStatus: this.claimHistoryForm.value.chkShippingApproved === true ? "shippingToBilling" : "orderProcessing",
        patients: response.patients,
        organizationUnitId: response.organizationUnitId,
        patientId: response.patientId,
        isShippingApprove: response.isShippingApprove,
        isCashPayment: response.isCashPayment,
        patientLedgerReason: response.patientLedgerReason,
        patientHistoryReason: this.claimHistoryForm.value.txtReason,
        documentReason: response.documentReason,
        paymentTransactionDetails: response.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        orderProcessStatus: response?.orderProcessStatus,
        authType: response?.authType

      }
    }
    // let personal: PatientDTO;


    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, authDetails)).subscribe(responses => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')

      this.saveHistoryFormState.emit(this.claimHistoryForm.value);
      //   this.getAuthTableData(this.orderId);
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, authDetails)).subscribe(responses => {
      this.saveHistoryFormState.emit(this.claimHistoryForm.value);
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

  }
  getClaimHistoryById() {
    this.orderService.get(this.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      let selectedOrder: SaleorderDTO = response;
      // let authDetail: AuthorizationDTO = selectedOrder?.authorizations[0];
      this.claimHistoryForm.patchValue({
        chkShippingApproved: selectedOrder.shippingApprovalStatus === "shippingToBilling" ? true : false,
        rbtnClaimHistory: selectedOrder.claimHistoryStatus,
        txtReason: selectedOrder.patientHistoryReason
      });
      if (selectedOrder?.claimHistoryStatus === "Disapproved") {
        this.show = true
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

  }
  //Show only Ready status records
  showInt(event: MatCheckboxChange) {
    this.isShowInt = event.checked;
    if (event.checked == true && this.isShowPaid == false) {
      let status = "Ready";
      let isPostingCompleted = 0;
      const getList = this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element.patientResponsibilityCount,
            // planCode: null,
            processedCount: element.processedCount,
            readyCount: element.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount
          });
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getList);
    }
    else if (event.checked == true && this.isShowPaid == true) {
      let status = "Ready";
      let isPostingCompleted = 1;
      const getList = this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element.patientResponsibilityCount,
            // planCode: null,
            processedCount: element.processedCount,
            readyCount: element.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount
          });
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getList);
    }
    else {
      if (this.isShowPaid == true) {
        let status = null;
        let isPostingCompleted = 1;
        const getList = this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
          this.claimTableData = [];
          response?.items?.forEach((element, index) => {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientId: element?.defaultPatientId,
              //status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
              //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element.patientResponsibilityCount,
              // planCode: null,
              processedCount: element.processedCount,
              readyCount: element.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount
            });
          })
          this.tableService.setBillingHistoryDataTable(this.claimTableData);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(getList)
      }
      else {
        this.loadPatientVisitsTable();
      }
    }
  }

  //Show only PostingCompleted records
  showPaid(event: MatCheckboxChange) {
    this.isShowPaid = event.checked;
    if (this.isShowInt == false && event.checked == true) {
      let status = null;
      let isPostingCompleted = 1;
      const getList = this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element.patientResponsibilityCount,
            // planCode: null,
            processedCount: element.processedCount,
            readyCount: element.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount
          });
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getList);
    }
    else if (this.isShowInt == true && event.checked == true) {
      let status = "Ready";
      let isPostingCompleted = 1;
      const getList = this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element.patientResponsibilityCount,
            // planCode: null,
            processedCount: element.processedCount,
            readyCount: element.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount
          });
        })
        this.tableService.setBillingHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getList);
    }
    else {
      if (this.isShowInt == true) {
        let status = "Ready";
        let isPostingCompleted = 0;
        const getList = this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
          this.claimTableData = [];
          response?.items?.forEach((element, index) => {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientId: element?.defaultPatientId,
              //status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
              // dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element.patientResponsibilityCount,
              // planCode: null,
              processedCount: element.processedCount,
              readyCount: element.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount
            });
          })
          this.tableService.setBillingHistoryDataTable(this.claimTableData);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(getList);
      }
      else {
        this.loadPatientVisitsTable();
      }

    }
  }

  ViewClaim(id: string) {
    // const dialogRef = this.dialog.open(ClaimInfoComponent);

    // dialogRef.afterClosed().subscribe(() => {

    // });
  }
  CreateNewPatientWindow: any = 0;

  claimInfo(id: string) {
    this.router.navigate(['/billing/claimInsurance'])

  }

}
export interface billingList {
  id: string,
  status: string,
  daysheet: string,
  dos: string,
  provider: string,
  data: string,
  patBal: string,
  insBal: string,
  total: string,
  Fille: string,
  lastFilled: string,
  claimid: string,

}
