import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import 'devextreme/data/odata/store';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MISReportsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/mis-reports';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import DataSource from 'devextreme/data/data_source';
import {
  arAgingFilter,
  arAgingFilterOptions,
} from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/enumeration-data/ar-aging-filter.enum';
import { Title } from '@angular/platform-browser';
import { coloumnConfig } from '../billing-reports-tab/billing-reports-tab.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ClaimInfoDetailsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { ClaimStatusDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { ClaimStatusSearchDTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto';
import { Subscription } from 'rxjs';
const getOrderDay = function (rowData: any): number {
  return new Date(rowData.OrderDate).getDay();
};
@Component({
  selector: 'app-ar-aging-reports-tab',
  templateUrl: './ar-aging-reports-tab.component.html',
  styleUrls: ['./ar-aging-reports-tab.component.scss'],
})
export class ArAgingReportsTabComponent implements OnInit, OnDestroy {
  ARForm: FormGroup;
  tableColumns: coloumnConfig[] = [];
  reportType: string;
  reportTypes: string[] = ['PatientWise', 'InsuranceWise'];
  showButtonHide: boolean = false;
  patientId: string;
  drpAmountType: { value: string; key: string }[] = [];
  amountType: string;
  isLoading = false;
  isClaimLoading: boolean = false;
  columnResizingMode: string = 'nextColumn';
  showHeaderFilter: boolean = true;
  showFilterRow: boolean = true;
  currentFilter: any;
  loadingVisible: boolean = false;
  showInfo = true;

  subscription$: Subscription[] = [];
  dataSource: DataSource = new DataSource([]);
  claimsDataSource: MatTableDataSource<ClaimStatusSearchDTO> =
    new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  arrDisplayedClaimColumns: string[] = [
    'defaultClaimId',
    'defaultOrderId',
    //"defaultPatientId",
    //"providerName",
    //"billedQuantity",
    'billedAmount',
    'insuranceName',
    'currentBalance',
    //"dos",
    'claimStatus',
    'waystarClaimStatus',
    'postingCount',
    'claimProcessedDate',
  ];
  readonly allowedPageSizes = [15, 30, 100, 200];
  popupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  allowSearch: boolean = true;
  customOperations = [
    {
      name: 'weekends',
      caption: 'Weekends',
      dataTypes: ['date'],
      icon: 'check',
      hasValue: false,
      calculateFilterExpression() {
        return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
      },
    },
  ];
  ngOnInit(): void {
    let pageState: { columns: { name: string }[] };
    if (localStorage.getItem('storage')) {
      pageState = JSON.parse(localStorage.getItem('storage') ?? '');
    }
    if (!pageState?.columns?.some((x) => x.name === 'zeroToThirtyDays')) {
      localStorage.removeItem('storage');
    }
    this.title.setTitle('Qsecure | AR Aging Reports');
    this.initializeForm();
    //this.getARAgingReport(0);
    //this.drpAmountType=this.arAgingFilterOptions

    this.drpAmountType = arAgingFilterOptions?.map((e) => {
      let x = {
        value: e.value,
        key: e.key,
      };
      return x;
    });

    this.ARForm.patchValue({
      rbtnReportType: this.reportTypes?.[0] ?? '',
      drpAmountType: this.drpAmountType?.[2]?.key ?? '',
    });
    this.reportType = this.reportTypes?.[0] ?? '';
    this.amountType = this.drpAmountType?.[2]?.value ?? '';

    this.getARAgingReport(this.amountType);
    this.tableColumns = [
      {
        caption: 'Patient Id',
        dataType: 'string',
        dataField: 'defaultPatientId',
        cellTemplate: 'defaultID&GUIDCellTemplate',
      },
      {
        caption: 'Patient Name',
        dataType: 'string',
        dataField: 'patientName',
        cellTemplate: 'patientNameCellTemplate',
      },
      {
        caption: 'Provider Name',
        dataType: 'string',
        dataField: 'providerName',
      },
      { caption: 'Date of Birth', dataType: 'date', dataField: 'dob' },
      { caption: 'Branch', dataType: 'string', dataField: 'branch' },
      {
        caption: '0-30 Days',
        dataType: 'string',
        dataField: 'zeroToThirtyDays',
      },
      {
        caption: '31-60 Days',
        dataType: 'string',
        dataField: 'thirtyToSixtyDays',
      },
      {
        caption: '61-90 Days',
        dataType: 'string',
        dataField: 'sixtyToNinetyDays',
      },
      {
        caption: '>90 Days',
        dataType: 'string',
        dataField: 'moreThanNinetyDays',
      },
    ];
  }
  ngOnDestroy(): void { }

  //Clear Dropdown
  clearDrpForms() {
    this.amountType = '';
    this.ARForm.patchValue({ drpAmountType: '' });
  }
  //To Intialize Form
  initializeForm() {
    this.ARForm = this.fb.group({
      rbtnReportType: new FormControl('', Validators.required),
      drpAmountType: new FormControl('', Validators.required),
    });
  }

  constructor(
    private title: Title,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private claimInfoService: ClaimInfoDetailsService,
    private mISReportsService: MISReportsService
  ) {
    //   this.dataSource = service.getDataSource();
  }
  //To get the AR Aging Report
  getARAgingReport(e: string) {
    this.loadingVisible = true;
    if (this.reportType == 'PatientWise') {
      const filter: arAgingFilter = this.ARForm.value.drpAmountType;

      const patientWise = this.mISReportsService
        .arAgingReportPatientWiseByFilter(filter)
        .subscribe(
          (response) => {
            this.dataSource = new DataSource(response ?? []);
            this.loadingVisible = false;
            this.tableColumns = [
              {
                caption: 'Patient Id',
                dataType: 'string',
                dataField: 'defaultPatientId',
                cellTemplate: 'defaultID&GUIDCellTemplate',
              },
              {
                caption: 'Patient Name',
                dataType: 'string',
                dataField: 'patientName',
                cellTemplate: 'patientNameCellTemplate',
              },

              {
                caption: 'Provider Name',
                dataType: 'string',
                dataField: 'providerName',
              },
              { caption: 'Date of Birth', dataType: 'date', dataField: 'dob' },
              { caption: 'Branch', dataType: 'string', dataField: 'branch' },
              {
                caption: '0-30 Days',
                dataType: 'string',
                dataField: 'zeroToThirtyDays',
              },
              {
                caption: '31-60 Days',
                dataType: 'string',
                dataField: 'thirtyToSixtyDays',
              },
              {
                caption: '61-90 Days',
                dataType: 'string',
                dataField: 'sixtyToNinetyDays',
              },
              {
                caption: '>90 Days',
                dataType: 'string',
                dataField: 'moreThanNinetyDays',
              },
            ];
          },
          (err) => {
            this.loadingVisible = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );

      this.subscription$.push(patientWise);
    } else if (this.reportType == 'InsuranceWise') {
      const filter: arAgingFilter = this.ARForm.value.drpAmountType;
      const insuranceWise = this.mISReportsService
        .arAgingReportInsuranceWiseByFilter(filter)
        .subscribe(
          (response) => {
            this.tableColumns = [
              {
                caption: 'Insurance Name',
                dataType: 'string',
                dataField: 'insuranceName',
              },
              {
                caption: '0-30 Days',
                dataType: 'string',
                dataField: 'zeroToThirtyDays',
              },
              {
                caption: '31-60 Days',
                dataType: 'string',
                dataField: 'thirtyToSixtyDays',
              },
              {
                caption: '61-90 Days',
                dataType: 'string',
                dataField: 'sixtyToNinetyDays',
              },
              {
                caption: '>90 Days',
                dataType: 'string',
                dataField: 'moreThanNinetyDays',
              },
            ];
            this.dataSource = new DataSource(response ?? []);
            this.loadingVisible = false;
          },
          (err) => {
            this.loadingVisible = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(insuranceWise);
    }
  }
  getClaimList(templateRef: TemplateRef<any>, e) {
    this.setTableData([]);
    this.matDialog.open(templateRef, { width: '95%' });
    this.isClaimLoading = true;
    const claimList = this.claimInfoService
      .getClaimDetailsByPatientId(e)
      .subscribe(
        (response) => {
          this.isClaimLoading = false;
          this.setTableData(response);
        },
        (err) => {
          this.isClaimLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(claimList);
  }
  setTableData(response = []) {
    this.claimsDataSource = new MatTableDataSource(response);
    this.claimsDataSource.paginator = this.paginator;
  }
}
