<br />
<div class="card card-body">
<div class="row mt-4">
  <div class="col-6">
    <h4 class="customThemeClass mar-top"><b>Reminders</b></h4>

  </div>

</div>
<div class="row mt-4">
  <div class="col-12">
    <app-table (viewButton)="editReminderPopup($event)" [isLoading]="isLoading" (deleteButton)="deleteReminder($event)"  [strPageType]='strPageType'></app-table>
  </div>
</div>
</div>
