import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ReminderDTO } from '../order-proxy/order-management/order/dto/models';
import { ReminderService } from '../order-proxy/order-management/order/reminder.service';
import { addReminder, getReminder, updateReminder } from './reminder.action';

@State<reminderStateModel>({
  name: 'reminderState',
  defaults: {
    reminders: [],
    selectedreminder: null
  }
})
@Injectable()
export class reminderState {
  constructor(
    private reminderService: ReminderService,
  ) { }

  @Action(addReminder)
  addReminder({ patchState }: StateContext<reminderStateModel>, { payload }: addReminder) {
    return this.reminderService.create(payload).pipe(tap((result) => {
      patchState({
        selectedreminder: result
      });
    }));
  }
  @Action(updateReminder)
  updateReminder({ patchState }: StateContext<reminderStateModel>, { id, payload }: updateReminder) {
    return this.reminderService.update(id, payload).pipe(tap((result) => {
      patchState({
        selectedreminder: result
      });
    }));
  }


  @Action(getReminder)
  getReminder({ patchState }: StateContext<reminderStateModel>, { id }: getReminder) {
    return this.reminderService.get(id).pipe(tap((result) => {
      patchState({
        selectedreminder: result
      });
    }));
  }

}
export class reminderStateModel {
  reminders: ReminderDTO[];
  selectedreminder: ReminderDTO |null;
}
