import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transactioncodes-list',
  templateUrl: './transactioncodes-list.component.html',
  styleUrls: ['./transactioncodes-list.component.scss']
})
export class TransactioncodesListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
