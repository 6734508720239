<!-- <div class="card card-body"> -->
    <div class="col-lg-12 content table-responsive">
        <ng-container>
            <table id="tblPractice" datatable [dtOptions]="patientTabledetails"
                class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th><mat-icon>apps</mat-icon></th>
                        <th [matTooltip]="'Practice Name'">Practice Name</th>
                        <th [matTooltip]="'Fax Number'">Fax Number</th>
                        <th [matTooltip]="'Fax Id'">Fax Id</th>
                        <th [matTooltip]="'Patient Name'">Patient Name</th>
                        <th [matTooltip]="'Chart No'">Chart No</th>
                        <th [matTooltip]="'Received Fax On'" class="text-center">Received Fax On</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of patientTableData">
                        <td>
                            <a class="eyeCursorclass">
                                <i class="fa fa-eye editBtnColor mr-1" (click)="OpenPatienList(data.practiceId)"></i>
                            </a>
                        </td>
                        <td [matTooltip]="data.practiceName || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.practiceName || '-')">
                            <a [routerLink]="'/addpracticemanagement/' + data.practiceId" class="preview-link"> {{
                                (data.practiceName !== null && data.practiceName !== ' ') ? data.practiceName : '-'
                                }}</a>
                        </td>
                        <td [matTooltip]="data.faxNumber || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.faxNumber || '-')">{{ data.faxNumber ?
                            data.faxNumber :
                            '-'}}</td>

                        <td [matTooltip]="data.defaultFaxId || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.defaultFaxId || '-')">
                            <a [routerLink]="'/patient_preview_tiff_document/'+ data.defaultFaxId+ '/' + data.documentId"
                                target="_blank" [class.disabled]="isEmpty(data.defaultFaxId)"
                                class="preview-link">{{
                                data.defaultFaxId ?
                                data.defaultFaxId :
                                '-'}}</a>
                        </td>
                        <td [matTooltip]="data.patientName || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.patientName || '-')">{{ data.patientName ?
                            data.patientName :
                            '-'}}</td>
                              <td (dblclick)="onRowDoubleClickV1(data.chartId || '-')" >
                                <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank" [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
                              </td>
                        <td [matTooltip]="data?.lastSenddate"
                            (dblclick)="onRowDoubleClickV1(data?.lastSenddate)"  class="text-center">
                            {{ data?.lastSenddate }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th><mat-icon>apps</mat-icon></th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Practice Name"
                                name="search-PracticeName" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Fax Number"
                                name="search-FaxNumber" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Fax Id"
                                name="search-FaxId" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Patient Name"
                                name="search-PatientName" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Chart No"
                                name="search-ChartNo" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class text-center" placeholder="Last Sent Date"
                                name="search-LastSentDate" />
                        </th>
                    </tr>
                </tfoot>

            </table>
        </ng-container>
    </div>
<!-- </div> -->