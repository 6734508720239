import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { TS835 } from '../../../edi-fabric/templates/hipaa5010/models';
import type { PayerDTO } from '../../patient-management/patient/dto/models';
import { AutoPostingFiles, ClaimDTO, ExportClaimReportDTO, SearchClaimStatusDTO } from '../../billing-management/posting/dto';
import { IFormFile } from 'projects/patient/src/app/patient-proxy/microsoft/asp-net-core/http';
import { Observable } from 'rxjs';
import { APIResponseDTO } from './dto';

@Injectable({
  providedIn: 'root',
})
export class ZermidClearingHouseService {
  apiName = 'ClaimProcessingManagement';

  checkClaimHistoryBySClaimNumberAndDtDateOfServiceAndClaimId = (sClaimNumber: string, dtDateOfService: string, claimId: string) =>
    this.restService.request<any, ClaimDTO>({
      method: 'POST',
      url: `/api/claimProcessingApp/zermid-clearing-house/check-claim-history/${claimId}`,
      params: { sClaimNumber, dtDateOfService },
    },
      { apiName: this.apiName });

  convertCsvFileToJsonObjectBySPath = (sPath: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/zermid-clearing-house/convert-csv-file-to-json-object',
      params: { sPath },
    },
      { apiName: this.apiName });

  downloadEOBByBlobName = (blobName: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/zermid-clearing-house/download-eOB',
      params: { blobName },
    },
      { apiName: this.apiName });

  downloadPaymentFile835 = () =>
    this.restService.request<any, TS835>({
      method: 'POST',
      url: '/api/claimProcessingApp/zermid-clearing-house/download-payment-file835',
    },
      { apiName: this.apiName });

  exportClaimReportByLstExportData = (lstExportData: ExportClaimReportDTO[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/zermid-clearing-house/export-claim-report',
      body: lstExportData,
    },
      { apiName: this.apiName });

    read835EOBClaimFileByEobFileAndEobPdfFile(eobFile: File, eobPdfFile: File ,iPostingMode: number): Observable<string> {
      const formData = new FormData();
      formData.append('eobPdfFile', eobFile);
      const formData1 = new FormData();
      formData.append('eobFile', eobPdfFile);
      return this.restService.request<any, string>(
        {
          method: 'POST',
          url: '/api/claimProcessingApp/zermid-clearing-house/read835EOBClaim-file',
          body: formData,
          params: { iPostingMode },
        },
        { apiName: this.apiName }
      );
    }
  
  getAllClaimHistory = () =>
    this.restService.request<any, PagedResultDto<ClaimDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/zermid-clearing-house/get-all-claim-history',
    },
      { apiName: this.apiName });

  getAllClaimStatus = () =>
    this.restService.request<any, PagedResultDto<ClaimDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/zermid-clearing-house/get-all-claim-status',
    },
      { apiName: this.apiName });

  getClaimsBasedOnStatusByClaimStatus = (claimStatus: string) =>
    this.restService.request<any, ClaimDTO[]>({
      method: 'GET',
      url: '/api/claimProcessingApp/zermid-clearing-house/get-claims-based-on-status',
      params: { claimStatus },
    },
      { apiName: this.apiName });

  getRemitsEobHistory = (sClaimNumber: string, dtDateOfService: string, claimId: string, tenantId: string) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: '/api/claimProcessingApp/zermid-clearing-house/get-remits-eob-history',
      params: { sClaimNumber, dtDateOfService, claimId, tenantId },
    },
      { apiName: this.apiName });

  refreshPayer = () =>
    this.restService.request<any, PayerDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/zermid-clearing-house/refresh-payer',
    },
      { apiName: this.apiName });

  uploadClaimBatchBySSourceFilePathForSftpUpload = (sSourceFilePathForSftpUpload: string) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/claimProcessingApp/zermid-clearing-house/upload-claim-batch',
      params: { sSourceFilePathForSftpUpload },
    },
      { apiName: this.apiName });
  searchClaimStatusByClaimStatusAndOSearchClaim = (claimStatus: string, oSearchClaim: SearchClaimStatusDTO) =>
    this.restService.request<any, ClaimDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/zermid-clearing-house/search-claim-status',
      params: { claimStatus },
      body: oSearchClaim,
    },
      { apiName: this.apiName });
  update835PaymentByEobFileAndEobTxtFileAndEobPdfFileAndIPostingMode(eobFile: File, eobTxtFile: File, eobPdfFile: File, iPostingMode: number): Observable<APIResponseDTO> {
    const formData = new FormData();
    formData.append('eobFile', eobFile);
    formData.append('eobTxtFile', eobTxtFile);
    formData.append('eobPdfFile', eobPdfFile);
    return this.restService.request<any, APIResponseDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/zermid-clearing-house/update835Payment',
        body: formData,
        params: { iPostingMode },
      },
      { apiName: this.apiName }
    );
}
  constructor(private restService: RestService) { }
}
