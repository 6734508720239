
    <div #tableContainer class="p-4" >
        <!-- style="overflow-y: auto; height: 400px;" -->
        <!-- <div *ngIf="!isLoading"> -->
        <table datatable [dtOptions]="dtOptions" class="faxfindertbl row-border hover">
            <thead>
                <tr>
                    <!-- <th><mat-icon>apps</mat-icon></th> -->
                    <th>Fax Id</th>
                    <th>Patient Name</th>
                    <th>chart Id</th>
                    <th>Inbound Status</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr *ngFor="let data of faxFinderDataList">
                    <td>1</td> 
                    <td  [matTooltip]="data?.faxId || '-'"><a [routerLink]="'/patientCreateEditTab/'" target="_blank"> {{data?.faxId || '-'}}</a>
                    </td>
                    <td  [matTooltip]="data?.patientName || '-'">{{ data?.patientName || '-' }}</td>
                    <td  [matTooltip]="data?.chartId || '-'">{{ data?.chartId || '-' }}</td>
                    <td  [matTooltip]="data?.referalsourceName || '-'">{{ data?.referalsourceName || '-' }}</td>
                    
                  </tr> -->
            </tbody>
            <tfoot>
                <tr>
                    <!-- <th></th> -->
                    <th><input #faxIdInput matInput type="text" class="textbox-border-class" placeholder="Fax Id" /></th>
                    <th><input #patientNameInput matInput type="text" class="textbox-border-class" placeholder="Patient Name" /></th>
                    <th><input #chartNoInput matInput type="text" class="textbox-border-class" placeholder="Chart No" /></th>
                    <th><input #referralSourceInput matInput class="textbox-border-class" type="text" placeholder="Referral Source Name" /></th>
                </tr>
            </tfoot>
        </table>

        
    <!-- </div> -->

    <mat-menu #menu="matMenu">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
        <button mat-menu-item>Item 3</button>
      </mat-menu>
    <!-- <button mat-icon-button [matMenuTriggerFor]="menu">Open Menu</button> -->
    <!-- <button #menuTrigger mat-icon-button [matMenuTriggerFor]="menu">Open Menu</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>Item 1</button>
      <button mat-menu-item>Item 2</button>
      <button mat-menu-item>Item 3</button>
    </mat-menu> -->
    <!-- <button (click)="loadMore()">Load More</button> -->
</div>


