import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { PDFDocument } from 'pdf-lib';
import {
  NgxExtendedPdfViewerComponent,
  NgxExtendedPdfViewerService,
  PageViewModeType,
  PagesLoadedEvent,
} from 'ngx-extended-pdf-viewer';

import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DocumentClassificationDto,
  SplitPageDetails,
} from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { DocumentSortingService } from '../admin-proxy/platform-app-management/rcm/platform-management/sorting';
import { SaveDocumentDto } from '../admin-proxy/platform-app-management/rcm/platform-management/sorting/dto/models';
import { SortType } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/sort-type.enum';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DocumentBinService } from '../admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableService } from 'projects/shared/src/app/table.service';
import { Console } from 'console';

@Component({
  selector: 'app-document-sorting',
  templateUrl: './document-sorting.component.html',
  styleUrls: ['./document-sorting.component.scss'],
})
export class DocumentSortingComponent implements OnInit {
  strSelectedPdfPath: string = '';
  inputForm: FormGroup;
  pageViewMode: PageViewModeType = 'single';
  pdfHeight: string;
  patientId: string;
  defaultPatientId: string;
  patientName: string;
  documentId: string;
  docView: any;
  totalPages: number = 0;
  pdfPages: number = 0;
  pageNumber: number = 1;
  width: any;
  pageCount;
  tempArray = [];
  imageDataURL: any;
  widthDisplay: number;
  sortStatus: any;
  isModel: boolean = false;
  heightDisplay: number;
  isLoading: boolean = false;
  moveButtonDisabled: boolean = true;
  completeButtonDisabled: boolean = true;
  currentPage: number = 1;
  faxId: any;
  sortingBin: any;
  moveToMissingBin: any;
  dTypeId: string; //Document Type NgModel Field
  documentClassification: DocumentClassificationDto[] = [];
  saveDocumentDetails: SaveDocumentDto[] = [];
  subscription$: Subscription[] = [];
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private inboundDocumentService: InboundDocumentService,
    private patientPersonalService: PatientPersonalService,
    private SortingService: DocumentSortingService,
    private documentBindService: DocumentBinService,
    private cookieService: CookieService,
    private tableService: TableService,
    private fb: FormBuilder,
    private InboundDocumentService: InboundDocumentService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // if (data && data.patientId && data.documentId) {
    // }
  }

  //Page Load Function
  ngOnInit(): void {
    this.pdfHeight = '80%';
    this.moveButtonDisabled = true;
    this.activatedRoute.paramMap.subscribe((response) => {
      if (response?.get('patientId')) {
        this.patientId = response?.get('patientId') ?? null;
      }
      if (response?.get('documentId')) {
        this.documentId = response?.get('documentId') ?? '';
      }
    });

    if (this.patientId == undefined) {
      this.patientId = this.data.patientId;
      this.sortStatus = false;
      this.isModel = true;
    }
    if (this.documentId == undefined) {
      this.documentId = this.data.documentId;
    }

    this.sortingBin = this.cookieService.get('sortingBin');
    if (this.sortStatus == undefined) {
      this.sortStatus = this.cookieService.get('sortingView');
    }

    this.loadDocumentTypes();

    if (this.sortStatus == 'true') {
      this.sortingDocView();
    } else {
      this.loadInboundDocument();
    }
    this.getFax();
    this.loadPatientData();
    //this.completeButtonDisabled=this.documentClassification.length>0?false:true;
  }
  getFax() {
    this.InboundDocumentService.get(this.documentId).subscribe((response) => {
      this.moveToMissingBin = response;
      this.faxId = response.defaultFaxId;
    });
  }
  sortingDocView() {
    // this.inboundDocumentService.getSortedDocumentPagesByGDocumentId(this.documentId)
    // .subscribe((response) =>{

    //           this.docView = response;
    //       let test = response[0].fileContent;

    //       this.strSelectedPdfPath = String(
    //         'data:application/pdf;base64,' + test
    //       );

    //       this.docView.forEach((data) => {
    //         this.documentClassification.forEach((x) => {
    //           if (x.documentType === data.documentType) {
    //             x.documentPages = data.pageCount;
    //             x.fileCount = data.pagecount;
    //           } else {
    //           }
    //         });
    //       });
    // },
    // (err) => {
    //   console.warn('Error', err);

    //   // Check if the error has the expected structure
    //   if (err.error && err.error.message) {
    //     // Parse the JSON error message and extract the specific message
    //     try {
    //       const errorMessageObject = JSON.parse(err.error.message);
    //       const specificErrorMessage = errorMessageObject.error.message;

    //       // Print the specific error message

    //       // Show a warning toaster with the specific error message
    //       this.toastr.warning('Reason: ' + specificErrorMessage, 'Warning');
    //     } catch (parseError) {
    //       // Log a warning if there is an issue parsing the error message
    //       console.warn('Error parsing error message:', parseError);
    //       this.toastr.warning('Reason: ' + parseError, 'Warning');
    //     }
    //   } else {
    //     // If the error structure is unexpected, log the entire error message
    //     console.warn('Unexpected error structure:', err);
    //     this.toastr.error('Fax has not joined: Reason: ' + err, 'Error');
    //   }

    // }
    // );

    this.patientPersonalService
      .getSortedDocumentPDF(this.patientId, this.documentId)
      .subscribe(
        (response) => {
          this.docView = response;
          let test = response[0].fileContent;

          this.strSelectedPdfPath = String(
            'data:application/pdf;base64,' + test
          );

          this.docView.forEach((data) => {
            this.documentClassification.forEach((x) => {
              if (x.documentType === data.documentType) {
                x.documentPages = data.pageCount;
                x.fileCount = data.pagecount;
              } else {
              }
            });
          });
        },
        (err) => {
          this.strSelectedPdfPath = '';
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  //To Load the Inbound Document Details
  loadInboundDocument() {
    // this.inboundDocumentService.getSortedDocumentPagesByGDocumentId(this.documentId)
    // .subscribe((response) =>{

    //           this.docView = response;

    //       let test = response[0].fileContent;

    //       this.strSelectedPdfPath = String(
    //         'data:application/pdf;base64,' + test
    //       );

    //       // this.docView.forEach((data) => {
    //       //   this.documentClassification.forEach((x) => {
    //       //     if (x.documentType === data.documentType) {
    //       //       x.documentPages = data.pageCount;
    //       //       x.fileCount = data.pagecount;
    //       //     } else {
    //       //     }
    //       //   });
    //       // });
    // },
    // (err) => {
    //   console.warn('Error', err);

    //   // Check if the error has the expected structure
    //   if (err.error && err.error.message) {
    //     // Parse the JSON error message and extract the specific message
    //     try {
    //       const errorMessageObject = JSON.parse(err.error.message);
    //       const specificErrorMessage = errorMessageObject.error.message;

    //       // Print the specific error message

    //       // Show a warning toaster with the specific error message
    //       this.toastr.warning('Reason: ' + specificErrorMessage, 'Warning');
    //     } catch (parseError) {
    //       // Log a warning if there is an issue parsing the error message
    //       console.warn('Error parsing error message:', parseError);
    //       this.toastr.warning('Reason: ' + parseError, 'Warning');
    //     }
    //   } else {
    //     // If the error structure is unexpected, log the entire error message
    //     console.warn('Unexpected error structure:', err);
    //     this.toastr.error('Fax has not joined: Reason: ' + err, 'Error');
    //   }

    // }
    // );

    this.inboundDocumentService
      .downloadInboundDocumentByDocumentId(this.documentId)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strSelectedPdfPath = String(
              'data:application/pdf;base64,' + response
            );
          }
        },
        (err) => {
          this.strSelectedPdfPath = '';
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  /// for enter number only
  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 44 || charCode > 45)
    ) {
      return false;
    }
    return true;
  }

  //Get Patient Details
  loadPatientData() {
    //Load Patient List Dropdown
    const searchPatientSub = this.patientPersonalService
      .patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        1
      )
      .subscribe((response) => {
        this.defaultPatientId = response?.find(
          (a) => a.patientId == this.patientId
        )?.defaultPatientId;
        this.patientName = response?.find(
          (a) => a.patientId == this.patientId
        )?.patientName;
      });
    this.subscription$.push(searchPatientSub);
  }
  getToolTip(data) {
    return data.documentType;
  }
  //Load Document Types
  loadDocumentTypes() {
    const documentBinTypes = this.documentBindService
      .documentTypeList()
      .subscribe(
        (response) => {
          if (response) {
            this.documentClassification = response.map((value) => ({
              docTypeId: value.id,
              documentType: value.documentType.toUpperCase(),
              documentPages: [],
              fileCount: 0,
            }));
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.subscription$.push(documentBinTypes);
  }

  //List Selection Event-To enable the document move button
  onListSelected(e) {
    this.moveButtonDisabled = false;
    if (this.sortStatus == 'true') {
      this.docView.forEach((data) => {
        this.documentClassification.forEach((x) => {
          if (x.documentType === data.documentType) {
            if (x.docTypeId == e) {
              this.strSelectedPdfPath = String(
                'data:application/pdf;base64,' + data.fileContent
              );
            }
            x.fileCount = data.pageCount;
          }
        });
      });
    } else {
    }
  }
  //Sort Document to the Document Types
  async moveDocument() {
    let list = [];
    let documentDetails: DocumentClassificationDto;

    var split = this.pageCount?.split('-');

    if (split && split != '') {
      if (split.length < 2) {
        split = this.pageCount.split(',');
        split = split.map((a) => Number(a));

        list = split;
      } else {
        for (var i = Number(split[0]); i <= Number(split[1]); i++) {
          list.push(i);
        }
      }
    } else {
      list = [this.currentPage];
      this.pageCount = this.currentPage;
    }
    for (const x of list) {
      this.pageNumber = x;
      const val = await this.getPdfPage();
      this.completeButtonDisabled =
        this.documentClassification.length > 0 ? false : true;
      const pageDetails: SplitPageDetails = {
        pageDetails: val,
        pageNumber: this.pageNumber,
      };

      //Current Page with the Last Page condition of the pdf
      this.currentPage =
        this.pdfPages < Number(this.pageNumber) + 1
          ? this.currentPage
          : Number(this.pageNumber) + 1;

      //Page Already Added to any Classification Validation
      if (!this.isPageNumberAlreadyExists(this.pageNumber)) {
        documentDetails = this.documentClassification.find(
          (value) => value.docTypeId === this.dTypeId[0]
        );
        documentDetails.documentPages.push(pageDetails);
        let SaveDocumentDto: SaveDocumentDto = {
          patientId: this.patientId == 'null' ? null : this.patientId,
          sortType: SortType.Manual,
          documentTypeId: documentDetails.docTypeId,
          inboundDocumentId: this.documentId,
          documentType: documentDetails.documentType,
          pageNumber: pageDetails.pageNumber,
          pageContent: pageDetails.pageDetails,
        };
        this.saveDocumentDetails.push(SaveDocumentDto);
        this.totalPages--;
      } else {
        this.toastr.error('Page ' + this.pageNumber + ' Already Sorted!');
      }
    }
    documentDetails != null &&
      this.toastr.success(
        'Page ' +
          this.pageCount +
          ' Added to ' +
          documentDetails.documentType +
          '!'
      );
    this.pageCount = '';
  }
  //Split Pdf
  public async getPdfPage() {
    const dataUri = 'data:application/pdf;base64,' + this.strSelectedPdfPath;
    const mainDocument = await PDFDocument.load(dataUri);
    const splitDocument = await PDFDocument.create();
    const [existingPage] = await splitDocument.copyPages(mainDocument, [
      this.pageNumber - 1,
    ]);
    splitDocument.addPage(existingPage);
    // const base64StringMainDoc = await mainDocument.saveAsBase64();

    const base64StringSplitDoc = await splitDocument.saveAsBase64();
    return base64StringSplitDoc;
    //For Base64Uri
    //const base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true })
  }
  //On Pages Load to Get the total Pages in the pdf
  public onPagesLoaded(pagecount: PagesLoadedEvent): void {
    this.totalPages = pagecount.pagesCount;
    this.pdfPages = pagecount.pagesCount;
  }
  onPageChange(e) {
    this.currentPage = e;
    // this.pdfViewer.navigateToPage(pageNumber);
  }
  //Page Number Already Exists In the Document List Validation Method
  isPageNumberAlreadyExists(pageNumber: number): boolean {
    return this.documentClassification.some((document) => {
      return document.documentPages.some(
        (page) => page.pageNumber === pageNumber
      );
    });
  }
  //Method to save the sorted document by api
  saveSortedDocument() {
    this.isLoading = true;
    this.completeButtonDisabled = true;
    Swal.fire({
      title: 'Are you sure you want to Complete Sorting?',
      // text: 'Complete!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      this.isLoading = false;
      if (result.value) {
        const docDetails =
          this.SortingService.saveSortedDocumentsByInboundDocumentIdAndDocumentSortingInput(
            this.documentId,
            this.saveDocumentDetails
          ).subscribe(
            (response) => {
              this.isLoading = false;
              this.completeButtonDisabled = false;
              this.toastr.success('Document Sorted Successfully');
              this.redirectToProcessedDocuments();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              this.isLoading = false;
              this.completeButtonDisabled = false;
            }
          );
      } else {
        this.completeButtonDisabled = false;
      }
    });
  }
  redirectToProcessedDocuments() {
    // this.router.navigate(['processedDocuments']);
    this.router.navigate(['dashboard']); //after completion,redirect to processedDocuments
  }
  redirectTomissingDocuments() {
    this.router.navigate(['dashboard']);
  }
}
