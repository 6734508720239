<meta http-equiv="cache-control" content="no-cache, must-revalidate, post-check=0, pre-check=0">
<meta http-equiv="expires" content="0">
<meta http-equiv="pragma" content="no-cache">
<div class="row p-2">

  <div class="col-12 d-flex justify-content-between">
    <h3 class="font-weight-bolder">Fax Id : <span class="app-custom-color font-weight-bolder">{{defaultFaxId ||
            '--'}}</span></h3>
    <h3 class="font-weight-bolder">Patient Name : <span class="app-custom-color font-weight-bolder">{{patientName ||'--'}}</span></h3>
    <h3 class="font-weight-bolder">Chart No : <span class="app-custom-color font-weight-bolder">{{chartID ||'--'}}</span></h3>

    <div class="row">
      <div class="col-12">
    <button class="buttonColor mb-2 p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isDownloading" mat-raised-button matTooltip="Download Patient Document" [matTooltipDisabled]="isEmpty(blobName)" (click)="patientDocPdfDownload()">
      <i class="fa fa-download mr-1" aria-hidden="true"></i> <span *ngIf="!isDownloading" class="dd-text ml-1">Document</span> <span *ngIf="isDownloading" class="dd-text ml-1">Downloading</span>
      <mat-icon *ngIf="isDownloading">
          <mat-spinner class="spinner-border-sm ml-1" diameter="18"></mat-spinner>
      </mat-icon>
    </button>

    <button class="buttonColor mb-2 p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isDownloading" mat-raised-button matTooltip="View Document in PDF" [matTooltipDisabled]="isEmpty(blobName)" (click)="viewPatientBlobPDF()">
      <i class="fa fa-eye" aria-hidden="true"></i> <span class="dd-text ml-1">View Document in PDF</span>
  </button>
</div>
</div>
</div>


  <!-- <div class="col-4"><h2 class="font-weight-bolder">Fax Id : <span class="app-custom-color font-weight-bolder">{{defaultFaxId || '--'}}</span></h2></div>
  <div class="col-4"><h2 class="font-weight-bolder">Patient Name : <span class="app-custom-color font-weight-bolder">{{patientName || '--'}}</span></h2></div>
  <div class="col-4"><h2 class="font-weight-bolder">Chart No : <span class="app-custom-color font-weight-bolder">{{chartID || '--'}}</span></h2></div> -->
    <!-- <div class="col-4">
        <h3 class="customThemeClass">
            <b> Fax : {{ headerText || '-' }}</b>
        </h3>
    </div>
    <div class="col-4">
      <h3 class="customThemeClass">
          <b> Patient Name : {{ patientName || '-' }}</b>
      </h3>
  </div>
  <div class="col-4">
    <h3 class="customThemeClass">
        <b> Chart Id : {{ chartID || '-' }}</b>
    </h3>
</div> -->
</div>

<!--  *ngIf="!isLoading"  -->
<div class="row pr-2 pl-2" >
    <div class="col-lg-2" [hidden]="isHideTiffViewer">
        <div class="card card-body align-items-center scrollable-card p-0" style="height: 80vh">
            <ng-container *ngFor="let image of existingDocumentImageList">
                <div class="card my-2 elevated-div custom-thumbnail-view">
                    <img class="card-img-top img-fluid custom-thumbnail-image" (click)="loadExistingImage(image)"
                        [ngClass]="{
                'custom-thumbnail-view-selected':
                  selectedExistingDocument?.pageNo === image?.pageNo
              }" [src]="image?.src" [alt]="image?.pageNo" />
                    <p class="align-end">Page No : {{ image.pageNo }}</p>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="col-lg-10" [hidden]="isHideTiffViewer">

        <div class="card card-body align-items-center justify-content-center p-0" style="background: #f9f9f9;">
          <!-- <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }"  *ngIf="isLoading || TiffError">
            {{ loadingMessage }}
          </h1> -->
            <div class="zoom-buttons" *ngIf="!isLoading" >

              <a [matTooltip]="'Rotate Clockwise'" class="ml-2 mt-2" (click)="rotateClockwise()"
                [class.disabled]="isLoading" href="javascript:void(0)">
                <mat-icon>rotate_right</mat-icon>
              </a>

              <a [matTooltip]="'Rotate Counter Clock Wise'" class="ml-2 mt-2" (click)="rotateCounterclockwise()"
                [class.disabled]="isLoading" href="javascript:void(0)">

                <mat-icon>rotate_left</mat-icon>
              </a>


            <a [matTooltip]="'Zoom In Page'" class="ml-2 mt-2" (click)="zoomIn()"
              [class.disabled]="isLoading || zoomLevel === MAX_ZOOM_LEVEL"
              href="javascript:void(0)">
              <mat-icon>add</mat-icon>
            </a>

            <a [matTooltip]="'Zoom Out Page'" class="ml-2 mt-2" (click)="zoomOut()"
              [class.disabled]="isLoading || zoomLevel === MIN_ZOOM_LEVEL"
              href="javascript:void(0)">
              <mat-icon>remove</mat-icon>
            </a>

            <a [matTooltip]="'Go to First Page'" class="ml-2 mt-2" (click)="firstImage()"
            [class.disabled]="isLoading || pageNumber === 1" href="javascript:void(0)">
            <mat-icon>first_page</mat-icon>
          </a>
          <a [matTooltip]="'Go to Previous Page'" class="ml-2 mt-2" (click)="previousImage()"
            [class.disabled]="isLoading ||  pageNumber === 1" href="javascript:void(0)">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </a>

                <!-- <button [matTooltip]="'Clear All Annotation'" mat-raised-button color="warn" (click)="clearAllAnnotation()" [disabled]="this.textAnnotations.length === 0" ><mat-icon>restore</mat-icon></button> -->
              <!-- <button [matTooltip]="'Zoom In Page'" mat-button color="primary" (click)="zoomIn()" [disabled]="isLoading">
                <mat-icon>add</mat-icon>
              </button>  -->
              <!-- <button [matTooltip]="'Go to First Page'" mat-button color="primary" (click)="firstImage()" [disabled]="isLoading">
                <mat-icon>first_page</mat-icon>
              </button> -->
              <!-- <button [matTooltip]="'Go to Previous Page'" mat-button color="primary" (click)="previousImage()" [disabled]="isLoading">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button> -->
              <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number" [(ngModel)]="pageNumber" min="1"
                max="{{totalPages}}" (focusout)="goToPage()" [readonly]="isLoading" />

              <span> of {{ totalPages }}</span>

            <a [matTooltip]="'Go to Next Page'" class="ml-2 mt-2" (click)="nextImage()"
              [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
              href="javascript:void(0)">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </a>
            <a [matTooltip]="'Go to Last Page'" class="ml-2 mt-2" (click)="lastImage()"
              [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
              href="javascript:void(0)">
              <mat-icon>last_page</mat-icon>
            </a>

              <!-- <button [matTooltip]="'Go to Next Page'" mat-button color="primary" (click)="nextImage()" [disabled]="isLoading">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
              <button  [matTooltip]="'Go to Last Page'" mat-button color="primary" (click)="lastImage()" [disabled]="isLoading">
                <mat-icon>last_page</mat-icon>
              </button> -->

              <!-- <button  [matTooltip]="'Zoom Out Page'" mat-button color="primary" (click)="zoomOut()" [disabled]="isLoading">
                <mat-icon>remove</mat-icon>
              </button>  -->
              <!-- <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
              (click)="saveAnnotation()"  [disabled]="this.textAnnotations.length === 0">
                <mat-icon>save</mat-icon>
              </button> -->
            </div>
        </div>

        <div class="d-flex justify-content-center scrollable-card p-0 background border-line">
            <div class="scrollable-card-image">
                <canvas #canvas></canvas>
            </div>
        </div>
      <!-- <div *ngIf="!isLoading">
        <div class="row">
          <div class="col-6">
        <img src='file://13.232.229.219/RCM_Files/RCM_Document/QSecureInboundDocuments/2024/Apr/tenants/4ef17b39-ff21-b34e-779b-3a11f74e44e1/RCM.PlatformManagement.Inbound.Container.InboundDocuments/02f54b74-8987-cb81-46ec-3a121b78248f/page_1.tiff' width="450" height="345">
      </div>
      <div class="col-6">
        <img src='https://www.w3schools.com/html/img_chania.jpg' width="450" height="345">
      </div>
      </div>
      </div> -->


    </div>


    <div class="col-lg-12" *ngIf="isHideTiffViewer" >
      <div  class="pdf-container pb-4" >
        <!-- <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }" *ngIf="isLoading || TiffError" >
          {{ loadingMessage }}
        </h1> -->
        <ng-container>
          <ngx-extended-pdf-viewer
            [base64Src]="strPdfString"
            [height]="pdfheight"
            [showSidebarButton]="false"
            [textLayer]="true"
            [showPagingButtons]="true"
            [showOpenFileButton]="false"
            [showHandToolButton]="false"
            [showScrollingButton]="false"
            [showSpreadButton]="false"
            [showPropertiesButton]="false"
            [showBookmarkButton]="false"
            [zoom]="'page-width'"
            [enableDragAndDrop]="false"
            [showFindButton]="false"
          >
          </ngx-extended-pdf-viewer>
        </ng-container>
      </div>

  </div>


</div>
