import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { dateFormatter } from 'projects/patient/src/app/user-task-list/user-task-list.component';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { AddCoverageAreaComponent } from '../add-coverage-area/add-coverage-area.component';
import { CoverageAreaService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/coverage-area.service';
import { CoverageAreaDTO, CreateUpdateCoverageAreaDTO, SearchCoverageAreaDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ontime-coverage-area',
  templateUrl: './ontime-coverage-area.component.html',
  styleUrls: ['./ontime-coverage-area.component.scss'],
  providers:[DatePipe]
})
export class OntimeCoverageAreaComponent implements OnInit {

  isLoading: boolean;
  step: boolean = false;
  BranchForm: FormGroup;
  coverageAreaSearchForm: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  chosenYearDate: Date;
  coverageAreaTableData
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  status: string = '';
  desigation: string;
  displayedColumns: string[] = ['Options','Name', 'Square Mile', 'User Details', 'Create Date', 'Status', ];


  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private coverageAreaService: CoverageAreaService,
    private datepipe: DatePipe,
    private dateValidator: DateValidator,
    private toastr: ToastrService,
    public title: Title,

  ) { }

  ngOnInit(): void {

    this.title.setTitle("Qsecure | Notes");
    this.coverageAreaSearchForm = this.formBuilder.group({
      name: new FormControl(""),
      squareMile: new FormControl(""),
      userDetails: new FormControl(""),
      createDate: new FormControl("", this.dateValidator.dateVaidator),
      status: new FormControl(""),
    })
    this.getListtable();

    // Get Table Search formcontrol values
    this.coverageAreaSearchForm.valueChanges.subscribe((value: CoverageArea) => {

      const filter: SearchCoverageAreaDTO = {
        coverageAreaName: value?.name != null || undefined ? value?.name.trim().toLowerCase() : value?.name,
        squareMile: value?.squareMile != null || undefined ? value?.squareMile.trim().toLowerCase() : value?.squareMile,
        userDetails: value?.userDetails != null || undefined ? value?.userDetails.trim().toLowerCase() : value?.userDetails,
        status: value?.status != null || undefined ? value?.status.trim().toLowerCase() : value?.status,
        createdDate: value?.createDate
      };
      if (this.coverageAreaSearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.searchCoverageArea(filter);
      }
    });
  }

  createNewCoverageAreaInPopUp() {
    this.dialog.open(AddCoverageAreaComponent, { maxHeight: "80%", maxWidth: "80%", disableClose: true, data: { value:  ""  } })
    this.dialog.afterAllClosed.subscribe(resposne => {
      this.getListtable()
    })
  }


  viewCoverageAreaInPopUp(data: any) {
    this.dialog.open(AddCoverageAreaComponent, { maxHeight: "80%", maxWidth: "80%", disableClose: true, data: { value: data } })
    this.dialog.afterAllClosed.subscribe(resposne => {
      this.getListtable()
    })
  }

  getListtable() {
    let coverageAreaList: CoverageAreaDTO[] = [];
    this.dataSource = new MatTableDataSource(coverageAreaList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {

      coverageAreaList = response?.items ?? [];
      const newCoverageAreaList: any[] = [];
      coverageAreaList.forEach((e,tableIndex) => {
        newCoverageAreaList.push({
          id: e?.id,
          sno: tableIndex + 1,
          creationTime: e?.creationTime === null ? "" : this.datepipe.transform(e?.creationTime, 'MM/dd/yyyy') ?? "",
          // dateFormatter(e.creationTime ?? "") ?? "",
          coverageAreaName: e?.coverageAreaName,
          squareMile: e?.squareMile,
          userDetails: e?.userDetails,
          status: e?.status,
          coverageAreaLocation: e?.coverageAreaLocation
        });
      })
      this.dataSource = new MatTableDataSource(newCoverageAreaList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
  }

  // Search Coverage Area
  searchCoverageArea(filter){
    this.coverageAreaTableData = [];
    this.coverageAreaService.searchCoverageAreaByInput(filter).subscribe(response => {
      response?.items.forEach((element,tableIndex) => {
        this.coverageAreaTableData.push({
          id: element?.id,
          sno: tableIndex + 1,
          creationTime: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
          coverageAreaName: element?.coverageAreaName,
          squareMile: element?.squareMile,
          userDetails: element?.userDetails,
          status: element?.status,
          coverageAreaLocation: element?.coverageAreaLocation
        });
      })
      this.dataSource = new MatTableDataSource(this.coverageAreaTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

  statusChange(event: MatSlideToggleChange, data: any) {

    this.status = event.checked == true ? "Active" : "Inactive";
    if(event.checked == true){
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Coverage Area will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          const input: CreateUpdateCoverageAreaDTO = {
            coverageAreaName: data?.coverageAreaName,
            squareMile: data?.squareMile,
            userDetails: data?.userDetails,
            status: "Active",
            coverageAreaLocation: data?.coverageAreaLocation
          }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.coverageAreaService.update(data?.id, input).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getListtable();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else{
          this.getListtable();
        }
      });
    }
    else
    if(event.checked == false){
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Coverage Area will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!'
      }).then(result => {
        if (result.value) {
          const input: CreateUpdateCoverageAreaDTO = {
            coverageAreaName: data?.coverageAreaName,
            squareMile: data?.squareMile,
            userDetails: data?.userDetails,
            status: "Inactive",
            coverageAreaLocation: data?.coverageAreaLocation
          }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.coverageAreaService.update(data?.id, input).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Deactivateed Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Deactivateed Successfully','Success')
              this.getListtable();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else{
          this.getListtable();
        }
      });
    }
  }
}
export interface CoverageArea{
  name:string;
  squareMile: string;
  userDetails: string;
  createDate: string;
  status: string;
}
