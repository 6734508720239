import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PracticeAddPhysicianComponent } from '../practice-add-physician/practice-add-physician.component';
import { PatientDoctorComponent } from '../patient-doctor/patient-doctor.component';
import { Subscription } from 'rxjs';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import { GetDoctorNpiService } from '../patient-proxy/controllers/get-doctor-npi.service';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import {
  DoctorDTO,
  NPIDoctorDTO,
  NPIDoctorDTOs,
} from '../patient-proxy/patient/dto/models';
import { ToastrService } from 'ngx-toastr';
import { TableService } from 'projects/shared/src/app/table.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { UpdatePracticePhysicianComponent } from '../update-practice-physician/update-practice-physician.component';
import { DataTableDirective } from 'angular-datatables';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-practice-management-physician',
  templateUrl: './practice-management-physician.component.html',
  styleUrls: ['./practice-management-physician.component.scss']
})
export class PracticeManagementPhysicianComponent implements OnInit {
  @Input() sessionPracticeId: any;
  @Output() reloadTableEvent = new EventEmitter();
  practiceId: any;
  isLoading: boolean;
  ischeckPracticeId: boolean;
  dtOptions: any;
  tableData$: Subscription;
  $subscription: any;
  subscription$: Subscription[] = [];
  Physiciandetails: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private dialog: MatDialog,
    private doctorService: DoctorService,
    private npiDoctorService: GetDoctorNpiService,
    private toastr: ToastrService,
    private Table: TableService,
    public dialogRef: MatDialogRef<PatientDoctorComponent>,
    private practiceManagementService: PracticeManagementService,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private datepipe:DatePipe
  ) {
    this.communicationService.physicianFunctionCall$.subscribe((selectedNode) => {
      this.getPracticePhysician();
    });
    this.communicationService.physicianFunctionCallv1$.subscribe((practiceId) => {
      this.getPracticeID(practiceId);
    });
  }

  ngOnInit(): void {
    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      buttons: [
        {
          extend: 'excel',
          text:'Export',
          filename: 'Physician Info',
          exportOptions: {
            columns: ':visible:not(:first-child,:eq(0)) ',
          },
        },
      ]
    };

    this.practiceId = this.sessionPracticeId;
    this.checkpracticeId();
    // if (this.practiceId) {
    //   this.getPracticePhysician();
    //   this.ischeckPracticeId = false;
    // } else {
    //   this.ischeckPracticeId = true;
    // }

    this.dtOptions.buttons[0].filename =
    'Physician Info' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
 //After View Method
 ngAfterViewInit(): void {
  if (this.datatableElement && this.datatableElement.dtInstance) {
    this.refreshDataTable();
  } else {
    setTimeout(() => this.refreshDataTable(), 500);
  }
}


refreshDataTable(): void {
  this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.columns().every(function () {
      const that = this;
      $('input', this.footer()).on('keyup change', function () {
        if (that.search() !== this['value']) {
          that.search(this['value']).draw();
        }
      });
    });
  });
}

onRowDoubleClickV1(textToCopy) {
  this.copyTextToClipboard(textToCopy);
  this.showNotification('Text copied: ' + textToCopy);
}

showNotification(message: string): void {
  this.snackBar.open(message, 'Close', {
    duration: 3000, // Duration in milliseconds
    horizontalPosition: 'end', // Positioning the notification horizontally
    verticalPosition: 'bottom', // Positioning the notification vertically
  });
}

copyTextToClipboard(text: string): void {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
}
  //Add Physician
  addPhysicians() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '32vh',
      height: '80vh',
      minWidth: '35vw',
      maxWidth: '85vw',
      data: {
        physiciantitle: 'Add Physican'
      },
    };
    const dialogRef = this.dialog.open(PracticeAddPhysicianComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => { },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }

  //Open Referral Doctors Search Popup
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.dialog.open(PatientDoctorComponent, {
      disableClose: true,
      minHeight: '85%',
      maxWidth: '60%',
      data: {
        doctorId: doctorId,
        practiceId: this.practiceId
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.doctorDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  doctorId: string = '';
  AddNpiDoctor(data) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    Swal.fire({
      title: 'Are you sure you want to save?',
      text: 'Would You Like To Save the NPI Doctor Details',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Save!',
    }).then((result) => {
      if (result.value) {
        let npiDoctorDatas = data;
        if (npiDoctorDatas.MiddleName == null) {
          npiDoctorDatas.MiddleName = '';
        }
        npiDoctorDatas.MiddleName = npiDoctorDatas?.MiddleName?.replace(
          '.',
          ''
        );
        npiDoctorDatas.FirstName = npiDoctorDatas?.FirstName?.replace('.', '');
        npiDoctorDatas.LastName = npiDoctorDatas?.LastName?.replace('.', '');
        let npiDoctorNumber: string = npiDoctorDatas.TelephoneNumber;
        npiDoctorNumber = npiDoctorNumber.replace('#', '');

        let responeMobile = npiDoctorDatas.TelephoneNumber.replace('-', '');
        responeMobile = responeMobile
          .replace('(', '')
          .replace(')', '')
          .replace('#', '')
          .replace('-', '')
          .split(' ')
          .join('');
        let responeFaxno = npiDoctorDatas.FaxNumber.replace('-', '');
        responeFaxno = responeFaxno
          .replace('(', '')
          .replace(')', '')
          .replace('#', '')
          .replace('-', '')
          .split(' ')
          .join('');
        let responePhone = npiDoctorDatas.TelephoneNumber.replace('-', '');
        responePhone = responePhone
          .replace('(', '')
          .replace(')', '')
          .replace('#', '')
          .replace('-', '')
          .split(' ')
          .join('');
        let title: string = npiDoctorDatas.Prefix;

        let doctor: DoctorDTO = {
          id: this.doctorId,
          title: title?.trim(),
          lastName: npiDoctorDatas.LastName,
          firstName: npiDoctorDatas.FirstName,
          middleName: npiDoctorDatas.MiddleName,
          suffix: '',
          doctorGroup: '',
          facilityId: '',
          npiNumber: npiDoctorDatas.NpiNumber,
          marketingRep: '',
          address: npiDoctorDatas.Address1,
          city: npiDoctorDatas.City,
          state: npiDoctorDatas.State,
          country: npiDoctorDatas.Country,
          postalCode: npiDoctorDatas.PostalCode,
          isPCOS: 0,
          phoneNo: responePhone,
          faxNo: responeFaxno,
          mobileNo: responeMobile,
          emailId: '',
          faxAttention: '',
          medicalLicenseNo: npiDoctorDatas.MedicalLicenseNo,
          expiration: '',
          deaNumber: '',
          upin: '',
          stateMedicalId: '',
          doctorNotes: '',
          userDeliveredUser1: '',
          userDeliveredUser2: '',
          excludeFromElectronicFax: 0,
          taxonomyCode: '',
          organizationUnitId: null,
          isMedicare: true,
        };
        this.doctorService.create(doctor).subscribe(
          (response) => {
            if (response) {
              // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
              this.toastr.success('Saved Successfully', 'Success');
              this.Table.setDoctorTable(response);
              this.dialogRef.close();
              // this.SearchNpiDoctorsAbp();
            } else {
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    });
  }

  //GET getPracticePhysician List In Table
  getPracticePhysician() {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getFaxNumberbleData = this.practiceManagementService
      .practiceDocGetList(this.practiceId)
      .subscribe(
        (response) => {
          this.Physiciandetails = response;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.Physiciandetails = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getFaxNumberbleData;
  }

  updatephysicianId(physid: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        physicianid: physid,
      },
    };
    const dialogRef = this.dialog.open(UpdatePracticePhysicianComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getPracticePhysician();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }

  openDoctorModal1() {
    this.reloadTableEvent.emit();

  }
  getPracticeID(practiceEditId: string) {
      this.practiceId = practiceEditId;
      this.checkpracticeId();
  }

  checkpracticeId(){
    if (this.practiceId) {
      this.getPracticePhysician();
      this.ischeckPracticeId = false;
    } else {
      this.ischeckPracticeId = true;
    }
  }
}
