<!-- Title -->
<div class="row">
  <div class="col-lg-12">
    <div
      class="d-flex justify-content-between align-items-center justify-content-center"
    >
      <h2 class="customThemeClass fw-bolder mb-0">
        Order History - ({{ data.mmOrderId }})
      </h2>
      <button mat-icon-button>
        <mat-icon mat-icon-button mat-dialog-close>close</mat-icon>
      </button>
    </div>
    <mat-divider class="mb-2"></mat-divider>
  </div>
</div>

<div class="mt-4">
  <div class="text-center">
    <mat-label mat-button class="buttonColor p-2 header-label fw-bolder"
      >{{ data.patientName }} - {{ data.chartId }}</mat-label
    >
  </div>
  <div
    class="m-3 d-flex align-items-center justify-content-center"
    *ngIf="orderHistory.length == 0"
  >
    <h2 class="m-3 text-center">No Data Available</h2>
  </div>
  <div *ngIf="orderHistory.length > 0" class="timeline mx-0 mt-2">
    <div *ngFor="let data of orderHistory; let i = index">
      <div
        class="container mx-0"
        [ngClass]="{ left: i % 2 !== 0, right: i % 2 == 0 }"
      >
        <div class="content">
          <p
            class="mb-0 header-text"
            [ngClass]="{ 'd-flex flex-row-reverse': i % 2 !== 0 }"
          >
            {{ data.orderStatusName }}
          </p>
          <p
            class="mb-0 small-text"
            [ngClass]="{ 'd-flex flex-row-reverse': i % 2 !== 0 }"
          >
            {{ data.updatedBy }} - {{ data.createdDateNew }}
            <!-- Lee Cooper - 06/06/2024 11:32 PM -->
          </p>
          <!-- <h2> Lee Cooper - 06/06/2024 11:32 PM </h2> -->
        </div>
      </div>
    </div>
  </div>
</div>
