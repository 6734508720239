// Created By Manikandan - 13 April 2024
// Last Modify By Manikandan - 24 April 2024 14.34
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ClaimUploadEOBComponent } from './upload-eob/upload-eob.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { Moment } from 'moment';
import moment from 'moment';
import { ItemPaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { ClaimStatusService } from '../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-status.service';
import { map, startWith } from 'rxjs/operators';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-auto-posting-era',
  templateUrl: './auto-posting-era.component.html',
  styleUrls: ['./auto-posting-era.component.scss'],
})
export class AutoPostingERAComponent implements OnInit {
  autoPostingERAForm: FormGroup;
  isLoading: boolean = false;
  DataList: any;
  filteredStatusList: any;
  iscolorCode : boolean = false;
  lstStatus: any;
  fromDate: any;
  toDate: any;
  ranges: any;
  calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  calendarPlaceholder: string = '';
  filterValue: boolean;
  sTicketId: any = '';
  private loadClaimTableDataAPICall: Subscription;
  txtStatusControl =new FormControl();
  selectedRange: any = { startDate: null, endDate: null };
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private title: Title,
    public matDialog: MatDialog,
    private itemPaymentService: ItemPaymentService,
    private claimStatusService: ClaimStatusService,
    
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
  }

  ngOnInit(): void {
    this.title.setTitle('QSecure | Auto Posting (ERA)');
    // this.fromDate = new Date().toLocaleDateString();
    // this.toDate = new Date().toISOString();
    // this.initializeForms();
    this.initializeForms();

    this.fromDate = this.convertISODateStringToCustomFormat(
      this. getCTStartOfMonth()
     )
       this.toDate = this.convertISODateStringToCustomFormat(
         this.ctDateNow()
       );
       this.autoPostingERAForm.patchValue({
        txtDateValue: moment(this.getCTStartOfMonth()) +'-'+ moment( this.ctDateNow())
      });
       this.selectedRange.startDate=this. getCTStartOfMonth(),
       this.selectedRange.endDate= this.ctDateNow()
   
   
       this.convertISODateStringToCustomFormat(
         this. getCTStartOfMonth()
        ) +'-'+ this.convertISODateStringToCustomFormat(
         this.ctDateNow()
       );
   
   
    this.loadAutoPostingList()

    this.loadStatusList()
     //Status search
     this.txtStatusControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.filteredStatusList = this.lstStatus.filter((dx) =>
        dx.claimStatus.toLowerCase().includes(searchTerm)
      );
    });
    
  }

  //To initialize the Forms
  initializeForms() {
    this.autoPostingERAForm = this.fb.group({
      // uploadEOBFile: new FormControl(null, [Validators.required]),
      drpStatus: new FormControl(defaultGuid),
      txtStatusFilter: new FormControl(''),
      txtDateValue: new FormControl(''),
      dtDOSTo: new FormControl(''),
      txtTicketNo: new FormControl('')
    });
  }

  loadAutoPostingList(){
    this.isLoading = true
    if (this.loadClaimTableDataAPICall) {
      this.loadClaimTableDataAPICall.unsubscribe();
    }
    this.loadClaimTableDataAPICall = this.itemPaymentService.getAutoPostedEobDetailsByDtFromDateAndDtToDateAndGClaimStatusAndSCheckNumber(this.fromDate,this.toDate,this.autoPostingERAForm.value.drpStatus,this.autoPostingERAForm.value.txtTicketNo).subscribe(response =>{
      this.DataList = response.items
      this.isLoading = false
    },(err) => {
      this.isLoading = false
    })
  }

  sanitizeInput(event: any, controlName: string) {
    const inputValue = event.target.value;
    const newValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters

    // Check if the new value matches the desired pattern
    const isValidInput = /^(?!0)\d{1,10}$/.test(newValue);

    if (!isValidInput) {
      this.autoPostingERAForm.get(controlName)?.setValue(newValue.slice(0, -1)); // Revert to the previous valid value
    } else {
      this.autoPostingERAForm.get(controlName)?.setValue(newValue); // Update the form value
    }
  }

  handleInputChange(event: any) {
    const inputValue = event.target.value;
    this.fromDate = null
    this.toDate = null
    if (this.sTicketId !== inputValue) {
      this.sTicketId = inputValue;
      this.loadAutoPostingList();
    }

    
  }

  loadStatusList(){
    this.claimStatusService.getdrpclaimStaus().subscribe(response => {
      this.lstStatus = response || [];
          const allstatusOption = {
            id: '00000000-0000-0000-0000-000000000000',
            claimStatus: 'All',
          };
          this.filteredStatusList = [allstatusOption, ...this.lstStatus];
      
    })
  }
  onDateChangeTo(event: MatDatepickerInputEvent<Date>): void {
  }

  onStatusChange(){
    this.isLoading = true
    this.itemPaymentService.getAutoPostedEobDetailsByDtFromDateAndDtToDateAndGClaimStatusAndSCheckNumber(this.fromDate,this.toDate,this.autoPostingERAForm.value.drpStatus,this.autoPostingERAForm.value.txtTicketNo).subscribe(response =>{
      this.DataList = response.items
      this.isLoading = false
    },(err)=>{
      this.isLoading = false
    })
  }

  datesUpdateds(e){
    if(e.startDate !=null || e.endDate !=null){
      this.dateRangStatus = true
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate,"MM/dd/yyyy")
      this.toDate = this.datepipe.transform(endDate,"MM/dd/yyyy")
      this.loadAutoPostingList()
    }else{
      this.fromDate= null;
      this.toDate=null;
    }
  }
  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    return dateObj;
  }
  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    //    const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;

    return dateObj;
  }

  convertISODateStringToCustomFormat(isoDateString: any): string {
    const inputDate = new Date(isoDateString);

    // Extracting date components
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();

    // Formatted date string in MM/DD/YYYY format
    const formattedDateString = `${month}/${day}/${year}`;

    return formattedDateString;
  }
  // onDateRangeChange() {
  //   // const startDate = this.autoPostingERAForm.get('txtDateValue').value;
  //   // const endDate = this.autoPostingERAForm.get('txtDate').value;
  //   // this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
  //   // this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
  //   // if (startDate != null && endDate != null) {
  //   // }
  // }
  openEOBModel(){
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
       // selectedNode: this.selectedNode ?? null,
      }
    };
    const dialogRef = this.matDialog.open(ClaimUploadEOBComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaveEob: any) => {
        if (isSaveEob) {
        this.loadAutoPostingList();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
  
  filterChange(event: boolean) {
    //Patient - True
    //Others - False
    this.filterValue = event;
    this.filterValue === true &&
      this.autoPostingERAForm.patchValue({
        // drpPatient: '',
      });
  }

  OpenColorDiv(){
    this.iscolorCode = !this.iscolorCode
  }
}
const DataList = [
  {
    ChartId: '5648451365HG',
    PatientName: 'Daryl Marshell',
    ACNT: '-',
    ICN: '192959232215',
    Rank: 'Secondary',
    childData: [
      {
        Code: 236598,
        CPT: 'E0254',
        DOS: '03/08/2024',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 403.32,
        Allowed: 134.36,
        Deduct: 0.00,
        Copay: 0.25,
        coins: 0.26,
        Paid: 25.36,
        WrtOff: 148.32,
        Claim: 'No Action'
      },
      {
        Code: 236598,
        CPT: 'E0255',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 45.32,
        Allowed: 44.36,
        Deduct: 0.00,
        Copay: 0.56,
        coins: 0.86,
        Paid: 45.36,
        WrtOff: 0.32,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary & Forwarded',
    Billed: '403.32',
    Paid: '250.33',
    Pending: '152.99',
    Skip: false,
    Ok: true,
    notes: ['Test Notes Test NotesTest NotesTest NotesTest NotesTest NotesTest Notes', 'Test Notes Test NotesTest NotesTest NotesTest NotesTest NotesTest Notes']
  },
  {
    ChartId: '5948456989JH',
    PatientName: 'Nathan Astle',
    ACNT: '-',
    ICN: '192959232215',
    Rank: 'Secondary',
    childData: [
      {
        Code: 236563,
        CPT: 'E0234',
        DOS: '03/08/2014',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 44.32,
        Allowed: 124.36,
        Deduct: 0.00,
        Copay: 0.25,
        coins: 0.26,
        Paid: 25.36,
        WrtOff: 146.32,
        Claim: 'No Action'
      },
      {
        Code: 123448,
        CPT: 'E0987',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 45.32,
        Allowed: 44.36,
        Deduct: 0.00,
        Copay: 0.56,
        coins: 0.86,
        Paid: 45.36,
        WrtOff: 0.32,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary & Secondary Forwarded',
    Billed: '111.32',
    Paid: '333.33',
    Pending: '444.99',
    Skip: true,
    Ok: false,
    notes: ['Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes', 'Test Notes']
  },
  {
    ChartId: '1234567890AB',
    PatientName: 'John Doe',
    ACNT: '-',
    ICN: '9876543210ZY',
    Rank: 'Primary',
    childData: [
      {
        Code: 123456,
        CPT: 'E0123',
        DOS: '03/08/2022',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 200.00,
        Allowed: 150.00,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 150.00,
        WrtOff: 50.00,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '200.00',
    Paid: '150.00',
    Pending: '50.00',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '0987654321CD',
    PatientName: 'Jane Smith',
    ACNT: '-',
    ICN: '4567890123WX',
    Rank: 'Secondary',
    childData: [
      {
        Code: 987654,
        CPT: 'E0456',
        DOS: '03/08/2022',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 300.00,
        Allowed: 200.00,
        Deduct: 0.00,
        Copay: 0.50,
        coins: 0.75,
        Paid: 199.75,
        WrtOff: 100.25,
        Claim: 'No Action'
      }
    ],
    Status: 'Secondary',
    Billed: '300.00',
    Paid: '199.75',
    Pending: '100.25',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '7777777777ZZ',
    PatientName: 'Emily Johnson',
    ACNT: '-',
    ICN: '8888888888XY',
    Rank: 'Primary',
    childData: [
      {
        Code: 777777,
        CPT: 'E0777',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 777.77,
        Allowed: 555.55,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 555.55,
        WrtOff: 222.22,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '777.77',
    Paid: '555.55',
    Pending: '222.22',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '5555555555XY',
    PatientName: 'Michael Brown',
    ACNT: '-',
    ICN: '6666666666WZ',
    Rank: 'Primary',
    childData: [
      {
        Code: 555555,
        CPT: 'E0555',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 555.55,
        Allowed: 444.44,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 444.44,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '555.55',
    Paid: '444.44',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '9999999999ZW',
    PatientName: 'Sarah Davis',
    ACNT: '-',
    ICN: '1111111111WX',
    Rank: 'Primary',
    childData: [
      {
        Code: 999999,
        CPT: 'E0999',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 999.99,
        Allowed: 888.88,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 888.88,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '999.99',
    Paid: '888.88',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '3333333333WY',
    PatientName: 'David Wilson',
    ACNT: '-',
    ICN: '4444444444ZX',
    Rank: 'Primary',
    childData: [
      {
        Code: 333333,
        CPT: 'E0333',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 333.33,
        Allowed: 222.22,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 222.22,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '333.33',
    Paid: '222.22',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '4444444444ZX',
    PatientName: 'Lisa Miller',
    ACNT: '-',
    ICN: '3333333333WY',
    Rank: 'Primary',
    childData: [
      {
        Code: 444444,
        CPT: 'E0444',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 444.44,
        Allowed: 333.33,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 333.33,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '444.44',
    Paid: '333.33',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '6666666666WZ',
    PatientName: 'Steven Martinez',
    ACNT: '-',
    ICN: '5555555555XY',
    Rank: 'Primary',
    childData: [
      {
        Code: 666666,
        CPT: 'E0666',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 666.66,
        Allowed: 555.55,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 555.55,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '666.66',
    Paid: '555.55',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '8888888888XY',
    PatientName: 'Alexis Thompson',
    ACNT: '-',
    ICN: '7777777777ZZ',
    Rank: 'Primary',
    childData: [
      {
        Code: 888888,
        CPT: 'E0888',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 888.88,
        Allowed: 777.77,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 777.77,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '888.88',
    Paid: '777.77',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '7777777777YZ',
    PatientName: 'Jessica Garcia',
    ACNT: '-',
    ICN: '8888888888XX',
    Rank: 'Primary',
    childData: [
      {
        Code: 777777,
        CPT: 'E0777',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 777.77,
        Allowed: 555.55,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 555.55,
        WrtOff: 222.22,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '777.77',
    Paid: '555.55',
    Pending: '222.22',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '3333333333YW',
    PatientName: 'Daniel Hernandez',
    ACNT: '-',
    ICN: '4444444444XZ',
    Rank: 'Primary',
    childData: [
      {
        Code: 333333,
        CPT: 'E0333',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 333.33,
        Allowed: 222.22,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 222.22,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '333.33',
    Paid: '222.22',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '4444444444XZ',
    PatientName: 'Maria Rodriguez',
    ACNT: '-',
    ICN: '3333333333YW',
    Rank: 'Primary',
    childData: [
      {
        Code: 444444,
        CPT: 'E0444',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 444.44,
        Allowed: 333.33,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 333.33,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '444.44',
    Paid: '333.33',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '6666666666ZW',
    PatientName: 'Olivia Lewis',
    ACNT: '-',
    ICN: '5555555555XX',
    Rank: 'Primary',
    childData: [
      {
        Code: 666666,
        CPT: 'E0666',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 666.66,
        Allowed: 555.55,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 555.55,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '666.66',
    Paid: '555.55',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '8888888888XZ',
    PatientName: 'Andrew Clark',
    ACNT: '-',
    ICN: '7777777777YZ',
    Rank: 'Primary',
    childData: [
      {
        Code: 888888,
        CPT: 'E0888',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 888.88,
        Allowed: 777.77,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 777.77,
        WrtOff: 111.11,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '888.88',
    Paid: '777.77',
    Pending: '111.11',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  },
  {
    ChartId: '7777777777XW',
    PatientName: 'Sophia Martinez',
    ACNT: '-',
    ICN: '8888888888YZ',
    Rank: 'Primary',
    childData: [
      {
        Code: 777777,
        CPT: 'E0777',
        DOS: '03/08/2023',
        M1: 'NL',
        M2: 'KL',
        M3: '-',
        M4: '-',
        Unit: 1,
        Billed: 777.77,
        Allowed: 555.55,
        Deduct: 0.00,
        Copay: 0.00,
        coins: 0.00,
        Paid: 555.55,
        WrtOff: 222.22,
        Claim: 'No Action'
      }
    ],
    Status: 'Primary',
    Billed: '777.77',
    Paid: '555.55',
    Pending: '222.22',
    Skip: false,
    Ok: true,
    notes: ['Sample note']
  }
]