import { Component, Input, OnInit } from '@angular/core';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatRadioChange } from '@angular/material/radio';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { StatusService } from '../../patient-proxy/patient/status.service';
import { CreateUpdateInboundStatusDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dropdowns/dto';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DataTableDirective } from 'angular-datatables';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-wip-status-table',
  templateUrl: './wip-status-table.component.html',
  styleUrls: ['./wip-status-table.component.scss']
})
export class WipStatusTableComponent implements OnInit {

  dtOptionsWipstatus = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      // { targets: [0], orderable: false },
      { targets: [3,4,5], visible: false },
      {
        targets: [2],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Bin',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0),:eq(1),:eq(2), :eq(-1))',
      }
    ],
  };
  @Input() tableWipStatus: any;
  @Input() selectedOption:number;
  wipTableData:any;
  step: boolean = false;
  isFieldDisabled: boolean = false;
  isShowSpinner: boolean = false;
  issaving = false;
  saveButtonHide: boolean;
  mainStatusId: string = defaultGuid;
  emptyguid: string = defaultGuid;
  tenantId: string;
  AppontmentForm: FormGroup;
  inboundStatusDetails: CreateUpdateInboundStatusDTO;
  isActive: any;
  isLoading: boolean=false;
  $subscription: Subscription[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  constructor(
    private formBuilder: FormBuilder,
    private statusService: StatusService,
    private InboundDocumentService: InboundDocumentService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private commonService:CommonService,
  ) { }

  ngOnInit(): void {

    this.AppontmentForm = this.formBuilder.group({
      txtStatusName: new FormControl('', [
        Validators.required, noWhitespaceValidator
      ]),
    });
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.wipTableData=this.tableWipStatus;

    this.dtOptionsWipstatus.buttons[0].filename =
    'Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  //To Save the Inbound status
  saveStatus() {
    this.issaving = true;
    this.isShowSpinner = true;
    this.isLoading = true;
    if (this.mainStatusId !== defaultGuid) {
      this.inboundStatusDetails = {
        inboundStatus: (this.AppontmentForm.get('txtStatusName').value || '').toString().trim(),
        mainStatusId: this.mainStatusId,
        tenantId: this.tenantId,
        isActive: this.isActive
      };
      this.InboundDocumentService
        .updateStatus(this.mainStatusId, this.inboundStatusDetails)
        .subscribe(
          (response) => {
            this.reset();
            this.isFieldDisabled = false;
            // this.GetStatusFilterList();--
            // this.communicationService.triggerLoadWipStatusListv1();
            this.isShowSpinner = false;
            this.step = false;
            const index = this.wipTableData.findIndex(
              (obj) => obj.id === response?.id
            );
            if (index !== -1) {
              this.wipTableData[index] = response; //Replace the Object
              const objectToMove = this.wipTableData.splice(index, 1)[0]; // Remove and get the object

              const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
              response.lastModificationTime = userTimezonelastModificationTime;
              const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
              response.creationTime = userTimezonecreationTime;

              this.wipTableData.unshift(objectToMove);
              // this.wipTableData = this.wipTableData.map((data) => {
              //   return {
              //     ...data,
              //     creationTime: this.commonService.getFormattedDateTimeZone(
              //       data.creationTime
              //     ),
              //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
              //       data.lastModificationTime
              //     ),
              //   };
              // });
            }
            this.isLoading = false;
            this.toastr.success('Bin Updated Successfully', 'Success');
            this.issaving = false;
            // let index = this.wipTableData.findIndex(d => d.id === response.id);
            // this.wipTableData.splice(index,1);
            // this.wipTableData.push(response);
          },
          (err) => {
            this.isLoading = false;
            this.isShowSpinner = false;
            // this.GetStatusFilterList();
            this.communicationService.triggerLoadWipStatusListv1();
            this.step = false;
            this.wipTableData = [];
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
            this.issaving = false;
          }
        );

    } else if (this.mainStatusId === defaultGuid) {
      // this.sortedId = this.AppontmentForm.get('txtSortedId').value
      this.inboundStatusDetails = {
        inboundStatus: (this.AppontmentForm.get('txtStatusName').value || '')
          .toString()
          .trim(),
        tenantId: this.tenantId,
        isActive: 1
      };
      // this.isLoading = true;
      this.InboundDocumentService.createStatus(this.inboundStatusDetails).subscribe(
        (response) => {
          this.reset();
          this.isFieldDisabled = false;

          // this.GetStatusFilterList();
          // this.communicationService.triggerLoadWipStatusListv1();
          this.isShowSpinner = false;

          this.issaving = false;
          if(this.wipTableData.length===0){
            this.communicationService.triggerLoadRoleListv1();
           } else{
            if(this.selectedOption !== 2){
          //  this.wipTableData.push(response);
          const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
          response.lastModificationTime = userTimezonelastModificationTime;
          const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
          response.creationTime = userTimezonecreationTime;

          this.wipTableData.unshift(response);
          // this.wipTableData = this.wipTableData.map((data) => {
          //   return {
          //     ...data,
          //     creationTime: this.commonService.getFormattedDateTimeZone(
          //       data.creationTime
          //     ),
          //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
          //       data.lastModificationTime
          //     ),
          //   };
          // });
            }
           }
           this.toastr.success('Bin Added Successfully', 'Success');
           this.isLoading = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.issaving = false;
        }
      );
    }
  }

  //View Status
  viewStatus(id: string, isdefault: number) {
    this.isFieldDisabled = false;
    // this.AppontmentForm.controls['txtSortedId'].enable();
    if (isdefault == 1) {
      this.isFieldDisabled = true;
    }
    if (this.step == false) {
      this.step = true;
    }
    const colorCodes = this.InboundDocumentService.getById(id).subscribe(
      (response) => {
        this.AppontmentForm.patchValue({
          txtStatusName: response.inboundStatus,
          // txtSortedId: response.iSortOrder,
        });
        this.mainStatusId = response.id ?? '';
        this.isActive = response.isActive ?? '';

      },
      (err) => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(colorCodes);
  }

  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this bin will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          this.isLoading=true;
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.InboundDocumentService.updateEnableDisable(data?.id, 1).subscribe(response => {
              // this.GetStatusFilterList();
              // this.communicationService.triggerLoadWipStatusListv1();
               // this.branchStatusFilter(this.param);
               if(this.selectedOption !==0){
                let index = this.wipTableData.findIndex(d => d.id === data?.id);
                this.wipTableData.splice(index,1);
              }else{
                this.wipTableData.find(item => item.id == data?.id).isActive = 1;
              }
              this.toastr.success('Activated Successfully', 'Success')
              this.isLoading=false;
            }, err => {
              if(this.selectedOption !==0){
                let index = this.wipTableData.findIndex(d => d.id === data?.id);
                this.wipTableData.splice(index,1);
              }else{
                this.wipTableData.find(item => item.id == data?.id).isActive = 1;
              }
              this.toastr.success('Activated Successfully', 'Success')
              this.isLoading=false;
          }
        );
      }
        }
        else {
          // this.GetStatusFilterList();
          // this.communicationService.triggerLoadWipStatusListv1();
          event.source.checked = true;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this bin will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            this.isLoading=true;
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.InboundDocumentService.updateEnableDisable(data?.id, 0).subscribe(response => {
                // this.GetStatusFilterList();
                // this.communicationService.triggerLoadWipStatusListv1();
                if(this.selectedOption !==0){
                  let index = this.wipTableData.findIndex(d => d.id === data?.id);
                  this.wipTableData.splice(index,1);
                }else{
                  this.wipTableData.find(item => item.id == data?.id).isActive = 0;
                }
                this.isLoading=false;
                this.toastr.success('Deactivated Successfully', 'Success')
              }, err => {
                if(this.selectedOption !==0){
                  let index = this.wipTableData.findIndex(d => d.id === data?.id);
                  this.wipTableData.splice(index,1);
                }else{
                  this.wipTableData.find(item => item.id == data?.id).isActive = 0;
                }
                this.isLoading=false;
                this.toastr.success('Deactivated Successfully', 'Success')
             }
           );
         }

          }
          else {
            // this.GetStatusFilterList();
            // this.communicationService.triggerLoadWipStatusListv1();
            event.source.checked = true;
          }
        });
      }
  }

  //Reset Inbound status
  reset() {
    // this.txtStatusName = '';
    this.AppontmentForm.reset();
    // this.AppontmentForm.patchValue({
    //   txtStatusName: '',
    //   // txtSortedId: '',
    // });
    this.mainStatusId = defaultGuid;
    this.isFieldDisabled = false;
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
export function noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && control.value.trim() === '') {
    return { 'whitespace': true };
  }
  return null;
}
