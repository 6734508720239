<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-6">
            <h2 *ngIf="data != null" >Edit Product Allowables</h2>
            <h2 *ngIf="data == null" >Add Product Allowables</h2>
        </div>
    </div>



    <form [formGroup]="allowablesForm">
        <div class="row">
            <div class="col-lg-12">
              <mat-card-content>
                <div class="row">
                  <div class="col-lg-12">
                    <!-- <mat-form-field class="col-6">
                        <mat-label>Product Name <span class="asterisk">*</span></mat-label>
                        <input  formControlName="txtProductName" matInput type="text" />
                        <mat-error
                          *ngIf="allowablesForm?.get('txtProductName')?.touched &&
                          allowablesForm?.get('txtProductName')?.errors?.required">
                          Product Name is a required field!
                        </mat-error>
                      </mat-form-field> -->
                      <div class="row col-lg-12">          
                        <mat-radio-group  aria-label="Select an option" (change)="changeProduct($event)"  formControlName="txtProductType">
                          <mat-radio-button class="col-3 " [checked]="true"   [value]="1">Main Product</mat-radio-button>
                          <mat-radio-button class="col-3 space" [value]="2">Resupply</mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <mat-form-field class="col-6">
                        <mat-label>Product Name<span class="asterisk">*</span> </mat-label>
                        <mat-select  formControlName="txtProductName"
                          [(value)]="product" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtMainProductFilter"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let provider of filterMainProduct | async" [value]="provider.id">
                            {{provider.mainProductName}}
                          </mat-option>
                        </mat-select>

                        <button mat-button (click)="product=undefined;$event.stopPropagation()" *ngIf="product"
                          matSuffix (click)="clearMainProduct()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="
                        allowablesForm?.get('txtProductName')?.touched &&
                        allowablesForm?.get('txtProductName')?.errors?.required
                                        ">
                          Product Name is a required field!
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Insurance Name <span class="asterisk">*</span></mat-label>
                        <mat-select  formControlName="drpInsuranceName" [(value)]="insurance" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtInsuranceFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let insurance of filteredInsuranceTypes | async"
                                [value]="insurance.id">
                                {{insurance.payerName}}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="insurance=undefined;$event.stopPropagation()" *ngIf="insurance"
                            matSuffix (click)="clearInsurance()" mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error
                          *ngIf="allowablesForm?.get('drpInsuranceName')?.touched &&
                          allowablesForm?.get('drpInsuranceName')?.errors?.required">
                          Insurance Name is a required field!
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label> Eligible Quantity <span class="asterisk">*</span></mat-label>
                        <input autocomplete="new-txtEligibleQty" matInput maxlength="4" formControlName="txtEligibleQty" type="text" (keypress)="numberOnly($event)"/>
                        <mat-error
                        *ngIf="allowablesForm?.get('txtEligibleQty')?.touched &&
                        allowablesForm?.get('txtEligibleQty')?.errors?.required">
                        Eligible Units is a required field!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-6">
                        <mat-label> Eligible Months <span class="asterisk">*</span></mat-label>
                        <input autocomplete="new-txtEligibleQty" matInput maxlength="2" formControlName="txtEligibleMonths" type="text" (keypress)="numberOnly($event)"/>
                        <mat-error *ngIf="allowablesForm?.get('txtEligibleMonths')?.touched &&
                        allowablesForm?.get('txtEligibleMonths')?.errors?.required">
                        Eligible Months is a required field!
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label> Allowable Amount <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtEligibleQty" matInput (keypress)="keyPressNumbersDecimal($event)" maxlength="15" formControlName="txtAllowableAmount" type="text" />
                      <mat-error *ngIf="allowablesForm?.get('txtAllowableAmount')?.touched &&
                      allowablesForm?.get('txtAllowableAmount')?.errors?.required">
                      Allowable Amount is a required field!
                      </mat-error>
                  </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button [disabled]="!(!allowablesForm.invalid && allowablesForm.dirty )"
    mat-button class="buttonColor" (click)='saveAllowables()'>Save
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
