import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from '@ngxs/store';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { getOrder, updateOrder } from 'projects/order/src/app/order-store/order.action';
import { ChartDTO, OrderMasterDropdownDTO, PayablePercentDTO, PreparedByDTO, PriceCodeDTO, TypeOfPlanDTO, VerifiedByDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PolicyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

// import { ChartDTO} from '@proxy/rcm/order-management/dropdown/dto';


@Component({
  selector: 'app-shipping-verification',
  templateUrl: './shipping-verification.component.html',
  styleUrls: ['./shipping-verification.component.scss']
})

export class ShippingVerificationComponent implements OnInit , OnDestroy{
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  @Output() saveInsuranceVerificationFormState: EventEmitter<any> = new EventEmitter<any>();
  sDefaultPatientId: string;
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpVerified: VerifiedByDTO[] = [];
  drpPrepared: PreparedByDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  shippingVerificationForm: FormGroup;
  @Input() patientId: string = '';
  // drpPolices: drpPolicesDTO[] = [];
  drpPriceCode: PriceCodeDTO[] = [];
  drpPolicies: PolicyDTO[] = [];
  @Input() strPageTitleType: string = '';
  drpPlanType: any[];
  drpYearType: any[];
  @Input() orderId: string = '';
  // verifyId:string = '';
  @Input() public strPageType: string = "verification";
  tblNotesReponse: any[] = [];
  payorLevel: any[]
  @Input() public strPageType1: string = "items";
  @Input() public isTableShow: boolean = true;
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  verifyTableData: any[] = []
  data: MatRadioChange;
  plan: string;
  type: string;
  typeOfPlan: string;
  payableIndividual: string;
  payableOutOfNetwork: string;
  payableFamily: string;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];

  constructor(
    private store: Store, private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private textValidators: TextValidators,
    private Table: TableService,
    private numberValidators: NumberValidators,
    private orderService: SaleorderService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.shippingVerificationForm = this.formBuilder.group({
      txtName: new FormControl("", [this.textValidators.isTextCheck]),
      //   txtAddress: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateOfBirth: new FormControl("", [Validators.required]),
      txtCommentsNotes: new FormControl(""),
      txtPlan: new FormControl(null, [Validators.required]),
      txtPlanName: new FormControl("", [Validators.required]),
      txtPlanPhone: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl("", [Validators.required]),
      txtGroup: new FormControl(""),
      txtEffectiveDate: new FormControl("", [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(""),
      txtEnd: new FormControl(""),
      chkMedicare: new FormControl(""),
      chkPatient: new FormControl(""),
      chkFamily: new FormControl(""),
      txtCmnCode: new FormControl("", [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(""),
      chkOutOfNetwork: new FormControl(""),
      chkFamilyDeductible: new FormControl(""),
      txtDeductibleIndividual: new FormControl(""),
      txtDeductibleOutOfNetwork: new FormControl(""),
      txtDeductibleFamily: new FormControl(""),
      txtDeductibleMetIndividual: new FormControl(""),
      txtDeductibleMetOutOfNetwork: new FormControl(""),
      txtDeductibleMetFamily: new FormControl(""),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(""),
      txtExpenseOutOfNetwork: new FormControl(""),
      txtExpenseFamily: new FormControl(""),
      txtExpenseMetIndividual: new FormControl(""),
      txtExpenseMetOutOfNetwork: new FormControl(""),
      txtExpenseMetFamily: new FormControl(""),
      chkExpenseIndividual: new FormControl(""),
      chkExpenseOutOfNetwork: new FormControl(""),
      chkExpenseFamily: new FormControl(""),
      chkPrecertRequired: new FormControl(""),
      txtPhoneCall: new FormControl("", [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(""),
      txtPcp: new FormControl("", [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(""),
      txtPreNotes: new FormControl(""),
      txtLifetimeMax: new FormControl(""),
      txtLifetimeMaxMet: new FormControl(""),
      chkDmePartsCovered: new FormControl(""),
      chkDmeReplacementUnit: new FormControl(""),
      txtDmeAnnualLimit: new FormControl(""),
      txtDmeLimitMet: new FormControl(""),
      txtRequestedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl("", [this.textValidators.isTextCheck]),
      txtContact: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(""),
      txtPolicyHolderName: new FormControl("", [this.textValidators.isTextCheck]),
      txtPolicyHolderDob: new FormControl(""),
      chkNetwork: new FormControl(""),
      txtClaimAddress: new FormControl(""),
      txtPlanCob: new FormControl(""),
      txtDedTotal: new FormControl(""),
      txtDedMet: new FormControl(""),
      txtOopTotal: new FormControl(""),
      txtOopMet: new FormControl(""),
      txtOopDeductible: new FormControl(""),
      txtDmeCoverage: new FormControl(""),
      txtDmeMax: new FormControl(""),
      txtHcpcs: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtState: new FormControl("", [this.textValidators.isTextCheck]),
      txtCoverageCriteria: new FormControl("", [this.textValidators.isTextCheck]),
      txtQtyLimits: new FormControl("", [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl("", [this.textValidators.isTextCheck]),
      txtRent: new FormControl("", [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl("", [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl("", [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl("", [this.textValidators.isTextCheck]),
      txtSpokeWith: new FormControl("", [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateVerified: new FormControl(""),
      drpPreparedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPreparedOn: new FormControl(""),
      rbtnVerificationStatus: new FormControl("", [Validators.required]),
      txtDeo: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtCoIns: new FormControl(""),
      rbtnPolicyType: new FormControl(""),
      rbtnInsuranceVerify: new FormControl("", [Validators.required]),
      txtcommentsToTech: new FormControl(""),
      txtReason: new FormControl(""),
      chkActive: new FormControl(""),



      // rbtnPending:new FormControl(""),
      // rbtnUnableToVerify:new FormControl(""),
      // newly added
      chkIsBlueCardEligible: new FormControl(""),
      chkIsDirectBill: new FormControl(""),
      chkIsPreAuthNeeded: new FormControl(""),
      chkIsClaimSubmit: new FormControl(""),
      chkIsReferenceNumberAvailable: new FormControl(""),
      chkClaimForHCPC: new FormControl(""),
      txtPatientResposponsiblePaymentPercentage: new FormControl(""),
      txtReferenceNumber: new FormControl(""),
    });
    this.getDropdown();
    // this.findInvalidControls();
    this.getInsuranceVerifyByOrderId();
    // this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.checkCreateEditAndGetPatientData();

    if (this.shippingVerificationForm.invalid || this.shippingVerificationForm.dirty) {
      this.saveInsuranceVerificationFormState.emit(this.shippingVerificationForm);
    }
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // clear dropdown
  clearPlan() {
    this.plan = '';
    this.shippingVerificationForm.patchValue({ txtPlan: '' });
  }
  clearType() {
    this.type = '';
    this.shippingVerificationForm.patchValue({ txtType: '' });
  }

  clearTypeOfPlan() {
    this.typeOfPlan = '';
    this.shippingVerificationForm.patchValue({ drpTypeOfPlan: '' });
  }

  clearPayableIndividual() {
    this.payableIndividual = '';
    this.shippingVerificationForm.patchValue({ drpPayableIndividual: '' });
  }
  clearPayableOutOfNetwork() {
    this.payableOutOfNetwork = '';
    this.shippingVerificationForm.patchValue({ drpPayableOutOfNetwork: '' });
  }
  clearPayableFamily() {
    this.payableFamily = '';
    this.shippingVerificationForm.patchValue({ drpPayableFamily: '' });
  }

  // checkCreateEditAndGetPatientData() {
  //   (this.patientId == null || this.patientId == undefined || this.patientId == "") && this.activatedRoute.params.pipe(filter(value => { return value && value.patientId !== "" && value.patientId !== undefined && value.patientId !== null }),
  //     switchMap(value => {
  //       return this.store.dispatch(new getPatient(this.patientId));
  //     })).subscribe(response => {

  //       let patientdata: PatientDTO = response?.patientState.selectedPatient;
  //       this.patientId = PatientData?.id;
  //       this.getDropdowns();
  //       this.patchDummyData(response?.patientState.selectedPatient);
  //     });

  //   (this.orderId == null || this.orderId == undefined || this.orderId == "") && this.activatedRoute.params.pipe(filter(value => { return value && value.orderId !== "" && value.orderId !== undefined && value.orderId !== null }),
  //     switchMap(value => {
  //       this.orderId = value.orderId;
  //       return this.store.dispatch(new getOrder(this.orderId));
  //     })).subscribe(response => {
  //       let patientdata: PatientDTO = response?.patientState.selectedPatient;
  //       this.patientId = PatientData?.id;
  //       this.getDropdowns();
  //       this.patchDummyData(response?.patientState.selectedPatient);

  //     });
  // }

  patchDummyData(orderDetail: any) {
    this.drpPolicies = orderDetail.policies.map(value => {
      const data: drpPolicesDTO = {
        insuranceType: value.insuranceType,
        insuranceId: value.insuranceId,
        birthdate: value.birthdate,
        policyNo: value.policyNo,
        startDate: value.startDate,
        endDate: value.endDate,
        groupName: value.groupName,
        groupNo: value.groupNo,
        fisrtName: value.fisrtName,
        address1: value.address1
      }
      return data;
    })
  }
  valueChangeLoadDropdown(_$event) {
    this.shippingVerificationForm.patchValue({
      txtName: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.fisrtName,
      txtAddress: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.address1,
      txtDateOfBirth: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.birthdate,
      // txtPlan: this.drpPolices.filter(value => { return value && value?.insuranceType })[0]?.insuranceType,
      txtPlanName: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.policyNo,
      txtPolicy: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.policyNo,
      txtGroup: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.groupName,
      txtEffectiveDate: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.startDate,
      txtEnd: this.drpPolicies.filter(value => { return value && value?.insuranceType })[0]?.endDate,
    })
  }
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.VerifiedBies,
      OrderDropdowns.PreparedBies,
      OrderDropdowns.PayablePercents,
      OrderDropdowns.PriceCodes,
      OrderDropdowns.PlanTypes,
      OrderDropdowns.YearTypes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      const response: OrderMasterDropdownDTO = stateResponse;
      this.drpChart = response?.charts;
      this.drpPlan = response?.typeOfPlans;
      this.drpVerified = response?.verifiedBies;
      this.drpPrepared = response?.preparedBies;
      this.drpPayable = response?.payablePercents;
      this.drpPriceCode = response?.priceCodes;
      this.drpPlanType = response?.planTypes;
      this.drpYearType = response?.yearTypes;
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$.push(orderDropdown);
    //
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PayorLevels
    ]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response?.payorLevels;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientDropdown)
  }


  getPatientDetailsForSave(verifyStatus) {

    let personalDetails: SaleorderDTO;
    const orderGet = this.orderService.get(this.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      personalDetails = response;
      const PatientData = response.patients;
      this.verifyTableData = [];
      if (personalDetails.patients.insuranceVerifications) {
        const savedData = {
          primaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.primaryInsuranceVerifies,
          secondaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.secondaryInsuranceVerifies,
          tertiaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.tertiaryInsuranceVerifies
        }
        return this.saveInsuranceVerify(personalDetails, savedData, PatientData, verifyStatus);

      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(orderGet);
  }

  insuranceVerificationId: string = "00000000-0000-0000-0000-000000000000";
  saveInsuranceVerify(response, savedData, PatientData, verifyStatus) {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    const payorLevelName: string[] = this.payorLevel.map(v => v.payorLevelName);
    if (this.shippingVerificationForm.value.txtPlan === payorLevelName[0]) {
      savedData.primaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.shippingVerificationForm.value?.txtName,
        name: this.shippingVerificationForm.value?.txtName,
        address: this.shippingVerificationForm.value?.txtName,
        dateofBirth: this.shippingVerificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.shippingVerificationForm.value?.txtCommentsNotes,
        plan: this.shippingVerificationForm.value?.txtPlan,
        planName: this.shippingVerificationForm.value?.txtPlanName,
        planPhone: this.shippingVerificationForm.value?.txtPlanPhone,
        policy: this.shippingVerificationForm.value?.txtPolicy,
        groupNo: this.shippingVerificationForm.value?.txtGroup,
        effectiveDate: this.shippingVerificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.shippingVerificationForm.value?.txtType,
        fascalYearStart: this.shippingVerificationForm.value?.txtFascalYearStart,
        end: this.shippingVerificationForm.value?.txtEnd,
        isMedicareCmn: +this.shippingVerificationForm.value?.chkMedicare,
        isPatientHome: +this.shippingVerificationForm.value?.chkPatient,
        isFamilyDeductible: +this.shippingVerificationForm.value?.chkFamilyDeductible,
        cmnCode: this.shippingVerificationForm.value?.txtCmnCode,
        typeOfPlan: this.shippingVerificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.shippingVerificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.shippingVerificationForm.value?.chkOutOfNetwork,
        isFamily: +this.shippingVerificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.shippingVerificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.shippingVerificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.shippingVerificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.drpPayableOutOfNetwork,
          family: this.shippingVerificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.shippingVerificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.shippingVerificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.shippingVerificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.shippingVerificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.shippingVerificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.shippingVerificationForm.value?.chkPrecertRequired,
        phone: this.shippingVerificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.shippingVerificationForm.value?.chkReferralRequired,
        pcp: this.shippingVerificationForm.value?.txtPcp,
        isPredetermination: +(this.shippingVerificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkPredetermination)),
        preNotes: this.shippingVerificationForm.value?.txtPreNotes,
        lifeTimeMax: this.shippingVerificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.shippingVerificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.shippingVerificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.shippingVerificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.shippingVerificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.shippingVerificationForm.value?.txtDmeLimitMet,
        requestedBy: this.shippingVerificationForm.value?.txtRequestedBy,
        primaryPayor: this.shippingVerificationForm.value?.txtPrimaryPayor,
        contact: this.shippingVerificationForm.value?.txtContact,
        callTrackingNumber: this.shippingVerificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.shippingVerificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.shippingVerificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.shippingVerificationForm.value?.chkNetwork,
        claimAddress: this.shippingVerificationForm.value?.txtClaimAddress,
        planCob: this.shippingVerificationForm.value?.txtPlanCob,
        dedTotal: this.shippingVerificationForm.value?.txtDedTotal,
        dedMet: this.shippingVerificationForm.value?.txtDedMet,
        oopTotal: this.shippingVerificationForm.value?.txtOopTotal,
        oopMet: this.shippingVerificationForm.value?.txtOopMet,
        oop: this.shippingVerificationForm.value?.txtOopDeductible,
        dmeCoverage: this.shippingVerificationForm.value?.txtDmeCoverage,
        dmeMax: this.shippingVerificationForm.value?.txtDmeMax,
        hcpcs: this.shippingVerificationForm.value?.txtHcpcs,
        coverageCriteria: this.shippingVerificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.shippingVerificationForm.value?.txtQtyLimits,
        frequency: this.shippingVerificationForm.value?.txtFrequency,
        rent: this.shippingVerificationForm.value?.txtRent,
        paRequired: this.shippingVerificationForm.value?.txtPaRequired,
        codesRequirePa: this.shippingVerificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.shippingVerificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.shippingVerificationForm.value?.txtSpokeWith,
        verifiedBy: this.shippingVerificationForm.value?.drpVerifiedBy,
        dateVerified: this.shippingVerificationForm.value?.txtDateVerified,
        preparedBy: this.shippingVerificationForm.value?.drpPreparedBy,
        preparedOn: this.shippingVerificationForm.value?.txtPreparedOn,
        verificationStatus: this.shippingVerificationForm.value?.rbtnVerificationStatus,
        deo: this.shippingVerificationForm.value?.txtDeo,
        coInsurance: this.shippingVerificationForm.value?.txtCoIns,
        approvalStatus: this.shippingVerificationForm.value.rbtnInsuranceVerify,
        commentsToTech: this.shippingVerificationForm.value.txtcommentsToTech,
        reason: this.shippingVerificationForm.value.txtReason,
        isActive: +this.shippingVerificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.shippingVerificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.shippingVerificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.shippingVerificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.shippingVerificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.shippingVerificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.shippingVerificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.shippingVerificationForm.value?.txtReferenceNumber

      }

    } else if (this.shippingVerificationForm.value.txtPlan === payorLevelName[1]) {

      savedData.secondaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.shippingVerificationForm.value?.txtName,
        name: this.shippingVerificationForm.value?.txtName,
        address: this.shippingVerificationForm.value?.txtName,
        dateofBirth: this.shippingVerificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.shippingVerificationForm.value?.txtCommentsNotes,
        plan: this.shippingVerificationForm.value?.txtPlan,
        planName: this.shippingVerificationForm.value?.txtPlanName,
        planPhone: this.shippingVerificationForm.value?.txtPlanPhone,
        policy: this.shippingVerificationForm.value?.txtPolicy,
        groupNo: this.shippingVerificationForm.value?.txtGroup,
        effectiveDate: this.shippingVerificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.shippingVerificationForm.value?.txtType,
        fascalYearStart: this.shippingVerificationForm.value?.txtFascalYearStart,
        end: this.shippingVerificationForm.value?.txtEnd,
        isMedicareCmn: +this.shippingVerificationForm.value?.chkMedicare,
        isPatientHome: +this.shippingVerificationForm.value?.chkPatient,
        isFamilyDeductible: +this.shippingVerificationForm.value?.chkFamilyDeductible,
        cmnCode: this.shippingVerificationForm.value?.txtCmnCode,
        typeOfPlan: this.shippingVerificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.shippingVerificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.shippingVerificationForm.value?.chkOutOfNetwork,
        isFamily: +this.shippingVerificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.shippingVerificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.shippingVerificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.shippingVerificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.drpPayableOutOfNetwork,
          family: this.shippingVerificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.shippingVerificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.shippingVerificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.shippingVerificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.shippingVerificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.shippingVerificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.shippingVerificationForm.value?.chkPrecertRequired,
        phone: this.shippingVerificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.shippingVerificationForm.value?.chkReferralRequired,
        pcp: this.shippingVerificationForm.value?.txtPcp,
        isPredetermination: +(this.shippingVerificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkPredetermination)),
        preNotes: this.shippingVerificationForm.value?.txtPreNotes,
        lifeTimeMax: this.shippingVerificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.shippingVerificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.shippingVerificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.shippingVerificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.shippingVerificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.shippingVerificationForm.value?.txtDmeLimitMet,
        requestedBy: this.shippingVerificationForm.value?.txtRequestedBy,
        primaryPayor: this.shippingVerificationForm.value?.txtPrimaryPayor,
        contact: this.shippingVerificationForm.value?.txtContact,
        callTrackingNumber: this.shippingVerificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.shippingVerificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.shippingVerificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.shippingVerificationForm.value?.chkNetwork,
        claimAddress: this.shippingVerificationForm.value?.txtClaimAddress,
        planCob: this.shippingVerificationForm.value?.txtPlanCob,
        dedTotal: this.shippingVerificationForm.value?.txtDedTotal,
        dedMet: this.shippingVerificationForm.value?.txtDedMet,
        oopTotal: this.shippingVerificationForm.value?.txtOopTotal,
        oopMet: this.shippingVerificationForm.value?.txtOopMet,
        oop: this.shippingVerificationForm.value?.txtOopDeductible,
        dmeCoverage: this.shippingVerificationForm.value?.txtDmeCoverage,
        dmeMax: this.shippingVerificationForm.value?.txtDmeMax,
        hcpcs: this.shippingVerificationForm.value?.txtHcpcs,
        coverageCriteria: this.shippingVerificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.shippingVerificationForm.value?.txtQtyLimits,
        frequency: this.shippingVerificationForm.value?.txtFrequency,
        rent: this.shippingVerificationForm.value?.txtRent,
        paRequired: this.shippingVerificationForm.value?.txtPaRequired,
        codesRequirePa: this.shippingVerificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.shippingVerificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.shippingVerificationForm.value?.txtSpokeWith,
        verifiedBy: this.shippingVerificationForm.value?.drpVerifiedBy,
        dateVerified: this.shippingVerificationForm.value?.txtDateVerified,
        preparedBy: this.shippingVerificationForm.value?.drpPreparedBy,
        preparedOn: this.shippingVerificationForm.value?.txtPreparedOn,
        verificationStatus: this.shippingVerificationForm.value?.rbtnVerificationStatus,
        approvalStatus: this.shippingVerificationForm.value.rbtnInsuranceVerify,
        deo: this.shippingVerificationForm.value?.txtDeo,
        coInsurance: this.shippingVerificationForm.value?.txtCoIns,
        commentsToTech: this.shippingVerificationForm.value?.txtcommentsToTech,
        reason: this.shippingVerificationForm.value?.txtReason,
        isActive: +this.shippingVerificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.shippingVerificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.shippingVerificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.shippingVerificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.shippingVerificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.shippingVerificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.shippingVerificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.shippingVerificationForm.value?.txtReferenceNumber

      }
    } else if (this.shippingVerificationForm.value.txtPlan === payorLevelName[2]) {

      savedData.tertiaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.shippingVerificationForm.value?.txtName,
        name: this.shippingVerificationForm.value?.txtName,
        address: this.shippingVerificationForm.value?.txtName,
        dateofBirth: this.shippingVerificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.shippingVerificationForm.value?.txtCommentsNotes,
        plan: this.shippingVerificationForm.value?.txtPlan,
        planName: this.shippingVerificationForm.value?.txtPlanName,
        planPhone: this.shippingVerificationForm.value?.txtPlanPhone,
        policy: this.shippingVerificationForm.value?.txtPolicy,
        groupNo: this.shippingVerificationForm.value?.txtGroup,
        effectiveDate: this.shippingVerificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.shippingVerificationForm.value?.txtType,
        fascalYearStart: this.shippingVerificationForm.value?.txtFascalYearStart,
        end: this.shippingVerificationForm.value?.txtEnd,
        isMedicareCmn: +this.shippingVerificationForm.value?.chkMedicare,
        isPatientHome: +this.shippingVerificationForm.value?.chkPatient,
        isFamilyDeductible: +this.shippingVerificationForm.value?.chkFamilyDeductible,
        cmnCode: this.shippingVerificationForm.value?.txtCmnCode,
        typeOfPlan: this.shippingVerificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.shippingVerificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.shippingVerificationForm.value?.chkOutOfNetwork,
        isFamily: +this.shippingVerificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.shippingVerificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.shippingVerificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.shippingVerificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.drpPayableOutOfNetwork,
          family: this.shippingVerificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.shippingVerificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.shippingVerificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.shippingVerificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.shippingVerificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.shippingVerificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.shippingVerificationForm.value?.chkPrecertRequired,
        phone: this.shippingVerificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.shippingVerificationForm.value?.chkReferralRequired,
        pcp: this.shippingVerificationForm.value?.txtPcp,
        isPredetermination: +(this.shippingVerificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkPredetermination)),
        preNotes: this.shippingVerificationForm.value?.txtPreNotes,
        lifeTimeMax: this.shippingVerificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.shippingVerificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.shippingVerificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.shippingVerificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.shippingVerificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.shippingVerificationForm.value?.txtDmeLimitMet,
        requestedBy: this.shippingVerificationForm.value?.txtRequestedBy,
        primaryPayor: this.shippingVerificationForm.value?.txtPrimaryPayor,
        contact: this.shippingVerificationForm.value?.txtContact,
        callTrackingNumber: this.shippingVerificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.shippingVerificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.shippingVerificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.shippingVerificationForm.value?.chkNetwork,
        claimAddress: this.shippingVerificationForm.value?.txtClaimAddress,
        planCob: this.shippingVerificationForm.value?.txtPlanCob,
        dedTotal: this.shippingVerificationForm.value?.txtDedTotal,
        dedMet: this.shippingVerificationForm.value?.txtDedMet,
        oopTotal: this.shippingVerificationForm.value?.txtOopTotal,
        oopMet: this.shippingVerificationForm.value?.txtOopMet,
        oop: this.shippingVerificationForm.value?.txtOopDeductible,
        dmeCoverage: this.shippingVerificationForm.value?.txtDmeCoverage,
        dmeMax: this.shippingVerificationForm.value?.txtDmeMax,
        hcpcs: this.shippingVerificationForm.value?.txtHcpcs,
        coverageCriteria: this.shippingVerificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.shippingVerificationForm.value?.txtQtyLimits,
        frequency: this.shippingVerificationForm.value?.txtFrequency,
        rent: this.shippingVerificationForm.value?.txtRent,
        paRequired: this.shippingVerificationForm.value?.txtPaRequired,
        codesRequirePa: this.shippingVerificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.shippingVerificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.shippingVerificationForm.value?.txtSpokeWith,
        verifiedBy: this.shippingVerificationForm.value?.drpVerifiedBy,
        dateVerified: this.shippingVerificationForm.value?.txtDateVerified,
        preparedBy: this.shippingVerificationForm.value?.drpPreparedBy,
        preparedOn: this.shippingVerificationForm.value?.txtPreparedOn,
        verificationStatus: this.shippingVerificationForm.value?.rbtnVerificationStatus,
        approvalStatus: this.shippingVerificationForm.value.rbtnInsuranceVerify,
        deo: this.shippingVerificationForm.value?.txtDeo,
        coInsurance: this.shippingVerificationForm.value?.txtCoIns,
        commentsToTech: this.shippingVerificationForm.value?.txtcommentsToTech,
        reason: this.shippingVerificationForm.value?.txtReason,
        isActive: +this.shippingVerificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.shippingVerificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.shippingVerificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.shippingVerificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.shippingVerificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.shippingVerificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.shippingVerificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.shippingVerificationForm.value?.txtReferenceNumber,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling
      }
    }

    let insVerification: SaleorderDTO;
    const verifyData = savedData
    if (verifyStatus === "Approved") {
      insVerification = {
        defaultClaimId: response?.defaultClaimId,
        orders: {
          ...response?.orders, isCompleted: "Done"
        },
        clinicals: response?.clinicals,
        insurances: response?.insurances,
        items: response?.items,
        patients: {
          personals: PatientData?.personals,
          addresses: PatientData?.addresses,
          contacts: PatientData?.contacts,
          clinicals: PatientData?.clinicals,
          insurances: PatientData?.insurances,
          notes: PatientData?.notes,
          policies: PatientData?.policies,
          defaultPatientId: PatientData?.defaultPatientId,
          insuranceVerifications: {
            primaryInsuranceVerifies: verifyData?.primaryInsuranceVerifies,
            secondaryInsuranceVerifies: verifyData?.secondaryInsuranceVerifies,
            tertiaryInsuranceVerifies: verifyData?.tertiaryInsuranceVerifies,
          },
          authorizations: PatientData?.authorizations,
          patientDocuments: PatientData?.patientDocuments,
          organizationUnitId: PatientData?.organizationUnitId,
          isAutoIntake: PatientData?.isAutoIntake,
          autoIntakeDocumentDetails: PatientData?.autoIntakeDocumentDetails,
          chartId: PatientData?.chartId,
          billingDetails: PatientData?.billingDetails,
          patientLock: PatientData?.patientLock,
          // isSorted: PatientData?.isSorted,
          fileId: PatientData?.fileId,
        },
        saleorderId: response?.saleorderId,
        orderStatus: response?.orderStatus,
        organizationUnitId: response?.organizationUnitId,
        currentStage: response?.currentStage,
        currentStageStatus: response?.currentStageStatus,
        claimId: response?.claimId,
        patientId: response?.patientId,
        isShippingApprove: response?.isShippingApprove,
        isCashPayment: response?.isCashPayment,
        orderProcessStatus: response?.orderProcessStatus,
        patientLedgerReason: response?.patientLedgerReason,
        documentReason: response?.documentReason,
        paymentTransactionDetails: response?.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        patientLedgerStatus: response?.patientLedgerStatus,
        claimHistoryStatus: response?.claimHistoryStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        authType: response?.authType

      }
    } else {
      insVerification = {
        defaultClaimId: response?.defaultClaimId,
        orders: {
          ...response?.orders, isCompleted: "Shipping Disapproved"
        },
        clinicals: response?.clinicals,
        insurances: response?.insurances,
        items: response?.items,
        patients: {
          personals: PatientData?.personals,
          addresses: PatientData?.addresses,
          contacts: PatientData?.contacts,
          clinicals: PatientData?.clinicals,
          insurances: PatientData?.insurances,
          notes: PatientData?.notes,
          policies: PatientData?.policies,
          defaultPatientId: PatientData?.defaultPatientId,
          insuranceVerifications: {
            primaryInsuranceVerifies: verifyData?.primaryInsuranceVerifies,
            secondaryInsuranceVerifies: verifyData?.secondaryInsuranceVerifies,
            tertiaryInsuranceVerifies: verifyData?.tertiaryInsuranceVerifies,
          },
          authorizations: PatientData?.authorizations,
          patientDocuments: PatientData?.patientDocuments,
          organizationUnitId: PatientData?.organizationUnitId,
          isAutoIntake: PatientData?.isAutoIntake,
          autoIntakeDocumentDetails: PatientData?.autoIntakeDocumentDetails,
          chartId: PatientData?.chartId,
          billingDetails: response?.patients.billingDetails,
          patientLock: response?.patients.patientLock,
          // isSorted: response?.patients?.isSorted,
          fileId: response?.patients?.fileId,
        },

        saleorderId: response?.saleorderId,
        orderStatus: "Shipping Disapproved",
        organizationUnitId: response?.organizationUnitId,
        currentStage: response?.currentStage,
        currentStageStatus: response?.currentStageStatus,
        claimId: response?.claimId,
        patientId: response?.patientId,
        isShippingApprove: response?.isShippingApprove,
        isCashPayment: response?.isCashPayment,
        orderProcessStatus: response?.orderProcessStatus,
        patientLedgerReason: response?.patientLedgerReason,
        documentReason: response?.documentReason,
        paymentTransactionDetails: response?.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        patientLedgerStatus: response?.patientLedgerStatus,
        claimHistoryStatus: response?.claimHistoryStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        authType: response?.authType


      }
    }

    this.patientId !== "" && this.store.dispatch(new updateOrder(this.orderId, insVerification)).subscribe(response => {

      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.orderId = response?.orderState?.selectedOrder?.id;
      this.Table.setSelectedOrderId(this.orderId);
      this.saveInsuranceVerificationFormState.emit(this.shippingVerificationForm.value);
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // ! To get verification details based on order id
  getInsuranceVerifyByOrderId() {
    this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      const selectedPatient = responses?.orderState?.selectedOrder?.patients;
      const verifyData: any[] = [];
      this.patientId = selectedPatient?.patientId;
      this.sDefaultPatientId = selectedPatient?.defaultPatientId;
      this.getDropdowns();
      // ! To get verification details based on primary, secondary & tertiary policies
      const verifyDatas = selectedPatient?.insuranceVerifications?.primaryInsuranceVerifies;

      verifyDatas.plan === "Primary" && verifyData?.push(verifyDatas)

      return verifyData;
    })).subscribe(response => {

      this.shippingVerificationForm.patchValue({

        drpChart: response[0]?.chart,
        txtName: response[0]?.name,
        txtAddress: response[0]?.address,
        txtDateOfBirth: ((response[0]?.dateofBirth) == null ? "" : response[0]?.dateofBirth),
        txtCommentsNotes: response[0]?.commentsOrNotes,
        txtPlan: response[0]?.plan,
        txtPlanName: response[0]?.planName,
        txtPlanPhone: response[0]?.planPhone,
        txtPolicy: response[0]?.policy,
        txtGroup: response[0]?.groupNo,
        txtEffectiveDate: ((response[0]?.effectiveDate) == null ? "" : response[0]?.effectiveDate),
        txtType: response[0]?.typeOfPolicy,
        txtFascalYearStart: ((response[0]?.fascalYearStart) == null ? "" : response[0]?.fascalYearStart),
        txtEnd: ((response[0]?.end) == null ? "" : response[0]?.end),
        chkMedicare: response[0]?.isMedicareCmn,
        chkPatient: response[0]?.isPatientHome,
        chkFamilyDeductible: response[0]?.isFamilyDeductible,
        txtCmnCode: response[0]?.cmnCode,
        drpTypeOfPlan: response[0]?.typeOfPlan,
        chkIndividual: response[0]?.isIndividual,
        chkOutOfNetwork: response[0]?.isOutOfNetwork,
        chkFamily: response[0]?.isFamily,
        txtDeductibleIndividual: response[0]?.planDeductible?.individual,
        txtDeductibleOutOfNetwork: response[0]?.planDeductible?.outOfNetwork,
        txtDeductibleFamily: response[0]?.planDeductible?.family,
        txtDeductibleMetIndividual: response[0]?.deductibleMet?.individual,
        txtDeductibleMetOutOfNetwork: response[0]?.deductibleMet?.outOfNetwork,
        txtDeductibleMetFamily: response[0]?.deductibleMet?.family,
        drpPayableIndividual: response[0]?.payablePercent?.individual,
        drpPayableOutOfNetwork: response[0]?.payablePercent?.outOfNetwork,
        drpPayableFamily: response[0]?.payablePercent?.family,
        txtExpenseIndividual: response[0]?.outOfPocketExpense?.individual,
        txtExpenseOutOfNetwork: response[0]?.outOfPocketExpense?.outOfNetwork,
        txtExpenseFamily: response[0]?.outOfPocketExpense?.family,
        txtExpenseMetIndividual: response[0]?.outOfPocketExpenseMet?.individual,
        txtExpenseMetOutOfNetwork: response[0]?.outOfPocketExpenseMet?.outOfNetwork,
        txtExpenseMetFamily: response[0]?.outOfPocketExpenseMet?.family,
        chkExpenseIndividual: response[0]?.outOfPocketIncludeDeductible?.individual,
        chkExpenseOutOfNetwork: response[0]?.outOfPocketIncludeDeductible?.outOfNetwork,
        chkExpenseFamily: response[0]?.outOfPocketIncludeDeductible?.family,
        chkPrecertRequired: response[0]?.isPercertRequired,
        txtPhoneCall: response[0]?.phone,
        chkReferralRequired: response[0]?.isReferralRequired,
        txtPcp: response[0]?.pcp,
        chkPredetermination: response[0]?.isPredetermination,
        txtPreNotes: response[0]?.preNotes,
        txtLifetimeMax: response[0]?.lifeTimeMax,
        txtLifetimeMaxMet: response[0]?.lifeTimeMaxMet,
        chkDmePartsCovered: response[0]?.isDmePartsCovered,
        chkDmeReplacementUnit: response[0]?.isDmeReplaceMentUnit,
        txtDmeAnnualLimit: response[0]?.dmeAnnualLimit,
        txtDmeLimitMet: response[0]?.dmeLimitMet,
        txtRequestedBy: response[0]?.requestedBy,
        txtPrimaryPayor: response[0]?.primaryPayor,
        txtContact: response[0]?.contact,
        txtCallTrackingNumber: response[0]?.callTrackingNumber,
        txtPolicyHolderName: response[0]?.policyHolderName,
        txtPolicyHolderDob: ((response[0]?.policyHolderDob) == null ? "" : response[0]?.policyHolderDob),
        chkNetwork: response[0]?.isNetwork,
        txtClaimAddress: response[0]?.claimAddress,
        txtPlanCob: response[0]?.planCob,
        txtDedTotal: response[0]?.dedTotal,
        txtDedMet: response[0]?.dedMet,
        txtOopTotal: response[0]?.oopTotal,
        txtOopMet: response[0]?.oopMet,
        txtOopDeductible: response[0]?.oop,
        txtDmeCoverage: response[0]?.dmeCoverage,
        txtDmeMax: response[0]?.dmeMax,
        txtHcpcs: response[0]?.hcpcs,
        txtCoverageCriteria: response[0]?.coverageCriteria,
        txtQtyLimits: response[0]?.qtyLimits,
        txtFrequency: response[0]?.frequency,
        txtRent: response[0]?.rent,
        txtPaRequired: response[0]?.paRequired,
        txtCodesRequirePa: response[0]?.codesRequirePa,
        txtSameOrSimilarInfo: response[0]?.sameOrSimilarInfo,
        txtSpokeWith: response[0]?.spokeWith,
        drpVerifiedBy: response[0]?.verifiedBy,
        txtDateVerified: ((response[0]?.dateVerified) == null ? "" : response[0]?.dateVerified),
        drpPreparedBy: response[0]?.preparedBy,
        txtPreparedOn: ((response[0]?.preparedOn) == null ? "" : response[0]?.preparedOn),
        rbtnVerificationStatus: response[0]?.verificationStatus,
        rbtnInsuranceVerify: response[0]?.approvalStatus,
        txtDeo: response[0]?.deo,
        txtCoIns: response[0]?.coInsurance,
        txtcommentsToTech: response[0]?.commentsToTech,
        txtReason: response[0]?.reason,
        chkActive: response[0]?.isActive,
        chkIsBlueCardEligible: response[0]?.isBlueCardEligible,
        chkIsDirectBill: response[0]?.isDirectBill,
        chkIsPreAuthNeeded: response[0]?.isPreAuthNeeded,
        chkIsClaimSubmit: response[0]?.isClaimSubmit,
        txtPatientResposponsiblePaymentPercentage: response[0]?.patientResposponsiblePaymentPercentage,
        chkIsReferenceNumberAvailable: response[0]?.isReferenceNumberAvailable,
        txtReferenceNumber: response[0]?.referenceNumber

      })
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }
  // ! To get verification details based on order id
  onChangeGetInsuranceVerify($event: MatRadioChange) {
    this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      const selectedPatient = responses?.orderState.selectedOrder.patients.insuranceVerifications;
      this.patientId = selectedPatient.patientId;
      // this.getDropdowns();
      const verifyData: any[] = [];
      const verifyData1: any[] = [];
      const verifyData2: any[] = [];
      const verifyDatas = selectedPatient?.primaryInsuranceVerifies;
      const verifyDatas1 = selectedPatient?.secondaryInsuranceVerifies;
      const verifyDatas2 = selectedPatient?.tertiaryInsuranceVerifies;
      if ($event.value === 'Primary') {
        $event.value === 'Primary' && verifyDatas.plan === "Primary" && verifyData?.push(verifyDatas)
        return verifyData
      }
      if ($event.value === 'Secondary') {
        $event.value === 'Secondary' && verifyDatas1?.plan === "Secondary" && verifyData1.push(verifyDatas1)
        return verifyData1
      }
      if ($event.value === 'Tertiary') {
        $event.value === 'Tertiary' && verifyDatas2?.plan === "Tertiary" && verifyData2.push(verifyDatas2)
        return verifyData2
      }

      // return verifyData || verifyData1 || verifyData2;
    })).subscribe((response) => {
      this.shippingVerificationForm.patchValue({
        drpChart: response[0]?.chart,
        txtName: response[0]?.name,
        txtAddress: response[0]?.address,
        txtDateOfBirth: ((response[0]?.dateofBirth) == null ? "" : response[0]?.dateofBirth),
        txtCommentsNotes: response[0]?.commentsOrNotes,
        txtPlan: response[0]?.plan,
        txtPlanName: response[0]?.planName,
        txtPlanPhone: response[0]?.planPhone,
        txtPolicy: response[0]?.policy,
        txtGroup: response[0]?.groupNo,
        txtEffectiveDate: ((response[0]?.effectiveDate) == null ? "" : response[0]?.effectiveDate),
        txtType: response[0]?.typeOfPolicy,
        txtFascalYearStart: ((response[0]?.fascalYearStart) == null ? "" : response[0]?.fascalYearStart),
        txtEnd: ((response[0]?.end) == null ? "" : response[0]?.end),
        chkMedicare: response[0]?.isMedicareCmn,
        chkPatient: response[0]?.isPatientHome,
        chkFamilyDeductible: response[0]?.isFamilyDeductible,
        txtCmnCode: response[0]?.cmnCode,
        drpTypeOfPlan: response[0]?.typeOfPlan,
        chkIndividual: response[0]?.isIndividual,
        chkOutOfNetwork: response[0]?.isOutOfNetwork,
        chkFamily: response[0]?.isFamily,
        txtDeductibleIndividual: response[0]?.planDeductible?.individual,
        txtDeductibleOutOfNetwork: response[0]?.planDeductible?.outOfNetwork,
        txtDeductibleFamily: response[0]?.planDeductible?.family,
        txtDeductibleMetIndividual: response[0]?.deductibleMet?.individual,
        txtDeductibleMetOutOfNetwork: response[0]?.deductibleMet?.outOfNetwork,
        txtDeductibleMetFamily: response[0]?.deductibleMet?.family,
        drpPayableIndividual: response[0]?.payablePercent?.individual,
        drpPayableOutOfNetwork: response[0]?.payablePercent?.outOfNetwork,
        drpPayableFamily: response[0]?.payablePercent?.family,
        txtExpenseIndividual: response[0]?.outOfPocketExpense?.individual,
        txtExpenseOutOfNetwork: response[0]?.outOfPocketExpense?.outOfNetwork,
        txtExpenseFamily: response[0]?.outOfPocketExpense?.family,
        txtExpenseMetIndividual: response[0]?.outOfPocketExpenseMet?.individual,
        txtExpenseMetOutOfNetwork: response[0]?.outOfPocketExpenseMet?.outOfNetwork,
        txtExpenseMetFamily: response[0]?.outOfPocketExpenseMet?.family,
        chkExpenseIndividual: response[0]?.outOfPocketIncludeDeductible?.individual,
        chkExpenseOutOfNetwork: response[0]?.outOfPocketIncludeDeductible?.outOfNetwork,
        chkExpenseFamily: response[0]?.outOfPocketIncludeDeductible?.family,
        chkPrecertRequired: response[0]?.isPercertRequired,
        txtPhoneCall: response[0]?.phone,
        chkReferralRequired: response[0]?.isReferralRequired,
        txtPcp: response[0]?.pcp,
        chkPredetermination: response[0]?.isPredetermination,
        txtPreNotes: response[0]?.preNotes,
        txtLifetimeMax: response[0]?.lifeTimeMax,
        txtLifetimeMaxMet: response[0]?.lifeTimeMaxMet,
        chkDmePartsCovered: response[0]?.isDmePartsCovered,
        chkDmeReplacementUnit: response[0]?.isDmeReplaceMentUnit,
        txtDmeAnnualLimit: response[0]?.dmeAnnualLimit,
        txtDmeLimitMet: response[0]?.dmeLimitMet,
        txtRequestedBy: response[0]?.requestedBy,
        txtPrimaryPayor: response[0]?.primaryPayor,
        txtContact: response[0]?.contact,
        txtCallTrackingNumber: response[0]?.callTrackingNumber,
        txtPolicyHolderName: response[0]?.policyHolderName,
        txtPolicyHolderDob: ((response[0]?.policyHolderDob) == null ? "" : response[0]?.policyHolderDob),
        chkNetwork: response[0]?.isNetwork,
        txtClaimAddress: response[0]?.claimAddress,
        txtPlanCob: response[0]?.planCob,
        txtDedTotal: response[0]?.dedTotal,
        txtDedMet: response[0]?.dedMet,
        txtOopTotal: response[0]?.oopTotal,
        txtOopMet: response[0]?.oopMet,
        txtOopDeductible: response[0]?.oop,
        txtDmeCoverage: response[0]?.dmeCoverage,
        txtDmeMax: response[0]?.dmeMax,
        txtHcpcs: response[0]?.hcpcs,
        txtCoverageCriteria: response[0]?.coverageCriteria,
        txtQtyLimits: response[0]?.qtyLimits,
        txtFrequency: response[0]?.frequency,
        txtRent: response[0]?.rent,
        txtPaRequired: response[0]?.paRequired,
        txtCodesRequirePa: response[0]?.codesRequirePa,
        txtSameOrSimilarInfo: response[0]?.sameOrSimilarInfo,
        txtSpokeWith: response[0]?.spokeWith,
        drpVerifiedBy: response[0]?.verifiedBy,
        txtDateVerified: ((response[0]?.dateVerified) == null ? "" : response[0]?.dateVerified),
        drpPreparedBy: response[0]?.preparedBy,
        txtPreparedOn: ((response[0]?.preparedOn) == null ? "" : response[0]?.preparedOn),
        rbtnVerificationStatus: response[0]?.verificationStatus,
        txtDeo: response[0]?.deo,
        txtCoIns: response[0]?.coInsurance,
        txtcommentsToTech: response[0]?.commentsToTech,
        txtReason: response[0]?.reason,
        rbtnInsuranceVerify: response[0]?.approvalStatus,
        chkActive: response[0]?.isActive
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  private getDropdowns() {
    //   this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
    //     let patientDetails: PatientDTO;
    //     patientDetails = response?.patientState?.selectedPatient;
    //     this.drpPolicies = patientDetails??.policies;
    //   });
  }
  onSelection(value) {
    this.shippingVerificationForm.patchValue({ txtCoIns: value });
  }


}
export interface drpPolicesDTO {
  insuranceType: string
  insuranceId: string
  birthdate: string
  policyNo: string
  startDate: string
  endDate: string
  groupName: string
  groupNo: string
  fisrtName: string
  address1: string
}
