<table datatable class="tbl hover row-border hover w-100 display" [dtOptions]="dtCallLogOptions">
  <thead>
    <tr>
      <th [matTooltip]="'Called'" class="text-center">Called</th>
      <th [matTooltip]="'Called By'">Called By</th>
      <th [matTooltip]="'Notes'">Notes</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of callLogTableData">
      <!-- <td (dblclick)="onRowDoubleClick(data?.callDate || '-')" [matTooltip]="data?.callDate || '-'">{{ data.callDate }}</td> -->
      <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.callDate))"
        [matTooltip]="getFormattedDate(data?.callDate)" class="text-center">
        {{ getFormattedDate(data?.callDate) }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.calledByName || '-')" [matTooltip]="data?.calledByName || '-'">{{
        data.calledByName }}</td>
      <td (dblclick)="onRowDoubleClick(data?.callDetails || '-')" [matTooltip]="data?.callDetails || '-'">
        {{ data.callDetails }}
      </td>
    </tr>
  </tbody>
</table>