<!-- Title -->
<div class="row align-items-center">
    <div class="col-md-10">
        <h2 class="customThemeClass"> Add Notes - 52145698SD</h2>
    </div>
    <div class="col-md-2" style="text-align: right">
        <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
    </div>
</div>

<mat-dialog-content>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body pb-0 mb-1 mt-1">
                    <form [formGroup]="noteForm">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <mat-form-field class="col-6">
                                            <mat-select formControlName="drpNotetype">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="NoteTypeControl"
                                                        placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                                        formControlName="txtNoteType"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let note of drpNotetype"
                                                    [value]="note.id+ '/' +note.noteType">
                                                    {{ note.noteType }}
                                                </mat-option>
                                            </mat-select>

                                            <mat-label>
                                                <div class="select-placeholder-container" *ngIf="isdrpLoading">
                                                    <span>Loading...</span>
                                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                                </div>
                                                <span *ngIf="!isdrpLoading">Note Type <span
                                                        class="asterisk">*</span></span>
                                            </mat-label>

                                            <mat-error class="spanAlignment" *ngIf="
                                                        noteForm?.get('drpNotetype')?.touched &&
                                                        noteForm?.get('drpNotetype')?.errors?.required
                                            ">
                                                Note Type is a required field!
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-checkbox class="col-3 ml-3 mt-3" formControlName="chkIsCallNote">is Call
                                            Note<span class="asterisk">*</span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="row">
                                        <mat-form-field class="col-12">
                                            <mat-label>Description<span class="asterisk">*</span></mat-label>
                                            <textarea matInput rows="6" autocomplete="off" maxlength="5000"
                                                formControlName="txtDescription" [(ngModel)]="notesText"
                                                (input)="updateCharacterCount(5000)" type="text"
                                                cdkFocusInitial></textarea>
                                            <div class="char-count">
                                                {{ notesText?.length }} / 5000
                                            </div>
                                            <mat-error class="spanAlignment"
                                                *ngIf="noteForm?.get('txtDescription')?.touched">
                                                <ng-container
                                                    *ngIf="noteForm?.get('txtDescription')?.hasError('required')">
                                                    Description is a required field!
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="noteForm?.get('txtDescription')?.hasError('whitespace')">
                                                    Description cannot be just whitespace!
                                                </ng-container>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="col-12 text-center mt-1">
        <button mat-button class="buttonColor mb-2 mr-2"
            [disabled]="isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty)" (click)="saveCallNotes()">
            Save
            <mat-icon *ngIf="isShowSpinner">
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
            </mat-icon>
        </button>
        <button mat-button class="resetclr mb-2 mr-2" (click)="resetCallNotes()">Reset</button>
    </div>
</mat-dialog-actions>