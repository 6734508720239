import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import {
  AnnotatedDTO,
  LockDocListDTO,
  SortedDocumentGroupDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import {
  SortingShortCode,
  TextAnnotation,
  defaultGuid,
  sotingSelectedCSS,
  tiffPointerMode,
} from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SaveDocumentDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/sorting/dto';
import { SortType } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { PatientDocumentService } from '../patient-proxy/patient-optimization/patient-document.service';
import {
  CreateUpdateComplainceDTO,
  CreateUpdateDiagnosticSleepStudyDTO,
  CreateUpdatePrescriptionDTO,
  CreateUpdateSleepStudyDTO,
  CreateUpdateTitrationSleepStudyDTO,
} from '../patient-proxy/patient/dto';
import {
  CreateUpdateChecklistSortingDTO,
  CreateUpdateInitialFaceToFaceDTO,
} from '../patient-proxy/patient-optimization/dto';
import { doctorInterface } from '../patient-clinical-tab/patient-clinical-tab.component';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import { ListService } from '@abp/ng.core';
import { MatDialog } from '@angular/material/dialog';
import { PatientDoctorComponent } from '../patient-doctor/patient-doctor.component';
import {
  DiagnosisCode10DTO,
  MachineTypeDTO,
  SymptomsDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  DiagnosisCode10Service,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientIcd10Component } from '../patient-icd10/patient-icd10.component';
import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';
import { DatePipe } from '@angular/common';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
import {
  CreateUpdateDocumentBinDTO,
  ValidateDocumentBinDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/dto/models';
import { Router } from '@angular/router';
import { HellosignSignatureService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/hellosign-signature.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inbound-batch-sorting',
  templateUrl: './inbound-batch-sorting.component.html',
  styleUrls: ['./inbound-batch-sorting.component.scss'],
  providers: [ListService, DatePipe],
})
export class InboundBatchSortingComponent implements OnInit {
  //#region related Variables Initialization Declaration
  docId: string;
  patientId: string;
  subscription$: Subscription[] = [];
  private loadInboundDocumentAPICall$: Subscription;
  SortedloadInboundDocumentAPICall$: Subscription;
  PendingloadInboundDocumentAPICall$: Subscription;
  private inboundgetLockDocDetailsAPICall: Subscription;
  private inboundisLockUpdateAPICall: Subscription;
  private saveAnnotationAPICall: Subscription;
  isLoading: boolean;
  document_shortCode: any;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  pageNumber: number = 1;
  totalPages: number = 0;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 0.8;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL + 4;
  readonly MIN_ZOOM_LEVEL = 0.2;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  existingDocumentImageList: any = [];
  selectedExistingDocument: any = null;
  loadingMessage: string = 'Loading ...';
  signatureRequestId:string;
  lockDocListDTO: LockDocListDTO[];
  lenlockDocListDTO: number = 0;
  currentlockDoc: number = 0;
  batchSortingForm: FormGroup;
  defaultGuid = defaultGuid;
  // filteredPatients: Observable<{ guid: string; idAndNameAndDOB: string }[]> =
  //   of([]);
  lstAllPatientIds: { guid: string; idAndNameAndDOB: string }[] = [];
  documentTypes = [];
  SortingShortCode = SortingShortCode;
  isDisabled = false;
  overAllPages: number = 0;
  sortedPages: number = 0;
  pendingPages: number = 0;
  isLoadSortingView: boolean;

  isBTNdisable: boolean = true;
  tiffPageNo: number = 0;
  isSortingStart = false;
  checkValidInputCase = 0;
  arrowButtonClicked = false;
  @ViewChildren('myInputRefs') myInputRefs: QueryList<ElementRef>;
  saveDocumentDetails: SaveDocumentDto[] = [];

  RxdateMin: Date;
  RxdateMax: Date;

  TotalDocumentImageList: any = [];
  SortedDocumentImageList: any = [];
  PendingDocumentImageList: any = [];
  BinDocumentImageList: any = [];
  currentArrayIndex: number = 0;
  private saveSubject: Subject<void> = new Subject<void>();
  isSaveDisabled = false;
  filteredDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  lstDoctor: any[];
  drpSymptoms: any[] = [];
  drpMachines: MachineTypeDTO[] = [];
  filteredSymptoms: Observable<SymptomsDTO[]> | undefined;
  filteredDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredRxDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredMachines: Observable<MachineTypeDTO[]> | undefined;
  drpDiagnosisICD10: DiagnosisCode10DTO[] = [];
  filteredRxDoctors: Observable<doctorInterface[]> | undefined;
  textAnnotations: TextAnnotation[] = [];
  documentBinId: string = defaultGuid;
  isDrawing: boolean = false;
  startX: number = 0;
  startY: number = 0;
  lineColor: string = '#F6BE00';
  textColor: string = '#0000FF';
  savedState: ImageData | null = null;
  isMouseDown: boolean = false;
  isMouseMove: boolean = false;
  isMouseUp: boolean = false;
  isMouseLeave: boolean = false;
  rectWidth: number;
  rectHeight: number;
  headerText: string;
  TiffError: boolean = true;
  disableAnnotation: boolean = false;

  // overAllPages: number = 0;
  overAllPagesBG: number = 0;
  // sortedPages: number = 0;
  sortedPagesBG: number = 0;
  // pendingPages: number = 0;
  pendingPagesBG: number = 0;
  sotingSelectedCSS = sotingSelectedCSS;
  selectedOption: sotingSelectedCSS = sotingSelectedCSS.Total;

  SortSaveError: boolean = true;

  readonly DEFAULT_LAZY_LOADING = 10; // 25;
  from: number = 1;
  to: number = this.DEFAULT_LAZY_LOADING;
  readonly DEFAULT_LAZY_LOADING_API = 10; // If change the Value API side also need to change 10 to X
  // readonly DEFAULT_LAZY_LOADING_Index = 9;
  pendingFrom: number = 0;
  pendingTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X
  sortedFrom: number = 0; // Index based
  sortedTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X

  firstTotalAPICall: boolean = false;
  strPdfString: string = '';
  isHideTiffViewer: boolean = false;
  isSortingStatus: boolean = false;
  TotalAPICallDone: boolean = false;
  PendingAPICallDone: boolean = false;
  pendingTimeout: any;
  isTotalAPIcallGoingOn: boolean = false;
  isDocumentLoadBefore: boolean = false;
  lstChartNos: any;
  patientChartApiSubscription: Subscription;
  chartID: any;
  patientName: string;
  defaultPatientId: any;
  faxId: any;
  physicianDropdown: Subscription;
  physicianDrpdwn: any;
  isSkipped: boolean = false;
  isSave: boolean = false;
  document_documentTypeId: any;
  isTotalAPIcallGoingOnCount: number = 0;
  loadloadOverAllSortingAPICall$: Subscription;
  isLoadOverAllSortingAPI: boolean = true;
  isOrderPageLoading: boolean;
  showPdfViwer: boolean = false;
  pdfheight: string = '100%';
  blobName: string;
  prevZoomLevel: number;
  LastSortingPageNo: number = null;
  LastSortingShortCode: string = null;
  mstDocumentBinForm: FormGroup;
  zoomLevelReSize: number = this.DEFAULT_ZOOM_LEVEL;
  DocumentBinSaveBtn: boolean = false;
  tenantId:string;
  annotationModeFlag: number = 1;
  annotationModeFlagOld: number = 1;
  tiffPointerMode = tiffPointerMode;
  cursorStyle = 'crosshair';
  refreshButtonClicked: boolean = false;
  TiffNotLoadingError: boolean = true;
  loadInboundDocumentIsPrevoiusCalled: boolean = false;
  //#endregion

  constructor(
    private documentBinService: DocumentBinService,
    private inboundDocumentService: InboundDocumentService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private patientDocumentService: PatientDocumentService,
    public list: ListService,
    private mmOrderService: MmOrderService,
    private loaderService: LoaderService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private helloSign: HellosignSignatureService,
  ) {
    // setTimeout(() => {
    //   if (this.selectedExistingDocument === null) {
    //     this.loadingMessage = 'Image not found';
    //   }
    // }, 10000);
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.intializeForms();
    this.saveSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.isSaveDisabled = false;
      this.isDisabled = true;
      this.saveDocument();
    });
  }
  ngAfterViewInit() {}

  ngOnDestroy(): void {
    if (this.physicianDropdown) {
      this.physicianDropdown.unsubscribe();
    }
    if (this.inboundgetLockDocDetailsAPICall) {
      this.inboundgetLockDocDetailsAPICall?.unsubscribe();
    }
    if (this.inboundisLockUpdateAPICall) {
      this.inboundisLockUpdateAPICall?.unsubscribe();
    }
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall?.unsubscribe();
    }
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.SortedloadInboundDocumentAPICall$) {
      this.SortedloadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.PendingloadInboundDocumentAPICall$) {
      this.PendingloadInboundDocumentAPICall$.unsubscribe();
    }

    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }

    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$.unsubscribe();
    }

    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // Check if there are unsaved changes (you'll need to implement this logic)
    if (this.isSortingStart || this.textAnnotations.length !== 0) {
      $event.returnValue = true;
    }
  }

  //To Initialize the Forms
  intializeForms() {
    this.batchSortingForm = this.fb.group({
      drpSearchPatient: new FormControl(this.patientId),
      txtPatientFilter: new FormControl(''),
    });
    this.mstDocumentBinForm = this.fb.group({
      txtBinName: new FormControl(
        '',
        [Validators.required, noWhitespaceValidator],
        this.checkDocumentBinUniqness()
      ),
    });
    this.searchPatient('');
    this.getLockedDocument();
    // this.loadPatientDetails();
  }

  loadPatientDetails() {
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(this.docId)
      .subscribe(
        (response) => {
          this.chartID = response?.chartId || '';
          this.patientName = (
            (response?.firstName || '') +
            ' ' +
            (response?.middleName || '') +
            ' ' +
            (response?.lastName || '')
          ).trim();
          this.defaultPatientId = response?.defaultPatientId || '';
          this.faxId = response?.defaultFaxId || '';
          this.signatureRequestId = response?.signatureRequestId || '';
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
          console.warn(data?.error?.error?.message);
        }
      );
  }

  /// for enter number only
  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 44 || charCode > 45)
    ) {
      return false;
    }
    return true;
  }

  loadOverAllSortingAPI() {
    // this.sortingDocView()
    // this.isLoadSortingView = true;
    this.isLoadOverAllSortingAPI = true;
    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getSortedDocumentPagesv2ByGDocumentId(this.docId)
      .subscribe(
        (response) => {
          this.documentTypes = response?.ltDocuments || [];
          this.isLoadSortingView = false;
          if (!this.isTotalAPIcallGoingOn) {
            this.PendingDocumentImageList = this.TotalDocumentImageList.filter(
              (dx) => response.pending.includes(dx.pageNo)
            );

            if (this.selectedOption === this.sotingSelectedCSS.Pending) {
              this.loadPendingDocument();
            }
            this.SortedDocumentImageList = this.TotalDocumentImageList.filter(
              (dx) => response.sortedDocuments.includes(dx.pageNo)
            );

            if (this.selectedOption === this.sotingSelectedCSS.Sorted) {
              this.loadSortedDocument();
            }

            // Function to add docPageContent to documentTypesV1
            this.documentTypes.forEach((docType) => {
              if (docType.docPage) {
                docType.docPageContent = this.TotalDocumentImageList.filter(
                  (doc) => docType.docPage.includes(doc.pageNo)
                );
              } else {
                docType.docPageContent = [];
              }
            });
          }
          this.isTotalAPIcallGoingOnCount++;
          setTimeout(() => {
            this.isTotalAPIcallGoingOnCount++;
          }, 500);
        },
        (err) => {
          // this.handleErrorResponse(err);
          setTimeout(() => {
            this.isTotalAPIcallGoingOnCount++;
          }, 500);
        }
      );
    this.loadloadOverAllSortingAPICall$ = loadInboundDocument;
  }

  skipBatch() {
    this.toastr.success('Batch Skipped Successfully', 'Success');
  }

  clearBatchSoringForNextDoc() {
    this.from = 1;
    this.to = 10;
    this.pendingFrom = 1;
    this.pendingTo = 10;
    this.sortedFrom = 1;
    this.sortedTo = 10;
    this.selectedOption = sotingSelectedCSS.Total;
    this.overAllPages = 0;
    this.overAllPagesBG = 0;
    this.pendingPages = 0;
    this.pendingPagesBG = 0;
    this.sortedPages = 0;
    this.sortedPagesBG = 0;
    this.TotalDocumentImageList = [];
    this.PendingDocumentImageList = [];
    this.SortedDocumentImageList = [];
    this.documentTypes = [];
    this.existingDocumentImageList = [];
    this.saveDocumentDetails = [];
    this.isLoading = true;
    // this.isDisabled=true;
    this.isDisabled = false;
    this.clearCanvas();
    this.pageNumber = 1;
    this.currentArrayIndex = 0;
    this.document_shortCode = 'ABC';
    // this.loadTotalDocument();
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  lockUpdateAPICall(flag: number) {
    this.isSkipped = true;
    this.isHideTiffViewer = false;
    // 1 Skip Go to Next // 2 Save Go to next
    if (
      this.saveDocumentDetails.length === 0 &&
      this.textAnnotations.length === 0
    ) {
      try {
        if (this.inboundisLockUpdateAPICall) {
          this.inboundisLockUpdateAPICall.unsubscribe();
        }

        if (this.currentlockDoc <= this.lenlockDocListDTO) {
          this.inboundisLockUpdateAPICall = this.inboundDocumentService
            .updateLockstatusByDocumentId(
              this.lockDocListDTO[this.currentlockDoc]?.documentId
            )
            .subscribe(
              (response) => {

                if(this.lockDocListDTO.length-1 === this.currentlockDoc){
                  this.router.navigate(['/inbound_sorting_pending']);
                }
                this.clearBatchSoringForNextDoc();
                this.loadOverAllSortingAPI();
                // 1 Skip Go to Next // 2 Save Go to next
                if (flag === 1) {
                  this.skipBatch();
                }
                this.isSkipped = false;
                this.currentlockDoc++;
                if (this.currentlockDoc >= this.lenlockDocListDTO) {
                  this.currentlockDoc = this.lenlockDocListDTO - 1;
                  this.isBTNdisable = true;
                  this.lenlockDocListDTO = 0;
                  // window.close();
                } else {
                  this.docId =
                    this.lockDocListDTO[this.currentlockDoc]?.documentId ||
                    defaultGuid;
                  // this.loadInboundDocument(this.docId);
                  this.batchSortingForm.patchValue({
                    drpSearchPatient:
                      this.lockDocListDTO[this.currentlockDoc]?.patientId ||
                      defaultGuid,
                  });
                  this.updatePageHeaderText();
                  this.loadInboundDocument(this.from, this.to);
                  this.isBTNdisable = false;
                }

                this.LastSortingPageNo = null;
                this.LastSortingShortCode = null;
              },
              (error) => {
                this.toastr.error('Failed', 'Error');
                // this.reloadTable.emit();
                console.error('Error:', error);
                this.isSkipped = false;
              }
            );
        }
      } catch (error) {
        // this.toastr.error('Lock Update Failed', 'Error');
        // this.reloadTable.emit('Mani');
        console.error('Unexpected error:', error);
      } finally {
      }
    } else {
      let text = 'Changes you made may not be saved.';
      if (this.saveDocumentDetails.length != 0) {
        text = 'Are you sure you want to clear sorted documents ?';
      }
      if (this.textAnnotations.length != 0) {
        text = 'Are you sure you want to clear Annotation text ?';
      }

      if (
        this.textAnnotations.length != 0 &&
        this.saveDocumentDetails.length != 0
      ) {
        text =
          'Are you sure you want to clear sorted documents & Annotation text?';
      }

      const confirmation = confirm(text);
      if (confirmation) {
        this.saveDocumentDetails = [];
        this.lockUpdateAPICall(flag);
        return true;
      } else {
        this.isSkipped = false;
        return false;
      }
    }
  }

  saveSortedDetails() {
    try {
      if (!this.isDisabled) {
        this.isDisabled = true;
        this.isSave = true;
        this.loaderService.showLoader();
        this.saveSubject.next();
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // Optionally, you can reset isDisabled after a certain period
      setTimeout(() => {
        this.isDisabled = false;
        this.isSave = false;
      }, 5000); // 5000 milliseconds (adjust as needed)
    }
  }

  private saveDocument() {
    try {
      // this.isDisabled = true;
      this.isSave = true;

      let createUpdateChecklistSortingDTO: CreateUpdateChecklistSortingDTO = {
        lstSorting: this.saveDocumentDetails,
        initialFaceToFaces: null,
        sleepStudies: null,
        prescriptions: null,
        complainces: null,
        isInitialFaceToFaceDataAvailable: 0,
        isSleepStudyDataAvailable: 0,
        isPrescriptionDataAvailable: 0,
        isComplainceDataAvailable: 0,
        isVerificationDataAvailable: 0,
        isRuleCheck: 0,
        isRulesNeeded: 0,
        isCompSA: 0,
        dxType: null,
        setupType: null,
        percentageOfCompletion: 0,
        initialFaceToFacePendingValidationCount: 0,
        sleepStudyPendingValidationCount: 0,
        prescriptionPendingValidationCount: 0,
        compliancePendingValidationCount: 0,
        isApproved: true,
        patientId: this.patientId,
        documentId: this.docId,
        bCompleteSorting: true,
      };

      // return false;
      const docDetails = this.patientDocumentService
        .checklistwithSortingUpdateByInput(createUpdateChecklistSortingDTO)
        .subscribe(
          (response) => {
            this.toastr.success('Document Sorted Successfully');
            this.saveDocumentDetails = [];
            this.isSortingStart = false;
            this.isSkipped = false;
            this.lockUpdateAPICall(2);
            this.isSkipped = false;
            this.loaderService.hideLoader();
            // this.router.navigate(['dashboard']);
            // this.sortingDocView();
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.saveDocumentDetails = [];
            // this.router.navigate(['dashboard']);
            // this.sortingDocView();
            this.loadOverAllSortingAPI();
            this.toastr.error(data?.error?.error?.message, 'Error');
            this.loaderService.hideLoader();
          }
        );
    } catch (error) {
      console.error('Error:', error);
      //  this.isDisabled = false;
      this.isSave = false;
      this.isSaveDisabled = false;
      this.isSortingStart = false; // Enable the button when the operation completes (or encounters an error)
      this.loaderService.hideLoader();
    } finally {
      // this.saveDocumentDetails = [];
    }
  }

  loadTotalDocument() {
    this.isHideTiffViewer = false;
    if (this.mustSaveAnnotations()) {
      this.document_shortCode = 'ABC';
      this.tiffPageNo = 1;
      this.pageNumber = 1;
      this.currentArrayIndex = 0;
      this.isLoading = true;
      this.totalPages = this.TotalDocumentImageList.length;
      if (this.totalPages > 0) {
        this.existingDocumentImageList = this.TotalDocumentImageList;
        this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        this.TiffError = false;
      } else {
        this.TiffError = true;
        this.TotalDocumentImageList = [];
        this.loadingMessage = 'Total Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      }
      this.isLoading = false;
      this.selectedOption = sotingSelectedCSS.Total;
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  loadSortedDocument() {
    if (!this.isLoadSortingView) {
      if (this.mustSaveAnnotations()) {
        this.document_shortCode = 'ABC';
        this.tiffPageNo = 1;
        this.pageNumber = 1;
        this.currentArrayIndex = 0;
        this.isLoading = true;
        this.totalPages = this.SortedDocumentImageList.length;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = this.SortedDocumentImageList;
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
          this.TiffError = false;
        } else {
          this.TiffError = true;
          this.SortedDocumentImageList = [];
          this.loadingMessage = 'Sorted Images has not found';
          setTimeout(() => {
            this.clearCanvas();
          }, 500);
        }
        this.isLoading = false;
        this.selectedOption = sotingSelectedCSS.Sorted;
      } else {
        this.pageNumber =
          this.existingDocumentImageList[this.currentArrayIndex].pageNo;
      }
    }
  }

  loadPendingDocument() {
    this.isHideTiffViewer = false;
    if (!this.isLoadSortingView) {
      if (this.mustSaveAnnotations()) {
        this.document_shortCode = 'ABC';
        this.tiffPageNo = 1;
        this.pageNumber = 1;
        this.currentArrayIndex = 0;
        this.isLoading = true;
        this.totalPages = this.PendingDocumentImageList.length;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = this.PendingDocumentImageList;
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.TiffError = true;
          this.PendingDocumentImageList = [];
          this.loadingMessage = 'Unsorted Images has not found';
          setTimeout(() => {
            this.clearCanvas();
          }, 500);
        }
        this.isLoading = false;
        this.selectedOption = sotingSelectedCSS.Pending;
      } else {
        this.pageNumber =
          this.existingDocumentImageList[this.currentArrayIndex].pageNo;
      }
    }
  }

  loadBinDocument(shortCode: string, pagecount: number,documentTypeId: string) {

    if (this.mustSaveAnnotations()) {
      this.tiffPageNo = 1;
      this.pageNumber = 1;
      this.currentArrayIndex = 0;
      this.isHideTiffViewer = false;
      this.document_shortCode = shortCode;
      this.document_documentTypeId = documentTypeId;
      this.selectedOption = sotingSelectedCSS.Bin;

      if (shortCode === SortingShortCode.SIGNED_DOCUMENT) {
        this.viewDocmentPDFHelloSign(this.signatureRequestId);
        return false;
      }
      if (pagecount !== 0) {
        const filteredDocuments = this.documentTypes.filter(
          (doc) => doc.shortCode === shortCode
        );
        // Extract docPageContent
        const docPageContent = filteredDocuments.map(
          (doc) => doc.docPageContent
        );
        this.isLoading = true;
        this.totalPages = docPageContent[0]?.length || 0;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = docPageContent[0].map((a) => {
            return {
              ...a,
              src: this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/tiff;base64, ${a.file}`
              ),
              pageNo: a.pageNo,
            };
          });
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.clearCanvas();
          this.TiffError = true;
          this.loadingMessage = 'Images has not found';
        }
        this.isLoading = false;
      } else {
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      }
      // this.document_shortCode = shortCode;
      // this.selectedOption = sotingSelectedCSS.Bin;
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  isHelloSignAPIcallDoneBefore: boolean = false;
  viewDocmentPDFHelloSign(signaturerequestid) {
    this.TiffError = true;
    this.clearCanvas();
    if (!signaturerequestid) {
      this.toastr.warning('Reason: Hello Sign signoure has not done', 'Warning');
      return false;
    }
    this.isHideTiffViewer = true;

    this.showPdfViwer = false;
    if (!this.isHelloSignAPIcallDoneBefore) {
      this.isHelloSignAPIcallDoneBefore = true;
      this.helloSign
        .getSignatureDownloadDocumentBySignatureIdAndTenantId(
          signaturerequestid,
          this.tenantId
        )
        .subscribe(
          (response) => {
            if (
              typeof response === 'string' &&
              response &&
              response !== null &&
              response?.length !== 0
            ) {
              this.strPdfString = String(response);
              this.showPdfViwer = true;
            }

            this.showPdfViwer = true;
          },
          (err) => {
            this.strPdfString = '';
            this.showPdfViwer = true;
            console.error(err)
            this.toastr.error('Hello Sign Documenet has not found');
          }
        );
    } else {
      if(this.isEmpty(this.strPdfString)){
        this.toastr.error('Hello Sign Documenet has not found');
      }
      this.showPdfViwer = true;
    }
  }

  searchPatient(value: any) {
    this.loadPatientChartNos(value);
  }

  //To Load the Chart No of the Patients
  loadPatientChartNos(searchParameter) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(
        searchParameter,
        this.patientId
      )
      .subscribe(
        (response) => {
          this.lstChartNos = response;
          return this.lstChartNos;
        },
        (err) => {}
      );
  }

  updatePageHeaderText() {
    let lstChartNosFilter = this.lstChartNos.find(
      (patient) =>
        patient.patientId === this.batchSortingForm.value.drpSearchPatient
    );
    this.headerText = lstChartNosFilter?.ddValue || 'New Patient';
    this.patientId = this.batchSortingForm.value.drpSearchPatient;
    this.loadPatientDetails();
  }

  getLockedDocument() {
    try {
      if (this.inboundgetLockDocDetailsAPICall) {
        this.inboundgetLockDocDetailsAPICall.unsubscribe();
      }
      this.inboundgetLockDocDetailsAPICall = this.inboundDocumentService
        .getLockDocDetails()
        .subscribe(
          (response) => {
            this.lenlockDocListDTO = response?.length || 0;
            if (this.lenlockDocListDTO === 0) {
              this.isBTNdisable = true;
              this.currentlockDoc = -1;
              this.router.navigate(['/inbound_sorting_pending']);
            } else {
              this.currentlockDoc = 0;
              this.docId = response[0]?.documentId;
              this.lockDocListDTO = response || [];
              this.patientId = response[0]?.patientId;
              this.blobName = response[0]?.blobName || 'no.pdf';

              // this.loadInboundDocument(this.docId);
              // this.loadInboundDocument(this.from, this.to);
              this.loadInboundDocument(-100, -20); // VVV Need to check
              // this.sortingDocView();
              this.loadOverAllSortingAPI();
              this.loadPatientDetails();

              this.batchSortingForm.patchValue({
                drpSearchPatient: this.patientId,
              });
              this.updatePageHeaderText();
              this.isBTNdisable = false;
            }
          },
          (error) => {
            const data: HttpErrorResponse = error;
            this.toastr.error(data?.error?.error?.message, 'Error');
            this.isBTNdisable = true;
            this.currentlockDoc = -1;
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
    }
  }

  private handleErrorResponse(err: any) {
    const data: HttpErrorResponse = err;
    console.warn(data?.error?.error?.message);
    this.loadingMessage = 'TIFF Image conversion has been In Progress.';
    this.TiffError = true;
    this.SortSaveError = true;
    this.isLoading = false;
  }

  loadInboundDocument(from: number, to: number) {
    // if (!this.loadInboundDocumentIsPrevoiusCalled || this.refreshButtonClicked) {
      this.loadInboundDocumentIsPrevoiusCalled = true;
    this.isLoading = true;
    this.firstTotalAPICall = false;
    let pageNo = 1;
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docId, this.from, this.to)
      .subscribe(
        (response) => {
          this.totalPages = response?.files?.length || 0;
          this.overAllPagesBG = response?.totalPagecount || 0;
          this.overAllPages = response?.totalPagecount || 0;

          if (response && this.totalPages > 0) {
            this.TotalDocumentImageList = response.files.map((a) => {
              return {
                ...a,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${a.file}`
                ),
                pageNo: a.pageNo,
              };
            });
            this.selectedExistingDocument = this.TotalDocumentImageList?.[0];
            this.existingDocumentImageList = this.TotalDocumentImageList;
            this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
            this.loadInboundDocumentLoadMore(this.from, this.to);
          }
          this.isLoading = false;
          this.TiffError = false;
          this.SortSaveError = false;
          this.TiffNotLoadingError=false;

          // this.sortingDocView();
          // this.loadOverAllSortingAPI();
        },
        (err) => {
          this.handleErrorResponse(err);
          this.isHideTiffViewer = true;
          if (this.isHideTiffViewer && !this.isEmpty(this.blobName)) {
            this.loadInboundDocumentPDF();
            this.loadOverAllSortingAPI();
          } else {
            console.error('File name  = ' + this.blobName);
          }
          this.TiffNotLoadingError=false;
          if(this.refreshButtonClicked){
            this.refreshButtonClicked = false;
          }
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
    // }
  }

  loadInboundDocumentLoadMore(from: number, to: number) {
    this.isTotalAPIcallGoingOn = true;
    if (this.overAllPagesBG >= this.to) {
      this.from = to + 1;
      this.to = to + this.DEFAULT_LAZY_LOADING;
      if (this.loadInboundDocumentAPICall$) {
        this.loadInboundDocumentAPICall$?.unsubscribe();
      }
      const loadInboundDocument = this.inboundDocumentService
        .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docId, this.from, this.to)
        .subscribe(
          (response) => {
            let totalPagesLoadMore = response.files.length;
            if (response && totalPagesLoadMore > 0) {
              // Assuming response is an array of items to be pushed into the list
              response.files.forEach((item) => {
                const newItem = {
                  ...item,
                  src: this.sanitizer.bypassSecurityTrustResourceUrl(
                    `data:image/tiff;base64, ${item.file}`
                  ),
                  pageNo: item.pageNo,
                };
                this.TotalDocumentImageList.push(newItem);
                item = null;
              });
              this.overAllPages = this.TotalDocumentImageList.length;
              if (this.selectedOption === this.sotingSelectedCSS.Total) {
                this.totalPages = this.TotalDocumentImageList.length;
                this.existingDocumentImageList = this.TotalDocumentImageList;
              }
            }
            this.loadInboundDocumentLoadMore(this.from, this.to);
            this.firstTotalAPICall = true;
          },
          (err) => {
            // this.handleErrorResponse(err);
            this.isTotalAPIcallGoingOn = false;
            this.loadOverAllSortingAPI();
          }
        );
      this.loadInboundDocumentAPICall$ = loadInboundDocument;
    } else {
      this.firstTotalAPICall = true;
      this.isTotalAPIcallGoingOn = false;
      this.loadOverAllSortingAPI();
      // this.checkTotalAPICallCompletion();
    }
  }

  checkValidInput(input: string): boolean {
    // Your validation logic goes here
    // Return true if the input is valid, otherwise return false

    // For example, let's check if the input is a number

    if (input === '0') {
      this.checkValidInputCase = 0;
      // If none of the cases match, return false
      return false;
    } else if (/^[1-9]\d*$/.test(input) && Number(input)) {
      // if (/^\d+$/.test(input) && Number(input)) {
      // Case 1: Single number
      this.checkValidInputCase = 1;
      return true;
    } else if (/^\d+(,\d+)*$/.test(input)) {
      // else if (/^\d+([1-9]\d*)*(,\d+([1-9]\d*)*)*$/.test(input)) {
      // Case 2: Comma-separated list of numbers
      this.checkValidInputCase = 2;
      return true;
    } else if (/^\d+-\d+$/.test(input)) {
      // else if (/^\d+([1-9]\d*)*-(\d+([1-9]\d*)*)*$/.test(input)) {
      // Case 3: Range of numbers without 0 (e.g., 1-5)
      this.checkValidInputCase = 3;
      const [start, end] = input.split('-').map(Number);
      return !isNaN(start) && !isNaN(end) && start <= end;
    } else if (input === '') {
      this.checkValidInputCase = 4;
      return true;
    }

    this.checkValidInputCase = 0;
    // If none of the cases match, return false
    return false;

    // return !isNaN(Number(input));
  }
  check(){
    console.log(this.isDisabled);
    console.log(this.TiffError)
    console.log(this.overAllPagesBG === 0);
    console.log(this.isTotalAPIcallGoingOnCount);
    console.log(!(this.isTotalAPIcallGoingOnCount > 1));
    console.log(this.overAllPages != this.overAllPagesBG);
    console.log(this.overAllPages !== this.SortedDocumentImageList.length+this.PendingDocumentImageList.length);

    // isDisabled || TiffError || document_shortCode === document.shortCode || overAllPagesBG === 0 || !(isTotalAPIcallGoingOnCount > 1) || overAllPages != overAllPagesBG || overAllPages !== SortedDocumentImageList.length+PendingDocumentImageList.length
  }

  async moveDocument(inputValue: any, document: any) {
    if (this.isTotalAPIcallGoingOnCount > 1) {
      // if (
      //   this.overAllPages === this.overAllPagesBG &&
      //   this.overAllPagesBG !== 0
      // ) {
      let len = this.existingDocumentImageList.length;
      // this.isMoved = true;
      this.isSortingStart = true;
      this.isDisabled = true;
      try {
        if (document.shortCode !== SortingShortCode.All) {
          var split = [];
          if (this.checkValidInput(inputValue)) {
            if (this.checkValidInputCase === 1) {
              split.push(Number(inputValue));
            } else if (this.checkValidInputCase === 2) {
              split = inputValue?.split(',');
              split = split.map((a) => Number(a));
            } else if (this.checkValidInputCase === 3) {
              let splitwithHyphen = inputValue?.split('-');
              for (
                var i = Number(splitwithHyphen[0]);
                i <= Number(splitwithHyphen[1]);
                i++
              ) {
                split.push(Number(i));
              }
            } else if (this.checkValidInputCase === 4) {
              split.push(this.pageNumber);
            } else {
              split = [];
            }
            let uniqueArray = [...new Set(split)];
            if (
              uniqueArray.every(
                (num) => num > 0 && num <= this.existingDocumentImageList.length
              )
            ) {
              let PageArray = [];
              for (const x of uniqueArray) {
                let page = this.existingDocumentImageList[x - 1].pageNo;
                this.pageNumber = x + 1;
                PageArray.push(page);
                if (x <= len) {
                  this.pushorUpdateIntoSaveArray(page, document.shortCode);
                } else {
                  this.showNotification(
                    'Please enter valid input - Zero not allowed, or less than zero not allowed, or maximum allowed ' +
                      len
                  );
                }
              }
              for (const p of PageArray) {
                this.UpdateSorting_Pending_BinArray(p, document.shortCode);
              }
              PageArray = [];
              let uploadTiffLoadedIndex = this.documentTypes.findIndex(
                (value) => value.shortCode === this.document_shortCode
              );
              if (uploadTiffLoadedIndex !== -1) {
                this.existingDocumentImageList =
                  this.documentTypes[uploadTiffLoadedIndex].docPageContent;
              }
              this.totalPages = this.existingDocumentImageList.length;
              if (this.pageNumber > this.totalPages) {
                this.pageNumber = this.totalPages;
              }

              if (this.pageNumber === 0) {
                this.TiffError = true;
                this.loadingMessage = 'Images has not found';
                setTimeout(() => {
                  this.clearCanvas();
                }, 500);
              }
              this.sortedPagesBG = this.SortedDocumentImageList.length;
              this.pendingPagesBG = this.PendingDocumentImageList.length;
              if (
                this.selectedOption === sotingSelectedCSS.Sorted ||
                this.selectedOption === sotingSelectedCSS.Total
              ) {
                // this.pageNumber++;
                this.goToPage();
              } else {
                this.goToPage();
              }
            } else {
              this.showNotification(
                'Please enter valid input - Zero not allowed, or less than zero not allowed, or maximum allowed ' +
                  len
              );
            }
            this.arrowButtonClicked = true;
          } else {
            this.toastr.error('Please enter vaild input');
          }
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isDisabled = false;
        this.myInputRefs.forEach((inputRef) => {
          inputRef.nativeElement.value = '';
        });
        // this.cd.detectChanges();
      }
      // } else {
      //   this.showNotification('Please Wait Document is Loading ..... ');
      // }
    } else {
      this.showNotification('Please Wait pages count is loading ..... ');
    }
  }

  pushorUpdateIntoSaveArray(pageNumber: number, shortCode: string) {
    if (
      this.LastSortingPageNo === pageNumber &&
      this.LastSortingShortCode === shortCode
    ) {
      this.showNotification('Already Sorted');
    }
    this.LastSortingPageNo = pageNumber;
    this.LastSortingShortCode = shortCode;
    let documentDetails: SortedDocumentGroupDTO;
    documentDetails = this.documentTypes.find(
      (value) => value.shortCode === shortCode
    );
    let ctDoc = this.existingDocumentImageList.find(
      (value) => value.pageNo === pageNumber
    );

    let existingIndex = this.saveDocumentDetails.findIndex(
      (item) => item.pageNumber === ctDoc.pageNo
    );

    if (existingIndex !== -1) {
      this.saveDocumentDetails[existingIndex].documentTypeId =
        documentDetails.documentTypeId;
      this.saveDocumentDetails[existingIndex].documentType =
        documentDetails.documentType;
    } else {
      let SortDocumentDto: SaveDocumentDto = {
        patientId: this.patientId,
        sortType: SortType.Manual,
        documentTypeId: documentDetails.documentTypeId,
        documentType: documentDetails.documentType,
        pageNumber: ctDoc.pageNo,
        inboundDocumentId: this.docId,
      };
      this.saveDocumentDetails.push(SortDocumentDto);
    }
    // this.UpdateSorting_Pending_BinArray(ctDoc.pageNo, shortCode);
    // this.showNotification('Sorting has been temporarily saved !');
  }

  UpdateSorting_Pending_BinArray(pageNo: number, shortCode: string) {
    let existingPendingIndex = this.PendingDocumentImageList.findIndex(
      (item) => item.pageNo === pageNo
    );
    let removedPendItem = [];
    if (existingPendingIndex !== -1) {
      // Remove item from PendingDocumentImageList and add it to SortedDocumentImageList
      removedPendItem = this.PendingDocumentImageList.splice(
        existingPendingIndex,
        1
      )[0];
      this.SortedDocumentImageList.push(removedPendItem);
      this.sortedPages = this.SortedDocumentImageList.length;
      this.pendingPages = this.PendingDocumentImageList.length;
      let existingBinIndex = this.documentTypes.findIndex(
        (value) => value.shortCode === shortCode
      );
      if (!this.documentTypes[existingBinIndex].docPageContent) {
        this.documentTypes[existingBinIndex].docPageContent = [];
      }
      // Push removedItem into docPageContent and update pageCount
      this.documentTypes[existingBinIndex].docPageContent.push(removedPendItem);
      this.documentTypes[existingBinIndex].pageCount =
        this.documentTypes[existingBinIndex].docPageContent.length;
    }

    let BinCount = 0;
    this.documentTypes.forEach((document) => {
      // Check if the document has a docPageContent array and if it does, remove items based on page number
      if (document.docPageContent && document.docPageContent.length > 0) {
        document.docPageContent.forEach((content, index) => {
          // Check if the page number exists in docPageContent, if so, remove it
          const pageIndex = document.docPageContent.findIndex(
            (content) => content.pageNo === pageNo
          );
          if (pageIndex !== -1) {
            BinCount++;
            // Remove the item from docPageContent
            document.docPageContent.splice(pageIndex, 1);
            // Update the pageCount accordingly
            document.pageCount = document.docPageContent.length;
          }
        });
      }
    });

    if (BinCount !== 0) {
      const findPageinTotal = this.TotalDocumentImageList.find(
        (content) => content.pageNo === pageNo
      );

      let existingBinIndex = this.documentTypes.findIndex(
        (value) => value.shortCode === shortCode
      );
      if (!this.documentTypes[existingBinIndex].docPageContent) {
        this.documentTypes[existingBinIndex].docPageContent = [];
      }
      // Push removedItem into docPageContent and update pageCount
      this.documentTypes[existingBinIndex].docPageContent.push(findPageinTotal);
      this.documentTypes[existingBinIndex].pageCount =
        this.documentTypes[existingBinIndex].docPageContent.length;
    }
  }

  clearCanvas() {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.selectedExistingDocument = null;
    this.existingDocumentImageList = [];
    this.pageNumber = 0;
    this.totalPages = 0;
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'top', // Positioning the notification vertically
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadExistingImage(docDetails: any) {
    this.loadTiffImageV1(docDetails);
    // this.pageNumber = docDetails.pageNo;
  }

  thumbnailSelectedImage(docDetails: any) {
    if (this.mustSaveAnnotations()) {
      this.loadExistingImage(docDetails);
      this.currentArrayIndex = this.pageNumber - 1;
    }
  }

  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel + 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel - 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  previousImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex--;
      if (0 < this.currentArrayIndex) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.firstImage();
      }
    }
  }

  nextImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex++;
      if (this.currentArrayIndex < this.existingDocumentImageList.length) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.lastImage();
      }
    }
  }

  firstImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = 0;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  lastImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = this.existingDocumentImageList.length - 1;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  // Function to navigate to a specific page
  goToPage() {
    if (this.mustSaveAnnotations()) {
      if (this.existingDocumentImageList.length === 0) {
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      } else if (this.pageNumber <= 0) {
        this.pageNumber = 1;
        this.firstImage();
      } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
        this.currentArrayIndex = this.pageNumber - 1;
        this.loadExistingImage(
          this.existingDocumentImageList[this.currentArrayIndex]
        );
      } else {
        this.pageNumber = this.totalPages;
        this.lastImage();
      }
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  mustSaveAnnotations(): boolean {
    if (this.textAnnotations.length > 0) {
      const confirmation = confirm(
        'Do you want to leave/move this page without saving annotation?'
      );
      if (confirmation) {
        this.clrAllAnnotation();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  fnRefreshButtonClicked(){
    this.refreshButtonClicked = true;
    this.loadInboundDocument(1,this.DEFAULT_LAZY_LOADING_API)
  }

  // Function to draw the image on the canvas with the current zoom level
  drawZoomedImage() {
    const docDetails = this.selectedExistingDocument;
    const tiffBase64 = docDetails.file;
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      // Draw image with adjusted dimensions and centered position
      this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
  }

  loadTiffImageV1(docDetails: any) {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
      // const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      // Scroll to the selected image
      const selectedImageElement = document.querySelector(
        '.custom-thumbnail-view-selected'
      );
      if (selectedImageElement) {
        selectedImageElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    };
    img.src = 'data:image/tiff;base64,' + docDetails.file;
    // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.tiffPageNo = docDetails.pageNo || 0;
    this.selectedExistingDocument = docDetails;
    this.TiffError = false;
    this.loadingMessage = '';
  }

  convertCanvasToBase64() {
    const canvas = this.canvasRef.nativeElement;
    return canvas.toDataURL('image/tiff');
  }

  clearAllAnnotation() {
    const confirmation = confirm(
      'Are you sure you want to delete all annotation text?'
    );
    if (confirmation) {
      this.clrAllAnnotation();
      this.zoomLevel = this.zoomLevelReSize;
    }
  }
  rotateClockwise() {
  }
  rotateCounterclockwise() {
    // TODO: Implement rotation logic
    // this.rotateClockwise();
  }

  clrAllAnnotation() {
    this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.textAnnotations = [];
    this.redrawAnnotationsZoom();
  }



  onMouseDown(event: MouseEvent) {
    if (this.TiffError) {
      return;
    }
    if (!this.isLoading) {
      event.preventDefault(); // Prevent default right-click menu
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
      this.isDrawing = true;
      this.isMouseDown = true;
      if (this.annotationModeFlag === this.tiffPointerMode.grab) {
      }else{
      this.savedState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
    }
  }

  // onMouseMove(event: MouseEvent) {
  //   if (this.TiffError) {
  //     return;
  //   }
  //   if (!this.isLoading) {
  //     // event.preventDefault(); // Prevent default right-click menu
  //     if (!this.isDrawing) return;
  //     if (!this.isMouseDown) return;

  //     this.isMouseMove = true;
  //     // Get the canvas and its context
  //     const canvas = this.canvasRef.nativeElement;
  //     this.ctx = canvas.getContext('2d');

  //     // Get the bounding rectangle of the canvas
  //     const rect = canvas.getBoundingClientRect();

  //     // Calculate current mouse position relative to the canvas
  //     const x = event.clientX - rect.left;
  //     const y = event.clientY - rect.top;

  //     // Calculate width and height of the rectangle
  //     const rectWidth = x - this.startX;
  //     const rectHeight = y - this.startY;

  //     if (this.savedState !== null) {
  //       this.ctx.putImageData(this.savedState, 0, 0);
  //     }

  //     // Draw the rectangle for the current move
  //     this.ctx.strokeStyle = this.lineColor;
  //     this.ctx.lineWidth = 3;
  //     this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
  //   }
  // }

  onMouseMoveVVV(event: MouseEvent) { // WithOutRotating
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');

    // Get the bounding rectangle of the canvas
    const rect = canvas.getBoundingClientRect();

    // Calculate current mouse position relative to the canvas
    let clickX = (event.clientX - rect.left) / this.zoomLevel;
    let clickY = (event.clientY - rect.top) / this.zoomLevel;

    this.checkAnnotationMouseOver(clickX, clickY);
    clickX=clickX*this.zoomLevel;
    clickY=clickY*this.zoomLevel;

    if (this.TiffError || this.isLoading) {
      return;
    }

    if (this.annotationModeFlag === this.tiffPointerMode.grab) {
      if (!this.isDrawing) return;

      // // Clear the previous highlight
      // if (this.savedState !== null) {
      //     ctx.putImageData(this.savedState, 0, 0);
      // }

      this.highlightPoint(ctx, clickX, clickY);
    } else if (this.annotationModeFlag === this.tiffPointerMode.crosshair) {
      if (!this.isDrawing || !this.isMouseDown) return;

      this.isMouseMove = true;

      // Calculate width and height of the rectangle
      const rectWidth = clickX - this.startX;
      const rectHeight = clickY - this.startY;

      // Clear the previous rectangle
      if (this.savedState !== null) {
        ctx.putImageData(this.savedState, 0, 0);
      }

      // Draw the rectangle for the current move
      ctx.strokeStyle = this.lineColor;
      ctx.lineWidth = 3;
      ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
    }
  }
  highlightedPoints: { x: number, y: number }[] = [];
  // savedState1: ImageData | null = null;
  actionStack: { type: string, point: { x: number, y: number } }[] = [];
  baseImageState: ImageData | null = null;
  // Add this method to check if a point is already highlighted with some tolerance
  isPointHighlighted(x: number, y: number): boolean {
    const tolerance = 10; // Define a tolerance range
    return this.highlightedPoints.some(point =>
      Math.abs(point.x - x) < tolerance && Math.abs(point.y - y) < tolerance
    );
  }

  highlightPoint(ctx: CanvasRenderingContext2D, x: number, y: number) {
    // Check if the point is already highlighted
    if (this.isPointHighlighted(x, y)) {
      return;
    }

    // Save the base image state if not already saved
    if (this.baseImageState === null) {
      this.baseImageState = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    // // Save the canvas state
    // if (this.savedState === null) {
      // this.savedState = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    // } else {
      // ctx.putImageData(this.savedState, 0, 0);
    // }

    // Draw a semi-transparent circle at the mouse pointer location
    ctx.fillStyle = 'rgba(255, 255, 0, 0.2)'; // Yellow highlight with 30% opacity
    ctx.beginPath();
    ctx.arc(x, y, 10, 0, 2 * Math.PI); // 10 is the radius of the highlight circle
    ctx.fill();

    // Add the point to the list of highlighted points and the action stack
    const point = { x, y };
    this.highlightedPoints.push(point);
    this.actionStack.push({ type: 'highlight', point });

  }

  checkAnnotationMouseOver(clickX, clickY) {
    if (this.textAnnotations.length > 0) {
      for (let i = this.textAnnotations.length - 1; i >= 0; i--) {
        const annotation = this.textAnnotations[i];
        const fontSize = 25; // Assuming fixed font size, adjust as needed
        const textHeight = fontSize; // Adjust as needed for the actual text height

        // Set up the canvas context to measure text width
        const canvas = this.canvasRef.nativeElement;
        const ctx = canvas.getContext('2d');
        ctx.font = `${fontSize}px Arial`;
        const textWidth =
          ctx.measureText(annotation.text).width * this.zoomLevel;

        // Adjust the bounding box to consider the text above the rectangle
        // const left = annotation.x - Math.max(annotation.rectWidth, textWidth);
        const left = annotation.x - annotation.rectWidth;
        // const right = annotation.x + Math.max((annotation.rectWidth* this.zoomLevel), textWidth);
        // const right = annotation.x + textWidth;
        const right = annotation.x + textWidth * 0.9;
        // const right = annotation.x ;
        // const right = annotation.rectWidth + Math.max(annotation.x, textWidth);
        // const right =  annotation.x + Math.max(annotation.rectWidth, textWidth);

        const top = annotation.y - annotation.rectHeight - textHeight; // Include text height above the rectangle
        const bottom = annotation.y - annotation.rectHeight;

        // console.log(`Annotation ${i} boundaries: left=${left}, right=${right}, top=${top}, bottom=${bottom}`);

        if (
          clickX >= left &&
          clickX <= right &&
          clickY >= top &&
          clickY <= bottom
        ) {
          // this.annotationModeFlag = 3;
          this.annotationMode(this.tiffPointerMode.help);
          break;
        } else {
          this.annotationModeFlag=this.annotationModeFlagOld;
          this.annotationMode(this.annotationModeFlag);
          // this.annotationMode(this.tiffPointerMode.crosshair);
        }
      }
    }
    // else {
    //   // this.annotationMode(this.tiffPointerMode.crosshair);
    // }
  }

  // onMouseUp(event: MouseEvent) {
  //   if (this.TiffError) {
  //     return;
  //   }
  //   if (!this.isLoading) {
  //     if (!this.isDrawing) {
  //       return;
  //     }
  //     // Get the canvas and its context
  //     const canvas = this.canvasRef.nativeElement;
  //     this.ctx = canvas.getContext('2d');

  //     // Get the bounding rectangle of the canvas
  //     const rect = canvas.getBoundingClientRect();

  //     // Calculate current mouse position relative to the canvas
  //     const x = event.clientX - rect.left;
  //     const y = event.clientY - rect.top;

  //     // Calculate width and height of the rectangle
  //     const rectWidth = x - this.startX;
  //     const rectHeight = y - this.startY;

  //     // Save the current canvas state
  //     const currentState = this.ctx.getImageData(
  //       0,
  //       0,
  //       canvas.width,
  //       canvas.height
  //     );

  //     if (this.savedState !== null) {
  //       // Redraw the previously saved canvas state
  //       this.ctx.putImageData(this.savedState, 0, 0);
  //     }

  //     // Draw the rectangle for the current move
  //     this.ctx.strokeStyle = this.lineColor;
  //     this.ctx.lineWidth = 3;
  //     this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
  //     this.rectWidth = rectWidth;
  //     this.rectHeight = rectHeight;

  //     // Save the changes for the current move
  //     this.savedState = this.ctx.getImageData(
  //       0,
  //       0,
  //       canvas.width,
  //       canvas.height
  //     );
  //     this.isDrawing = false;
  //   }
  //   this.isDrawing = false;
  //   // this.isMouseMove = false;
  // }

  onMouseUpVVV(event: MouseEvent) { // WithOutRotate
    if (this.TiffError) {
      return;
    }
    if (!this.isLoading) {
      if (!this.isDrawing) {
        return;
      }
      if (this.annotationModeFlag === this.tiffPointerMode.grab) {
      }else{
      // Get the canvas and its context
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');

      // Get the bounding rectangle of the canvas
      const rect = canvas.getBoundingClientRect();

      // Calculate current mouse position relative to the canvas
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Calculate width and height of the rectangle
      const rectWidth = x - this.startX;
      const rectHeight = y - this.startY;

      // Save the current canvas state
      const currentState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );

      if (this.savedState !== null) {
        // Redraw the previously saved canvas state
        this.ctx.putImageData(this.savedState, 0, 0);
      }

      // Draw the rectangle for the current move
      this.ctx.strokeStyle = this.lineColor;
      this.ctx.lineWidth = 3;
      this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
      this.rectWidth = rectWidth;
      this.rectHeight = rectHeight;


        // Save the changes for the current move
        this.savedState = this.ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
      this.isDrawing = false;
    }
    this.isDrawing = false;
    // this.isMouseMove = false;
  }

  onMouseLeave(event: MouseEvent) {
    if (this.TiffError) {
      return;
    }
    if (!this.isDrawing) return;
    if (!this.isLoading && !this.isMouseDown && !this.isMouseMove) {
      this.isDrawing = false;
      return;
    } else {
      // this.onMouseUp(event);
      this.onMouseUpVVV(event);
      this.addText(event);
    }
    this.isDrawing = false;
  }

  deleteText(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    // Find the text annotation closest to the clicked position
    const indexToDelete = this.textAnnotations.findIndex((annotation) => {
      const textWidth = this.ctx.measureText(annotation.text).width;
      return (
        clickX >= annotation.x - annotation.rectWidth &&
        clickX <= annotation.x - annotation.rectWidth + textWidth &&
        clickY >= annotation.y - annotation.rectHeight - 10 - 14 &&
        clickY <= annotation.y - annotation.rectHeight - 10
      ); // 14 is font size
    });

    if (indexToDelete !== -1) {
      // Ask for confirmation
      const confirmation = confirm(
        'Are you sure you want to delete this text?'
      );

      if (confirmation) {
        this.textAnnotations.splice(indexToDelete, 1);
        this.redrawAnnotationsZoom();
      }
    }
  }

  addText(event: MouseEvent) {
    if(this.annotationModeFlag === this.tiffPointerMode.help){
      this.deleteTextV4(event);
    }
    if (this.TiffError) {
      return;
    }
    this.isDrawing = false;
    if (!this.isLoading && this.isMouseMove) {
      this.isMouseMove = false;
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      const textX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
      const textY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

      let text = prompt('Enter text:');
      if (text === '' || text) {
        text = text.trim();
        if (text === '') {
          text = '.';
        }
        // Create a new TextAnnotation object and add it to the textAnnotations array
        const newAnnotation: TextAnnotation = {
          text: this.isEmpty(text) ? '.' : text,
          x: textX,
          y: textY,
          rectWidth: this.rectWidth / this.zoomLevel,
          rectHeight: this.rectHeight / this.zoomLevel,
        };
        this.textAnnotations.push(newAnnotation);
      }
      // Redraw all annotations
      this.redrawAnnotationsZoom();
    }
  }

  saveAnnotation() {
    this.loaderService.showLoader();
    this.clrAllZoomForSave();
    this.disableAnnotation = true;
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall.unsubscribe();
    }

    try {
      setTimeout(() => {
        const base64Content = this.convertCanvasToBase64().split(',')[1];
        const annotatedDTO: AnnotatedDTO = {
          file: base64Content,
          pageNo: this.pageNumber,
          id: this.docId,
        };

        this.saveAnnotationAPICall = this.inboundDocumentService
          .addAnnotationByDocumentIdByDto(annotatedDTO)
          .subscribe(
            (response) => {
              this.existingDocumentImageList[this.currentArrayIndex].file =
                base64Content;
              this.clrAllAnnotation();
              this.goToPage();
              this.zoomLevel = this.zoomLevelReSize;
              this.toastr.success(response, 'Success');
              this.disableAnnotation = false;
              this.loaderService.hideLoader();
            },
            (error) => {
              this.handleError(error);
            }
          );
      }, 1000);
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleError(error: any) {
    const data: HttpErrorResponse = error;
    this.toastr.error(data?.error?.error?.message, 'Error');
    this.disableAnnotation = true;
    this.loaderService.hideLoader();
  }

  clrAllZoomForSave() {
    // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.zoomLevel = 1;
    this.redrawAnnotationsZoom();
  }

  redrawAnnotationsZoom() {
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        // Calculate the new image dimensions based on the zoom level
        let imageWidth = Math.round(img.width * this.zoomLevel);
        let imageHeight = Math.round(img.height * this.zoomLevel);

        // Adjust image dimensions based on previous zoom level
        if (this.prevZoomLevel && this.zoomLevel < this.prevZoomLevel) {
          const reductionFactor = 5; // Adjust this value as needed
          const reductionAmount = Math.round(
            reductionFactor * (this.prevZoomLevel - this.zoomLevel)
          );
          imageWidth -= reductionAmount;
          imageHeight -= reductionAmount;
        }

        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        this.textAnnotations.forEach((annotation) => {
          const fontSize = Math.round(25 * this.zoomLevel);
          this.ctx.font = `${fontSize}px Arial`;

          const adjustedX = Math.round(annotation.x * this.zoomLevel);
          const adjustedY = Math.round(annotation.y * this.zoomLevel);

          this.ctx.fillStyle = this.textColor;
          this.ctx.fillText(
            annotation.text,
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY -
              Math.round((annotation.rectHeight + 10) * this.zoomLevel)
          );
          this.ctx.strokeStyle = this.lineColor;
          this.ctx.lineWidth = 3;
          this.ctx.strokeRect(
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY - Math.round(annotation.rectHeight * this.zoomLevel),
            Math.round(annotation.rectWidth * this.zoomLevel),
            Math.round(annotation.rectHeight * this.zoomLevel)
          );

          this.savedState = this.ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
        });

        // Save the current zoom level for the next redraw
        this.prevZoomLevel = this.zoomLevel;
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }

  loadInboundDocumentPDF() {
    this.showPdfViwer = false;
    this.inboundDocumentService
      // .downloadInboundDocumentByBlobName(this.data.blobName)
      .downloadInboundDocumentByBlobName(this.blobName)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strPdfString = String(response);
          }
          this.showPdfViwer = true;
        },
        (err) => {
          this.strPdfString = '';
          this.showPdfViwer = false;

          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }
  saveDocumentBin() {
    this.DocumentBinSaveBtn = true;
    let text = this.mstDocumentBinForm.value.txtBinName.trim();
    let documentBin: CreateUpdateDocumentBinDTO = {
      documentType: text.trim(),
      isDefault: false,
      TenantId: localStorage.getItem('tenantId').trim() ?? defaultGuid,
    };

    this.documentBinService.create(documentBin).subscribe(
      (response) => {
        this.toastr.success('Bin Name Saved Successfully');
        this.mstDocumentBinForm.reset();
        // this.loadOverAllSortingAPI();

        const loadInboundDocument = this.inboundDocumentService
          .getSortedDocumentPagesv2ByGDocumentId(this.docId)
          .subscribe((response) => {
            response?.ltDocuments.forEach((element) => {
              let existingBinIndex = this.documentTypes.findIndex(
                (value) => value.documentTypeId === element.documentTypeId
              );
              if (existingBinIndex === -1) {
                // console.log(element);
                element.docPageContent=[];
                this.documentTypes.push(element);
              }
            });
            this.documentTypes.sort((a, b) =>
              a.documentType.localeCompare(b.documentType)
            );
          });
        this.DocumentBinSaveBtn = false;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
  }

  annotationMode(flag: number) {
    this.annotationModeFlag = flag;
    switch (flag) {
      case tiffPointerMode.crosshair:
        this.cursorStyle = 'crosshair';
        this.annotationModeFlagOld = flag;
        break;
      case tiffPointerMode.text:
        this.cursorStyle = 'text';
        this.annotationModeFlagOld = flag;
        break;
      case tiffPointerMode.help:
        this.cursorStyle = 'help';
        // this.annotationModeFlagOld = this.annotationModeFlagOld;
        break;
      case tiffPointerMode.grab:
        this.cursorStyle = 'grab';
        this.annotationModeFlagOld = flag;
        break;
      case tiffPointerMode.none:
        this.cursorStyle = 'none';
        this.annotationModeFlagOld = flag;
        // this.cursorStyleCSS = 'circularcursor';
        break;
      default:
        this.cursorStyle = 'crosshair'; //'default';
        this.annotationModeFlagOld = flag;
        break;
    }
  }

  deleteTextV4(event: MouseEvent) { // WithOutRotate
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const clickX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
    const clickY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

    // console.log(`Click coordinates: (${clickX}, ${clickY})`);

    // for (let i = 0; i < this.textAnnotations.length; i++) {
    // Loop in reverse
    for (let i = this.textAnnotations.length - 1; i >= 0; i--) {
      const annotation = this.textAnnotations[i];
      const fontSize = 25; // Assuming fixed font size, adjust as needed
      const textHeight = fontSize; // Adjust as needed for the actual text height

      // Set up the canvas context to measure text width
      const canvas = this.canvasRef.nativeElement;
      const ctx = canvas.getContext('2d');
      ctx.font = `${fontSize}px Arial`;
      const textWidth = ctx.measureText(annotation.text).width * this.zoomLevel;

      // Adjust the bounding box to consider the text above the rectangle
      // const left = annotation.x - Math.max(annotation.rectWidth, textWidth);
      const left = annotation.x - annotation.rectWidth;
      // const right = annotation.x + Math.max((annotation.rectWidth* this.zoomLevel), textWidth);
      // const right = annotation.x + textWidth;
      const right = annotation.x + textWidth * 0.9;
      // const right = annotation.x ;
      // const right = annotation.rectWidth + Math.max(annotation.x, textWidth);
      // const right =  annotation.x + Math.max(annotation.rectWidth, textWidth);

      const top = annotation.y - annotation.rectHeight - textHeight; // Include text height above the rectangle
      const bottom = annotation.y - annotation.rectHeight;

      // console.log(`Annotation ${i} boundaries: left=${left}, right=${right}, top=${top}, bottom=${bottom}`);
      // console.log(`Annotation ${i} x >= left=>${clickX} >= ${left}, x <= right =>${clickX} <= ${right}`);

      if (
        clickX >= left &&
        clickX <= right &&
        clickY >= top &&
        clickY <= bottom
      ) {
        // console.log(`Deleting annotation at index ${i}: ${annotation.text}`);
        // console.log('textWidth: ', textWidth);
        // console.log('annotation.rectWidth: ', annotation.rectWidth);
        // console.log('Math.max(annotation.rectWidth, textWidth): ', Math.max(annotation.rectWidth, textWidth));
        // this.textAnnotations.splice(i, 1); // Remove the annotation
        // this.redrawAnnotationsZoom(); // Redraw all annotations

        Swal.fire({
          title: 'Are you sure you want to delete this text?',
          text: 'This Annotation will be removed!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.value) {
            this.textAnnotations.splice(i, 1);
            this.redrawAnnotationsZoom();
          }
        });

        break;
      } else {
        console.log('Annotation Else');
      }
    }
    this.annotationMode(this.tiffPointerMode.crosshair);
    // this.annotationModeFlag=this.annotationModeFlagOld;
  }

  //Check Add Document Bin Uniqnes
  checkDocumentBinUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const gid = this.documentBinId ? this.documentBinId : null;
      const input: ValidateDocumentBinDTO = {
        id: gid,
        documentType:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
      };
      return this.documentBinService.addDocumentBinCheckByValidate(input).pipe(
        tap((x) => control?.setErrors({ isDocumentBinExists: true })),
        debounceTime(300),
        map((response) =>
          response === true
            ? { isDocumentBinExists: true }
            : control?.setErrors(null) == null
            ? null
            : null
        )
      );
    };
  }
}
