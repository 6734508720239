<div class="col-12">
    <div class="d-flex align-item-center justify-content-between m-1">
        <h3 class="font-weight-bolder mb-0"><b>Patient Care Delivery Ticket</b></h3>
        <div class=" page-title-box  page-title-right pt-0">
            <ol class="breadcrumbs m-0">
                <li>
                    <button matTooltip="Click To Goback to Pack View" mat-button class="buttonColor font-size-10"
                        (click)="backToPack()">
                        <i class="fa fa-backward mr-1" ></i> Back To View
                    </button>
                </li>
                <li class="ml-2 mt-2 breadcrumb-item">Packed List</li>
                <li class="mt-2 breadcrumb-item">Patient Care Delivery Ticket</li>
            </ol>
        </div>
    </div>
</div>
<!-- <div class="card card-body"> -->
<div class="row">
    <div class="col-2">
    </div>
    <div class="col-8">
        <mat-card>

            <div class="row">
                <div class=" col-12 mb-2 customThemeClass">
                    <h2 class="text-align-center">
                        <b>Patient Care Delivery Ticket - </b>
                        <b style="color: lightseagreen;"> {{saleOrderId}} </b>
                    </h2>
                </div>
            </div>



            <form [formGroup]="shippingTicketForm">
                <div class="row">
                    <div class="col-7">
                        <div class="row ml-2">
                            <b>{{patientName}}</b>

                        </div>
                        <div class="row ml-2">
                            <b>{{dob}}</b>

                        </div>

                        <div class="row ml-2">
                            <b> {{Address}} </b>
                        </div>
                        <div class="row ml-2">
                            <b>{{insurance}}</b>
                        </div>
                    </div>
                    <div class="col-5">

                        <div class="image-container">
                            <img class="profile-image" style="margin-top: -16px;"
                             [src]="fileAs64Value" title="Profile" alt="">
                            <div class="change-image-icon-container">
                              <!-- <a class="image-icon-container" matTooltip="Change Logo" (click)="fileInput.click()"
                                aria-label="Change profile picture." target="_top">
                                <svg class="icon-container" enable-background="new 0 0 24 24" focusable="false" height="22"
                                  viewBox="0 0 24 24" width="22">
                                  <path style="fill: #9c9c9c"
                                    d="M20 5h-3.17L15 3H9L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 14H4V7h16v12zM12 9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z">
                                  </path>
                                </svg>
                              </a> -->
                            </div>
                          </div>

                    </div>
                </div>

                <hr>

                <div class="row">
                    <mat-form-field class="col-4">
                        <mat-label>Patient Id</mat-label>
                        <input autocomplete="new-txtsaleOrderId" readonly matInput maxlength="40" formControlName="txtsaleOrderId" type="text" />
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>Ticket Id</mat-label>
                        <input autocomplete="new-txtDateOfBirth" readonly matInput maxlength="40" formControlName="txtDateOfBirth" type="text" />
                    </mat-form-field>

                    <!-- <mat-form-field class="col-4">
                            <mat-label >Date Of Birth </mat-label>
                            <input readonly formControlName="txtDateOfBirth" matInput maxlength="40"
                                placeholder="MM/DD/YYYY" [matDatepicker]="txtDateOfBirth">
                            <mat-datepicker-toggle matSuffix [for]="txtDateOfBirth">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtDateOfBirth></mat-datepicker>
                        </mat-form-field> -->
                    <mat-form-field class="col-4">
                        <mat-label> Email Address</mat-label>
                        <input autocomplete="new-txtBillingEmailAddress" readonly formControlName="txtBillingEmailAddress" matInput
                           pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                            [textMask]="{ mask: emailMask }" maxlength="40" type="text" />
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-4">
                        <mat-label>Mobile Number </mat-label>
                        <input autocomplete="new-txtLandLine" readonly matInput prefix="+1-" mask="(000)-000-0000" maxlength="40"
                            placeholder="Enter Landline" formControlName="txtLandLine" type="text">
                    </mat-form-field>
                    <mat-form-field class="col-4">
                        <mat-label>Phone Number</mat-label>
                        <input autocomplete="new-txtPhoneNo" readonly matInput prefix="+1-" mask="(000)-000-0000" maxlength="40"
                            placeholder="Enter Phone Number" formControlName="txtPhoneNo" type="text">
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label> Account Number</mat-label>
                        <input autocomplete="new-txtAccountNumber" readonly matInput maxlength="40" formControlName="txtAccountNumber" type="text" />
                    </mat-form-field>
                </div>
                <hr>

                <div class="row">
                    <div class=" col-12 mb-2 customThemeClass">
                        <ng-container>
                            <b>Pressure Settings : </b>
                        </ng-container>
                    </div>
                </div>

                <div class="row">
                    <mat-form-field class="col-2">
                        <mat-label>Min Press</mat-label>
                        <input autocomplete="new-txtMinPress" matInput maxlength="40" formControlName="txtMinPress" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtMinPress')?.touched &&
                        shippingTicketForm?.get('txtMinPress')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label>Max Press</mat-label>
                        <input autocomplete="new-txtMaxPress" matInput maxlength="40" formControlName="txtMaxPress" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtMaxPress')?.touched &&
                        shippingTicketForm?.get('txtMaxPress')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label>Setting </mat-label>
                        <input autocomplete="new-txtSetting" formControlName="txtSetting" matInput maxlength="40">
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtSetting')?.touched &&
                        shippingTicketForm?.get('txtSetting')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label>IPAP </mat-label>
                        <input autocomplete="new-txtIPAP" matInput maxlength="40" placeholder="Enter Landline" formControlName="txtIPAP"
                            type="text">
                            <mat-error *ngIf="
                            shippingTicketForm?.get('txtIPAP')?.touched &&
                            shippingTicketForm?.get('txtIPAP')?.errors
                          ">
                            Enter Minimun 5 Digits
                          </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label>EPAP</mat-label>
                        <input autocomplete="new-txtEPAP" matInput maxlength="40" placeholder="Enter Phone Number" formControlName="txtEPAP"
                            type="text">
                            <mat-error *ngIf="
                            shippingTicketForm?.get('txtEPAP')?.touched &&
                            shippingTicketForm?.get('txtEPAP')?.errors
                          ">
                            Enter Minimun 5 Digits
                          </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label>Ramp Press</mat-label>
                        <input autocomplete="new-txtRampPress" formControlName="txtRampPress" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtRampPress')?.touched &&
                        shippingTicketForm?.get('txtRampPress')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label> Ramp Time</mat-label>
                        <input autocomplete="new-txtRampTime" formControlName="txtRampTime" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtRampTime')?.touched &&
                        shippingTicketForm?.get('txtRampTime')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label> BI Flex</mat-label>
                        <input autocomplete="new-txtBIFlex" formControlName="txtBIFlex" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtBIFlex')?.touched &&
                        shippingTicketForm?.get('txtBIFlex')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-2">
                        <mat-label>Set Press</mat-label>
                        <input autocomplete="new-txtSetPress" formControlName="txtSetPress" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtSetPress')?.touched &&
                        shippingTicketForm?.get('txtSetPress')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label> CF ICX</mat-label>
                        <input autocomplete="new-txtCFICX" formControlName="txtCFICX" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtCFICX')?.touched &&
                        shippingTicketForm?.get('txtCFICX')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label> RR BPM</mat-label>
                        <input autocomplete="new-txtRRBPM" formControlName="txtRRBPM" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtRRBPM')?.touched &&
                        shippingTicketForm?.get('txtRRBPM')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-2">
                        <mat-label>IPAP Min</mat-label>
                        <input autocomplete="new-txtIPAPMin" formControlName="txtIPAPMin" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtIPAPMin')?.touched &&
                        shippingTicketForm?.get('txtIPAPMin')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label>IPAP Max</mat-label>
                        <input autocomplete="new-txtIPAPMax" formControlName="txtIPAPMax" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtIPAPMax')?.touched &&
                        shippingTicketForm?.get('txtIPAPMax')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label> Set VI</mat-label>
                        <input autocomplete="new-txtSetVI" formControlName="txtSetVI" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtSetVI')?.touched &&
                        shippingTicketForm?.get('txtSetVI')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                        <mat-label> Rate</mat-label>
                        <input autocomplete="new-txtRate" formControlName="txtRate" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtRate')?.touched &&
                        shippingTicketForm?.get('txtRate')?.errors
                      ">
                        Enter Minimun 5 Digits
                      </mat-error>
                    </mat-form-field>

                </div>
                <hr>
                <div class="row">
                    <mat-form-field class="col-4">
                        <mat-label>Technician </mat-label>
                        <input autocomplete="new-txtTechnician" readonly matInput  formControlName="txtTechnician" type="text" />
                    </mat-form-field>
                    <mat-form-field class="col-4">
                        <mat-label> Physician</mat-label>
                        <input autocomplete="new-txtPhysician" readonly matInput  formControlName="txtPhysician" type="text" />
                    </mat-form-field>
                    <mat-form-field class="col-4">
                        <mat-label> Referal Practice</mat-label>
                        <input autocomplete="new-txtReferalPractice" readonly matInput formControlName="txtReferalPractice" type="text" />
                    </mat-form-field>
                </div>




                <div class="row ml-2 mr-2">
                    <div class="col-12">
                        <div class="row pt-1">
                            <table style="width:100%">
                                <tr>
                                    <td> <b>Product Name</b></td>
                                    <td><b>Brand Name</b></td>
                                    <td><b>Category Name</b></td>
                                    <td><b>Units</b></td>
                                    <td><b>Price</b></td>
                                    <!-- <td><b>Option</b></td> -->
                                </tr>
                                <tr *ngFor="let item of itemTable">
                                    <td> {{item.productName}}</td>
                                    <td> {{item.brandName}}</td>
                                    <td> {{item.categoryName}}</td>
                                    <td> {{item.qty}}</td>
                                    <td> $ {{item.price}}</td>
                                    <!-- <td>
                                            <a matTooltip="Click To View Provider">
                                                <i class="fa fa-eye pointer editBtnColor pl-3" ></i>
                                            </a>
                                        </td> -->
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Est. Allowable </mat-label>
                        <input autocomplete="new-txtEstAllowable" matInput maxlength="10" formControlName="txtEstAllowable" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtEstAllowable')?.touched &&
                        shippingTicketForm?.get('txtEstAllowable')?.errors
                      ">
                        Enter Minimun 10 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                        <mat-label> Total Accesories</mat-label>
                        <input autocomplete="new-txtTotalAccesories" matInput maxlength="10" formControlName="txtTotalAccesories" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtTotalAccesories')?.touched &&
                        shippingTicketForm?.get('txtTotalAccesories')?.errors
                      ">
                        Enter Minimun 10 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                        <mat-label> Shipping Fee</mat-label>
                        <input autocomplete="new-txtShippingFee" matInput maxlength="10" formControlName="txtShippingFee" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtShippingFee')?.touched &&
                        shippingTicketForm?.get('txtShippingFee')?.errors
                      ">
                        Enter Minimun 10 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                        <mat-label>Amount Paid </mat-label>
                        <input autocomplete="new-txtAmountPadi" matInput maxlength="10" formControlName="txtAmountPadi" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtAmountPadi')?.touched &&
                        shippingTicketForm?.get('txtAmountPadi')?.errors
                      ">
                        Enter Minimun 10 Digits
                      </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Sunrise Balance </mat-label>
                        <input autocomplete="new-txtSunrise" matInput maxlength="10" formControlName="txtSunrise" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtSunrise')?.touched &&
                        shippingTicketForm?.get('txtSunrise')?.errors
                      ">
                        Enter Minimun 10 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                        <mat-label> EST Amount Due </mat-label>
                        <input autocomplete="new-txtDue" matInput maxlength="10" formControlName="txtDue" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtDue')?.touched &&
                        shippingTicketForm?.get('txtDue')?.errors
                      ">
                        Enter Minimun 10 Digits
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> Balance </mat-label>
                        <input autocomplete="new-txtBalance" matInput maxlength="10" formControlName="txtBalance" type="text" />
                        <mat-error *ngIf="
                        shippingTicketForm?.get('txtBalance')?.touched &&
                        shippingTicketForm?.get('txtBalance')?.errors
                      ">
                        Enter Minimun 10 Digits
                      </mat-error>
                    </mat-form-field>
                </div>
                <hr>
                <div class="row">
                    <div class=" col-12 mb-2 customThemeClass">
                        <ng-container>
                            <h3>
                                <b>Comments to Patient </b>
                            </h3>
                        </ng-container>
                    </div>
                </div>

                <div [hidden]="true" class="col-12 text-lg-right">
                    <mat-form-field class="ml-2">
                        <mat-label>Serial No</mat-label>
                        <input autocomplete="new-txtserialNo" matInput maxlength="40" formControlName="txtserialNo" type="text" />
                    </mat-form-field>
                </div>

                <mat-form-field class="col-12">
                    <mat-label>Comments </mat-label>
                    <textarea autocomplete="new-txtComments" matInput formControlName="txtComments" maxlength="50" type="text"></textarea>

                </mat-form-field>
                <hr>
                <div class="row">
                    <!-- <div class="col-6">
                            <label   for="a">Customer Service :</label>
                            <input class="ml-1" formControlName="txtService" id="a" type="text">
                        </div> -->

                    <mat-form-field class="col-6">
                        <mat-label>Customer Service </mat-label>
                        <input autocomplete="new-txtService" matInput formControlName="txtService" maxlength="60" type="text">

                    </mat-form-field>

                    <!-- <div class="col-6">
                            <label   for="b">Toll Free Number :</label>
                            <input class="ml-1" formControlName="txtTollFreeNum" id="b" type="text">
                        </div> -->

                    <mat-form-field class="col-6">
                        <mat-label>Toll Free Number </mat-label>
                        <input autocomplete="new-txtTollFreeNum" matInput formControlName="txtTollFreeNum" maxlength="10" type="text">

                    </mat-form-field>
                </div>
                <hr>
                <div class="row">
                    <!-- <div class="col-6 ">
                            <label   for="c">Patient Signature :</label>
                            <input class="ml-1" formControlName="txtSignature" id="c" type="text">
                        </div> -->
                    <mat-form-field class="col-6">
                        <mat-label>Patient Signature </mat-label>
                        <input autocomplete="new-txtSignature" matInput formControlName="txtSignature" maxlength="60" type="text">

                    </mat-form-field>


                    <!-- <div class="col-6" style="padding-left: 102px;">
                            <label   for="d">Date :</label>
                            <input class="ml-1" formControlName="txtDate" id="d" type="text">
                        </div> -->
                    <mat-form-field class="col-6">
                        <mat-label>Date </mat-label>
                        <!-- <input matInput formControlName="txtDate" maxlength="60" type="text"> -->
                        <input autocomplete="new-txtDate" matInput formControlName="txtDate" maxlength="40" placeholder="(MM/DD/YYYY)"
                            [formControl]="shippingTicketForm.controls.txtDate" [matDatepicker]="myPicker">
                        <mat-datepicker-toggle matSuffix [for]="myPicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #myPicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </form>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-10">
                        </div>
                        <div class="col-sm-2">
                            <button class="mt-2" type="submit" (click)="submit()" mat-button
                            [disabled]="!(!shippingTicketForm.invalid  && shippingTicketForm.dirty)"  class="buttonColor mb-2 mr-2">Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card>
    </div>
</div>

<div class="col-2">
</div>
<!-- </div> -->
