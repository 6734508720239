import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';

@Component({
  selector: 'app-rental-monthly-calculation',
  templateUrl: './rental-monthly-calculation.component.html',
  styleUrls: ['./rental-monthly-calculation.component.scss'],
})
export class RentalMonthlyCalculationComponent implements OnInit {
  isLoaded: boolean = true;
  rentalTableData: any;
  dtRentalTblOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '400px',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    columnDefs: [
      { targets: [0], visible: false },
      { orderable: true, targets: '_all' },
    ],
  };
  balanceAmount: any;
  monthlyAmounts: string;
  nextTwelveMonths: any;
  constructor(
    public dialog: MatDialog,
    private mmOrderService: MmOrderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      balanceAmount: number;
      monthlyAmount: string;
      totalDate: [];
      orderId: string;
      orderDate: string;
      months: number;
    } = {
      balanceAmount: 0,
      monthlyAmount: '',
      totalDate: [],
      orderId: defaultGuid,
      orderDate: '',
      months: 0,
    }
  ) {}

  ngOnInit(): void {
    this.balanceAmount = this.data.balanceAmount;
    this.loadMonthlyDetails();
  }
  loadMonthlyDetails() {
    this.isLoaded = false;
    this.mmOrderService
      .getRentalOrderAmountByGOrderAndMonthAndAmountAndDtOrderDate(
        this.data.orderId,
        this.data.months,
        this.data.balanceAmount,
        this.data.orderDate
      )
      .subscribe(
        (response) => {
          this.rentalTableData = response;
          this.isLoaded = true;
        },
        (err) => {
          this.isLoaded = true;
        }
      );
  }
}
