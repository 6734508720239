import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { Reminder, RemindersService, ReminderType } from './reminders.service';

@Component({
  selector: 'app-reminder-message',
  templateUrl: './reminder-message.component.html',
  styleUrls: ['./reminder-message.component.scss']
})
export class ReminderMessageComponent implements OnInit, OnDestroy {
  reminders: Reminder[] = [];
  private _subscription: Subscription;
  organizationUnitId = null;
  subscription$: Subscription[] = [];
  constructor(
    private tableService: TableService,
    private reminderService: RemindersService,
    //  private snackBar: MatSnackBar,
    // @Inject(MAT_SNACK_BAR_DATA) public data: { patientName?: string, subject?: string }
  ) {

  }

  patientName: string = "";
  reminderSubject: string = "";
  loginUserName: string = "";

  ngOnInit(): void {
    const reminder = this._subscription = this.reminderService.getObservable().subscribe(reminder => {
      this._addReminder(reminder)
    });
    this.subscription$.push(reminder);
    //

    const getLoginUser = this.tableService.getLoginUserName().subscribe(val => {
      this.loginUserName = val;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getLoginUser);
    // this.patientName = this.data.patientName;
    // this.reminderSubject = this.data.subject;

  }



  close() {
    //  this.snackBar.dismiss();
  }



  closeReminder(reminder: Reminder) {
    this.reminders = this.reminders.filter(rem => rem.id !== reminder.id);
  }

  private _addReminder(reminder: Reminder) {
    this.reminders.push(reminder);
    if (reminder.timeout !== 0) {
      setTimeout(() => this.closeReminder(reminder), reminder.timeout);
    }
  }
  ngOnDestroy() {
    this._subscription.unsubscribe();
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  className(reminder: Reminder): string {
    let style: string;
    switch (reminder.type) {
      case ReminderType.success: style = 'success'; break;
      case ReminderType.warning: style = 'warning'; break;
      case ReminderType.error: style = 'error'; break;
      default: style = 'info'; break;
    }
    return style;
  }
}

