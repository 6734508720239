import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { CCSettlementService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/ccsettlement.service';
import { ClaimCCSettlementListDTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { CCSettlementTab } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/medicare-check-list-status.enum';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cc-settlement-tab',
  templateUrl: './cc-settlement-tab.component.html',
  styleUrls: ['./cc-settlement-tab.component.scss'],
})
export class CcSettlementTabComponent implements OnInit {
  CCSettlementTab = CCSettlementTab;
  activeTab: CCSettlementTab = this.CCSettlementTab.Pending;
  settlementForm: FormGroup;
  dOrderFromDate: Date;
  dOrderToDate: Date;
  isLoadCCTableData: boolean = false; 
  pendingSelectedCount: number = 0;
  CCSettlementArray: ClaimCCSettlementListDTO[];
  private loadCCCTableDataAPICall: Subscription;
  private loadCCPTableDataAPICall: Subscription;
  
  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private ccSettlementService: CCSettlementService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Qsecure | CC Settlement');
    this.dOrderFromDate = this.getCTStartOfMonth();
    this.dOrderToDate = this.ctDateNow();
    this.settlementForm = this.formBuilder.group({
      txtStartDateValue: new FormControl('', [Validators.required]),
      txtEndDateValue: new FormControl('', [Validators.required]),
      fileUpload: new FormControl(''),
    });
    this.getTableData();
  }

  ngOnDestroy(): void {
    if (this.loadCCCTableDataAPICall) {
      this.loadCCCTableDataAPICall.unsubscribe();
    }
    if (this.loadCCPTableDataAPICall) {
      this.loadCCPTableDataAPICall.unsubscribe();
    }
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    return dateObj;
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  changeTab(tab: number) {
    // Check if the clicked tab is already active
    if (this.activeTab === tab) {
      return; // Exit the function if the clicked tab is already active
    }
    this.activeTab = tab;
    this.getTableData();
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.settlementForm.value.txtStartDateValue &&
      this.settlementForm.value.txtEndDateValue
    ) {
      this.getTableData();
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  getTableData() {
    if (this.activeTab === this.CCSettlementTab.Pending) {
      this.loadCCPTableData();      
    } else if (this.activeTab === this.CCSettlementTab.Completed) {
      this.loadCCCTableData()
    }else{
      this.toastr.warning('Active tab has not matched','Warning');
    }
  }

  loadCCCTableData() {
    this.isLoadCCTableData = true;
    try {
      if (this.loadCCCTableDataAPICall) {
        this.loadCCCTableDataAPICall.unsubscribe();
      }

      let fromDate: string = this.dOrderFromDate
          ? this.dOrderFromDate.toString()
          : null,
        toDate: string = this.dOrderToDate
          ? this.dOrderToDate.toString()
          : null;
      if (fromDate) {
        fromDate = this.convert(fromDate).toString();
      } else {
        fromDate = null;
      }
      if (toDate) {
        toDate = this.convert(toDate).toString();
      } else {
        toDate = null;
      }

      this.loadCCCTableDataAPICall = this.ccSettlementService
        .getCompletedCCSettlementDetailsByDtFromDateAndDtToDate(
          fromDate,
          toDate
        )
        .subscribe(
          (response) => {
            this.CCSettlementArray = response;
            setTimeout(() => {
              this.isLoadCCTableData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            setTimeout(() => {
              this.isLoadCCTableData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadCCTableData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  loadCCPTableData() {
    this.isLoadCCTableData = true;
    try {
      if (this.loadCCPTableDataAPICall) {
        this.loadCCPTableDataAPICall.unsubscribe();
      }

      let fromDate: string = this.dOrderFromDate
          ? this.dOrderFromDate.toString()
          : null,
        toDate: string = this.dOrderToDate
          ? this.dOrderToDate.toString()
          : null;
      if (fromDate) {
        fromDate = this.convert(fromDate).toString();
      } else {
        fromDate = null;
      }
      if (toDate) {
        toDate = this.convert(toDate).toString();
      } else {
        toDate = null;
      }

      this.loadCCPTableDataAPICall = this.ccSettlementService
        .getCompletedCCSettlementDetailsByDtFromDateAndDtToDate(
          fromDate,
          toDate
        )
        .subscribe(
          (response) => {
            this.CCSettlementArray = response;
            setTimeout(() => {
              this.isLoadCCTableData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            setTimeout(() => {
              this.isLoadCCTableData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadCCTableData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }
}
