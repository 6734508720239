<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <ng-container *ngFor="let data of menus">
            <label
              [ngClass]="getArrowClass(data)"
              [ngStyle]="{ 'background-color': data.color }"
              (click)="binActiveTab(data)"
              class="pointer customPointingLabel claim-status-label rounded-border down mb-2 py-2 px-2 text-center mr-1"
              matlabel
            >
              {{ data.status }} ({{ data.count }})
            </label>
          </ng-container>
        </div>
      </div>

      <div class="card card-body">
        <div class="row">
          <div class="col-12 px-0">
            <p class="header-text mb-0 pl-3">Filters</p>
            <form class="pl-1" [formGroup]="cliamBinQueueFilterForm">
              <!-- Provider Name -->
              <mat-form-field
                class="col-1_5 custom-form-field"
                style="min-width: 130px !important"
                *ngIf="
                  inboundstatusCode === MyQueueClaimStatusV1.ClaimSubmission
                "
              >
                <mat-label class="drpLabel"
                  >Provider Name
                  <ng-container *ngIf="lstSelecteddrpProvider?.length > 0"
                    >({{ lstSelecteddrpProvider?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  class="custom-mat-select drpOptionFontSize"
                  formControlName="drpProvider"
                  (selectionChange)="onSelectionProviderChange($event)"
                  [(ngModel)]="lstSelecteddrpProvider"
                  (openedChange)="
                    onProviderNameDropdownOpenChange(
                      $event,
                      lstSelecteddrpProvider
                    )
                  "
                  (closed)="
                    handleProviderCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpProvider').value
                    )
                  "
                  multiple
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      (keyup)="loadProviderDropdown(lstSelecteddrpProvider)"
                      placeholderLabel="Provider Name"
                      formControlName="txtProvider"
                      noEntriesFoundLabel="No Matches found"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngIf="!providerSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="providerSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >

                  <mat-option
                    *ngFor="let data of lstprovider | async"
                    [value]="data.pId"
                    [ngClass]="{ inActiveRecord: data?.sActive == 0 }"
                    [matTooltip]="
                      data?.sActive == 0
                        ? 'Inactive Record'
                        : data?.providerName
                    "
                    class="drpOptionFontSize"
                    >{{ data.providerName }}</mat-option
                  >
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="lstSelecteddrpProvider?.length > 0"
                  (click)="
                    lstSelecteddrpProvider = [];
                    providerSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpProvider').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!--claim No Drop Down-->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="
                  inboundstatusCode === MyQueueClaimStatusV1.ClaimChecklist
                "
              >
                <mat-label class="drpLabel"
                  >Claim No
                  <ng-container *ngIf="selectedClaimNoValue?.length > 0"
                    >({{ selectedClaimNoValue?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  formControlName="drpClaimNo"
                  multiple
                  (selectionChange)="onSelectionClaimNoChange($event)"
                  [(ngModel)]="selectedClaimNoValue"
                  (openedChange)="
                    onClaimNoDropdownOpenChange($event, selectedClaimNoValue)
                  "
                  (closed)="
                    handleClaimCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpClaimNo').value
                    )
                  "
                  class="drpOptionFontSize"
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Claim No"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtClaimNoSearch"
                      (keyup)="
                        getClaimNoDropDown(
                          $event.target.value,
                          selectedClaimNoValue
                        )
                      "
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngIf="!claimNoSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="claimNoSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >
                  <mat-option
                    *ngFor="let data of lstFilterClaimNo | async"
                    [value]="data?.claimFormId"
                    class="drpOptionFontSize"
                  >
                    {{ data?.claimNumber }}
                  </mat-option>
                </mat-select>

                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="selectedClaimNoValue?.length > 0"
                  (click)="
                    selectedClaimNoValue = [];
                    claimNoSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpClaimNo').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!--Check No Drop Down-->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="
                  inboundstatusCode === MyQueueClaimStatusV1.AutoPosting ||
                  inboundstatusCode === MyQueueClaimStatusV1.ClaimChecklist
                "
              >
                <mat-label class="drpLabel"
                  >Check No
                  <ng-container *ngIf="selectedCheckNoValue?.length > 0"
                    >({{ selectedCheckNoValue?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  formControlName="drpCheckNo"
                  multiple
                  (selectionChange)="onSelectionCheckNoChange($event)"
                  [(ngModel)]="selectedCheckNoValue"
                  (openedChange)="
                    onCheckNoDropdownOpenChange($event, selectedCheckNoValue)
                  "
                  (closed)="
                    handleCheckNoCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpCheckNo').value
                    )
                  "
                  class="drpOptionFontSize"
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Check No"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtCheckNoSearch"
                      (keyup)="
                        getCheckNoDropDown(
                          $event.target.value,
                          selectedCheckNoValue
                        )
                      "
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngIf="!checkNoSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="checkNoSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >
                  <mat-option
                    *ngFor="let data of lstFilterCheckNo | async"
                    [value]="data?.itemPaymentId"
                    class="drpOptionFontSize"
                  >
                    {{ data?.checkNumber }}
                  </mat-option>
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="selectedCheckNoValue?.length > 0"
                  (click)="
                    selectedCheckNoValue = [];
                    checkNoSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpCheckNo').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Order No -->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="inboundstatusCode === MyQueueClaimStatusV1.ClaimPosting"
              >
                <mat-label class="drpLabel"
                  >Order No
                  <ng-container *ngIf="lstSelectedOrderNos?.length > 0"
                    >({{ lstSelectedOrderNos?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  class="custom-mat-select"
                  formControlName="drpOrderNo"
                  [(ngModel)]="lstSelectedOrderNos"
                  (selectionChange)="onSelectionOrderNoChange($event)"
                  (openedChange)="onOrderNoDropdownOpenChange($event)"
                  (closed)="
                    handleOrderNoCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpOrderNo').value
                    )
                  "
                  multiple
                >
                  <mat-option>
                    <ngx-mat-select-search
                      (keyup)="loadOrderNoDropdown()"
                      placeholderLabel="Order No"
                      formControlName="txtOrderNo"
                      noEntriesFoundLabel="No Matches found"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngIf="!orderNoSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="orderNoSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >

                  <mat-option
                    *ngFor="let data of lstOrderNos"
                    [value]="data.orderId"
                    >{{ data.mmRefereceOrderId }}</mat-option
                  >
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="lstSelectedOrderNos?.length > 0"
                  (click)="
                    lstSelectedOrderNos = [];
                    orderNoSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpOrderNo').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!--Batch No Drop Down-->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="inboundstatusCode === MyQueueClaimStatusV1.ClaimPosting"
              >
                <mat-label class="drpLabel"
                  >Batch No
                  <ng-container *ngIf="selectedBatchNoValue?.length > 0"
                    >({{ selectedBatchNoValue?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  formControlName="drpBatchNo"
                  multiple
                  (selectionChange)="onSelectionBatchNoChange($event)"
                  [(ngModel)]="selectedBatchNoValue"
                  (openedChange)="
                    onBatchNoDropdownOpenChange($event, selectedBatchNoValue)
                  "
                  (closed)="
                    handleBatchNoCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpBatchNo').value
                    )
                  "
                  class="drpOptionFontSize"
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Batch No"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBatchNoSearch"
                      (keyup)="
                        getBatchNoDropDown(
                          $event.target.value,
                          selectedBatchNoValue
                        )
                      "
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngIf="!BatchNoSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="BatchNoSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >
                  <mat-option
                    *ngFor="let data of lstFilterBatchNo | async"
                    [value]="data?.claimFormId"
                    class="drpOptionFontSize"
                  >
                    {{ data?.batchNumber }}
                  </mat-option>
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="selectedBatchNoValue?.length > 0"
                  (click)="
                    selectedBatchNoValue = [];
                    BatchNoSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpBatchNo').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Chart Id -->
              <mat-form-field class="col-1_5 custom-form-field">
                <mat-label class="drpLabel"
                  >Chart Id
                  <ng-container *ngIf="lstSelectedChartNos?.length > 0"
                    >({{ lstSelectedChartNos?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  formControlName="drpChartId"
                  multiple
                  (selectionChange)="onSelectionChartNoChange($event)"
                  [(ngModel)]="lstSelectedChartNos"
                  (openedChange)="
                    onChartNoDropdownOpenChange($event, lstSelectedChartNos)
                  "
                  (closed)="
                    handleChartIdCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpChartId').value
                    )
                  "
                  class="drpOptionFontSize"
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Chart Id"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtChartId"
                      (keyup)="loadChartNoDropdown(lstSelectedChartNos)"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngIf="!ChartNoSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="ChartNoSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >
                  <mat-option
                    *ngFor="let data of lstChartNos | async"
                    [value]="data.spatientID"
                    [ngClass]="{
                      inActiveRecord: data?.isActive == '003'
                    }"
                    [matTooltip]="
                      data?.isActive == '003'
                        ? 'Inactive Record'
                        : data?.chartId
                    "
                    class="drpOptionFontSize"
                    >{{ data.chartId }}
                  </mat-option>
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="lstSelectedChartNos?.length > 0"
                  (click)="
                    lstSelectedChartNos = [];
                    ChartNoSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpChartId').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Patient Name -->
              <mat-form-field class="col-1_5 custom-form-field">
                <mat-label class="drpLabel"
                  >Patient
                  <ng-container *ngIf="lstSelectedPatients?.length > 0"
                    >({{ lstSelectedPatients?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  class="custom-mat-select"
                  formControlName="drpPatientName"
                  [(ngModel)]="lstSelectedPatients"
                  (selectionChange)="onSelectionPatientNameChange($event)"
                  (openedChange)="
                    onPatientNameDropdownOpenChange($event, lstSelectedPatients)
                  "
                  (closed)="
                    handlePatientCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpPatientName').value
                    )
                  "
                  multiple
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Patient"
                      (keyup)="loadPatientDropdown(lstSelectedPatients)"
                      formControlName="txtPatientName"
                      noEntriesFoundLabel="No Matches found"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngIf="!patientNameSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="patientNameSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >

                  <mat-option
                    *ngFor="let data of lstPatients | async"
                    [value]="data.patId"
                    [ngClass]="{
                      inActiveRecord: data?.activeStatus == '003'
                    }"
                    [matTooltip]="
                      data?.activeStatus == '003'
                        ? 'Inactive Record'
                        : data?.fullName
                    "
                    class="drpOptionFontSize"
                    >{{ data.fullName }}</mat-option
                  >
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="lstSelectedPatients?.length > 0"
                  (click)="
                    lstSelectedPatients = [];
                    patientNameSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpPatientName').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!--Claim Status Drop Down-->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="
                  inboundstatusCode === MyQueueClaimStatusV1.ClaimSubmission
                "
              >
                <mat-label class="drpLabel">Status </mat-label>
                <!-- <mat-select
                  class="drpOptionFontSize"
                  formControlName="drpClaimsubSatus"
                  (selectionChange)="onSelectionClaimStsChange($event)"
                  (closed)="handleClaimStsCloseDrpdown(cliamBinQueueFilterForm.get('drpClaimsubSatus').value)">

                  <mat-option [value]="'Fill Claim Form'" class="drpOptionFontSize">
                    Fill Claim Form
                  </mat-option>
                  <mat-option [value]="'Ready to process'" class="drpOptionFontSize">
                    Ready to Process
                  </mat-option>
                </mat-select> -->
                
              <mat-select class="custom-mat-select" formControlName="drpClaimsubSatus" [(ngModel)]="lstSelectedtClaimsubSatus" (selectionChange)="onSelectionclaimStatusChange($event)" 
              (closed)="handleClaimStsCloseDrpdown(cliamBinQueueFilterForm.get('drpClaimsubSatus').value)" multiple>
                <mat-option class="drpOptionFontSize">
                  <ngx-mat-select-search placeholderLabel="Status" [formControl]="txtClaimbSatusTypeControl" noEntriesFoundLabel="No Matches found">

                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="!claimsubSatusSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
                <mat-option *ngIf="claimsubSatusSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
                <mat-option *ngFor="let data of claimStatusOptions" [value]="data.value" class="drpOptionFontSize">
                  {{ data.value }}</mat-option>
              </mat-select>

                <button mat-button *ngIf="cliamBinQueueFilterForm.get('drpClaimsubSatus').value !='' && cliamBinQueueFilterForm.get('drpClaimsubSatus').value !=null && cliamBinQueueFilterForm.get('drpClaimsubSatus').value !=undefined" (click)="clearClaimSubStatusDrp(); $event.stopPropagation()" matSuffix mat-icon-button>
                  <mat-icon style="font-size: 16px !important">close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Waystar Status -->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="inboundstatusCode === MyQueueClaimStatusV1.ClaimPosting"
                style="min-width: 130px !important"
              >
                <mat-label class="drpLabel"
                  >Waystar Status
                  <ng-container *ngIf="lstSelectedWaystarStatus?.length"
                    >({{ lstSelectedWaystarStatus?.length }})
                  </ng-container>
                </mat-label>
                <mat-select
                  class="custom-mat-select"
                  formControlName="drpWaystarStatus"
                  [(ngModel)]="lstSelectedWaystarStatus"
                  (selectionChange)="onSelectionWaystarStatusChange($event)"
                  (openedChange)="
                    onWaystarStatusDropdownOpenChange(
                      $event,
                      lstSelectedClaimStatus
                    )
                  "
                  (closed)="
                    handleWaysterStsCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpWaystarStatus').value
                    )
                  "
                  multiple
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Waystar Status"
                      formControlName="txtWaystarStatus"
                      (keyup)="
                        getClaimWaystarStatusDropDown(
                          $event.target.value,
                          lstSelectedClaimStatus
                        )
                      "
                      noEntriesFoundLabel="No Matches found"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngIf="!waystarStatusSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="waystarStatusSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >
                  <mat-option
                    *ngFor="let data of lstFilterwaystarStatus | async"
                    [value]="data.waystarStatusId"
                    class="drpOptionFontSize"
                    >{{ data.waystarStatus }}</mat-option
                  >
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="lstSelectedWaystarStatus?.length > 0"
                  (click)="
                    lstSelectedWaystarStatus = [];
                    waystarStatusSelectAllOption = false;
                    cliamBinQueueFilterForm
                      .get('drpWaystarStatus')
                      .setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Status -->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="
                  inboundstatusCode === MyQueueClaimStatusV1.AutoPosting ||
                  inboundstatusCode === MyQueueClaimStatusV1.ClaimPosting
                "
              >
                <mat-label class="drpLabel"
                  >Status
                  <ng-container *ngIf="lstSelectedClaimStatus?.length > 0"
                    >({{ lstSelectedClaimStatus?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  class="custom-mat-select"
                  formControlName="drpClaimStatus"
                  [(ngModel)]="lstSelectedClaimStatus"
                  (selectionChange)="onSelectionStatusChange($event)"
                  (openedChange)="
                    onStatusDropdownOpenChange($event, lstSelectedClaimStatus)
                  "
                  (closed)="
                    handleStatusCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpClaimStatus').value
                    )
                  "
                  multiple
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Status"
                      formControlName="txtClaimStatus"
                      (keyup)="
                        getClaimStatusDropDown(
                          $event.target.value,
                          lstSelectedClaimStatus
                        )
                      "
                      noEntriesFoundLabel="No Matches found"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngIf="!statusSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="statusSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >
                  <mat-option
                    *ngFor="let data of lstClaimStatus | async"
                    [value]="data.claimStatusId"
                    class="drpOptionFontSize"
                    >{{ data.claimStatusName }}</mat-option
                  >
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="lstSelectedClaimStatus?.length > 0"
                  (click)="
                    lstSelectedClaimStatus = [];
                    statusSelectAllOption = false;
                    cliamBinQueueFilterForm.get('drpClaimStatus').setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Posting Method -->
              <!-- <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="inboundstatusCode === MyQueueClaimStatusV1.AutoPosting" style="min-width: 130px !important;">
                <mat-label class="drpLabel">Posting Method</mat-label>
                <mat-select class="custom-mat-select" formControlName="drpPostingMethod"
                  [(ngModel)]="lstSelectedtPostingMethod" (selectionChange)="onSelectionPostMethodChange($event)"
                  (openedChange)="onPostMethodsDropdownOpenChange($event,lstSelectedtPostingMethod)"
                  (closed)="handleCloseDrpdown(cliamBinQueueFilterForm.get('drpPostingMethod').value);" multiple>
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search placeholderLabel="Status" formControlName="txtPostingMethod"
                      (keyup)="getClaimPostMethodDropDown($event.target.value,lstSelectedtPostingMethod)"
                      noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngIf="!postMethodSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select
                    All</mat-option>
                  <mat-option *ngIf="postMethodSelectAllOption" [value]="'DeselectAll'"
                    class="drpOptionFontSize">Deselect
                    All</mat-option>
                  <mat-option *ngFor="let data of lstPostindMethod | async" [value]="data.postingMethodId"
                    class="drpOptionFontSize">{{
                    data.postingMethod }}</mat-option>
                </mat-select>
              </mat-form-field> -->

              <!-- Posting Method -->
              <mat-form-field
                class="col-1_5 custom-form-field"
                *ngIf="inboundstatusCode === MyQueueClaimStatusV1.AutoPosting"
                style="min-width: 130px !important"
              >
                <mat-label class="drpLabel"
                  >Posting Method
                  <ng-container *ngIf="lstSelectedtPostingMethod?.length > 0"
                    >({{ lstSelectedtPostingMethod?.length }})</ng-container
                  >
                </mat-label>
                <mat-select
                  class="custom-mat-select"
                  formControlName="drpPostingMethod"
                  [(ngModel)]="lstSelectedtPostingMethod"
                  (selectionChange)="onSelectionPostMethodChange($event)"
                  (closed)="
                    handlePostMethodCloseDrpdown(
                      cliamBinQueueFilterForm.get('drpPostingMethod').value
                    )
                  "
                  multiple
                >
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search
                      placeholderLabel="Posting Method"
                      formControlName="txtPostingMethod"
                      noEntriesFoundLabel="No Matches found"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngIf="!postMethodSelectAllOption"
                    [value]="'selectAll'"
                    class="drpOptionFontSize"
                    >Select All</mat-option
                  >
                  <mat-option
                    *ngIf="postMethodSelectAllOption"
                    [value]="'DeselectAll'"
                    class="drpOptionFontSize"
                    >Deselect All</mat-option
                  >
                  <mat-option
                    *ngFor="let data of lstPostindMethod | async"
                    [value]="data.postingMethodId"
                    class="drpOptionFontSize"
                  >
                    {{ data.postingMethod }}</mat-option
                  >
                </mat-select>
                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="lstSelectedtPostingMethod?.length > 0"
                  (click)="
                    lstSelectedtPostingMethod = [];
                    postMethodSelectAllOption = false;
                    cliamBinQueueFilterForm
                      .get('drpPostingMethod')
                      .setValue([]);
                    getClaimFileterList(inboundstatusCode);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Date of Service -->
              <mat-form-field
                class="dd-text customWidth ml-3"
                *ngIf="
                  inboundstatusCode === MyQueueClaimStatusV1.ClaimSubmission
                "
              >
                <mat-label class="drpLabelDate">Date of Service</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    matInput
                    placeholder="Start date"
                    formControlName="txtDOSFromDate"
                    (keydown)="addSlashToDate($event)"
                  />

                  <input
                    matEndDate
                    matInput
                    placeholder="End date"
                    formControlName="txtDOSEndDate"
                    (dateChange)="onDateRangeChange()"
                    (keydown)="addSlashToDate($event)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <button
                  mat-button
                  *ngIf="
                    (cliamBinQueueFilterForm.get('txtDOSFromDate').value !=
                      '' &&
                      cliamBinQueueFilterForm.get('txtDOSFromDate').value !=
                        null &&
                      cliamBinQueueFilterForm.get('txtDOSFromDate').value !=
                        undefined) ||
                    (cliamBinQueueFilterForm.get('txtDOSEndDate').value != '' &&
                      cliamBinQueueFilterForm.get('txtDOSEndDate').value !=
                        null &&
                      cliamBinQueueFilterForm.get('txtDOSEndDate').value !=
                        undefined)
                  "
                  (click)="clearDOS(); $event.stopPropagation()"
                  matSuffix
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <button
                type="button"
                (click)="resetForm()"
                matTooltip="Reset"
                mat-raised-button
                class="reset-icon-button ml-4 mat-raised-small-button"
              >
                <mat-icon class="fg-white">refresh</mat-icon>
              </button>
            </form>
          </div>
        </div>

        <mat-divider class="col-12 p-1"></mat-divider>

        <div class="d-flex justify-content-center" *ngIf="loadInboundData">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>

        <div class="table-responsive" *ngIf="!loadInboundData">
          <app-claim-bin-queue-table [inBoundTableData]="inboundTableData" (nevigatePage)="changePage($event)"
            [activeTabBin]="activeTabBin" [visibleColumns]="visibleColumns" [currentPage]="currentPage"
            [totalPages]="totalPages" [paginationInfo]="paginationInfo"></app-claim-bin-queue-table>
        </div>
      </div>
    </div>
  </div>
</div>
