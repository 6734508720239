import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ClaimCCSettlementListDTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-cc-settlement-tab-completed',
  templateUrl: './cc-settlement-tab-completed.component.html',
  styleUrls: ['./cc-settlement-tab-completed.component.scss']
})
export class CcSettlementTabCompletedComponent implements OnInit {

  @Input() DataList: any[];
  CCSettlementArray: ClaimCCSettlementListDTO[];

  dtCCCListOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    // fixedColumns: {
    //   leftColumns: 3, // Number of columns to fix on the left
    //   rightColumns: 1, // Number of columns to fix on the right
    // },
    columnDefs: [
      { targets: [0, 1, -1], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
      // { targets: [12,13], visible: false },
      // {
      //   targets: [8],
      //   type: 'date',
      //   render: function (data, type, row) {
      //     // Assuming data is in the format MM/DD/YYYY
      //     if (type === 'sort' || type === 'type') {
      //       var dateParts = data.split('/');
      //       return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      //     } else {
      //       return data;
      //     }
      //   },
      // },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'CC Settlement - Completed',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };


  constructor( private datepipe:DatePipe) { }

  ngOnInit(): void {
    this.dtCCCListOptions.buttons[0].filename =
    'CC Settlement - Completed' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.CCSettlementArray=this.DataList;
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

}
