<!-- Header Section -->
<div class="customThemeClass mr-4">
    <div class="row" *ngIf="!isLoading">
        <div class="col-md-10">
            <mat-radio-group [(ngModel)]="selectedOption" (change)="GetReminderFilterList()">
                <mat-radio-button class="Reminders pl-3" [value]="0"><b><span class="fontresize">All
                            Reminders</span></b></mat-radio-button>
                <mat-radio-button class="Reminders" [value]="ReminderFilter.MyReminders"><b><span class="fontresize">My
                            Reminders</span></b></mat-radio-button>
                <mat-radio-button class="Reminders" [value]="ReminderFilter.AssignedTo"><b><span
                            class="fontresize">Assigned To</span></b></mat-radio-button>
                <mat-radio-button class="Reminders" [value]="ReminderFilter.Completed"><b><span
                            class="fontresize">Completed</span></b></mat-radio-button>
                <mat-radio-button class="Reminders" [value]="ReminderFilter.Priority"><b><span
                            class="fontresize">Priority</span></b></mat-radio-button>
            </mat-radio-group>

        </div>
        <div class="col-md-2 text-right">
            <button mat-button class="buttonColor" (click)="OpenAddReminderMdl(true)">
                <i class="fa fa-plus mr-1"></i> Add Reminder
            </button>
        </div>
    </div>
</div>
<!-- Table Section -->
<!-- <div class="row"> -->
<div class="col-lg-12">
    <div class="card card-body">
        <!-- Loader Section -->
        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
        <div class="table-responsive" *ngIf="!isLoading">

            <app-patient-reminder-dashboard-table [tableData]="remindertbldata"></app-patient-reminder-dashboard-table>
            <!-- <table id="tblReminderList" datatable [dtOptions]="dtReminderTableOptions"
                class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th><mat-icon> wrap_text</mat-icon></th>
                        <th><mat-icon>apps</mat-icon></th>
                        <th [matTooltip]="'Notes Type'">Notes Type</th>
                        <th [matTooltip]="'Patient Name'">Patient Name</th>
                        <th [matTooltip]="'Chart No'">Chart No</th>
                        <th [matTooltip]="'Assigned To'">Assigned To</th>
                        <th [matTooltip]="'Due Date'" class="text-center">Due Date</th>
                        <th [matTooltip]="'User Name'">User Name</th>
                        <th [matTooltip]="'Description'">Description</th>
                        <th [matTooltip]="'Priority'">Priority</th>
                        <th [matTooltip]="'Status'">Status</th>
                        <th [matTooltip]="'Completed'">Completed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of remindertbldata">
                        <td [matTooltip]="'Expand Notes'" class="dt-control"
                            (click)="handleTableCellClick($event, data.description)">
                        </td>
                        <td [matTooltip]="'Open Options'">
                            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                            <mat-menu class="custom-mat-menu" #menu="matMenu">
                                <button mat-menu-item (click)="UpdateReminder(data.id,false)"
                                    [disabled]="data.status === 'Completed' || data.isEdit === 0">Edit</button>
                                <button mat-menu-item (click)="DeleteReminder(data.id)"
                                    [disabled]="data.isEdit === 0">Delete</button>
                                <mat-divider></mat-divider>
                                <button mat-menu-item (click)="completeReminder(data.id)"
                                    [disabled]="data.status === 'Completed'">Complete</button>
                            </mat-menu>
                        </td>
                        <td (dblclick)="onRowDoubleClick(data.note || '-')" [matTooltip]="data.note || '-'"> {{
                            data.note
                            || '-' }} </td>
                        <td (dblclick)="onRowDoubleClick(data?.patientName || '-')"
                            [matTooltip]="data.patientName || '-'"> {{ data.patientName || '-' }}
                        </td>

                        <td (dblclick)="onRowDoubleClick(data?.chartId || '-')">
                            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
                                [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                                [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
                        </td>

                        <td (dblclick)="onRowDoubleClick(data?.assignedUserName || '-')"
                            [matTooltip]="data.assignedUserName || '-'"> {{ data.assignedUserName ||
                            '-' }} </td>
                        <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.dueDate))"
                            [matTooltip]="data?.dueDate ? (data?.dueDate | date : 'MM/dd/yyyy') : '-'"
                            [ngClass]="{'past-due-row': isPastDue(data?.dueDate) && data.status !== 'Completed'}"
                            class="text-center">
                            {{ data?.dueDate ? (data?.dueDate | date : 'MM/dd/yyyy') : '-' }}
                        </td>
                        <td (dblclick)="onRowDoubleClick(data?.userName || '-')" [matTooltip]="data.userName || '-'"> {{
                            data.userName || '-' }} </td>
                        <td (dblclick)="onRowDoubleClick(data?.description || '-')"
                            [matTooltip]="data.description || '-'">{{ data.description || '-' }}</td>
                        <td (dblclick)="onRowDoubleClick(data?.priority || '-')" [matTooltip]="data.priority || '-'">{{
                            data.priority || '-' }}</td>
                        <td (dblclick)="onRowDoubleClick(data?.status || '-')" [matTooltip]="data.status || '-'">{{
                            data.status ||'-' }}</td>
                        <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.completedDate))"
                            [matTooltip]="data?.completedDate ? (data?.completedDate | date : 'MM/dd/yyyy') : '-'">{{
                            data?.completedDate ? (data?.completedDate | date :
                            'MM/dd/yyyy') : '-' }}</td>
                    </tr>
                </tbody>
            </table> -->
            <!-- <td (dblclick)="onRowDoubleClick(data.note.split('-')[0] || '-')" [matTooltip]="data.note.split('-')[0] || '-'"> {{ data.note.split('-')[0]
                            || '-' }} </td> -->
        </div>
    </div>
</div>