<table id="tblInsuranceHistory" datatable [dtOptions]="dtInsuranceHistoryTableOptions"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th [matTooltip]="'Insurance Type'">Insurance Type</th>
            <th [matTooltip]="'Insurance'" class="custstatus">Insurance </th>
            <th [matTooltip]="'Eligibility Mode'">Eligibility Mode</th>
            <th [matTooltip]="'Policy No'">Policy No</th>
            <th [matTooltip]="'Group No'">Group No</th>
            <th [matTooltip]="'Type'">Type</th>
            <th [matTooltip]="'start Date'" class="text-center">Start Date</th>
            <th [matTooltip]="'End Date'" class="text-center">End Date</th>
            <th [matTooltip]="'Verification Status'" class="text-center custstatus">Verification Status</th>
            <th [matTooltip]="'Auth Status'" class="text-center custstatus">Auth Status</th>
            <th [matTooltip]="'Status'">Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of insuranceHistorydata">

            <td [matTooltip]="data?.plan || '-'" (dblclick)="onRowDoubleClickV1(data?.plan || '-')">
                {{ data?.plan || '-' }}
            </td>
            <td [matTooltip]="data?.insurance || '-'" (dblclick)="onRowDoubleClickV1(data?.insurance || '-')">
                {{ data?.insurance || '-'}}
            </td>
            <td [matTooltip]="data?.eligibilityMode || '-'"
                (dblclick)="onRowDoubleClickV1(data?.eligibilityMode || '-')">
                {{ data?.eligibilityMode || '-' }}
            </td>

            <td [matTooltip]="data?.policyNo || '-'" (dblclick)="onRowDoubleClickV1(data?.policyNo || '-')">
                {{ data?.policyNo || '-' }}
            </td>

            <td [matTooltip]="data?.groupNo || '-'" (dblclick)="onRowDoubleClickV1(data?.groupNo || '-')">
                {{ data?.groupNo ||'-' }}
            </td>

            <td [matTooltip]="data?.typeofPlan || '-'" (dblclick)="onRowDoubleClickV1(data?.typeofPlan || '-')">
                {{ data?.typeofPlan ||'-' }}
            </td>

            <td [matTooltip]="getFormattedDate(data?.creationTime)"
                (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.creationTime))" class="text-center">
                {{ getFormattedDate(data?.creationTime) }}
            </td>

            <td [matTooltip]="getFormattedDate(data?.policyEndedOn)"
                (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.policyEndedOn))" class="text-center">
                {{ getFormattedDate(data?.policyEndedOn) }}
            </td>

            <td (dblclick)="onRowDoubleClickV1(data?.verValidity || '-')" class="text-center custstatus">
                <i class="fa fa-clock-o pendingicon ml-2" *ngIf="data?.verificationStatus === 1"
                    [matTooltip]="data?.vStatus"></i>
                <i class="fa fa-check checkicon" *ngIf="data?.verificationStatus === 2"
                    [matTooltip]="data?.vStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.verificationStatus === 2" [matTooltip]="'View Insurance'"
                    (click)="viewVerificationById(data?.patientId,data?.verificationId,data?.vStatus)"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.verificationStatus === 3"
                    [matTooltip]="data?.vStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.verificationStatus === 4"
                    [matTooltip]="data?.vStatus"></i>
                <!-- <span class="ml-2" [matTooltip]="data?.verValidity || '-'">{{ data?.verValidity || '-' }}</span> -->
                <span class="ml-2" [matTooltip]="data?.verValidity || '-'" *ngIf="data?.verificationStatus != 1">{{
                    data?.verValidity || '-' }}</span>
                <span class="ml-2" [matTooltip]="data?.verValidity" *ngIf="data?.verificationStatus === 1">{{
                    data?.verValidity }}</span>
            </td>
            
            <td (dblclick)="onRowDoubleClickV1(data?.authValidity || '-')" class="text-center custstatus">
                <i class="fa fa-clock-o pendingicon ml-2" *ngIf="data?.authStatus === 1 || data?.authStatus === 3"
                    [matTooltip]="data?.aStatus"></i>
                <i class="fa fa-check checkicon" *ngIf="data?.authStatus === 2" [matTooltip]="data?.aStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.authStatus === 2" [matTooltip]="'View Insurance'"
                    (click)="viewVerificationById(data?.patientId,data?.verificationId,data?.aStatus)"></i>
                <i class="fa fa-file-o fileicon ml-2" *ngIf="data?.authStatus === 4" [matTooltip]="data?.aStatus"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.authStatus === 5" [matTooltip]="data?.aStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.authStatus === 6"
                    [matTooltip]="data?.aStatus"></i>
                <span class="ml-2" [matTooltip]="data?.authValidity || '-'"
                    *ngIf="data?.authStatus !== 1 && data?.authStatus !== 3"> {{
                    data?.authValidity || '-' }}</span>
                <span class="ml-2" [matTooltip]="data?.authValidity"
                    *ngIf="data?.authStatus === 1 && data?.authStatus === 3"> {{
                    data?.authValidity }}</span>
            </td>
            <td [matTooltip]="data?.isActive ? 'Active' : 'Inactive' || '-'"
                (dblclick)="onRowDoubleClickV1(data?.isActive ? 'Active' : 'Inactive')">
                <i class="fa fa-toggle-on toggleicon ml-2" *ngIf="data?.isActive===true"></i> <i
                    class="fa fa-toggle-on toggleofficon ml-2" *ngIf="data?.isActive===false"></i> {{data?.isActive ?
                'Active' : 'Inactive' }}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Insurance Type"
                    name="search-InsuranceType" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Insurance"
                    name="search-Insurance" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Eligibility Mode"
                    name="search-EligibilityMode" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Policy No"
                    name="search-PolicyNo" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Group No" name="search-GroupNo" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Type" name="search-Type" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center" type="text" placeholder="Start Date"
                    name="search-StartDate" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center" type="text" placeholder="End Date"
                    name="search-EndDate" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center custstatus" type="text" placeholder="Verification Status"
                    name="search-verficationstatus" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center custstatus" type="text" placeholder="Auth Status"
                    name="search-authstatus" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" />
            </th>
        </tr>
    </tfoot>
</table>