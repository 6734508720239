import { mapEnumToOptions } from '@abp/ng.core';

export enum ReminderFilter {
  AllReminder = 0,
  MyReminders = 1,
  AssignedTo = 2,
  Completed = 3,
  Priority = 4,
}

export const reminderFilterOptions = mapEnumToOptions(ReminderFilter);
