import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import {
  CheckListOption,
  defaultGuid,
  InsuranceDTO,
  MyQueueBinStatus,
  ScheduleStatusValues,
  SortingFilter,
  TAtFilter,
  TAtFilterValues,
  VerificationOption,
} from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  GetPracticeDTO,
  PayorDetailDTO,
} from 'projects/patient/src/app/patient-proxy/patient/practice-management/dto/models';
import {
  GetBranchDTO,
  GetBranchOrgDTO,
} from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { OrganizationUnitService } from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { map, startWith } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MasterProviderService } from '../../warehouse-proxy/inventory';
import { CSRGetDTO } from '../../warehouse-proxy/inventory/dto/models';
import { PracticeManagementService } from 'projects/patient/src/app/patient-proxy/patient/practice-management';
import { InboundDocumentService } from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import {
  AuthStatDTO,
  FaxIdDTO,
  patientEntryRequestDto,
} from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { CalendarStatus } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar-status.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  AuthStatusDTO,
  PatientMasterDropdownDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import { VerificationStatus } from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/verification-status.enum';
import { MedicareCheckListStatus } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/medicare-check-list-status.enum';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';

@Component({
  selector: 'app-document-bin-queue-table',
  templateUrl: './document-bin-queue-table.component.html',
  styleUrls: ['./document-bin-queue-table.component.scss'],
})
export class DocumentBinQueueTableComponent implements OnInit {
  @Input() inBoundTableData: any[];
  @Input() activeTabBin: any;
  @Input() inboundStatusId: string;
  @Input()
  overallTableCount: number = 0;
  @Input() currentPage: any = 1;
  currentPageV1: any = 1;
  recordsPerPage: number = 50;
  @Input() totalPages: any = 1;
  pageOffset: any = 50;
  @Output() nevigatePage? = new EventEmitter<number>();
  inboundStatusIds = InboundStatusShortCode;
  @ViewChild('tableContainer', { read: ViewContainerRef })
  tableContainer: ViewContainerRef;
  myTable2: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  MyQueueBinStatus = MyQueueBinStatus;
  patientEntrySearchForm: FormGroup;
  tenantId: string = '';
  selectedBranchList: string[] = null;
  selectedTATList: string[] = null;
  selectedInsuranceList: string[] = null;
  selectedPrimaryInsuranceList: string[] = null;
  selectedCSRList: string[] = null;
  selectedPracticeList: string[] = null;
  selectedSplitList: string[] = null;
  selectedJoinList: string[] = null;
  selectedScheduleList: string[] = null;
  selectedAuthList: string[] = null;
  selectedVerificationList: string[] = null;
  selectedChecklistList: string[] = null;
  BranchSelectAllOption: boolean = false;
  csrSelectAllOption: boolean = false;
  TATSelectAllOption: boolean = false;
  practiceSelectAllOption: boolean = false;
  scheduleSelectAllOption: boolean = false;
  checklistSelectAllOption: boolean = false;
  AuthSelectAllOption: boolean = false;
  splitSelectAllOption: boolean = false;
  joinSelectAllOption: boolean = false;
  InsuranceSelectAllOption: boolean = false;
  PrimaryInsuSelectAllOption: boolean = false;
  VerificationSelectAllOption: boolean = false;
  // public drpBranchtype: GetBranchDTO[] = [];
  public drpBranchtype: GetBranchOrgDTO[] = [];
  public drpCSRName: CSRGetDTO[] = [];
  public drpPractice: GetPracticeDTO[] = [];
  public drpSplit: FaxIdDTO[] = [];
  public drpJoin: FaxIdDTO[] = [];
  public drpAuthStatus: AuthStatDTO[] = [];
  public drpInsurance: PayorDetailDTO[] = [];
  public drpPrimaryInsurance: PayorDetailDTO[] = [];
  verificationOptions: any;
  checklistOptions: any;
  verificaionTypeControl = new FormControl();
  isInsuranceLoading: boolean = false;
  isBranchLoading: boolean = false;
  isCsrLoading: boolean = false;
  isPracticeLoading: boolean = false;
  isSplitLoading: boolean = false;
  isJoinLoading: boolean = false;
  loadInboundData: boolean = false;
  BranchDrpubscription: Subscription;
  CsrDrpbscription: Subscription;
  practiceDrpsubcription: Subscription;
  SplitandJoinDrpsubcription: Subscription;
  JoinDrpsubcription: Subscription;
  getInboundTableData: Subscription;
  PrimayPayerDetails: Subscription;
  payerDrpsubcription: Subscription;
  subscription$: Subscription[] = [];
  public lstFilterBranch: any | undefined;
  public lstFilterCSR: any | undefined;
  public lstFilterPractice: any | undefined;
  public lstFilterSplit: any | undefined;
  public lstFilterJoin: any | undefined;
  public lstFilterInsurance: any | undefined;
  public ltAuthStatus: any | undefined;
  public filteredPrimaryPayors: any | undefined;
  public filteredInsurancePayors: any | undefined;

  isLoactionSelectionChange: boolean = false;
  isInsuranceSelectionChange: boolean = false;
  isTATSelectionChange: boolean = false;
  isPriInsSelectionChange: boolean = false;
  isverifStatusSelectionChange: boolean = false;
  isSchStatusSelectionChange: boolean = false;
  isCheckListSelectionChange: boolean = false;
  isAuthStatusSelectionChange: boolean = false;
  isCSRSelectionChange: boolean = false;
  iPracticeSelectionChange: boolean = false;
  isSortingSelectionChange: boolean = false;
  isSplitSelectionChange: boolean = false;
  isJoinSelectionChange: boolean = false;

  selectPayorList: any;
  selectedBranchValue: string[] = [];
  selectedCSRValue: string[] = [];
  selectedPracticeValue: string[] = [];
  selectedSplitValue: string[] = [];
  selectedJointValue: string[] = [];
  selectedPriInsValue: string[] = [];
  selectedInsValue: string[] = [];
  tatFilter = TAtFilter;
  tatFilterKeys: any;
  scheduleDrpValues: any;
  TATTypeControl = new FormControl();
  calendarTypeControl = new FormControl();
  checklistTypeControl = new FormControl();
  sortingOptions = Object.keys(SortingFilter).map((key) => ({
    key,
    value: SortingFilter[key],
  }));
  calendarstatus = CalendarStatus;
  public drptatControl: TAtFilterValues[] = [
    { tatId: 0, tatValue: this.tatFilter.greaterthanNegative72 },
    { tatId: 1, tatValue: this.tatFilter.negative72to48 },
    { tatId: 2, tatValue: this.tatFilter.negative48tozero },
    { tatId: 3, tatValue: this.tatFilter.zeroto48 },
    { tatId: 4, tatValue: this.tatFilter.between48to72 },
    { tatId: 5, tatValue: this.tatFilter.greaterthan72 },
  ];
  public drpSecheduleStatus: ScheduleStatusValues[] = [
    { scheValues: this.calendarstatus.All },
    { scheValues: this.calendarstatus.AwaitingCheckIn },
    { scheValues: this.calendarstatus.Confirmed },
    { scheValues: this.calendarstatus.CheckedOut },
    { scheValues: this.calendarstatus.Cancelled },
    { scheValues: this.calendarstatus.Completed },
    { scheValues: this.calendarstatus.NoShow },
    { scheValues: this.calendarstatus.Rescheduled },
    { scheValues: this.calendarstatus.Scheduled },
  ];
  public drpVerificationStatus: VerificationOption[] = [
    { key: 'New', value: VerificationStatus.New },
    { key: 'Done', value: VerificationStatus.Done },
    { key: 'Pending', value: VerificationStatus.Pending },
    { key: 'Unable to verify', value: VerificationStatus.Unabletoverify },
    { key: 'Cancelled', value: VerificationStatus.Cancelled },
    { key: 'Expired', value: VerificationStatus.Expired },
  ];
  public drpChecklistStatus: any[] = [
    { key: 0, value: 'New' },
    { key: 1, value: 'Approved' },
    { key: 2, value: 'Disapproved' },
    { key: 3, value: 'Pending' },
    { key: 4, value: 'Submitted' },
  ];
  dtDocumentBinTableOptions: any;
  // dtDocumentBinTableOptions: any = {
  //   responsive: true,
  //   paging: false,
  //   dom: 'Bfrtip',
  //   scrollCollapse: true,
  //   scrollY: '500px',
  //   scrollX: true,
  //   search: {
  //     smart: false,
  //   },
  //   language: {
  //     search: '',
  //     searchPlaceholder: 'Search Here',
  //     emptyTable: 'No records',
  //     info: 'Total : _MAX_ records',
  //     infoEmpty: 'Total : _MAX_ records',
  //     infoFiltered: '(filtered : _TOTAL_ records)',
  //     loadingRecords: 'Loading...',
  //     zeroRecords: 'No matching records',
  //   },
  //   columnDefs: [],
  //   order: [0],
  //   buttons: [
  //     {
  //       extend: 'excel',
  //       text: 'Export',
  //       className: 'custom-dt-export-button',
  //       filename: '',
  //       exportOptions: {
  //         columns: ':not(:first-child) ',
  //       },
  //     },
  //   ],
  //   initComplete: function (this: any) {
  //     //Search Input Alignment
  //     const searchInput = document.querySelector(
  //       '.dataTables_wrapper .dataTables_filter'
  //     ) as HTMLElement;
  //     if (searchInput) {
  //       searchInput.style.float = 'left';
  //     }
  //     //Search Input Design and Icon
  //     const searchFilterInput = document.querySelector(
  //       '.dataTables_filter input'
  //     ) as HTMLInputElement;
  //     if (searchFilterInput) {
  //       const searchContainer = document.createElement('div');
  //       searchContainer.className = 'search-container';
  //       searchFilterInput.style.paddingLeft = '25px';
  //       searchFilterInput.style.fontStyle = 'italic';
  //       searchFilterInput.style.fontSize = '12px';
  //       // Add event listeners for focusin and focusout
  //       searchFilterInput.addEventListener('focus', () => {
  //         searchFilterInput.style.border = '1px solid lightseagreen';
  //         searchFilterInput.style.outline = 'lightseagreen';
  //       });

  //       searchFilterInput.addEventListener('blur', () => {
  //         searchFilterInput.style.border = '';
  //         searchFilterInput.style.outline = '';
  //       });

  //       searchFilterInput.parentNode?.insertBefore(
  //         searchContainer,
  //         searchFilterInput
  //       );
  //       searchContainer.appendChild(searchFilterInput);

  //       const searchIcon = document.createElement('i');
  //       searchIcon.className = 'fa fa-search';
  //       searchIcon.style.position = 'absolute';
  //       searchIcon.style.left = '10px';
  //       searchIcon.style.top = '16px';
  //       searchIcon.style.transform = 'translateY(-50%)';
  //       searchIcon.style.pointerEvents = 'none';
  //       searchIcon.style.color = '#aaa';

  //       searchContainer.appendChild(searchIcon);
  //     }
  //   },
  // };
  constructor(
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private commonService: CommonService,
    private masterproviderservice: MasterProviderService,
    private practicemanagementservice: PracticeManagementService,
    private inbounddocumentservice: InboundDocumentService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private fb: FormBuilder,
    private organizationunitService: OrganizationUnitService,
    private patientDropdownService: PatientMasterDropdownService,
    private payerDetailService: PayerDetailService
  ) {}

  ngOnInit(): void {
    this.tableInitilization();
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.tatFilterKeys = this.drptatControl;
    this.scheduleDrpValues = this.drpSecheduleStatus;
    this.verificationOptions = this.drpVerificationStatus;
    this.checklistOptions = this.drpChecklistStatus;
    this.updateColumnDefs();
    this.formInitiliZation();
    this.getBrachDropDown('', []);
    this.getCSRNameDropDown('', []);
    this.getPracticeDropDown('', []);
    this.getSplitDropDown('', []);
    this.loadAuthStatusDropdowns();
    this.getInsuranceDropDown('', []);
    this.dtDocumentBinTableOptions.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    //TAT Search Type
    this.TATTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.tatFilterKeys = this.drptatControl.filter((dx) =>
        dx.tatValue.toLowerCase().includes(searchTerm)
      );
    });

    this.calendarTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.scheduleDrpValues = this.drpSecheduleStatus.filter((dx) =>
        dx.scheValues.toLowerCase().includes(searchTerm)
      );
    });

    this.verificaionTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.verificationOptions = this.drpVerificationStatus.filter((dx) =>
        dx.key.toLowerCase().includes(searchTerm)
      );
    });

    this.checklistTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.checklistOptions = this.drpChecklistStatus.filter((dx) =>
        dx.value.toLowerCase().includes(searchTerm)
      );
    });
  }

  // Data Table Initialization
  tableInitilization() {
    this.dtDocumentBinTableOptions = {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      search: {
        smart: false,
      },
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.currentPage} to _END_ of ${this.overallTableCount} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      columnDefs: [],
      order: [0],
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          className: 'custom-dt-export-button',
          filename: '',
          exportOptions: {
            columns: ':not(:first-child) ',
          },
        },
      ],
      initComplete: function (this: any) {
        //Search Input Alignment
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }
        //Search Input Design and Icon
        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          searchContainer.className = 'search-container';
          searchFilterInput.style.paddingLeft = '25px';
          searchFilterInput.style.fontStyle = 'italic';
          searchFilterInput.style.fontSize = '12px';
          // Add event listeners for focusin and focusout
          searchFilterInput.addEventListener('focus', () => {
            searchFilterInput.style.border = '1px solid lightseagreen';
            searchFilterInput.style.outline = 'lightseagreen';
          });

          searchFilterInput.addEventListener('blur', () => {
            searchFilterInput.style.border = '';
            searchFilterInput.style.outline = '';
          });

          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);

          const searchIcon = document.createElement('i');
          searchIcon.className = 'fa fa-search';
          searchIcon.style.position = 'absolute';
          searchIcon.style.left = '10px';
          searchIcon.style.top = '16px';
          searchIcon.style.transform = 'translateY(-50%)';
          searchIcon.style.pointerEvents = 'none';
          searchIcon.style.color = '#aaa';

          searchContainer.appendChild(searchIcon);
        }
      },
    };
  }
  ngAfterViewInit(): void {
    if (this.datatableElement2 && this.datatableElement2.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  refreshDataTable(): void {
    this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();

      // var visColumns =  this.visibleColumns;
      // var index = 0;
      // dtInstance.columns().every(function () {
      //   const that = this;
      //   if(visColumns && visColumns.length > 0)
      //   {
      //         if(visColumns.indexOf(index) < 0)
      //           this.visible(false);
      //   }
      //   index++;

      //   $('input', this.footer()).on('keyup change', function () {
      //     if (that.search() !== this['value']) {
      //       that.search(this['value']).draw();
      //     }
      //   });
      // });
    });
  }

  addPageNavigationControls() {
    let buttonsElement = $('.dt-buttons')[0];

    // $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
    // $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);

    // $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button dt-custom-pagination-buttons disabled' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button dt-custom-pagination-buttons' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button dt-custom-pagination-buttons' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }
    // style='float:left !important;'
    $("<span class='dt-pagination-input-span' > Page <span>").appendTo(
      buttonsElement
    );
    $(
      "<input class='dt-pagination-input' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text' maxlength='6'/>"
    ).appendTo(buttonsElement);
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      (this as HTMLInputElement).value = value;
    });
    $(
      "<span class='dt-pagination-input-span pl-1'> of " +
        this.totalPages +
        ' <span>'
    ).appendTo(buttonsElement);
    $(
      "<button  class='dt-button dt-custom-pagination-buttons-goto' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button dt-custom-pagination-buttons disabled' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button dt-custom-pagination-buttons' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button dt-custom-pagination-buttons' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    // $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnFirstPage').on('click', (event) => this.changePageination(1));
    // $('#btnLastPage').on('click', (event) =>this.nevigatePage.emit(this.totalPages));
    $('#btnLastPage').on('click', (event) =>
      this.changePageination(this.totalPages)
    );
  }

  changePageination(pageNo: number) {
    if (
      pageNo != this.currentPageV1 &&
      pageNo > 0 &&
      pageNo <= this.totalPages
    ) {
      this.currentPageV1 = pageNo;
      this.currentPage = pageNo;
      this.getFaxFileterList(this.currentPageV1);
    }
  }

  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.changePageination(this.currentPage);
    // this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      // this.nevigatePage.emit(pageno);
      this.changePageination(pageno);
    }
  }

  formInitiliZation() {
    this.patientEntrySearchForm = this.fb.group({
      drpBranchtype: new FormControl(''),
      drpInsurance: new FormControl(''),
      drpTAT: new FormControl(''),
      drpPrimaryInsurance: new FormControl(''),
      drpTechnician: new FormControl(''),
      drpSecheduleStatus: new FormControl(''),
      drpChecklistStatus: new FormControl(''),
      drpAuthStatus: new FormControl(''),
      drpCSRName: new FormControl(''),
      drpPractice: new FormControl(''),
      drpSorting: new FormControl(''),
      drpSplit: new FormControl(''),
      drpJoin: new FormControl(''),
      drpVerificationStatus: new FormControl(''),
      txtBranchTypeV1: new FormControl(''),
      drptatControl: new FormControl(''),
      txtCSRTypeV1: new FormControl(''),
      txtPracticeType: new FormControl(''),
      txtSplitSearch: new FormControl(''),
      txtJoinSearch: new FormControl(''),
      txtInsuranceTypeV1: new FormControl(''),
      txtInsuranceSearch: new FormControl(''),
      txtPrimaryInsuranceType: new FormControl(''),
    });
  }

  //TAT Multi Select
  onSelectionTATChange(event: MatSelectChange): void {
    this.selectedTATList = event.value;
    this.isTATSelectionChange = true;
    if (this.selectedTATList.includes('selectAll')) {
      this.TATSelectAllOption = true;
      this.selectedTATList = this.drptatControl.map((data) => data.tatId);
      this.patientEntrySearchForm
        .get('drptatControl')
        .patchValue(this.selectedTATList);
    } else if (this.selectedTATList.includes('DeselectAll')) {
      this.selectedTATList = [];
      this.TATSelectAllOption = false;
      this.patientEntrySearchForm.get('drptatControl').patchValue([]);
    } else {
      this.TATSelectAllOption =
        this.selectedTATList.length === this.drptatControl.length;
    }
  }

  //Insurance Multi Select
  onSelectionInsuranceChange(event: MatSelectChange): void {
    this.selectedInsuranceList = event.value;
    this.isInsuranceSelectionChange = true;
    if (this.selectedInsuranceList.includes('selectAll')) {
      this.InsuranceSelectAllOption = true;
      this.selectedInsuranceList = this.drpInsurance.map(
        (data) => data.payerId
      );
      this.patientEntrySearchForm
        .get('drpInsurance')
        .patchValue(this.selectedInsuranceList);
    } else if (this.selectedInsuranceList.includes('DeselectAll')) {
      this.selectedInsuranceList = [];
      this.InsuranceSelectAllOption = false;
      this.patientEntrySearchForm.get('drpInsurance').patchValue([]);
    } else {
      this.InsuranceSelectAllOption =
        this.selectedInsuranceList.length === this.drpInsurance.length;
    }
    this.selectedInsValue = this.patientEntrySearchForm.value.drpInsurance;
  }

  //Primary Insurance Multi Select
  onSelectionPrimaryInsuranceChange(event: MatSelectChange): void {
    this.selectedPrimaryInsuranceList = event.value;
    this.isPriInsSelectionChange = true;
    if (this.selectedPrimaryInsuranceList.includes('selectAll')) {
      this.PrimaryInsuSelectAllOption = true;
      this.selectedPrimaryInsuranceList = this.drpPrimaryInsurance.map(
        (data) => data.payerId
      );
      this.patientEntrySearchForm
        .get('drpPrimaryInsurance')
        .patchValue(this.selectedPrimaryInsuranceList);
    } else if (this.selectedPrimaryInsuranceList.includes('DeselectAll')) {
      this.selectedPrimaryInsuranceList = [];
      this.PrimaryInsuSelectAllOption = false;
      this.patientEntrySearchForm.get('drpPrimaryInsurance').patchValue([]);
    } else {
      this.PrimaryInsuSelectAllOption =
        this.selectedPrimaryInsuranceList.length ===
        this.drpPrimaryInsurance.length;
    }
    this.selectedPriInsValue =
      this.patientEntrySearchForm.value.drpPrimaryInsurance;
  }

  //Verificaion Status Multi Select
  onSelectionVerifiacationChange(event: MatSelectChange): void {
    this.selectedVerificationList = event.value;
    this.isverifStatusSelectionChange = true;
    if (this.selectedVerificationList.includes('selectAll')) {
      this.VerificationSelectAllOption = true;
      this.selectedVerificationList = this.drpVerificationStatus.map(
        (data) => data.value
      );
      this.patientEntrySearchForm
        .get('drpVerificationStatus')
        .patchValue(this.selectedVerificationList);
    } else if (this.selectedVerificationList.includes('DeselectAll')) {
      this.selectedVerificationList = [];
      this.VerificationSelectAllOption = false;
      this.patientEntrySearchForm.get('drpVerificationStatus').patchValue([]);
    } else {
      this.VerificationSelectAllOption =
        this.selectedVerificationList.length ===
        this.drpVerificationStatus.length;
    }
  }

  //Checklist Multi Select
  onSelectionChecklistChange(event: MatSelectChange): void {
    this.selectedChecklistList = event.value;
    this.isCheckListSelectionChange = true;
    if (this.selectedChecklistList.includes('selectAll')) {
      this.checklistSelectAllOption = true;
      this.selectedChecklistList = this.drpChecklistStatus.map(
        (data) => data.key
      );
      this.patientEntrySearchForm
        .get('drpChecklistStatus')
        .patchValue(this.selectedChecklistList);
    } else if (this.selectedChecklistList.includes('DeselectAll')) {
      this.selectedChecklistList = [];
      this.checklistSelectAllOption = false;
      this.patientEntrySearchForm.get('drpChecklistStatus').patchValue([]);
    } else {
      this.checklistSelectAllOption =
        this.selectedChecklistList.length === this.drpChecklistStatus.length;
    }
  }

  //Schedule Status Multi Select
  onSelectionScheduleChange(event: MatSelectChange): void {
    this.selectedScheduleList = event.value;
    this.isSchStatusSelectionChange = true;
    if (this.selectedScheduleList.includes('selectAll')) {
      this.scheduleSelectAllOption = true;
      this.selectedScheduleList = this.drpSecheduleStatus.map(
        (data) => data.scheValues
      );
      this.patientEntrySearchForm
        .get('drpSecheduleStatus')
        .patchValue(this.selectedScheduleList);
    } else if (this.selectedScheduleList.includes('DeselectAll')) {
      this.selectedScheduleList = [];
      this.scheduleSelectAllOption = false;
      this.patientEntrySearchForm.get('drpSecheduleStatus').patchValue([]);
    } else {
      this.scheduleSelectAllOption =
        this.selectedScheduleList.length === this.drpSecheduleStatus.length;
    }
  }

  //Auth Status Multi Select
  onSelectionAuthChange(event: MatSelectChange): void {
    this.selectedAuthList = event.value;
    this.isAuthStatusSelectionChange = true;
    if (this.selectedAuthList.includes('selectAll')) {
      this.AuthSelectAllOption = true;
      this.selectedAuthList = this.drpAuthStatus.map((data) => data.authId);
      this.patientEntrySearchForm
        .get('drpAuthStatus')
        .patchValue(this.selectedAuthList);
    } else if (this.selectedAuthList.includes('DeselectAll')) {
      this.selectedAuthList = [];
      this.AuthSelectAllOption = false;
      this.patientEntrySearchForm.get('drpAuthStatus').patchValue([]);
    } else {
      this.AuthSelectAllOption =
        this.selectedAuthList.length === this.drpAuthStatus.length;
    }
  }
  //GET Branch Drop Dwon
  getBrachDropDown(branchName: string, branchId: string[]) {
    if (this.BranchDrpubscription) {
      this.BranchDrpubscription.unsubscribe();
    }
    this.isBranchLoading = true;
    this.BranchDrpubscription = this.organizationunitService
      .getBranchListByNameAndSBranch(branchName, branchId)
      // this.BranchDrpubscription = this.organizationunitService
      //   .getBranchList(this.tenantId)
      .subscribe(
        (response) => {
          this.isBranchLoading = false;
          this.drpBranchtype = response || [];
          this.lstFilterBranch = this.patientEntrySearchForm
            ?.get('txtBranchTypeV1')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranchtype?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isBranchLoading = false;
        }
      );
    this.subscription$.push(this.BranchDrpubscription);
  }

  //GET CSR Name Drop Dwon
  getCSRNameDropDown(CSRName: string, CSRId: string[]) {
    if (this.CsrDrpbscription) {
      this.CsrDrpbscription.unsubscribe();
    }
    this.isCsrLoading = true;
    this.CsrDrpbscription = this.masterproviderservice
      .getCsrNameByCNameAndSCSR(CSRName, CSRId)
      .subscribe(
        (response) => {
          this.isCsrLoading = false;
          this.drpCSRName = response || [];
          this.lstFilterCSR = this.patientEntrySearchForm
            ?.get('txtCSRTypeV1')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpCSRName?.filter((option) =>
                  option?.userName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isCsrLoading = false;
        }
      );
    this.subscription$.push(this.CsrDrpbscription);
  }

  //GET CSR Name Drop Dwon
  getPracticeDropDown(practiceName: string, practiceId: string[]) {
    if (this.practiceDrpsubcription) {
      this.practiceDrpsubcription.unsubscribe();
    }
    this.isPracticeLoading = true;
    this.practiceDrpsubcription = this.practicemanagementservice
      .getDropPracticeByPNameAndSPractice(practiceName, practiceId)
      .subscribe(
        (response) => {
          this.isPracticeLoading = false;
          this.drpPractice = response || [];
          this.lstFilterPractice = this.patientEntrySearchForm
            ?.get('txtPracticeType')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpPractice?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isPracticeLoading = false;
        }
      );
    this.subscription$.push(this.practiceDrpsubcription);
  }

  //GET Spilt Drop Down
  getSplitDropDown(FaxName: string, faxId: string[]) {
    if (this.SplitandJoinDrpsubcription) {
      this.SplitandJoinDrpsubcription.unsubscribe();
    }
    this.isSplitLoading = true;
    this.SplitandJoinDrpsubcription = this.inbounddocumentservice
      .getFaxIdByFaxDocAndSearchfax(FaxName, faxId)
      .subscribe(
        (response) => {
          this.isSplitLoading = false;
          this.drpSplit = response || [];
          this.lstFilterSplit = this.patientEntrySearchForm
            ?.get('txtSplitSearch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpSplit?.filter((option) =>
                  option?.faxId
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isSplitLoading = false;
        }
      );
    this.subscription$.push(this.SplitandJoinDrpsubcription);
  }

  //GET Join Drop Down
  getJoinDropDown(FaxName: string, faxId: string[]) {
    if (this.JoinDrpsubcription) {
      this.JoinDrpsubcription.unsubscribe();
    }
    this.isJoinLoading = true;
    faxId = faxId ?? [];
    this.JoinDrpsubcription = this.inbounddocumentservice
      .getFaxIdByFaxDocAndSearchfax(FaxName, faxId)
      .subscribe(
        (response) => {
          this.isJoinLoading = false;
          this.drpJoin = response || [];
          this.lstFilterJoin = this.patientEntrySearchForm
            ?.get('txtJoinSearch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpJoin?.filter((option) =>
                  option?.faxId
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isJoinLoading = false;
        }
      );
    this.subscription$.push(this.JoinDrpsubcription);
  }

  //GET Auth Status DropDowm
  loadAuthStatusDropdowns() {
    const getPatientDropdown = this.inbounddocumentservice
      .getAuthStatusListByANameAndSAuth('', [])
      .subscribe((response) => {
        this.drpAuthStatus = response;
        this.ltAuthStatus = this.patientEntrySearchForm
          ?.get('txtInsuranceSearch')
          ?.valueChanges?.pipe(
            startWith(''),
            map((value) =>
              this.drpAuthStatus?.filter((option) =>
                option?.authStatus
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(getPatientDropdown);
  }

  //GET Primary Insurance List

  getInsuranceDropDown(payerName: string, payerId: string[]) {
    if (this.payerDrpsubcription) {
      this.payerDrpsubcription.unsubscribe();
    }
    this.isInsuranceLoading = true;
    this.payerDrpsubcription = this.payerDetailService
      .getPayorListByPayerDetaislAndSPayer(payerName, payerId)
      .subscribe(
        (response) => {
          this.isInsuranceLoading = false;
          this.drpInsurance = response || [];
          this.drpPrimaryInsurance = response || [];

          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payorCode === null ||
              x?.payorCode === '' ||
              (x?.payorCode).toLowerCase() == 'null'
                ? '-'
                : x?.payorCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName;
            return x;
          });
          this.drpInsurance = this.selectPayorList;
          this.drpPrimaryInsurance = this.selectPayorList;
          this.filteredPrimaryPayors = this.patientEntrySearchForm
            .get('txtInsuranceTypeV1')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          this.filteredInsurancePayors = this.patientEntrySearchForm
            .get('txtInsuranceTypeV1')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isInsuranceLoading = false;
        }
      );
  }

  payerSearch(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
    ) {
      const insuranceId = this.patientEntrySearchForm.value.drpInsurance || '';
      //Get Payer list
      this.isInsuranceLoading = true;
      if (this.PrimayPayerDetails) {
        this.PrimayPayerDetails.unsubscribe();
      }
      let eligibilityMode = 4;
      this.PrimayPayerDetails = this.payerDetailService
        .getdrpPayerByIModeV1(eligibilityMode, value, insuranceId)
        .subscribe((response) => {
          this.selectPayorList = response;
          this.isInsuranceLoading = false;
          // this.selectPayorList = response.map((x) => {
          //   x['payerShortCodeList'] =
          //     (x?.payerCode === null ||
          //     x?.payerCode === '' ||
          //     (x?.payerCode).toLowerCase() == 'null'
          //       ? '-'
          //       : x?.payerCode) +
          //     ' ' +
          //     '/' +
          //     ' ' +
          //     x?.payerName +
          //     ' ' +
          //     '/' +
          //     ' ' +
          //     (x?.code === null ||
          //     x?.code === '' ||
          //     (x?.code).toLowerCase() == 'null'
          //       ? '-'
          //       : x?.code);
          //   return x;
          // });

          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName;
            return x;
          });

          this.filteredPrimaryPayors = this.patientEntrySearchForm
            .get('txtInsuranceTypeV1')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }

  payerPrimSearch(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
    ) {
      const insuranceId =
        this.patientEntrySearchForm.value.drpPrimaryInsurance || '';
      //Get Payer list
      this.isInsuranceLoading = true;
      if (this.PrimayPayerDetails) {
        this.PrimayPayerDetails.unsubscribe();
      }
      let eligibilityMode = 4;
      this.PrimayPayerDetails = this.payerDetailService
        .getdrpPayerByIModeV1(eligibilityMode, value, insuranceId)
        .subscribe((response) => {
          this.selectPayorList = response;
          this.isInsuranceLoading = false;
          // this.selectPayorList = response.map((x) => {
          //   x['payerShortCodeList'] =
          //     (x?.payerCode === null ||
          //     x?.payerCode === '' ||
          //     (x?.payerCode).toLowerCase() == 'null'
          //       ? '-'
          //       : x?.payerCode) +
          //     ' ' +
          //     '/' +
          //     ' ' +
          //     x?.payerName +
          //     ' ' +
          //     '/' +
          //     ' ' +
          //     (x?.code === null ||
          //     x?.code === '' ||
          //     (x?.code).toLowerCase() == 'null'
          //       ? '-'
          //       : x?.code);
          //   return x;
          // });

          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName;
            return x;
          });

          this.filteredInsurancePayors = this.patientEntrySearchForm
            .get('txtInsuranceTypeV1')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }

  isBranchDropdownOpen = false;
  onBranchDropdownOpenChange(isOpen: boolean, branchId: string[]) {
    this.isBranchDropdownOpen = isOpen;
    if (this.isBranchDropdownOpen) {
      this.patientEntrySearchForm.patchValue({
        txtBranchTypeV1: '',
      });
      this.getBrachDropDown('', branchId);
    }
  }

  isInsDropdownOpen = false;
  onInsDropdownOpenChange(isOpen: boolean, InsuranceId: string[]) {
    this.isInsDropdownOpen = isOpen;
    if (this.isInsDropdownOpen) {
      this.patientEntrySearchForm.patchValue({
        txtInsuranceTypeV1: '',
      });
      this.getInsuranceDropDown('', InsuranceId);
    }
  }

  isPrimInsDropdownOpen = false;
  onPrimInsDropdownOpenChange(isOpen: boolean, primInsuranceId: string[]) {
    this.isPrimInsDropdownOpen = isOpen;
    if (this.isPrimInsDropdownOpen) {
      this.patientEntrySearchForm.patchValue({
        txtPrimaryInsuranceType: '',
      });
      this.getInsuranceDropDown('', primInsuranceId);
    }
  }

  isCSrDropdownOpen = false;
  onCSRDropdownOpenChange(isOpen: boolean, csrId: string[]) {
    this.isCSrDropdownOpen = isOpen;
    if (this.isCSrDropdownOpen) {
      this.patientEntrySearchForm.patchValue({
        txtCSRTypeV1: '',
      });
      this.getCSRNameDropDown('', csrId);
    }
  }

  isPracticeDropdownOpen = false;
  onPracticeDropdownOpenChange(isOpen: boolean, practiceId: string[]) {
    this.isPracticeDropdownOpen = isOpen;
    if (this.isPracticeDropdownOpen) {
      this.patientEntrySearchForm.patchValue({
        txtPracticeType: '',
      });
      this.getPracticeDropDown('', practiceId);
    }
  }

  isSplitDropdownOpen = false;
  onSplitDropdownOpenChange(isOpen: boolean, splitId: string[]) {
    this.isSplitDropdownOpen = isOpen;
    if (this.isSplitDropdownOpen) {
      this.patientEntrySearchForm.patchValue({
        txtSplitSearch: '',
      });
      this.getSplitDropDown('', splitId);
    }
  }

  isJoinDropdownOpen = false;
  onJoinDropdownOpenChange(isOpen: boolean, joinId: string[]) {
    this.isJoinDropdownOpen = isOpen;
    if (this.isJoinDropdownOpen) {
      this.patientEntrySearchForm.patchValue({
        txtJoinSearch: '',
      });
      this.getJoinDropDown('', joinId);
    }
  }
  //Branch Multi Select
  // onSelectionBranchChange(event: MatSelectChange): void {
  //   this.selectedBranchList = event.value;
  //   console.log(event);
  //   this.isLoactionSelectionChange = true;
  //   if (this.selectedBranchList.includes('selectAll')) {
  //     this.BranchSelectAllOption = true;
  //     if (this.BranchSelectAllOption) {
  //       this.selectedBranchList = this.drpBranchtype.map((data) => data.id);
  //       this.patientEntrySearchForm
  //         .get('drpBranchtype')
  //         .patchValue(this.selectedBranchList);
  //     }
  //   } else if (this.selectedBranchList.includes('DeselectAll')) {
  //     this.selectedBranchList = [];
  //     this.BranchSelectAllOption = false;
  //     this.patientEntrySearchForm.get('drpBranchtype').patchValue([]);
  //   } else {
  //     this.BranchSelectAllOption =
  //       this.selectedBranchList.length === this.drpBranchtype.length;
  //   }
  //   this.selectedBranchValue = this.patientEntrySearchForm.value.drpBranchtype;
  // }


  onSelectionBranchChange(event: MatSelectChange): void {
    this.selectedBranchList = event.value;
    this.isLoactionSelectionChange = true;
    if (this.selectedBranchList.includes('selectAll')) {
      this.BranchSelectAllOption = true;
      this.selectedBranchList = this.drpBranchtype.map((data) => data.orgId);
      this.patientEntrySearchForm
        .get('drpBranchtype')
        .patchValue(this.selectedBranchList);
    } else if (this.selectedBranchList.includes('DeselectAll')) {
      this.selectedBranchList = [];
      this.BranchSelectAllOption = false;
      this.patientEntrySearchForm.get('drpBranchtype').patchValue([]);
    } else {
      this.BranchSelectAllOption =
        this.selectedBranchList.length === this.drpBranchtype.length;
    }
    this.selectedBranchValue = this.patientEntrySearchForm.value.drpBranchtype;
  }









  //CSR Multi Select
  onSelectionCSRChange(event: MatSelectChange): void {
    this.selectedCSRList = event.value;
    this.isCSRSelectionChange = true;
    if (this.selectedCSRList.includes('selectAll')) {
      this.csrSelectAllOption = true;
      this.selectedCSRList = this.drpCSRName.map((data) => data.csrId);
      this.patientEntrySearchForm
        .get('drpCSRName')
        .patchValue(this.selectedCSRList);
    } else if (this.selectedCSRList.includes('DeselectAll')) {
      this.selectedCSRList = [];
      this.csrSelectAllOption = false;
      this.patientEntrySearchForm.get('drpCSRName').patchValue([]);
    } else {
      this.csrSelectAllOption =
        this.selectedCSRList.length === this.drpCSRName.length;
    }
    this.selectedCSRValue = this.patientEntrySearchForm.value.drpCSRName;
  }

  //Practice Multi Select
  onSelectionPracticeChange(event: MatSelectChange): void {
    this.selectedPracticeList = event.value;
    this.iPracticeSelectionChange = true;
    if (this.selectedPracticeList.includes('selectAll')) {
      this.practiceSelectAllOption = true;
      this.selectedPracticeList = this.drpPractice.map(
        (data) => data.practiceID
      );
      this.patientEntrySearchForm
        .get('drpPractice')
        .patchValue(this.selectedPracticeList);
    } else if (this.selectedPracticeList.includes('DeselectAll')) {
      this.selectedPracticeList = [];
      this.practiceSelectAllOption = false;
      this.patientEntrySearchForm.get('drpPractice').patchValue([]);
    } else {
      this.practiceSelectAllOption =
        this.selectedPracticeList.length === this.drpPractice.length;
    }
    this.selectedPracticeValue = this.patientEntrySearchForm.value.drpPractice;
  }

  //Split Multi Select
  onSelectionSplitChange(event: MatSelectChange): void {
    this.selectedSplitList = event.value;
    this.isSplitSelectionChange = true;
    if (this.selectedSplitList.includes('selectAll')) {
      this.splitSelectAllOption = true;
      this.selectedSplitList = this.drpSplit.map((data) => data.sfax);
      this.patientEntrySearchForm
        .get('drpSplit')
        .patchValue(this.selectedSplitList);
    } else if (this.selectedSplitList.includes('DeselectAll')) {
      this.selectedSplitList = [];
      this.splitSelectAllOption = false;
      this.patientEntrySearchForm.get('drpSplit').patchValue([]);
    } else {
      this.splitSelectAllOption =
        this.selectedSplitList.length === this.drpSplit.length;
    }
    this.selectedSplitValue = this.patientEntrySearchForm.value.drpSplit;
  }

  //Join Multi Select
  onSelectionJoinChange(event: MatSelectChange): void {
    this.selectedJoinList = event.value;
    this.isJoinSelectionChange = true;
    if (this.selectedJoinList.includes('selectAll')) {
      this.joinSelectAllOption = true;
      this.selectedJoinList = this.drpJoin.map((data) => data.sfax);
      this.patientEntrySearchForm
        .get('drpJoin')
        .patchValue(this.selectedJoinList);
    } else if (this.selectedJoinList.includes('DeselectAll')) {
      this.selectedJoinList = [];
      this.joinSelectAllOption = false;
      this.patientEntrySearchForm.get('drpJoin').patchValue([]);
    } else {
      this.joinSelectAllOption =
        this.selectedJoinList.length === this.drpJoin.length;
    }
    this.selectedJointValue = this.patientEntrySearchForm.value.drpJoin;
  }

  onSelectionSortingChange(event: MatSelectChange): void {
    this.isSortingSelectionChange = true;
  }
  //#endregion

  updateColumnDefs() {
    let hiddenColumns = [];
    switch (this.activeTabBin) {
      case MyQueueBinStatus.Unprocessed:
        this.dtDocumentBinTableOptions.columnDefs = [
          {
            targets: [
              6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
              23, 24, 25, 26, 27, 28,
            ],
            visible: false,
          },
        ];
        hiddenColumns = [0, 6, 7, 8, 9, 10, 18, 20, 21, 22, 28];
        break;
      case MyQueueBinStatus.Verification:
        this.dtDocumentBinTableOptions.columnDefs = [
          {
            targets: [
              5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
              23, 24, 25, 26, 27, 28,
            ],
            visible: false,
          },
        ];
        hiddenColumns = [0, 5, 7, 8, 9, 10, 18, 22, 28];
        break;
      case MyQueueBinStatus.Precert:
        this.dtDocumentBinTableOptions.columnDefs = [
          {
            targets: [
              5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
              23, 24, 25, 26, 27, 28,
            ],
            visible: false,
          },
        ];
        hiddenColumns = [0, 5, 6, 8, 9, 10, 18, 28];
        break;
      case MyQueueBinStatus.Checklist:
        this.dtDocumentBinTableOptions.columnDefs = [
          {
            targets: [
              5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
              23, 24, 25, 26, 27, 28,
            ],
            visible: false,
          },
        ];
        hiddenColumns = [0, 5, 6, 7, 9, 10];
        break;
      case MyQueueBinStatus.Schedule:
        this.dtDocumentBinTableOptions.columnDefs = [
          {
            targets: [
              5, 6, 7, 8, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
              24, 25, 26, 27, 28,
            ],
            visible: false,
          },
        ];
        hiddenColumns = [0, 5, 6, 7, 8];
        break;
      case MyQueueBinStatus.ReadyforOrder:
        this.dtDocumentBinTableOptions.columnDefs = [
          {
            targets: [
              6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
              23, 24, 25, 26, 27, 28,
            ],
            visible: false,
          },
        ];
        hiddenColumns = [0, 6, 7, 8, 9, 10, 18, 20, 21, 22, 28];
        break;
      default:
        this.dtDocumentBinTableOptions.columnDefs = [
          {
            targets: [
              11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
              27, 28,
            ],
            visible: false,
          },
        ];
        hiddenColumns = [0];
        break;
    }
    this.dtDocumentBinTableOptions.buttons[0].exportOptions.columns = function (
      idx,
      data,
      node
    ) {
      return hiddenColumns.indexOf(idx) === -1;
    };
  }

  //#region DoubleClick Copy Text
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  //#endRegio DoubleClick Copy Text

  getTotalInboundData(event: Event, inboundData: any) {
    const target = event.target as HTMLElement;
    const tr = target.closest('tr');
    const icon = target as HTMLElement;
    this.getDataTableData2(tr, inboundData, icon);
  }

  //#region History Tables

  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData2(
    tr: any,
    inboundData: any,
    icon: HTMLElement
  ): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;
    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        icon.innerText = 'add';
      } else {
        // Open this row
        row.child(this.format2(row.data(), inboundData)).show();
        row.child().addClass('no-padding');
        icon.innerText = 'remove';
      }
    }
  }

  //Add Dynamic Row
  format2(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      // { text: 'TAT', key: 'tat' },
      // { text: 'Fax Id', key: 'faxId' },
      { text: 'Document', key: 'document' },
      { text: 'Modified', key: 'modifiedByandDate' },
      { text: 'Secondary Insurance', key: 'secInsurance' },
      { text: 'Tertiary Insurance', key: 'terInsurance' },
      { text: 'Checklist Id', key: 'checklistId' },
      { text: 'CSR Name', key: 'csrName' },
      { text: 'Sorting', key: 'sorting' },
      { text: 'Upload Type', key: 'uploadtype' },
      { text: 'Service Type', key: 'servicetype' },
      { text: 'Document CheckList', key: 'docchecklist' },
      { text: 'Practice', key: 'practice' },
      { text: 'Insurance Group', key: 'insuranceGroup' },
      { text: 'Aging Days', key: 'agingDays' },
      { text: 'Split', key: 'split' },
      { text: 'Join', key: 'join' },
      { text: 'Prepared', key: 'prepared' },
    ];

    const headerOrder = {
      [MyQueueBinStatus.Unprocessed]: [
        // 'tat',
        // 'faxId',
        'document',
        'modifiedByandDate',
        'secInsurance',
        'terInsurance',
        'csrName',
        'sorting',
        'practice',
        'insuranceGroup',
        'agingDays',
        'split',
        'join',
        'uploadtype',
        'servicetype',
        'docchecklist',
        'prepared',
        'checklistId',
      ],
      [MyQueueBinStatus.Verification]: [
        // 'tat',
        // 'faxId',
        'document',
        'modifiedByandDate',
        'secInsurance',
        'terInsurance',
        'csrName',
        'sorting',
        'uploadtype',
        'servicetype',
        'insuranceGroup',
        'docchecklist',
        'agingDays',
        'practice',
        'split',
        'join',
        'checklistId',
        'prepared',
      ],
      [MyQueueBinStatus.Precert]: [
        // 'tat',
        // 'faxId',
        'document',
        'modifiedByandDate',
        'secInsurance',
        'terInsurance',
        'csrName',
        'sorting',
        'uploadtype',
        'servicetype',
        'insuranceGroup',
        'docchecklist',
        'agingDays',
        'practice',
        'split',
        'join',
        'checklistId',
        'prepared',
      ],
      [MyQueueBinStatus.Checklist]: [
        // 'tat',
        // 'faxId',
        'document',
        'modifiedByandDate',
        'prepared',
        'secInsurance',
        'terInsurance',
        'checklistId',
        'csrName',
        'uploadtype',
        'sorting',
        'servicetype',
        'insuranceGroup',
        'docchecklist',
        'agingDays',
        'practice',
        'split',
        'join',
      ],
      [MyQueueBinStatus.Schedule]: [
        // 'tat',
        // 'faxId',
        'document',
        'modifiedByandDate',
        'prepared',
        'secInsurance',
        'terInsurance',
        'checklistId',
        'csrName',
        'uploadtype',
        'sorting',
        'servicetype',
        'insuranceGroup',
        'docchecklist',
        'agingDays',
        'practice',
        'split',
        'join',
      ],
      [MyQueueBinStatus.ReadyforOrder]: [
        // 'tat',
        // 'faxId',
        'document',
        'modifiedByandDate',
        'secInsurance',
        'terInsurance',
        'csrName',
        'sorting',
        'practice',
        'insuranceGroup',
        'agingDays',
        'split',
        'join',
        'uploadtype',
        'servicetype',
        'docchecklist',
        'prepared',
        'checklistId',
      ],
    };

    const shouldSkipKey = (key: string): boolean => {
      const skipKeys = {
        [MyQueueBinStatus.Unprocessed]: [
          'docchecklist',
          'checklistId',
          'uploadtype',
          'servicetype',
          'prepared',
        ],
        [MyQueueBinStatus.ReadyforOrder]: [
          'docchecklist',
          'checklistId',
          'uploadtype',
          'servicetype',
          'prepared',
        ],
        [MyQueueBinStatus.Verification]: ['checklistId', 'prepared'],
        [MyQueueBinStatus.Precert]: ['checklistId', 'prepared'],
      };
      return skipKeys[this.activeTabBin]?.includes(key);
    };

    const orderedHeaders = headerOrder[this.activeTabBin]
      .map((key) => headers.find((header) => header.key === key))
      .filter((header) => header);

    orderedHeaders.forEach((header) => {
      if (shouldSkipKey(header.key)) return;
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      if (header.key === 'modifiedByandDate') {
        this.renderer.setStyle(th, 'width', '200px');
      }
      th.setAttribute('title', header.text);
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }

    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        // {
        //   text: value?.tat || '-',
        //   key: 'tat',
        //   style: { color: this.getValueColor(value?.isTatDone) },
        // },
        // { text: value?.defaultFaxId || '-', key: 'faxId' },
        { text: value.documentName || '-', key: 'document' },
        { text: value?.modifiedByAndDateText || '-', key: 'modifiedByandDate' },
        { text: value.secPolicyName || '-', key: 'secInsurance' },
        { text: value.terPolicyName || '-', key: 'terInsurance' },
        { text: value.checklistId || '-', key: 'checklistId' },
        { text: value.csrName || '-', key: 'csrName' },
        {
          text:
            value.isSorted !== null &&
            value.isSorted !== undefined &&
            value.isSorted !== ''
              ? value.isSorted
                ? 'Yes'
                : 'No'
              : '-',
          key: 'sorting',
        },
        { text: value.uploadType || '-', key: 'uploadtype' },
        { text: value.serviceType || '-', key: 'servicetype' },

        {
          text:
            value.isDocumentCheckList !== null &&
            value.isDocumentCheckList !== undefined &&
            value.isDocumentCheckList !== ''
              ? value.isDocumentCheckList
                ? 'Yes'
                : 'No'
              : '-',
          key: 'docchecklist',
        },
        { text: value.practice || '-', key: 'practice' },
        { text: value.insuranceGroup || '-', key: 'insuranceGroup' },
        { text: value.agingsDays || '-', key: 'agingDays' },
        { text: value.splitFromFaxId || '-', key: 'split' },
        { text: value.joinedFaxIds || '-', key: 'join' },
        { text: value.preparedOn || '-', key: 'prepared' },
      ];
      const orderedCells = headerOrder[this.activeTabBin]
        .map((key) => cells.find((cell) => cell.key === key))
        .filter((cell) => cell);
      orderedCells.forEach((cell) => {
        if (shouldSkipKey(cell.key)) return;

        const td = this.renderer.createElement('td');
        td.setAttribute('title', cell.text);
        if (cell.key === 'faxId') {
          const a = this.renderer.createElement('a');
          this.renderer.setAttribute(a, 'class', 'preview-link');
          this.renderer.appendChild(a, document.createTextNode(cell.text));
          this.renderer.listen(a, 'click', (event) => {
            event.preventDefault();
            this.onFaxIdClick(value.docId, value.defaultFaxId);
          });
          this.renderer.appendChild(td, a);
        } else {
          this.renderer.appendChild(td, document.createTextNode(cell.text));
        }
        if (cell.style) {
          Object.keys(cell.style).forEach((key) => {
            this.renderer.setStyle(td, key, cell.style[key]);
          });
        }
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  getValueColor(value: number): string {
    if (value == 2) {
      return 'red';
    } else if (value == 1) {
      return 'green';
    } else {
      return 'black';
    }
  }
  onButtonClick(data: any) {}
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  //View Patient Notes
  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
  //View Patient Ledger
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  onFaxIdClick(docId: string, defaultFaxId: string) {
    const url =
      '/#/patient_preview_tiff_document/' + docId + '/' + defaultFaxId;
    window.open(url, '_blank');
  }
  //US Format Date and Time
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  clearSortingDrp() {
    this.patientEntrySearchForm.patchValue({
      drpSorting: null,
    });
    this.getFaxFileterList(1);
  }
  handleLocationCloseDrpdown(value: any): void {
    if (value && this.isLoactionSelectionChange) {
      this.isLoactionSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleInsuranceCloseDrpdown(value: any): void {
    if (value && this.isInsuranceSelectionChange) {
      this.isInsuranceSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleTATCloseDrpdown(value: any): void {
    if (value && this.isTATSelectionChange) {
      this.isTATSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handlePriInsCloseDrpdown(value: any): void {
    if (value && this.isPriInsSelectionChange) {
      this.isPriInsSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleVeriStatusCloseDrpdown(value: any): void {
    if (value && this.isverifStatusSelectionChange) {
      this.isverifStatusSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleSchStatusCloseDrpdown(value: any): void {
    if (value && this.isSchStatusSelectionChange) {
      this.isSchStatusSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleCheckListCloseDrpdown(value: any): void {
    if (value && this.isCheckListSelectionChange) {
      this.isCheckListSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleAuthStatusCloseDrpdown(value: any): void {
    if (value && this.isAuthStatusSelectionChange) {
      this.isAuthStatusSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleCSRdrpCloseDrpdown(value: any): void {
    if (value && this.isCSRSelectionChange) {
      this.isCSRSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handlePracticeDrpCloseDrpdown(value: any): void {
    if (value && this.iPracticeSelectionChange) {
      this.iPracticeSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleSortingCloseDrpdown(value: any): void {
    if (value && this.isSortingSelectionChange) {
      this.isSortingSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleSplitCloseDrpdown(value: any): void {
    if (value && this.isSplitSelectionChange) {
      this.isSplitSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  handleJoinCloseDrpdown(value: any): void {
    if (value && this.isJoinSelectionChange) {
      this.isJoinSelectionChange = false;
      this.getFaxFileterList(1);
    }
  }

  getFaxFileterList(currentPage: number) {
    this.currentPage = currentPage;
    this.currentPageV1 = currentPage;
    this.loadInboundData = true;
    if (this.getInboundTableData) {
      this.getInboundTableData.unsubscribe();
    }
    console.log(this.selectedSplitValue);
    console.log(this.patientEntrySearchForm.value.drpSplit);
    const selectedSplitFaxIds = (this.drpSplit ?? [])
      .filter((data) =>
        (this.patientEntrySearchForm.value.drpSplit ?? []).includes(
          data?.sfax ?? ''
        )
      )
      .map((data) => data?.faxId ?? '');
    const selectedJoinFaxIds = (this.drpJoin ?? [])
      .filter((data) =>
        (this.patientEntrySearchForm.value.drpJoin ?? []).includes(
          data?.sfax ?? ''
        )
      )
      .map((data) => data?.faxId ?? '');
    // const faxId = [...selectedSplitFaxIds, ...selectedJoinFaxIds];

    const selectedInsurance =
      this.patientEntrySearchForm.value.drpInsurance || [];
    const selectedPrimIns =
      this.patientEntrySearchForm.value.drpPrimaryInsurance || [];
    const priPolicyId = [...selectedInsurance, ...selectedPrimIns];
    const locationId = this.patientEntrySearchForm.value.drpBranchtype || [];
    const secPolicyId = this.patientEntrySearchForm.value.drpInsurance || [];
    const terPolicyId = this.patientEntrySearchForm.value.drpInsurance || [];
    const csrId = this.patientEntrySearchForm.value.drpCSRName || [];
    const authStatus = this.patientEntrySearchForm.value.drpAuthStatus || [];
    const practiceId = this.patientEntrySearchForm.value.drpPractice || [];
    const sorting = this.patientEntrySearchForm.value.drpSorting || 0;
    const calendarStatus =
      this.patientEntrySearchForm.value.drpSecheduleStatus || [];
    const tatFilter = this.patientEntrySearchForm.value.drptatControl || [];

    let wonaceRequestDto: patientEntryRequestDto = {
      statusCode: this.inboundStatusId,
      locationId: locationId,
      priPolicyId: priPolicyId,
      secPolicyId: secPolicyId,
      terPolicyId: terPolicyId,
      csrId: csrId,
      authStatus: authStatus,
      practiceId: practiceId,
      sorting: sorting,
      calendarStatus: calendarStatus,
      verificationStatus:
        this.patientEntrySearchForm.value.drpVerificationStatus || [],
      medicareChecklistStatus:
        this.patientEntrySearchForm.value.drpChecklistStatus || [],
      tatFilter: tatFilter,
      splitfaxId: selectedSplitFaxIds,
      joinfaxId: selectedJoinFaxIds,
      pageNo: currentPage,
      pageOffset: this.pageOffset,
    };

    this.getInboundTableData = this.inbounddocumentservice
      .getInboundFaxDocsForWonaceByRequestDto(wonaceRequestDto)
      .subscribe(
        (response) => {
          switch (this.inboundStatusId) {
            case this.inboundStatusIds.unprocessedFax:
              this.totalPages = Math.ceil(
                response?.unprocessedCount / this.pageOffset
              );
              this.overallTableCount = response?.unprocessedCount || 0;
              break;
            case this.inboundStatusIds.verification:
              this.totalPages = Math.ceil(
                response?.verificationCount / this.pageOffset
              );
              this.overallTableCount = response?.verificationCount || 0;
              break;
            case this.inboundStatusIds.preCert:
              this.totalPages = Math.ceil(
                response?.precetCount / this.pageOffset
              );
              this.overallTableCount = response?.precetCount || 0;
              break;
            case this.inboundStatusIds.scheduled:
              this.totalPages = Math.ceil(
                response?.sheduleCount / this.pageOffset
              );
              this.overallTableCount = response?.sheduleCount || 0;
              break;
            case this.inboundStatusIds.medicare:
              this.totalPages = Math.ceil(
                response?.medicareCount / this.pageOffset
              );
              this.overallTableCount = response?.medicareCount || 0;
              break;
            case this.inboundStatusIds.ready:
              this.totalPages = Math.ceil(
                response?.readyCount / this.pageOffset
              );
              this.overallTableCount = response?.readyCount || 0;
              break;
          }
          this.inBoundTableData = response?.ouput;
          this.inBoundTableData = this.inBoundTableData.map((inbound) => {
            const lastModifierByText =
              inbound?.lastModifierBy && inbound.lastModifierBy !== '-'
                ? ' by ' + inbound.lastModifierBy
                : '';
            const modifiedByAndDateText =
              this.commonService.getFormattedDateTimeZone(
                inbound.lastModificationTime
              ) + lastModifierByText;
            return {
              ...inbound,
              uploadedDate: this.commonService.getFormattedDateTimeZone(
                inbound.uploadedDate
              ),
              preparedOn: this.commonService.getFormattedDateTimeZone(
                inbound.preparedOn
              ),
              modifiedByAndDateText: modifiedByAndDateText,
            };
          });
          this.loadInboundData = false;
          const startRecord = (this.currentPage - 1) * this.recordsPerPage + 1;
          const endRecord = Math.min(
            this.currentPage * this.recordsPerPage,
            this.overallTableCount
          );
          this.dtDocumentBinTableOptions.language.info = `Showing ${startRecord} to ${endRecord} of ${this.overallTableCount} records`;
          setTimeout(() => this.refreshDataTable(), 5);
        },
        (err) => {
          this.loadInboundData = false;
        }
      );
  }

  resetForm() {
    this.AuthSelectAllOption = false;
    this.BranchSelectAllOption = false;
    this.InsuranceSelectAllOption = false;
    this.PrimaryInsuSelectAllOption = false;
    this.splitSelectAllOption = false;
    this.joinSelectAllOption = false;
    this.checklistSelectAllOption = false;
    this.TATSelectAllOption = false;
    this.practiceSelectAllOption = false;
    this.csrSelectAllOption = false;
    this.scheduleSelectAllOption = false;
    this.VerificationSelectAllOption = false;
    this.patientEntrySearchForm.reset();
    this.getFaxFileterList(1);
  }
}
