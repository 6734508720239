import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-referral-practice',
  templateUrl: './referral-practice.component.html',
  styleUrls: ['./referral-practice.component.scss']
})
export class ReferralPracticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
