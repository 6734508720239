import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-billing-payments-filter',
  templateUrl: './billing-payments-filter.component.html',
  styleUrls: ['./billing-payments-filter.component.scss']
})
export class BillingPaymentsFilterComponent implements OnInit {
  fgBillingPaymentFilter:FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.fgBillingPaymentFilter = new FormGroup({
      txtPayer: null,
    });
  }

}
