<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2 mat-dialog-title><B>Claim Info</B></h2>
    </div>
    <div class="col-lg-6"></div>
  </div>
  <!-- <hr /> -->
  <mat-card-content>
    <div class="row">
      <form [formGroup]="patientDetailsForm">
        <mat-checkbox class="col-3 pt-2" formControlName="chkMedicare">1.Medicare(Medicare#)</mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkMedicaid">1.Medicaid(Medicaid#)</mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkTrciare">1.Tricare(ID#/DoD#)</mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkChampva">1.Champva(Member ID#)</mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkGroupPlan">1.Group Health Plan(ID#)</mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkFeca">1.Feca blk lung(ID#)</mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkOther">1.Other(ID#)</mat-checkbox>
        <mat-form-field class="col-3">
          <mat-label>1a.Insured's I.D.Number<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtInsuredPolicyId" maxlength="40" formControlName="txtInsuredPolicyId" matInput type="text" [(ngModel)]="policyNo"
            (input)="onPolicyNoChange($event.target.value)" pattern="[a-zA-Z0-9]*" />
          <mat-error *ngIf="
              patientDetailsForm?.get('txtInsuredPolicyId')?.touched &&
              patientDetailsForm?.get('txtInsuredPolicyId')?.errors?.pattern
            ">
            Enter Only Alphanumeric Only!
          </mat-error>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtInsuredPolicyId')?.touched &&
              patientDetailsForm?.get('txtInsuredPolicyId')?.errors?.required
            ">
            Insured's I.D.Number is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 2.Patient's Name<span class="asterisk">*</span>(LastName,FirstName,MiddleInitial)
          </mat-label>
          <input autocomplete="new-txtFullName" readonly matInput maxlength="40" [(ngModel)]="ptName" (input)="onPtNameChange($event.target.value)"
            formControlName="txtFullName" type="text" />
          <mat-error *ngIf="
              patientDetailsForm?.get('txtFullName')?.touched &&
              patientDetailsForm?.get('txtFullName')?.errors?.required
            ">
            Patient's Name is a required field!
          </mat-error>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtFullName')?.touched &&
              patientDetailsForm?.get('txtFullName')?.errors?.sAlphabets
            ">
            Enter only Alphabets!
          </mat-error>
        </mat-form-field>


        <mat-form-field class="col-3">
          <mat-label> 3.Patient's Birth Date <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtBirthdate" readonly [max]="BirthdateMaxDate" formControlName="txtBirthdate" [(ngModel)]="ptDob"
            (input)="onPtDobChange($event.target.value)" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtBirthdate" [min]="BirthdateMinDate" />
          <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtBirthdate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtBirthdate')?.touched &&
              patientDetailsForm?.get('txtBirthdate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtBirthdate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtBirthdate')?.touched &&
              patientDetailsForm?.get('txtBirthdate')?.errors?.required
            ">
            Birth Date is a required field!
          </mat-error>
          <mat-error *ngIf="
              BirthdateMinDate <=
                patientDetailsForm?.get('txtBirthdate')?.value &&
              !(
                patientDetailsForm?.get('txtBirthdate')?.touched &&
                patientDetailsForm?.get('txtBirthdate')?.errors?.required
              )
            ">
            Enter Past Dates Only!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>3.Gender <span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpGender" [(value)]="gender" maxlength="40">
            <mat-option *ngFor="let test of drpGenderLoop" [value]="test.genderShortCode">
              {{ test.genderName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="gender = ''; $event.stopPropagation()" *ngIf="gender" matSuffix
            (click)="clearGender()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
                    patientDetailsForm?.get('drpGender')?.touched &&
                    patientDetailsForm?.get('drpGender')?.errors?.required
                ">
            Gender is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 4.Insured's Name<span class="asterisk">*</span>(LastName,FirstName,MiddleInitial)
          </mat-label>
          <input autocomplete="new-txtInsrdFullName" matInput maxlength="40" formControlName="txtInsrdFullName" type="text" />
          <mat-error *ngIf="
              patientDetailsForm?.get('txtInsrdFullName')?.touched &&
              patientDetailsForm?.get('txtInsrdFullName')?.errors?.required
            ">
            Insured's Name is a required field!
          </mat-error>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtInsrdFullName')?.touched &&
              patientDetailsForm?.get('txtInsrdFullName')?.errors?.sAlphabets
            ">
            Enter only Alphabets!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 5.Patient's Address(No.,Street) <span class="asterisk">*</span> </mat-label>
          <textarea autocomplete="new-txtAddress" formControlName="txtAddress" [(ngModel)]="ptAddress"
            (input)="onPtAddressChange($event.target.value)" rows="1" matInput maxlength="400"></textarea>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtAddress')?.touched &&
              patientDetailsForm?.get('txtAddress')?.errors?.required
            ">
            Patient Address is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>5.State <span class="asterisk">*</span> </mat-label>
          <mat-select formControlName="txtBillingState" (selectionChange)="asyncOnChangeBillCity($event?.value, 'US')"
            [(value)]="BillingState" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtBillingStateFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of filteredBillingStates | async" [value]="state.stateShortName">
              {{ state.stateName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="BillingState = undefined; $event.stopPropagation()" *ngIf="BillingState" matSuffix
            (click)="clearBillingState()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtBillingState')?.touched &&
                    patientDetailsForm?.get('txtBillingState')?.errors?.required
                  ">
            State is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>5.City <span class="asterisk">*</span></mat-label>
          <mat-select formControlName="txtBillingCity" [(value)]="BillingCity" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtBillingCityFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of filteredBillingCities | async" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="BillingCity = undefined; $event.stopPropagation()" *ngIf="BillingCity" matSuffix
            (click)="clearBillingCity()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>

          <mat-error *ngIf="
                    patientDetailsForm?.get('txtBillingCity')?.touched &&
                    patientDetailsForm?.get('txtBillingCity')?.errors?.required
                  ">
            City is a required field!
          </mat-error>

        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 5.Zip Code <span class="asterisk">*</span> </mat-label>
          <input autocomplete="new-txtBillingPostalCode" matInput maxlength="10" [(ngModel)]="ptZipCode" (input)="onPtZipCodeChange($event.target.value)"
            formControlName="txtBillingPostalCode" pattern="[0-9]*" type="text" />
          <mat-error *ngIf="
              patientDetailsForm?.get('txtBillingPostalCode')?.touched &&
              patientDetailsForm?.get('txtBillingPostalCode')?.errors?.pattern
            ">
            Enter only Numbers!
          </mat-error>

          <mat-error *ngIf="
                    patientDetailsForm?.get('txtBillingPostalCode')?.touched &&
                    patientDetailsForm?.get('txtBillingPostalCode')?.errors?.required
                  ">
            Zip Code is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <span class="mr-1" matPrefix>{{countryPhoneCode}}</span>
          <mat-label> 5.TelePhone(Include Area Code) </mat-label>
          <input autocomplete="new-txtTelephone" matInput readonly maxlength="10" formControlName="txtTelephone" pattern="[0-9]*" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 6.Patient Relationship to insured <span class="asterisk">*</span></mat-label>
          <mat-select [(value)]="relationship" formControlName="drpResponsibleRelationshipType" maxlength="40">
            <mat-option *ngFor="let responsible of responsibleRelationship" [value]="responsible.relationShipCode">
              {{ responsible.relationType }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="relationship = undefined; $event.stopPropagation()" *ngIf="relationship" matSuffix
            (click)="clearRelationship()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
              patientDetailsForm?.get('drpResponsibleRelationshipType')
                .touched &&
              patientDetailsForm?.get('drpResponsibleRelationshipType')?.errors
                ?.required
            ">
            Relationship is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 7.Insured's Address(No.,Street) </mat-label>
          <textarea autocomplete="new-txtInsAddress" formControlName="txtInsAddress" rows="1" matInput maxlength="400"></textarea>
          <mat-error *ngIf="
                        patientDetailsForm?.get('txtInsAddress')?.touched &&
                        patientDetailsForm?.get('txtInsAddress')?.errors?.required
                      ">
            Insured Address is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>7.State</mat-label>
          <mat-select formControlName="txtResponsibleState" (selectionChange)="asyncOnChangeResCity($event?.value,'US')"
            [(value)]="ResponsibleState" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtResponsibleStateFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of filteredResponsibleStates | async" [value]="state.stateShortName">
              {{ state.stateName }}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="patientDetailsForm?.value?.txtResponsibleState" matSuffix
            (click)="clearResponsibleState();$event.stopPropagation()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtResponsibleState')?.touched &&
                    patientDetailsForm?.get('txtResponsibleState')?.errors?.required
                  ">
            State is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>7.City </mat-label>
          <mat-select formControlName="txtResponsibleCity" [(value)]="ResponsibleCity" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtResponsibleCityFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of filteredResponsibleCities | async" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="patientDetailsForm?.value?.txtResponsibleCity" matSuffix
            (click)="clearResponsibleCity();$event.stopPropagation()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>

          <mat-error *ngIf="
                    patientDetailsForm?.get('txtResponsibleCity')?.touched &&
                    patientDetailsForm?.get('txtResponsibleCity')?.errors?.required
                  ">
            City is a required field!
          </mat-error>
        </mat-form-field>



        <mat-form-field class="col-3">
          <mat-label> 7.Zip Code </mat-label>
          <input autocomplete="new-txtRespPostalCode" matInput maxlength="10" formControlName="txtRespPostalCode" pattern="[0-9]*" type="text" />
          <mat-error *ngIf=" patientDetailsForm?.get('txtRespPostalCode')?.touched && patientDetailsForm?.get('txtRespPostalCode')?.errors?.pattern ">
            Enter only Numbers!
          </mat-error>
          <mat-error *ngIf="patientDetailsForm?.get('txtRespPostalCode')?.touched && patientDetailsForm?.get('txtRespPostalCode')?.errors?.required">
            Zip Code is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <span class="mr-1" matPrefix>{{countryPhoneCode}}</span>
          <mat-label> 7.TelePhone(Include Area Code) </mat-label>
          <input autocomplete="new-txtInsTelephone" matInput maxlength="10" formControlName="txtInsTelephone" pattern="[0-9]*" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 8.Reserved for NUCC </mat-label>
          <input autocomplete="new-txtReservedForNucc" matInput maxlength="10" formControlName="txtReservedForNucc" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 9.Other Insured's Name(LastName,FirstName,MiddleInitial) </mat-label>
          <input autocomplete="new-txtReservedForNucc" matInput maxlength="10" formControlName="txtReservedForNucc" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 9a.Other Insured's Policy Or Group Number </mat-label>
          <input autocomplete="new-txtPolicyGroup" matInput maxlength="20" formControlName="txtPolicyGroup" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 9b.Reserved For NUCC Use</mat-label>
          <input autocomplete="new-txtOtherInsuredPolicy" matInput maxlength="10" formControlName="txtOtherInsuredPolicy" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 9c.Reserved For NUCC Use</mat-label>
          <input autocomplete="new-txtReservedNucc" matInput maxlength="10" formControlName="txtReservedNucc" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 9d.Insurance PlanName Or ProgramName <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtInsPlanName" matInput maxlength="100" [(ngModel)]="strPayers" formControlName="txtInsPlanName" type="text" />
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtInsPlanName')?.touched &&
                    patientDetailsForm?.get('txtInsPlanName')?.errors?.required
                  ">
            Insurance PlanName Or ProgramName is a required field!
          </mat-error>
        </mat-form-field>


        <mat-label class="col-3 pt-2"> 10.Is Patient's Condition Related To</mat-label>
        <mat-checkbox class="col-3 pt-2" formControlName="chkEmployment">10a.Employment(Current or Previous)
        </mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkAutoAccident">10b.AutoAccident?</mat-checkbox>
        <mat-checkbox class="col-3 pt-2" formControlName="chkOtherAccident">10c.OtherAccident?</mat-checkbox>
        <mat-checkbox class="col-6 pt-2" formControlName="chkClaimCodes">10d.Claim Codes (Designated By NUCC)
        </mat-checkbox>
        <mat-form-field class="col-3">
          <mat-label>11.Insured's Policy Group Or Feca Number<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="txtPolicyInfoPayor" [(value)]="strPayers" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtPayerFilter"></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let insurance of filteredPayerTypes | async" [value]="insurance?.displayName">
              {{insurance?.displayName}}
            </mat-option>

          </mat-select>
          <mat-error
            *ngIf="patientDetailsForm?.get('txtPolicyInfoPayor')?.touched && patientDetailsForm?.get('txtPolicyInfoPayor')?.errors?.required">
            Insured's Policy Group is a required field!
          </mat-error>
          <button mat-button (click)="strPayers=undefined;$event?.stopPropagation()" *ngIf="strPayers" matSuffix
            (click)="clearPayers()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>11a.Insured's Date Of Birth </mat-label>
          <input autocomplete="new-txtInsBirthdate" [max]="BirthdateMaxDate" formControlName="txtInsBirthdate" matInput maxlength="40" type="text"
            placeholder="(MM/DD/YYYY)" [matDatepicker]="txtInsuredDob" [min]="BirthdateMinDate" />
          <mat-datepicker-toggle matSuffix [for]="txtInsuredDob">
          </mat-datepicker-toggle>
          <mat-datepicker #txtInsuredDob> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtInsBirthdate')?.touched &&
              patientDetailsForm?.get('txtInsBirthdate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtInsBirthdate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtInsBirthdate')?.touched &&
              patientDetailsForm?.get('txtInsBirthdate')?.errors?.required
            ">
            Insured's Date Of Birth is a required field!
          </mat-error>
          <mat-error *ngIf="
              BirthdateMinDate <=
                patientDetailsForm?.get('txtInsBirthdate')?.value &&
              !(
                patientDetailsForm?.get('txtInsBirthdate')?.touched &&
                patientDetailsForm?.get('txtInsBirthdate')?.errors?.required
              )
            ">
            Enter Past Dates Only!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>11a.Gender</mat-label>
          <mat-select formControlName="drpInsGender" maxlength="40">
            <mat-option *ngFor="let test of drpGenderLoop" [value]="test.genderShortCode">
              {{ test.genderName }}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="patientDetailsForm?.value?.drpInsGender" matSuffix
            (click)="clearInsGender();$event.stopPropagation()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
                    patientDetailsForm?.get('drpInsGender')?.touched &&
                    patientDetailsForm?.get('drpInsGender')?.errors?.required
                ">
            Gender is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 11b.Other ClaimId(Designated by NUCC) <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtInsPlanName" matInput maxlength="100" [(ngModel)]="strPayers" formControlName="txtInsPlanName" type="text" />
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 11c.Insurance PlanName Or ProgramName <span class="asterisk">*</span> </mat-label>
          <input autocomplete="new-txtInsPlanName" matInput maxlength="100" [(ngModel)]="strPayers" formControlName="txtInsPlanName" type="text" />
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtInsPlanName')?.touched &&
                    patientDetailsForm?.get('txtInsPlanName')?.errors?.required
                  ">
            Insurance PlanName Or ProgramName is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 11d.Is There Another Health Benefit Plan?</mat-label>
          <input autocomplete="new-txtInsPlanName" matInput maxlength="100" [(ngModel)]="strPayers" formControlName="txtInsPlanName" type="text" />
        </mat-form-field>

        <div class="col-12">
          <mat-label> 12.Patient's Or Authorized Person's Signature I authorize the release of any medical or
            other infromation necessary to process this claim. I also request payment of government benefits
            either to myself or to the party who accepts assignment below</mat-label>
        </div>

        <mat-form-field class="col-6">
          <mat-label> Signed</mat-label>
          <input autocomplete="new-txtSigned" matInput maxlength="10" formControlName="txtSigned" type="text" />
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> Date</mat-label>
          <input autocomplete="new-txtDate" formControlName="txtDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtDate" />
          <mat-datepicker-toggle matSuffix [for]="txtDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtDate')?.touched &&
              patientDetailsForm?.get('txtDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>

        <div class="col-12">
          <mat-label> 13.Insured's Or Authorized Person's Signature I authorize the release of any medical or
            other infromation necessary to process this claim. I also request payment of government benefits
            either to myself or to the party who accepts assignment below</mat-label>
        </div>

        <mat-form-field class="col-12">
          <mat-label> 13.Signed</mat-label>
          <input autocomplete="new-txtSigned" matInput maxlength="10" formControlName="txtSigned" type="text" />
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 14.Date Of Current Illness,Injury Or Pregnancy(LMP)</mat-label>
          <input autocomplete="new-txtCurrentIllnessDate" formControlName="txtCurrentIllnessDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtCurrentIllnessDate" />
          <mat-datepicker-toggle matSuffix [for]="txtCurrentIllnessDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtCurrentIllnessDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtCurrentIllnessDate')?.touched &&
              patientDetailsForm?.get('txtCurrentIllnessDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtCurrentIllnessDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 15.Other Date</mat-label>
          <input autocomplete="new-txtOtherDate" formControlName="txtOtherDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtOtherDate" />
          <mat-datepicker-toggle matSuffix [for]="txtOtherDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtOtherDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtOtherDate')?.touched &&
              patientDetailsForm?.get('txtOtherDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtOtherDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>

        <div class="col-12">
          <mat-label> 16.Dates Patient Unable to work in Current Occupation</mat-label>
        </div>

        <mat-form-field class="col-6">
          <mat-label> 16.From Date</mat-label>
          <input autocomplete="new-txtFromDate" formControlName="txtFromDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtFromDate" />
          <mat-datepicker-toggle matSuffix [for]="txtFromDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtFromDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtFromDate')?.touched &&
              patientDetailsForm?.get('txtFromDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtFromDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 16.To Date</mat-label>
          <input autocomplete="new-txtToDate" formControlName="txtToDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtToDate" />
          <mat-datepicker-toggle matSuffix [for]="txtToDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtToDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtToDate')?.touched &&
              patientDetailsForm?.get('txtToDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtToDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label> 17.Name Of Referring Provider Or Other Source <span class="asterisk">*</span>
          </mat-label>
          <mat-select formControlName="txtReferringDoctor" [(value)]="ReferringDoctor" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtRefDoctorFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let doctor of filteredRefDoctorTypes | async" [value]="doctor.id">
              {{ doctor.fTname }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="ReferringDoctor = ''; $event.stopPropagation()" *ngIf="ReferringDoctor"
            (click)="clearReferringDoctor()" matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtReferringDoctor')?.touched &&
                    patientDetailsForm?.get('txtReferringDoctor')?.errors?.required
                  ">
            Name of Referring Provider Or Other Source !
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 17a.</mat-label>
          <input autocomplete="new-txt17a" matInput maxlength="10" formControlName="txt17a" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 17b.NPI <span class="asterisk">*</span> </mat-label>
          <mat-select formControlName="txtReferringDoctor" [(value)]="ReferringDoctor" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtRefDoctorFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let doctor of filteredRefDoctorTypes | async" [value]="doctor.id">
              {{ doctor.fTname }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="ReferringDoctor = ''; $event.stopPropagation()" *ngIf="ReferringDoctor"
            (click)="clearReferringDoctor()" matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtReferringDoctor')?.touched &&
                    patientDetailsForm?.get('txtReferringDoctor')?.errors?.required
                  ">
            NPI Or ProgramName is a required field!
          </mat-error>

        </mat-form-field>

        <div class="col-12">
          <mat-label> 18.Hospitalization Dates Related To Current Services</mat-label>
        </div>

        <mat-form-field class="col-6">
          <mat-label> 18.From Date</mat-label>
          <input autocomplete="new-txtHosFromDate" formControlName="txtHosFromDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtHosFromDate" />
          <mat-datepicker-toggle matSuffix [for]="txtHosFromDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtHosFromDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtHosFromDate')?.touched &&
              patientDetailsForm?.get('txtHosFromDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtHosFromDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 18.To Date</mat-label>
          <input autocomplete="new-txtHosToDate" formControlName="txtHosToDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtHosToDate" />
          <mat-datepicker-toggle matSuffix [for]="txtHosToDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtHosToDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtHosToDate')?.touched &&
              patientDetailsForm?.get('txtHosToDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtHosToDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 19.Additional Claim Information (Designated by NUCC)</mat-label>
          <input autocomplete="new-txtAdditionalClaimInfo" matInput maxlength="10" formControlName="txtAdditionalClaimInfo" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 20.OutSideLab</mat-label>
          <input autocomplete="new-chkOutsideLab" matInput maxlength="10" formControlName="chkOutsideLab" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 20.$Charges</mat-label>
          <input autocomplete="new-txtCharges" matInput maxlength="10" formControlName="txtCharges" type="text" />
        </mat-form-field>



        <div class="col-12">
          <mat-label> 21.Diagnosis Or Nature Of Illness Or Injury Relate A-L to service line below
            <span class="asterisk">*</span>
          </mat-label>

        </div>

        <mat-form-field class="col-6">
          <mat-label>Referring Provider</mat-label>
          <mat-label>Diagnosis Code-ICD-10 <span class="asterisk">*</span></mat-label>
          <mat-select multiple formControlName="drpDiag10ID" [(value)]="txtIcd10" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtIcd10Filter">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let diagnosis of filteredIcd10Types | async"
              [value]="diagnosis.diagnosisCode10ShortCodeId">
              {{

              diagnosis.diagnosisCode +
              " / " +
              diagnosis.diagnosisCodeTypeData
              }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="txtIcd10 = []; $event.stopPropagation()" *ngIf="txtIcd10" matSuffix
            (click)="clearIcd10()" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
              patientDetailsForm?.get('drpDiag10ID')?.touched &&
              patientDetailsForm?.get('drpDiag10ID')?.errors?.required
            ">
            Diagnosis Code-ICD-10 is a required field!
          </mat-error>
        </mat-form-field>


        <mat-form-field class="col-2">
          <mat-label> 21a</mat-label>
          <input autocomplete="new-txtIcdTen1" readonly matInput maxlength="10" formControlName="txtIcdTen1" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21b</mat-label>
          <input autocomplete="new-txtIcdTen2" readonly matInput maxlength="10" formControlName="txtIcdTen2" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21c</mat-label>
          <input autocomplete="new-txtIcdTen3" readonly matInput maxlength="10" formControlName="txtIcdTen3" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21d</mat-label>
          <input autocomplete="new-txtIcdTen4" readonly matInput maxlength="10" formControlName="txtIcdTen4" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21e</mat-label>
          <input autocomplete="new-txtIcdTen5" readonly matInput maxlength="10" formControlName="txtIcdTen5" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21f</mat-label>
          <input autocomplete="new-txtIcdTen6" readonly matInput maxlength="10" formControlName="txtIcdTen6" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21g</mat-label>
          <input autocomplete="new-txtIcdTen7" readonly matInput maxlength="10" formControlName="txtIcdTen7" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21h</mat-label>
          <input autocomplete="new-txtIcdTen8" readonly matInput maxlength="10" formControlName="txtIcdTen8" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21i</mat-label>
          <input autocomplete="new-txtIcdTen9" readonly matInput maxlength="10" formControlName="txtIcdTen9" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21j</mat-label>
          <input autocomplete="new-txtIcdTen10" readonly matInput maxlength="10" formControlName="txtIcdTen10" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21k</mat-label>
          <input autocomplete="new-txtIcdTen11" readonly matInput maxlength="10" formControlName="txtIcdTen11" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label> 21l</mat-label>
          <input autocomplete="new-txtIcdTen12" readonly matInput maxlength="10" formControlName="txtIcdTen12" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 22.Resubmission Code</mat-label>
          <input autocomplete="new-txtResubmissionCode" matInput maxlength="40" formControlName="txtResubmissionCode" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 22.Original Ref No</mat-label>
          <input autocomplete="new-txtOriginalRefNo" matInput maxlength="40" formControlName="txtOriginalRefNo" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 23.Prior Authorization Number</mat-label>
          <input autocomplete="new-txtPriorNumber" matInput maxlength="40" formControlName="txtPriorNumber" type="text" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 25.Federal Tax ID Number</mat-label>
          <input autocomplete="new-txtFederalTaxIdNumber" matInput maxlength="40" formControlName="txtFederalTaxIdNumber" type="text" />
        </mat-form-field>

        <mat-checkbox class="col-3" formControlName="chkSsn">25.SSN</mat-checkbox>
        <mat-checkbox class="col-3" formControlName="chkEin">25.EIN</mat-checkbox>`

        <mat-form-field class="col-3">
          <mat-label> 26.Patient's Account Number</mat-label>
          <input autocomplete="new-txtPtAccountNumber" readonly matInput maxlength="40" formControlName="txtPtAccountNumber" type="text" />
        </mat-form-field>

        <mat-checkbox class="col-3" formControlName="chkAcceptAssignment">27.Accept Assignment</mat-checkbox>

        <mat-form-field class="col-3">
          <mat-label> 28.$Total Charge</mat-label>
          <input autocomplete="new-txtTotalCharge" readonly matInput maxlength="40" formControlName="txtTotalCharge" type="text" />
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtTotalCharge')?.touched &&
                    patientDetailsForm?.get('txtTotalCharge')?.errors?.required
                  ">
            Total Charge is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> 29.$Amount Paid</mat-label>
          <input autocomplete="new-txtAmountPaid" matInput maxlength="40" formControlName="txtAmountPaid" type="text" />
        </mat-form-field>

        <mat-form-field class="col-12">
          <mat-label> 30.Rsvd for NUCC Use</mat-label>
          <input autocomplete="new-txtRsvd" matInput maxlength="40" formControlName="txtRsvd" type="text" />
        </mat-form-field>

        <div class="col-12">
          <mat-label> 31. Signature Of Physician or supplier including degrees or credentials (I certify that
            the statements on the reverse apply to this bill and are made a part thereof.)</mat-label>
        </div>

        <mat-form-field class="col-6">
          <mat-label> 31.Signed<span class="asterisk">*</span></mat-label>
          <!-- <input matInput maxlength="40" formControlName="txtPhysicianSigned" type="text" /> -->
          <mat-select maxlength="40" [(value)]="provider" formControlName="txtProvider">
            <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
              {{ providers.fullName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="provider = ''; $event.stopPropagation()" *ngIf="provider" matSuffix
            (click)="clearProvider()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
                            patientDetailsForm?.get('txtProvider')?.touched &&
                            patientDetailsForm?.get('txtProvider')?.errors?.required
                            ">
            Provider is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label> 18.Date</mat-label>
          <input autocomplete="new-txtPhysicianSignedDate" formControlName="txtPhysicianSignedDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
            [matDatepicker]="txtPhysicianSignedDate" />
          <mat-datepicker-toggle matSuffix [for]="txtPhysicianSignedDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtPhysicianSignedDate> </mat-datepicker>
          <mat-error *ngIf="
              patientDetailsForm?.get('txtPhysicianSignedDate')?.touched &&
              patientDetailsForm?.get('txtPhysicianSignedDate')?.errors &&
              !(
                BirthdateMinDate <=
                patientDetailsForm?.get('txtPhysicianSignedDate')?.value
              )
            ">
            Enter Valid date
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>32.Service Facility Location Information <span class="asterisk">*</span></mat-label>
          <mat-select maxlength="40" formControlName="txtServiceInfo" [(value)]="generalPlaceOfService">
            <mat-option *ngFor="let pos of drpPlaceOfSerivce" [value]="pos.placeOfServiceCode">
              {{ pos.placeOfServiceType }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearGeneralPlaceOfService()"
            (click)="generalPlaceOfService='';$event.stopPropagation()" *ngIf="generalPlaceOfService" matSuffix
            mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf=" patientDetailsForm?.get('txtServiceInfo')?.touched &&
          patientDetailsForm?.get('txtServiceInfo')?.errors?.required">
            Place Of Service is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 32a.</mat-label>
          <input autocomplete="new-txtServiceInfoA" matInput maxlength="40" formControlName="txtServiceInfoA" type="text" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 32b.</mat-label>
          <input autocomplete="new-txtServiceInfoB" matInput maxlength="40" formControlName="txtServiceInfoB" type="text" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 33.Billing Provider Info & PH#</mat-label>
          <textarea readonly matInput maxlength="400" rows="1" formControlName="txtBillingProvider"
            type="text"></textarea>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 33a <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtBillingProviderA" readonly matInput maxlength="40" formControlName="txtBillingProviderA" type="text" />
          <mat-error *ngIf="
                    patientDetailsForm?.get('txtBillingProviderA')?.touched &&
                    patientDetailsForm?.get('txtBillingProviderA')?.errors?.required
                    ">
            Provider is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> 33b</mat-label>
          <input autocomplete="new-txtBillingProviderB" matInput maxlength="40" formControlName="txtBillingProviderB" type="text" />
        </mat-form-field>

        <div class="col-3">
          Claim Address : {{strClaimAddress}}
        </div>

      </form>
    </div>

  </mat-card-content>
  <div class="row">
    <div class="col-12">
      &nbsp;
      <h4 class="customThemeClass"><b>Item Codes List</b></h4>
      <div class="row pt-1">
        <div class="col-12">
          <table class="table table-responsive">
            <thead>
              <tr>
                <td><b>Option</b></td>
                <td class="col-1"><b>24a.DOS From</b></td>
                <td><b>24a.DOS To</b></td>
                <td><b>24b.POS</b></td>
                <td><b>24c.Emg</b></td>
                <td><b>24d.CPT/HCPCS</b></td>
                <td><b>24d.Modifiers 1</b></td>
                <td><b>24d.Modifiers 2</b></td>
                <td><b>24d.Modifiers 3</b></td>
                <td><b>24d.Modifiers 4</b></td>
                <td><b>24e.Diagonsis Pointer</b></td>
                <td><b>24f.Charges</b></td>
                <td><b>24g.DaysOrUnits</b></td>
                <td><b>24H.EPSDT Family Plan</b></td>
                <td><b>24i.Id.Qual.</b></td>
                <td><b>24j.RenderingProviderId.#</b></td>
                <!-- <td><b>Units</b></td>
                                    <td><b>Total Price</b></td>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of tblPostReponse">
                <td>
                  <a matTooltip="Click To View Provider">
                    <i class="fa fa-eye pointer editBtnColor pl-3" (click)="ViewPosting(item)"></i>
                  </a>
                </td>
                <td>
                  {{ item?.dateFrom | date: "MM/dd/yyyy":"en_US" }}
                </td>
                <td>
                  {{ item?.dateTo | date: "MM/dd/yyyy":"en_US" }}
                </td>
                <td>{{item?.placeOfService}}</td>
                <td></td>
                <td>{{ item?.item }}</td>
                <td>{{ item?.m1 }}</td>
                <td>{{ item?.m2 }}</td>
                <td>{{ item?.m3 }}</td>
                <td>{{ item?.m4 }}</td>
                <td></td>
                <td>$ {{ item?.mfee | number : '1.2-2'}}</td>
                <td>{{ item?.units }}</td>
                <td></td>
                <td></td>
                <td>{{item?.renderingProviderNpi}}</td>


                <!--<td>$ {{ item?.total }}</td> -->

              </tr>
              <tr>
                <td colspan="10"></td>
                <td colspan="1"><b>Total</b></td>
                <td colspan="1">
                  <b>$ {{ totalItemPrice }}</b>
                </td>
                <td colspan="1">
                  <b>{{ totalItemQty }}</b>
                </td>

                <!-- <td colspan="1"></td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor mr-2"
    [disabled]="saveButtonHide || !(!patientDetailsForm.invalid && (patientDetailsForm.dirty) )"
    (click)="savePatientDetails()">
    Save
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
