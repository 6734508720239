<table id="tblInboundSortingPendingList" datatable [dtOptions]="dttblInboundSortingPendingOptions"
  class="tbl hover row-border hover w-100 display">
  <thead>
    <tr>
      <!-- class="text-center" -->
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Fax ID'">Fax ID</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Document Name'">Document Id</th>
      <th [matTooltip]="'Document Name'">Document Name</th>
      <th [matTooltip]="'Uploaded By'">Uploaded By</th>
      <th [matTooltip]="'Uploaded Date'">Uploaded Date</th>
      <th [matTooltip]="'Locked By'">Locked By</th>
      <th [matTooltip]="'Locked At'">Locked At</th>
    </tr>
  </thead>
  <tbody>
    <!-- [ngClass]="
                    tblItemSelectedRowId === data.patientId
                      ? 'row-highlighted'
                      : ''
                  " -->
    <tr *ngFor="let data of inboundSortingPendingListDataArray"
      (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
      <td>
        <ng-container *ngIf="data.isLocked === 2; else case1">
          <mat-icon style="color: #fd0808;">lock</mat-icon>
        </ng-container>

        <ng-template #case1>
          <ng-container *ngIf="data.isLocked === 0 || data.isLocked === 1; else case2">
            <mat-checkbox color="primary" [disabled]="disableLockCheckbox" [matTooltip]="'Lock Checkbox'" class="mt-1"
              [(ngModel)]="data.isLocked" (change)="islockUpdate(data)">
            </mat-checkbox>
          </ng-container>
        </ng-template>

        <ng-template #case2>
          <mat-checkbox color="primary" [disabled]="disableLockCheckbox" [matTooltip]="'Lock Checkbox'" class="mt-1"
            [(ngModel)]="data.isLocked" (change)="islockUpdate(data)">
          </mat-checkbox>
        </ng-template>

      </td>
      <!-- <td (dblclick)="onRowDoubleClick(data?.faxId || '-')" [matTooltip]="data?.faxId || '-'">
        {{data?.faxId || '-'}}
      </td> -->

      <td
      class="custom-width"
      (dblclick)="onRowDoubleClick(data?.faxId || '-')"
      >
      <a
        [matTooltip]="data?.faxId || '-'"
        [class.disabled]="
          isEmpty(data.documentId) || isEmpty(data.faxId)
        "
        [routerLink]="
          '/patient_preview_tiff_document/' +
          data.faxId +
          '/' +
          data.documentId
        "
        target="_blank"
        class="preview-link"
        >{{ data?.faxId  || "-" }}</a
      >
      </td>

      <td (dblclick)="onRowDoubleClick(data?.chartId || '-')" [matTooltip]="data?.chartId || '-'">
        <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="'/patientCreateEditTab/' + data.patientId + '/' + data.documentId" target="_blank"
          class="preview-link">{{data?.chartId || '-'}}
        </a>
      </td>
      <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">{{
        data?.patientName || '-' }}</td>
      <td (dblclick)="onRowDoubleClick(data?.defaultDocument || '-')" [matTooltip]="data?.defaultDocument || '-'">{{
        data?.defaultDocument || '-' }}</td>
      <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'"><a [class.disabled]="isEmpty(data.docId) || isEmpty(data.defaultFaxId)" [routerLink]="'/patient_preview_tiff_document/'+ data.defaultDocument+ '/' + data.documentId" target="_blank" class="preview-link" >
        {{ data?.documentName || '-' }}
      </a></td>
      <td (dblclick)="onRowDoubleClick(data?.uploadedBy || '-')" [matTooltip]="data?.uploadedBy || '-'">{{
        data?.uploadedBy || '-' }}</td>
      <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.uploadedDate))"
        [matTooltip]="getFormattedDate(data?.uploadedDate)">{{ getFormattedDate(data?.uploadedDate) }}</td>
      <td (dblclick)="onRowDoubleClick(data?.lockedByName || '-')" [matTooltip]="data?.lockedByName || '-'">{{
        data?.lockedByName || '-' }}</td>
      <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.lockedOn))"
        [matTooltip]="getFormattedDate(data?.lockedOn)">{{getFormattedDate(data?.lockedOn) }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input matInput type="text" class="textbox-border-class" placeholder="Fax Id" />
      </th>
      <th>
        <input matInput type="text" class="textbox-border-class" placeholder="Chart No" />
      </th>
      <th>
        <input matInput type="text" class="textbox-border-class" placeholder="Patient Name" />
      </th>
      <th>
        <input matInput type="text" class="textbox-border-class" placeholder="Document Id" />
      </th>
      <th>
        <input matInput type="text" class="textbox-border-class" placeholder="Document Name" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Uploaded By" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Uploaded Date" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Locked By" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Locked At" />
      </th>
    </tr>
  </tfoot>
</table>