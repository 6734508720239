import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { EOBHistoryService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/eobhistory.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-claim-cheque-list',
  templateUrl: './claim-cheque-list.component.html',
  styleUrls: ['./claim-cheque-list.component.scss'],
})
export class ClaimChequeListComponent implements OnInit {
  claimChequeListForm: FormGroup;
  selectedDate: any;
  isClaimTableLoaded: boolean = true;
  ltClaims: any[] = [];
  @ViewChild('picker') picker: MatDatepicker<Date>;
  fromDate: any;
  toDate: any;
  ranges: any;
  calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  calendarPlaceholder: string = '';
  filterValue: boolean;
  sTicketId: any = '';
  private loadClaimTableDataAPICall: Subscription;
  selectedRange: any = { startDate: null, endDate: null };
  selectedRangeV1: any = { startDate: null, endDate: null };
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private title: Title,
    private EOBHistoryService: EOBHistoryService,
    private commonService: CommonService
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'MM/DD/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
  }

  ngOnInit() {
    this.title.setTitle('QSecure | Claim Check List');

    // this.initializeForms();

    this.fromDate = this.getCTStartOfMonth();
    this.toDate = this.ctDateNow();
    this.initializeForms();
    // this.fromDate = this.convertISODateStringToCustomFormat(
    //   this. getCTStartOfMonth()
    //  )
    //    this.toDate = this.convertISODateStringToCustomFormat(
    //      this.ctDateNow()
    //    );
    //    this.claimChequeListForm.patchValue({
    //     txtDateValue: moment(this.getCTStartOfMonth()) +'-'+ moment( this.ctDateNow())
    //   });
      //  this.selectedRange.startDate=this. getCTStartOfMonth(),
      //  this.selectedRange.endDate= this.ctDateNow()
      //  this.selectedRangeV1.startDate=this. getCTStartOfMonth(),
      //  this.selectedRangeV1.endDate= this.ctDateNow()

      //  this.convertISODateStringToCustomFormat(
      //    this. getCTStartOfMonth()
      //   ) +'-'+ this.convertISODateStringToCustomFormat(
      //    this.ctDateNow()
      //  );
       this.loadChequeList();


  }
  //To initialize the Forms
  initializeForms() {
    this.claimChequeListForm = this.fb.group({
      // txtDateValue: new FormControl(''),
      // txtDate: new FormControl(''),
      // dtDOSTo: new FormControl(''),
      txtTicketNo: new FormControl(''),
      isDateRange:new FormControl(true),
      chartNo:new FormControl(''),
      patientName:new FormControl(''),
      claimNumber:new FormControl(''),
      checkNumber:new FormControl(''),
      txtStartDateValue:new FormControl(this.fromDate),
      txtEndDateValue:new FormControl(this.toDate),
    });
  }

  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    //    const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);

    return dateObj;
  }
  loadChequeList(){
    this.isClaimTableLoaded = false
    if (this.loadClaimTableDataAPICall) {
      this.loadClaimTableDataAPICall.unsubscribe();
    }{
      this.loadClaimTableDataAPICall = this.EOBHistoryService.getChequeWiseClaimsByDtFromDateAndDtToDateAndSClaimNumberAndSCheckNumberV1(
        this.claimChequeListForm.value.isDateRange,
        this.convertISODateStringToCustomFormat(this.claimChequeListForm.value.txtStartDateValue),
        this.convertISODateStringToCustomFormat(this.claimChequeListForm.value.txtEndDateValue)
      ).subscribe(response=> {
        this.ltClaims = response?.items
        this.ltClaims = this.ltClaims.map((inbound) => {
          return {
            ...inbound,
            chequeDate: this.commonService.getFormattedDateZone(
              inbound.chequeDate
            ),
            postingDate: this.commonService.getFormattedDateZone(
              inbound.postingDate
            ),
          };
        });
        this.isClaimTableLoaded = true
      },(err)=>{
        const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
        this.isClaimTableLoaded = true
      })
    }
  }

  loadChequeListV1(){
    this.isClaimTableLoaded = false
    if (this.loadClaimTableDataAPICall) {
      this.loadClaimTableDataAPICall.unsubscribe();
    }{
      this.loadClaimTableDataAPICall = this.EOBHistoryService.getChequeWiseClaimsByDtFromDateAndDtToDateAndSClaimNumberAndSCheckNumberV1(
        this.claimChequeListForm.value.isDateRange,null,null,this.claimChequeListForm.value.chartNo.trim(),
        this.claimChequeListForm.value.patientName.trim(),this.claimChequeListForm.value.claimNumber.trim(),this.claimChequeListForm.value.checkNumber.trim(),
      ).subscribe(response=> {
        this.ltClaims = response?.items
        this.isClaimTableLoaded = true
      },(err)=>{
        const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
        this.isClaimTableLoaded = true
      })
    }

  }
  reset(){

    this.fromDate = this. getCTStartOfMonth()
    this.toDate = this.ctDateNow()
       this.claimChequeListForm.patchValue({
        // txtDateValue: '',
        // txtDate: '',
        txtStartDateValue: this.fromDate,
        txtEndDateValue:this.toDate,
        // dtDOSTo: '',
      });
      //  this.claimChequeListForm.patchValue({
      //   txtDateValue: this.convertISODateStringToCustomFormat(
      //     this. getCTStartOfMonth()
      //    ) +'-'+ this.convertISODateStringToCustomFormat(
      //     this.ctDateNow()
      //   )
      // });
      //  this.selectedRange=this.selectedRangeV1;


      //  this.convertISODateStringToCustomFormat(
      //    this. getCTStartOfMonth()
      //   ) +'-'+ this.convertISODateStringToCustomFormat(
      //    this.ctDateNow()
      //  );
       this.loadChequeList();
  }
  resetV1(){
    this.claimChequeListForm.patchValue({
      txtTicketNo: '',
      chartNo: '',
      patientName: '',
      claimNumber: '',
      checkNumber: '',
    });
    this.loadChequeListV1();
  }
  get otherSearchValidation(): boolean {
    return (
      (this.claimChequeListForm.getRawValue().chartNo.trim() === '' ||
      this.claimChequeListForm.getRawValue().chartNo === null ||
      this.claimChequeListForm.getRawValue().chartNo === undefined) &&
      (this.claimChequeListForm.getRawValue().patientName.trim() === '' ||
      this.claimChequeListForm.getRawValue().patientName === null ||
      this.claimChequeListForm.getRawValue().patientName === undefined) &&
      (this.claimChequeListForm.getRawValue().claimNumber.trim() === '' ||
      this.claimChequeListForm.getRawValue().claimNumber === null ||
      this.claimChequeListForm.getRawValue().claimNumber === undefined ) &&
      (this.claimChequeListForm.getRawValue().checkNumber.trim() === '' ||
      this.claimChequeListForm.getRawValue().checkNumber === null ||
      this.claimChequeListForm.getRawValue().checkNumber === undefined)
    );
    }
  sanitizeInput(event: any, controlName: string) {
    const inputValue = event.target.value;
    const newValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters

    // Check if the new value matches the desired pattern
    const isValidInput = /^(?!0)\d{1,10}$/.test(newValue);

    if (!isValidInput) {
      this.claimChequeListForm.get(controlName)?.setValue(newValue.slice(0, -1)); // Revert to the previous valid value
    } else {
      this.claimChequeListForm.get(controlName)?.setValue(newValue); // Update the form value
    }
  }

  handleInputChange(event: any) {
    const inputValue = event.target.value;
    this.fromDate = null
    this.toDate =  null
    if (this.sTicketId !== inputValue) {
      this.sTicketId = inputValue;
      this.loadChequeList();
    }
  }
  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);
    return dateObj;
  }
  ctDateNow1(): Date {
   let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    //    const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);

    return dateObj;
  }
  onDateRangeChange() {
    const startDate = this.claimChequeListForm.get('txtStartDateValue').value;
    const endDate = this.claimChequeListForm.get('txtEndDateValue').value;
    this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    if (startDate != null && endDate != null) {
    }
  }

  datesUpdateds(e){
    if(e.startDate !=null || e.endDate !=null){
      this.dateRangStatus = true
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate,"MM/dd/yyyy")
      this.toDate = this.datepipe.transform(endDate,"MM/dd/yyyy")
      // this.loadChequeList()
    }else{
      this.fromDate= null;
      this.toDate=null;
    }
  }

  filterChange(event: boolean) {
    //Patient - True
    //Others - False
    this.filterValue = event;
    this.filterValue === true &&
      this.claimChequeListForm.patchValue({
        // drpPatient: '',
      });
  }

    // Function to get the first and last dates of the current month
    getFirstAndLastDatesOfCurrentMonth() {
      const today = new Date();
      this.fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
      this.toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    }

    convertISODateStringToCustomFormat(isoDateString: any): string {
      const inputDate = new Date(isoDateString);

      // Extracting date components
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
      const day = inputDate.getDate().toString().padStart(2, '0');
      const year = inputDate.getFullYear().toString();

      // Formatted date string in MM/DD/YYYY format
      const formattedDateString = `${month}/${day}/${year}`;

      return formattedDateString;
    }
    addSlashToDate(event: Event): void {
      const input = event.target as HTMLInputElement;
      const inputValue = input.value;
      input.value = inputValue
        .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
        .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
        .replace(/[^\d\/]/g, '');
    }
    dateRangeValidator: ValidatorFn = (
      control: AbstractControl
    ): { [key: string]: any } | null => {
      const from = control.get('txtStartDateValue')?.value;
      const to = control.get('txtEndDateValue')?.value;
      if (from && to && from > to) {
        return { dateRangeError: true };
      }
      return null;
    };

}
