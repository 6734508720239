<mat-accordion #thirdAccordion="matAccordion">
    <mat-expansion-panel [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="ftpid===''; else elseTitleTemplate2">
            <b>Add FTP</b>
          </ng-container>
          <ng-template #elseTitleTemplate2>
            <b>Edit FTP</b>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="FTPForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Branch<span class="asterisk">*</span></mat-label>
            <mat-select #selectBranch formControlName="drpBranchTypes" [(ngModel)]="seletedBranchValues">

            <mat-option>
                <ngx-mat-select-search [formControl]="BranchTypeControl" placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtBranchType"></ngx-mat-select-search>
            </mat-option>

              <mat-option *ngFor="let branch of filteredBranchTypeList" [value]="branch.id">
                {{ branch.organizationUnitName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Server IP <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtServerIP" matInput maxlength="15" formControlName="txtServerIP"
              pattern="[0-9\.]*" type="text" />
            <mat-error *ngIf=" FTPForm?.get('txtServerIP')?.touched && FTPForm?.get('txtServerIP')?.errors?.pattern">
              Enter valid server IP!
            </mat-error>
            <mat-error *ngIf="FTPForm?.get('txtServerIP')?.touched &&
                    FTPForm?.get('txtServerIP')?.errors?.required ">
              Server IP is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Port Number <span class="asterisk">*</span></mat-label>
            <input formControlName="txtPortNumber" matInput maxlength="5" pattern="[0-9]*" type="text"
              autocomplete="new-txtPortNumber" />
            <mat-error
              *ngIf="FTPForm?.get('txtPortNumber')?.touched && FTPForm?.get('txtPortNumber')?.errors?.required">
              Enter Minimum 10 Digits!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> User Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtUserName" matInput maxlength="40" formControlName="txtUserName" type="text"  oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);" />
            <mat-error *ngIf="FTPForm?.get('txtUserName')?.touched &&
                    FTPForm?.get('txtUserName')?.errors?.required ">
              Name is a required field!
            </mat-error>

          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Password <span class="asterisk">*</span></mat-label>
            <input matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtUserPassword"
              autocomplete="new-txtUserPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}"
              type="text" />
            <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
            <mat-error *ngIf="
              FTPForm?.get('txtUserPassword')?.touched &&
              FTPForm?.get('txtUserPassword')?.errors?.required
              ">
              Password is a required field!
            </mat-error>
            <mat-error *ngIf="
              FTPForm?.get('txtUserPassword')?.touched &&
              FTPForm?.get('txtUserPassword')?.errors?.pattern
            ">
              Passwords must contain at least one non alphanumeric character,one uppercase,one digit
              and at
              least 8 or more characters
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Source Folder <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtSourceFolder" matInput maxlength="40" formControlName="txtSourceFolder"
              type="text"
              oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace(/[^a-zA-Z0-9,\/\\]/g, '');" />
            <mat-error *ngIf="FTPForm?.get('txtSourceFolder')?.touched &&
                    FTPForm?.get('txtSourceFolder')?.errors?.required ">
              Source Folder is a required field!
            </mat-error>

          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Destination Folder <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtDesFolder" matInput maxlength="40" formControlName="txtDesFolder" type="text"
              oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace(/[^a-zA-Z0-9,\/\\]/g, '');" />
            <mat-error *ngIf="FTPForm?.get('txtDesFolder')?.touched &&
                    FTPForm?.get('txtDesFolder')?.errors?.required ">
              Destination Folder is a required field!
            </mat-error>

          </mat-form-field>

        </div>
      </form>
      <br />
      <div class="row">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <button *ngIf="inActiveHide" [disabled]="saveButtonHide||FTPForm.invalid" mat-button
              class="buttonColor mr-2" (click)="saveFTP()">
              Save
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
            <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetFTP()">Reset</button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="card card-body mt-3">
    <mat-radio-group [(ngModel)]="selectedOption" class="radio-buttons">
      <mat-radio-button class="Inactive" (click)="FTPStatusFilterv1(0)" value="All"><b>All</b></mat-radio-button>
      <mat-radio-button class="Inactive p-2" (click)="FTPStatusFilterv1(1)" value="Active"
        [checked]="true"><b>Active</b></mat-radio-button>
      <mat-radio-button class="Inactive" (click)="FTPStatusFilterv1(2)"
        value="InActive"><b>Inactive</b></mat-radio-button>
    </mat-radio-group>
    <br />
    <div class="d-flex justify-content-center" *ngIf="isLoading">
      <!-- <mat-spinner></mat-spinner> -->
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
      <div class="col-lg-12 content table-responsive p-0">
        <!-- <table mat-table class="table ml-4"style=" margin-left: 3px !important; border: 1px solid #cdc7c7" matSort [dataSource]="dataSource"> -->
        <table id="tbl" datatable [dtOptions]="dtFTPListOptions" *ngIf="!isLoading"
        class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'Status'">Status</th>
              <th [matTooltip]="'Branch'">Branch</th>
              <th class="text-right" [matTooltip]="'Server IP'">Server IP</th>
              <th class="text-right" [matTooltip]="'Port Number'">Port Number</th>
              <th [matTooltip]="'User Name'">User Name</th>
              <th [matTooltip]="'Source Folder'">Source Folder</th>
              <th [matTooltip]="'Destination Folder'">Destination Folder</th>
              <th [matTooltip]="'Created By'">Created By</th>
              <th [matTooltip]="'Created Time'">Creation Time</th>
              <th [matTooltip]="'Modify By'">Modify By</th>
              <th class="text-right" [matTooltip]="'Modified Date'">Modified Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ftp of FTPDetails" [ngClass]="{stat_highlight:ftp.isStat == true}">
              <td>
                <a class="eyeCursorclass" [matTooltip]="'Edit FTP'"><i *ngIf="ftp?.isActive===1"
                    (click)="ViewFtp(ftp)" class="fa fa-pencil editBtnColor mr-1"></i>
                </a>
              </td>
              <td [matTooltip]="ftp?.isActive ? 'Active' : 'Inactive' || '-'">
                <ng-container *ngIf="ftp?.isActive===1">
                  <mat-slide-toggle (change)="ftpStatusChange($event, ftp)" [checked]='true' class="ml-2">
                  </mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="ftp?.isActive===0">
                  <mat-slide-toggle (change)="ftpStatusChange($event, ftp)" [checked]='false' class="ml-2">
                  </mat-slide-toggle>
                </ng-container>
              </td>
              <td [matTooltip]="ftp.branchName || '-'" (dblclick)="onRowDoubleClick(ftp?.branchName || '-')">{{ ftp.branchName || '-'}}</td>
              <td class="text-right" [matTooltip]="ftp.server_IP || '-'" (dblclick)="onRowDoubleClick(ftp?.server_IP || '-')">{{
                ftp.server_IP || '-' }}</td>
              <td class="text-right" [matTooltip]="ftp.portNumber || '-'" (dblclick)="onRowDoubleClick(ftp?.portNumber || '-')"> {{ ftp.portNumber == '' ? '-' : ftp.portNumber }}</td>
              <td [matTooltip]="ftp.userName || '-'" (dblclick)="onRowDoubleClick(ftp?.userName || '-')"> {{ftp.userName || '-'}}</td>
              <td [matTooltip]="ftp.faxSourceFolder || '-'" (dblclick)="onRowDoubleClick(ftp?.faxSourceFolder || '-')"> {{ftp.faxSourceFolder || '-'}}</td>
              <td [matTooltip]="ftp.destinationFolder || '-'" (dblclick)="onRowDoubleClick(ftp?.destinationFolder || '-')"> {{ftp.destinationFolder || '-'}}</td>
              <td [matTooltip]="ftp.createdBy || '-'" (dblclick)="onRowDoubleClick(ftp?.createdBy || '-')"> {{ftp.createdBy || '-'}}</td>
              <td [matTooltip]="ftp?.creationTime"
              (dblclick)="onRowDoubleClick(ftp?.creationTime)" class="text-right">
              {{ ftp?.creationTime }}
            </td>
              <td [matTooltip]="ftp.modifiedBy || '-'" (dblclick)="onRowDoubleClick(ftp?.modifiedBy || '-')"> {{ftp.modifiedBy || '-'}}</td>
              <td [matTooltip]="ftp?.lastModificationTime"
                (dblclick)="onRowDoubleClick(ftp?.lastModificationTime)" class="text-right">
                {{ ftp?.lastModificationTime }}
              </td>
              
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Status"
                  name="search-Status" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Branch" name="search-BranchName" />
              </th>
              <th class="text-right">
                <input matInput class="textbox-border-class" type="text" placeholder="Server IP"
                  name="search-ServerIP" />
              </th>
              <th class="text-right">
                <input matInput type="text" class="textbox-border-class" placeholder="Port Number" name="search-PortNumber" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="User Name"
                  name="search-UserName" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Source Folder"
                  name="search-SourceFolder" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Destination Folder"
                  name="search-DestinationFolder" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Created By"
                  name="search-Created By" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Creation Time"
                  name="search-CreationTime" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modify By"
                  name="search-Modify By" />
              </th>
              <th class="text-right">
                <input matInput type="text" class="textbox-border-class" placeholder="Modified Date"
                  name="search-ModifiedDate" />
              </th>
            </tr>
          </tfoot>

        </table>
      </div>
  </div>
