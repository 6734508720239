<div class="container-fluid main-content">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18"><b>Billing Report</b></h3>
        <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li class="ml-2 mt-2 breadcrumb-item">Referral Report</li>
            <li class="mt-2 breadcrumb-item muted">MIS Reports</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-body">
    <div class="row">
      <!-- <div class="col-12">
          <b>
            Billing Report
          </b>
        </div> -->
      <form [formGroup]="msiForm">
        <mat-form-field class="col-6 mt-4">
          <mat-label>From Date <span class="asterisk">*</span> </mat-label>
          <input
            maxlength="40"
            formControlName="txtFromDate"
            matInput
            [matDatepicker]="txtFromDate"
            placeholder="(MM/DD/YYYY)"
            autocomplete="new-txtFromDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="txtFromDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #txtFromDate></mat-datepicker>
          <mat-error
            *ngIf="
              msiForm?.get('txtFromDate')?.touched &&
              msiForm?.get('txtFromDate')?.errors
            "
          >
            Enter Valid Date!
          </mat-error>
          <mat-error
            *ngIf="
              msiForm?.get('txtToDate')?.value >=
                msiForm?.get('txtFromDate')?.value &&
              !(
                msiForm?.get('txtFromDate')?.touched &&
                msiForm?.get('txtFromDate')?.errors?.required
              )
            "
          >
            Enter Future Dates Only!
          </mat-error>
          <mat-error
            *ngIf="
              msiForm?.get('txtFromDate')?.touched &&
              msiForm?.get('txtFromDate')?.errors?.required
            "
          >
            From Date is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6 mt-4">
          <mat-label>To Date <span class="asterisk">* </span></mat-label>
          <input
            [min]="msiForm?.get('txtFromDate')?.value"
            maxlength="40"
            formControlName="txtToDate"
            matInput
            [matDatepicker]="txtToDate"
            placeholder="(MM/DD/YYYY)"
            autocomplete="new-txtToDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="txtToDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #txtToDate></mat-datepicker>
          <mat-error
            *ngIf="
              msiForm?.get('txtToDate')?.touched &&
              msiForm?.get('txtToDate')?.errors
            "
          >
            Enter Valid Date!
          </mat-error>
          <mat-error
            *ngIf="
              msiForm?.get('txtToDate')?.value >=
                msiForm?.get('txtFromDate')?.value &&
              !(
                msiForm?.get('txtToDate')?.touched &&
                msiForm?.get('txtToDate')?.errors?.required
              )
            "
          >
            Enter Future Dates Only!
          </mat-error>
          <mat-error
            *ngIf="
              msiForm?.get('txtToDate')?.touched &&
              msiForm?.get('txtToDate')?.errors?.required
            "
          >
            To Date is a required field!
          </mat-error>
        </mat-form-field>
      </form>
      <div class="col-4 mt-4">
        <button
          mat-button
          class="mr-2 buttonColor"
          [disabled]="msiForm?.invalid"
          (click)="getTableDataBasedOnDates()"
        >
          Submit
        </button>
        <button
          mat-button
          class="mr-2 buttonColor"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

          (click)="exportDataToExcel()"
        >
          Export
        </button>
        <button mat-button class="resetclr" (click)="resetForm()">Reset</button>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th class="header" mat-header-cell *matHeaderCellDef>Patient Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dob">
        <th class="header" mat-header-cell *matHeaderCellDef>Date Of Birth</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.dob | date: "MM/dd/yyyy":"en_US" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="insurance">
        <th class="header" mat-header-cell *matHeaderCellDef>Insurance</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.insuranceDetailsList[0]?.insurance }}
        </td>
      </ng-container>

      <ng-container matColumnDef="CreatedOn">
        <th mat-header-cell *matHeaderCellDef>Created Date</th>
        <td mat-cell *matCellDef="let data">
          <ng-container>
            {{ data?.creationTime | date: "MM/dd/yyyy":"en_US" }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator
            class="table table-responsive"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['noRecords']"
        [ngClass]="{
          active:
            dataSource && dataSource?.data && dataSource?.data?.length !== 0
        }"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
    </table>
    <div
      *ngIf="dataSource && dataSource?.data && dataSource?.data?.length !== 0"
      class="row mt-3"
    >
      <div class="col-12">
        <b> Billing Summary </b>
      </div>
    </div>
    <div
      *ngIf="dataSource && dataSource?.data && dataSource?.data?.length !== 0"
      class="row mt-2"
    >
      <mat-form-field class="col-3">
        <mat-label>Row </mat-label>
        <mat-select [(ngModel)]="txtRow">
          <mat-option
            *ngFor="let report of drpBillingReport"
            [value]="report.rowName"
          >
            {{ report.rowName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>Column </mat-label>
        <mat-select [(ngModel)]="txtColumn">
          <mat-option
            *ngFor="let report of drpBillingReport"
            [value]="report.rowName"
          >
            {{ report.rowName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-6">
        <button
          mat-button
          class="mt-2 mr-2 buttonColor"
          [disabled]="!(txtRow && txtColumn)"
          (click)="getTableBasedOnFilter()"
        >
          Submit
        </button>
      </div>
    </div>
    <ng-container
      *ngIf="
        filteredData &&
        dataSource &&
        dataSource?.data &&
        dataSource?.data?.length !== 0
      "
    >
      <app-import-pivot-table
        *ngIf="txtRow && txtColumn"
        [source]="source"
        [configs]="configs"
      >
      </app-import-pivot-table>
    </ng-container>
  </div>
</div>

<div class="row">
  <div class="col-12 text-right">
    <button
      mat-button
      matTooltip="Click To View Reports"
      class="clr mat-fab-bottom-right rightfloatfabbutton"
      (click)="openReportsmodal()"
      mat-mini-fab
    >
      <mat-icon class="icon">add</mat-icon>
    </button>
  </div>
</div>
