import { Title } from '@angular/platform-browser';
import { PatientVImportComponent } from './../patient-v-import/patient-v-import.component';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SourceMDPatientService } from '../patient-proxy/source-mdpatient';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { FilterPatientsDTO, InsuranceDetailsDTO, SourceMDPatientDashboardDTO, SourceMDPatientsDTO } from '../patient-proxy/source-mdpatient/dto/models';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PatientMasterDropdownService } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/patient-dropdowns.enum';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-patient-import',
  templateUrl: './patient-import.component.html',
  styleUrls: ['./patient-import.component.scss'],
  providers: [DatePipe],

  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])],
})
export class PatientImportComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<SourceMDPatientsDTO> = new MatTableDataSource();
  displayedColumns: string[] = ["ptId", "fName", "lName", "dob", "insName", "status", "CreatedOn", "Options",];
  isShowSpinner: boolean = false;
  expandedElement: any | null = null;
  subscription$: Subscription[] = [];
  @ViewChild('uploadFileReference', { static: false }) uploadFileReference: ElementRef
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  SPatientImprtForm: FormGroup;
  drpInsurance: SourceMDPatientsDTO[] = [];
  drpVerificationStatus: any[] = [];
  drpInsuranceNames: any[] = [];
  drpIsActiveStatus: any[] = [];
  BirthdateMaxDate: Date;
  disableExportButton: boolean = true;
  shownDateRangeErr: boolean = false;
  sourceMDPatientDashboardCount: SourceMDPatientDashboardDTO;
  constructor(
    public dialog: MatDialog,
    private smdPatientService: SourceMDPatientService,
    private formBuilder: FormBuilder,
    private dropdownService: PatientMasterDropdownService,
    private title: Title,
    private datePipe: DatePipe,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  ngOnInit(): void {
    this.title.setTitle("Qsecure | Patient");
    this.getTableData();
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 0);
    this.SPatientImprtForm = this.formBuilder.group({
      txtInsStatus: new FormControl(""),
      txtInsurance: new FormControl(""),
      txtVerifyStatus: new FormControl(""),
      patientName: new FormControl(""),
      txtUplaodedFromDate: new FormControl("", Validators.required),
      txtUplaodedToDate: new FormControl("", Validators.required),
    })
    this.SPatientImprtForm.patchValue({
      txtUplaodedFromDate: new Date(),
      txtUplaodedToDate: new Date()
    })

    this.getDropdowns();
    const valueChanges = this.SPatientImprtForm?.get('txtUplaodedToDate').valueChanges.subscribe(resposne => {
      this.shownDateRangeErr = (this.SPatientImprtForm.value.txtUplaodedFromDate >= resposne);
    })
    this.subscription$.push(valueChanges);

  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  getDropdowns() {
    const input: PatientDropdowns[] = [PatientDropdowns.All];
    const patientDropdown = this.dropdownService.getPatientDropdownsByInput(input).subscribe(resposne => {
      this.drpVerificationStatus = resposne?.lstVerificationStatus ?? [];
      this.drpIsActiveStatus = resposne?.lstIsActiveStatus ?? [];
    })
    this.subscription$.push(patientDropdown);
  }

  /// to get mis report for source md
  MISReport() {
    this.router.navigate(['import-mis-report'])
  }


  getTableData() {
    this.disableExportButton = true;
    let tableData: SourceMDPatientsDTO[] = [];
    const getTableData = this.smdPatientService.getList(new PagedAndSortedResultRequestDto).subscribe(resposne => {
      tableData = resposne?.items ?? [];
      const MtableData: any[] = tableData ?? [];

      const MItableData: any[] = flattenasd(flatten(tableData, "insuranceDetailsList")) ?? [];
      this.dataSource = new MatTableDataSource(MtableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      let InsNames: string[] = MItableData?.map(e => e?.insurance) ?? [];
      this.drpInsuranceNames = [...new Set(InsNames)];
      this.disableExportButton = false;
    }, err => {
      this.disableExportButton = false;

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getTableData);
    // this.smdPatientService.getSourceMDPatientDashboardCount().subscribe(response => {
    //   this.sourceMDPatientDashboardCount = response??null;
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({ icon: 'error', text: data?.error?.error?.message});
    // });

  }
  edit(value: string, uniqueInsuranceId: string) {
    const dialogRef = this.dialog.open(PatientVImportComponent, {
      disableClose: true,
      data: { pateintId: value ?? "", InsId: uniqueInsuranceId ?? "" }
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }

  importExcel(value: any) {
    const fileList: FileList = value;
    if (fileList?.length >= 2) {
      return Swal.fire({
        icon: 'info',
        text: "Please Select Only One File",
      });
    }
    let fileExcel: File = null;
    for (let index = 0; index < fileList?.length; index++) {
      if (fileList[index]?.type === DocumentMaxandTypeLimits.ExcelTypeLimit) {
        fileExcel = fileList?.item(index);
      };
    }
    let fileExcelValue: string = "";
    let reader = new FileReader();
    reader?.readAsDataURL(fileExcel);
    reader.onload = () => {

      fileExcelValue = reader?.result?.slice(78)?.toString();
      if (fileExcelValue && fileExcelValue !== "") {
        fileExcelValue = `"` + fileExcelValue + `"`;
        const exportData = this.smdPatientService.importSourceMDPatientBySFileContentAsBase64String(fileExcelValue).subscribe(response => {
          this.getTableData();
          this.uploadFileReference.nativeElement.value = null;

          // Swal.fire({ title: 'Success', html: 'Imported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
          this.toastr.success('Imported Successfully','Success')
        }, err => {
          this.uploadFileReference.nativeElement.value = null;

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(exportData);
      }
    }
  }

  searchpatImport() {
    const searchFormValue = this.SPatientImprtForm?.value;
    const sInsuranceName: string = searchFormValue?.txtInsurance ?? "";
    const sPatientName: string = searchFormValue?.patientName ?? "";
    const sInsuranceId: string = "";
    const isActiveInsurance: boolean = (searchFormValue?.txtInsStatus === "" || searchFormValue?.txtInsStatus === undefined) ? null : (searchFormValue?.txtInsStatus === "Yes") ? true : false ?? null;
    const sVerificationStatus: string = searchFormValue?.txtVerifyStatus ?? "";
    const txtUplaodedFromDate: string = ((searchFormValue?.txtUplaodedFromDate ?? null) === "") ? null : (new Date(searchFormValue?.txtUplaodedFromDate)?.toLocaleDateString("en-US")) ?? null;
    const txtUplaodedToDate: string = ((searchFormValue?.txtUplaodedToDate ?? null) === "") ? null : (new Date(searchFormValue?.txtUplaodedToDate)?.toLocaleDateString("en-US")) ?? null;

    const sCreatedDateFrom: string = txtUplaodedFromDate ?? null;
    const sCreatedDateTo: string = txtUplaodedToDate ?? null;
    let tableData: SourceMDPatientsDTO[] = [];
    let searchIbput: FilterPatientsDTO = {
      patientName: sPatientName,
      insuranceId: "",
      insuranceName: sInsuranceName,
      isActiveInsurance: isActiveInsurance,
      verificationStatus: sVerificationStatus,
      createdDateFrom: this.datePipe.transform(sCreatedDateFrom, "yyyy-MM-dd"),
      createdDateTo: this.datePipe.transform(sCreatedDateTo, "yyyy-MM-dd")
    }
    const filterPatient = this.smdPatientService.filterPatientsByOFilterPatients(searchIbput).subscribe(resposne => {
      tableData = resposne ?? [];
      // tableData.forEach(e=>{
      //   e.insuranceDetailsList= [e['insuranceDetails']]
      // });
      const MtableData: any[] = tableData;

      this.dataSource = new MatTableDataSource(MtableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(filterPatient)
  }

  resetFilter() {
    this.SPatientImprtForm.patchValue({
      txtInsurance: "",
      txtInsStatus: "",
      txtVerifyStatus: "",
      txtUplaodedFromDate: "",
      txtUplaodedToDate: "",
    })
    this.SPatientImprtForm.patchValue({
      txtUplaodedFromDate: new Date(),
      txtUplaodedToDate: new Date()
    })

    this.getTableData();
  }
  exportDataToExcel() {
    const searchFormValue = this.SPatientImprtForm?.value;
    let txtUplaodedFromDate: string = ((searchFormValue?.txtUplaodedFromDate ?? null) === "") ? null : (new Date(searchFormValue?.txtUplaodedFromDate)?.toLocaleDateString("en-US")) ?? null;
    let txtUplaodedToDate: string = ((searchFormValue?.txtUplaodedToDate ?? null) === "") ? null : (new Date(searchFormValue?.txtUplaodedToDate)?.toLocaleDateString("en-US")) ?? null;

    let filterData: FilterPatientsDTO = {
      patientName: searchFormValue?.patientName ?? "",
      insuranceId: '',
      insuranceName: searchFormValue?.txtInsurance ?? "",
      isActiveInsurance: (searchFormValue?.txtInsStatus === "" || searchFormValue?.txtInsStatus === undefined) ? null : (searchFormValue?.txtInsStatus === "Yes") ? true : false ?? null,
      verificationStatus: searchFormValue?.txtVerifyStatus ?? "",
      createdDateFrom: this.datePipe.transform(txtUplaodedFromDate, "yyyy-MM-dd"),
      createdDateTo: this.datePipe.transform(txtUplaodedToDate, "yyyy-MM-dd")
    }
    const sInsuranceName: string = searchFormValue?.txtInsurance ?? "";
    const isActiveInsurance: boolean = (searchFormValue?.txtInsStatus === "" || searchFormValue?.txtInsStatus === undefined) ? null : (searchFormValue?.txtInsStatus === "Yes") ? true : false ?? null;
    const sVerificationStatus: string = searchFormValue?.txtVerifyStatus ?? "";

    const sCreatedDateFrom: string = this.datePipe.transform(txtUplaodedFromDate, "yyyy-MM-dd");
    const sCreatedDateTo: string = this.datePipe.transform(txtUplaodedToDate, "yyyy-MM-dd");
    let tableData: SourceMDPatientsDTO[] = [];
    this.disableExportButton = true;
    const exportData = this.smdPatientService.exportSourceMDPatientByOFilterPatientsDTO(filterData).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Patient-Import") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
      this.disableExportButton = false;

    }, err => {
      this.disableExportButton = false;

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(exportData)
  }
  downloadTemplate() {
    this.disableExportButton = true;
    const DdnwloadTemplate = this.smdPatientService.downloadsourcemDPatienttemplate().subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Template") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Downloaded Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Downloaded Successfully','Success')
      }
      this.disableExportButton = false;

    }, err => {
      this.disableExportButton = false;

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(DdnwloadTemplate)
  }
}




function flatten(i: SourceMDPatientsDTO[] | SourceMDPatientsDTO, arrayField: string) {

  if (Array.isArray(i)) {
    return i?.map(c => flatten(c, arrayField))
  };
  if (i?.hasOwnProperty(arrayField)) {
    let insuranceDetailsList = i[arrayField];
    let xyz: any[] = [];
    insuranceDetailsList?.forEach((j: InsuranceDetailsDTO) => {
      let a: {
        uniqueInsuranceId: string;
        id?: string
        firstName?: string;
        lastName?: string;
        dob?: string;
        streetAddress?: string;
        city?: string;
        state?: string;
        zipCode?: string;
        defaultPatientId?: string;
        tenantId?: string;
        insurance?: string;
        insuranceId?: string;
        hcps?: string;
        payerAllowables?: string;
        productCategory?: string;
        product?: string;
        isInsuranceActive?: boolean;
        insuranceComments?: string;
        isBlueCardEligible?: boolean;
        blueCardComments?: string;
        isLocalBillAvailable?: boolean;
        localBillComments?: string;
        isPreAuthAvailable?: boolean;
        preAuthComments?: string;
        isClaimSubmit?: boolean;
        claimSubmitComments?: string;
        patientResponsiblePayment?: string;
        deductiblePayment?: string;
        referenceNumber?: string;
        creationTime?: string | Date
        verificationStatus?: string
      } = {
        id: i?.id,
        firstName: i?.firstName,
        lastName: i?.lastName,
        dob: i?.dob,
        streetAddress: i?.streetAddress,
        city: i?.city,
        state: i?.state,
        zipCode: i?.zipCode,
        defaultPatientId: i?.defaultPatientId,
        tenantId: i?.tenantId,
        insurance: j?.insurance,
        insuranceId: j?.insuranceId,
        hcps: j?.hcps,
        payerAllowables: j?.payerAllowables,
        productCategory: j?.productCategory,
        product: j?.product,
        isInsuranceActive: j?.isInsuranceActive,
        insuranceComments: j?.insuranceComments,
        isBlueCardEligible: j?.isBlueCardEligible,
        blueCardComments: j?.blueCardComments,
        isLocalBillAvailable: j?.isLocalBillAvailable,
        localBillComments: j?.localBillComments,
        isPreAuthAvailable: j?.isPreAuthAvailable,
        preAuthComments: j?.preAuthComments,
        isClaimSubmit: j?.isClaimSubmit,
        claimSubmitComments: j?.claimSubmitComments,
        patientResponsiblePayment: j?.patientResponsiblePayment,
        deductiblePayment: j?.deductiblePayment,
        referenceNumber: j?.referenceNumber,
        uniqueInsuranceId: j?.uniqueInsuranceId,
        creationTime: i?.creationTime,
        verificationStatus: j?.verificationStatus
      }
      xyz?.push(a)
    });
    return [...xyz]
  };
  return { ...i, [arrayField]: null };
}
function flattenasd(arr): any[] {
  const newArr = arr.reduce((acc, item) => {
    if (Array.isArray(item)) {
      acc = acc.concat(flattenasd(item));
    } else {
      acc.push(item);
    }

    return acc;
  }, []);

  return newArr;
}
