import { Enum } from 'projects/admin/src/assets/rules.enum';
import { shippingTypeService } from './shipping-type.enum';
import type {
  CompletedOrderDTO,
  CreateUpdateShippingApprovalDTO,
  ShippingApprovalDTO,
  ShippingPatientBalanceNotesDTO,
  ShippingProductListDTO,
} from './dto/models';
import type { ShippingApproval } from './entity/models';
import type { ShippingApprovalStatus } from './shipping-approval-status.enum';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShippingApprovalService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateShippingApprovalDTO) =>
    this.restService.request<any, ShippingApprovalDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/shipping-approval',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/orderApp/shipping-approval/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ShippingApprovalDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-approval/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ShippingApprovalDTO>>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-approval',
        params: {
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateShippingApprovalDTO) =>
    this.restService.request<any, ShippingApprovalDTO>(
      {
        method: 'PUT',
        url: `/api/orderApp/shipping-approval/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  getPatientBalNotesDetailsV1ByOrderId = (OrderId: string) =>
    this.restService.request<any, ShippingPatientBalanceNotesDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-approval/patient-bal-notes-details-v1/${OrderId}`,
      },
      { apiName: this.apiName }
    );

  getShippingApprovalStatusByShipping = (shipping: ShippingApproval) =>
    this.restService.request<any, ShippingApprovalStatus>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-approval/get-shipping-approval-status',
      },
      { apiName: this.apiName }
    );

  searchShippingApporvalListBySSaleOrderIdAndSDefaultPatientIdAndSPatientNameAndSUserNameAndStatusAndDtDOS =
    (
      sSaleOrderId: string,
      sDefaultPatientId: string,
      sPatientName: string,
      sUserName: string,
      Status: string,
      dtDOS: string
    ) =>
      this.restService.request<any, ShippingApprovalDTO[]>(
        {
          method: 'POST',
          url: '/api/orderApp/shipping-approval/search-shipping-apporval-list',
          params: {
            sSaleOrderId,
            sDefaultPatientId,
            sPatientName,
            sUserName,
            status: Status,
            dtDOS,
          },
        },
        { apiName: this.apiName }
      );
  getCompletedOrderDetails = () =>
    this.restService.request<any, CompletedOrderDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-approval/completed-order-details',
      },
      { apiName: this.apiName }
    );
  getShippingItemDetailsByGOrderId = (gOrderId: string) =>
    this.restService.request<any, ShippingProductListDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-approval/shipping-item-details/${gOrderId}`,
      },
      { apiName: this.apiName }
    );
  updateShippingApprovalStatusByGOrderIdAndStatusAndType = (
    gOrderId: string,
    status: boolean,
    type: shippingTypeService,
    sComments: string
  ) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/orderApp/shipping-approval/shipping-approval-status/${gOrderId}`,
        params: { status, type, sComments },
      },
      { apiName: this.apiName }
    );

  getPatientBalNotesDetailsByGpatId = (gpatId: string) =>
    this.restService.request<any, ShippingPatientBalanceNotesDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-approval/patient-bal-notes-details/${gpatId}`,
      },
      { apiName: this.apiName }
    );
  getDisapprovedOrderDetails = () =>
    this.restService.request<any, CompletedOrderDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-approval/disapproved-order-details',
      },
      { apiName: this.apiName }
    );
  getCompletedOrderDetailsV1 = () =>
    this.restService.request<any, CompletedOrderDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-approval/completed-order-details-v1',
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
