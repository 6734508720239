<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0 customThemeClass">Add Pickup and Exchange Items</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <button
              matTooltip="Click To Go back to Order"
              (click)="redirectToOrder()"
              mat-button
              class="buttonColor font-size-10 mr-2"
            >
              <i class="fa fa-backward mr-1"></i> Back To Order
            </button>
            <li class="breadcrumb-item">Orders</li>
            <li class="breadcrumb-item">Item List</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="col-lg-12 pb-5">
      <div class="float-right pb-3 pt-3">
        <button
        matTooltip="Click to Return All items"
        mat-button
        (click)="returnTotalItems()"
        class="buttonColor font-size-10 mr-2"
      >
      <mat-icon>replay</mat-icon> Return Entire Order
      </button>
      </div>
      <div
        class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0"
      >
        <table class="table table-bordered">
          <thead>
            <th>Exchange</th>
            <th>Pickup</th>
            <th>DOS</th>
            <th>Item Name</th>
            <th>Item code</th>
            <th>Price Per Unit</th>
            <th>Units</th>
            <th>Price</th>
          </thead>
          <tbody>

            <tr *ngFor="let record of tblItemList; let i = index">
              <td>
                <mat-icon class="datas" (click)="openNewItemDialog(record.id)"
                  >library_add</mat-icon>
                <!-- {{record.itemId}} -->
              </td>
              <td>
                <mat-icon class="datas" (click)="openPickupExchangeOptionsTab(record.id)"
                  >library_add</mat-icon
                >
              </td>
              <td>
                {{ record?.dosFrom | date : "MM/dd/yyyy" : "en_US" }}
              </td>
              <td>
                {{ record?.itemName }}
              </td>
              <td>
                {{ record?.itemProcedureCode }}
              </td>
              <td>$ {{ +record?.charge | number : ".2-2" }}</td>
              <td>{{ +record?.orderingQuantity }}</td>
              <td>$ {{ +record?.totalPrice | number : ".2-2" }}</td>
            </tr>
            <!-- *ngIf="tblItemList && tblItemList?.length !== 0" -->
            <tr *ngIf="tblItemList && tblItemList?.length !== 0">
              <td colspan="6" class="text-right"><b>Total</b></td>
              <td colspan="1">
                <b>{{ totalQty }} </b>
              </td>
              <td colspan="1">
                <b>$ {{ +totalPrice | number : ".2-2" }} </b>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <ng-container
              *ngIf="tblItemList && tblItemList?.length === 0 && !isLoading"
            >
              <td class="text-align-center" colspan="12">No Records Found</td>
            </ng-container>
            <ng-container *ngIf="isLoading">
              <td class="text-align-center" colspan="12">
                <mat-spinner [diameter]="220"></mat-spinner>
              </td>
            </ng-container>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
