import type {
  CreateUpdatePatientAuthorizationDTO,
  PatientAuthorizationDTO,
  authSearchDTO,
  authReportDTO,
  patientAuthSearchDTO,
  ProviderNPIdetailsDTO,
  activeAuthDTO,
  ParReport,
  ParReportCount,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ListofVerificationsDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';
import { ParReportShortCode } from './par-report-short-code.enum';

@Injectable({
  providedIn: 'root',
})
export class PatientAuthorizationService {
  apiName = 'Default';
  authFollowupReports = (followupType: string) =>
    this.restService.request<any, authReportDTO[]>(
      {
        method: 'POST',
        params: { reportType: followupType },
        url: `/api/app/patient-authorization/auth-followup-reports`,
      },
      { apiName: this.apiName }
    );
  create = (input: CreateUpdatePatientAuthorizationDTO) =>
    this.restService.request<any, PatientAuthorizationDTO>(
      {
        method: 'POST',
        url: '/api/app/patient-authorization',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/patient-authorization`,
        params: {
          id,
        },
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, PatientAuthorizationDTO>(
      {
        method: 'GET',
        url: `/api/app/patient-authorization/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientAuthorizationDTO>>(
      {
        method: 'GET',
        url: '/api/app/patient-authorization',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdatePatientAuthorizationDTO) =>
    this.restService.request<any, PatientAuthorizationDTO>(
      {
        method: 'PUT',
        url: `/api/app/patient-authorization/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getPatientAuthByOrderIdByOrderId = (orderId: string) =>
    this.restService.request<any, PatientAuthorizationDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-authorization/get-patient-auth-by-order-id/${orderId}`,
      },
      { apiName: this.apiName }
    );

  // Supplies
  searchPatientAuthorizationByInput = (input: patientAuthSearchDTO) =>
    this.restService.request<any, authSearchDTO[]>(
      {
        method: 'POST',
        url: '/api/app/patient-authorization/search-patient-authorization',
        body: input,
      },
      { apiName: this.apiName }
    );

  getPatientAuthByPatientId = (gPatientId: string) =>
    this.restService.request<any, PatientAuthorizationDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-authorization/get-auth-details/${gPatientId}`,
      },
      { apiName: this.apiName }
    );
  getPatientAuthByPatientIdV1 = (gPatientId: string) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/app/patient-personal/patient-basic-auth-details/${gPatientId}`,
      },
      { apiName: this.apiName }
    );

  getPatientBasicInfoByAuthId = (authId: string) =>
    this.restService.request<any, ProviderNPIdetailsDTO>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-auth-details-by-id/${authId}`,
      },
      { apiName: this.apiName }
    );
  getAuthMachineCodeReport = () =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/app/pre-cert-codes/precet-code-report`,
      },
      { apiName: this.apiName }
    );
  getActiveAuthorizationsByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, activeAuthDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-authorization/get-active-authorizations-by-patient-id/${patientId}`,
      },
      { apiName: this.apiName }
    );

    initiateDoPrecertResupplyByGPatientId = (gPatientId: string) =>
      this.restService.request<any, void>({
        method: 'POST',
        url: `/api/app/patient-authorization/initiate-do-precert-resupply/${gPatientId}`,
      },
      { apiName: this.apiName });

    // getParReportByPageNoAndPageOffset = (pageNo: number, pageOffset: number) =>
    //   this.restService.request<any, ParReport>({
    //     method: 'GET',
    //     url: '/api/app/patient-authorization/get-par-report',
    //     params: { pageNo, pageOffset },
    //   },
    //   { apiName: this.apiName });

  getParReportByShortcodeAndPageNoAndPageOffset = (shortcode: ParReportShortCode, pageNo: number, pageOffset: number) =>
    this.restService.request<any, ParReport>({
      method: 'GET',
      url: '/api/app/patient-authorization/get-par-report',
      params: { shortcode, pageNo, pageOffset },
    },
    { apiName: this.apiName });

    getParReportCount = () =>
      this.restService.request<any, ParReportCount>({
        method: 'GET',
        url: '/api/app/patient-authorization/get-par-report-count',
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
