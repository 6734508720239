import type { CreateUpdateProductHcpcCodeDTO, ProductHcpcCodeDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductHcpcCodeService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductHcpcCodeDTO) =>
    this.restService.request<any, ProductHcpcCodeDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-hcpc-code',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-hcpc-code/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ProductHcpcCodeDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-hcpc-code/${id}`,
    },
    { apiName: this.apiName });

  getAllProductHCPCCodeList = () =>
    this.restService.request<any, ProductHcpcCodeDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/product-hcpc-code/product-hCPCCode-list',
    },
    { apiName: this.apiName });
    getAllProductHCPCCodeListV1 = (sSearch:string,sHcpc:string[]) =>
      this.restService.request<any, ProductHcpcCodeDTO[]>({
        method: 'POST',
        url: '/api/ItemApp/product-hcpc-code/product-hCPCCode-list-for-update-allowables',
        body: { sSearch, sHcpc },
      },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductHcpcCodeDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-hcpc-code',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getProductHCPCCodeListByID = (productId: string) =>
    this.restService.request<any, ProductHcpcCodeDTO[]>({
      method: 'GET',
      url: `/api/ItemApp/product-hcpc-code/product-hCPCCode-list-by-iD/${productId}`,
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateProductHcpcCodeDTO) =>
    this.restService.request<any, ProductHcpcCodeDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-hcpc-code/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
