<!-- Component Start -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <!-- Nav Bar Start -->
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-2 mb-0 font-size-18"><b>Master / WIP Status</b></h4>
        <div class="page-title-box page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <!-- <li> -->

            <button
              class="mr-2 mt-0 buttonColor"
              mat-raised-button
              [disabled]="
                dataSource && dataSource?.data && dataSource?.data?.length === 0
              "
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Master WIP Status',
                  sheet: 'WIP Status',
                  Props: { Author: 'QSecure' }
                })
              "
            >
              Export
            </button>
          </ol>
        </div>
      </div>
      <!-- Nav End Start -->
    </div>

    <div class="col-12">
      <!-- <div class="card card-body"> -->
      <!-- Accordion Form Start -->
      <mat-accordion>
        <mat-expansion-panel
          (toggle)="(step)"
          [expanded]="step"
          (afterExpand)="toggle()"
          (closed)="toggleV2()"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container
                *ngIf="colorCodeId === defaultGuid; else elseTitleTemplate"
              >
                <!--*ngIf="mstModifierId === ''; else elseTitleTemplate" -->
                <b>Add WIP Status</b>
              </ng-container>
              <ng-template #elseTitleTemplate>
                <b>Edit WIP Status</b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="mstWIPStatusForm">
            <mat-card-content>
              <div class="row">
                <mat-form-field class="col-4">
                  <mat-label
                    >Status Name<span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    formControlName="txtStatusName"
                    placeholder="Enter Status Name"
                    maxlength="80"
                    autocomplete="new-txtStatusName"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      mstWIPStatusForm?.get('txtStatusName')?.touched &&
                      mstWIPStatusForm?.get('txtStatusName')?.errors?.required
                    "
                  >
                    Color Code Name is a required field!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-4">
                  <mat-label
                    >Color Code<span class="asterisk">*</span></mat-label
                  >

                  <input
                    matInput
                    [ngxMatColorPicker]="picker"
                    [style.background-color]="bgcolor"
                    style="font-weight: 700"
                    formControlName="colorCtr"
                    [disabled]="false"
                    autocomplete="new-colorCtr"
                  />
                  <ngx-mat-color-toggle
                    matSuffix
                    [for]="picker"
                  ></ngx-mat-color-toggle>
                  <ngx-mat-color-picker
                    #picker
                    [touchUi]="touchUi"
                    [color]="color"
                  ></ngx-mat-color-picker>
                  <mat-error
                    *ngIf="
                      mstWIPStatusForm?.get('colorCtr')?.touched &&
                      mstWIPStatusForm?.get('colorCtr')?.errors?.required
                    "
                  >
                    Enter Valid Color Code!
                  </mat-error>
                </mat-form-field>
                <div class="col-4">
                  <button
                    mat-button
                    class="buttonColor mb-2 mr-2"
                    [disabled]="
                      saveButtonHide ||
                      !(!mstWIPStatusForm.invalid && mstWIPStatusForm.dirty)
                    "
                    (click)="saveStatusColorCode()"
                  >
                    Save
                    <mat-icon *ngIf="saveButtonHide">
                      <mat-spinner
                        class="spinner-border spinner-border-sm"
                        diameter="20"
                      >
                      </mat-spinner>
                    </mat-icon>
                  </button>
                  <button
                    mat-button
                    class="resetclr buttonColor mb-2 mr-2"
                    (click)="resetColorCodes()"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </mat-card-content>
          </form>
          <br />
        </mat-expansion-panel>
      </mat-accordion>
      <!-- Accordion Form End -->
      <!-- </div> -->
    </div>
    <br />
    <div class="col-12 pr-0 pl-0">
      <!-- <div class="card card-body"> -->
      <div class="col-12 pt-2">
        <!-- <h4 class="customThemeClass ml-3 mt-2"><b>Master WIP Status</b></h4> -->
        <div class="example-container mat-elevation-z4">
          <!-- Table Start -->

          <table
            mat-table
            class="table"
            matTableExporter
            #exporter="matTableExporter"
            [hiddenColumns]="[0]"
            matSort
            [dataSource]="dataSource"
            class="mat-elevation-z8"
          >
            <!-- WIP Status Name-->
            <ng-container matColumnDef="wipStatusName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                WIP Status
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.colorCodingName }}
                </ng-container>
              </td>
            </ng-container>
            <!-- Color Code -->
            <ng-container matColumnDef="colorCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Color Code
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  <button
                    mat-mini-fab
                    class="colorbutton"
                    [style.background-color]="data.colorCode"
                  >
                    <!-- <mat-icon>favorite</mat-icon> -->
                  </button>

                  <span>
                    <!-- [style.background-color]='data.colorCode' -->
                    <span class="mat-ripple mat-mdc-button-ripple"></span>
                    {{ data?.colorCode }}
                  </span>
                </ng-container>
              </td>
            </ng-container>
            <!-- Creation Time -->
            <ng-container matColumnDef="creationTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Created Date
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.creationTime | date : "MM/dd/yyyy" : "en_US" }}
                </ng-container>
              </td>
            </ng-container>
            <!-- Options -->
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef>Options</th>
              <td mat-cell *matCellDef="let data">
                <a class="editBtnColor" (click)="viewColorCode(data?.id)">
                  <i
                    class="fa fa-pencil editBtnColor mr-1"
                    matTooltip="Click To Edit"
                  ></i>
                </a>
                &nbsp;
                <a class="deleteBtnColor" (click)="deleteColorCode(data?.id)">
                  <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div
            *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              )
            "
            class="text-align-center"
          >
            <div class="message py-3" *ngIf="!isShowSpinner">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isShowSpinner">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
          <mat-paginator
            [pageSizeOptions]="[10, 20, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
        <!-- Table End -->
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
<!-- Component End -->
