<br />
<!--Mat Card-->
<mat-card class="mr-3 mt-2">
  <!--Heading-->
  <p class="accordHeading">Document View</p>
  <mat-divider></mat-divider>
  <br />
  <!--Heading End-->

  <!--Card Body-->
  <!--Form Start-->
  <form [formGroup]="documentForm" autocomplete="off">
    <!--Input Elements-->
    <div class="row">
      <div class="col-3">
        <label for="file-upload" class="col-lg-12 btnclr waves-effect font-size-12 pt-2 pb-2 cur-pointer"
          #lblUploadFile>
          <i class="fa fa-cloud-upload pr-3"></i>Browse<span class="asterisk">
            *
          </span>
        </label>
        <input id="file-upload" type="file" [multiple]="true" class="waves-effect" formControlName="documentFile"
          accept="application/pdf" #UploadFieldReference (change)="getfilename(UploadFieldReference.files)" />
        <mat-error *ngIf="
            documentForm?.get('documentFile')?.touched &&
            documentForm?.get('documentFile')?.errors?.required
          ">
          Document is a required field!
        </mat-error>
      </div>

      <div class="col-3">
        <mat-form-field class="col-12">
          <mat-label>Document Type <span class="asterisk">*</span> </mat-label>
          <mat-select (selectionChange)="togglePanel()" formControlName="drpDocumentType" maxlength="40">
            <mat-option *ngFor="let document of drpDocumentTypeLoop" [value]="document.documentType">
              {{ document.documentType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span>
          <mat-error class="spanAlignment" *ngIf="
              documentForm?.get('drpDocumentType')?.touched &&
              documentForm?.get('drpDocumentType')?.errors?.required
            ">
            Document Type is a required field!
          </mat-error>
        </span>
      </div>
    </div>

    <!--Uploaded Files-->
    <div class="col-12">
      <div *ngIf="filesname !== [] && filesname.length !== 0" class="alert" style="background-color: #9ae3df">
        <div *ngIf="filesname !== [] && filesname.length !== 0">
          <div class="lbl" *ngIf="filesname !== [] && filesname.length !== 0">
            <b>Documents To be Uploaded:{{ filesname.length }}</b>
          </div>
          <mat-divider></mat-divider>
          <div class="bgr mb-1 mt-3" *ngFor="let item of filesname">
            <i class="fa fa-file-pdf-o"></i>
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!--End Uploaded Files-->

    <!--Extended Info-->
    <mat-accordion class="pt-2">
      <mat-expansion-panel [expanded]="panelOpenState">
        <mat-expansion-panel-header class="accordHeading">
          <mat-panel-title> Extended Info </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row pt-1">
          <mat-form-field class="col-3">
            <mat-label>Review Status</mat-label>
            <mat-select formControlName="drpReviewStatus" maxlength="40">
              <mat-option *ngFor="let reviewstatus of drpReviewStatusLoop"
                [value]="reviewstatus.reviewStatusShortCodeId">
                {{ reviewstatus.reviewStatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Review Reason</mat-label>
            <mat-select formControlName="drpReviewReason" maxlength="40">
              <mat-option *ngFor="let reviewstatus of drpReviewReasonLoop"
                [value]="reviewstatus.reviewReasonShortCodeId">
                {{ reviewstatus.reviewReason }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-3">
            <mat-form-field class="col-12">
              <mat-label>
                Document Start Date
                <ng-container *ngIf="
                    documentForm?.get('drpDocumentType')?.value === 'RX' ||
                      documentForm?.get('drpDocumentType')?.value ===
                        'FACE TO FACE';
                    else elseStartDateReqTemplate
                  ">
                  <span class="asterisk">*</span>
                </ng-container>
                <ng-template #elseStartDateReqTemplate> </ng-template>
              </mat-label>
              <input autocomplete="new-txtDocumentStartDate" matInput [ngxMatDatetimePicker]="txtDocumentStartDate" placeholder="(MM/DD/YYYY)"
                formControlName="txtDocumentStartDate" />

              <mat-datepicker-toggle matSuffix [for]="txtDocumentStartDate"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #txtDocumentStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>
            <ng-container *ngIf="
                documentForm?.get('drpDocumentType')?.value === 'RX' ||
                documentForm?.get('drpDocumentType')?.value === 'FACE TO FACE'
              ">
              <span>
                <mat-error class="spanAlignment" *ngIf="
                    documentForm?.get('txtDocumentStartDate')?.touched &&
                    documentForm?.get('txtDocumentStartDate')?.errors?.required
                  ">
                  Document Start Date is a required field!
                </mat-error>
              </span>
            </ng-container>
          </div>

          <div class="col-3">
            <mat-form-field class="col-12">
              <mat-label>Document End Date
                <ng-container *ngIf="
                    documentForm?.get('drpDocumentType')?.value === 'RX' ||
                      documentForm?.get('drpDocumentType')?.value ===
                        'FACE TO FACE';
                    else elseStartDateReqTemplate
                  ">
                  <span class="asterisk">*</span>
                </ng-container>
                <ng-template #elseStartDateReqTemplate> </ng-template>
              </mat-label>
              <input autocomplete="new-txtDocumentEndDate" matInput [ngxMatDatetimePicker]="txtDocumentEndDate" placeholder="(MM/DD/YYYY)"
                formControlName="txtDocumentEndDate" />

              <mat-datepicker-toggle [for]="txtDocumentEndDate" matSuffix></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #txtDocumentEndDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>

            <ng-container *ngIf="
                documentForm?.get('drpDocumentType')?.value !== 'RX' ||
                documentForm?.get('drpDocumentType')?.value !== 'FACE TO FACE'
              ">
              <span>
                <mat-error class="spanAlignment" *ngIf="
                    documentForm?.get('txtDocumentEndDate')?.touched &&
                    documentForm?.get('txtDocumentEndDate')?.errors?.required
                  ">
                  Document End Date is a required field!
                </mat-error>
              </span>
            </ng-container>
          </div>

          <mat-form-field class="col-6">
            <mat-label>Description</mat-label>
            <textarea formControlName="txtNote" id="txtDescription" rows="1" matInput maxlength="400"></textarea>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!--End Extended Info-->
    <!--End Input Elements-->

    <!--Buttons-->
    <div class="col-12 pt-3 pb-3">
      <ng-container>
        <button [hidden]="isDocumentEditSaveButtonHidden" class="mr-3 waves-effect"
          [disabled]="documentForm.invalid || isShowSpinner" mat-raised-button color="primary"
          (click)="upload(UploadFieldReference.files)">
          <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner>
          </mat-icon>
          Save
        </button>
      </ng-container>
      <button [disabled]="isEditBtn == true" mat-raised-button color="warn" class="waves-effect"
        (click)="resetDocumentForm()">
        Reset
      </button>
    </div>
    <!--End Buttons-->
    <!--End Input Elements-->
  </form>
  <!--Form End-->

  <!--Table-->
  <mat-accordion multi class="pt-5">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="accordHeading">
        <mat-panel-title>
          Uploaded Document(s) Count: {{ arrUnsortedDocumentList?.length }}
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="row pt-1">
        <ng-container>
          <div class="col-12 table-responsive">
            <div class="mat-elevation-z8">
              <form [formGroup]="documentSearchForm">
                <table mat-table [dataSource]="dataSource">
                  <!-- Document Type Column -->
                  <ng-container matColumnDef="documentType">
                    <th class="header" mat-header-cell *matHeaderCellDef class="customizedHeadingFont">
                      <mat-form-field class="filter">
                        <mat-label class="customizedHeadingFont">
                          Document Type
                        </mat-label>
                        <input matInput formControlName="documentType" autocomplete="new-documentType" />
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element" class="customizedFont">
                      {{ element.documentType }}
                    </td>
                  </ng-container>
                  <!-- File Name Column -->

                  <ng-container matColumnDef="fileName">
                    <th class="header" mat-header-cell *matHeaderCellDef class="customizedHeadingFont">
                      <mat-form-field class="filter">
                        <mat-label class="customizedHeadingFont">File Name</mat-label>
                        <input matInput formControlName="fileName" type="text" autocomplete="new-fileName" />
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element" class="customizedFont">
                      {{ element.fileName }}
                    </td>
                  </ng-container>

                  <!-- Document Start Date Column -->
                  <ng-container matColumnDef="documentStartDate">
                    <th class="header" mat-header-cell *matHeaderCellDef class="customizedHeadingFont">
                      <mat-form-field class="filter" autocomplete="off">
                        <mat-label class="customizedHeadingFont">
                          Document Start Date
                        </mat-label>
                        <input matInput placeholder="(MM/DD/YYYY)" autocomplete="new-documentStartDate"
                          formControlName="documentStartDate" class="customizedHeadingFont" />
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element" class="customizedFont">
                      {{
                      element?.documentStartDate
                      | date : "MM/dd/yyyy h:mm a" : "en_US"
                      }}
                    </td>
                  </ng-container>
                  <!-- Document End Date Column -->
                  <ng-container matColumnDef="documentEndDate">
                    <th class="header" mat-header-cell *matHeaderCellDef class="customizedHeadingFont">
                      <mat-form-field class="filter">
                        <mat-label class="customizedHeadingFont">
                          Document End Date
                        </mat-label>
                        <input autocomplete="new-documentEndDate" matInput placeholder="(MM/DD/YYYY)" formControlName="documentEndDate"
                          class="customizedHeadingFont" />
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element" class="customizedFont">
                      {{
                      element?.documentEndDate
                      | date : "MM/dd/yyyy h:mm a" : "en_US"
                      }}
                    </td>
                  </ng-container>

                  <!-- Document Status Column -->
                  <ng-container matColumnDef="documentStatus">
                    <th mat-header-cell *matHeaderCellDef class="customizedHeadingFont pt-2">
                      Document Status
                    </th>
                    <td mat-cell *matCellDef="let element" class="customizedFont">
                      {{ element.docStatus }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="noRecords">
                    <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
                      <div *ngIf="!isShowProcessBar">
                        {{ "No Records Found" }}
                      </div>
                      <mat-icon [hidden]="!isShowProcessBar">
                        <mat-spinner class="table spinner-border spinner-border-sm" diameter="20">
                        </mat-spinner>
                      </mat-icon>
                    </td>
                  </ng-container>
                  <!-- Options Column -->
                  <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef class="customizedHeadingFont pl-2">
                      Options
                    </th>
                    <td mat-cell *matCellDef="let documentList" class="customizedFont">
                      <a class="editBtnColor">
                        <i class="fa fa-pencil mr-3 pointer customThemeClass" matTooltip="Edit" (click)="
                            viewPdfFile(documentList.blobName, documentList.id)
                          "></i>
                      </a>
                      <a class="editBtnColor">
                        <i class="fa fa-cloud-download mr-3 pointer customThemeClass" matTooltip="Download" (click)="
                            downloadFile(
                              documentList.blobName,
                              documentList.fileName
                            )
                          "></i>
                      </a>
                      <a class="editBtnColor">
                        <i class="fa fa-trash pointer asterisk" matTooltip="Delete"
                          (click)="deleteFileById(documentList.id)"></i>
                      </a>
                    </td>
                  </ng-container>

                  <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
                      active:
                        dataSource &&
                        dataSource?.data &&
                        dataSource?.data?.length !== 0
                    }"></tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </form>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                aria-label="Select page of periodic elements">
              </mat-paginator>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!--End Table-->

  <br />
  <mat-divider></mat-divider>

  <div class="pl-5">
    <form [formGroup]="medicareUpdateForm">
      <div class="row pt-3" (click)="clearNotificationData()">
        <div class="col-12">
          <mat-progress-bar mode="determinate" [ngClass]="{
              progress_success: percentageOfCompletion === 100,
              progress_danger: percentageOfCompletion !== 100
            }" matTooltip="{{ percentageOfCompletion + ' % Of Completion' }}" [value]="percentageOfCompletion">
          </mat-progress-bar>
        </div>
        <div class="col-12" [style.text-align]="'-webkit-center'">
          <button type="button" *ngIf="percentageOfCompletion === 100" class="mt-3 btn btn-success">
            {{ percentageOfCompletion + " % Of Completion" }}
          </button>
          <button type="button" *ngIf="percentageOfCompletion !== 100" class="mt-3 btn btn-danger">
            {{ percentageOfCompletion + " % Of Completion" }}
          </button>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-lg-2">
          <mat-checkbox formControlName="chkIsRulesNeeded">Is Rules Needed
          </mat-checkbox>
        </div>
        <div class="col-lg-2">
          <mat-form-field>
            <mat-label>DX Type</mat-label>
            <mat-select formControlName="drpDXType">
              <mat-option *ngFor="let dx of drpDXType" [value]="dx.dxType">
                {{ dx.dxType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-lg-2">
          <mat-form-field *ngIf="
        medicareUpdateForm?.get('drpDXType')?.value !== 'CPAP to BIPAP' &&
        medicareUpdateForm?.get('drpDXType')?.value !== 'CompSA'
        ">
            <mat-label>Product Setup Type</mat-label>
            <mat-select formControlName="drpPrdSetupType">
              <mat-option *ngFor="let setup of drpPrdSetupType" [value]="setup?.setupType">
                {{ setup?.setupType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="row pt-5">
    <!--Side Bar-->
    <div class="col-xl-5 tree-container" style="height: 1000px">
      <div class="card card-body ml-2">
        <div class="text-center">
          <span style="font-size: larger"><b class="colored-heading">Document Types</b></span>
        </div>
        <div layout-align="center center">
          <!-- <mat-tree [dataSource]="dataSource1" [treeControl]="treeControl">
            <mat-tree-node
              class="childNodeClass text-justify"
              *matTreeNodeDef="let ex"
              matTreeNodePadding
              val="ex"
              (click)="activeNode = ex"
              [ngClass]="{ 'background-highlight': activeNode === ex }"
              (click)="getPdf(ex.blobName)"
            >
              <div class="custom-doc-name">
                <i class="fa fa-file-pdf-o"></i>
                {{ ex?.name }}
              </div>

              <small>{{ ex?.uploadedDate }}</small>

            </mat-tree-node>
            <mat-tree-node
              style="font-weight: 500"
              style="cursor: default"
              *matTreeNodeDef="let ex; when: hasChild"
              matTreeNodePadding
            >
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + ex.name"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(ex) ? "expand_more" : "chevron_right"
                  }}
                </mat-icon>
              </button>
              {{ ex?.name }}
              &nbsp;
              <span
                class="pull-right"
                matBadge="{{ ex?.documentCount }}"
                matBadgeOverlap="false"
              ></span>
            </mat-tree-node>
          </mat-tree> -->

          <mat-accordion>
            <form [formGroup]="PreviewExtractionForm">


              <mat-expansion-panel>
                <mat-expansion-panel-header class="custom-acccordion-header">
                  <mat-panel-title>
                    <div class="custom-qsecure-color font-weight-bolder">
                      ALL&nbsp;
                      <span class="circular-badge">
                        {{ childTree['all'] ? childTree['all'].length : 0 }}
                      </span>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content>
                  <mat-tree [dataSource]="childTree['all']" [treeControl]="treeControl">
                    <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex" matTreeNodePadding
                      val="ex" (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
                      (click)="getPdf(ex.blobName)">
                      <div class="custom-doc-name">
                        <i class="fa fa-file-pdf-o"></i>
                        {{ ex?.name }}
                      </div>

                      <small>{{ ex?.uploadedDate }}</small>

                    </mat-tree-node>

                  </mat-tree>
                </mat-card-content>
              </mat-expansion-panel>

              <!-- <mat-expansion-panel>
                <mat-expansion-panel-header class="custom-acccordion-header">
                  <mat-panel-title>
                    <div class="custom-qsecure-color font-weight-bolder">
                      DEMOGRAPHICS&nbsp;
                      <span class="circular-badge">
                        {{ childTree['demographics'] ? childTree['demographics'].length : 0 }}
                      </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content>

                  <mat-form-field class="col-12">
                    <mat-label> First Name </mat-label>
                    <input matInput maxlength="40" formControlName="txtFirstName" type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtFirstName')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtFirstName')
                          ?.errors?.sAlphabets
                      ">
                      Enter only Alphabets!
                    </mat-error>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtFirstName')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtFirstName')
                          ?.errors?.required
                      ">
                      First Name is a required field!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label> Middle Name </mat-label>
                    <input matInput maxlength="40" formControlName="txtMiddleName" type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtMiddleName')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtMiddleName')
                          ?.errors?.sAlphabets
                      ">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label> Last Name </mat-label>
                    <input matInput maxlength="40" formControlName="txtLastName" type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtLastName')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtLastName')
                          ?.errors?.sAlphabets
                      ">
                      Enter only Alphabets!
                    </mat-error>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtLastName')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtLastName')
                          ?.errors?.required
                      ">
                      Last Name is a required field!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="drpGeneralGender" [(value)]="gender" maxlength="40">
                      <mat-option *ngFor="let test of drpGenderLoop" [value]="test.genderShortCode">
                        {{ test.genderName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="gender = ''; $event.stopPropagation()" *ngIf="gender" matSuffix
                      (click)="clearGender()" mat-icon-button>
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('drpGeneralGender')
                          ?.touched &&
                        PreviewExtractionForm?.get('drpGeneralGender')
                          ?.errors?.required
                      ">
                      Gender is a required field!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label> Birth Date </mat-label>
                    <input formControlName="txtDOB" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                      [matDatepicker]="txtDOB" />
                    <mat-datepicker-toggle matSuffix [for]="txtDOB">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtDOB> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtDOB')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtDOB')?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtDOB')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtDOB')?.errors
                          ?.required
                      ">
                      Birth Date is a required field!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="mt-4 col-12">
                    <mat-label> Reason </mat-label>
                    <input matInput maxlength="40" formControlName="txtReason" type="text" />
                  </mat-form-field>


                  <mat-tree [dataSource]="childTree['demographics']" [treeControl]="treeControl">
                    <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex" matTreeNodePadding
                      val="ex" (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
                      (click)="getPdf(ex.blobName)">
                      <div class="custom-doc-name">
                        <i class="fa fa-file-pdf-o"></i>
                        {{ ex?.name }}
                      </div>

                      <small>{{ ex?.uploadedDate }}</small>

                    </mat-tree-node>

                  </mat-tree>

                </mat-card-content>

              </mat-expansion-panel> -->

              <mat-expansion-panel>
                <mat-expansion-panel-header class="custom-acccordion-header">
                  <mat-panel-title>
                    <div class="custom-qsecure-color font-weight-bolder">
                      INITIAL FACE TO FACE&nbsp;
                      <span class="circular-badge">
                        {{ childTree['facetoface'] ? childTree['facetoface'].length : 0 }}
                      </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content>
                  <!-- <div *ngFor="let ticket of b.controls; let i = index" class="list-group list-group-flush"> -->
                  <!-- <div [formGroup]="ticket" class="form-row"> -->
                    <div  class="form-row">
                      <!-- [formGroup]="faceToface" -->

                  <mat-form-field class="col-12">
                    <mat-label> Patient Name </mat-label>
                    <input autocomplete="new-txtFobPatientName" matInput maxlength="40" formControlName="txtFobPatientName" type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get(
                          'txtFobPatientName'
                        )?.touched &&
                        PreviewExtractionForm?.get(
                          'txtFobPatientName'
                        )?.errors?.sAlphabets
                      ">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label> Date of Birth </mat-label>
                    <input autocomplete="new-txtIobDob" formControlName="txtIobDob" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                      [matDatepicker]="txtIobDob" />
                    <mat-datepicker-toggle matSuffix [for]="txtIobDob">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtIobDob> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtIobDob')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtIobDob')
                          ?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label [ngClass]="{
                        warning:
                          ruleColor?.erStatus13 ||
                          ruleColor?.erStatus15 ||
                          ruleColor?.erStatus29 ||
                          ruleColor?.erStatus40 ||
                          ruleColor?.erStatus43
                      }">
                      Face To Face Date
                    </mat-label>
                    <input autocomplete="new-txtIobFadeToFace" formControlName="txtIobFadeToFace" matInput matTooltip="{{
                        ruleColor?.errValue13 ||
                          ruleColor?.errValue15 ||
                          ruleColor?.errValue29 ||
                          ruleColor?.errValue40 ||
                          ruleColor?.errValue43
                      }}" maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtIobFadeToFace" />
                    <mat-datepicker-toggle matSuffix [for]="txtIobFadeToFace">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtIobFadeToFace>
                    </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtIobFadeToFace')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtIobFadeToFace')
                          ?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>

                  <div class="col-md-12 text-right">
                    <button type="button" class="buttonColor btn-sm btn-circle" (click)="openDoctorModal()">
                      <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                    </button>
                  </div>

                  <mat-form-field class="col-12">
                    <mat-label>Doctor Name</mat-label>
                    <mat-select formControlName="txtDoctor" (valueChange)="
                        doctorValueChange('f2f', singleSelect)
                      " [(value)]="OrderingDoctor" #singleSelect>
                      <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.name">
                        {{ doctor.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label>NPI Number</mat-label>
                    <input autocomplete="new-txtNpiNumber" maxlength="13" mask="000-000-0000" formControlName="txtNpiNumber" matInput type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtNpiNumber')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtNpiNumber')
                          ?.errors?.number
                      ">
                      Enter only Numbers!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label> Signed date </mat-label>
                    <input autocomplete="new-txtSignedDate" formControlName="txtSignedDate" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtSignedDate1" />
                    <mat-datepicker-toggle matSuffix [for]="txtSignedDate1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtSignedDate1> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtSignedDate')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtSignedDate')
                          ?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>

                  <div class="col-12">
                    <br />
                    <mat-checkbox formControlName="chkPrevioushistory">Previous history – declaration
                    </mat-checkbox>
                  </div>

                  <div class="col-12">
                    <br />
                    <mat-checkbox aria-labelledby="Sleep Test Recommended" [ngClass]="{
                        warning:
                          ruleColor?.erStatus2 ||
                          ruleColor?.erStatus21
                      }" matTooltip="{{
                        ruleColor?.errValue2 || ruleColor?.errValue21
                      }}" formControlName="chkSleepTestRecommended">Sleep Test Recommended
                    </mat-checkbox>
                  </div>

                  <div class="col-12">
                    <br />
                    <mat-checkbox [ngClass]="{
                        warning:
                          ruleColor?.erStatus3 ||
                          ruleColor?.erStatus17 ||
                          ruleColor?.erStatus22 ||
                          ruleColor?.erStatus32
                      }" matTooltip="{{
                        ruleColor?.errValue3 ||
                          ruleColor?.errValue17 ||
                          ruleColor?.errValue22 ||
                          ruleColor?.errValue32
                      }}" formControlName="chkSleepDoctorSigned">Doctor Signed
                    </mat-checkbox>
                  </div>

                  <div class="col-12">
                    <br />

                    <mat-checkbox matTooltip="{{
                        ruleColor?.errValue16 || ruleColor?.errValue30
                      }}" [ngClass]="{
                        warning:
                          ruleColor?.erStatus16 ||
                          ruleColor?.erStatus30
                      }" formControlName="chkSleepPatientBenifited">
                      Patient Benifits and Usage
                    </mat-checkbox>
                  </div>

                  <mat-form-field class="col-12">
                    <mat-label [ngClass]="{
                        warning:
                          ruleColor?.erStatus1 ||
                          ruleColor?.erStatus20
                      }">Symptoms
                    </mat-label>
                    <mat-select multiple matTooltip="{{
                        ruleColor?.errValue1 || ruleColor?.errValue20
                      }}" formControlName="drpSymptoms">
                      <mat-option *ngFor="let sysm of drpSymptoms" [value]="sysm.symptom">
                        {{ sysm.symptom }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('drpSymptoms')
                          ?.touched &&
                        PreviewExtractionForm?.get('drpSymptoms')
                          ?.errors?.required
                      ">
                      Symptoms is a required field!
                    </mat-error>
                  </mat-form-field>

                  <mat-tree [dataSource]="childTree['facetoface']" [treeControl]="treeControl">
                    <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex" matTreeNodePadding
                      val="ex" (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
                      (click)="getPdf(ex.blobName)">
                      <div class="custom-doc-name">
                        <i class="fa fa-file-pdf-o"></i>
                        {{ ex?.name }}
                      </div>

                      <small>{{ ex?.uploadedDate }}</small>

                    </mat-tree-node>

                  </mat-tree>
                <!-- </div> -->
              </div>

                </mat-card-content>

              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header class="custom-acccordion-header">
                  <mat-panel-title>
                    <div class="custom-qsecure-color font-weight-bolder">
                      SLEEP STUDY REPORT&nbsp;
                      <span class="circular-badge">{{ childTree['sleepstudyreport'] ?
                        childTree['sleepstudyreport'].length : 0 }}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content>
                <div  class="form-row">
                  <!-- [formGroup]="sleepStudyReport" -->
                  <mat-form-field class="col-12">
                    <mat-label> Patient Name </mat-label>
                    <input autocomplete="new-txtPatientName1" matInput maxlength="40" formControlName="txtPatientName1" type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtPatientName1')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtPatientName1')
                          ?.errors?.sAlphabets
                      ">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label> Birth Date </mat-label>
                    <input autocomplete="new-txtDob" formControlName="txtDob" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                      [matDatepicker]="txtDob" />
                    <mat-datepicker-toggle matSuffix [for]="txtDob">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtDob> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtDob')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtDob')?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-6">
                    <mat-label> Study date </mat-label>
                    <input autocomplete="new-txtStudyDate" formControlName="txtStudyDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                      [matDatepicker]="txtStudyDate" />
                    <mat-datepicker-toggle matSuffix [for]="txtStudyDate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtStudyDate> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtStudyDate')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtStudyDate')
                          ?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-6">
                    <mat-label>Pressure Setting </mat-label>
                    <input autocomplete="new-txtPressureSetting" matInput formControlName="txtPressureSetting" pattern="^\d{0,10}"
                      (keypress)="numberOnly($event)" maxlength="40" type="text" />
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label>REI</mat-label>
                    <input autocomplete="new-txtREI" maxlength="5" formControlName="txtREI" (keypress)="keyPressNumbersDecimal($event)" matInput
                      type="text" />
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label>RDI </mat-label>
                    <input autocomplete="new-txtRDI" maxlength="5" formControlName="txtRDI" (keypress)="keyPressNumbersDecimal($event)" matInput
                      type="text" />
                  </mat-form-field>
                  <div>
                    <p class="col-12">
                      <br />
                      <b> Diagnostic Sleep Study </b>
                    </p>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{ warning: ruleColor?.erStatus5 }">Total Sleep Minutes</mat-label>
                      <input autocomplete="new-txtSleepMinutes" matInput formControlName="txtSleepMinutes" matTooltip="{{ ruleColor?.errValue5 }}"
                        pattern="^\d{0,10}" (keypress)="numberOnly($event)" maxlength="5" type="text" />
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{
                          warning:
                            ruleColor?.erStatus6 ||
                            ruleColor?.erStatus24 ||
                            ruleColor?.erStatus32
                        }">
                        AHI</mat-label>
                      <input autocomplete="new-txtAHI" type="text" maxlength="5" (keypress)="keyPressNumbersDecimal($event)"
                        formControlName="txtAHI" matInput matTooltip="{{
                          ruleColor?.errValue6 ||
                            ruleColor?.errValue24 ||
                            ruleColor?.errValue32
                        }}" />
                    </mat-form-field>
                    <div class="col-6">
                      <br />
                      <mat-checkbox matTooltip="{{
                          ruleColor?.errValue7 ||
                            ruleColor?.errValue18 ||
                            ruleColor?.errValue23 ||
                            ruleColor?.errValue26 ||
                            ruleColor?.errValue35
                        }}" [ngClass]="{
                          warning:
                            ruleColor?.erStatus7 ||
                            ruleColor?.erStatus18 ||
                            ruleColor?.erStatus23 ||
                            ruleColor?.erStatus26 ||
                            ruleColor?.erStatus35
                        }" formControlName="chkSleepStudyDoctorSigned">Doctor Signed
                      </mat-checkbox>
                    </div>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Apneas</mat-label>
                      <input autocomplete="new-txtSSCentralApneas" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                        (keypress)="keyPressNumbersDecimal($event)" formControlName="txtSSCentralApneas" matInput />
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Hyponeas</mat-label>
                      <input autocomplete="new-txtSSCentralHyponeas" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                        (keypress)="keyPressNumbersDecimal($event)" formControlName="txtSSCentralHyponeas" matInput />
                    </mat-form-field>

                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Total Apnea And Hypopnea
                      </mat-label>
                      <input autocomplete="new-txtSSApneaAndHypopnea" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                        (keypress)="keyPressNumbersDecimal($event)" formControlName="txtSSApneaAndHypopnea" matInput />
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{
                          warning:
                            ruleColor?.erStatus4 ||
                            ruleColor?.erStatus23 ||
                            ruleColor?.erStatus14
                        }">
                        Diagnosis Sleep Study date
                      </mat-label>
                      <input autocomplete="new-txtDiagnosisStudyDate" formControlName="txtDiagnosisStudyDate" matInput maxlength="40" type="text" matTooltip="{{
                          ruleColor?.errValue4 ||
                            ruleColor?.errValue23 ||
                            ruleColor?.errValue14
                        }}" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDiagnosisStudyDate" />
                      <mat-datepicker-toggle matSuffix [for]="txtDiagnosisStudyDate">
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtDiagnosisStudyDate>
                      </mat-datepicker>
                      <mat-error *ngIf="
                          PreviewExtractionForm?.get(
                            'txtDiagnosisStudyDate'
                          )?.touched &&
                          PreviewExtractionForm?.get(
                            'txtDiagnosisStudyDate'
                          )?.errors
                        ">
                        Enter Valid date
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div>
                    <p class="col-12">
                      <br />
                      <b> Titration Sleep Study </b>
                    </p>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{
                          warning:
                            ruleColor?.erStatus37 ||
                            ruleColor?.erStatus45
                        }">Sleep Study Date
                      </mat-label>
                      <input autocomplete="new-txtTitrationSleepStudyDate" formControlName="txtTitrationSleepStudyDate" matInput maxlength="40" type="text"
                        placeholder="(MM/DD/YYYY)" matTooltip="{{
                          ruleColor?.errValue37 ||
                            ruleColor?.errValue45
                        }}" [matDatepicker]="txtTitrationSleepStudyDate" />
                      <mat-datepicker-toggle matSuffix [for]="txtTitrationSleepStudyDate">
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtTitrationSleepStudyDate>
                      </mat-datepicker>
                      <mat-error *ngIf="
                          PreviewExtractionForm?.get(
                            'txtTitrationSleepStudyDate'
                          )?.touched &&
                          PreviewExtractionForm?.get(
                            'txtTitrationSleepStudyDate'
                          )?.errors
                        ">
                        Enter Valid date
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{
                          warning:
                            ruleColor?.erStatus6 ||
                            ruleColor?.erStatus24 ||
                            ruleColor?.erStatus32
                        }">
                        AHI</mat-label>
                      <input autocomplete="new-txtTAHI" type="text" maxlength="5" (keypress)="keyPressNumbersDecimal($event)"
                        formControlName="txtTAHI" matTooltip="{{
                          ruleColor?.errValue6 ||
                            ruleColor?.errValue24 ||
                            ruleColor?.errValue32
                        }}" matInput />
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Apneas</mat-label>
                      <input autocomplete="new-txtTCentralApneas" type="text" matTooltip="{{ ruleColor?.errValue27 }}" maxlength="5"
                        (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTCentralApneas" matInput />
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Hyponeas</mat-label>
                      <input autocomplete="new-txtTCentralHyponeas" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                        (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTCentralHyponeas" matInput />
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Total Apnea And Hypopnea
                      </mat-label>
                      <input autocomplete="new-txtTApneaAndHypopnea" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                        (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTApneaAndHypopnea" matInput />
                    </mat-form-field>
                    <div class="row m-0 p-0">
                      <div class="col-12">
                        <br />
                        <mat-checkbox [ngClass]="{
                            warning:
                              ruleColor?.erStatus8 ||
                              ruleColor?.erStatus38
                          }" matTooltip="{{
                            ruleColor?.errValue8 ||
                              ruleColor?.errValue38
                          }}" formControlName="chkCPAPTriedAndFail">
                          CPAP Tried And Fail
                        </mat-checkbox>
                      </div>
                      <div class="col-12">
                        <br />
                        <mat-checkbox [ngClass]="{
                            warning:
                              ruleColor?.erStatus10 ||
                              ruleColor?.erStatus39
                          }" matTooltip="{{
                            ruleColor?.errValue10 ||
                              ruleColor?.errValue39
                          }}" formControlName="chkRespiratoryBreakdownAvailable">
                          Respiratory Breakdown Available
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row m-0 p-0">
                      <div class="col-12">
                        <br />
                        <mat-checkbox [ngClass]="{
                            warning: ruleColor?.erStatus3
                          }" matTooltip="{{ ruleColor?.errValue3 }}"
                          formControlName="chkTitrationSleepStudyDoctorSigned">
                          Doctor Signed
                        </mat-checkbox>
                      </div>
                      <div class="col-12">
                        <br />
                        <mat-checkbox formControlName="chkisCPAPReturned">
                          CPAP Returned
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row m-0 p-0">
                      <div class="col-12">
                        <br />
                        <mat-checkbox [ngClass]="{
                            warning: ruleColor?.erStatus41
                          }" matTooltip="{{ ruleColor?.errValue41 }}" formControlName="chkTSSBaselineFile">
                          Base line File Available
                        </mat-checkbox>
                      </div>
                      <mat-form-field class="col-12">
                        <mat-label [ngClass]="{
                            warning: ruleColor?.erStatus49
                          }">Obstructive Apnea
                        </mat-label>
                        <input autocomplete="new-txtObstructiveApnea" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue49 }}"
                          (keypress)="keyPressNumbersDecimal($event)" formControlName="txtObstructiveApnea" matInput />
                      </mat-form-field>
                    </div>
                    <div class="col-md-12 text-right">
                      <button type="button" class="buttonColor btn-sm btn-circle" (click)="openIcd10Modal()">
                        <i class="fa fa-plus" matTooltip="Click to Add DiagnosisCodes-10"></i>
                      </button>
                    </div>
                    <mat-form-field class="col-12">
                      <mat-label>Diagnosis Code-ICD-10</mat-label>
                      <mat-select multiple formControlName="txtdiagnosisCode10Id" #singleSelect>
                        <mat-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="
                            diagnosis.diagnosisCode10ShortCodeId
                          ">
                          {{ diagnosis.diagnosisCodeTypeData }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <mat-tree [dataSource]="childTree['sleepstudyreport']" [treeControl]="treeControl">
                    <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex" matTreeNodePadding
                      val="ex" (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
                      (click)="getPdf(ex.blobName)">
                      <div class="custom-doc-name">
                        <i class="fa fa-file-pdf-o"></i>
                        {{ ex?.name }}
                      </div>

                      <small>{{ ex?.uploadedDate }}</small>

                    </mat-tree-node>

                  </mat-tree>
                </div>
                </mat-card-content>

              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header class="custom-acccordion-header">
                  <mat-panel-title>
                    <div class="custom-qsecure-color font-weight-bolder">
                      RX&nbsp;
                      <span class="circular-badge">
                        {{ childTree['rx'] ? childTree['rx'].length : 0 }}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content>
                  <div  class="form-row">
                    <!-- [formGroup]="rx" -->
                  <mat-form-field class="col-12">
                    <mat-label> Patient Name </mat-label>
                    <input autocomplete="new-txtPatientName2" matInput maxlength="40" formControlName="txtPatientName2" type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtPatientName2')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtPatientName2')
                          ?.errors?.sAlphabets
                      ">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label> Birth Date </mat-label>
                    <input autocomplete="new-txtDOB2" [max]="txtDOB2" formControlName="txtDOB2" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDOB2" [min]="txtDOB2" />
                    <mat-datepicker-toggle matSuffix [for]="txtDOB2">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtDOB2> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtDOB2')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtDOB2')?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-md-12">
                    <mat-label [ngClass]="{
                        warning:
                          ruleColor?.erStatus12 ||
                          ruleColor?.erStatus19 ||
                          ruleColor?.erStatus28 ||
                          ruleColor?.erStatus36
                      }">
                      Prescription Date
                    </mat-label>
                    <input autocomplete="new-txtPrescriptionDate" formControlName="txtPrescriptionDate" matTooltip="{{
                        ruleColor?.errValue12 ||
                          ruleColor?.errValue19 ||
                          ruleColor?.errValue28 ||
                          ruleColor?.errValue36
                      }}" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                      [matDatepicker]="txtPrescriptionDate" />
                    <mat-datepicker-toggle matSuffix [for]="txtPrescriptionDate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtPrescriptionDate>
                    </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get(
                          'txtPrescriptionDate'
                        )?.touched &&
                        PreviewExtractionForm?.get(
                          'txtPrescriptionDate'
                        )?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>

                  <div class="col-md-12 text-right">
                    <button type="button" class="buttonColor btn-sm btn-circle" (click)="openDoctorModal()">
                      <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                    </button>
                  </div>

                  <mat-form-field class="col-12">
                    <mat-label>Doctor Name</mat-label>
                    <mat-select formControlName="txtDoctor" (valueChange)="
                        doctorValueChange('p2p', doctorSelect)
                      " #doctorSelect>
                      <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.name">
                        {{ doctor.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="col-6">
                    <mat-label>Machine Details</mat-label>
                    <mat-select formControlName="drpMachineDetails" maxlength="40">
                      <mat-option *ngFor="let machine of drpMachines" [value]="machine.machineType">
                        {{ machine.machineType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label> Pressure Level </mat-label>
                    <input autocomplete="new-txtPressureLevel" matInput maxlength="40" formControlName="txtPressureLevel" (keypress)="numberOnly($event)"
                      pattern="^\d{0,10}" type="text" />
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label> Length of need </mat-label>
                    <input autocomplete="new-txtLengthOfNeed" matInput maxlength="40" formControlName="txtLengthOfNeed" type="text" />
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>NPI Number</mat-label>
                    <input autocomplete="new-txtNpiNumber" maxlength="13" mask="000-000-0000" formControlName="txtNpiNumber" matInput type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtNpiNumber')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtNpiNumber')
                          ?.errors?.number
                      ">
                      Enter only Numbers!
                    </mat-error>
                  </mat-form-field>
                  <div class="col-6">
                    <br />
                    <mat-checkbox formControlName="chkDoctorSigned">Is Doctor Signed
                    </mat-checkbox>
                  </div>
                  <mat-form-field class="col-12">
                    <mat-label> Doctor Signed Date </mat-label>
                    <input autocomplete="new-txtSignedDate" [max]="txtSignedDate" formControlName="txtSignedDate" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtSignedDate" [min]="txtSignedDate" />
                    <mat-datepicker-toggle matSuffix [for]="txtSignedDate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtSignedDate> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtSignedDate')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtSignedDate')
                          ?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-12">
                    <mat-label>Diagnosis Code-ICD-10</mat-label>
                    <mat-select multiple formControlName="txtdiagnosisCode10Id1" #singleSelect>
                      <mat-option *ngFor="let diagnosis of drpDiagnosisICD10"
                        [value]="diagnosis.diagnosisCode10ShortCodeId">
                        {{ diagnosis.diagnosisCodeTypeData }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-tree [dataSource]="childTree['rx']" [treeControl]="treeControl">
                    <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex" matTreeNodePadding
                      val="ex" (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
                      (click)="getPdf(ex.blobName)">
                      <div class="custom-doc-name">
                        <i class="fa fa-file-pdf-o"></i>
                        {{ ex?.name }}
                      </div>

                      <small>{{ ex?.uploadedDate }}</small>

                    </mat-tree-node>

                  </mat-tree>
                </div>
                </mat-card-content>

              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header class="custom-acccordion-header">
                  <mat-panel-title>
                    <div class="custom-qsecure-color font-weight-bolder">
                      COMPLIANCE REPORT&nbsp;
                      <span class="circular-badge">
                        {{ childTree['compliancereport'] ? childTree['compliancereport'].length : 0 }}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content>
                <div [formGroup]="complianceReport" class="form-row">


                  <mat-form-field class="col-6">
                    <mat-label> Patient Name </mat-label>
                    <input autocomplete="new-txtPatientName3" matInput maxlength="40" formControlName="txtPatientName3" type="text" />
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtPatientName3')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtPatientName3')
                          ?.errors?.sAlphabets
                      ">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label> Birth Date </mat-label>
                    <input autocomplete="new-txtDOB3" formControlName="txtDOB3" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                      [matDatepicker]="txtDOB3" />
                    <mat-datepicker-toggle matSuffix [for]="txtDOB3">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtDOB3> </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtDOB3')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtDOB3')?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label> Study Start date </mat-label>
                    <input autocomplete="new-txtStudyStartDate" formControlName="txtStudyStartDate" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStudyStartDate" />
                    <mat-datepicker-toggle matSuffix [for]="txtStudyStartDate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtStudyStartDate>
                    </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get(
                          'txtStudyStartDate'
                        )?.touched &&
                        PreviewExtractionForm?.get(
                          'txtStudyStartDate'
                        )?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Compliance percentage</mat-label>
                    <input autocomplete="new-txtCompliancePercentage" maxlength="40" formControlName="txtCompliancePercentage" matInput type="text"
                      pattern="[a-zA-Z0-9]*" />
                  </mat-form-field>
                  <mat-form-field class="col-12">
                    <mat-label> Study End date </mat-label>
                    <input autocomplete="new-txtStudyEndDate" formControlName="txtStudyEndDate" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStudyEndDate" />
                    <mat-datepicker-toggle matSuffix [for]="txtStudyEndDate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtStudyEndDate>
                    </mat-datepicker>
                    <mat-error *ngIf="
                        PreviewExtractionForm?.get('txtStudyEndDate')
                          ?.touched &&
                        PreviewExtractionForm?.get('txtStudyEndDate')
                          ?.errors
                      ">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                  <div class="col-12 mt-2">
                    <label for="rbnComplianceMet">
                      Compliance Met
                    </label>
                    <mat-radio-group id="rbnComplianceMet" formControlName="rbnComplianceMet"
                      class="col-sm-7 radio mt-2 mr-2 txt-left">
                      <mat-radio-button class="mr-2" value="1">Yes</mat-radio-button>
                      <mat-radio-button class="mr-2" value="0">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <mat-form-field class="col-6">
                    <mat-label> Usage days</mat-label>
                    <input autocomplete="new-txtUsagedays" maxlength="40" formControlName="txtUsagedays" matInput type="text" pattern="[a-zA-Z0-9]*" />
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>out of</mat-label>
                    <input autocomplete="new-txtUsagedaysOutOf" maxlength="40" formControlName="txtUsagedaysOutOf" matInput type="text"
                      pattern="[a-zA-Z0-9]*" />
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Greater than 4 hours</mat-label>
                    <input autocomplete="new-txtGreater4Hours" maxlength="40" formControlName="txtGreater4Hours" matInput type="text"
                      pattern="[a-zA-Z0-9]*" />
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Less than 4 hours</mat-label>
                    <input autocomplete="new-txtLess4hours" maxlength="40" formControlName="txtLess4hours" matInput type="text" pattern="[a-zA-Z0-9]*" />
                  </mat-form-field>

                  <mat-tree [dataSource]="childTree['compliancereport']" [treeControl]="treeControl">
                    <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex" matTreeNodePadding
                      val="ex" (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
                      (click)="getPdf(ex.blobName)">
                      <div class="custom-doc-name">
                        <i class="fa fa-file-pdf-o"></i>
                        {{ ex?.name }}
                      </div>

                      <small>{{ ex?.uploadedDate }}</small>

                    </mat-tree-node>

                  </mat-tree>
                </div>
                </mat-card-content>

              </mat-expansion-panel>

              <mat-expansion-panel *ngFor="let key of childDocType let index = index">
                <!-- <div *ngIf="key !== 'all' && key !== 'demographics' && key !== 'facetoface' && key !== 'sleepstudyreport' && key !== 'rx' && key !== 'compliancereport'"> -->
                  <!-- <div> -->

                <mat-expansion-panel-header class="custom-acccordion-header">
                  <mat-panel-title>
                    <div class="custom-qsecure-color font-weight-bolder">
                      {{ sfull_name[index] }}&nbsp;
                      <span class="circular-badge">{{ childTree[key] ? childTree[key].length : 0 }}</span>
                      <!-- {{ childTree['{{key}}']?.length }} -->
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content>
                  <div class="form-row">
                    <mat-tree [dataSource]="childTree[key]" [treeControl]="treeControl">
                      <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex" matTreeNodePadding
                        val="ex" (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
                        (click)="getPdf(ex.blobName)">
                        <div class="custom-doc-name">
                          <i class="fa fa-file-pdf-o"></i>
                          {{ ex?.name }}
                        </div>

                        <small>{{ ex?.uploadedDate }}</small>

                      </mat-tree-node>

                    </mat-tree>

                  </div>



                </mat-card-content>
                <!-- </div> -->
              </mat-expansion-panel>

              <button mat-button class="float-right buttonColors mr-2 mt-2" [disabled]="true" [matTooltip]="'This button is disabled because of an upcoming feature under progress'" (click)="onSubmitDisapproveMedicare()">
                Disapprove Medicare
              </button>
              <button mat-button class="float-right buttonColor mr-2 mt-2" (click)="onSubmit()">
                Check Rules
              </button>
            </form>
          </mat-accordion>
        </div>
      </div>
    </div>
    <!--Side Bar End-->

    <!--Document Viewer-->
    <div class="col-xl-7" [hidden]="viewDocViewer">
      <ng-container>
        <!-- [src]="strSelectedPdfPath"
          [useBrowserLocale]="true"
          [textLayer]="true"
          [showHandToolButton]="true"
          [height]="pdfHeight"
          (pagesLoaded)="onPagesLoaded($event)" -->
        <ngx-extended-pdf-viewer [src]="strSelectedPdfPath" (pagesLoaded)="onPagesLoaded($event)" [height]="pdfHeight"
          [showSidebarButton]="false"  [textLayer]="true" [showPagingButtons]="true"
          [showOpenFileButton]="false" [showHandToolButton]="false" [showScrollingButton]="false"
          [showSpreadButton]="false" [showPropertiesButton]="false" [showBookmarkButton]="false" [zoom]="'page-width'"
          [enableDragAndDrop]="false" [showFindButton]="false">
        </ngx-extended-pdf-viewer>
      </ng-container>
    </div>
    <!--End Document Viewer-->
    <br />
  </div>
  <!--End Card Body-->
</mat-card>
<!--Mat Card End-->
<br /><br /><br />
