<table
  datatable
  [dtOptions]="dtProductTableOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th matTooltip="Options">Options</th>
      <th matTooltip="Insurance Category Name">Insurance Category Name</th>
      <th matTooltip="Hcpc Code">Hcpc Code</th>
      <th matTooltip="Allowable($)"  class="text-right">Allowable($)</th>
      <th matTooltip="Created By">Created By</th>
      <th matTooltip="Created Date">Created Date</th>
      <th matTooltip="Modified By">Modified By</th>
      <th matTooltip="Modified Date">Modified Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of insuranceTableData">
      <td >
        <a
          class="editBtnColor mr-2"
          (click)="getInsAllowablesById(data.insuranceAllowablesId)"
        >
          <i
            class="fa fa-pencil pointer"
            (click)="triggerToggle()"
            matTooltip="Click To Edit"
          ></i>
        </a>
        &nbsp;
        <i style="color: red;"
        class="fa fa-trash-o"
        (click)="deleteAllowables(data.insuranceAllowablesId)"
        matTooltip="Click To Delete"
      ></i>
      </td>
      <td (dblclick)="onRowDoubleClick(data.insuranceCategory || '-')"[matTooltip]="data.insuranceCategory ? data.insuranceCategory : '-'">
        {{ data.insuranceCategory ? data.insuranceCategory : "-" }}
      </td>

      <td (dblclick)="onRowDoubleClick(data.hcpCcode || '-')"[matTooltip]="data.hcpCcode ? data.hcpCcode : '-'">
        {{ data.hcpCcode ? data.hcpCcode : "-" }}
      </td>
      <td (dblclick)="onRowDoubleClick(data.allowable)"
        class="text-right"
        matTooltip="{{ data.allowable }}"
      >
        {{ data.allowable }}
      </td>
      <td (dblclick)="onRowDoubleClick(data.createdByName || '-')"[matTooltip]="data.createdByName ? data.createdByName : '-'">
        {{ data.createdByName ? data.createdByName : "-" }}
      </td>
      <td (dblclick)="onRowDoubleClick(data.createdDate || '-')">
        <span [matTooltip]="data?.createdDate">{{
          data?.createdDate }}</span>
      </td>

      <td (dblclick)="onRowDoubleClick(data.modifyByName || '-')"[matTooltip]="data.modifyByName ? data.modifyByName : '-'">
        {{ data.modifyByName ? data.modifyByName : "-" }}
      </td>

    <td (dblclick)="onRowDoubleClick(data.modifyDate || '-')">
      <span [matTooltip]="data?.modifyDate">{{
        data?.modifyDate }}</span>
    </td>

    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th></th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance Category Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="HCPC Code"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Allowable ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified By "
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified Date"
        />
      </th>

    </tr>
  </tfoot>
</table>
