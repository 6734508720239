import type { CreateUpdateInsuranceAllowablesDTO, InsuranceAllowablesDTO, InsuranceAllowablesHCPCDTO, MultipleInsuranceAllowablesDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InsuranceAllowablesService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateInsuranceAllowablesDTO) =>
    this.restService.request<any, InsuranceAllowablesDTO>({
      method: 'POST',
      url: '/api/ItemApp/insurance-allowables',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/insurance-allowables/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: `/api/ItemApp/insurance-allowables/${id}`,
    },
    { apiName: this.apiName });

    createMultipleAllowables = (input: MultipleInsuranceAllowablesDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/ItemApp/insurance-allowables/multiple-allowables',
      body: input,
    },
    { apiName: this.apiName });

  getAllInsuranceAllowablesList = () =>
    this.restService.request<any, InsuranceAllowablesDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/insurance-allowables/insurance-allowables-list',
    },
    { apiName: this.apiName });

  getInsuranceAllowablesListByHCPCCodeID = (hcpcCodeId: string) =>
    this.restService.request<any, InsuranceAllowablesDTO[]>({
      method: 'GET',
      url: `/api/ItemApp/insurance-allowables/insurance-allowables-list-by-hCPCCode-iD/${hcpcCodeId}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InsuranceAllowablesDTO>>({
      method: 'GET',
      url: '/api/ItemApp/insurance-allowables',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getProductHCPCCodeListByID = (insuranceGroupId: string) =>
    this.restService.request<any, InsuranceAllowablesDTO[]>({
      method: 'GET',
      url: `/api/ItemApp/insurance-allowables/product-hCPCCode-list-by-iD/${insuranceGroupId}`,
    },
    { apiName: this.apiName });
    createMultipleAllowablesV1 = (input: MultipleInsuranceAllowablesDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/ItemApp/insurance-allowables/multiple-allowables-async-v1',
      body: input,
    },
    { apiName: this.apiName });
  update = (id: string, input: CreateUpdateInsuranceAllowablesDTO) =>
    this.restService.request<any, InsuranceAllowablesDTO>({
      method: 'PUT',
      url: `/api/ItemApp/insurance-allowables/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    getAllInsuranceAllowablesListWithHCPC = () =>
    this.restService.request<any, InsuranceAllowablesHCPCDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/insurance-allowables/insurance-allowables-list-with-hCPC',
    },
    { apiName: this.apiName });
    productAlowablesForMutipleHcpc = (InsuranceAllowable:any) =>
      this.restService.request<any, any>({
        method: 'PUT',
        url: '/api/ItemApp/insurance-allowables/product-alowables-for-mutiple-hcpc',
        body: InsuranceAllowable,
      },
      { apiName: this.apiName });
      getAllowableForInsCategoryAndHCPCCode = (InsuranceCategory :string[],hcpcCode :string[],AllowableAmount ?:number) =>
        this.restService.request<any, any>({
          method: 'POST',
          url: '/api/ItemApp/insurance-allowables/get-allowables-for-insurance-catogory-and-hcpc-code',
          body:{InsuranceCategory ,hcpcCode ,AllowableAmount },
        },
        { apiName: this.apiName });
        getListOfUpdatedAllowable = (pageNo:number,pageOffset:number) =>
          this.restService.request<any, any>({
            method: 'GET',
            url: '/api/ItemApp/insurance-allowables/get-list-ofupdated-allowable',
            params:{pageNo:pageNo,pageOffset:pageOffset},
          },
          { apiName: this.apiName });
          deleteAllowable = (id: string) =>
            this.restService.request<any, void>({
              method: 'DELETE',
              url: `/api/ItemApp/insurance-allowables/${id}/updated-allowable`,
            },
            { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
