import type { SaleorderDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShippingSearchService {
  apiName = 'orderManagement';

  searchShippingBySSaleOrderIdAndSInsuranceNameAndSPatientIdAndSPatientNameAndDtSuppliedDateAndSEmailAndSShippingLocationAndDtCreatedDateAndSStatusAndSPlan = (sSaleOrderId: string, sInsuranceName: string, sPatientId: string, sPatientName: string, dtSuppliedDate: string, sEmail: string, sShippingLocation: string, dtCreatedDate: string, sStatus: string, sPlan: string) =>
    this.restService.request<any, PagedResultDto<SaleorderDTO>>({
      method: 'POST',
      url: '/api/orderApp/shipping-search/search-shipping',
      params: { sSaleOrderId, sInsuranceName, sPatientId, sPatientName, dtSuppliedDate, sEmail, sShippingLocation, dtCreatedDate, sStatus, sPlan },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
