import { mapEnumToOptions } from '@abp/ng.core';

export enum AgingDaysFilter {
  All = 0,
  Today = 1,
  Yesterday = 2,
  Thirtydays = 3,
  Sixtydays = 4,
  Ninty = 5,
  CurrentWeek = 6,
}

export const agingDaysFilterOptions = mapEnumToOptions(AgingDaysFilter);
