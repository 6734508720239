import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';

import { CreateUpdateHellosignSignatureDTO, HelloSignHistoryDetail, HellosignSignatureDTO, helloSignDashboardDTO } from './dto';
import { Injectable } from '@angular/core';
import { IFormFile } from 'projects/patient/src/app/patient-proxy/microsoft/asp-net-core/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HellosignSignatureService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateHellosignSignatureDTO) =>
    this.restService.request<any, HellosignSignatureDTO>({
      method: 'POST',
      url: '/api/orderApp/hellosign-signature',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/hellosign-signature/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, HellosignSignatureDTO>({
      method: 'GET',
      url: `/api/orderApp/hellosign-signature/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<HellosignSignatureDTO>>({
      method: 'GET',
      url: '/api/orderApp/hellosign-signature',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });


    sendSingatureRequestByFileAndInput = (file: IFormFile[], input: CreateUpdateHellosignSignatureDTO) =>
    this.restService.request<any, HellosignSignatureDTO>({
      method: 'POST',
      url: '/api/orderApp/hellosign-signature/send-singature-request',
      body: input,
    },
    { apiName: this.apiName });

    getSignatureDownloadDocumentBySignatureIdAndTenantId = (signatureId: string, TenantId: string) =>
      this.restService.request<any, string>({
        method: 'GET',
        responseType: 'text',
        url: '/api/orderApp/hellosign-signature/signature-download-document',
        params: { signatureId, tenantId: TenantId },
      },
      { apiName: this.apiName });

  // sendSingatureRequestByFileAndInput = (file: IFormFile[], input: CreateUpdateHellosignSignatureDTO) =>
  //   this.restService.request<any, HellosignSignatureDTO>({
  //     method: 'POST',
  //     url: '/api/orderApp/hellosign-signature/send-singature-request',
  //     body: input,
  //   },
  //   { apiName: this.apiName });

  update = (id: string, input: CreateUpdateHellosignSignatureDTO) =>
    this.restService.request<any, HellosignSignatureDTO>({
      method: 'PUT',
      url: `/api/orderApp/hellosign-signature/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getHesignStatusCountDetails = () =>
    this.restService.request<any, helloSignDashboardDTO>({
      method: 'GET',
      url: '/api/orderApp/hellosign-signature/get-hesign-status-count-details',
    },
    { apiName: this.apiName });

  signatureHistoryByGOrderIdAndGSignature = (gOrderId: string, gSignature: string) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/orderApp/hellosign-signature/signature-history/${gOrderId}`,
      params: { gSignature },
    },
    { apiName: this.apiName });
    revertSignatureRequest = (signatureId : string) =>
      this.restService.request<any, any>({
        method: 'POST',
        url: `/api/orderApp/hellosign-signature/cance-or-remove-signature-request/${signatureId}`,
      },
      { apiName: this.apiName });
      HellosignDetailsByhellosignId = (HelloSignId  : string) =>
        this.restService.request<any, any>({
          method: 'Get',
          url: `/api/orderApp/hellosign-signature/by-hello-sign-id/${HelloSignId }`,
        },
        { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
