import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { dtInboundOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import {
  IdentityUserUsingTenantIdDto,
  UserService,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { RequisitionService } from '../item-proxy/item-management/optimization/requisition.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { RequisitionApprovalComponent } from '../requisition-approval/requisition-approval.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { RequisitionApprovalTabComponent } from '../requisition-approval-tab/requisition-approval-tab.component';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { RequisitionTrackingService } from '../item-proxy/item-management/optimization/requisition-tracking.service';
import {
  CreateUpdateTrackingDTO,
  Runtransfer,
} from '../item-proxy/item-management/optimization/dto';
import { Status } from '../item-proxy/item-management/items';
// import { element } from 'protractor';
import { PatientNextCallService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-next-call.service';
import { DataTableDirective } from 'angular-datatables';
import { ShippingTypesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/shipping-types';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Subject, Subscription } from 'rxjs';
import { RequisitionStatusService } from '../item-proxy/item-management/optimization/requisition-status.service';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { DatePipe } from '@angular/common';
// import moment from 'moment';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-requistion-viewer',
  templateUrl: './requistion-viewer.component.html',
  styleUrls: ['./requistion-viewer.component.scss'],
})
export class RequistionViewerComponent implements OnInit {
  // dtInboundOptions = dtInboundOptions;
  // dtRequistionOptions = dtInboundOptions;
  requistionTableData: any;
  dtTrigger: Subject<any> = new Subject<any>();
  loadTableValue: any;
  loadRequistionTable: any;
  orderDirectData = [];
  requisitionForm: FormGroup;
  getRequisitionData: any;
  lstUserList1: any;
  filteredUserList1: any;
  isrequisitionForm: boolean = false;
  isLoadData = true;
  pageInfo: any;
  isLoad = true;
  lstUserList: any;
  filteredOptions: any;
  shippingMethodId: string;
  statusId: string;
  requisitionDate: any;
  searchControl = new FormControl();
  requisitionFormValue: any;
  date = new Date();
  isChecked: boolean = false;
  ispackingList: boolean = false;
  reqItemCount: number = 0;
  reqItemList = [];
  itemCount: number = 0;
  isSplit: boolean = false;
  reqId: string;
  isPackingSlip: boolean = false;
  defaultVerifiedBy: string = '';
  defaultPackedBy: string = '';
  filteredUserList: any;
  dtInboundOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollX: true,
    scrollY: '300px',
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },

    fixedHeader: true, // Fix the header
    order: [],
    // info: false
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [0], width: '5%' }, //req no
      { targets: [1], width: '5%' }, // total
      { targets: [2], width: '20%' }, // req location
      { targets: [3], width: '10%' }, // CSR
      { targets: [4], width: '15%' }, // shipping method
      { targets: [5], width: '10%' }, // created date
      { targets: [6], width: '10%' }, // modified date
      { targets: [7], width: '10%' }, // modified by
      { targets: [8], width: '10%' }, // status
      // { orderable: true, targets: '_all' }
      { targets: [11, 12], visible: false },
    ],
  };
  dtRequistionOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '200px',
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },

    fixedHeader: true, // Fix the header
    order: [],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [0, 1, 2, 3, 4, -1], className: 'dt-fixed-column' }, // Ad
    ],
    // info: false
  };
  @ViewChild('myButton', { static: true }) myButton: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  datatableElement: DataTableDirective;

  myTable: DataTables.Api;

  hcpcTableData: any;
  notesText: any;
  tblItemSelectedRowId: string;
  CallLogDetails: any;
  notesData: any;
  subscription$: Subscription[] = [];
  ltShippingMethods: any;
  lststatus: any;
  isOpenfields: boolean;
  errorMessage: string;

  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  visibleColumns: number[] ;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private userService: UserService,
    private requisitionService: RequisitionService,
    private tableService: TableService,
    private toastr: ToastrService,
    private requisitionTrackingService: RequisitionTrackingService,
    private PatientNextCallService: PatientNextCallService,
    private shippingMethodService: ShippingTypesService,
    private requisitionStatusService: RequisitionStatusService,
    private datePipe: DatePipe,
    private communicationservice: CommunicationService,
    private zone: NgZone,
    private title : Title,
    private commonService : CommonService
  ) {
    // LoadTableData
    const tableLoad =
      this.communicationservice.functionLoadRequisitionList$.subscribe(() => {
        this.LoadTableData();
      });
    this.subscription$.push(tableLoad);
    this.searchControl.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        this.filterShippingMethod();
      });
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure| Requisition Viewer');
    this.requisitionForm = this.formBuilder.group({
      txtTracking: new FormControl(''),
      txtPackedBy: new FormControl(''),
      drpVerifyedBy: new FormControl(''),
      txtUserList1: new FormControl(''),
      txtUserList: new FormControl(''),
      drpUserList: new FormControl(''),
      // drpVerifiedBy: new FormControl(''),
      txtSpecialInstructions: new FormControl(''),
    });
    this.loadDropDown();
    this.LoadTableData();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  loadDropDown() {
    const shippingMethodDetails = this.shippingMethodService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltShippingMethods = response?.items;
        },
        (err) => {}
      );
    this.subscription$.push(shippingMethodDetails);

    const requisitionStatus = this.requisitionStatusService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.lststatus = response?.items;
        },
        (err) => {}
      );
    this.subscription$.push(requisitionStatus);

    const filterUserByTenant: IdentityUserUsingTenantIdDto = {
      tenantId: localStorage?.getItem('tenantId') ?? '',
    };
    const userList = this.userService
      .getUserListV1(filterUserByTenant)
      .subscribe((response) => {
        this.lstUserList = response;
        // this.defaultPackedBy = response[0].id ?? '';
        this.defaultPackedBy = localStorage.getItem('userId') ?? defaultGuid;
        // this.lstUserList = response.sort((a, b) =>  a.name.localeCompare(b.name));

        this.filteredUserList = this.requisitionForm
          ?.get('txtUserList')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUserList.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });

    const userList1 = this.userService
      .getUserListV1(filterUserByTenant)
      .subscribe((response) => {
        // this.defaultVerifiedBy = response[0].id ?? '';
        this.defaultVerifiedBy = localStorage.getItem('userId') ?? defaultGuid;
        this.lstUserList1 = response;
        // this.lstUserList1 = response.sort((a, b) =>  a.name.localeCompare(b.name));
        this.filteredUserList1 = this.requisitionForm
          ?.get('txtUserList1')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUserList1.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  isDateValidFilter = (date: Date | null): boolean => {
    // Return true if the date is valid; otherwise, return false
    return date instanceof Date && !isNaN(date.getTime());
  };
  //Method To Search the Products in Dropdown
  filterShippingMethod(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltShippingMethods.filter((option) =>
      option.description.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  updateRequisition(data) {
    this.shippingMethodId = data.value;
    // const reqDate = this.datePipe.transform(this.getRequisitionData.requisitionDate, 'MM/dd/yyyy');
    this.requisitionService
      .updateRequisitionDataByGReqIdAndGShippingMethodAndDReqDateAndGReqStatus(
        this.reqId,
        this.getRequisitionData.shippingMethodId,
        this.getRequisitionData.requisitionDate,
        this.getRequisitionData.requisitioStatusId
      )
      .subscribe(
        (response) => {
          this.toastr.success('Updated Successfully!', 'Success');
        },
        (err) => {
          const data: HttpErrorResponse = err;
          if (data && data.error && data.error.message) {
            this.toastr.error(data.error.message);
          } else {
            // Handle the case where the error structure is unexpected or missing information
            // Swal.fire({
            //   icon: 'info',
            //   text: 'Invalid Date.',
            // });
            this.toastr.error('Invalid Date');
          }
        }
      );
  }

  validation(event: any) {
    // Implement your custom date format validation logic
    const isValidDateFormat = 'mm/dd/yyyy';

    if (!isValidDateFormat) {
      this.errorMessage = 'Please enter a valid date format.';
    } else {
      this.errorMessage = '';
    }
  }

  runTransfer() {
    let data: Runtransfer = {
      reqId: this.reqId,
      trackingNo: this.requisitionForm.value.txtTracking,
      packedBy: this.requisitionForm.value.drpUserList,
      lockedBy: this.requisitionForm.value.drpVerifyedBy,
      itemIds: this.reqItemList,
    };
    this.requisitionService.runTransferByInput(data).subscribe(
      (response) => {
        this.itemCount = 0;
        this.reqItemCount = 0;
        this.reqItemList = [];
        this.isSplit = false;
        this.requisitionForm.patchValue({
          txtTracking: '',
        });
        this.loadRequistionItemTable(this.reqId);
        this.LoadTableData();
        this.toastr.success('Transfer Successfully!', 'Success');
      },
      (err) => {
        // this.isShowSpinner = false;
        this.isSplit = false;
        const data: HttpErrorResponse = err;
        const message = JSON.parse(data?.error);
        Swal.fire({
          icon: 'info',
          text: message?.error?.message,
        });
      }
    );
  }

  LoadTableData() {
    var req = {status:null,reqId:null};
    this.tblItemSelectedRow(req);
    this.isLoadData = false;
    const reuistion = this.requisitionService
      .getAllRequisitionsDataByIsCompleted(
        false,
        this.currentPage,
        this.pageOffset
      )
      .subscribe(
        (response) => {

          let pagedResponse = response as any;
          if (pagedResponse == null)
          {
            this.isLoadData = true;
            return null;
          }
          this.loadTableValue = pagedResponse.items;
          this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);

          this.isLoadData = true;
          this.loadTableValue = this.loadTableValue.map(
            (reqs) =>
            {
              return {
                ...reqs,
                requisitionDate: this.datePipe.transform(reqs.requisitionDate,'MM/dd/yyyy')
              }
            }
          );

          // this.requisitionItemsList = [];
          // console.log('this.loadTableValue.length > 0 '+ this.loadTableValue.length);
          // this.loadTableValue &&
          //   this.loadTableValue.length > 0 &&
          //   this.tblItemSelectedRow(
          //     this.loadTableValue?.[0]?.reqId
          //   );
            // if (this.datatableElement && this.datatableElement.dtInstance) {
            //   this.refreshDataTable();
            // } else {
            //   setTimeout(() => this.refreshDataTable(), 500);
            // }
          //this.loadTableValue = response;
          // this.loadTableValue = this.loadTableValue.map((data) => {
          //   return {
          //     ...data,
          //     lastModifiedDate: this.commonService.getFormattedDateTimeZone(
          //       data.lastModifiedDate
          //     ),
          //     createdDate: this.commonService.getFormattedDateTimeZone(
          //       data.createdDate
          //     ),
          //   };
          // });

          // if (this.reqId) {
          //   this.loadRequistionItemTable(this.reqId);
          // }

          // const reqId = this.getRequisitionData?.reqId;
          // let indexData = this.loadTableValue.findIndex(
          //   (item) => item.reqId == reqId
          // );
          // if (this.datatableElement && this.datatableElement.dtInstance) {
          //   this.refreshDataTable();
          // } else {
            setTimeout(() => this.refreshDataTable(), 500);
          // }
        },
        (err) => {
          // this.isShowSpinner = false;
          this.isLoadData = true;
          const data: HttpErrorResponse = err;
        }
      );
  }
  getReqId(event, reqId: string) {
    console.log('getReqID..............');
    if (event.checked == true) {
      this.itemCount++;
      this.isSplit = true;
      if (this.itemCount < this.reqItemCount) {
        this.reqItemList.push(reqId);
      } else {
        this.reqItemList.push(reqId);
        // Swal.fire({
        //   title: 'Keep One Product When Moving',
        //   // text: 'By Changing Status, this Manufacturer will be deactivated',
        //   icon: 'warning',
        //   confirmButtonColor: '#34c38f',
        //   confirmButtonText: 'Ok',
        // }).then((result) => {
        //   this.itemCount--;
        //   this.loadRequistionTable.forEach((item) => {
        //     if (reqId.includes(item.requestItemId)) {
        //       item.isChecked = false;
        //     } else {

        //     }
        //   });
        // });
      }
    } else if (event.checked != true) {
      // this.itemCount--;
      var index = this.reqItemList.findIndex((item) => item === reqId);
      this.reqItemList.splice(index, 1);
      this.itemCount--;
      this.isSplit = this.itemCount == 0 ? false : true;
    }
  }
  // goToPage(pageNumber: number) {
  //   // this.myButton?.nativeElement?.click();

  //   if (this.dtElement && this.dtElement.dtInstance) {
  //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //       if (dtInstance) {
  //         dtInstance.page.len(this.dtInboundOptions.pageLength).draw(false);

  //         setTimeout(() => {
  //           dtInstance.page(pageNumber - 1).draw(false);
  //         }, 100);
  //       }
  //     });
  //   }
  // }
  buttonClick() {}

  splitRequisition() {
    if (this.isSplit == true) {
      if (this.itemCount == this.reqItemCount) {
        Swal.fire({
          title: 'Keep One Product When Moving',
          // text: 'By Changing Status, this Manufacturer will be deactivated',
          icon: 'warning',
          confirmButtonColor: '#34c38f',
          confirmButtonText: 'Ok',
        }).then((result) => {});
      } else {
        Swal.fire({
          title: 'Split The Selected Lines From This Requisition?',
          // text: 'By Changing Status, this Manufacturer will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.value) {
            this.requisitionService
              .splitReuisitionItemByGReqIdAndGItemIds(
                this.reqId,
                this.reqItemList
              )
              .subscribe(
                (response) => {
                  this.toastr.success('Splited Successfully!', 'Success');
                  this.itemCount = 0;
                  this.reqItemCount = 0;
                  this.reqItemList = [];
                  this.isSplit = false;
                  this.loadRequistionItemTable(this.reqId);
                  this.LoadTableData();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          } else {
            // clear the check Box value in  item table
            // this.itemCount = 0;
            // this.reqItemCount = 0;
            // this.isSplit = false;
            // this.loadRequistionItemTable(this.reqId);
          }
        });
      }
    } else {
      Swal.fire({
        title: 'Please Select The Product',
        icon: 'info',
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'Ok',
      });
    }
  }

  loadRequistionItemTable(reqId: string) {
    this.itemCount = 0;
    this.isrequisitionForm = true;
    this.reqId = reqId;
    this.isLoad = false;
    this.requisitionForm.patchValue({
      txtTracking: '',
    });
    const reuistionItem = this.requisitionService
      .getRequisitionItemDetailsByReqId(reqId)
      .subscribe(
        (response) => {
          this.orderDirectData = [];
          this.reqItemCount = response.length;
          this.isLoad = true;
          this.requisitionForm.patchValue({
            txtTracking: '',

          });
// firstData.verifiedByName = firstData.verifiedByName.replace(/\s+/g, ' ').trim();
          this.loadRequistionTable = response;
          if( this.reqItemCount !== 0 ) {
          let firstData=response[0];
        //   let savedVerifiedBy =this.lstUserList.filter(x=>((x.name.replace(/\s+/g, ' ').trim()).toLowerCase()==(
        //   (firstData.verifiedByName !== null && firstData.verifiedByName !==undefined )?
        //    (firstData.verifiedByName.replace(/\s+/g, ' ').trim()).toLowerCase():''))
        //  )[0]?.id;
        let verifiedByName = firstData.verifiedByName !== null && firstData.verifiedByName !== undefined
          ? firstData.verifiedByName.replace(/\s+/g, ' ').trim().toLowerCase()
          : '';

        let savedVerifiedBy = this.lstUserList
          .filter(user => user.userName.replace(/\s+/g, ' ').trim().toLowerCase() === verifiedByName)[0]?.id;
         this.defaultVerifiedBy = savedVerifiedBy|| localStorage.getItem('userId');

         let savedPackedBy =this.lstUserList.filter(x=>((x.userName.replace(/\s+/g, ' ').trim()).toLowerCase()==(
          (firstData.packedByName !== null && firstData.packedByName !==undefined )?
          (firstData.packedByName.replace(/\s+/g, ' ').trim()).toLowerCase():''))
         )[0]?.id;
          this.defaultPackedBy = savedPackedBy || localStorage.getItem('userId');
        }else{
          this.defaultVerifiedBy = localStorage.getItem('userId') ?? defaultGuid;
          this.defaultPackedBy = localStorage.getItem('userId') ?? defaultGuid;
        }
        },
        (err) => {
          this.defaultVerifiedBy = localStorage.getItem('userId') ?? defaultGuid;
          this.defaultPackedBy = localStorage.getItem('userId') ?? defaultGuid;
          // this.isShowSpinner = false;
          this.isLoad = true;
          this.loadRequistionTable=[];
          const data: HttpErrorResponse = err;
        }
      );
  }

  orderDirect() {
    this.isLoadData = false;
    this.loadRequistionTable.forEach((element) => {
      this.orderDirectData.push({
        requisitionTrackingId: '00000000-0000-0000-0000-000000000000',
        requisitionItemId: element?.requestItemId ?? '',
        qtyShipped: element?.receivedQuantity ?? 0,
        status: Status.Active,
      });
    });

    let orderDetails: CreateUpdateTrackingDTO = {
      requisitionTrackingId: 0,
      trackingNo: this.requisitionForm.value.txtTracking,
      date: String(this.date.toISOString()),
      requisitionId: this.reqId,
      packedBy: this.requisitionForm.value.drpUserList,
      verifiedBy: this.requisitionForm.value.drpVerifyedBy,
      status: Status.Active,
      trackingItemList: this.orderDirectData,
      specialInstructions:
        this.requisitionForm.value.txtSpecialInstructions ?? '',
    };

    this.requisitionTrackingService
      .createReqTrackingStatusByInput(orderDetails)
      .subscribe(
        (response) => {
          this.isLoadData = true;
          this.requisitionForm.patchValue({
            txtTracking: '',
          });
          this.toastr.success('Order Moved Successfully', 'Success');
          this.LoadTableData();
        },
        (err) => {
          let data = JSON.parse(err.error);

          this.isLoadData = true;
          this.toastr.error(data.error.message, 'Error');
        }
      );
  }

  //! Open Requisition Form ModalPopup Function
  ViewRequisition(ReqData) {
    this.tableService.setRequisitionForm([ReqData.reqId]);
    const dialogRef = this.dialog.open(RequisitionApprovalTabComponent, {
      data: {
        reqData: ReqData,
      },

      disableClose: true,
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      // minHeight: '100%',
      // minWidth: '100%',
    });
    dialogRef.afterClosed().subscribe(() => {
      // setTimeout(() => {
      //this.LoadTableData(); // or any other static value
      // }, 1000);
      // this.goToPage(3);
      this.tableService.setRequisitionForm(['']);
    });

  }

  tblItemSelectedRow(req) {
    console.log('tblItemSelectedRow...........');
    req.status == 'Ordered Direct'
      ? (this.isPackingSlip = true)
      : (this.isPackingSlip = false);
    this.getRequisitionData = req;
    const id = req.reqId;
    this.isOpenfields = true;
    id != this.tblItemSelectedRowId && this.loadRequistionItemTable(req.reqId);
    this.tblItemSelectedRowId = id;
    this.requisitionForm.patchValue({
      txtSpecialInstructions: req.specialInstruction,
    });
  }

  ngAfterViewInit(): void {
    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.columns().every(function () {
    //     const that = this;
    //     $('input', this.footer()).on('keyup change', function () {
    //       if (that.search() !== this['value']) {
    //         that.search(this['value']).draw();
    //       }
    //     });
    //   });
    // });
  }
  toFixed(data) {
    if (data != null || data != undefined) {
      let value = data.toFixed(2);
      return value;
    } else {
      return data;
    }
  }

  viewPackingListPDF() {
    this.requisitionService.packingListPDF(this.reqId).subscribe(
      (response) => {
        const jsonData = response;
        const docDefinition = this.createPdfContent(jsonData);
        this.displayPdf(docDefinition, 'Packing Slip');
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).download(filename);
    } catch (error) {
      console.error('Error creating PDF:', error);
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }

  createPdfContent(data: any): any {
    const locationName = data[0].location;
    const poNumber = data[0].referenceNumber;
    const userName = data[0].packedPerson;

    const productDetails = this.createProductDetails(data);

    return {
      content: [
        {
          text: 'Packing Slip',
          style: 'header',
          alignment: 'center', // Center the header
          margin: [0, 10, 0, 5], // Adjust the margins to move the header
        },

        {
          columns: [
            [{ text: ` ${locationName}`, alignment: 'center', color: 'blue' }],
          ],
          margin: [0, 5, 0, 5],
        },

        {
          columns: [
            [{ text: `PO#: ${poNumber}`, alignment: 'left', color: 'blue' }],
            // [{ text: ' ', width: '50%' }],
            [{ text: ` ${userName}`, alignment: 'right', color: 'blue' }],
          ],
          margin: [0, 5, 0, 5],
        },
        {
          // canvas: [
          //   {
          //     type: 'line',
          //     x1: 0,
          //     y1: 5,
          //     x2: 555.276,
          //     y2: 5,
          //     lineWidth: 2,
          //     lineColor: 'black',
          //   },
          // ],
        },
        productDetails,
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        'top-header': {
          fontSize: 13,
          bold: true,
          margin: [0, 10, 0, 5],
          color: 'green',
        },
      },
    };
  }

  createCustomerInfo(data: any): any {
    return [
      { text: `Full Name: ${data.fullName}` },
      { text: `Date of Birth: ${data.dateOfBirth}` },
      // Add other customer information fields as needed
    ];
  }

  createProductDetails(products: any[]): any {
    return {
      stack: [
        // { text: '', margin: [0, 0, 0, 0] }, // Add this line to add some space before the table
        {
          style: 'tableContainer',
          table: {
            widths: ['25%', '25%', '25%', '25%'],
            body: [
              [
                { text: 'Product Code', bold: true },
                { text: 'Product Description', bold: true },
                { text: 'Quantity', bold: true },
                { text: 'Bin Location', bold: true },
              ],
              ...products.map((product) => [
                product.productCode ? product.productCode : '-',
                product.productDescription ? product.productDescription : '-',
                product.qty ? product.qty : '-',
                product.binLocation ? product.binLocation : '-',
              ]),
            ],
          },
        },
        // { text: '\n', margin: [0, 0, 0, 0] }, // Add this line to add some space after the table
      ],
      styles: {
        tableContainer: {
          margin: [0, 0, 0, 0],
          alignment: 'center', // Add this line to center the table
        },
      },
    };
  }
//US Format Date and Time
getFormattedDateTimeZone(date: string): string {
  if (date) {
	const formattedDate = new Date(date);
	const options: Intl.DateTimeFormatOptions = {
	  year: 'numeric', month: '2-digit', day: '2-digit',
	  hour: 'numeric', minute: '2-digit',
	  hour12: true,
	  timeZone: 'America/Chicago'
	};
	return formattedDate.toLocaleString('en-US', options);
  } else {
	return '-';
  }
}

addPageNavigationControls(){
  let buttonsElement =  $(".dt-buttons")[0];
  console.log('$addPageNavigationControls : current page = {currentPage}' + this.currentPage)
  if(this.currentPage == 1)
  {
    $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
    $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
  }
  else
  {
    $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
    $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
   }

  $("<span> Page <span>").appendTo(buttonsElement);
  $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
  $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
  $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

  if(this.currentPage >= this.totalPages)
  {
    $("<button  disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
    $("<button  disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
  }
  else
  {
     $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
     $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
  }

  $('#btnNextPage').on('click', (event) => this.MovePage(true));
  $('#btnPrevPage').on('click', (event) => this.MovePage(false));
  $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
  $('#btnFirstPage').on('click', (event) => this.changePage(1));
  $('#btnLastPage').on('click', (event) => this.changePage(this.totalPages));
 }

 fnGotoPage(){
  let pageValue = $("#txtGotoPage").val();
  let pageno = parseInt(pageValue.toString());
  if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
  {
    this.changePage(pageno);
  }
}

MovePage(isNext:boolean)
{
  console.log('MovePage called....' + this.currentPage);
  let pageNo = this.currentPage;
  if(isNext)
    pageNo++;
  else
  pageNo--;
  //this.nevigatePage.emit(this.currentPage);
  this.changePage(pageNo);
}

changePage(pageNo : number){
  console.log('changePage PageNo : ' + pageNo + 'this.currentPage :' + this.currentPage + 'this.totalPages :' + this.totalPages);
  if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
  {
    this.currentPage = pageNo;
    this.LoadTableData();
  }
}

refreshDataTable(): void {
  console.log('refreshDataTable.....');
  //this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

    // Clear search input values
    //$('input', dtInstance.table(0).footer()).val('');
    // Redraw the DataTable to load all records
    //dtInstance.search('').columns().search('').draw();
    this.addPageNavigationControls();
    // console.log($(".dt-buttons")[0])

    // $("<span>Page " + this.currentPage + " of " + this.totalPages + " <span>").appendTo($(".dt-buttons")[0]);
    // //console.log(document.getElementsByClassName("dt-buttons"));
    // Attach the event listener for filtering
    var visColumns =  this.visibleColumns;
    var index = 0;
    // dtInstance.columns().every(function () {
    //   const that = this;
    //   if(visColumns && visColumns.length > 0)
    //   {
    //         if(visColumns.indexOf(index) < 0)
    //           this.visible(false);
    //   }
    //   index++;

    //   $('input', this.footer()).on('keyup change', function () {
    //     if (that.search() !== this['value']) {
    //       that.search(this['value']).draw();
    //     }
    //   });
    // });
  // });

}


}
