<br />

<div class="row">
    <div class=col-3>
        <div class="card card-body  ">
            <div class="col-lg-3">
                <a class="eyeCursorclass"><i class="fas fa-pencil-alt customThemeClass fa-lg mr-3"></i></a>
                <a class="eyeCursorclass customThemeClass rounded-circle"><i class="fas fa-user-tie fa-5x "></i> </a>
            </div>
            <div class="text-center">
                <h3>Admin</h3>
                <strong class="customThemeClass">Administrator</strong>
            </div>
            <div class="text-center">
                <span> <i class="fa fa-thumbs-up" ></i> Active</span>
            </div>
            <hr>

            <div>
                <span class="float-left customThemeClass">
                    <i class="fa fa-phone "></i>
                    Phone Number
                </span>
            </div>
            <hr>
            <div>
                <span class="float-left customThemeClass">
                    <i class="fa fa-envelope "></i>
                    Email Id
                </span>

            </div>
            <hr>
            <div>
                <span class="float-left customThemeClass">
                    <i class="fa fa-home "></i>
                    Address
                </span>
            </div>
            <hr>
            <div>
                <span class="float-left customThemeClass">
                    <i class="fa fa-user "></i>
                    Type
                </span> <br>
            </div>
            <hr>
            <br>
        </div>
    </div>
    <div class="col-9">
        <div class="card card-body ">
            <div class="row">
                <div class="col-2">
                    <h4 class="customThemeClass"><b>Day Off / Vacation</b></h4>
                </div>
                <div class="col mb-2">
                    <mat-form-field class>
                        <mat-label>Apply</mat-label>
                        <mat-select>
                            <mat-option *ngFor="let leaves of leave" [value]="leaves.value">
                                {{leaves.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <h4 class="customThemeClass"><b>Leave Details</b></h4>
            </div>

            <div style="margin-top: -10px;">
                <form [formGroup]="ontimeProfile">
                    <!-- <div [hidden]="!isShowProgress"
                        style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                        <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                        </mat-progress-bar>
                    </div> -->
                    <div class="table table-responsive">

                        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                            <ng-container matColumnDef="S.No">
                                <th class="header" mat-header-cell *matHeaderCellDef>
                                    <mat-form-field class="filter">
                                        <input autocomplete="new-Sno" matInput formControlName="Sno" type="text" />
                                        <mat-placeholder class="center">S.No</mat-placeholder>
                                    </mat-form-field>
                                </th>
                                <td mat-cell *matCellDef="let element; let i=index">
                                    {{ i+1 }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Type">
                                <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <mat-form-field class="filter">
                                        <input autocomplete="new-type" matInput formControlName="type" />
                                        <mat-placeholder>Type</mat-placeholder>
                                    </mat-form-field>
                                </th>
                            </ng-container>

                            <ng-container matColumnDef="From Date">
                                <th class="header" mat-header-cell *matHeaderCellDef>
                                    <mat-form-field class="filter">
                                        <input autocomplete="new-fromDate" matInput formControlName="fromDate" />
                                        <mat-placeholder>From Date</mat-placeholder>
                                    </mat-form-field>
                                </th>
                                <td mat-cell *matCellDef="let element"></td>
                            </ng-container>

                            <ng-container matColumnDef="To Date">
                                <th class="header" fxFlex="200" mat-header-cell *matHeaderCellDef>
                                    <mat-form-field class="filter">
                                        <input autocomplete="new-toDate" matInput formControlName="toDate" />
                                        <mat-placeholder>To Date</mat-placeholder>
                                    </mat-form-field>
                                </th>
                                <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Comments">
                                <th class="header  w-175" mat-header-cell *matHeaderCellDef>
                                    <mat-form-field class="filter">
                                        <input autocomplete="new-comments" matInput formControlName="comments" />
                                        <mat-placeholder>Comments</mat-placeholder>
                                    </mat-form-field>
                                </th>
                                <td mat-cell *matCellDef="let element"></td>
                            </ng-container>

                            <ng-container matColumnDef="Status">

                                <th class="header" mat-header-cell *matHeaderCellDef>
                                    <mat-form-field class="filter">
                                        <input autocomplete="new-status" matInput formControlName="status" />
                                        <mat-placeholder>Status</mat-placeholder>
                                    </mat-form-field>
                                </th>
                                <td mat-cell *matCellDef="let element"></td>
                            </ng-container>

                            <ng-container matColumnDef="options">
                                <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
                                <td mat-cell *matCellDef="let element" class="text-center">
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="noRecords">
                                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="7">
                                    <div *ngIf="!isShowProgress">
                                        {{"No Records Found"}}
                                    </div>
                                    <div [hidden]="!isShowProgress">
                                        <mat-spinner [diameter]="220"></mat-spinner>
                               </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="paginator">
                                <td mat-footer-cell *matFooterCellDef colspan="7">
                                    <mat-paginator class="table table-responsive" #MatPaginator
                                        [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0"
                                        showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                                    </mat-paginator>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="['noRecords']"
                                [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                        </table>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>