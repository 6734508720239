import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { ToastrService } from 'ngx-toastr';
import { CreateUpdateMoreInfoDTO } from '../patient-proxy/patient/practice-management/dto/models';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-practice-management-more-info',
  templateUrl: './practice-management-more-info.component.html',
  styleUrls: ['./practice-management-more-info.component.scss']
})
export class PracticeManagementMoreInfoComponent implements OnInit {
  @Input() sessionPracticeId: any;
  practiceNotesId: string;
  isShowSpinner: boolean = false;
  maoreInfoForm: FormGroup;
  ischeckPracticeId: boolean;
  constructor(
    private fb: FormBuilder,
    private practiceManagementService: PracticeManagementService,
    private toastr: ToastrService,
    private datePipe: DatePipe

  ) { }

  ngOnInit(): void {

    this.maoreInfoForm = this.fb.group({
      txtStartdate: new FormControl('',[Validators.required,this.isValidDateValidator.bind(this)]),
      txtEnddate: new FormControl('',[Validators.required,this.isValidDateValidator.bind(this)]),
      chkStat: new FormControl(''),
    });
    this.practiceNotesId = this.sessionPracticeId;
    if (this.practiceNotesId) {
      this.getMoreInfoById();
      this.maoreInfoForm.enable();
    } else {
      this.ischeckPracticeId = true;
      this.maoreInfoForm.disable();
    }
  }

  //Date Picker Validation start
  isValidDateValidator(control: FormControl) {
    const selectedDate = new Date(control.value);

    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // Month is zero-based, so add 1
    const day = selectedDate.getDate();

    const isValidDate = this.isValidDate(year, month, day);
    return isValidDate ? null : { invalidDate: true };
  }

  isValidDate(year: number, month: number, day: number): boolean {
    // Check if year, month, and day are valid
    // For simplicity, you can use a basic check here
    // You can add more complex validation logic if needed
    if (
      year >= 1000 &&
      year <= 9999 &&
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= 31
    ) {
      return true;
    } else {
      return false;
    }
  }
  //Date Picker Validation End..

  saveMoreInfo() {
    this.isShowSpinner = true;
    const moreInfoInput: CreateUpdateMoreInfoDTO = {
      subContractAgreement: this.maoreInfoForm.value.chkStat ?? '',
      subContractStartDate: this.maoreInfoForm.value.txtStartdate ?? '',
      subContractEndDate: this.maoreInfoForm.value.txtEnddate ?? '',
    };
    //Save
    this.practiceManagementService.updateMoreInfoByPracticeIdAndInput(this.practiceNotesId, moreInfoInput).subscribe(
      (response) => {
        this.toastr.success('Update Successfully!', 'Success');
        this.isShowSpinner = false;
        // this.maoreInfoForm.reset();
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  getMoreInfoById() {
    this.practiceManagementService.getMoreInfoByPracticeIdByPracticeId(this.practiceNotesId).subscribe(response => {
      const startDate = this.datePipe.transform(response[0]?.subContractStartDate,'MM/dd/yyyy')
      const endDate = this.datePipe.transform(response[0]?.subContractEndDate,'MM/dd/yyyy')
      // console.log(endDate)
      this.maoreInfoForm.patchValue({
        txtStartdate: response[0].subContractStartDate,
        txtEnddate: response[0].subContractEndDate && endDate == '01/01/0001'? '': response[0].subContractEndDate,
        chkStat: response[0].subContractAgreement && startDate == '01/01/0001'? '': response[0].subContractAgreement,

      });
      this.maoreInfoForm.markAsDirty();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  resetMoreInfo(): void {
    this.maoreInfoForm.reset();
  }
}
