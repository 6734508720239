import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class RegionDropdownService {
  apiName = 'DropdownManagement';

  getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName = (countryShortName: string, stateShortName: string) =>
    this.restService.request<any, PagedResultDto<RegionCitiesDTO>>({
      method: 'POST',
      url: '/api/DropdownApp/region-dropdown/get-all-cities-by-country-and-state-name',
      params: { countryShortName, stateShortName },
    },
    { apiName: this.apiName });

  getAllCountriesByInput = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<RegionCountriesDTO>>({
      method: 'GET',
      url: '/api/DropdownApp/region-dropdown/countries',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getAllStatesByCountryNameByCountryShortName = (countryShortName: string) =>
    this.restService.request<any, PagedResultDto<RegionStatesDTO>>({
      method: 'POST',
      url: '/api/DropdownApp/region-dropdown/get-all-states-by-country-name',
      params: { countryShortName },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
