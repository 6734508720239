import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient-document-tiff-single-preview',
  templateUrl: './patient-document-tiff-single-preview.component.html',
  styleUrls: ['./patient-document-tiff-single-preview.component.scss'],
})
export class PatientDocumentTiffSinglePreviewComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 0.3;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL+4;
  readonly MIN_ZOOM_LEVEL = 0.2;
  isLoading: boolean = false;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  selectedExistingDocument: any = null;
  pageNumber: number = 1;
  existingDocumentImageList: any = [];
  totalPages: number = 0;
  currentArrayIndex: number = 0;
  constructor(
    private toastr: ToastrService,
    public dialog: MatDialogRef<PatientDocumentTiffSinglePreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      base64?: any[];
      patientInfo?: any;
    }
  ) {}

  ngOnInit(): void {
    this.existingDocumentImageList = this.data.base64;
    this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
    this.totalPages = this.existingDocumentImageList.length;
    this.selectedExistingDocument = this.existingDocumentImageList?.[0];
  }

  closePreview() {
    this.dialog.close();
  }

  loadTiffImageV1(docDetails: any) {
    if (docDetails && docDetails.file) {
      this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        // Calculate the position to draw the image so that it remains centered
        // const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
        // const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        // Clear canvas before drawing
        this.ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Adjust canvas size based on zoom level
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // Scroll to the selected image
        const selectedImageElement = document.querySelector(
          '.custom-thumbnail-view-selected'
        );
        if (selectedImageElement) {
          selectedImageElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
          });
        }
      };
      img.src = 'data:image/tiff;base64,' + docDetails.file;

      // this.tiffPageNo = docDetails.pageNo || 0;
      this.selectedExistingDocument = docDetails;
      // this.TiffError = false;
      // this.loadingMessage = '';
    }
    // else {
    //   this.TiffError = true;
    //   this.loadingMessage = 'Images has not found/Empty';
    // }
  }

  redrawAnnotationsZoom() {
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        // Clear canvas before drawing
        this.ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Adjust canvas size based on zoom level
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        // Draw image with adjusted dimensions and centered position
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

        // this.ctx.drawImage(img, 0, 0);
        // this.textAnnotations.forEach((annotation) => {
        //   // // Adjust font size for zoom
        //   const fontSize = 14 * this.zoomLevel; // Change 14 to your base font size
        //   this.ctx.font = `${fontSize}px Arial`;

        //   // this.ctx.font = '14px Arial';

        //   // Adjust position for zoom
        //   const adjustedX = annotation.x * this.zoomLevel;
        //   const adjustedY = annotation.y * this.zoomLevel;

        //   this.ctx.fillStyle = this.textColor;
        //   this.ctx.fillText(
        //     annotation.text,
        //     adjustedX - annotation.rectWidth * this.zoomLevel,
        //     adjustedY - (annotation.rectHeight + 10) * this.zoomLevel
        //   );
        //   this.ctx.strokeStyle = this.lineColor;
        //   this.ctx.lineWidth = 3;
        //   this.ctx.strokeRect(
        //     adjustedX - annotation.rectWidth * this.zoomLevel,
        //     adjustedY - annotation.rectHeight * this.zoomLevel,
        //     annotation.rectWidth * this.zoomLevel,
        //     annotation.rectHeight * this.zoomLevel
        //   );

        //   this.savedState = this.ctx.getImageData(
        //     0,
        //     0,
        //     canvas.width,
        //     canvas.height
        //   );
        // });
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }

  loadExistingImage(docDetails: any) {
    this.loadTiffImageV1(docDetails);
    // this.page Number = docDetails.pageNo;
  }

  // Function to zoom in
  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.zoomLevel += 0.1;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.zoomLevel -= 0.1;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  previousImage() {
    // if (this.mustSaveAnnotations()) {
    this.currentArrayIndex--;
    if (0 < this.currentArrayIndex) {
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    } else {
      this.firstImage();
    }
    // }
  }

  nextImage() {
    // if (this.mustSaveAnnotations()) {
    this.currentArrayIndex++;
    if (this.currentArrayIndex < this.existingDocumentImageList.length) {
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    } else {
      this.lastImage();
    }
    // }
  }

  firstImage() {
    // if (this.mustSaveAnnotations()) {
    this.currentArrayIndex = 0;
    this.pageNumber = this.currentArrayIndex + 1;
    this.loadExistingImage(
      this.existingDocumentImageList?.[this.currentArrayIndex]
    );
    // }
  }

  lastImage() {
    // if (this.mustSaveAnnotations()) {
    this.currentArrayIndex = this.existingDocumentImageList.length - 1;
    this.pageNumber = this.currentArrayIndex + 1;
    this.loadExistingImage(
      this.existingDocumentImageList?.[this.currentArrayIndex]
    );
    // }
  }

  // Function to navigate to a specific page
  goToPage() {
    // if (this.mustSaveAnnotations()) {
    if (this.existingDocumentImageList.length === 0) {
      // this.TiffError = true;
      //       this.loadingMessage = 'Images has not found';
      //       setTimeout(() => {
      //         this.clearCanvas();
      //       }, 500);
    } else if (this.pageNumber <= 0) {
      this.pageNumber = 1;
      this.firstImage();
    } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
      this.currentArrayIndex = this.pageNumber - 1;
      this.loadExistingImage(
        this.existingDocumentImageList[this.currentArrayIndex]
      );
    } else {
      this.pageNumber = this.totalPages;
      this.lastImage();
    }
    // } else {
    //   this.pageNumber =
    //     this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    // }
  }

  sendFax() {
    this.toastr.success('Fax Sent Successfully!');
    this.dialog.close();
  }
}
