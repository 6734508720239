import { ListService } from '@abp/ng.core';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/volo/abp/identity/models';
import { ClaimInformationsService } from 'projects/billing/src/app/billing-proxy/billing-management/posting/claim-informations.service';
import {
  ClaimDTO,
  PostingChargesDTO,
} from 'projects/billing/src/app/billing-proxy/billing-management/posting/dto/models';
import {
  getBilling,
  updateBilling,
} from 'projects/billing/src/app/billing-store/billing.action';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import {
  getOrder,
  updateOrder,
} from 'projects/order/src/app/order-store/order.action';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import {
  BillingMasterDropdownDTO,
  ClearingHouseTypeDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing/models';
import {
  StatusDTO,
  WipStatusDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { PatientDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { distinct } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TableService } from '../table.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [ListService],
  // animations: [
  //   trigger('detailExpand', [
  //     state('collapsed', style({ height: '0px', minHeight: '0' })),
  //     state('expanded', style({ height: '*' })),
  //     transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  //   ]),
  // ],
})
export class TableComponent implements OnInit, AfterViewInit {
  expandedElement: any | null;
  isShownclientPermission: boolean = true;
  @Input() strPageType: string = '';
  @Input() pageType: string = '';
  selection = new SelectionModel<any>(true, [], true);
  totalRecords: number = 0;
  show: any = true;
  @Input() arrTableData: any[] = [];
  @Input() isLoading: boolean = false;
  @Input() isLoadings: boolean = false;
  @Input() isLoadingForVerification: boolean = false;
  @Output() arrTableDataEventEmitter: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();
  @Input() currentUSerLoggedIn: boolean = false;
  drpOrganization: any = [];
  orderForm: FormGroup = new FormGroup({});
  insuranceAuthorizationForm: FormGroup = new FormGroup({});
  insuranceVerificationForm: FormGroup = new FormGroup({});
  billingListForm: FormGroup = new FormGroup({});
  billingwarehouseForm: FormGroup = new FormGroup({});
  patientLedgerForm: FormGroup = new FormGroup({});
  postingForm: FormGroup = new FormGroup({});
  paymentForm: FormGroup = new FormGroup({});
  transactionForm: FormGroup = new FormGroup({});
  billingClaimListForm: FormGroup = new FormGroup({});
  claimTransactionForm: FormGroup = new FormGroup({});
  public arrDisplayedColumns: string[] = [];
  drpStatus: StatusDTO[] = [];
  drpwarehouse: WipStatusDTO[] = [];
  claimTableData: any[] = [];
  enableEdit = false;
  @Input() saveButtonHide: boolean = false;
  enableEdits = false;
  enableEditIndex: string = null;
  isShown: boolean = false;
  isTenantDeactivate: boolean = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  organizationUnitId: string = null;
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @Output() viewButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() billingDetailsUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewsButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewClaimButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() claimButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() reminderButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() addPayment: EventEmitter<any> = new EventEmitter<any>();
  @Output() editButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() activateTenantButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() addPaymentPayPalInvoice: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() addPaymentPayPalCheckout: EventEmitter<any> =
    new EventEmitter<any>();

  patientId: string = '';
  public modeselect = 'Select';
  claimId: string = '';
  showValue: boolean = false;
  showValue1: boolean = false;
  showClaim: boolean = true;
  showClaim1: boolean = false;
  postingDetails: string = '';
  organizationunitId: string = '';
  orgTitle: string = 'Activate';
  clearingHouseTypes: ClearingHouseTypeDTO[] = [];
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = '';
  userId: string;
  roleId: string;
  @Output() claimSearchValueChanges: EventEmitter<{
    billedAmount: string;
    billingType: string;
    patientId: string;
    patientName: string;
    payerName: string;
    saleOrderId: string;
  }> = new EventEmitter();
  changeButtonClikced(value: any) {
    this.changeButton.emit(value);
  }
  viewButtonClikced(value: any) {
    this.viewButton.emit(value);
  }
  enableEditMethod(i: string) {
    this.enableEdit = true;
    this.enableEditIndex = i;
  }
  claimButtonClikced(value: any) {
    this.claimButton.emit(value);
  }
  reminderButtonClikced(value: any) {
    this.reminderButton.emit(value);
  }

  // ActivateTenant SlideToggle OnChange Function
  onSlideToggleChange(event: MatSlideToggleChange, value: any) {
    if (event.checked == true) {
      setTimeout(() => {
        this.activateTenantButton.emit(value);
      }, 1000);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.filter(
      (row) => !row.clearingHouseType
    ).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data?.forEach((row) => {
          if (!row.clearingHouseType && row.clearingHouseType !== '') {
            this.selection.select(row);
          }
        });
  }

  emitClaimProcess() {
    this.viewButton.emit(this.selection.selected);
  }

  emitElectronicClaimProcess() {
    this.viewsButton.emit(this.selection.selected);
  }
  emitUserDocumentsFaxBinToProcess() {
    this.reminderButton.emit(this.selection.selected);
  }
  constructor(
    private store: Store,
    private claimInformationService: ClaimInformationsService,
    private formBuilder: FormBuilder,
    private Table: TableService,
    private router: Router,
    private userService: UserService,
    private listService: ListService,
    private toastr: ToastrService,
    private billingDropdownService: BillingMasterDropdownService
  ) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
  }
  ngAfterViewInit(): void {}
  drpinsurance: insuranceface[] = [];
  postingChargesId: string = '';
  tblPostsReponse: any[] = [];
  tblPostReponse: any[] = [];
  selectedCity: string;
  ngOnInit(): void {
    this.roleId = localStorage.getItem('roleId') ?? '';
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.userId = localStorage.getItem('userId') ?? '';
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.Table.getBillClaimListDataTable().subscribe(
      (val) => {
        this.claimId = val.id;
        this.patientId = val.patientId;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.getPageType();
    this.fetchTable();
    // this.getDropdown();
    setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.selectedCity = 'london';
    this.Table.getRolesUpdate().subscribe(
      (value) => {
        this.isRolesUpdatePermission = value;
        if (this.isRolesUpdatePermission == true) {
          this.isShownUpdateIcon = true;
        } else {
          this.isShownUpdateIcon = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getRolesDelete().subscribe(
      (value) => {
        this.isRolesDeletePermission = value;
        if (this.isRolesDeletePermission == true) {
          this.isShownDeleteIcon = true;
        } else {
          this.isShownDeleteIcon = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getTenantUpdate().subscribe(
      (value) => {
        this.isTenantUpdatePermission = value;
        if (this.isTenantUpdatePermission == true) {
          this.isShownUpdateIconForTenant = true;
        } else {
          this.isShownUpdateIconForTenant = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getTenantDelete().subscribe(
      (value) => {
        this.isTenantDeletePermission = value;
        if (this.isTenantDeletePermission == true) {
          this.isShownDeleteIconForTenant = true;
        } else {
          this.isShownDeleteIconForTenant = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getUsersUpdate().subscribe(
      (value) => {
        this.isUsersUpdatePermission = value;
        if (this.isUsersUpdatePermission == true) {
          this.isShownUpdateIconForUser = true;
        } else {
          this.isShownUpdateIconForUser = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getUsersDelete().subscribe(
      (value) => {
        this.isUsersDeletePermission = value;
        if (this.isUsersDeletePermission == true) {
          this.isShownDeleteIconForUser = true;
        } else {
          this.isShownDeleteIconForUser = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getRolesManagePermission().subscribe(
      (value) => {
        if (value == true) {
          this.isShownclientPermission = true;
        } else {
          this.isShownclientPermission = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getItemUpdate().subscribe(
      (value) => {
        this.isItemUpdatePermission = value;
        if (this.isItemUpdatePermission == true) {
          this.isShownUpdateIconForItem = true;
        } else {
          this.isShownUpdateIconForItem = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getItemDelete().subscribe(
      (value) => {
        this.isItemDeletePermission = value;
        if (this.isItemDeletePermission == true) {
          this.isShownDeleteIconForItem = true;
        } else {
          this.isShownDeleteIconForItem = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getOrderUpdate().subscribe(
      (value) => {
        this.isOrderUpdatePermission = value;
        if (this.isOrderUpdatePermission == true) {
          this.isShownUpdateIconForOrder = true;
        } else {
          this.isShownUpdateIconForOrder = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.Table.getOrganizationUnitId().subscribe(
      (val) => {
        this.organizationunitId = val;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  insuranceDropdown(): void {
    this.drpinsurance = [];
    this.store.dispatch(new getPatient(this.patientId)).subscribe(
      (response) => {
        const patientData: PatientDTO = response?.patientState.selectedPatient;
        if (
          patientData?.policies?.primaryPolicy &&
          patientData?.policies &&
          patientData
        ) {
          const primaryPolicy = patientData.policies.primaryPolicy;
          this.drpinsurance.push({
            policy: primaryPolicy.insuranceType ?? '',
            policiesId: primaryPolicy.policiesId ?? '',
            payorLevel: primaryPolicy.payorLevel ?? '',
            name: primaryPolicy.fisrtName ?? '',
          });
        }
        if (
          patientData?.policies?.secondaryPolicy &&
          patientData?.policies &&
          patientData
        ) {
          const secondaryPolicy = patientData.policies.secondaryPolicy;
          this.drpinsurance.push({
            policy: secondaryPolicy.insuranceType ?? '',
            policiesId: secondaryPolicy.policiesId ?? '',
            payorLevel: secondaryPolicy.payorLevel ?? '',
            name: secondaryPolicy.fisrtName ?? '',
          });
        }
        if (
          patientData?.policies?.tertiaryPolicy &&
          patientData?.policies &&
          patientData
        ) {
          const tertiaryPolicy = patientData.policies.tertiaryPolicy;
          this.drpinsurance.push({
            policy: tertiaryPolicy.insuranceType ?? '',
            policiesId: tertiaryPolicy.policiesId ?? '',
            payorLevel: tertiaryPolicy.payorLevel ?? '',
            name: tertiaryPolicy.fisrtName ?? '',
          });
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  savePosting(claimId: string, postingChargesId: string) {
    // this.postingChargesId =postingChargesId;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let claimDate: ClaimDTO;
    this.store.dispatch(new getBilling(claimId)).subscribe(
      (response) => {
        claimDate = response.billingState.selectedBilling;
        const postingAndCharges: PostingChargesDTO[] =
          claimDate.lstPostingCharges;
        const posting: any[] = [];
        postingAndCharges.forEach((element) => {
          const reslutedStatus: string = this.claimTransactionForm.value.Status;

          if (element?.postingChargesId === postingChargesId) {
            element = {
              itemId: element?.itemId,
              itemSize: element?.itemSize,
              productId: element?.productId,
              status: reslutedStatus,
              fee: element?.fee,
              patientResp: element?.patientResp,
              postingChargesId: element?.postingChargesId,
              diagnosisCode9: element?.diagnosisCode9,
              diagnosisCode10: element?.diagnosisCode10,
              item: element?.item,
              insurance: element?.insurance, // resultedPlan,
              modifier1: element?.modifier1,
              modifier2: element?.modifier2,
              modifier3: element?.modifier3,
              modifier4: element?.modifier4,
              pos: element?.pos,
              tos: element?.tos,
              provider: element?.provider,
              dateFrom: element?.dateFrom,
              dateTo: element?.dateTo,
              dxPointer1: element?.dxPointer1,
              dxPointer2: element?.dxPointer2,
              dxPointer3: element?.dxPointer3,
              dxPointer4: element?.dxPointer4,
              checkNo: element?.checkNo,
              units: element?.units,
              copay: element?.copay,
              other: element?.other,
              total: element?.total,
              document: element?.document,
              statementNote: element?.statementNote,
              cpts: element?.cpts,
              dos: element?.dos,
            };
          }
          posting.push({ ...element });
        });
        claimDate = {
          isVoided: claimDate?.isVoided,
          lstClearingHouseUpdates: [],
          lstPostingCharges: posting,
          lstClaimInfo: claimDate.lstClaimInfo,
          insurancePayments: {
            primaryInsurancePaymentDetails:
              claimDate.insurancePayments.primaryInsurancePaymentDetails,
            secondaryInsurancePaymentDetails:
              claimDate.insurancePayments.secondaryInsurancePaymentDetails,
            tertiaryInsurancePaymentDetails:
              claimDate.insurancePayments.tertiaryInsurancePaymentDetails,
          },
          saleOrder: claimDate.saleOrder,
          defaultPatientId: claimDate.defaultPatientId,
          saleorderId: claimDate.saleorderId,
          readyCount: claimDate.readyCount,
          processedCount: claimDate.processedCount,
          crossOverCount: claimDate.crossOverCount,
          holdCount: claimDate.holdCount,
          patientResponsibilityCount: claimDate.patientResponsibilityCount,
          patientResponsibilityAfterInsCount:
            claimDate.patientResponsibilityAfterInsCount,
          totalInsBal: claimDate.totalInsBal,
          totalPatientBal: claimDate.totalPatientBal,
          totalBilled: claimDate.totalBilled,
          claimId: claimDate.claimId,
          orderId: claimDate.orderId,
          patientDob: claimDate.patientDob,
          patientId: claimDate.patientId,
          patientName: claimDate.patientName,
          creatorId: claimDate.creatorId,
          id: claimDate.id,
          lstPatientVisitNotes: claimDate.lstPatientVisitNotes,
          lstReminders: claimDate?.lstReminders,
          claimProcessMethod: claimDate?.claimProcessMethod,
          currentBalance: claimDate?.currentBalance,
          claimProcessedDate: claimDate?.claimProcessedDate,
        };
        setTimeout(() => {
          this.store.dispatch(new updateBilling(claimId, claimDate)).subscribe(
            () => {
              // Swal.fire({
              //   title: 'Success',
              //   html: 'Saved Successfully',
              //   icon: 'success',
              //   timer: 3000,
              //   timerProgressBar: true,
              // });
              this.toastr.success('Saved Successfully', 'Success');

              this.enableEdits = false;
              this.showClaim = true;
              this.showClaim1 = false;
              this.getTableData();
              this.loadPatientVisitsTable();
              // this.dialogRef.close();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        }, 10);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  getTableData() {
    let postingDetails: ClaimDTO;
    this.store.dispatch(new getBilling(this.claimId)).subscribe(
      (response) => {
        postingDetails = response?.billingState?.selectedBilling;
        this.tblPostReponse = [];
        const id = postingDetails.id;
        postingDetails.lstPostingCharges.forEach((element) => {
          if (
            element?.postingChargesId !== '00000000-0000-0000-0000-000000000000'
          ) {
            this.tblPostReponse.push({
              code: element?.item,
              cpt: '',
              dos: element?.dos,
              m1: element?.modifier1,
              m2: element?.modifier2,
              m3: element?.modifier3,
              m4: element?.modifier4,
              fee: element?.fee,
              diagnosis9: element?.diagnosisCode9,
              diagnosis10: element?.diagnosisCode10,
              provider: element?.provider,
              postingChargesId: element?.postingChargesId,
              statusType: element?.status,
              id,
              pos: element?.pos,
              units: element?.units,
              plan: element?.insurance,
            });
          }
        });
        this.Table.setClaimHistoryDataTable(this.tblPostReponse);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => {}
    );
  }

  loadPatientVisitsTable() {
    const claimInfoList = (query: any) =>
      this.claimInformationService.getList(query);
    this.listService.hookToQuery(claimInfoList).subscribe(
      (response) => {
        this.claimTableData = [];
        response?.items?.forEach((element) => {
          this.Table.setBillClaimListDataTable(element);
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            // status: element?.status,
            daySheet: '12/12/2020',
            dos:
              element?.saleOrder?.items[0]?.originalDos == null
                ? ''
                : new Date(
                    element?.saleOrder?.items[0]?.originalDos
                  )?.toLocaleDateString('en-US'),
            // provider: element?.lstPostingCharges[0].provider,
            provider: '',
            data: '1',
            patBal: '$0.00',
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille:
              element?.creationTime == null
                ? ''
                : new Date(element?.creationTime)?.toLocaleDateString('en-US'),
            lastFilled:
              element?.lastModificationTime == null
                ? ''
                : new Date(element?.lastModificationTime)?.toLocaleDateString(
                    'en-US'
                  ),
            claimid: element?.claimId,
            id: element?.id,
            patientResponsibilityAfterInsCount:
              element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            // planCode: null,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount,
          });
        });
        this.Table.setBillingHistoryDataTable(this.claimTableData);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  showWip() {
    this.showValue = false;
    this.showValue1 = true;
  }
  cancelWip() {
    this.showValue = true;
    this.showValue1 = false;
  }

  showClaims() {
    this.showClaim = false;
    this.showClaim1 = true;
  }
  cancelClaim() {
    this.showClaim = true;
    this.showClaim1 = false;
  }
  getDropdown() {
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Status,
      BillingDropdowns.ClearingHouseTypes,
    ];
    this.billingDropdownService
      .getBillingDropdownsByInput(billingDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: BillingMasterDropdownDTO = stateResponse;
          this.drpStatus = response.statuses;
          this.clearingHouseTypes = response.clearingHouseTypes;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  updatePaymentData(arrTableData: any[]) {
    this.viewButton.emit(arrTableData);
  }
  getPageType() {
    switch (this.strPageType) {
      case 'transactionCodesList':
        {
          this.transactionForm = this.formBuilder.group({
            Code: new FormControl(''),
            CPTCode: new FormControl(''),
            Description: new FormControl(''),
            POS: new FormControl(''),
            Qty: new FormControl(''),
            MinOH: new FormControl(''),
            OrderQty: new FormControl(''),
          });
          this.Table.getTransactionData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                // 'sno',
                'Code',
                'CPT Code',
                'Description',
                'POS',
                'Qty',
                'Min OH',
                'Order Qty',
                'Action',
              ];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          // this.transactionForm.valueChanges.pipe().subscribe(value => {
          //
          //   let filter: any = {
          //     Code: value.Code,
          //     CPTCode: value.CPTCode,
          //     Description: value.Description,
          //     POS: value['POS'],
          //     Qty: value.Qty,
          //     MinOH: value.MinOH,
          //     OrderQty: value.OrderQty
          //   };
          //   this.dataSource.filter = JSON.stringify(filter);
          //   this.filtertable();
          // });
        }
        break;
      case 'pverifyEligibilityMedicare':
        {
          this.arrTableData = [];
          this.Table.getCoverageSummaryData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            'insurance',
            'coverage',
            'date',
            'expDate',
            'grpName',
            'grpNo',
          ];
        }
        break;
      case 'selectedDocumentList':
        {
          this.arrTableData = [];
          this.Table.getSelectedDocumentList().subscribe(
            (value) => {
              this.arrTableData = value;

              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            // 'sno',
            'fileName',
            'fileSize',
            'fileType',
          ];
        }
        break;

      case 'AdminfaxBin':
        {
          this.arrTableData = [];
          this.Table.getlstfaxBinData().subscribe(
            (value) => {
              this.arrTableData = value;

              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            'Options',
            // 'sno',
            'documentName',
            'documentType',
            'documentSize',
            'uploadedDate',
            'stat',
            'assignedPerson',
            'assignedDate',
            'expandedErrorDetail',
          ];
        }
        break;
      case 'userfaxBin':
        {
          this.arrTableData = [];

          this.userService.getIdentityUser().subscribe(
            (response: IdentityUserDto[]) => {
              this.arrUsersList = response.filter(
                (user) => user.id !== this.userId
              );
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
          this.Table.getlstfaxBinData().subscribe(
            (value) => {
              this.arrTableData = value;

              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            'Options',
            'select',
            // 'sno',
            'usersList',
            'documentName',
            'documentType',
            'documentSize',
            'stat',
            'assignedDate',
          ];
        }
        break;

      case 'daySheetlist':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'daysheetDate',
            'description',
            'Options',
          ];
          this.Table.getSheetListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
        }
        break;

      case 'clientList':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'ClientCode',
            'name',
            'mail',
            'status',
            'date',
            'Options',
          ];
          this.Table.getClientListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;
      case 'officialList':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'name',
            'email',
            'ceo',
            'telephone',
            'Options',
          ];
          // this.Table.getClientListData().subscribe(value => {

          //     this.arrTableData = value;
          //     this.fetchTable();
          //   },
          //   () => { }
          // );
        }
        break;
      case 'branchDetals':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'name',
            'address',
            'mail',
            'Contact',
            'Options',
          ];
          this.Table.getBranchListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;
      case 'Roles':
        {
          this.arrDisplayedColumns = [
            // 'sno' ,
            'Options',
            'roleName',
            'wip'
          ];
          this.Table.getRoleListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;
      case 'userList':
        {
          this.arrDisplayedColumns = [
            // 'sno',
            'Options',
            'name',
            'email',
            'branch',
            'role',

          ];
          this.Table.getUserListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;
      case 'ExecutiveRoles':
        {
          this.arrDisplayedColumns = [
            // 'sno',
            'Options',
            'roleName',

          ];
          this.Table.getExecutiveRoleListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;
      case 'executiveUserList':
        {
          this.arrDisplayedColumns = [
            // 'sno',
            'Options',
            'name',
            'email',
            'role',

          ];
          this.Table.getExecutiveUserListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;

      case 'claimStatusList':
        {
          this.arrTableData = [];
          this.Table.getClaimStatusListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );

          // this.arrDisplayedColumns = ["patientName", "status", "amountPaid", "checkIssueDate", "checkNumber", "claimServiceDate", "paidDate", "entity", "trackingNumber", "tradingPartnerClaimNumber", "statusCodeValue",];
          this.arrDisplayedColumns = [
            'tradingPartnerClaimNumber',
            'patientId',
            'patientName',
            'payerID',
            'payerName',
            'claimServiceDate',
            'billingName',
            'status',
          ];
        }
        break;

      case 'pverifyDeductibleBenefits':
        {
          this.arrTableData = [];
          this.Table.getDeductibleBenefitData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            // 'sno',
            'type',
            'baseAmount',
            'remaining',
            'planDates',
          ];
        }
        break;
      case 'npiDoctor':
        {
          this.arrTableData = [];
          this.Table.getNpiDoctorData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            'Action',
            //'S.NO',
            'NPI Number',
            'Doctor Name',
            'Address',
            'Country',
            'State',
            'City',
            'Postal Code'

          ];
        }
        break;
      case 'icd9Page':
        {
          this.arrTableData = [];
          this.Table.getIcd9Codes().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = ['Code', 'Description', 'Action'];
        }
        break;
      case 'History':
        {
          this.arrTableData = [];
          this.Table.getPatientHistoryDataTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            //'SNO',
            'ActionComment',
            'dateTime',
            'UserName',
            'Action',
          ];
        }
        break;
      case 'payorPage':
        {
          this.arrTableData = [];
          this.Table.getPayorCodeList().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            // 'sno',
            'Options',
            'payorId',
            'payorName',

          ];
        }
        break;
      case 'paymentSetup':
        {
          this.arrTableData = [];
          this.Table.getPaymentSetupList().subscribe(
            (value) => {
              this.arrTableData = value;

              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
          this.arrDisplayedColumns = [
            // 'sno',
            'Options',
            'merchantId',
            'paymentType',
            'paymentOptionName',
            'chkEnabled',

          ];
        }
        break;

      case 'icd10Page':
        {
          this.arrTableData = [];
          this.Table.getIcd10Codes().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = ['Code', 'Description', 'Action']; // 'Billable'
        }
        break;
      case 'pverifyCoInsuranceBenefits':
        {
          this.arrTableData = [];
          this.Table.getCoBenefitData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.arrDisplayedColumns = [
            // 'sno',
            'type',
            'amount',
            'planDates',
          ];
        }
        break;
      case 'paymentHistory':
        {
          this.arrDisplayedColumns = [
            'Options',
            'claim',
            'saleorderId',
            'patientId',
            'patientName',
            // 'status',
            'totalBilledAmount',
            'totalInsurancePaidAmount',
            'totalPatientAmount',
            // 'checkNo',
            // 'day',

            // 'dos',
            // 'provider',
            // 'qty',
            // 'totalFee',
            // 'pat',
            // 'ins',
            // 'last',
          ];
          this.Table.getBillingHistoryDataTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'AdminTenants':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'name',
            'email',
            'Options',
          ];
          this.Table.getTenantData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'sAdminActivateTenants':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'name',
            'email',
            'Options',
          ];
          this.Table.getDeactivatedTenantData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {
              //
            }
          );
        }
        break;
      case 'AdminUsers':
        {
          this.arrDisplayedColumns = [
            'Options',
            // 'sno',
            'userName',
            'name',
            'surName',
            'role',
            'tenantName',
            'organizationUnitName',
            'emailAddress',
            'phoneNumber',
          ];
          this.Table.getUsersData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'AdminInviteUsers':
        {
          this.arrDisplayedColumns = [
            'Options',
            // 'sno',
            'name',
            'emailAddress',
            'roleName',
            'branchName',
            'createdOn',
            'expireOn',
            'inviteStatus',
          ];
          this.Table.getInviteUsersData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'AdminRoles':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'roleName',
            'Options',
          ];
          this.Table.getRolesData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'AdminOrganizations':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'branchName',
            'Options',
          ];
          this.Table.getOrganizationData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'insPayment2':
        {
          // this.paymentForm = this.formBuilder.group({
          //   allowed: new FormControl(""),
          //   claim: new FormControl(""),
          //   deduct: new FormControl(""),
          //   copay: new FormControl(""),
          //   payment: new FormControl(""),
          //   writeOff: new FormControl(""),
          //   insBal: new FormControl(""),
          //   patBal: new FormControl("")
          // })
          this.arrDisplayedColumns = [
            'Code',
            'Dos',
            'charge',
            'allowed',
            'deduct',
            'copay',
            'payment',
            'writeOff',
            'insBal',
            'patBal',
            'claim',
          ];
          this.Table.getPaymentItemData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
        }
        break;
      case 'editPayment':
        {
          this.arrDisplayedColumns = [
            'options',
            'Payment',
            'Posted',
            'user',
            'code',
            'description',
            'check',
            'fee',
            'unapplied',
          ];
          this.Table.getEditPaymentsDataTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'eraPayment':
        {
          this.arrDisplayedColumns = [
            'Payment',
            'Patient',
            'acnt',
            'icn',
            'status',
            'billed',
            'paid',
            'skip',
            'ok',
          ];
          // let testData = { OrderID: 111, HCPCS: 12, patientId: 102, Item: 'AS21', Name: 'Headgear', Qty: 1, RP: 'Rental', scheduleDate: '', DOS: '' }
          //  this.arrTableData.push(testData)
          this.fetchTable();
        }
        break;
      case 'eraPayment2':
        {
          this.arrDisplayedColumns = [
            'code',
            'cpt',
            'dos',
            'm1',
            'm2',
            'm3',
            'm4',
            'unit',
            'billed',
            'allowed',
            'deduct',
            'copay',
            'co-ins',
            'paid',
            'write',
            'claim',
          ];
          // let testData = { OrderID: 111, HCPCS: 12, patientId: 102, Item: 'AS21', Name: 'Headgear', Qty: 1, RP: 'Rental', scheduleDate: '', DOS: '' }
          //  this.arrTableData.push(testData)
          this.fetchTable();
        }
        break;
      case 'addPosting2':
        {
          this.arrDisplayedColumns = [
            'Code',
            'dos',
            // 'ItemName',
            'Units',
            'price',
            'total',
            'Options',
            // 'Fee',
            // 'Diag9',
            // 'Diag10',
            // 'Provider',
          ];
          this.Table.getPostTable().subscribe(
            (value) => {
              this.arrTableData = value;

              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'InsPayments':
        {
          this.arrDisplayedColumns = ['Code', 'description', 'date', 'fee'];
          // let testData = { OrderID: 111, HCPCS: 12, patientId: 102, Item: 'AS21', Name: 'Headgear', Qty: 1, RP: 'Rental', scheduleDate: '', DOS: '' }
          //  this.arrTableData.push(testData)
          this.fetchTable();
        }
        break;
      case 'Positing':
        {
          this.postingForm = this.formBuilder.group({
            patientId: new FormControl(''),
            patientName: new FormControl(''),
            dob: new FormControl(''),
            dos: new FormControl(''),
            planCode: new FormControl(''),
            insurance: new FormControl(''),
            createdDateTime: new FormControl(''),
          });
          this.Table.getTransactionData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                'options',
                'patientId',
                'patientName',
                'dob',
                'dos',
                'planCode',
                'insurance',
                'createdDateTime',
              ];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'patientLedger':
        {
          this.patientLedgerForm = this.formBuilder.group({
            ticketId: new FormControl(''),
            chartNo: new FormControl(''),
            insuranceName: new FormControl(''),
            suppliedDate: new FormControl(''),
            patientBalance: new FormControl(''),
            insuranceBalance: new FormControl(''),
            totalBalance: new FormControl(''),
            patientId: new FormControl(''),
            patientName: new FormControl(''),
            email: new FormControl(''),
            shippingLocation: new FormControl(''),
            createdDate: new FormControl(''),
            status: new FormControl(''),
            csr: new FormControl(''),
          });
          this.Table.getPatientLedgerDataTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                'options',
                'ticketId',
                'chartNo',
                'insuranceName',
                'patientId',
                'patientName',
                'patientBalance',
                'insuranceBalance',
                'totalBalance',
                'suppliedDate',
                'email',
                'shippingLocation',
                'createdDate',
                'status',
                'csr',
              ];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          // this.patientLedgerForm.valueChanges.subscribe((value: any) => {
          //   let filter: patientLedger = {
          // ticketId: value.ticketId,
          // chartNo: value.chartNo,
          // createdDate: value.createdDate,
          // email: value.email,
          // insuranceBalance: value.insuranceBalance,
          // insuranceName: value.insuranceName,
          // patientBalance: value.patientBalance,
          //     patientId: value.patientId,
          //   patientName: value.patientName,
          //   shippingLocation: value.shippingLocation,
          //   status: value.status,
          //   suppliedDate: value.suppliedDate,
          //   totalBalance: value.totalBalance,
          //   csr: value.csr
          //   };
          //   this.dataSource.filter = JSON.stringify(filter);
          //   this.filtertable();

          // });
        }
        break;
      case 'billingWarehouseList':
        {
          this.billingwarehouseForm = this.formBuilder.group({
            billingId: new FormControl(''),
            chartNo: new FormControl(''),
            insuranceName: new FormControl(''),
            suppliedDate: new FormControl(''),
            patientBalance: new FormControl(''),
            insuranceBalance: new FormControl(''),
            totalBalance: new FormControl(''),
            patientId: new FormControl(''),
            patientName: new FormControl(''),
            email: new FormControl(''),
            shippingLocation: new FormControl(''),
            createdDate: new FormControl(''),
            status: new FormControl(''),
            warehouse: new FormControl(''),
            // inputWarehouse:new FormControl("")
          });
          this.Table.getBillingWarehouseListDataTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                'options',
                'billingId',
                'insuranceName',
                'patientId',
                'patientName',
                'suppliedDate',
                'email',
                'shippingLocation',
                'createdDate',
                'status',
                'warehouse',
              ];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;

      case 'orders':
        {
          this.Table.getActiveData().subscribe(
            (value) => {
              this.arrDisplayedColumns = [
                'Options',
                'orderId',
                'ticketId',
                'itemNames',
                'qty',
                'totalPrice',
                'scheduleDate',
                'originalDos',
                'createdDate',
              ];
              // this.filtertable();
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'verification':
        {
          this.arrDisplayedColumns = [
            'name',
            'Type',
            'Status',
            'Plan',
            'Options',
          ];
          this.Table.getVerifyData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'eligiblityHistory':
        {
          this.arrDisplayedColumns = [
            // 'Sno',
            'Plan',
            'name',
            'Policy',
            'Status',
            'Options',
          ];
          this.Table.getOutVerifyData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
        }
        break;
      case 'ordersHistory':
        {
          this.Table.getActiveData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                'orderId',
                'ticketId',
                'itemNames',
                'qty',
                'totalItemPrice',
                'scheduleDate',
                'originalDos',
                'createdDate',
                // 'Options',
              ];

              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'item':
        {
          this.Table.getOrderData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
              this.arrDisplayedColumns = [
                'DOS',
                'itemId',
                // 'Quantity',
                // 'billQty',
                // 'ProcCode',
                'totalPrice',
                'totalItemQty',
                'Options',
                // 'Modifiers',
                // 'additional',
                // 'Coverage',
                // 'Allow',
                // 'nextDos',
              ];
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'order':
        {
          this.Table.getOrdersData().subscribe(
            (value) => {
              this.arrDisplayedColumns = ['Carrier', 'Tracking', 'Date'];

              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'patientOrderStatus':
        {
          this.Table.getPatientOrderStatusTable().subscribe(
            (value) => {
              this.arrTableData = value;

              this.arrDisplayedColumns = [
                //'SNo',
                'OrderId',
                'Verification',
                'Authorization',
                'OrderStatus',
                'CreatedDate',
                'Action',
              ];
              // this.arrDisplayedColumns = ['SNO'];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'InventoryItem':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'itemId',
            'itemName',
            'itemPrice',
            'Options',
          ];
          this.Table.getInventoryData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'auth':
        {
          // this.arrDisplayedColumns = ['authId', 'startDate', 'endDate', 'authAmount', 'Completed','days',  'Plan', 'Options'];
          this.arrDisplayedColumns = [
            'Options',
            'authId',
            'startDate',
            'endDate',
            'ExpiryStatus',
            'isFollowUp',
            'authAmount',
            'Completed',
            'Plan',
          ];
          this.Table.getAuthData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'ItemBasedAuth':
        {
          // this.arrDisplayedColumns = ['authId', 'startDate', 'endDate', 'authAmount', 'Completed','days',  'Plan', 'Options'];
          this.arrDisplayedColumns = [
            'Options',
            'item',
            'authId',
            'startDate',
            'endDate',
            'Days',
            'ExpiryStatus',
            'isFollowUp',
            'type',
            'Units',
            'Used',
            'Plan',
          ];
          this.Table.getAuthData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'disapproveOrders':
        {
          this.orderForm = this.formBuilder.group({
            OrderId: new FormControl(''),
            ItemId: new FormControl(''),
            ItemName: new FormControl(''),
            Quantity: new FormControl(''),
            CreatedDate: new FormControl(''),
          });
          this.Table.getpatientOrderTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                'OrderId',
                'ItemId',
                'ItemName',
                'CreatedDate',
                'Quantity',
                'options',
              ];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );

          this.orderForm.valueChanges.pipe().subscribe(
            (value) => {
              const filter: any = {
                // hCPCS: value.hCPCS,
                itemId: String(value.ItemId),
                itemName: String(value.ItemName).trim()?.toLowerCase(),
                saleorderId: value.OrderId,
                quantity: value.Quantity,
                CreatedDate: value.CreatedDate,
              };
              this.dataSource.filter = JSON.stringify(filter);
              //   this.filtertable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        }
        break;

      case 'patientInsuranceVerification':
        {
          this.insuranceVerificationForm = this.formBuilder.group({
            SNo: new FormControl(''),
            PatientId: new FormControl(''),
            PatientName: new FormControl(''),
            orderId: new FormControl(''),
            createDate: new FormControl(''),
            VerifyName: new FormControl(''),
            Status: new FormControl(''),
            CreatedDate: new FormControl(''),
          });

          this.Table.getpatientInsuranceVerficationTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                'Options',
                //'SNo',
                'PatientId',
                'PatientName',
                'orderId',
                'createDate',
                'VerifyName',
                'Status',
              ];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
        }
        break;
      case 'note':
        {
          this.arrTableData = [];
          this.arrDisplayedColumns = [
            'defaultNotesId',
            'createdBy',
            'dateCreated',
            'actualDate',
            'noteType',
            'reason',
            // 'dateNeeded',
            'status',
            'action',
          ];
          this.Table.getNoteTable().subscribe(
            (value) => {
              this.arrTableData = value;
              //
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'reminders':
        {
          this.Table.getPateintRemindersTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.arrDisplayedColumns = [
                //'SNo',
                'reminderSubject',
                'reminderDate',
                'CreatedDate',
                'Action',
              ];
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'Provider':
        {
          this.arrTableData = [];
          this.arrDisplayedColumns = [
            'npi',
            'code',
            'payorId',
            'name',
            'phone',
            'address',
            'action',
          ];
          this.Table.getproviderTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'items':
        {
          this.Table.getOrderData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
              this.arrDisplayedColumns = [
                'DOS',
                'itemId',
                'Quantity',
                'billQty',
                'ProcCode',
                'Modifiers',
                'additional',
                'Coverage',
                'Allow',
                'nextDos',
              ];
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
        }
        break;
      case 'claimTransaction':
        {
          this.getDropdown();
          this.claimTransactionForm = this.formBuilder.group({
            Status: new FormControl(''),
            txtPlan: new FormControl(''),
          });
          this.arrDisplayedColumns = [
            'options',
            'Code',
            'M1',
            'M2',
            'M3',
            'pos',
            'dos',
            'provider',
            'free',
            'units',
            'plan',
            'status',
            'hr',
          ];
          this.Table.getClaimHistoryDataTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {
              //
            }
          );
          this.insuranceDropdown();
        }
        break;
      case 'claimProcessList':
        {
          this.arrDisplayedColumns = [
            'options',
            'select',
            'saleorderId',
            'pateintId',
            'patientName',
            // 'patientDob',
            'amount',
            'primaryInsuranceName',
            // 'clrhouse',
            'billingType',
            // 'formDescripition',
          ];

          this.Table.getClaimProcessListData()
            .pipe(distinct())
            .subscribe(
              (value) => {
                this.arrTableData = value ?? [];
                // this.getDropdown()
                this.fetchTable();
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              },
              () => {
                //
              }
            );
        }
        break;
      case 'policy':
        {
          this.arrDisplayedColumns = [
            'payorLevel',
            'insuranceType',
            'payorId',
            'policyNo',
            'note',
            'options',
          ];
          this.Table.getPolicyData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
          // this.arrDisplayedColumns = ['startDate','endDate','policyNumber','insuranceId','deductible','coverageType','groupNumber','groupName','options'];
          // this.fetchTable();
        }

        break;

      case 'practiceReferral':
        {
          this.arrDisplayedColumns = [
            'FaxId',
            'Path',
            'name',
            'page',
            'description',
            'location',
            'pName',
            'phyName',
            'pId',
            // 'options',
          ];
          this.Table.getPracticeData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
          // this.arrDisplayedColumns = ['startDate','endDate','policyNumber','insuranceId','deductible','coverageType','groupNumber','groupName','options'];
          // this.fetchTable();
        }

        break;

      case 'practiceReferralFaxNo':
        {
          this.arrDisplayedColumns = ['FaxNo'];
          this.Table.getPracticeData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
          // this.arrDisplayedColumns = ['startDate','endDate','policyNumber','insuranceId','deductible','coverageType','groupNumber','groupName','options'];
          // this.fetchTable();
        }

        break;

      case 'plan':
        {
          this.arrDisplayedColumns = [
            'payerId',
            'payerName',
            'npiCode',
            'planName',
            'options',
          ];
          this.Table.getPlansTabledata().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
          // this.arrDisplayedColumns = ['startDate','endDate','policyNumber','insuranceId','deductible','coverageType','groupNumber','groupName','options'];
          // this.fetchTable();
        }
        break;

      // ontime schedule
      case 'appointmentType':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'name',
            'address',
            'mail',
            'Options',
          ];
          this.Table.getAppointmentListData().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;

      case 'onTimeUser':
        {
          this.arrDisplayedColumns = [
            // 'sno',

            'name',
            'email',
            'branch',
            'Options',
          ];
          this.Table.getOnTimeUserListTable().subscribe(
            (value) => {
              this.arrTableData = value;
              this.fetchTable();
            },
            () => {}
          );
        }
        break;
      default:
        this.fetchTable();
    }
  }

  fetchTable() {
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    setTimeout(() => {}, 1);
    this.selection.clear();
  }
  claimButtonHitted(value: any) {
    switch (this.strPageType) {
      case 'paymentHistory':
        this.claimButtonClikced(value);
        break;
    }
  }
  addReminderButtonHitted(value: any) {
    switch (this.strPageType) {
      case 'paymentHistory':
        this.reminderButtonClikced(value);
        break;
    }
  }
  getDetailsForSave(id: string) {
    let OrderDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(id)).subscribe(
      (response) => {
        OrderDetails = response.orderState.selectedOrder;
        this.saveWipStatus(OrderDetails);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => {}
    );
  }
  saveWipStatus(response: SaleorderDTO) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let OrderDetails: SaleorderDTO;
    OrderDetails = {
      defaultClaimId: response.defaultClaimId,
      orders: response.orders,
      insurances: response.insurances,
      clinicals: response.clinicals,
      items: response?.items,
      orderStatus: response.orderStatus,
      saleorderId: response.saleorderId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      orderWipStatus: this.billingwarehouseForm.value.warehouse,
      organizationUnitId: this.organizationUnitId ?? '',
      patients: response.patients,
      patientLedgerStatus: response.patientLedgerStatus,
      shippingApprovalStatus: response.shippingApprovalStatus,
      claimHistoryStatus: response.claimHistoryStatus,
      claimId: response.claimId,
      patientId: response.patientId,
      isShippingApprove: response.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      shippingCheckList: response?.shippingCheckList,
      orderBilling: response?.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType,
    };
    response.id != '' &&
      this.store
        .dispatch(new updateOrder(response?.id ?? '', OrderDetails))
        .subscribe(
          () => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully', 'Success');
            this.enableEdit = false;
            this.enableEdit = false;
            this.showValue = true;
            this.showValue1 = false;
            this.billingDetailsUpdated.emit(true);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }
  addPayPalInvoiceButtonHitted(value: any) {
    this.addPaymentPayPalInvoice.emit(value);
  }
  addPayPalCheckoutButtonHitted(value: any) {
    this.addPaymentPayPalCheckout.emit(value);
  }
  addButtonHitted(value: any) {
    this.addPayment.emit(value);
  }
  editButtonHitted(value: any) {
    this.editButton.emit(value);
  }
  ChangeButtonHitted(value: any) {
    switch (this.strPageType) {
      case 'ItemBasedAuth':
        this.changeButtonClikced(value);
        break;

      default:
    }
  }
  ViewButtonHitted(value: any, value3?: any, ...value2: any) {
    switch (this.strPageType) {
      case 'patientLedger':
        this.viewButtonClikced(value);
        break;

      case 'item':
        this.viewButtonClikced(value);
        break;
      case 'orders':
        this.viewButtonClikced(value);
        break;

      case 'note':
        this.viewButtonClikced(value);
        break;
      case 'Provider':
        this.viewButtonClikced(value);
        break;
      case 'verification':
        this.viewButtonClikced(value);
        break;
      case 'InventoryItem':
        this.viewButtonClikced(value);
        break;
      case 'reminders':
        this.viewButtonClikced(value);
        break;

      case 'patientRenewalOrders':
        this.viewButtonClikced(value);
        break;

      case 'patientInsuranceVerification':
        this.viewButtonClikced(value);
        break;
      case 'auth':
        this.viewButtonClikced(value);
        break;
      case 'ItemBasedAuth':
        this.viewButtonClikced(value);
        break;
      case 'patientOrderStatus':
        this.viewButtonClikced(value);
        break;
      case 'eligiblityHistory':
        this.viewButtonClikced(value);
        break;
      case 'paymentHistory':
        this.viewButtonClikced(value);
        break;
      case 'billingClaimList':
        this.viewButtonClikced(value);
        break;
      case 'claimProcessList':
        this.viewClaimButton.emit({ value, value2, value3 });
        break;
      case 'policy':
        this.viewButtonClikced(value);
        break;
      case 'ExecutiveRoles':
        this.viewButtonClikced(value);
        break;

      case 'executiveUserList':
        this.viewButtonClikced(value);
        break;

      case 'AdminTenants':
        this.viewButtonClikced(value);
        break;
      case 'plan':
        this.viewButtonClikced(value);
        break;
      case 'AdminUsers':
        this.viewButtonClikced(value);
        //
        break;

      case 'AdminInviteUsers':
        this.viewButtonClikced(value);
        //
        break;
      case 'AdminRoles':
        this.viewButtonClikced(value);
        //
        break;

      case 'AdminOrganizations':
        this.viewButtonClikced(value);
        //
        break;
      case 'npiDoctor':
        this.viewButtonClikced(value);
        break;
      case 'icd9Page':
        this.viewButtonClikced(value);
        break;
      case 'editPayment':
        this.viewButtonClikced(value);
        break;
      case 'icd10Page':
        this.viewButtonClikced(value);
        break;
      case 'Positing':
        this.viewButtonClikced(value);
        break;
      case 'billingClaimList':
        this.viewButtonClikced(value);
        break;
      case 'payorPage':
        this.viewButtonClikced(value);
        break;

      case 'paymentSetup':
        this.viewButtonClikced(value);
        break;

      case 'addPosting2':
        this.viewButtonClikced(value);
        break;

      case 'AdminfaxBin':
        this.viewButtonClikced(value);
        break;
      case 'userfaxBin':
        this.viewButtonClikced(value);
        break;
      case 'clientList':
        this.viewButtonClikced(value);
        break;
      case 'branchDetals':
        this.viewButtonClikced(value);
        break;
      case 'Roles':
        this.viewButtonClikced(value);
        break;
      case 'userList':
        this.viewButtonClikced(value);
        break;
      case 'onTimeUser':
        this.viewButtonClikced(value);
        break;
      default:
    }
  }
  @Output() deleteButton: EventEmitter<any> = new EventEmitter<any>();
  deleteButtonClikced(value: any) {
    this.deleteButton.emit(value);
  }
  ViewExecutiveRolesPermissionsClikced(value: any) {
    this.viewPermissionButton.emit(value);
  }

  deleteButtonHitted(value: any) {
    switch (this.strPageType) {
      case 'payorPage':
        this.deleteButtonClikced(value);
        break;
      case 'InventoryItem':
        this.deleteButtonClikced(value);
        break;
      case 'paymentHistory':
        this.deleteButtonClikced(value);
        break;
      case 'paymentSetup':
        this.deleteButtonClikced(value);
        break;
      case 'policy':
        this.deleteButtonClikced(value);
        break;
      case 'auth':
        this.deleteButtonClikced(value);
        break;
      case 'plan':
        this.deleteButtonClikced(value);
        break;
      case 'note':
        this.deleteButtonClikced(value);
        break;
      case 'Provider':
        this.deleteButtonClikced(value);
        break;
      case 'item':
        this.deleteButtonClikced(value);
        break;
      case 'reminders':
        this.deleteButtonClikced(value);
        break;
      case 'addPosting2':
        this.deleteButtonClikced(value);
        break;
      case 'userfaxBin':
        this.deleteButtonClikced(value);
        break;
      case 'AdminfaxBin':
        this.deleteButtonClikced(value);
        break;
      case 'eligiblityHistory':
        this.deleteButtonClikced(value);
        break;
      case 'branchDetals':
        this.deleteButtonClikced(value);
        break;
      case 'userList':
        this.deleteButtonClikced(value);
        break;
      case 'executiveUserList':
        this.deleteButtonClikced(value);
        break;
      case 'onTimeUser':
        this.deleteButtonClikced(value);
        break;
      default:
    }
  }
  deleteExecutiveRolesClikced(value: any) {
    this.deleteButton.emit(value);
  }

  filtertable(_filter: {
    sSaleOrderId: string;
    sItemId: string;
    sItemName: string;
    dtCreatedDate: string;
    iQuantity: number;
    organizationUnitId: string;
  }) {
    switch (this.strPageType) {
      case 'patientRenewalOrders':
        {
          // this.dataSource.filterPredicate = (data: any, filter: string) => {
          //   let passeddata: any = data;
          //   let filterParams: any = JSON.parse(filter);
          //   const saleorderId: boolean = !filterParams.saleorderId || passeddata.saleorderId.toString().trim()?.includes(filterParams.saleorderId.toString());
          //   const itemName: boolean = !filterParams.itemName || passeddata.itemName?.trim()?.toLowerCase().includes(filterParams.itemName);
          //   const itemId: boolean = !filterParams.itemId || passeddata.itemId?.trim()?.toLowerCase().includes(filterParams.itemId);
          //   const quantity: boolean = !filterParams.quantity || passeddata.quantity.toString().trim()?.includes(filterParams.quantity.toString());
          //   const CreatedDate: boolean = !filterParams.CreatedDate || passeddata.CreatedDate.toString().trim()?.includes(filterParams.CreatedDate.toString());
          //   return itemName && saleorderId && itemId && quantity && CreatedDate;
          // };
        }
        break;

      case 'billingWarehouseList':
        {
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const passeddata: billingList = data;
            const filterParams: billingList = JSON.parse(filter);
            const billingId: boolean =
              !filterParams.billingId ||
              passeddata.billingId
                .toString()
                .trim()
                .toLowerCase()
                .includes(filterParams.billingId.toString());
            const chartNo: boolean =
              !filterParams.chartNo ||
              String(passeddata.chartNo)
                .trim()
                ?.toLowerCase()
                .includes(filterParams.chartNo);
            const insuranceName: boolean =
              !filterParams.insuranceName ||
              String(passeddata.insuranceName)
                .trim()
                .toLowerCase()
                .includes(filterParams.insuranceName);
            const suppliedDate: boolean =
              !filterParams.suppliedDate ||
              String(passeddata.suppliedDate)
                .trim()
                .toLowerCase()
                .includes(filterParams.suppliedDate);
            const patientBalance: boolean =
              !filterParams.patientBalance ||
              String(passeddata.patientBalance)
                .trim()
                .toLowerCase()
                .includes(filterParams.patientBalance);
            const insuranceBalance: boolean =
              !filterParams.insuranceBalance ||
              String(passeddata.insuranceBalance)
                .trim()
                .toLowerCase()
                .includes(filterParams.insuranceBalance);
            const totalBalance: boolean =
              !filterParams.totalBalance ||
              String(passeddata.totalBalance)
                .trim()
                .toLowerCase()
                .includes(filterParams.totalBalance);
            const patientId: boolean =
              !filterParams.patientId ||
              String(passeddata.patientId)
                .trim()
                ?.toLowerCase()
                .includes(filterParams.patientId);
            const patientName: boolean =
              !filterParams.patientName ||
              String(passeddata.patientName)
                .trim()
                .toLowerCase()
                .includes(filterParams.patientName);
            const email: boolean =
              !filterParams.email ||
              String(passeddata.email)
                .trim()
                ?.toLowerCase()
                .includes(filterParams.email);
            const shippingLocation: boolean =
              !filterParams.shippingLocation ||
              String(passeddata.shippingLocation)
                .trim()
                .toLowerCase()
                .includes(filterParams.shippingLocation);
            const createdDate: boolean =
              !filterParams.createdDate ||
              String(passeddata.createdDate)
                .trim()
                .toLowerCase()
                .includes(filterParams.createdDate);
            const status: boolean =
              !filterParams.status ||
              String(passeddata.status)
                .trim()
                ?.toLowerCase()
                .includes(filterParams.status);
            return (
              billingId &&
              chartNo &&
              insuranceName &&
              suppliedDate &&
              patientBalance &&
              insuranceBalance &&
              totalBalance &&
              patientId &&
              patientName &&
              email &&
              shippingLocation &&
              createdDate &&
              status
            );
          };
        }
        break;

      default:
    }
  }

  // !admin

  deleteTenantClikced(value: any) {
    this.deleteButton.emit(value);
  }

  deleteUsersClikced(value: any) {
    this.deleteButton.emit(value);
  }

  deleteInviteUsersClicked(value: any) {
    this.deleteButton.emit(value);
  }

  deleteRolesClikced(value: any) {
    this.deleteButton.emit(value);
  }

  deleteOrganizationClikced(value: any) {
    this.deleteButton.emit(value);
  }

  ResendInviteUsersClicked(value: any) {
    this.resendInviteButton.emit(value);
  }

  redirectToUserPageToAssignRoles() {
    this.router.navigate(['users']);
  }

  ViewPermissionsClikced(value: any) {
    this.viewPermissionButton.emit(value);
  }
  @Output() viewPermissionButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() resendInviteButton: EventEmitter<any> = new EventEmitter<any>();

  isRolesUpdatePermission: boolean = false;
  isShownUpdateIcon: boolean = true;
  isRolesDeletePermission: boolean = true;
  isShownDeleteIcon: boolean = true;

  isTenantUpdatePermission: boolean = false;
  isShownUpdateIconForTenant: boolean = true;
  isTenantDeletePermission: boolean = false;
  isShownDeleteIconForTenant: boolean = true;

  isUsersUpdatePermission: boolean = true;
  isShownUpdateIconForUser: boolean = true;
  isUsersDeletePermission: boolean = true;
  isShownDeleteIconForUser: boolean = true;

  isItemUpdatePermission: boolean = false;
  isShownUpdateIconForItem: boolean = true;
  isItemDeletePermission: boolean = false;
  isShownDeleteIconForItem: boolean = true;

  isOrderUpdatePermission: boolean = false;
  isShownUpdateIconForOrder: boolean = true;
}
export interface insuranceface {
  // id: string;
  policy: string;
  name: string;
  policiesId: string;
  payorLevel: string;
}
export interface billingList {
  id: string;
  billingId: string;
  chartNo: string;
  insuranceName: string;
  suppliedDate: string;
  patientBalance: string;
  insuranceBalance: string;
  totalBalance: string;
  patientId: string;
  patientName: string;
  email: string;
  shippingLocation: string;
  createdDate: string;
  status: string;
  orderStatus: boolean;
}
