import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { DateAdapter, MatOption } from '@angular/material/core';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management/practice-management.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-report-practice-management',
  templateUrl: './report-practice-management.component.html',
  styleUrls: ['./report-practice-management.component.scss']
})
export class ReportPracticeManagementComponent implements OnInit {

  activeTab: string = 'TAT';
  isLoading: boolean;
  @ViewChild('txtDateValue') txtDateValue: MatDatepicker<Date>;
  reportForm: FormGroup;
  dateValue: string;
  selectedNode: string;
  selectedMonth: number;
  selectedYear: number;
  maxDate: Date;
  tableData$: Subscription;
  subscription$: Subscription[] = [];
  inboundTableData: any;
  physicianTableData: any;
  patientTableData: any;
  constructor(
    private title: Title, private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private inboundDocumentService: InboundDocumentService,
    private practiceManagementservice: PracticeManagementService,
    private commonService : CommonService
  ) {
    this.maxDate = this.dateAdapter.addCalendarYears(new Date(), 0);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Practice Management');
    this.dateValue =
      new Date().toLocaleString('default', { month: 'short' }) +
      '/' +
      new Date().getFullYear();

    this.selectedNode =
      new Date().getMonth() + 1 + '/' + new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();


    this.reportForm = this.formBuilder.group({
      txtDateValue: new FormControl(''),
      txtDate: new FormControl(),
    });
    this.reportForm.patchValue({
      txtDateValue: this.dateValue,
    });

    this.getInboundData(this.selectedMonth, this.selectedYear);
    // this.getPatientData(this.selectedMonth, this.selectedYear);

  }

  ngOnDestroy(): void {
    this.tableData$?.unsubscribe();
  }

  changeTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab === 'TAT') {
      this.getInboundData(this.selectedMonth, this.selectedYear);
    } else if (this.activeTab === 'Physicians') {
      this.getPhysiciansData(this.selectedMonth, this.selectedYear);
    } else if (this.activeTab === 'PatientDetails') {
      this.getPatientData(this.selectedMonth, this.selectedYear);
    }
  }

  openDatePicker() {
    this.txtDateValue.open();
  }
  change(event: boolean) {
    if (event == true) {
      // this.getActiveTableList();
    } else {
      // this.getTableList();
    }
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: any) {
    datepicker.close();
    this.dateValue =
      normalizedMonth.toLocaleString('default', { month: 'short' }) +
      '/' +
      normalizedMonth.getFullYear();
    this.selectedNode =
      normalizedMonth.getMonth() + 1 + '/' + normalizedMonth.getFullYear();
    this.selectedMonth = normalizedMonth.getMonth() + 1;
    this.selectedYear = normalizedMonth.getFullYear();
    // this.getInboundData(this.selectedMonth, this.selectedYear);
    // this.getPatientData(this.selectedMonth, this.selectedYear);
    // this.getPhysiciansData(this.selectedMonth, this.selectedYear);
    if (this.activeTab === 'TAT') {
      this.getInboundData(this.selectedMonth, this.selectedYear);
    } else if (this.activeTab === 'Physicians') {
      this.getPhysiciansData(this.selectedMonth, this.selectedYear);
    } else if (this.activeTab === 'PatientDetails') {
      this.getPatientData(this.selectedMonth, this.selectedYear);
    }
  }

  //GET Practice TAT Table Data
  getInboundData(selectedMonth: number, selectedYear: number) {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getInboundData = this.inboundDocumentService
      .getFaxReportByIMonthAndIYear(selectedMonth, selectedYear)
      .subscribe(
        (response) => {
          this.inboundTableData = response['items'];
          this.inboundTableData = this.inboundTableData.map((data) => {
            return {
              ...data,
              uploadedDate: this.commonService.getFormattedDateTimeZone(
                data.uploadedDate
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                data.lastModificationTime
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.inboundTableData = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getInboundData;

  }
  //GET Practice Physician Table Data
  getPhysiciansData(selectedMonth: number, selectedYear: number) {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getPhysicianData = this.practiceManagementservice
      .physiciansFaxReportByIMonthAndIYear(selectedMonth, selectedYear)
      .subscribe(
        (response) => {
          this.physicianTableData = response;
          this.physicianTableData = this.physicianTableData.map((data) => {
            return {
              ...data,
              lastSenddate: this.commonService.getFormattedDateTimeZone(
                data.lastSenddate
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.physicianTableData = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getPhysicianData;

  }

  //GET Patient Table Data
  getPatientData(selectedMonth: number, selectedYear: number) {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getPatientData = this.practiceManagementservice
      .patientFaxReportByIMonthAndIYear(selectedMonth, selectedYear)
      .subscribe(
        (response) => {
          this.patientTableData = response;
          this.patientTableData = this.patientTableData.map((data) => {
            return {
              ...data,
              lastModificationTime: this.commonService.getFormattedDate(
                data.lastModificationTime
              ),
              lastSenddate: this.commonService.getFormattedDateTimeZone(
                data.lastSenddate
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.patientTableData = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getPatientData;

  }

}
