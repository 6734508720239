import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { PatientNotesService } from '../../patient-proxy/patient-optimization/patient-notes.service';
import { PatientVerificationService } from '../../patient-proxy/patient-optimization/patient-verification.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

import { AddNoteTabComponent } from '../../add-note-tab/add-note-tab.component';
import { PatientAddPolicyTabComponent } from '../../patient-add-policy-tab/patient-add-policy-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VerificationStatus, VerificationStatusV1 } from '../../patient-proxy/patient/enumeration-data/verification-status.enum';
import { InsuranceTypeV1 } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/insurance-type.enum';
import { PatientEligibilityHistoryComponent } from '../../patient-eligibility-history/patient-eligibility-history.component';
import { PatientEligibilityListTabComponent } from '../../patient-eligibility-list-tab/patient-eligibility-list-tab.component';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-myverficationtable',
  templateUrl: './myverficationtable.component.html',
  styleUrls: ['./myverficationtable.component.scss'],
})
export class MyverficationtableComponent implements OnInit {
  @Input() tableData: any;
  @Output() isLoadTbl? = new EventEmitter<boolean>();
  tblItemSelectedRowId: string;
  InsuranceType=InsuranceTypeV1;
  dtMyVerficationListOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1, -1], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
      { targets: [10,11,12,13], visible: false },
      { type: 'date', targets: [10] },
      // {
      //   targets: [10],
      //   type: 'date',
      //   render: function (data, type, row) {
      //     // Assuming data is in the format MM/DD/YYYY
      //     if (type === 'sort' || type === 'type') {
      //       var dateParts = data.split('/');
      //       return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      //     } else {
      //       return data;
      //     }
      //   },
      // },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'My Verification',
        exportOptions: {
          columns: ':visible:not(:nth-child(1))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  selectedRow: string;
  defaultGuid = defaultGuid;
  chartNo: string;
  patientId: string;
  selectedPatient: boolean = false;
  isNewPatient: boolean = false;
  isShowSpinner: boolean;
  isLoading: boolean;
  dataSource: MatTableDataSource<any>;
  dtvOptions: any;
  tenantId: string = '';
  statusId: string;
  policyId: string;
  docId: string;
  public drpBranch: Branch[] = [];
  branchForm: FormGroup;
  organizationUnitName: string;
  // myverification: InboundListDTO[] = [];
  myverification: any;
  VerificationStatus=VerificationStatus;
  VerificationStatusV1=VerificationStatusV1;
  myTable: DataTables.Api;

  subscription$: Subscription[] = [];
  constructor(
    public dialog: MatDialog,
    public PatientNotesService: PatientNotesService,
    private toastr: ToastrService,
    public PatientVerificationService: PatientVerificationService,private snackBar: MatSnackBar,
    private datepipe:DatePipe // private title: Title
  ) {}

  ngOnInit() {
    this.myverification = this.tableData;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);

    this.dtMyVerficationListOptions.buttons[0].filename =
    'My Verification' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });

    window.removeEventListener('resize', this.calculateScrollY);
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtMyVerficationListOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  isEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  tblItemSelectedRow(
    patientId: string,
    chartNo: string,
    policyId: string,
    docId: string
  ) {
    this.patientId = patientId;
    this.selectedPatient = true;
    this.chartNo = chartNo;
    this.policyId = policyId;
    this.selectedRow = this.docId = docId;
    this.tblItemSelectedRowId = patientId;
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: this.patientId,
        defaultFaxId: this.chartNo,
        isCallNote: false,
        isPending: false,
        docId: defaultGuid,
        chartNo: this.chartNo,
        // inboundStatusShortCodeId: defaultGuid,
        // selectedNode: defaultGuid,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  openPolicyModal() {
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      maxWidth: '80%',
      minHeight: '60%',
      disableClose: true,
      data: {
        patientId: this.patientId,
        // wipId: this.wipId ?? defaultGuid,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      (result) => {
        // this.patientId !== defaultGuid && this.getTableData();
        // this.patientIdEmitter.next(this.patientId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  // openViewNotesPopup() {
  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     data: {
  //       patientId: this.patientId,
  //       pageType: 'Patient Note',
  //       isAddVisible: false,
  //     },
  //   };
  //   const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

  //   dialogRef.afterClosed().subscribe(
  //     () => {
  //       //this.getReminderTableData();
  //     },
  //     (err: HttpErrorResponse) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  // }

  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
        chartNo: this.chartNo,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  eligibilityHistory(patientId:string,policyId:string,verificationStatus:string){
    const dialogRef = this.dialog.open(PatientEligibilityListTabComponent, {
      disableClose: true,
      data: {
        patientId:patientId,
        policyId:policyId,
        verificationStatus: verificationStatus,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
    });
    this.subscription$.push(closeDialog);
  }
  cancelVerificationStatus(verificationId:string){

    const verificationCancel =
    this.PatientVerificationService.cancelVerificationStatus(
      verificationId,VerificationStatusV1.Cancelled
    ).subscribe(
      (response) => {
        this.toastr.success('Verification Cancelled Successfully','Success')
        this.isLoadTbl.emit(true);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(err,'Fail')
      }
    );
  }
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
