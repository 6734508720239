import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UploadedFileStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { MasterProviderDTO } from 'projects/inventory/src/app/inventory-provider/inventory-provider.component';
import { BillingDropdowns, PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { BillingMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { BillingMasterDropdownService, PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { Status } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { claimProcessMethod } from '../billing-proxy/billing-management/billing/claim-process-method.enum';
import { CreateUpdateInsurancePaymentDTO, UploadEobBlobDTO } from '../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto';
import { ZermidClearingHouseService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import { InsurancePaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/insurance-payment.service';
import { ItemPaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { PostingStatus } from '../manual-posting-history/manual-posting-history.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-add-insurance-payment',
  templateUrl: './add-insurance-payment.component.html',
  styleUrls: ['./add-insurance-payment.component.scss']
})
export class AddInsurancePaymentComponent implements OnInit, OnDestroy {
  public paymentForm: FormGroup;
  itemPaymentTitle: "Add Payment" | "Edit Payment" = "Add Payment";
  subscription$: Subscription[] = [];

  strInsPaymentId: string = '';
  @ViewChild('uploadFileReference', { static: false }) uploadFileReference: ElementRef;
  drpCode: any[] = [];
  drpPayment: any[] = [];
  drpReason: any[] = [];
  selFileNameList: { name: string; type: string; }[] = [];
  strClaimId: string = '';
  strClaimNumber: string = '';
  strProviderId: string = '';
  selFileEOBResultValue: string = '';
  selFile835ResultValue: string = '';
  strInsurance: string = '';
  strSource: string = '';
  strCode: string = '';
  strReason: string = '';
  strType: string = '';
  drpInsurance: PayorLevelDTO[] = [];
  drpProviders: MasterProviderDTO[] = [];
  isLoading: boolean = false;
  postingStatus: string = "";

  constructor(
    private fb: FormBuilder,
    private insurancePaymentService: InsurancePaymentService,
    private masterProviderService: MasterProviderService,
    private patientMasterDropdownService: PatientMasterDropdownService,
    private itemPaymentService: ItemPaymentService,
    private billingDropdownService: BillingMasterDropdownService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { claimId: string, insurancePayment: string },
    private router: Router,
    private zermidClearingHouse: ZermidClearingHouseService,
    private matDialogRef: MatDialog,
    private toastr: ToastrService,

  ) { }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  ngOnInit(): void {
    this.strClaimId = this.dialogData?.claimId ?? "";
    this.strInsPaymentId = this.dialogData?.insurancePayment ?? "";
    this.paymentForm = this.fb.group({
      drpPlan: new FormControl("", Validators.required),
      txtCheckNo: new FormControl("", Validators.required),
      txtCheckDate: new FormControl("", Validators.required),
      txtDescription: new FormControl(""),
      documentFile: new FormControl(null),
    })

    this.getDropdown();
  }

  //! Get Insurance payment details by id
  getPaymentDetails() {
    const getPaymentDetails: Subscription = this.insurancePaymentService.get(this.strInsPaymentId).subscribe(response => {
      this.itemPaymentTitle = "Edit Payment";
      this.paymentForm.patchValue({
        drpPlan: response?.plan ?? "",
        txtCheckNo: response?.checkNo ?? "",
        txtCheckDate: response?.checkDate ?? "",
        txtDescription: response?.notes ?? "",
      })
      this.selFileNameList = [{ name: response?.eobBlobName, type: "PDF" }, { name: response?.ediBlobName, type: "Text" }];
      this.postingStatus = response?.postingStatus ?? "";
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$?.push(getPaymentDetails);

  }

  //! get dropdowns data API fun 
  getDropdown() {
    this.patientMasterDropdownService
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Codes,
      BillingDropdowns.PaymentTypes,
      BillingDropdowns.Reasons
    ]
    const getbillingDropdowns: Subscription = this.billingDropdownService.getBillingDropdownsByInput(billingDropdownInput).subscribe((stateResponse) => {
      let response: BillingMasterDropdownDTO = stateResponse;
      this.drpCode = response?.codes;
      this.drpPayment = response?.paymentTypes;
      this.drpReason = response?.reasons;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$?.push(getbillingDropdowns);

    const getProviderDropdowns: Subscription = this.masterProviderService.getList(new PagedAndSortedResultRequestDto).pipe(map(e => e?.items)).subscribe(response => {
      this.drpProviders = response ?? [];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$?.push(getProviderDropdowns);

    const input: PatientDropdowns[] = [PatientDropdowns.PayorLevels];
    const getpatientDropdowns: Subscription = this.patientMasterDropdownService.getPatientDropdownsByInput(input).subscribe(response => {
      this.drpInsurance = response?.payorLevels ?? [];
      this.strInsPaymentId && this.getPaymentDetails();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$?.push(getpatientDropdowns);

  }

  savePayments() {
    this.isLoading = true;
    const clmform = this.paymentForm.value;
    const input: CreateUpdateInsurancePaymentDTO = {
      claimId: this.strClaimId,
      claimNumber: this.strClaimNumber,
      //      statusCode: clmform?.drpPlan,
      notes: clmform?.txtDescription,
      checkNo: clmform?.txtCheckNo,
      checkDate: clmform?.txtCheckDate,
      // payer: clmform?.drpPlan,
      // payee: this.strProviderId,
      // amount: clmform?.drpPlan,
      plan: clmform?.drpPlan,
      // paymentType: "ClearingHouse",
      // reason: clmform?.drpPlan,
      // icn: clmform?.drpPlan,
      // status: clmform?.drpPlan,
      eobFileContentAsBase64String: this.selFileEOBResultValue,
      ediFileContentAsBase64String: this.selFile835ResultValue,
      eobBlobName: this.selFileNameList[0]?.name ?? "",
      ediBlobName: this.selFileNameList[1]?.name ?? "",
      totalAllowed: 0,
      // totalDeduct: 0,
      totalCoInsurance: 0,
      totalWriteOff: 0,
      totalInsurancePaid: 0,
      // totalInsBal: 0,
      // totalPatientBal: 0,
      // isCompleted: ((clmform?.iscompleted === true) ? 1 : 0),
      postingStatus: this.postingStatus === "" ? PostingStatus.Pending : this.postingStatus,
      totalCurrentBalance: "",
      totalBilled: "",
      lstItemPayments: [],
    }
    if (this.strInsPaymentId !== '' && this.uploadFileReference.nativeElement.value === '') {
      this.insurancePaymentService.update(this.strInsPaymentId, input).subscribe(response => {
        this.strInsPaymentId = response?.id ?? "";
        this.isLoading = false;
        this.matDialogRef.closeAll();
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
      }, err => {
        this.isLoading = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })

    } else if (this.strInsPaymentId === '') {
      this.insurancePaymentService.create(input).subscribe(response => {
        this.strInsPaymentId = response?.id ?? "";
        this.isLoading = false;
        this.matDialogRef.closeAll();

        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
      }, err => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    } else {
      const input: UploadEobBlobDTO = {
        insurancePaymentId: this.strInsPaymentId,
        eobFileContentAsBase64String: this.selFileEOBResultValue
      }
      this.insurancePaymentService.updateEOBByInsurancePaymentIdAndEob(input).subscribe(response => {
        this.isLoading = false;
        this.matDialogRef.closeAll();
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Updated Successfully','Success')
      }, err => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }


  //! Read From File. and upload to Backend For EDI parsing
  eftImportFormFile(value?: any) {
    try {
      const fileList: FileList = value;
      let file835: File = null;
      let fileEOB: File = null;
      this.selFileNameList = [];
      if (!(fileList?.length === 1 || fileList?.length === 2)) {
        return
      }
      for (let index = 0; index < fileList?.length; index++) {
        if (fileList[index]?.type === "" || fileList[index]?.type === "text/plain") {
          file835 = fileList?.item(index);
          this.selFileNameList.push({ name: fileList[index]?.name, type: "Text" });
        };
        if (fileList[index]?.type === DocumentMaxandTypeLimits.documentTypeLimit) {
          fileEOB = fileList?.item(index);
          this.selFileNameList.push({ name: fileList[index]?.name, type: "PDF" });
        };
      }
      if (!fileEOB) {
        Swal.fire({ icon: 'info', text: "Please Select Remits PDF File" });
        this.uploadFileReference.nativeElement.value = null;
        return;
      }
      let file835ResultValue: string = "";
      let fileEOBResultValue: string = "";
      let reader = new FileReader();

      if (!!fileEOB) {
        reader?.readAsDataURL(fileEOB);
        reader.onload = () => {
          fileEOBResultValue = reader?.result?.slice(28)?.toString() ?? "";
          if (fileEOBResultValue && fileEOBResultValue !== "") {
            this.selFileEOBResultValue = fileEOBResultValue ?? "";
          }
          if (!!file835) {
            reader?.readAsDataURL(file835);
            reader.onload = () => {
              file835ResultValue = ((file835.type === '') ? (reader?.result?.slice(37)?.toString()) : ((file835.type === "text/plain") ? (reader?.result?.slice(23)?.toString()) : ""));
              if (file835ResultValue && file835ResultValue !== "") {
                this.selFile835ResultValue = file835ResultValue ?? "";
              }
            }
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
  //! download EOB PDF FIle

  downloadEOBFile(files?: { name?: string, type?: string }) {
    if (files?.name && typeof files?.name === 'string') {
      const downloadEOBFile: Subscription = this.zermidClearingHouse.downloadEOBByBlobName(files?.name).subscribe((response: string) => {
        if (typeof response === 'string' && response && response?.length !== 0) {
          let filelocation: String = String('data:application/pdf;base64,' + response);
          let link = document.createElement('a');
          link.download = files?.name;
          link.href = filelocation.toString();
          link.click();
        }
      }, (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$?.push(downloadEOBFile);
    }
  }
  // clear dropdown

  clearInsurance() { this.strInsurance = ''; this.paymentForm.patchValue({ drpPlan: "" }) }
  clearSource() { this.strSource = ''; }
  clearCode() { this.strCode = ''; }
  clearReason() { this.strReason = ''; }
  clearType() { this.strType = ''; }
}
