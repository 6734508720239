import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2';
import { ClaimSearchService } from '../billing-proxy/billing-management/posting/claim-search.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-claim-denial-reports',
  templateUrl: './claim-denial-reports.component.html',
  styleUrls: ['./claim-denial-reports.component.scss'],
  providers: [DatePipe],
})
export class ClaimDenialReportsComponent implements OnInit {
  el: ElementRef;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public arrDisplayedColumns: string[] = [];
  isLoading: boolean = true;
  scheduledDate: Date;
  denialForm: FormGroup;
  urlPath: string = "";
  constructor(
    @Inject(ElementRef) el: ElementRef,
    private fb: FormBuilder,
    private title: Title,
    private claimSearchService: ClaimSearchService,
    private datePipe: DatePipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    this.el = el;
  }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Denial Reports");
    this.arrDisplayedColumns = ['patientId', 'name', 'claimId', 'NDate', 'notification', 'CreatedOn'];
    this.denialForm = this.fb.group({
      txtFromDate: new FormControl("", Validators.required),
      txtToDate: new FormControl('', Validators.required),
      rbtnReportType: new FormControl('')
    })
    this.getTableData();
    this.activatedRoute.url.subscribe((response) => {

      response.forEach(element => {
        this.urlPath = element?.path;
        if (this.urlPath == "All") {
          const currentYear = new Date().getFullYear();
          const firstDay = new Date(currentYear, 0, 1);
          const lastDay = new Date(currentYear, 11, 31);
          this.denialForm.patchValue({
            txtFromDate: firstDay,
            txtToDate: lastDay
          });
          this.getTableDataBasedOnDates();
        }
        else {

          const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
          const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
          this.denialForm.patchValue({
            txtFromDate: firstDay,
            txtToDate: lastDay
          });
          this.getTableDataBasedOnDates();
        }
      })
    })
    this.scheduledDate = new Date(this.denialForm.value.txtFromDate);
  }

  /// get table list based on from and to dates
  getTableDataBasedOnDates() {
    this.isLoading = true;
    let txtUplaodedFromDate: string = ((this.denialForm?.value?.txtFromDate ?? null) === "") ? null : (new Date(this.denialForm?.value?.txtFromDate)?.toLocaleDateString("en-US")) ?? null;
    let txtUplaodedToDate: string = ((this.denialForm?.value?.txtToDate ?? null) === "") ? null : (new Date(this.denialForm?.value?.txtToDate)?.toLocaleDateString("en-US")) ?? null;

    let dtStartDate = this.datePipe.transform(txtUplaodedFromDate, "yyyy-MM-dd");
    let dtEndDate = this.datePipe.transform(txtUplaodedToDate, "yyyy-MM-dd");
    let claimStatus = this.denialForm.value.rbtnReportType === "" ? 'Denied' : this.denialForm.value.rbtnReportType
    this.claimSearchService.searchClaimByStatusByDtStartDateAndDtEndDateAndInput(dtStartDate, dtEndDate, claimStatus).subscribe(response => {
      this.arrTableData = [];
      response?.items?.forEach(element => {
        let arrDuedate = element?.lstReminders[element?.lstReminders.length - 1]?.dueDate;
        this.arrTableData.push({
          claimId: element?.claimId,
          claimProcessMethod: element?.claimProcessMethod,
          claimStatus: element?.claimStatus,
          creationTime: element?.creationTime,
          defaultPatientId: element?.defaultPatientId,
          id: element?.id,
          orderId: element?.orderId,
          patientDob: element?.patientDob,
          patientId: element?.patientId,
          patientName: element?.patientName,
          saleorderId: element?.saleorderId,
          dueDate: arrDuedate,
          notifyStatus: element?.lstReminders.length != 0 ? 'Set Reminder' : 'Not Set Reminder'
        })
      });
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  /// export list Claim-Danial
  exportDataToExcel() {
    let exportData: any[] = [];
    this.arrTableData?.forEach(ele => {
      exportData.push({
        patientId: ele?.defaultPatientId,
        patientName: ele?.patientName,
        claimId: ele?.claimId,
        notificationDate: ele?.dueDate,
        notificationStatus: ele?.notifyStatus,
        createdDate: ele?.creationTime
      })
    })

    this.claimSearchService.exportReportByExportDatas(exportData).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Claim-Danial") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  //// denial year wise report
  denialYearReport() {
    this.router.navigate(['denial-year-report'])

  }
  /// onchange value get for api call
  onChangeGetValue(event) {
    if (event?.value == 'Denied') {
      const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
      this.denialForm.patchValue({
        txtFromDate: firstDay,
        txtToDate: lastDay
      });
      this.scheduledDate = new Date(this.denialForm.value.txtFromDate);
      this.getTableData()
    }
    if (event?.value == 'ClaimProcessed') {
      const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
      this.denialForm.patchValue({
        txtFromDate: firstDay,
        txtToDate: lastDay
      });
      this.scheduledDate = new Date(this.denialForm.value.txtFromDate);
      this.getTableData()
    }
  }

  /// to get table datas
  arrTableData: any[] = [];
  getTableData() {
    this.isLoading = true;
    let dtStartDate: null;
    let dtEndDate: null;
    let claimStatus = this.denialForm.value.rbtnReportType === "" ? 'Denied' : this.denialForm.value.rbtnReportType
    this.claimSearchService.searchClaimByStatusByDtStartDateAndDtEndDateAndInput(dtStartDate, dtEndDate, claimStatus).subscribe(response => {
      this.arrTableData = [];
      response?.items?.forEach(element => {
        let arrDuedate = element?.lstReminders[element?.lstReminders.length - 1]?.dueDate;
        this.arrTableData.push({
          claimId: element?.claimId,
          claimProcessMethod: element?.claimProcessMethod,
          claimStatus: element?.claimStatus,
          creationTime: element?.creationTime,
          defaultPatientId: element?.defaultPatientId,
          id: element?.id,
          orderId: element?.orderId,
          patientDob: element?.patientDob,
          patientId: element?.patientId,
          patientName: element?.patientName,
          saleorderId: element?.saleorderId,
          dueDate: arrDuedate,
          notifyStatus: element?.lstReminders.length != 0 ? 'Set Reminder' : 'Not Set Reminder'
        })
      });
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


  /// reset form
  resetForm() {
    this.denialForm.reset();
    this.denialForm.updateValueAndValidity();
    this.denialForm.markAsUntouched();
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
    this.denialForm.patchValue({
      txtFromDate: firstDay,
      txtToDate: lastDay,
      rbtnReportType: 'Denied'
    });
    this.scheduledDate = new Date(this.denialForm.value.txtFromDate);
    this.getTableData()
  }
}

function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}
