import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { ClaimBatchService } from '../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-batch.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ClaimViewTabComponent } from '../../claim-view-tab/claim-view-tab.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadEOBComponent } from '../../claim-status-list/claim-status-list-table/upload-eob/upload-eob.component';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-claim-batch-table',
  templateUrl: './claim-batch-table.component.html',
  styleUrls: ['./claim-batch-table.component.scss'],
})
export class ClaimBatchTableComponent implements OnInit {
  @Input() DataList: any[];
  dtClaimBatchOptions: any = {
    responsive: true,
    paging: false,
    order:[],
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '250px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
 { targets: 0, orderable: false }, // Targets the first column (index 0)
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Claim Batch',
        exportOptions: {
          columns: ':visible:not(:nth-child(0))',
        },
       },
      {
        extend: 'colvis',
        // columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        columns: ':not(:eq(0), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          // dt.columns(':not(:lt(3),:last-child)').visible(false);
          dt.columns(':not(:lt(1),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  dtClaimBatchChildOptions: any = {
    responsive: true,
    paging: false,
    dom: 'lrtip',
    order:[],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true,
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  isLoading: any;
  defaultGuid = defaultGuid;
  selectedRow: string = '';
  subscription$: Subscription[] = [];
  DataListChild: any = [];
  constructor(private snackBar: MatSnackBar,
    private claimBatchService: ClaimBatchService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  ngOnInit() {

    this.dtClaimBatchOptions.buttons[0].filename =
    'Claim Batch' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    window.removeEventListener('resize', this.calculateScrollY);
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtClaimBatchOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  tblItemSelectedRow(data: any) {
    if(this.selectedRow !== data.claimBatchId){
      this.selectedRow = data.claimBatchId;
      this.isLoading = true;
      this.LoadChildTable(data);
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  LoadChildTable(data: any) {
    this.isLoading=true;
    const status = this.claimBatchService
    .claimBatchDetailsId(data.claimBatchId).subscribe(
      (response) => {
        this.DataListChild = response || [];
        this.DataListChild = this.DataListChild.map((data) => {
          return {
            ...data,
            orderDate: this.commonService.getFormattedDateTimeZone(
              data.orderDate
            ),
            claimProcessDate: this.commonService.getFormattedDateTimeZone(
              data.claimProcessDate
            ),
          };
});
        this.isLoading = false;
      },
      (err) => {
         this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
//Open the Claim Form modal popup
  openClaimForm(
          orderId: string,
          patientId: string,
          defaultSaleOrderId: string,
          claimId: string
        ) {
          const dialogRef = this.dialog.open(ClaimViewTabComponent, {
            disableClose: true,
            data: {
              orderId: orderId,
              patientId: patientId,
              defaultSaleOrderId: defaultSaleOrderId,
              claimId : claimId,
              isClaimBatch:true,
            },
          });

          const closeDialog = dialogRef.afterClosed().subscribe(
            (claimDetails: any) => {

            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        }
        // openEOBModel() {
        //   let isSaveEnable: boolean = true;
        //   let cliamData: any;
        //   this.dialog.open(UploadEOBComponent, {
        //     width: '100vw',
        //     height: '100vh',
        //     maxWidth: '100vw',
        //     maxHeight: '100vh',
        //     panelClass: 'fullscreen-dialog-panel',
        //     disableClose: true,
        //     data: {
        //       cliamData: cliamData,
        //       isSaveEnable: isSaveEnable,
        //     },
        //   });
        //   this.dialog.afterAllClosed.subscribe((resposne) => {});
        // }
        uploadEOB(cliamData: any, isSaveEnable: boolean) {
          const dialogRef =  this.dialog.open(UploadEOBComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: 'fullscreen-dialog-panel',
            disableClose: true,
            data: {
              cliamData: cliamData,
              isSaveEnable: isSaveEnable,
            },
          });
          // this.dialog.afterAllClosed.subscribe((resposne) => {

          // });

          const closeDialog = dialogRef.afterClosed().subscribe(
            (isSaved: any) => {
              if(isSaved){
              }
            },
            (err) => {
              const data: HttpErrorResponse = err;
              console.warn(data?.error?.error?.message)
            }
          );
        }
}
