<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">
          <b>
            <ng-container *ngIf="itemId === ''; then thenTitleTemplate; else elseTitleTemplate"></ng-container>
            <ng-template #thenTitleTemplate> Add Itemssssssssss </ng-template>
            <ng-template #elseTitleTemplate> Edit Item </ng-template>
          </b>
        </h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <li class="breadcrumb-item ">Inventory</li>
            <li class="breadcrumb-item ">Item</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-12" style="margin-top: -19px;">
  <div class="card card-body">

    <form [formGroup]="itemForm">

      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-4">
            <mat-label>Item Id / HCPC Code<span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtItemId" matInput maxlength="40" formControlName="txtItemId" placeholder="Enter Item Id" type="text" />
            <mat-error *ngIf="
                        itemForm?.get('txtItemId')?.touched &&
                        itemForm?.get('txtItemId')?.errors?.required
                      ">
              Item Id is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-4">
            <mat-label>Item Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtItemName" matInput formControlName="txtItemName" placeholder="Enter Item Name" type="text" />
            <mat-error *ngIf="
                        itemForm?.get('txtItemName')?.touched &&
                        itemForm?.get('txtItemName')?.errors?.required
                      ">
              Item Name is a required field!
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="col-3">
                    <mat-label>Price <span class="asterisk">*</span></mat-label>
                    <input matInput formControlName="txtPrice" placeholder="Enter Price" maxlength="15" prefix="$"
                      mask="0000.00" type="text">

                    <mat-error *ngIf="
                    itemForm?.get('txtPrice')?.touched &&
                    itemForm?.get('txtPrice')?.errors
                    ">
                      Price is a required field!
                    </mat-error>
                    <mat-error *ngIf="itemForm?.get('txtPrice')?.touched && itemForm?.get('txtPrice')?.errors">Enter Minimum
                      6 Digits!</mat-error>
                  </mat-form-field> -->
          <mat-form-field class="col-4">
            <mat-label>Priceeee <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtPrice" matInput maxlength="5" formControlName="txtPrice" ngModel pattern="[+-]?([0-9]*[.])?[0-9]+
            " type="text" />
            <mat-error *ngIf="
                        itemForm?.get('txtPrice')?.touched &&
                        itemForm?.get('txtPrice')?.errors?.required
                      ">
              Price is a required field!
            </mat-error>
            <!-- <mat-error *ngIf="
                        itemForm?.get('txtPrice')?.touched && itemForm?.get('txtPrice')?.errors?.pattern
                      ">
              Enter only numbers!</mat-error> -->
          </mat-form-field>
          <!-- <div class="col-3 required">
                    <mat-label> Insurance <span class="asterisk">*</span></mat-label>
                    <ng-select formControlName="drpInsurance" maxlength="40">
                      <ng-option *ngFor="let insurance of drpInsurance" [value]="insurance.shortCodeId">
                        {{ insurance.itemInsuranceType }}</ng-option>
                    </ng-select>
                    <mat-error *ngIf="
                  itemForm?.get('drpInsurance')?.touched &&
                  (itemForm?.get('drpInsurance').value === null || itemForm?.get('drpInsurance').value === '')">
                      Insurance is a required field!
                    </mat-error>
                  </div> -->
          <mat-form-field class="col-4">
            <mat-label>Insurance<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpInsurance" [(value)]="txtInsurance" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                  formControlName="txtInsSearchFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let insurance of filteredInsTypes | async " [value]="insurance.shortCodeId">
                {{ insurance.itemInsuranceType }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                    itemForm?.get('drpInsurance')?.touched &&
                    itemForm?.get('drpInsurance').value === null">
              Insurance is a required field!
            </mat-error>
            <button mat-button (click)="txtInsurance=undefined;$event.stopPropagation()" *ngIf="txtInsurance" matSuffix
              (click)="clearInsType()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>

          </mat-form-field>
          <mat-form-field class="col-4">
            <mat-label>Branch <span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" formControlName="drpLocation" [(value)]="location">
              <mat-option *ngFor="let location of drpLocation" [value]="location.organizationUnitName">
                {{ location.organizationUnitName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="location = undefined; $event.stopPropagation()" *ngIf="location"
              (click)="clearLocation()" matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="
                        itemForm?.get('drpLocation')?.touched &&
                        itemForm?.get('drpLocation')?.errors?.required
                      ">
              Location is a required field!
            </mat-error>
          </mat-form-field>

        </div>
      </mat-card-content>

    </form>
    <div class="row">
      <div class="col-12 text-lg-center">
        <button *ngIf='isShownSaveButton' mat-button class="buttonColor mb-2 mr-2"
          [disabled]="!(!itemForm.invalid && itemForm.dirty)" (click)="saveItem()">
          Save
        </button>
        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetItem()">
          Reset
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf='isShownItemTable' class="col-12 table-responsive">
  <!-- <div [hidden]="!isLoading"
    style="display: flex; justify-content: center; align-items: center; background: transparent">
    <mat-progress-bar mode="indeterminate" value="66" diameter="45" class="lightseagreen">
    </mat-progress-bar>
  </div> -->
  <!-- <app-table [hidden]="isLoading" [strPageType]="strPageType" (deleteButton)="deleteItems($event)"
      (viewButton)="ViewItem($event)"></app-table> -->

  <div class="card-body">
    <table table mat-table matSort [dataSource]="dataSource">
      <form style="display: flex" [formGroup]="itemSearchForm">
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            S.No
          </th>
          <td mat-cell *matCellDef="let data; let i = index">
            {{ i+1 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ItemId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <mat-form-field class="filter">
              <input autocomplete="new-itemIdSearch" matInput formControlName="itemIdSearch">
              <mat-placeholder>Item Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.equipmentBillingCode }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="itemName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <mat-form-field class="filter">
              <input autocomplete="new-itemNameSearch" matInput formControlName="itemNameSearch">
              <mat-placeholder>Item Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.itemName }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <mat-form-field class="filter">
              <input autocomplete="new-priceSearch" matInput formControlName="priceSearch">
              <mat-placeholder>Price</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.price }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="Options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Options
          </th>
          <td mat-cell *matCellDef="let data">
            <a *ngIf='isShownUpdateIconForItem' class="editBtnColor" (click)="ViewItem(data)">
              <i class="fa fa-pencil pointer" matTooltip="Click To Edit" ></i>
            </a> &nbsp;
            <a *ngIf='isShownDeleteIconForItem' class="deleteBtnColor" (click)="deleteItem(data.id)">
              <i class="fa fa-trash pointer" matTooltip="Click To Delete" ></i>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
            <div *ngIf="!isLoading">
              {{"No Records Found"}}
            </div>
            <div [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>

      </form>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="['noRecords']"
        [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>

    </table>
    <mat-paginator #MatPaginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="10">
    </mat-paginator>
  </div>
</div>
