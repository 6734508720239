import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PracticeManagementService } from '../patient-proxy/practice-management';
import { PracticePhysiciansComponent } from '../practice-physicians/practice-physicians.component';

@Component({
  selector: 'app-practice-add-doctor',
  templateUrl: './practice-add-doctor.component.html',
  styleUrls: ['./practice-add-doctor.component.scss']
})
export class PracticeAddDoctorComponent implements OnInit, OnDestroy {
  AddDoctorSearchForm: FormGroup;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  displayedColumns: string[] = ['firstName', 'lastName', 'npi'];
  isLoading: boolean = false;
  practiceId: string = '';
  subscription$: Subscription[] = [];
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private PracticeManagementService: PracticeManagementService,
  ) { }

  ngOnInit(): void {
    this.AddDoctorSearchForm = this.formBuilder.group({
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      portNumber: new FormControl(""),
      txtFirstName: new FormControl(""),
      txtLastName: new FormControl(""),
    });

    const activateRoute = this.activatedRoute.paramMap.subscribe((response) => {
      this.practiceId = response.get('practiceId') ?? '';
    });
    this.subscription$.push(activateRoute);
    this.getPhysicianTableData();
    // this.getTable();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //! Doctor Modal Open Functions
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.dialog.open(PracticePhysiciansComponent, {
      disableClose: true,
      data: { doctorId: doctorId, practiceId: this.practiceId }
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getPhysicianTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog)
  }

  ViewMaster(data) {

  }

  deleteMaster(data) {

  }

  // getTable() {
  //   this.PracticeManagementService.get(this.practiceId).subscribe(resposne => {
  //   })
  // }

  physicianTableData: any[] = [];
  getPhysicianTableData() {
   const getTable = this.PracticeManagementService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.physicianTableData = [];
      response?.items?.forEach(element => {
        element?.physicians?.forEach(value => {
          this.physicianTableData.push({
            firstName: value?.firstName,
            lastName: value?.lastName,
            npi: value?.npi,
            id: value?.physicianId
          })
        })
      })
      this.dataSource = new MatTableDataSource(this.physicianTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    })
    this.subscription$.push(getTable);
  }

}
