<!-- Header Section -->
<div class="customThemeClass mr-4">
  <div class="row" *ngIf="!isReportLoading">
      <div class="col-md-12">
          <mat-radio-group [(ngModel)]="selectedOption" (change)="getPARFilterListChangeTab()">
              <mat-radio-button class="radiobuttoncls pl-3" [value]="1"><b><span class="fontresize">All</span></b></mat-radio-button>
              <mat-radio-button class="radiobuttoncls" [value]="2"><b><span class="fontresize">Expired</span></b></mat-radio-button>
              <mat-radio-button class="radiobuttoncls" [value]="3"><b><span class="fontresize">Yet To Expire</span></b></mat-radio-button>
          </mat-radio-group>

      </div>
  </div>
</div>

<!-- Table Section -->
<div class="col-lg-12">
  <div class="card card-body">
      <!-- Loader Section -->
      <div class="row" *ngIf="isReportLoading">
          <div class="col-12">
              <div class="d-flex justify-content-center">
                  <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
              </div>
          </div>
      </div>

      <div class="table-responsive" *ngIf="!isReportLoading">
          <app-patient-par-report-table  (nevigatePage)="changePage($event)" [tableData]="parreportTableData" [currentPage]="currentPage" [totalPages]="totalPages"
          [paginationInfo]="paginationInfo"></app-patient-par-report-table>
      </div>
  </div>
</div>
