import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CreateUpdateProductHcpcCodeDTO } from '../../item-proxy/item-management/optimization/dto';
import { Status } from '../../item-proxy/item-management/optimization';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';

@Component({
  selector: 'app-product-hcpc-code',
  templateUrl: './product-hcpc-code.component.html',
  styleUrls: ['./product-hcpc-code.component.scss']
})
export class ProductHCPCCodeComponent implements OnInit {

  hcpcForm: FormGroup;
  ltHCPC: CreateUpdateProductHcpcCodeDTO[] = [];
  hcpcDuplicateValidation: boolean = false;
  isAddHCPC: boolean = false;
  constructor(
    private toaster: ToastrService,
    private fb: FormBuilder,
    private communicationService: CommunicationService,
    public hcpcDialogRef: MatDialogRef<ProductHCPCCodeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productdDpId: string;
      productHCPCList: any;
    } = {
        productdDpId: defaultGuid,
        productHCPCList: [],
      },

  ) { }

  ngOnInit(): void {
    this.hcpcForm = this.fb.group({
      txtHCPCCode: new FormControl(''),
    });
    this.ltHCPC = this.data?.productHCPCList;
  }


  // Add the HCPC Codes to the Table
  addHCPC() {
    if (
      this.hcpcForm?.value?.txtHCPCCode?.trim() === '' ||
      this.hcpcForm?.value?.txtHCPCCode?.trim() === null ||
      this.hcpcForm?.value?.txtHCPCCode?.trim() === undefined
    ) {
      this.toaster.error('Enter Valid HCPC Code!');
    } else if (!this.validateHCPC()) {
      const value = this.hcpcForm.value.txtHCPCCode.trim();
      this.ltHCPC.push({
        hcpcCode: value,
        productId: this.data?.productdDpId,
        status: Status.Active,
        line: 0,
      });
      this.hcpcForm.patchValue({
        txtHCPCCode: '',
      });
      this.isAddHCPC = true;
      this.communicationService.triggerAddHCPCMethodCallMethodCall(value);
    } else {
      this.toaster.error('Code Already Exists!');
    }
  }

//Check Validation for duplicate HCPC Codes
  validateHCPC() {
    const value = this.hcpcForm?.value?.txtHCPCCode?.trim();
    const count = this.ltHCPC.filter(
      (a) => a.hcpcCode?.toLowerCase() == value.toLowerCase()
    ).length;
    this.hcpcDuplicateValidation = count > 0 ? true : false;
    return this.hcpcDuplicateValidation;
  }

  //Remove the HCPC Codes
  removeHCPC(code: string) {
    this.ltHCPC = this.ltHCPC.filter((a) => a.hcpcCode !== code);
    this.communicationService.triggerremoveHCPCMethodCallMethodCall(code);
    this.isAddHCPC = true;
  }

  //Close the dialog 
  closeHcpcMdl() {
    this.hcpcDialogRef.close({ isAddHCPC: this.isAddHCPC, ltHCPC: this.ltHCPC });
  }
}
