<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h3 class="font-weight-bolder mb-0">Existing Patient Payment : Claim History</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <li class="breadcrumb-item ">Existing Patient</li>
            <li class="breadcrumb-item ">Patient Payment</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <mat-card class="mrgn"> -->

<!-- <div class="col-lg-12">

  <div class="row">
    <div class="col-lg-12">
      <h4 class="customThemeClass"><b>Patient Name - </b> {{lblPatientName}} </h4>
    </div>
    <div class="col-9"> <span class="mr-2"><b>Status :</b></span>
      <button mat-button class="btn btn-sm red-dot mr-2"> <span> Hold </span></button>
      <button mat-button class="btn btn-sm green-dot mr-2"> <span> Ready </span></button>
      <button mat-button class="btn btn-sm dark-dot mr-2"> <span> Processed </span></button>
      <button mat-button class="btn btn-sm yellow-dot mr-2"> <span> Crossed-over </span></button>
      <button mat-button class="btn btn-sm blue-dot mr-2"> <span> Auth No </span></button>
      <button mat-button class="btn btn-sm pink-dot mr-2"> <span> >55days </span></button>

      <!-- <button mat-button class="buttonColor" (click)="charges()"> <span> Charges </span></button> -->
<!-- </div>
    <div class="col-3 text-right">
      <mat-checkbox class="mr-2" (change)="showInt($event)">Show Int</mat-checkbox>
      <mat-checkbox (change)="showPaid($event)">Show Paid</mat-checkbox>
    </div>
  </div> -->

<!-- </div>  -->
<div class="col-lg-12">
  <app-table [strPageType]="strPageType" [pageType]='strPageType' (claimButton)="claimInfo($event)"
    (addPaymentPayPalCheckout)="paymentInitiate($event)" (viewButton)="openPatientVisitNotes($event)"
    (reminderButton)="addReminder($event?.id)" (addPayment)='payment($event)' (deleteButton)="deleteItems($event)">
  </app-table>
</div>
<!-- (addPaymentPayPalInvoice)="paymentInvoiceInitiate($event)" -->
<!-- </mat-card> -->
