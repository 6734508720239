<br />
<!--Mat Card-->
<mat-card class="mr-3 mt-2">
  <!--Heading-->
  <p class="accordHeading">Document View</p>
  <mat-divider></mat-divider>
  <br />
  <!--Heading End-->

  <!--Card Body-->
  <!--Form Start-->
  <form [formGroup]="documentForm" autocomplete="off">
    <!--Input Elements-->
    <div class="row">
      <div class="col-3">
        <label
          for="file-upload"
          class="col-lg-12 btnclr waves-effect font-size-12 pt-2 pb-2 cur-pointer"
          #lblUploadFile
        >
          <i class="fa fa-cloud-upload pr-3"></i>Browse<span class="asterisk">
            *
          </span>
        </label>
        <input
          id="file-upload"
          type="file"
          [multiple]="true"
          class="waves-effect"
          formControlName="documentFile"
          accept="application/pdf"
          #UploadFieldReference
          (change)="getfilename(UploadFieldReference.files)"
        />
        <mat-error
          *ngIf="
            documentForm?.get('documentFile')?.touched &&
            documentForm?.get('documentFile')?.errors?.required
          "
        >
          Document is a required field!
        </mat-error>
      </div>

      <div class="col-3">
        <mat-form-field class="col-12">
          <mat-label>Document Type <span class="asterisk">*</span> </mat-label>
          <mat-select
            (selectionChange)="togglePanel()"
            formControlName="drpDocumentType"
            maxlength="40"
          >
            <mat-option
              *ngFor="let document of drpDocumentTypeLoop"
              [value]="document.documentType"
            >
              {{ document.documentType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span>
          <mat-error
            class="spanAlignment"
            *ngIf="
              documentForm?.get('drpDocumentType')?.touched &&
              documentForm?.get('drpDocumentType')?.errors?.required
            "
          >
            Document Type is a required field!
          </mat-error>
        </span>
      </div>
    </div>

    <!--Uploaded Files-->
    <div class="col-12">
      <!-- <div class="alert" style="background-color:#9ae3df"> -->

      <div
        *ngIf="filesname !== [] && filesname.length !== 0"
        class="alert"
        style="background-color: #9ae3df"
      >
        <div *ngIf="filesname !== [] && filesname.length !== 0">
          <div class="lbl" *ngIf="filesname !== [] && filesname.length !== 0">
            <b>Documents To be Uploaded:{{ filesname.length }}</b>
          </div>
          <mat-divider></mat-divider>
          <div class="bgr mb-1 mt-3" *ngFor="let item of filesname">
            <i class="fa fa-file-pdf-o"></i>
            {{ item }}
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
    <!--End Uploaded Files-->

    <!--Extended Info-->
    <mat-accordion class="pt-2">
      <mat-expansion-panel [expanded]="panelOpenState">
        <mat-expansion-panel-header class="accordHeading">
          <mat-panel-title> Extended Info </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row pt-1">
          <mat-form-field class="col-3">
            <mat-label>Review Status</mat-label>
            <mat-select formControlName="drpReviewStatus" maxlength="40">
              <mat-option
                *ngFor="let reviewstatus of drpReviewStatusLoop"
                [value]="reviewstatus.reviewStatusShortCodeId"
              >
                {{ reviewstatus.reviewStatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Review Reason</mat-label>
            <mat-select formControlName="drpReviewReason" maxlength="40">
              <mat-option
                *ngFor="let reviewstatus of drpReviewReasonLoop"
                [value]="reviewstatus.reviewReasonShortCodeId"
              >
                {{ reviewstatus.reviewReason }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-3">
            <mat-form-field class="col-12">
              <mat-label>
                Document Start Date
                <ng-container
                  *ngIf="
                    documentForm?.get('drpDocumentType')?.value === 'RX' ||
                      documentForm?.get('drpDocumentType')?.value ===
                        'FACE TO FACE';
                    else elseStartDateReqTemplate
                  "
                >
                  <span class="asterisk">*</span>
                </ng-container>
                <ng-template #elseStartDateReqTemplate> </ng-template>
              </mat-label>
              <input
                matInput
                [ngxMatDatetimePicker]="txtDocumentStartDate"
                placeholder="(MM/DD/YYYY)"
                formControlName="txtDocumentStartDate"
                autocomplete="new-txtDocumentStartDate"
              />

              <mat-datepicker-toggle
                matSuffix
                [for]="$any(txtDocumentStartDate)"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #txtDocumentStartDate
                [showSpinners]="true"
                [showSeconds]="true"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="'primary'"
                [enableMeridian]="true"
              >
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>
            <ng-container
              *ngIf="
                documentForm?.get('drpDocumentType')?.value === 'RX' ||
                documentForm?.get('drpDocumentType')?.value === 'FACE TO FACE'
              "
            >
              <span>
                <mat-error
                  class="spanAlignment"
                  *ngIf="
                    documentForm?.get('txtDocumentStartDate')?.touched &&
                    documentForm?.get('txtDocumentStartDate')?.errors?.required
                  "
                >
                  Document Start Date is a required field!
                </mat-error>
              </span>
            </ng-container>
          </div>

          <div class="col-3">
            <mat-form-field class="col-12">
              <mat-label
                >Document End Date
                <ng-container
                  *ngIf="
                    documentForm?.get('drpDocumentType')?.value === 'RX' ||
                      documentForm?.get('drpDocumentType')?.value ===
                        'FACE TO FACE';
                    else elseStartDateReqTemplate
                  "
                >
                  <span class="asterisk">*</span>
                </ng-container>
                <ng-template #elseStartDateReqTemplate> </ng-template>
              </mat-label>
              <input
                matInput
                [ngxMatDatetimePicker]="txtDocumentEndDate"
                placeholder="(MM/DD/YYYY)"
                formControlName="txtDocumentEndDate"
                autocomplete="new-txtDocumentEndDate"
              />

              <mat-datepicker-toggle
                matSuffix
                [for]="txtDocumentEndDate"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #txtDocumentEndDate
                [showSpinners]="true"
                [showSeconds]="true"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="'primary'"
                [enableMeridian]="true"
              >
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>

            <ng-container
              *ngIf="
                documentForm?.get('drpDocumentType')?.value !== 'RX' ||
                documentForm?.get('drpDocumentType')?.value !== 'FACE TO FACE'
              "
            >
              <span>
                <mat-error
                  class="spanAlignment"
                  *ngIf="
                    documentForm?.get('txtDocumentEndDate')?.touched &&
                    documentForm?.get('txtDocumentEndDate')?.errors?.required
                  "
                >
                  Document End Date is a required field!
                </mat-error>
              </span>
            </ng-container>
          </div>

          <mat-form-field class="col-6">
            <mat-label>Description</mat-label>
            <textarea
              formControlName="txtNote"
              id="txtDescription"
              rows="1"
              matInput
              maxlength="400"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!--End Extended Info-->
    <!--End Input Elements-->

    <!--Buttons-->
    <div class="col-12 pt-3 pb-3">
      <ng-container>
        <button
          [hidden]="isDocumentEditSaveButtonHidden"
          class="mr-3 waves-effect"
          [disabled]="documentForm.invalid || isShowSpinner"
          mat-raised-button
          color="primary"
          (click)="upload(UploadFieldReference.files)"
        >
          <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner>
          </mat-icon>
          Save
        </button>
      </ng-container>
      <button
        mat-raised-button
        color="warn"
        class="waves-effect"
        (click)="resetDocumentForm()"
      >
        Reset
      </button>
    </div>
    <!--End Buttons-->
    <!--End Input Elements-->
  </form>
  <!--Form End-->

  <!--Table-->
  <mat-accordion multi class="pt-5">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="accordHeading">
        <mat-panel-title>
          Uploaded Document(s) Count: {{ arrUnsortedDocumentList?.length }}
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="row pt-1">
        <ng-container>
          <div class="col-12 table-responsive">
            <div class="mat-elevation-z8">
              <form [formGroup]="documentSearchForm">
                <table mat-table [dataSource]="dataSource">
                  <!-- Document Type Column -->
                  <ng-container matColumnDef="documentType">
                    <th
                      class="header"
                      mat-header-cell
                      *matHeaderCellDef
                      class="customizedHeadingFont"
                    >
                      <mat-form-field class="filter">
                        <mat-label class="customizedHeadingFont">
                          Document Type
                        </mat-label>
                        <input
                          matInput
                          formControlName="documentType"
                          autocomplete="new-documentType"
                        />
                      </mat-form-field>
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="customizedFont"
                    >
                      {{ element.documentType }}
                    </td>
                  </ng-container>
                  <!-- File Name Column -->

                  <ng-container matColumnDef="fileName">
                    <th
                      class="header"
                      mat-header-cell
                      *matHeaderCellDef
                      class="customizedHeadingFont"
                    >
                      <mat-form-field class="filter">
                        <mat-label class="customizedHeadingFont"
                          >File Name</mat-label
                        >
                        <input
                          matInput
                          formControlName="fileName"
                          type="text"
                          autocomplete="new-fileName"
                        />
                      </mat-form-field>
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="customizedFont"
                    >
                      {{ element.fileName }}
                    </td>
                  </ng-container>

                  <!-- Document Start Date Column -->
                  <ng-container matColumnDef="documentStartDate">
                    <th
                      class="header"
                      mat-header-cell
                      *matHeaderCellDef
                      class="customizedHeadingFont"
                    >
                      <mat-form-field class="filter" autocomplete="off">
                        <mat-label class="customizedHeadingFont">
                          Document Start Date
                        </mat-label>
                        <input
                          matInput
                          placeholder="(MM/DD/YYYY)"
                          autocomplete="new-documentStartDate"
                          formControlName="documentStartDate"
                          class="customizedHeadingFont"
                        />
                      </mat-form-field>
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="customizedFont"
                    >
                      {{
                        element?.documentStartDate
                          | date: "MM/dd/yyyy h:mm a":"en_US"
                      }}
                    </td>
                  </ng-container>
                  <!-- Document End Date Column -->
                  <ng-container matColumnDef="documentEndDate">
                    <th
                      class="header"
                      mat-header-cell
                      *matHeaderCellDef
                      class="customizedHeadingFont"
                    >
                      <mat-form-field class="filter">
                        <mat-label class="customizedHeadingFont">
                          Document End Date
                        </mat-label>
                        <input
                          matInput
                          placeholder="(MM/DD/YYYY)"
                          formControlName="documentEndDate"
                          class="customizedHeadingFont"
                          autocomplete="new-documentEndDate"
                        />
                      </mat-form-field>
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="customizedFont"
                    >
                      {{
                        element?.documentEndDate
                          | date: "MM/dd/yyyy h:mm a":"en_US"
                      }}
                    </td>
                  </ng-container>

                  <!-- Document Status Column -->
                  <ng-container matColumnDef="documentStatus">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="customizedHeadingFont pt-2"
                    >
                      Document Status
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="customizedFont"
                    >
                      {{ element.docStatus }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="noRecords">
                    <td
                      class="text-align-center"
                      mat-footer-cell
                      *matFooterCellDef
                      colspan="6"
                    >
                      <div *ngIf="!isShowProcessBar">
                        {{ "No Records Found" }}
                      </div>
                      <mat-icon [hidden]="!isShowProcessBar">
                        <mat-spinner
                          class="table spinner-border spinner-border-sm"
                          diameter="20"
                        >
                        </mat-spinner>
                      </mat-icon>
                    </td>
                  </ng-container>
                  <!-- Options Column -->
                  <ng-container matColumnDef="options">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="customizedHeadingFont pl-2"
                    >
                      Options
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let documentList"
                      class="customizedFont"
                    >
                      <a class="editBtnColor">
                        <i
                          class="fa fa-pencil mr-3 pointer customThemeClass"
                          matTooltip="Edit"
                          (click)="
                            viewPdfFile(documentList.blobName, documentList.id)
                          "
                        ></i>
                      </a>
                      <a class="editBtnColor">
                        <i
                          class="fa fa-cloud-download mr-3 pointer customThemeClass"
                          matTooltip="Download"
                          (click)="
                            downloadFile(
                              documentList.blobName,
                              documentList.fileName
                            )
                          "
                        ></i>
                      </a>
                      <a class="editBtnColor">
                        <i
                          class="fa fa-trash pointer asterisk"
                          matTooltip="Delete"
                          (click)="deleteFileById(documentList.id)"
                        ></i>
                      </a>
                    </td>
                  </ng-container>

                  <tr
                    mat-footer-row
                    *matFooterRowDef="['noRecords']"
                    [ngClass]="{
                      active:
                        dataSource &&
                        dataSource?.data &&
                        dataSource?.data?.length !== 0
                    }"
                  ></tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </form>
              <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
                aria-label="Select page of periodic elements"
              >
              </mat-paginator>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!--End Table-->

  <br />
  <mat-divider></mat-divider>

  <div class="row pt-5">
    <!--Side Bar-->
    <div class="col-xl-3" style="height: 1000px">
      <div class="card card-body ml-2">
        <div class="text-center">
          <span style="font-size: larger"
            ><b class="colored-heading">Document Types</b></span
          >
        </div>
        <div layout-align="center center">
          <mat-tree [dataSource]="dataSource1" [treeControl]="treeControl" >
            <mat-tree-node
              class="childNodeClass text-justify"
              *matTreeNodeDef="let ex"
              matTreeNodePadding
              val="ex"
              (click)="activeNode = ex" [ngClass]="{ 'background-highlight': activeNode === ex }"
              (click)="getPdf(ex.blobName)"
            >
              <button mat-icon-button disabled></button>
              <!-- <pre>{{ex | json}}</pre> -->
              <i class="fa fa-file-pdf-o"></i>
              {{ ex?.name }}  &nbsp;

            </mat-tree-node>
            <mat-tree-node
              style="font-weight: 500"
              style="cursor: default"
              *matTreeNodeDef="let ex; when: hasChild"

              matTreeNodePadding
            >
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + ex.name"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(ex) ? "expand_more" : "chevron_right"
                  }}
                </mat-icon>
              </button>
              {{ ex?.name }}
              &nbsp;
              <span
                class="pull-right"
                matBadge="{{ ex?.documentCount }}"
                matBadgeOverlap="false"
              ></span>

              <!-- <pre>{{node | json}}</pre> -->
            </mat-tree-node>
          </mat-tree>
        </div>
      </div>
    </div>
    <!--Side Bar End-->

    <!--Document Viewer-->
    <div class="col-xl-9">
      <ng-container>
        <ngx-extended-pdf-viewer
          [src]="strSelectedPdfPath"
          [useBrowserLocale]="true"
          [textLayer]="true"
          [showHandToolButton]="true"
          [height]="pdfHeight"
          (pagesLoaded)="onPagesLoaded($event)"
          [showFindButton]="false"
        >
        </ngx-extended-pdf-viewer>
      </ng-container>
    </div>
    <!--End Document Viewer-->
    <br />
  </div>
  <!--End Card Body-->
</mat-card>
<!--Mat Card End-->
<br /><br /><br />
