<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
      <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
    </div>