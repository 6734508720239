<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <p class="header-text mb-0">
          Patient Delivery Address Details - {{ data?.chartId }}
        </p>
        <button
          type="button"
          mat-icon-button
          mat-dialog-close
          class="close-button"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <div class="card card-body">
        <form [formGroup]="addressForm">
          <!-- Name Line -->
          <mat-form-field class="col-3">
            <mat-label>Name Line <span class="asterisk">*</span></mat-label>
            <input formControlName="txtNameLine" matInput />
          </mat-form-field>
          <!-- Description -->
          <mat-form-field class="col-3">
            <mat-label>Description <span class="asterisk">*</span></mat-label>
            <input formControlName="txtDescription" matInput />
          </mat-form-field>

          <!-- Address -->
          <mat-form-field class="col-6">
            <mat-label>Address <span class="asterisk">*</span></mat-label>
            <textarea
              matInput
              rows="3"
              autocomplete="new-txtAddress"
              maxlength="200"
              formControlName="txtAddress"
              [(ngModel)]="addressText"
              (input)="updateCharacterCount(200)"
              type="text"
            ></textarea>
            <div class="char-count">{{ addressText?.length }} / 200</div>
          </mat-form-field>

          <!-- Country-->
          <mat-form-field class="col-3">
            <mat-label>Country <span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="txtCountry"
              (selectionChange)="onChangeState($event.value)"
              [(value)]="country"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtCountryFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let country of filteredCountries | async"
                [value]="country.countryShortName"
              >
                {{ country.countryName }}
              </mat-option>
            </mat-select>
            <!-- <button
            mat-button
            (click)="State = ''; $event.stopPropagation()"
            *ngIf="State"
            (click)="clearState()"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button> -->
          </mat-form-field>
          <!-- State-->
          <mat-form-field class="col-3">
            <mat-label>State <span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="txtState"
              (selectionChange)="onChangeCity($event.value, addressForm?.get('txtCountry').value)"
              [(value)]="State"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtStateFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let state of filteredStates | async"
                [value]="state.stateShortName"
              >
                {{ state.stateName }}
              </mat-option>
            </mat-select>
            <!-- <button
            mat-button
            (click)="State = ''; $event.stopPropagation()"
            *ngIf="State"
            (click)="clearState()"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button> -->
          </mat-form-field>
          <!-- City-->
          <mat-form-field class="col-3">
            <mat-label>City <span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="txtCity"
              [(value)]="City"
              #citySelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtCityFilter"
                  hideClearSearchButton="true"
                  (keypress)="onCityKeyPress($event)"
                  [noEntriesFoundLabel]="cityDrpDownMessage()"
                    (focusout)="focusOut()"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let city of filteredCities | async"
                [value]="city.cityName"
              >
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <!-- <button
            mat-button
            (click)="City = ''; $event.stopPropagation()"
            *ngIf="City"
            matSuffix
            (click)="clearCity()"
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button> -->
          </mat-form-field>

          <!-- Zip Code -->
          <mat-form-field class="col-3">
            <mat-label>Zip Code <span class="asterisk">*</span> </mat-label>
            <input
            maxlength="10"
            pattern="[0-9-]*"
            matInput
            type="text"
            class="zip-code-input"
              formControlName="txtPostalCode"
            />
            <mat-error
              *ngIf="
                addressForm?.get('txtPostalCode')?.touched &&
                addressForm?.get('txtPostalCode')?.errors?.pattern
              "
            >
              Enter only Numbers!
            </mat-error>
          </mat-form-field>

          <!-- Home Cell -->
          <mat-form-field class="col-3">
            <mat-label> Home Cell </mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtHomeNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
          </mat-form-field>

          <!-- Cell -->
          <mat-form-field class="col-3">
            <mat-label> Cell </mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtCellNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
          </mat-form-field>

          <!-- Email-->
          <mat-form-field class="col-3">
            <mat-label>Email</mat-label>
            <input
              matInput
              pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }"
              maxlength="40"
              type="text"
              formControlName="txtEmail"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                addressForm?.get('txtEmail')?.touched &&
                addressForm?.get('txtEmail')?.errors?.pattern
              "
            >
              Enter a valid email!
            </mat-error>
          </mat-form-field>
        </form>
      </div>
      <!-- Buttons -->
      <div class="row">
        <div class="col-12 d-flex flex-row-reverse mt-2">
          <!-- <button
              [disabled]="addressForm?.invalid || isSaveBtnDisabled"
              (click)="verifyAddress()"
              mat-raised-button
              class="buttonColor"
            >
              <img
                class="img-responsive pr-1 mb-1"
                src="assets/Order Page Icons/Verify Address 1.png"
                alt=""
                height="17"
              />
              Verify
            </button> -->
          <button
            [disabled]="addressForm?.invalid || isSaveBtnDisabled"
            (click)="verifyandSaveAddress()"
            mat-raised-button
            class="buttonColor"
          >
            <mat-icon class="mb-1">save</mat-icon>
            Save & Verify
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
