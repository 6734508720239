import { mapEnumToOptions } from '@abp/ng.core';

export enum QuarterYear {
  JanuaryToMarch = 'JanuaryToMarch',
  AprilToJune = 'AprilToJune',
  JulyToSeptember = 'JulyToSeptember',
  OctoberToDecember = 'OctoberToDecember',
}

export const quarterYearOptions = mapEnumToOptions(QuarterYear);
