import type {
  CreateUpdatePatientNextCallDTO,
  CreateUpdatePatientPersonalDTO,
  patientCalenderDetails,
  PatientCallDetailsDTO,
  PatientDocumentBlobDTO,
  PatientNewVerificationDTO,
  PatientPersonalDoNotCallReason,
  PatientPersonalDTO,
  patientSearchDTO,
  PatientSummaryDTO,
  ProviderNPIdetailsDTO,
  SortedDocumentGroupDTO,
  patientInsuranceDTO,
  PatientPolicyDTO,
  CompailanceReportDTO,
  PatientWiseCallLogDTO,
  PatientWiseProvideDetails,
  NewInsuranceDTO,
  PatientAuthVerificationDTO,
  GetBase64TiffSorted,
  PatientChartNoDTO,
  PatientIdDetailsDTO,
  PatientExportDataDTO,
  PatientExportSearchDTO,
  PatientExportOrderDataDTO,
  PatientListDto,
  PatientChartIdDTO,
  PayorDetailDTO,
  InputPatientListDto,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { AssignDoNotCallReason } from './assign-do-not-call-reason.enum';
import { getPatient } from '../../patient-store/patient.action';
import { ComlplainceFilter } from '../patient/enumeration-data/comlplaince-filter.enum';

@Injectable({
  providedIn: 'root',
})
export class PatientPersonalService {
  apiName = 'Default';

  create = (patientPersonal: CreateUpdatePatientPersonalDTO) =>
    this.restService.request<any, PatientPersonalDTO>(
      {
        method: 'POST',
        url: '/api/app/patient-personal',
        body: patientPersonal,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `//${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, PatientPersonalDTO>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientPersonalDTO>>(
      {
        method: 'GET',
        url: '/api/app/patient-personal',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  getPatientByIdByGPatientId = (gPatientId: string) =>
    this.restService.request<any, PatientPersonalDTO>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/patient-by-id/${gPatientId}`,
      },
      { apiName: this.apiName }
    );

  update = (
    patientId: string,
    patientPersonal: CreateUpdatePatientPersonalDTO
  ) =>
    this.restService.request<any, PatientPersonalDTO>(
      {
        method: 'PUT',
        url: '/api/app/patient-personal',
        params: { patientId },
        body: patientPersonal,
      },
      { apiName: this.apiName }
    );

  patientSummaryByPatientSummaryDto = (patientSummaryDto: PatientSummaryDTO) =>
    this.restService.request<any, PatientSummaryDTO>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/patient-summary',
        body: patientSummaryDto,
      },
      { apiName: this.apiName }
    );
  patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus =
    (
      sDefaultPatientId: string,
      sPatientName: string,
      dtDOB: string,
      sSSN: string,
      sEmailId: string,
      sMobile: string,
      sStatus: string,
      isInbound: number
    ) =>
      this.restService.request<any, patientSearchDTO[]>(
        {
          method: 'POST',
          url: '/api/app/patient-personal/patient-search',
          params: {
            sDefaultPatientId,
            sPatientName,
            dtDOB,
            sSSN,
            sEmailId,
            sMobile,
            sStatus,
            isInbound,
          },
        },
        { apiName: this.apiName }
      );

  patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatusAndFromDateAndToDate =
    (
      sDefaultPatientId: string,
      sPatientName: string,
      dtDOB: string,
      sSSN: string,
      sEmailId: string,
      sMobile: string,
      sStatus: string,
      fromDate: string,
      toDate: string,
      isInbound: number,
      postalCode: string,
      colorCode: string
    ) =>
      this.restService.request<any, patientSearchDTO[]>(
        {
          method: 'POST',
          url: '/api/app/patient-personal/patient-search',
          params: {
            sDefaultPatientId,
            sPatientName,
            dtDOB,
            sSSN,
            sEmailId,
            sMobile,
            sStatus,
            fromDate,
            toDate,
            isInbound,
            postalCode,
            colorCode,
          },
        },
        { apiName: this.apiName }
      );

  validatePatientAsync = (patientId: string) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        //url: '/api/orderApp/patient-sale-order/validate-patient/${id}',
        url: `/api/orderApp/patient-sale-order/validate-patient/${patientId}`,
        //params: { patientId },
      },
      { apiName: 'orderManagement' }
    );

  getNPIList = (patientId: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/provider-npi/${patientId}`,
        //params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
      },
      { apiName: this.apiName }
    );
  getSortedDocumentPDF = (gPatientId: string, gWipId: string) =>
    this.restService.request<any, SortedDocumentGroupDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-sorted-document-pDF`,
        params: { gPatientId, gWipId },
      },
      { apiName: this.apiName }
    );
  getPatientBasicDetails = (iType: number) =>
    this.restService.request<any, ProviderNPIdetailsDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-patient-basic-details`,
        params: { iType },
      },
      { apiName: this.apiName }
    );
  getPatientIdBasicDetails = (gPatientId: string, gDocument: string) =>
    this.restService.request<any, ProviderNPIdetailsDTO[]>(
      {
        method: 'POST',
        url: `/api/app/patient-personal/patient-basic-auth-details/${gPatientId}`,
        params: { gDocument: gDocument },
      },
      { apiName: this.apiName }
    );
  getPatientIdBasicDetailsV1 = (gPatientId: string, gDocument: string) =>
    this.restService.request<any, ProviderNPIdetailsDTO[]>(
      {
        method: 'POST',
        url: `/api/app/patient-personal/patient-document-auth-details/${gPatientId}`,
        params: { gDocument: gDocument },
      },
      { apiName: this.apiName }
    );
  patientDocumentAuthDetailsV1NewByGAuthId = (gAuthId: string) =>
    this.restService.request<any, ProviderNPIdetailsDTO>(
      {
        method: 'POST',
        url: `/api/app/patient-personal/patient-document-auth-details-v1/${gAuthId}`,
      },
      { apiName: this.apiName }
    );
  getAuthFiles = (docIds: string[]) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/patient-personal/get-auth-files',
        body: docIds,
      },
      { apiName: this.apiName }
    );
  getAuthFileDetails = (dTypeId: string, gPatientId: string) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/patient-personal/get-documents-pages-by-type',
        params: { dTypeId, gPatientId },
      },
      { apiName: this.apiName }
    );

  getAuthDocument = (docPreview: string[]) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/patient-personal/get-base64Content',
        body: docPreview,
      },
      { apiName: this.apiName }
    );

  getBase64TiffSortedByDocs = (docs: string[]) =>
    this.restService.request<any, GetBase64TiffSorted[]>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/get-base64Tiff-sorted',
        body: docs,
      },
      { apiName: this.apiName }
    );
  assignDoNotCallReasonByPatientIDAndAssignDoNotCallReason = (
    patientID: string,
    assignDoNotCallReason: AssignDoNotCallReason
  ) =>
    this.restService.request<any, PatientPersonalDoNotCallReason>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/assign-do-not-call-reason',
        params: { patientID, assignDoNotCallReason },
      },
      { apiName: this.apiName }
    );

  assignDoNotCallReasonByPatientIDAndAssignDoNotCallReasonV1 = (
    patientID: string,
    ReasonId: string
  ) =>
    this.restService.request<any, PatientPersonalDoNotCallReason>(
      {
        method: 'POST',
        url: `/api/app/patient-personal/assign-do-not-call-reason/${ReasonId}`,
        params: { patientID },
      },
      { apiName: this.apiName }
    );
  getPatientForCalenderV1 = () =>
    this.restService.request<any, patientCalenderDetails[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patient-for-calender-v1',
      },
      { apiName: this.apiName }
    );

  getdonotCallReason = (statusFilter: number) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/app/do-not-call-reason/do-not-call-reason-list',
        params: { statusFilter },
      },
      { apiName: this.apiName }
    );

  getBinPatientDetailsByGPatient = (gPatient: string) =>
    this.restService.request<any, PatientCallDetailsDTO>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-bin-patient-details',
        params: { gPatient },
      },
      { apiName: this.apiName }
    );

  getPatientsByTenant = (gbranch: string[]) =>
    this.restService.request<any, PatientCallDetailsDTO>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patients-by-tenant',
        params: { gbranch },
      },
      { apiName: this.apiName }
    );

  patientNextCall = (input: CreateUpdatePatientNextCallDTO) =>
    this.restService.request<any, PatientCallDetailsDTO>(
      {
        method: 'POST',
        url: '/api/app/patient-next-call',
        body: input,
      },
      { apiName: this.apiName }
    );
  getPatientPlanDetail = (gPatientId: string, payorLevel: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/app/patient-policy/get-patient-policy-details/${gPatientId}`,
        params: { payorLevel },
      },
      { apiName: this.apiName }
    );
  patientNewVerificationSave = (input: PatientNewVerificationDTO) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/app/patient-verification/initiate-new-verification',
        body: input,
      },
      { apiName: this.apiName }
    );

  getPatientsManagement = (
    ltBranch: string[],
    ltCsr: string[],
    NextCallDate: string,
    LastMaskProcessedDate: string,
    sChartNo: string,
    sPatientName: string,
    DOB: string,
    sPolicyName: string,
    CsrName: string
  ) =>
    this.restService.request<any, patientSearchDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patients-by-user',
        params: {
          ltBranch,
          ltCsr,
          nextCallDate: NextCallDate,
          lastMaskProcessedDate: LastMaskProcessedDate,
          sChartNo,
          sPatientName,
          dob: DOB,
          sPolicyName,
          csrName: CsrName,
        },
      },
      { apiName: this.apiName }
    );

  patientwisePolicyDetails = () =>
    this.restService.request<any, patientInsuranceDTO[]>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/patientwise-policy-details',
      },
      { apiName: this.apiName }
    );

  getAllPolicyByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, PatientPolicyDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-all-policy-by-patient-id/${patientId}`,
      },
      { apiName: this.apiName }
    );

  complainceReportByFilter = (filter: ComlplainceFilter) =>
    this.restService.request<any, CompailanceReportDTO[]>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/complaince-report',
        params: { filter },
      },
      { apiName: this.apiName }
    );

  getPatientWiseCallLogByPatientId = (patientId: string) =>
    this.restService.request<any, PatientWiseCallLogDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-patient-wise-call-log/${patientId}`,
      },
      { apiName: this.apiName }
    );

  getPatientWiseProviderDetailsByPatientId = (patientId: string) =>
    this.restService.request<any, PatientWiseProvideDetails[]>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-patient-wise-provider-details/${patientId}`,
      },
      { apiName: this.apiName }
    );

  patientwisePolicyDetailsV1 = () =>
    this.restService.request<any, NewInsuranceDTO[]>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/patientwise-policy-details-v1',
      },
      { apiName: this.apiName }
    );

  getAllPolicyByPatientIdV1ByPatientId = (patientId: string) =>
    this.restService.request<any, PatientAuthVerificationDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-all-policy-by-patient-id-v1/${patientId}`,
      },
      { apiName: this.apiName }
    );

  // getPatientsListV1 = (gbranch: string[], sSearch: string, sChartNo: string, sPatientName: string, DOB: string, sPolicyName: string, CsrName: string) =>
  // this.restService.request<any, patientSearchDTO[]>({
  //   method: 'GET',
  //   url: '/api/app/patient-personal/get-patients-by-tenant',
  //   params: { gbranch, sSearch, sChartNo, sPatientName, dob: DOB, sPolicyName, csrName: CsrName },
  // },
  // { apiName: this.apiName });

  getPatientsListV1 = (
    gbranch: string[],
    bFlag: boolean,
    sSearch: string,
    sChartNo: string,
    sPatientName: string,
    DOB: string,
    sPolicyName: string,
    CsrName: string
  ) =>
    this.restService.request<any, patientSearchDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patients-by-tenant',
        params: {
          gbranch,
          bFlag,
          sSearch,
          sChartNo,
          sPatientName,
          dob: DOB,
          sPolicyName,
          csrName: CsrName,
        },
      },
      { apiName: this.apiName }
    );

  getPatientsByTenantV1 = () =>
    this.restService.request<any, patientSearchDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patients-by-tenant-v1',
      },
      { apiName: this.apiName }
    );

  // getPatientsManagementV1= (ltBranch: string[], ltCsr: string[], NextCallDate: string, LastMaskProcessedDate: string, sChartNo: string, sPatientName: string, DOB: string, sSearch: string, sPolicyName: string) =>
  //   this.restService.request<any, patientSearchDTO[]>({
  //     method: 'GET',
  //     url: '/api/app/patient-personal/get-patients-by-user',
  //     params: { ltBranch, ltCsr, nextCallDate: NextCallDate, lastMaskProcessedDate: LastMaskProcessedDate, sChartNo, sPatientName, dob: DOB, sSearch, sPolicyName },
  //   },
  //   { apiName: this.apiName });

  getPatientsManagementV1 = (
    ltBranch: string[],
    bFlag: boolean,
    ltCsr: string[],
    NextCallDate: string,
    LastMaskProcessedDate: string,
    sChartNo: string,
    sPatientName: string,
    DOB: string,
    sSearch: string,
    sPolicyName: string
  ) =>
    this.restService.request<any, patientSearchDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patients-by-user',
        params: {
          ltBranch,
          bFlag,
          ltCsr,
          nextCallDate: NextCallDate,
          lastMaskProcessedDate: LastMaskProcessedDate,
          sChartNo,
          sPatientName,
          dob: DOB,
          sSearch,
          sPolicyName,
        },
      },
      { apiName: this.apiName }
    );
  getPatientsManagementV2 = () =>
    this.restService.request<any, patientSearchDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patients-by-user-v1',
        // params: { ltBranch, bFlag, ltCsr, nextCallDate: NextCallDate, lastMaskProcessedDate: LastMaskProcessedDate, sChartNo, sPatientName, dob: DOB, sSearch, sPolicyName },
      },
      { apiName: this.apiName }
    );

  getPatientSearchV1NewSetup = (
    Dob: string,
    chartId: string,
    patientName: string,
    emergencyContact: string,
    Address: string,
    ssn: string,
    patient: string,
    memberId: string,
    email: string
  ) =>
    this.restService.request<any, PatientChartNoDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patient-search-v1',
        params: {
          dob: Dob,
          chartId,
          patientName,
          emergencyContact,
          address: Address,
          ssn,
          patient,
          memberId,
          email,
        },
      },
      { apiName: this.apiName }
    );

  getPatientSearchV1NewSetupV1 = (
    Dob: string,
    chartId1: string,
    patientName: string,
    emergencyContact1: string,
    Address1: string,
    ssn1: string,
    patient: string,
    member: string,
    email: string
  ) =>
    this.restService.request<any, PatientChartNoDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patient-search-v1',
        params: {
          dob: Dob,
          chartId1,
          patientName,
          emergencyContact1,
          address1: Address1,
          ssn1,
          patient,
          member,
          email,
        },
      },
      { apiName: this.apiName }
    );

  // patientListSearchByChartNoAndPatientNameAndDobAndPolicyIdsAndSsn = (chartNo: string, patientName: string, dob: string, policyIds: string[], ssn: string) =>
  //   this.restService.request<any, patientSearchDTO[]>({
  //     method: 'POST',
  //     url: '/api/app/patient-personal/patient-list-search',
  //     params: { chartNo, patientName, dob, ssn },
  //     body: policyIds,
  //   },
  //   { apiName: this.apiName });

  patientListSearchByChartNoAndPatientNameAndDobAndPolicyIdsAndSsn = (
    chartNo: string,
    patientName: string,
    dob: string,
    policyIds: string[],
    ssn: string,
    pageNo: any,
    pageOffset: any
  ) =>
    this.restService.request<any, PagedResultDto<patientSearchDTO>>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/patient-list-search',
        params: { chartNo, patientName, dob, ssn, pageNo, pageOffset },
        body: policyIds,
      },
      { apiName: this.apiName }
    );
  patientManagementSearchV1 = (
    chartNo: string,
    patientName: string,
    dob: string,
    ssn: string,
    laskMaskDate: string,
    fromDate: string,
    toDate: string,
    ltCsr: string[],
    ltPolicies: string[],
    pageNo: number,
    pageOffset: number
  ) =>
    this.restService.request<any, patientSearchDTO[]>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/patient-management-search-v1',
        params: {
          chartNo,
          patientName,
          dob,
          ssn,
          laskMaskDate,
          fromDate,
          toDate,
          pageNo,
          pageOffset,
        },
        body: { ltCsr, ltPolicies },
      },
      { apiName: this.apiName }
    );
  getPatientIdDetailsByPatient = (patient: string) =>
    this.restService.request<any, PatientIdDetailsDTO>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patient-id-details',
        params: { patient },
      },
      { apiName: this.apiName }
    );
  getPatientExportDataByDTO = (DTO: PatientExportSearchDTO) =>
    this.restService.request<any, PatientExportDataDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-personal/get-patient-export-data',
        params: {
          patientId: DTO.patientId,
          practiceId: DTO.practiceId,
          branchId: DTO.branchId,
          insuranceId: DTO.insuranceId,
          orderBy: DTO.orderBy,
          isDefault: DTO.isDefault,
          days: DTO.days,
          isGreaterthan: DTO.isGreaterthan,
          balance: DTO.balance,
        },
      },
      { apiName: this.apiName }
    );
  getPatientExportOrderDataByPatientId = (patientId: string) =>
    this.restService.request<any, PatientExportOrderDataDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-patient-export-order-data/${patientId}`,
      },
      { apiName: this.apiName }
    );
  getPatientRxFileDetails = (patient: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/app/patient-personal/get-patient-rXFile-details`,
        params: { patient },
      },
      { apiName: this.apiName }
    );

  getPatientListByInput = (input: InputPatientListDto) =>
    this.restService.request<any, PatientListDto[]>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/get-patient-list',
        body: input,
      },
      { apiName: this.apiName }
    );
  createChartIdByChartIdPATAndSPatient = (
    chartIdPAT: string,
    sPatient: string[]
  ) =>
    this.restService.request<any, PatientChartIdDTO[]>(
      {
        method: 'POST',
        url: '/api/app/patient-personal/chart-id',
        params: { chartIdPAT },
        body: sPatient,
      },
      { apiName: this.apiName }
    );
  getPayorListByPayerDetaislAndSPayer = (
    payerDetaisl: string,
    sPayer: string[]
  ) =>
    this.restService.request<any, PayorDetailDTO[]>(
      {
        method: 'POST',
        url: '/api/app/payer-detail/get-payor-list',
        params: { payerDetaisl },
        body: sPayer,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
