import type { CreateUpdateProductAllowableDetailsDTO, ProductAllowableDetailsDTO, SearchProductAllowablesDetailsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductAllowableDetailsService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductAllowableDetailsDTO) =>
    this.restService.request<any, ProductAllowableDetailsDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-allowable-details',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-allowable-details/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ProductAllowableDetailsDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-allowable-details/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductAllowableDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-allowable-details',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateProductAllowableDetailsDTO) =>
    this.restService.request<any, ProductAllowableDetailsDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-allowable-details/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    getProductListByGProductId = (gProductId: string) =>
    this.restService.request<any, PagedResultDto<ProductAllowableDetailsDTO>>({
      method: 'GET',
      url: `/api/ItemApp/product-allowable-details/product-list/${gProductId}`,
    },
    { apiName: this.apiName });


  getSearchAllowablesByQuantityAndEligibleMonthsAndPriceTypeAndAllowableAmount = (quantity: number, eligibleMonths: number, priceType: string, allowableAmount: number) =>
    this.restService.request<any, ProductAllowableDetailsDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/product-allowable-details/get-search-allowables',
      params: { quantity, eligibleMonths, priceType, allowableAmount },
    },
    { apiName: this.apiName });
    searchProductAllowablesDetailsByInput = (input: SearchProductAllowablesDetailsDTO) =>
    this.restService.request<any, PagedResultDto<ProductAllowableDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-allowable-details/search-product-allowables-details',
      params: { productIds: input.productIds, insuraceIds: input.insuraceIds, createdFromDate: input.createdFromDate, createdToDate: input.createdToDate },
    },
    { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
