import type { CreateReminderDTO, ReminderDTO, UpdateReminderDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ReminderFilter } from '../localization/enumeration/reminder-filter.enum';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  apiName = 'platformManagement';

  create = (input: CreateReminderDTO) =>
    this.restService.request<any, ReminderDTO>({
      method: 'POST',
      url: '/api/PlatformApp/reminder',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/reminder/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ReminderDTO>({
      method: 'GET',
      url: `/api/PlatformApp/reminder/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ReminderDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/reminder',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: UpdateReminderDTO) =>
    this.restService.request<any, ReminderDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/reminder/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}

  getRemainderList = (
    filter: ReminderFilter,
    pageNo: number,
    pageOffset: number
  ) =>
  this.restService.request<any, ReminderDTO[]>({
    method: 'GET',
    url: '/api/PlatformApp/reminder/remainder-list',
    params: { 
      filter,
      pageNo,
      pageOffset
     },
  },
  { apiName: this.apiName });

  getPatientRemainderList = (filter: ReminderFilter, patientId: string) =>
  this.restService.request<any, ReminderDTO[]>({
    method: 'GET',
    url: `/api/PlatformApp/reminder/patient-remainder-list/${patientId}`,
    params: { filter },
  },
  { apiName: this.apiName });
  
  getRemainderById = (id: string) =>
  this.restService.request<any, ReminderDTO[]>({
    method: 'GET',
    url: `/api/PlatformApp/reminder/${id}/remainder-by-id`,
  },
  { apiName: this.apiName });

  enableDisableReminderById = (id: string) =>
  this.restService.request<any, string>({
    method: 'POST',
    responseType: 'text',
    url: `/api/PlatformApp/reminder/${id}/enable-disable-reminder`,
  },
  { apiName: this.apiName });

  updateReminderStatus = (id: string) =>
  this.restService.request<any, string>({
    method: 'PUT',
    responseType: 'text',
    url: `/api/PlatformApp/reminder/${id}/reminder-status`,
  },
  { apiName: this.apiName });
}
