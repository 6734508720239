import type { AddressType } from '../../patient/enumeration-data/address-type.enum';
import type { drpContactType } from '../../patient/enumeration-data/drp-contact-type.enum';
import {
  AuditedEntityDto,
  FullAuditedEntityDto,
  mapEnumToOptions,
} from '@abp/ng.core';
import { InsuranceType } from '../../patient/enumeration-data/insurance-type.enum';
import {
  VerificationStatus,
  YearType,
} from '../../patient/enumeration-data/verification-status.enum';
import {
  authReference,
  authType,
} from '../../patient/enumeration-data/auth-type.enum';
import {
  ComplainceDTO,
  CreateUpdateComplainceDTO,
  CreateUpdateDemographicsDTO,
  CreateUpdatePrescriptionDTO,
  CreateUpdateSleepStudyDTO,
  InitialFaceToFaceDTO,
  PrescriptionDTO,
  SleepStudyDTO,
} from '../../patient/dto';
import type { yesOrNo } from '../../patient-optimization/yes-or-no.enum';
import { DisapprovedStatus } from '../../patient/enumeration-data/disapproved-status.enum';
import { MedicareCheckListStatus } from '../../patient/enumeration-data/medicare-check-list-status.enum';
import { Status } from '../../patient/enumeration-data';
import { SortType } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/sort-type.enum';
import { wipStatus } from '../../patient/enumeration-data/wip-status.enum';
import { FullAuditedAggregateRoot } from 'projects/admin/src/app/admin-proxy/platform-app-management/domain/entities/auditing/models';
import { inboundUploadType } from '../inbound-upload-type.enum';
import { patientExportOrder } from '../patient-export-order.enum';

export interface authReportDTO {
  id?: string;
  authId?: string;
  orderId?: string;
  defaultOrderId?: string;
  patientId?: string;
  defaultPatientId?: string;
  planType?: InsuranceType;
  planName?: string;
  authBeginDate?: string;
  authEndDate?: string;
  days: number;
  authStatus: FollowupType;
}
export enum FollowupType {
  All = 'All',
  Active = 'Active',
  AboutToExpire = 'About To Expire',
  Expired = 'Expired',
}

export const followUpTypeOptions = mapEnumToOptions(FollowupType);

export interface CreatePatientDocumentBlobDTO {
  faxId?: string;
  // patientId?: string;
  // documentId?: string;
  // fileContentAsBase64String?: string;
  // blobName?: string;
  // fileName?: string;
  // documentTypeId?: string;
  // reviewStatus?: string;
  // reviewReason?: string;
  // documentStartDate?: string;
  // documentEndDate?: string;
  // tenantId?: string;
  // note?: string;
  // docStatus?: string;
  // documentUploadType?: string;
  // isVerified: number;
  // documentType?: string;
  // wipId?: string;

  patientId?: string;
  inboundDocumentId?: string;
  pageNumber?: number;
  pageCount?: number;
  documentId?: string;
  fileContentAsBase64String?: string;
  blobName?: string;
  fileName?: string;
  documentTypeId?: string;
  documentTypeShortCode?: string;
  reviewStatus?: string;
  reviewReason?: string;
  documentStartDate?: string;
  documentEndDate?: string;
  tenantId?: string;
  wipId?: string;
  note?: string;
  docStatus?: string;
  documentUploadType?: string;
  isVerified?: number;
  documentType?: string;
  patientCount?: number;
  uploadType?: inboundUploadType;
  filePathPDF?: string;
  filePathTIFF?: string;
}

export interface CreateUpdateInsuranceBenefitInfoDTO {
  individual?: string;
  outOfNetwork?: string;
  family?: string;
}

export interface CreateUpdateOutOfPocketDeductibleDTO {
  individual: number;
  outOfNetwork: number;
  family: number;
}

export interface CreateUpdatePatientAddressDTO {
  patientId?: string;
  addressType: AddressType;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  zone?: string;
  nameLine?: string;
  description?: string;
  isGoogleValidated?: boolean;
  emailId?: string;
  isNewCity?: boolean;
}

export interface CreateUpdatePatientContactDTO {
  patientId?: string;
  contactType: drpContactType;
  relationshipId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  emailAddress?: string;
  isNewCity?: boolean;
}

export interface CreateUpdatePatientInsuranceDTO {
  patientId?: string;
  stateOfAutoAccident?: string;
  injuryRelatedToEmployment: number;
  injuryRelatedToAutoAccident: number;
  injuryRelatedToOtherAccident: number;
  dataOnset?: string;
  martialStatus?: string;
  employment?: string;
  printAmountsOnDeliveryTickets: number;
  patientFinancialHardship: number;
  discountPct?: string;
  startDate?: string;
  reviewDate?: string;
}

export interface PatientNotesSearchDTO extends PatientNotesDTO {
  defaultPatientId?: string;
  patientName?: string;
  reasonData?: string;
  userName?: string;
  statusData?: string;
  severityData?: string;
}
export interface CreateUpdatePatientNotesDTO {
  notesId?: string;
  patientId?: string;
  orderId?: string;
  claimId?: string;
  documentId?: string;
  patientDocChecklistId?: string;
  patientMedicareChecklistId?: string;
  patientVerificationId?: string;
  patientAuthorizationId?: string;
  defaultNotesId?: string;
  noteType: string;
  noteTypeId?: string;
  createdBy?: string;
  dateCreated?: string;
  actualDate?: string;
  dateNeeded?: string;
  severity?: string;
  status?: string;
  dateComplete?: string;
  subject?: string;
  description: string;
  modifiedBy?: string;
  createdByName?: string;
  modifiedDate?: string;
  assignedTo?: string;
  assignedDate?: string;
  isCallNote?: boolean;
  notesDetails?: string;
  tenantId?: string;
}
export interface InsuranceCategoryDTO extends AuditedEntityDto<string> {
  insuranceCategory?: string;
  status: boolean;
  isActive: Status;
  tenantId?: string;
  mmReferrenceId?: string;
  description?: string;
  defaultCategory?: number;
  estimatedCollectionPercent?: string;
  ddOrder: number;
  disabled: boolean;
  defaultDeductible?: number;
  preCertRequired: boolean;
  hideAllowed: boolean;
  maskCallIncrement: number;
  suppliesCallIncrement: number;
  requiresFunctionalityQuestions: boolean;
  noAllowedAmount: boolean;
  requiresPreAuthorization: boolean;
  isMedicare: boolean;
  orderMachinePoints: number;
  complianceMachinePoints: number;
  requiresMachineCompliance: boolean;
  assignPatientsToCustomerService: boolean;
  electronicVerification: boolean;
  docCheckListRequired: boolean;
  autoreminder: boolean;
}
export interface CreateUpdateInsuranceCategoryDTO {
  insuranceCategory?: string;
  description?: string;
  defaultCategory?: number;
  estimatedCollectionPercent?: string;
  ddOrder?: number;
  disabled?: boolean;
  defaultDeductible?: number;
  preCertRequired?: boolean;
  hideAllowed?: boolean;
  maskCallIncrement?: number;
  suppliesCallIncrement?: number;
  requiresFunctionalityQuestions?: boolean;
  noAllowedAmount?: boolean;
  requiresPreAuthorization?: boolean;
  isMedicare?: boolean;
  orderMachinePoints?: number;
  complianceMachinePoints?: number;
  requiresMachineCompliance?: boolean;
  assignPatientsToCustomerService?: boolean;
  electronicVerification?: boolean;
  docCheckListRequired?: boolean;
  autoreminder?: boolean;
}
export enum NoteType {
  general = 'General Note',
  generalNoted = 'GENERAL NOTED',
  financial = 'Financial Note',
  practitioner = 'Practitioner Note',
  patient = 'patient',
  order = 'Order Note',
  invoice = 'invoice',
  shipping = 'shipping',
  faxNote = 'Fax',
  others = 'Others',
}
export const noteTypeOptions = mapEnumToOptions(NoteType);
export interface PatientNotesDTO extends FullAuditedEntityDto<string> {
  notesId?: string;
  orderId?: string;
  claimId?: string;
  patientId?: string;
  defaultNotesId?: string;
  noteType: string;
  noteTypeId?: string;
  noteReason?: string;
  createdBy?: string;
  dateCreated?: string;
  actualDate?: string;
  dateNeeded?: string;
  severity?: string;
  status?: string;
  dateComplete?: string;
  subject?: string;
  description?: string;
  notesDetails?: string;
}

export interface CreateUpdatePatientPersonalDTO {
  defaultPatientId?: string;
  patientId?: string;
  title?: string;
  chartId?: string;
  locationType: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  fullName?: string;
  suffix?: string;
  dateOfBirth?: string;
  gender: string;
  maritalStatus?: string;
  emailId?: string;
  ssn?: string;
  accountNumber?: string;
  customerType?: string;
  accountGroupType: string;
  placeOfService?: string;
  dateOfAdmission?: string;
  dateOfDischarge?: string;
  auditTrialCreatedBy?: string;
  auditTrialCreatedDate?: string;
  colorCodingId?: string;
  providerId?: string;
  patientBalance: number;
  insuranceBalance: number;
  totalBalance: number;
  holdReason?: string;
  autoDunningId?: string;
  isAutoDunning: number;
  isCashPayment: number;
  isStat: number;
  authNotes?: string;
  authComments?: string;
  homePhoneNo?: string;
  workPhoneNo?: string;
  cellNo?: string;
  patientinfo?: string;
  inActiveDateTime?: string;
  tenantId?: string;
  locationId: string;
  emergencyContact?: string;
  alternatecontact?: string;
  patientAlerts?: string;
  patientCollection?: number;
  patientHippaSignatureOnLife?: number;
}

export interface CreateUpdatePatientPolicyDTO {
  patientId: string;
  docId?: string;
  policyId?: string;
  wipId?: string;
  payorLevel: InsuranceType;
  pVerifyPayorId?: string;
  pVerifyPayorName?: string;
  policyNo: string;
  groupNo?: string;
  zirmedPayorId?: string;
  zirmedPayorName?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  relationship?: string;
  birthdate?: string;
  gender?: string;
  ssn?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  mobile?: string;
  employerId?: string;
  note?: string;
  isActive: boolean;
  tenantId?: string;
  payorId?: string;
  payerId?: string;
  payorName?: string;
  eligibilityMode: number;
}

export interface VerificationData {
  verificationId?: string;
  policyId?: string;
  patientId?: string;
  verificationMethod?: string;
  verificationType?: string;
  verifiedBy?: string;
  dateVerified?: string;
  lastModifiedDate?: string;
  verificationStatus: VerificationStatus;
  updatedBy?: string;
  addedBy?: string;
}

export interface PatientPolicyDTO extends FullAuditedEntityDto<string> {
  patientId: string;
  policyId?: string;
  wipId?: string;
  payorLevel: InsuranceType;
  pVerifyPayorId?: string;
  pVerifyPayorName?: string;
  policyNo: string;
  zirmedPayorId?: string;
  zirmedPayorName?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  relationship?: string;
  birthdate?: string;
  gender?: string;
  groupNo?: string;
  ssn?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  mobile?: string;
  employerId?: string;
  note?: string;
  isActive: boolean;
  tenantId?: string;
  isEligibilityDone?: VerificationStatus;
  isPrecertRequired?: number;
  allowedPercentage?: string;
  payorId?: string;
  payerId?: string;
  payorName?: string;
  // typeofPlan: string;
  eligibilityMode: number;
  lastVerifiedDate?: string;
  verifiedBy?: string;
  verifiedByName?: string;
  lastVerificationDaysCount: number;
  verificationExpired?: string;
  lastVerificationId?: string;
  lastVerificationName?: string;
  verificationStatus?: string;
  typeofPlanId?: string;
  typeofPlan?: string;
  verificationMethod?: string;
  verificationType?: string;
  status: Status;
  isMedicarePolicy?: number;
  partyInsured?: number;
  coverageBegins?: string;
  coverageEnd?: string;
  copay?: number;
  planOrder?: number;
  promptforcopay?: boolean;
  authVisits?: number;
  authAmount?: number;
  deductible?: number;
  modifiedBy?: string;
  modifiedOn?: string;
  authBegins?: string;
  authEnds?: string;
  insertedOn?: string;
  insertedBy?: string;
  patEstPct?: number;
  couretesyBilling?: boolean;
  deletedFlag?: boolean;
  deletedBy?: string;
  deletedOn?: string;
  patientAssignBenefits?: string;
  interestApplies?: boolean;
  insId?: number;
  relationId?: number;
  patientInsured?: number;
  insuranceCategoryId?: string;
  insuranceCategoryName?: string;
  payerLevelId?: string;
  providerNpiDetails: providerNpiDetails;
  verificationData?: any;
  code?: string;
}

export interface providerNpiDetails {
  providerId?: string;
  providerName?: string;
  mmTaxId?: string;
  npi?: string;
}
export interface CreateUpdatePolicyPverifyDTO {
  patientId: string;
  policyId?: string;
  planType: string;
  payerCode: string;
  payerName?: string;
  providerLastName: string;
  providerNpi: string;
  patientFirstName: string;
  patientDob: string;
  patientLastName: string;
  memberID: string;
  dateOfService: string;
  referenceId?: string;
  location?: string;
  practiceTypeCode: string;
  includeTextResponse: boolean;
}

export interface DocumentGroupDTO {
  documentType?: string;
  documentCount: number;
  documentDetail: DocumentGroupDetailDTO[];
}
export interface CreateUpdateAllVerificationDTO {
  iMode: number;
  patientId: string;
  planType: string;
  payerCode: string;
  payerName?: string;
  zermidPayerName: string;
  providerNpi: string;
  providerLastName: string;
  patientFirstName: string;
  patientDob?: string;
  patientLastName: string;
  memberId: string;
  dateOfService: string;
  referenceId?: string;
  location?: string;
  practiceTypeCode: string;
  includeTextResponse: boolean;
  policyId?: string;
  payerId?: string;
  docId?: string;
}
export interface eligibilityview {
  pverify: PverifyDTO;
  zirmid?: string;
  changehealth: clsChEligibilityResponse;
}
export interface clsChEligibilityResponse {
  statusCode: number;
  error_description?: string;
  errors: clsChEligibityErrRes[];
  controlNumber?: string;
  reassociationKey?: string;
  tradingPartnerServiceId?: string;
  provider: clsChElgResProvider;
  subscriber: clsChElgResSubscriber;
  subscriberTraceNumbers: clsChElgResSubscriberTraceNumbers[];
  payer: clsChElgResPayer;
  planDateInformation: clsChElgResPlanDateInformation;
  planStatus: clsChElgResPlanStatus[];
  benefitsInformation: clsChElgResBenefitsInformation[];
}
export interface clsChAddress {
  address1?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export interface clsChDependents {
  firstName?: string;
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  groupNumber?: string;
  memberId?: string;
  paymentResponsibilityLevelCode?: string;
  relationshipToSubscriberCode?: string;
  address: clsChAddress;
  policyNumber?: string;
}

export interface clsChElgResAdditionalInformation {
  description?: string;
}

export interface clsChElgResBenefitsAdditionalInformation {
  planNumber?: string;
  planNetworkIdNumber?: string;
}

export interface clsChElgResBenefitsDateInformation {
  plan?: string;
  benefit?: string;
}

export interface clsChElgResBenefitsInformation {
  code?: string;
  name?: string;
  serviceTypeCodes: string[];
  serviceTypes: string[];
  insuranceTypeCode?: string;
  insuranceType?: string;
  timeQualifierCode?: string;
  timeQualifier?: string;
  benefitAmount?: string;
  benefitPercent?: string;
  headerLoopIdentifierCode?: string;
  trailerLoopIdentifierCode?: string;
  benefitsDateInformation: clsChElgResBenefitsDateInformation;
  additionalInformation: clsChElgResAdditionalInformation[];
  benefitsAdditionalInformation: clsChElgResBenefitsAdditionalInformation;
  benefitsRelatedEntity: clsChElgResBenefitsRelatedEntity;
  benefitsRelatedEntities: clsChElgResBenefitsRelatedEntities[];
}

export interface clsChElgResBenefitsRelatedEntities {
  entityIdentifier?: string;
  entityType?: string;
  entityName?: string;
  address: clsChAddress;
  contactInformation: clsChElgResContactInformation;
}

export interface clsChElgResBenefitsRelatedEntity {
  entityIdentifier?: string;
  entityType?: string;
  entityName?: string;
  address: clsChAddress;
  contactInformation: clsChElgResContactInformation;
}

export interface clsChElgResContactInformation {
  contacts: clsChElgResContacts[];
}

export interface clsChElgResContacts {
  communicationMode?: string;
  communicationNumber?: string;
}

export interface clsChElgResPayer {
  entityIdentifier?: string;
  entityType?: string;
  name?: string;
  payorIdentification?: string;
}

export interface clsChElgResPlanDateInformation {
  eligibility?: string;
}

export interface clsChElgResPlanStatus {
  statusCode?: string;
  status?: string;
  serviceTypeCodes: string[];
}

export interface clsChElgResProvider {
  providerName?: string;
  entityIdentifier?: string;
  entityType?: string;
  npi?: string;
}

export interface clsChElgResSubscriber {
  memberId?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  gender?: string;
  entityIdentifier?: string;
  entityType?: string;
  dateOfBirth?: string;
  relationToSubscriber?: string;
  insuredIndicator?: string;
  maintenanceTypeCode?: string;
  maintenanceReasonCode?: string;
  address: clsChAddress;
}

export interface clsChElgResSubscriberTraceNumbers {
  traceTypeCode?: string;
  traceType?: string;
  referenceIdentification?: string;
  originatingCompanyIdentifier?: string;
}

export interface clsChEligibilityResponse {
  statusCode: number;
  error_description?: string;
  errors: clsChEligibityErrRes[];
  controlNumber?: string;
  reassociationKey?: string;
  tradingPartnerServiceId?: string;
  provider: clsChElgResProvider;
  subscriber: clsChElgResSubscriber;
  subscriberTraceNumbers: clsChElgResSubscriberTraceNumbers[];
  payer: clsChElgResPayer;
  planDateInformation: clsChElgResPlanDateInformation;
  planStatus: clsChElgResPlanStatus[];
  benefitsInformation: clsChElgResBenefitsInformation[];
}

export interface clsChEligibityErrRes {
  field?: string;
  description?: string;
  code?: string;
  followupAction?: string;
  location?: string;
}

export interface clsChEncounter {
  beginningDateOfService?: string;
  endDateOfService?: string;
  serviceTypeCodes: string[];
  trackingNumber?: string;
}

export interface clsChProvider {
  organizationName?: string;
  npi?: string;
  serviceProviderNumber?: string;
  providerCode?: string;
  referenceIdentification?: string;
}
export interface clsChDependents {
  firstName?: string;
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  groupNumber?: string;
  memberId?: string;
  paymentResponsibilityLevelCode?: string;
  relationshipToSubscriberCode?: string;
  address: clsChAddress;
  policyNumber?: string;
}

export interface clsChSubscriber {
  memberId?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  ssn?: string;
  idCard?: string;
  policyNumber?: string;
  groupNumber?: string;
  paymentResponsibilityLevelCode?: string;
  address: clsChAddress;
}

export interface PverifyDTO {
  requestID: number;
  apiResponseCode?: string;
  apiResponseMessage?: string;
  ediErrorMessage: object;
  isPayerBackOffice: boolean;
  pverifyPayerCode?: string;
  payerName?: string;
  verificationType?: string;
  dos?: string;
  exceptionNotes?: string;
  isHMOPlan: boolean;
  addtionalInfo?: string;
  location?: string;
  referrenceId?: string;
  internalId: object;
  customerId: object;
  notes: object;
  resultPracticeType?: string;
  areAllSTCsProcessed: boolean;
  stCsStatusMessage?: string;
  isProviderInNetwork: object;
  recursiveRequestId: number;
  detailsURL: object;
  demographicInfo: DemographicInfo;
  otherPayerInfo: object;
  planCoverageSummary: PlanCoverageSummary;
  pcpAuthInfoSummary: PCPAuthInfoSummary;
  hbpc_Deductible_OOP_Summary: HBPCDeductibleOOPSummary;
  medicareInfoSummary: object;
  miscellaneousInfoSummary: MiscellaneousInfoSummary;
  specialistOfficeSummary: SpecialistOfficeSummary;
  disclaimerMessage?: string;
  preventiveServices: object;
  serviceDetails: ServiceDetail[];
  eligibilityResult: object;
  nonEDIResultPath: object;
  dentalInfo: object;
}
export interface CoInsInNet {
  value?: string;
  notes?: string;
}
export interface ServiceDetail {
  serviceName?: string;
  eligibilityDetails: EligibilityDetail[];
}

export interface EligibilityDetail {
  authorizationOrCertificationRequired?: string;
  eligibilityOrBenefit?: string;
  coverageLevel?: string;
  date: Date[];
  identifications: object[];
  insuranceType: object;
  message: string[];
  monetaryAmount?: string;
  percent?: string;
  planCoverageDescription?: string;
  planNetworkIndicator?: string;
  quantityQualifier?: string;
  quantity?: string;
  timePeriodQualifier?: string;
  procedure: object;
  placeOfService?: string;
  benefitEntities: BenefitEntity[];
  healthCareServiceDeliveries: HealthCareServiceDelivery[];
}
export interface HealthCareServiceDelivery {
  quantityQualifier?: string;
  totalQuantity?: string;
  measurementUnit?: string;
  measurementPeriod: object;
  timePeriodQualifier?: string;
  totalNumberOfPeriods?: string;
  deliveryFrequency: object;
  deliveryPatternTime: object;
}
export interface CommunicationNumber {
  number?: string;
  type?: string;
}
export interface BenefitEntity {
  address1?: string;
  address2: object;
  city?: string;
  communicationNumber: CommunicationNumber[];
  entityType?: string;
  firstname: object;
  identificationCode: object;
  identificationType: object;
  lastname_R?: string;
  middlename: object;
  state?: string;
  suffix: object;
  zip?: string;
  providerTaxonomy: object;
}
export interface CoInsOutNet {
  value?: string;
  notes?: string;
}

export interface CoPayInNet {
  value?: string;
  notes?: string;
}

export interface CoPayOutNet {
  value?: string;
  notes?: string;
}

export interface SpecialistOfficeSummary {
  coPayInNet: CoPayInNet;
  coInsInNet: CoInsInNet;
  coPayOutNet: CoPayOutNet;
  coInsOutNet: CoInsOutNet;
  umoName: object;
  umoTelephone: object;
  inNetServiceAuthorizationInfo?: string;
  outNetServiceAuthorizationInfo: object;
  individualDeductibleInNet: IndividualDeductibleInNet;
  individualDeductibleOutNet: IndividualDeductibleOutNet;
  individualDeductibleRemainingInNet: IndividualDeductibleRemainingInNet;
  individualDeductibleRemainingOutNet: IndividualDeductibleRemainingOutNet;
  familyDeductibleInNet: FamilyDeductibleInNet;
  familyDeductibleOutNet: FamilyDeductibleOutNet;
  familyDeductibleRemainingInNet: FamilyDeductibleRemainingInNet;
  familyDeductibleRemainingOutNet: FamilyDeductibleRemainingOutNet;
  individualOOP_InNet: IndividualOOPInNet;
  individualOOP_OutNet: IndividualOOPOutNet;
  individualOOPRemainingInNet: IndividualOOPRemainingInNet;
  individualOOPRemainingOutNet: IndividualOOPRemainingOutNet;
  familyOOPInNet: FamilyOOPInNet;
  familyOOPOutNet: FamilyOOPOutNet;
  familyOOPRemainingInNet: FamilyOOPRemainingInNet;
  familyOOPRemainingOutNet: FamilyOOPRemainingOutNet;
}
export interface MiscellaneousInfoSummary {
  remainingSpendDown: object;
  isNPIInNetwork: object;
  memberID?: string;
}
export interface DemographicInfo {
  subscriber: Subscriber;
  dependent: object;
}
export interface Subscriber {
  address1?: string;
  address2: object;
  city?: string;
  communicationNumber?: string;
  date: Date[];
  dob_R?: string;
  firstname?: string;
  gender_R?: string;
  identification: Identification[];
  lastname_R?: string;
  middlename: object;
  state?: string;
  suffix: object;
  zip?: string;
  militaryPersonnelInfo: object;
  fullName?: string;
}
export interface Identification {
  code?: string;
  type?: string;
  name: object;
}
export interface PlanCoverageSummary {
  status?: string;
  effectiveDate?: string;
  expiryDate?: string;
  planName?: string;
  policyType?: string;
  groupNumber?: string;
  groupName?: string;
  planNetworkID: object;
  planNetworkName: object;
  subscriberRelationship: object;
  planNumber: object;
  hrAorHSALimitationsRemaining: object;
  lastUpdatedDateOfEDI: object;
  patientGender: object;
  benefitPlanCode: object;
}
export interface PCPAuthInfoSummary {
  primaryCareProviderName: object;
  pcpStartDate: object;
  primaryCareProviderPhoneNumber: object;
  inNetHBPCAuthorizationInfo: object;
  outNetHBPCAuthorizationInfo: object;
  utilizationManagementOrganizationName: object;
  umoTelephone: object;
  capitationFacilityName: object;
}
export interface HBPCDeductibleOOPSummary {
  individualDeductibleInNet: IndividualDeductibleInNet;
  individualDeductibleOutNet: IndividualDeductibleOutNet;
  individualDeductibleRemainingInNet: IndividualDeductibleRemainingInNet;
  individualDeductibleRemainingOutNet: IndividualDeductibleRemainingOutNet;
  familyDeductibleInNet: FamilyDeductibleInNet;
  familyDeductibleOutNet: FamilyDeductibleOutNet;
  familyDeductibleRemainingInNet: FamilyDeductibleRemainingInNet;
  familyDeductibleRemainingOutNet: FamilyDeductibleRemainingOutNet;
  individualOOP_InNet: IndividualOOPInNet;
  individualOOP_OutNet: IndividualOOPOutNet;
  individualOOPRemainingInNet: IndividualOOPRemainingInNet;
  individualOOPRemainingOutNet: IndividualOOPRemainingOutNet;
  familyOOPInNet: FamilyOOPInNet;
  familyOOPOutNet: FamilyOOPOutNet;
  familyOOPRemainingInNet: FamilyOOPRemainingInNet;
  familyOOPRemainingOutNet: FamilyOOPRemainingOutNet;
}
export interface IndividualDeductibleInNet {
  value?: string;
  notes?: string;
}

export interface IndividualDeductibleOutNet {
  value?: string;
  notes?: string;
}

export interface IndividualDeductibleRemainingInNet {
  value?: string;
  notes?: string;
}

export interface IndividualDeductibleRemainingOutNet {
  value?: string;
  notes?: string;
}

export interface IndividualOOPInNet {
  value?: string;
  notes?: string;
}

export interface IndividualOOPOutNet {
  value?: string;
  notes?: string;
}

export interface FamilyDeductibleInNet {
  value?: string;
  notes?: string;
}

export interface FamilyDeductibleOutNet {
  value?: string;
  notes?: string;
}

export interface FamilyDeductibleRemainingInNet {
  value?: string;
  notes?: string;
}

export interface FamilyDeductibleRemainingOutNet {
  value?: string;
  notes?: string;
}

export interface FamilyOOPInNet {
  value?: string;
  notes?: string;
}

export interface FamilyOOPOutNet {
  value?: string;
  notes?: string;
}

export interface FamilyOOPRemainingInNet {
  value?: string;
  notes?: string;
}

export interface FamilyOOPRemainingOutNet {
  value?: string;
  notes?: string;
}

export interface IndividualOOPRemainingInNet {
  value?: string;
  notes?: string;
}

export interface IndividualOOPRemainingOutNet {
  value?: string;
  notes?: string;
}

export interface DocumentGroupDetailDTO {
  documentId?: string;
  blobName?: string;
  fileName?: string;
  uploadedDate?: string;
}

export interface InsuranceBenefitInfoDTO {
  individual?: string;
  outOfNetwork?: string;
  family?: string;
}

export interface OutOfPocketDeductibleDTO {
  individual: number;
  outOfNetwork: number;
  family: number;
}

export interface PatientAddressDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  addressType: AddressType;
  addressId?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  phone?: string;
  homePhoneNo?: string;
  fax?: string;
  mobile?: string;
  zone?: string;
  nameLine?: string;
  description?: string;
  emailId?: string;
  mmRefId?: number;
  isGoogleValidated?: boolean;
  isNewCity?: boolean;
}

export interface patientAuthSearchDTO {
  sPatientGuid?: string;
  sDefaultPatientNo?: string;
  sPatientName?: string;
  dtCreatedDate?: string;
  dtAuthBeginDate?: string;
  dtAuthEndDate?: string;
  sOrderId?: string;
  sItem?: string;
  sUnits?: string;
  sUsed?: string;
  sAuthType?: string;
  sDays?: string;
  sAmount?: string;
  sSaleOrRent?: string;
  sAuthNo?: string;
  sPlanName?: string;
  sStatus?: string;
}

export interface PatientAuthorizationDTO extends FullAuditedEntityDto<string> {
  mmPrecertId?: number;
  mmPrecertAuthId?: number;
  patientId?: string;
  policyId?: string;
  planType?: InsuranceType;
  planName?: string;
  typeofPlanId?: string;
  verificationId?: string;
  orderId: string[];
  sortedDocsId: string[];
  authId?: string;
  startDate?: string;
  startDate1?: string;
  endDate?: string;
  endDate1?: string;
  isExpired?: number;
  tenantId?: string;
  reference?: authReference;
  spokeWith?: string;
  callDate?: string;
  callDate1?: string;
  creationTime1?: string;
  lastmodifedDate1?: string;
  faxNumber?: string;
  authStatus?: string;
  previousStatus?: string;
  wipId?: string;
  documentId?: string;
  authFileContent?: string;
  authFileBlobName?: string;
  authStatusName?: string;
  planTypeName?: string;
  referenceName?: string;
  authorizationNotes: string[];
  authorizationId?: string;
  isSTAT?: boolean;
  isLock?: boolean;
  lockedBy?: string;
  lockedAT?: string;
  lockedAT1?: string;
  notificationMessage?: string;
  used?: string;
  chartId?: string;
  cptPrecercodes: CPTOxygenDTO;
  supplies: SuppliesDiagnosisDTO[];
  oxygenPrecercodes: CPTOxygenDTO;
  diagnosis: SuppliesDiagnosisDTO[];
  note?: string;
}

// export interface PatientAuthorizationDTO extends FullAuditedEntityDto<string> {
//   patientId?: string;
//   policyId?: string;
//   orderId: string[];
//   authDocsId: string[];
//   sortedDocsId: string[];
//   authorizationId?: string;
//   authId?: string;
//   planName?: string;
//   authAmount?: string;
//   startDate?: string;
//   endDate?: string;
//   itemAuthDetails: ItemAuthDetailsDTO[];
//   note?: string;
//   used?: string;
//   isExpired?: number;
//   tenantId?: string;
//   reference?: authReference;
//   planType?: InsuranceType;
//   spokeWith?: string;
//   faxNumber?: string;
//   notificationMessage?: string;
//   authStatus?: string;
//   authStatusName?: string;
//   wipId?: string;
//   authFileContent?: string;
//   planTypeName?: string;
//   referenceName?: string;
//   typeofPlanId?: string;
//   patientInsId?: string;
//   authNo?: string;
//   authVisits?: string;
//   planAuthCategoryID?: string;
//   authCode?: string;
//   completedOn?: string;
//   completed?: boolean;
//   completedBy?: number;
// }

export interface authSearchDTO {
  mAuthType: string;
  patientId?: string;
  orderId?: string[];
  authorizationId?: string;
  authId?: string;
  item?: string;
  authBeginDate?: string;
  authEndDate?: string;
  defaultPatientId?: string;
  authType: authType;
  patientName?: string;
  createdDate?: string;
  planName?: string;
  status?: string;
  saleOrRent?: string;
  planType: InsuranceType;
  days: number;
  isFollowUp: number;
  units?: string;
  used?: string;
  amount?: string;
  itemName?: string[];
}
export interface CreateUpdatePatientAuthorizationDTO {
  authorizationId?: string;
  mmPrecertId?: number;
  mmPrecertAuthId?: number;
  patientId?: string;
  policyId?: string;
  planType?: InsuranceType;
  planName?: string;
  typeofPlanId?: string;
  verificationId?: string;
  orderId: string[];
  sortedDocsId: string[];
  authId?: string;
  startDate?: string;
  endDate?: string;
  isExpired?: number;
  tenantId?: string;
  reference?: authReference;
  spokeWith?: string;
  note?: string;
  callDate?: string;
  faxNumber?: string;
  authStatus?: string;
  previousStatus?: string;
  wipId?: string;
  documentId?: string;
  authFileContent?: string;
  authFileBlobName?: string;
  authorizationNotes: string[];
  isSTAT?: boolean;
  isLock?: boolean;
  lockedBy?: string;
  lockedAT?: string;
  isCompliance: boolean;
  complianceDate: number;
  isAfterFirstMonth: boolean;
  isDuringThirdMonth: boolean;
  other: boolean;
  cptPrecercodes: CPTOxygenDTO;
  supplies: SuppliesDiagnosisDTO[];
  oxygenPrecercodes: CPTOxygenDTO;
  diagnosis: SuppliesDiagnosisDTO[];
}

// export interface PatientClinicalDTO extends FullAuditedEntityDto<string> {
//   patientId: string;
//   height: number;
//   weight: number;
//   dod?: string;
//   functionalAbility?: string;
//   generalPractitioner?: string;
//   infectiousConditon: number;
//   airborne: number;
//   contact: number;
//   droplet: number;
//   referringProviderType?: string;
//   referringProviderDoctor?: string;
//   referringProviderFacility?: string;
//   primaryDoctor?: string;
//   marketingReferralType?: string;
//   marketingReferralDoctor?: string;
//   marketingReferralFacility?: string;
//   marketingReferralContact?: string;
//   diagnosisCode9: string[];
//   diagnosisCode10: string[];
//   tenantId?: string;
// }

export interface PatientClinicalDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  height: number;
  weight: number;
  dod?: string;
  functionalAbility?: string;
  generalPractitioner?: string;
  infectiousConditon: number;
  airborne: number;
  contact: number;
  droplet: number;
  referringProviderType?: string;
  referringProviderDoctorId?: string;
  referringProviderDoctor?: string;
  referringProviderFacilityId?: string;
  referringProviderFacility?: string;
  primaryDoctor?: string;
  primaryDoctorId?: string;
  marketingReferralType?: string;
  marketingReferralDoctor?: string;
  marketingReferralFacility?: string;
  marketingReferralContact?: string;
  diagnosisCode9: string[];
  diagnosisCode10: string[];
  tenantId?: string;
  referralsourceId?: string;
  referalpracticeId?: string;
  doctorname?: string;
  otherFacility: OtherFacilityDTO[];
  otherDoctor: OtherDoctorDTO[];
}
export interface OtherFacilityDTO {
  id?: string;
  practiceName?: string;
  creatorId?: string;
  creationName?: string;
  creationTime?: string;
  address?: string;
}
export interface OtherDoctorDTO {
  id?: string;
  npi?: string;
  doctorName?: string;
  mailingAddress?: string;
  primaryAddress?: string;
  secondaryAddress?: string;
  creatorId?: string;
  creationName?: string;
  creationTime?: string;
}

export interface CreateUpdatePatientClinicalDTO {
  patientId: string;
  height: number;
  weight: number;
  dod?: string;
  functionalAbility?: string;
  generalPractitioner?: string;
  infectiousConditon: number;
  airborne: number;
  contact: number;
  droplet: number;
  referringProviderType?: string;
  referringProviderDoctor?: string;
  referringProviderFacility?: string;
  referringProviderDoctorId?: string;
  referringProviderFacilityId?: string;
  primaryDoctor?: string;
  primaryDoctorId?: string;
  marketingReferralType?: string;
  marketingReferralDoctor?: string;
  marketingReferralDoctorId?: string;
  marketingReferralFacility?: string;
  marketingReferralContact?: string;
  diagnosisCode9: string[];
  diagnosisCode10: string[];
  tenantId?: string;
}

export interface PatientContactDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  contactType: drpContactType;
  relationshipId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  emailAddress?: string;
}

export interface PatientDocumentBlobDTO extends AuditedEntityDto<string> {
  patientId?: string;
  documentId?: string;
  fileName?: string;
  fileContentAsBase64String?: string;
  blobName?: string;
  documentTypeId?: string;
  reviewStatus?: string;
  reviewReason?: string;
  documentStartDate?: string;
  documentEndDate?: string;
  tenantId?: string;
  wipId?: string;
  note?: string;
  docStatus?: string;
  fileNameType?: string;
  documentUploadType?: string;
  isVerified: number;
  documentType?: string;
}

export interface PatientInsuranceDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  stateOfAutoAccident?: string;
  injuryRelatedToEmployment: number;
  injuryRelatedToAutoAccident: number;
  injuryRelatedToOtherAccident: number;
  dataOnset?: string;
  martialStatus?: string;
  employment?: string;
  printAmountsOnDeliveryTickets: number;
  patientFinancialHardship: number;
  discountPct?: string;
  startDate?: string;
  reviewDate?: string;
}

export interface patientSearchDTO {
  chartId?: string;
  patientId?: string;
  defaultPatientId?: string;
  patientName?: string;
  dob?: string;
  ssn?: string;
  emailId?: string;
  mobile?: string;
  colorCode?: string;
  postalCode?: string;
  colorCodeName?: string;
  modifiedDate?: string;
  createdDate?: string;

  homePhone?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  cellNo?: string;
  csrName?: string;
  csr?: string;
  locationId?: string;
  branch?: string;
  wipStatus?: wipStatus;
  wipStatusId?: string;
  wipColorCode?: string;
  setupDate?: string;
  maskDate?: string;
  nextCallDate?: string;
  rxDate?: string;
  checkListDate?: string;
  lastMask?: string;
  lastCushion?: string;
  primaryInsuranceName?: string;
  ordering?: string;
  referralPractice?: string;
  defaulWipId?: string;
  wipId?: string;
  documentId?: string;
  policyId?: string;
  policyName?: string;
  ltInboundStausId: string[];
  ltInboundStaus: string[];
  referringPhysicianId?: string;
  referringPhysician?: string;
  reminderEmail?: string;
  priPolicyId?: string;
  secPolicyId?: string;
  terPolicyId?: string;
  isPolicy: boolean;
  isMedicarePolicy?: number;
  faxId?: string;
  blobname?: string;
  createdBy?: string;
  csrStatus?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  isSorted?: boolean;
  mode?: number[];
  modeName?: string[];
  isMessageSent?: number;
  isMailsSent?: number;
  isWhatsappSent?: number;
  messageSentDate?: string;
  mailSentDate?: string;
  whatsappSentDate?: string;
  creatorId?: string;
}

export interface patientCalenderDetails {
  patientId?: string;
  patientName?: string;
  chartId?: string;
  dateOfBirth?: string;
  patientDetails?: string;
}

export interface PatientPersonalDTO extends AuditedEntityDto<string> {
  defaultPatientId?: string;
  patientId?: string;
  chartId?: string;
  locationType: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  fullName?: string;
  suffix?: string;
  dateOfBirth?: string;
  gender: string;
  maritalStatus?: string;
  emailId?: string;
  ssn?: string;
  accountNumber?: string;
  customerType?: string;
  accountGroupType: string;
  placeOfService?: string;
  dateOfAdmission?: string;
  dateOfDischarge?: string;
  auditTrialCreatedBy?: string;
  auditTrialCreatedDate?: string;
  colorCodingId?: string;
  providerId?: string;
  patientBalance: number;
  insuranceBalance: number;
  totalBalance: number;
  holdReason?: string;
  autoDunningId?: string;
  isAutoDunning: number;
  isCashPayment: number;
  isStat: number;
  authNotes?: string;
  authComments?: string;
  homePhoneNo?: string;
  workPhoneNo?: string;
  cellNo?: string;
  patientinfo?: string;
  inActiveDateTime?: string;
  tenantId?: string;
  relationshipId: string;
  locationId: string;

  createdBy?: string;
  previousCsr?: string;
  doNotCallReason?: string;
  csr?: string;
  title?: string;
  locationName?: string;
  authstatus?: string;
  authstatusName?: string;
  emergencyContact?: string;
  alternatecontact?: string;
  serviceTypeId?: string;
  serviceTypeName?: string;
  provider?: string;
  address?: string;
  memo?: string;
  modifiedBy?: string;
  modifiedOn?: string;
  referralpracticeId?: string;
  donotCallReasonId?: string;
  lastCheckListverifiedDate?: string;
  isMailSent?: boolean;
  mailSentDate?: string;
  mailReminderEnable?: string;
  patientCollection?: number;
  patientHippaSignatureOnLife?: number;
  patientAlerts?: string;
  inboundDocumentID?: string;
  defaultFaxId?: string;
  sDateOfBirth?: string;
}

export interface PatientSummaryDTO {
  patientId: string;
  defaultPatientId?: string;
  chartId?: string;
  orderId?: string;
  defaultOrderId: string;
  patientName?: string;
  dob?: string;
  currentStatus?: string;
  billedAmount?: string;
  receivedAmount?: string;
  pendingAmount?: string;
  billingStatus?: string;
  thirtyDayBilledAmnt?: string;
  sixtyDayBilledAmnt?: string;
  ninetyDayBilledAmnt?: string;
  aboveNinetyDayBilledAmnt?: string;
  gender: string;
  adjustmentAmount?: string;
  patientAge?: string;
  formattedDOB?: string;
  locationName?: string;
}

export interface UpdateDocumentBlobDTO {
  blobName?: string;
  documentTypeId?: string;
  reviewReason?: string;
  reviewStatus?: string;
  documentStartDate?: string;
  documentEndDate?: string;
  tenantId?: string;
  note?: string;
  documentType?: string;
}
export interface PatientVerificationDTO extends FullAuditedEntityDto<string> {
  // requestID: number;
  apiResponseCode?: string;
  apiResponseMessage?: string;
  verificationId?: string;
  patientId?: string;
  docId?: string;
  policyId?: string;
  payerId?: string;
  chartId?: string;
  defaultPatientId?: string;
  name?: string;
  address?: string;
  expiryStatus?: string;
  dateofBirth?: string;
  dateofBirth1?: string;
  commentsOrNotes?: string;
  plan?: InsuranceType;
  planName?: string;
  planPhone?: string;
  policy?: string;
  groupNo?: string;
  type?: YearType;
  effectiveDate?: string;
  effectiveDate1?: string;
  typeOfPolicy?: string;
  fascalYearStart?: string;
  fascalYearEnd?: string;
  fascalYearStart1?: string;
  fascalYearEnd1?: string;
  isPatientHomeHealthEpisode?: boolean;
  medicareCMNonFile?: boolean;
  familyDeductCoversIndividual?: boolean;
  cmnCode?: string;
  deo?: string;
  typeOfPlanId?: string;
  typeOfPlan?: string;
  isIndividual: number;
  deductible?: string;
  deductibleMet?: string;
  payableAt?: string;
  outOfPocket?: string;
  outOfPocketMet?: string;
  doesOutOfPocketIncludeDed?: number;
  isFamily: number;
  familyDeductible?: string;
  familyDeductibleMet?: string;
  familyPayableAt?: string;
  familyOutOfPocket?: string;
  familyOutOfPocketMet?: string;
  doesFamilyOutOfPocketIncludeDed?: number;
  isOutOfNetwork: number;
  oonDeductible?: string;
  oonDeductibleMet?: string;
  oonPayableAt?: string;
  oonOutOfPocket?: string;
  oonOutOfPocketMet?: string;
  doesOonOutOfPocketIncludeDed?: number;
  lmnReq?: boolean;
  isPrecertRequired?: number;
  preCertNumber?: string;
  phoneNumber?: string;
  pcp?: string;
  referralRequired?: boolean;
  predetermination?: boolean;
  predeterminationNotes?: string;
  lifetimeMaxBenefit?: string;
  lifetimeBenefitRemaining?: string;
  lifetimeBenefitUsed?: string;
  dmeReplacement?: number;
  dmeOftenSupplies?: number;
  dmeUnitReplacement?: number;
  dmeUnitOften?: number;
  dmeLimitPerYear?: number;
  dmeLimitAmount?: number;
  dmeLimitMet?: number;
  requestedBy?: string;
  primaryPayor?: string;
  contact?: string;
  callTrackingNumber?: string;
  policyHolderName?: string;
  policyHolderDob?: string;
  policyHolderDob1?: string;
  isNetwork: number;
  claimAddress?: string;
  planCob?: string;
  planCob1?: string;
  coverageCriteria?: string;
  qtyLimits?: string;
  frequency?: string;
  rent?: string;
  paRequired?: string;
  codesRequirePa?: string;
  sameOrSimilarInfo?: string;
  spokeWith?: string;
  verifiedBy?: string;
  verifiedByUser?: string;
  verifiedOn?: string;
  verifiedOn1?: string;
  approvedBy?: string;
  approvedOn?: string;
  approvedOn1?: string;
  approvalStatus?: string;
  verificationStatus: VerificationStatus;
  vMethod: string;
  isActive: number;
  tenantId?: string;
  lockedBySPID?: string;
  pending?: boolean;
  masterVerificationNote?: string;
  onlineInfo?: string;
  countTypeId?: number;
  commentToTech?: string;
  referralPracticeId: number;
  benefitColumn?: string;
  stat?: boolean;
  relationToInsured?: string;
  moveToAuthStatus?: string;
  reason?: string;
  lastVerificationId?: string;
  eligibilityMode: number;
  chElgControlNumber?: string;
  coPayment?: string;
  payorCode?: string;
  payorName?: string;
  payorPlanType?: string;
  coInsurance?: string;
  dmeCoverage?: number;
  dmeMax?: string;
  hcpcs?: string;
  isMedicarePolicy?: boolean;
}

export interface CreateUpdatePatientVerificationDTO {
  verificationId?: string;
  patientId?: string;
  docId?: string;
  policyId?: string;
  payerId?: string;
  chartId?: string;
  defaultPatientId?: string;
  name?: string;
  address?: string;
  dateofBirth?: string;
  commentsOrNotes?: string;
  plan?: InsuranceType;
  planName?: string;
  planPhone?: string;
  policy?: string;
  groupNo?: string;
  type?: YearType;
  effectiveDate?: string;
  typeOfPolicy?: string;
  fascalYearStart?: string;
  fascalYearEnd?: string;
  isPatientHomeHealthEpisode?: boolean;
  medicareCMNonFile?: boolean;
  familyDeductCoversIndividual?: boolean;
  cmnCode?: string;
  deo?: string;
  typeOfPlanId?: string;
  typeOfPlan?: string;
  isIndividual: number;
  deductible?: string;
  deductibleMet?: string;
  payableAt?: string;
  outOfPocket?: string;
  outOfPocketMet?: string;
  doesOutOfPocketIncludeDed?: number;
  isFamily: number;
  familyDeductible?: string;
  familyDeductibleMet?: string;
  familyPayableAt?: string;
  familyOutOfPocket?: string;
  familyOutOfPocketMet?: string;
  doesFamilyOutOfPocketIncludeDed?: number;
  isOutOfNetwork: number;
  oonDeductible?: string;
  oonDeductibleMet?: string;
  oonPayableAt?: string;
  oonOutOfPocket?: string;
  oonOutOfPocketMet?: string;
  doesOonOutOfPocketIncludeDed?: number;
  lmnReq?: boolean;
  isPrecertRequired?: number;
  preCertNumber?: string;
  phoneNumber?: string;
  pcp?: string;
  referralRequired?: boolean;
  predetermination?: boolean;
  predeterminationNotes?: string;
  lifetimeMaxBenefit?: string;
  lifetimeBenefitRemaining?: string;
  lifetimeBenefitUsed?: string;
  dmeReplacement?: number;
  dmeCoverage?: number;
  dmeOftenSupplies?: number;
  dmeUnitReplacement?: number;
  dmeUnitOften?: number;
  dmeLimitPerYear?: number;
  dmeLimitAmount?: number;
  dmeLimitMet?: number;
  requestedBy?: string;
  primaryPayor?: string;
  contact?: string;
  callTrackingNumber?: string;
  policyHolderName?: string;
  policyHolderDob?: string;
  isNetwork: number;
  claimAddress?: string;
  planCob?: string;
  coverageCriteria?: string;
  qtyLimits?: string;
  frequency?: string;
  rent?: string;
  paRequired?: string;
  codesRequirePa?: string;
  sameOrSimilarInfo?: string;
  spokeWith?: string;
  verifiedBy?: string;
  verifiedByUser?: string;
  verifiedOn?: string;
  approvedBy?: string;
  approvedOn?: string;
  approvalStatus?: string;
  verificationStatus: VerificationStatus;
  vMethod: string;
  isActive: number;
  tenantId?: string;
  lockedBySPID?: string;
  pending?: boolean;
  masterVerificationNote?: string;
  onlineInfo?: string;
  countTypeId?: number;
  commentToTech?: string;
  referralPracticeId: number;
  benefitColumn?: string;
  stat?: boolean;
  relationToInsured?: string;
  moveToAuthStatus?: string;
  reason?: string;
  eligibilityMode: number;
  chElgControlNumber?: string;
  coPayment?: string;
  payorCode?: string;
  payorName?: string;
  payorPlanType?: string;
  coInsurance?: string;
  dmeMax?: string;
  hcpcs?: string;
}

export interface GetNPI_TaxId {
  mmTaxId?: string;
  npi?: string;
}

export interface SortedDocumentGroupDTO {
  documentType: string;
  documentTypeId: string;
  pageCount: number;
  fileContent: string;
}
export interface ProviderNPIdetailsDTO {
  note?: string;
  faxNumber?: string;
  ptan?: string;
  tpi?: string;
  payorPhone?: string;
  npi?: string;
  mmTaxId?: string;
  homeAddress?: string;
  providerstate?: string;
  providerCity?: string;
  providerzip?: string;
  providerAddress?: string;
  defaultPatientId?: string;
  chartId?: string;
  saleOrderId?: string;
  defaultSaleOrderId?: string;
  defaultWipId?: string;
  patientName?: string;
  dateofbirth?: string;
  memberId?: string;
  doctorNPI?: string;
  doctorFax?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  location?: string;
  payerName?: string;
  payerCode?: string;
  groupNo?: string;
  wipId?: string;
  patientId?: string;
  policyId?: string;
  typeofPlanId?: string;
  typeofPlanShortCode?: string;
  typeofPlan?: string;
  authStatus?: string;
  authOrder?: number;
  policyName?: string;
  authDetails: PatientAuthorizationDTO;
  lstDocs: PatientDocumentBlobNewDTO[];
  diagnosis10?: string;
  diagnosis9?: string;
  resupplyItems: ItemAuthDetailsDTO[];
  isCompliance?: boolean;
  complianceDate?: number;
  isAfterFirstMonth?: boolean;
  isDuringThirdMonth?: boolean;
  other?: boolean;
  cptPrecercodes: CPTOxygenDTO;
  supplies: SuppliesDiagnosisDTO[];
  oxygenPrecercodes: CPTOxygenDTO;
  diagnosis: SuppliesDiagnosisDTO[];
}
export interface PatientDocumentBlobNewDTO extends AuditedEntityDto<string> {
  isSorted?: boolean;
  patientId?: string;
  documentId?: string;
  fileName?: string;
  fileContentAsBase64String?: string;
  blobName?: string;
  pageNumber: number;
  pageCount: number;
  documentTypeId?: string;
  documentTypeShortCode?: string;
  reviewStatus?: string;
  reviewReason?: string;
  documentStartDate?: string;
  documentEndDate?: string;
  tenantId?: string;
  wipId?: string;
  note?: string;
  docStatus?: string;
  fileNameType?: string;
  documentUploadType?: string;
  isVerified: number;
  documentType?: string;
  filePathPDF?: string;
  filePathTIFF?: string;
}
export interface CPTOxygenDTO {
  preCertCodeId?: string;
  isRental: boolean;
  rentalMonth: number;
}
export interface SuppliesDiagnosisDTO {
  preCertCodeId?: string;
}
export interface PatientPersonalDoNotCallReason {
  patientId?: string;
  patientName?: string;
  csr?: string;
  previousCsr?: string;
  doNotCallReason?: string;
}

export interface CreateUpdatePatientNextCallDTO {
  patientId?: string;
  nextCallDate?: string;
  isNextCall: boolean;
  tenantId?: string;
}
export interface LastRecordsOfNextCall {
  lastTwoCallLogDetails: PatientNextCallDTO[];
  lastFivePatientNotes: string[];
  cellNo?: string;
  homePhoneNo?: string;
  workPhoneNo?: string;
  rxDate?: string;
}
export interface PatientNextCallDTO extends AuditedEntityDto<string> {
  patientId?: string;
  nextCallDate?: string;
  isNextCall: boolean;
}
export interface CreateUpdateMedicareDocDTO {
  // demographics: CreateUpdateDemographicsDTO[];
  initialFaceToFaces: InitialFaceToFaceDTO[];
  sleepStudies: CreateUpdateSleepStudyDTO[];
  prescriptions: CreateUpdatePrescriptionDTO[]; // RX
  complainces: CreateUpdateComplainceDTO[];
  // fileId?: string;
  isDemographicsDataAvailable: number;
  isInitialFaceToFaceDataAvailable: number;
  isSleepStudyDataAvailable: number;
  isPrescriptionDataAvailable: number;
  isComplainceDataAvailable: number;
  isVerificationDataAvailable: number;
  isRuleCheck: number;
  isRulesNeeded: number;
  percentageOfCompletion: number;
  dxType: string;
  setupType: string;
  isCompSA: number;
  compliancePendingValidationCount: number;
  demographicsPendingValidationCount: number;
  initialFaceToFacePendingValidationCount: number;
  prescriptionPendingValidationCount: number;
  sleepStudyPendingValidationCount: number;
  verificationPendingValidationCount: number;
  approvedPatientId?: string;
  saleOrderId?: string;
}
export interface ItemAuthDetailsDTO {
  itemId?: string;
  itemName?: string;
  units?: string;
  saleOrRent?: string;
}
export interface CreateUpdateItemAuthDetailsDTO {
  itemId?: string;
  itemName?: string;
  units?: string;
  saleOrRent?: string;
}

export interface CreateUpdateMasterCheckListDTO {
  checklist?: string;
  isActive: boolean;
  tenantId?: string;
}

export interface CreateUpdateMasterCheckListQuestionsDTO {
  checklistId?: string;
  reffOneChecklistId?: string;
  reffTwoChecklistId?: string;
  questions?: string;
  isActive: boolean;
  tenantId?: string;
  isYesOrNoType?: boolean;
}

export interface MasterCheckListQuestionsDTO
  extends FullAuditedEntityDto<string> {
  checklistId?: string;
  reffOneChecklistId?: string;
  reffTwoChecklistId?: string;
  questions?: string;
  isActive: boolean;
  tenantId?: string;
  isYesOrNoType?: boolean;
}

export interface MedicareDisapprovalRequest {
  patientId?: string;
  description?: string;
  documentId?: string;
}

export interface QuestionMapping {
  checkListId?: string;
  questionId?: string;
  documentId?: string;
  patientId?: string;
  option?: yesOrNo;
  secondOption?: yesOrNo;
}

export interface QuestionResult {
  checkListQuestion: MasterCheckListQuestionsDTO[];
  questions: checkListQuestion[];
}

export interface checkListQuestion {
  questionId?: string;
  question?: string;
}

export interface MasterCheckListDTO extends FullAuditedEntityDto<string> {
  checklist?: string;
  isActive: boolean;
  tenantId?: string;
}

export interface PatientCallUserDTO {
  calledBy?: string;
  calledOn?: string;
}

export interface PatientCallDetailsDTO {
  callLogDetails: PatientCallUserDTO[];
  nextCallDate?: string;
  patientNotes: string[];
  cellNo?: string;
  email?: string;
  homePhoneNo?: string;
  workPhoneNo?: string;
  rxDate?: string;
  patientId?: string;
  csr?: string;
  chartId?: string;
  patientName?: string;
  dob?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  locationId?: string;
  defaultPatientId?: string;
  patientAddress?: string;
  referringProviderId?: string;
}
export interface PatientNewVerificationDTO {
  patientId: string;
  policyId?: string;
  method?: number;
  status?: string;
  policyNo?: string;
  groupNo?: string;
  plan?: string;
  notes?: string;
  skipPrevious?: boolean;
}

export interface PatientMedicareChecklistDTO
  extends FullAuditedEntityDto<string> {
  patientId?: string;
  checklistName?: string;
  verificationDetails?: any;
  disapprovedByForGetById?: DisapprovedStatus;
  initialFaceToFaces: InitialFaceToFaceDTO[];
  sleepStudies: SleepStudyDTO[];
  prescriptions: PrescriptionDTO[];
  complainces: ComplainceDTO[];
  isInitialFaceToFaceDataAvailable: number;
  isSleepStudyDataAvailable: number;
  isPrescriptionDataAvailable: number;
  isComplainceDataAvailable: number;
  isRuleCheck: number;
  isRulesNeeded: number;
  isCompSA: number;
  dxType?: string;
  setupType?: string;
  tenantId?: string;
  organizationUnitId?: string;
  percentageOfCompletion: number;
  initialFaceToFacePendingValidationCount: number;
  sleepStudyPendingValidationCount: number;
  prescriptionPendingValidationCount: number;
  compliancePendingValidationCount: number;
  isApprove: number;
}

export interface PatientNotesV1DTO {
  notesId: string;
  patientId: string;
  patientName: string;
  chartNo: string;
  chartId?: string;
  noteType: string;
  noteReason: string;
  createdBy: string;
  creationTime: string;
  description: string;
  modifiedBy: string;
  createdByName: string;
  modifiedDate: string;
}

export interface PatientNotesV1ApiDTO {
  notesId: string;
  allNotes: PatientNotesV1DTO[];
  lastNoteDescription: string;
  patientName: string;
  chartId: string;
  chartNo: string;
}

export interface VerificationResult {
  patientVerificationList: InboundListDTO[];
  verificationList: InboundListDTO[];
}

export interface InboundListDTO {
  inboundStatusId: string[];
  inboundStatusName?: string;
  chartNo?: string;
  name?: string;
  state?: string;
  enteredBy?: string;
  locationId?: string;
  locationName?: string;
  policyLevel: InsuranceType;
  policyName?: string;
  lockedBy?: string;
  lockedAt?: string;
  days: number;
  enteredOn?: string;
  status: number;
  type?: string;
  stat: boolean;
  preparedBy?: string;
  preparedOn?: string;
  approvedBy?: string;
  approvedOn?: string;
  createdDate?: string;
  modifiedDate?: string;
  patientId?: string;
  policyId?: string;
  verificationId?: string;
  docId?: string;
}

export interface PatientMedicareChecklistDTO
  extends FullAuditedEntityDto<string> {
  patientId?: string;
  initialFaceToFaces: InitialFaceToFaceDTO[];
  sleepStudies: SleepStudyDTO[];
  prescriptions: PrescriptionDTO[];
  complainces: ComplainceDTO[];
  isInitialFaceToFaceDataAvailable: number;
  isSleepStudyDataAvailable: number;
  isPrescriptionDataAvailable: number;
  isComplainceDataAvailable: number;
  isRuleCheck: number;
  isRulesNeeded: number;
  isCompSA: number;
  dxType?: string;
  setupType?: string;
  tenantId?: string;
  organizationUnitId?: string;
  percentageOfCompletion: number;
  initialFaceToFacePendingValidationCount: number;
  sleepStudyPendingValidationCount: number;
  prescriptionPendingValidationCount: number;
  compliancePendingValidationCount: number;
  verificationId?: string;
  isApprove: number;
  documentId?: string;
  patientName?: string;
  chartNo?: string;
  verificationStatus?: string;
  insuranceComment?: string;
  otherComments?: string;
  final?: string;
  preparedDate?: string;
  preparedBy?: string;
  preparedName?: string;
  approvedDate?: string;
  approvedBy?: string;
  approvedName?: string;
  isGrandFathered?: boolean;
  disapprovedBy?: DisapprovedStatus;
  statusOfCheckList?: MedicareCheckListStatus;
  questionOptions: LtQuestionsAndOptions[];
  checkListId?: string;
  cpaptoBiLevel: CPAPtoBiLevel;
  replacementPAP: ReplacementPAP;
  papRequal: PAPRequal;
  oxygenSetup: OxygenSetup;
  complainceRelease: ComplainceRelease;
  nonInvasionVentilation: NonInvasionVentilation;
}
export interface LtQuestionsAndOptions {
  questions?: string;
  options?: string;
  questionCode?: string;
}
// export interface CPAPtoBiLevel {
//   faceToFaceEvaluation?: string;
//   medicareCoveredSleepTest?: string;
//   rxDate?: string;
//   pickUpTicket?: string;
//   detailedWrittenOrder?: string;
//   npi?: string;
// }

export interface CPAPtoBiLevel {
  checklistName?: string;
  faceToFaceEvaluation?: string;
  medicareCoveredSleepTest?: string;
  rxDate?: string;
  pickUpTicket?: string;
  detailedWrittenOrder?: string;
  npi?: string;
  npiId?: string;
  faceToFaceImg: string[];
  medicareCoveredSleepTestImg: string[];
  pickUpTicketImg: string[];
  rxImg: string[];
  faceToFacePageNo: number[];
  medicareCoveredSleepTestPageNo: number[];
  pickUpTicketPageNo: number[];
  dwoPageNo: number[];
}

// export interface ReplacementPAP {
//   medicareCoveredSleepTest?: string;
//   rxDate?: string;
//   ftFnote?: string;
//   detailedWrittenOrder?: string;
//   npi?: string;
// }

export interface ReplacementPAP {
  checklistName?: string;
  medicareCoveredSleepTest?: string;
  rxDate?: string;
  ftFnote?: string;
  detailedWrittenOrder?: string;
  npi?: string;
  npiId?: string;
  medicareCoveredSleepTestImg: string[];
  rxImg: string[];
  ftfNoteImg: string[];
  medicareCoveredSleepTestPageNo: number[];
  dwoPageNo: number[];
  ftfNotePageNo: number[];
}

// export interface PAPRequal {
//   faceToFaceEvaluation?: string;
//   medicareCoveredSleepTest?: string;
//   rxDate?: string;
//   returnTicket?: string;
//   detailedWrittenOrder?: string;
//   npi?: string;
// }
export interface PAPRequal {
  checklistName?: string;
  faceToFaceEvaluation?: string;
  medicareCoveredSleepTest?: string;
  rxDate?: string;
  returnTicket?: string;
  detailedWrittenOrder?: string;
  npi?: string;
  npiId?: string;
  faceToFaceImg: string[];
  medicareCoveredSleepTestImg: string[];
  returnTicketImg: string[];
  rxImg: string[];
  faceToFacePageNo: number[];
  medicareCoveredSleepTestPageNo: number[];
  returnTicketPageNo: number[];
  dwoPageNo: number[];
}

// export interface OxygenSetup {
//   offlineClinicalEvaluatonNotes?: string;
//   medicareCoveredTestingReport?: string;
//   rxDate?: string;
//   npi?: string;
//   detailedWrittenOrder?: string;
// }

export interface OxygenSetup {
  checklistName?: string;
  offlineClinicalEvaluatonNotes?: string;
  medicareCoveredTestingReport?: string;
  rxDate?: string;
  npi?: string;
  npiId?: string;
  detailedWrittenOrder?: string;
  offlineClinicalNoteImg: string[];
  medicareCoveredTestingReportImg: string[];
  rxImg: string[];
  offlineClinicalNotePageNo: number[];
  medicareCoveredTestingReportPageNo: number[];
  dwoPageNo: number[];
}

// export interface ComplainceRelease {
//   currentFaceToFaceNoteDiscussion?: string;
//   medicareCoveredSleepTest?: string;
//   rxDate?: string;
//   npi?: string;
//   detailedWrittenOrder?: string;
// }

export interface ComplainceRelease {
  checklistName?: string;
  currentFaceToFaceNoteDiscussion?: string;
  medicareCoveredSleepTest?: string;
  rxDate?: string;
  npi?: string;
  npiId?: string;
  detailedWrittenOrder?: string;
  faceToFaceImg: string[];
  complainceDLImg: string[];
  sleepTestImg: string[];
  followUpFaceToFaceImg: string[];
  rxImg: string[];
  faceToFacePageNo: number[];
  complainceDLPageNo: number[];
  sleepTestPageNo: number[];
  followUpFaceToFacePageNo: number[];
  dwoPageNo: number[];
}

// export interface NonInvasionVentilation {
//   faceToFaceEvaluation?: string;
//   medicareTestingReport?: string;
//   rxDate?: string;
//   npi?: string;
//   detailedWrittenOrder?: string;
// }

export interface NonInvasionVentilation {
  checklistName?: string;
  faceToFaceEvaluation?: string;
  medicareTestingReport?: string;
  rxDate?: string;
  npi?: string;
  npiId?: string;
  detailedWrittenOrder?: string;
  rxImg: string[];
  medicareTestingImg: string[];
  faceToFaceImg: string[];
  dwoPageNo: number[];
  medicareTestingPageNo: number[];
  faceToFacePageNo: number[];
}

export interface CreateUpdatePatientMedicareDTO {
  patientId?: string;
  isRuleCheck: number;
  isRulesNeeded: number;
  isCompSA: number;
  dxType?: string;
  setupType?: string;
  verificationId?: string;
  tenantId?: string;
  patientName?: string;
  chartNo?: string;
  verificationStatus?: string;
  insuranceComment?: string;
  otherComments?: string;
  final?: string;
  preparedDate?: string;
  preparedBy?: string;
  preparedName?: string;
  approvedDate?: string;
  approvedBy?: string;
  approvedName?: string;
  isGrandFathered?: boolean;
  disapprovedBy?: DisapprovedStatus;
  statusOfCheckList?: MedicareCheckListStatus;
  questionOptions: LtQuestionsAndOptions[];
  checkListId?: string;
  cpaptoBiLevel: CPAPtoBiLevel;
  replacementPAP: ReplacementPAP;
  papRequal: PAPRequal;
  oxygenSetup: OxygenSetup;
  complainceRelease: ComplainceRelease;
  nonInvasionVentilation: NonInvasionVentilation;
}

export interface checkListRequestDTO {
  checkListId?: string;
  questionCode?: string;
  documentId?: string;
  patientId?: string;
  medicareChecklistId?: string;
  option?: string;
}

// export interface finalSaveOutput {
//   checkListId?: string;
//   patientId?: string;
//   verificationId?: string;
//   cpaptoBiLevel: CPAPtoBiLevel;
//   replacementPAP: ReplacementPAP;
//   papRequal: PAPRequal;
//   oxygenSetup: OxygenSetup;
//   complainceRelease: ComplainceRelease;
//   nonInvasionVentilation: NonInvasionVentilation;
//   disapprovedStatus?: DisapprovedStatus;
//   statusOfCheckList: MedicareCheckListStatus;
//   preparedDate?: string;
//   preparedBy?: string;
//   medicareChecklistId?: string;
//   documentId?: string;
// }

export interface finalSaveOutput {
  checkListId?: string;
  patientId?: string;
  verificationId?: string;
  newSetup: NewSetup;
  cpaptoBiLevel: CPAPtoBiLevel;
  replacementPAP: ReplacementPAP;
  papRequal: PAPRequal;
  oxygenSetup: OxygenSetup;
  complainceRelease: ComplainceRelease;
  nonInvasionVentilation: NonInvasionVentilation;
  preparedDate?: string;
  preparedBy?: string;
  medicareChecklistId?: string;
  referralProviderId?: string;
  isGrandfathered?: boolean;
  documentId?: string;
  disapprovedBy?: DisapprovedStatus;
  disapprovedNotes?: string;
  statusOfCheckList?: MedicareCheckListStatus;
  medicareNotes?: string;
  verificationNotes?: string;
  npi?: string;
  rxDate?: string;
}

export interface NewSetup {
  checklistName?: string;
  faceToFaceEvaluation?: string;
  medicareCoveredSleepTest?: string;
  rxDate?: string;
  detailedWrittenOrder?: string;
  npi?: string;
  npiId?: string;
  faceToFaceImg: string[];
  medicareCoveredSleepTestImg: string[];
  rxImg: string[];
  faceToFacePageNo: number[];
  medicareCoveredSleepTestPageNo: number[];
  dwoPageNo: number[];
}

export interface medicareCheckListResult {
  chartNo?: string;
  patientName?: string;
  patientId?: string;
  verificationDetails: VerificationDataResult[];
  checkListId?: string;
  checkListDetails: object;
  disapprovedBy: string[];
}

export interface VerificationDataResult {
  policyId?: string;
  verificationId?: string;
  verificationDate?: string;
  policyName?: string;
  verificationNotes?: string;
  payorLevel?: InsuranceType;
  verificatinStatus?: VerificationStatus;
}

export interface FilterResult {
  checklistId?: string;
  checkListType?: string;
  patientId?: string;
  docId?: string;
  patientName?: string;
  blobName?: string;
  uniqueChecklistId?: string;
  chartNo?: string;
  defautlFaxNo?: string;
  defautlDocumentId?: string;
  status?: string;
  location?: string;
  locationId?: string;
  preparedOn?: string;
  uploadedDate?: string;
  verifiedOn?: string;
  preparedBy?: string;
  lockedby?: string;
  stat?: number;
  isLocked?: number;
  lockedStatus?: number;
  technician?: string;
  statusOfdocumentFrom?: string;
  notes: string[];
}

export interface CreateUpdateChecklistSortingDTO {
  lstSorting: SaveDocumentsDto[];
  lstRemoveSorting?: number[];
  initialFaceToFaces: CreateUpdateInitialFaceToFaceDTO;
  sleepStudies: CreateUpdateSleepStudyDTO;
  prescriptions: CreateUpdatePrescriptionDTO;
  complainces: CreateUpdateComplainceDTO;
  isInitialFaceToFaceDataAvailable: number;
  isSleepStudyDataAvailable: number;
  isPrescriptionDataAvailable: number;
  isComplainceDataAvailable: number;
  isVerificationDataAvailable: number;
  isRuleCheck: number;
  isRulesNeeded: number;
  isCompSA: number;
  dxType?: string;
  setupType?: string;
  percentageOfCompletion: number;
  initialFaceToFacePendingValidationCount: number;
  sleepStudyPendingValidationCount: number;
  prescriptionPendingValidationCount: number;
  compliancePendingValidationCount: number;
  isApproved: boolean;
  patientId?: string;
  documentId?: string;
  bCompleteSorting: boolean;
}

export interface SaveDocumentsDto {
  patientId?: string;
  blobName?: string;
  sortType: SortType;
  documentTypeId?: string;
  documentType?: string;
  pageNumber: number;
  pageContent?: string;
  inboundDcoumentId?: string;
}

export interface CreateUpdateInitialFaceToFaceDTO {
  initialFaceToFaceId?: string;
  patientName?: string;
  dateOfBirth?: string;
  faceToFaceDate?: string;
  doctorName?: string;
  npi?: string;
  doctorSignature?: string;
  symptoms: string[];
  apneaTreatment?: string;
  dateOfVisit?: string;
  patientInfo?: string;
  hieght: number;
  weight: number;
  signedDate?: string;
  previousHistoryDeclaration: number;
  isSleepTestRecommended: number;
  isDoctorSigned: number;
  isPatientBenifited: number;
  patientBenefitNotes?: string;
}
export interface patientInsuranceDTO {
  patientId?: string;
  patientName?: string;
  insertedOn?: string;
  insertedBy?: string;
  typeofPlan?: string;
  policyNo?: string;
  groupNo?: string;
  payerName?: string;
  plan?: InsuranceType;
  payerCode?: string;
  chartId?: string;
  policyEndedOn?: string;
  defaultDocumentId?: string;
  defaultFaxId?: string;
  blobName?: string;
  documentId?: string;
  isMedicarePolicy?: number;
}

export interface CompailanceReportDTO {
  id?: string;
  orderDate?: string;
  location?: string;
  chartId?: string;
  name?: string;
  day: number;
  technician: number;
  datausedvsdata: number;
  datausedvssetup: number;
  datausedvs4hr: number;
  complaince: number;
  returned: boolean;
  nc: boolean;
}

export interface PatientWiseCallLogDTO {
  calledByName?: string;
  callDetails?: string;
  callDate?: string;
  creationTime?: string;
}

export interface PatientWiseProvideDetails {
  orderingPhysician?: string;
  referalPractice?: string;
  phone?: string;
  cellPhone?: string;
}

export interface PatientAuthCodeDetailsDTO {
  preCertCodeId?: string;
  isRental?: boolean;
  rentalMonth?: number;
}
export interface NewInsuranceDTO {
  patientId?: string;
  patientName?: string;
  chartId?: string;
  defaultDocumentId?: string;
  defaultFaxId?: string;
  blobName?: string;
  documentId?: string;
  primary: AuthVerificationDTO[];
  secondary: AuthVerificationDTO[];
  tertiary: AuthVerificationDTO[];
  creationTime?: string;
  dateOfBirth?: string;
  csrName?: string;
}
export interface AuthVerificationDTO {
  insurance?: string;
  verificationId?: string;
  patientId?: string;
  verLastModificationTime?: string;
  verCreationTime?: string;
  verValidity?: string;
  plan?: InsuranceType;
  verificationStatus: number;
  authId?: string;
  authLastModificationTime?: string;
  authCreationTime?: string;
  authValidity?: string;
  authStatus?: number;
}
export interface PatientAuthVerificationDTO {
  insurance?: string;
  eligibilityMode?: string;
  policyNo?: string;
  groupNo?: string;
  typeofPlan?: string;
  verificationId?: string;
  patientId?: string;
  verLastModificationTime?: string;
  verCreationTime?: string;
  verValidity?: string;
  plan?: InsuranceType;
  verificationStatus: number;
  authId?: string;
  authLastModificationTime?: string;
  authCreationTime?: string;
  authValidity?: string;
  authStatus?: number;
  creationTime?: string;
  isActive: boolean;
}

export interface MedicareChecklistDetailsDTO {
  uniqueChecklistId?: any;
  checkListId?: any;
  patientID?: string;
  faxID?: string;
  documetId?: string;
  blobName?: string;
  chartId?: string;
  patientname?: string;
  patientAge?: string;
}

export interface GetBase64TiffSorted {
  file?: string;
  pageNo?: number;
}

export interface PatientChartNoDTO {
  patientId?: string;
  docId?: string;
  chartId?: string;
  patientName?: string;
  dob?: string;
  lastModificationTime?: string;
  ddValue?: string;
  ddValue1?: string;
  ssn?: string;
  cellNo?: string;
  emergencyContact?: string;
  alternatecontact?: string;
  policyNo?: string;
  address?: string;
  email?: string;
  gender?: string;
}

export interface mmCMNonFileType extends FullAuditedAggregateRoot<string> {
  mmReferenceId: number;
  tenantId?: string;
  shortdesc?: string;
  description?: string;
  ddOrder: number;
}
export interface activeAuthDTO {
  id?: string;
  authId?: string;
  endDate?: string;
  createdDate?: string;
}

export interface imageList {
  pageContent?: string;
  pageNo?: number;
}
export interface PatientInfo {
  patientId?: string;
  fullName?: string;
  dob?: string;
}

export interface PatientDocumentDataDTO {
  faxId?: string;
  documentName?: string;
  blobName?: string;
  docId?: string;
  patientId?: string;
  isSorted?: boolean;
  uploadedBy?: string;
  uploadorName?: string;
  uploadedDate?: string;
  modifiedDate?: string;
  updatorName?: string;
  updatedby?: string;
  isInbound?: boolean;
  shortCodeId?: string;
  signatureId?: string;
}

export interface uploadedFileDTO {
  fileName?: string;
  blobName?: string;
  pages: number;
  filePathPDF?: string;
}

export interface PatientDocumentBlobNewDTO extends AuditedEntityDto<string> {
  isSorted?: boolean;
  patientId?: string;
  documentId?: string;
  fileName?: string;
  fileContentAsBase64String?: string;
  blobName?: string;
  pageNumber: number;
  pageCount: number;
  documentTypeId?: string;
  documentTypeShortCode?: string;
  reviewStatus?: string;
  reviewReason?: string;
  documentStartDate?: string;
  documentEndDate?: string;
  tenantId?: string;
  wipId?: string;
  note?: string;
  docStatus?: string;
  fileNameType?: string;
  documentUploadType?: string;
  isVerified: number;
  documentType?: string;
  filePathPDF?: string;
  filePathTIFF?: string;
}

export interface LockOutput {
  lockStatus?: number;
  message?: string;
  csrName?: string;
  lockedBy?: string;
  lockedAt?: string;
}
export interface PatientIdDetailsDTO {
  patientId?: string;
  locationId?: string;
  practiceId?: string;
  insId?: string;
}
export interface PatientExportSearchDTO {
  patientId?: string;
  practiceId?: string;
  branchId?: string;
  insuranceId?: string;
  orderBy?: patientExportOrder;
  isDefault: boolean;
  isGreaterthan: boolean;
  balance?: number;
  days?: number;
}
export interface PatientExportDataDTO {
  patientId?: string;
  patientName?: string;
  chartId?: string;
  dob?: string;
  orderId?: string;
  orderNo?: number;
  orderDate?: string;
  referringProviderFacility?: string;
  referringProviderFacilityId?: string;
  providerId?: string;
  providerName?: string;
  primaryVerificationId?: string;
  insurance?: string;
  orderAmount: number;
  paidAmount?: number;
  paidDate?: string;
  locationId?: string;
  locationName?: string;
  claimId?: string;
  claimNo?: string;
  totalBalance?: number;
  zipCode?: string;
  patientBalance?: number;
  insuranceBalance?: number;
}
export interface PatientExportOrderDataDTO {
  orderId?: number;
  primaryIns?: string;
  orderDate?: string;
  suppliesDate?: string;
  lastMaskDate?: string;
  orderStatusName?: string;
}
export interface clsTenantDoctors {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  npiNumber?: string;
  createdDate?: string;
  patientFirstName?: string;
  patientLastName?: string;
  patientMiddleName?: string;
  doctorId?: string;
  docAddress?: string;
  docCity?: string;
  docState?: string;
  docCountry?: string;
  zipCode?: string;
}
export interface PatientListDto {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  middleName?: string;
  patId?: string;
  activeStatus?: string;
}

export interface PatientChartIdDTO {
  spatientID?: string;
  chartId?: string;
  isActive?: string;
}
export interface PayorDetailDTO {
  payerName?: string;
  payorCode?: string;
  payerId?: string;
  isActive?: boolean;
}
export interface InputPatientListDto {
  gPat: string[];
  sSearch?: string;
}
export interface ParReport {
  totalCount: number;
  parDetailsReport: parReportData[];
}
export interface parReportData {
  id?: string;
  patientId?: string;
  csrId?: string;
  docId?: string;
  verificationId?: string;
  primaryVerId?: string;
  secondaryVerId?: string;
  tertiaryVerId?: string;
  dob?: string;
  authEndDate?: string;
  chartId?: string;
  defaultFaxId?: string;
  csrName?: string;
  authStatusName?: string;
  fullName?: string;
  statusOfExpiry?: string;
  hcpcCode?: string;
  durationOfExpiry?: number;
}
export interface ParReportCount {
  totalCount: number;
  yetToExpireCount: number;
  expiredCount: number;
}