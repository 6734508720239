import type { CreateUpdateMmUPSLabelDTO, MmUPSLabelDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MmUPSLabelService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMmUPSLabelDTO) =>
    this.restService.request<any, MmUPSLabelDTO>({
      method: 'POST',
      url: '/api/orderApp/mm-uPSLabel',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/mm-uPSLabel/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MmUPSLabelDTO>({
      method: 'GET',
      url: `/api/orderApp/mm-uPSLabel/${id}`,
    },
    { apiName: this.apiName });

  getAllUPSLabelList = () =>
    this.restService.request<any, MmUPSLabelDTO[]>({
      method: 'GET',
      url: '/api/orderApp/mm-uPSLabel/u-pSLabel-list',
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MmUPSLabelDTO>>({
      method: 'GET',
      url: '/api/orderApp/mm-uPSLabel',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMmUPSLabelDTO) =>
    this.restService.request<any, MmUPSLabelDTO>({
      method: 'PUT',
      url: `/api/orderApp/mm-uPSLabel/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
