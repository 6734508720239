import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { IdentityUserUsingTenantIdDto, IdentityUserWithRoleDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { AddNotesComponent } from 'projects/patient/src/app/add-notes/add-notes.component';
import { ConfirmationDialogService } from 'projects/patient/src/app/confirmation-dialog.service';
import { AuthVisitsDTO, OrderMasterDropdownDTO, PriceCodeDTO, TypeOfPlanDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import {
  AccountGroupDTO,
  BranchOfficeDTO,
  ClaimFillingCodeDTO,
  CustomerTypeDTO,
  FacilityDTO,
  GenderDTO,
  LocationTypeDTO,
  PatientMasterDropdownDTO,
  PayorLevelDTO,
  PlaceOfServiceDTO,
  PTSecurityGroupDTO,
  TaxZoneDTO,
  ZoneDTO
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { AddressDTO, ClinicalDTO, PatientDTO, PersonalDTO, PolicyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { getPatient, updatePatient } from 'projects/patient/src/app/patient-store/patient.action';
import { OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { OrderNotesComponent } from '../order-notes/order-notes.component';
import { ItemDTO, OrdersDTO, ReminderDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { ReminderService } from '../order-proxy/order-management/order/reminder.service';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { AuthorizationDTO, InsuranceDTO } from '../order-proxy/order-management/rcm/patient-management/patient/dto/models';
import { getOrderDropdown } from '../order-store/dropdown.action';
import { addOrder, getOrder, updateOrder } from '../order-store/order.action';
import { RemindersService } from '../reminder-message/reminders.service';
import { ReminderPopupComponent } from '../reminder-popup/reminder-popup.component';
import { ToastrService } from "ngx-toastr";

// import { InsuranceProviderProductEligibilityRulesService } from '../order-proxy/order-management/inventory/insurance-provider-product-eligibility-rules.service';

@Component({
  selector: 'app-order-create-edit',
  templateUrl: './order-create-edit.component.html',
  styleUrls: ['./order-create-edit.component.scss'],
  providers: [ListService]
})

export class OrderCreateEditComponent implements OnInit, OnDestroy {
  savedContactData: any;
  savedClinicalData: any;
  savedDocumentData: any;
  savedInsuranceData: any;
  savedAuthorizationData: any;
  orderStatus: boolean = true;
  currentOrderStatus: string = '';
  savedItemData: any;
  savedVerificationData: any;
  tblNotesReponse: any[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpPlanType: any[];
  drpPolicies: PolicyDTO[] = [];
  patientids: string = "";
  arrUsersList: any[] = [];
  drpitem: itemInterface[] = [];
  saleorderId: number = 0;
  drpPriceCode: PriceCodeDTO[] = [];
  orderForm: FormGroup;
  clinicalForm: FormGroup;
  insuranceForm: FormGroup;
  itemForm: FormGroup;
  verificationForm: FormGroup;
  authorizationForm: FormGroup;
  @Input() isStatusShow: boolean = false;
  forBlink: boolean = true

  patientId: string = "";
  defaultPatientId: string = '';
  saleOrderId: string = "";
  patientName: string = "";
  strUrlPath: string = "";
  isOrderViewPermission: boolean = true;
  isOrderCreatePermission: boolean = true;
  isShownAuthTab: boolean = true;
  isShownOrderTab: boolean = true;
  lblDefaultSaleOrderId: string = "";
  lblDefaultPatientId: string = "";
  lblPatientName: string = "";
  lblPatientGender: string = "";
  lblPatientDOB: string = "";
  lblPatientHeight: string = "";
  lblPatientWeight: string = "";
  lblBranchOffice: string = "";
  lblAccountNumber: string = "";
  lblSsnNumber: string = "";
  lblBillingAmount: string = "";
  lblBillingStatus: string = "";
  orderId: string = "";
  drpBranchOffices: BranchOfficeDTO[] = [];
  drpGenderLoop: GenderDTO[] = [];
  strLocation: string = "";
  strBillingAddress: string = "";
  strBillingState: string = "";
  strBillingCity: string = "";
  strBillingCountry: string = "";
  strPostalCode: string = "";
  strMobile: string = "";
  strEmailAddress: string = "";
  drpAccountGroups: AccountGroupDTO[];
  drpZones: ZoneDTO[];
  drpPTSecurityGroups: PTSecurityGroupDTO[];
  drpCustomerTypes: CustomerTypeDTO[];
  drpLocationTypes: LocationTypeDTO[];
  // drpCountries: CountryDTO[];
  drpClaimFillingCodes: ClaimFillingCodeDTO[];
  drpPlaceOfServices: PlaceOfServiceDTO[];
  drpFacility: FacilityDTO[];
  drpTaxZone: TaxZoneDTO[];
  // drpCity: CityDTO[];
  // drpState: StateDTO[];
  // drpCounty: CountyDTO[];
  organizationUnitId: string = localStorage.getItem("initialOrganizationUnitId") ?? "";
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  @Output() orderIdEmitter: EventEmitter<OrdersDTO> = new EventEmitter<OrdersDTO>(null);
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() saveOrderFormState: EventEmitter<any> = new EventEmitter<any>();
  savedOrderData: any;
  showswal = true;
  insuranceVerificationId: string;
  @ViewChild('nav') nav: NgbNav;
  authorizationId: string = "00000000-0000-0000-0000-000000000000";
  show: boolean = true;
  show1: boolean;
  verifyTableData: any[] = [];
  payorLevel: PayorLevelDTO[];
  drpAuthVisit: AuthVisitsDTO[] = [];
  userId: string = "";
  tenantId: string = "";
  height: string = '';

  subscription$: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    public title: Title,
    private table: TableService,
    private snackBar: MatSnackBar,
    private store: Store,
    private router: Router,
    private reminder: ReminderService,
    private list: ListService,
    private Table: TableService,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private formBuilder: FormBuilder,
    private confirmationDialogService: ConfirmationDialogService,
    private fb: FormBuilder,
    private number: NumberValidators,
    private text: TextValidators,
    private activatedRoute: ActivatedRoute,
    private saleOrderService: SaleorderService,
    private reminderService: RemindersService,
    private userService: UserService,
    private region: RegionDropdownService,
    private toastr: ToastrService,
    // private itemService: InsuranceProviderProductEligibilityRulesService,
    private patientDropdownService: PatientMasterDropdownService,
    private patientService: PatientService

  ) {
    // this.getdropdowns();
  }
  // orderId: string = "f1c3304b-524f-eaf3-9169-39fa1b9daa37";
  preOrderId: string = "";

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  ngOnInit(): void {
    this.userId = localStorage.getItem("userId");
    this.tenantId = localStorage.getItem("tenantId");
    const tenantId: IdentityUserUsingTenantIdDto = { tenantId: this.tenantId };

    if (this.tenantId !== undefined && this.tenantId !== "" && this.tenantId !== null) {
      const userList = this.userService.getUserList(tenantId).subscribe((response: IdentityUserWithRoleDto[]) => {
        this.arrUsersList = response;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(userList);
    }

    this.title.setTitle("Qsecure | New Order");
    const activateRoute = this.activatedRoute.paramMap.subscribe(response => {
      this.orderId = response.get('orderId') ?? "";
      this.preOrderId = response.get('preOrderID') ?? "";
      this.patientId = response.get('patientId') ?? "";
      this.patientId !== "" && this.orderId === '' && this.getDetailsByPatientID(this.patientId);
      if (this.patientId === "" && this.orderId !== '') {
        this.orderId !== '' && this.saleOrderService.get(this.orderId).subscribe(Res => {
          const orderResp: SaleorderDTO = Res;
          this.lblDefaultSaleOrderId = orderResp?.saleorderId ?? "";
          this.patientId = orderResp?.patientId ?? "";
          if (this.patientId === "") {
            this.patientId = orderResp?.patients?.id;
          }
          this.patientId !== "" && this.orderId !== '' && this.getDetailsByPatientID(this.patientId);
        })
      }

      // this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getDetailsByPatientID(this.patientId);

      this.Table.setSelectedOrderId(this.orderId);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(activateRoute);
    //
    const activateUrl = this.activatedRoute.url.subscribe(value => {
      if (value[2]?.path === "" || value[2]?.path === undefined || value[2]?.path === null) {
        this.strUrlPath = "saleorderCreateEdit";
        this.title.setTitle("Qsecure | Create SaleOrder");
      } else {
        this.strUrlPath = "patientViewEdit";
        this.title.setTitle("Qsecure | View SaleOrder");
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(activateUrl);
    //
    const getOrganizationUnitId = this.Table.getOrganizationUnitId().subscribe(value => {
      this.organizationUnitId = value;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getOrganizationUnitId);
    // reminder getlist based on organizationUnitId
    this.userId = localStorage.getItem("userId");
    // reminder getlist based on organizationUnitId

    const remainderList = (query: any) => this.reminder.getList(query);
    this.list.hookToQuery(remainderList).pipe(map(value => {
      return value.items?.filter(value => { return value && value?.patientId === this.patientId });
    })).subscribe(response => {
      response?.forEach(element => {
        // ! For Curerent Users Only
        if (new Date()?.toLocaleDateString("en-US") === new Date(element?.reminderDate)?.toLocaleDateString("en-US") && element?.isCompleted === 0 && element?.isDontShowAgain === 0 && element?.assignedTo === this.userId && !element?.isAllUsers) {
          // this.snackBar.openFromComponent(ReminderMessageComponent, {
          //   data: { patientName: element?.patientName, subject: element?.subject, isAllUsers: false },
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition
          // });
          const userName: string = this.arrUsersList?.filter(u => u?.id === localStorage.getItem("userId"))[0]?.userName ?? "";
          const titleMsg: string = 'Hi ' + userName + ' 👋';
          const message: { patientName: string, subject: string } = { patientName: element?.patient, subject: element?.subject };

          const messageTimeOut: number = 1000000;
          this.reminderService.success(titleMsg, message, messageTimeOut);

        }
        // ! For All Users
        if (new Date()?.toLocaleDateString("en-US") === new Date(element?.reminderDate)?.toLocaleDateString("en-US") && element?.isCompleted === 0 && element?.isDontShowAgain === 0 && !!element?.isAllUsers) {
          // this.snackBar.openFromComponent(ReminderMessageComponent, {
          //   data: { patientName: element?.patientName, subject: element?.subject, isAllUsers: true },
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition
          // });
          const userName: string = this.arrUsersList?.filter(u => u?.id === localStorage.getItem("userId"))[0]?.userName ?? "";
          const titleMsg: string = 'Hi ' + userName + ' 👋';
          const message: { patientName: string, subject: string } = { patientName: element?.patient, subject: element?.subject };

          const messageTimeOut: number = 1000000;
          this.reminderService.success(titleMsg, message, messageTimeOut);
        }
      });

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    // this.table.getSelectedPatientId().subscribe(response => {
    //  this.patientId = response;
    // this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getDetailsByPatientID(this.patientId);
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    this.createPatientForm();
    if (this.insuranceVerificationId! = "" && this.insuranceVerificationId != undefined && this.insuranceVerificationId != null) {
      // this.getOrderByid(this.insuranceVerificationId);
    }
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PayorLevels
    ]
    const getPatientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response?.payorLevels;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getPatientDropdown);
    //
    this.itemDropdown();
    this.getDropdown();

    // get current id for current status
    const getSaleOrderId = this.Table.getSelectedOrderId().subscribe(value => {
      setTimeout(() => {
        if (this.orderId != null) {
          this.currentOrderStatusShow(this.orderId);

        } else {
          this.currentPatientStatusShow(this.patientId);
        }
      }, 400);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getSaleOrderId);
  }




  createPatientForm() {
    this.orderForm = this.formBuilder.group({
      Delivery: new FormGroup({
        txtCountryFilter: new FormControl(""),
        txtCityFilter: new FormControl(""),
        txtStateFilter: new FormControl(""),
        txtScheduledDate: new FormControl("", Validators.required),
        txtScheduledTime: new FormControl(""),
        txtActualDate: new FormControl("", Validators.required),
        txtActualTime: new FormControl(""),
        txtAddress: new FormControl(""),
        drpCity: new FormControl(null),
        // chkNotValidated: new FormControl(""),
        drpState: new FormControl(null),
        drpCounty: new FormControl(null),
        drpCountry: new FormControl(null),
        txtPostalCode: new FormControl(""),
        txtPhone: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtMobile: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtFacility: new FormControl(""),
        txtTaxzone: new FormControl(""),
        txtTaxRate: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtOrderNote: new FormControl(""),
        txtDeliveryNote: new FormControl(""),
        drpSetupMethod: new FormControl(""),
        txtDeliveryTechnician: new FormControl("", [this.textValidators.isTextCheck]),
        chkSignatureRequired: new FormControl(""),
        drpFulfillmentVendor: new FormControl(""),
        drpAccountNumber: new FormControl(""),
        drpShipBy: new FormControl(""),
        txtStatus: new FormControl(""),
        txtStatusDate: new FormControl(""),
        txtdrpShipStatus: new FormControl("", [this.textValidators.isTextCheck]),
        chkCahPayment: new FormControl(""),
      }),
      General: new FormGroup({
        chkGeneralManualHold: new FormControl(""),
        drpGeneralHoldReason: new FormControl(""),
        txtGeneralStopDate: new FormControl(""),
        drpGeneralStopReason: new FormControl(""),
        drpGeneralBranch: new FormControl(""),
        drpGeneralInvLocation: new FormControl(""),
        drpGeneralStatus: new FormControl(""),
        drpGeneralClassification: new FormControl(""),
        drpGeneralPlaceOfService: new FormControl(""),
        txtGeneralDateOfAdmission: new FormControl(""),
        txtGeneralDateOfDischarge: new FormControl(""),
        txtGeneralDiscountPct: new FormControl(0, [Validators.min(0), Validators.max(100), this.numberValidators.isNumberCheck]),
        txtGeneralPoNumber: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtGeneralReference: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser1: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser2: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser3: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser4: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralPriorSystemKey: new FormControl("", [this.textValidators.isTextCheck]),
      }),
      AutoConfirm: new FormGroup({
        chkAutoConfirmAutoConfirm: new FormControl(""),
        txtAutoConfirmInitialDate: new FormControl(""),
        PointDelivery: new FormGroup({
          txtPointDeliveryOrderStatus: new FormControl("", [this.textValidators.isTextCheck]),
          txtPointDeliveryStatusDateTime: new FormControl(""),
          txtPointDeliveryLastMessage: new FormControl("", [this.textValidators.isTextCheck]),
          txtPointDeliveryLastMessageDateTime: new FormControl(""),
        }),
        BrightShip: new FormGroup({

          txtBrightShipShippingStatus: new FormControl("", [this.textValidators.isTextCheck]),
          drpBrightShipShippingCarrier: new FormControl(""),
          drpBrightShipShippingMethod: new FormControl(""),
        }),
        WorkProgress: new FormGroup({
          txtWorkProgressDaysInState: new FormControl("", [this.textValidators.isTextCheck]),
          drpWorkProgressWIPState: new FormControl(""),
          txtWorkProgressAssignedTo: new FormControl("", [this.textValidators.isTextCheck]),
          txtWorkProgressDateNeeded: new FormControl(""),
          chkWorkProgressCompleted: new FormControl(""),
        }),
      }),
      AuditTrial: new FormGroup({
        txtAuditTrialOrderNumber: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtAuditTrialType: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialDateCreated: new FormControl(""),
        txtAuditTrialCreatedBy: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialLastPrinted: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialPrintedBy: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialConfirmedBy: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialConfirmedDate: new FormControl(""),
        txtAuditTrialPrevBilling: new FormControl(""),
        txtAuditTrialNextBilling: new FormControl(""),
        drpCustomFieldsComplianceUsage: new FormControl(""),
        drpCustomFieldsAuthorizationOrder: new FormControl(""),
        drpCustomFieldsMaskOrder: new FormControl(""),
        drpCustomFieldsHeadGear: new FormControl(""),
        drpCustomFieldsTubing: new FormControl(""),
        drpCustomFieldsCushionsPillow: new FormControl(""),
        drpCustomFieldsDispFilters: new FormControl(""),
        drpCustomFieldsReusableFilters: new FormControl(""),
        drpCustomFieldsWaterChamber: new FormControl("")
        // txtCustomFieldsCpapBpapSettings: new FormControl(""),
        // txtCustomFieldsO2LiterFlow: new FormControl(""),
        // txtCustomFieldsHomeHealthAgency: new FormControl(""),
        // txtCustomFieldsMcareAuditLevel: new FormControl(""),
        // drpCustomFieldsSpecialityItemsRcm: new FormControl(""),
        // drpCustomFieldsBasicHcpc: new FormControl(""),
        // drpCustomFieldsGrp3Pmdu: new FormControl(""),
      }),
      Tracking: new FormGroup({
        txtTrackingCarrier: new FormControl(""),
        txtTrackingTrackingNo: new FormControl("", [this.numberValidators.isNumberCheck]),
      }),
    });


    this.clinicalForm = this.formBuilder.group({
      clinicalGeneral: new FormGroup({
        txtDOD: new FormControl(""),
        // txtHeight: new FormControl(""),
        txtGeneralHeightInches: new FormControl("", [Validators.min(0), Validators.max(11)]),
        txtGeneralHeightFeet: new FormControl("",),
        txtWeight: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtPractioner: new FormControl(null),
      }),
      clinicalOrder: new FormGroup({
        txtOrderingDoctor: new FormControl(null),
        txtMarketingRep: new FormControl(null),
        txtOrdSearchFilter: new FormControl(""),
        txtMarRepSearchFilter: new FormControl(""),
      }),
      clinicalRender: new FormGroup({
        drpRenderType: new FormControl(null),
        txtRenderDoctor: new FormControl(null),
        txtRenderFacility: new FormControl(""),
        txtRenDoctorFilter: new FormControl(null),
      }),
      clinicalRefer: new FormGroup({
        drpReferring: new FormControl(null),
        txtReferringDoctor: new FormControl(null),
        txtReferringFacility: new FormControl(""),
        txtRefDoctorFilter: new FormControl("")
      }),
      clinicalMarketing: new FormGroup({
        drpMarketingRef: new FormControl(null),
        txtMarketingDoctor: new FormControl(null),
        txtMarketingFacility: new FormControl(""),
        txtMarDoctorFilter: new FormControl(""),
      }),
      clinicalDiagnosis: new FormGroup({
        txtDiagnosis9: new FormControl(null),
        txtIcd10Filter1: new FormControl(null),
        txtIcd10Filter2: new FormControl(null),
        txtIcd10Filter3: new FormControl(null),
        txtDiagnosis10Id1: new FormControl(null),
        txtDiagnosis10Id2: new FormControl(null),
        txtDiagnosis10Id3: new FormControl(null),
        txtIcd9Filter: new FormControl(null),
      }),
      clinicalEpsdt: new FormGroup({
        drpCertification: new FormControl(null),
        drpCondition: new FormControl(null),
      })
    });


    this.insuranceForm = this.formBuilder.group({
      insurancePrimary: new FormGroup({
        txtPrimaryPolicy: new FormControl(""),
        txtPrimaryPaypct: new FormControl(0, [Validators.min(0), Validators.max(100), this.numberValidators.isNumberCheck]),
        txtPrimaryBox10d: new FormControl(""),
        txtPrimaryBox19: new FormControl(""),
        txtPrimaryBox24Ia: new FormControl(""),
        txtPrimaryBox24Jb: new FormControl(""),
        chkPrimaryIncludeBox24jb: new FormControl(""),
        chkPrimarySaleorder: new FormControl(""),
        chkPrimaryBeforeBill: new FormControl(""),
        chkPrimaryPayPct: new FormControl(""),
        txtPrimaryBox24Ja: new FormControl("",)
      }),
      insuranceSecondary: new FormGroup({
        txtSecondaryPolicy: new FormControl(""),
        txtSecondaryPct: new FormControl(0, [Validators.min(0), Validators.max(100), this.numberValidators.isNumberCheck]),

        txtSecondaryBox10: new FormControl(""),
        txtSecondaryBox19: new FormControl(""),
        txtSecondaryBox24Ia: new FormControl(""),
        txtSecondaryBox24Ja: new FormControl(""),
        txtSecondaryBox24Jb: new FormControl(""),
        chkSecondaryBox24Jb: new FormControl(""),
        chkSecondarySale: new FormControl(""),
        chkSecondaryBeforeBill: new FormControl(""),
        chkSecondaryPct: new FormControl(""),
      }),
      insuranceTertiary: new FormGroup({
        txtTertiaryPolicy: new FormControl(""),
        txtTertiaryPct: new FormControl(0, [Validators.min(0), Validators.max(100), this.numberValidators.isNumberCheck]),

        txtTertiaryBox10: new FormControl(""),
        txtTertiaryBox19: new FormControl(""),
        txtTertiaryBox24Ia: new FormControl(""),
        txtTertiaryBox24Ja: new FormControl(""),
        txtTertiaryBox24Jb: new FormControl(""),
        chkTertiaryInclude: new FormControl(""),
        chkTertiarySale: new FormControl(""),
        chkTertiaryBeforeBill: new FormControl(""),
        chkTertiaryPayPct: new FormControl(""),
      }),
      insuranceVerification: new FormGroup({
        chkVerificationInsurance: new FormControl(""),
        chkVerificationCoverage: new FormControl(""),
        chkVerificationExclude: new FormControl("")
      }),
      insurancePatient: new FormGroup({
        txtPatientPct: new FormControl(0, [Validators.min(0), Validators.max(100), this.numberValidators.isNumberCheck]),

        chkPatientSale: new FormControl(""),
        chkPatientBill: new FormControl(""),
      }),
      insuranceCompensation1: new FormGroup({
        chkCompensation1Auto: new FormControl(""),
        chkCompensation1Other: new FormControl(""),
        chkCompensation1Emp: new FormControl(""),
        txtCompensation1Onset: new FormControl(""),
        txtCompensation1State: new FormControl(""),
      }),
      insuranceCompensation2: new FormGroup({
        chkCompensation2eclaim: new FormControl(""),
        txtCompensation2Attatchment: new FormControl("", [this.numberValidators.isNumberCheck]),
        drpTypeCode: new FormControl(""),
        drpTrans: new FormControl(""),
        drpClaim: new FormControl(""),
        txtCompensation2Note: new FormControl(""),
      })

    });
    this.itemForm = this.fb.group({
      drpSaleType: new FormControl(""),
      txtQty: new FormControl("", this.number.isNumberCheck),
      txtBarcode: new FormControl(null),
    });
    this.verificationForm = this.formBuilder.group({
      //     drpChart: new FormControl("", [Validators.required]),
      txtName: new FormControl("", [this.textValidators.isTextCheck]),
      //   txtAddress: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateOfBirth: new FormControl("", [Validators.required]),
      txtCommentsNotes: new FormControl(""),
      txtPlan: new FormControl(null, [Validators.required]),
      txtPlanName: new FormControl("", [Validators.required]),
      txtPlanPhone: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl("", [Validators.required]),
      txtGroup: new FormControl(""),
      txtEffectiveDate: new FormControl("", [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(""),
      txtEnd: new FormControl(""),
      chkMedicare: new FormControl(""),
      txtVerfnAMethod: new FormControl(""),
      chkPatient: new FormControl(""),
      chkFamily: new FormControl(""),
      txtCmnCode: new FormControl("", [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(""),
      chkOutOfNetwork: new FormControl(""),
      chkFamilyDeductible: new FormControl(""),
      txtDeductibleIndividual: new FormControl(""),
      txtDeductibleOutOfNetwork: new FormControl(""),
      txtDeductibleFamily: new FormControl(""),
      txtDeductibleMetIndividual: new FormControl(""),
      txtDeductibleMetOutOfNetwork: new FormControl(""),
      txtDeductibleMetFamily: new FormControl(""),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(""),
      txtExpenseOutOfNetwork: new FormControl(""),
      txtExpenseFamily: new FormControl(""),
      txtExpenseMetIndividual: new FormControl(""),
      txtExpenseMetOutOfNetwork: new FormControl(""),
      txtExpenseMetFamily: new FormControl(""),
      chkExpenseIndividual: new FormControl(""),
      chkExpenseOutOfNetwork: new FormControl(""),
      chkExpenseFamily: new FormControl(""),
      chkPrecertRequired: new FormControl(""),
      txtPhoneCall: new FormControl("", [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(""),
      txtPcp: new FormControl("", [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(""),
      txtPreNotes: new FormControl(""),
      txtLifetimeMax: new FormControl(""),
      txtLifetimeMaxMet: new FormControl(""),
      chkDmePartsCovered: new FormControl(""),
      chkDmeReplacementUnit: new FormControl(""),
      txtDmeAnnualLimit: new FormControl(""),
      txtDmeLimitMet: new FormControl(""),
      txtRequestedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl("", [this.textValidators.isTextCheck]),
      txtContact: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(""),
      txtPolicyHolderName: new FormControl("", [this.textValidators.isTextCheck]),
      txtPolicyHolderDob: new FormControl(""),
      chkNetwork: new FormControl(""),
      txtClaimAddress: new FormControl(""),
      txtPlanCob: new FormControl(""),
      txtDedTotal: new FormControl(""),
      txtDedMet: new FormControl(""),
      txtOopTotal: new FormControl(""),
      txtOopMet: new FormControl(""),
      txtOopDeductible: new FormControl(""),
      txtDmeCoverage: new FormControl(""),
      txtDmeMax: new FormControl(""),
      txtHcpcs: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtState: new FormControl("", [this.textValidators.isTextCheck]),
      txtCoverageCriteria: new FormControl("", [this.textValidators.isTextCheck]),
      txtQtyLimits: new FormControl("", [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl("", [this.textValidators.isTextCheck]),
      txtRent: new FormControl("", [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl("", [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl("", [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl("", [this.textValidators.isTextCheck]),
      txtSpokeWith: new FormControl("", [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl("",),
      txtDateVerified: new FormControl(""),
      drpPreparedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPreparedOn: new FormControl(""),
      rbtnVerificationStatus: new FormControl("", [Validators.required]),
      txtDeo: new FormControl("", this.numberValidators.isNumberCheck),
      txtCoInsurance: new FormControl(""),
      txtcommentsToTech: new FormControl(""),


      // rbtnPending:new FormControl(""),
      // rbtnUnableToVerify:new FormControl(""),
    })


    this.authorizationForm = this.fb.group({
      txtAuthId: new FormControl("", [Validators.required]),
      drpAuthVisit: new FormControl(null),
      txtAuthAmount: new FormControl(""),
      txtStartDate: new FormControl(""),
      txtEndDate: new FormControl(""),
      drpCategory: new FormControl(null),
      drpItem: new FormControl(null),
      txtNote: new FormControl(""),
      chkCompleted: new FormControl(""),
      txtCompletedBy: new FormControl("", [this.text.isTextCheck]),
      drpPlan: new FormControl(null),
      txtPlan: new FormControl(null, [Validators.required]),
      // txtPlanName: new FormControl(null, [Validators.required]),
      rbtnAuth: new FormControl("", Validators.required),
      rbtnPolicyType: new FormControl(null),
    });





  }
  changeNextTab(value: string) {
    value === "Order" && this.nav?.select("Clinical");
    value === "Clinical" && this.nav?.select("Insurance");
    value === "Insurance" && this.nav?.select("Item");
    value === "Verification" && this.nav?.select("Authorization");
    value === "Authorization" && this.nav?.select("Document");
    value === "Document" && this.nav?.select("Reminders");
  }
  backToPatient() {
    if (this.patientId != null || this.patientId != "" || this.patientId != undefined) {
      this.router.navigate(['/patientViewEdit/' + this.patientId]);
    }
    else {
      this.router.navigate(['patientCreateEdit']);
    }
  }
  orderDetailsAfterSave(value: SaleorderDTO) {

    if (!(value === null || value === undefined || value?.id === ""))
      this.orderId = value?.id;

    // this.patientID = "ee89fdcd-1d60-9d56-6fab-39f9d5ebb575";
  }

  // current order status show
  currentOrderStatusShow(orderId) {
    if (this.orderId != null && this.orderId != "") {
      const getSaleOrderId = this.saleOrderService.get(orderId).subscribe(response => {
        // this.store.dispatch(new getOrder(orderId)).subscribe(response => {
        this.lblDefaultSaleOrderId = response?.saleorderId ?? "";
        this.currentOrderStatus = response?.currentOrderStatus;
      })
      this.subscription$.push(getSaleOrderId);
    }
  }

  currentPatientStatusShow(patientId) {
    if (this.patientId != null && this.patientId != "") {
      this.patientService.get(patientId).subscribe(response => {
        // this.store.dispatch(new getPatient(patientId)).subscribe(response => {
        this.currentOrderStatus = response?.currentStatus
      })
    }
  }

  openRemindermodal() {
    const config: MatDialogConfig = {
      disableClose: true, data: {
        patientId: this.patientId,
        defaultPatientId: this.defaultPatientId,
        saleOrderId: this.saleOrderId,
      }
    }
    const dialogRef = this.dialog.open(ReminderPopupComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getReminderTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true, data: {
        patientID: this.patientId,
        notesId: ""
      }
    }
    const dialogRef = this.dialog.open(AddNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      // this.getReminderTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
       data: {
        patientID: this.patientId,
      }
    }
    const dialogRef = this.dialog.open(OrderNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      // this.getReminderTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }


  getDetailsByPatientID(patientId: string) {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.BranchOffices,
      PatientDropdowns.Genders,
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const reponse: PatientMasterDropdownDTO = stateResponse;
      this.drpBranchOffices = reponse?.branchOffices;
      this.drpGenderLoop = reponse?.genders;
      // this.drpBranchOffices = reponse?.branchOffices;
      // this.drpAccountGroups = reponse?.accountGroups;
      // this.drpZones = reponse?.zones;
      // this.drpPTSecurityGroups = reponse['ptSecurityGroups'];
      // this.drpCustomerTypes = reponse?.customerTypes;
      // this.drpLocationTypes = reponse?.locationTypes;
      // this.drpCounty = reponse?.counties;
      // this.drpClaimFillingCodes = reponse?.claimFillingCodes;
      // this.drpPlaceOfServices = reponse?.placeOfServices;
      // this.drpFacility = reponse?.facilities;
      // this.drpTaxZone = reponse['taxZones'];
      // this.drpCity = reponse?.cities;
      // this.drpState = reponse?.states;
      // this.drpCountries = reponse?.countries;
      this.store.dispatch(new getPatient(patientId)).subscribe(responseData => {
        const personalResponse: PersonalDTO = responseData?.patientState?.selectedPatient?.personals;
        const patientResponse: PatientDTO = responseData?.patientState?.selectedPatient;
        const clinicalResponse: ClinicalDTO = responseData?.patientState?.selectedPatient?.clinicals;
        if (personalResponse !== null || personalResponse !== undefined) {
          this.lblDefaultPatientId = responseData?.patientState?.selectedPatient?.defaultPatientId?.toString();
          this.lblPatientName = personalResponse?.fullName;
          // this.lblPatientGender = clinicalResponse?.genderType == null ? "" : clinicalResponse?.genderType,
          this.lblPatientGender = clinicalResponse.genderType == 'M' ? "Male" : clinicalResponse.genderType == 'F' ? 'Female' : 'Unknown';// this.drpGenderLoop?.filter(value => { return value && value?.genderShortCodeId === clinicalResponse?.genderType })[0]?.genderName;
          this.lblPatientDOB = personalResponse?.dateOfBirth == null ? "" : new Date(personalResponse?.dateOfBirth)?.toLocaleDateString("en-US");
          this.lblPatientHeight = clinicalResponse?.height === 0 ? "" : clinicalResponse?.height.toString();
          this.lblPatientWeight = clinicalResponse?.weight === 0 ? "" : clinicalResponse?.weight.toString();
          //  this.lblBranchOffice = personalResponse?.branchOffice == null ? "" : personalResponse?.branchOffice
          this.lblAccountNumber = personalResponse?.accountNumber;
          this.lblSsnNumber = personalResponse?.ssn;
          this.patientId = responseData?.patientState?.selectedPatient?.id;
          this.lblBranchOffice = personalResponse?.branchOffice;
          // this.lblBranchOffice = this.drpBranchOffices?.filter(value => { return value && value?.branchOfficeShortCodeId === personalResponse?.branchOffice })[0]?.branchOffice;
          this.strLocation = personalResponse?.locationType;
          // this.drpLocationTypes?.filter(value => { return value && value?.locationTypeShortCodeId === patientResponse?.personals?.locationType })[0].locationType;
          this.strBillingAddress = patientResponse?.addresses[0].address;
          if (patientResponse?.billingDetails != null) {
            this.forBlink = false;
            this.lblBillingAmount = patientResponse?.billingDetails.amount;
            this.lblBillingStatus = patientResponse?.billingDetails.status;
          }
          this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
            this.strBillingCountry = response?.items?.filter(value => { return value && value?.countryShortName === patientResponse?.addresses[0]?.country })[0]?.countryName;
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });


          patientResponse?.addresses[0]?.country != null && patientResponse?.addresses[0]?.country !== "" && patientResponse?.addresses[0]?.country != undefined &&
            this.region.getAllStatesByCountryNameByCountryShortName(patientResponse?.addresses[0]?.country).subscribe(response => {
              this.strBillingState = response?.items?.filter(value => { return value && value?.stateShortName === patientResponse?.addresses[0]?.state })[0]?.stateName
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          // this.strBillingState = patientResponse?.addresses[0]?.state;//this.drpState?.filter(value => { return value && value?.stateId === patientResponse?.addresses[0].state })[0]?.stateType;
          this.strBillingCity = patientResponse?.addresses[0]?.city;// this.drpCity?.filter(value => { return value && value?.cityId === patientResponse?.addresses[0].city })[0]?.cityType;
          // this.strBillingCountry = patientResponse?.addresses[0]?.country; //this.drpCountries?.filter(value => { return value && value?.countryTypeId === patientResponse?.addresses[0].country })[0]?.countryType;
          this.strPostalCode = patientResponse?.addresses[0]?.postalCode;
          this.strMobile = patientResponse?.addresses[0]?.mobile;
          // this.strEmailAddress = patientResponse?.addresses[0]?.emailId;
          this.strEmailAddress = patientResponse?.personals?.emailId// billingEmailAddress;
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  // getdropdowns() {
  //   this.store.dispatch(new getDropdown()).subscribe((stateResponse) => {
  //     let reponse: PatientMasterDropdownDTO = stateResponse?.dropdownState?.selectedDropdown;
  //     this.drpBranchOffices = reponse?.branchOffices;
  //     this.drpGenderLoop = reponse?.genders;
  //     this.drpBranchOffices = reponse?.branchOffices;
  //     this.drpAccountGroups = reponse?.accountGroups;
  //     this.drpZones = reponse?.zones;
  //     this.drpPTSecurityGroups = reponse['ptSecurityGroups'];
  //     this.drpCustomerTypes = reponse?.customerTypes;
  //     this.drpLocationTypes = reponse?.locationTypes;
  //     this.drpCountries = reponse?.countries;
  //     this.drpClaimFillingCodes = reponse?.claimFillingCodes;
  //     this.drpPlaceOfServices = reponse?.placeOfServices;
  //     this.drpFacility = reponse?.facilities;
  //     this.drpTaxZone = reponse['taxZones'];
  //     this.drpCity = reponse?.cities;
  //     this.drpState = reponse?.states;
  //     this.drpCounty = reponse?.counties;

  //   })
  // }

  // getDetailsByPatientID(patientId: string) {
  //   this.store.dispatch(new getPatient(patientId)).subscribe(responseData => {
  //     let personalResponse: PersonalDTO = responseData?.patientState?.selectedPatient?.personals;
  //     let patientResponse: PatientDTO = responseData?.patientState?.selectedPatient;
  //     let clinicalResponse: ClinicalDTO = responseData?.patientState?.selectedPatient?.clinicals;
  //     if (personalResponse !== null || personalResponse !== undefined) {
  //       this.lblDefaultPatientId = personalResponse?.defaultPatientId?.toString();
  //       this.lblPatientName = personalResponse?.firstName + " " + personalResponse?.middleName + " " + personalResponse?.lastName;
  // this.lblPatientGender = clinicalResponse?.genderType == null ? "" : clinicalResponse?.genderType,
  //       this.lblPatientGender = this.drpGenderLoop?.filter(value => { return value && value?.genderId === clinicalResponse?.genderType })[0]?.genderName;
  //       this.lblPatientDOB = personalResponse?.dateOfBirth == null ? "" : new Date(personalResponse?.dateOfBirth)?.toLocaleDateString("en-US");
  //       this.lblPatientHeight = clinicalResponse?.height === 0 ? "" : clinicalResponse?.height?.toString();
  //       this.lblPatientWeight = clinicalResponse?.weight === 0 ? "" : clinicalResponse?.weight?.toString();
  //  this.lblBranchOffice = personalResponse?.branchOffice == null ? "" : personalResponse?.branchOffice
  //       this.lblAccountNumber = personalResponse?.accountNumber;
  //       this.lblSsnNumber = personalResponse?.ssn;
  //       this.patientId = responseData?.patientState?.selectedPatient?.id;
  //       this.lblBranchOffice = this.drpBranchOffices?.filter(value => { return value && value?.branchOfficeId === personalResponse?.branchOffice })[0]?.branchOffice;
  //       this.strLocation = this.drpLocationTypes?.filter(value => { return value && value?.locationTypeId === patientResponse?.personals.locationType })[0]?.locationType;
  //       this.strBillingAddress = patientResponse?.addresses[0]?.address;
  //       this.strBillingState = this.drpState?.filter(value => { return value && value?.stateId === patientResponse?.addresses[0]?.state })[0]?.stateType;
  //       this.strBillingCity = this.drpCity?.filter(value => { return value && value?.cityId === patientResponse?.addresses[0]?.city })[0]?.cityType;
  //       this.strBillingCountry = this.drpCountries?.filter(value => { return value && value?.countryTypeId === patientResponse?.addresses[0]?.country })[0]?.countryType;
  //       this.strPostalCode = patientResponse?.addresses[0]?.postalCode;
  //       this.strMobile = patientResponse?.addresses[0]?.mobile;
  //       this.strEmailAddress = patientResponse?.addresses[0]?.emailId;
  //     }
  //   });
  // }
  getReminderTableData() {
    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    const remainderList = (query: any) => this.reminder.getList(query);
    this.list.hookToQuery(remainderList).pipe(map(value => {
      const data: ReminderDTO[] = value?.items??[];
      data?.forEach(element => {
        element.subject = element?.subject ?? "";
        element.patientId = element?.patientId ?? "";
        element.patientName = element?.patientName ?? "";
        element.reminderDate = ((element?.reminderDate ?? "") === "") ? "" : new Date(element?.reminderDate)?.toLocaleDateString("en-US");
        element.creationTime = ((element?.creationTime ?? "") === "") ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US");
      });
      value.items = data;
      return value;
    })).subscribe(response => {
      this.table.setPateintRemindersTable(response?.items?.reverse());
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  beforeUnloadHander(event) {
    // this.PostCall();
    this.getConfirmationDialog(event);
    // return event.returnValue = "something..."
  }

  getConfirmationDialog(event: any) {
    event.returnValue = "something...."
    if ((this.savedOrderData && this.savedOrderData.dirty) || (this.savedContactData && this.savedContactData.dirty) || (this.savedClinicalData && this.savedClinicalData.dirty) || (this.savedInsuranceData && this.savedInsuranceData.dirty)(this.savedItemData && this.savedItemData.dirty)(this.savedVerificationData && this.savedVerificationData.dirty)(this.savedAuthorizationData && this.savedAuthorizationData.dirty)) {
      this.confirmationDialogService.confirm('Please confirm..', 'Do you want to save the changes ... ?')
        .then((confirmed) => {
          if ((confirmed == true) && this.savedOrderData && this.savedOrderData.dirty) {
            this.saveOrderForm();
          }
          else {
            this.orderForm.reset();
          }
          if ((confirmed == true) && this.savedInsuranceData && this.savedInsuranceData.dirty) {
            this.saveInsuranceForm();
          }
          else {
            this.insuranceForm.reset();
          }
          if ((confirmed == true) && this.savedItemData && this.savedItemData.dirty) {
            this.saveInsuranceForm();
          }
          else {
            this.insuranceForm.reset();
          }
          if ((confirmed == true) && this.savedVerificationData && this.savedVerificationData.dirty) {
            this.saveVerificationForm();
          }
          else {
            this.verificationForm.reset();
          }
          if ((confirmed == true) && this.savedAuthorizationData && this.savedAuthorizationData.dirty) {
            this.saveAuthorizationForm();
          }
          else {
            this.authorizationForm.reset();
          }
        })
        .catch((e) => e);
    }
  }


  onSaveOrderData(value: any) {
    this.savedOrderData = value;
  }
  onSaveClinicalData(value: any) {
    this.savedClinicalData = value;
  }
  onSaveInsuranceData(value: any) {
    this.savedInsuranceData = value;
  }
  onSaveItemData(value: any) {
    this.savedItemData = value;
  }
  onSaveVerificationData(value: any) {
    this.savedVerificationData = value;
  }
  onSaveAuthorizationData(value: any) {
    this.savedAuthorizationData = value;
  }

  onSaveVerificaiton(value: any) {
    this.insuranceVerificationId = value;
  }

  onNavChange($event: NgbNavChangeEvent) {
    // if (this.savedOrderData && this.showswal && this.savedOrderData.dirty) {
    //   if (($event.nextId === "Clinical") || ($event.nextId === "Insurance") || ($event.nextId === 'Item') || ($event.nextId === 'Verification') || ($event.nextId === 'Authorization') || ($event.nextId === 'Document') || ($event.nextId === 'Reminders')) {
    //     Swal.fire({
    //       title: 'Changes Has Been Done?',
    //       text: 'Would You Like To Save The Changes!',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#34c38f',
    //       cancelButtonColor: '#f46a6a',
    //       confirmButtonText: 'Yes!',
    //       cancelButtonText: 'No',
    //       allowOutsideClick: false,
    //     }).then(result => {
    //       if (result.value) {
    //         this.showswal = false;
    //         this.saveOrderForm();
    //       } else {
    //         this.showswal = false;
    //         this.savedOrderData = [];
    //       }
    //     })
    //   }
    //   this.showswal = true;
    // }
    // this.showswal = true;
    if (this.savedClinicalData && this.showswal && this.savedClinicalData.dirty) {
      if (($event.nextId === "Order") || ($event.nextId === "Insurance") || ($event.nextId === 'Item') || ($event.nextId === 'Verification') || ($event.nextId === 'Authorization') || ($event.nextId === 'Document') || ($event.nextId === 'Reminders')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveClinicalForm();
          } else {
            this.showswal = false;
            this.savedClinicalData = [];
          }
        })
      }
      this.showswal = true;
    }
    this.showswal = true;
    if (this.savedInsuranceData && this.showswal && this.savedInsuranceData.dirty) {
      if (($event.nextId === "Order") || ($event.nextId === "Clinical") || ($event.nextId === 'Item') || ($event.nextId === 'Verification') || ($event.nextId === 'Authorization') || ($event.nextId === 'Document') || ($event.nextId === 'Reminders')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveInsuranceForm();
          } else {

            this.showswal = false;
            this.savedInsuranceData = [];
          }
        })
      }
      this.showswal = true;
    }
    if (this.savedItemData && this.showswal && this.savedItemData.dirty) {

      if (($event.nextId === "Order") || ($event.nextId === "Clinical") || ($event.nextId === 'Insurance') || ($event.nextId === 'Verification') || ($event.nextId === 'Authorization') || ($event.nextId === 'Document') || ($event.nextId === 'Reminders')) {
        Swal.fire({
          title: 'Item Has Been Added?',
          text: 'Would You Like To Save This?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.savedItemForm();

          } else {

            this.showswal = false;
            this.savedItemData = [];
          }

        })
      }
      this.showswal = true;
    }
    if (this.savedVerificationData && this.showswal && this.savedVerificationData.dirty) {
      if (($event.nextId === "Order") || ($event.nextId === "Clinical") || ($event.nextId === 'Item') || ($event.nextId === 'Insurance') || ($event.nextId === 'Document') || ($event.nextId === 'Reminders') || ($event.nextId === 'Authorization')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveVerificationForm();
          } else {

            this.showswal = false;
            this.savedVerificationData = [];
          }
        })
      }
    }

    if (this.savedAuthorizationData && this.showswal && this.savedAuthorizationData.dirty) {

      if (($event.nextId === "Order") || ($event.nextId === "Clinical") || ($event.nextId === 'Item') || ($event.nextId === 'Insurance') || ($event.nextId === 'Verification') || ($event.nextId === 'Document') || ($event.nextId === 'Reminders')) {

        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveAuthorizationForm();
          } else {

            this.showswal = false;
            this.savedAuthorizationData = [];
          }
        })
      }
      this.showswal = true;
    }
  }




  // Save order Tab change fuctions
  saveOrderForm() {
    if (this.savedOrderData && this.savedOrderData !== null) {
      this.orderForm.patchValue({

        Delivery: {
          txtScheduledDate: this.savedOrderData.controls.Delivery.controls.txtScheduledDate.value,
          txtScheduledTime: this.savedOrderData.controls.Delivery.controls.txtScheduledTime.value,
          txtActualDate: this.savedOrderData.controls.Delivery.controls.txtActualDate.value,
          txtActualTime: this.savedOrderData.controls.Delivery.controls.txtActualTime.value,
          txtAddress: this.savedOrderData.controls.Delivery.controls.txtAddress.value,
          drpCity: this.savedOrderData.controls.Delivery.controls.drpCity.value,
          // chkNotValidated:
          drpState: this.savedOrderData.controls.Delivery.controls.drpState.value,
          drpCounty: this.savedOrderData.controls.Delivery.controls.drpCounty.value,
          drpCountry: this.savedOrderData.controls.Delivery.controls.drpCountry.value,
          txtPostalCode: this.savedOrderData.controls.Delivery.controls.txtPostalCode.value,
          txtPhone: this.savedOrderData.controls.Delivery.controls.txtPhone.value,
          txtMobile: this.savedOrderData.controls.Delivery.controls.txtMobile.value,
          txtFacility: this.savedOrderData.controls.Delivery.controls.txtFacility.value,
          txtTaxzone: this.savedOrderData.controls.Delivery.controls.txtTaxzone.value,
          txtTaxRate: this.savedOrderData.controls.Delivery.controls.txtTaxRate.value,
          txtOrderNote: this.savedOrderData.controls.Delivery.controls.txtOrderNote.value,
          txtDeliveryNote: this.savedOrderData.controls.Delivery.controls.txtDeliveryNote.value,
          drpSetupMethod: this.savedOrderData.controls.Delivery.controls.drpSetupMethod.value,
          txtDeliveryTechnician: this.savedOrderData.controls.Delivery.controls.txtDeliveryTechnician.value,
          chkSignatureRequired: this.savedOrderData.controls.Delivery.controls.chkSignatureRequired.value,
          drpFulfillmentVendor: this.savedOrderData.controls.Delivery.controls.drpFulfillmentVendor.value,
          drpAccountNumber: this.savedOrderData.controls.Delivery.controls.drpAccountNumber.value,
          drpShipBy: this.savedOrderData.controls.Delivery.controls.txtStatus.value,
          txtStatus: this.savedOrderData.controls.Delivery.controls.txtStatus.value,
          txtStatusDate: this.savedOrderData.controls.Delivery.controls.txtStatusDate.value,
          txtdrpShipStatus: this.savedOrderData.controls.Delivery.controls.txtdrpShipStatus.value,
          chkCahPayment: this.savedOrderData.controls.Delivery.controls.chkCahPayment.value
        },
        General: {
          chkGeneralManualHold: this.savedOrderData.controls.General.controls.chkGeneralManualHold.value,
          drpGeneralHoldReason: this.savedOrderData.controls.General.controls.drpGeneralHoldReason.value,
          txtGeneralStopDate: this.savedOrderData.controls.General.controls.txtGeneralStopDate.value,
          drpGeneralStopReason: this.savedOrderData.controls.General.controls.drpGeneralStopReason.value,
          drpGeneralBranch: this.savedOrderData.controls.General.controls.drpGeneralBranch.value,
          drpGeneralInvLocation: this.savedOrderData.controls.General.controls.drpGeneralInvLocation.value,
          drpGeneralStatus: this.savedOrderData.controls.General.controls.drpGeneralStatus.value,
          drpGeneralClassification: this.savedOrderData.controls.General.controls.drpGeneralClassification.value,
          drpGeneralPlaceOfService: this.savedOrderData.controls.General.controls.drpGeneralPlaceOfService.value,
          txtGeneralDateOfAdmission: this.savedOrderData.controls.General.controls.txtGeneralDateOfAdmission.value,
          txtGeneralDateOfDischarge: this.savedOrderData.controls.General.controls.txtGeneralDateOfDischarge.value,
          txtGeneralDiscountPct: this.savedOrderData.controls.General.controls.txtGeneralDiscountPct.value,
          txtGeneralPoNumber: this.savedOrderData.controls.General.controls.txtGeneralPoNumber.value,
          txtGeneralReference: this.savedOrderData.controls.General.controls.txtGeneralReference.value,
          txtGeneralUser1: this.savedOrderData.controls.General.controls.txtGeneralUser1.value,
          txtGeneralUser2: this.savedOrderData.controls.General.controls.txtGeneralUser2.value,
          txtGeneralUser3: this.savedOrderData.controls.General.controls.txtGeneralUser3.value,
          txtGeneralUser4: this.savedOrderData.controls.General.controls.txtGeneralUser4.value,
          txtGeneralPriorSystemKey: this.savedOrderData.controls.General.controls.txtGeneralPriorSystemKey.value,
        },
        AutoConfirm: {
          chkAutoConfirmAutoConfirm: this.savedOrderData.controls.AutoConfirm.controls.chkAutoConfirmAutoConfirm.value,
          txtAutoConfirmInitialDate: this.savedOrderData.controls.AutoConfirm.controls.txtAutoConfirmInitialDate.value,
          PointDelivery: {
            txtPointDeliveryOrderStatus: this.savedOrderData.controls.AutoConfirm.controls.PointDelivery.controls.txtPointDeliveryOrderStatus.value,
            txtPointDeliveryStatusDateTime: this.savedOrderData.controls.AutoConfirm.controls.PointDelivery.controls.txtPointDeliveryStatusDateTime.value,
            txtPointDeliveryLastMessage: this.savedOrderData.controls.AutoConfirm.controls.PointDelivery.controls.txtPointDeliveryLastMessage.value,
            txtPointDeliveryLastMessageDateTime: this.savedOrderData.controls.AutoConfirm.controls.PointDelivery.controls.txtPointDeliveryLastMessageDateTime.value,
          },
          BrightShip: {
            txtBrightShipShippingStatus: this.savedOrderData.controls.AutoConfirm.controls.BrightShip.controls.txtBrightShipShippingStatus.value,
            drpBrightShipShippingCarrier: this.savedOrderData.controls.AutoConfirm.controls.BrightShip.controls.drpBrightShipShippingCarrier.value,
            drpBrightShipShippingMethod: this.savedOrderData.controls.AutoConfirm.controls.BrightShip.controls.drpBrightShipShippingMethod.value,
          },
          WorkProgress: {
            txtWorkProgressDaysInState: this.savedOrderData.controls.AutoConfirm.controls.WorkProgress.controls.txtWorkProgressDaysInState.value,
            drpWorkProgressWIPState: this.savedOrderData.controls.AutoConfirm.controls.WorkProgress.controls.drpWorkProgressWIPState.value,
            txtWorkProgressAssignedTo: this.savedOrderData.controls.AutoConfirm.controls.WorkProgress.controls.txtWorkProgressAssignedTo.value,
            txtWorkProgressDateNeeded: this.savedOrderData.controls.AutoConfirm.controls.WorkProgress.controls.txtWorkProgressDateNeeded.value,
            chkWorkProgressCompleted: this.savedOrderData.controls.AutoConfirm.controls.WorkProgress.controls.chkWorkProgressCompleted.value,
          },
        },
        AuditTrial: {
          txtAuditTrialOrderNumber: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialOrderNumber.value,
          txtAuditTrialType: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialType.value,
          txtAuditTrialDateCreated: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialDateCreated.value,
          txtAuditTrialCreatedBy: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialCreatedBy.value,
          txtAuditTrialLastPrinted: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialLastPrinted.value,
          txtAuditTrialPrintedBy: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialPrintedBy.value,
          txtAuditTrialConfirmedBy: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialConfirmedBy.value,
          txtAuditTrialConfirmedDate: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialConfirmedDate.value,
          txtAuditTrialPrevBilling: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialPrevBilling.value,
          txtAuditTrialNextBilling: this.savedOrderData.controls.AuditTrial.controls.txtAuditTrialNextBilling.value,
          drpCustomFieldsComplianceUsage: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsComplianceUsage.value,
          drpCustomFieldsAuthorizationOrder: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsAuthorizationOrder.value,
          drpCustomFieldsMaskOrder: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsMaskOrder.value,
          drpCustomFieldsHeadGear: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsHeadGear.value,
          drpCustomFieldsTubing: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsTubing.value,
          drpCustomFieldsCushionsPillow: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsCushionsPillow.value,
          drpCustomFieldsDispFilters: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsDispFilters.value,
          drpCustomFieldsReusableFilters: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsReusableFilters.value,
          drpCustomFieldsWaterChamber: this.savedOrderData.controls.AuditTrial.controls.drpCustomFieldsWaterChamber.value,

        },

        Tracking: {
          txtTrackingCarrier: this.savedOrderData.controls.Tracking.controls.txtTrackingCarrier.value,
          txtTrackingTrackingNo: this.savedOrderData.controls.Tracking.controls.txtTrackingTrackingNo.value,
        },

      });
    }
    this.showswal = false;
    this.saveOrder()
    this.savedOrderData = [];
  }

  // ! Save Order Page Details
  saveOrder() {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    let personal: PatientDTO;
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      personal = response?.patientState.selectedPatient;
      personal = { ...personal }

      let patient: PatientDTO = response?.patientState.selectedPatient;

      let updatePatientPersonaldata: PersonalDTO = patient.personals;
      updatePatientPersonaldata = { branchOffice: this.orderForm.value.General.drpGeneralBranch, ...updatePatientPersonaldata }
      let updatePatientAddressdata: AddressDTO[] = patient.addresses;
      updatePatientAddressdata = [

        {
          addressType: updatePatientAddressdata[1].addressType,
          address: this.orderForm.value.Delivery.txtAddress,
          city: this.orderForm.value.Delivery.drpCity === null || this.orderForm.value.Delivery.drpCity === undefined ? "" : this.orderForm.value.Delivery.drpCity,
          state: this.orderForm.value.Delivery.drpState === null || this.orderForm.value.Delivery.drpState === undefined ? "" : this.orderForm.value.Delivery.drpState,
          county: this.orderForm.value.Delivery.drpCounty === null || this.orderForm.value.Delivery.drpCounty === undefined ? "" : this.orderForm.value.Delivery.drpCounty,
          country: this.orderForm.value.Delivery.drpCountry === null || this.orderForm.value.Delivery.drpCountry === undefined ? "" : this.orderForm.value.Delivery.drpCountry,
          postalCode: this.orderForm.value.Delivery.txtPostalCode,
          phone: this.orderForm.value.Delivery.txtPhone,
          mobile: this.orderForm.value.Delivery.txtMobile,
          relationship: updatePatientAddressdata[1].relationship,
          fax: updatePatientAddressdata[1].fax,
          // emailId: updatePatientAddressdata[1].emailId,
          zone: updatePatientAddressdata[1].zone,
        },
        updatePatientAddressdata[0]];

      patient = {
        id: patient.id,
        // patientId:this.patientId,
        personals: updatePatientPersonaldata,
        addresses: updatePatientAddressdata,
        contacts: patient.contacts,
        clinicals: patient.clinicals,
        insurances: patient.insurances,
        notes: patient.notes,
        policies: patient.policies,
        patientDocuments: patient.patientDocuments,
        defaultPatientId: patient.defaultPatientId,
        insuranceVerifications: patient.insuranceVerifications,
        authorizations: patient.authorizations,
        organizationUnitId: this.organizationUnitId,
        tenantId: patient.tenantId,
        isAutoIntake: patient.isAutoIntake,
        autoIntakeDocumentDetails: patient.autoIntakeDocumentDetails,
        chartId: patient.chartId,
        billingDetails: patient.billingDetails,
        patientLock: patient.patientLock,
        isSorted: patient.isSorted,
        fileId: patient?.fileId,
      }

      setTimeout(() => {
        this.store.dispatch(new updatePatient(this.patientId, patient)).subscribe(response => {
          personal = response?.patientState.selectedPatient;
          personal = { ...personal }

          setTimeout(() => {


            if (this.saleorderId == 0 && this.orderId === "" || this.orderId === null || this.orderId === undefined) {
              personal = { ...personal }
              const orderData = {
                orders: {

                  // saleorderId: this.saleorderId,
                  scheduledDate: this.orderForm.value.Delivery.txtScheduledDate,
                  scheduledTime: this.orderForm.value.Delivery.txtScheduledTime,
                  actualDate: this.orderForm.value.Delivery.txtActualDate,
                  actualTime: this.orderForm.value.Delivery.txtActualTime,
                  address: this.orderForm.value.Delivery.txtAddress,
                  city: this.orderForm.value.Delivery.drpCity === null || undefined ? "" : this.orderForm.value.Delivery.drpCity,
                  state: this.orderForm.value.Delivery.drpState === null || undefined ? "" : this.orderForm.value.Delivery.drpState,
                  county: this.orderForm.value.Delivery.drpCounty === null || undefined ? "" : this.orderForm.value.Delivery.drpCounty,
                  country: this.orderForm.value.Delivery.drpCountry === null || undefined ? "" : this.orderForm.value.Delivery.drpCountry,
                  postalCode: this.orderForm.value.Delivery.txtPostalCode,
                  phone: this.orderForm.value.Delivery.txtPhone,
                  mobile: this.orderForm.value.Delivery.txtMobile,
                  facilityId: this.orderForm.value.Delivery.txtFacility,
                  taxZoneId: this.orderForm.value.Delivery.txtTaxzone,
                  taxRate: this.orderForm.value.Delivery.txtTaxRate,
                  orderNote: this.orderForm.value.Delivery.txtOrderNote,
                  deliveryNote: this.orderForm.value.Delivery.txtDeliveryNote,
                  setupMethodId: this.orderForm.value.Delivery.drpSetupMethod,
                  deliveryTechnicianId: this.orderForm.value.Delivery.txtDeliveryTechnician,
                  dropShipStatus: this.orderForm.value.Delivery.txtdrpShipStatus,
                  isSignatureRequired: +(this.orderForm.value.Delivery.chkSignatureRequired) | 0,

                  isManualHold: +(this.orderForm.value.General.chkGeneralManualHold) | 0,
                  holdReasonId: this.orderForm.value.General.drpGeneralHoldReason,
                  stopDate: this.orderForm.value.General.txtGeneralStopDate,
                  stopReasonId: this.orderForm.value.General.drpGeneralStopReason,
                  branchId: this.orderForm.value.General.drpGeneralBranch,
                  invLocationId: this.orderForm.value.General.drpGeneralInvLocation,
                  statusId: this.orderForm.value.General.drpGeneralStatus,
                  classificationId: this.orderForm.value.General.drpGeneralClassification,
                  placeOfServiceId: this.orderForm.value.General.drpGeneralPlaceOfService,
                  dateOfAdmission: this.orderForm.value.General.txtGeneralDateOfAdmission,
                  dateOfDischarge: this.orderForm.value.General.txtGeneralDateOfDischarge,
                  discountPct: this.orderForm.value.General.txtGeneralDiscountPct == "" ? '0' : this.orderForm.value.General.txtGeneralDiscountPct,
                  poNumber: this.orderForm.value.General.txtGeneralPoNumber,
                  reference: this.orderForm.value.General.txtGeneralReference,
                  user1: this.orderForm.value.General.txtGeneralUser1,
                  user2: this.orderForm.value.General.txtGeneralUser2,
                  user3: this.orderForm.value.General.txtGeneralUser3,
                  user4: this.orderForm.value.General.txtGeneralUser4,
                  priorSystemKey: this.orderForm.value.General.txtGeneralPriorSystemKey,
                  orderStatus: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryOrderStatus,
                  statusDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryStatusDateTime,
                  lastMessage: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessage,
                  lastMessageDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessageDateTime,
                  orderNumber: this.orderForm.value.AuditTrial.txtAuditTrialOrderNumber,
                  type: this.orderForm.value.AuditTrial.txtAuditTrialType,
                  dateCreated: this.orderForm.value.AuditTrial.txtAuditTrialDateCreated,
                  createdBy: this.orderForm.value.AuditTrial.txtAuditTrialCreatedBy,
                  lastPrinted: this.orderForm.value.AuditTrial.txtAuditTrialLastPrinted,
                  printedBy: this.orderForm.value.AuditTrial.txtAuditTrialPrintedBy,
                  confirmedBy: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedBy,
                  confirmedDate: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedDate,
                  prevBilling: this.orderForm.value.AuditTrial.txtAuditTrialPrevBilling,
                  nextBilling: this.orderForm.value.AuditTrial.txtAuditTrialNextBilling,
                  shippingStatus: this.orderForm.value.AutoConfirm.BrightShip.txtBrightShipShippingStatus,
                  shippingCarrierId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingCarrier,
                  shippingMethodId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingMethod,
                  daysInState: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDaysInState,
                  wipStateId: this.orderForm.value.AutoConfirm.WorkProgress.drpWorkProgressWIPState,
                  assignedToId: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressAssignedTo,
                  dateNeeded: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDateNeeded,

                  isCompleted: this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === true || this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === 1 ? "Done" : "Pending",
                  complianceUsageMetId: this.orderForm.value.AuditTrial.drpCustomFieldsComplianceUsage,
                  authorizationOfOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsAuthorizationOrder,
                  maskOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsMaskOrder,
                  headGearId: this.orderForm.value.AuditTrial.drpCustomFieldsHeadGear,
                  tubingId: this.orderForm.value.AuditTrial.drpCustomFieldsTubing,
                  cushionsPillowId: this.orderForm.value.AuditTrial.drpCustomFieldsCushionsPillow,
                  dispFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsDispFilters,
                  reusableFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsReusableFilters,
                  waterChamberId: this.orderForm.value.AuditTrial.drpCustomFieldsWaterChamber,
                  carrierId: this.orderForm.value.Tracking.txtTrackingCarrier,
                  trackingNo: this.orderForm.value.Tracking.txtTrackingTrackingNo,

                },
                insurances: [],
                clinicals: [],
                items: [],
                // saleorderId:0,
                orderStatus: "",
                saleorderId: "",
                organizationUnitId: this.organizationUnitId,
                currentStage: "orderProcessing",
                currentStageStatus: "",
                patients: personal,
                defaultClaimId: "",
                claimId: "00000000-0000-0000-0000-000000000000",
                patientId: this.patientId,
                isShippingApprove: 0,
                isCashPayment: +this.orderForm.value.Delivery.chkCahPayment | 0,
                orderProcessStatus: "",
                patientLedgerReason: "",
                documentReason: "",
                paymentTransactionDetails: null,
                payPalCheckOutOrderMailResendCount: 0,
                payPalCheckOutOrderPaymentStatus: null,
                payPalInvoiceMailResendCount: 0,
                payPalInvoicePaymentStatus: null,
                // saleorderId:0
              }
              this.saveOrUpdate(orderData);
            }
            else {
              let selectedOrder: SaleorderDTO;
              (this.orderId != "" && this.orderId != null && this.orderId != undefined) && this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
                selectedOrder = response?.orderState?.selectedOrder;


                personal = { ...personal }
                const orderData = {
                  orders: {
                    // saleorderId: selectedOrder?.orders[0].saleorderId,
                    // saleorderId:0,
                    scheduledDate: this.orderForm.value.Delivery.txtScheduledDate,
                    scheduledTime: this.orderForm.value.Delivery.txtScheduledTime,
                    actualDate: this.orderForm.value.Delivery.txtActualDate,
                    actualTime: this.orderForm.value.Delivery.txtActualTime,
                    address: this.orderForm.value.Delivery.txtAddress,
                    city: this.orderForm.value.Delivery.drpCity === null || undefined ? "" : this.orderForm.value.Delivery.drpCity,
                    state: this.orderForm.value.Delivery.drpState === null || undefined ? "" : this.orderForm.value.Delivery.drpState,
                    county: this.orderForm.value.Delivery.drpCounty === null || undefined ? "" : this.orderForm.value.Delivery.drpCounty,
                    country: this.orderForm.value.Delivery.drpCountry === null || undefined ? "" : this.orderForm.value.Delivery.drpCountry,
                    postalCode: this.orderForm.value.Delivery.txtPostalCode,
                    phone: this.orderForm.value.Delivery.txtPhone,
                    mobile: this.orderForm.value.Delivery.txtMobile,
                    facilityId: this.orderForm.value.Delivery.txtFacility,
                    taxZoneId: this.orderForm.value.Delivery.txtTaxzone,
                    taxRate: this.orderForm.value.Delivery.txtTaxRate,
                    orderNote: this.orderForm.value.Delivery.txtOrderNote,
                    deliveryNote: this.orderForm.value.Delivery.txtDeliveryNote,
                    setupMethodId: this.orderForm.value.Delivery.drpSetupMethod,
                    deliveryTechnicianId: this.orderForm.value.Delivery.txtDeliveryTechnician,
                    dropShipStatus: this.orderForm.value.Delivery.txtdrpShipStatus,
                    isSignatureRequired: +(this.orderForm.value.Delivery.chkSignatureRequired) | 0,
                    isManualHold: +(this.orderForm.value.General.chkGeneralManualHold) | 0,
                    holdReasonId: this.orderForm.value.General.drpGeneralHoldReason,
                    stopDate: this.orderForm.value.General.txtGeneralStopDate,
                    stopReasonId: this.orderForm.value.General.drpGeneralStopReason,
                    branchId: this.orderForm.value.General.drpGeneralBranch,
                    invLocationId: this.orderForm.value.General.drpGeneralInvLocation,
                    statusId: this.orderForm.value.General.drpGeneralStatus,
                    classificationId: this.orderForm.value.General.drpGeneralClassification,
                    placeOfServiceId: this.orderForm.value.General.drpGeneralPlaceOfService,
                    dateOfAdmission: this.orderForm.value.General.txtGeneralDateOfAdmission,
                    dateOfDischarge: this.orderForm.value.General.txtGeneralDateOfDischarge,
                    discountPct: this.orderForm.value.General.txtGeneralDiscountPct == "" ? '0' : this.orderForm.value.General.txtGeneralDiscountPct,
                    poNumber: this.orderForm.value.General.txtGeneralPoNumber,
                    reference: this.orderForm.value.General.txtGeneralReference,
                    user1: this.orderForm.value.General.txtGeneralUser1,
                    user2: this.orderForm.value.General.txtGeneralUser2,
                    user3: this.orderForm.value.General.txtGeneralUser3,
                    user4: this.orderForm.value.General.txtGeneralUser4,
                    priorSystemKey: this.orderForm.value.General.txtGeneralPriorSystemKey,
                    orderStatus: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryOrderStatus,
                    statusDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryStatusDateTime,
                    lastMessage: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessage,
                    lastMessageDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessageDateTime,
                    orderNumber: this.orderForm.value.AuditTrial.txtAuditTrialOrderNumber,
                    type: this.orderForm.value.AuditTrial.txtAuditTrialType,
                    dateCreated: this.orderForm.value.AuditTrial.txtAuditTrialDateCreated,
                    createdBy: this.orderForm.value.AuditTrial.txtAuditTrialCreatedBy,
                    lastPrinted: this.orderForm.value.AuditTrial.txtAuditTrialLastPrinted,
                    printedBy: this.orderForm.value.AuditTrial.txtAuditTrialPrintedBy,
                    confirmedBy: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedBy,
                    confirmedDate: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedDate,
                    prevBilling: this.orderForm.value.AuditTrial.txtAuditTrialPrevBilling,
                    nextBilling: this.orderForm.value.AuditTrial.txtAuditTrialNextBilling,
                    shippingStatus: this.orderForm.value.AutoConfirm.BrightShip.txtBrightShipShippingStatus,
                    shippingCarrierId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingCarrier,
                    shippingMethodId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingMethod,
                    daysInState: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDaysInState,
                    wipStateId: this.orderForm.value.AutoConfirm.WorkProgress.drpWorkProgressWIPState,
                    assignedToId: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressAssignedTo,
                    dateNeeded: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDateNeeded,
                    isCompleted: this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === true || this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === 1 ? "Done" : "Pending",
                    complianceUsageMetId: this.orderForm.value.AuditTrial.drpCustomFieldsComplianceUsage,
                    authorizationOfOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsAuthorizationOrder,
                    maskOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsMaskOrder,
                    headGearId: this.orderForm.value.AuditTrial.drpCustomFieldsHeadGear,
                    tubingId: this.orderForm.value.AuditTrial.drpCustomFieldsTubing,
                    cushionsPillowId: this.orderForm.value.AuditTrial.drpCustomFieldsCushionsPillow,
                    dispFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsDispFilters,
                    reusableFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsReusableFilters,
                    waterChamberId: this.orderForm.value.AuditTrial.drpCustomFieldsWaterChamber,
                    carrierId: this.orderForm.value.Tracking.txtTrackingCarrier,
                    trackingNo: this.orderForm.value.Tracking.txtTrackingTrackingNo,
                  },
                  insurances: selectedOrder?.insurances,
                  clinicals: selectedOrder?.clinicals,
                  items: selectedOrder?.items,
                  orderStatus: selectedOrder?.orderStatus,
                  saleorderId: selectedOrder?.saleorderId,
                  patients: personal,
                  defaultClaimId: "",
                  claimId: "00000000-0000-0000-0000-000000000000",
                  organizationUnitId: this.organizationUnitId,
                  // saleorderId:0
                  // saleorderId:0
                  currentStage: (selectedOrder?.currentStage === "orderProcessing" && selectedOrder?.orders?.isCompleted === "1") ? "orderProcessing" : "shippingApproval",
                  currentStageStatus: selectedOrder?.currentStageStatus,
                  patientId: selectedOrder?.patientId,
                  isShippingApprove: selectedOrder?.isShippingApprove,
                  isCashPayment: +this.orderForm.value.Delivery.chkCahPayment | 0,
                  orderProcessStatus: selectedOrder?.orderProcessStatus,
                  patientLedgerReason: selectedOrder?.patientLedgerReason,
                  documentReason: selectedOrder?.documentReason,
                  paymentTransactionDetails: selectedOrder.paymentTransactionDetails,
                  payPalMailResendCount: selectedOrder?.payPalMailResendCount,
                  payPalPaymentMethodType: selectedOrder?.payPalPaymentMethodType,
                  payPalPaymentStatus: selectedOrder?.payPalPaymentStatus,
                  claimHistoryStatus: response.claimHistoryStatus,
                  patientLedgerStatus: response.patientLedgerStatus
                }
                this.saveOrUpdate(orderData);
              }, () => {
                // const data: HttpErrorResponse = err;
                // Swal.fire({
                //   // icon: 'error',
                //   text: data?.error?.error?.message,
                // });
              })
            }
          }, 300);
        }, () => {
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   // icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        })
      }, 300);
      // this.Table.setSelectedPatientId(this.patientId);
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   // icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }
  saveOrUpdate(orderData) {


    if ((this.orderId !== "" && this.orderId !== null && this.orderId !== undefined)) {

      this.store.dispatch(new updateOrder(this.orderId, orderData)).subscribe((stateResponse) => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            // !change next tab
            this.nextTabMoveOnSaveEmitter.emit("Personal");
          }
        }, () => {
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   // icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        });
        // this.getTableData(this.orderId)
        const orderDetail = stateResponse?.orderState?.selectedOrder;
        this.orderId = stateResponse?.orderState?.selectedOrder?.id;
        this.orderIdEmitter.emit(orderDetail);
        this.Table.setSelectedOrderId(this.orderId);
        localStorage.setItem('orderId', this.orderId);
        this.saveOrderFormState.emit(this.orderForm.value);
        if (orderDetail.orders.isCompleted === "Done") {
          this.show = true;
          this.show1 = false;
        }


      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          // icon: 'error',
          text: data?.error?.error?.message,
        });
      })
    } else {
      this.store.dispatch(new addOrder(orderData)).subscribe((stateResponse) => {

        this.saleorderId = 0;

        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            // !change next tab
            this.nextTabMoveOnSaveEmitter.emit("Order");
          }
        }, () => { });
        const orderDetail = stateResponse?.orderState?.selectedOrder;
        this.orderId = stateResponse?.orderState?.selectedOrder?.id;
        this.orderIdEmitter.emit(orderDetail);
        this.Table.setSelectedOrderId(this.orderId);
        localStorage.setItem('orderId', this.orderId);
        this.saveOrderFormState.emit(this.orderForm.value);
        if (orderDetail.orders.isCompleted === "Done") {
          this.show = true;
          this.show1 = false;
        }

      }, error => {
        const errData: HttpErrorResponse = error;
        errData?.error && errData?.error?.error &&
          Swal.fire({
            // title: 'Please Fill Below Required Feilds',
            text: `${errData?.error?.error.message}`
          })

      })
    }

  }

  // Save Clinical Tab change fuctions
  saveClinicalForm() {
    if (this.savedClinicalData && this.savedClinicalData !== null) {
      this.clinicalForm.patchValue({
        clinicalGeneral: {
          txtDOD: this.savedClinicalData.controls.clinicalGeneral.controls.txtDOD.value,
          txtGeneralHeightInches: this.savedClinicalData.controls.clinicalGeneral.controls.txtGeneralHeightInches.value,
          txtGeneralHeightFeet: this.savedClinicalData.controls.clinicalGeneral.controls.txtGeneralHeightFeet.value,
          txtWeight: this.savedClinicalData.controls.clinicalGeneral.controls.txtWeight.value,
          txtPractioner: this.savedClinicalData.controls.clinicalGeneral.controls.txtPractioner.value,
        },
        clinicalOrder: {
          txtOrderingDoctor: this.savedClinicalData.controls.clinicalOrder.controls.txtOrderingDoctor.value,
          txtMarketingRep: this.savedClinicalData.controls.clinicalOrder.controls.txtMarketingRep.value,
        },
        clinicalRender: {
          drpRenderType: this.savedClinicalData.controls.clinicalRender.controls.drpRenderType.value,
          txtRenderDoctor: this.savedClinicalData.controls.clinicalRender.controls.txtRenderDoctor.value,
          txtRenderFacility: this.savedClinicalData.controls.clinicalRender.controls.txtRenderFacility.value,
        },
        clinicalRefer: {
          drpReferring: this.savedClinicalData.controls.clinicalRefer.controls.drpReferring.value,
          txtReferringDoctor: this.savedClinicalData.controls.clinicalRefer.controls.txtReferringDoctor.value,
          txtReferringFacility: this.savedClinicalData.controls.clinicalRefer.controls.txtReferringFacility.value,
        },
        clinicalMarketing: {
          drpMarketingRef: this.savedClinicalData.controls.clinicalMarketing.controls.drpMarketingRef.value,
          txtMarketingDoctor: this.savedClinicalData.controls.clinicalMarketing.controls.txtMarketingDoctor.value,
          txtMarketingFacility: this.savedClinicalData.controls.clinicalMarketing.controls.txtMarketingFacility.value,
        },
        clinicalDiagnosis: {
          txtDiagnosis9: this.savedClinicalData.controls.clinicalDiagnosis.controls.txtDiagnosis9.value,
          txtDiagnosis10Id1: this.savedClinicalData.controls.clinicalDiagnosis.controls.txtDiagnosis10Id1.value,
          txtDiagnosis10Id2: this.savedClinicalData.controls.clinicalDiagnosis.controls.txtDiagnosis10Id2.value,
          txtDiagnosis10Id3: this.savedClinicalData.controls.clinicalDiagnosis.controls.txtDiagnosis10Id3.value,
        },
        clinicalEpsdt: {
          drpCertification: this.savedClinicalData.controls.clinicalEpsdt.controls.drpCertification.value,
          drpCondition: this.savedClinicalData.controls.clinicalEpsdt.controls.drpCondition.value,
        }

      });
    }
    this.showswal = false;
    this.getPatientDetailsForClinicalSave();
    this.savedClinicalData = [];
  }
  getPatientDetailsForClinicalSave() {

    if ((this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == "" ||
      this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == null ||
      this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == undefined) &&
      (this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == "" ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == undefined ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == null)) {
      this.height = "0"
    }
    else {
      this.height = this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet + '.' +
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches;

      parseFloat(this.height);
    }
    let patientData: PatientDTO;
    let clinicalOrderDetails: SaleorderDTO;
    let patientDetails: PatientDTO;
    let clinicalDetails: ClinicalDTO;
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      patientData = response.patientState.selectedPatient;
      patientDetails = response.patientState.selectedPatient;
      patientData = { ...patientData }
      clinicalDetails = patientData.clinicals;
      clinicalDetails = {
        id: this.patientId,
        height: this.height == "0" ? 0 : parseFloat(this.height),
        weight: +(this.clinicalForm.value.clinicalGeneral.txtWeight) | 0,
        dod: this.clinicalForm.value.clinicalGeneral.txtDOD == null ? "" : this.clinicalForm.value.clinicalGeneral.txtDOD,
        generalPractitioner: this.clinicalForm.value.clinicalGeneral.txtPractioner,
        renderingProviderType: this.clinicalForm?.get("clinicalRender.drpRenderType").value,
        renderingProviderDoctor: this.clinicalForm.value.clinicalRender.txtRenderDoctor,
        renderingProviderFacility: this.clinicalForm.value.clinicalRender.txtRenderFacility,
        referringProviderType: this.clinicalForm?.get("clinicalRefer.drpReferring").value,
        referringProviderDoctor: this.clinicalForm.value.clinicalRefer.txtReferringDoctor,
        referringProviderFacility: this.clinicalForm.value.clinicalRefer.txtReferringFacility,
        orderingDoctor: this.clinicalForm.value.clinicalOrder.txtOrderingDoctor,

        marketingRepType: this.clinicalForm.value.clinicalOrder.txtMarketingRep,
        marketingReferralType: this.clinicalForm?.get("clinicalMarketing.drpMarketingRef").value,
        marketingReferralDoctor: this.clinicalForm.value.clinicalMarketing.txtMarketingDoctor,
        marketingReferralFacility: this.clinicalForm.value.clinicalMarketing.txtMarketingFacility,
        diagnosisCode9: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis9,
        diagnosisCode10: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis10Id1,
        // diagnosisCode10Id2: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis10Id2,
        // diagnosisCode10Id3: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis10Id3,
        infectiousConditon: clinicalDetails.infectiousConditon,
        airborne: clinicalDetails.airborne,
        contact: clinicalDetails.contact,
        droplet: clinicalDetails.droplet,
        primaryDoctor: clinicalDetails.primaryDoctor,
        genderType: clinicalDetails.genderType,
        functionalAbility: clinicalDetails.functionalAbility,
        marketingReferralContact: clinicalDetails.marketingReferralContact,
        appointmentDate: clinicalDetails.appointmentDate,
        branch: clinicalDetails.branch,
        description: clinicalDetails.description,
        practitioner: clinicalDetails.practitioner,
        status: clinicalDetails.status

      }
      patientDetails = {
        organizationUnitId: patientDetails?.organizationUnitId,
        id: patientDetails?.id,
        tenantId: patientDetails?.tenantId,
        personals: patientDetails?.personals,
        addresses: patientDetails?.addresses,
        contacts: patientDetails?.contacts,
        clinicals: clinicalDetails,
        insurances: patientDetails?.insurances,
        notes: patientDetails?.notes,
        policies: patientDetails?.policies,
        defaultPatientId: patientDetails?.defaultPatientId,
        insuranceVerifications: patientDetails?.insuranceVerifications,
        authorizations: patientDetails?.authorizations,
        patientDocuments: patientDetails?.patientDocuments,
        isAutoIntake: patientDetails?.isAutoIntake,
        autoIntakeDocumentDetails: patientDetails?.autoIntakeDocumentDetails,
        chartId: patientDetails?.chartId,
        billingDetails: patientDetails?.billingDetails,
        patientLock: patientDetails?.patientLock,
        isSorted: patientDetails?.isSorted,
        fileId: patientDetails?.fileId,

      }
      setTimeout(() => {
        this.store.dispatch(new updatePatient(this.patientId, patientDetails)).subscribe(response => {
          this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
            patientData = response.patientState.selectedPatient;
            patientData = { ...patientDetails };
            setTimeout(() => {
              this.store.dispatch(new getOrder(this.orderId)).subscribe(orderResponse => {
                clinicalOrderDetails = { ...orderResponse.orderState.selectedOrder };
                this.SaveClinical(clinicalOrderDetails, patientData);
              }, err => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }, () => { });
            }, 500);
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          })
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }, 300);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  SaveClinical(response: SaleorderDTO, patientDetails: PatientDTO) {
    if ((this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == "" ||
      this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == null ||
      this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == undefined) &&
      (this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == "" ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == undefined ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == null)) {
      this.height = "0"
    }
    else {
      this.height = this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet + '.' +
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches;

      parseFloat(this.height);
    }
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let clinicalDetails;
    clinicalDetails = {
      orders: response.orders,
      insurances: response.insurances,
      defaultClaimId: response?.defaultClaimId,
      clinicals: [{
        dod: this.clinicalForm?.get("clinicalGeneral.txtDOD").value,
        height: this.height == "0" ? 0 : parseFloat(this.height),
        weight: +(this.clinicalForm?.get("clinicalGeneral.txtWeight").value) | 0,
        practitionerId: this.clinicalForm?.get("clinicalGeneral.txtPractioner").value,
        renderingProviderTypeId: this.clinicalForm?.get("clinicalRender.drpRenderType").value,
        renderingProviderDoctorId: this.clinicalForm?.get("clinicalRender.txtRenderDoctor").value,
        renderingProviderFacilityId: this.clinicalForm?.get("clinicalRender.txtRenderFacility").value,
        referringProviderTypeId: this.clinicalForm?.get("clinicalRefer.drpReferring").value,
        referringProviderDoctorId: this.clinicalForm?.get("clinicalRefer.txtReferringDoctor").value,
        referringProviderFacilityId: this.clinicalForm?.get("clinicalRefer.txtReferringFacility").value,
        orderingDoctorId: this.clinicalForm?.get("clinicalOrder.txtOrderingDoctor").value,
        marketingReferralTypeId: this.clinicalForm?.get("clinicalMarketing.drpMarketingRef").value,
        marketingReferralDoctorId: this.clinicalForm?.get("clinicalMarketing.txtMarketingDoctor").value,
        marketingReferralFacilityId: this.clinicalForm?.get("clinicalMarketing.txtMarketingFacility").value,
        marketingRepTypeId: this.clinicalForm?.get("clinicalOrder.txtMarketingRep").value,
        diagnosisCode9: this.clinicalForm?.get("clinicalDiagnosis.txtDiagnosis9").value,
        diagnosisCode10: this.clinicalForm?.get("clinicalDiagnosis.txtDiagnosis10Id1").value,
        // diagnosisCode10Id2: this.clinicalForm?.get("clinicalDiagnosis.txtDiagnosis10Id2").value,
        // diagnosisCode10Id3: this.clinicalForm?.get("clinicalDiagnosis.txtDiagnosis10Id3").value,
        certificationConditionIndictorId: this.clinicalForm?.get("clinicalEpsdt.drpCertification").value,
        conditionCodeId: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,

        // genderType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // functionalAbility:this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // generalPractitioner:this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // infectiousConditon: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // airborne: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // contact: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // droplet: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // renderingProviderType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // renderingProviderDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // renderingProviderFacility: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // referringProviderType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // referringProviderDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // referringProviderFacility: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // orderingDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // primaryDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // marketingRepType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // marketingReferralType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // marketingReferralDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // marketingReferralFacility: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // marketingReferralContact: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // appointmentDate: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // branch: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // description: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // practitioner: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        // status: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
      }],
      items: response.items,
      orderStatus: response.orderStatus,
      saleorderId: response.saleorderId,
      patients: patientDetails,
      organizationUnitId: response.organizationUnitId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      claimId: response.claimId,
      patientId: response.patientId,
      isShippingApprove: response.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
    }
    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, clinicalDetails)).subscribe(() => {
      // !
      Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        if (result.value) {
          // !change next tab

        }
      }, err => { });


      // !

      //
      Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        if (result.value) {
          // !change next tab

        }
      }, err => { });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }

  // save insurance tab change fuctions
  saveInsuranceForm() {
    if (this.savedInsuranceData && this.savedInsuranceData !== null) {
      this.insuranceForm.patchValue({
        insurancePrimary: {
          txtPrimaryPolicy: this.savedInsuranceData.controls.insurancePrimary.controls.txtPrimaryPolicy.value,
          txtPrimaryPaypct: this.savedInsuranceData.controls.insurancePrimary.controls.txtPrimaryPaypct.value,
          txtPrimaryBox10d: this.savedInsuranceData.controls.insurancePrimary.controls.txtPrimaryBox10d.value,
          txtPrimaryBox19: this.savedInsuranceData.controls.insurancePrimary.controls.txtPrimaryBox19.value,
          txtPrimaryBox24Ia: this.savedInsuranceData.controls.insurancePrimary.controls.txtPrimaryBox24Ia.value,
          txtPrimaryBox24Jb: this.savedInsuranceData.controls.insurancePrimary.controls.txtPrimaryBox24Jb.value,
          chkPrimaryIncludeBox24jb: this.savedInsuranceData.controls.insurancePrimary.controls.chkPrimaryIncludeBox24jb.value,
          chkPrimarySaleorder: this.savedInsuranceData.controls.insurancePrimary.controls.chkPrimarySaleorder.value,
          chkPrimaryBeforeBill: this.savedInsuranceData.controls.insurancePrimary.controls.chkPrimaryBeforeBill.value,
          chkPrimaryPayPct: this.savedInsuranceData.controls.insurancePrimary.controls.chkPrimaryPayPct.value,
          txtPrimaryBox24Ja: this.savedInsuranceData.controls.insurancePrimary.controls.txtPrimaryBox24Ja.value,
        },

        insuranceSecondary: {
          txtSecondaryPolicy: this.savedInsuranceData.controls.insuranceSecondary.controls.txtSecondaryPolicy.value,
          txtSecondaryPct: this.savedInsuranceData.controls.insuranceSecondary.controls.txtSecondaryPct.value,
          txtSecondaryBox10: this.savedInsuranceData.controls.insuranceSecondary.controls.txtSecondaryBox10.value,
          txtSecondaryBox19: this.savedInsuranceData.controls.insuranceSecondary.controls.txtSecondaryBox19.value,
          txtSecondaryBox24Ia: this.savedInsuranceData.controls.insuranceSecondary.controls.txtSecondaryBox24Ia.value,
          txtSecondaryBox24Ja: this.savedInsuranceData.controls.insuranceSecondary.controls.txtSecondaryBox24Ja.value,
          txtSecondaryBox24Jb: this.savedInsuranceData.controls.insuranceSecondary.controls.txtSecondaryBox24Jb.value,
          chkSecondaryBox24Jb: this.savedInsuranceData.controls.insuranceSecondary.controls.chkSecondaryBox24Jb.value,
          chkSecondarySale: this.savedInsuranceData.controls.insuranceSecondary.controls.chkSecondarySale.value,
          chkSecondaryBeforeBill: this.savedInsuranceData.controls.insuranceSecondary.controls.chkSecondaryBeforeBill.value,
          chkSecondaryPct: this.savedInsuranceData.controls.insuranceSecondary.controls.chkSecondaryPct.value,
        },

        insuranceTertiary: {
          txtTertiaryPolicy: this.savedInsuranceData.controls.insuranceTertiary.controls.txtTertiaryPolicy.value,
          txtTertiaryPct: this.savedInsuranceData.controls.insuranceTertiary.controls.txtTertiaryPct.value,
          txtTertiaryBox10: this.savedInsuranceData.controls.insuranceTertiary.controls.txtTertiaryBox10.value,
          txtTertiaryBox19: this.savedInsuranceData.controls.insuranceTertiary.controls.txtTertiaryBox19.value,
          txtTertiaryBox24Ia: this.savedInsuranceData.controls.insuranceTertiary.controls.txtTertiaryBox24Ia.value,
          txtTertiaryBox24Ja: this.savedInsuranceData.controls.insuranceTertiary.controls.txtTertiaryBox24Ja.value,
          txtTertiaryBox24Jb: this.savedInsuranceData.controls.insuranceTertiary.controls.txtTertiaryBox24Jb.value,
          chkTertiaryInclude: this.savedInsuranceData.controls.insuranceTertiary.controls.chkTertiaryInclude.value,
          chkTertiarySale: this.savedInsuranceData.controls.insuranceTertiary.controls.chkTertiarySale.value,
          chkTertiaryBeforeBill: this.savedInsuranceData.controls.insuranceTertiary.controls.chkTertiaryBeforeBill.value,
          chkTertiaryPayPct: this.savedInsuranceData.controls.insuranceTertiary.controls.chkTertiaryPayPct.value,


        },
        insuranceVerification: {
          chkVerificationInsurance: this.savedInsuranceData.controls.insuranceVerification.controls.chkVerificationInsurance.value,
          chkVerificationCoverage: this.savedInsuranceData.controls.insuranceVerification.controls.chkVerificationCoverage.value,
          chkVerificationExclude: this.savedInsuranceData.controls.insuranceVerification.controls.chkVerificationExclude.value,
        },
        insurancePatient: {
          txtPatientPct: this.savedInsuranceData.controls.insurancePatient.controls.txtPatientPct.value,
          chkPatientSale: this.savedInsuranceData.controls.insurancePatient.controls.chkPatientSale.value,
          chkPatientBill: this.savedInsuranceData.controls.insurancePatient.controls.chkPatientBill.value,
        },
        insuranceCompensation1: {
          chkCompensation1Auto: this.savedInsuranceData.controls.insuranceCompensation1.controls.chkCompensation1Auto.value,
          chkCompensation1Other: this.savedInsuranceData.controls.insuranceCompensation1.controls.chkCompensation1Other.value,
          chkCompensation1Emp: this.savedInsuranceData.controls.insuranceCompensation1.controls.chkCompensation1Emp.value,
          txtCompensation1Onset: this.savedInsuranceData.controls.insuranceCompensation1.controls.txtCompensation1Onset.value,
          txtCompensation1State: this.savedInsuranceData.controls.insuranceCompensation1.controls.txtCompensation1State.value,

        },
        insuranceCompensation2: {
          chkCompensation2eclaim: this.savedInsuranceData.controls.insuranceCompensation2.controls.chkCompensation2eclaim.value,
          txtCompensation2Attatchment: this.savedInsuranceData.controls.insuranceCompensation2.controls.txtCompensation2Attatchment.value,
          drpTypeCode: this.savedInsuranceData.controls.insuranceCompensation2.controls.drpTypeCode.value,
          drpTrans: this.savedInsuranceData.controls.insuranceCompensation2.controls.drpTrans.value,
          drpClaim: this.savedInsuranceData.controls.insuranceCompensation2.controls.drpClaim.value,
          txtCompensation2Note: this.savedInsuranceData.controls.insuranceCompensation2.controls.txtCompensation2Note.value,
        },
      });
    }
    this.showswal = false;
    this.getPatientDetailsForInsuranceSave()
    this.savedInsuranceData = [];
  }
  getPatientDetailsForInsuranceSave() {
    let patientData: PatientDTO;
    let insuranceOrderDetails: SaleorderDTO;
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      patientData = response.patientState.selectedPatient;
      let updatePatientData: PatientDTO = response.patientState.selectedPatient;
      patientData = { ...patientData };
      const insuranceData: InsuranceDTO[] = [{
        stateOfAutoAccident: this.insuranceForm.value.insuranceCompensation1.txtCompensation1State,
        injuryRelatedToEmployment: +this.insuranceForm.value.insuranceCompensation1.chkCompensation1Emp | 0,
        injuryRelatedToAutoAccident: +this.insuranceForm.value.insuranceCompensation1.chkCompensation1Auto | 0,
        injuryRelatedToOtherAccident: +this.insuranceForm.value.insuranceCompensation1.chkCompensation1Other | 0,
        dataOnset: this.insuranceForm.value.insuranceCompensation1.txtCompensation1Onset,
        martialStatus: patientData.insurances[0].martialStatus,
        employment: patientData.insurances[0].employment,
        printAmountsOnDeliveryTickets: patientData.insurances[0].printAmountsOnDeliveryTickets,
        patientFinancialHardship: patientData.insurances[0].patientFinancialHardship,
        discountPct: patientData.insurances[0].discountPct,
        startDate: patientData.insurances[0].startDate,
        reviewDate: patientData.insurances[0].reviewDate,
        id: patientData.id,
      }];
      updatePatientData = {
        insurances: insuranceData,
        personals: updatePatientData.personals,
        contacts: updatePatientData.contacts,
        clinicals: updatePatientData.clinicals,
        addresses: updatePatientData.addresses,
        notes: updatePatientData.notes,
        policies: updatePatientData.policies,
        patientDocuments: updatePatientData.patientDocuments,
        tenantId: updatePatientData.tenantId,
        insuranceVerifications: updatePatientData.insuranceVerifications,
        authorizations: updatePatientData.authorizations,
        organizationUnitId: updatePatientData.organizationUnitId,
        isAutoIntake: updatePatientData.isAutoIntake,
        autoIntakeDocumentDetails: updatePatientData.autoIntakeDocumentDetails,
        chartId: updatePatientData.chartId,
        billingDetails: updatePatientData.billingDetails,
        patientLock: updatePatientData.patientLock,
        isSorted: updatePatientData.isSorted,
        fileId: updatePatientData?.fileId,
      }
      setTimeout(() => {
        this.store.dispatch(new updatePatient(this.patientId, updatePatientData)).subscribe(response => {
          //
          patientData = response.patientState.selectedPatient;
          patientData = { ...patientData };
          setTimeout(() => {
            this.store.dispatch(new getOrder(this.orderId)).subscribe(orderResponse => {
              insuranceOrderDetails = { ...orderResponse.orderState.selectedOrder };
              this.saveInsurance(insuranceOrderDetails, patientData);
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }, 5);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        })
      }, 200);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  saveInsurance(response, patientData: PatientDTO) {
    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let insuranceDetails;
    insuranceDetails = {
      orders: response.orders,
      insurances: [{
        primaryPolicyId: this.insuranceForm?.get("insurancePrimary.txtPrimaryPolicy").value,
        primaryPayPct: +this.insuranceForm?.get("insurancePrimary.txtPrimaryPaypct").value | 0,
        primaryBox10d: this.insuranceForm?.get("insurancePrimary.txtPrimaryBox10d").value,
        primaryBox19: this.insuranceForm?.get("insurancePrimary.txtPrimaryBox19").value,
        primaryBox24la: this.insuranceForm?.get("insurancePrimary.txtPrimaryBox24Ia").value,
        primaryBox24ja: this.insuranceForm?.get("insurancePrimary.txtPrimaryBox24Ja").value,
        primaryBox24jb: this.insuranceForm?.get("insurancePrimary.txtPrimaryBox24Jb").value,
        isPrimaryIncludeBox24jb: +(this.insuranceForm?.get("insurancePrimary.chkPrimaryIncludeBox24jb").value) | 0,
        isPrimaryIncludePayorLevel: +(this.insuranceForm?.get("insurancePrimary.chkPrimarySaleorder").value) | 0,
        isPrimaryWaitForPreviousPayor: + (this.insuranceForm?.get("insurancePrimary.chkPrimaryBeforeBill").value) | 0,
        isPrimaryPayPct: + (this.insuranceForm?.get("insurancePrimary.chkPrimaryPayPct").value) | 0,
        isInsuranceVerified: +(this.insuranceForm?.get("insuranceVerification.chkVerificationInsurance").value) | 0,
        isCoverageVerified: +(this.insuranceForm?.get("insuranceVerification.chkVerificationCoverage").value) | 0,
        isExcludeFromEligibiltyCheck: +(this.insuranceForm?.get("insuranceVerification.chkVerificationExclude").value) | 0,
        patientPayPct: +this.insuranceForm?.get("insurancePatient.txtPatientPct").value | 0,
        isPatientIncludePayorLevel: +(this.insuranceForm?.get("insurancePatient.chkPatientSale").value) | 0,
        isPatientWaitForPreviousPayor: +(this.insuranceForm?.get("insurancePatient.chkPatientBill").value) | 0,
        secondaryPolicyId: this.insuranceForm?.get("insuranceSecondary.txtSecondaryPolicy").value,
        secondaryPayPct: +this.insuranceForm?.get("insuranceSecondary.txtSecondaryPct").value | 0,
        secondaryBox10d: this.insuranceForm?.get("insuranceSecondary.txtSecondaryBox10").value,
        secondaryBox19: this.insuranceForm?.get("insuranceSecondary.txtSecondaryBox19").value,
        secondaryBox24la: this.insuranceForm?.get("insuranceSecondary.txtSecondaryBox24Ia").value,
        secondaryBox24ja: this.insuranceForm?.get("insuranceSecondary.txtSecondaryBox24Ja").value,
        secondaryBox24jb: this.insuranceForm?.get("insuranceSecondary.txtSecondaryBox24Jb").value,
        isSecondaryIncludeFor24jb: +(this.insuranceForm?.get("insuranceSecondary.chkSecondaryBox24Jb").value) | 0,
        isSecondaryIncludePayorLevel: +(this.insuranceForm?.get("insuranceSecondary.chkSecondarySale").value) | 0,
        isSecondaryWaitForPreviousPayor: +(this.insuranceForm?.get("insuranceSecondary.chkSecondaryBeforeBill").value) | 0,
        isSecondaryPayPct: +(this.insuranceForm?.get("insuranceSecondary.chkSecondaryPct").value) | 0,
        dateOfOnset: this.insuranceForm?.get("insuranceCompensation1.txtCompensation1Onset").value,
        isInjuryRelatedToEmployment: +(this.insuranceForm?.get("insuranceCompensation1.chkCompensation1Emp").value) | 0,
        isInjuryRelatedToAutoAccident: +(this.insuranceForm?.get("insuranceCompensation1.chkCompensation1Auto").value) | 0,
        stateOfAccidentId: this.insuranceForm?.get("insuranceCompensation1.txtCompensation1State").value,
        isInjuryRelatedToOtherAccident: +(this.insuranceForm?.get("insuranceCompensation1.chkCompensation1Other").value) | 0,
        isIncludeEClaimsAttachment: +(this.insuranceForm?.get("insuranceCompensation2.chkCompensation2eclaim").value) | 0,
        attachmentNumber: this.insuranceForm?.get("insuranceCompensation2.txtCompensation2Attatchment").value,
        typeCodeId: this.insuranceForm?.get("insuranceCompensation2.drpTypeCode").value,
        transCodeId: this.insuranceForm?.get("insuranceCompensation2.drpTrans").value,
        claimNoteTypeId: this.insuranceForm?.get("insuranceCompensation2.drpClaim").value,
        note: this.insuranceForm?.get("insuranceCompensation2.txtCompensation2Note").value,
        tertiaryPolicyId: this.insuranceForm?.get("insuranceTertiary.txtTertiaryPolicy").value,
        tertiaryPayPct: this.insuranceForm?.get("insuranceTertiary.txtTertiaryPct").value | 0,
        tertiaryBox10d: this.insuranceForm?.get("insuranceTertiary.txtTertiaryBox10").value,
        tertiaryBox19: this.insuranceForm?.get("insuranceTertiary.txtTertiaryBox19").value,
        tertiaryBox24la: this.insuranceForm?.get("insuranceTertiary.txtTertiaryBox24Ia").value,
        tertiaryBox24ja: this.insuranceForm?.get("insuranceTertiary.txtTertiaryBox24Ja").value,
        tertiaryBox24jb: this.insuranceForm?.get("insuranceTertiary.txtTertiaryBox24Jb").value,
        isTertiaryIncludeFor24jb: this.insuranceForm?.get("insuranceTertiary.chkTertiaryInclude").value | 0,
        isTertiaryIncludePayorLevel: this.insuranceForm?.get("insuranceTertiary.chkTertiarySale").value | 0,
        isTertiaryWaitForPreviousPayor: this.insuranceForm?.get("insuranceTertiary.chkTertiaryBeforeBill").value | 0,
        isTertiaryPayPct: this.insuranceForm?.get("insuranceTertiary.chkTertiaryPayPct").value | 0,
      }],
      defaultClaimId: response?.defaultClaimId,
      clinicals: response.clinicals,
      items: response.items,
      orderStatus: response.orderStatus,
      saleorderId: response.saleorderId,
      organizationUnitId: response.organizationUnitId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      patients: patientData,
      claimId: response.claimId,
      patientId: this.patientId,
      isShippingApprove: response.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalCheckOutOrderMailResendCount: response?.payPalCheckOutOrderMailResendCount,
      payPalCheckOutOrderPaymentStatus: response.payPalCheckOutOrderPaymentStatus,
      payPalInvoiceMailResendCount: response.payPalInvoiceMailResendCount,
      payPalInvoicePaymentStatus: response.payPalInvoicePaymentStatus
      // saleorderId:response.saleorderId
    }
    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, insuranceDetails)).subscribe(response => {
      Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        if (result.value) {
          // !change next tab

        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }



  // save tab change for item save
  savedItemForm() {
    if (this.savedItemData && this.savedItemData !== null) {
      this.itemForm.patchValue({
        drpSaleType: this.savedItemData.controls.drpSaleType.value,
        txtQty: this.savedItemData.controls.txtQty.value,
        txtBarcode: this.savedItemData.controls.txtBarcode.value,


      });
    }

    this.showswal = false;
    this.getPatientDetailsForItemSave();
    this.savedItemData = [];
  }
  getPatientDetailsForItemSave() {
    let itemOrderDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {

      itemOrderDetails = response.orderState.selectedOrder;
      this.saveItem(itemOrderDetails);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
      //
    });
  }
  itemId: string = "00000000-0000-0000-0000-000000000000";
  saveItem(response: SaleorderDTO) {
    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let itemDetails: SaleorderDTO;
    let currentItems: ItemDTO[] = response?.items;
    const selectedItem: itemInterface = this.drpitem?.filter(value => { return value && value?.id === this.itemForm.value.txtBarcode })[0];
    if (this.itemId != "00000000-0000-0000-0000-000000000000") {
      currentItems = currentItems.filter(items => { return items && items.itemId != this.itemId })
      currentItems = [...currentItems, {

        itemId: this.itemId,
        saleType: this.itemForm.value.drpSaleType,
        quickAddQuantity: +(this.itemForm.value.txtQty) | 0,
        itemIdOrBarCode: this.itemForm.value.txtBarcode,
        itemCodeId: selectedItem.itemId,
        name: selectedItem.name,
        description: "",
        location: selectedItem.location,
        note: "",
        insuranceName: selectedItem.insurance,
        isSpecialPricing: 0,
        isTaxable: 0,
        isPriceOverride: 0,
        isSpecialTaxRate: 0,
        isAcceptAssignment: 0,
        isManualConvertToPurchase: 0,
        isAbn: 0,
        isAbnUpgrade: 0,
        isCbOverride: 0,
        qty: +(this.itemForm.value.txtQty) | 0,
        bQty: 0,
        priceCode: "",
        priceOption: "",
        modifiers1: "",
        modifiers2: "",
        modifiers3: "",
        modifiers4: "",
        charge: (selectedItem.charge) ? Number(selectedItem.charge) : 0,
        allow: "",
        // parId: "",
        // parNumber: "",
        // parStatus: "",
        // parExpDate: "",
        taxRate: "",
        taxZone: "",
        nonTaxReason: "",
        quantity: 0,
        billingQuantity: 0,
        isPrimary: 0,
        coverageType: "",
        claimSubmission: "",
        isSecondary: 0,
        isTertiary: 0,
        isPatient: 0,
        isDaysOfSupplyOverride: 0,
        daysSupply: "",
        originalDos: "",
        dosTo: "",
        preBilling: "",
        nextBilling: "",
        nextPeriod: "",
        nextDOS: "",
        isSpanDateSplitBillingEnabled: 0,
        // cmnId: "",
        // cmnStatus: "",
        // cmnExpDate: "",
        // cmnForm: "",
        itemClaimNoteType: "",
        itemClaimNoteQuantity: 0,
        additionalModifiers1: "",
        additionalModifiers2: "",
        additionalModifiers3: "",
        additionalModifiers4: "",
        espdtIndicator: "",
        isIncludeEclaimsAttachment: 0,
        attachmentNo: "",
        typeCode: "",
        transCode: "",
        isDiagnosisCodeICD9: 0,
        isDiagnosisCodeICD10: 0,
        itemProcedureCode: "",
        // lotId:[],
        // currentAvailableStockCount:10,
        productId: "00000000-0000-0000-0000-000000000000",
        productType: "",
        productStockId: "00000000-0000-0000-0000-000000000000",
        // isAllSerialNumbersUpdated:true,
        isItemRuleCheck: 0,
        productLotDetails: null,
        totalPrice: 0,
        isFollowupNeeded: true,
        followUpDuration: 30
      }]
    } else {
      currentItems = [...currentItems, {
        itemId: this.itemId,
        saleType: this.itemForm.value.drpSaleType,
        quickAddQuantity: +(this.itemForm.value.txtQty) | 0,
        itemIdOrBarCode: this.itemForm.value.txtBarcode,
        itemCodeId: selectedItem.itemId,
        name: selectedItem.name,
        description: "",
        location: selectedItem.location,
        note: "",
        insuranceName: selectedItem.insurance,
        isSpecialPricing: 0,
        isTaxable: 0,
        isPriceOverride: 0,
        isSpecialTaxRate: 0,
        isAcceptAssignment: 0,
        isManualConvertToPurchase: 0,
        isAbn: 0,
        isAbnUpgrade: 0,
        isCbOverride: 0,
        qty: +(this.itemForm.value.txtQty) | 0,
        bQty: 0,
        priceCode: "",
        priceOption: "",
        modifiers1: "",
        modifiers2: "",
        modifiers3: "",
        modifiers4: "",
        charge: (selectedItem.charge) ? Number(selectedItem.charge) : 0,

        allow: "",
        // parId: "",
        // parNumber: "",
        // parStatus: "",
        // parExpDate: "",
        taxRate: "",
        taxZone: "",
        nonTaxReason: "",
        quantity: 0,
        billingQuantity: 0,
        isPrimary: 0,
        coverageType: "",
        claimSubmission: "",
        isSecondary: 0,
        isTertiary: 0,
        isPatient: 0,
        isDaysOfSupplyOverride: 0,
        daysSupply: "",
        originalDos: "",
        dosTo: "",
        preBilling: "",
        nextBilling: "",
        nextPeriod: "",
        nextDOS: "",
        isSpanDateSplitBillingEnabled: 0,
        // cmnId: "",
        // cmnStatus: "",
        // cmnExpDate: "",
        // cmnForm: "",
        itemClaimNoteType: "",
        itemClaimNoteQuantity: 0,
        additionalModifiers1: "",
        additionalModifiers2: "",
        additionalModifiers3: "",
        additionalModifiers4: "",
        espdtIndicator: "",
        isIncludeEclaimsAttachment: 0,
        attachmentNo: "",
        typeCode: "",
        transCode: "",
        isDiagnosisCodeICD9: 0,
        isDiagnosisCodeICD10: 0,
        itemProcedureCode: "",
        // lotId:[],
        //  currentAvailableStockCount:10,
        productId: "00000000-0000-0000-0000-000000000000",
        productType: "",
        productStockId: "00000000-0000-0000-0000-000000000000",
        //  isAllSerialNumbersUpdated:true,
        isItemRuleCheck: 0,
        productLotDetails: null,
        totalPrice: 0,
        isFollowupNeeded: true,
        followUpDuration: 30
      }]
    }
    itemDetails = {
      defaultClaimId: response.defaultClaimId,
      orders: response.orders,
      clinicals: response.clinicals,
      insurances: response.insurances,
      items: currentItems,
      patients: response.patients,
      saleorderId: response.saleorderId,
      organizationUnitId: response.organizationUnitId,
      claimId: response.claimId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      orderStatus: response.orderStatus,
      patientId: response.patientId,
      isShippingApprove: response.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      shippingCheckList: response?.shippingCheckList,
      orderBilling: response?.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType

      // saleorderId: response.saleorderId
    }


    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, itemDetails)).subscribe(() => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.itemId = "00000000-0000-0000-0000-000000000000";
      this.getTableData(this.orderId)
      this.clearDataForItem();
      // !
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }
  // get table table data for item
  getTableForItemData(orderId: string) {
    let itemDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      //  //!
      itemDetails = response?.orderState?.selectedOrder;
      this.tblNotesReponse = [];
      itemDetails.items.forEach(element => {
        // !!
        if (element.itemId !== "00000000-0000-0000-0000-000000000000") {
          this.tblNotesReponse.push({
            itemId: element.itemId,
            saleType: element.saleType,
            quickAddQuantity: element.quickAddQuantity,
            itemIdOrBarCode: element.itemIdOrBarCode,
            itemCodeId: element.itemCodeId,
            name: element.name,
            description: element.description,
            location: element.location,
            note: element.note,
            insuranceName: element.insuranceName,
            qty: element.qty,
            bQty: element.billingQuantity,
            priceCode: this.drpPriceCode.filter(value => { return value.priceCodeId === element.priceCode })[0]?.priceCodeType,
            priceOption: element.priceOption,
            modifiers1: element.modifiers1,
            dosTo: element.dosTo,
            nextDOS: element?.nextDOS !== null && element?.nextDOS !== "" ? new Date(element?.nextDOS)?.toLocaleDateString("en-US") : "",
            // isSpanDateSplitBillingEnabled: number;
            // cmnId: string;
            // cmnStatus: string;
            // cmnExpDate?: string;
            // cmnForm: string;
            // itemClaimNoteType: string;
            // itemClaimNoteQuantity: string;
            // preBilling?: string;
            // nextBilling?: string;
            // nextPeriod: string;
            additionalModifiers1: element.additionalModifiers1,
            modifiers2: element.modifiers2,
            modifiers3: element.modifiers3,
            modifiers4: element.modifiers4,
            charge: element.charge,
            allow: element.allow,
            // parId: element.parId,
            // parNumber: element.parNumber,
            // parStatus: element.parStatus,
            // parExpDate: element.parExpDate,
            taxRate: element.taxRate,
            taxZone: element.taxZone,
            nonTaxReason: element.nonTaxReason,
            quantity: element.quantity,
            billingQuantity: element.billingQuantity,
            isPrimary: element.isPrimary,
            coverageType: element.coverageType,
            claimSubmission: element.claimSubmission,
            isSecondary: element.isSecondary,
            isTertiary: element.isTertiary,
            isPatient: element.isPatient,
            isDaysOfSupplyOverride: element.isDaysOfSupplyOverride,
            daysSupply: element.daysSupply,
            originalDos: element?.originalDos !== null && element?.originalDos !== "" ? new Date(element?.originalDos)?.toLocaleDateString("en-US") : "",
            additionalModifiers2: element.additionalModifiers2,
            additionalModifiers3: element.additionalModifiers3,
            additionalModifiers4: element.additionalModifiers4,
            espdtIndicator: element.espdtIndicator,
            isIncludeEclaimsAttachment: element.isIncludeEclaimsAttachment,
            attachmentNo: element.attachmentNo,
            typeCode: element.typeCode,
            transCode: element.transCode,
            isDiagnosisCodeICD9: element.isDiagnosisCodeICD9,
            isDiagnosisCodeICD10: element.isDiagnosisCodeICD10,
          });
        }
      })
      this.Table.setOrderTable(this.tblNotesReponse.reverse());
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
      // !
    });

  }
  // clear data for item
  clearDataForItem() {
    this.itemForm.reset({
      drpSaleType: "",
      txtQty: "",
      txtBarcode: ""

    })
  }
  // item dropdwon fo save
  public filteredItemTypes: Observable<itemInterface[]>;

  itemDropdown(): void {
    this.drpitem = [];

    // const itemList = (query: any) => this.itemService.getList(query);
    // this.list.hookToQuery(itemList).subscribe(response => {

    //   response?.items?.forEach(element => {
    //     //
    //     this.drpitem.push({
    //       equipmentBillingCode: element?.equipmentBillingCode,
    //       itemId: element.equipmentBillingCode + ' / ' + element.itemName,
    //       id: element.id,
    //       name: element.itemName,
    //       location: element.locationId,
    //       insurance: element.insuranceId,
    //       charge: element.price
    //     });
    //     this.filteredItemTypes = this.itemForm?.get("txtSearchFilter")?.valueChanges
    //       .pipe(
    //         startWith(''),
    //         map(value => this.drpitem?.filter(option => option?.itemId?.toLowerCase()?.includes(value?.toLowerCase())))
    //       );

    //   });
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // }, () => {
    // });
  }
  // get details for verification save
  saveVerificationForm() {

    if (this.savedVerificationData && this.savedVerificationData !== null) {
      this.verificationForm.patchValue({

        txtName: this.savedVerificationData.controls.txtName.value,
        txtDateOfBirth: this.savedVerificationData.controls.txtDateOfBirth.value,
        txtCommentsNotes: this.savedVerificationData.controls.txtCommentsNotes.value,

        txtPlan: this.savedVerificationData.controls.txtPlan.value,
        txtPlanName: this.savedVerificationData.controls.txtPlanName.value,
        txtPlanPhone: this.savedVerificationData.controls.txtPlanPhone.value,
        txtPolicy: this.savedVerificationData.controls.txtPolicy.value,
        txtGroup: this.savedVerificationData.controls.txtGroup.value,


        txtEffectiveDate: this.savedVerificationData.controls.txtEffectiveDate.value,
        txtType: this.savedVerificationData.controls.txtType.value,
        txtFascalYearStart: this.savedVerificationData.controls.txtFascalYearStart.value,
        txtEnd: this.savedVerificationData.controls.txtEnd.value,
        chkMedicare: this.savedVerificationData.controls.chkMedicare.value,

        chkPatient: this.savedVerificationData.controls.chkPatient.value,
        chkFamily: this.savedVerificationData.controls.chkFamily.value,
        txtCmnCode: this.savedVerificationData.controls.txtCmnCode.value,
        drpTypeOfPlan: this.savedVerificationData.controls.drpTypeOfPlan.value,
        chkIndividual: this.savedVerificationData.controls.chkIndividual.value,

        chkOutOfNetwork: this.savedVerificationData.controls.chkOutOfNetwork.value,
        chkFamilyDeductible: this.savedVerificationData.controls.chkFamilyDeductible.value,
        txtDeductibleIndividual: this.savedVerificationData.controls.txtDeductibleIndividual.value,
        txtDeductibleOutOfNetwork: this.savedVerificationData.controls.drpTypeOfPlan.value,
        txtDeductibleFamily: this.savedVerificationData.controls.txtDeductibleFamily.value,

        txtDeductibleMetIndividual: this.savedVerificationData.controls.txtDeductibleMetIndividual.value,
        txtDeductibleMetOutOfNetwork: this.savedVerificationData.controls.txtDeductibleMetOutOfNetwork.value,
        txtDeductibleMetFamily: this.savedVerificationData.controls.txtDeductibleMetFamily.value,
        drpPayableIndividual: this.savedVerificationData.controls.drpPayableIndividual.value,
        drpPayableOutOfNetwork: this.savedVerificationData.controls.drpPayableOutOfNetwork.value,
        drpPayableFamily: this.savedVerificationData.controls.drpPayableFamily.value,

        txtExpenseIndividual: this.savedVerificationData.controls.txtExpenseIndividual.value,
        txtExpenseOutOfNetwork: this.savedVerificationData.controls.txtExpenseOutOfNetwork.value,
        txtExpenseFamily: this.savedVerificationData.controls.txtExpenseFamily.value,
        txtExpenseMetIndividual: this.savedVerificationData.controls.txtExpenseMetIndividual.value,
        txtExpenseMetOutOfNetwork: this.savedVerificationData.controls.txtExpenseMetOutOfNetwork.value,

        txtExpenseMetFamily: this.savedVerificationData.controls.txtExpenseMetFamily.value,
        chkExpenseIndividual: this.savedVerificationData.controls.chkExpenseIndividual.value,
        chkExpenseOutOfNetwork: this.savedVerificationData.controls.chkExpenseOutOfNetwork.value,
        chkExpenseFamily: this.savedVerificationData.controls.chkExpenseFamily.value,
        chkPrecertRequired: this.savedVerificationData.controls.chkPrecertRequired.value,

        txtPhoneCall: this.savedVerificationData.controls.txtPhoneCall.value,
        chkReferralRequired: this.savedVerificationData.controls.chkReferralRequired.value,
        txtPcp: this.savedVerificationData.controls.txtPcp.value,
        chkPredetermination: this.savedVerificationData.controls.chkPredetermination.value,
        txtPreNotes: this.savedVerificationData.controls.txtPreNotes.value,
        txtLifetimeMax: this.savedVerificationData.controls.txtLifetimeMax.value,

        txtLifetimeMaxMet: this.savedVerificationData.controls.txtLifetimeMaxMet.value,
        chkDmePartsCovered: this.savedVerificationData.controls.chkDmePartsCovered.value,
        chkDmeReplacementUnit: this.savedVerificationData.controls.chkDmeReplacementUnit.value,
        txtDmeAnnualLimit: this.savedVerificationData.controls.txtDmeAnnualLimit.value,
        txtDmeLimitMet: this.savedVerificationData.controls.txtDmeLimitMet.value,

        txtRequestedBy: this.savedVerificationData.controls.txtRequestedBy.value,
        txtPrimaryPayor: this.savedVerificationData.controls.txtPrimaryPayor.value,
        txtContact: this.savedVerificationData.controls.txtContact.value,
        txtCallTrackingNumber: this.savedVerificationData.controls.txtCallTrackingNumber.value,
        txtPolicyHolderName: this.savedVerificationData.controls.txtPolicyHolderName.value,

        txtPolicyHolderDob: this.savedVerificationData.controls.txtPolicyHolderDob.value,
        chkNetwork: this.savedVerificationData.controls.chkNetwork.value,
        txtClaimAddress: this.savedVerificationData.controls.txtClaimAddress.value,
        txtPlanCob: this.savedVerificationData.controls.txtPlanCob.value,
        txtDedTotal: this.savedVerificationData.controls.txtDedTotal.value,


        txtDedMet: this.savedVerificationData.controls.txtDedMet.value,
        txtOopTotal: this.savedVerificationData.controls.txtOopTotal.value,
        txtOopMet: this.savedVerificationData.controls.txtOopMet.value,
        txtOopDeductible: this.savedVerificationData.controls.txtOopDeductible.value,
        txtDmeCoverage: this.savedVerificationData.controls.txtDmeCoverage.value,

        txtDmeMax: this.savedVerificationData.controls.txtDmeMax.value,
        txtHcpcs: this.savedVerificationData.controls.txtHcpcs.value,
        txtState: this.savedVerificationData.controls.txtState.value,
        txtCoverageCriteria: this.savedVerificationData.controls.txtCoverageCriteria.value,
        txtQtyLimits: this.savedVerificationData.controls.txtQtyLimits.value,
        txtFrequency: this.savedVerificationData.controls.txtFrequency.value,

        txtRent: this.savedVerificationData.controls.txtRent.value,
        txtPaRequired: this.savedVerificationData.controls.txtPaRequired.value,
        txtCodesRequirePa: this.savedVerificationData.controls.txtCodesRequirePa.value,
        txtSameOrSimilarInfo: this.savedVerificationData.controls.txtSameOrSimilarInfo.value,
        txtSpokeWith: this.savedVerificationData.controls.txtSpokeWith.value,

        drpVerifiedBy: this.savedVerificationData.controls.drpVerifiedBy.value,
        txtDateVerified: this.savedVerificationData.controls.txtDateVerified.value,
        drpPreparedBy: this.savedVerificationData.controls.drpPreparedBy.value,
        txtPreparedOn: this.savedVerificationData.controls.txtPreparedOn.value,
        rbtnVerificationStatus: this.savedVerificationData.controls.rbtnVerificationStatus.value,
        txtDeo: this.savedVerificationData.controls.txtDeo.value,
        txtCoInsurance: this.savedVerificationData.controls.txtCoInsurance.value,
        txtcommentsToTech: this.savedVerificationData.controls.txtcommentsToTech.value,
        txtVerfnAMethod: this.savedVerificationData.controls.txtVerfnAMethod.value



      });
    }

    this.showswal = false;
    this.getPatientDetailsForVerificationSave();
    this.savedVerificationData = [];
  }
  getPatientDetailsForVerificationSave() {
    let personalDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {

      const notesDetails = response?.orderState?.selectedOrder;
      personalDetails = response?.orderState?.selectedOrder;
      this.verifyTableData = [];
      // if (personalDetails.insuranceVerifications) {
      const savedData = {
        primaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.primaryInsuranceVerifies,
        secondaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.secondaryInsuranceVerifies,
        tertiaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.tertiaryInsuranceVerifies
      }
      return this.saveInsuranceVerify(personalDetails, savedData);

      // }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  saveInsuranceVerify(response, savedData) {

    const payorLevelName: string[] = this.payorLevel.map(v => v.payorLevelName);
    if (this.verificationForm.value.txtPlan === payorLevelName[0]) {

      savedData.primaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        groupName: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +this.verificationForm.value?.chkExpenseIndividual,
          outOfNetwork: +this.verificationForm.value?.chkExpenseOutOfNetwork,
          family: +this.verificationForm.value?.chkExpenseFamily,
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod,
      }

    } else if (this.verificationForm.value.txtPlan === payorLevelName[1]) {

      savedData.secondaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        group: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +this.verificationForm.value?.chkExpenseIndividual,
          outOfNetwork: +this.verificationForm.value?.chkExpenseOutOfNetwork,
          family: +this.verificationForm.value?.chkExpenseFamily,
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod
      }
    } else if (this.verificationForm.value.txtPlan === payorLevelName[2]) {

      savedData.tertiaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        group: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +this.verificationForm.value?.chkExpenseIndividual,
          outOfNetwork: +this.verificationForm.value?.chkExpenseOutOfNetwork,
          family: +this.verificationForm.value?.chkExpenseFamily,
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod
      }
    }


    let insVerification: SaleorderDTO;
    const verifyData = savedData

    insVerification = {
      defaultClaimId: response?.defaultClaimId,
      orders: response?.orders,
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      items: response?.items,
      patients: {
        personals: response?.patients.personals,
        addresses: response?.patients.addresses,
        contacts: response?.patients.contacts,
        clinicals: response?.patients.clinicals,
        insurances: response?.patients.insurances,
        notes: response?.patients.notes,
        policies: response?.patients.policies,
        defaultPatientId: response?.patients.defaultPatientId,
        insuranceVerifications: {
          primaryInsuranceVerifies: verifyData.primaryInsuranceVerifies,
          secondaryInsuranceVerifies: verifyData.secondaryInsuranceVerifies,
          tertiaryInsuranceVerifies: verifyData.tertiaryInsuranceVerifies
        },
        authorizations: response.patients.authorizations,
        patientDocuments: response.patients.patientDocuments,
        organizationUnitId: response.patients.organizationUnitId,
        isAutoIntake: response?.patients.isAutoIntake,
        autoIntakeDocumentDetails: response.patients.autoIntakeDocumentDetails,
        chartId: response.chartId,
        billingDetails: response.billingDetails,
        patientLock: response.patientLock,
        isSorted: response.isSorted,
        fileId: response?.patients?.fileId,
      },
      saleorderId: response?.saleorderId,
      orderStatus: response?.orderStatus,
      organizationUnitId: response?.organizationUnitId,
      currentStage: response?.currentStage,
      currentStageStatus: response?.currentStageStatus,
      claimId: response?.claimId,
      patientId: response?.patientId,
      isShippingApprove: response?.isShippingApprove,
      isCashPayment: response?.isCashPayment,
      orderProcessStatus: response?.orderProcessStatus,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      shippingCheckList: response?.shippingCheckList,
      orderBilling: response?.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType

    }
    this.patientId !== "" && this.store.dispatch(new updateOrder(this.orderId, insVerification)).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')

      this.clearVerificationData();
      this.getVerificationTableData(response?.orderState?.selectedOrder?.id)

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  saveInsuranceVerification(response: SaleorderDTO) {
    //
    // let personal: PatientDTO;
    // let verificationDetails: SaleorderDTO;
    // let currentVerification: InsuranceVerifyDTO[] = response?.insuranceVerifies;
    // if (this.insuranceVerificationId != "00000000-0000-0000-0000-000000000000") {
    //   currentVerification = currentVerification.filter(insuranceVerifies => {
    //     return insuranceVerifies && insuranceVerifies.insuranceVerificationId != this.insuranceVerificationId
    //   });
    //   currentVerification = [...currentVerification, {
    //     insuranceVerificationId: this.insuranceVerificationId,
    //     chart: this.verificationForm.value.drpChart,
    //     name: this.verificationForm.value.txtName,
    //     address: this.verificationForm.value.txtAddress,
    //     dateofBirth: this.verificationForm.value.txtDateOfBirth,
    //     commentsOrNotes: this.verificationForm.value.txtCommentsNotes,
    //     plan: this.verificationForm.value.txtPlan,
    //     planName: this.verificationForm.value.txtPlanName,
    //     planPhone: this.verificationForm.value.txtPlanPhone,
    //     policy: this.verificationForm.value.txtPolicy,
    //     group: this.verificationForm.value.txtGroup,
    //     effectivedate: this.verificationForm.value.txtEffectiveDate,
    //     typeOfPolicy: this.verificationForm.value.txtType,
    //     fascalYearStart: this.verificationForm.value.txtFascalYearStart,
    //     end: this.verificationForm.value.txtEnd,
    //     isMedicareCmn: +this.verificationForm.value.chkMedicare,
    //     isPatientHome: +this.verificationForm.value.chkPatient,
    //     isFamilyDeductible: +this.verificationForm.value.chkFamilyDeductible,
    //     cmnCode: this.verificationForm.value.txtCmnCode,
    //     typeOfPlan: this.verificationForm.value.drpTypeOfPlan,
    //     isIndividual: +this.verificationForm.value.chkIndividual,
    //     isOutOfNetwork: +this.verificationForm.value.chkOutOfNetwork,
    //     isFamily: +this.verificationForm.value.chkFamily,

    //     planDeductible: {
    //       individual: this.verificationForm.value.txtDeductibleIndividual,
    //       outOfNetwork: this.verificationForm.value.txtDeductibleOutOfNetwork,
    //       family: this.verificationForm.value.txtDeductibleFamily,
    //     },

    //     deductibleMet: {
    //       individual: this.verificationForm.value.txtDeductibleMetIndividual,
    //       outOfNetwork: this.verificationForm.value.txtDeductibleMetOutOfNetwork,
    //       family: this.verificationForm.value.txtDeductibleMetFamily,
    //     },
    //     payablePercent: {
    //       individual: this.verificationForm.value.drpPayableIndividual,
    //       outOfNetwork: this.verificationForm.value.drpPayableOutOfNetwork,
    //       family: this.verificationForm.value.drpPayableFamily,
    //     },
    //     outOfPocketExpense: {
    //       individual: this.verificationForm.value.txtExpenseIndividual,
    //       outOfNetwork: this.verificationForm.value.txtExpenseOutOfNetwork,
    //       family: this.verificationForm.value.txtExpenseFamily,
    //     },
    //     outOfPocketExpenseMet: {
    //       individual: this.verificationForm.value.txtExpenseMetIndividual,
    //       outOfNetwork: this.verificationForm.value.txtExpenseMetOutOfNetwork,
    //       family: this.verificationForm.value.txtExpenseMetFamily,
    //     },
    //     outOfPocketIncludeDeductible: {
    //       individual: +this.verificationForm.value.chkExpenseIndividual,
    //       outOfNetwork: +this.verificationForm.value.chkExpenseOutOfNetwork,
    //       family: +this.verificationForm.value.chkExpenseFamily,
    //     },
    //     isPercertRequired: +this.verificationForm.value.chkPrecertRequired,
    //     phone: this.verificationForm.value.txtPhoneCall,
    //     isReferralRequired: +this.verificationForm.value.chkReferralRequired,
    //     pcp: this.verificationForm.value.txtPcp,
    //     isPredetermination: +this.verificationForm.value.chkPredetermination,
    //     preNotes: this.verificationForm.value.txtPreNotes,
    //     lifeTimeMax: this.verificationForm.value.txtLifetimeMax,
    //     lifeTimeMaxMet: this.verificationForm.value.txtLifetimeMaxMet,
    //     isDmePartsCovered: +this.verificationForm.value.chkDmePartsCovered,
    //     isDmeReplaceMentUnit: +this.verificationForm.value.chkDmeReplacementUnit,
    //     dmeAnnualLimit: this.verificationForm.value.txtDmeAnnualLimit,
    //     dmeLimitMet: this.verificationForm.value.txtDmeLimitMet,
    //     requestedBy: this.verificationForm.value.txtRequestedBy,
    //     primaryPayor: this.verificationForm.value.txtPrimaryPayor,
    //     contact: this.verificationForm.value.txtContact,
    //     callTrackingNumber: this.verificationForm.value.txtCallTrackingNumber,
    //     policyHolderName: this.verificationForm.value.txtPolicyHolderName,
    //     policyHolderDob: this.verificationForm.value.txtPolicyHolderDob,
    //     isNetwork: +this.verificationForm.value.chkNetwork,
    //     claimAddress: this.verificationForm.value.txtClaimAddress,
    //     planCob: this.verificationForm.value.txtPlanCob,
    //     dedTotal: this.verificationForm.value.txtDedTotal,
    //     dedMet: this.verificationForm.value.txtDedMet,
    //     oopTotal: this.verificationForm.value.txtOopTotal,
    //     oopMet: this.verificationForm.value.txtOopMet,
    //     oop: this.verificationForm.value.txtOopDeductible,
    //     dmeCoverage: this.verificationForm.value.txtDmeCoverage,
    //     dmeMax: this.verificationForm.value.txtDmeMax,
    //     hcpcs: this.verificationForm.value.txtHcpcs,
    //     coverageCriteria: this.verificationForm.value.txtCoverageCriteria,
    //     qtyLimits: this.verificationForm.value.txtQtyLimits,
    //     frequency: this.verificationForm.value.txtFrequency,
    //     rent: this.verificationForm.value.txtRent,
    //     paRequired: this.verificationForm.value.txtPaRequired,
    //     codesRequirePa: this.verificationForm.value.txtCodesRequirePa,
    //     sameOrSimilarInfo: this.verificationForm.value.txtSameOrSimilarInfo,
    //     spokeWith: this.verificationForm.value.txtSpokeWith,
    //     verifiedBy: this.verificationForm.value.drpVerifiedBy,
    //     dateVerified: this.verificationForm.value.txtDateVerified,
    //     preparedBy: this.verificationForm.value.drpPreparedBy,
    //     preparedOn: this.verificationForm.value.txtPreparedOn,
    //     verificationStatus: this.verificationForm.value.rbtnVerificationStatus,
    //     approvalStatus: this.verificationForm.value.rbtnInsuranceVerify,
    //     approvedByWhom: "",
    //     approvedOn: ""
    //   }]
    // } else {
    //   currentVerification = [...currentVerification, {
    //     insuranceVerificationId: this.insuranceVerificationId,
    //     chart: this.verificationForm.value.drpChart,
    //     name: this.verificationForm.value.txtName,
    //     address: this.verificationForm.value.txtAddress,
    //     dateofBirth: this.verificationForm.value.txtDateOfBirth,
    //     commentsOrNotes: this.verificationForm.value.txtCommentsNotes,
    //     plan: this.verificationForm.value.txtPlan,
    //     planName: this.verificationForm.value.txtPlanName,
    //     planPhone: this.verificationForm.value.txtPlanPhone,
    //     policy: this.verificationForm.value.txtPolicy,
    //     group: this.verificationForm.value.txtGroup,
    //     effectivedate: this.verificationForm.value.txtEffectiveDate,
    //     typeOfPolicy: this.verificationForm.value.txtType,
    //     fascalYearStart: this.verificationForm.value.txtFascalYearStart,
    //     end: this.verificationForm.value.txtEnd,
    //     isMedicareCmn: +this.verificationForm.value.chkMedicare,
    //     isPatientHome: +this.verificationForm.value.chkPatient,
    //     isFamilyDeductible: +this.verificationForm.value.chkFamilyDeductible,
    //     cmnCode: this.verificationForm.value.txtCmnCode,
    //     typeOfPlan: this.verificationForm.value.drpTypeOfPlan,
    //     isIndividual: +this.verificationForm.value.chkIndividual,
    //     isOutOfNetwork: +this.verificationForm.value.chkOutOfNetwork,
    //     isFamily: +this.verificationForm.value.chkFamily,
    //     planDeductible: {
    //       individual: this.verificationForm.value.txtDeductibleIndividual,
    //       outOfNetwork: this.verificationForm.value.txtDeductibleOutOfNetwork,
    //       family: this.verificationForm.value.txtDeductibleFamily,
    //     },

    //     deductibleMet: {
    //       individual: this.verificationForm.value.txtDeductibleMetIndividual,
    //       outOfNetwork: this.verificationForm.value.txtDeductibleMetOutOfNetwork,
    //       family: this.verificationForm.value.txtDeductibleMetFamily,
    //     },
    //     payablePercent: {
    //       individual: this.verificationForm.value.drpPayableIndividual,
    //       outOfNetwork: this.verificationForm.value.drpPayableOutOfNetwork,
    //       family: this.verificationForm.value.drpPayableFamily,
    //     },
    //     outOfPocketExpense: {
    //       individual: this.verificationForm.value.txtExpenseIndividual,
    //       outOfNetwork: this.verificationForm.value.txtExpenseOutOfNetwork,
    //       family: this.verificationForm.value.txtExpenseFamily,
    //     },
    //     outOfPocketExpenseMet: {
    //       individual: this.verificationForm.value.txtExpenseMetIndividual,
    //       outOfNetwork: this.verificationForm.value.txtExpenseMetOutOfNetwork,
    //       family: this.verificationForm.value.txtExpenseMetFamily,
    //     },
    //     outOfPocketIncludeDeductible: {
    //       individual: +this.verificationForm.value.chkExpenseIndividual,
    //       outOfNetwork: +this.verificationForm.value.chkExpenseOutOfNetwork,
    //       family: +this.verificationForm.value.chkExpenseFamily,
    //     },
    //     isPercertRequired: +this.verificationForm.value.chkPrecertRequired,
    //     phone: this.verificationForm.value.txtPhoneCall,
    //     isReferralRequired: +this.verificationForm.value.chkReferralRequired,
    //     pcp: this.verificationForm.value.txtPcp,
    //     isPredetermination: +this.verificationForm.value.chkPredetermination,
    //     preNotes: this.verificationForm.value.txtPreNotes,
    //     lifeTimeMax: this.verificationForm.value.txtLifetimeMax,
    //     lifeTimeMaxMet: this.verificationForm.value.txtLifetimeMaxMet,
    //     isDmePartsCovered: +this.verificationForm.value.chkDmePartsCovered,
    //     isDmeReplaceMentUnit: +this.verificationForm.value.chkDmeReplacementUnit,
    //     dmeAnnualLimit: this.verificationForm.value.txtDmeAnnualLimit,
    //     dmeLimitMet: this.verificationForm.value.txtDmeLimitMet,
    //     requestedBy: this.verificationForm.value.txtRequestedBy,
    //     primaryPayor: this.verificationForm.value.txtPrimaryPayor,
    //     contact: this.verificationForm.value.txtContact,
    //     callTrackingNumber: this.verificationForm.value.txtCallTrackingNumber,
    //     policyHolderName: this.verificationForm.value.txtPolicyHolderName,
    //     policyHolderDob: this.verificationForm.value.txtPolicyHolderDob,
    //     isNetwork: +this.verificationForm.value.chkNetwork,
    //     claimAddress: this.verificationForm.value.txtClaimAddress,
    //     planCob: this.verificationForm.value.txtPlanCob,
    //     dedTotal: this.verificationForm.value.txtDedTotal,
    //     dedMet: this.verificationForm.value.txtDedMet,
    //     oopTotal: this.verificationForm.value.txtOopTotal,
    //     oopMet: this.verificationForm.value.txtOopMet,
    //     oop: this.verificationForm.value.txtOopDeductible,
    //     dmeCoverage: this.verificationForm.value.txtDmeCoverage,
    //     dmeMax: this.verificationForm.value.txtDmeMax,
    //     hcpcs: this.verificationForm.value.txtHcpcs,
    //     coverageCriteria: this.verificationForm.value.txtCoverageCriteria,
    //     qtyLimits: this.verificationForm.value.txtQtyLimits,
    //     frequency: this.verificationForm.value.txtFrequency,
    //     rent: this.verificationForm.value.txtRent,
    //     paRequired: this.verificationForm.value.txtPaRequired,
    //     codesRequirePa: this.verificationForm.value.txtCodesRequirePa,
    //     sameOrSimilarInfo: this.verificationForm.value.txtSameOrSimilarInfo,
    //     spokeWith: this.verificationForm.value.txtSpokeWith,
    //     verifiedBy: this.verificationForm.value.drpVerifiedBy,
    //     dateVerified: this.verificationForm.value.txtDateVerified,
    //     preparedBy: this.verificationForm.value.drpPreparedBy,
    //     preparedOn: this.verificationForm.value.txtPreparedOn,
    //     verificationStatus: this.verificationForm.value.rbtnVerificationStatus,
    //     approvalStatus: this.verificationForm.value.rbtnInsuranceVerify,
    //     approvedByWhom: "",
    //     approvedOn: ""
    //   }]
    // }
    // verificationDetails = {
    //   orders: response.orders,
    //   clinicals: response.clinicals,
    //   insurances: response.insurances,
    //   items: response.items,
    //   insuranceVerifies: currentVerification,
    //   authorizations: response.authorizations,
    //   patients: response.patients,
    //   isOrderCompleted: response.isOrderCompleted,
    //   saleorderId: response.saleorderId,
    //   currentStage: response.currentStage,
    //   currentStageStatus: response.currentStageStatus,
    //   orderWipStatus: response.orderWipStatus,
    //   patientLedgerStatus: response.patientLedgerStatus,
    //   shippingApprovalStatus: response.shippingApprovalStatus,
    //   claimHistoryStatus: response.claimHistoryStatus,
    //   organizationUnitId: response.organizationUnitId,
    //   claimId:response.claimId,
    //   defaultClaimId:response.defaultClaimId,
    // }
    // this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, verificationDetails)).subscribe(response => {
    //   Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
    //   this.clearDataForVerification();
    //   this.insuranceVerificationId = "";
    //   this.getTableDataForVerification(this.orderId);
    //   this.clearDataForVerification();
    //   //!
    // });
  }
  // verificaiton clear data
  clearVerificationData() {
    this.verificationForm.patchValue({
      //  drpChart: "",
      txtName: "",
      //  txtAddress: "",
      txtDateOfBirth: "",
      txtCommentsNotes: "",
      txtPlan: "",
      txtPlanName: "",
      txtPlanPhone: "",
      txtPolicy: "",
      txtGroup: "",
      txtEffectiveDate: "",
      txtType: "",
      txtFascalYearStart: "",
      txtEnd: "",
      chkMedicare: "",
      chkPatient: "",
      chkFamily: "",
      txtCmnCode: "",
      drpTypeOfPlan: "",
      chkIndividual: "",
      chkOutOfNetwork: "",
      chkFamilyDeductible: "",
      txtDeductibleIndividual: "",
      txtDeductibleOutOfNetwork: "",
      txtDeductibleFamily: "",
      txtDeductibleMetIndividual: "",
      txtDeductibleMetOutOfNetwork: "",
      txtDeductibleMetFamily: "",
      drpPayableIndividual: "",
      drpPayableOutOfNetwork: "",
      drpPayableFamily: "",
      txtExpenseIndividual: "",
      txtExpenseOutOfNetwork: "",
      txtExpenseFamily: "",
      txtExpenseMetIndividual: "",
      txtExpenseMetOutOfNetwork: "",
      txtExpenseMetFamily: "",
      chkExpenseIndividual: "",
      txtVerfnAMethod: "",
      chkExpenseOutOfNetwork: "",
      chkExpenseFamily: "",
      chkPrecertRequired: "",
      txtPhoneCall: "",
      chkReferralRequired: "",
      txtPcp: "",
      chkPredetermination: "",
      txtPreNotes: "",
      txtLifetimeMax: "",
      txtLifetimeMaxMet: "",
      chkDmePartsCovered: "",
      chkDmeReplacementUnit: "",
      txtDmeAnnualLimit: "",
      txtDmeLimitMet: "",
      txtRequestedBy: "",
      txtPrimaryPayor: "",
      txtContact: "",
      txtCallTrackingNumber: "",
      txtPolicyHolderName: "",
      txtPolicyHolderDob: "",
      chkNetwork: "",
      txtClaimAddress: "",
      txtPlanCob: "",
      txtDedTotal: "",
      txtDedMet: "",
      txtOopTotal: "",
      txtOopMet: "",
      txtOopDeductible: "",
      txtDmeCoverage: "",
      txtDmeMax: "",
      txtHcpcs: "",
      txtState: "",
      txtCoverageCriteria: "",
      txtQtyLimits: "",
      txtFrequency: "",
      txtRent: "",
      txtPaRequired: "",
      txtCodesRequirePa: "",
      txtSameOrSimilarInfo: "",
      txtSpokeWith: "",
      drpVerifiedBy: "",
      txtDateVerified: "",
      drpPreparedBy: "",
      txtPreparedOn: "",
      rbtnVerificationStatus: "",
      txtDeo: "",
      txtCoInsurance: "",
      txtcommentsToTech: ""
      // rbtnPending:new FormControl(""),
      // rbtnUnableToVerify:new FormControl(""),
    })

    this.verificationForm.markAsUntouched();
    this.verificationForm.updateValueAndValidity();
    this.insuranceVerificationId = "00000000-0000-0000-0000-000000000000";
    // this.verificationForm.markAsUntouched();
    // this.verificationForm.markAsPristine();

    // this.verificationForm?.get('drpChart').updateValueAndValidity();
    this.verificationForm?.get('txtDateOfBirth').updateValueAndValidity();

    this.verificationForm?.get('txtPlan').updateValueAndValidity();
    this.verificationForm?.get('txtPlanName').updateValueAndValidity();
    this.verificationForm?.get('txtPolicy').updateValueAndValidity();
    this.verificationForm?.get('txtEffectiveDate').updateValueAndValidity();
    this.verificationForm?.get('txtType').updateValueAndValidity();
    this.verificationForm?.get('drpTypeOfPlan').updateValueAndValidity();
    this.verificationForm?.get('rbtnVerificationStatus').updateValueAndValidity();
    this.verificationForm.updateValueAndValidity();

  }
  // verification get table data
  getVerificationTableData(orderId) {

    let verifyDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      verifyDetails = response?.orderState?.selectedOrder
      this.verifyTableData = [];
      const primaryIns = verifyDetails.patients.insuranceVerifications.primaryInsuranceVerifies
      const secondaryIns = verifyDetails.patients.insuranceVerifications.secondaryInsuranceVerifies
      const tertiaryIns = verifyDetails.patients.insuranceVerifications.tertiaryInsuranceVerifies

      if (primaryIns) {
        this.verifyTableData.push({
          patientDob: verifyDetails?.patients?.personals?.dateOfBirth,
          defaultPatientId: verifyDetails.patients.defaultPatientId,
          patientId: verifyDetails.id,
          address: primaryIns?.address,
          callTrackingNumber: primaryIns?.callTrackingNumber,
          chart: primaryIns?.chart,
          claimAddress: primaryIns?.claimAddress,
          cmnCode: primaryIns?.cmnCode,
          codesRequirePa: primaryIns?.codesRequirePa,
          commentsOrNotes: primaryIns?.commentsOrNotes,
          contact: primaryIns?.contact,
          coverageCriteria: primaryIns?.coverageCriteria,
          dateVerified: primaryIns?.dateVerified,
          dateofBirth: primaryIns?.dateofBirth,
          dedMet: primaryIns?.dedMet,
          dedTotal: primaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: primaryIns?.dmeAnnualLimit,
          dmeCoverage: primaryIns?.dmeCoverage,
          dmeLimitMet: primaryIns?.dmeLimitMet,
          dmeMax: primaryIns?.dmeMax,
          effectivedate: primaryIns?.effectiveDate,
          end: primaryIns?.end,
          fascalYearStart: primaryIns?.fascalYearStart,
          frequency: primaryIns?.frequency,
          group: primaryIns?.groupName,
          hcpcs: primaryIns?.hcpcs,
          insuranceVerificationId: primaryIns?.insuranceVerificationId,
          isDmePartsCovered: primaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: primaryIns?.isDmeReplaceMentUnit,
          isFamily: primaryIns?.isFamily,
          isFamilyDeductible: primaryIns?.isFamilyDeductible,
          isIndividual: primaryIns?.isIndividual,
          isMedicareCmn: primaryIns?.isMedicareCmn,
          isNetwork: primaryIns?.isNetwork,
          isOutOfNetwork: primaryIns?.isOutOfNetwork,
          isPatientHome: primaryIns?.isPatientHome,
          isPercertRequired: primaryIns?.isPercertRequired,
          isReferralRequired: primaryIns?.isReferralRequired,
          lifeTimeMax: primaryIns?.lifeTimeMax,
          lifeTimeMaxMet: primaryIns?.lifeTimeMaxMet,
          name: primaryIns?.name,
          oop: primaryIns?.oop,
          oopMet: primaryIns?.oopMet,
          oopTotal: primaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: primaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpense?.outOfNetwork,
            family: primaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: primaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: primaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: primaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: primaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: primaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: primaryIns?.paRequired,
          payablePercent: {
            individual: primaryIns?.payablePercent?.individual,
            outOfNetwork: primaryIns?.payablePercent?.outOfNetwork,
            family: primaryIns?.payablePercent?.family
          },
          pcp: primaryIns?.pcp,
          phone: primaryIns?.phone,
          plan: primaryIns?.plan,
          planCob: primaryIns?.planCob,
          planDeductible: {
            individual: primaryIns?.planDeductible?.individual,
            outOfNetwork: primaryIns?.planDeductible?.outOfNetwork,
            family: primaryIns?.planDeductible?.family
          },
          planName: primaryIns?.planName,
          planPhone: primaryIns?.planPhone,
          policy: primaryIns?.policy,
          policyHolderDob: primaryIns?.policyHolderDob,
          policyHolderName: primaryIns?.policyHolderName,
          preNotes: primaryIns?.preNotes,
          preparedBy: primaryIns?.preparedBy,
          preparedOn: primaryIns?.preparedOn,
          primaryPayor: primaryIns?.primaryPayor,
          qtyLimits: primaryIns?.qtyLimits,
          rent: primaryIns?.rent,
          requestedBy: primaryIns?.requestedBy,
          sameOrSimilarInfo: primaryIns?.sameOrSimilarInfo,
          spokeWith: primaryIns?.spokeWith,
          typeOfPlan: primaryIns?.typeOfPlan,
          typeOfPolicy: primaryIns?.typeOfPolicy,
          verificationStatus: primaryIns?.verificationStatus,
          verifiedBy: primaryIns?.verifiedBy,
          deo: primaryIns?.deo,
          coInsurance: primaryIns?.coInsurance,

          commentsToTech: primaryIns?.commentsToTech,
          verficationMethod: primaryIns?.verifiedMethod,
          isPredetermination: primaryIns?.isPredetermination

        })
      }
      if (secondaryIns) {
        this.verifyTableData.push({
          patientDob: verifyDetails?.patients?.personals?.dateOfBirth,
          defaultPatientId: verifyDetails.patients.defaultPatientId,
          patientId: verifyDetails.id,
          address: secondaryIns?.address,
          callTrackingNumber: secondaryIns?.callTrackingNumber,
          chart: secondaryIns?.chart,
          claimAddress: secondaryIns?.claimAddress,
          cmnCode: secondaryIns?.cmnCode,
          codesRequirePa: secondaryIns?.codesRequirePa,
          commentsOrNotes: secondaryIns?.commentsOrNotes,
          contact: secondaryIns?.contact,
          coverageCriteria: secondaryIns?.coverageCriteria,
          dateVerified: secondaryIns?.dateVerified,
          dateofBirth: secondaryIns?.dateofBirth,
          dedMet: secondaryIns?.dedMet,
          dedTotal: secondaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: secondaryIns?.dmeAnnualLimit,
          dmeCoverage: secondaryIns?.dmeCoverage,
          dmeLimitMet: secondaryIns?.dmeLimitMet,
          dmeMax: secondaryIns?.dmeMax,
          effectivedate: secondaryIns?.effectiveDate,
          end: secondaryIns?.end,
          fascalYearStart: secondaryIns?.fascalYearStart,
          frequency: secondaryIns?.frequency,
          group: secondaryIns?.groupName,
          hcpcs: secondaryIns?.hcpcs,
          insuranceVerificationId: secondaryIns?.insuranceVerificationId,
          isDmePartsCovered: secondaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: secondaryIns?.isDmeReplaceMentUnit,
          isFamily: secondaryIns?.isFamily,
          isFamilyDeductible: secondaryIns?.isFamilyDeductible,
          isIndividual: secondaryIns?.isIndividual,
          isMedicareCmn: secondaryIns?.isMedicareCmn,
          isNetwork: secondaryIns?.isNetwork,
          isOutOfNetwork: secondaryIns?.isOutOfNetwork,
          isPatientHome: secondaryIns?.isPatientHome,
          isPercertRequired: secondaryIns?.isPercertRequired,
          isReferralRequired: secondaryIns?.isReferralRequired,
          lifeTimeMax: secondaryIns?.lifeTimeMax,
          lifeTimeMaxMet: secondaryIns?.lifeTimeMaxMet,
          name: secondaryIns?.name,
          oop: secondaryIns?.oop,
          oopMet: secondaryIns?.oopMet,
          oopTotal: secondaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: secondaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpense?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: secondaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: secondaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: secondaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: secondaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: secondaryIns?.paRequired,
          payablePercent: {
            individual: secondaryIns?.payablePercent?.individual,
            outOfNetwork: secondaryIns?.payablePercent?.outOfNetwork,
            family: secondaryIns?.payablePercent?.family
          },
          pcp: secondaryIns?.pcp,
          phone: secondaryIns?.phone,
          plan: secondaryIns?.plan,
          planCob: secondaryIns?.planCob,
          planDeductible: {
            individual: secondaryIns?.planDeductible?.individual,
            outOfNetwork: secondaryIns?.planDeductible?.outOfNetwork,
            family: secondaryIns?.planDeductible?.family
          },
          planName: secondaryIns?.planName,
          planPhone: secondaryIns?.planPhone,
          policy: secondaryIns?.policy,
          policyHolderDob: secondaryIns?.policyHolderDob,
          policyHolderName: secondaryIns?.policyHolderName,
          preNotes: secondaryIns?.preNotes,
          preparedBy: secondaryIns?.preparedBy,
          preparedOn: secondaryIns?.preparedOn,
          primaryPayor: secondaryIns?.primaryPayor,
          qtyLimits: secondaryIns?.qtyLimits,
          rent: secondaryIns?.rent,
          requestedBy: secondaryIns?.requestedBy,
          sameOrSimilarInfo: secondaryIns?.sameOrSimilarInfo,
          spokeWith: secondaryIns?.spokeWith,
          typeOfPlan: secondaryIns?.typeOfPlan,
          typeOfPolicy: secondaryIns?.typeOfPolicy,
          verificationStatus: secondaryIns?.verificationStatus,
          verifiedBy: secondaryIns?.verifiedBy,
          deo: secondaryIns?.deo,
          coInsurance: secondaryIns?.coInsurance,

          commentsToTech: secondaryIns?.commentsToTech,
          verficationMethod: secondaryIns?.verifiedMethod,
          isPredetermination: secondaryIns?.isPredetermination

        })
      }
      if (tertiaryIns) {
        this.verifyTableData.push({
          patientDob: verifyDetails?.patients?.personals?.dateOfBirth,
          defaultPatientId: verifyDetails.patients.defaultPatientId,
          patientId: verifyDetails.id,
          address: tertiaryIns?.address,
          callTrackingNumber: tertiaryIns?.callTrackingNumber,
          chart: tertiaryIns?.chart,
          claimAddress: tertiaryIns?.claimAddress,
          cmnCode: tertiaryIns?.cmnCode,
          codesRequirePa: tertiaryIns?.codesRequirePa,
          commentsOrNotes: tertiaryIns?.commentsOrNotes,
          contact: tertiaryIns?.contact,
          coverageCriteria: tertiaryIns?.coverageCriteria,
          dateVerified: tertiaryIns?.dateVerified,
          dateofBirth: tertiaryIns?.dateofBirth,
          dedMet: tertiaryIns?.dedMet,
          dedTotal: tertiaryIns?.dedTotal,
          deductibleMet: {
            individual: tertiaryIns?.deductibleMet?.individual,
            outOfNetwork: tertiaryIns?.deductibleMet?.outOfNetwork,
            family: tertiaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: tertiaryIns?.dmeAnnualLimit,
          dmeCoverage: tertiaryIns?.dmeCoverage,
          dmeLimitMet: tertiaryIns?.dmeLimitMet,
          dmeMax: tertiaryIns?.dmeMax,
          effectivedate: tertiaryIns?.effectiveDate,
          end: tertiaryIns?.end,
          fascalYearStart: tertiaryIns?.fascalYearStart,
          frequency: tertiaryIns?.frequency,
          group: tertiaryIns?.groupName,
          hcpcs: tertiaryIns?.hcpcs,
          insuranceVerificationId: tertiaryIns?.insuranceVerificationId,
          isDmePartsCovered: tertiaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: tertiaryIns?.isDmeReplaceMentUnit,
          isFamily: tertiaryIns?.isFamily,
          isFamilyDeductible: tertiaryIns?.isFamilyDeductible,
          isIndividual: tertiaryIns?.isIndividual,
          isMedicareCmn: tertiaryIns?.isMedicareCmn,
          isNetwork: tertiaryIns?.isNetwork,
          isOutOfNetwork: tertiaryIns?.isOutOfNetwork,
          isPatientHome: tertiaryIns?.isPatientHome,
          isPercertRequired: tertiaryIns?.isPercertRequired,
          isReferralRequired: tertiaryIns?.isReferralRequired,
          lifeTimeMax: tertiaryIns?.lifeTimeMax,
          lifeTimeMaxMet: tertiaryIns?.lifeTimeMaxMet,
          name: tertiaryIns?.name,
          oop: tertiaryIns?.oop,
          oopMet: tertiaryIns?.oopMet,
          oopTotal: tertiaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: tertiaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpense?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: tertiaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: tertiaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: tertiaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: tertiaryIns?.paRequired,
          payablePercent: {
            individual: tertiaryIns?.payablePercent?.individual,
            outOfNetwork: tertiaryIns?.payablePercent?.outOfNetwork,
            family: tertiaryIns?.payablePercent?.family
          },
          pcp: tertiaryIns?.pcp,
          phone: tertiaryIns?.phone,
          plan: tertiaryIns?.plan,
          planCob: tertiaryIns?.planCob,
          planDeductible: {
            individual: tertiaryIns?.planDeductible?.individual,
            outOfNetwork: tertiaryIns?.planDeductible?.outOfNetwork,
            family: tertiaryIns?.planDeductible?.family
          },
          planName: tertiaryIns?.planName,
          planPhone: tertiaryIns?.planPhone,
          policy: tertiaryIns?.policy,
          policyHolderDob: tertiaryIns?.policyHolderDob,
          policyHolderName: tertiaryIns?.policyHolderName,
          preNotes: tertiaryIns?.preNotes,
          preparedBy: tertiaryIns?.preparedBy,
          preparedOn: tertiaryIns?.preparedOn,
          primaryPayor: tertiaryIns?.primaryPayor,
          qtyLimits: tertiaryIns?.qtyLimits,
          rent: tertiaryIns?.rent,
          requestedBy: tertiaryIns?.requestedBy,
          sameOrSimilarInfo: tertiaryIns?.sameOrSimilarInfo,
          spokeWith: tertiaryIns?.spokeWith,
          typeOfPlan: tertiaryIns?.typeOfPlan,
          typeOfPolicy: tertiaryIns?.typeOfPolicy,
          verificationStatus: tertiaryIns?.verificationStatus,
          verifiedBy: tertiaryIns?.verifiedBy,
          deo: tertiaryIns?.deo,
          coInsurance: tertiaryIns?.coInsurance,

          commentsToTech: tertiaryIns?.commentsToTech,
          verficationMethod: tertiaryIns?.verifiedMethod,
          isPredetermination: tertiaryIns?.isPredetermination

        })
      }
      this.Table.setVerifyTable(this.verifyTableData);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }
  // vieworder Verification
  ViewOrders(record) {
    ;
    //
    this.getOrderByid(record)
  }
  getOrderByid(response) {
    this.insuranceVerificationId = response?.insuranceVerificationId;
    this.patientids = response.defaultPatientId;
    this.verificationForm.patchValue({
      insuranceVerificationId: response?.insuranceVerificationId,
      //  txtChart: response?.chart,
      txtName: response?.chart,
      // txtAddress: response?.address,
      txtDateOfBirth: response?.patientDob,
      txtCommentsNotes: response?.commentsOrNotes,
      txtPlan: response?.plan,
      txtPlanName: response?.planName,
      txtPlanPhone: response?.planPhone,
      txtPolicy: response?.policy,
      txtGroup: response?.group,
      txtEffectiveDate: response?.effectivedate,
      txtType: response?.typeOfPolicy,
      txtFascalYearStart: response?.fascalYearStart,
      txtEnd: response?.end,
      chkMedicare: response?.isMedicareCmn,
      chkPatient: response?.isPatientHome,
      chkFamilyDeductible: response?.isFamilyDeductible,
      txtCmnCode: response?.cmnCode,
      drpTypeOfPlan: response?.typeOfPlan,
      chkIndividual: response?.isIndividual,
      chkOutOfNetwork: response?.isOutOfNetwork,
      chkFamily: response?.isFamily,
      txtDeductibleIndividual: response?.planDeductible?.individual,
      txtDeductibleOutOfNetwork: response?.planDeductible?.outOfNetwork,
      txtDeductibleFamily: response?.planDeductible?.family,
      txtDeductibleMetIndividual: response?.deductibleMet?.individual,
      txtDeductibleMetOutOfNetwork: response?.deductibleMet?.outOfNetwork,
      txtDeductibleMetFamily: response?.deductibleMet?.family,
      drpPayableIndividual: response?.payablePercent?.individual,
      drpPayableOutOfNetwork: response?.payablePercent?.outOfNetwork,
      drpPayableFamily: response?.payablePercent?.family,
      txtExpenseIndividual: response?.outOfPocketExpense?.individual,
      txtExpenseOutOfNetwork: response?.outOfPocketExpense?.outOfNetwork,
      txtExpenseFamily: response?.outOfPocketExpense?.family,
      txtExpenseMetIndividual: response?.outOfPocketExpenseMet?.individual,
      txtExpenseMetOutOfNetwork: response?.outOfPocketExpenseMet?.outOfNetwork,
      txtExpenseMetFamily: response?.outOfPocketExpenseMet?.family,
      chkExpenseIndividual: response?.outOfPocketIncludeDeductible?.individual,
      chkExpenseOutOfNetwork: response?.outOfPocketIncludeDeductible?.outOfNetwork,
      chkExpenseFamily: response?.outOfPocketIncludeDeductible?.family,
      chkPrecertRequired: response?.isPercertRequired,
      txtPhoneCall: response?.phone,
      chkReferralRequired: response?.isReferralRequired,
      txtPcp: response?.pcp,
      chkPredetermination: response?.isPredetermination,
      txtPreNotes: response?.preNotes,
      txtLifetimeMax: response?.lifeTimeMax,
      txtLifetimeMaxMet: response?.lifeTimeMaxMet,
      chkDmePartsCovered: response?.isDmePartsCovered,
      chkDmeReplacementUnit: response?.isDmeReplaceMentUnit,
      txtDmeAnnualLimit: response?.dmeAnnualLimit,
      txtDmeLimitMet: response?.dmeLimitMet,
      txtRequestedBy: response?.requestedBy,
      txtPrimaryPayor: response?.primaryPayor,
      txtContact: response?.contact,
      txtCallTrackingNumber: response?.callTrackingNumber,
      txtPolicyHolderName: response?.policyHolderName,
      txtPolicyHolderDob: response?.policyHolderDob,
      chkNetwork: response?.isNetwork,
      txtClaimAddress: response?.claimAddress,
      txtPlanCob: response?.planCob,
      txtDedTotal: response?.dedTotal,
      txtDedMet: response?.dedMet,
      txtOopTotal: response?.oopTotal,
      txtOopMet: response?.oopMet,
      txtOopDeductible: response?.oop,
      txtDmeCoverage: response?.dmeCoverage,
      txtDmeMax: response?.dmeMax,
      txtHcpcs: response?.hcpcs,
      txtCoverageCriteria: response?.coverageCriteria,
      txtQtyLimits: response?.qtyLimits,
      txtFrequency: response?.frequency,
      txtRent: response?.rent,
      txtPaRequired: response?.paRequired,
      txtCodesRequirePa: response?.codesRequirePa,
      txtSameOrSimilarInfo: response?.sameOrSimilarInfo,
      txtSpokeWith: response?.spokeWith,
      drpVerifiedBy: response?.verifiedBy,
      txtDateVerified: response?.dateVerified,
      drpPreparedBy: response?.preparedBy,
      txtPreparedOn: response?.preparedOn,
      rbtnVerificationStatus: response?.verificationStatus,
      txtDeo: response?.deo,
      txtCoInsurance: response?.coinsurance,

      txtcommentsToTech: response?.commentsToTech,
      txtVerfnAMethod: response?.verficationMethod
    })

  }

  // Authorization tab save function
  saveAuthorizationForm() {
    if (this.savedAuthorizationData && this.savedAuthorizationData !== null) {
      this.authorizationForm.patchValue({
        txtAuthId: this.savedAuthorizationData.controls.txtAuthId.value,
        drpAuthVisit: this.savedAuthorizationData.controls.drpAuthVisit.value,
        txtAuthAmount: this.savedAuthorizationData.controls.txtAuthAmount.value,
        txtStartDate: this.savedAuthorizationData.controls.txtStartDate.value,
        txtEndDate: this.savedAuthorizationData.controls.txtEndDate.value,
        drpCategory: this.savedAuthorizationData.controls.drpCategory.value,
        drpItem: this.savedAuthorizationData.controls.drpItem.value,
        txtNote: this.savedAuthorizationData.controls.txtNote.value,
        chkCompleted: this.savedAuthorizationData.controls.chkCompleted.value,
        txtCompletedBy: this.savedAuthorizationData.controls.txtCompletedBy.value,
        drpPlan: this.savedAuthorizationData.controls.drpPlan.value,
        txtPlan: this.savedAuthorizationData.controls.txtPlan.value,
        // txtPlanName: this.savedAuthorizationData['controls'].txtPlanName.value,
        // rbtnAuth: this.savedAuthorizationData['controls']. rbtnAuth.value,
        rbtnPolicyType: this.savedAuthorizationData.controls.rbtnPolicyType
      });
    }

    this.showswal = false;
    this.getPatientDetailsForAuthorizationSave();
    this.savedAuthorizationData = [];
  }
  // get details for auhorization save
  getPatientDetailsForAuthorizationSave() {
    let personalDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      personalDetails = response?.orderState.selectedOrder;
      const savedData = {
        primaryAuthorizations: personalDetails.patients.authorizations.primaryAuthorizations,
        secondaryAuthorizations: personalDetails.patients.authorizations.secondaryAuthorizations,
        tertiaryAuthorizations: personalDetails.patients.authorizations.tertiaryAuthorizations
      }
      return this.saveAuthorization(personalDetails, savedData);

    }, err => {
      const data: HttpErrorResponse = err;


      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  // save authorization form
  saveAuthorization(response, savedData) {
    const typeOfPlanName: string[] = this.drpPlan.map(v => v.typeOfPlanName);

    if (this.authorizationForm.value.txtPlan === typeOfPlanName[0]) {
      savedData.primaryAuthorizations = {
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: ""

      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[1]) {

      savedData.secondaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",

      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[2]) {

      savedData.tertiaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",

      }
    }
    let insAuthorization: SaleorderDTO;

    insAuthorization = {
      defaultClaimId: response?.defaultClaimId,
      orders: response?.orders,
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      items: response.items,
      patients: {
        personals: response.patients.personals,
        addresses: response.patients.addresses,
        contacts: response.patients.contacts,
        clinicals: response.patients.clinicals,
        insurances: response.patients.insurances,
        notes: response.patients.notes,
        policies: response.patients.policies,
        defaultPatientId: response.patients.defaultPatientId,
        insuranceVerifications: response.patients.insuranceVerifications,
        authorizations: {
          primaryAuthorizations: savedData.primaryAuthorizations,
          secondaryAuthorizations: savedData.secondaryAuthorizations,
          tertiaryAuthorizations: savedData.tertiaryAuthorizations
        },
        patientDocuments: response.patients.patientDocuments,
        organizationUnitId: response.patients.organizationUnitId,
        isAutoIntake: response.patients.isAutoIntake,
        autoIntakeDocumentDetails: response.patients.autoIntakeDocumentDetails,
        chartId: response.chartId,
        billingDetails: response.billingDetails,
        patientLock: response.patientLock,
        isSorted: response.isSorted,
        fileId: response?.patients?.fileId,
      },
      saleorderId: response?.saleorderId,
      orderStatus: response.orderStatus,
      organizationUnitId: response?.organizationUnitId,
      currentStage: response?.currentStage,
      currentStageStatus: response?.currentStageStatus,
      claimId: response.claimId,
      patientId: response.patientId,
      isShippingApprove: response?.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      shippingCheckList: response?.shippingCheckList,
      orderBilling: response?.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType

    }
    this.orderId !== "" && this.store.dispatch(new updateOrder(this.orderId, insAuthorization)).subscribe(response => {

      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      this.clearAuthorizationData();
      this.getTableData(response?.orderState?.selectedOrder?.id);

    }, err => {
      const data: HttpErrorResponse = err;


      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // clear Authorization Data
  clearAuthorizationData() {
    // this.authorizationForm.patchValue({
    //   txtAuthId: "",
    //   drpAuthVisit: "",
    //   txtAuthNo: "",
    //   txtAuthAmount: "",
    //   txtStartDate: "",
    //   txtEndDate: "",
    //   drpCategory: "",
    //   drpItem: "",
    //   txtNote: "",
    //   chkCompleted: "",
    //   txtCompletedBy: "",
    //   drpPlan: "",
    //   txtPlan: "",
    //   rbtnAuth: "",
    // })
    this.authorizationForm.reset();
    this.authorizationForm.markAsUntouched();
    this.authorizationForm.updateValueAndValidity();
  }
  // get table data for Authorization
  getTableData(orderId) {
    let saleOrderDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      const saleOrderDetails = responses?.orderState.selectedOrder;
      const authData: AuthorizationDTO[] = [];
      // ! To get verification details based on primary, secondary & tertiary policies
      // const primaryAuth = itemDetails.patients.authorizations.primaryAuthorizations
      const authDatas = saleOrderDetails.patients.authorizations.primaryAuthorizations;
      authDatas?.plan === "Primary" && authData.push(authDatas);
      return authData;
    })).subscribe(primaryAuth => {
      this.authorizationForm.patchValue({
        txtAuthId: primaryAuth[0]?.authId,
        drpAuthVisit: primaryAuth[0]?.authVisits,
        txtAuthNo: primaryAuth[0]?.authNo,
        txtAuthAmount: primaryAuth[0]?.authAmount,
        txtStartDate: primaryAuth[0]?.startDate,
        txtEndDate: primaryAuth[0]?.endDate,
        drpCategory: primaryAuth[0]?.category,
        drpItem: primaryAuth[0]?.item,
        txtNote: primaryAuth[0]?.note,
        chkCompleted: primaryAuth[0]?.isCompleted == "Done" ? 1 : 0,
        txtCompletedBy: primaryAuth[0]?.completedBy,
        txtPlan: primaryAuth[0]?.plan,
        rbtnAuth: primaryAuth[0]?.approvalStatus,
      });


    })
    let itemDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      itemDetails = response?.orderState.selectedOrder;
      this.tblNotesReponse = [];
      const primaryAuth = itemDetails.patients.authorizations.primaryAuthorizations
      const secondaryAuth = itemDetails.patients.authorizations.secondaryAuthorizations
      const tertiaryAuth = itemDetails.patients.authorizations.tertiaryAuthorizations
      if (primaryAuth != null) {
        this.tblNotesReponse.push({
          authId: primaryAuth.authId,
          authVisits: primaryAuth?.authVisits,
          authNo: primaryAuth.authNo,
          authAmount: primaryAuth.authAmount,
          item: primaryAuth.item,
          note: primaryAuth.note,
          typeOfPolicy: primaryAuth.typeOfPolicy,
          startDate: primaryAuth.startDate == null ? "" : new Date(primaryAuth.startDate)?.toLocaleDateString("en-US"),
          endDate: primaryAuth.endDate == null ? "" : new Date(primaryAuth.endDate)?.toLocaleDateString("en-US"),
          category: primaryAuth.category,
          completed: primaryAuth.isCompleted,
          completedBy: primaryAuth.completedBy,
          plan: primaryAuth.plan,
          orderId: itemDetails.id,
          patientId: itemDetails?.patientId,
          authorizationId: primaryAuth?.authorizationId
        });
      }
      if (secondaryAuth != null) {
        this.tblNotesReponse.push({
          authId: secondaryAuth.authId,
          authVisits: secondaryAuth?.authVisits,
          authNo: secondaryAuth.authNo,
          authAmount: secondaryAuth.authAmount,
          item: secondaryAuth.item,
          note: secondaryAuth.note,
          typeOfPolicy: secondaryAuth.typeOfPolicy,
          startDate: secondaryAuth.startDate == null ? "" : new Date(secondaryAuth.endDate)?.toLocaleDateString("en-US"),
          endDate: secondaryAuth.endDate == null ? "" : new Date(secondaryAuth.endDate)?.toLocaleDateString("en-US"),
          category: secondaryAuth.category,
          completed: secondaryAuth.isCompleted,
          completedBy: secondaryAuth.completedBy,
          plan: secondaryAuth.plan,
          orderId: itemDetails.id,
          patientId: itemDetails?.patientId,
          authorizationId: secondaryAuth?.authorizationId
        });
      }
      if (tertiaryAuth != null) {
        this.tblNotesReponse.push({
          authId: tertiaryAuth.authId,
          authVisits: tertiaryAuth?.startDate,
          authNo: tertiaryAuth.authNo,
          authAmount: tertiaryAuth.authAmount,
          item: tertiaryAuth.item,
          note: tertiaryAuth.note,
          typeOfPolicy: tertiaryAuth.typeOfPolicy,
          startDate: tertiaryAuth.startDate == null ? "" : new Date(tertiaryAuth.endDate)?.toLocaleDateString("en-US"),
          endDate: tertiaryAuth.endDate == null ? "" : new Date(tertiaryAuth.endDate)?.toLocaleDateString("en-US"),
          category: tertiaryAuth.category,
          completed: tertiaryAuth.isCompleted,
          completedBy: tertiaryAuth.completedBy,
          plan: tertiaryAuth.plan,
          orderId: itemDetails.id,
          patientId: itemDetails?.patientId,
          authorizationId: tertiaryAuth?.authorizationId
        });
      }

      this.Table.setAuthTable(this.tblNotesReponse);
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
  }
  getByData(response) {

    this.authorizationForm.patchValue({
      txtAuthId: response.authId,
      drpAuthVisit: response.authVisits,
      txtAuthNo: response.authNo,
      txtAuthAmount: response.authAmount,
      txtStartDate: response.startDate,
      txtEndDate: response.endDate,
      drpCategory: response.category,
      drpItem: response.item,
      txtNote: response.note,
      chkCompleted: response.completed,
      txtCompletedBy: response.completedBy,
      txtPlan: response.plan
    })
  }
  getDropdown() {
    this.store.dispatch(new getOrderDropdown()).subscribe((stateResponse) => {

      const response: OrderMasterDropdownDTO = stateResponse?.dropdownStates?.selectedDropdown
      this.drpAuthVisit = response?.authVisits;

      this.drpPlan = response?.typeOfPlans;
      this.drpPlanType = response?.planTypes;


      // this.drpAuthItem = response.authItems;
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    this.orderId = this.orderId ?? "";
    this.orderId !== "" && this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      const selectedOrder: SaleorderDTO = response?.orderState?.selectedOrder;
      //  this.orderStatus = (selectedOrder?.orderBilling?.status === billingStatus.ReadyToBilling || selectedOrder?.orderBilling?.status === billingStatus.OrderPending) ? true : false;
      const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
      this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (selectedOrder?.orderBilling?.status)))
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

}
export interface itemInterface {
  id: string;
  itemId: string;
  name: string;
  location: string;
  insurance: string;
  charge: number;
  equipmentBillingCode: string;
}
