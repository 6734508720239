<table [attr.class]="
    'table table-responsive db-table-view' + configs.css.table_class
  ">
  <thead>
    <tr>
      <!-- <th class="header-corner"> -->
      <table class="header-corner">
        <thead>
          <tr>
            <td class=""></td>
            <td class="corner-top">{{ configs.columns }}</td>
          </tr>
          <tr>
            <td class="corner-bottom">{{ configs.rows }}</td>
            <td class=""></td>
          </tr>
        </thead>
      </table>
      <!-- </th> -->
      <th *ngFor="let header of processed_headers">
        {{ header }}
      </th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of processed_data">
      <td *ngFor="let col of row">
        {{ col }}
      </td>
    </tr>
  </tbody>
  <tfoot></tfoot>
</table>
