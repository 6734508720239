import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailOTPControllerService {
  apiName = 'platformManagement';

  twoAuthOTPMailSend = (emailId:string,bRegOrCreate:boolean) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/PlatformApp/EmailOTPController/sendEmailVerification`,
      params:{
        sEmail:emailId,
        bRegOrCreate:bRegOrCreate
      },
    },
    { apiName: this.apiName });

    twoAuthOTPVerify = (otp:string) =>
      this.restService.request<any, any>({
        method: 'POST',
        url: `/api/PlatformApp/EmailOTPController/validateEmailVerification`,
        params:{
          sOTP:otp,
        },
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
