import type { CreateUpdatePatientCheckListDTO, CreateUpdatePatientDocumentCheckListDTO } from './dto/models';
import type { PatientDocumentCheckListDTO } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientDocumentCheckListService {
  apiName = 'platformManagement';

  create = (input: CreateUpdatePatientDocumentCheckListDTO) =>
    this.restService.request<any, PatientDocumentCheckListDTO>({
      method: 'POST',
      url: '/api/PlatformApp/patient-document-check-list',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/patient-document-check-list/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PatientDocumentCheckListDTO>({
      method: 'GET',
      url: `/api/PlatformApp/patient-document-check-list/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientDocumentCheckListDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/patient-document-check-list',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePatientDocumentCheckListDTO) =>
    this.restService.request<any, PatientDocumentCheckListDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/patient-document-check-list/${id}`,
      body: input,
    },
    { apiName: this.apiName });
    checkLisUpdate = (id: string, input: CreateUpdatePatientCheckListDTO) =>
    this.restService.request<any, PatientDocumentCheckListDTO>({
      method: 'POST',
      url: `/api/PlatformApp/patient-document-check-list/${id}/check-lis-update`,
      body: input,
    },
    { apiName: this.apiName });

    // updateCheckListNotesByGpatientIdAndSComments = (gpatientId: string, sComments: string) =>
    // this.restService.request<any, string>({
    //   method: 'PUT',
    //   responseType: 'text',
    //   url: `/api/PlatformApp/patient-document-check-list/update-check-list-notes/${gpatientId}`,
    //   params: { sComments },
    // },
    // { apiName: this.apiName });

    updateCheckListNotesByGpatientIdAndGDocumentIdAndSCommentsAndGPatientChecklistIdAndSCreatedDate = (gpatientId: string, gDocumentId: string, sComments: string, gPatientChecklistId: string, sCreatedDate: string) =>
    this.restService.request<any, string[]>({
      method: 'PUT',
      url: '/api/PlatformApp/patient-document-check-list/update-check-list-notes',
      params: { gpatientId, gDocumentId, sComments, gPatientChecklistId, sCreatedDate },
    },
    { apiName: this.apiName });

    updateCheckListNotesByGpatientIdAndGDocumentIdAndSCommentsAndGPatientChecklistIdAndSCreatedDateV1 = (gpatientId: string, gDocumentId: string, sComments: string, gPatientChecklistId: string) =>
      this.restService.request<any, any>({
        method: 'PUT',
        url: '/api/PlatformApp/patient-document-check-list/update-check-list-notesv1',
        params: { gpatientId, gDocumentId, sComments, gPatientChecklistId },
      },
      { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
