<mat-dialog-content class="mat-typography">
  <div class="modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1 *ngIf="!data.isMarkConfirm" class="modal-title head" >Move To</h1>
    <h1 *ngIf="data.isMarkConfirm" class="modal-title head" >Mark Confirmed</h1>
    <button class="text-right close" aria-label="Close" mat-dialog-close>
      <b> X </b>
    </button>
  </div>
  <form [formGroup]="moveToForm">
    <div class="modal-body">
      <mat-card-content>
        <div class="row">
          <!-- Status -->
          <mat-form-field class="col-lg-6 mt-3" [hidden]="data.isMarkConfirm">
            <mat-label>Move to<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpSearchStatus"  [disabled]="isDisabled" >
              <ng-container *ngFor="let branch of moveToData">
                <mat-option [value]="branch.shortCodeId">{{ branch.inboundStatus }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field class="col-6 mt-3">
            <mat-label>Note Type <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpNotetype"
              #singleSelect >
              <mat-option>
                <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found" formControlName="txtNoteType"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let note of drpNotetype" [value]="note.id+ '/' +note.noteType">
                {{ note.noteType }}
              </mat-option>
            </mat-select>
            <mat-error class="spanAlignment" *ngIf="
            moveToForm?.get('drpNotetype')?.touched &&
            moveToForm?.get('drpNotetype')?.errors?.required
      ">
              Note Type is a required field!
            </mat-error>
          </mat-form-field> -->

          <mat-form-field class="col-6 mt-3">
            <mat-select formControlName="drpNotetype">
              <mat-option>
                <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtNoteType"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let note of drpNotetype" [value]="note.id+ '/' +note.noteType">
                {{ note.noteType }}
              </mat-option>
            </mat-select>
            <mat-label>
              <div class="select-placeholder-container" *ngIf="isdrpLoading">
                <span>Loading...</span>
                <mat-spinner class="spinner" diameter="20"></mat-spinner>
              </div>
              <span *ngIf="!isdrpLoading">Note Type <span class="asterisk">*</span></span>
            </mat-label>
            <mat-error class="spanAlignment" *ngIf="
        moveToForm?.get('drpNotetype')?.touched &&
        moveToForm?.get('drpNotetype')?.errors?.required
      ">
              Note Type is a required field!
            </mat-error>
          </mat-form-field>

           <!-- <mat-option [value]="'011'">Missing Document</mat-option>
                <mat-option [value]="'002'">Fax issue</mat-option>
                <mat-option [value]="'016'">Pending</mat-option> -->
          <!-- Notes -->
          <mat-form-field class="col-12">
            <mat-label>Notes<span class="asterisk">*</span></mat-label>
            <textarea
              formControlName="txtComment"
              type="text"
              rows="6"
              maxlength="5000"
              autocomplete="off"
              matInput
              [(ngModel)]="notesText"
              (input)="updateCharacterCount(5000)"
            ></textarea>
            <div class="char-count">{{ notesText?.length }} / 5000</div>
            <!-- <mat-error
              class="spanAlignment"
              *ngIf="
                moveToForm?.get('txtComment')?.touched &&
                moveToForm?.get('txtComment')?.errors?.required
              "
              >Notes is Required field</mat-error
            > -->
          </mat-form-field>
          <br><br>
          <!-- (moveToForm.get('txtComment').value==='' || moveToForm.get('drpSearchStatus').value==='')|| -->
            <!-- Buttons -->
            <div class="col-lg-12  d-flex justify-content-center btncls">
                <ng-container class="col-12" *ngIf="!moveToForm?.disabled">
                  <!-- <button mat-button
                  [disabled]="moveToForm.invalid||saveButton"
                    class="buttonColor"  (click)="saveNotes()"
                  >
                    Save
                  </button> -->
                  <button mat-button class="buttonColor mb-2 mr-2"
                  [disabled]="isShowSpinner || moveToForm.invalid||saveButton" (click)="saveNotes()">
                  Save
                  <mat-icon *ngIf="isShowSpinner">
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                  </mat-icon>
                </button>

                  <button mat-button class="resetclr mb-2 mr-2" (click)="Reset()">Reset</button>
                </ng-container>
              </div>
        </div>
      </mat-card-content>
    </div>
  </form>
</mat-dialog-content>
