<div class="modal-header">
    <h1 class="modal-title"><b>Edit Physician</b></h1>
    <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
</div>
<div class="card card-body">
    <form [formGroup]="physicianForm">
        <div class="modal-body">
            <div class="row">

                <!--NPI Number Field-->
                <mat-form-field class="col-4">
                    <mat-label>NPI Number</mat-label>
                    <input autocomplete="new-txtNpiNumber" matInput formControlName="txtNpiNumber" type="text"
                        maxlength="40"/>
                </mat-form-field>

                <!--Doctor Name Field-->
                <mat-form-field class="col-4">
                    <mat-label>Doctor Name</mat-label>
                    <input autocomplete="new-txtDoctorName" matInput formControlName="txtDoctorName" type="text"
                        maxlength="40"/>
                </mat-form-field>

                <!--Address Field-->
                <mat-form-field class="col-4">
                    <mat-label>Address</mat-label>
                    <input autocomplete="new-txtphyAddress" matInput formControlName="txtphyAddress" type="text"
                        maxlength="80" />
                </mat-form-field>

                <!--State Drop Down-->
                <mat-form-field class="col-4">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="drpState" (selectionChange)="onChangeCity($event.value, '')"
                        [(value)]="State" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="drpStateFilter">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                            {{ state.stateName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="isStateTouched && physicianForm?.get('drpState')?.touched &&
                            physicianForm?.get('drpState')?.hasError('required')">
                        State is a required field!
                    </mat-error>
                </mat-form-field>


                <!-- City Drop Down -->
                <mat-form-field class="col-4">
                    <mat-label>City</mat-label>
                    <mat-select formControlName="drpCity" [(value)]="City" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="drpCityFilter">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                            {{ city.cityName }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="physicianForm?.get('drpCity')?.touched && physicianForm?.get('drpCity')?.errors?.required">
                        City is a required field!
                    </mat-error>
                </mat-form-field>


                <!--Country Drop Down-->
                <mat-form-field class="col-4 readonly" [hidden]="hidecountry">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="drpCountry" (selectionChange)="onChangeState($event.value)"
                        [(value)]="Country">
                        <mat-option *ngFor="let country of drpCountry" [value]="country.countryShortName">
                            {{ country.countryName }}
                        </mat-option>
                    </mat-select>
                    <button mat-button (click)="Country=''; $event.stopPropagation()" *ngIf="Country" matSuffix
                        mat-icon-button>
                    </button>
                    <mat-error
                        *ngIf="physicianForm?.get('drpCountry')?.touched && physicianForm?.get('drpCountry')?.errors?.required">
                        Country is a required field!
                    </mat-error>
                </mat-form-field>

                <!-- ZipCode -->
                <mat-form-field class="col-4">
                    <mat-label>ZipCode</mat-label>
                    <input autocomplete="new-txtZipcode" matInput formControlName="txtZipCode" type="text"
                        maxlength="10" class="zip-code-input" />
                    <mat-error
                        *ngIf="physicianForm?.get('txtZipCode')?.touched && physicianForm?.get('txtZipCode')?.hasError('required')">
                        ZipCode is a required field!
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 text-center">

            <button mat-button class="buttonColor mr-2"
                    [disabled]="isShowSpinner || !(!physicianForm?.invalid && physicianForm?.dirty)"
                    (click)="updatePhysician()">
                    <span>Update</span>
                    <mat-icon *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon>
                </button>
                <button mat-button class="resetclr mr-2" (click)="resetPhysicain()">Reset</button>
        </div>
    </form>
</div>