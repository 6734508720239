<mat-dialog-content class="mat-typography">
  <div class="row">
    <div>
      <h2 class="customThemeClass mr-4 ml-3">{{title}}</h2>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <mat-form-field class="col-12">
        <mat-label>Enter Address </mat-label>
        <input matInput matGoogleMapsAutocomplete (onAutocompleteSelected)="onAutocompleteSelected($event)"
          (onLocationSelected)="onLocationSelected($event)">
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-12">
        <agm-map id="map_element" *ngIf="showMap" [latitude]="latitude" [longitude]="longitude" [usePanning]='true'
          [disableDefaultUI]="false" [zoom]="18" (mapReady)="initDrawingManager($event)"  (mapClick)="mapClicked($event)"
          #agmmap>
          <agm-marker [latitude]="latitude" [longitude]="longitude" >
          </agm-marker>
          <agm-polygon (polyDrag)="onDragEnd($event)" [polyDraggable]="true" [paths]="markers"
            (polyClick)="onDragEnd($event)" [visible]="true" [editable]="true" [geodesic]="true">
          </agm-polygon>
        </agm-map>
        <agm-map id="map_element" *ngIf="!showMap" [latitude]="latitude" [longitude]="longitude"  [usePanning]='true'
          [disableDefaultUI]="false" [zoom]="12" (mapReady)="initDrawingManager($event)" (mapClick)="mapClicked($event)"
          #agmmap>
            <agm-marker [latitude]="latitude" [longitude]="longitude" >
            </agm-marker>
            <agm-polygon (polyDrag)="onDragEnd($event)" [polyDraggable]="true" [paths]="markers"
              (polyClick)="onDragEnd($event)" [visible]="true" [editable]="true" [geodesic]="true">
            </agm-polygon>
          </agm-map>
      </div>
    </div>

  </div>
</mat-dialog-content>
<form [formGroup]="AddCoverageAreaForm">
  <div class="row mt-4 ml-2">
    <mat-form-field class="col-4">
      <mat-label>Enter Coverage Area Name<span class="asterisk">*</span></mat-label>
      <input autocomplete="new-txtCovAreaName" matInput maxlength="40" formControlName="txtCovAreaName" type="text" />
    </mat-form-field>
    
    <div *ngIf="selectedArea" class="col-6 ">
      <span class="float-right"> Selected Area: {{ selectedArea + ' ' + 'm²' }}</span>
    </div>
  </div>
</form>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor mb-2 mr-2" (click)="saveCoverageValue()"
    [disabled]="AddCoverageAreaForm.invalid ">Save</button>
  <button mat-button class="resetclr mb-2 mr-2" (click)="reset()">
    Reset
  </button>
  <button class="buttonCancelColor mb-2" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
