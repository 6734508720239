import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from '@ngxs/store';
import { ClaimInformationsService } from 'projects/billing/src/app/billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO } from 'projects/billing/src/app/billing-proxy/billing-management/posting/dto/models';
import { ItemDTO, PayPalCheckOutOrderPaymentsDTO, PayPalInvoicePaymentsDTO, SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { paypalPaymentMethodType } from 'projects/order/src/app/order-proxy/order-management/order/paypal-payment-method-type.enum';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { CheckoutOrderService } from 'projects/order/src/app/order-proxy/order-management/pay-pal/checkout-order.service';
import { CreateUpdateCheckOutOrderCreateDTO, CreateUpdatePayPalInvoiceDTO, Item, Phone } from 'projects/order/src/app/order-proxy/order-management/pay-pal/dto/models';
import { InvoiceService } from 'projects/order/src/app/order-proxy/order-management/pay-pal/invoice.service';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { RegionCountriesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { AddressDTO, PatientDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { baseUrl } from 'projects/patient/src/environments/environment';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.scss']
})
export class PaymentViewComponent implements OnInit {
  saleOrderId: string = "";
  Name: string = "";
  dob: string;
  paymentCompletedDateTime: string = "";
  paymentCreatedDateTime: string = "";
  totalPaidAmount: string = "";
  totalPaypalAmount: string = "";
  totalTransferredAmount: string = "";
  checkOutOrderId: string = "";
  items: any[] = [];
  paymentData: any[] = []
  showCheckOutProceedButton: boolean = false;
  showInvoiceProceedButton: boolean = false;

  orderId: string = '';
  checkOutNumber: string = '';
  payPalInvoiceMailResendCount: number = 0;
  claimId: string = "";
  paypalPaymentForm: FormGroup;
  drpCountry: RegionCountriesDTO[] = [];
  invoiceOrderId: string = "";
  invoiceNumber: string = '';
  paypalPaymentMethodType: string = "";

  constructor(public dialogRef: MatDialogRef<PaymentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, claimId: string },
    private store: Store, private paymentService: CheckoutOrderService,
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,

    private saleOrderService: SaleorderService,
    private claimInformationService: ClaimInformationsService,
    private region: RegionDropdownService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {


    this.paypalPaymentForm = this.formBuilder.group({
      rbtnPayPalPaymentType: new FormControl("",[Validators.required]),
    });
    this.orderId = this.data?.orderId ?? "";
    this.claimId = this.data?.claimId ?? "";

    this.orderId && this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getPaymentView();
    this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response?.items ?? [];
    });
  }

  isShowCheckOutOrder: boolean = true;
  isShowInvoice: boolean = false;

  //! to get verify option
  onChangePayPalPaymentType($event: MatRadioChange) {
    if ($event.value === 'CheckOutOrder') {
      this.isShowCheckOutOrder = true;
      this.showCheckOutProceedButton = true;
      this.isShowInvoice = false;
      this.showInvoiceProceedButton = false;

    }
    if ($event.value === 'Invoice') {
      this.isShowInvoice = true;
      this.showInvoiceProceedButton = true;
      this.isShowCheckOutOrder = false;
      this.showCheckOutProceedButton = false;
    }

  }
  totalItemsQty: number = 0;
  totalItemsPrice:number = 0;
  //! payment view
  getPaymentView() {
    this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
      const orderData: SaleorderDTO = response?.orderState?.selectedOrder;
      this.paypalPaymentMethodType = orderData.payPalPaymentMethodType
      this.paypalPaymentForm.patchValue({
        rbtnPayPalPaymentType:orderData.payPalPaymentMethodType == null || orderData.payPalPaymentMethodType == undefined || orderData.payPalPaymentMethodType == ""? "CheckOutOrder" : orderData.payPalPaymentMethodType
      });
      if(this.paypalPaymentMethodType === 'Invoice'){
        this.orderId && this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getInvoicePaymentView();
        this.isShowInvoice = true;
        this.isShowCheckOutOrder = false;
      }
      else{
        if (orderData?.payPalPaymentStatus === "Initiate Payment" || orderData?.payPalPaymentStatus === "Link Expired" || orderData?.payPalPaymentStatus ===null ) {
          this.showCheckOutProceedButton = true;
          this.showInvoiceProceedButton = false;
        } else {
          this.showCheckOutProceedButton = false;
        }
        this.totalItemsQty = orderData?.shippingCheckList?.totalItemQty;
        this.totalItemsPrice = orderData?.shippingCheckList?.totalItemPrice;
        this.saleOrderId = orderData?.saleorderId ?? "";
        this.dob = orderData?.patients?.personals?.dateOfBirth == null ? undefined : new Date(orderData?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US") ?? "";
        this.Name = orderData?.patients?.personals?.fullName ?? "";
        orderData?.items?.forEach(element => { this.items.push({
          itemName: element.name,
          amount: element.charge,
          code: element.itemIdOrBarCode,
          totalUnits: element?.qty,
          totalPrice: element?.totalPrice }) });
        const OrderPayments: PayPalCheckOutOrderPaymentsDTO = orderData?.paymentTransactionDetails?.payPalCheckOutOrderPayments?.[0] || null;
        this.paymentCompletedDateTime = OrderPayments?.paymentCompletedDateTime ?? "";
        this.paymentCreatedDateTime = OrderPayments?.paymentCreatedDateTime ?? "";
        this.totalPaidAmount = OrderPayments?.totalPaidAmount ?? "";
        this.totalPaypalAmount = OrderPayments?.totalPaypalTransactionChargeAmount ?? "";
        this.checkOutOrderId = OrderPayments?.checkOutOrderId ?? "";
        this.totalTransferredAmount = OrderPayments?.totalTransferredAmount ?? "";
        this.payPalInvoiceMailResendCount = orderData?.payPalMailResendCount ?? 0;
      }

    })
  }
  //! paypal save
  paypalProceed() {
    // this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
    // if (response?.orderState?.selectedOrder?.payPalCheckOutOrderPaymentStatus === "Initiate Payment") {
    let payment: CreateUpdateCheckOutOrderCreateDTO;
    this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
      let orderData = response?.orderState?.selectedOrder;
      if (response?.orderState?.selectedOrder?.payPalCheckOutOrderPaymentStatus === "Link Expired") {
        Swal.fire({
          title: 'Are you sure want to resend Mail again?',
          text: 'Mail will send to registered mail again!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, Resend!'
        }).then(result => {
          if (result.value === true) {
            let orderItem = response?.orderState?.selectedOrder.items;
            let orderId = orderData.id
            this.paymentData = []
            orderItem?.forEach((element: { itemId: any; charge: any; }) => {
              this.paymentData.push({
                itemId: element?.itemId,
                itemCurrenyCode: "USD",
                itemAmount: String(element?.charge)
              })
            });
            payment = {
              paypalPaymentMethodType: paypalPaymentMethodType.CheckOutOrder,
              saleOrderId: orderData?.id,
              returnUrl: baseUrl + "#/cashPayment/" + orderId,
              itemDetails: this.paymentData,
              status: "Link Expired"
              // clientMailAddress: "sb-pddu46631598@business.example.com",
              // clientMerchantId: "VN995TY99ZFHC"
            }
            this.paymentService.createCheckOutOrder(payment).subscribe(response => {
              this.getClaimDataById();
            },
              err => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })

          }
        });

      } else {
        let orderItem = response?.orderState?.selectedOrder.items;
        let orderId = orderData.id
        this.paymentData = []
        orderItem?.forEach((element: { itemId: any; charge: any; }) => {
          this.paymentData.push({
            itemId: element?.itemId,
            itemCurrenyCode: "USD",
            itemAmount: String(element?.charge)
          })
        });
        payment = {
          paypalPaymentMethodType: paypalPaymentMethodType.CheckOutOrder,
          saleOrderId: orderData?.id,
          returnUrl: baseUrl + "#/cashPayment/" + orderId,
          itemDetails: this.paymentData,
          status: ""
          // clientMailAddress: "sb-pddu46631598@business.example.com",
          // clientMerchantId: "VN995TY99ZFHC"
        }
        this.paymentService.createCheckOutOrder(payment).subscribe(response => {
          this.getClaimDataById();

        },
          err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          })
      }
    })
  }


  //! Get Claim Data By Id
  getClaimDataById() {


    if (this.claimId && this.claimId !== "" && this.claimId !== undefined && this.claimId !== null) {
      this.claimInformationService.get(this.claimId).subscribe((claimResponse: ClaimDTO) => {

        this.updateClaimDataOnOrderUpdate(claimResponse);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else{
      // Swal.fire({ title: 'Success', html: 'Mail Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Mail Sent Successfully','Success')
      this.dialogRef.close();
    }
  }




  //! Update Claim Order Data
  updateClaimDataOnOrderUpdate(claimResponse: ClaimDTO) {
    if (this.claimId && this.claimId !== "" && this.claimId !== undefined && this.claimId !== null) {

        const input: any = {
          lstClaimInfo: claimResponse?.lstClaimInfo,
          lstPostingCharges: claimResponse?.lstPostingCharges,
          saleOrder: claimResponse?.saleOrder,
          lstPatientVisitNotes: claimResponse?.lstPatientVisitNotes,
          lstClearingHouseUpdates: claimResponse?.lstClearingHouseUpdates,
          totalPatientBal: claimResponse?.totalPatientBal,
          readyCount: claimResponse?.readyCount,
          processedCount: claimResponse?.processedCount,
          crossOverCount: claimResponse?.crossOverCount,
          holdCount: claimResponse?.holdCount,
          patientResponsibilityCount: claimResponse?.patientResponsibilityCount,
          patientResponsibilityAfterInsCount: claimResponse?.patientResponsibilityAfterInsCount,
          patientId: claimResponse?.patientId,
          defaultPatientId: claimResponse?.defaultPatientId,
          patientName: claimResponse?.patientName,
          patientDob: claimResponse?.patientDob,
          orderId: claimResponse?.orderId,
          saleorderId: claimResponse?.saleorderId,
          claimStatus: claimResponse?.claimStatus,
          claimId: claimResponse?.claimId,
          insurancePayments: claimResponse.insurancePayments,
          totalBilled:claimResponse.totalBilled,
          totalInsBal:claimResponse.totalInsBal,
        }


        this.claimInformationService.update(this.claimId, input).subscribe((claimResponse: ClaimDTO) => {
          // Swal.fire({ title: 'Success', html: 'Mail Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Mail Sent Successfully','Success')
          this.dialogRef.close();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
    }
  }

  //! Invoice Paypal Proceed
  paypalInvoiceProceed() {
    this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
      const orderData: SaleorderDTO = response?.orderState?.selectedOrder || null;
      const orderItem: ItemDTO[] = response?.orderState?.selectedOrder?.items ?? [];
      const orderId: string = orderData?.id ?? null;
      const patientData = orderData?.patients;
      const bAddress: AddressDTO = patientData?.addresses?.[0] || null;
      const dAddress: AddressDTO = patientData?.addresses?.[1] || null;

      let invoiceItemsData: Item[] = [];
      orderItem.forEach(element => {
        invoiceItemsData.push({
          name: element.name,
          description: element.description,
          quantity: String(element.qty),
          unit_amount: {
            currency_code: "USD",
            value: String(element.charge),
          },
          tax: null,
          discount: null,
          unit_of_measure: "QUANTITY"
        })
      });
      let patientPhones: Phone[];
      if (bAddress?.country !== "" && bAddress?.country !== null && bAddress?.country !== undefined &&
          bAddress?.phone !== "" && bAddress?.phone !== null && bAddress?.phone !== undefined) {
        patientPhones = [
          {
            country_code: this.drpCountry.filter(v => v?.countryShortName === bAddress?.country)?.[0]?.countryPhoneCode ?? null,
            national_number: bAddress?.phone ?? null,
            phone_type: "MOBILE"
          }
        ]
      }
      const input: CreateUpdatePayPalInvoiceDTO = {
        paypalPaymentMethodType: paypalPaymentMethodType.Invoice,
        saleOrderId: orderId,
        detail: null,
        invoicer: null,
        primary_recipients: [
          {
            billing_info: {
              name: {
                given_name: patientData?.personals?.firstName ?? "",
                surname: patientData?.personals?.lastName ?? ""
              },
              address: {
                address_line_1: bAddress?.address ?? "",
                admin_area_1: bAddress?.city ?? "",
                postal_code: bAddress?.postalCode ?? "",
                country_code: bAddress?.country === "" ? null : bAddress?.country,
              },
              email_address: patientData?.personals?.emailId == null ||
              patientData?.personals?.emailId == undefined ||
              patientData?.personals?.emailId == "" ? 'sb-cvmiv6652198@business.example.com' : patientData?.personals?.emailId,// patientData?.personals?.emailId ?? null,
              phones: patientPhones ?? null,//!
              additional_info_value: null
            },
            shipping_info: {
              name: {
                given_name: patientData?.personals?.firstName ?? "",
                surname: patientData?.personals?.lastName ?? ""
              },
              address: {
                address_line_1: dAddress?.address ?? "",
                admin_area_1: dAddress?.city ?? "",
                postal_code: dAddress?.postalCode ?? "",
                country_code: dAddress?.country === "" ? null : dAddress?.country,
              }
            }
          }
        ],
        items: invoiceItemsData,
        configuration: null,
        amount: null,
      }

      this.invoiceService.createDraftInvoice(input).subscribe(response => {
        this.getClaimDataById();



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //! Invoice payment view
  getInvoicePaymentView() {
    this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
      const orderData: SaleorderDTO = response?.orderState?.selectedOrder;
      this.showInvoiceProceedButton = true;
      if (orderData?.payPalPaymentStatus === "Initiate Payment" || orderData?.payPalPaymentStatus === null) {
        this.showInvoiceProceedButton = true;
        this.showCheckOutProceedButton = false;
      } else { this.showInvoiceProceedButton = false; }
      this.saleOrderId = orderData?.saleorderId ?? "";
      this.dob = orderData?.patients?.personals?.dateOfBirth == null ? "" : new Date(orderData?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US") ?? "";
      this.Name = orderData?.patients?.personals?.fullName ?? "";
      orderData?.items?.forEach(element => { this.items?.push({ itemName: element.name,
        totalUnits: element?.qty,
        totalPrice: element?.totalPrice, amount: element.charge, code: element.itemIdOrBarCode }) });
      const lastInvoiceDetails: PayPalInvoicePaymentsDTO | null = orderData?.paymentTransactionDetails?.payPalInvoicePayments?.slice(orderData?.paymentTransactionDetails?.payPalInvoicePayments?.length - 1, orderData?.paymentTransactionDetails?.payPalInvoicePayments?.length)?.[0] || null;
      this.paymentCompletedDateTime = lastInvoiceDetails?.payments?.transactions?.[0]?.payment_date ? lastInvoiceDetails.detail?.metadata?.last_update_time : "";
      this.paymentCreatedDateTime = lastInvoiceDetails?.detail?.metadata?.create_time ?? "";
      this.totalPaidAmount = lastInvoiceDetails?.['totalPaidAmount'] ?? "";
      this.totalPaypalAmount = lastInvoiceDetails?.['totalPaypalTransactionChargeAmount'] ?? "";
      this.invoiceOrderId = lastInvoiceDetails?.id ?? "";
      this.totalTransferredAmount = lastInvoiceDetails?.['totalTransferredAmount'] ?? "";
      this.invoiceNumber = lastInvoiceDetails?.detail?.invoice_number ?? "";
      this.payPalInvoiceMailResendCount = orderData?.payPalMailResendCount?? 0;
      this.totalItemsQty = orderData?.shippingCheckList?.totalItemQty;
      this.totalItemsPrice = orderData?.shippingCheckList?.totalItemPrice;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}
