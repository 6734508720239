import type { CreateUpdateInsuranceProviderProductEligibilityRulesDTO, InsuranceProviderProductEligibilityRulesDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InsuranceProviderProductEligibilityRulesService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateInsuranceProviderProductEligibilityRulesDTO) =>
    this.restService.request<any, InsuranceProviderProductEligibilityRulesDTO>({
      method: 'POST',
      url: '/api/orderApp/insurance-provider-product-eligibility-rules',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/insurance-provider-product-eligibility-rules/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, InsuranceProviderProductEligibilityRulesDTO>({
      method: 'GET',
      url: `/api/orderApp/insurance-provider-product-eligibility-rules/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InsuranceProviderProductEligibilityRulesDTO>>({
      method: 'GET',
      url: '/api/orderApp/insurance-provider-product-eligibility-rules',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateInsuranceProviderProductEligibilityRulesDTO) =>
    this.restService.request<any, InsuranceProviderProductEligibilityRulesDTO>({
      method: 'PUT',
      url: `/api/orderApp/insurance-provider-product-eligibility-rules/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchItemBySItemCodeAndSItemNameAndSPrice = (sItemCode: string, sItemName: string, sPrice: string) =>
    this.restService.request<any, PagedResultDto<InsuranceProviderProductEligibilityRulesDTO>>({
      method: 'POST',
      url: '/api/orderApp/insurance-provider-product-eligibility-rules/search-item',
      params: { sItemCode, sItemName, sPrice },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
