<div class="row">
    <div class="col-lg-6">
      <h2 *ngIf=" data != undefined || data != null " >Edit Stock</h2>
      <h2 *ngIf=" data == undefined || data == null ">Add Stock</h2>
     
    </div>
    <div class= "col-lg-3" *ngIf=" data != undefined || data != null ">
      <button mat-button class="buttonColor mb-2 mr-2"  (click)="updateSerialNo()"matTooltip="Click To Update serial No" style="margin-left: 425px !important; ">
        Update serial No. </button>
    </div>

  </div>

  <mat-dialog-content class="mat-typography">
    <!-- <div *ngIf="radioButton" class="row">

      <mat-radio-group class="rg pt-3 ml-3" (change)="onChangeGetInsuranceVerify($event)">
        <mat-radio-button [checked]='true' value="MainProduct">Main Product</mat-radio-button>
        &nbsp;&nbsp;&nbsp;
        <mat-radio-button value="ResupplyProduct">Resupply Product</mat-radio-button>&nbsp;&nbsp;&nbsp;
      </mat-radio-group>
    </div> -->
    <div >
      <form [formGroup]="stockForm">
        <div class="row">
          <div class="col-lg-12">
            <mat-card-content>
              <div class="row">
                <div class="col-lg-12">
                  <div class="row col-lg-12 p-3">          
                    <mat-radio-group  aria-label="Select an option" (change)="changeProduct($event)"  formControlName="txtProductType">
                      <mat-radio-button class="col-3 " [checked]="true"   [value]="1">Main Product</mat-radio-button>
                      <mat-radio-button class="col-3 space" [value]="2">Resupply</mat-radio-button>
                    </mat-radio-group>
                  </div>
                    <mat-form-field class="col-4">
                      <input formControlName="soldQuantity" type="hidden" />
                        <mat-label>Product Name<span class="asterisk">*</span> </mat-label>
                        <mat-select (selectionChange)="onChanges($event)" formControlName="txtMainProduct"
                          [(value)]="MainProduct" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtMainProductFilter"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let provider of filterMainProduct | async" [value]="provider.id">
                            {{provider.mainProductName}}
                          </mat-option>
                        </mat-select>

                        <button mat-button (click)="MainProduct=undefined;$event.stopPropagation()" *ngIf="MainProduct"
                          matSuffix (click)="clearMainProduct()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="
                        stockForm?.get('txtMainProduct')?.touched &&
                        stockForm?.get('txtMainProduct')?.errors?.required
                                        ">
                          Product Name is a required field!
                        </mat-error>
                      </mat-form-field>
                      <!-- <mat-form-field class="col-4">
                        <mat-label>Brand Name <span class="asterisk">*</span></mat-label>
                       <input maxlength="40" formControlName="txtBrandName"
                                              matInput type="text" /> -->
                        <!-- <mat-select formControlName="txtBrandName" [(value)]="brand" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtbrandFilter"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let brand of filterProductBrand | async" [value]="brand.id">
                            {{brand.productBrandDetailName}}
                          </mat-option>
                        </mat-select>

                        <button mat-button (click)="brand=undefined;$event.stopPropagation()" *ngIf="brand" matSuffix
                          (click)="clearbrand()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>

                        <mat-error *ngIf="
                            stockForm?.get('txtBrandName')?.touched &&
                            stockForm?.get('txtBrandName')?.errors?.required
                                            ">
                          Brand Name is a required field!
                        </mat-error>
                      </mat-form-field> -->

                      <mat-form-field class="col-4">
                        <mat-label>Manufacturer  Name <span class="asterisk">*</span></mat-label>
                        <!-- <input maxlength="40" formControlName="txtVendorName"
                                  matInput type="text" /> -->
                        <mat-select formControlName="txtVendorName" [(value)]="provider" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtVendorFilter"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let provider of filterVendorName | async" [value]="provider.id">
                            {{provider.vendorName}}
                          </mat-option>
                        </mat-select>

                        <button mat-button (click)="provider=undefined;$event.stopPropagation()" *ngIf="provider"
                          matSuffix (click)="clearsupplyVendor()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-error
                          *ngIf="stockForm?.get('txtVendorName')?.touched &&stockForm?.get('txtVendorName')?.errors?.required">
                          Manufacturer  Name is a required field!
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="col-4">
                        <mat-label>Category Name <span class="asterisk">*</span></mat-label>
                        <!-- <input maxlength="40" formControlName="txtCategoryName"
                                              matInput type="text" /> -->
                        <mat-select formControlName="txtCategoryName" [(value)]="category" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtCategoryFilter"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let category of filterCategoryName | async" [value]="category.id">
                            {{category.productCategoryName}}
                          </mat-option>
                        </mat-select>

                        <button mat-button (click)="category=undefined;$event.stopPropagation()" *ngIf="category" matSuffix
                          (click)="clearCategory()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="
                            stockForm?.get('txtCategoryName')?.touched &&
                            stockForm?.get('txtCategoryName')?.errors?.required
                                            ">
                          Category Name is a required field!
                        </mat-error>
                      </mat-form-field>

                  <mat-form-field class="col-4">
                    <mat-label>Lot No</mat-label>
                    <input autocomplete="new-txtLotNo" maxlength="40" formControlName="txtLotNo" matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <mat-error *ngIf="
                      stockForm?.get('txtLotNo')?.touched &&
                      stockForm?.get('txtLotNo')?.errors?.required
                                ">
                      Lot No is a required field!
                    </mat-error>
                    <mat-error *ngIf="
                      stockForm?.get('txtLotNo')?.touched &&
                      stockForm?.get('txtLotNo')?.errors?.pattern
                    ">
                      Enter only Alphanumeric!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-4">
                    <mat-label>Qty<span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtQty" min="1" maxlength="4" formControlName="txtQty" matInput type="text" />
                    <mat-error *ngIf="
                        stockForm?.get('txtQty')?.touched &&
                        stockForm?.get('txtQty')?.errors?.required 
                        
                                  ">
                                  Qty is a required field!
                    </mat-error>
                    <mat-error *ngIf="
                        stockForm?.get('txtQty')?.touched &&
                        stockForm?.get('txtQty')?.errors?.number
                      ">
                      Enter only Numbers!
                    </mat-error>
                    <mat-error *ngIf="
                    stockForm?.get('txtQty')?.touched &&
                    stockForm?.get('txtQty')?.errors?.min
                  ">
                  Enter Valid Number Greater Than 0!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-4">
                    <mat-label>Price per Unit <span class="asterisk">*</span></mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input autocomplete="new-txtPrice" max="4" formControlName="txtPrice" matInput type="number" />
                    <mat-error *ngIf="
                        stockForm?.get('txtPrice')?.touched &&
                        stockForm?.get('txtPrice')?.errors?.required
                                  ">
                      Price is a required field!
                    </mat-error>
                    <mat-error *ngIf=" stockForm?.get('txtPrice')?.touched && stockForm?.get('txtPrice')?.errors?.msg">
                      {{stockForm?.get('txtPrice')?.errors?.msg}}
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field class="col-4 readonly">
                    <mat-label> Stock Purchase Date <span class="asterisk">*</span></mat-label>
                    <input formControlName="txtPurchasedate" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtPurchasedate" />
                    <mat-datepicker-toggle matSuffix [for]="txtPurchasedate"> </mat-datepicker-toggle>
                    <mat-datepicker #txtPurchasedate> </mat-datepicker>
                    <mat-error *ngIf="
                      stockForm?.get('txtPurchasedate')?.touched &&
                        stockForm?.get('txtPurchasedate')?.errors ">
                      Enter Valid date
                    </mat-error>
                    <mat-error
                      *ngIf=" stockForm?.get('txtPurchasedate')?.touched && stockForm?.get('txtPurchasedate')?.errors?.required">
                      Stock Purchase Date is a required field!
                    </mat-error>
                </mat-form-field> -->
                </div>
              </div>
            </mat-card-content>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button  [disabled]="(stockForm.invalid )"
    mat-button class="buttonColor"
      (click)='saveStock()'>Save
    </button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

  </mat-dialog-actions>
