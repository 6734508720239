<mat-dialog-content class="mat-typography">
  <form [formGroup]="paypalPaymentForm">
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
              <mat-radio-group class="rg pt-3 ml-3" (change)="onChangePayPalPaymentType($event)" formControlName="rbtnPayPalPaymentType">
                <mat-radio-button [disabled]="paypalPaymentMethodType === 'Invoice' " value="CheckOutOrder">CheckOutOrder</mat-radio-button>&nbsp;&nbsp;&nbsp;
                <mat-radio-button [disabled]="paypalPaymentMethodType === 'CheckOutOrder' " value="Invoice">Invoice</mat-radio-button>&nbsp;&nbsp;&nbsp;
              </mat-radio-group>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <div class="row" *ngIf='isShowCheckOutOrder'>
    <div class="col-lg-12">
      <h2 class="customThemeClass">CheckOutOrder Payment Details</h2>
    </div>
    <div class="col-lg-4">
      <table>
        <tr>
          <td class="mt-2 text-right"><b>Name </b></td>
          <td>
            <b> : {{ Name }}</b>
          </td>
        </tr>
        <tr>
          <td class="text-right"><b>SaleOrder Id </b></td>
          <td>
            <b>: {{ saleOrderId }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>DOB </b></td>
          <td>
            <b> : {{ dob }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Mail Resend Counts </b></td>
          <td>
            <b> : {{ payPalInvoiceMailResendCount }}</b>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-4">
      <table>
        <tr>
          <td class="mt-2 text-right"><b>Payment Completed Date </b></td>
          <td>
            <b> : {{ paymentCompletedDateTime | date: 'MM/dd/yyyy h:mm a':'en_US' }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Payment Created Date </b></td>
          <td>
            <b> : {{ paymentCreatedDateTime | date: 'MM/dd/yyyy h:mm a':'en_US' }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Check Out OrderId </b></td>
          <td>
            <b> : {{ checkOutOrderId }}</b>
          </td>
        </tr>


      </table>
    </div>
    <div class="col-lg-4">
      <table>
        <tr>
          <td class="mt-2 text-right"><b>Total Paid Amount </b></td>
          <td>
            <b> : $ {{ totalPaidAmount }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Paypal Transaction Amount </b></td>
          <td>
            <b> : $ {{ totalPaypalAmount }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Total Transferred Amount </b></td>
          <td>
            <b> : $ {{ totalTransferredAmount }}</b>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-12">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Price Per Unit</th>
            <th>Total Units</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td>{{ item.code + " / " +  item.itemName}}</td>
            <td>$ {{ item.amount }}</td>
            <td>{{ item.totalUnits }}</td>
            <td>$ {{ item.totalPrice }}</td>
          </tr>
          <tr>
            <!-- <td colspan="2"></td> -->
            <td colspan="2" class="text-right">Total</td>
            <td colspan="1"><b>{{totalItemsQty}}</b> </td>
            <td colspan="1"><b>{{"$ "+totalItemsPrice}}</b> </td>
            
        </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="col-12 text-center">
      <button class="buttonColor" *ngIf="showButton" (click)="paypalProceed()" mat-button>
        Proceed
      </button>
    </div> -->
  </div>
  <div *ngIf="isShowInvoice">
    <!-- <app-invoice-payment-view></app-invoice-payment-view> -->
    <div class="row">
      <div class="col-lg-12">
        <h2 class="customThemeClass">Invoice Payment Details</h2>
      </div>
      <div class="col-lg-4">
        <table>
          <tr>
            <td class="mt-2 text-right"><b>Name </b></td>
            <td>
              <b> : {{ Name }}</b>
            </td>
          </tr>
          <tr>
            <td class="text-right"><b>SaleOrder Id </b></td>
            <td>
              <b>: {{ saleOrderId }}</b>
            </td>
          </tr>
          <tr>
            <td class="mt-2 text-right"><b>DOB </b></td>
            <td>
              <b> : {{ dob }}</b>
            </td>
          </tr>
          <tr>
            <td class="mt-2 text-right"><b>Mail Resend Counts </b></td>
            <td>
              <b> : {{ payPalInvoiceMailResendCount }}</b>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-lg-4">
        <table>
          <tr>
            <td class="mt-2 text-right"><b>Payment Completed Date </b></td>
            <td>
              <b> : {{ paymentCompletedDateTime | date: 'MM/dd/yyyy h:mm a':'en_US' }}</b>
            </td>
          </tr>
          <tr>
            <td class="mt-2 text-right"><b>Payment Created Date </b></td>
            <td>
              <b> : {{ paymentCreatedDateTime | date: 'MM/dd/yyyy h:mm a':'en_US' }}</b>
            </td>
          </tr>

          <tr>
            <td class="mt-2 text-right"><b>Invoice Number </b></td>
            <td>
              <b> : {{ invoiceNumber }}</b>
            </td>
          </tr>
          <tr>
            <td class="mt-2 text-right"><b>Invoice Id </b></td>
            <td>
              <b> : {{ invoiceOrderId }}</b>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-lg-4">
        <table>
          <tr>
            <td class="mt-2 text-right"><b>Total Paid Amount </b></td>
            <td>
              <b> : $ {{ totalPaidAmount }}</b>
            </td>
          </tr>
          <tr>
            <td class="mt-2 text-right"><b>Paypal Transaction Amount </b></td>
            <td>
              <b> : $ {{ totalPaypalAmount }}</b>
            </td>
          </tr>
          <tr>
            <td class="mt-2 text-right"><b>Total Transferred Amount </b></td>
            <td>
              <b> : $ {{ totalTransferredAmount }}</b>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-lg-12">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Price Per Unit</th>
              <th>Total Units</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of items">
              <td>{{ item.code + " / " +  item.itemName}}</td>
              <td>$ {{ item.amount }}</td>
              <td>{{ item.totalUnits }}</td>
              <td>$ {{ item.totalPrice }}</td>
            </tr>
            <tr>
              <!-- <td colspan="2"></td> -->
              <td colspan="2" class="text-right">Total</td>
              <td colspan="1"><b>{{totalItemsQty}}</b> </td>
              <td colspan="1"><b>{{"$ "+totalItemsPrice}}</b> </td>
              
          </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="col-12 text-center">
        <button class="buttonColor" *ngIf="showButton" (click)="paypalProceed()" mat-button>
          Proceed
        </button>
      </div> -->
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="buttonColor" *ngIf="showCheckOutProceedButton" (click)="paypalProceed()" mat-button>
    Proceed
  </button>
  <button class="buttonColor" *ngIf="showInvoiceProceedButton" (click)="paypalInvoiceProceed()" mat-button>
    Proceed
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
