import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxChartComponent, reportPageType, reportsType } from 'projects/admin/src/app/ngx-chart/ngx-chart.component';
import { Subscription } from 'rxjs';
import { QuarterYear } from '../patient-proxy/patient/enumeration-data/quarter-year.enum';
import { SourceMDPatientService } from '../patient-proxy/source-mdpatient';
import { SummarySourceMDPatientDTO } from '../patient-proxy/source-mdpatient/dto/models';

@Component({
  selector: 'app-patient-sourcemd-popup',
  templateUrl: './patient-sourcemd-popup.component.html',
  styleUrls: ['./patient-sourcemd-popup.component.scss']
})
export class PatientSourcemdPopupComponent implements OnInit , OnDestroy {
  sumrySrcMDPtReport: SummarySourceMDPatientDTO = null;
  hideQuarter1button: boolean = false;
  hideQuarter2button: boolean = false;
  hideInitialButton: boolean = true;
  hide2022YearButton: boolean = true;
  hide2021YearButton: boolean = true;
  hide2022YearButton2: boolean = false;
  hide2021YearButton2: boolean = false;
  detailsHide: boolean = false;
  hideJanButton: boolean = false;
  hideAprButton: boolean = false;
  hideJulyButton: boolean = false;
  hideOctButton: boolean = false;
  selectQuarter: boolean = false;
  previousYear: number;
  selDyear: number;
  currentYear: number = new Date().getFullYear();
  subscription$: Subscription[] = [];
  constructor(
    private sourceMdService: SourceMDPatientService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.previousYear = this.currentYear - 1;
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  // Bind Weekly Reports
  getWeeklyReports() {
    const summarySource = this.sourceMdService.getSummarySourceMDPatientReport().subscribe(response => {
      this.sumrySrcMDPtReport = response;
    })
    this.subscription$.push(summarySource);
  }

  // Bind Monthly Report
  getMonthlyReports() {

  }
  openReportsSummary(typeOfReport: reportsType, quatarlyType: QuarterYear, year: number, pageType: reportPageType) {
    const dialogRef = this.dialog.open(NgxChartComponent, {
      disableClose: true,
      maxHeight: '81vh',
      data: { pagetype: pageType, type: typeOfReport, typeofquery: quatarlyType, year: year }
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.hideQuarter1button = false;
      this.hideQuarter2button = false;
      this.hideInitialButton = true;
      this.hide2022YearButton = true;
      this.hide2021YearButton = true;
      this.hide2022YearButton2 = false;
      this.hide2021YearButton2 = false;
      this.detailsHide = false;
      this.hideJanButton = false;
      this.hideAprButton = false;
      this.hideJulyButton = false;
      this.hideOctButton = false;
      this.selectQuarter = false;
    });
    this.subscription$.push(closeDialog)
  }

  // OnChange Based On Condition
  onChange21(year: string | number) {
    if (+year == this.previousYear) {
      this.hideInitialButton = false;
      this.hide2021YearButton2 = true;
      this.hide2022YearButton2 = false;
      this.hideQuarter1button = true;
    }
    if (+year == this.currentYear) {
      this.hideInitialButton = false;
      this.hide2022YearButton2 = true;
      this.hide2021YearButton2 = false;
      this.hideQuarter1button = true;
    }
    this.selDyear = +year;
  }

  // OnChange Based On Quarter
  onChangeQuarter(month: string) {
    let quatarlyType: QuarterYear;
    if (month == 'Jan') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = true;
      this.hideAprButton = false;
      this.hideJulyButton = false;
      this.hideOctButton = false;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType = QuarterYear.JanuaryToMarch;
    }
    if (month == 'Apr') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = false;
      this.hideAprButton = true;
      this.hideJulyButton = false;
      this.hideOctButton = false;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType = QuarterYear.AprilToJune;

    }
    if (month == 'July') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = false;
      this.hideAprButton = false;
      this.hideJulyButton = true;
      this.hideOctButton = false;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType = QuarterYear.JulyToSeptember;

    }
    if (month == 'Oct') {
      this.hideQuarter1button = false;
      this.hideQuarter2button = true;
      this.hideJanButton = false;
      this.hideAprButton = false;
      this.hideJulyButton = false;
      this.hideOctButton = true;
      this.detailsHide = true;
      this.selectQuarter = true;
      quatarlyType = QuarterYear.OctoberToDecember;

    }
    setTimeout(() => {
      this.openReportsSummary('QuaterlyReport', quatarlyType, this.selDyear, 'SourceMd');
    }, 1000);
  }

}
