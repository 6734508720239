import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Moment } from 'moment';
import {
  DaterangepickerDirective,
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import emailMask from 'text-mask-addons/dist/emailMask';
@Component({
  selector: 'app-practice-management-v2',
  templateUrl: './practice-management-v2.component.html',
  styleUrls: ['./practice-management-v2.component.scss'],
  providers: [DatePipe],
})
export class PracticeManagementV2Component implements OnInit {
  activeTab: string = 'physicians';
  practiceForm: FormGroup;
  notesText: string = '';
  phoneCode: string = '';
  emailMask: any;

  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any;
  toDate: any;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  dateRangStatus: boolean = false;
  alwaysShowCalendars: boolean;

  constructor(private fb: FormBuilder, private datepipe: DatePipe) {
    this.emailMask = emailMask;
  }

  ngOnInit(): void {
    this.initializeForms();
  }
  initializeForms() {
    this.practiceForm = this.fb.group({
      txtPracticeName: new FormControl(''),
      txtPracticeAddress: new FormControl(''),
      txtCountry: new FormControl(''),
      txtCountryFilter: new FormControl(''),
      txtState: new FormControl(''),
      txtStateFilter: new FormControl(''),
      txtCity: new FormControl(''),
      txtCityFilter: new FormControl(''),
      txtPostalCode: new FormControl(''),
      txtPhoneNo: new FormControl(''),
      txtFaxNo: new FormControl(''),
      txtPracticeWebsite: new FormControl(''),
      txtPracticeManager: new FormControl(''),
      txtPracticeEmail: new FormControl(''),
      drpAccountManager: new FormControl(''),
      txtAccountManager: new FormControl(''),
      chkMedicareIDTF: new FormControl(''),
      chkOutsourceReferrals: new FormControl(''),
      txtComments: new FormControl(''),
      dtDate: new FormControl(''),
    });
  }
  changeTab(tab: string) {
    this.activeTab = tab;
  }
  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      //this.getAssignedTask();
      //this.getWipList();
    } else {
      this.dateRangStatus = false;
      (this.fromDate = null), (this.toDate = null);
      //this.getAssignedTask();
      //this.getWipList();
    }
  }
}
