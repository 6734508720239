<mat-dialog-content>
  <h2 class="customThemeClass" mat-dialog-title>Report Summary <ng-container *ngIf="dataType === 'QuaterlyReport'">
      {{ " - " + trendsTitle}} </ng-container>
  </h2>

  <ng-container *ngIf="pageType==='SourceMd'">
    <ng-container *ngIf="weeklySMDData && dataType === 'WeeklyReports'">
      <div class="row">
        <div class="col-12">
          <b>
            <p> Verification Week Wise</p>
          </b>
          <div class="table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Verification Status</th>
                  <th scope="col">Current Week Period</th>
                  <th scope="col">Current Week Count</th>
                  <th scope="col">Previous Week Count</th>
                  <th scope="col">Previous Week Period</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of weeklySMDData?.weekVerificationData;let i=index">
                  <td>{{ i+1 }}</td>
                  <td>{{ item?.verification }}</td>
                  <td>{{ item?.currentWeekPeriod }}</td>
                  <td>{{ ( item?.currentWeekVerifcationCount ||0 ) }}</td>
                  <td>{{ item?.previousWeekPeriod }}</td>
                  <td>{{ ( item?.previousWeekVerifcationCount ||0 ) }}</td>
                  <td>{{ (item?.deviation||0 ) + " %"}}</td>
                </tr>
                <tr *ngIf="weeklySMDData?.weekVerificationData===[] || weeklySMDData?.weekVerificationData?.length===0">
                  <td colspan="5" class="center-align">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="monthlySMDData && dataType === 'MonthlyReports'">
      <div class="row">
        <div class="col-12">
          <b>
            <p> Verification Month Wise</p>
          </b>
          <div class="table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Verification Status</th>
                  <th scope="col">Current Month Period</th>

                  <th scope="col">Current Month Count</th>
                  <th scope="col">Previous Month Period</th>

                  <th scope="col">Previous Month Count</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of monthlySMDData?.monthVerificationData;let i=index">
                  <td>{{ i + 1}}</td>
                  <td>{{ item?.verification }}</td>
                  <td>{{ item?.currentMonthPeriod }}</td>
                  <td>{{ ( item?.currentMonthVerifcationCount ||0 ) }}</td>
                  <td>{{ item?.previousMonthPeriod }}</td>
                  <td>{{ ( item?.previousMonthVerifcationCount ||0 ) }}</td>
                  <td>{{ (item?.deviation||0 ) + " %"}}</td>
                </tr>
                <tr
                  *ngIf="monthlySMDData?.monthVerificationData===[] || monthlySMDData?.monthVerificationData?.length===0">
                  <td class="center-align" colspan="5">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="(dataType === 'WeeklyTrends')||(dataType=== 'MonthlyTrends')">
      <div class="row">
        <div class="col-12">
          <b class="center-align">
            <p> {{ trendsTitle}}</p>
          </b>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="dataType === 'MonthlyReports' || dataType === 'WeeklyReports'">
      <div class="row mt-3">
        <div class="col-12">
          <b class="center-align">
            <p> Verification Summary</p>
          </b>
        </div>
      </div>
    </ng-container>
    <div *ngIf="dataType==='QuaterlyReport'">
      <div class="row">
        <div class="col-12">
          <b>
            <p> VerificationStatus Wise</p>
          </b>
          <div class="sm-table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Month</th>
                  <th scope="col">Verification Status</th>
                  <th scope="col">Total Count</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of customViewTable;let i=index;">
                  <td>{{ i+1 }}</td>
                  <td>{{ item?.month}}</td>
                  <td>{{ item?.verificationStatus }}</td>
                  <td>{{ "$ "+(item?.totalCount||0) }}</td>
                </tr>
                <tr *ngIf="customViewTable===[] || customViewTable?.length===0">
                  <td colspan="5" class="center-align">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="pageType==='MISReports'">
    <ng-container *ngIf="weeklyData && dataType === 'WeeklyReports'">
      <div class="row">
        <div class="col-6">
          <b>
            <p> Branch Wise</p>
          </b>
          <div class="table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Branch</th>
                  <th scope="col">This Week</th>
                  <th scope="col">Previous Week</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of weeklyData?.weekBranchReports;let i=index;">
                  <td>{{ i+1 }}</td>
                  <td>{{ item?.branchName }}</td>
                  <td>{{ "$ " + ( item?.currentWeekTotalBilled ||0 ) }}</td>
                  <td>{{ "$ " + ( item?.previousWeekTotalBilled ||0 ) }}</td>
                  <td>{{ (item?.deviation||0 ) + " %"}}</td>
                </tr>
                <tr *ngIf="weeklyData?.weekBranchReports===[] || weeklyData?.weekBranchReports?.length===0">
                  <td colspan="5" class="center-align">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-6">
          <b>
            <p> Item Group wise</p>
          </b>
          <div class="table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Item Group</th>
                  <th scope="col">This Week</th>
                  <th scope="col">Previous Week</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of weeklyData?.weekItemReports;let i=index">
                  <td>{{ i+1 }}</td>
                  <td>{{ item?.itemName }}</td>
                  <td>{{ "$ " + ( item?.currentWeekTotalBilled ||0 ) }}</td>
                  <td>{{ "$ " + ( item?.previousWeekTotalBilled ||0 ) }}</td>
                  <td>{{ (item?.deviation||0 ) + " %"}}</td>
                </tr>
                <tr *ngIf="weeklyData?.weekItemReports===[] || weeklyData?.weekItemReports?.length===0">
                  <td colspan="5" class="center-align">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="monthlyData && dataType === 'MonthlyReports'">
      <div class="row">
        <div class="col-6">
          <b>
            <p> Branch Wise</p>
          </b>
          <div class="table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Branch</th>
                  <th scope="col">This Month</th>
                  <th scope="col">Previous Month</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of monthlyData?.monthBranchReports;let i=index">
                  <td>{{ i + 1}}</td>
                  <td>{{ item?.branchName }}</td>
                  <td>{{ "$ " + ( item?.currentMonthTotalBilled ||0 ) }}</td>
                  <td>{{ "$ " + ( item?.previousMonthTotalBilled ||0 ) }}</td>
                  <td>{{ (item?.deviation||0 ) + " %"}}</td>
                </tr>
                <tr *ngIf="monthlyData?.monthBranchReports===[] || monthlyData?.monthBranchReports?.length===0">
                  <td class="center-align" colspan="5">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-6">
          <b>
            <p> Item Group Wise</p>
          </b>
          <div class="table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Item Group</th>
                  <th scope="col">This Month</th>
                  <th scope="col">Previous Month</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of monthlyData?.monthItemReports;let i=index">
                  <td>{{ i+1 }}</td>
                  <td>{{ item?.itemName }}</td>
                  <td>{{ "$ " + ( item?.currentMonthTotalBilled ||0 ) }}</td>
                  <td>{{ "$ " + ( item?.previousMonthTotalBilled ||0 ) }}</td>
                  <td>{{ (item?.deviation||0 ) + " %"}}</td>
                </tr>
                <tr *ngIf="monthlyData?.monthItemReports===[] || monthlyData?.monthItemReports?.length===0">
                  <td class="center-align" colspan="5">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(dataType === 'WeeklyTrends')||(dataType=== 'MonthlyTrends')">
      <div class="row">
        <div class="col-12">
          <b class="center-align">
            <p> {{ trendsTitle}}</p>
          </b>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="dataType === 'MonthlyReports' || dataType === 'WeeklyReports'">
      <div class="row mt-3">
        <div class="col-12">
          <b class="center-align">
            <p> Verification Summary</p>
          </b>
        </div>
      </div>
    </ng-container>
    <div *ngIf="dataType==='QuaterlyReport'">
      <div class="row">
        <div class="col-12">
          <b>
            <p> Verification Wise</p>
          </b>
          <div class="sm-table-container">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">S.No</th> -->
                  <th scope="col">Month</th>
                  <th scope="col">Branch Name</th>
                  <th scope="col">Total Billed</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of customViewTable;let i=index;">
                  <td>{{ i+1 }}</td>
                  <td>{{ item?.month}}</td>
                  <td>{{ item?.branchName }}</td>
                  <td>{{ "$ "+(item?.totalBilled||0) }}</td>
                </tr>
                <tr *ngIf="customViewTable===[] || customViewTable?.length===0">
                  <td colspan="5" class="center-align">No Records Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="chartOptions && chartOptions?.series" id="chart" class="mt-4">
    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke" [tooltip]="chartOptions.tooltip" [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend" [markers]="chartOptions.markers" [grid]="chartOptions.grid"
      [yaxis]="chartOptions.yaxis" [title]="chartOptions.title"></apx-chart>
  </div>

  <div *ngIf="!(chartOptions && chartOptions?.series ) || customViewTable">
    <div class="row">
      <!-- <div class="col-12">
        <b>
          <p> Branch Wise</p>
        </b>
        <div class="sm-table-container">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Month</th>
                <th scope="col">Branch Name</th>
                <th scope="col">Total Billed</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of customViewTable;let i=index;">
                <td>{{ i+1 }}</td>
                <td>{{ item?.month}}</td>
                <td>{{ item?.branchName }}</td>
                <td>{{ "$ "+(item?.totalBilled||0) }}</td>
              </tr>
              <tr *ngIf="customViewTable===[] || customViewTable?.length===0">
                <td colspan="5" class="center-align">No Records Found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonColor" (click)="sendReportsToMailUsingPopUp(pageType)" mat-button>Send Email</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>