import { mapEnumToOptions } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import Swal from 'sweetalert2';
import { CreateUpdateRentalSaleOrderTemplateDTO } from '../order-proxy/order-management/order-optimization/dto';
import { RentalSaleOrderTemplateService } from '../order-proxy/order-management/order-optimization/rental-sale-order-template.service';
import { RentalTypes } from '../order-proxy/order-management/order-optimization/rental-types.enum';
import { ToastrService } from "ngx-toastr";
import { RentalOrderJobStatus } from '../order-proxy/order-management/order-optimization/rental-order-job-status.enum';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-schedule-config',
  templateUrl: './schedule-config.component.html',
  styleUrls: ['./schedule-config.component.scss']
})
export class ScheduleConfigComponent implements OnInit {
  drpScheduleType: any[] = ["Daily", "Once", "Monthly"];
  templateCode: string = "";
  clearScheduleType() {
    this.scheduleForm.patchValue({
      drpMonths: []
    });
  };
  @Input() isTemplate: boolean = false;

  strScheduleType: string = "";
  scheduleForm: FormGroup;
  scheduledMinDate: Date;
  drpMonthsList: { key: string, value: string }[] = []
  arrMonths: [] = [];
  saveButtonHide = false;
  isShowSpinner = false;
  @Input() orderId: string = defaultGuid;
  @Input() templateId: string = defaultGuid;

  constructor(private fb: FormBuilder, private rentalSaleOrderTemplateService: RentalSaleOrderTemplateService,private toastr: ToastrService,) { }

  ngOnInit(): void {

    this.scheduledMinDate = new Date();
    this.scheduledMinDate.setDate(this.scheduledMinDate.getDate() + 1);
    this.scheduleForm = this.fb.group({
      drpScheduleType: new FormControl("", Validators.required),
      chkDisabled: new FormControl(""),
      txtEndPeriod: new FormControl("", Validators.required),
      txtScheduledDate: new FormControl("", Validators.required),
      txtScheduledDays: new FormControl("",),
      drpMonths: new FormControl([]),
      txtScheduleSummary: new FormControl(""),
      txtLastDate: new FormControl(""),
      txtNextDate: new FormControl(""),
    });
    this.drpMonthsList = monthTypeOptions?.map(x => { let y = { key: x?.key.toString(), value: x?.value.toString() }; return y });

    this.scheduleForm.get("drpScheduleType").valueChanges.subscribe(x => {
      this.resetScheduleForm();
      if (x === 'Once') {
        this.scheduleForm.patchValue({
          txtEndPeriod: "1"
        });
      }
      if (x !== 'Once') {
        this.scheduleForm.patchValue({
          txtEndPeriod: "999"
        });
      }
      this.scheduleForm?.get("txtScheduledDate")?.setValidators([Validators.required]);
      this.scheduleForm?.get("txtScheduledDate")?.updateValueAndValidity();
      this.scheduleForm?.get("txtScheduledDays")?.setValidators([Validators.nullValidator]);
      this.scheduleForm?.get("txtScheduledDays")?.updateValueAndValidity();
      this.scheduleForm?.get("drpMonths")?.setValidators([Validators.nullValidator]);
      this.scheduleForm?.get("drpMonths")?.updateValueAndValidity();
      if (x === "Monthly") {
        this.scheduleForm.patchValue({
          txtEndPeriod: "13"
        });
        this.scheduleForm?.get("txtScheduledDate")?.setValidators([Validators.nullValidator]);
        this.scheduleForm?.get("txtScheduledDate")?.updateValueAndValidity();
        this.scheduleForm?.get("drpMonths")?.setValidators([Validators.required]);
        this.scheduleForm?.get("drpMonths")?.updateValueAndValidity();
        this.scheduleForm?.get("txtScheduledDays")?.setValidators([Validators.required]);
        this.scheduleForm?.get("txtScheduledDays")?.updateValueAndValidity();
      }
    })


    this.scheduleForm.get("txtScheduledDate")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })
    this.scheduleForm.get("txtEndPeriod")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })

    this.scheduleForm.get("txtScheduledDays")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })
    this.scheduleForm.get("drpMonths")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })
    this.templateId !== defaultGuid && this.getScheduleDeatilsById();


  }
  //reset Schedule Form
  resetScheduleForm() {
    this.scheduleForm.patchValue({
      chkDisabled: "",
      txtEndPeriod: "",
      txtScheduledDate: "",
      txtScheduledDays: "",
      drpMonths: [],
      txtScheduleSummary: "",
      txtLastDate: "",
      txtNextDate: "",
    })
  }
  // get Schedule Deatils By Id
  getScheduleDeatilsById() {
    this.rentalSaleOrderTemplateService.get(this.templateId)?.subscribe(response => {
      let rentalTypes = response?.rentalTypes === RentalTypes.daily ? "Daily" : response?.rentalTypes === RentalTypes.monthly ? "Monthly" : response?.rentalTypes === RentalTypes.once ? "Once" : "Weekly";
      this.templateCode = response?.templateCode ?? "";
      const days = new Date(response?.startDate).getDate();
      const months = response?.lstMonths?.map(x => this.drpMonthsList?.filter(y => +y?.value === x)[0]) ?? [];
      this.scheduleForm.patchValue({
        drpScheduleType: rentalTypes,
        chkDisabled: response?.isEnabled === 0,
        txtEndPeriod: response?.endPeriod,
        txtScheduledDate: response?.startDate,
        txtScheduledDays: days,
        drpMonths: months,
        txtScheduleSummary: response?.templateSummary,
        txtLastDate: response?.lastRun,
        txtNextDate: response?.nextRun,
      })
    })
  }
  // calucate Days on form chnages
  calucateDays() {
    const drpScheduleType = this.scheduleForm.value.drpScheduleType;
    const txtScheduledDate = this.scheduleForm.get("txtScheduledDate").value ?? null;
    const txtEndPeriod = Number(+this.scheduleForm.get("txtEndPeriod").value);
    const txtScheduledDays = this.scheduleForm.get("txtScheduledDays").value;
    const drpMonths = this.scheduleForm.get("drpMonths")?.value;

    if (drpScheduleType === 'Monthly') {
      if (txtScheduledDays && drpMonths) {
        let lstMonths: string[] = drpMonths?.map((x: { key: string }) => ((x?.key))) ?? [];
        lstMonths = lstMonths?.filter(x => x !== undefined);
        this.scheduleForm.patchValue({
          txtScheduleSummary: this.getScheduleMonthSummary(this.getDate(txtScheduledDays).toLocaleDateString(), lstMonths)
        })
      }
    }
    if (drpScheduleType === 'Once') {
      if (txtScheduledDate && txtEndPeriod) {
        this.scheduleForm.patchValue({
          txtScheduleSummary: this.getScheduleSummary(new Date(txtScheduledDate).toLocaleDateString(), getDateXDaysAgo(new Date(txtScheduledDate), txtEndPeriod).toLocaleDateString()),
        })
      }
    }
    if (drpScheduleType === 'Daily') {
      if (txtScheduledDate && txtEndPeriod) {
        this.scheduleForm.patchValue({
          txtScheduleSummary: this.getScheduleSummary(new Date(txtScheduledDate).toLocaleDateString(), getDateXDaysAgo(new Date(txtScheduledDate), txtEndPeriod).toLocaleDateString()),
        })
      }
    }
  }
// get Schedule Summary

  getScheduleSummary(value: string, value2: string) {
    return `Starting on ${value} - Ending on ${value2}`;

  }
// get Schedule Month Summary
  getScheduleMonthSummary(value: string, value2: string[] = []) {
    return `Starting on ${value}  ${value2?.length !== 0 ? ' every ' + value2?.join(",") : ""}`;
  }

// get Custom Data formatted

  getDate(daysInNumber: number) {
    return new Date(` ${new Date().getMonth() + 1} / ${daysInNumber} / ${new Date().getFullYear()}`)
  }

  //save Or Update Schedule Details
  saveOrUpdateSchedule() {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    const schFormValue = this.scheduleForm.value;
    let rentalTypes = <RentalTypes>String(schFormValue?.drpScheduleType).toLowerCase() ?? RentalTypes.daily;
    const lstMonths: number[] = schFormValue?.drpMonths?.map((x: { value: string }) => ((+x?.value))) ?? [];
    const input: CreateUpdateRentalSaleOrderTemplateDTO = {
      rentalTypes: rentalTypes,
      templateCode: this.templateCode,
      saleOrderId: this.orderId,
      startDate: rentalTypes === RentalTypes.monthly ? this.getDate(+schFormValue?.txtScheduledDays ?? 0) : schFormValue?.txtScheduledDate,
      nextRun: schFormValue?.txtNextDate,
      templateSummary: schFormValue?.txtScheduleSummary,
      endPeriod: +schFormValue?.txtEndPeriod,
      periodsRun: 0,
      isEnabled: schFormValue?.chkDisabled ? 0 : 1,
      monthDay: rentalTypes === RentalTypes.monthly ? +schFormValue?.txtScheduledDays : 0,
      lstMonths: rentalTypes === RentalTypes.monthly ? (lstMonths ?? []) : [],
      weeknumber: 0,
      yearNumber: 0,
      lstWeeks: [0],
      lstYears: [''],
      status: RentalOrderJobStatus.Active,
      statusType: null

    }
    if (this.templateId !== defaultGuid) {
      this.rentalSaleOrderTemplateService.update(this.templateId, input).subscribe(response => {
        // Swal.fire({
        //   title: 'Success',
        //   html: 'Updated Successfully',
        //   icon: 'success',
        //   timer: 3000,
        //   timerProgressBar: true,
        // });
        this.toastr.success('Updated Successfully','Success')
        this.templateId=response?.id;
        this.saveButtonHide = false;
        this.isShowSpinner = false;
      },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const errData: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: errData?.error?.error?.message,
          });
        }
      );
    } else {
      this.rentalSaleOrderTemplateService.create(input).subscribe(response => {
        // Swal.fire({
        //   title: 'Success',
        //   html: 'Saved Successfully',
        //   icon: 'success',
        //   timer: 3000,
        //   timerProgressBar: true,
        // });
        this.toastr.success('Saved Successfully','Success')
        this.templateId=response?.id;

        this.saveButtonHide = false;
        this.isShowSpinner = false;
      },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const errData: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: errData?.error?.error?.message,
          });
        }
      );
    }

  }
}

enum MONTHS { JANUARY = 1, FEBRUARY =2, MARCH = 3, APRIL = 4, MAY = 5, JUNE = 6, JULY = 7, AUGUST = 8, SEPTEMBER = 9, OCTOBER = 10, NOVEMBER = 11, DECEMBER = 12 }
export const monthTypeOptions = mapEnumToOptions(MONTHS);
function getDateXDaysAgo(startDate: Date, dateRange: number) {
  startDate.setDate(startDate.getDate() + (dateRange));
  return startDate;
}
