<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2 class="customThemeClass"><b>Exchange/Pickup Details</b></h2>
    </div>
  </div>
  <div class="modal-body">
    <!--New Patient-->
    <div>
      <form [formGroup]="ExchangeForm">
        <div class="row">
          <div class="row col-lg-12">
            <mat-form-field class="col-lg-12">
              <mat-label>
                Return/Exchange Initiated Date
                <span class="asterisk">*</span></mat-label
              >
              <input
                [max]="maxDate"
                matInput
                maxlength="40"
                formControlName="txtReturnDate"
                type="text"
                placeholder="(MM/DD/YYYY)"
                autocomplete="new-txtReturnDate"
                matInput
                [matDatepicker]="txtReturnDate"
                [min]="minDate"
              />
              <mat-datepicker-toggle matSuffix [for]="txtReturnDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtReturnDate> </mat-datepicker>
              <mat-error
                *ngIf="
                  ExchangeForm?.get('txtReturnDate')?.touched &&
                  ExchangeForm?.get('txtReturnDate')?.errors &&
                  !(ExchangeForm <= ExchangeForm?.get('txtReturnDate')?.value)
                "
              >
                Enter Valid date
              </mat-error>
              <mat-error
                *ngIf="
                  ExchangeForm?.get('txtReturnDate')?.touched &&
                  ExchangeForm?.get('txtReturnDate')?.errors?.required
                "
              >
                Pickup/Exchange Date is a required field!
              </mat-error>
              <mat-error
                *ngIf="
                  ExchangeForm <= ExchangeForm?.get('txtReturnDate')?.value &&
                  !(
                    ExchangeForm?.get('txtReturnDate')?.touched &&
                    ExchangeForm?.get('txtReturnDate')?.errors?.required
                  )
                "
              >
                Enter Past Dates Only!
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row col-lg-12">
            <!--Comments-->
            <mat-form-field class="col-lg-12">
              <mat-label> Comments <span class="asterisk">*</span></mat-label>
              <textarea
                matInput
                rows="4"
                autocomplete="off"
                maxlength="500"
                formControlName="txtComments"
                type="text"
              ></textarea>
              <mat-error
                class="spanAlignment"
                *ngIf="
                  ExchangeForm?.get('txtComments')?.touched &&
                  ExchangeForm?.get('txtComments')?.errors?.required
                "
              >
                Comments is a required field!
              </mat-error>
              <mat-error
                class="spanAlignment"
                *ngIf="ExchangeForm?.get('txtComments')?.hasError('whitespace')"
              >
                Comments is a required field!
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonColor" mat-button 
  [disabled]="!(!ExchangeForm?.invalid && ExchangeForm?.dirty)"
  
  
  (click)="initializeExchangeSave()">
    Save
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>

  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
