import type { AssignUserDTO, BatchDocumentUploadsDTO, BatchFileUploadsDTO, CreateUpdateBatchDocumentUploadsDTO, CreateUpdateDocumentUploadsDTO, DocumentUploadsDTO, DocumentUploadsWithBatchIdDTO, UpdateSortedDataDTO } from './dto/models';
import type { Index } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IFormFile } from '../../../microsoft/asp-net-core/http/models';
import type { PatientDTO } from '../../patient-management/patient/dto/models';

@Injectable({
  providedIn: 'root',
})
export class DocumentUploadService {
  apiName = 'platformManagement';

  batchDocumentUploadByBatchFileUploadsInput = (batchFileUploadsInput: CreateUpdateBatchDocumentUploadsDTO) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'POST',
      url: '/api/PlatformApp/document-upload/batch-document-upload',
      body: batchFileUploadsInput,
    },
     { apiName: this.apiName, skipHandleError: true });

  checkBatchJobStatus = () =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'POST',
      url: '/api/PlatformApp/document-upload/check-batch-job-status',
    },
     { apiName: this.apiName, skipHandleError: true });

  checkPatientCurrentStatusByPatientIdAndTenantId = (patientId: string, tenantId: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/PlatformApp/document-upload/check-patient-current-status',
      params: { patientId, tenantId },
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteBatchByBatchId = (batchId: string) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: `/api/PlatformApp/document-upload/delete-batch/${batchId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteFileByBatchIdAndFileId = (batchId: string, fileId: string) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'DELETE',
      url: '/api/PlatformApp/document-upload/delete-file',
      params: { batchId, fileId },
    },
     { apiName: this.apiName, skipHandleError: true });

  documentUploadForExtraction = () =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'POST',
      url: '/api/PlatformApp/document-upload/document-upload-for-extraction',
    },
     { apiName: this.apiName, skipHandleError: true });

  documentUploadForSorting = () =>
    this.restService.request<any, BatchFileUploadsDTO>({
      method: 'POST',
      url: '/api/PlatformApp/document-upload/document-upload-for-sorting',
    },
     { apiName: this.apiName, skipHandleError: true });

  generateChartId = () =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/PlatformApp/document-upload/generate-chart-id',
    },
     { apiName: this.apiName, skipHandleError: true });

  getAssignnedFilesByUserId = (userId: string) =>
    this.restService.request<any, DocumentUploadsWithBatchIdDTO[]>({
      method: 'GET',
      url: `/api/PlatformApp/document-upload/get-assignned-files/${userId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getBatchDocumentByIdByBatchId = (batchId: string) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'GET',
      url: `/api/PlatformApp/document-upload/get-batch-document-by-id/${batchId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getBatchDocumentList = () =>
    this.restService.request<any, BatchDocumentUploadsDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/document-upload/get-batch-document-list',
    },
     { apiName: this.apiName, skipHandleError: true });

  getBearerToken = () =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/PlatformApp/document-upload/get-bearer-token',
    },
     { apiName: this.apiName, skipHandleError: true });

  getFileAsByte64StringByInputFile = (inputFile: IFormFile) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/PlatformApp/document-upload/get-file-as-byte64String',
    },
     { apiName: this.apiName, skipHandleError: true });

  getRejectedDocumentList = () =>
    this.restService.request<any, DocumentUploadsWithBatchIdDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/document-upload/get-rejected-document-list',
    },
     { apiName: this.apiName, skipHandleError: true });

  searchBatchBySBatchCodeAndSTypeAndSUploadFromAndDtCreatedDateAndSUploadByAndIFileCountAndSBatchStatus = (sBatchCode: string, sType: string, sUploadFrom: string, dtCreatedDate: string, sUploadBy: string, iFileCount: number, sBatchStatus: string) =>
    this.restService.request<any, BatchDocumentUploadsDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/document-upload/search-batch',
      params: { sBatchCode, sType, sUploadFrom, dtCreatedDate, sUploadBy, iFileCount, sBatchStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  searchFilesInBatchByBatchIdAndSDefaultBatchIdAndSFileNameAndSAssignedToAndSAssignedByAndDtAssignedTimeAndSStatus = (batchId: string, sDefaultBatchId: string, sFileName: string, sAssignedTo: string, sAssignedBy: string, dtAssignedTime: string, sStatus: string) =>
    this.restService.request<any, DocumentUploadsWithBatchIdDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/document-upload/search-files-in-batch',
      params: { batchId, sDefaultBatchId, sFileName, sAssignedTo, sAssignedBy, dtAssignedTime, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateApprovedPatientIdByBatchIdAndFileIdAndPatientId = (batchId: string, fileId: string, patientId: string) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/document-upload/update-approved-patient-id',
      params: { batchId, fileId, patientId },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateArchiveFileBatchStatusByBatchIdAndArchived = (batchId: string, archived: boolean = true) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/document-upload/update-archive-file-batch-status/${batchId}`,
      params: { archived },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateAssignedUserInBatchByLstAssignUsers = (lstAssignUsers: AssignUserDTO[]) =>
    this.restService.request<any, DocumentUploadsDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/document-upload/update-assigned-user-in-batch',
      body: lstAssignUsers,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateBatchFileStatusByBatchId = (batchId: string) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/document-upload/update-batch-file-status/${batchId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateDocumentByBatchIdAndUpdateDocumentInput = (batchId: string, updateDocumentInput: CreateUpdateDocumentUploadsDTO) =>
    this.restService.request<any, DocumentUploadsDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/document-upload/update-document/${batchId}`,
      body: updateDocumentInput,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateDocumentIndexingByBatchIdAndFileIdAndIndexInput = (batchId: string, fileId: string, indexInput: Index) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/document-upload/update-document-indexing',
      params: { batchId, fileId },
      body: indexInput,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateExtractedDataOnBatch = () =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/document-upload/update-extracted-data-on-batch',
    },
     { apiName: this.apiName, skipHandleError: true });

  updateExtractedDetailsOnFileByBatchIdAndFileIdAndExtractedDataInput = (batchId: string, fileId: string, extractedDataInput: PatientDTO) =>
    this.restService.request<any, DocumentUploadsWithBatchIdDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/document-upload/update-extracted-details-on-file',
      params: { batchId, fileId },
      body: extractedDataInput,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateOverallStatusByTenantIdAndBatchIdAndFileIdAndSStatus = (tenantId: string, batchId: string, fileId: string, sStatus: string) =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/document-upload/update-overall-status',
      params: { tenantId, batchId, fileId, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateSortedFileOnBatchByBatchIdAndFileUploadsInput = (batchId: string, fileUploadsInput: UpdateSortedDataDTO) =>
    this.restService.request<any, DocumentUploadsDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/document-upload/update-sorted-file-on-batch/${batchId}`,
      body: fileUploadsInput,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateStatusOnFileByBatchIdAndFileIdAndSReasonForRejectedAndSStatus = (batchId: string, fileId: string, sReasonForRejected: string, sStatus: string = "Rejected") =>
    this.restService.request<any, BatchDocumentUploadsDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/document-upload/update-status-on-file',
      params: { batchId, fileId, sReasonForRejected, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
