<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="font-weight-bolder">
                    <b class="headercolr">List / Practice Management / Report</b>
                </h4>
                <div class="page-title-box page-title-right pt-3 pb-0">
                    <ol class="breadcrumb m-0">
                        <!-- <app-toggle-switch [onText]="'Active'" [offText]="'Inactive'" class="mr-3 mt-3"
                            (changed)="change($event)">
                        </app-toggle-switch> -->
                        <form [formGroup]="reportForm">
                            <!-- Date Picker -->
                            <mat-form-field class="pr-2 mr-2">
                                <input placeholder="Month" formControlName="txtDateValue" matInput [(value)]="dateValue"
                                    readonly (focus)="openDatePicker()" />
                                <input matInput maxlength="40" formControlName="txtDate" type="text" [hidden]="true"
                                    autocomplete="new-txtDateValue" matInput [(value)]="dateValue"
                                    [matDatepicker]="txtDateValue" [max]="maxDate" />
                                <mat-datepicker-toggle matSuffix [for]="txtDateValue"></mat-datepicker-toggle>
                                <mat-datepicker #txtDateValue startView="multi-year"
                                    (monthSelected)="chosenMonthHandler($event, txtDateValue)"></mat-datepicker>
                            </mat-form-field>
                        </form>
                        <!-- <li>
                            <button matTooltip="Export" mat-button class="mr-2  mt-3 buttonColor font-size-10">
                                Export
                            </button>
                        </li> -->
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <!-- Tab View -->
            <div class="card elevated-div">
                <ul class="card-title" ngbNav #nav="ngbNav" justify="justified" class="alert-success nav nav-pills"
                    style="border-bottom: 1px solid rgb(201, 201, 201)">

                    <!--TAT-->
                    <li ngbNavItem="TAT" [class.selected-tab]="nav.activeId === 'TAT'">
                        <a ngbNavLink id="tab-2" (click)="changeTab('TAT')"><b class="custom-tab-header">TAT</b></a>
                    </li>

                    <!--Physicians-->
                    <li ngbNavItem="Physicians" [class.selected-tab]="nav.activeId === 'Physicians'">
                        <a ngbNavLink id="tab-1" (click)="changeTab('Physicians')"><b
                                class="custom-tab-header">Physicians</b></a>
                    </li>

                    <!--Patient Details Info-->
                    <li ngbNavItem="PatientDetails" [class.selected-tab]="nav.activeId === 'PatientDetails'">
                        <a ngbNavLink id="tab-3" (click)="changeTab('PatientDetails')"><b
                                class="custom-tab-header">Patient Details</b></a>
                    </li>
                </ul>

                <!-- TAT Tab -->
                <div class="card card-body" *ngIf="nav.activeId === 'TAT'">
                    <div class="row" *ngIf="isLoading">
                        <div class="col-12">
                            <div class="d-flex justify-content-center" *ngIf="isLoading">
                                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1">
                            <div *ngIf="!isLoading" class="table table-responsive">
                                <app-tat-practice-management
                                    [tableData]="inboundTableData"></app-tat-practice-management>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Physicians Tab-->
                <div class="card card-body" *ngIf="nav.activeId === 'Physicians'">
                    <div class="row" *ngIf="isLoading">
                        <div class="col-12">
                            <div class="d-flex justify-content-center" *ngIf="isLoading">
                                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1">
                            <div *ngIf="!isLoading" class="table table-responsive">
                                <app-physician-practice-management
                                    [tableData]="physicianTableData"></app-physician-practice-management>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Patient Details Tab -->
                <div class="card card-body" *ngIf="nav.activeId === 'PatientDetails'">
                    <div class="row" *ngIf="isLoading">
                        <div class="col-12">
                            <div class="d-flex justify-content-center" *ngIf="isLoading">
                                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1">
                            <div *ngIf="!isLoading" class="table table-responsive">
                                <app-patientdetails-practice-management
                                    [tableData]="patientTableData" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear"></app-patientdetails-practice-management>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    </div>
</div>