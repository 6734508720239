<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-6">
            <h2>Add Doctor</h2>
        </div>
    </div>
    <div class="card card-body mt-3 mr-2">
        <div class="row">
            <div class="col-lg-12">
                <form [formGroup]="prtzManagForm">
                    <mat-card-content>
                        <div class="row">
                            <div class="col-10">
                                <h5 class="card-title npiColor font-size-16">NPI Doctor Search</h5>
                            </div>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-4">
                                <mat-label>NPI Number</mat-label>
                                <input autocomplete="new-txtNpiNumber" maxlength="13" mask="000-000-0000" [(ngModel)]="txtNpiNumber"
                                    formControlName="txtNpiNumber" matInput type="text" />
                                <mat-error *ngIf="
                  prtzManagForm?.get('txtNpiNumber')?.touched &&
                  prtzManagForm?.get('txtNpiNumber')?.errors?.number
                ">
                                    Enter only Numbers!
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-4">
                                <mat-label>First Name</mat-label>
                                <input autocomplete="new-txtFirstName" matInput type="text" minlength="2" maxlength="40" [(ngModel)]="txtFirstName"
                                    formControlName="txtFirstName" />
                                <mat-error *ngIf="
                  prtzManagForm?.get('txtFirstName')?.touched &&
                  prtzManagForm?.get('txtFirstName')?.errors?.sAlphabets
                ">
                                    Enter only Alphabets!
                                </mat-error>
                                <mat-error *ngIf="
              prtzManagForm?.get('txtFirstName')?.touched &&
              prtzManagForm?.get('txtFirstName')?.errors?.minlength">
                                    Enter Minimum 2 Letters!
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-4">
                                <mat-label>Last Name</mat-label>
                                <input autocomplete="new-txtLastName" matInput type="text" maxlength="40" [(ngModel)]="txtLastName"
                                    formControlName="txtLastName" />
                                <mat-error *ngIf="
                  prtzManagForm?.get('txtLastName')?.touched &&
                  prtzManagForm?.get('txtLastName')?.errors?.sAlphabets
                ">
                                    Enter only Alphabets!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <br />
                                <button mat-button (click)="SearchNpiDoctorsAbp()" [disabled]="     
                            (!(!prtzManagForm.invalid && (prtzManagForm.dirty ))) ||
                            (!txtFirstName &&
                              !txtLastName &&
                              !txtNpiNumber &&
                              !txtPosatlCode &&
                              !txtState &&
                              !txtCity)
                            " class="buttonColor mb-2 mr-2">
                                    Search
                                </button>
                                <button (click)="ResetNpiDoctors()" mat-button class="buttonResetColor mb-2 mr-2">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </form>
            </div>
        </div>
        <br />
        <div *ngIf='isShown' class="row">
            <div class="col-lg-12">
                <!-- <div [hidden]="!isLoading"
                    style="display: flex; justify-content: center; align-items: center; background: transparent;">
                    <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                    </mat-progress-bar>
                </div> -->
                <div [hidden]="isLoading" class=" table table-responsive">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="NPI Number">
                            <th mat-header-cell *matHeaderCellDef>NPI Number</th>
                            <td class="text-left" mat-cell *matCellDef="let element">{{ element.NpiNumber }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Doctor Name">
                            <th mat-header-cell *matHeaderCellDef>Doctor Name</th>
                            <td class="text-left" mat-cell *matCellDef="let element">{{ element.FullName }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Address">
                            <th mat-header-cell *matHeaderCellDef>Address</th>
                            <td class="text-left" mat-cell *matCellDef="let element">{{ element.Address1 }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Country">
                            <th mat-header-cell *matHeaderCellDef>Country</th>
                            <td class="text-left" mat-cell *matCellDef="let element">{{ element.Country }}</td>
                        </ng-container>
                        <ng-container matColumnDef="State">
                            <th mat-header-cell *matHeaderCellDef>State</th>
                            <td class="text-left" mat-cell *matCellDef="let element">{{ element.State }}</td>
                        </ng-container>
                        <ng-container matColumnDef="City">
                            <th mat-header-cell *matHeaderCellDef>City</th>
                            <td class="text-left" mat-cell *matCellDef="let element">{{ element.City }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Postal Code">
                            <th mat-header-cell *matHeaderCellDef>Postal Code</th>
                            <td class="text-left" mat-cell *matCellDef="let element">{{ element.PostalCode }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="element.isExists; then content; else other_content">here is ignored</div>
                                <ng-template #content>Already Exists</ng-template>
                                <ng-template #other_content><a target="_blank" (click)="saveNpi(element)">
                                        <i class="fa fa-plus pointer mr-5"></i>
                                    </a>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="noRecords">
                            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                                No Records Found
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="paginator">
                            <td mat-footer-cell *matFooterCellDef colspan="9">
                                <mat-paginator class="table table-responsive" #MatPaginator
                                    [length]="dataSource?.filteredData?.length" [pageSize]="10" [pageIndex]="0"
                                    showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                                </mat-paginator>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        <tr mat-footer-row *matFooterRowDef="['noRecords']"
                            [ngClass]="{ active: dataSource && dataSource.filteredData && dataSource.filteredData.length !== 0 }">
                        </tr>
                        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>