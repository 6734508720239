<div class="container-fluid">
 <div class="container-fluid main-content">
   <div class="row bg">
     <div class="col-12">
       <div class="d-flex align-items-center justify-content-between mb-3">
         <h3 class="font-weight-bolder mb-0"><b>Orders / Billing List</b></h3>
         <div class="page-title-box page-title-right">
           <ol class="breadcrumbs m-0 align-items-center">
 
             <form [formGroup]="branchForm">
               <mat-form-field class="pr-3">
                 <mat-label>Branch</mat-label>
                 <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                   <mat-option>
                     <ngx-mat-select-search
                       placeholderLabel="Search"
                       noEntriesFoundLabel="No Matches found"
                       formControlName="txtBranch"
                     ></ngx-mat-select-search>
                   </mat-option>
   
                   <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>
   
                   <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                     {{ branch.organizationUnitName }}
                   </mat-option>
                 </mat-select>
               </mat-form-field>
               <button
                 mat-button
                 class="mr-2 buttonColor"
                 [disabled]="
                   dataSource && dataSource?.data && dataSource?.data?.length === 0
                 "
                 (click)="exportTableData()"
               >
                 Export
               </button>
             </form>
             
             <!-- <li class="breadcrumb-item">Orders</li>
             <li class="breadcrumb-item ">Billing List</li> -->
           </ol>
         </div>
       </div>
     </div>
   </div>
 </div>

 <div class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0" style="margin-top: -20px">
   <table mat-table class="pt-0 mt-0" style="margin-left: 15px !important;" [dataSource]="dataSource" matSort>
     <form  [formGroup]="orderForm">
       <ng-container matColumnDef="patientId">
         <th class="header" *matHeaderCellDef mat-header-cell mat-sort-header>
           <mat-form-field class="filter">
             <mat-placeholder class="center">Patient Id</mat-placeholder>
             <input
               autocomplete="new-patientId"
               matInput
               formControlName="patientId"
             />
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
       </ng-container>
 
       <ng-container matColumnDef="PatientName">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input
               autocomplete="new-patientName"
               matInput
               formControlName="patientName"
             />
             <mat-placeholder class="center">Patient Name</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.PatientName }}</td>
       </ng-container>
       <ng-container matColumnDef="branch">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input autocomplete="new-branch" matInput formControlName="branch">
             <mat-placeholder class="center">Branch</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element"> - </td>
       </ng-container>
       <ng-container matColumnDef="saleOrderId">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input
               autocomplete="new-saleorderId"
               matInput
               formControlName="saleorderId"
             />
             <mat-placeholder class="center">Saleorder Id</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.saleOrderId }}</td>
       </ng-container>
 
       <ng-container matColumnDef="plan">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input autocomplete="new-Plan" matInput formControlName="Plan" />
             <mat-placeholder>Policy No</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.plan }}</td>
       </ng-container>
 
       <ng-container matColumnDef="VerifyName">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input
               autocomplete="new-VerifyName"
               matInput
               formControlName="VerifyName"
             />
             <mat-placeholder class="center"> Insurance Name</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.VerifyName }}</td>
       </ng-container>
 
       <ng-container matColumnDef="payment">
         <th class="header" mat-header-cell *matHeaderCellDef>Cash Payment</th>
         <td mat-cell *matCellDef="let element">
           <input
             disabled
             style="width: 60px; height: 20px"
             type="checkbox"
             [checked]="element?.cashPayment"
           />
         </td>
       </ng-container>
 
       <ng-container matColumnDef="supplyDate">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <mat-label>Supplied Date</mat-label>
             <input
               autocomplete="new-supplyDate"
               matInput
               formControlName="supplyDate"
               placeholder="(MM/DD/YYYY)"
             />
             <mat-error
               *ngIf="
                 orderForm?.get('supplyDate')?.touched &&
                 orderForm?.get('supplyDate')?.errors?.dateVaidator
               "
             >
               Enter Valid date
             </mat-error>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.supplyDate }}</td>
       </ng-container>
       <ng-container matColumnDef="email">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input autocomplete="new-email" matInput formControlName="email" />
             <mat-placeholder>Email</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.email }}</td>
       </ng-container>
 
       <ng-container matColumnDef="shippingLocation">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input
               autocomplete="new-shippingLocation"
               matInput
               formControlName="shippingLocation"
             />
             <mat-placeholder>Shipping Location</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.location }}</td>
       </ng-container>
 
       <ng-container matColumnDef="createDate">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <mat-label>Order Created Date</mat-label>
             <input
               autocomplete="new-createDate"
               matInput
               formControlName="createDate"
               placeholder="(MM/DD/YYYY)"
             />
             <mat-error
               *ngIf="
                 orderForm?.get('createDate')?.touched &&
                 orderForm?.get('createDate')?.errors?.dateVaidator
               "
             >
               Enter Valid date
             </mat-error>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">{{ element?.createDate }}</td>
       </ng-container>
 
       <ng-container matColumnDef="currStatus">
         <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
           <mat-form-field class="filter">
             <input
               autocomplete="new-status"
               matInput
               formControlName="status"
             />
             <mat-placeholder> Status</mat-placeholder>
           </mat-form-field>
         </th>
         <td mat-cell *matCellDef="let element">
           {{
             element?.voidStatus !== "Voided order"
               ? element?.currStatus
               : element?.voidStatus
           }}
         </td>
       </ng-container>
 
       <ng-container matColumnDef="option">
         <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
         <td class="align" mat-cell *matCellDef="let element">
           <a
             [hidden]="element?.billingStatus === 'Processed'"
             class="editBtnColor"
           >
             <i
               matTooltip="Click to View"
               (click)="viewClaimDetails(element)"
               class="fa fa-eye"
             ></i>
           </a>
           <!-- element?.voidStatus!=='Voided order' -->
           <a
             *ngIf="element?.voidStatus !== 'Voided order' && element?.data"
             class="editBtnColor ml-2"
           >
             <i
               matTooltip="Click to Set as Void Order"
               class="fa fa-pencil"
               (click)="setOrderStatusVoided(element?.claimId, element.data)"
             ></i>
           </a>
         </td>
       </ng-container>
 
       <ng-container matColumnDef="noRecords">
         <td
           class="text-align-center"
           mat-footer-cell
           *matFooterCellDef
           colspan="9"
         >
           <div *ngIf="!isLoading">
             {{ "No Records Found" }}
           </div>
           <div [hidden]="!isLoading">
             <mat-spinner [diameter]="220"></mat-spinner>
           </div>
         </td>
       </ng-container>
 
       <ng-container matColumnDef="paginator">
         <td mat-footer-cell *matFooterCellDef colspan="11">
           <mat-paginator
             class="table table-responsive"
             #MatPaginator
             [length]="dataSource?.data?.length"
             [pageSize]="10"
             [pageIndex]="0"
             showFirstLastButtons
             [pageSizeOptions]="[5, 10, 25, 100]"
           >
           </mat-paginator>
         </td>
       </ng-container>
     </form>
     <tr
       mat-footer-row
       *matFooterRowDef="['noRecords']"
       [hidden]="dataSource && dataSource?.data && dataSource?.data?.length > 0"
     ></tr>
     <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
     <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
   </table>
 </div>
</div>
