import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  Input
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { IdentityUserWithRoleDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { NoteType } from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import {
  AuthVisitsDTO,
  TypeOfPlanDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import {
  AccountGroupDTO,
  BranchOfficeDTO,
  ClaimFillingCodeDTO,
  CustomerTypeDTO,
  FacilityDTO,
  GenderDTO,
  LocationTypeDTO,
  PlaceOfServiceDTO,
  PTSecurityGroupDTO,
  TaxZoneDTO,
  ZoneDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientPersonalService } from '../patient-proxy/patient-optimization';
import {
  PatientPersonalDTO,
  PatientSummaryDTO,
} from '../patient-proxy/patient-optimization/dto';
import {
  CreateUpdatePatientIntakeDTO,
  PatientDTO,
} from '../patient-proxy/patient/dto';
import { getPatient } from '../patient-store/patient.action';
import { PatientClinicalTabComponent } from '../patient-clinical-tab/patient-clinical-tab.component';
import { PatientsTabComponent } from '../patients-tab/patients-tab.component';
// import { PatientCommonDocumentTabComponent } from 'projects/shared/src/app/components/patient-common-document-tab/patient-common-document-tab.component';
import { PatientCommonDocumentMedicareTabComponent } from 'projects/shared/src/app/components/patient-common-document-medicare-tab/patient-common-document-medicare-tab.component';
import { PatientInsuranceTabComponent } from '../patient-insurance-tab/patient-insurance-tab.component';
import { PatientOrderTabComponent } from '../patient-order-tab/patient-order-tab.component';
import { CookieService } from 'ngx-cookie-service';
import { WipAllocationModalComponent } from '../wip-allocation-modal/wip-allocation-modal.component';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import { WorkInProgressDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
// import { Input } from '@syncfusion/ej2-angular-inputs';
import { AddReminderComponent } from 'projects/admin/src/app/add-reminder/add-reminder.component';
import { defaultGuid, PatientDetailsTab } from 'projects/shared/src/app/enums/allenums.enum';
import { PatientCommonSortingTabComponent } from 'projects/shared/src/app/components/PatientCommonSortingTab/PatientCommonSortingTab.component';
@Component({
  selector: 'app-patient-create-edit-tab',
  templateUrl: './patient-create-edit-tab.component.html',
  styleUrls: ['./patient-create-edit-tab.component.scss'],
})
export class PatientCreateEditTabComponent implements OnInit, OnDestroy {
  PatientDetailsTab=PatientDetailsTab;
  activeTab: PatientDetailsTab = this.PatientDetailsTab.Personal_Contact;
  @ViewChild('nav') nav: NgbNav;
  @Input() selectedNode: any;
  @ViewChild(PatientsTabComponent) PatientsTabComponent: PatientsTabComponent;
  // @ViewChild(PatientCommonDocumentTabComponent)
  // PatientCommonDocumentTabComponent: PatientCommonDocumentTabComponent;
  @ViewChild(PatientCommonDocumentMedicareTabComponent)
  PatientCommonDocumentMedicareTabComponent: PatientCommonDocumentMedicareTabComponent;
  // @ViewChild(PatientCommonDocumentMedicareTabComponent)
  // PatientCommonDocumentMedicareTabComponent : PatientCommonDocumentMedicareTabComponent;
  @ViewChild(PatientClinicalTabComponent)
  PatientClinicalTabComponent: PatientClinicalTabComponent;
  @ViewChild(PatientInsuranceTabComponent)
  PatientInsuranceTabComponent: PatientInsuranceTabComponent;
  @ViewChild(PatientCommonNotesComponent)
  PatientCommonNotesComponent: PatientCommonNotesComponent;
  @ViewChild(PatientOrderTabComponent)
  PatientOrderTabComponent: PatientOrderTabComponent;
  @ViewChild(PatientCommonSortingTabComponent)
  PatientCommonSortingTabComponent: PatientCommonSortingTabComponent;
  strUrlPath: string = '';
  patientId: string = '';
  docId:string=defaultGuid;
  defaultGuid: string = defaultGuid;
  orderId: string = defaultGuid;
  wipId: string = defaultGuid; //To track the wip id from wip page
  defaultWipId: string = '';
  wipDetails: WorkInProgressDTO;
  isOrderViewPermission: boolean = true;
  isOrderCreatePermission: boolean = true;
  isShownUnlockButton: boolean = false;
  isPatientLocked: boolean = true;
  isShownAuthTab: boolean = true;
  isShownOrderTab: boolean = true;
  forBlink: boolean = true;
  lblDefaultPatientId: string = '';
  // @Input() lblDefaultPatientId: string = '';
  lblLastUpdatedDateTime: string | Date;
  lblPatientName: string = '';
  lblPatientGender: string = '';
  lblPatientDOB: string = '';
  lblPatientHeight: string = '';
  lblBilledAmount: string = '';
  lblReceivedAmount: string = '';
  lblPendingAmount: string = '';
  lblBillingStatus: string = '';
  lblthirtyDayPaidAmnt: string = '';
  lblsixtyDayPaidAmnt: string = '';
  lblninetyDayPaidAmnt: string = '';
  lblAboveNinetyDayPaidAmnt: string = '';
  lblPatientWeight: string = '';
  lblBranchOffice: string = '';
  lblAccountNumber: string = '';
  lblPtCurrentStatus: string = '';
  lblSsnNumber: string = '';
  drpBranchOffices: BranchOfficeDTO[] = [];
  drpGenderLoop: GenderDTO[] = [];
  strLocation: string = '';
  patientStatus: string = '';
  strBillingAddress: string = '';
  strBillingState: string = '';
  strBillingCity: string = '';
  strBillingCountry: string = '';
  strPostalCode: string = '';
  strMobile: string = '';
  strEmailAddress: string = '';
  drpAccountGroups: AccountGroupDTO[];
  drpZones: ZoneDTO[];
  patientSummary: PatientSummaryDTO;
  drpPTSecurityGroups: PTSecurityGroupDTO[];
  drpCustomerTypes: CustomerTypeDTO[];
  drpLocationTypes: LocationTypeDTO[];
  drpClaimFillingCodes: ClaimFillingCodeDTO[];
  drpPlaceOfServices: PlaceOfServiceDTO[];
  drpFacility: FacilityDTO[];
  drpTaxZone: TaxZoneDTO[];
  noteType: NoteType = NoteType.patient;
  @Output() patientIdEmitter: EventEmitter<CreateUpdatePatientIntakeDTO> =
    new EventEmitter<CreateUpdatePatientIntakeDTO>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>(null);
  @Output() savePersonalFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveClinicalFormState: EventEmitter<any> = new EventEmitter<any>();
  showswal = true;
  organizationUnitId = null;
  drpPlan: TypeOfPlanDTO[] = [];
  authorizationId: string = '00000000-0000-0000-0000-000000000000';
  tblNotesReponse: any[] = [];
  drpAuthVisit: AuthVisitsDTO[] = [];
  strLoginUserId: string = '';
  strLoginUserName: string = '';
  subscription$: Subscription[] = [];
  $subscription: any;
  lbllastModifier: string = '';
  sharedData: any;
  enableChildForm: boolean = false;
  lstUsers: IdentityUserWithRoleDto[] = [];
  pageType: NoteType = NoteType.general; //For Notes Table Popup
  isEditButton: boolean = false;
  isEditblink: boolean = false;

  active: any = 'Personal';
  isNewPatient: any;
  isButtonDisable: boolean= true;
  constructor(
    private activatedRoute: ActivatedRoute,
    public title: Title,
    public dialog: MatDialog,
    private store: Store,
    private router: Router,
    private Table: TableService,
    private snackBar: MatSnackBar,
    private patientPersonalService: PatientPersonalService,
    private cookieService: CookieService,
    private wipService: WorkInProgressService
  ) {}

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    const getLogin = this.Table.getLoginUserId().subscribe((val) => {
      this.strLoginUserId = val;
    });
    this.subscription$.push(getLogin);

    //
    const getLoginUser = this.Table.getLoginUserName().subscribe((val) => {
      this.strLoginUserName = val;
    });
    this.subscription$.push(getLoginUser);
    this.snackBar.dismiss();

    //
    const getOrderView = this.Table.getOrderView().subscribe(
      (value) => {
        this.isOrderViewPermission = value;
        if (this.isOrderViewPermission == true) {
          this.isShownOrderTab = true;
        } else {
          this.isShownOrderTab = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getOrderView);

    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId');
        this.docId=response.get('documentId');
        this.wipId = response.get('wipId') ?? defaultGuid;
        this.active=response.get('activeTab')??'Personal';



        this.isNewPatient=response.get('isNewPatient')??'false';
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);
    //

    this.isEditblink = false;

    // const activatedRoute1 = this.activatedRoute.url.subscribe(
    //   (value) => {
    //     if (value.length == 1) {
    //       this.isEditButton = true;
    //     }
    //     if (value[0].path === 'patientCreateEditTab') {
    //       this.strUrlPath = 'patientCreateEditTab';
    //       // this.title.setTitle('Qsecure | Create Patient');
    //     } else {
    //       setTimeout(() => {}, 400);
    //       this.strUrlPath = 'patientViewEdit';
    //       // this.title.setTitle('Qsecure | View Patient');
    //     }
    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );
    // this.subscription$.push(activatedRoute1);
    if(this.patientId === defaultGuid|| this.patientId===null || this.patientId === undefined || this.patientId === ''){
      this.title.setTitle('Qsecure | Create Patient');
    }else{
      this.title.setTitle('Qsecure | View Patient');
    }
    //
    // const hearderData = this.Table.getheaderData().subscribe(() => {
    //   setTimeout(() => {

    //     this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getDetailsByPatientID(this.patientId);
    //   }, 400);
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    // this.subscription$.push(hearderData);
    let data = this.cookieService.get('OrderTab');
    if (data == 'true') {
      this.active = 'Order';
    }

    if (this.wipId != '' && this.wipId != defaultGuid) {
      this.getWIPDetails();
    }
  }

  changeTab(tab: number) {
    // Check if the clicked tab is already active
    if (this.activeTab === tab) {
      return; // Exit the function if the clicked tab is already active
    }

    this.activeTab = tab;
    // if (this.activeTab === this.MMCDocumentUploadTypes.FaxProcess ||
    //     this.activeTab === this.MMCDocumentUploadTypes.OnDemand ||
    //     this.activeTab === this.MMCDocumentUploadTypes.ApproveDisapprove ||
    //     this.activeTab === this.MMCDocumentUploadTypes.Pending) {
    //   this.getTableData(
    //     this.activeTab,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null
    //   );
    // }
  }

  editPatient(e) {
    this.isEditblink = false;
    if (this.PatientsTabComponent) {
      this.isEditblink = true;
      this.PatientsTabComponent.editForm();
      this.isButtonDisable=false;
    } else if (this.PatientClinicalTabComponent) {
      this.isEditblink = true;
      this.PatientClinicalTabComponent.editForm();
      // } else if (this.PatientCommonDocumentTabComponent) {
    } else if (this.PatientCommonDocumentMedicareTabComponent) {
      this.isEditblink = true;
      // this.PatientCommonDocumentTabComponent.editForm();
      this.PatientCommonDocumentMedicareTabComponent.editForm();
    } else if (this.PatientInsuranceTabComponent) {
      this.isEditblink = true;
      this.PatientInsuranceTabComponent.editForm();
    } else if (this.PatientCommonNotesComponent) {
      this.isEditblink = true;
      this.PatientCommonNotesComponent.editForm();
    } else if (this.PatientOrderTabComponent) {
      this.isEditblink = true;
      this.PatientOrderTabComponent.editForm();
    }
    else if (this.PatientCommonSortingTabComponent) {
      this.isEditblink = true;
      this.PatientCommonSortingTabComponent.editForm();
    }
  }
  //// to get PatientId
  patientDetailsAfterSave(event: string) {
    // const patientId = (event ?? '') !== '' ? event : defaultGuid;
    // this.patientId = '';
    // setTimeout(() => {
    //   this.patientId = patientId ?? defaultGuid;
    // }, 1);
    if (event != undefined) {
      if (this.PatientsTabComponent) {
        this.nav.select('Clinical');
      } else if (this.PatientClinicalTabComponent) {
        // this.nav.select('Document');
        this.nav.select('Sorting');
      }
    } else {
      this.isEditblink = false;
    }

    // this.isEditblink = false
  }
  getPatientDetailsForInsuranceSave() {
    let patientDetails: PatientDTO;
    if (
      this.patientId !== '' &&
      this.patientId !== null &&
      this.patientId !== undefined
    ) {
      this.store.dispatch(new getPatient(this.patientId)).subscribe(
        (response) => {
          patientDetails = response.patientState.selectedPatient;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    }
  }

  //getWipDetails
  getWIPDetails() {
    const wipDetails = this.wipService.get(this.wipId).subscribe((response) => {
      this.wipDetails = response;
      this.defaultWipId = response.wipId;
    });
    this.subscription$.push(wipDetails);
  }
  //To get WIP Allocation popup
  openWipAllocationModal() {
    const dialogRef = this.dialog.open(WipAllocationModalComponent, {
      disableClose: true,
      data: {
        wipId: [this.wipId],
        patientId: [this.wipDetails.patientId] ?? [],
        defaultWipId: [this.wipDetails.wipId] ?? [],
      },
    });
    dialogRef.afterClosed().subscribe((a) => {});
  }
  //Open Add Notes Popup by floating Icon
  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      // data: {
      //   patientId: this.patientId,
      //   notesId: defaultGuid,
      //   orderId: defaultGuid,
      //   claimId: defaultGuid,
      //   noteType: NoteType.patient,
      //   pageType: NoteType.patient,
      //   isAddVisible: false,
      // },
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: this.patientId,
        // defaultFaxId: this.data.defaultFaxId,
        // isCallNote: isCallNote,
        isPending: false,
        docId: defaultGuid,
        inboundStatusShortCodeId: defaultGuid,
        // selectedNode: this.selectedNode,
        // chartNo: this.vchartNo,
        addnotes:false
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //Open Notes Table Data Popup by floating Icon
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  backToPatientSearch() {
    this.wipId == defaultGuid
      ? this.router.navigate(['/patient-list/'])
      : this.router.navigate(['/patient/taskListTab']);
  }

    //Add Remainder Model
  OpenAddReminderMdl(status: boolean) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        noteType: 'patient',
        remindertitle: 'Add Reminder',
        status: status,
        reminderid: defaultGuid,
        selectedNode: this.selectedNode,
        lblDefaultPatientId: this.patientId,
        isdisable: false,
      },
    };
    const dialogRef = this.dialog.open(AddReminderComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.GetReminderFilterList();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }

  //To Open Note Modal
  // openNoteModal(value: string) {
  //   const dialogRef = this.dialog.open(PatientCommonNotesComponent, {
  //     disableClose: true,
  //     data: {
  //       patientId: this.patientId,
  //       notesId: value ?? defaultGuid,
  //       pageType: NoteType.general,
  //     },
  //   });
  //   const closeDialog$ = dialogRef.afterClosed().subscribe(
  //     (result) => {},
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'error',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(closeDialog$);
  // }
}
