import { mapEnumToOptions } from '@abp/ng.core';

export enum ChatReports {
  WeeklyReport = 'WeeklyReport',
  MonthlyReport = 'MonthlyReport',
  WeekSummaryReport = 'WeekSummaryReport',
  MonthSummaryReport = 'MonthSummaryReport',
  CurrentWeek = 'CurrentWeek',
  PreviousWeek = 'PreviousWeek',
  CurrentMonth = 'CurrentMonth',
  PreviousMonth = 'PreviousMonth'
}

export const chatReportsOptions = mapEnumToOptions(ChatReports);
