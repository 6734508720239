<div class="py-0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 my-1">
        <h5 class="d-inline-flex text-primary"><b>Process Credit Card Payment</b> </h5>
        <button type="button" class="close" (click)="closeDialog()">
          <span><b>X</b></span>
        </button>

      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

            </div>
            <div class="row">
              <div class="col-md-4 my-1">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Order No.</mat-label>
                    <input matInput type="number">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 my-1">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Total Due ($)</mat-label>
                    <input matInput type="number">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 my-1">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Charge ($)</mat-label>
                    <input matInput type="number">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 ">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Card Type</mat-label>
                    <mat-select>
                      <mat-option value="one">Amex </mat-option>
                      <mat-option value="two">Discover</mat-option>
                      <mat-option value="two">MC</mat-option>
                      <mat-option value="two">VISA</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Card No.</mat-label>
                    <input matInput type="number">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 mdi-battery-30-bluetooth">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <input matInput [matDatepicker]="expiryMonthPicker" placeholder="Expires Month"
                      (dateInput)="handleExpiryMonthChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="expiryMonthPicker"></mat-datepicker-toggle>
                    <mat-datepicker #expiryMonthPicker startView="year" [startAt]="startDate"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 ">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <input matInput [matDatepicker]="expiryYearPicker" placeholder="Expires Year"
                      (dateInput)="handleExpiryYearChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="expiryYearPicker"></mat-datepicker-toggle>
                    <mat-datepicker #expiryYearPicker startView="year" [startAt]="startDate"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>CVV</mat-label>
                    <input matInput type="number">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-center"> <button type="submit" class="btn btn-primary "
                  contenteditable="true">Process Payment</button></div>
            </div>
            <div class="row">
              <div class="col-md-12 my-0">
                <h6 class=""><b>Status:</b></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- table 1 -->

<!-- Frequently Bought Items -->
<!-- Table 1 Section -->
<!-- <h5 class="text-primary mt-3"><b>Paypal Vault details</b></h5> -->
<div class="card mb-3 mt-2 mx-2">
  <div class="card-body p-2">
    <div class="table table-responsive">
      <table #frequentItemsTable [dtOptions]="dtFrequentItemTableOptions" datatable class="row-border hover w-100">
        <thead>
          <tr>
            <th>Payment type</th>
            <th>Amount ($)</th>
            <th>Approval No.</th>
            <th>User</th>
            <th>Date</th>
            <th>Processor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of frequentBoughtItems">
            <td>{{ data.paymentType }}</td>
            <td>{{ data.amount($) }}</td>
            <td>{{ data.approvalNo }}</td>
            <td>{{ data.user }}</td>
            <td>{{ data.date }}</td>
            <td>{{ data.processor }}</td>
            <td><mat-checkbox></mat-checkbox></td>
            <td><mat-checkbox></mat-checkbox></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Table 2 Section -->
<h5 class="text-primary mt-3 mx-2"><b>Paypal Vault details</b></h5>
<div class="card mb-3 mt-2">
  <div class="card-body p-2">
    <div class="table table-responsive">
      <table #frequentItemsTable [dtOptions]="dtFrequentItemTableOptions" datatable class="row-border hover w-100">
        <thead>
          <tr>
            <th>PNREF</th>
            <th>Card ID</th>
            <th>Card</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of frequentBoughtItems">
            <td>{{ data.pnref }}</td>
            <td>{{ data.cardId }}</td>
            <td>{{ data.Card }}</td>
            <td>{{ data.createdDate }}</td>
            <td><mat-checkbox></mat-checkbox></td>
            <td><mat-checkbox></mat-checkbox></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>