import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { DataTableDirective } from 'angular-datatables';
import { CalendarStatus } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar-status.enum';
import { MessageAndMailResponseService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/smsresponse-history';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeLineComponent } from 'projects/order/src/app/time-line/time-line.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-calendar-list-table',
  templateUrl: './calendar-list-table.component.html',
  styleUrls: ['./calendar-list-table.component.scss']
})
export class CalendarListTableComponent implements OnInit {

  dtCalenderTableOptions:any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    order:[],
    columnDefs: [
      // { targets: [0, 1], orderable: false },
      { targets: [0, 1,-1], className: 'dt-fixed-column' },
       { targets: [14,15,16], visible: false },
      {
        targets: [6],
        type: 'date',
        render: function (data, type, row) {
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text:'Export',
        filename: 'Schedule List',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0) ,:eq(1)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2))',
      }
    ],
  };
  CalenderStatus = CalendarStatus;
  myTable: DataTables.Api;
  @Input() tableData:any;
  @Input() statuslabel:boolean;
  calendarDataList:any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor( 
    private snackBar: MatSnackBar,
    private messageAndMailResponseService: MessageAndMailResponseService,
    private dialog: MatDialog,
    private datepipe: DatePipe

  ) { }

  ngOnInit(): void {
    this.calendarDataList=this.tableData;

    this.dtCalenderTableOptions.buttons[0].filename =
    'Schedule List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

   //After View Method
   ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
  getFormattedDateTime(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '-';
  }
  getFormattedTime(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '-';
  }

  //Format Date and Time 
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago'
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  //Format Date
  getFormattedDateZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        // hour: 'numeric', minute: '2-digit',
        // hour12: true,
        timeZone: 'America/Chicago'
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

    //Format Time
    getFormattedTimeZone(date: string): string {
      if (date) {
        const formattedDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = {
          hour: 'numeric', minute: '2-digit',
          hour12: true,
          timeZone: 'America/Chicago'
        };
        return formattedDate.toLocaleString('en-US', options);
      } else {
        return '-';
      }
    }



//   getStatusColor(status: string): string {
//     switch (status) {
//         case this.CalenderStatus.Scheduled:
//             return 'red';
//         case this.CalenderStatus.Completed:
//             return 'green';
//         case this.CalenderStatus.Cancelled:
//             return '#ff8c00';
//         case this.CalenderStatus.Rescheduled:
//             return 'blue';
//         default:
//             return 'black';
//     }
// }

getStatusColor(status: string): string {
  switch (status) {
      case this.CalenderStatus.Scheduled:
          return '#c8102f';
      case this.CalenderStatus.Completed:
          return '#2a9446';
      case this.CalenderStatus.Cancelled:
          return '#f79d47';
      case this.CalenderStatus.Rescheduled:
          return '#b33e96';
      case 'Confirmed':
          return '#1075bb';
      default:
          return 'black';
  }
}

getStatusBackgroundColor(status: string): string {
switch (status) {
    case this.CalenderStatus.Scheduled:
        return '#fbeef0';
    case this.CalenderStatus.Completed:
        return '#d5f2dd';
    case this.CalenderStatus.Cancelled:
        return '#ffe9d3';
    case this.CalenderStatus.Rescheduled:
        return '#f7e1f2';
    case 'Confirmed':
        return '#e2eff9';
    default:
        return '#fff';
}
}

// checkCondition(givenString: string) :string{
//   // Remove dot and space, and convert to lowercase
//   const cleanedString: string = givenString.replace(".", "").replace(" ", "").toLowerCase();

//   // Check the condition
//   if (cleanedString === "awaitingcheckin") {
//     console.log("Condition is met.");
//   } else {
//     console.log("Condition is not met.");
//     return cleanedString;

//   }
// }

openHistory(scheduleId){
    const config: MatDialogConfig = {
      disableClose: true,
      height: '60vh',
      width: '45vw',
      data: {
          scheduleId: scheduleId,
         isScheduleStatus: true
      },
    };
    const dialogRef = this.dialog.open(TimeLineComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  
}

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  updateTextColor(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black if colorCode is invalid
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }

  //Row Expan Event Table To View Description
  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else {
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }

  format(d: any, notesData: any): string {
    let returnString: string = '';

    if (Array.isArray(notesData)) {
      notesData.forEach(function (value) {
        returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + value + '</td></tr>';
      });
    } else if (typeof notesData === 'string') {
      returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + notesData + '</td></tr>';
    } else {
      console.error('notesData is neither an array nor a string:', notesData);
    }

    return returnString;
  }

}
