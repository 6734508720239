import { defaultGuid } from './../../../../shared/src/app/enums/allenums.enum';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataTableDirective } from 'angular-datatables';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { AddNotesComponent } from '../add-notes/add-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { PatientAuthSaveUpdateFormComponent } from '../patient-auth-save-update-form/patient-auth-save-update-form.component';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { InboundStatusDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound-status/dto';
import { InboundStatusService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound-status/inbound-status.service';
import { CallbackComponent } from '../callback/callback.component';
import { NewsetupMovetoComponent } from '../newsetup-moveto/newsetup-moveto.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { PatientsService } from '../patient-proxy/patient/patients.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InsuranceType, VerificationStatus, VerificationStatusV1 } from '../patient-proxy/patient/enumeration-data/verification-status.enum';
import { PatientVerificationService } from '../patient-proxy/patient-optimization';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-miverification-list',
  templateUrl: './miverification-list.component.html',
  styleUrls: ['./miverification-list.component.scss']
})
export class MiverificationListComponent implements OnInit {
  @Input() DataList: any[];
  @Input() activeTab: string;
  @Output() isChecked = new EventEmitter<boolean>();
  @Output() lstId? = new EventEmitter<number[]>();
  @Output() isLoadTbl? = new EventEmitter<boolean>();
  @Output() reloadTableEvent = new EventEmitter(); //Event Emitter to reload the table
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  isLoading:boolean=false;
  lstChk:number;
  subscription$: Subscription[] = [];
  inboundStatusIds = InboundStatusShortCode;
  binList: InboundStatusDTO[] = [];
  id:number;
  insuranceType=InsuranceType;
  lstVerificationSttaus=VerificationStatus;
  lstVerificationSttausV1=VerificationStatusV1;
  disableSTATCheckbox:boolean=false;
  disableLockCheckbox:boolean=false;
 defaultGuid= defaultGuid;
  constructor(private branchService: OrganizationUnitService,
    private inboundStatusService: InboundStatusService,
    private patientsService: PatientsService,
    private toaster: ToastrService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private patientVerificationService: PatientVerificationService,
    private toastr: ToastrService,
    private datepipe:DatePipe,
    private commonService: CommonService
  ) { }
  myVerification:any;
  ngOnInit() {
    this.myVerification = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        // {
        //   targets: [5,6],
        //   type: 'date',
        //   render: function (data, type, row) {
        //     // Assuming data is in the format MM/DD/YYYY HH:mm:ss
        //     if (type === 'sort' || type === 'type') {
        //       var dateTimeParts = data.split(' ');
        //       var dateParts = dateTimeParts[0].split('/');
        //       var timeParts = dateTimeParts[1].split(':');
        //       return new Date(dateParts[2], dateParts[0] - 1, dateParts[1], timeParts[0], timeParts[1], timeParts[2]);
        //     } else {
        //       return data;
        //     }
        //   },
        // },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'My Verification - Fax Process',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        {
          text: 'Show Default Column',
          action: function (e, dt, node, config) {
            dt.columns(':not(:lt(4),:last-child)').visible(false);
          },
        },
        {
          text: 'Show All Column',
          action: function (e, dt, node, config) {
            dt.columns().visible(true);
          },
        },
      ],
    };
    this.lstChk = this.DataList.filter(
      (a) => a.lockedStatus == 1
    )?.length;
    if(this.lstChk!==0){
      this.isChecked.emit(true);
      // this.lstId.emit(this.lstChk)
    }else{
      this.isChecked.emit(false);
    }

    this.myVerification.buttons[0].filename =
    'Master Verification - Qsecure' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }
  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.myVerification.scrollY =
      screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
//Row Click Event UnProcessed Table
handleTableCellClick(e: Event, notesData: any): void {
  const target = e.target as HTMLElement;
  const tr = target.closest('tr');
  this.getDataTableData(tr, notesData);
}
//DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
async getDataTableData(tr: any, notesData: any): Promise<void> {
  this.myTable = await this.datatableElement.dtInstance;

  if (tr) {
    const row = this.myTable.row(tr);

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
    } else {
      // Open this row
      this.collapseAllRows();
      row.child(this.format(row.data(), notesData)).show();
    }
  }
}
//Add Dynamic Notes Row in UnProcessed Table
format(d: any, notesData: any) {
  let returnString: string = '';
  notesData?.forEach(function (value) {
    returnString +=
    '<tr class="border-bottom " style="white-space: pre-wrap !important;"><td style="border: 1px solid rgba(0, 0, 0, 0.2); width: 100%">' +
    value +
    '</td></tr>';
  });
  return returnString;
}
addRemoveChk(event: MatSlideToggleChange,data:any) {
  this.disableLockCheckbox=true;
  if(this.activeTab==='Authorization'){
    // const isStatUpdate = this.patientsService.lockUpdateAuth(id).subscribe(
    //   (value) => {
    //     this.toaster.success('Lock has been updated successfully', 'Success');
    //     this.isLoadTbl.emit(true);
    //   },
    //   (err) => {
    //     (err: HttpErrorResponse) => {
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'info',
    //         text: data?.error?.error?.message,
    //       });
    //     }
    //     this.isLoadTbl.emit(true);
    //   }
    // );
    // this.subscription$.push(isStatUpdate);
    try {
      const isStatUpdate = this.patientsService.lockUpdateAuth(data.id).subscribe(
        (value) => {
          this.toaster.success('Lock has been updated successfully', 'Success');
          this.disableLockCheckbox=false;
          data.islockstatusCheck = event.checked ? true : false;
          if(event.checked){
          data.lockedStatus=1;
          }
          else{
            data.lockedStatus=0;
          }
          this.DataList.find(item => item.id == data.id).lockedByName = value.lockedBy;
          this.DataList.find(item => item.id == data.id).lockedAT = this.commonService.getFormattedDateTimeZone(value.lockedAt);
          // this.DataList.find(item => item.id == data.id).modifiedDate = this.commonService.getFormattedDateTimeZone(value.modifiedDate);
          // this.isLoadTbl.emit(true);
          },
          (error) => {
            const err: HttpErrorResponse = error;
            if(err.status===200){
              this.toaster.success('Lock has been updated successfully', 'Success');
              data.islockstatusCheck = event.checked ? true : false;
              this.disableLockCheckbox=false;
              if(event.checked){
                data.lockedStatus=1;
                }
                else{
                  data.lockedStatus=0;
                }
                this.DataList.find(item => item.id == data.id).lockedByName = error.lockedBy;
                this.DataList.find(item => item.id == data.id).lockedAT = this.commonService.getFormattedDateTimeZone(error.lockedAt);
                // this.DataList.find(item => item.id == data.id).modifiedDate = this.commonService.getFormattedDateTimeZone(error.modifiedDate);
        // this.isLoadTbl.emit(true);
            }else{
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
          this.toaster.error('Lock has been updated fail', 'Fail');
          this.disableLockCheckbox=false;
        }
          },
          () => {
            // This block will be executed on completion
            setTimeout(() => {

            }, 1000);
          }
        );
        this.subscription$.push(isStatUpdate);
    } catch (error) {
      this.toaster.error('Update Failed', 'Error');
      console.error('Unexpected error:', error);
      this.disableLockCheckbox=false;
    } finally {
    }
  }else{

  const isStatUpdate = this.patientsService.lockUpdate(data.verificationId).subscribe(
    (value) => {
      this.toaster.success('Lock has been updated successfully', 'Success');
      data.islockstatusCheck = event.checked ? true : false;
      this.disableLockCheckbox=false;
      if(event.checked){
        data.lockedStatus=1;
        }
        else{
          data.lockedStatus=0;
        }
        this.DataList.find(item => item.verificationId == data.verificationId).lockedBy = value.lockedBy;
        this.DataList.find(item => item.verificationId == data.verificationId).lockedAt = this.commonService.getFormattedDateTimeZone(value.lockedAt);
        // this.DataList.find(item => item.verificationId == data.verificationId).modifiedDate = this.commonService.getFormattedDateTimeZone(value.modifiedDate);
      // this.isLoadTbl.emit(true);
    },
    (err) => {
      (err: HttpErrorResponse) => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
        this.toaster.error('Lock has been updated fail', 'Fail');
        this.disableLockCheckbox=false;
      }
      this.isLoadTbl.emit(true);
    }
  );
  this.subscription$.push(isStatUpdate);
  }
  if (event.checked === true) {
     this.lstChk=this.lstChk+1
  } else {
      this.lstChk=this.lstChk-1
  }
  if(this.lstChk!==0){
    this.isChecked.emit(true);
    // this.lstId.emit(this.lstChk)
  }else{
    this.isChecked.emit(false);
  }
}
EditPolicy(policy: any) {

  const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
    data: {
      patientId: policy.patientId,
      policyId: policy.policyId,
      documentId: policy.documentId,
      wipId: policy.wipId ?? defaultGuid,
      verificationId: policy.verificationId,
      verificationStatus:policy.verificationStatus,
      defaultFaxId:policy?.defaultFaxId
    },
    disableClose: true
  });
  const aftClosed = dialogRef.afterClosed().subscribe(
    (result) => {
      // this.patientIdEmitters.next(policy.patientId);
      this.isLoadTbl.emit(true);
      // policy.patientId !== defaultGuid && this.getTableData();
    },
    (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
  this.subscription$.push(aftClosed);
}
viewVerificationVerifyBinById(data: any): void {
  const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
    data: {
      patientId: data.patientId,
      verificationId: data.verificationId,
      policyId: data.policyId,
      documentId: data.documentId,
      // inboundShortCode: data.inboundShortCode,
      // savehidden: true,
      isViewVerification: true,
      defaultFaxId:data?.defaultFaxId
    },
    disableClose: true
  });
  const closeDialog = dialogRef.afterClosed().subscribe((result) => {
    dialogRef?.close(result);
  });
  this.subscription$.push(closeDialog);
}
viewVerificationById(data: any): void {

  const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
    data: {
      patientId: data.patientId,
      verificationId: data.verificationId,
      policyId: data.policyId,
      documentId: data.documentId,
      // inboundShortCode:data.inboundShortCode,
       savehidden: true,
      isViewVerification:true,
      defaultFaxId:data?.defaultFaxId
    },
  });
  const closeDialog = dialogRef.afterClosed().subscribe((result) => {
    dialogRef?.close(result);
  });
  this.subscription$.push(closeDialog);
}

openAddNotesPopUp( event : MouseEvent,data:any) {
  const config: MatDialogConfig = {
    disableClose: true,
    minHeight: '30vh',
    minWidth: '50vw',
    data: {
      noteType: 'patient',
      notesId: '00000000-0000-0000-0000-000000000000',
      patientVerificationId:data.verificationId,
      patientId: data.patientId,
      defaultFaxId: data.defaultFaxId,
      isCallNote: false,
      isPending: false,
      chartNo:data.chartNo,
      docId: data.documentId,
      inboundStatusShortCodeId: defaultGuid,
      addnotes:false,
      patientNotes : data.notes
    },
  };
  const dialogRef = this.dialog.open(AddNoteTabComponent, config);

  const closeDialog = dialogRef.afterClosed().subscribe(
    () => {
      this.isLoadTbl.emit(true);
      // if (notes) {
      //   data.notes = notes;
      //   this.collapseAllRows();
      // }
    },
    (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
  this.subscription$.push(closeDialog);
}

openAddAuthNotesPopUp( data:any) {
  const config: MatDialogConfig = {
    disableClose: true,
    minHeight: '30vh',
    minWidth: '50vw',
    data: {
      noteType: 'patient',
      notesId: '00000000-0000-0000-0000-000000000000',
      patientAuthorizationId:data.id,
      patientId: data.patientId,
      defaultFaxId: data.defaultFaxId,
      isCallNote: false,
      isPending: false,
      chartNo:data.chartNo,
      docId: data.docId,
      inboundStatusShortCodeId: defaultGuid,
      addnotes:false,
      patientNotes : data.authorizationNotes
    },
  };
  const dialogRef = this.dialog.open(AddNoteTabComponent, config);

  const closeDialog = dialogRef.afterClosed().subscribe(
    () => {
      //
      this.isLoadTbl.emit(true);
    },
    (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
  this.subscription$.push(closeDialog);
}

collapseAllRows(): void {
  const rows = this.myTable.rows({ page: 'current' }); // Get all rows on the current page
  rows.every((idx, data, node) => {
    const rowData = this.myTable.row(node).data();
    if (rowData) {
      this.myTable.row(node).child.hide(); // Hide the child row if it's expanded
    }
    return true; // Continue to the next row
  });
}

// openAddNotesPopUpAuth(data:any) {
//   const config: MatDialogConfig = {
//     disableClose: true,
//     minHeight: '30vh',
//     minWidth: '50vw',
//     data: {
//       noteType: 'patient',
//       notesId: '00000000-0000-0000-0000-000000000000',
//       patientAuthorizationId:data.id,
//       patientId: data.patientId,
//       defaultFaxId: data.defaultFaxId,
//       isCallNote: false,
//       isPending: false,
//       docId: data.docId,
//       chartNo:data.chartId,
//       inboundStatusShortCodeId: defaultGuid,
//       addnotes:false
//     },
//   };
//   const dialogRef = this.dialog.open(AddNoteTabComponent, config);

//   const closeDialog = dialogRef.afterClosed().subscribe(
//     () => {
//       // this.getReminderTableData();
//       this.isLoadTbl.emit(true);
//     },
//     (err) => {
//       const data: HttpErrorResponse = err;
//       Swal.fire({
//         icon: 'info',
//         text: data?.error?.error?.message,
//       });
//     }
//   );
//   this.subscription$.push(closeDialog);
// }
isEmpty(value: any): boolean {
  return value === null || value === undefined || value === '';
}
ViewNotes(data: any) {
  const dialogRef = this.dialog.open(AddNotesComponent, {
    data: { patientID: data.patientID, notesId: data.notesId }
  });
}
viewPatientLedger(data: any) {
  this.dialog.open(ViewPatientLedgerComponent, {
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
    panelClass: 'fullscreen-dialog-panel',
    disableClose: true,
    data: {
      patientId: data.patientId,
    },
  });
  this.dialog.afterAllClosed.subscribe((resposne) => { });
}
doprecert(data:any) {

  const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
    data: {
      documentId: data.docId,
      defaultFaxId:data?.defaultFaxId
    },
  });
  const componentInstance = dialogRef.componentInstance;
  componentInstance.patientId = data.patientId;
  componentInstance.verificationId = data.verficationId;
  componentInstance.docId = data.docId;
  componentInstance.faxId = data.defaultFaxId;
  componentInstance.isView = true;
  componentInstance.isSupplies = true;
  componentInstance.chartId = data.chartId;
  componentInstance.authorizationId=data.id;
  this.dialog.afterAllClosed.subscribe((resposne) => { this.isLoadTbl.emit(true);});
}
viewprecert(data) {
  const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
    data: {
      documentId: data.docId,
      defaultFaxId:data?.defaultFaxId
    },
  });
  const componentInstance = dialogRef.componentInstance;
  componentInstance.patientId = data.patientId;
  componentInstance.verificationId = data.verficationId;
  componentInstance.docId = data.docId;
  componentInstance.faxId = data.defaultFaxId;
  componentInstance.isView = true;
  componentInstance.isSupplies = true;
  componentInstance.chartId = data.chartId;
  componentInstance.authorizationId=data.id;
  this.dialog.afterAllClosed.subscribe((resposne) => { this.isLoadTbl.emit(true);});
}
loadBins() {
  // this.inboundStatusService
  //   .getList(new PagedAndSortedResultRequestDto())
  //   .subscribe((response) => {
  const saveNotes = this.inboundStatusService
    .MoveToDetails()
    .subscribe((response) => {
      this.binList = response;
      this.binList = this.binList.filter((item) => item.isActive == 1);
    });
  this.subscription$.push(saveNotes);
}
callback(docId: string, shortCodeId: string) {
  const dialogRef = this.dialog.open(CallbackComponent, {
    minHeight: '33vh',
    minWidth: '52vw',
    data: {
      documentId: docId,
      shortCodeId: shortCodeId,
    },
  });
  dialogRef.afterClosed().subscribe(
    () => { },
    (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
}
newSetupMoveTo(
  documentId: string,
  inboundStatusId: string,
  shortCodeId: string,
  isMarkConfirm: boolean,
) {
  const dialogRef = this.dialog.open(NewsetupMovetoComponent, {
    minHeight: '33vh',
    minWidth: '52vw',
    disableClose: true,
    data: {
      docId: documentId,
      inboundStatusId: inboundStatusId,
      moveToId: 2,
      // selectedNode: this.selectedNode,
      shortCodeId: shortCodeId,
      isMarkConfirm: isMarkConfirm,
    },
  });
  dialogRef.afterClosed().subscribe((a) => { });
}
openViewNotesPopup(data:any) {
  const config: MatDialogConfig = {
    disableClose: true,
    width: '70vw',
    maxWidth: '70vw',
    height: '40vw',
    maxHeight: '40vw',
    data: {
      patientId: data.patientId,
      pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
      isAddVisible: false,
      defaultFaxId: data.defaultFaxId,
      docId: data.documentId,
      patientVerificationId:data.verificationId,
      patientNotes : data.notes
    },
  };
  const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

  dialogRef.afterClosed().subscribe(
    () => {
      this.isLoadTbl.emit(true);
    },
    (err: HttpErrorResponse) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
}
openViewNotesPopupAuth(data:any) {
  const config: MatDialogConfig = {
    disableClose: true,
    width: '70vw',
    maxWidth: '70vw',
    height: '40vw',
    maxHeight: '40vw',
    data: {
      patientId: data.patientId,
      pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
      isAddVisible: false,
      defaultFaxId: data.defaultFaxId,
      docId: data.docId,
      patientAuthorizationId:data.id,
      patientNotes : data.authorizationNotes

    },
  };
  const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

  dialogRef.afterClosed().subscribe(
    () => {
      //this.getReminderTableData();
      this.isLoadTbl.emit(true);
    },
    (err: HttpErrorResponse) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
}
isStatUpdate(event:any, data: any): void {
  this.disableSTATCheckbox=true;
  this.isLoading=true;
  const isLockUpdate = this.patientsService.STATupdate(data.verificationId).subscribe(
    (value) => {
      this.toaster.success('STAT has been updated successfully', 'Success');
      data.successfulStatUpdate=(event.checked)  ?  true : false;
      // this.isLoadTbl.emit(true);
      this.disableSTATCheckbox=false;
      this.isLoading=false;
    },
    (err) => {
      this.toaster.error('STAT update has failed', 'Error');
      this.isLoadTbl.emit(true);
      this.disableSTATCheckbox=false;
      this.isLoading=false;
    }
  );
  this.subscription$.push(isLockUpdate);
}
isStatUpdateAuth(event:any, data: any): void {
  this.disableSTATCheckbox=true;
  this.isLoading=true;
  const isLockUpdate = this.patientsService.STATUpdateAuth(data.id).subscribe(
    (value) => {
      this.toaster.success('STAT has been updated successfully', 'Success');
      data.successfulStatUpdate=(event.checked)  ?  true : false;
      this.disableSTATCheckbox=false;
      this.isLoading=false;
      // this.isLoadTbl.emit(true);
    },
    (err) => {
      this.isLoading=false;
      this.toaster.error('STAT update has failed', 'Error');
      this.isLoadTbl.emit(true);
      this.disableSTATCheckbox=false;
    }
  );
  this.subscription$.push(isLockUpdate);
}
isStatusUpdate(verificationId: string) {
  const isStatusUpdate = this.patientsService.Statusupdate(verificationId).subscribe(
    (value) => {
      this.toaster.success('status has been updated successfully', 'Success');
      this.isLoadTbl.emit(true);
    },
    (err) => {
      this.toaster.success('Status has been updated successfully', 'Success');
      this.isLoadTbl.emit(true);
    }
  );
  this.subscription$.push(isStatusUpdate);
}
isStatusUpdateAuth(id: string) {
  const isStatusUpdate = this.patientsService.StatusUpdateAuth(id).subscribe(
    (value) => {
      this.toaster.success('status has been updated successfully', 'Success');
      this.isLoadTbl.emit(true);
    },
    (err) => {
      this.toaster.success('Status has been updated successfully', 'Success');
      this.isLoadTbl.emit(true);
    }
  );
  this.subscription$.push(isStatusUpdate);
}

reloadParentPage() {
  this.reloadTableEvent.emit();
}


onRowDoubleClickV1(textToCopy) {
  this.copyTextToClipboard(textToCopy);
  this.showNotification('Text copied: ' + textToCopy);
}
showNotification(message: string): void {
  this.snackBar.open(message, 'Close', {
    duration: 3000, // Duration in milliseconds
    horizontalPosition: 'end', // Positioning the notification horizontally
    verticalPosition: 'bottom', // Positioning the notification vertically
  });
}

copyTextToClipboard(text: string): void {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
}




cancelVerificationStatus(verificationId:string){

  const verificationCancel =
  this.patientVerificationService.cancelVerificationStatus(
    verificationId,VerificationStatusV1.Cancelled
  ).subscribe(
    (response) => {
      this.toastr.success('Verification Cancelled Successfully','Success')
      this.isLoadTbl.emit(true);
    },
    (err) => {
      const data: HttpErrorResponse = err;
      this.toastr.error(err.message,'Fail')
    }
  );
}
}
