import type { PushNotificationsDTO, SendNotificationDTO, TokenDTO } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  apiName = 'platformManagement';

  sendPushNotificationByOSendNotification = (oSendNotification: SendNotificationDTO) =>
    this.restService.request<any, PushNotificationsDTO[]>({
      method: 'POST',
      url: '/api/PlatformApp/push-notification/send-push-notification',
      body: oSendNotification,
    },
    { apiName: this.apiName });

  updateTokenByUserIdAndToken = (userId: string, token: string) =>
    this.restService.request<any, TokenDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/push-notification/update-token/${userId}`,
      params: { token },
    },
    { apiName: this.apiName });


    getallNotifiaction  = (userId: string) =>
    this.restService.request<any, PushNotificationsDTO[]>({
      method: 'GET',
      url: `/api/PlatformApp/push-notification/get-all-notifications/${userId}`,
    },
    { apiName: this.apiName });

    deleteTokenByUserIdAndToken = (userId: string, token: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/push-notification/delete-token/${userId}`,
      params: { token },
    },
    { apiName: this.apiName });


    

    updateNotification = (notificationId: string, isViewed: boolean) =>
    this.restService.request<any, any>({
      method: 'PUT',
      url: `/api/PlatformApp/push-notification/update-notification/${notificationId}`,
      params: { isViewed },
    },
    { apiName: this.apiName });


    getAllNotifications = () =>
    this.restService.request<any, any[]>({
      method: 'GET',
      url: '/api/PlatformApp/push-notification/get-all-notifications',
    },
    { apiName: this.apiName });
    
  constructor(private restService: RestService) {}
}
