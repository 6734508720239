<br />
<div class="card card-body ">
    <div class="mt-1">
        <form [formGroup]="pSearchForm">
            <div class="row">
                <mat-form-field class="col-3">
                    <mat-label> Created Date</mat-label>
                    <input autocomplete="new-txtCreatedate" formControlName="txtCreatedate" matInput maxlength="40" type="text"
                        placeholder="(MM/DD/YYYY)" [matDatepicker]="txtCreatedate" />
                    <mat-datepicker-toggle matSuffix [for]="txtCreatedate"> </mat-datepicker-toggle>
                    <mat-datepicker #txtCreatedate> </mat-datepicker>
                    <mat-error *ngIf="
                    pSearchForm?.get('txtCreatedate')?.touched &&
                    pSearchForm?.get('txtCreatedate')?.errors ">
                        Enter Valid date
                    </mat-error>
                </mat-form-field>
                <div class="col-3">
                    <button mat-button class="buttonColor">Search
                    </button>
                    <button mat-button class="resetclr buttonColor mb-2 mr-2 ml-2" (click)="reset()">
                        Reset
                    </button>
                </div>
            </div>
        </form>
        <div class="col-12 table-responsive">
            <app-table [strPageType]="strPageType" [isLoading]="isLoading" >
            </app-table>
          </div>
    </div>
</div>