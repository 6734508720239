<br />
<div class="card card-body">
  <div class="row mb-3">
    <div class="col-md-6">
      <h4 class="customThemeClass"><b>Active Rental Items</b></h4>
    </div>
    <div *ngIf='isShownOrderButton' class="col-md-6 text-sm-right">
      <div class="text-sm-right col-lg-12 p-0">
        <button mat-button class="buttonColor font-size-14" (click)="newSaleorder()">
          <i class="fa fa-plus mr-1" ></i>
          New SaleOrder
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 table-responsive">
      <app-table (viewButton)="ViewOrders($event)" (deleteButton)="setOrderStatusVoided($event)"  [isLoading]="isLoading" [strPageType]="strPageType">
      </app-table>
    </div>
    <div class="col-12 mar-top">
      &nbsp; <h4 class="customThemeClass"><b>Order History</b></h4>
    </div>
    <div class="col-12 table-responsive ">
      <app-table [strPageType]="strPageType2" [isLoading]="isLoading"></app-table>
    </div>
  </div>
</div>
