<br />
<div class="row">
  <div class="col-6">
    <h4 class="customThemeClass"><b>Practice Management</b></h4>
  </div>
  <div class="col-6 text-lg-right">
  </div>
</div>
<form [formGroup]="prtzManagForm">
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-6">
            <mat-label> Practice Name <span class="asterisk">*</span> </mat-label>
            <input autocomplete="new-txtPractizName" matInput maxlength="40" formControlName="txtPractizName" type="text" />
            <mat-error
              *ngIf="  prtzManagForm?.get('txtPractizName')?.touched &&  prtzManagForm?.get('txtPractizName')?.errors?.required ">
              Practice Name is a required field!
            </mat-error>
            <mat-error
              *ngIf="  prtzManagForm?.get('txtPractizName')?.touched &&  prtzManagForm?.get('txtPractizName')?.errors?.sAlphabets ">
              Enter only Alphabets!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label> Address </mat-label>
            <textarea matInput formControlName="txtPractizAddress" type="text"></textarea>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>Phone Number <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtPhoneNumber" matInput prefix="+1-" mask="(000)-000-0000" maxlength="40" placeholder="Enter Phone Number"
              formControlName="txtPhoneNumber" type="text">
            <mat-error *ngIf="
                prtzManagForm?.get('txtPhoneNumber')?.touched &&
                prtzManagForm?.get('txtPhoneNumber')?.errors
                  ">
              Enter Minimum 10 Digits!
            </mat-error>
            <mat-error *ngIf="prtzManagForm?.get('txtPhoneNumber')?.touched &&
            prtzManagForm?.get('txtPhoneNumber')?.errors?.required ">
              Phone Number is a required field!
            </mat-error>
          </mat-form-field>


          <mat-form-field class="col-6">
            <mat-label>Fax <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtFax" matInput mask="(000)-000-00000" maxlength="40" placeholder="Enter FAX Number"
              formControlName="txtFax" type="text">
            <mat-error *ngIf="
              prtzManagForm?.get('txtFax')?.touched &&
              prtzManagForm?.get('txtFax')?.errors
                ">
              Enter Minimum 10 Digits!
            </mat-error>
            <mat-error *ngIf="prtzManagForm?.get('txtFax')?.touched &&
          prtzManagForm?.get('txtFax')?.errors?.required ">
              Fax is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>Country <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpCountry" (selectionChange)='onChangeState($event.value)'
              [(value)]="Country">
              <!-- <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCountryFilter"></ngx-mat-select-search>
                </mat-option> -->
              <mat-option *ngFor="let country of drpCountry" [value]="country.countryShortName">
                {{ country.countryName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="Country='';$event.stopPropagation()" *ngIf="Country" (click)="clearCountry()"
              matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="prtzManagForm?.get('drpCountry')?.touched &&
            prtzManagForm?.get('drpCountry')?.errors?.required ">
              Country is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>State <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
              #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="drpStateFilter">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                {{ state.stateName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
              matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="prtzManagForm?.get('drpState')?.touched &&
            prtzManagForm?.get('drpState')?.errors?.required ">
              State is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>City <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpCity" [(value)]="City" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="drpCityFilter">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="prtzManagForm?.get('drpCity')?.touched &&
            prtzManagForm?.get('drpCity')?.errors?.required ">
              City is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label> Zip Code </mat-label>
            <input autocomplete="new-intPractizZipCode" matInput maxlength="10" formControlName="intPractizZipCode" pattern="[0-9]*" type="text" />
            <mat-error
              *ngIf="prtzManagForm?.get('intPractizZipCode')?.touched && prtzManagForm?.get('intPractizZipCode')?.errors?.pattern">
              Enter only Numbers!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label> Practice Website </mat-label>
            <input autocomplete="new-txtPractizWebsite" matInput maxlength="40" formControlName="txtPractizWebsite" type="text" />
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label> Practice Manager </mat-label>
            <input autocomplete="new-txtPractizManager" matInput maxlength="40" formControlName="txtPractizManager" type="text" />
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label> Practice Email </mat-label>
            <input autocomplete="new-txtPractizEmail" matInput maxlength="40" formControlName="txtPractizEmail" type="text" />
          </mat-form-field>
          <div class="col-12">
            <mat-checkbox formControlName="IsOutSrcReferrals" class="mt-3 ">Is This Location that outsource referrals
            </mat-checkbox>
          </div>
          <div class="col-12">
            <mat-checkbox formControlName="IsMedicareIDTF" class=" mt-3 ">Is Medicare ID TF</mat-checkbox>
          </div>
          <mat-form-field class="col-6">
            <mat-label> Account Manager </mat-label>
            <input autocomplete="new-txtAccountManger" matInput maxlength="40" formControlName="txtAccountManger" type="text" />
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label> Comment / Notes </mat-label>
            <textarea matInput formControlName="txtComtNotes" type="text"></textarea>
          </mat-form-field>
        </div>
      </mat-card-content>
    </div>
  </div>
  <br />
</form>
<br />
<div class="row">
  <div class="col-sm-12">
    <div class="text-lg-center">
      <button mat-button class="buttonColor" (click)="savePratzManager()"
        [disabled]="!( saveButtonHide ||!prtzManagForm.invalid && prtzManagForm.dirty )">
        Save
      </button>
    </div>
  </div>
</div>
