import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { defaultGuid } from '../claim-status-tab/claim-status-tab.component';
import { BillingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/billing-list.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ShippingApprovedNotesDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { ShippingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-list.service';
import { Subscription } from 'rxjs';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { Title } from '@angular/platform-browser';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-billing-list-tab',
  templateUrl: './billing-list-tab.component.html',
  styleUrls: ['./billing-list-tab.component.scss'],
})
export class BillingListTabComponent implements OnInit {
  isDataLoaded: boolean = false;
  activeTab: string = 'NewSetup';
  billingOrders: any[] = [];
  subscription$: Subscription[] = [];
  billingListApi: Subscription;
  constructor(
    private BillingListService: BillingListService,
    private communicationService: CommunicationService,
    private commonService: CommonService,
    private title: Title
  ) {
    const comService =
      this.communicationService.billingOrdersGetMethodCall$.subscribe(() => {
        var type =
          this.activeTab == 'NewSetup' || this.activeTab == 'NewSetupPending'
            ? 1
            : 0;
        var pendingType =
          this.activeTab == 'NewSetupPending' ||
          this.activeTab == 'SuppliesPending'
            ? true
            : false;
        this.loadBillingList(type, pendingType);
      });
    this.subscription$.push(comService);
  }

  ngOnInit() {
    this.title.setTitle('QSecure | Billing List');
    this.loadBillingList(1, false);
  }

  //On Destroy method
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //To load the Billing List
  loadBillingList(type, isPending) {
    this.isDataLoaded = false;
    if (this.billingListApi) {
      this.billingListApi.unsubscribe();
    }

    this.billingListApi =
      this.BillingListService.getBillingApprovedListV1ByNewSetupAndIsPending(
        type,
        isPending
      ).subscribe(
        (response) => {
          this.isDataLoaded = true;
          this.billingOrders = response;
          this.billingOrders = this.billingOrders.map((billing) => {
            return {
              ...billing,
              suppliesDate: this.commonService.getFormattedDateZone(
                billing.suppliesDate
              ),
              createdDate: this.commonService.getFormattedDateTimeZone(
                billing.createdDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                billing.modifiedDate
              ),
              backOrderSince: this.commonService.getFormattedDateZone(
                billing.backOrderSince
              ),
            };
          });
        },
        (err) => {
          this.isDataLoaded = true;
        }
      );
  }


  //On Tab Change
  changeTab(tab: string, billingType: any, isPending: any) {
    this.activeTab = tab;
    this.loadBillingList(billingType, isPending);
    // if (this.activeTab == 'NewSetup') {
    //   this.loadBillingList(billingType);
    // } else if (this.activeTab == 'NewSetupPending') {
    // } else if (this.activeTab == 'Supplies') {
    // } else if (this.activeTab == 'SuppliesPending') {
    // }
  }





}
