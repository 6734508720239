<div class="container-fluid">

  <div class="row ">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder">
          <b class="customThemeClass">List / Claim Dept / Claim Batch</b>
        </h3>
        <div class="page-title-box page-title-right">
          <!-- <ol class="breadcrumbs m-0 align-items-center"> -->
            <form [formGroup]="claimBatchForm">
            <div class="row d-flex justify-content-center align-items-center">
              <mat-form-field class="col-4 pt-2 customWidth">
                <!-- <mat-label>Select Date Range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate matInput placeholder="Start date" formControlName="txtDateValue" />
               (click)="getOrderDetails('data')"
                  <input matEndDate matInput placeholder="End date" formControlName="txtDate"
                    (dateChange)="onDateRangeChange()" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker> -->
                <mat-label>Select Date Range
                  <i class="fa fa-calendar ml-2" style="color:rgb(0, 0, 0)" matSuffix align="end"></i>
                 </mat-label>
                <input autocomplete="new-txtDateValue" matInput  type="text" name="daterange" ngxDaterangepickerMd
                [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"  [(ngModel)]="selectedRange"
                  [linkedCalendars]="false" [ranges]="ranges" [showClearButton]="false" [locale]="calendarLocale"
                   [keepCalendarOpeningWithRange]="true"  formControlName="txtDateValue"
                   (datesUpdated)="datesUpdateds($event)" [autoApply]="true" [readonly]="true" (keyup)="datesUpdateds($event)"
                  />
                  <!-- (keydown)="addSlashToDate($event)"  -->
                  <mat-error *ngIf="
                  claimBatchForm?.get('txtDateValue')?.touched &&
                  claimBatchForm?.get('txtDateValue')?.errors?.required
                ">
                Auth ID is a required field!
              </mat-error>
              </mat-form-field>

              <!-- <mat-form-field class="pr-2 dd-text">
                <mat-label>Provider</mat-label>
                <mat-select formControlName="drpProvider" (selectionChange)="onDateRangeChange()">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="txtProviderFilter"></ngx-mat-select-search>
                  </mat-option>


                </mat-select>
              </mat-form-field> -->
               <!-- Provider -->
               <mat-form-field class="col-4 pt-2">
                <mat-label>
                  <div class="select-placeholder-container" *ngIf="isLoadProvider">
                    <mat-spinner class="spinner" matSuffix diameter="20"></mat-spinner>
                  </div>
                  <span  *ngIf="!isLoadProvider">Provider</span>
                </mat-label>
                <mat-select formControlName="drpProvider"  (selectionChange)="getClaimBatchdetails()">
                  <!-- <mat-option [value]="0">All</mat-option> -->
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"  [formControl]="txtProviderControl"
                      formControlName="txtProviderFilter"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let providers of lstProviders"
                    [value]="providers.id"
                  >
                  {{ providers?.fullName }}
                    <!-- {{ providers?.fullName || '-' }} ({{ providers?.fileCount || 0 }}) -->
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="col-3 pt-2 dd-text">
                <mat-label>Status</mat-label>
                <mat-select formControlName="drpStatus" (selectionChange)="getClaimBatchdetails()">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"  [formControl]="txtStatusControl"
                      formControlName="txtStatusFilter"></ngx-mat-select-search>
                  </mat-option>

                  <mat-option *ngFor="let users of filteredStatusList" [value]="users.id">
                    {{ users.batchStatus }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img matTooltip="Check Color Code" (click)="OpenColorDiv()" class="pointer img-responsive mb-1 mr-1"
              height="33" src="assets/Claim Page Icons/color_drop.png" />
              </div>
            </form>
          <!-- </ol> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row p-2">
    <div class="" [ngClass]="iscolorCode === true ? 'col-11_5' : 'col-12'">
      <!-- <div class="card card-body p-2"> -->
        <div class="d-flex justify-content-center" *ngIf="claimbatchLoader">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
        <!-- <div  class="table table-responsive"> -->
          <app-claim-batch-table [DataList]="ClaimBatchList" *ngIf="!claimbatchLoader"></app-claim-batch-table>
        <!-- </div> -->
      <!-- </div> -->
    </div>
    <div *ngIf="iscolorCode" class="" [ngClass]="iscolorCode === true ? 'col-0_5' : ''">
      <div class="border d-flex align-items-center justify-content-center">
          <div class="p-1" >
              <div *ngFor="let color of lstStatus">
              <div [ngbTooltip]="color?.batchStatus" class="circle  mb-2" [style.backgroundColor]="color?.colorCode"></div>
              <!-- <div [ngbTooltip]="'Pending'" class="circle auth-no mb-2"></div>
              <div [ngbTooltip]="'Completed'" class="circle days mb-2"></div> -->
          </div>
          </div>
      </div>
  </div>

  </div>
  <!-- <div class="row">
    <div class="col-12">
      <app-claim-batch-table [DataList]="ClaimBatchList" *ngIf="!claimbatchLoader"></app-claim-batch-table>
    </div>
  </div> -->

  <!-- <div class="row" *ngIf="claimbatchLoader">
    <div class="col-12">
      <div class="d-flex justify-content-center">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
    </div>
  </div> -->
</div>

