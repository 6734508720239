import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { PatientDTO } from '../patient-proxy/patient/dto/models';
import { PatientService } from '../patient-proxy/patient/patient.service';
import { addAuthorization, addPatient, addPatientIntake, addVerification, getPatient, updatePatient, updatePatientIntake } from './patient.action';


@State<pateintStateModel>({
  name: 'patientState',
  defaults: {
    patients: [],
    selectedPatient: null
  }
})
@Injectable()
export class PatientState {
  constructor(
    private pateintService: PatientService,
  ) { }

  @Action(addPatient)
  addPatient({ patchState }: StateContext<pateintStateModel>, { payload }: addPatient) {
    return this.pateintService.create(payload).pipe(tap((result) => {
      patchState({
        selectedPatient: result
      });
    }));
  }

  @Action(addPatientIntake)
  addPatientIntake({ patchState }: StateContext<pateintStateModel>, { payload }: addPatientIntake) {
    return this.pateintService.createPatientIntakeByPatientIntakeDto(payload).pipe(tap((result) => {
      patchState({
        selectedPatient: result
      });
    }));
  }

  @Action(addVerification)
  addVerification({ patchState }: StateContext<pateintStateModel>, { id,payload }: addVerification) {
    return this.pateintService.createInsuranceVerificationByPatientIdAndInsuranceVerifications(id,payload).pipe(tap((result) => {
      patchState({
        selectedPatient: result
      });
    }));
  }


  @Action(updatePatientIntake)
  updatePatientIntake({ patchState }: StateContext<pateintStateModel>, { id,payload }: updatePatientIntake) {
    return this.pateintService.updatePatientIntakeByPatientIdAndPatientIntakeDto(id,payload).pipe(tap((result) => {
      patchState({
        selectedPatient: result
      });
    }));
  }

  @Action(addAuthorization)
  addAuthorization({ patchState }: StateContext<pateintStateModel>, { id,payload }: addAuthorization) {
    return this.pateintService.createAuthorizationByPatientIdAndAuthorizations(id,payload).pipe(tap((result) => {
      patchState({
        selectedPatient: result
      });
    }));
  }

  @Action(updatePatient)
  updatePatient({ patchState }: StateContext<pateintStateModel>, { id, payload }: updatePatient) {
    return this.pateintService.update(id, payload).pipe(tap((result) => {
      patchState({
        selectedPatient: result
      });
    }));
  }
  @Action(getPatient)
  getSelectedPatientId({ patchState }: StateContext<pateintStateModel>, { id }: getPatient) {
    return this.pateintService.get(id).pipe(tap((result) => {
      patchState({
        selectedPatient: result
      });
    }));
  }









}
export class pateintStateModel {
  patients: PatientDTO[];
  selectedPatient: PatientDTO|null;
}
