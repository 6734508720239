<div class="container-fluid mt-3">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h2 class="font-weight-bolder mb-3">
                    <b class="header-class">List / Practice Management / Practice List</b>
                </h2>

                <div class="page-title-box page-title-right pt-3 pb-0">
                    <form [formGroup]="practicelistForm">
                        <ol class="breadcrumb m-0">
                            <!-- Date Picker -->
                            <!-- <mat-form-field class="pr-2 pt-1 mr-3 mt-1">
                                <input placeholder="Month" formControlName="txtDateValue" matInput [(value)]="dateValue"
                                    readonly (focus)="openDatePicker()" />
                                <input matInput maxlength="40" formControlName="txtDate" type="text" [hidden]="true"
                                    autocomplete="new-txtDateValue" matInput [(value)]="dateValue"
                                    [matDatepicker]="txtDateValue" [max]="maxDate" />
                                <mat-datepicker-toggle matSuffix [for]="txtDateValue"></mat-datepicker-toggle>
                                <mat-datepicker #txtDateValue startView="multi-year"
                                    (monthSelected)="chosenMonthHandler($event, txtDateValue)"></mat-datepicker>
                            </mat-form-field> -->
                            <form class="form-horizontal" [formGroup]="searchForm">
                              <mat-form-field class="col-12">
                                <mat-label> Practice Name </mat-label>
                                <input
                                  type="text"
                                  matInput
                                  (ngModelChange)="practiceFilter()"
                                  formControlName="sSearch"
                                  placeholder="Practice Name"
                                />
                              </mat-form-field>
                            </form>
                            <!--Active/Inctive-->
                            <app-toggle-switch [onText]="'Inactive'" [offText]="'Active'" class="mr-3 mt-3"
                            (changed)="change($event)">
                            </app-toggle-switch>
                            <li>
                                <!-- <button mat-button class="buttonColor font-size-10 mt-3">
                                    <a [routerLink]="'/addpracticemanagement/'" target="_blank" class="preview-link practicecls">
                                        <i class="fa fa-plus mr-1" matTooltip="Add Practice"></i>Add Practice
                                    </a>
                                </button> -->
                                <button mat-button class="buttonColor font-size-14 mt-3" (click)="newPractice()">
                                    <i class="fa fa-plus"></i>
                                    &nbsp; Add Practice
                                </button>
                            </li>

                        </ol>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-body mt-3">
        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-1">
                <div *ngIf="!isLoading" class="table table-responsive">
                    <app-practice-management-v1 [tableData]="practicelistdata"></app-practice-management-v1>
                </div>
            </div>
        </div>
    </div>
</div>
