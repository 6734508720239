import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sale-order-override-modal',
  templateUrl: './sale-order-override-modal.component.html',
  styleUrls: ['./sale-order-override-modal.component.scss'],
})
export class SaleOrderOverrideModalComponent implements OnInit {
  overrideForm: FormGroup;
  overrideNotes: string = '';
  OverrideTypeTxt:string='';
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private dialog: MatDialogRef<SaleOrderOverrideModalComponent>,
    private mmOrderService: MmOrderService,
    private communicationService: CommunicationService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      patientId: string;
      orderDto?: any;
      overrideType?:number;
    } = {
      orderId: defaultGuid,
      patientId: defaultGuid,
      orderDto: null,
      overrideType:0
    }
  ) {

  }
  isOrderPageLoading:any;
  ngOnInit() {
    this.OverrideTypeTxt=this.data.overrideType>=5?'Patient Balance Override':'Shipping Override';
    this.initializeForms();
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
  }

  initializeForms() {
    this.overrideForm = this.fb.group({
      txtOverrideType: new FormControl(this.OverrideTypeTxt),
      txtOverrideNotes: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
    });
  }
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.overrideNotes?.length > maxlength) {
      this.overrideNotes = this.overrideNotes?.substr(0, maxlength);
    }
  }

  //To save and update the order details,mark as override or move to shipping approval
  saveUpdateOrder(isOverride: boolean) {
    const orderDetails = this.data?.orderDto ?? null;
    orderDetails.isOverride = isOverride;
    orderDetails.overrideType =
      this.overrideForm.get('txtOverrideType').value.trim() ?? '';
    orderDetails.overrideNote =
      this.overrideForm.get('txtOverrideNotes').value.trim() ?? '';
      this.loaderService.showLoader();
    if (
      this.data.orderId != null &&
      this.data.orderId != '' &&
      this.data.orderId != undefined &&
      this.data.orderId != defaultGuid
    ) {
      this.mmOrderService
        .updateOrderOverrideByIdAndInputData(this.data.orderId, orderDetails)
        .subscribe(
          (response) => {


            isOverride
              ? this.toaster.success('Order Marked as Override')
              : this.toaster.success('Order moved to Shipping Approval');
            this.communicationService.triggerLoadOrderDetails({
              patientId: response?.patientId ?? defaultGuid,
              orderId: response?.id ?? defaultGuid,
            });
            this.loaderService.hideLoader();
            this.dialog.close(true);
          },
          (err) => {
            this.loaderService.hideLoader();
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    } else {
      this.mmOrderService.createOverrideOrderByInput(orderDetails).subscribe(
        (response) => {
          isOverride
            ? this.toaster.success('Order Marked as Override')
            : this.toaster.success('Order moved to Shipping Approval');
          this.communicationService.triggerLoadOrderDetails({
            patientId: response?.patientId ?? defaultGuid,
            orderId: response?.id ?? defaultGuid,
          });
          this.loaderService.hideLoader();
          this.dialog.close(true);
        },
        (err) => {
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
}
