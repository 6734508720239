<div class="card card-body">
    <form [formGroup]="requirementForm">
        <div class="row">
            <div class="col-12">
                <ng-container class="col-3 mt-3">
                    <mat-checkbox class="mr-2" formControlName="medicareidtf"><span
                            class="font-weight-bolder mr-5">Medicare IDTF</span></mat-checkbox>
                </ng-container>
                <ng-container class="col-3 mt-3">
                    <mat-checkbox class="mr-2" formControlName="outsource"><span class="font-weight-bolder mr-5">Is this
                            location that
                            outsource referrals
                        </span></mat-checkbox>
                </ng-container>
                <ng-container class="col-3 mt-3">
                    <mat-checkbox class="mr-2" formControlName="modemRequires"><span
                            class="font-weight-bolder mr-5">Modem
                            Requires
                        </span></mat-checkbox>
                </ng-container>
                <ng-container class="col-3 mt-3">
                    <mat-checkbox class="mr-2" formControlName="unitcompliance"><span
                            class="font-weight-bolder mr-5">Unit
                            compliance met
                        </span></mat-checkbox>
                </ng-container>

            </div>
        </div>

        <div class="row mt-3">


            <!--Duration-->
            <mat-form-field class="col-4">
                <mat-label>Duration</mat-label>
                <input autocomplete="new-txtduration" matInput formControlName="txtduration" type="text" maxlength="2"
                    oninput="this.value = this.value.replace(/[^0-9]+/g, '').replace(/^0+/, '');" />
            </mat-form-field>

            <!-- Name -->
            <mat-form-field class="col-4">
                <mat-label>Name</mat-label>
                <input autocomplete="new-txtRequirementName" matInput formControlName="txtRequirementName" type="text"
                    maxlength="40"
                    oninput="this.value = this.value.replace(/[^a-zA-Z\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);" />
                <!-- <mat-error
                    *ngIf=" requirementForm?.get('txtRequirementName')?.touched &&  requirementForm?.get('txtRequirementName')?.hasError('required')">
                    Name is required field!
                </mat-error> -->
                <mat-error
                    *ngIf=" requirementForm?.get('txtRequirementName')?.touched &&  requirementForm?.get('txtRequirementName')?.errors?.sAlphabets">
                    Enter only Alphabets!
                </mat-error>
            </mat-form-field>

            <!-- Email -->
            <mat-form-field class="col-4">
                <mat-label> Email</mat-label>
                <input autocomplete="new-txtRequirementEmail" matInput maxlength="40"
                    formControlName="txtRequirementEmail" type="text"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    [textMask]="{ mask: emailMask }" />

                <mat-error *ngIf="
                requirementForm?.get('txtRequirementEmail')?.touched &&
                requirementForm?.get('txtRequirementEmail')?.errors
            ?.pattern
        ">
                    This is not a valid Email!!!
                </mat-error>
            </mat-form-field>

            <!-- Fax No-->
            <mat-form-field class="col-4">
                <mat-label> Fax No</mat-label>
                <input matInput maxlength="40" formControlName="txtRequirementFaxNo" type="text" mask="(000)-000-0000"
                    prefix="+1-" />
            </mat-form-field>

            <!--Preferred Product Drop Down-->

            <mat-form-field class="col-4">
                <mat-label>Preferred Product Brand</mat-label>
                <input matInput maxlength="40" formControlName="txtProductBrand" type="text">
            </mat-form-field>
            <!-- <mat-form-field class="col-4">
                <mat-label>Preferred Product</mat-label>
                <mat-select formControlName="drpProductBrand" #singleSelect>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="ProductTypeControl"
                            placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                            formControlName="txtProductBrand"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let users of lstProductBrand" [value]="users?.userId">
                        {{ users?.productName }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->


            <!-- <div class="card card-body mt-3"> -->
            <!-- <div class="row mt-3"> -->
            <div class="col-12 mt-1">
                <mat-divider></mat-divider>
            </div>
            <div class="col-12 d-flex justify-content-between mt-3">
                <mat-label><b class="customThemeClass">Point of Contact</b></mat-label>
            </div>

            <!-- Name -->
            <mat-form-field class="col-3">
                <mat-label>Name</mat-label>
                <input autocomplete="new-txtPocName" matInput formControlName="txtPocName" type="text" maxlength="40"
                    oninput="this.value = this.value.replace(/[^a-zA-Z\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);" />
            </mat-form-field>

            <!-- Email -->
            <mat-form-field class="col-3">
                <mat-label> Email</mat-label>
                <input autocomplete="new-txtPocEmail" matInput maxlength="40" formControlName="txtPocEmail" type="text"
                   pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    [textMask]="{ mask: emailMask }" />

                <mat-error
                    *ngIf="requirementForm?.get('txtPocEmail')?.touched && requirementForm?.get('txtPocEmail')?.errors ?.pattern">
                    This is not a valid Email!!!
                </mat-error>
            </mat-form-field>

            <!-- Fax No-->
            <mat-form-field class="col-3">
                <mat-label> Fax No</mat-label>
                <input matInput maxlength="40" formControlName="txtPocFaxNo" type="text" mask="(000)-000-0000"
                    prefix="+1-" />
            </mat-form-field>

            <!-- Phone No-->
            <mat-form-field class="col-3">
                <mat-label> Phone No</mat-label>
                <input matInput maxlength="40" formControlName="txtPcoPhoneNo" type="text" mask="(000)-000-0000"
                    prefix="+1-" />
            </mat-form-field>

            <!--Other Preference Field-->
            <mat-form-field class="col-12 mt-1">
                <mat-label>Other Preference</mat-label>
                <textarea matInput rows="5" autocomplete="off" maxlength="500" formControlName="txtPreference"
                    type="text" prefi></textarea>
            </mat-form-field>
            <!-- </div> -->
            <!-- </div> -->

        </div>

        <div class="modal-footer mt-3">
            <div class="col-12 text-center mt-3">
                <!-- <button mat-button class="buttonColor mr-2"
                    [disabled]="isShowSpinner || !(!requirementForm?.invalid && requirementForm?.dirty)"
                    (click)="saveRequirements()">
                    <span>{{ sessionPracticeId ? 'Update' : 'Save' }}</span>
                    <mat-icon *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon>
                </button> -->
                <button mat-button class="buttonColor mr-2" [disabled]="isShowSpinner || saveButtonDisabled || requirementForm?.invalid"
                    (click)="saveRequirements()">
                    <span>{{ sessionPracticeId ? 'Update' : 'Save' }}</span>
                    <mat-icon *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon>
                </button>
                <button mat-button class="resetclr mr-2" (click)="resetRequirement()">Reset</button>
            </div>
        </div>
    </form>
</div>