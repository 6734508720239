import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  constructor(
    private select: MatSelect,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {}
  close() {
    this.select.close();
  }
  // Handle click outside the dropdown to close it
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (!this.table.nativeElement.contains(event.target)) {
      this.close();
    }
  }
  items = [
    { column1: 'Data 1A', column2: 'Data 1B' },
    { column1: 'Data 2A', column2: 'Data 2B' },
    // Add more items as needed
  ];
}
