import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-patient-search-newsetup-table',
  templateUrl: './patient-search-newsetup-table.component.html',
  styleUrls: ['./patient-search-newsetup-table.component.scss']
})
export class PatientSearchNewsetupTableComponent implements OnInit {

  @Input() tableData :any;
  @Input() patientID :string;
  tblSelectedPatientId: string;
  patientIDV3:string
  patientSearchList:any;
  defaultGuid = defaultGuid;
  patientSearchOptions: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    info: true,
    dom: 'lrti',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true,
  };
  constructor(private snackBar: MatSnackBar, private elementRef: ElementRef,  private dialog: MatDialog,private communicationService: CommunicationService) {}

  ngOnInit(): void {
    this.patientIDV3=this.patientID;
    this.patientSearchList=this.tableData;
  }
  fnSelectedRow(id: string, patientId: string) {
    this.tblSelectedPatientId = patientId;
  }
  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  // getFormattedDate(date: any): string {
  //   return date
  //     ? new Date(date).toLocaleString('en-US', {
  //       month: '2-digit',
  //       day: '2-digit',
  //       year: 'numeric',
  //       hour: 'numeric',
  //       minute: 'numeric',
  //       hour12: true,
  //     })
  //     : '-';
  // }
  addPatient(patientIdInput: any) {
    this.communicationService.triggerPatchPatientDetails(patientIdInput);
  }
}
