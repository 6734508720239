import type {
  CreateUpdateCreditCardDetailsDTO,
  CreditCardDetailsDTO,
} from './dto/models';

import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreditCardDetailsService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateCreditCardDetailsDTO) =>
    this.restService.request<any, CreditCardDetailsDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/credit-card-details',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/orderApp/credit-card-details/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, CreditCardDetailsDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/credit-card-details/${id}`,
      },
      { apiName: this.apiName }
    );

  getAllActiveCreditCardList = () =>
    this.restService.request<any, CreditCardDetailsDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/credit-card-details/active-credit-card-list',
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<CreditCardDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/orderApp/credit-card-details',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateCreditCardDetailsDTO) =>
    this.restService.request<any, CreditCardDetailsDTO>(
      {
        method: 'PUT',
        url: `/api/orderApp/credit-card-details/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
