import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AddBranchWorkHoursComponent } from 'projects/on-time-schedules/src/app/add-branch-work-hours/add-branch-work-hours.component';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { defaultCountry, defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { TenantsService } from '../admin-proxy//platform-app-management/rcm/platform-management/tenant/tenants.service';
import { CreateUpdateFTPUnitDTO, CreateUpdateFaxUnitDTO, CreateUpdateOrganizationUnitDTO, OrganizationUnitDTO, SearchOrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { UserProfileDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/user/models';
// import { dateYYYYMMDDtoMMDDYYYY } from '../sub-batch-list/sub-batch-list.component';
import { ToastrService } from "ngx-toastr";
import { Title } from '@angular/platform-browser';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
// import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { DataTableDirective } from 'angular-datatables';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { TenantNameEmailValidationDto } from '../admin-proxy/platform-app-management/rcm/platform-management/tenants';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { UsernameEmailValidationDto } from '../admin-proxy/platform-app-management/rcm/platform-management/users/dto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
  providers: [DatePipe]
})

export class BranchComponent implements OnInit {
  // dtOptions: any = {
  //   responsive: true,
  //   pageLength: 10,
  //   lengthMenu: [10, 25, 50, 100, 200],
  // };
  // dtOptions: DataTables.Settings = {};
  dtOptions: any;
  faxStatus:number;
  ftStatus:number;
  isAlreadyUsed:boolean=false;
  @Input() tableBranch: any;
  @Input() viewstatus: number;

  dtBranchListOptions = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      // { targets: [0, 1], orderable: false },
      { targets: [6,7,8,9,10,11,12,13,14,15,16,17], visible: false },
      { targets: [0, 1, -1], className: 'dt-fixed-column' }
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Branch List',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(-1))', // Exclude first three and last columns from the column visibility toggle
      }
    ],
  };

  // dtFaxEmailListOptions: any = {
  //   responsive: true,
  //   stateSave: true,
  //   paging: false,
  //   dom: 'Bfrtip',
  //   language: {
  //     emptyTable: 'No records',
  //     info: 'Total : _MAX_ records',
  //     infoEmpty: 'Total : _MAX_ records',
  //     infoFiltered: '(filtered : _TOTAL_ records)',
  //     loadingRecords: 'Loading...',
  //     zeroRecords: 'No matching records',
  //   },
  //   scrollCollapse: true,
  //   scrollY: '300px',
  //   scrollX: true,
  //   fixedHeader: true, // Fix the header
  //   columnDefs: [
  //     { targets: [0], orderable: false }
  //   ],
  //   search: {
  //     smart: false,
  //   },
  //   buttons: [
  //     {
  //       extend: 'excel',
  //       text: 'Export',
  //       filename: 'Fax/Email List',
  //       exportOptions: {
  //         columns: ':visible:not(:first-child) ',
  //       },
  //     },
  //     {
  //       extend: 'colvis',
  //       columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
  //     }
  //   ],
  // };

  // dtFTPListOptions: any = {
  //   responsive: true,
  //   stateSave: true,
  //   paging: false,
  //   dom: 'Bfrtip',
  //   language: {
  //     emptyTable: 'No records',
  //     info: 'Total : _MAX_ records',
  //     infoEmpty: 'Total : _MAX_ records',
  //     infoFiltered: '(filtered : _TOTAL_ records)',
  //     loadingRecords: 'Loading...',
  //     zeroRecords: 'No matching records',
  //   },
  //   scrollCollapse: true,
  //   scrollY: '300px',
  //   scrollX: true,
  //   fixedHeader: true, // Fix the header
  //   columnDefs: [
  //     { targets: [0], orderable: false }
  //   ],
  //   search: {
  //     smart: false,
  //   },
  //   buttons: [
  //     {
  //       extend: 'excel',
  //       text: 'Export',
  //       filename: 'FTP List',
  //       exportOptions: {
  //         columns: ':visible:not(:first-child) ',
  //       },
  //     },
  //     {
  //       extend: 'colvis',
  //       columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
  //     }
  //   ],
  // };

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }

  // dtOptionsBranch: any;
  // dtOptionsFax: any;
  // dtOptionsFtp: any;
  step: boolean = false;
  tenantId: string = '';
  phoneCode: string = '';
  userId: string = '';
  role: string = "";
  branch: string = "";
  drpOffice: any[] = [];
  branchDetails: any;
  faxDetails: any;
  FTPDetails: any;
  //  id:string;
  panelOpenState = false;
  panelOpenState1 = false;
  panelOpenState2 = false;
  FaxAddShow = true;
  BranchForm: FormGroup;
  // FaxForm: FormGroup;
  hidecountry: boolean = true;
  FTPForm: FormGroup;
  $subscription: Subscription[] = [];
  seletedBranchValue: string = defaultGuid;
  seletedBranchValues: string = defaultGuid;
  seletedFaxValue: number;
  filteredBranchTypeList: OrganizationUnitDTO[];
  lstBranch: OrganizationUnitDTO[] = [];
  ontimeBranchForm: FormGroup;
  isLoading: boolean;
  @Input() clientId: string = '';
  branchTableData: any[] = [];
  branchId: string = "";
  emailMask: any;
  inActiveHide: boolean = true;
  showTable: boolean = true;
  branchTab: boolean = true;
  FaxTab: boolean = false;
  FTPTab: boolean = false;
  selectedOption: string;
  saveButtonHide: boolean = false;
  saveBranchButtonHide: boolean = false;
  isShowSpinner: boolean = false;
  searchLoading: boolean = false;
  isTableData: boolean = false;
  Country: string = "";
  State: string = "";
  City: string = "";
  status: string = '';
  isActive: number;
  stateName: string = "";
  CountryName: string = "";
  branchName: string = '';
  chartSuffix: string = '';
  FaxPath: string = '';
  FaxQueueName: string = '';
  DispenseInventory: number;
  MaxInventoryLimit: number;
  // NPI:string;
  // taxId:string;
  locationURL: string = '';
  AppointmentNoteLocation: string = '';
  addWorkingButton: boolean = false
  loginResponse: UserProfileDTO;
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  drpCountry: RegionCountriesDTO[] = [];
  isConfirmBranchLocation: boolean = false;
  branchLocation: string = '';
  public latitude: number;
  public longitude: number;
  mapHide: boolean = true;
  faxid: string = '';
  ftpid: string = '';
  hide = true;
  param: number;
  param1: number;
  param2: number;
  faxName: any;
  //Accordion Toggle Methods
  toggle() {
    this.panelOpenState = true;
  }
  toggleV2() {
    this.panelOpenState = false;
  }
  constructor(private formBuilder: FormBuilder, private officialService: TenantsService,
    private branchService: OrganizationUnitService, private Table: TableService,
    private activatedRoute: ActivatedRoute,
    private regionDropdownService: RegionDropdownService,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    private onTimeDropdown: OnTimeMasterDropdownService,
    private mapsAPILoader: MapsAPILoader,
    private userService: UserService,
    private toastr: ToastrService,
    public title: Title,
    public dateValidators: DateValidator,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private commonService : CommonService
    // public notesDialogRef: MatDialogRef<AddBranchWorkHoursComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: { branchId: string, wrokingbranchId: string, BranchName: string }
  ) {
    this.communicationService.functionLoadFaxList$.subscribe((statusFax) => {
      this.FaxStatusFilter(statusFax);
    });
    this.communicationService.functionLoadFtpList$.subscribe((statusFtp) => {
      this.FTPStatusFilter(statusFtp);
    });
  }
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  @ViewChild('secondAccordion') secondAccordion: MatAccordion;
  @ViewChild('thirdAccordion') thirdAccordion: MatAccordion;
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>();
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @ViewChild(AgmMap) agmmap: AgmMap;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  datatableElementList: QueryList<DataTableDirective>;
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  displayedColumns: string[] = ['Option', 'branchName', 'email', 'fax', 'address', 'createdDate', 'Status',];


  ngOnInit(): void {

    this.dtBranchListOptions.buttons[0].filename =
    'Branch List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    // this.selectedOption = "Active";
    this.fnselectedOption();
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.loadDropdown();
    this.title.setTitle("Qsecure | Branch");
    this.BranchForm = this.formBuilder.group({
      /// txtCompanyName: new FormControl(""),
      txtBranchName: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtAddress: new FormControl("", [Validators.required, noWhitespaceValidator]),
      // txtEmail: new FormControl("", [Validators.required, Validators.email]),
      txtPhoneNumber: new FormControl("", [Validators.required]),
      txtAddress2: new FormControl(""),
      drpCountry: new FormControl(defaultCountry.US),
      drpState: new FormControl("", [Validators.required]),
      drpCity: new FormControl("", [Validators.required]),
      txtPostalCode: new FormControl("", [Validators.required]),
      drpCityFilter: new FormControl(""),
      txtCountryFilter: new FormControl(""),
      drpStateFilter: new FormControl(""),
      //drpOffice: new FormControl("", [Validators.required]),
      txtChartSuffix: new FormControl("", [Validators.required, noWhitespaceValidator], this.checkChartSuffixUniqness()),
      txtMaxInventoryLimit: new FormControl("", [Validators.required]),
      // txtNPI:new FormControl("", [Validators.required]),
      // txtTaxId:new FormControl("", [Validators.required]),
      txtFaxPath: new FormControl(""),
      txtFaxQueueName: new FormControl(""),
      chkFaxQueue:new FormControl(""),
      chkisAppoinment:new FormControl(false),
      // txtFaxNumber: new FormControl(""),
      // txtPassword: new FormControl(""),
      // txtEmailPort: new FormControl(""),
      // txtEmailProtocol: new FormControl(""),
      // txtEmailHost: new FormControl(""),
    });
    // this.FaxForm = this.formBuilder.group({
    //   drpBranchType: new FormControl("", [Validators.required]),
    //   drpFaxType: new FormControl("", [Validators.required]),
    //   txtPassword: new FormControl("", [Validators.required]),
    //   txtEmail: new FormControl("", [Validators.required]),
    //   //  txtFaxNumber: new FormControl("",[this.checkFaxNoUniqness()]),
    //   txtFaxNumber: new FormControl(""),
    //   txtFaxName: new FormControl(""),
    //   txtEmailPort: new FormControl("", [Validators.required]),
    //   txtEmailProtocol: new FormControl("", [Validators.required]),
    //   txtEmailHost: new FormControl("", [Validators.required, noWhitespaceValidator]),
    // });
    this.FTPForm = this.formBuilder.group({
      drpBranchTypes: new FormControl("", [Validators.required]),
      txtServerIP: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtPortNumber: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtUserName: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtUserPassword: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtSourceFolder: new FormControl("", [Validators.required, noWhitespaceValidator]),
      txtDesFolder: new FormControl("", [Validators.required, noWhitespaceValidator]),
    });
    this.ontimeBranchForm = this.formBuilder.group({
      branchName: new FormControl(""),
      address: new FormControl(""),
      phoneNumber: new FormControl(""),
      createdDate: new FormControl('', this.dateValidators.dateVaidator),
      status: new FormControl(""),
      email: new FormControl(""),
      type: new FormControl(""),
      faxNumber: new FormControl(""),
    });
    // this.isLoading=true;
    // this.getBranchList();
    this.getDropDown();
    this.countryDropdown();

    this.branchDetails = this.tableBranch;
    // this.branchStatusFilter(1);
    this.BranchForm.patchValue({ "drpCountry": defaultCountry.US })
    // this.Table.gettBranchCreate().subscribe(response => {
    //   this.inActiveHide = response == true ? true : false;
    // });

    this.Table.getBranchView().subscribe(response => {
      this.showTable = response == true ? true : false;
    });

    this.Table.getclientLoginView().subscribe(response => {
      if (response?.tenantId != null && response?.roleName == 'admin' && response?.organizationUnitId == null) {
        this.clientId = response?.tenantId;
        this.loginResponse = response;
        this.getCompanyName(response);
      } else {
        this.clientId != null && this.clientId != "" && this.clientId != undefined && this.getBranch()
      }
    })

    // this.clientId != null && this.clientId != "" && this.clientId != undefined && this.getBranchList()
    this.emailMask = emailMask;
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tblBranch').DataTable().columns.adjust().draw();
    }, 200);
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  // refreshDataTable(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }

  ngOnDestroy(): void {
    this.$subscription.forEach(sub => {
      sub && sub?.unsubscribe();
    })
  }
  checkChartSuffixUniqness(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.officialService.checkChartSuffixExistsByInput(String(control?.value ?? "")?.trim() ?? "", this.branchId)
        .pipe(
          tap(x => control?.setErrors({ 'isChartSuffixExists': true })),
          debounceTime(300),
          map(response => response === true ? { 'isChartSuffixExists': true } : (control?.setErrors(null) == null) ? null : null));
    }
  }
  // checkEmailUniqness(): AsyncValidatorFn {
  //   return (
  //     control: AbstractControl
  //   ): Observable<{ [key: string]: any } | null> => {
  //     const gUser = this.userId == '' ? defaultGuid : this.userId;
  //     const input: UsernameEmailValidationDto = {
  //       username: '',
  //       emailAddress:
  //         String(control?.value ?? '')
  //           ?.toLowerCase()
  //           ?.trim() ?? '',
  //       isUserNameExists: false,
  //       isEmailAddressExists: false,
  //       // userId:"4472e4c3-968b-b08b-c11a-3a0d4e4e48f2",
  //       userId: gUser,
  //     };
  //     return this.userService.checkEmailIfExistsByInput(input).pipe(
  //       tap((x) => control?.setErrors({ isEmailAddressExists: true })),
  //       debounceTime(300),
  //       map((response) =>
  //         response?.isEmailAddressExists === true
  //           ? { isEmailAddressExists: true }
  //           : control?.setErrors(null) == null
  //           ? null
  //           : null
  //       )
  //     );
  //   };
  // }
  // checkFaxNoUniqness(): AsyncValidatorFn {
  //   return (
  //     control: AbstractControl
  //   ): Observable<{ [key: string]: any } | null> => {
  //     const sFaxNumber=String(control?.value ?? '')
  //           ?.toLowerCase()
  //           ?.trim() ?? '';
  //     return this.userService.checkFaxIfExistsByInput(sFaxNumber).pipe(
  //       tap((x) => control?.setErrors({ isFaxNumberExists: true })),
  //       debounceTime(300),
  //       map((response) =>
  //         response === true
  //           ? { isFaxNumberExists: true }
  //           : control?.setErrors(null) == null
  //           ? null
  //           : null
  //       )
  //     );
  //   };
  // }

  // branchStatusFilter(status: number) {
  //   this.isLoading = true;
  //   const branchData = this.branchService.getbranchStatusFilter(status).subscribe(
  //     (response) => {
  //       this.branchDetails = response.items;
  //       this.isLoading = false;
  //     },
  //     (error) => {
  //       console.error('Error fetching branch details:', error);
  //       this.isLoading = false;
  //     }
  //   );
  //   this.$subscription.push(branchData);
  // }

  FaxStatusFilter(status: number) {
    this.isLoading = true;
    const faxData = this.branchService.getFaxStatusFilter(status,this.tenantId).subscribe(
      (response) => {
        this.faxDetails = response;
        this.faxDetails = this.faxDetails.map((data) => {
          return {
            ...data,
            createdDate: this.commonService.getFormattedDateTimeZone(
              data.createdDate
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              data.lastModificationTime
            ),
          };
        });
        this.faxStatus=status;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching branch details:', error);
        this.isLoading = false;
      }
    );
    this.$subscription.push(faxData);
  }

  FTPStatusFilter(status: number) {
    this.isLoading = true;
    const faxData = this.branchService.getFTPStatusFilter(status,this.tenantId).subscribe(
      (response) => {
        this.FTPDetails = response;
        this.FTPDetails = this.FTPDetails.map((data) => {
          return {
            ...data,
            creationTime: this.commonService.getFormattedDateTimeZone(
              data.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              data.lastModificationTime
            ),
          };
        });
        this.ftStatus=status;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching branch details:', error);
        this.isLoading = false;
      }
    );
    this.$subscription.push(faxData);
  }
  getOrganizationUnitName(branchId: string, branchName: string): string {
    const selectedBranch = this.filteredBranchTypeList.find(branch => branch.id === branchId);
    return selectedBranch ? selectedBranch.organizationUnitName : branchName;
  }
  // getBranchList() {
  //   this.isLoading = true;
  //   // const cChangeValue = this.ontimeBranchForm.valueChanges.pipe(
  //   //   startWith({
  //   //     branchName: '',
  //   //     address: '',
  //   //     phoneNumber: '',
  //   //     createdDate: '',
  //   //     status: '',
  //   //     email: '',
  //   //     //type: '',
  //   //     faxNumber: ''
  //   //   }),
  //   //   tap((x) => {
  //   //     this.isLoading = true;
  //   //   }),
  //     // filter((e) => this.ontimeBranchForm?.valid),
  //     // debounceTime(300),
  //     // map((response) => {
  //     //   this.searchLoading = true;
  //     //   const sValue = {
  //     //     branchName: response?.branchName?.trim().toLowerCase(),
  //     //     address: response?.address?.trim().toLowerCase(),
  //     //     phoneNumber: response?.phoneNumber?.trim().toLowerCase(),
  //     //     createdDate: response?.createdDate?.trim().toLowerCase(),
  //     //     status: response?.status?.trim().toLowerCase(),
  //     //     email: response?.email?.trim().toLowerCase(),
  //     //     //type: response?.type?.trim().toLowerCase(),
  //     //     tenantId: this.clientId ?? defaultGuid,
  //     //     faxNumber: response?.faxNumber.trim().toLowerCase()
  //     //   };

  //     //   let searchInput: SearchOrganizationUnitDTO = {
  //     //     organizationUnitName: sValue.branchName,
  //     //     emailAddress: sValue.email,
  //     //     address: sValue.address,
  //     //     phoneNumber: sValue.phoneNumber,
  //     //     status: sValue.status,
  //     //     //officeType: sValue.type,
  //     //     createdDate: dateYYYYMMDDtoMMDDYYYY(sValue.createdDate),
  //     //     tenantId: sValue.tenantId,
  //     //     faxNumber: sValue.faxNumber
  //     //   }
  //     //   return searchInput;
  //     // }),
  //     //  switchMap((searchInput) =>
  //     //   //  this.branchService.searchOrganizationByInput(searchInput)
  //     //  )
  //   // )
  //   //   .subscribe(
  //   //     (response) => {
  //   //       this.searchLoading = false;
  //   //       this.isLoading = true;
  //   //       this.branchDetails = response.items;
  //   //       this.branchTableData = [];
  //   //       response?.items.forEach(element => {
  //   //         this.branchTableData.push({
  //   //           id: element.id,
  //   //           branchName: element?.organizationUnitName,
  //   //           address: element?.address1,
  //   //           contact: element?.phoneNumber,
  //   //           createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
  //   //           status: element?.status,
  //   //           tenentId: element?.tenantId,
  //   //           workingHoursId: element?.workingHoursId,
  //   //           //officeType: element?.officeType,
  //   //           email: element?.emailAddress,
  //   //           faxNumber: element?.faxNumber
  //   //         });
  //   //       })
  //   const branchList = this.branchService.getbranchStatusFilter(1).subscribe(
  //           (response) => {
  //             this.branchDetails = response.items;
  //             this.isLoading = false;
  //           });
  //           this.$subscription.push(branchList);
  //         // this.dataSource = new MatTableDataSource(this.branchTableData);
  //         // this.dataSource.sort = this.sort;
  //         // this.dataSource.paginator = this.paginator;
  //       //}
  // }

  //! get branch by id
  getBranchById(id: string) {
    // this.addWorkingButton = true;
    this.saveButtonHide = false;
    this.branchId == '' ? this.addWorkingButton = true : this.addWorkingButton = false
    this.branchService.get(id).subscribe(response => {
      this.wrokingbranchId = response.workingHoursId
      this.status = response?.status;
      this.stateName = response?.state;
      // this.CountryName = defaultCountry.US;
      this.CountryName = response?.country;
      this.branchName = response?.organizationUnitName;
      this.branchId = response?.id ?? "";
      this.chartSuffix = response?.chartSuffix;
      // this.NPI=response?.npi;
      // this.taxId=response?.taxId;
      // this.FaxPath=response?.faxPathId;
      this.FaxQueueName=response?.faxQueueName;
      this.MaxInventoryLimit = response?.maxInventoryValue;
      this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
        // this.onChangeState(defaultCountry.US) && this.BranchForm.patchValue({
        //   drpCountry: this.CountryName,
        // });
      this.onChangeState(response?.country) && this.BranchForm.patchValue({
        drpCountry: response?.country,
      });
      this.stateName != null && this.stateName !== "" && this.stateName != undefined &&
        this.onChangeCity(this.stateName, response?.country) && this.BranchForm.patchValue({
          drpState: this.stateName,
          drpCity: response?.city
        });
      this.BranchForm.patchValue({
        txtBranchName: response?.organizationUnitName,
        txtAddress: response?.address1,
        // txtEmail: response?.emailAddress,
        txtPhoneNumber: response?.phoneNumber,
        txtAddress2: response.address2,
        // drpCountry: defaultCountry.US,
        drpCountry: response?.country,
        drpState: response?.state,
        drpCity: response?.city,
        txtPostalCode: response?.zipcode,
        // txtNPI:response?.npi,
        // txtTaxId:response?.taxId,
        //drpOffice: response?.officeType,
        txtFaxNumber: response?.faxNumber,
        txtChartSuffix: response?.chartSuffix,
        // txtFaxPath:response?.faxPathId,
       txtFaxQueueName:response?.faxQueueName,
       chkFaxQueue:response?.faxQueue,
        txtAppointmentNoteLocation: response?.appointmentNoteLocationId,
        txtlocationURL: response?.locationURL,
        txtMaxInventoryLimit: response?.maxInventoryValue,
        chkisAppoinment:response?.bScheduleAppointment
        // txtPassword: response?.passWord,
        // txtEmailPort: response?.emailPort,
        // txtEmailProtocol: response?.emailProtocol,
        // txtEmailHost: response?.emailHost,
      });
      this.getByIdFaxQueue(response?.faxQueue);
    })
  }
  getByIdFaxQueue(value){
    if(value===true){
      this.BranchForm?.controls?.txtFaxQueueName?.setValidators([Validators.required,noWhitespaceValidator]);
      this.BranchForm?.controls?.txtFaxQueueName?.updateValueAndValidity();
    }else{
      this.BranchForm?.controls?.txtFaxQueueName?.setValidators([Validators.nullValidator]);
      this.BranchForm?.controls?.txtFaxQueueName?.updateValueAndValidity();
    }
  }

  // getFaxById(id: string) {
  //   this.faxid == '' ? this.addWorkingButton = true : this.addWorkingButton = false
  //   this.branchService.getFax(id).subscribe(data => {
  //     this.tenantId = this.tenantId,
  //       this.isActive = data?.isActive,
  //       this.faxid = data?.id ?? '';

  //     this.FaxForm.patchValue({
  //       drpBranchType: data?.branchId,
  //       drpFaxType: data?.isFax,
  //       txtFaxName: data?.faxName === '' ? null : data?.faxName,
  //       txtEmail: data?.mailId,
  //       txtFaxNumber: data?.faxNumber,
  //       txtPassword: data?.mailPassword,
  //       txtEmailPort: data?.portNumber,
  //       txtEmailProtocol: data?.serverProtocol,
  //       txtEmailHost: data?.hostName,
  //     });
  //   })
  // }
  getFtpById(id: string) {

    this.ftpid == '' ? this.addWorkingButton = true : this.addWorkingButton = false;
    this.branchService.getFtp(id).subscribe(data => {
      this.tenantId = this.tenantId,
        this.isActive = data?.isActive,
        this.ftpid = data?.id;
      this.FTPForm.patchValue({
        drpBranchTypes: data?.branchId,
        txtPortNumber: data?.portNumber,
        txtServerIP: data?.server_IP,
        txtUserName: data?.userName,
        txtUserPassword: data?.passWord,
        txtSourceFolder: data?.faxSourceFolder,
        txtDesFolder: data?.destinationFolder,
      });
    })
  }
  //! get official name in super admin
  getBranch() {
    // this.BranchForm.controls['txtCompanyName'].disable()
    this.officialService.getTenantInformationById(this.clientId).subscribe(response => {
      // this.BranchForm.patchValue({
      //   txtCompanyName: response?.tenantName,
      // })

      if (response?.status == "InActive") {
        this.inActiveHide = false
        this.showTable = false
      }
    })
  }
  // onReferenceChange() {
  //   this.seletedFaxValue = this.FaxForm.get('drpFaxType').value;
  // }

  wrokingbranchId: string;
  BranchName: string;

  openAddWorkingModel() {
    // const dialogRef = this.dialog.open(BranchWorkHoursComponent, {
    //   disableClose: true,
    //   data: { branchId: this.branchId, wrokingbranchId: this.wrokingbranchId }
    // });

    const dialogRef = this.dialog.open(AddBranchWorkHoursComponent, {
      // height: "80%", width:"80%",
      disableClose: true,
      data: { branchId: this.branchId, wrokingbranchId: this.wrokingbranchId, BranchName: this.branchName }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
    this.reset()
  }
  //! get official name by client
  getCompanyName(value: UserProfileDTO) {
    // this.BranchForm.controls['txtCompanyName'].disable()
    // this.BranchForm.patchValue({
    //   txtCompanyName: value?.tenantName,
    // });
  }
  //! save branch
  saveBranch() {
    this.saveBranchButtonHide = true;
    this.isShowSpinner=true;
    if ((this.branchId == null || this.branchId == "" || this.branchId == undefined)) {
      let branch: CreateUpdateOrganizationUnitDTO = {
        organizationUnitName: this.BranchForm.value.txtBranchName,
        // emailAddress: this.BranchForm.value.txtEmail,
        address1: this.BranchForm.value.txtAddress,
        address2: this.BranchForm.value.txtAddress2,
        phoneNumber:this.BranchForm.value.txtPhoneNumber,
        tenantId: this.clientId,
        // country: defaultCountry.US,
        country: this.BranchForm.value.drpCountry,
        state: this.BranchForm.value.drpState,
        city: this.BranchForm.value.drpCity,
        zipcode: this.BranchForm.value.txtPostalCode,
        workingHoursId: this.wrokingbranchId,
        status: "Active",
        chartSuffix: this.BranchForm.value.txtChartSuffix,
        // npi:this.BranchForm.value.txtNPI,
        // faxPathId:this.BranchForm.value.txtFaxPath,
        faxQueueName:this.BranchForm.value.txtFaxQueueName,
        faxQueue:this.BranchForm.value.chkFaxQueue===""?false:this.BranchForm.value.chkFaxQueue,
        //   appointmentNoteLocationId:this.BranchForm.value.txtAppointmentNoteLocation,
        //  locationURL:this.BranchForm.value.txtlocationURL,
        maxInventoryValue: this.BranchForm.value.txtMaxInventoryLimit,
        bScheduleAppointment: this.BranchForm.value.chkisAppoinment??false,
        // officeType: this.BranchForm.value.drpOffice,
        // latitude: this.latitude?.toString(),
        // longitude: this.longitude?.toString(),
        // faxNumber: this.BranchForm.value.txtFaxNumber,
        // passWord: this.BranchForm.value.txtPassword,
        // emailPort: this.BranchForm.value.txtEmailPort,
        // emailProtocol: this.BranchForm.value.txtEmailProtocol,
        // emailHost: this.BranchForm.value.txtEmailHost,
      };
      this.isLoading = true;
      this.branchService.create(branch).subscribe(response => {
        this.isShowSpinner=false;
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        //   if (result.value) {
        //     //!change next tab
        //     // this.nextTabMoveOnSaveEmitter.emit("Branch");
        //   }
        this.saveBranchButtonHide = false;
        // this.isLoading = false;
        // });
        this.reset();
        if (this.selectedOption === 'All') {
          this.param = 0
        } else if (this.selectedOption === 'Active') { this.param = 1; } else {
          this.param = 2;
        }
        if(this.branchDetails.length===0){
         this.communicationService.triggerLoadBranchList(this.param);
        } else{
          if(this.selectedOption !== 'Inactive'){
            const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
            response.lastModificationTime = userTimezonelastModificationTime;
            const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
            response.creationTime = userTimezonecreationTime;
        // this.branchDetails.push(response);
          this.branchDetails.unshift(response);
          // this.branchDetails = this.branchDetails.map((data) => {
          //   return {
          //     ...data,
          //     creationTime: this.commonService.getFormattedDateTimeZone(
          //       data.creationTime
          //     ),
          //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
          //       data.lastModificationTime
          //     ),
          //   };
          // });
          }
        }
        this.toastr.success('Saved Successfully', 'Success')
        this.isLoading = false;
        // this.branchStatusFilter(this.param);
      }, err => {
        this.isShowSpinner=false;
        this.saveBranchButtonHide = false;
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      let branch: CreateUpdateOrganizationUnitDTO = {
        organizationUnitName: this.BranchForm.value.txtBranchName,
        // emailAddress: this.BranchForm.value.txtEmail,
        address1: this.BranchForm.value.txtAddress,
        address2: this.BranchForm.value.txtAddress2,
        phoneNumber: this.BranchForm.value.txtPhoneNumber,
        tenantId: this.clientId,
        country: this.BranchForm.value.drpCountry,
        state: this.BranchForm.value.drpState,
        city: this.BranchForm.value.drpCity,
        zipcode: this.BranchForm.value.txtPostalCode,
        workingHoursId: this.wrokingbranchId,
        status: this.status,
        // npi:this.BranchForm.value.txtNPI,
        // taxId:this.BranchForm.value.txtTaxId,
        //officeType: this.BranchForm.value.drpOffice,
        chartSuffix: this.BranchForm.value.txtChartSuffix,
        // faxPathId:this.BranchForm.value.txtFaxPath,
        faxQueueName:this.BranchForm.value.txtFaxQueueName,
        faxQueue:this.BranchForm.value.chkFaxQueue,
        maxInventoryValue: this.BranchForm.value.txtMaxInventoryLimit,
        bScheduleAppointment: this.BranchForm.value.chkisAppoinment,
      };
      this.isLoading = true;
      this.branchService.update(this.branchId, branch).subscribe(response => {
        this.step = false;
        this.isShowSpinner=false;

        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        //   if (result.value) {
        //     //!change next tab
        //     // this.nextTabMoveOnSaveEmitter.emit("Branch");
        //   }
        this.saveBranchButtonHide = false;

        this.isConfirmBranchLocation = false;
        //}, err => { });

        this.reset(); if (this.selectedOption === 'All') {
          this.param = 0
        } else if (this.selectedOption === 'Active') { this.param = 1; } else {
          this.param = 2;
        }
        const index = this.branchDetails.findIndex(
          (obj) => obj.id === response?.id
        );
        if (index !== -1) {
          this.branchDetails[index] = response; //Replace the Object
          const objectToMove = this.branchDetails.splice(index, 1)[0]; // Remove and get the object
          const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
          response.lastModificationTime = userTimezonelastModificationTime;
          const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
          response.creationTime = userTimezonecreationTime;
          this.branchDetails.unshift(objectToMove);
          // this.branchDetails = this.branchDetails.map((data) => {
          //   return {
          //     ...data,
          //     creationTime: this.commonService.getFormattedDateTimeZone(
          //       data.creationTime
          //     ),
          //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
          //       data.lastModificationTime
          //     ),
          //   };
          // });
        }
        this.isLoading = false;
        //this.communicationService.triggerLoadBranchList(this.param);
        // let index = this.branchDetails.findIndex(d => d.id === response.id);
        // this.branchDetails.splice(index,1);
        // this.branchDetails.push(response);

        localStorage.removeItem('updateBranch');
        localStorage.setItem('updateBranch', 'true');
        this.toastr.success('Updated Successfully', 'Success')
        // this.branchStatusFilter(this.param);
      }, err => {
        this.step = false;
        this.isShowSpinner=false;
        this.saveBranchButtonHide = false;
        this.isLoading = false;
        this.isConfirmBranchLocation = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        if(data?.error?.error?.code==207){
          this.BranchForm.patchValue({
            chkFaxQueue:true,
          });
        }
      });
    }
  }
  // saveFax() {
  //   if (this.FaxForm.value.txtFaxName !== null) {
  //     this.faxName = (this.FaxForm.value.txtFaxName).trim();
  //   } else {
  //     this.faxName = null;
  //   }
  //   if (this.faxName === "") {
  //     Swal.fire({
  //       title: 'Invalid Fax Name!',
  //       // text: 'By Changing Status, this branch will be deactivated',
  //       icon: 'warning',
  //       confirmButtonColor: '#34c38f',
  //       confirmButtonText: 'OK'
  //     }).then(result => {
  //       this.saveButtonHide = false;
  //     })
  //   } else {
  //     this.saveButtonHide = true;
  //     if ((this.faxid == null || this.faxid == "" || this.faxid == undefined)) {
  //       let fax: CreateUpdateFaxUnitDTO = {
  //         branchId: this.FaxForm.value.drpBranchType,
  //         isFax: this.FaxForm.value.drpFaxType,
  //         mailId: this.FaxForm.value.txtEmail,
  //         tenantId: this.clientId,
  //         isActive: 1,
  //         faxName: this.FaxForm.value.txtFaxName,
  //         faxNumber: this.FaxForm.value.txtFaxNumber,
  //         mailPassword: this.FaxForm.value.txtPassword,
  //         portNumber: this.FaxForm.value.txtEmailPort,
  //         serverProtocol: this.FaxForm.value.txtEmailProtocol,
  //         hostName: this.FaxForm.value.txtEmailHost,
  //       };
  //       this.branchService.createFax(fax).subscribe(response => {
  //         this.toastr.success('Saved Successfully', 'Success')
  //         this.saveButtonHide = false;
  //         this.isLoading = false;
  //         this.faxReset();
  //         if (this.selectedOption === 'All') {
  //           this.param1 = 0
  //         } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
  //           this.param1 = 2;
  //         }
  //         // this.branchStatusFilter(this.param);
  //         // this.selectedOption="Active";
  //         this.FaxStatusFilter(this.param1);
  //       }, err => {
  //         this.saveButtonHide = false;
  //         this.isLoading = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //     }
  //     else {
  //       let fax: CreateUpdateFaxUnitDTO = {
  //         branchId: this.FaxForm.value.drpBranchType,
  //         isFax: this.FaxForm.value.drpFaxType,
  //         mailId: this.FaxForm.value.txtEmail,
  //         tenantId: this.clientId,
  //         isActive: this.isActive,
  //         faxName: this.FaxForm.value.txtFaxName,
  //         faxNumber: this.FaxForm.value.txtFaxNumber,
  //         mailPassword: this.FaxForm.value.txtPassword,
  //         portNumber: this.FaxForm.value.txtEmailPort,
  //         serverProtocol: this.FaxForm.value.txtEmailProtocol,
  //         hostName: this.FaxForm.value.txtEmailHost,
  //       };
  //       this.branchService.updateFax(this.faxid, fax).subscribe(response => {
  //         this.toastr.success('Updated Successfully', 'Success')
  //         //   }
  //         this.saveButtonHide = false;
  //         this.isLoading = false;
  //         this.isConfirmBranchLocation = false;
  //         //}, err => { });

  //         this.faxReset();if (this.selectedOption === 'All') {
  //           this.param1 = 0
  //         } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
  //           this.param1 = 2;
  //         }
  //         // this.branchStatusFilter(this.param);
  //         // this.selectedOption="Active";
  //         this.FaxStatusFilter(this.param1);
  //         this.faxid = '';
  //       }, err => {
  //         this.saveButtonHide = false;
  //         this.isLoading = false;
  //         this.isConfirmBranchLocation = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //     }
  //   }
  // }

  // saveFTP() {
  //   this.saveButtonHide = true;
  //   if ((this.ftpid == null || this.ftpid == "" || this.ftpid == undefined)) {
  //     let ftp: CreateUpdateFTPUnitDTO = {
  //       branchId: this.FTPForm.value.drpBranchTypes,
  //       tenantId: this.clientId,
  //       isActive: 1,
  //       portNumber: this.FTPForm.value.txtPortNumber,
  //       server_IP: this.FTPForm.value.txtServerIP,
  //       userName: this.FTPForm.value.txtUserName,
  //       passWord: this.FTPForm.value.txtUserPassword,
  //       faxSourceFolder: this.FTPForm.value.txtSourceFolder,
  //       destinationFolder: this.FTPForm.value.txtDesFolder,
  //     };
  //     this.branchService.createFtp(ftp).subscribe(response => {
  //       this.toastr.success('Saved Successfully', 'Success')
  //       this.saveButtonHide = false;
  //       this.isLoading = false;
  //       this.resetFTP();
  //       if (this.selectedOption === 'All') {
  //         this.param2 = 0;
  //       } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
  //         this.param2 = 2;
  //       }
  //       this.FTPStatusFilter(this.param2);
  //     }, err => {
  //       this.saveButtonHide = false;
  //       this.isLoading = false;
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     });
  //   }
  //   else {
  //     let ftp: CreateUpdateFTPUnitDTO = {
  //       branchId: this.FTPForm.value.drpBranchTypes,
  //       tenantId: this.clientId,
  //       isActive: 1,
  //       portNumber: this.FTPForm.value.txtPortNumber,
  //       server_IP: this.FTPForm.value.txtServerIP,
  //       userName: this.FTPForm.value.txtUserName,
  //       passWord: this.FTPForm.value.txtUserPassword,
  //       faxSourceFolder: this.FTPForm.value.txtSourceFolder,
  //       destinationFolder: this.FTPForm.value.txtDesFolder,
  //     };
  //     this.branchService.updateFtp(this.ftpid, ftp).subscribe(response => {
  //       this.toastr.success('Updated Successfully', 'Success')
  //       //   }
  //       this.saveButtonHide = false;
  //       this.isLoading = false;
  //       this.isConfirmBranchLocation = false;
  //       //}, err => { });

  //       this.resetFTP();
  //       if (this.selectedOption === 'All') {
  //         this.param2 = 0;
  //       } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
  //         this.param2 = 2;
  //       }
  //       this.FTPStatusFilter(this.param2);
  //       this.ftpid = '';
  //     }, err => {
  //       this.saveButtonHide = false;
  //       this.isLoading = false;
  //       this.isConfirmBranchLocation = false;
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     });
  //   }
  // }
  //! branch table list
  getBranchTable() {
    this.isLoading = true;
    this.branchTableData = [];
    this.branchService.getOrganizationList(this.clientId).subscribe(response => {
      response && response?.forEach(element => {
        if (element.organizationUnitName != "No Branches") {
          this.branchTableData.push({
            id: element.id,
            branchName: element?.organizationUnitName,
            address: element?.address1,
            address2: element?.address2,
            contact: element?.phoneNumber,
            createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
            status: element?.status,
            tenentId: element?.tenantId,
            workingHoursId: element?.workingHoursId,
            officeType: element?.officeType,
            email: element?.emailAddress,
            country: element?.country,
            state: element?.state,
            city: element?.city,
            zipCode: element?.zipcode
          });
        }
      });
      this.isLoading = false;
      if (this.branchTableData.length != 0) {

        this.isTableData = true
        this.step = false;
      } else {
        this.isTableData = false
        this.step = true;
      }
      // this.dataSource = new MatTableDataSource(this.branchTableData);
      // this.dataSource.sort = this.sort;
      // setTimeout(() => this.dataSource.paginator = this.paginator);
    }, err => {
      this.dataSource = new MatTableDataSource(this.branchTableData);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
      const data: HttpErrorResponse = err;
      this.isLoading = false;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // // filter table search
  // filtertable(filter) {
  //   let Date = filter.Date;
  //   // let DateFormat = Date === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(Date));
  //   let searchInput: SearchOrganizationUnitDTO = {
  //     organizationUnitName: filter.branchName,
  //     emailAddress: filter.email,
  //     address: filter.address,
  //     phoneNumber: filter.phoneNumber,
  //     status: filter.status,
  //     officeType: filter.type,
  //     createdDate: dateYYYYMMDDtoMMDDYYYY(filter.createdDate)
  //   }
  //   this.branchTableData = [];
  //   this.branchService.searchOrganizationByInput(searchInput).subscribe(data => {
  //     this.branchTableData = [];
  //     data?.items.forEach(element => {
  //       this.branchTableData.push({
  //         id: element.id,
  //         branchName: element?.organizationUnitName,
  //         address: element?.address1,
  //         contact: element?.phoneNumber,
  //         createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
  //         status: element?.status,
  //         tenentId: element?.tenantId,
  //         workingHoursId: element?.workingHoursId,
  //         officeType: element?.officeType,
  //         email: element?.emailAddress
  //       });
  //     })
  //     this.dataSource = new MatTableDataSource(this.branchTableData);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;
  //   })
  // }

  ViewClient(event:any) {
    window.scrollTo({ top: 0, behavior: 'smooth' });    // Scroll to the top of the page
    this.step = !this.step;
    this.firstAccordion.id;
    this.panelOpenState = true;
    this.getBranchById(event.id)
    this.wrokingbranchId = event.id
    if(event.isAlreadyInUse){
    this.isAlreadyUsed=true;
    }else{
      this.isAlreadyUsed=false;
    }
  }
  // ViewFax(event: { id: string; }) {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });    // Scroll to the top of the page
  //   this.step = !this.step;
  //   this.secondAccordion.id;
  //   this.panelOpenState = true;
  //   this.getFaxById(event.id);
  // }
  // ViewFtp(event: { id: string; }) {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });    // Scroll to the top of the page
  //   this.step = !this.step;
  //   this.thirdAccordion.id;
  //   this.panelOpenState = true;
  //   this.getFtpById(event.id)
  // }

  // deleteBranch(event: { id: string }) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.branchService.deleteOrganization(event.id).subscribe(response => {
  //         this.branchTableData.forEach(element => {
  //           if (event.id == element?.id) {
  //             let index = this.branchTableData.indexOf(element, 0)
  //             this.branchTableData.splice(index, 1)
  //           }
  //         });
  //         this.dataSource = new MatTableDataSource(this.branchTableData);
  //         this.reset();
  //         this.getBranchList();
  //       }, err => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.reset();
  //         this.getBranchList();
  //       });
  //     }
  //   });

  // }
  // deleteFax(event: { id: string }) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.branchService.deleteFax(event.id).subscribe(response => {
  //         this.branchTableData.forEach(element => {
  //           if (event.id == element?.id) {
  //             let index = this.branchTableData.indexOf(element, 0)
  //             this.branchTableData.splice(index, 1)
  //           }
  //         });
  //         this.dataSource = new MatTableDataSource(this.branchTableData);
  //         this.faxReset();
  //         this.FaxStatusFilter(1);
  //       }, err => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.faxReset();
  //         this.FaxStatusFilter(1);
  //       });
  //     }
  //   });
  // }
  // deleteFtp(event: { id: string }) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.branchService.deleteFtp(event.id).subscribe(response => {
  //         this.branchTableData.forEach(element => {
  //           if (event.id == element?.id) {
  //             let index = this.branchTableData.indexOf(element, 0)
  //             this.branchTableData.splice(index, 1)
  //           }
  //         });
  //         this.dataSource = new MatTableDataSource(this.branchTableData);
  //         this.resetFTP();
  //         this.FTPStatusFilter(1);
  //       }, err => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.resetFTP();
  //         this.FTPStatusFilter(1);
  //       });
  //     }
  //   });
  // }
  reset() {
    this.isAlreadyUsed=false;
    this.BranchForm.patchValue({
      txtBranchName: "",
      drpCountry: defaultCountry.US,
      txtAddress: "",
      txtEmail: "",
      txtPhoneNumber: "",
      txtAddress2: "",
      drpState: "",
      drpCity: "",
      txtPostalCode: "",
      drpCityFilter: "",
      // txtCountryFilter: "",
      drpStateFilter: "",
      //drpOffice: "",
      faxNumber: "",
      txtPassword: "",
      // txtEmailPort: "",
      // txtEmailProtocal: "",
      // txtEmailHost: "",
      txtFaxNumber: "",
      txtChartSuffix: "",
      txtAppointmentNoteLocation: "",
      txtlocationURL: "",
      txtMaxInventoryLimit: "",
      txtdispenseInventory: "",
    })
    this.branchId = '';
    this.BranchForm?.controls?.txtFaxQueueName?.setValidators([Validators.nullValidator]);
    this.BranchForm?.controls?.txtFaxQueueName?.updateValueAndValidity();
    this.BranchForm.reset();
    this.BranchForm.markAsUntouched();
    this.BranchForm.updateValueAndValidity();
    // this.getCompanyName(this.loginResponse);
    this.addWorkingButton = false;
    this.BranchForm.patchValue({
      drpCountry: defaultCountry.US
    });
  }

  // faxReset() {
  //   this.FaxForm.patchValue({
  //     drpBranchType: "",
  //     drpFaxType: "",
  //     txtEmail: "",
  //     txtPassword: "",
  //     txtEmailPort: "",
  //     txtEmailProtocol: "",
  //     txtEmailHost: "",
  //     txtFaxNumber: "",
  //     txtFaxName: "",
  //   })
  //   this.faxid = '';
  //   this.FaxForm.reset();
  //   this.FaxForm.markAsUntouched();
  //   this.FaxForm.updateValueAndValidity();
  //   // this.getCompanyName(this.loginResponse);
  //   this.addWorkingButton = false;
  // }
  // resetFTP() {
  //   this.FTPForm.patchValue({
  //     drpBranchType: "",
  //     txtPortNumber: "",
  //     txtUserName: "",
  //     txtUserPassword: "",
  //     txtSourceFolder: "",
  //     txtDesFolder: "",
  //     txtServerIP: "",
  //   })
  //   this.ftpid = '';
  //   this.FTPForm.reset();
  //   this.FTPForm.markAsUntouched();
  //   this.FTPForm.updateValueAndValidity();
  //   // this.getCompanyName(this.loginResponse);
  //   this.addWorkingButton = false;
  // }


  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.OfficeType,
    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      let response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpOffice = response?.officeTypes;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true,
    //   content: "InfoWindow content",
    //   iconUrl: "http://maps.google.com/mapfiles/ms/micons/blue-pushpin.png"
    // });
  }

  confirmBranchLocation() {
    this.isConfirmBranchLocation = true;
    this.branchLocation = '';
    this.branchName = this.BranchForm.value.txtBranchName;
    this.BranchForm.value.txtAddress2 = this.BranchForm.value.txtAddress2 == null || undefined ? " " : this.BranchForm.value.txtAddress2;
    this.BranchForm.value.drpCity = this.BranchForm.value.drpCity == null ? " " : this.BranchForm.value.drpCity;
    this.mapsAPILoader?.load()?.then(() => {
      let geocoder = new google.maps.Geocoder();
      this.branchLocation = this.branchLocation.concat(
        this.BranchForm.value.txtAddress + ' ' +
        this.BranchForm.value.txtAddress2 + ' ' +
        this.BranchForm.value.drpState + ' ' +
        this.BranchForm.value.drpCountry + ' ' +
        this.BranchForm.value.drpCity + ' ' +
        this.BranchForm.value.txtPostalCode).trim();
      geocoder?.geocode({ address: this.branchLocation }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.latitude = results[0].geometry.location.lat(),
            this.longitude = results[0].geometry.location.lng()
        }
        else {
        }
      });
    });
  }

  // Get Country Dropdown
  countryDropdown() {
    this.BranchForm.patchValue({ "drpCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;

    this.drpCountry = [];
    this.regionDropdownService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      //
      // this.drpCountry = response?.items.filter(x => x.countryShortName == "US") ?? [];
      this.drpCountry = response?.items?? [];
      this.filteredCountries = this.BranchForm?.get("txtCountryFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.BranchForm.patchValue({
      drpState: null,
      drpCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    value !== null && value !== undefined && this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
      this.drpStates = response?.items ?? [];
      if (response?.items?.length === 0) {
        this.phoneCode = "";
        this.BranchForm.controls['txtPhoneNumber'].disable();
      } else {
        this.BranchForm.controls['txtPhoneNumber'].enable();
        this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? "";
      }
      this.filteredStates = this.BranchForm?.get("drpStateFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpStates.filter(option =>
            option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
          ))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpStates.length !== 0;
  }
  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.BranchForm.patchValue({
      drpCity: null
    });
    country = (country == "") ? (this.BranchForm.value.drpCountry == (null || undefined) ? "" : (this.BranchForm.value.drpCountry)) : country;
    this.drpCities = [];
    state !== null && state !== undefined && this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {

      this.drpCities = response?.items ?? [];

      this.filteredCities = this.BranchForm?.get("drpCityFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpCities.length !== 0;
  }

  // clearCountry() {
  //   // this.OnTimeUserForm.controls['txtPhnNo'].disable();
  //   this.Country = "";
  //   this.BranchForm.patchValue({ drpCountry: "" })
  // }

  clearState() {
    this.State = "";
    this.BranchForm.patchValue({ drpState: "" })
  }

  clearCity() {
    this.City = "";
    this.BranchForm.patchValue({ drpCity: "" })
  }

  clearRole() {
    this.role = '';
    this.BranchForm.patchValue({ txtRole: '' });
  }

  clearBranch() {
    this.branch = '';
    this.BranchForm.patchValue({ txtBranchName: '' });
  }

  statusChange(event: MatSlideToggleChange, data: any) {

    // this.status = event.checked == true ? "Active" : "Inactive";
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this branch will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          this.isLoading=true;
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.branchService.updateBranchEnableDisable(data?.id).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })


              if (this.selectedOption === 'All') {
                this.param = 0
              } else if (this.selectedOption === 'Active') { this.param = 1; } else {
                this.param = 2;
              }
              // this.communicationService.triggerLoadBranchList(this.param);
              // this.branchStatusFilter(this.param);
              if(this.selectedOption !=='All'){
                let index = this.branchDetails.findIndex(d => d.id === data?.id);
                this.branchDetails.splice(index,1);
              }else{
                this.branchDetails.find(item => item.id == data?.id).status = 'Active';
              }
              this.isLoading=false;
              this.toastr.success('Activated Successfully', 'Success')
            }, err => {
              const data: HttpErrorResponse = err;
              this.isLoading=false;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this branch will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            this.isLoading=true;
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.branchService.updateBranchEnableDisable(data?.id).subscribe(response => {

                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })

                if (this.selectedOption === 'All') {
                  this.param = 0
                } else if (this.selectedOption === 'Active') { this.param = 1; } else {
                  this.param = 2;
                }
                // this.communicationService.triggerLoadBranchList(this.param);
                // this.branchStatusFilter(this.param);
                if(this.selectedOption !=='All'){
                  let index = this.branchDetails.findIndex(d => d.id === data?.id);
                  this.branchDetails.splice(index,1);
                }else{
                  this.branchDetails.find(item => item.id == data?.id).status = 'Inactive';
                }
                this.isLoading=false;
                this.toastr.success('Deactivated Successfully', 'Success')
              }, err => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              });
            }

          }
          else {
            event.source.checked = true;
          }
        });
      }
  }
  // faxStatusChange(event: MatSlideToggleChange, data: any) {

  //   if (event.checked == true) {
  //     Swal.fire({
  //       title: 'Do you want to activate?',
  //       text: 'By Changing Status, this Fax/Email will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!'
  //     }).then(result => {
  //       if (result.value) {

  //         if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //           this.branchService.updateFaxEnableDisable(data?.id).subscribe(response => {
  //             // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
  //             this.toastr.success('Activated Successfully', 'Success')
  //             if (this.selectedOption === 'All') {
  //               this.param1 = 0
  //             } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
  //               this.param1 = 2;
  //             }
  //             // this.branchStatusFilter(this.param);
  //             // this.selectedOption="Active";
  //             // this.FaxStatusFilter(this.param1);--
  //           }, err => {
  //             const data: HttpErrorResponse = err;
  //             Swal.fire({
  //               icon: 'info',
  //               text: data?.error?.error?.message,
  //             });
  //           });
  //         }
  //       }
  //       else {
  //         event.source.checked = false;
  //       }
  //     });
  //   }
  //   else
  //     if (event.checked == false) {
  //       Swal.fire({
  //         title: 'Do you want to deactivate?',
  //         text: 'By Changing Status, this  Fax/Email will be deactivated',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#34c38f',
  //         cancelButtonColor: '#f46a6a',
  //         confirmButtonText: 'Yes, deactivate it!'
  //       }).then(result => {
  //         if (result.value) {
  //           if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //             this.branchService.updateFaxEnableDisable(data?.id).subscribe(response => {
  //               this.toastr.success('Deactivated Successfully', 'Success')
  //               // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
  //               if (this.selectedOption === 'All') {
  //                 this.param1 = 0
  //               } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
  //                 this.param1 = 2;
  //               }
  //               this.FaxStatusFilter(this.param1);
  //             }, err => {
  //               const data: HttpErrorResponse = err;
  //               Swal.fire({
  //                 icon: 'info',
  //                 text: data?.error?.error?.message,
  //               });
  //             });
  //           }

  //         }
  //         else {
  //           event.source.checked = true;
  //         }
  //       });
  //     }
  // }

  // ftpStatusChange(event: MatSlideToggleChange, data: any) {

  //   if (event.checked == true) {
  //     Swal.fire({
  //       title: 'Do you want to activate?',
  //       text: 'By Changing Status, this FTP will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!'
  //     }).then(result => {
  //       if (result.value) {

  //         if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //           this.branchService.updateFtpEnableDisable(data?.id).subscribe(response => {
  //             // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
  //             this.toastr.success('Activated Successfully', 'Success')
  //             if (this.selectedOption === 'All') {
  //               this.param2 = 0;
  //             } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
  //               this.param2 = 2;
  //             }
  //             this.FTPStatusFilter(this.param2);
  //           }, err => {
  //             const data: HttpErrorResponse = err;
  //             Swal.fire({
  //               icon: 'info',
  //               text: data?.error?.error?.message,
  //             });
  //           });
  //         }
  //       }
  //       else {
  //         event.source.checked = false;
  //       }
  //     });
  //   }
  //   else
  //     if (event.checked == false) {
  //       Swal.fire({
  //         title: 'Do you want to deactivate?',
  //         text: 'By Changing Status, this FTP will be deactivated',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#34c38f',
  //         cancelButtonColor: '#f46a6a',
  //         confirmButtonText: 'Yes, deactivate it!'
  //       }).then(result => {
  //         if (result.value) {
  //           if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //             this.branchService.updateFtpEnableDisable(data?.id).subscribe(response => {
  //               this.toastr.success('Deactivated Successfully', 'Success')
  //               // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
  //               if (this.selectedOption === 'All') {
  //                 this.param2 = 0;
  //               } else if (this.selectedOption === 'Active') { this.param2 = 1; } else {
  //                 this.param2 = 2;
  //               }
  //               this.FTPStatusFilter(this.param2);
  //             }, err => {
  //               const data: HttpErrorResponse = err;
  //               Swal.fire({
  //                 icon: 'info',
  //                 text: data?.error?.error?.message,
  //               });
  //             });
  //           }

  //         }
  //         else {
  //           event.source.checked = true;
  //         }
  //       });
  //     }
  // }
  branchStatusFilterV1(statusid: number) {
    this.communicationService.triggerLoadBranchList(statusid);
  }
  fnselectedOption() {
    if (this.viewstatus == 0) {
      this.selectedOption = "All";
    } else if (this.viewstatus == 1) {
      this.selectedOption = "Active";
    } else if (this.viewstatus == 2) {
      this.selectedOption = "InActive";
    }
  }
  showTab(id: number) {
    if (id === 0) {
      this.branchTab = true;
      this.FaxTab = false;
      this.FTPTab = false;
      this.selectedOption = 'Active'
      this.communicationService.triggerLoadBranchList(1);
      // this.branchStatusFilter(1);
      this.reset();
      this.countryDropdown();
    } else if (id === 1) {
      this.branchTab = false;
      this.FaxTab = true;
      this.FTPTab = false;
      this.selectedOption = 'Active'
      this.FaxStatusFilter(1);
      // this.faxReset();
      this.loadDropdown();
    } else if (id === 2) {
      this.branchTab = false;
      this.FaxTab = false;
      this.FTPTab = true;
      this.selectedOption = 'Active'
      this.FTPStatusFilter(1);
      // this.resetFTP();
      this.loadDropdown();
    } else {

    }
  }
  loadDropdown() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        this.lstBranch = response;
        this.filteredBranchTypeList = [...this.lstBranch];

      });
  }

  // handleTableCellClick(e: Event, notesData: any): void {
  //   const target = e.target as HTMLElement;
  //   const tr = target.closest('tr');
  //   this.getDataTableData(tr, notesData);
  // }
  // //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  // async getDataTableData(tr: any, notesData: any): Promise<void> {
  //   this.branchDetails = await this.datatableElement.dtInstance;

  //   if (tr) {
  //     const row = this.branchDetails.row(tr);

  //     if (row.child.isShown()) {
  //       // This row is already open - close it
  //       row.child.hide();
  //     } else {
  //       // Open this row
  //       row.child(this.format(row.data(), notesData)).show();
  //     }
  //   }
  // }

  //   format(d: any, notesData: any): string {
  //     let returnString: string = '<table>';
  //     returnString += `<tr ><td style="width: 05%;"><strong>Address:</strong></td><td style="width: 70%;">${notesData.address1}</td></tr>`;
  //     returnString += `<tr ><td style="width: 10%;"><strong>Phone Number:</strong></td><td style="width: 70%;">${notesData.phoneNumber}</td></tr>`;
  //     returnString += `<tr ><td style="width: 10%;"><strong>Chart Suffix:</strong></td><td style="width: 70%;">${notesData.chartSuffix}</td></tr>`;
  //     returnString += `<tr ><td style="width: 10%;"><strong>Max Inventory Limit($):</strong></td><td style="width: 70%;">${notesData.maxInventoryValue}</td></tr>`;
  //     returnString += `<tr ><td style="width: 10%;"><strong>NPI Id:</strong></td><td style="width: 70%;">${notesData.npi}</td></tr>`;
  //     returnString += '</table>';
  //     return returnString;
  // }

  // this.myTable = await this.datatableElement.dtInstance;

  // if (tr) {
  //   const row = this.myTable.row(tr);

  //   if (row.child.isShown()) {
  //     // This row is already open - close it
  //     row.child.hide();
  //   } else {
  //     // Open this row
  //     row.child(this.format(row.data(), notesData)).show();
  //   }
  // }
  // // Now you can use the dataTable object to get data, for example:
  // // const data = this.myTable.rows().data().toArray();

  // }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }
  faxqueueEvent(event){
    if(event.checked===true){
      this.BranchForm?.controls?.txtFaxQueueName?.setValidators([Validators.required,noWhitespaceValidator]);
      this.BranchForm?.controls?.txtFaxQueueName?.updateValueAndValidity();
    }else{
      this.BranchForm?.controls?.txtFaxQueueName?.setValidators([Validators.nullValidator]);
      this.BranchForm?.controls?.txtFaxQueueName?.updateValueAndValidity();
    }
  }
  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}


interface marker {
  lat?: number;
  lng?: number;
  label?: string;
  draggable?: boolean;
  visible?: boolean,
  opacity?: number
}
