import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { map, startWith } from 'rxjs/operators';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items';
import { MmOrderItemExchanges } from 'projects/inventory/src/app/item-proxy/item-management/optimization/dto';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-exchange-order',
  templateUrl: './exchange-order.component.html',
  styleUrls: ['./exchange-order.component.scss']
})
export class ExchangeOrderComponent implements OnInit {
  exchangeForm: FormGroup
  lstUsers: any;
  filteredUsers: any;
  subscription$: any;
  // searchControl = new FormControl();
  ltproducts: any;
  filteredOptions: any;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  itemTableData: any;
  filteredExchangeItem: any;
  itemData: any;
  tenantId: string;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private mmOrderService: MmOrderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      exchangeItemId: string;
      mmOrderId: string;
      mmOrderItemId: string;
      mmProductId: string

    } = {
      exchangeItemId: defaultGuid,
      mmOrderId: defaultGuid,
      mmOrderItemId: defaultGuid,
      mmProductId: defaultGuid
    }
  ) { }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.exchangeForm = this.formBuilder.group({
      drpNewItem: new FormControl(''),
      txtNewItem: new FormControl(''),
      txtCSRFilter: new FormControl(''),
      txtExchangeItem: new FormControl(''),
      drpExchange: new FormControl(''),
      suppliesDate: new FormControl(''),
      drpCSR: new FormControl(''),
      createdBy: new FormControl(''),
      txtOrderNo: new FormControl(''),
      txtQty: new FormControl(''),
      chxApprove:  new FormControl(''),
      drpItem: new FormControl(''),
      searchControl:  new FormControl(''),
      drpProduct: new FormControl(''),

    })

    this.getCSR()
    this.loadDropDown()
    this.loadCSRUsers('')
    this.loadProducts()
    this.viewExchangeLedger()
  }
  loadDropDown(){
    this.exchangeForm.patchValue({
      drpExchange: this.data.mmProductId
    })
  }

  getCSR(){

    this.mmOrderService.get(this.data.mmOrderId).subscribe(response => {

      this.exchangeForm.patchValue({
        drpCSR :response.csRid
      })
    })
  }

  selectExchange(){

      let value = this.exchangeForm.value
      let exchangeDetails:MmOrderItemExchanges = {
        newOrderItemId: value.drpExchange ?? defaultGuid,
        originalOrderItemId: value.drpNewItem ?? defaultGuid,
        userId: value.drpCSR ?? defaultGuid,
        createdDate: new Date().toLocalISOString(),
        originalProductId: value.drpProduct ?? defaultGuid,
        originalOrderNo: Number(value.txtOrderNo) ?? 0,
        originalSuppliesDate: value.suppliesDate ?? new Date().toLocalISOString(),
        originalOrderCreatedBy: defaultGuid,
        qtyExchanged: value.txtQty ?? 0,
        needsApproved: value.chxApprove ?? false,
        orderReturnExchangeItemConditionId: defaultGuid,
        rma_number: '',
        mg_number: '',
        returnFlag: false,
        restocked: false,
        received: new Date().toLocalISOString(),
        receivedUserId: defaultGuid,
        receivedSerialLotNo: '',
        otherReasonText: '',
        tenantId: this.tenantId,
        status: Status.Active,
        isDeleted: false
      }
        this.mmOrderService.exchangeItemByGOrderIdAndGOrderItemIdAndGExchangedOrderItemIdAndINoQtyExgeAndInput(this.data.mmOrderId,this.data.mmOrderItemId,value.drpNewItem,value.txtQty,exchangeDetails).subscribe(response => {

             this.toastr.success('Exchanged Successfully','Success')
            },(err) => {

              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })

  }


  loadCSRUsers(search) {
    const csrUsersList = this.userService
      .getCSRUsers(search)
      .subscribe((response) => {
        this.lstUsers = response;
        this.filteredUsers = this.exchangeForm
          .get('txtCSRFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUsers?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }
  UserSearch(value){
    this.loadCSRUsers(value);
    }
  //Method To Search the Products in Dropdown
  filterProducts(): void {
    const searchValue = this.exchangeForm.controls.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltproducts.filter((option) =>
      option.productDescription.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
    //Load Products
    loadProducts() {
      const productsList = this.mmOrderService.getProductDetails().subscribe(
        (response) => {

          this.ltproducts = response;
          this.filteredOptions = [...this.ltproducts];
        },
        (err) => {

        }
      );
    }

    viewExchangeLedger(){
      this.mmOrderService.getPatientOrderItemList(this.data.exchangeItemId).subscribe(response => {
        this.itemData = response.items
        this.filteredExchangeItem = this.exchangeForm
        .get('txtExchangeItem')
        .valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.itemData?.filter((option) =>
              option?.productDescription
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    })
  }
}
