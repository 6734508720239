
<div class="col-12">
    <div class="row">
<h1 mat-dialog-title class="col-10 tittle"><b>Insurance Verification Form</b></h1>
<div class="col-2" style="text-align-last: right">
    <button mat-icon-button mat-dialog-close=" close-button ">
        <mat-icon class="mr-2">close</mat-icon>
      </button>
</div>
</div>
</div>
<mat-dialog-content class="mat-typography" style="height:90% !important">
<div class="container-fluid">

  <div class="row">
    <div class="col-2">
      <div class="card card-body">
        <form [formGroup]="primaryInsForm1">
          <div class="row pb-4">
            <mat-form-field class="col-lg-12">
              <mat-label>Chart</mat-label>
              <input
                autocomplete="new-txtChartNo"
                matInput
                maxlength="50"
                formControlName="txtChartNo"
                placeholder="Enter Chart No"
                type="text"
              />
            </mat-form-field>
            <mat-form-field class="col-lg-12">
              <mat-label>Name</mat-label>
              <input
                autocomplete="new-txtName"
                matInput
                maxlength="50"
                formControlName="txtName"
                placeholder="Enter Name"
                type="text"
              />
            </mat-form-field>
            <mat-form-field class="algin col-lg-12">
              <mat-label>Address</mat-label>
              <input
                autocomplete="new-txtAddress"
                matInput
                maxlength="50"
                formControlName="txtAddress"
                placeholder="Enter Address"
                type="text"
              />
            </mat-form-field>
            <mat-form-field class="col-12">
              <mat-label>Date of Birth</mat-label>
              <input
                autocomplete="new-txtDOB"
                formControlName="txtDOB"
                matInput
                placeholder="MM/DD/YYYY"
                [matDatepicker]="txtDOB"
              />
              <mat-datepicker-toggle matSuffix [for]="txtDOB">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDOB></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-12">
              <mat-label>Notes</mat-label>
              <textarea
                formControlName="txtNotes"
                rows="2"
                maxlength="5000"
                matInput
              ></textarea>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
    <div class="col-10">
      <div class="card card-body">
        <div class="col-12">
          <div class="card card-body">
            <label class="tittle"><b>Lookup Patient Insurance</b></label>
          </div>
          <form [formGroup]="primaryInsForm2">
            <div class="row pb-4">
              <mat-form-field class="col-xl-4">
                <mat-label>Plan</mat-label>
                <mat-select formControlName="drpPlan">
                  <mat-option [value]="1"> Primary </mat-option>
                  <mat-option [value]="2"> Secondary </mat-option>
                  <mat-option [value]="3"> Tertiary </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="col-xl-4">
                <mat-label>Plan Name</mat-label>
                <mat-select formControlName="drpPlanName">
                  <mat-option [value]="1"> sample </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="algin col-lg-4">
                <mat-label>Plan Phone</mat-label>
                <input
                  autocomplete="new-txtPlanPhone"
                  matInput
                  maxlength="50"
                  formControlName="txtPlanPhone"
                  placeholder="Enter Plan Phone"
                  type="text"
                />
              </mat-form-field>
              <mat-form-field class="col-lg-4">
                <mat-label>Policy #</mat-label>
                <input
                  autocomplete="new-txtPolicy"
                  matInput
                  maxlength="50"
                  formControlName="txtPolicy"
                  placeholder="Enter Policy"
                  type="text"
                />
              </mat-form-field>

              <mat-form-field class="col-lg-4">
                <mat-label>Group #</mat-label>
                <input
                  autocomplete="new-txtGroup"
                  matInput
                  maxlength="50"
                  formControlName="txtGroup"
                  placeholder="Enter Group"
                  type="text"
                />
              </mat-form-field>

              <mat-form-field class="col-lg-4">
                <mat-label>Effective Date </mat-label>
                <input
                  autocomplete="new-txtEffectiveDate"
                  formControlName="txtEffectiveDate"
                  matInput
                  placeholder="MM/DD/YYYY"
                  [matDatepicker]="txtEffectiveDate"
                />
                <mat-datepicker-toggle matSuffix [for]="txtEffectiveDate">
                </mat-datepicker-toggle>
                <mat-datepicker #txtEffectiveDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="col-xl-4">
                <mat-label>Type</mat-label>
                <mat-select formControlName="drpType">
                  <mat-option [value]="1"> HMO </mat-option>
                  <mat-option [value]="2"> POS </mat-option>
                  <mat-option [value]="3"> OTHER </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Fiscal Year Start</mat-label>
                <input
                  autocomplete="new-txtFascalYearStart"
                  formControlName="txtFascalYearStart"
                  matInput
                  placeholder="MM/DD/YYYY"
                  [matDatepicker]="txtFascalYearStart"
                />
                <mat-datepicker-toggle matSuffix [for]="txtFascalYearStart">
                </mat-datepicker-toggle>
                <mat-datepicker #txtFascalYearStart></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="col-6">
                <mat-label>End</mat-label>
                <input
                  autocomplete="new-txtEnd"
                  formControlName="txtEnd"
                  placeholder="MM/DD/YYYY"
                  matInput
                  [matDatepicker]="txtEnd"
                />
                <mat-datepicker-toggle matSuffix [for]="txtEnd">
                </mat-datepicker-toggle>
                <mat-datepicker #txtEnd></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-12 text-lg-center">
              <button mat-button class="col-3 buttonColor mb-1 mr-2">
                Submit For Verification
              </button>
              <button mat-button class="col-3 buttonColor mb-1 mr-2">
                Submit For Online Verification
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card card-body">
      <div class="row">
        <div class="col-6">
          <br />
          <mat-checkbox
            >Medicare CMN on file?</mat-checkbox
          >
        </div>
        <mat-form-field class="col-4">
          <mat-label>CMN Code</mat-label>
          <input
            autocomplete="new-txtCmnCode"
            matInput
            maxlength="40"

            type="text"
          />
        </mat-form-field>
        <div class="col-md-6">
          <br />
          <mat-checkbox
            ><p class="mb-0" style="text-wrap: wrap">
              Does Family Deductible cover Individual Deductible?
            </p>
          </mat-checkbox>
        </div>
        <div class="col-3">
          <br />
          <!-- <mat-checkbox formControlName="chkPatient">Is Patient Home Health Episode?</mat-checkbox> -->
          <mat-checkbox
            ><p class="mb-0" style="text-wrap: wrap">
              Is Patient Home Health Episode?
            </p></mat-checkbox
          >
        </div>
        <mat-form-field class="col-3">
          <mat-label> Type Of Plan </mat-label>
          <mat-select >
            <mat-option [value]="1"> sample </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12">
      <div clas="card card-body">
        <div class="card card-body">
          <div class="col-12">
            <label>Insurance Benefit Info</label>
          </div>
        </div>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-md-2">
            <br />
            <mat-checkbox
              >Individual</mat-checkbox
            >
          </div>
          <div class="col-md-2">
            <br />
            <mat-checkbox >Family</mat-checkbox>
          </div>
          <div class="col-md-2">
            <br />
            <mat-checkbox
              >OutofNetwork</mat-checkbox
            >
          </div>
          <div class="col-6">
            <br />
            <p>What is Deductible Remaining?</p>
          </div>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingIndividual"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingFamily"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingOutOfNetwork"
              matInput

              type="text"
            />
          </mat-form-field>

          <div class="col-6">
            <br />
            <p>How much of Deductible has been met?</p>
          </div>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingIndividual"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingFamily"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingOutOfNetwork"
              matInput

              type="text"
            />
          </mat-form-field>

          <div class="col-6">
            <br />
            <p>Payable at what percent of the allowed amount?</p>
          </div>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <mat-select >
              <mat-option [value]="1"> Primary </mat-option>
              <mat-option [value]="2"> Secondary </mat-option>
              <mat-option [value]="3"> Tertiary </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <mat-select >
              <mat-option [value]="1"> sample </mat-option>
              <mat-option [value]="2"> Test </mat-option>
              <mat-option [value]="3"> Datas </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <mat-select >
              <mat-option [value]="1"> testing data </mat-option>
              <mat-option [value]="2"> Test11 </mat-option>
              <mat-option [value]="3"> Datas </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-6">
            <br />
            <p>Out-of-pocket expense?</p>
          </div>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingIndividual"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingFamily"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingOutOfNetwork"
              matInput

              type="text"
            />
          </mat-form-field>

          <div class="col-6">
            <br />
            <p>How much Out-of-pocket expense has been met?</p>
          </div>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingIndividual"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingFamily"
              matInput

              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label></mat-label>
            <input
              autocomplete="new-txtDeductibleRemainingOutOfNetwork"
              matInput

              type="text"
            />
          </mat-form-field>

          <div class="col-6">
            <br />
            <p>Does Out-of-pocket expense include the deductible?</p>
          </div>
          <div class="col-md-2">
            <br />
            <mat-checkbox ></mat-checkbox>
          </div>
          <div class="col-md-2">
            <br />
            <mat-checkbox ></mat-checkbox>
          </div>
          <div class="col-md-2">
            <br />
            <mat-checkbox ></mat-checkbox>
          </div>
          <div class="col-3">
            <br />
            <mat-checkbox
              >Is Precert Required?</mat-checkbox
            >
          </div>
        </div>
      </div>
      <br /><br /><br />
    </div>
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
