<div class="col-12">
    <div class="row">
<h1 mat-dialog-title class="col-10 tittle"><b>Insurance Complance CheckList</b></h1>
<div class="col-2" style="text-align-last: right">
    <button mat-icon-button mat-dialog-close=" close-button ">
        <mat-icon class="mr-2">close</mat-icon>
      </button>
</div>
</div>
</div>
<mat-dialog-content class="mat-typography" style="height:90% !important">
<div class="container-fluid">
    <div class="col-12">
        <div class="row">
            <div class="crad card-body">
                <form [formGroup]="checkListIns">
                    <div class="row pb-4">
                       
                      <mat-form-field class="col-lg-6">
                        <mat-label>Chart No </mat-label>
                        <input
                          autocomplete="new-txtChartNo"
                          matInput
                          maxlength="50"
                          formControlName="txtChartNo"
                          placeholder="Enter Chart No"
                          type="text"
                        />
                      </mat-form-field>
                      <mat-form-field class="col-lg-6">
                        <mat-label>Patient Name</mat-label>
                        <input
                          autocomplete="new-txtName"
                          matInput
                          maxlength="50"
                          formControlName="txtPatientName"
                          placeholder="Enter Name"
                          type="text"
                        />
                      </mat-form-field>
                      <mat-form-field class="algin col-lg-6">
                        <mat-label>Verification</mat-label>
                        <input
                          autocomplete="new-txtAddress"
                          matInput
                          maxlength="50"
                          formControlName="txtVerification"
                          placeholder="Enter Address"
                          type="text"
                        />
                      </mat-form-field>
                      <mat-form-field class="col-lg-6">
                        <!-- <mat-label>Notes</mat-label> -->
                        <textarea  formControlName="txtVerification"  matInput  rows="2" id="comment"></textarea>
                    </mat-form-field>
                        
                     

                      <!-- <mat-form-field class="col-6">
                        <mat-label>Date of Birth</mat-label>
                        <input
                          autocomplete="new-txtDOB"
                          formControlName="txtDOB"
                          matInput
                          placeholder="MM/DD/YYYY"
                          [matDatepicker]="txtDOB"
                        />
                        <mat-datepicker-toggle matSuffix [for]="txtDOB">
                        </mat-datepicker-toggle>
                        <mat-datepicker #txtDOB></mat-datepicker>
                      </mat-form-field>
           -->
                        <mat-form-field class="col-lg-6">
                        <mat-label>Ins Comments</mat-label>
                        <textarea formControlName="txtInsComments" matInput  rows="2" id="comment"></textarea>
                    </mat-form-field>
                    </div>
                  </form>
            </div> 
        </div>
    </div>
    <div class="col-12">
        <div class="card card-body">
            <div class="row">
                <div class="col-xl-12 pb-2">
                  <ul
                    class="card card-body"
                    ngbNav
                    #nav="ngbNav"
                    justify="justified"
                    class="alert-success nav nav-pills nav-fill"
                    style="border-bottom: 3px solid rgb(201, 201, 201)"
                  >
                    
                    <li
                      ngbNavItem="Supplies"
                      [class.selected-tab]="nav.activeId === 'Supplies'"
                    >
                      <a ngbNavLink id="tab-3" (click)="changeTab('Supplies')"
                        ><b>Supplies</b></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <h1 class="tittle"><b> Supplies</b></h1><br><br>
                <form [formGroup]="checkListIns1">
                    <div class="row">
                       
                    <div class="col-6">
                <div class="col-6">
                  
                    <mat-checkbox 
                      >Face-to-face clnical evaluation conducted prior to <br>the sleep test to assess the patient for OSA</mat-checkbox
                    >
                <mat-form-field >
                        <input
                        autocomplete="new-txtDOB"
                        formControlName="txtDOB"
                        matInput
                        placeholder="MM/DD/YYYY"
                        [matDatepicker]="txtDOB"
                      />
                      <mat-datepicker-toggle matSuffix [for]="txtDOB">
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtDOB></mat-datepicker>
                    </mat-form-field>
                  </div><br>

                 
              
                  <div class="col-6">
                 
                    <mat-checkbox 
                      >Medicare Covered sleep test</mat-checkbox
                    >
                    <mat-form-field >
                        <input
                        autocomplete="new-txtDOBB"
                        formControlName="txtDOBB"
                        matInput
                        placeholder="MM/DD/YYYY"
                        [matDatepicker]="txtDOBB"
                      />
                      <mat-datepicker-toggle matSuffix [for]="txtDOBB">
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtDOBB></mat-datepicker>
                    </mat-form-field>
                  </div><br>

                  <div class="col-6">
                   
                    <mat-checkbox 
                      > <mat-form-field  >
                        
                        <mat-select >
                          <mat-option [value]="1"> Primary </mat-option>
                          <mat-option [value]="2"> Secondary </mat-option>
                          <mat-option [value]="3"> Tertiary </mat-option>
                        </mat-select>
                      </mat-form-field>Detailed written order</mat-checkbox
                    >
                    <mat-form-field >
                        <input
                        autocomplete="new-txtDOBData"
                        formControlName="txtDOBData"
                        matInput
                        placeholder="MM/DD/YYYY"
                        [matDatepicker]="txtDOBData"
                      />
                      <mat-datepicker-toggle matSuffix [for]="txtDOBData">
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtDOBData></mat-datepicker>
                    </mat-form-field>
                  </div><br>
                  <div class="col-12">
                  <mat-form-field class="col-6">
                  
                    <input
                      autocomplete="new-txtChartNo"
                      matInput
                      maxlength="50"
                      formControlName="txtChartNo"
                   
                      type="text"
                    />
                    
                  </mat-form-field>
                  <mat-label class="col-6">NPI :</mat-label>
                    <span>{{'AARTHI RAM is Enrolled'}}</span>
                </div>
                </div>
                <div class="col-6">
                    <div class="col-6">
                    
                        <mat-checkbox 
                          >Compliance Dowload</mat-checkbox
                        ><br>
                        <mat-form-field >
                            <input
                            autocomplete="new-txtDOBValue"
                            formControlName="txtDOBValue"
                            matInput
                            placeholder="MM/DD/YYYY"
                            [matDatepicker]="txtDOBValue"
                          />
                          <mat-datepicker-toggle matSuffix [for]="txtDOBValue">
                          </mat-datepicker-toggle>
                          <mat-datepicker #txtDOBValue></mat-datepicker>
                        </mat-form-field>
                      </div><br>
                     
                      <div class="col-6">
                   
                        <mat-checkbox 
                          >Follow Up Face to Face</mat-checkbox
                        ><br>
                        <mat-form-field >
                            <input
                            autocomplete="new-txtDOBs"
                            formControlName="txtDOBs"
                            matInput
                            placeholder="MM/DD/YYYY"
                            [matDatepicker]="txtDOBs"
                          />
                          <mat-datepicker-toggle matSuffix [for]="txtDOBs">
                          </mat-datepicker-toggle>
                          <mat-datepicker #txtDOBs></mat-datepicker>
                        </mat-form-field>
                      </div> <br>

                    </div>
                </div>
                </form>
           
              </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card card-body">
            <from [formGroup]="InsCheckList">
                <div class="row">
                    <mat-form-field class="col-lg-4">
                        <mat-label>Add Comments</mat-label>
                        <textarea  matInput  rows="5" id="comment"></textarea>
                    </mat-form-field>
                    <div class="col-8">
                    <div class="card card-body">
                        <h1><b>Approved</b></h1>
                    <mat-form-field class="col-lg-8">
                        <mat-label>Prepared Date </mat-label>
                        <input
                          autocomplete="new-txtChartNo"
                          matInput
                          maxlength="50"
                          formControlName="txtPreparedDate"
                          placeholder="Enter Chart No"
                          type="text"
                        />
                      </mat-form-field>
                      <div class="col-4"></div>
                      <mat-form-field class="col-lg-8">
                        <mat-label>Prepared By</mat-label>
                        <input
                          autocomplete="new-txtName"
                          matInput
                          maxlength="50"
                          formControlName="txtPreparedBy"
                          placeholder="Enter Prepared By"
                          type="text"
                        />
                      </mat-form-field>
                      <div class="col-4"></div>
                      <mat-form-field class="algin col-lg-8">
                        <mat-label>Disapproved</mat-label>
                        <input
                          autocomplete="new-txtAddress"
                          matInput
                          maxlength="50"
                          formControlName="txtDisapproved"
                          placeholder="Enter Disapproved"
                          type="text"
                        />
                      </mat-form-field>
                      <div class="col-4"></div>
                      <mat-form-field class="algin col-lg-8">
                        <mat-label>Approved Date</mat-label>
                        <input
                          autocomplete="new-txtAddress"
                          matInput
                          maxlength="50"
                          formControlName="txtApprovedDate"
                          placeholder="Enter Approved Date"
                          type="text"
                        />
                      </mat-form-field>
                    </div>
                </div>
                </div>
            </from>
            <div class="col-sm-12">
                <div class="text-lg-center">
                  <button
                    mat-button
                    class="buttonColor mt-2 mb-2 mr-2"
                   
                  >
                    Save
                    
                  </button>
                  <button
                    mat-button
                    class="buttonColor mt-2 mb-2 mr-2"
                   
                  >
                    Approve
                    
                  </button>
                  <button
                    mat-button
                    class="resetclr buttonColor mt-2 mb-2 mr-2"
                  
                  >
                    Close
                  </button>
                  </div>
                  </div>
        </div>
    </div>
    
</div>
</mat-dialog-content>

