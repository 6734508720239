import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-saleorder-paymentprocess-dialog',
  templateUrl: './saleorder-paymentprocess-dialog.component.html',
  styleUrls: ['./saleorder-paymentprocess-dialog.component.scss'],
})
export class SaleorderPaymentprocessDialogComponent implements OnInit {
  [x: string]: any;
  startDate = new Date(1990, 0, 1);
  expiryMonth: Date;
  expiryYear: Date;
  dtOptions: any;

  constructor(
    private dialogRef: MatDialogRef<SaleorderPaymentprocessDialogComponent>
  ) {}

  // tslint:disable-next-line:typedef
  handleExpiryMonthChange(event: MatDatepickerInputEvent<Date>) {
    this.expiryMonth = event.value;
    // Add any additional logic you want to perform when the expiry month changes
  }
  // tslint:disable-next-line:typedef
  ngOnInit() {}

  // tslint:disable-next-line:typedef
  handleExpiryYearChange(event: MatDatepickerInputEvent<Date>) {
    this.expiryYear = event.value;
    // Add any additional logic you want to perform when the expiry year changes
  }
  // tslint:disable-next-line:typedef
  closeDialog() {
    this.dialogRef.close();
  }
}
