import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WonaceClaimTab } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/wonace-claim-tab.enum';
import {
  ClaimStatusListDto,
  InputClaimStatusListDto,
  InputWaystartStatusListDto,
  requestOfClaimwoance,
  WaystartStatusListDto,
} from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/dto/models';
import {
  InputClaimFormDTO,
  MyQueueBatchNumberMethodDTO,
  MyQueueClaimNumberDTO,
  MyQueuePostingMethodDTO,
  requestOfClaimwoance1,
} from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { ItemPaymentService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import {
  ClaimStatusOption,
  MyQueueClaimStatus,
  MyQueueClaimStatusText,
} from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Subscription } from 'rxjs';
import { MasterProviderService } from '../warehouse-proxy/inventory/master-provider.service';
import { ProviderGetDTO } from '../warehouse-proxy/inventory/dto/models';
import {
  InputPatientListDto,
  PatientChartIdDTO,
  PatientListDto,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-personal.service';
import { claimStatus } from 'projects/billing/src/app/billing-proxy/billing-management/billing/claim-status.enum';
import { DatePipe } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';
import { map, startWith } from 'rxjs/operators';
import {
  InputCheckNumberDTO,
  MyQueueCheckNumberDTO,
} from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto/models';
import { ClaimInfoDetailsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { ClaimBatchService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-batch.service';
import {
  InputOrderListDto,
  OrderListDto,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { ClaimProcessingService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
@Component({
  selector: 'app-claim-bin-queue',
  templateUrl: './claim-bin-queue.component.html',
  styleUrls: ['./claim-bin-queue.component.scss'],
})
export class ClaimBinQueueComponent implements OnInit {
  loadInboundData: boolean = false;
  MyQueueClaimStatusV1 = MyQueueClaimStatus;
  inboundStatusIds = MyQueueClaimStatus;
  lstSelectedChartNos: string[] = [];
  lstSelectedPatients: string[] = [];
  lstSelecteddrpProvider: string[] = [];
  lstSelectedClaimStatus: string[] = [];
  lstSelectedWaystarStatus: string[] = [];
  lstSelectedtPostingMethod: string[] = [];
  lstOrderNos: OrderListDto[] = [];
  drpWaystarStatus: WaystartStatusListDto[] = [];
  drpPatientName: PatientListDto[] = [];

  selectedCheckNoList: string[] = [];
  selectedClaimNoList: string[] = [];
  selectedClaimNoValue: string[] = [];
  selectedChartNoList: string[] = [];
  selectedProviderList: string[] = [];
  selectedBatchNoList: string[] = [];
  selectedStatusList: string[] = [];
  selectedwaystarList: string[] = [];
  selectedClaimStsList: string[] = [];
  lstSelectedOrderNos: string[] = [];
  lstSelectedPatientName: string[] = [];
  selectedPostMethodList: any[] = [];
  checkNoSelectAllOption: boolean = false;
  claimNoSelectAllOption: boolean = false;
  ChartNoSelectAllOption: boolean = false;
  BatchNoSelectAllOption: boolean = false;
  statusSelectAllOption: boolean = false;
  postMethodSelectAllOption: boolean = false;
  isOrderNoSelectionChanged: boolean = false;
  orderNoSelectAllOption: boolean = false;
  patientNameSelectAllOption: boolean = false;
  waystarStatusSelectAllOption: boolean = false;
  providerSelectAllOption: boolean = false;
  claimsubSatusSelectAllOption:boolean = false;
  selectedCheckNoValue: string[] = [];
  selectedBatchNoValue: string[] = [];
  drpCheckNo: MyQueueCheckNumberDTO[] = [];
  drpBatchNo: MyQueueBatchNumberMethodDTO[] = [];
  drpClaimNo: MyQueueClaimNumberDTO[] = [];
  drpChartId: PatientChartIdDTO[] = [];
  drpClaimStatus: ClaimStatusListDto[] = [];
  drpPostingMethod: MyQueuePostingMethodDTO[] = [];
  drpProvider: ProviderGetDTO[] = [];
  subscription$: Subscription[] = [];
  lstSelectedtClaimsubSatus: string[] = [];
  checkNoDrpsubcription: Subscription;
  batchNoDrpsubcription: Subscription;
  statusDrpsubcription: Subscription;
  postmethodDrpsubcription: Subscription;
  orderNoDD$: Subscription;
  selectedFromDate:any;
  selectedToDate:any;
  overallTableCount:number = 0;
  paginationInfo:any;
  recordsPerPage:number = 50;
  txtClaimbSatusTypeControl = new FormControl();
  claimStatusOptions:any;
  public lstClaimsubSatus: ClaimStatusOption[] = [
    { value: 'Fill Claim Form'},
    { value: 'Ready to process' },
  ];

  public lstFilterCheckNo: any | undefined;
  public lstClaimStatus: any | undefined;
  public lstPostindMethod: any | undefined;
  public lstprovider: any | undefined;
  public lstFilterBatchNo: any | undefined;
  public lstFilterwaystarStatus: any | undefined;
  public lstFilterClaimNo: any | undefined;
  public lstChartNos: any | undefined;
  public lstPatients: any | undefined;
  postingMethodResponse: MyQueuePostingMethodDTO[] = [
    {
      postingMethodId: 0,
      postingMethod: 'Zermid',
      postingType: 0,
      isActive: 1,
      tenantId: undefined,
      claimFormId: undefined,
    },
    {
      postingMethodId: 1,
      postingMethod: 'Manual',
      postingType: 0,
      isActive: 1,
      tenantId: undefined,
      claimFormId: undefined,
    },
  ];
  menus = [
    {
      status: MyQueueClaimStatusText.ClaimSubmission,
      color: '#faa307',
      count: 0,
      arrowclass: 'claimsubmission-down',
      inboundStatusIds: MyQueueClaimStatus.ClaimSubmission,
    },
    {
      status: MyQueueClaimStatusText.AutoPosting,
      color: '#b56576',
      count: 0,
      arrowclass: 'autoposting-down',
      inboundStatusIds: MyQueueClaimStatus.AutoPosting,
    },
    {
      status: MyQueueClaimStatusText.ClaimPosting,
      color: '#c77dff',
      count: 0,
      arrowclass: 'claimposting-down',
      inboundStatusIds: MyQueueClaimStatus.ClaimPosting,
    },
    {
      status: MyQueueClaimStatusText.ClaimChecklist,
      color: '#57cc99',
      count: 0,
      arrowclass: 'claimchecklist-down',
      inboundStatusIds: MyQueueClaimStatus.ClaimChecklist,
    },
  ];
  inboundTableData: any;
  txtDOSFromDate: any;
  activeTabBin: MyQueueClaimStatusText;
  inboundstatusCode: any;
  chartIdDD$: Subscription;
  getClaimTableData: Subscription;
  patientDD$: Subscription;
  providerDD$: Subscription;
  cliamBinQueueFilterForm: FormGroup;
  inititalLoading: boolean = true;
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = 50;
  visibleColumns: number[];

  fromDate: any;
  toDate: any;

  constructor(
    private itemPaymentService: ItemPaymentService,
    private claiminfoDetailsService: ClaimInfoDetailsService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private masterProviderService: MasterProviderService,
    private patientPersonalService: PatientPersonalService,
    private claimbatchService: ClaimBatchService,
    private mmOrderService: MmOrderService,
    private claimProcessingService: ClaimProcessingService
  ) {}

  ngOnInit(): void {
    this.initializeForms();
    this.getClaimFileterList(this.inboundStatusIds.ClaimSubmission);
    this.activeTabBin = MyQueueClaimStatusText.ClaimSubmission;
    this.inboundstatusCode = MyQueueClaimStatus.ClaimSubmission;
    this.getClaimFileterList(this.inboundstatusCode);
    this.getClaimTabsSummary();
    this.loadProviderDropdown([]);
    this.loadChartNoDropdown([]);
    this.loadPatientDropdown([]);
    this.getCheckNoDropDown('', []);
    this.getClaimStatusDropDown('', []);
    this.getClaimPostMethodDropDown('', []);
    this.loadOrderNoDropdown();
    this.getBatchNoDropDown('', []);
    this.getClaimWaystarStatusDropDown('', []);
    this.getClaimNoDropDown('', []);
    this.claimStatusOptions = this.lstClaimsubSatus;

    this.txtClaimbSatusTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.claimStatusOptions = this.lstClaimsubSatus.filter((dx) =>
        dx.value.toLowerCase().includes(searchTerm)
      );
    });

  }

  initializeForms() {
    this.cliamBinQueueFilterForm = this.fb.group({
      drpProvider: new FormControl(''),
      txtProvider: new FormControl(''),
      drpChartId: new FormControl(''),
      drpPatientName: new FormControl(''),
      txtPatientName: new FormControl(''),
      txtChartId: new FormControl(''),
      txtDOSFromDate: new FormControl(''),
      txtDOSEndDate: new FormControl(''),
      txtClaimStatus: new FormControl(''),
      drpCheckNo: new FormControl(''),
      txtCheckNoSearch: new FormControl(''),
      drpClaimStatus: new FormControl(''),
      drpPostingMethod: new FormControl(''),
      txtPostingMethod: new FormControl(''),
      drpOrderNo: new FormControl(''),
      txtOrderNo: new FormControl(''),
      drpBatchNo: new FormControl(''),
      txtBatchNoSearch: new FormControl(''),
      txtWaystarStatus: new FormControl(''),
      drpWaystarStatus: new FormControl(''),
      drpClaimNo: new FormControl(''),
      txtClaimNoSearch: new FormControl(''),
      drpClaimsubSatus: new FormControl(''),
    });
  }

  //Prvoider Multi Select
  onSelectionProviderChange(event: MatSelectChange): void {
    this.selectedProviderList = event.value;
    this.isProviderSelectionChange = true;
    if (this.selectedProviderList.includes('selectAll')) {
      this.providerSelectAllOption = true;
      this.selectedProviderList = this.drpProvider.map((data) => data?.pId);
      this.cliamBinQueueFilterForm
        .get('drpProvider')
        .patchValue(this.selectedProviderList);
    } else if (this.selectedProviderList.includes('DeselectAll')) {
      this.selectedProviderList = [];
      this.providerSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpProvider').patchValue([]);
    } else {
      this.providerSelectAllOption =
        this.selectedProviderList.length === this.drpProvider.length;
    }
  }

  // Check No Drop Down
  onSelectionCheckNoChange(event: MatSelectChange): void {
    this.selectedCheckNoList = event.value;
    this.isCheckNoSelectionChange = true;
    if (this.selectedCheckNoList.includes('selectAll')) {
      this.checkNoSelectAllOption = true;
      this.selectedCheckNoList = this.drpCheckNo.map(
        (data) => data.itemPaymentId
      );
      this.cliamBinQueueFilterForm
        .get('drpCheckNo')
        .patchValue(this.selectedCheckNoList);
    } else if (this.selectedCheckNoList.includes('DeselectAll')) {
      this.selectedCheckNoList = [];
      this.checkNoSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpCheckNo').patchValue([]);
    } else {
      this.checkNoSelectAllOption =
        this.selectedCheckNoList.length === this.drpCheckNo.length;
    }
  }

  //Claim No Multi Select
  onSelectionClaimNoChange(event: MatSelectChange): void {
    this.selectedClaimNoList = event.value;
    this.isClaimSelectionChange = true;
    if (this.selectedClaimNoList.includes('selectAll')) {
      this.claimNoSelectAllOption = true;
      this.selectedClaimNoList = this.drpClaimNo.map(
        (data) => data.claimFormId
      );
      this.cliamBinQueueFilterForm
        .get('drpClaimNo')
        .patchValue(this.selectedClaimNoList);
    } else if (this.selectedClaimNoList.includes('DeselectAll')) {
      this.selectedClaimNoList = [];
      this.claimNoSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpClaimNo').patchValue([]);
    } else {
      this.claimNoSelectAllOption =
        this.selectedClaimNoList.length === this.drpClaimNo.length;
    }
  }

  //Chart No Multi Select
  onSelectionChartNoChange(event: MatSelectChange): void {
    this.selectedChartNoList = event.value;
    this.isChartIdSelectionChanged = true;
    if (this.selectedChartNoList.includes('selectAll')) {
      this.ChartNoSelectAllOption = true;
      this.selectedChartNoList = this.drpChartId.map((data) => data.spatientID);
      this.cliamBinQueueFilterForm
        .get('drpChartId')
        .patchValue(this.selectedChartNoList);
    } else if (this.selectedChartNoList.includes('DeselectAll')) {
      this.selectedChartNoList = [];
      this.ChartNoSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpChartId').patchValue([]);
    } else {
      this.ChartNoSelectAllOption =
        this.selectedChartNoList.length === this.drpChartId.length;
    }
  }

  // Batch No Drop Down
  onSelectionBatchNoChange(event: MatSelectChange): void {
    this.selectedBatchNoList = event.value;
    this.isBatchNoSelectionChanged = true;

    if (this.selectedBatchNoList.includes('selectAll')) {
      this.BatchNoSelectAllOption = true;
      this.selectedBatchNoList = this.drpBatchNo.map(
        (data) => data.claimFormId
      );
      this.cliamBinQueueFilterForm
        .get('drpBatchNo')
        .patchValue(this.selectedBatchNoList);
    } else if (this.selectedBatchNoList.includes('DeselectAll')) {
      this.selectedBatchNoList = [];
      this.BatchNoSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpBatchNo').patchValue([]);
    } else {
      this.BatchNoSelectAllOption =
        this.selectedBatchNoList.length === this.drpBatchNo.length;
    }
  }

  // Waystar Status Drop Down
  onSelectionWaystarStatusChange(event: MatSelectChange): void {
    this.selectedwaystarList = event.value;
    this.isWaysterStsSelectionChanged = true;
    if (this.selectedwaystarList.includes('selectAll')) {
      this.waystarStatusSelectAllOption = true;
      this.selectedwaystarList = this.drpWaystarStatus.map(
        (data) => data.waystarStatusId
      );
      this.cliamBinQueueFilterForm
        .get('drpWaystarStatus')
        .patchValue(this.selectedwaystarList);
    } else if (this.selectedwaystarList.includes('DeselectAll')) {
      this.selectedwaystarList = [];
      this.waystarStatusSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpWaystarStatus').patchValue([]);
    } else {
      this.waystarStatusSelectAllOption =
        this.selectedwaystarList.length === this.drpWaystarStatus.length;
    }
  }

  onSelectionclaimStatusChange(event: MatSelectChange): void {
    this.selectedClaimStsList = event.value;
    this.isClaimStsSelectionChanged = true;
    if (this.selectedClaimStsList.includes('selectAll')) {
      this.claimsubSatusSelectAllOption = true;
      this.selectedClaimStsList = this.lstClaimsubSatus.map((data) => data.value);
      this.cliamBinQueueFilterForm.get('drpClaimsubSatus').patchValue(this.selectedClaimStsList);
    } else if (this.selectedClaimStsList.includes('DeselectAll')) {
      this.selectedClaimStsList = [];
      this.claimsubSatusSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpClaimsubSatus').patchValue([]);
    } else {
      this.claimsubSatusSelectAllOption =
        this.selectedClaimStsList.length === this.lstClaimsubSatus.length;
    }
  }

  onSelectionStatusChange(event: MatSelectChange): void {
    this.selectedStatusList = event.value;
    this.isStatusSelectionChanged = true;
    if (this.selectedStatusList.includes('selectAll')) {
      this.statusSelectAllOption = true;
      this.selectedStatusList = this.drpClaimStatus.map(
        (data) => data.claimStatusId
      );
      this.cliamBinQueueFilterForm
        .get('drpClaimStatus')
        .patchValue(this.selectedStatusList);
    } else if (this.selectedStatusList.includes('DeselectAll')) {
      this.selectedStatusList = [];
      this.statusSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpClaimStatus').patchValue([]);
    } else {
      this.statusSelectAllOption =
        this.selectedStatusList.length === this.drpClaimStatus.length;
    }
  }

  onSelectionPostMethodChange(event: MatSelectChange): void {
    this.selectedPostMethodList = event.value;
    this.isPostMethodSelectionChanged = true;
    if (this.selectedPostMethodList.includes('selectAll')) {
      this.postMethodSelectAllOption = true;
      this.selectedPostMethodList = this.drpPostingMethod.map(
        (data) => data.postingMethodId
      );
      this.cliamBinQueueFilterForm
        .get('drpPostingMethod')
        .patchValue(this.selectedPostMethodList);
    } else if (this.selectedPostMethodList.includes('DeselectAll')) {
      this.selectedPostMethodList = [];
      this.postMethodSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpPostingMethod').patchValue([]);
    } else {
      this.postMethodSelectAllOption =
        this.selectedPostMethodList.length === this.drpPostingMethod.length;
    }
  }

  onSelectionOrderNoChange(event: MatSelectChange): void {
    this.isOrderNoSelectionChanged = true;
    this.lstSelectedOrderNos = event.value;
    if (this.lstSelectedOrderNos.includes('selectAll')) {
      this.orderNoSelectAllOption = true;
      if (this.orderNoSelectAllOption) {
        this.lstSelectedOrderNos = this.lstOrderNos.map((data) => data.orderId);
        this.cliamBinQueueFilterForm
          .get('drpOrderNo')
          .patchValue(this.lstSelectedOrderNos);
      }
    } else if (this.lstSelectedOrderNos.includes('DeselectAll')) {
      this.lstSelectedOrderNos = [];
      this.orderNoSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpOrderNo').patchValue([]);
    } else {
      this.orderNoSelectAllOption =
        this.lstSelectedOrderNos.length === this.lstOrderNos.length;
    }
  }

  onSelectionPatientNameChange(event: MatSelectChange): void {
    this.lstSelectedPatientName = event.value;
    this.isPatientSelectionChanged = true;
    if (this.lstSelectedPatientName.includes('selectAll')) {
      this.patientNameSelectAllOption = true;
      if (this.patientNameSelectAllOption) {
        this.lstSelectedPatientName = this.drpPatientName.map(
          (data) => data.patId
        );
        this.cliamBinQueueFilterForm
          .get('drpPatientName')
          .patchValue(this.lstSelectedPatientName);
      }
    } else if (this.lstSelectedPatientName.includes('DeselectAll')) {
      this.lstSelectedPatientName = [];
      this.patientNameSelectAllOption = false;
      this.cliamBinQueueFilterForm.get('drpPatientName').patchValue([]);
    } else {
      this.patientNameSelectAllOption =
        this.lstSelectedPatientName.length === this.drpPatientName.length;
    }
    this.lstSelectedPatientName =
      this.cliamBinQueueFilterForm.value.drpPatientName;
  }
  onSelectionClaimStsChange(event: MatSelectChange): void {
    this.isClaimStsSelectionChanged = true;
  }

  getCheckNoDropDown(CheckNoInput: string, checkNoId: string[]) {
    if (this.checkNoDrpsubcription) {
      this.checkNoDrpsubcription.unsubscribe();
    }
    let searchInput: InputCheckNumberDTO = {
      sSearch: CheckNoInput,
      itemPaymentIds: checkNoId || [],
    };
    this.checkNoDrpsubcription = this.claiminfoDetailsService
      .getCheckNumberForMyQueueByInput(searchInput)
      .subscribe(
        (response) => {
          this.drpCheckNo = response || [];
          this.lstFilterCheckNo = this.cliamBinQueueFilterForm
            ?.get('txtCheckNoSearch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpCheckNo?.filter((option) =>
                  option?.checkNumber
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }

  getClaimNoDropDown(claimNoInput: string, claimNoId: string[]) {
    if (this.checkNoDrpsubcription) {
      this.checkNoDrpsubcription.unsubscribe();
    }
    let searchInput: InputClaimFormDTO = {
      sSearch: claimNoInput,
      claimFormIds: claimNoId,
    };
    this.checkNoDrpsubcription = this.itemPaymentService
      .getClaimNumberForMyQueueByInput(searchInput)
      .subscribe(
        (response) => {
          this.drpClaimNo = response || [];
          this.lstFilterClaimNo = this.cliamBinQueueFilterForm
            ?.get('txtClaimNoSearch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpClaimNo?.filter((option) =>
                  option?.claimNumber
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }

  getBatchNoDropDown(batchNoInput: string, batchNoId: string[]) {
    if (this.batchNoDrpsubcription) {
      this.batchNoDrpsubcription.unsubscribe();
    }
    let searchInput: InputClaimFormDTO = {
      sSearch: batchNoInput,
      claimFormIds: batchNoId,
    };
    this.batchNoDrpsubcription = this.itemPaymentService
      .getBatchNumberForMyQueueByInput(searchInput)
      .subscribe(
        (response) => {
          this.drpBatchNo = response || [];
          this.lstFilterBatchNo = this.cliamBinQueueFilterForm
            ?.get('txtBatchNoSearch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBatchNo?.filter((option) =>
                  option?.batchNumber
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }

  getClaimWaystarStatusDropDown(statusInput: string, statusId: string[]) {
    if (this.batchNoDrpsubcription) {
      this.batchNoDrpsubcription.unsubscribe();
    }
    let searchInput: InputWaystartStatusListDto = {
      sSearch: statusInput,
      lstClaimIds: statusId,
    };
    this.batchNoDrpsubcription = this.claimProcessingService
      .getWayStarStatusForMyQueueByInput(searchInput)
      .subscribe(
        (response) => {
          this.drpWaystarStatus = response || [];
          this.lstFilterwaystarStatus = this.cliamBinQueueFilterForm
            ?.get('txtBatchNoSearch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpWaystarStatus?.filter((option) =>
                  option?.waystarStatus
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }

  getClaimStatusDropDown(statusInput: string, statusId: string[]) {
    if (this.statusDrpsubcription) {
      this.statusDrpsubcription.unsubscribe();
    }
    let searchInput: InputClaimStatusListDto = {
      sSearch: statusInput,
      lstClaimIds: statusId,
    };
    this.statusDrpsubcription = this.claimbatchService
      .getClaimStatusForMyQueueByInput(searchInput)
      .subscribe(
        (response) => {
          this.drpClaimStatus = response || [];
          this.lstClaimStatus = this.cliamBinQueueFilterForm
            ?.get('txtClaimStatus')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpClaimStatus?.filter((option) =>
                  option?.claimStatusName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }

  // getClaimPostMethodDropDown(postmethodInput: string, postmethodId: string[]) {
  //   if (this.postmethodDrpsubcription) {
  //     this.postmethodDrpsubcription.unsubscribe();
  //   }
  //   let searchInput : InputClaimFormDTO ={
  //     sSearch: postmethodInput,
  //     claimFormIds: postmethodId
  //   }
  //   this.postmethodDrpsubcription = this.itemPaymentService.getPostingMethodForMyQueueByInput(searchInput)
  //     .subscribe(
  //       (response) => {
  //         this.drpPostingMethod = response || [];
  //         this.lstPostindMethod = this.cliamBinQueueFilterForm
  //           ?.get('txtPostingMethod')
  //           ?.valueChanges?.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.drpPostingMethod?.filter((option) =>
  //                 option?.postingMethod
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //       },
  //       (err) => {
  //       }
  //     );
  // }

  getClaimPostMethodDropDown(postmethodInput: string, postmethodId: string[]) {
    this.drpPostingMethod = this.postingMethodResponse || [];
    this.lstPostindMethod = this.cliamBinQueueFilterForm
      ?.get('txtPostingMethod')
      ?.valueChanges?.pipe(
        startWith(''),
        map((value) =>
          this.drpPostingMethod?.filter((option) =>
            option?.postingMethod
              ?.toLowerCase()
              ?.includes(value?.toLowerCase() ?? '')
          )
        )
      );
  }

  loadOrderNoDropdown() {
    if (this.orderNoDD$) {
      this.orderNoDD$.unsubscribe();
    }
    let params: InputOrderListDto = {
      iSearch:
        this.cliamBinQueueFilterForm?.value?.txtOrderNo == '' ||
        this.cliamBinQueueFilterForm?.value?.txtOrderNo == undefined
          ? null
          : Number(this.cliamBinQueueFilterForm?.value?.txtOrderNo),
      orderIds: this.lstSelectedOrderNos || [],
    };
    this.orderNoDD$ = this.mmOrderService
      .getOrderNumbersForMyQueueByInput(params)
      .subscribe(
        (response) => {
          this.lstOrderNos = response ?? [];
        },
        (err) => {
          this.lstOrderNos = [];
        }
      );
  }

  isCheckNoDropdownOpen = false;
  onCheckNoDropdownOpenChange(isOpen: boolean, checkNoId: string[]) {
    this.isCheckNoDropdownOpen = isOpen;
    if (this.isCheckNoDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtCheckNoSearch: '',
      });
      this.getCheckNoDropDown('', checkNoId);
    }
  }

  isClaimNoDropdownOpen = false;
  onClaimNoDropdownOpenChange(isOpen: boolean, claimNoId: string[]) {
    this.isClaimNoDropdownOpen = isOpen;
    if (this.isClaimNoDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtCheckNoSearch: '',
      });
      this.getClaimNoDropDown('', claimNoId);
    }
  }

  isProviderNameDropdownOpen = false;
  onProviderNameDropdownOpenChange(isOpen: boolean, providerId: string[]) {
    this.isProviderNameDropdownOpen = isOpen;
    if (this.isProviderNameDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtProvider: '',
      });
      this.loadProviderDropdown(providerId);
    }
  }

  isStatusDropdownOpen = false;
  onStatusDropdownOpenChange(isOpen: boolean, statusId: string[]) {
    this.isStatusDropdownOpen = isOpen;
    if (this.isStatusDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtClaimStatus: '',
      });
      this.getClaimStatusDropDown('', statusId);
    }
  }

  isWaystarStatusDropdownOpen = false;
  onWaystarStatusDropdownOpenChange(isOpen: boolean, statusId: string[]) {
    this.isWaystarStatusDropdownOpen = isOpen;
    if (this.isWaystarStatusDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtWaystarStatus: '',
      });
      this.getClaimWaystarStatusDropDown('', statusId);
    }
  }

  isPostMethodDropdownOpen = false;
  onPostMethodsDropdownOpenChange(isOpen: boolean, postmethodId: string[]) {
    this.isPostMethodDropdownOpen = isOpen;
    if (this.isPostMethodDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtPostingMethod: '',
      });
      this.getClaimPostMethodDropDown('', postmethodId);
    }
  }

  isBatchNodDropdownOpen = false;
  onBatchNoDropdownOpenChange(isOpen: boolean, batchNoId: string[]) {
    this.isBatchNodDropdownOpen = isOpen;
    if (this.isBatchNodDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtBatchNoSearch: '',
      });
      this.getBatchNoDropDown('', batchNoId);
    }
  }

  isChartNodDropdownOpen = false;
  onChartNoDropdownOpenChange(isOpen: boolean, ChartNoId: string[]) {
    this.isChartNodDropdownOpen = isOpen;
    if (this.isChartNodDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtChartId: '',
      });
      this.loadChartNoDropdown(ChartNoId);
    }
  }

  isPatientNamedDropdownOpen = false;
  onPatientNameDropdownOpenChange(isOpen: boolean, ChartNoId: string[]) {
    this.isPatientNamedDropdownOpen = isOpen;
    if (this.isPatientNamedDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtPatientName: '',
      });
      this.loadPatientDropdown(ChartNoId);
    }
  }
  isOrderNoDropdownOpen: boolean = false;
  onOrderNoDropdownOpenChange(isOpen: boolean) {
    this.isOrderNoDropdownOpen = isOpen;
    if (this.isOrderNoDropdownOpen) {
      this.cliamBinQueueFilterForm.patchValue({
        txtOrderNo: '',
      });
      this.currentPage = 1;
      this.loadOrderNoDropdown();
    }
  }

  handleCloseDrpdown(value: any): void {
    if (value) {
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  isProviderSelectionChange: boolean = false;
  isClaimSelectionChange: boolean = false;
  isCheckNoSelectionChange: boolean = false;
  isBatchNoSelectionChanged: boolean = false;
  isChartIdSelectionChanged: boolean = false;
  isPatientSelectionChanged: boolean = false;
  isClaimStsSelectionChanged: boolean = false;
  isWaysterStsSelectionChanged: boolean = false;
  isStatusSelectionChanged: boolean = false;
  isPostMethodSelectionChanged: boolean = false;
  handleProviderCloseDrpdown(value: any): void {
    if (value && this.isProviderSelectionChange) {
      this.isProviderSelectionChange = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }
  handleClaimCloseDrpdown(value: any): void {
    if (value && this.isClaimSelectionChange) {
      this.isClaimSelectionChange = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handleCheckNoCloseDrpdown(value: any): void {
    if (value && this.isCheckNoSelectionChange) {
      this.isCheckNoSelectionChange = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handleOrderNoCloseDrpdown(value: any): void {
    if (value && this.isOrderNoSelectionChanged) {
      this.isOrderNoSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handleBatchNoCloseDrpdown(value: any): void {
    if (value && this.isBatchNoSelectionChanged) {
      this.isBatchNoSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handleChartIdCloseDrpdown(value: any): void {
    if (value && this.isChartIdSelectionChanged) {
      this.isChartIdSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handlePatientCloseDrpdown(value: any): void {
    if (value && this.isPatientSelectionChanged) {
      this.isPatientSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handleClaimStsCloseDrpdown(value: any): void {
    if (value && this.isClaimStsSelectionChanged) {
      this.isClaimStsSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handleWaysterStsCloseDrpdown(value: any): void {
    if (value && this.isWaysterStsSelectionChanged) {
      this.isWaysterStsSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handleStatusCloseDrpdown(value: any): void {
    if (value && this.isStatusSelectionChanged) {
      this.isStatusSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  handlePostMethodCloseDrpdown(value: any): void {
    if (value && this.isPostMethodSelectionChanged) {
      this.isPostMethodSelectionChanged = false;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  clearDOS() {
    this.cliamBinQueueFilterForm.patchValue({
      txtDOSFromDate: null,
      txtDOSEndDate: null,
    });
    this.fromDate = null;
    this.toDate = null;
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.getClaimFileterList(this.inboundstatusCode);
  }
  //Load Provider Dropdown
  loadProviderDropdown(providerId: string[]) {
    if (this.providerDD$) {
      this.providerDD$.unsubscribe();
    }
    let pName: string = this.cliamBinQueueFilterForm?.value?.txtProvider,
      sProvider: string[] = providerId;
    this.providerDD$ = this.masterProviderService
      .getProviderNameByPNameAndSProvider(pName, sProvider)
      .subscribe(
        (response) => {
          this.lstprovider = response ?? [];

          this.drpProvider = response || [];
          this.lstprovider = this.cliamBinQueueFilterForm
            ?.get('txtProvider')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpProvider?.filter((option) =>
                  option?.providerName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.lstprovider = [];
        }
      );
  }

  //Load Chart No Dropdown
  loadChartNoDropdown(ChartNoId: string[]) {
    if (this.chartIdDD$) {
      this.chartIdDD$.unsubscribe();
    }
    let chartSearchString =
      this.cliamBinQueueFilterForm?.value?.txtChartId ?? '';

    this.chartIdDD$ = this.patientPersonalService
      .createChartIdByChartIdPATAndSPatient(chartSearchString, ChartNoId ?? [])
      .subscribe(
        (response) => {
          this.drpChartId = response || [];
          this.lstChartNos = this.cliamBinQueueFilterForm
            ?.get('txtChartId')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpChartId?.filter((option) =>
                  option?.chartId
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.lstChartNos = [];
        }
      );
  }
  //Patient Dropdown
  loadPatientDropdown(patientId: string[]) {
    if (this.patientDD$) {
      this.patientDD$.unsubscribe();
    }

    let params: InputPatientListDto = {
      // gPat: this.lstSelectedPatients ?? [],
      gPat: patientId ?? [],
      sSearch: this.cliamBinQueueFilterForm?.value?.txtPatientName ?? '',
    };
    this.patientDD$ = this.patientPersonalService
      .getPatientListByInput(params)
      .subscribe(
        (response) => {
          this.drpPatientName = response || [];
          this.lstPatients = this.cliamBinQueueFilterForm
            ?.get('txtPatientName')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpPatientName?.filter((option) =>
                  option?.fullName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.lstPatients = [];
        }
      );
  }

  getArrowClass(data: any): any {
    return { [data.arrowclass]: this.activeTabBin === data.status };
  }

  binActiveTab(data: any) {
    this.activeTabBin = data?.status;
    this.inboundstatusCode = data?.inboundStatusIds;
    this.currentPage = 1;
    this.toDate = null;
    this.fromDate = null;
    this.cliamBinQueueFilterForm.reset();
    this.getClaimFileterList(this.inboundstatusCode);
  }

  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  onDateRangeChange() {
    const startDate = this.cliamBinQueueFilterForm.get('txtDOSFromDate').value;
    const endDate = this.cliamBinQueueFilterForm.get('txtDOSEndDate').value;
    this.selectedFromDate = startDate;
    this.selectedToDate = endDate;
    this.fromDate = this.datepipe.transform(startDate, 'yyyy-MM-dd');
    this.toDate = this.datepipe.transform(endDate, 'yyyy-MM-dd');
    if (startDate != null && endDate != null) {
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  getClaimFileterList(inboundStatusIds: any) {
    let shortCode = inboundStatusIds;

    const selectedChartNo = this.cliamBinQueueFilterForm.value.drpChartId || [];
    const selectedPatientName =
      this.cliamBinQueueFilterForm.value.drpPatientName || [];
    const patientId = [...selectedChartNo, ...selectedPatientName];

    const selectedChekNoIds =
      this.cliamBinQueueFilterForm.value.drpCheckNo || [];
    const filteredCheckNumbers = (this.drpCheckNo ?? [])
      .filter((data) =>
        (selectedChekNoIds ?? []).includes(data?.itemPaymentId ?? '')
      )
      .map((data) => data?.checkNumber ?? '');

    const selectedBatchNoIds =
      this.cliamBinQueueFilterForm.value.drpBatchNo || [];
    const filteredBatchNumbers = (this.drpBatchNo ?? [])
      .filter((data) =>
        (selectedBatchNoIds ?? []).includes(data?.claimFormId ?? '')
      )
      .map((data) => data?.batchNumber ?? '');

    const selectedWaystarIds =
      this.cliamBinQueueFilterForm.value.drpWaystarStatus || [];
    const filteredWaystarStatus = (this.drpWaystarStatus ?? [])
      .filter((data) =>
        (selectedWaystarIds ?? []).includes(data?.waystarStatusId ?? '')
      )
      .map((data) => data?.waystarStatus ?? '');

    let dto: requestOfClaimwoance1 = {
      postingMethod: this.cliamBinQueueFilterForm.value.drpPostingMethod || [],
      shortCode: shortCode,
      checkNo: filteredCheckNumbers || [],
      batchNo: filteredBatchNumbers || [],
      // claimProcessingStatus: this.cliamBinQueueFilterForm.value.drpClaimsubSatus
      //   ? [this.cliamBinQueueFilterForm.value.drpClaimsubSatus]
      //   : [],
      claimProcessingStatus: this.cliamBinQueueFilterForm.value.drpClaimsubSatus || [],
      waystarStatus: filteredWaystarStatus || [],
      claimStatus: this.cliamBinQueueFilterForm.value.drpClaimStatus || [],
      patientId: patientId,
      orderId: this.cliamBinQueueFilterForm.value.drpOrderNo || [],
      claimId: this.cliamBinQueueFilterForm.value.drpClaimNo || [],
      fromdos: this.fromDate,
      todos: this.toDate,
      providerId: this.lstSelecteddrpProvider || [],
      pageNo: this.currentPage,
      pageOffset: this.pageOffset,
    };
    this.loadInboundData = true;
    if (this.getClaimTableData) {
      this.getClaimTableData.unsubscribe();
    }
    this.getClaimTableData = this.itemPaymentService
      .getClaimTabReportByDto(dto)
      .subscribe(
        (response) => {
          if (shortCode == MyQueueClaimStatus.ClaimSubmission) {
            this.overallTableCount = response?.claimSubmissionCount || 0;
            this.totalPages = Math.ceil(response?.claimSubmissionCount / this.pageOffset);
            this.inboundTableData = response?.claimSubmission;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                dos: this.commonService.getFormattedDateZone(inbound.dos),
              };
            });
          } else if (shortCode == MyQueueClaimStatus.AutoPosting) {
            this.overallTableCount = response?.autoPostingCount || 0;
            this.totalPages = Math.ceil(response?.autoPostingCount / this.pageOffset);
            this.inboundTableData = response?.autoPosting;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                checkDate: this.commonService.getFormattedDateZone(
                  inbound.checkDate
                ),
                itempaymentDetails: inbound.itempaymentDetails.map((item) => {
                  return {
                    ...item,

                    dtDos: this.commonService.getFormattedDateZone(item.dtDos),
                  };
                }),
              };
            });
          } else if (shortCode == MyQueueClaimStatus.ClaimPosting) {
            this.overallTableCount = response?.claimPostingCount || 0;
            this.totalPages = Math.ceil(response?.claimPostingCount / this.pageOffset);
            this.inboundTableData = response?.claimPosting;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                checkDate: this.commonService.getFormattedDateZone(
                  inbound.checkDate
                ),
                processedDate: this.commonService.getFormattedDateZone(
                  inbound.processedDate
                ),

                isCCSettled: inbound.isCCSettled ? 'Settled' : 'Unsettled',
              };
            });
          } else if (shortCode == MyQueueClaimStatus.ClaimChecklist) {
            this.overallTableCount = response?.claimChecklistCount || 0;
            this.totalPages = Math.ceil(response?.claimChecklistCount / this.pageOffset);
            this.inboundTableData = response?.claimChecklist;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                chequeDate: this.commonService.getFormattedDateZone(
                  inbound.chequeDate
                ),
                postingDate: this.commonService.getFormattedDateZone(
                  inbound.postingDate
                ),
              };
            });
          }
          this.paginationInfo = {
            startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
            endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
            overallTableCount : this.overallTableCount
          };
        setTimeout(() => {
          this.cliamBinQueueFilterForm.patchValue({
            txtDOSFromDate: this.selectedFromDate,
            txtDOSEndDate: this.selectedToDate
          });
        },100);


          this.loadInboundData = false;
        },
        (err) => {
          this.loadInboundData = false;
        }
      );
  }

  getClaimTabsSummary() {
    const claimSummary = this.mmOrderService.myQueueClaimTabSummary().subscribe(
      (response) => {
        this.menus.forEach((menu) => {
          switch (menu.status) {
            case MyQueueClaimStatusText.ClaimSubmission:
              menu.count = response?.claimSubmissionCount || 0;
              break;
            case MyQueueClaimStatusText.AutoPosting:
              menu.count = response?.autoPostingCount || 0;
              break;
            case MyQueueClaimStatusText.ClaimPosting:
              menu.count = response?.claimPostingCount || 0;
              break;
            case MyQueueClaimStatusText.ClaimChecklist:
              menu.count = response?.claimCheckListCount || 0;
              break;
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
    this.subscription$.push(claimSummary);
  }

  clearClaimSubStatusDrp() {
    this.cliamBinQueueFilterForm.patchValue({
      drpClaimsubSatus: null,
    });
    this.claimsubSatusSelectAllOption=false;
    this.getClaimFileterList(this.inboundstatusCode);
  }
  resetForm() {
    this.providerSelectAllOption = false;
    this.claimNoSelectAllOption = false;
    this.checkNoSelectAllOption = false;
    this.orderNoSelectAllOption = false;
    this.BatchNoSelectAllOption = false;
    this.ChartNoSelectAllOption = false;
    this.patientNameSelectAllOption = false;
    this.statusSelectAllOption = false;
    this.waystarStatusSelectAllOption = false;
    this.postMethodSelectAllOption = false;
    this.cliamBinQueueFilterForm.reset();
    this.fromDate = null;
    this.toDate = null;
    this.selectedFromDate = null;
    this.selectedToDate = null;
    setTimeout(() => {
      this.cliamBinQueueFilterForm.patchValue({
        txtDOSFromDate: null,
        txtDOSEndDate: null,
      });
      this.getClaimFileterList(this.inboundstatusCode);
    }, 100);
  }
}
