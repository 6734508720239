import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { ShippingTypesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/shipping-types';
import {
  ProductDetailsService,
  VendorDetailsService,
} from '../item-proxy/item-management/optimization';
import { ToastrService } from 'ngx-toastr';
import { RequisitionService } from '../item-proxy/item-management/optimization/requisition.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import moment from 'moment';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import {
  CreateUpdateRequisitionDTO,
  RequisitionProductDTO,
} from '../item-proxy/item-management/optimization/dto';
import Swal from 'sweetalert2';
import { element } from 'protractor';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { event } from 'jquery';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-requisition-approval-tab',
  templateUrl: './requisition-approval-tab.component.html',
  styleUrls: ['./requisition-approval-tab.component.scss'],
})
export class RequisitionApprovalTabComponent implements OnInit {
  requisitionForm: FormGroup;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  notesText = '';
  requisitionTotal: any;
  itemCount: number = 0;
  requisitionDate: string = '';
  ltShippingMethods: any;
  lstBranch: any;
  ltVendors: any;
  searchCtrl: string = '';
  isDropDown: boolean = false;
  filteredVendorDetails: Observable<string[]> | undefined;
  loadTable = true;
  reqId: string;
  ltproducts: any = [];
  searchControl = new FormControl();
  filteredProductDetails: any;
  userId: string = defaultGuid;
  userName: string = '';
  tenantId: string = defaultGuid;
  isSaveBtnDisabled: boolean = false;
  requisitionItemsValidator: boolean = false;
  requisitionItems: any = [];
  filteredShippingMethodDetails: any;
  filteredLocationDetails: any;
  isRequisitionLoading: any;
  orderTableOptions = {
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '300px',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [[0, 'asc']], // Sort based on the first column (index 0)
    columnDefs: [
      { targets: [0], visible: false }, // Hide the first column (index 0)
    ],
  };
  reqDatas: any;
  subscription$: Subscription[] = [];
  editableRowIndex: number | null = null;
  defaultRequisitionId: number;
  requisitionStatus: string;
  constructor(
    private formBuilder: FormBuilder,
    private shippingMethodService: ShippingTypesService,
    private branchService: OrganizationUnitService,
    private vendorService: VendorDetailsService,
    private productDetailsService: ProductDetailsService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private requisitionService: RequisitionService,
    private TableService: TableService,
    private loaderService: LoaderService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: { reqData: any }
  ) {
    //To search the products inisde the products dropdown
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterProducts();
      });

    //Loader Section
    const loader = this.loaderService
      .getLoaderState()
      .subscribe((isRequisitionLoading) => {
        this.isRequisitionLoading = isRequisitionLoading;
      });
    this.subscription$.push(loader);
  }

  ngOnInit(): void {
    this.reqDatas = this.data?.reqData;
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.userId = localStorage.getItem('userId') ?? '';
    this.initializeForms();
    this.loadUserDetails();
    this.loadDropdowns();
    // this.getInventoryLimitDetails();
    this.validateItemsList();

    const tableservice=this.TableService.getRequisitionForm().subscribe((res) => {
      this.reqId = res[0];

      if (this.reqId) {
        this.requisitionGetByID();
      }
    });
    this.subscription$.push(tableservice);
  }

  //To Load the user details patch it to the requisition form
  loadUserDetails() {
    //To patch the default fields
    this.TableService.getLoginUserName().subscribe((val) => {
      this.userName = val;
      this.requisitionDate = moment(new Date()).format('MM/DD/YYYY');
      this.requisitionForm.patchValue({
        txtRequisitionDate: this.requisitionDate,
        txtRequestBy: this.userName,
      });
    });
  }
  initializeForms() {
    this.requisitionForm = this.formBuilder.group({
      txtId: new FormControl(''),
      txtRequisitionDate: new FormControl(''),
      txtRequestBy: new FormControl(''),
      drpShippingMethod: new FormControl('', [Validators.required]),
      drpLocation: new FormControl('', [Validators.required]),
      txtInventoryLimit: new FormControl(''),
      txtOnHandTotal: new FormControl(''),
      txtOnOrder: new FormControl(''),
      txtRequisitionTotal: new FormControl(''),
      txtSpecialInstructions: new FormControl(''),
      txtNoofDays: new FormControl(''),
      txtManufacturer: new FormControl(''),
      txtManufacturerFilter: new FormControl(''),
      txtProductFilter: new FormControl(''),
      txtShippingMethodFilter: new FormControl(''),
      txtLocationFilter: new FormControl(''),
    });
  }
  //To Validate the Items List
  validateItemsList() {
    this.requisitionItemsValidator =
      this.requisitionItems.filter((a) => a.productId != '').length > 1
        ? true
        : false;
  }

  get isSubmitButtonEnabled(): boolean {
    // Check both form validity and items list condition
    return (
      this.requisitionForm.valid &&
      this.requisitionItems.filter((a) => a.productId != '').length > 0 &&
      this.requisitionItems
        .filter((a) => a.productId != '')
        .every(
          (item) =>
            item.thisOrder.toString() !== '0' &&
            item.thisOrder !== null &&
            item.thisOrder.toString() !== ''
        )
    );
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  //Method To Search the Products in Dropdown
  filterProducts(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredProductDetails = this.ltproducts.filter((option) =>
      option.productName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  //After View In it function
  ngAfterViewInit(): void {
    this.requisitionItems.push(this.addEmptyObject());
    this.dtTrigger.next();
    //this.reloadDatatable();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //Re Initialize the Datatable
  reloadDatatable() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns.adjust().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  //To Load the Dropdowns
  loadDropdowns() {
    //Load Shipping Methods
    const shippingMethodDetails = this.shippingMethodService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltShippingMethods = response?.items;
          this.filteredShippingMethodDetails = this.requisitionForm
            .get('txtShippingMethodFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltShippingMethods?.filter((option) =>
                  option?.description
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(shippingMethodDetails);

    //Branch List
    const getBranchList = this.branchService
      .getBranchListV1(this.tenantId, false)
      .subscribe((response) => {
        this.lstBranch = response;
        this.lstBranch = response.sort((a, b) =>
          a.organizationUnitName.localeCompare(b.organizationUnitName)
        );
        this.filteredLocationDetails = this.requisitionForm
          .get('txtLocationFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstBranch?.filter((option) =>
                option?.organizationUnitName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(getBranchList);
    //Manufacturer List
    const vendorDetails = this.vendorService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltVendors = response?.items;
          this.ltVendors = response.items.sort((a, b) =>
            a.vendorName.localeCompare(b.vendorName)
          );

          this.filteredVendorDetails = this.requisitionForm
            .get('txtManufacturerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltVendors?.filter((option) =>
                  option?.vendorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(vendorDetails);

    const productsList = this.productDetailsService
      .getProductListforStock()
      .subscribe(
        (response) => {
          this.ltproducts = response;
          this.filteredProductDetails = [...this.ltproducts];
        },
        (err) => {}
      );
    this.subscription$.push(productsList);
  }

  requisitionGetByID() {
    this.loaderService.showLoader();
    const req = this.requisitionService
      .getRequisitionListByID(this.reqId)
      .subscribe(
        (response) => {
          this.requisitionForm.patchValue({
            txtRequisitionDate: response?.requisitionDate,
            txtRequestBy: this.userName,
            drpShippingMethod: response?.shippingMethodId,
            drpLocation: response.mmRefLocId, //response?.destinationLocationId,
            txtInventoryLimit: response?.inventoryLimit,
            txtOnHandTotal: response?.onHandTotal,
            txtOnOrder: response?.onOrder,
            txtRequisitionTotal: response?.requisitionTotal,
            txtSpecialInstructions: response?.specialInstruction,
            txtNoofDays: response?.noOfDays,
          });
          this.defaultRequisitionId = response?.defaultRequisitionId;
          this.requisitionStatus = response?.requisitionStatus;

          this.requisitionItems = response?.itemList;
          this.itemCount = response?.itemList?.length;
          this.requisitionItems.push(this.addEmptyObject());
          this.reloadDatatable();
          this.calculateReuistionTotal();
          this.loaderService.hideLoader();
        },
        (err) => {
          this.loaderService.hideLoader();
        }
      );
    this.subscription$.push(req);
  }

  // get filteredOptions(): any[] {
  //   return this.ltproducts.filter((option) =>
  //     option.productName.toLowerCase().includes(this.searchCtrl.toLowerCase())
  //   );
  // }

  //To Load the Product Details based on the dropdown selection
  loadProductDetails(data: any, productId: string, thisordervalue: string) {
    const location = this.requisitionForm.value.drpLocation ?? defaultGuid;
    const noOfDays = 4;

    if (
      !(
        this.requisitionItems.filter((a) => a.productId === productId).length >
        1
      )
    ) {
      this.isDropDown = true;
      //Get Product Details Based on the Product Id
      this.requisitionService
        .getProductDetailsV1ByIProductIdAndOrgIdAndNoOfDays(
          productId,
          location,
          noOfDays
        )
        .subscribe(
          (response) => {
            let value = response?.[0];
            if (value != null && value != undefined) {
              data.availQty = value?.availQty;
              data.category = value?.category;
              data.dailyAvgDesp = value?.dailyAvgDesp;
              data.description = value?.description;
              data.extendedCost = value?.extendedCost;
              data.manufactureId = value?.manufactureId;
              data.manufacturer = value?.manufacturer;
              data.pendPatOrder = value?.pendPatOrder;
              data.productCategoryId = value?.productCategoryId;
              data.productCode = value?.productCode;
              data.productId = value?.productId;
              data.qtyOnHand = value?.qtyOnHand;
              data.quantity = value?.quantity;
              data.recOrderQty = value?.recOrderQty;
              data.status = value?.status;
              data.thisOrder =
                data.thisOrder > 1 ? data.thisOrder : value?.thisOrder;

              // data.thisOrder = thisordervalue;
              data.unitCost = value?.unitCost;

              this.requisitionItems.filter((a) => a.productId === '').length ===
                0 && this.requisitionItems.push(this.addEmptyObject());
              this.reloadDatatable();
              this.updateQuantity(data, data.thisOrder);
              // this.requisitionViewerFormUpdate();
            }
          },
          (err) => {}
        );
    } else {
      data.productId = '';
      this.toastr.warning('Product Already Added!');
    }
  }
  //Update Product Quantity Method
  updateQuantity(data: any, thisOrderValue: any) {
    data.thisOrder = thisOrderValue;

    data.totalCost = (data.thisOrder * data.unitCost).toFixed(2);

    this.calculateReuistionTotal();
  }

  //To remove the product from the requisition items list
  removeProduct(productId: string) {
    if (productId != '') {
      this.requisitionItems = this.requisitionItems.filter(
        (a) => a.productId != productId
      );
      // this.calculateRequisitionTotal();
      this.reloadDatatable();
      this.toastr.success('Product Removed');
    } else {
      this.toastr.error('Cannot Remove Empty Row');
    }
  }
  //Get Inventory Details
  getInventoryLimitDetails() {
    this.requisitionService.getInventoryLimit().subscribe((response) => {
      this.requisitionForm.patchValue({
        txtInventoryLimit: response?.inventoryLimit,
        txtOnHandTotal: response?.onHandTotal,
        txtOnOrder: response?.onOrder,
      });
    });
  }
  onselectionChange(event) {
    const mmRefLocId = this.lstBranch.filter((x) => x.id == event.value);

    this.requisitionService
      .getInventoryLimitV1(mmRefLocId[0].mmRefId)
      .subscribe((response) => {
        this.calculateReuistionTotal();
        this.requisitionForm.patchValue({
          txtInventoryLimit: response?.inventoryLimit,
          txtOnHandTotal: response?.onHandTotal,
          txtOnOrder: response?.onOrder,
        });
      });
  }

  calculateReuistionTotal() {
    this.requisitionItems.forEach((element, index) => {
      let sum = 0;
      if (element.productId != '') {
        sum = element.thisOrder * element.unitCost;
        this.requisitionItems[index].totalCost = sum.toFixed(2);

        const totalCost: number = this.requisitionItems
          .filter((a) => a.productId != '')
          .reduce((acc, item) => acc + item.thisOrder * item.unitCost, 0);
        this.requisitionTotal = totalCost?.toFixed(2)?.toString();
        this.requisitionForm.patchValue({
          txtRequisitionTotal: totalCost?.toFixed(2)?.toString(),
        });
      } else {
        this.requisitionItems[index].totalCost = 0;
      }
    });
  }
  //To Calculate the requistion total and patch in the field
  // calculateRequisitionTotal() {

  //   const totalCost: number = this.requisitionItems
  //     .filter((a) => a.productId != '')
  //     .reduce((acc, item) => acc + item.thisOrder * item.unitCost, 0);
  //   this.requisitionTotal = totalCost.toString();
  //   this.requisitionForm.patchValue({
  //     txtRequisitionTotal: this.requisitionTotal,
  //   });

  // }

  //Method to add the Empty Row to the Table
  addEmptyObject(): RequisitionProductDTO {
    this.itemCount++;
    let value: RequisitionProductDTO = {
      sortNumber: this.itemCount,
      availQty: 0,
      category: '',
      dailyAvgDesp: 0,
      description: '',
      extendedCost: 0,
      manufactureId: '',
      manufacturer: '',
      pendPatOrder: 0,
      productCategoryId: '',
      productCode: '',
      productId: '',
      qtyOnHand: 0,
      quantity: 0,
      recOrderQty: 0,
      status: '',
      thisOrder: 1,
      unitCost: 0,
    };
    return value;
  }
  //Method to submit the Requisition Form
  submitRequisition() {
    //this.requisitionItems.pop();
    // this.requisitionItems.filter(
    //   (a) => a.productId !== ''
    // );

    this.isSaveBtnDisabled = true;
    let RequisitionData: CreateUpdateRequisitionDTO = {
      requisitionId: this.reqId,
      defaultRequisitionId: this.defaultRequisitionId ?? 0,
      requisitionDate: this.requisitionDate,
      requestedUserId: this.userId,
      shippingMethodId: this.requisitionForm?.value?.drpShippingMethod,
      // destinationLocationId: this.requisitionForm?.value?.drpLocation,
      mmRefLocId: this.requisitionForm?.value?.drpLocation,
      requisitionTotal: Number(this.requisitionTotal),
      itemList: this.requisitionItems.filter((a) => a.productId !== ''),
      noOfDays: null,
      specialInstruction: this.requisitionForm?.value?.txtSpecialInstructions,
      inventoryLimit: this.requisitionForm?.value?.txtInventoryLimit,
      onHandTotal: this.requisitionForm?.value?.txtOnHandTotal,
      onOrder: this.requisitionForm?.value?.txtOnOrder,
      requisitionStatusDetails: null,
      requisitionStatus: this.requisitionStatus
        ? this.requisitionStatus
        : defaultGuid,
    };

    this.requisitionService.update(this.reqId, RequisitionData).subscribe(
      (response) => {
        this.toastr.success('Requisition Updated  successfully!');
        this.isSaveBtnDisabled = false;
        this.dialog.closeAll();
        this.communicationService.triggerLoadRequisitionList();
        // this.resetForm();
      },
      (err) => {
        this.isSaveBtnDisabled = false;
        Swal.fire({
          icon: 'info',
          text: 'Something Went Wrong!',
        });
      }
    );
  }

  resetForm() {
    this.requisitionForm.reset();
    this.getInventoryLimitDetails();
    this.loadUserDetails();
    this.requisitionItems = [];

    this.reloadDatatable();
    this.requisitionItems.push(this.addEmptyObject());
  }
  numberOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (
        value === null ||
        value === undefined ||
        Validators.required(control) !== null
      ) {
        // If the field is required or empty, don't perform number-only validation
        return null;
      }

      // Regular expression for numbers only
      const numberRegExp = /^[0-9]+$/;

      if (!numberRegExp.test(value.toString())) {
        return { numberOnly: true };
      }

      return null;
    };
  }
}
