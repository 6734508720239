<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h3 class="font-weight-bolder mb-0">
          Existing Patient Payment : Claim History
        </h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <!-- [routerLink]='("/AddNewPosting/")+ (claimId)' -->

            <button
              matTooltip="Click To Go back to Posting List"
              [routerLink]="'/billing/claimStatusList'"
              [queryParams]="{ id: strClaimStatus }"
              mat-button
              class="buttonColor font-size-10 mr-2"
            >
              <i class="fa fa-backward mr-1"></i> Back To Claim Status List
            </button>
            <button
              matTooltip="Click To Add Posting"
              (click)="addPosting()"
              mat-button
              class="buttonColor font-size-10"
            >
              <i class="fa fa-plus mr-1"></i> Add Posting
            </button>

            <button
              mat-button
              [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

              class="ml-2 buttonColor"
              (click)="exportTableData()"
            >
              Export
            </button>
            <li class="breadcrumb-item">Existing Patient</li>
            <li class="breadcrumb-item">Claim History</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-body pb-3 mb-2">
        <div class="row">
          <div class="col-6">
            <span
              ><b class="colored-heading">Claim ID : </b><b>{{ lblclaimId }}</b>
            </span>
          </div>
          <div class="col-6">
            <span
              ><b class="colored-heading"> Sale Order ID </b> :
              <a
                matTooltip="Click to View Sale Order"
                class="lightSeaGreen-dot eyeCursorclass"
                [routerLink]="'/patient/saleorderCreateEdit/' + orderId"
                target="_blank"
              >
                <b>{{ lblSaleOrderId }}</b>
              </a>
            </span>
          </div>
          <div class="col-6">
            <span
              ><b class="colored-heading"> Patient ID </b> :
              <a
                matTooltip="Click to View Patient"
                class="lightSeaGreen-dot eyeCursorclass"
                [routerLink]="'/patientViewEdit/' + patientId"
                target="_blank"
              >
                <b>{{ lblPatientId }}</b>
              </a>
            </span>
          </div>
          <div class="col-6">
            <span
              ><b class="colored-heading"> Patient Name </b> :
              <b>{{ lblPatientName }}</b>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <h4 class="customThemeClass text-left"><b>Posting List</b></h4>
    </div>

    <div class="col-lg-12 table-responsive">
      <form [formGroup]="checkForm">
        <div class="table table-responsive mt-0">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="status">
              <th class="header" mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <ng-container
                  *ngIf="element?.postingStatus === 'Posting Completed'"
                >
                  <mat-slide-toggle
                    matTooltip="Click To Change Payment Status"
                    (change)="paymentStatusChange($event, element, element?.id)"
                    [checked]="true"
                    class="ml-1"
                  >
                  </mat-slide-toggle>
                  {{ element?.postingStatus }}
                </ng-container>
                <ng-container
                  *ngIf="element?.postingStatus === 'Posting Pending'"
                >
                  <mat-slide-toggle
                    matTooltip="Click To Change Payment Status"
                    (change)="paymentStatusChange($event, element, element?.id)"
                    [checked]="false"
                    class="ml-1"
                  >
                  </mat-slide-toggle
                  >{{ element?.postingStatus }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-createdDate" matInput formControlName="createdDate" />
                  <mat-placeholder class="center">
                    Created Date
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.createdDate | date: "MM/dd/yyyy":"en_US" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalBilled">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-totalBilled" matInput formControlName="totalBilled" />
                  <mat-placeholder class="center">
                    Total Billed
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ "$ " + element?.totalBilled || "$ " + 0 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalPatientBal">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-totalPatientBal" matInput formControlName="totalPatientBal" />
                  <mat-placeholder class="center">
                    Total Balance
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ "$ " + element?.totalCurrentBalance || "$ " + 0 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="saleorderId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-saleorderId" matInput formControlName="saleorderId" />
                  <mat-placeholder class="center">SaleOrder Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.saleorderId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="checkNo">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientId" matInput formControlName="patientId" />
                  <mat-placeholder class="center"> Check No </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.checkNo }}</td>
            </ng-container>
            <ng-container matColumnDef="checkDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientName" matInput formControlName="patientName" />
                  <mat-placeholder class="center"> Check Date </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.checkDate | date: "MM/dd/yyyy":"en_US" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="plan">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-defaultClaimId" matInput formControlName="defaultClaimId" />
                  <mat-placeholder class="center"> Plan</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.plan }}</td>
            </ng-container>
            <ng-container matColumnDef="Options">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
              <td mat-cell *matCellDef="let datas">
                <a
                  class="eyeCursorclass customThemeClass"
                  matTooltip="Click To View Payment"
                  [routerLink]="'/AddNewPosting/' + claimId + '/' + datas?.id"
                >
                  <i class="fa fa-pencil"></i
                ></a>
                <a
                  class="eyeCursorclass customThemeClass"
                  matTooltip="Click To View EOB"
                  [routerLink]="
                    '/ViewDocumentPerviewByBlobId/' +
                    datas?.eobBlobName +
                    '/' +
                    4
                  "
                  target="_blank"
                >
                  <i class="fa fa-eye ml-2"></i
                ></a>
                <a
                  class="eyeCursorclass customThemeClass"
                  matTooltip="Click To Edit Posting"
                  (click)="addPosting(datas?.id)"
                  target="_blank"
                >
                  <i class="fa fa-pencil-square-o ml-2"></i
                ></a>
                <!-- <a class="eyeCursorclass customThemeClass" matTooltip="Click To Add Reminder"
                  (click)="addReminder(datas)" target="_blank"> <i class="fa fa-bell ml-2"></i></a> -->
                <a
                  class="deleteBtnColor"
                  (click)="deleteInsurancePayments(datas?.id)"
                >
                  <i
                    class="fa fa-trash pointer ml-2"
                    matTooltip="Click To Delete"
                  ></i>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td
                class="text-align-center"
                mat-footer-cell
                *matFooterCellDef
                colspan="15"
              >
                <div *ngIf="!isLoading">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="15">
                <mat-paginator
                  class="table table-responsive mat-table-class"
                  #MatPaginator
                  [length]="dataSource?.data?.length"
                  [pageSize]="10"
                  [pageIndex]="0"
                  showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]"
                >
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: arrDisplayedColumns"
              [ngClass]="{
                Primary: row.InsuranceType === 'Primary',
                Secondary: row.InsuranceType === 'Secondary',
                Tertiary: row.InsuranceType === 'Tertiary',
                'make-gold': row.claimStatus === 'Primary Denied'
              }"
            ></tr>
            <tr
              mat-footer-row
              *matFooterRowDef="['noRecords']"
              [ngClass]="{
                active:
                  dataSource &&
                  dataSource.data &&
                  dataSource?.data?.length !== 0
              }"
            ></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </form>
    </div>
    <app-billing-reminder-message></app-billing-reminder-message>
  </div>
</div>
