<mat-dialog-content class="mat-typography">
  <div class="modal-header">
      <h1  class="modal-title head" >  Insurance Recoup - </h1>&nbsp;
      <h2 class="head" >{{data.cliamData.defaultClaimId}}</h2> &nbsp;&nbsp;&nbsp;&nbsp;
      <h2 class=" text-right"><b class="font-weight-bolder blinking">{{warningMsg}}</b></h2>

      <button class="text-right close" aria-label="Close" mat-dialog-close>
        <b> X </b>
      </button>
  </div>
  <form [formGroup]="InsuranceRecoopForm">
    <div class="modal-body">
      <mat-card-content>
        <div class="row">
     <mat-form-field class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 disabled-link">
         <mat-label>Billed Amount<span class="asterisk">*</span></mat-label>
         <input autocomplete="new-billedAmount" type="text"
             (input)="sanitizeInput($event, 'txtbilledAmount')" maxlength="8"
             formControlName="txtbilledAmount" matInput  [readonly]="true"  class="disable-pointer"/>
         <mat-error
             *ngIf="InsuranceRecoopForm?.get('txtbilledAmount').invalid && (InsuranceRecoopForm?.get('txtbilledAmount').dirty || InsuranceRecoopForm?.get('txtbilledAmount').touched)">
             Enter 0-99999.99/Decimal min 1/max 2 digits.
         </mat-error>
     </mat-form-field>
     <mat-form-field class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 disabled-link" >
         <mat-label>Insurance Amount ($)</mat-label>
         <input autocomplete="new-txtInsuranceAmount" type="text" (input)="sanitizeInput($event, 'txtInsuranceAmount')"
             maxlength="8" formControlName="txtInsuranceAmount" matInput [readonly]="true"  class="disable-pointer" />
         <mat-error
             *ngIf="InsuranceRecoopForm?.get('txtInsuranceAmount').invalid && (InsuranceRecoopForm?.get('txtInsuranceAmount').dirty || InsuranceRecoopForm?.get('txtInsuranceAmount').touched)">
             Enter 0-99999.99/Decimal min 1/max 2 digits.
         </mat-error>
     </mat-form-field>

     <mat-form-field class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
         <!-- <mat-label>Item/HCPC Code<span class="asterisk">*</span></mat-label> -->
         <mat-label>
             <div class="select-placeholder-container" *ngIf="isLoadingHCPC">
                 <span>Item/HCPC Code<span class="asterisk">*</span></span>
                 <mat-spinner class="spinner" diameter="20"></mat-spinner>
             </div>
             <span *ngIf="!isLoadingHCPC">Item/HCPC Code <span
                     class="asterisk">*</span></span>
         </mat-label>
         <mat-select formControlName="sltItemId_HCPCCode" #singleSelect (selectionChange)="PatchReCopeAmount()">
             <mat-option>
                 <ngx-mat-select-search placeholderLabel="Search"
                     noEntriesFoundLabel="No Matches found"
                     formControlName="sltItemId_HCPCCodeFilter"></ngx-mat-select-search>
             </mat-option>
             <mat-option *ngFor="let itemId_HCPCCode of filteredtxtItemId_HCPCCode | async"
                 [value]="itemId_HCPCCode">
                 {{ itemId_HCPCCode.hcpcCode }}
             </mat-option>
         </mat-select>
         <!-- <button mat-button matSuffix (click)="clearItemId_HCPCCodeList($event)" mat-icon-button>
             <mat-icon>close</mat-icon>
         </button> -->
     </mat-form-field>
     <mat-form-field class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"
     [ngClass]="{'disabled-link': isOnChange}">
         <mat-label>Recoup Amount ($)<span class="asterisk">*</span></mat-label>
         <input [ngClass]="isOnChange ? 'disable-pointer' : ''" [readonly]="isOnChange" autocomplete="new-txtRecoopAmount" type="text" (input)="sanitizeInput($event, 'txtRecoopAmount')"
             maxlength="8" formControlName="txtRecoopAmount" matInput />
         <mat-error
             *ngIf="InsuranceRecoopForm?.get('txtRecoopAmount').invalid && (InsuranceRecoopForm?.get('txtRecoopAmount').dirty || InsuranceRecoopForm?.get('txtRecoopAmount').touched)">
             Enter 0-99999.99/Decimal min 1/max 2 digits.
         </mat-error>
     </mat-form-field>
        </div>
      </mat-card-content>
    </div>
    <br><br>
    <div class="modal-footer">
      <div class="col-lg-12  d-flex justify-content-center btncls">
      <button matTooltip="Click To Add New Patient" mat-button
      class="buttonColor font-size-10 mr-2" (click)="saveInsuranceRecoop()" [disabled]="InsuranceRecoopForm.invalid || isSaveInsuranceRecoop">
      Save
      </button>
      <button matTooltip="Click To Add New Patient" mat-button
      class="resetclr font-size-10 mr-2" [disabled]="isSaveInsuranceRecoop" (click)="resetInsuranceRecoop()">
      Reset
      </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
