import type {
  CreateUpdateSourceMDPatientsDTO,
  FilterPatientsDTO,
  SourceMDChartReportsDTO,
  SourceMDCustomReportsDTO,
  SourceMDPatientDashboardDTO,
  SourceMDPatientsDTO,
  SourceMDPatientsFilterResultDTO,
  SummaryDataReportsDTO,
  SummarySourceMDPatientDTO,
  TrendSourceMDChartReportsDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { QuarterYear, ReportType } from '../patient/enumeration-data';
import { SummaryMailDTO } from '../patient/dto/models';

@Injectable({
  providedIn: 'root',
})
export class SourceMDPatientService {
  apiName = 'Default';

  create = (input: CreateUpdateSourceMDPatientsDTO) =>
    this.restService.request<any, SourceMDPatientsDTO>(
      {
        method: 'POST',
        url: '/api/app/source-mDPatient',
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/source-mDPatient/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  get = (id: string) =>
    this.restService.request<any, SourceMDPatientsDTO>(
      {
        method: 'GET',
        url: `/api/app/source-mDPatient/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<SourceMDPatientsDTO>>(
      {
        method: 'GET',
        url: '/api/app/source-mDPatient',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  update = (id: string, input: CreateUpdateSourceMDPatientsDTO) =>
    this.restService.request<any, SourceMDPatientsDTO>(
      {
        method: 'PUT',
        url: `/api/app/source-mDPatient/${id}`,
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getFilteredPatientsReportsByPatientReportRowNameAndPatientReportColumnName = (
    patientReportRowName: string,
    patientReportColumnName: string
  ) =>
    this.restService.request<any, SourceMDPatientsFilterResultDTO>(
      {
        method: 'GET',
        url: '/api/app/source-mDPatient/get-filtered-patients-reports',
        params: { patientReportRowName, patientReportColumnName },
      },
      { apiName: this.apiName }
    );
  importSourceMDPatientBySFileContentAsBase64String = (
    sFileContentAsBase64String: string
  ) =>
    this.restService.request<any, SourceMDPatientsDTO>(
      {
        method: 'POST',
        url: '/api/app/source-mDPatient/import-source-mDPatient',
        body: sFileContentAsBase64String,
        headers: this.getHeaders('application/json'),
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  public getHeaders(headerString: string): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': headerString,
    });
    return headers;
  }

  filterPatientsByOFilterPatients = (oFilterPatients: FilterPatientsDTO) =>
    this.restService.request<any, SourceMDPatientsDTO[]>(
      {
        method: 'POST',
        url: '/api/app/source-mDPatient/filter-patients',
        body: oFilterPatients,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  exportAllSourceMDPatients = () =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        // responseType: 'text',
        url: '/api/app/source-mDPatient/export-all-source-mDPatients',
      },
      { apiName: this.apiName }
    );

  exportSourceMDPatientByOFilterPatientsDTO = (
    oFilterPatientsDTO: FilterPatientsDTO
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        // responseType: 'text',
        url: '/api/app/source-mDPatient/export-source-mDPatient',
        body: oFilterPatientsDTO,
      },
      { apiName: this.apiName }
    );

  downloadsourcemDPatienttemplate = () =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        url: '/api/app/source-mDPatient/download-source-mDPatient-template',
        headers: this.getHeaders('text/plain'),
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getSummarySourceMDPatientReport = () =>
    this.restService.request<any, SummarySourceMDPatientDTO>(
      {
        method: 'GET',
        url: '/api/app/source-mDPatient/get-summary-source-mDPatient-report',
      },
      { apiName: this.apiName }
    );
  getSummaryDataByInput = (input: ReportType) =>
    this.restService.request<any, SummaryDataReportsDTO>({
      method: 'GET',
      url: '/api/app/source-mDPatient/get-summary-data',
      params: { input },
    },
      { apiName: this.apiName });

  getSourceMDChartDataByInput = (input: ReportType) =>
    this.restService.request<any, SourceMDChartReportsDTO>(
      {
        method: 'GET',
        url: '/api/app/source-mDPatient/get-source-mDChart-data',
        params: { input },
      },
      { apiName: this.apiName }
    );

  getTrendChartReportByInput = (input: ReportType) =>
    this.restService.request<any, TrendSourceMDChartReportsDTO>(
      {
        method: 'GET',
        url: '/api/app/source-mDPatient/get-trend-chart-report',
        params: { input },
      },
      { apiName: this.apiName }
    );

  getSourceMDPatientDashboardCount = () =>
    this.restService.request<any, SourceMDPatientDashboardDTO>(
      {
        method: 'GET',
        url: '/api/app/source-mDPatient/get-source-mDPatient-dashboard-count',
      },
      { apiName: this.apiName }
    );

    sendSummaryReportToMailByLstSummaryMail = (lstSummaryMail: SummaryMailDTO[]) =>
    this.restService.request<any, boolean[]>({
      method: 'POST',
      url: '/api/app/source-mDPatient/send-summary-report-to-mail',
      body: lstSummaryMail,
    },
    { apiName: this.apiName, skipHandleError:true });

    getCustomReportByYearAndQuarterYear = (year: number, quarterYear: QuarterYear) =>
    this.restService.request<any, SourceMDCustomReportsDTO>({
      method: 'GET',
      url: '/api/app/source-mDPatient/get-custom-report',
      params: { year, quarterYear },
    },
    { apiName: this.apiName });
  importSourceMDPatient = (sFileContentAsBase64String: string) =>
    this.restService.request<any, SourceMDPatientsDTO>(
      {
        method: 'POST',
        url: '/api/app/source-mDPatient/import-source-mDPatient',
        body: sFileContentAsBase64String,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) { }
}
