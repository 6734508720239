<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2> {{ reminderId==""?"Add Reminders":"Edit Reminder"}}</h2>
    </div>
    <!-- <div class="col-lg-6 text-right">
        <button class="text-right close" aria-label="Close" mat-dialog-close><b> X </b></button>
      </div> -->
  </div>
  <hr />
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <form [formGroup]="reminderForm">
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label>Scheduled Date <span class="asterisk">*</span> </mat-label>
              <input autocomplete="new-txtDate" maxlength="40" formControlName="txtDate" matInput [matDatepicker]="txtDate" [min]="minDate"
                placeholder="MM/DD/YYYY" [formControl]="reminderForm.controls.txtDate" />
              <mat-datepicker-toggle matSuffix [for]="txtDate"></mat-datepicker-toggle>
              <mat-datepicker #txtDate></mat-datepicker>
              <mat-error *ngIf="reminderForm?.get('txtDate').touched || reminderForm?.get('txtDate')?.errors?.required &&
                (reminderForm?.get('txtDate').dirty)">
                Scheduled Date is a required field!
              </mat-error>
              <mat-error
                *ngIf="   reminderForm?.get('txtDate')?.touched && reminderForm?.get('txtDate')?.errors?.matDatepickerParse">
                Enter Valid Date!
              </mat-error>

              <mat-error
                *ngIf=" minDate>=reminderForm?.get('txtDate')?.value && !(reminderForm?.get('txtDate')?.touched && reminderForm?.get('txtDate')?.errors)">
                Enter Future Dates Only!
              </mat-error>


            </mat-form-field>
            <ng-select class="col-4" formControlName="drpAssignedTo" placeholder="Assigned To " maxlength="40">
              <ng-option *ngFor="let user of arrUsersList" [value]="user.id">
                {{ user.userName }}</ng-option>
            </ng-select>
            <div class="col-4 chk custom-control custom-checkbox mb-3">
              <br>
              <mat-checkbox formControlName="chkAllUsers">All Users</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Patient</mat-label>
              <input autocomplete="new-txtPatient" matInput formControlName="txtPatient" type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Completed By</mat-label>
              <input autocomplete="new-txtCompletedBy" matInput formControlName="txtCompletedBy" type="text">
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Completed On</mat-label>
              <input autocomplete="new-dtCompletedOn" maxlength="40" formControlName="dtCompletedOn" matInput [matDatepicker]="dtCompletedOn"
                placeholder="MM/DD/YYYY" [formControl]="reminderForm.controls.dtCompletedOn"
                [min]="reminderForm.controls.txtDate.value" />
              <mat-datepicker-toggle matSuffix [for]="dtCompletedOn"></mat-datepicker-toggle>
              <mat-datepicker #dtCompletedOn></mat-datepicker>
              <mat-error *ngIf="
                reminderForm?.get('dtCompletedOn').touched || reminderForm?.get('dtCompletedOn')?.errors?.required &&
                (reminderForm?.get('dtCompletedOn').dirty)">
                Completed On is a required field!
              </mat-error>
              <mat-error
                *ngIf="   reminderForm?.get('dtCompletedOn')?.touched && reminderForm?.get('dtCompletedOn')?.errors?.matDatepickerParse">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Entered Date</mat-label>
              <input autocomplete="new-dtEnteredDateTime" maxlength="40" formControlName="dtEnteredDateTime" matInput [matDatepicker]="dtEnteredDateTime"
                placeholder="MM/DD/YYYY" />
              <mat-datepicker-toggle matSuffix [for]="dtEnteredDateTime"></mat-datepicker-toggle>
              <mat-datepicker #dtEnteredDateTime></mat-datepicker>
              <mat-error *ngIf="
                reminderForm?.get('dtEnteredDateTime').touched || reminderForm?.get('dtEnteredDateTime')?.errors?.required &&
                (reminderForm?.get('dtEnteredDateTime').dirty)">
                Entered Date is a required field!
              </mat-error>
              <mat-error
                *ngIf="   reminderForm?.get('dtEnteredDateTime')?.touched && reminderForm?.get('dtEnteredDateTime')?.errors?.matDatepickerParse">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>



            <mat-form-field class="col-12">
              <mat-label>Subject <span class="asterisk">*</span></mat-label>
              <textarea matInput rows="9" maxlength="400" formControlName="txtSubject"></textarea>
              <mat-error *ngIf="
                    reminderForm?.get('txtSubject')?.touched && reminderForm?.get('txtSubject')?.errors?.required
                  ">
                Subject is a required field!
              </mat-error>
              <!-- <mat-error *ngIf="
                reminderForm?.get('txtSubject')?.touched &&
                reminderForm?.get('txtSubject')?.errors?.pattern
              ">
                Enter Only Alphanumeric !
              </mat-error> -->
            </mat-form-field>

            <div class="col-12" *ngIf='isShown'>
              <br>
              <mat-checkbox formControlName="chkDontShowAgain">Dont Remind Again</mat-checkbox>&nbsp;&nbsp;
              <mat-checkbox formControlName="chkCompleted">Completed</mat-checkbox>

              <!-- <mat-radio-button class="mb-2 mr-2" value="Done">Dont Remind Again</mat-radio-button> &nbsp;&nbsp;
                  <mat-radio-button class="mb-2 mr-2" value="Pending">Completed</mat-radio-button> &nbsp;&nbsp; -->
            </div>
            <!-- <div class="col-12" *ngIf = 'isShown'>
                <br>
                <mat-checkbox formControlName="chkDontShowAgain">Dont Remind Again</mat-checkbox>&nbsp;&nbsp;
                <mat-checkbox formControlName="chkCompleted">Completed</mat-checkbox>
              </div> -->

          </div>
        </form>
      </mat-card-content>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button  *ngIf="orderStatus"  mat-button (click)='saveReminders()'
    [disabled]="saveButtonHide || !(!reminderForm.invalid && (reminderForm.dirty ))" class="buttonColor">Save
    <mat-icon *ngIf='saveButtonHide'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button mat-button (click)='resetForm()' class="resetclr buttonColor">Reset</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
