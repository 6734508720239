<div class="table table-responsive">

    <table id="tblNotesMdl" datatable [dtOptions]="dtNotesOptionsv1" class="tbl hover row-border hover w-100 display">
        <thead>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th [matTooltip]="'Entered By Name'">Entered By Name</th>
                <th [matTooltip]="'Entered'" class="text-center">Entered</th>
                <th [matTooltip]="'Note Type'">Note Type</th>
                <!-- <th [matTooltip]="'Note Text'">Note Text</th> -->
                <th [matTooltip]="'Modified By'">Modified By</th>
                <th [matTooltip]="'Modified'" class="text-center">Modified</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of PatientNotesDetailsV1; let i = index"
                (click)="openDescription(data.description); setSelectedRow(i)"
                [ngClass]="{'selected-row': i === selectedRowIndex}">

                <td>
                    <a class="eyeCursorclass" target="_blank" [ngClass]="{'disabled-link': !data?.iscreateduser}" [matTooltip]="'Update Notes'">
                        <i class="fa fa-pencil editBtnColor mr-1" (click)="UpdateNotes(data.notesId,false,data.patientId)"></i>
                    </a>
                </td>

                <!-- <td [matTooltip]="'Update Notes'">
                    <a class="eyeCursorclass" target="_blank">
                        <i class="fa fa-pencil editBtnColor mr-1" (click)="UpdateNotes(data.notesId,false,data.patientId)"></i>
                    </a>
                </td> -->
                <td [matTooltip]=" data.createdByName || '-'">{{ data.createdByName || '-' }}</td>
                <!-- <td [matTooltip]="data?.creationTime ? (data.creationTime | date : 'MM/dd/yyyy h:mm a' : 'en_US') : '-'" class="text-center">
                    {{ data?.creationTime ? (data.creationTime | date : 'MM/dd/yyyy' : 'en_US') : '-' }}
                </td> -->

                <td class="text-center">
                    <span [matTooltip]="getFormattedDateTimeZone(data?.creationTime)">{{
                      getFormattedDateTimeZone(data?.creationTime) }}</span>
                </td>

                <td [matTooltip]=" data.noteType || '-'">{{ data.noteType || '-' }}</td>
                <!-- <td [matTooltip]=" data.description || '-'">{{ data.description || '-' }}</td> -->
                <td [matTooltip]=" data.modifiedBy || '-'">{{ data.modifiedBy || '-' }}</td>

                <td class="text-center">
                    <span [matTooltip]="getFormattedDateTimeZone(data?.lastModificationTime)">{{
                      getFormattedDateTimeZone(data?.lastModificationTime) }}</span>
                </td>

                <!-- <td [matTooltip]="data?.lastModificationTime ? (data.lastModificationTime | date : 'MM/dd/yyyy h:mm a' : 'en_US') : '-'" class="text-center">
                    {{ data?.lastModificationTime ? (data.lastModificationTime | date : 'MM/dd/yyyy' : 'en_US') : '-' }}
                </td> -->
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Entered By Name"
                        name="search-EnteredByName" />
                </th>
                <th>
                    <input matInput class="textbox-border-class text-center" type="text" placeholder="Entered"
                        name="search-Entered" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Note Type" name="search-NoteType" />
                </th>
                <!-- <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Note Text"
                        name="search-NoteText" />
                </th> -->
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Modified By" name="search-ModifiedBy" />
                </th>
                <th>
                    <input matInput class="textbox-border-class text-center" type="text" placeholder="Modified"
                        name="search-Modified" />
                </th>
            </tr>
        </tfoot>
    </table>
</div>

<mat-dialog-actions *ngIf="PatientNotesDetailsV1 && PatientNotesDetailsV1.length > 0">
    <textarea [disabled]="true" cols="132" rows="6" style="resize: none;"
        class="txtnotescls" id="scrolltxt">{{ vDescription }}</textarea>
</mat-dialog-actions>