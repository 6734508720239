import { tr } from 'date-fns/locale';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { PayerService } from 'projects/patient/src/app/patient-proxy/patient';
import {
  CreateUpdatePayerDetailDTO,
  PayerDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subject, Subscription } from 'rxjs';
import { delay, map, startWith, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import emailMask from 'text-mask-addons/dist/emailMask';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import {
  defaultCountry,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { CreateUpdateInsuranceCategoryDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { AddInsuranceCategoryComponent } from 'projects/shared/src/app/components/add-insurance-category/add-insurance-category.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
@Component({
  selector: 'app-inventory-payor',
  templateUrl: './inventory-payor.component.html',
  styleUrls: ['./inventory-payor.component.scss'],
  providers: [ListService],
})
export class InventoryPayorComponent implements OnInit, OnDestroy {
  lstInsurenceCategory: any;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  selectedPayorId: string = '';
  searchForm: FormGroup;
  isShowSpinner: boolean = false;
  strPageType: string = 'payorPage';
  payorId: string = '';
  isLoading: boolean = true;
  step: boolean = false;
  step1: boolean = false;
  Country: string = '';
  State: string = '';
  City: string = '';

  CountryName = '';
  stateName = '';

  selectedOption: number = 1;
  // sSearch:string='';
  drpModeType: number = 4;
  payorForm: FormGroup;
  payorInsuranceForm: FormGroup;
  payorSearchForm: FormGroup;
  eligibilityMode: any;
  EligibilityPayor: any;
  pVerifyPayor: string = '';
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'Options',
    'payerId',
    'payerName',
    'eligibilityMode',
  ];
  organizationUnitId: string = null;
  tenantId: string = '';
  payerTableData: any;
  saveButtonHide: boolean;
  emailMask: any;
  subscription$: Subscription[] = [];
  dtOptions: any;
  searchLoading: boolean = false;
  // drpStates: any[];
  // drpCities: any[];
  lstPayerId: string[] = [];
  filteredCities: any;
  filteredStates: any;
  eligibilityModeDetails: any;
  InsurenceCategoryList: any;
  saveButtonHideIns: boolean;
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = 50;
  InsCategFilterControl = new FormControl();
  payorDataSubscription: Subscription;
  private destroy$ = new Subject<void>();
  overallTableCount:number = 0;
  paginationInfo:any;
  recordsPerPage:number = 50;
  @ViewChild('submitButton') submitButton: ElementRef;
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  constructor(
    private formBuilder: FormBuilder,
    private table: TableService,
    private payerService: PayerService,
    private regionDropdownService: RegionDropdownService,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private list: ListService,
    public title: Title,
    private reportService: ReportService,
    private payerDetails: PayerDetailService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {
    const editPayorDetails =
      this.communicationService.masterPayorEditMethodCall$.subscribe(
        (value) => {
          this.selectedPayorId = value;
          this.submitButton.nativeElement.click();
          // this.editPayor(value);
        }
      );
    this.subscription$.push(editPayorDetails);
  }
  ngOnInit(): void {
    this.eligibilityModeDetails = [
      { id: 1, value: 'pVerify' },
      { id: 2, value: 'Zermid' },
      { id: 3, value: 'ChangeHealthCare' },
      { id: 4, value: 'Manual' },
      { id: 5, value: 'Office Ally' },
    ];
    this.selectedOption = 1;
    this.initializeForms();
    this.payorInsuranceForm = this.formBuilder.group({
      InsCategoryV1: new FormControl('', [Validators.required]),
      chkIns: new FormControl(''),
    });
    this.emailMask = emailMask;
    this.statusFilter(this.currentPage);
    this.title.setTitle('Qsecure | Payor');
    this.countryDropdown();
    // this.onChangeState(defaultCountry.US);
    this.getInsuranceCategory();

    this.InsCategFilterControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.InsurenceCategoryList = this.lstInsurenceCategory.filter((dx) =>
        dx.insuranceCategory.toLowerCase().includes(FilterText)
      );
    });
  }

  initializeForms() {
    this.payorForm = this.formBuilder.group({
      txtPayorID: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtPayorName: new FormControl('', [Validators.required,noWhitespaceValidator,]),
      selectEligibility: new FormControl('', [Validators.required]),
      InsCategory: new FormControl('', [Validators.required]),
      txtPayorCode: new FormControl(''), //Validators.required,
      txtFaxNumber: new FormControl(''), //, [Validators.required]
      txtEmail: new FormControl(''), //, [Validators.required]
      txtAddress: new FormControl(''), //, [Validators.required]
      txtAddress2: new FormControl(''),
      drpState: new FormControl(''), //, [Validators.required]
      drpCity: new FormControl(''), //, [Validators.required]
      drpCityFilter: new FormControl(''),
      drpStateFilter: new FormControl(''),
      txtPostalCode: new FormControl(''), //, [Validators.required]
      // txtContactName: new FormControl('', [this.textValidators.isTextCheck]),
      txtContactName: new FormControl(''),
      txtPhoneNumber: new FormControl(''),
      txtWrtOff: new FormControl(''),
      txtpmtCode: new FormControl(''),
      txtInsCode: new FormControl(''),
      txtClaimIndicator: new FormControl(''),
      txtCountryFilter:new FormControl(''),
      drpCountry:new FormControl(''),
      isChecklistRequired: false,
      isMedicare: false,
    });
    this.searchForm = this.formBuilder.group({
      sSearch: new FormControl(''),
    });
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  SecToggle() {
    this.step1 = true;
  }
  secToggleV2() {
    this.step1 = false;
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    
    this.destroy$.next();
    this.destroy$.complete();
  }

    // Get Country Dropdown
    countryDropdown() {
      this.payorForm.patchValue({ drpCountry: defaultCountry.US });
      this.onChangeState(defaultCountry.US);
      this.Country = defaultCountry.US;
      this.drpCountry = [];
      this.regionDropdownService
        .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
        .subscribe(
          (response) => {
            // this.drpCountry = response?.items.filter((x) => x.countryShortName == 'US') ?? [];
            this.drpCountry = response?.items ?? [];
            this.filteredCountries = this.payorForm
              ?.get('txtCountryFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCountry.filter((option) =>
                    option?.countryName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }

    
  clearCountry() {
    // this.userForm2.controls['txtPhnNo'].disable();
    this.Country = '';
    this.payorForm.patchValue({ drpCountry: '' });
  }
  openAddInsuranceCategoryModal() {
    const dialogRef = this.dialog.open(AddInsuranceCategoryComponent, {
      disableClose: true,

      data: {},
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        if (claimDetails) {
          const existingItemIndex = this.lstInsurenceCategory.findIndex(
            (item) => item.id === claimDetails.id
          );
          if (existingItemIndex === -1) {
            this.lstInsurenceCategory.push(claimDetails);
            this.lstInsurenceCategory.sort((a, b) =>
              a.insuranceCategory.localeCompare(b.insuranceCategory)
            );
          }
          this.InsCategFilterControl.valueChanges.subscribe((FilterText) => {
            FilterText = FilterText.toLowerCase();
            this.InsurenceCategoryList = this.lstInsurenceCategory.filter(
              (dx) => dx.insuranceCategory.toLowerCase().includes(FilterText)
            );
          });
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  eligibiltyStatus(modeId: number) {
    const selectedMode = this.eligibilityModeDetails.find(
      (mode) => mode.id === modeId
    );
    return selectedMode ? selectedMode.value : null;
  }

  //! filter table
  filtertable(filter) {
    this.isLoading = true;
    let PayorId = filter.payorIdSearch;
    let PayorName = filter.payorNameSearch;

    const searchPayer = this.payerService
      .searchPayerBySPayerAndSPayerName(PayorId, PayorName)
      .subscribe((data) => {
        this.payerTableData = [];
        data?.items?.forEach((element) => {
          this.payerTableData = element;
        });
        this.isLoading = false;
      });
    this.subscription$.push(searchPayer);
  }
  resetPayorInsuranceForm() {
    this.payorInsuranceForm.patchValue({
      InsCategoryV1: '',
      chkIns: '',
    });
    this.payorInsuranceForm.markAsTouched();
    this.payorInsuranceForm.updateValueAndValidity();
  }
  resetPayorForm() {
    this.payorForm.patchValue({
      txtPayorID: '',
      txtPayorName: '',
      selectEligibility: '',
      InsCategory: '',
      txtPayorCode: '',
      txtFaxNumber: '',
      txtEmail: '',
      txtAddress: '',
      txtAddress2: '',
      drpState: '',
      drpCity: '',
      txtPostalCode: '',
      txtContactName: '',
      txtPhoneNumber: '',
      txtWrtOff: '',
      txtpmtCode: '',
      txtInsCode: '',
      txtClaimIndicator: '',
      isChecklistRequired: false,
      isMedicare: false,
    });
    this.payorId = '';
    this.payorForm.reset();
    this.payorForm.markAsUntouched();
    this.payorForm.updateValueAndValidity();
    this.initializeForms();
  }
  savePayor() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.tenantId = localStorage.getItem('tenantId');
    let payerDetails: CreateUpdatePayerDetailDTO = {
      payerName: this.payorForm.value?.txtPayorName?.trim() ?? '',
      country: this.payorForm.value?.drpCountry ?? '',
      insuranceCategory: this.payorForm.value?.InsCategory?.trim() ?? '',
      // insuranceCategory:defaultGuid,
      payerCode: this.payorForm.value?.txtPayorID?.trim() ?? '',
      code: this.payorForm.value?.txtPayorCode?.trim() ?? '',
      payerShortCodeId: '',
      eligibilityMode: this.payorForm.value?.selectEligibility,
      isActive: true,
      isSupportingEligibility: true,
      isSupportingClaims: true,
      isEDIPayer: true,
      address: this.payorForm.value?.txtAddress?.trim() ?? '',
      city: this.payorForm.value?.drpCity?.trim() ?? '',
      state: this.payorForm.value?.drpState ?? '',
      zip: this.payorForm.value?.txtPostalCode ?? '',
      contactName: this.payorForm.value?.txtContactName?.trim() ?? '',
      phoneNo: this.payorForm.value?.txtPhoneNumber ?? '',
      preCertNo: null,
      email: this.payorForm.value?.txtEmail?.toLowerCase() ?? '',
      faxNo: this.payorForm.value?.txtFaxNumber ?? '',
      pmtCode: this.payorForm.value?.txtpmtCode?.trim() ?? '',
      wrtOffCode: this.payorForm.value?.txtWrtOff?.trim() ?? '',
      claimFilingIndicator:
        this.payorForm.value?.txtClaimIndicator?.trim() ?? '',
      insCategoryID: null,
      insuranceTypeCode: this.payorForm.value?.txtInsCode?.trim() ?? '',
      requireCheckList: this.payorForm.value.isChecklistRequired,
      isMedicarePolicy: this.payorForm.value.isMedicare,
    };
    if (
      this.payorId === '' ||
      this.payorId === undefined ||
      this.payorId === null
    ) {
      this.payorId = '';
      this.payerDetails.create(payerDetails).subscribe(
        (response) => {
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.toggleV2();
          this.resetPayorForm();
          this.statusFilter(1);
          this.isShowSpinner = false;
        },
        (err) => {
          this.isShowSpinner = false;
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      return;
    }
else{
  this.payerDetails.update(this.payorId, payerDetails).subscribe(
    (response) => {
      this.toastr.success('Updated Successfully', 'Success');
      this.resetPayorForm();
      this.toggleV2();
      this.statusFilter(1);
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    },
    (err) => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
}

  }
  addRemovePayorId(event: MatSlideToggleChange, id: string) {
    if (event.checked === true) {
      this.lstPayerId.push(id);
    } else {
      const index = this.lstPayerId.indexOf(id);
      if (index !== -1) {
        this.lstPayerId.splice(index, 1);
      }
    }
  }
  saveInsuranceCategory() {
    this.saveButtonHideIns = true;
    this.payerDetails
      .InsuranceCategoryCreate(
        this.payorInsuranceForm.value.InsCategoryV1,
        this.lstPayerId
      )
      .subscribe(
        (response) => {
          this.toastr.success('Updated Successfully', 'Success');
          this.saveButtonHideIns = false;
          this.secToggleV2();
          this.resetPayorInsuranceForm();
          this.statusFilter(1);
          this.lstPayerId = [];
        },
        (err) => {
          this.saveButtonHideIns = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    return;
  }

  triggerValidation() {
    const controls = [
      'txtEmail',
      'txtPayorName',
      'txtPayorID',
      'selectEligibility',
      'InsCategory',
      'txtFaxNumber',
      'txtPhoneNumber'
    ];
  
    controls.forEach(controlName => {
      const control = this.payorForm.get(controlName);
      if (control) {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }

  editPayor(value: string) {
    this.saveButtonHide = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.step == false) {
      this.step = true;
    }
    const payerGet = this.payerDetails.get(value).subscribe(
      (response) => {
        const insuranceCategoryId = response?.insuranceCategory;
        const InsucategoryExists = this.InsurenceCategoryList.some(category => category.id === insuranceCategoryId);

        this.stateName = response?.state;
        this.CountryName = response?.country;

        this.CountryName != null && this.CountryName !== '' && this.CountryName != undefined &&
          this.onChangeState(this.CountryName) &&
          this.payorForm.patchValue({
            drpCountry: this.CountryName,
          });

        this.stateName != null && this.stateName !== '' && this.stateName != undefined &&
          this.onChangeCity(this.stateName, this.CountryName) &&
          this.payorForm.patchValue({
            drpState: this.stateName,
            drpCity: this.City,
          });

        // this.onChangeCity(response?.state, defaultCountry.US);
        this.payorForm.patchValue({
          txtPayorID:
            response?.payerCode?.toLowerCase() === 'null'
              ? null
              : response?.payerCode,
          txtPayorName: response?.payerName,
          selectEligibility: response?.eligibilityMode,
          InsCategory: InsucategoryExists ? insuranceCategoryId : null,
          txtPayorCode: response?.code?.toLowerCase() === 'null' ? null : response?.code,
          txtFaxNumber: !response?.faxNo || response?.faxNo === '-' ? null : response?.faxNo,
          txtEmail: response?.email,
          txtAddress: response?.address,
          txtAddress2: response?.address,
          drpCountry: response?.country,
          drpState: response?.state,
          drpCity: response?.city,
          txtPostalCode: response?.zip,
          txtContactName: response?.contactName,
          txtPhoneNumber: !response?.phoneNo || response?.phoneNo === '-' ? null : response?.phoneNo,
          txtWrtOff: response?.wrtOffCode,
          txtpmtCode: response?.pmtCode,
          txtInsCode: response?.insuranceTypeCode,
          txtClaimIndicator: response?.claimFilingIndicator,
          isChecklistRequired: response?.requireCheckList,
          isMedicare: response?.isMedicarePolicy,
        });
        this.payorId = response?.id;
        this.triggerValidation();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(payerGet);
  }

  onSelect(event: any) {
    this.eligibilityMode = event.value
      ? event.value
      : this.payorForm.value.selectEligibility;
  }

  getInsuranceCategory() {
    const payerList = this.payerService.InsuranceCategoryGetList().subscribe(
      (response) => {
        this.lstInsurenceCategory = response.items;
        this.lstInsurenceCategory.sort((a, b) =>
          a.insuranceCategory.localeCompare(b.insuranceCategory)
        );
        this.InsurenceCategoryList = this.lstInsurenceCategory;
      },
      (err) => {
        this.table.setPayorCodeList(this.payerTableData);
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
      }
    );
    this.subscription$.push(payerList);
  }
  //! Delete Payer Function By Id
  deletePayor(payer: PayerDTO) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deletePayor = this.payerDetails.delete(payer.id).subscribe(
          (response) => {
            this.resetPayorForm();
            this.statusFilter(1);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
        this.subscription$.push(deletePayor);
      }
    });
  }

  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.payorForm.patchValue({
      drpState: null,
      drpCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            this.filteredStates = this.payorForm
              ?.get('drpStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpStates.length !== 0;
  }
  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.payorForm.patchValue({
      drpCity: null,
    });
    // country = defaultCountry.US;
    // country =
    //   country == ''
    //     ? this.payorForm.value.drpCountry == (null || undefined)
    //       ? ''
    //       : this.payorForm.value.drpCountry
    //     : country;
    country = country == '' ? this.payorForm.value.drpCountry == (null || undefined) ? '': this.payorForm.value.drpCountry: country;
    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items ?? [];

            this.filteredCities = this.payorForm
              ?.get('drpCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpCities.length !== 0;
  }
  statusChange(event: MatSlideToggleChange, id: string) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Payor will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          if (id && id !== '' && id !== null && id !== undefined) {
            this.payerDetails.payerEnableDisable(id).subscribe(
              (response) => {
                this.toastr.success('Activated Successfully', 'Success');
                this.statusFilter(1);
              },
              (err) => {
                this.toastr.success('Activated Successfully', 'Success');
                this.statusFilter(1);
                //   const data: HttpErrorResponse = err;
                //   Swal.fire({
                //     icon: 'info',
                //     text: data?.error?.error?.message,
                //   });
              }
            );
          }
        } else {
          event.source.checked = false;
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Payor will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
      }).then((result) => {
        if (result.value) {
          if (id && id !== '' && id !== null && id !== undefined) {
            this.payerDetails.payerEnableDisable(id).subscribe(
              (response) => {
                this.toastr.success('Deactivated Successfully', 'Success');
                this.statusFilter(1);
              },
              (err) => {
                // const data: HttpErrorResponse = err;
                // Swal.fire({
                //   icon: 'info',
                //   text: data?.error?.error?.message,
                // });
                this.toastr.success('Deactivated Successfully', 'Success');
                this.statusFilter(1);
              }
            );
          }
        } else {
          event.source.checked = true;
        }
      });
    }
  }
  statusFilter(pageNo: number) {
    this.isLoading = true;
    this.lstPayerId = [];
    this.currentPage = pageNo;
    if (this.payorDataSubscription) {
      this.payorDataSubscription.unsubscribe();
    }
    this.payorDataSubscription = this.payerDetails
      .getPayerListByModeByIModeAndStatusFilterAndSSearchAndPageNoAndPageOffset(
        this.drpModeType,
        this.selectedOption,
        this.searchForm.value.sSearch,
        pageNo,
        this.pageOffset
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.payerTableData = response?.items ?? [];
          this.overallTableCount = response?.totalCount || 0;
          this.payerTableData = this.payerTableData?.map((data) => {
            return {
              ...data,
              creationTime: this.commonService.getFormattedDateTimeZone(
                data.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                data.lastModificationTime
              ),
            };
          });
          this.totalPages =
            Math.ceil(response?.totalCount / this.pageOffset) == 0
              ? 1
              : Math.ceil(response?.totalCount / this.pageOffset);
          this.isLoading = false;
          this.paginationInfo = {
            startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
            endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
            overallTableCount : this.overallTableCount
          };
        },
        (error) => {
          console.error('Error fetching details:', error);
          this.isLoading = false;
        }
      );
  }
  lstPayer(lstPayerId: string[]) {
    this.lstPayerId = lstPayerId;
  }

  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.statusFilter(this.currentPage);
    }
  }
}
export interface payorList {
  payorIdSearch: string;
  payorNameSearch: string;
}
