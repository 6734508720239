<!-- <div class=" container-fluid pr-0 pl-0 mt-2" >
  <div class="mgn row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between m-0">
        <h4 class="mb-0 font-size-18"><b>Posting Details</b></h4>
        <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li></li>
            <li class="ml-2 mt-2 breadcrumb-item">List</li>
            <li class="mt-2 breadcrumb-item muted">Posting Details</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Posting List</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <li class="breadcrumb-item">Billing</li>
            <li class="breadcrumb-item ">Posting List</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-12 table-responsive" style="margin-top: -38px;">
  <!-- <div [hidden]="!isLoading"
    style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
    <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
    </mat-progress-bar>
  </div> -->
  <table id="{{strPageType}}" mat-table class="table table-bordered" [dataSource]="dataSource" matSort>

    <form [formGroup]="postingForm">
      <ng-container matColumnDef="patientId">
        <th class="w-150 th-center" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-patientId" matInput formControlName="patientId">
            <mat-placeholder class="center">PatientId</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.patientId}} </td>
      </ng-container>
      <ng-container matColumnDef="patientName">
        <th class="w-150 th-center" class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-patientName" matInput formControlName="patientName">
            <mat-placeholder>Patient Name</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.patientName}} </td>
      </ng-container>
      <ng-container matColumnDef="dob">
        <th class="header" class="w-150 th-center" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">

            <mat-label>DOB</mat-label>
            <input autocomplete="new-dob" matInput formControlName="dob" placeholder="(MM/DD/YYYY)" />
            <mat-error *ngIf=" postingForm?.get('dob')?.touched && postingForm?.get('dob')?.errors?.dateVaidator">
              Enter Valid date
            </mat-error>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.patientDob}} </td>
      </ng-container>
      <ng-container matColumnDef="dos">
        <th class="header" class="w-150 th-center" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <mat-label>DOS</mat-label>
            <input autocomplete="new-dos" matInput formControlName="dos" placeholder="(MM/DD/YYYY)" />
            <mat-error *ngIf=" postingForm?.get('dos')?.touched && postingForm?.get('dos')?.errors?.dateVaidator">
              Enter Valid date
            </mat-error>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.dos}} </td>
      </ng-container>
      <ng-container matColumnDef="planCode">
        <th class="header" class="w-150 th-center" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-planCode" matInput formControlName="planCode">
            <mat-placeholder>Plan Type</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.planType}} </td>
      </ng-container>
      <ng-container matColumnDef="insurance">
        <th class="header" class="w-150 th-center" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-insurance" matInput formControlName="insurance">
            <mat-placeholder>Insurance</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.insurance}} </td>
      </ng-container>
      <ng-container matColumnDef="createdDateTime">
        <th class="header" class="w-150 th-center" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <mat-label>CreatedDate</mat-label>
            <input autocomplete="new-createdDateTime" matInput formControlName="createdDateTime" placeholder="(MM/DD/YYYY)" />
            <mat-error
              *ngIf=" postingForm?.get('createdDateTime')?.touched && postingForm?.get('createdDateTime')?.errors?.dateVaidator">
              Enter Valid date
            </mat-error>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.createdDateTime}} </td>
      </ng-container>


      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>

        <td mat-cell *matCellDef="let element">
          <a class="eyeCursorclass customThemeClass" (click)="ViewPosting(element.id)" target="_blank"><i
              class="fa fa-eye"></i></a>


        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
          <div *ngIf="!isLoading">
            {{"No Records Found"}}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="8">
          <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
            [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </td>
      </ng-container>
    </form>
    <tr mat-footer-row *matFooterRowDef="['noRecords']"
      [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
    <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
  </table>
</div>


<div class="row">
</div>
