<div class="container-fluid mt-1">

  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="header-class">Tools / My Medicare Checklist</b>
        </h3>

        <div class="page-title-box page-title-right pt-3 pb-0">
          <ol class="breadcrumb m-0">
            <form [formGroup]="branchForm">
              <mat-form-field class="pr-3 mt-3">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName"
                  (selectionChange)="onBranchSelectionChange($event)">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"></ngx-mat-select-search>
                  </mat-option>


                  <mat-option *ngIf="!BranchSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                  <mat-option *ngIf="BranchSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>

                  <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>

            <!-- <li>
              <button matTooltip="Click To Add Patient Policy" mat-button class="mr-2  mt-3 buttonColor font-size-10"
                (click)="openPolicyModal()" [disabled]="!selectedPatient">
                <i class="fa fa-plus "></i>
              </button>
            </li> -->
            <!--
            <li>
              <button matTooltip="Click To Add Note" mat-button class="mr-2  mt-3 buttonColor font-size-10"
                (click)="openAddNotesPopUp()" [disabled]="!selectedPatient">
                <i class="fa fa-pencil-square-o"></i>
              </button>
            </li>
            <li>
              <button matTooltip="Click To View Notes" mat-button class="mr-2  mt-3 buttonColor font-size-10"
                (click)="openViewNotesPopup()" [disabled]="!selectedPatient">
                <i class="fa fa-eye"></i>
              </button>
            </li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-body mt-3">
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <div class="d-flex justify-content-center" *ngIf="isLoading">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
          </div>
        </div>
        <div class="table table-responsive" *ngIf="!isLoading">
          <app-medicare-check-list-table [tableData]="myMedicareCheeckList" ></app-medicare-check-list-table>
        </div>
      </div>
    </div>

  </div>

</div>
