import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { OrderMasterDropdownDTO, PriceCodeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientAddPolicyComponent } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { InsuranceVerifyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { AddVerificationComponent } from '../add-verification/add-verification.component';
import { SaleorderDTO } from '../order-proxy/order-management/order/dto';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';

interface Test {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-order-verification',
  templateUrl: './order-verification.component.html',
  styleUrls: ['./order-verification.component.scss']
})
export class OrderVerificationComponent implements OnInit, OnDestroy {
  // declarations
  isLoading: boolean = true;
  @Input() patientId: string = '';
  drpPolicies: any;
  BirthdateMinDate: Date;
  verificationForm: FormGroup;
  @Input() orderId: string = '';
  @Output() saveVerificationFormState: EventEmitter<any> = new EventEmitter<any>();
  @Input() public strPageType1: string = "items";
  @Input() public strPageType: string = "verification";
  viewOrEdit: string = "";
  verifyTableData: any[] = [];
  tblFrequentItemReponse: any[] = [];
  drpPriceCode: PriceCodeDTO[] = [];
  payorLevel: PayorLevelDTO[];
  @Input() preOrderId: string = '';
  subscription$: Subscription[] = [];
  constructor(
    private store: Store,
    public dialog: MatDialog,
    private Table: TableService,
    private frequentItem: SaleorderService,
    private orderDropdownService: OrderMasterDropdownService
  ) { }

  ngOnInit(): void {
    this.getTableData(this.orderId);
    if (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) { this.getFrequentItemTableData(); }
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //! Load Order Drodopwns
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.VerifiedBies,
      OrderDropdowns.PreparedBies,
      OrderDropdowns.PayablePercents,
      OrderDropdowns.PriceCodes,
      OrderDropdowns.PlanTypes,
      OrderDropdowns.YearTypes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.drpPriceCode = response?.priceCodes;
    });
    this.subscription$.push(orderDropdown);
  }

  //! Load Verification Table Datas
  getTableData(orderId) {
    this.isLoading = true;
    let verifyDetails: SaleorderDTO;
    const getOrder = this.frequentItem.get(orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      verifyDetails = response;
      this.verifyTableData = [];
      const primaryIns = verifyDetails.patients.insuranceVerifications.primaryInsuranceVerifies;
      const secondaryIns = verifyDetails.patients.insuranceVerifications.secondaryInsuranceVerifies;
      const tertiaryIns = verifyDetails.patients.insuranceVerifications.tertiaryInsuranceVerifies;
      const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
      const orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (verifyDetails?.orderBilling?.status)))
      if (primaryIns) {
        this.verifyTableData.push({
          patientDob: verifyDetails?.patients?.personals?.dateOfBirth,
          defaultPatientId: verifyDetails.patients.defaultPatientId,
          patientId: verifyDetails.id,
          address: primaryIns?.address,
          callTrackingNumber: primaryIns?.callTrackingNumber,
          chart: primaryIns?.chart,
          claimAddress: primaryIns?.claimAddress,
          cmnCode: primaryIns?.cmnCode,
          codesRequirePa: primaryIns?.codesRequirePa,
          commentsOrNotes: primaryIns?.commentsOrNotes,
          contact: primaryIns?.contact,
          coverageCriteria: primaryIns?.coverageCriteria,
          dateVerified: primaryIns?.dateVerified,
          dateofBirth: primaryIns?.dateofBirth,
          dedMet: primaryIns?.dedMet,
          dedTotal: primaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: primaryIns?.dmeAnnualLimit,
          dmeCoverage: primaryIns?.dmeCoverage,
          dmeLimitMet: primaryIns?.dmeLimitMet,
          dmeMax: primaryIns?.dmeMax,
          effectivedate: primaryIns?.effectiveDate,
          end: primaryIns?.end,
          fascalYearStart: primaryIns?.fascalYearStart,
          frequency: primaryIns?.frequency,
          group: primaryIns?.groupName,
          hcpcs: primaryIns?.hcpcs,
          insuranceVerificationId: primaryIns?.insuranceVerificationId,
          isDmePartsCovered: primaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: primaryIns?.isDmeReplaceMentUnit,
          isFamily: primaryIns?.isFamily,
          isFamilyDeductible: primaryIns?.isFamilyDeductible,
          isIndividual: primaryIns?.isIndividual,
          isMedicareCmn: primaryIns?.isMedicareCmn,
          isNetwork: primaryIns?.isNetwork,
          isOutOfNetwork: primaryIns?.isOutOfNetwork,
          isPatientHome: primaryIns?.isPatientHome,
          isPercertRequired: primaryIns?.isPercertRequired,
          isReferralRequired: primaryIns?.isReferralRequired,
          lifeTimeMax: primaryIns?.lifeTimeMax,
          lifeTimeMaxMet: primaryIns?.lifeTimeMaxMet,
          name: primaryIns?.name,
          oop: primaryIns?.oop,
          oopMet: primaryIns?.oopMet,
          oopTotal: primaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: primaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpense?.outOfNetwork,
            family: primaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: primaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: primaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: primaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: primaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: primaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: primaryIns?.paRequired,
          payablePercent: {
            individual: primaryIns?.payablePercent?.individual,
            outOfNetwork: primaryIns?.payablePercent?.outOfNetwork,
            family: primaryIns?.payablePercent?.family
          },
          pcp: primaryIns?.pcp,
          phone: primaryIns?.phone,
          plan: primaryIns?.plan,
          planCob: primaryIns?.planCob,
          planDeductible: {
            individual: primaryIns?.planDeductible?.individual,
            outOfNetwork: primaryIns?.planDeductible?.outOfNetwork,
            family: primaryIns?.planDeductible?.family
          },
          planName: primaryIns?.planName,
          planPhone: primaryIns?.planPhone,
          policy: primaryIns?.policy,
          policyHolderDob: primaryIns?.policyHolderDob,
          policyHolderName: primaryIns?.policyHolderName,
          preNotes: primaryIns?.preNotes,
          preparedBy: primaryIns?.preparedBy,
          preparedOn: primaryIns?.preparedOn,
          primaryPayor: primaryIns?.primaryPayor,
          qtyLimits: primaryIns?.qtyLimits,
          rent: primaryIns?.rent,
          requestedBy: primaryIns?.requestedBy,
          sameOrSimilarInfo: primaryIns?.sameOrSimilarInfo,
          spokeWith: primaryIns?.spokeWith,
          typeOfPlan: primaryIns?.typeOfPlan,
          typeOfPolicy: primaryIns?.typeOfPolicy,
          verificationStatus: primaryIns?.verificationStatus,
          verifiedBy: primaryIns?.verifiedBy,
          deo: primaryIns?.deo,
          coInsurance: primaryIns?.coInsurance,

          commentsToTech: primaryIns?.commentsToTech,
          verficationMethod: primaryIns?.verifiedMethod,
          isPredetermination: primaryIns?.isPredetermination,
          reason: primaryIns?.reason,
          isActive: primaryIns?.isActive,
          isBlueCardEligible: primaryIns?.isBlueCardEligible,
          isDirectBill: primaryIns?.isDirectBill,
          isPreAuthNeeded: primaryIns?.isPreAuthNeeded,
          isClaimSubmit: primaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: primaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: primaryIns?.isReferenceNumberAvailable,
          referenceNumber: primaryIns?.referenceNumber,
          orderStatus: orderStatus

        })
      }
      if (secondaryIns) {
        this.verifyTableData.push({
          patientDob: verifyDetails?.patients?.personals?.dateOfBirth,
          defaultPatientId: verifyDetails.patients.defaultPatientId,
          patientId: verifyDetails.id,
          address: secondaryIns?.address,
          callTrackingNumber: secondaryIns?.callTrackingNumber,
          chart: secondaryIns?.chart,
          claimAddress: secondaryIns?.claimAddress,
          cmnCode: secondaryIns?.cmnCode,
          codesRequirePa: secondaryIns?.codesRequirePa,
          commentsOrNotes: secondaryIns?.commentsOrNotes,
          contact: secondaryIns?.contact,
          coverageCriteria: secondaryIns?.coverageCriteria,
          dateVerified: secondaryIns?.dateVerified,
          dateofBirth: secondaryIns?.dateofBirth,
          dedMet: secondaryIns?.dedMet,
          dedTotal: secondaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: secondaryIns?.dmeAnnualLimit,
          dmeCoverage: secondaryIns?.dmeCoverage,
          dmeLimitMet: secondaryIns?.dmeLimitMet,
          dmeMax: secondaryIns?.dmeMax,
          effectivedate: secondaryIns?.effectiveDate,
          end: secondaryIns?.end,
          fascalYearStart: secondaryIns?.fascalYearStart,
          frequency: secondaryIns?.frequency,
          group: secondaryIns?.groupName,
          hcpcs: secondaryIns?.hcpcs,
          insuranceVerificationId: secondaryIns?.insuranceVerificationId,
          isDmePartsCovered: secondaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: secondaryIns?.isDmeReplaceMentUnit,
          isFamily: secondaryIns?.isFamily,
          isFamilyDeductible: secondaryIns?.isFamilyDeductible,
          isIndividual: secondaryIns?.isIndividual,
          isMedicareCmn: secondaryIns?.isMedicareCmn,
          isNetwork: secondaryIns?.isNetwork,
          isOutOfNetwork: secondaryIns?.isOutOfNetwork,
          isPatientHome: secondaryIns?.isPatientHome,
          isPercertRequired: secondaryIns?.isPercertRequired,
          isReferralRequired: secondaryIns?.isReferralRequired,
          lifeTimeMax: secondaryIns?.lifeTimeMax,
          lifeTimeMaxMet: secondaryIns?.lifeTimeMaxMet,
          name: secondaryIns?.name,
          oop: secondaryIns?.oop,
          oopMet: secondaryIns?.oopMet,
          oopTotal: secondaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: secondaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpense?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: secondaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: secondaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: secondaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: secondaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: secondaryIns?.paRequired,
          payablePercent: {
            individual: secondaryIns?.payablePercent?.individual,
            outOfNetwork: secondaryIns?.payablePercent?.outOfNetwork,
            family: secondaryIns?.payablePercent?.family
          },
          pcp: secondaryIns?.pcp,
          phone: secondaryIns?.phone,
          plan: secondaryIns?.plan,
          planCob: secondaryIns?.planCob,
          planDeductible: {
            individual: secondaryIns?.planDeductible?.individual,
            outOfNetwork: secondaryIns?.planDeductible?.outOfNetwork,
            family: secondaryIns?.planDeductible?.family
          },
          planName: secondaryIns?.planName,
          planPhone: secondaryIns?.planPhone,
          policy: secondaryIns?.policy,
          policyHolderDob: secondaryIns?.policyHolderDob,
          policyHolderName: secondaryIns?.policyHolderName,
          preNotes: secondaryIns?.preNotes,
          preparedBy: secondaryIns?.preparedBy,
          preparedOn: secondaryIns?.preparedOn,
          primaryPayor: secondaryIns?.primaryPayor,
          qtyLimits: secondaryIns?.qtyLimits,
          rent: secondaryIns?.rent,
          requestedBy: secondaryIns?.requestedBy,
          sameOrSimilarInfo: secondaryIns?.sameOrSimilarInfo,
          spokeWith: secondaryIns?.spokeWith,
          typeOfPlan: secondaryIns?.typeOfPlan,
          typeOfPolicy: secondaryIns?.typeOfPolicy,
          verificationStatus: secondaryIns?.verificationStatus,
          verifiedBy: secondaryIns?.verifiedBy,
          deo: secondaryIns?.deo,
          coInsurance: secondaryIns?.coInsurance,

          commentsToTech: secondaryIns?.commentsToTech,
          verficationMethod: secondaryIns?.verifiedMethod,
          isPredetermination: secondaryIns?.isPredetermination,
          reason: secondaryIns?.reason,
          isActive: secondaryIns?.isActive,
          isBlueCardEligible: secondaryIns?.isBlueCardEligible,
          isDirectBill: secondaryIns?.isDirectBill,
          isPreAuthNeeded: secondaryIns?.isPreAuthNeeded,
          isClaimSubmit: secondaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: secondaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: secondaryIns?.isReferenceNumberAvailable,
          referenceNumber: secondaryIns?.referenceNumber,
          orderStatus: orderStatus
        })
      }
      if (tertiaryIns) {
        this.verifyTableData.push({
          patientDob: verifyDetails?.patients?.personals?.dateOfBirth,
          defaultPatientId: verifyDetails.patients.defaultPatientId,
          patientId: verifyDetails.id,
          address: tertiaryIns?.address,
          callTrackingNumber: tertiaryIns?.callTrackingNumber,
          chart: tertiaryIns?.chart,
          claimAddress: tertiaryIns?.claimAddress,
          cmnCode: tertiaryIns?.cmnCode,
          codesRequirePa: tertiaryIns?.codesRequirePa,
          commentsOrNotes: tertiaryIns?.commentsOrNotes,
          contact: tertiaryIns?.contact,
          coverageCriteria: tertiaryIns?.coverageCriteria,
          dateVerified: tertiaryIns?.dateVerified,
          dateofBirth: tertiaryIns?.dateofBirth,
          dedMet: tertiaryIns?.dedMet,
          dedTotal: tertiaryIns?.dedTotal,
          deductibleMet: {
            individual: tertiaryIns?.deductibleMet?.individual,
            outOfNetwork: tertiaryIns?.deductibleMet?.outOfNetwork,
            family: tertiaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: tertiaryIns?.dmeAnnualLimit,
          dmeCoverage: tertiaryIns?.dmeCoverage,
          dmeLimitMet: tertiaryIns?.dmeLimitMet,
          dmeMax: tertiaryIns?.dmeMax,
          effectivedate: tertiaryIns?.effectiveDate,
          end: tertiaryIns?.end,
          fascalYearStart: tertiaryIns?.fascalYearStart,
          frequency: tertiaryIns?.frequency,
          group: tertiaryIns?.groupName,
          hcpcs: tertiaryIns?.hcpcs,
          insuranceVerificationId: tertiaryIns?.insuranceVerificationId,
          isDmePartsCovered: tertiaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: tertiaryIns?.isDmeReplaceMentUnit,
          isFamily: tertiaryIns?.isFamily,
          isFamilyDeductible: tertiaryIns?.isFamilyDeductible,
          isIndividual: tertiaryIns?.isIndividual,
          isMedicareCmn: tertiaryIns?.isMedicareCmn,
          isNetwork: tertiaryIns?.isNetwork,
          isOutOfNetwork: tertiaryIns?.isOutOfNetwork,
          isPatientHome: tertiaryIns?.isPatientHome,
          isPercertRequired: tertiaryIns?.isPercertRequired,
          isReferralRequired: tertiaryIns?.isReferralRequired,
          lifeTimeMax: tertiaryIns?.lifeTimeMax,
          lifeTimeMaxMet: tertiaryIns?.lifeTimeMaxMet,
          name: tertiaryIns?.name,
          oop: tertiaryIns?.oop,
          oopMet: tertiaryIns?.oopMet,
          oopTotal: tertiaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: tertiaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpense?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: tertiaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: tertiaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: tertiaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: tertiaryIns?.paRequired,
          payablePercent: {
            individual: tertiaryIns?.payablePercent?.individual,
            outOfNetwork: tertiaryIns?.payablePercent?.outOfNetwork,
            family: tertiaryIns?.payablePercent?.family
          },
          pcp: tertiaryIns?.pcp,
          phone: tertiaryIns?.phone,
          plan: tertiaryIns?.plan,
          planCob: tertiaryIns?.planCob,
          planDeductible: {
            individual: tertiaryIns?.planDeductible?.individual,
            outOfNetwork: tertiaryIns?.planDeductible?.outOfNetwork,
            family: tertiaryIns?.planDeductible?.family
          },
          planName: tertiaryIns?.planName,
          planPhone: tertiaryIns?.planPhone,
          policy: tertiaryIns?.policy,
          policyHolderDob: tertiaryIns?.policyHolderDob,
          policyHolderName: tertiaryIns?.policyHolderName,
          preNotes: tertiaryIns?.preNotes,
          preparedBy: tertiaryIns?.preparedBy,
          preparedOn: tertiaryIns?.preparedOn,
          primaryPayor: tertiaryIns?.primaryPayor,
          qtyLimits: tertiaryIns?.qtyLimits,
          rent: tertiaryIns?.rent,
          requestedBy: tertiaryIns?.requestedBy,
          sameOrSimilarInfo: tertiaryIns?.sameOrSimilarInfo,
          spokeWith: tertiaryIns?.spokeWith,
          typeOfPlan: tertiaryIns?.typeOfPlan,
          typeOfPolicy: tertiaryIns?.typeOfPolicy,
          verificationStatus: tertiaryIns?.verificationStatus,
          verifiedBy: tertiaryIns?.verifiedBy,
          deo: tertiaryIns?.deo,
          coInsurance: tertiaryIns?.coInsurance,

          commentsToTech: tertiaryIns?.commentsToTech,
          verficationMethod: tertiaryIns?.verifiedMethod,
          isPredetermination: tertiaryIns?.isPredetermination,
          reason: tertiaryIns?.reason,
          isActive: tertiaryIns?.isActive,
          isBlueCardEligible: tertiaryIns?.isBlueCardEligible,
          isDirectBill: tertiaryIns?.isDirectBill,
          isPreAuthNeeded: tertiaryIns?.isPreAuthNeeded,
          isClaimSubmit: tertiaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: tertiaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: tertiaryIns?.isReferenceNumberAvailable,
          referenceNumber: tertiaryIns?.referenceNumber,
          orderStatus: orderStatus

        })
      }
      this.isLoading = false;
      this.Table.setVerifyTable(this.verifyTableData);
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getOrder);
  }

  //! Load Frequent Item Table Datas
  getFrequentItemTableData() {
    const frequentItem = this.frequentItem.frequentOrderItemsByPatientId(this.patientId).subscribe(response => {
      this.tblFrequentItemReponse = [];
      response?.forEach(element => {
        if (element?.itemId !== "00000000-0000-0000-0000-000000000000") {
          this.tblFrequentItemReponse.push({
            itemId: element?.itemId,
            saleType: element?.saleType,
            quickAddQuantity: element?.quickAddQuantity,
            itemIdOrBarCode: element?.itemIdOrBarCode,
            itemCodeId: element?.itemCodeId,
            name: element?.name,
            description: element?.description,
            location: element?.location,
            note: element?.note,
            insuranceName: element?.insuranceName,
            qty: element?.qty,
            bQty: element?.billingQuantity,
            priceCode: this.drpPriceCode.filter(value => { return value?.priceCodeId === element?.priceCode })[0]?.priceCodeType,
            priceOption: element?.priceOption,
            modifiers1: element?.modifiers1,
            dosTo: element?.dosTo,
            nextDOS: element?.nextDOS !== null && element?.nextDOS !== "" ? new Date(element?.nextDOS)?.toLocaleDateString("en-US") : "",
            // isSpanDateSplitBillingEnabled: number;
            // cmnId: string;
            // cmnStatus: string;
            // cmnExpDate?: string;
            // cmnForm: string;
            // itemClaimNoteType: string;
            // itemClaimNoteQuantity: string;
            // preBilling?: string;
            // nextBilling?: string;
            // nextPeriod: string;
            additionalModifiers1: element?.additionalModifiers1,
            modifiers2: element?.modifiers2,
            modifiers3: element?.modifiers3,
            modifiers4: element?.modifiers4,
            billed: element?.charge,
            allow: element?.allow,
            // parId: element?.parId,
            // parNumber: element?.parNumber,
            // parStatus: element?.parStatus,
            // parExpDate: element?.parExpDate,
            taxRate: element?.taxRate,
            taxZone: element?.taxZone,
            nonTaxReason: element?.nonTaxReason,
            quantity: element?.quantity,
            billingQuantity: element?.billingQuantity,
            isPrimary: element?.isPrimary,
            coverageType: element?.coverageType,
            claimSubmission: element?.claimSubmission,
            isSecondary: element?.isSecondary,
            isTertiary: element?.isTertiary,
            isPatient: element?.isPatient,
            isDaysOfSupplyOverride: element?.isDaysOfSupplyOverride,
            daysSupply: element?.daysSupply,
            originalDos: element?.originalDos !== null && element?.originalDos !== "" ? new Date(element?.originalDos)?.toLocaleDateString("en-US") : "",
            additionalModifiers2: element?.additionalModifiers2,
            additionalModifiers3: element?.additionalModifiers3,
            additionalModifiers4: element?.additionalModifiers4,
            espdtIndicator: element?.espdtIndicator,
            isIncludeEclaimsAttachment: element?.isIncludeEclaimsAttachment,
            attachmentNo: element?.attachmentNo,
            typeCode: element?.typeCode,
            transCode: element?.transCode,
            isDiagnosisCodeICD9: element?.isDiagnosisCodeICD9,
            isDiagnosisCodeICD10: element?.isDiagnosisCodeICD10,
          });
        }

      })
      this.Table.setOrderTable(this.tblFrequentItemReponse.reverse());
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(frequentItem);
  }

  //! Open Verification Modal Popup
  openVerifyModal() {
    const dialogRef = this.dialog.open(AddVerificationComponent, {
      disableClose: true,
      data: { patientId: this.patientId, orderId: this.orderId }
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getTableData(this.orderId);
    });
    this.subscription$.push(closeModal);
  }

  //! View Verification Modal Popup
  viewVerification(verify: InsuranceVerifyDTO) {
    const dialogRef = this.dialog.open(AddVerificationComponent, {
      disableClose: true,
      data: { patientId: this.patientId, orderId: this.orderId, verifyData: verify }
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getTableData(this.orderId);
    });
    this.subscription$.push(closeModal);
  }

  //! open policy modal
  openPolicyModal() {
    const dialogRef = this.dialog.open(PatientAddPolicyComponent, {
      disableClose: true,
      data: { patientId: this.patientId }
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
    });
    this.subscription$.push(closeModal);
  }

}

export interface drpPolicesDTO {
  insuranceType: string
  insuranceId: string
  birthdate: string
  policyNo: string
  startDate: string
  endDate: string
  groupName: string
  groupNo: string
  fisrtName: string
  address1: string
}
