import type { PatientDocumentBlobDTO, SavePatientDocumentBlobDTO, SearchDocumentFollowUpReport, UpdateDocumentBlobDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  apiName = 'Default';

  deleteByPatientIdAndPatientDocumentId = (patientId: string, patientDocumentId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'DELETE',
      url: '/api/app/document',
      params: { patientId, patientDocumentId },
    },
     { apiName: this.apiName, skipHandleError: true });

  getByPatientId = (patientId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO[]>({
      method: 'GET',
      url: '/api/app/document',
      params: { patientId },
    },
     { apiName: this.apiName, skipHandleError: true });

  getPatientDocumentByBlobIDByBlobName = (blobName: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      url: '/api/app/document/patient-document-by-blob-iD',
      params: { blobName },
    },
     { apiName: this.apiName, skipHandleError: true });

  postByInputAndPatientObjectIDAndFileContent = (input: SavePatientDocumentBlobDTO, patientObjectID: string, fileContent: number[]) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'POST',
      url: '/api/app/document',
      params: { patientObjectID },
      body: fileContent,
    },
     { apiName: this.apiName, skipHandleError: true });

  postWithoutDocumentByInputAndPatientObjectID = (input: SavePatientDocumentBlobDTO, patientObjectID: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'POST',
      url: '/api/app/document/without-document',
      params: { patientObjectID },
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  putByInputAndPatientObjectID = (input: UpdateDocumentBlobDTO, patientObjectID: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>({
      method: 'PUT',
      url: '/api/app/document',
      params: { patientObjectID },
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getPatientDocumentsByPatientIdAndAutoUploadDocumentId = (patientId: string, autoUploadDocumentId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO[]>({
      method: 'GET',
      url: '/api/app/document/get-patient-documents',
      params: { patientId, autoUploadDocumentId },
    },
     { apiName: this.apiName, skipHandleError: true });

  mergeDocumentsByPatientIdAndParentDocumentId = (patientId: string, parentDocumentId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO[]>({
      method: 'POST',
      url: '/api/app/document/merge-documents',
      params: { patientId, parentDocumentId },
    },
     { apiName: this.apiName, skipHandleError: true });

     searchPatientDocumentsByPatientIdAndSFileNameAndSDocumentTypeAndDtDocumentStartDateAndDtDocumentEndDate = (patientId: string, sFileName: string, sDocumentType: string, dtDocumentStartDate: string, dtDocumentEndDate: string) =>
     this.restService.request<any, PatientDocumentBlobDTO[]>({
       method: 'POST',
       url: `/api/app/document/search-patient-documents/${patientId}`,
       params: { sFileName, sDocumentType, dtDocumentStartDate, dtDocumentEndDate },
     },
     { apiName: this.apiName });
     searchPatientDocumentsByInput = (input:SearchDocumentFollowUpReport) =>
     this.restService.request<any, PatientDocumentBlobDTO[]>({
       method: 'POST',
       url: `/api/app/document/search-documents-follow-up`,
      body: input,
     },
     { apiName: this.apiName });


  constructor(private restService: RestService) {}
}
