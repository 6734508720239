import type { CreateUpdateDiscountPercentDTO, DiscountPercentDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class DiscountPercentService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateDiscountPercentDTO) =>
    this.restService.request<any, DiscountPercentDTO>({
      method: 'POST',
      url: '/api/orderApp/discount-percent',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/discount-percent/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, DiscountPercentDTO>({
      method: 'GET',
      url: `/api/orderApp/discount-percent/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<DiscountPercentDTO>>({
      method: 'GET',
      url: '/api/orderApp/discount-percent',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getDiscountList = (statusFilter: ActiveStatus) =>
    this.restService.request<any, PagedResultDto<DiscountPercentDTO>>({
      method: 'GET',
      url: '/api/orderApp/discount-percent/discount-per-list',
      params: { statusFilter },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateDiscountPercentDTO) =>
    this.restService.request<any, DiscountPercentDTO>({
      method: 'PUT',
      url: `/api/orderApp/discount-percent/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  updateData = (id: string, input: CreateUpdateDiscountPercentDTO) =>
    this.restService.request<any, DiscountPercentDTO>({
      method: 'PUT',
      url: `/api/orderApp/discount-percent/${id}/data`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
