<table
  class="row-border hover display w-100"
  datatable
  [dtOptions]="dtPOSummaryTableOptions"
>
  <thead>
    <tr>
      <th [matTooltip]="'Manufacturer ID'">Manufacturer ID</th>
      <th [matTooltip]="'Manufacturer Name'">Manufacturer Name</th>
      <th class="text-center" [matTooltip]="'Requisition Count'">
        Requisition Count
      </th>
      <th class="text-center" [matTooltip]="'Total Cost ($)'">
        Total Cost ($)
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of ltSummaryData">
      <td [matTooltip]="data.mmManufacturerId || '-'">
        {{ data.mmManufacturerId || "-" }}
      </td>
      <td [matTooltip]="data.manufacturerName || '-'">
        {{ data.manufacturerName || "-" }}
      </td>
      <td [matTooltip]="data.requisitionCount || '-'" class="text-center">
        <a class="hyperlink" (click)="moveToPurchaseOrder(data.manufacturerId)">
          {{ data.requisitionCount || "-" }}
        </a>
      </td>
      <td
        class="text-center"
        matTooltip="{{ data.totalCost ? '$' + data.totalCost : '-' }}"
      >
        {{ data.totalCost ? "$" + data.totalCost : "-" }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <input
          matInput
          class="textbox-border-class w-100"
          type="text"
          placeholder="Manufacturer ID"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class w-100"
          type="text"
          placeholder="Manufacturer Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center w-100"
          type="text"
          placeholder="Requisition Count"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center w-100"
          type="text"
          placeholder="Total Cost ($)"
        />
      </th>
    </tr>
  </tfoot>
</table>
