<div class="row">
  <div class="col-lg-6">
    <h2>Insurance Eligibility</h2>
  </div>

</div>

<mat-dialog-content class="mat-typography">

  <form [formGroup]="eligibilityMedicareForm">
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">

            <mat-radio-group class="rg pt-3 ml-3" (change)="onChangeGetInsuranceVerify($event)"
              formControlName="rbtnPolicyType">
              <mat-radio-button [checked]='true' value="PVerify">PVerify</mat-radio-button>&nbsp;&nbsp;&nbsp;
              <mat-radio-button value="Zermid">Zermid Verify</mat-radio-button>&nbsp;&nbsp;&nbsp;
            </mat-radio-group>
            <!-- <div class="col-12"> -->
            <mat-accordion class="example-headers-align" multi>
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="customThemeClass font-size-18">
                    <b> Requests Criteria</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="col-4">
                      <h4 class="customThemeClass"><b>Provider</b></h4>
                    </div>
                    <mat-form-field class="col-3">
                      <mat-label>Plan <span class="asterisk">*</span></mat-label>
                      <mat-select maxlength="40" formControlName="drpPayor"
                        (valueChange)="valueChangeLoadDropdown($event)">
                        <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                          {{ payor.payorLevelName }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="eligibilityMedicareForm?.get('drpPayor')?.touched &&eligibilityMedicareForm?.get('drpPayor')?.errors?.required">
                        Payor Level is a required field!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>NPI <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtNpi" maxlength="40" formControlName="txtNpi" minlength="10" maxlength="10" matInput
                        type="text" />
                      <mat-error *ngIf="
                                                eligibilityMedicareForm?.get('txtNpi')?.touched &&
                                                eligibilityMedicareForm?.get('txtNpi')?.errors?.required
                                                ">
                        NPI is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                                                eligibilityMedicareForm?.get('txtNpi')?.touched &&
                                                eligibilityMedicareForm?.get('txtNpi')?.errors?.minlength">
                        Enter Minimum 10 Digits!
                      </mat-error>
                      <mat-error *ngIf="
                                                eligibilityMedicareForm?.get('txtNpi')?.touched &&
                                                eligibilityMedicareForm?.get('txtNpi')?.errors?.number">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Provider Name <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtProviderName" maxlength="40" formControlName="txtProviderName" matInput type="text" />
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtProviderName')?.touched &&
                                                    eligibilityMedicareForm?.get('txtProviderName')?.errors?.required
                                                    ">
                        Provider Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtProviderName')?.touched &&
                                                    eligibilityMedicareForm?.get('txtProviderName')?.errors?.sAlphabets
                                                  ">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>DOS <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtEligibilityDate" formControlName="txtEligibilityDate" matInput maxlength="40" placeholder="MM/DD/YYYY"
                        [matDatepicker]="txtEligibilityDate" />
                      <mat-datepicker-toggle matSuffix [for]="txtEligibilityDate">
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtEligibilityDate> </mat-datepicker>
                      <mat-error *ngIf="
                                                eligibilityMedicareForm?.get('txtEligibilityDate')?.touched &&
                                                eligibilityMedicareForm?.get('txtEligibilityDate')?.errors
                                              ">
                        Please select Valid Date!
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-12">
                    <div class="col-8">
                      <h4 class="customThemeClass"><b>Subscriber Information</b></h4>
                    </div>
                    <mat-form-field class="col-3">
                      <mat-label>First Name <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtFirstName" maxlength="40" formControlName="txtFirstName" matInput type="text" />
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtFirstName')?.touched &&
                                                    eligibilityMedicareForm?.get('txtFirstName')?.errors?.required
                                                    ">
                        First Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtFirstName')?.touched &&
                                                    eligibilityMedicareForm?.get('txtFirstName')?.errors?.sAlphabets
                                                  ">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Last Name <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtLastName" maxlength="40" formControlName="txtLastName" matInput type="text" />
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtLastName')?.touched &&
                                                    eligibilityMedicareForm?.get('txtLastName')?.errors?.required
                                                    ">
                        Last Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtLastName')?.touched &&
                                                    eligibilityMedicareForm?.get('txtLastName')?.errors?.sAlphabets
                                                  ">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>DOB <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtDob" formControlName="txtDob" matInput maxlength="40" placeholder="MM/DD/YYYY"
                        [matDatepicker]="txtDob" />
                      <mat-datepicker-toggle matSuffix [for]="txtDob">
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtDob> </mat-datepicker>
                      <mat-error *ngIf="
                                                eligibilityMedicareForm?.get('txtDob')?.touched &&
                                                eligibilityMedicareForm?.get('txtDob')?.errors
                                              ">
                        Please select Valid Date!
                      </mat-error>
                    </mat-form-field>
                    <!-- <mat-form-field class="col-4">
                        <mat-label>Suffix</mat-label>
                        <input maxlength="40" formControlName="txtSuffix" matInput type="text" />
                      </mat-form-field> -->
                    <mat-form-field class="col-3">
                      <mat-label>Member ID <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtMemeberId" maxlength="40" formControlName="txtMemeberId" matInput type="text"
                        pattern="[a-zA-Z0-9]*" />
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtMemeberId')?.touched &&
                                                    eligibilityMedicareForm?.get('txtMemeberId')?.errors?.required
                                                    ">
                        Last Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtMemeberId')?.touched &&
                                                    eligibilityMedicareForm?.get('txtMemeberId')?.errors?.pattern
                                                    ">
                        Special Characters not allowed!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="pverifyId" class="col-3">
                      <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtPayerId" maxlength="40" formControlName="txtPayerId" matInput type="text" />
                      <mat-error *ngIf="
                                                    eligibilityMedicareForm?.get('txtPayerId')?.touched &&
                                                    eligibilityMedicareForm?.get('txtPayerId')?.errors?.required
                                                    ">
                        Payer Id is a required field!
                      </mat-error>

                    </mat-form-field>


                    <mat-form-field class="col-3" *ngIf="zermidName">
                      <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtZermidPayerId" formControlName="txtZermidPayerId" matInput type="text" />
                      <mat-error *ngIf="
                        eligibilityMedicareForm?.get('txtZermidPayerId')?.touched &&
                        eligibilityMedicareForm?.get('txtZermidPayerId')?.errors?.required
                        ">
                        Payer Name is a required field!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3" *ngIf="pverifyName">
                      <mat-label>Payer Name<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtPayerName" formControlName="txtPayerName" matInput type="text" />
                      <mat-error *ngIf="
                        eligibilityMedicareForm?.get('txtPayerName')?.touched &&
                        eligibilityMedicareForm?.get('txtPayerName')?.errors?.required
                        ">
                        Payer Name is a required field!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-6" *ngIf="zermidName">
                      <mat-label>Payer Name<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtZermidPayerName" formControlName="txtZermidPayerName" matInput type="text" />
                      <mat-error *ngIf="
                        eligibilityMedicareForm?.get('txtZermidPayerName')?.touched &&
                        eligibilityMedicareForm?.get('txtZermidPayerName')?.errors?.required
                        ">
                        Payer Name is a required field!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
              </div> -->
              <mat-expansion-panel [hidden]="isLoading" *ngIf='isShown' [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="customThemeClass font-size-18">
                    <b>Results</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                  <div class="col-lg-12">
                    <h4 class="customThemeClass"><b>Eligibility Report</b></h4>
                  </div>
                  <!-- <br> -->
                  <div class="col-lg-4 pt-2">
                    <h4 class="mb-0"><b>Eligibility Begin Date</b> : {{lblEligibilityDate}}
                    </h4>
                  </div>
                  <div class="col-lg-4 pt-2">
                    <h4 class="mb-0"><b> Plan Begin Date</b> : {{lblPlanBeginDate}}
                    </h4>
                  </div>
                  <div class="col-lg-4 pt-2">
                    <h4 class="mb-0"><b>Plan End Date</b> : {{lblPlanEndDate}}
                    </h4>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-2">
                    <h4 class="customThemeClass"><b>Subscriber Info</b></h4>
                    <h4 class="mb-0 pt-2"><b>Patient Name</b></h4> <br>
                    <h4 class="mb-0"><b>Member ID</b></h4> <br>
                    <h4 class="mb-0"><b>Date Of Birth</b></h4><br>
                    <h4 class="mb-0"><b>Gender</b></h4><br>
                    <h4 class="mb-0"><b>Relationship</b></h4><br>

                  </div>
                  <div class="col-lg-3">
                    <h4 class="customThemeClass"><b>&nbsp;&nbsp;</b></h4>
                    <h4 class="mb-0 pt-2 text-left">: {{lblSubscriberName}}</h4> <br>
                    <h4 class="mb-0">: {{lblMemberId}}</h4> <br>
                    <h4 class="mb-0">: {{lblPatientDob}}</h4> <br>
                    <h4 class="mb-0">: {{lblPatientGender}}</h4><br>
                    <h4 class="mb-0">: {{lblSubscriberRelationship}}</h4><br>
                  </div>
                  <div class="col-lg-2">
                    <h4 class="customThemeClass"></h4>
                    <h4 class="mb-0 pt-4"><b>Address</b></h4><br>
                    <h4 class="mb-0"><b>State</b></h4><br>
                    <h4 class="mb-0"><b>City</b></h4><br>
                    <h4 class="mb-0"><b>Zip Code</b></h4>
                  </div>
                  <div class="col-lg-3">
                    <h4 class="customThemeClass"><b>&nbsp;&nbsp;</b></h4>
                    <h4 class="mb-0 ">: {{lblPatientAddress}}</h4><br>
                    <h4 class="mb-0">: {{lblState}}</h4> <br>
                    <h4 class="mb-0">: {{lblCity}}</h4><br>
                    <h4 class="mb-0">: {{lblZip}}</h4>

                  </div>
                  <div class="col-lg-12">
                    <h4 class="customThemeClass"><b>Plan Coverage Summary</b></h4>
                    <app-table [strPageType]="strPageType">
                    </app-table>
                  </div>
                </div>

                <h4 class="mb-0 customThemeClass"><b>Deductible Summary</b></h4><br>
                <table>
                  <tr>

                    <td><b>Individual Deductible InNet</b></td>
                    <!-- <td>:</td> -->
                    <td><b>: {{lblIDInNet}}</b></td>

                    <td><b>Family Deductible InNet</b></td>
                    <!--<td><b>:</b></td> -->
                    <td><b>: {{lblFDInNet}}</b></td>

                  </tr>
                  <tr>
                    <td class="mt-2"><b>Individual Deductible OutNet</b></td>
                    <td><b>: {{lblIDOutNet}}</b></td>
                    <td><b>Family Deductible OutNet</b></td>
                    <td><b>: {{lblFDOutNet}}</b></td>
                  </tr>
                  <tr>
                    <td><b>Individual OOP InNet</b></td>
                    <td><b>: {{lblIDOOPInNet}}</b></td>
                    <td><b>Family OOP InNet</b></td>
                    <td><b>: {{lblFOOPInNet}}</b></td>
                  </tr>
                  <tr>
                    <td><b>Individual OOP OutNet</b></td>
                    <td><b>: {{lblIDOOPOutNet}}</b></td>
                    <td><b>Family OOP OutNet</b></td>
                    <td><b>: {{lblFOOPOutNet}}</b></td>
                  </tr>
                  <tr>
                    <td><b>Individual Deductible Remaining InNet</b></td>
                    <td><b>: {{lblIRemainingInNet}}</b></td>
                    <td><b>
                        Family Deductible Remaining InNet</b></td>
                    <td><b>: {{lblFRemainingInNet}}</b></td>
                  </tr>

                  <tr>
                    <td><b>Individual Deductible Remaining OutNet</b></td>
                    <td><b>: {{lblIRemainingOutNet}}</b></td>
                    <td><b>Family Deductible Remaining OutNet</b></td>
                    <td><b>: {{lblFRemainingOutNet}}</b></td>
                  </tr>
                  <tr>
                    <td><b>Individual OOP Remaining InNet</b></td>
                    <td><b>: {{lblIOOPRemainingInNet}}</b></td>
                    <td><b>Family OOP Remaining InNet</b></td>
                    <td><b>: {{lblFOOPRemainingInNet}}</b></td>
                  </tr>
                  <tr>
                    <td><b>Individual OOP Remaining OutNet</b></td>
                    <td><b>: {{lblIOOPRemainingOutNet}}</b></td>
                    <td><b>Family OOP Remaining OutNet</b></td>
                    <td><b>: {{lblFOOPRemainingOutNet}}</b></td>
                  </tr>
                  <tr>
                    <td><b>CoIns Net</b></td>
                    <td><b>: {{lblCPInNet}}</b></td>
                    <td><b>CoPay Net</b></td>
                    <td><b>: {{lblCPOutNet}}</b></td>
                  </tr>

                </table>








                <!-- <div class="row pt-3">
                    <div class="col-lg-4">
                      <h4 class="mb-0 customThemeClass"><b>Deductible Summary</b></h4><br>
                      <h4 class="mb-0"><b>Individual Deductible InNet</b></h4><br>
                      <h4 class="mb-0"><b>Individual Deductible OutNet</b></h4> <br>
                      <h4 class="mb-0"><b>Individual OOP InNet</b></h4><br>
                      <h4 class="mb-0"><b>Individual OOP OutNet</b></h4><br>
                      <h4 class="mb-0"><b>Individual Deductible Remaining InNet</b></h4><br>
                      <h4 class="mb-0"><b>Individual Deductible Remaining OutNet</b></h4><br>
                      <h4 class="mb-0"><b>Individual OOP Remaining InNet</b></h4><br>
                      <h4 class="mb-0"><b>Individual OOP Remaining OutNet</b></h4><br>
                      <h4 class="mb-0"><b>CoIns Net</b></h4><br>
                    </div>
                    <div class="col-lg-2">
                      <h4 class="mb-0"></h4><br><br>
                      <h4 class="mb-0">: {{lblIDInNet}}</h4> <br>
                      <h4 class="mb-0">: {{lblIDOutNet}}</h4> <br>
                      <h4 class="mb-0">: {{lblIDOOPInNet}}</h4> <br>
                      <h4 class="mb-0">: {{lblIDOOPOutNet}}</h4><br>
                      <h4 class="mb-0">: {{lblIRemainingInNet}}</h4> <br>
                      <h4 class="mb-0">: {{lblIRemainingOutNet}}</h4> <br>
                      <h4 class="mb-0">: {{lblIOOPRemainingInNet}}</h4><br>
                      <h4 class="mb-0">: {{lblIOOPRemainingOutNet}}</h4><br>
                      <h4 class="mb-0">: {{lblCPInNet}}</h4><br>

                    </div>
                    <div class="col-lg-4">
                      <br><br>
                      <h4 class="mb-0"><b>Family Deductible InNet</b></h4><br>
                      <h4 class="mb-0"><b>Family Deductible OutNet</b></h4><br>
                      <h4 class="mb-0"><b>Family OOP InNet</b></h4><br>
                      <h4 class="mb-0"><b>Family OOP OutNet</b></h4><br>
                      <h4 class="mb-0"><b>Family Deductible Remaining InNet</b></h4><br>
                      <h4 class="mb-0"><b>Family Deductible Remaining OutNet</b></h4><br>
                      <h4 class="mb-0"><b>Family OOP Remaining InNet</b></h4><br>
                      <h4 class="mb-0"><b>Family OOP Remaining OutNet</b></h4><br>
                      <h4 class="mb-0"><b>CoPay Net</b></h4><br>
                    </div>
                    <div class="col-lg-2">
                      <br><br>
                      <h4 class="mb-0">: {{lblFDInNet}}</h4><br>
                      <h4 class="mb-0">: {{lblFDOutNet}}</h4> <br>
                      <h4 class="mb-0">: {{lblFOOPInNet}}</h4><br>
                      <h4 class="mb-0">: {{lblFOOPOutNet}}</h4><br>
                      <h4 class="mb-0">: {{lblFRemainingInNet}}</h4><br>
                      <h4 class="mb-0">: {{lblFRemainingOutNet}}</h4><br>
                      <h4 class="mb-0">: {{lblFOOPRemainingInNet}}</h4><br>
                      <h4 class="mb-0">: {{lblFOOPRemainingOutNet}}</h4><br>
                      <h4 class="mb-0">: {{lblCPOutNet}}</h4><br>
                    </div>
                  </div> -->
                <div class="row pt-3">

                  <div class="col-lg-12 pt-2">

                    <br>

                    <br>
                    <h4 class="mb-1 customThemeClass"><b>Other Covered Services</b></h4><br>
                    <div class="table-responsive table-bordered">
                      <table class="table mb-0">
                        <tbody>
                          <tr *ngFor="let table of otherServiceTableData">
                            <td>
                              {{table.serviceName}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div><br>
                    <h4 class="mb-1"><b>Free Services</b></h4><br>
                    <div class="table-responsive table-bordered">
                      <table class="table mb-0">
                        <tbody>
                          <tr>
                            <td>
                              Alcoholism
                            </td>
                          <tr>
                            <td>
                              Home Health
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Smoking Cessation
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div><br>
                  </div>
                </div>

                <br>
              </mat-expansion-panel>

              <mat-expansion-panel [hidden]="isLoading" [expanded]="true">
                <div [innerHtml]="KisshtHtml"></div>

              </mat-expansion-panel>
            </mat-accordion>
            <!-- </div> -->
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <!-- </div> -->


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="pverifyId" mat-button class="buttonColor" [disabled]='eligibilityMedicareForm.invalid'
    (click)='verify()'>Verify
  </button>
  <button *ngIf="zermidName" mat-button class="buttonColor" [disabled]='eligibilityMedicareForm.invalid'
    (click)='zermidVerify()'>Verify
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
