<mat-dialog-content class="mat-typography">
    <div class="modal-header">
        <h1 class="modal-title"><b>Patient List</b></h1>
        <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
    </div>
    <div class="card card-body">
        <div class="col-12" *ngIf="isLoading">
            <div class="d-flex justify-content-center" *ngIf="isLoading">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
        </div>
        <div class="col-lg-12 content table-responsive" *ngIf="!isLoading">
            <ng-container>
                <app-patient-list-model-table [tableData]="practiceListFax"></app-patient-list-model-table>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>