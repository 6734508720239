import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppNotification } from './app-notification.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, retry, switchMap, tap } from 'rxjs/operators';
import { environment } from 'projects/patient/src/environments/environment';
import { NotificationService } from 'projects/order/src/app/order-proxy/order-management/order';
import { PushNotificationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/push-notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    readonly entityPath = 'notifications';
    loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);

    constructor(private httpClient: HttpClient, private notifyService: PushNotificationService) {
    }

    getAll(userId: string): Observable<any[]> {
        this.loadingSubject.next(true);
        return this.notifyService.getallNotifiaction(userId);
    }

    /**
     * Util for showing native Notifications
     * @param noti:  {
     *    title: 'NGX WebApp Notification',
     *   options: {
     *     body: payload.message,
     *     icon: 'assets/icons/icon-72x72.png',
     *     data: {
     *       click_url: '/index.html',
     *     },
     *   },
     * }
     */
    async showNativeNotification(noti: { title: string; options?: Partial<NotificationOptions> }) {

        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const notification = new Notification(noti.title, noti.options);
            notification.onclick = () => {
            };
        }
        //}
    }

    private showReminderTabSource = new BehaviorSubject<string | null>(null);
  showReminderTab$ = this.showReminderTabSource.asObservable();

  toggleReminderTab(tabId: string) {
    this.showReminderTabSource.next(tabId);
  }
}