import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CreateUpdateItemEligibilityRulesDTO, RulesValidityDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { ItemEligibilityRulesService } from 'projects/order/src/app/order-proxy/order-management/inventory/item-eligibility-rules.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-item-insurance-view',
  templateUrl: './item-insurance-view.component.html',
  styleUrls: ['./item-insurance-view.component.scss']
})
export class ItemInsuranceViewComponent implements OnInit {
  step: boolean = false;
  isLoading: boolean = false;
  dataSource: MatTableDataSource<any>;
  insuranceSearchForm: FormGroup;
  insuranceForm: FormGroup;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'Options','insuranceName', 'eligibleQuantity', 'eligibleDays', 'status',];
  itemTableData: any[] = [];
  ruleId: string = "00000000-0000-0000-0000-000000000000";
  constructor(
    private itemService: ItemEligibilityRulesService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ItemInsuranceViewComponent>,
    private number: NumberValidators,
    private textValidators: TextValidators,
    private toastr: ToastrService,
    public authDialogRef: MatDialogRef<ItemInsuranceViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemId: string }
  ) { }

  ngOnInit(): void {
    // searchFormList
    this.insuranceSearchForm = this.formBuilder.group({
      insuranceNameSearch: new FormControl(""),
      eligibleQuantitySearch: new FormControl(""),
      eligibleDaySearch: new FormControl(""),
      statusSearch: new FormControl(""),
    })
    this.insuranceForm = this.formBuilder.group({
      txtInsuaranceName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtDays: new FormControl("", [Validators.required, this.number.isNumberCheck,]),
      txtQty: new FormControl("", [Validators.required, this.number.isNumberCheck, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      chkActive: new FormControl(""),
    })
    // valueChanges for filter
    this.insuranceSearchForm.valueChanges.subscribe((value) => {
      let filter = {
        insuranceNameSearch: value.insuranceNameSearch.trim().toLowerCase(),
        eligibleQuantitySearch: value.eligibleQuantitySearch.trim().toLowerCase(),
        eligibleDaySearch: value.eligibleDaySearch.trim().toLowerCase(),
        statusSearch: value.statusSearch.trim().toLowerCase(),
      };


      if (this.insuranceSearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
    });
    this.data.itemId !== "" && this.data.itemId !== undefined && this.data.itemId !== null && this.getInsuranceTable(this.data.itemId);
  }

  // save insurance get
  saveInsurance() {
    let itemDetails;

    if (this.data.itemId !== "" || this.data.itemId !== undefined || this.data.itemId !== null) {
      setTimeout(() => {

        this.itemService.get(this.data.itemId).subscribe(itemResponse => {

          itemDetails = { ...itemResponse };
          this.SaveItemInsurance(itemDetails);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }, 1);
    }
  }
  // save and update insurance
  SaveItemInsurance(itemDetails) {
    let itemData: CreateUpdateItemEligibilityRulesDTO;
    let currentItems: RulesValidityDTO[] = itemDetails?.rulesValidities;
    if (this.ruleId != "00000000-0000-0000-0000-000000000000") {
      currentItems = currentItems.filter(rulesValidities => { return rulesValidities && rulesValidities.ruleId != this.ruleId })
      currentItems = [...currentItems, {
        ruleId: this.ruleId,
        insuranceName: this.insuranceForm.value.txtInsuaranceName,
        eligibleQuantity: +this.insuranceForm.value.txtQty,
        eligibleDays: +this.insuranceForm.value.txtDays,
        eligibleMonths: "",
        status: this.insuranceForm.value.chkActive == true ? "Active" : "Inactive",
        startDate: "",
        endDate: "",
      }]


    } else {
      currentItems = [...currentItems, {
        ruleId: this.ruleId,
        insuranceName: this.insuranceForm.value.txtInsuaranceName,
        eligibleQuantity: +this.insuranceForm.value.txtQty,
        eligibleDays: +this.insuranceForm.value.txtDays,
        eligibleMonths: "",
        status: this.insuranceForm.value.chkActive == true ? "Active" : "Inactive",
        startDate: "",
        endDate: "",
      }]
    }
    itemData = {
      // locationId: itemDetails.locationId,
      locationId: "cbe",
      itemCode: itemDetails.itemCode,
      itemName: itemDetails.itemName,
      price: itemDetails.price,
      rulesValidities: currentItems
    }

    this.data.itemId != "" && this.itemService.update(this.data.itemId, itemData).subscribe(response => {


      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.dialogRef.close();
      this.ruleId = "00000000-0000-0000-0000-000000000000";
      // this.getTableData(this.orderId)

      //
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // Insurance Table
  getInsuranceTable(itemId) {
    this.itemService.get(itemId).subscribe(response => {

      this.itemTableData = [];
      response && response.rulesValidities.forEach(element => {
        this.itemTableData.push({
          ruleId: element.ruleId,
          insuranceName: element.insuranceName,
          eligibleQuantity: element.eligibleQuantity,
          eligibleDays: element.eligibleDays,
          eligibleMonths: element.eligibleMonths,
          status: element.status,
          startDate: element.startDate,
          endDate: element.endDate,

        });

        this.dataSource = new MatTableDataSource(this.itemTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

      })

    })

  }

  // insurance view edit
  ViewItem(data) {
    this.step = !this.step;
    this.ruleId = data.ruleId;
    this.itemService.get(this.data.itemId).pipe(map(response => {
      let selectedInsurance: RulesValidityDTO;
      response.rulesValidities.forEach(element => {
        if (element?.ruleId == data.ruleId) { selectedInsurance = element; }
      });
      return selectedInsurance;
    })).subscribe(response => {
      this.insuranceForm.patchValue({
        txtInsuaranceName: response.insuranceName,
        txtDays: response.eligibleDays,
        txtQty: response.eligibleQuantity,
        chkActive: response.status == "Active" ? 1 : 0

      })
    })
  }

  // delete insurance
  deleteItem(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.itemService.deleteItemRules(this.data.itemId, data.ruleId).subscribe(response => {
          this.itemTableData.forEach(element => {
            if (data.id == element?.id) {
              let index = this.itemTableData.indexOf(element, 0)
              this.itemTableData.splice(index, 1)
            }
          });
          this.getInsuranceTable(this.data.itemId);
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
    this.reset();
  }

  // filter records from table list
  filtertable(filter) {
    let insuranceNameSearch = filter.insuranceNameSearch;
    let eligibleQuantity = filter.eligibleQuantitySearch;
    let eligibleDaySearch = filter.eligibleDaySearch;
    let statusSearch = filter.statusSearch;

    this.itemTableData = [];
    this.itemService.searchItemRulesByIdAndSInsuranceNameAndIEligibleQuantityAndIEligibleDaysAndSStatus(this.data.itemId, insuranceNameSearch, eligibleQuantity, eligibleDaySearch, statusSearch).subscribe(data => {

      this.itemTableData = [];
      data?.items.forEach(element => {
        this.itemTableData.push({
          ruleId: element.ruleId,
          insuranceName: element.insuranceName,
          eligibleQuantity: element.eligibleQuantity,
          eligibleDays: element.eligibleDays,
          eligibleMonths: element.eligibleMonths,
          status: element.status,
          startDate: element.startDate,
          endDate: element.endDate,
        });
        this.dataSource = new MatTableDataSource(this.itemTableData);
        this.dataSource.sort = this.sort;
        setTimeout(() => this.dataSource.paginator = this.paginator);
      })
    })
  }

  // reset form
  reset() {
    this.insuranceForm.patchValue({
      txtInsuaranceName: "",
      txtDays: "",
      txtQty: "",
      chkActive: "",
    })
    this.insuranceForm.markAsUntouched();
    this.insuranceForm.updateValueAndValidity();
    this.ruleId = "00000000-0000-0000-0000-000000000000";
  }

  numberOnly(event): boolean {


    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
