import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import { ClaimStatusDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClaimDataDTO, ClaimEventDTO } from '../billing-proxy/billing-management/posting/dto';
import { ClaimInformationsService } from '../billing-proxy/billing-management/rcm/billing-management/posting/claim-informations.service';
import { ClaimDTO } from '../billing-proxy/billing-management/rcm/billing-management/posting/dto/models';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/claim-process.service';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-claim-event-view',
  templateUrl: './claim-event-view.component.html',
  styleUrls: ['./claim-event-view.component.scss']
})
export class ClaimEventViewComponent implements OnInit, AfterViewInit {
  @Input() claimEventData: ClaimEventDTO[] = [];
  @Input() claimData: ClaimDTO;
  @Input() claimId: string = '';
  arrDisplayedColumns: string[] = ["eventID", "eventDT", "usrName", "rawMessage", "status"];
  isLoading: boolean = true;
  strPageType: string = 'claimStatusList';
  claimTableData: ClaimEventDTO[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  drpClaimStatus: ClaimStatusDTO[] = [];
  strStatus: string = '';
  strCurrentStatus: string = '';
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<ClaimEventViewComponent>,
    private billingMasterDropdownService: BillingMasterDropdownService,
    private tableService: TableService,
    private claimProcessService: ClaimProcessService,
    private claimInformationsService: ClaimInformationsService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { id: string; }
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    const BillingDropdownsInput: BillingDropdowns[] = [
      BillingDropdowns.ClaimStatus,
    ];
    this.billingMasterDropdownService
      .getBillingDropdownsByInput(BillingDropdownsInput)
      .subscribe(
        (response) => {
          this.drpClaimStatus = response?.claimStatus?.filter(element => "All".indexOf(element?.claimStatus) !== 0) ?? [];
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.isLoading = true;

    this.claimId = this.data?.id ?? "";
    this.claimId &&
      this.claimInformationsService.get(this.claimId).subscribe(response => {
        this.claimData = response;
        this.claimEventData = response?.lstClearingHouseUpdates[0]?.lstBatches[0]?.claims.claimData[0]?.claimEvents?.claimEvent ?? [];
        this.claimTableData = this.claimEventData?.reverse() ?? [];
        this.dataSource = new MatTableDataSource(this.claimTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
        (err) => {
          this.isLoading = true;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
  }

  ngAfterViewInit(): void {


  }
  //! Update claim status in claim data
  updateClaimStatus(strStatus: string) {
    try {
      this.isShowSpinner = true;
      this.claimProcessService
        .updateClaimStatusByClaimIdAndClaimStatus(this.claimId, strStatus).subscribe(
          (response) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            this.dialogRef.close();
            this.tableService.setClaimStatusSaveTable(true);
            this.isShowSpinner = false;
          },
          (err) => {
            this.isShowSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    } catch (error) {
      console.warn(error);
    }
  }

}
