import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownDTO, PayorLevelDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PayerService, PverifyPayersService } from '../patient-proxy/patient';
import { CreateUpdateAllVerificationDTO, CreateUpdatePolicyPverifyDTO, PatientPersonalDTO, PatientPolicyDTO } from '../patient-proxy/patient-optimization/dto';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { PatientPolicyService } from '../patient-proxy/patient-optimization/patient-policy.service';
import { PatientVerificationService } from '../patient-proxy/patient-optimization/patient-verification.service';
import { ToastrService } from "ngx-toastr";
import { error } from 'console';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { VerificationStatus, VerificationStatusV1 } from '../patient-proxy/patient/enumeration-data/verification-status.enum';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-patient-pverify-tab',
  templateUrl: './patient-pverify-tab.component.html',
  styleUrls: ['./patient-pverify-tab.component.scss'],
  providers: [DatePipe, ListService]
})
export class PatientPverifyTabComponent implements OnInit {
  isLoading: boolean = false;
  isVerificationLoading: boolean=false;
  eligibilityMedicareForm: FormGroup;
  isSave:boolean=false;
  isSummaryLoading:boolean=false;
  // patientId: string = "";
  public strPageType: string = "pverifyEligibilityMedicare";
  public strPageType1: string = "pverifyDeductibleBenefits";
  public strPageType2: string = "pverifyCoInsuranceBenefits";
  isShown: boolean = false;
  lblState: string = "";
  lblCity: string = "";
  lblZip: string = "";
  lblSubscriberName: string = "";
  lblMemberId: string = "";
  lblPatientDob: string = "";
  lblPatientGender: string = "";
  lblPatientAddress: string = "";
  lblPlanEndDate: string = "";
  lblPlanBeginDate: string = "";
  lblEligibilityDate: string = "";
  lblIDInNet: string = "";
  lblIDOutNet: string = "";
  lblIDOOPInNet: string = "";
  lblIDOOPOutNet: string = "";
  lblFDInNet: string = "";
  lblFDOutNet: string = "";
  lblFOOPInNet: string = "";
  lblFOOPOutNet: string = "";
  lblCPInNet: string = "";
  lblCPOutNet: string = "";
  changeHealthCareData: any;
  pVerifyData: any;

  lblIRemainingInNet: string = "";
  lblIRemainingOutNet: string = "";
  lblIOOPRemainingInNet: string = "";
  lblIOOPRemainingOutNet: string = "";
  lblDRemainingInNet: string = "";
  lblDRemainingOutNet: string = "";
  lblDOOPRemainingInNet: string = "";
  lblDOOPRemainingOutNet: string = "";
  lblFRemainingInNet: string = "";
  lblFRemainingOutNet: string = "";
  lblFOOPRemainingInNet: string = "";
  lblFOOPRemainingOutNet: string = "";
  coverageSummaryTableData: any[] = [];
  deductibelBenefitsTableData: any[] = [];
  coInsuranceBenefitsTableData: any[] = [];
  otherServiceTableData: any[] = [];
  eligibilityAlerts: any[] = [];
  payorLevel: PayorLevelDTO[] = [];
  drpProviders: any[] = [];
  drpPlan: any[] = [];
  drpPayerId: any[] = [];
  eligibilityMode:number;
  policyGuid: string = defaultGuid;

  chkRadioButtonValue: "PVerify" | "Zirmed" = "PVerify";
  drpPolicies: PatientPolicyDTO[] = [];
  subscription$: Subscription[] = [];

  patientId: string = defaultGuid;
  policyId: string = defaultGuid;
  verificationStatus: any;
  dtSubscriberInfo: any = {   responsive: true,
    language: {
      emptyTable: 'No records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '100px',
    scrollX: true,
    info: false,
    bFilter: false
  };


  constructor(
    private formBuilder: FormBuilder,
    private number: NumberValidators,
    private text: TextValidators,
    private payerService: PayerService,
    private planService: PverifyPayersService,
    private patientVerification: PatientVerificationService,
    private datePipe: DatePipe,
    private Table: TableService,
    private providerList: MasterProviderService,
    private patientDropdownService: PatientMasterDropdownService,
    public list: ListService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PatientPverifyTabComponent>,
    private communicationService: CommunicationService,
    public patientPersonalService: PatientPersonalService,
    public patientPolicy: PatientPolicyService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string, documentId:string,policyId: string, selectedNode?:string;payerId?:string }
     = { patientId: defaultGuid,documentId:defaultGuid, policyId: defaultGuid , selectedNode:'',payerId:defaultGuid}
  ) { }

  ngOnInit(): void {
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.policyId = this.data?.policyId ?? defaultGuid;
    // ! Formgroups with validations
    this.eligibilityMedicareForm = this.formBuilder.group({
      txtNpi: new FormControl('', [this.number.isNumberCheck, Validators.required]),
      txtProviderName: new FormControl('', [Validators.required]),
      txtEligibilityDate: new FormControl(new Date(), [Validators.required]),
      // txtFirstName: new FormControl('', [this.text.isTextCheck, Validators.required]),
      // txtLastName: new FormControl('', [this.text.isTextCheck, Validators.required]),
      txtFirstName: new FormControl('', [Validators.required]),
      txtLastName: new FormControl('', [Validators.required]),
      txtSuffix: new FormControl(''),
      txtMemeberId: new FormControl('', [Validators.required]),
      txtDob: new FormControl('', [Validators.required]),
      drpPayor: new FormControl('', [Validators.required]),
      txtPayerName: new FormControl('', [Validators.required]),
      txtPayerId: new FormControl('', [Validators.required]),
      rbtnPolicyType: new FormControl(this.chkRadioButtonValue),
      txtZirmedPayerName: new FormControl(''),
      txtZirmedPayerId: new FormControl(''),
    });
    this.isVerificationLoading = true;
    this.getPolicyDropdown();

  }
  zirmedVerify() {

  }
  verify() {
    // console.log(this.eligibilityMedicareForm.invalid);
    // console.log(this.eligibilityMedicareForm.value);
    // return false;
    this.isLoading = true;

    const pVerifyDetails: CreateUpdateAllVerificationDTO = {
      //  planType: this.drpPolicies?.filter(x => x?.id === this.eligibilityMedicareForm?.value?.drpPayor)?.[0]?.payorLevel ?? "",
      iMode : this.eligibilityMode?this.eligibilityMode: 0 ,
      planType: this.drpPolicies?.filter(x => x)?.[0]?.payorLevel ?? "",
      payerCode: this.eligibilityMedicareForm?.value?.txtPayerId,
      payerName: this.eligibilityMedicareForm?.value?.txtPayerName,
      providerLastName: this.eligibilityMedicareForm?.value?.txtProviderName,
      providerNpi: this.eligibilityMedicareForm?.value?.txtNpi,
      patientFirstName: this.eligibilityMedicareForm?.value?.txtFirstName,
      patientDob: this.eligibilityMedicareForm?.value?.txtDob === "" ? "" : this.datePipe.transform(this.eligibilityMedicareForm?.value?.txtDob, 'MM/dd/yyyy') ?? "",
      patientLastName: this.eligibilityMedicareForm?.value?.txtLastName,
      // memberID: this.eligibilityMedicareForm?.value?.txtMemeberId,
      dateOfService: this.eligibilityMedicareForm?.value?.txtEligibilityDate === "" ? "" : this.datePipe.transform(this.eligibilityMedicareForm?.value?.txtEligibilityDate, 'MM/dd/yyyy') ?? "",
      referenceId: "",
      location: "",
      practiceTypeCode: "18",
      zermidPayerName: 'string',
      memberId:this.eligibilityMedicareForm?.value?.txtMemeberId,
      docId:this.data.documentId,
      includeTextResponse: true,
      patientId: this.patientId,
      payerId:this.data.payerId,
      policyId: this.policyId,//this.policyGuid





    }
// console.log(pVerifyDetails);
  //   const pVerifyDetails: CreateUpdateAllVerificationDTO = {
  //     iMode: 3,
  // patientId: "eed73a32-3f48-6087-a3c4-3a0bcfd5f4ca",
  // planType: "primary",
  // payerCode: "UHC",
  // payerName: "Cigna",
  // zermidPayerName: "string",
  // providerNpi: "1760854442",
  // providerLastName: "provider_name",
  // patientFirstName: "johnOne",
  // patientDob: "18800102",
  // patientLastName: "doeOne",
  // memberId: "0000000000",
  // dateOfService: "06/05/2023",
  // referenceId: "",
  // location: "",
  // practiceTypeCode: "18",
  // includeTextResponse: true,
  // policyId: "44bfaed3-6f3b-38e0-5037-3a0c0fe8b9fe"
  //   }

    this.patientVerification.commonelgibilityCheckByInput(pVerifyDetails).pipe(map(response => {
      this.pVerifyData = response['pverify']
      this.changeHealthCareData = response['changehealth']
      this.isLoading = false;
    // if (response?.apiResponseMessage !== "Processed") {
    //   // Swal.fire({ title: 'Warning', html: 'Requested service not registered for the client', icon: 'warning', timer: 3000, timerProgressBar: true });
    //   this.toastr.warning('Requested service not registered for the client','Warning')
    //   this.eligibilityMedicareForm.enable()
    // }

    // if (response?.apiResponseCode != null) {
      this.verificationStatus=response.verificationStatus;
      this.isLoading = false;
      // this.isShown = true;
      this.coverageSummaryTableData = [];

      if(response['pverify']){
        const pVerifyData = response['pverify']
      if(pVerifyData.statusCode == 200){
         this.isSave=true;
      this.isShown = true;
      this.lblSubscriberName = pVerifyData?.demographicInfo?.subscriber?.fullName ?? "";
      this.lblState = pVerifyData?.demographicInfo?.subscriber?.state ?? "";
      this.lblCity = pVerifyData?.demographicInfo?.subscriber?.city ?? "";
      this.lblZip = pVerifyData?.demographicInfo?.subscriber?.zip ?? "";

       this.lblIDInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualDeductibleInNet?.value ?? "";
      this.lblIDOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualDeductibleOutNet?.value ?? "";
      this.lblIDOOPInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualOOP_InNet?.value ?? "";
      this.lblIDOOPOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualOOP_OutNet?.value ?? "";
      this.lblFDInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet?.value ?? "";
      this.lblFDOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyDeductibleOutNet?.value ?? "";
      this.lblFOOPInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyOOPInNet?.value ?? "";
      this.lblFOOPOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyOOPOutNet?.value ?? "";

      this.lblIRemainingInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value ?? "";
      this.lblIRemainingOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingOutNet?.value ?? "";
      this.lblIOOPRemainingInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingInNet?.value ?? "";
      this.lblIOOPRemainingOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingOutNet?.value ?? "";
      this.lblDRemainingInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet?.value ?? "";
      this.lblDRemainingOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet?.value ?? "";
      this.lblDOOPRemainingInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet?.value ?? "";
      this.lblDOOPRemainingOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet?.value ?? "";
      this.lblFOOPRemainingInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet?.value ?? "";
      this.lblFOOPRemainingOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet?.value ?? "";
      this.lblFRemainingOutNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet?.value ?? "";
      this.lblFRemainingInNet = pVerifyData?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet?.value ?? "";
      this.lblCPInNet = pVerifyData?.specialistOfficeSummary?.coInsInNet?.value ?? "";
      this.lblCPOutNet = pVerifyData?.specialistOfficeSummary?.coPayInNet?.value ?? "";
      this.lblMemberId = pVerifyData?.demographicInfo?.subscriber?.identification?.[2]?.code ?? "";
      this.lblPatientDob = pVerifyDetails?.patientDob;
      this.lblPatientGender = pVerifyData?.demographicInfo?.subscriber?.gender_R ?? "";
      this.lblPatientAddress = pVerifyData?.demographicInfo?.subscriber?.address1 ?? "";

      if (pVerifyData?.demographicInfo?.subscriber?.date !== null || pVerifyData?.demographicInfo?.subscriber?.date !== null) {
            // this.lblEligibilityDate = pVerifyData?.demographicInfo?.subscriber?.date?.[0]?.date ?? "";
            // this.lblPlanBeginDate = pVerifyData?.demographicInfo?.subscriber?.date?.[1]?.date ?? "";
            // this.lblPlanEndDate = pVerifyData?.demographicInfo?.subscriber?.date?.[2]?.date ?? "";
            this.lblEligibilityDate = pVerifyData?.planCoverageSummary?.effectiveDate;
            this.lblPlanBeginDate = pVerifyData?.planCoverageSummary?.effectiveDate;
            this.lblPlanEndDate = pVerifyData?.planCoverageSummary?.expiryDate;
          }
        this.isSummaryLoading=true;
             // Plan Coverage Summary binded after the verification
      this.coverageSummaryTableData.push({
        insurance: pVerifyData?.planCoverageSummary?.planName?.toLocaleLowerCase(),
        coverage: pVerifyData?.planCoverageSummary?.status,
        date: pVerifyData?.planCoverageSummary?.effectiveDate,
        expDate: pVerifyData?.planCoverageSummary?.expiryDate,
        groupNo: pVerifyData?.planCoverageSummary?.groupNumber,
        groupName: pVerifyData?.planCoverageSummary?.groupName,
      });
      this.Table.setCoverageSummaryTable(this.coverageSummaryTableData);
      this.isSummaryLoading=false;
      const coverageData: any[] = [];
      const coBenefitData: any[] = [];
      const deductibleData: any[] = [];
      const coInsuranceData: any[] = [];
      pVerifyData?.serviceDetails?.forEach(resp => {
        resp?.eligibilityDetails?.forEach(element => {
          element?.message?.length != 0 && this.eligibilityAlerts.push(element)
        });

        resp?.serviceName === "Health Benefit Plan Coverage" && coverageData?.push(resp);
        resp?.serviceName === "Emergency Services" && coBenefitData?.push(resp);
      });

      coverageData?.forEach(resp => {
        resp?.eligibilityDetails?.forEach(deductibleResp => {
          deductibleResp?.eligibilityOrBenefit === "Deductible" && deductibleData?.push(deductibleResp)
        });
      });

      coBenefitData?.forEach(coResp => {
            coResp?.eligibilityDetails?.forEach(coBenefitResp => {
              coBenefitResp?.eligibilityOrBenefit === "Co-Payment" && coInsuranceData?.push(coBenefitResp);
            });
            this.coInsuranceBenefitsTableData = [];
            this.otherServiceTableData = pVerifyData?.serviceDetails;
            coInsuranceData?.forEach(element => {
              this.coInsuranceBenefitsTableData.push({
                type: element?.eligibilityOrBenefit,
                amount: element?.monetaryAmount
              });
            });
          });

          // ! CoInsurance Table Binding
          this.Table.setCoBenefitTable(this.coInsuranceBenefitsTableData);

          return deductibleData;
      }  else if(pVerifyData.statusCode == 400){
          this.isShown = false;
          this.eligibilityMedicareForm.enable()
          this.isLoading = true;
          let data : any =  [];
          response['pverify'].errors.map(x=>{
           data.push(x.description.concat("\r\n"))
          })
          Swal.fire({
            icon: 'info',
            html: `<pre style="text-align: -webkit-left;">${data?.join('\r\n')}</pre>`,

          });
        }

        } else if (response['changehealth']){
          const changehealth = response['changehealth']


         if(changehealth.statusCode == 200){
          this.isShown = true;

          this.lblSubscriberName = (changehealth.subscriber.firstName ?? "") + " " + (changehealth.subscriber.lastName ?? "");
          // this.lblSubscriberName = changehealth.subscriber.firstName +" "+ changehealth.subscriber.lastName?? "";
          this.lblState = changehealth?.subscriber?.address?.state ?? "";
          this.lblCity = changehealth?.subscriber?.address?.city ?? "";
          this.lblZip = changehealth?.subscriber?.address?.postalCode ?? "";
          this.lblMemberId = changehealth?.subscriber?.memberId ?? "";
          this.lblPatientDob = changehealth?.subscriber?.dateOfBirth;
          this.lblPatientGender = changehealth?.subscriber?.gender?? "";
          this.lblPatientAddress = changehealth?.subscriber?.address?.address1 ?? "";

          changehealth.benefitsInformation.forEach(element => {

            if(element.code == 'C' && element.coverageLevelCode == 'IND' && element.timeQualifier == 'Calendar Year' && element.benefitAmount>0 ){

              this.lblIDInNet = element.benefitAmount

            } else{

            }
            if(element.code == 'C' && element.coverageLevelCode == 'FAM' && element.timeQualifier == 'Calendar Year' && element.benefitAmount>0 ){
              this.lblFDInNet = element.benefitAmount
            } else{

            }
            if(element.code == 'G' && element.coverageLevelCode == 'IND' && element.timeQualifier == 'Calendar Year' && element.benefitAmount>0 ){

              this.lblIDOOPInNet = element.benefitAmount
            } else{

            }
            if(element.code == 'G' && element.coverageLevelCode == 'FAM' && element.timeQualifier == 'Calendar Year' && element.benefitAmount>0 ){
              this.lblFOOPInNet = element.benefitAmount
            } else{

            }

            if(element.code == 'C' && element.coverageLevelCode == 'IND' && element.timeQualifier == 'Remaining' && element.benefitAmount>0 ){
              this.lblIRemainingInNet = element.benefitAmount
            } else{

            }
            if(element.code == 'C' && element.coverageLevelCode == 'FAM' && element.timeQualifier == 'Remaining' && element.benefitAmount>0 ){
              this.lblFRemainingInNet = element.benefitAmount
            } else{

            }

            if(element.code == 'G' && element.coverageLevelCode == 'IND' && element.timeQualifier == 'Remaining' && element.benefitAmount>0 ){
              this.lblIOOPRemainingInNet = element.benefitAmount
            } else{

            }
            if(element.code == 'G' && element.coverageLevelCode == 'FAM' && element.timeQualifier == 'Remaining' && element.benefitAmount>0 ){
              this.lblFOOPRemainingInNet = element.benefitAmount
            } else{

            }

            if(element.code == 'B' && element.benefitAmount>0 ){
              this.lblCPOutNet = element.benefitAmount
            } else{

            }

            if(element.code == 'A' && element.benefitPercent>0 ){
              this.lblCPInNet = element.benefitAmount
            } else{

            }
            if(element.additionalInformation != null){
                element.additionalInformation.forEach(element => {
                this.otherServiceTableData.push(element)
            });
              const unique = this.otherServiceTableData.filter((obj, index) => {
                return index === this.otherServiceTableData.findIndex(o => obj.description === o.description);
              });
              this.otherServiceTableData = unique
          }

          });
        } else if(changehealth.statusCode == 400){
            this.isShown = false;
            this.eligibilityMedicareForm.enable()
            this.isLoading = true;
            let data : any =  [];
            response['changehealth'].errors.map(x=>{
             data.push(x.description.concat("\r\n"))
            })
            Swal.fire({
              icon: 'info',
              html: `<pre style="text-align: -webkit-left;">${data?.join('\r\n')}</pre>`,

            });
        }



          // coBenefitData?.forEach(coResp => {
          //   coResp?.eligibilityDetails?.forEach(coBenefitResp => {
          //     coBenefitResp?.eligibilityOrBenefit === "Co-Payment" && coInsuranceData?.push(coBenefitResp);
          //   });
          //   this.coInsuranceBenefitsTableData = [];
          //   this.otherServiceTableData = pVerifyData?.serviceDetails;
          //   coInsuranceData?.forEach(element => {
          //     this.coInsuranceBenefitsTableData.push({
          //       type: element?.eligibilityOrBenefit,
          //       amount: element?.monetaryAmount
          //     });
          //   });
          // });



        }

    })).subscribe(resp => {

      this.deductibelBenefitsTableData = [];
      if(this.verificationStatus===VerificationStatus.done||this.verificationStatus===VerificationStatusV1.done){
      this.toastr.success('Verification Updated and Moved to Precert/Medicare', 'Success');
      }else{
        this.toastr.success('Verification Updated Successfully', 'Success');
      }
      // resp?.forEach(element => {
      //   this.deductibelBenefitsTableData?.push({
      //     type: element?.eligibilityOrBenefit,
      //     baseAmount: element?.monetaryAmount,
      //     remaining: element?.monetaryAmount,
      //     planDates: element?.date
      //   });

      // });

      // ! Deductible Benefit Table Binding
      this.Table.setDeductibleBenefitTable(this.deductibelBenefitsTableData);
      this.isLoading = false;
    }, err => {
      this.eligibilityMedicareForm.enable()
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message || 'Invalid Request',
      });
    });


    // this.patientVerification.commonelgibilityCheckByInput(pVerifyDetails).pipe(map(response => {
    //   // if (response?.apiResponseMessage !== "Processed") {
    //   //   // Swal.fire({ title: 'Warning', html: 'Requested service not registered for the client', icon: 'warning', timer: 3000, timerProgressBar: true });
    //   //   this.toastr.warning('Requested service not registered for the client','Warning')
    //   //   this.eligibilityMedicareForm.enable()
    //   // }

    //   // if (response?.apiResponseCode != null) {

    //   //   this.isLoading = false;
    //   //   this.isShown = true;
    //   //   this.coverageSummaryTableData = [];


    //   //   this.lblSubscriberName = response?.demographicInfo?.subscriber?.fullName ?? "";
    //   //   this.lblState = response?.demographicInfo?.subscriber?.state ?? "";
    //   //   this.lblCity = response?.demographicInfo?.subscriber?.city ?? "";
    //   //   this.lblZip = response?.demographicInfo?.subscriber?.zip ?? "";

    //   //   this.lblIDInNet = response?.hbpC_Deductible_OOP_Summary?.individualDeductibleInNet?.value ?? "";
    //   //   this.lblIDOutNet = response?.hbpC_Deductible_OOP_Summary?.individualDeductibleOutNet?.value ?? "";
    //   //   this.lblIDOOPInNet = response?.hbpC_Deductible_OOP_Summary?.individualOOP_InNet?.value ?? "";
    //   //   this.lblIDOOPOutNet = response?.hbpC_Deductible_OOP_Summary?.individualOOP_OutNet?.value ?? "";
    //   //   this.lblFDInNet = response?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet?.value ?? "";
    //   //   this.lblFDOutNet = response?.hbpC_Deductible_OOP_Summary?.familyDeductibleOutNet?.value ?? "";
    //   //   this.lblFOOPInNet = response?.hbpC_Deductible_OOP_Summary?.familyOOPInNet?.value ?? "";
    //   //   this.lblFOOPOutNet = response?.hbpC_Deductible_OOP_Summary?.familyOOPOutNet?.value ?? "";

    //   //   this.lblIRemainingInNet = response?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value ?? "";
    //   //   this.lblIRemainingOutNet = response?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingOutNet?.value ?? "";
    //   //   this.lblIOOPRemainingInNet = response?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingInNet?.value ?? "";
    //   //   this.lblIOOPRemainingOutNet = response?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingOutNet?.value ?? "";
    //   //   this.lblDRemainingInNet = response?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet?.value ?? "";
    //   //   this.lblDRemainingOutNet = response?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet?.value ?? "";
    //   //   this.lblDOOPRemainingInNet = response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet?.value ?? "";
    //   //   this.lblDOOPRemainingOutNet = response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet?.value ?? "";
    //   //   this.lblFOOPRemainingInNet = response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet?.value ?? "";
    //   //   this.lblFOOPRemainingOutNet = response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet?.value ?? "";
    //   //   this.lblFRemainingOutNet = response?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet?.value ?? "";
    //   //   this.lblFRemainingInNet = response?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet?.value ?? "";
    //   //   this.lblCPInNet = response?.specialistOfficeSummary?.coInsInNet?.value ?? "";
    //   //   this.lblCPOutNet = response?.specialistOfficeSummary?.coPayInNet?.value ?? "";
    //   //   this.lblMemberId = response?.demographicInfo?.subscriber?.identification?.[2]?.code ?? "";
    //   //   this.lblPatientDob = pVerifyDetails?.patientDob;
    //   //   this.lblPatientGender = response?.demographicInfo?.subscriber?.gender_R ?? "";
    //   //   this.lblPatientAddress = response?.demographicInfo?.subscriber?.address1 ?? "";

    //   //   if (response?.demographicInfo?.subscriber?.date !== null || response?.demographicInfo?.subscriber?.date !== null) {
    //   //     this.lblEligibilityDate = response?.demographicInfo?.subscriber?.date?.[0]?.date ?? "";
    //   //     this.lblPlanBeginDate = response?.demographicInfo?.subscriber?.date?.[1]?.date ?? "";
    //   //     this.lblPlanEndDate = response?.demographicInfo?.subscriber?.date?.[2]?.date ?? "";
    //   //   }


    //   //   // Plan Coverage Summary binded after the verification
    //   //   this.coverageSummaryTableData.push({
    //   //     insurance: response?.planCoverageSummary?.planName?.toLocaleLowerCase(),
    //   //     coverage: response?.planCoverageSummary?.status,
    //   //     date: response?.planCoverageSummary?.effectiveDate,
    //   //     expDate: response?.planCoverageSummary?.expiryDate,
    //   //     groupNo: response?.planCoverageSummary?.groupNumber,
    //   //     groupName: response?.planCoverageSummary?.groupName,
    //   //   });
    //   //   this.Table.setCoverageSummaryTable(this.coverageSummaryTableData);

    //   //   const coverageData: any[] = [];
    //   //   const coBenefitData: any[] = [];
    //   //   const deductibleData: any[] = [];
    //   //   const coInsuranceData: any[] = [];
    //   //   response?.serviceDetails?.forEach(resp => {
    //   //     resp?.eligibilityDetails?.forEach(element => {
    //   //       element?.message?.length != 0 && this.eligibilityAlerts.push(element)
    //   //     });

    //   //     resp?.serviceName === "Health Benefit Plan Coverage" && coverageData?.push(resp);
    //   //     resp?.serviceName === "Emergency Services" && coBenefitData?.push(resp);
    //   //   });

    //   //   coverageData?.forEach(resp => {
    //   //     resp?.eligibilityDetails?.forEach(deductibleResp => {
    //   //       deductibleResp?.eligibilityOrBenefit === "Deductible" && deductibleData?.push(deductibleResp)
    //   //     });
    //   //   });

    //   //   coBenefitData?.forEach(coResp => {
    //   //     coResp?.eligibilityDetails?.forEach(coBenefitResp => {
    //   //       coBenefitResp?.eligibilityOrBenefit === "Co-Payment" && coInsuranceData?.push(coBenefitResp);
    //   //     });
    //   //     this.coInsuranceBenefitsTableData = [];
    //   //     this.otherServiceTableData = response?.serviceDetails;
    //   //     coInsuranceData?.forEach(element => {
    //   //       this.coInsuranceBenefitsTableData.push({
    //   //         type: element?.eligibilityOrBenefit,
    //   //         amount: element?.monetaryAmount
    //   //       });
    //   //     });
    //   //   });
    //   //   // ! CoInsurance Table Binding
    //   //   this.Table.setCoBenefitTable(this.coInsuranceBenefitsTableData);
    //   //   return deductibleData;
    //   // }
    //   // else {
    //   //   Swal.fire(response?.apiResponseMessage);
    //   // }

    // }))
    // })).subscribe(resp => {
    //   this.deductibelBenefitsTableData = [];
    //   resp?.forEach(element => {
    //     this.deductibelBenefitsTableData?.push({
    //       type: element?.eligibilityOrBenefit,
    //       baseAmount: element?.monetaryAmount,
    //       remaining: element?.monetaryAmount,
    //       planDates: element?.date
    //     });

    //   });

    //   // ! Deductible Benefit Table Binding
    //   this.Table.setDeductibleBenefitTable(this.deductibelBenefitsTableData);
    //   this.isLoading = false;
    // }, err => {
    //   this.eligibilityMedicareForm.enable()
    //   this.isLoading = false;
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'info',
    //     text: data?.error?.error?.message,
    //   });
    // });


  }

  valueChangeLoad() {
    this.isLoading = false
    const selectedPolicy = this.drpPolicies?.filter(x => x?.id)?.[0] ?? null;

    if (selectedPolicy) {
      this.policyGuid = selectedPolicy?.policyId ?? '';
      this.eligibilityMode = selectedPolicy.eligibilityMode
      this.eligibilityMedicareForm.patchValue({
        txtMemeberId: selectedPolicy?.policyNo ?? '',
        drpPayor: (selectedPolicy?.payorId ?? '') + " " + '/' + " " + (selectedPolicy?.payorName ?? ''),
        // drpPayor:selectedPolicy?.payorId+" " + '/'+" "+selectedPolicy?.payorName?? '',
        // txtPayerId: selectedPolicy?.pVerifyPayorId ?? "",
        // txtPayerName: selectedPolicy?.pVerifyPayorName ?? "",
        txtPayerId: selectedPolicy?.payorId ?? '',
        txtPayerName: selectedPolicy?.payorName ?? '',
        txtZirmedPayerName: selectedPolicy?.zirmedPayorName ?? '',
        txtZirmedPayerId: selectedPolicy?.zirmedPayorId ?? '',
      })

      // this.eligibilityMedicareForm.disable();
    }
  }

  valueChangeLoadDropdown(id: string) {
    this.isLoading = false
    const selectedPolicy = this.drpPolicies?.filter(x => x?.id === id)?.[0] ?? null;
    if (selectedPolicy) {
      this.policyGuid = selectedPolicy?.policyId ?? '';
      this.eligibilityMedicareForm.patchValue({
        txtMemeberId: selectedPolicy?.policyNo ?? '',
        // txtPayerId: selectedPolicy?.pVerifyPayorId ?? "",
        // txtPayerName: selectedPolicy?.pVerifyPayorName ?? "",
        txtPayerId: selectedPolicy?.payorId ?? '',
        txtPayerName: selectedPolicy?.payorName ?? '',
        txtZirmedPayerName: selectedPolicy?.zirmedPayorName ?? '',
        txtZirmedPayerId: selectedPolicy?.zirmedPayorId ?? '',
      })
      // this.eligibilityMedicareForm.disable();
    }
  }




  //To get Details in Electronic Eligibilty Popup
  getPolicyDropdown() {


    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.PayorLevels]

    //To patch the Patient Details in the Electronic Eligibility Popup
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response?.payorLevels;
      // this.planDropdown();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientDropdown);

    //To patch the Provider Details in the Electronic Eligibility Popup
    const providerList = (query: any) => this.providerList.getList(query);
    const providerDropdown = this.list.hookToQuery(providerList).subscribe(response => {

      response?.items?.forEach(element => {
        this.drpProviders?.push({
          id: element?.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi,
          lastName: element?.lastName
          // providerId: element?.providerId,
        });
      });
      this.getPatientDetailsById();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(providerDropdown);

    //To patch the Payer Details in the Electronic Eligibility Popup
    // const payerDropdown = this.payerService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
    //   this.drpPayerId = [];
    //   response?.items?.forEach(element => {
    //     this.drpPayerId.push({
    //       id: element?.id,
    //       name: element?.payerId +" "+ ' / ' + " "+element?.payerName,
    //       payer: element?.payerId
    //     });
    //   });
    // })
    // this.subscription$.push(payerDropdown);

    const patientPolicies = this.patientPolicy.getPatientPolicyByPatientPolicyIdByPatientIdAndPolicyId(this.data.patientId,this.data.policyId).subscribe(response => {


      this.drpPolicies = response ?? [];
      this.valueChangeLoad();
      this.isVerificationLoading = false;
    }, err => {
      this.isVerificationLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientPolicies);
    // this.eligibilityMedicareForm.disable()
    this.valueChangeLoad()
  }

  // Get Patient Details By Id Function
  getPatientDetailsById() {
    if (this.data.patientId !== "" && this.data.patientId !== null && this.data.patientId !== undefined) {
      const storeSubscription = this.patientPersonalService.get(this.data?.patientId).subscribe(response => {

        const selectedPatientPersonal: PatientPersonalDTO = response;
        //To patch the Patient Details in the Electronic Eligibility Popup
        selectedPatientPersonal !== null && selectedPatientPersonal !== undefined && this.eligibilityMedicareForm.patchValue({
          txtFirstName: selectedPatientPersonal?.firstName ?? '',
          txtLastName: selectedPatientPersonal?.lastName ?? '',
          txtSuffix: selectedPatientPersonal?.suffix ?? '',
          txtDob: selectedPatientPersonal?.dateOfBirth === null ? "" : selectedPatientPersonal?.dateOfBirth,
          txtNpi: this.drpProviders?.filter(val => {
            return val?.shortCodeId === selectedPatientPersonal?.providerId
          })[0]?.npi ?? '',

          txtProviderName: this.drpProviders?.filter(val => {
            return val?.shortCodeId === selectedPatientPersonal?.providerId
          })[0]?.lastName ?? '',
        });

        this.drpProviders?.filter(val => {
          return val?.shortCodeId === selectedPatientPersonal?.providerId
        })[0]?.npi ?? '';


      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(storeSubscription);
    }

  }
  planDropdown(): void {
    const planDropdown = this.planService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpPlan = [];

      response?.items?.forEach(element => {
        this.drpPlan.push({
          id: element?.id,
          name: element?.payerName,
          code: element?.payerCode,
          payorId: element?.shortCodeId,
          payerCode: element?.payerCode
        });
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(planDropdown)
  }
  close(){
    if(this.isShown){
      // this.dialog.closeAll();
      this.dialogRef.close(this.isSave);
      this.communicationService.triggerFunctionCall(this.data.selectedNode);
    }
  }
  // check(){
  //   Object.keys(this.eligibilityMedicareForm.controls).forEach(field => {
  //     const control = this.eligibilityMedicareForm.get(field);
  //     if (control && control.invalid) {
  //       console.log(`${field} is invalid: ${control.errors}`);
  //     }
  //   });
  // }
}
