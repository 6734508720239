import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { Observable, Subscription } from 'rxjs';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { dtCalllogTableOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResupplyStausList } from '../order-proxy/order-management/order-optimization/resupply-staus-list.enum';
import { AgingDaysFilter } from '../order-proxy/order-management/order-optimization/aging-days-filter.enum';
import { ResupplyFilterList } from '../order-proxy/order-management/order-optimization/resupply-filter-list.enum';
import { CreateUpdateResupplyStatusInactiveSuppliesDTO, FilterResupply } from '../order-proxy/order-management/order-optimization/dto/models';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { map, startWith } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-resupply',
  templateUrl: './resupply.component.html',
  styleUrls: ['./resupply.component.scss']
})
export class ResupplyComponent implements OnInit {
  selectedOption: number;
  dtCalllogTableOptions = dtCalllogTableOptions;
  isLoading: boolean;
  isPatientLoading: boolean = false;
  resupplyPatientForm: FormGroup;
  AgingDays = AgingDaysFilter;
  ResupplyTypes = ResupplyStausList;
  activeTab: ResupplyStausList = this.ResupplyTypes.CurrentSupplies;
  calendarLocale: LocaleConfig;
  selectedRange: any = { startDate: null, endDate: null };
  selectedRangeV1: any = { startDate: null, endDate: null };
  ranges: any;
  suppliesForm: FormGroup;
  panelOpenState: boolean = false;
  mmOrderServiceSubcribtion: Subscription;
  mmOrderInActiveSuppliesSubcribtion: Subscription;
  NextcallDate: string;
  fromDate: any = null;
  toDate: any = null;
  fromDateV1: any = null;
  toDateV1: any = null;
  chartNo: string;
  patientName: string;
  agingDays: number;
  tableDetails: any;
  aboveNinetyDaysLst:any;
  patientId: string;
  InactiveListData: any;
  SelectAllOption = false;
  lstAgingDays: any = [];
  calllogTableData: any;
  resupplyActiveTab: number;
  ResupplyFilter: ResupplyFilterList;
  InactivetableDetails: any;
  dateRangStatus: boolean = false;
  isChangeTab:boolean = false;
  lstUsers: UserByRoleDTO[] = [];
  subscription$ : Subscription[]=[];
  selectedCSRList: string[] = null;
  CSRSelectAllOption = false;
  public drpCSR: CSRDrpOptions[] = [];
  name:string;
  sesUserId:string;
  isAdmin:boolean = false;
  public filteredUsers: Observable<UserByRoleDTO[]> | undefined;
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private mmOrderService: MmOrderService,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private userService: UserService,
  ) {
    this.communicationService.ResupplySubject$.subscribe((isActive) => {
      switch (isActive) {
        case 1:
          this.fnSearchInActiveSupply();
          break;
        case 2:
          this.fnSearchSupply();
          break;
        default:
          this.fnSearchSupply();
      }
    });
  }


  ngOnInit(): void {
    this.sesUserId = localStorage.getItem("userId") ?? "";
    this.InitializationForms();
    this.loadCSRUsers();
    this.resupplyActiveTab = 0;

    this.lstAgingDays = [
      { id: this.AgingDays.All, agingDays: 'All' },
      { id: this.AgingDays.Today, agingDays: 'Today' },
      { id: this.AgingDays.CurrentWeek, agingDays: '0-7 Days' },
      { id: this.AgingDays.Thirtydays, agingDays: '0-30 Days' },
      { id: this.AgingDays.Sixtydays, agingDays: '31-60 Days' },
      { id: this.AgingDays.Ninty, agingDays: '61-90 Days' },
    ];

    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'MM/DD/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };

    this.selectedRange = null;


    this.fromDateV1 = this.convertISODateStringToCustomFormat(
      this.getCTStartOfMonth()
    )
    this.toDateV1 = this.convertISODateStringToCustomFormat(
      this.ctDateNow()
    );

    this.suppliesForm.patchValue({
      txtNextCallDateV1: this.getCTStartOfMonth() + '-' + this.ctDateNow(),
      txtSupplyDate:this.ctDateNow()
    });

    this.selectedRangeV1.startDate = this.getCTStartOfMonth(),
    this.selectedRangeV1.endDate = this.ctDateNow()
    this.convertISODateStringToCustomFormat(this.getCTStartOfMonth()) + '-' + this.convertISODateStringToCustomFormat(this.ctDateNow());

    if(this.activeTab === this.ResupplyTypes.CurrentSupplies){
      this.suppliesForm.patchValue({
        drpAgingDays : this.AgingDays.CurrentWeek
      })
    }
    setTimeout(() => {
      this.fnSearchSupply();
    },500);

  }
  InitializationForms() {
    this.suppliesForm = this.fb.group({
      txtNextCallDate: new FormControl(),
      txtNextCallDateV1: new FormControl(),
      txtChartId: new FormControl(''),
      patientName: new FormControl(''),
      drpAgingDays: new FormControl(''),
      isSelectAll: new FormControl(0),
      txtCSRFilter:new FormControl(''),
      drpCSR:new FormControl(''),
      txtSupplyDate:new FormControl(''),

    });
    this.resupplyPatientForm = this.fb.group({
      patientAmount: new FormControl(0),
      insAmount: new FormControl(0),
      totalAmount: new FormControl(0),
      overPaid: new FormControl(0),
      nextCallDate: new FormControl('-'),
      newsetupDate: new FormControl('-'),
      practitionerName: new FormControl('-'),
      rxDate: new FormControl('-'),
      email: new FormControl('-'),
      homeNo: new FormControl('-'),
    });
  }

  toggle() {
    this.panelOpenState = true;
  }
  toggleV2() {
    this.panelOpenState = false;
  }
  changeTab(tab: number) {
    let resupplyActiveTab: number;
    this.isChangeTab =true;
    switch (tab) {
      case this.ResupplyTypes.CurrentSupplies:
        this.activeTab = this.ResupplyTypes.CurrentSupplies;
        resupplyActiveTab = 0;
        break;
      case this.ResupplyTypes.OverDueSupplies:
        this.activeTab = this.ResupplyTypes.OverDueSupplies;
        resupplyActiveTab = 1;
        break;
      case this.ResupplyTypes.InactiveSupplies:
        this.activeTab = this.ResupplyTypes.InactiveSupplies;
        resupplyActiveTab = 2;
        break;
      case this.ResupplyTypes.ArchiveSupplies:
        this.activeTab = this.ResupplyTypes.ArchiveSupplies;
        resupplyActiveTab = 3;
        break;
        case this.ResupplyTypes.Above90Days:
        this.activeTab = this.ResupplyTypes.Above90Days;
        resupplyActiveTab = 4;
        break;
      default:
        throw new Error('Invalid tab name');
    }
    this.resupplyPatientForm.reset();
    setTimeout(() => {
      this.isPatientLoading = true;
    }, 100); // Adjust the delay as needed
    this.calllogTableData = [];
    setTimeout(() => {
      this.isPatientLoading = false;
    }, 100); // Adjust the delay as needed
    this.resupplyActiveTab = resupplyActiveTab;
    this.fnFormReset();
    switch (tab) {
      case this.ResupplyTypes.InactiveSupplies:
        this.fnSearchInActiveSupply();
        break;
        case this.ResupplyTypes.Above90Days:
        this.fnAboveNinetyDays();
        break;
      default:
        this.fnSearchSupply();
    }
  }

  fnFormReset() {
    setTimeout(() => {
      this.isChangeTab =false;
    }, 500);
    this.fromDate = null;
    this.toDate = null;
    this.fromDateV1 = this.convertISODateStringToCustomFormat(
      this.getCTStartOfMonth()
    )
    this.toDateV1 = this.convertISODateStringToCustomFormat(
      this.ctDateNow()
    );

    let agingDays;
    if(this.activeTab == this.ResupplyTypes.CurrentSupplies){
      agingDays = this.AgingDays.CurrentWeek
    }else{
      agingDays = ''
    }
    this.suppliesForm.patchValue({
      patientName:'',
      drpAgingDays:agingDays,
      txtChartId:'',
      isSelectAll: 0,
      txtSupplyDate:this.ctDateNow()
    });
    if (localStorage.getItem('roleName') !== 'admin') {
      this.suppliesForm.patchValue({ drpCSR: [this.sesUserId] });
    }else{
        this.onCSRSelectionChange({ value: 'selectAll' } as MatSelectChange);
    }

    // Ensure selectedRangeV1 is initialized
    this.selectedRangeV1 = this.selectedRangeV1 || {};

    // Set startDate and endDate
    this.selectedRangeV1.startDate = this.getCTStartOfMonth();
    this.selectedRangeV1.endDate = this.ctDateNow();

    // Format the date range (if needed)
    const formattedDateRange = this.convertISODateStringToCustomFormat(this.getCTStartOfMonth()) + '-' + this.convertISODateStringToCustomFormat(this.ctDateNow());

    // this.selectedRangeV1.startDate = this.getCTStartOfMonth(),
    // this.selectedRangeV1.endDate = this.ctDateNow()
    // this.convertISODateStringToCustomFormat(this.getCTStartOfMonth()) + '-' + this.convertISODateStringToCustomFormat(this.ctDateNow());

    this.selectedRange = null;

    switch (this.resupplyActiveTab) {
      case this.ResupplyTypes.InactiveSupplies:
        this.fnSearchInActiveSupply();
        break;
        case this.ResupplyTypes.Above90Days:
          this.fnAboveNinetyDays();
          break;
      default:
        this.fnSearchSupply();
    }
  }
  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctEndOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    // let month = dateObj.getMonth();
    // let endDate= new Date(dateObj.getFullYear(), month, 1);
    // // let endDate= this.datepipe.transform(new Date(dateObj.getFullYear(), month, 1), 'yyyy-MM-dd');
    // // dateObj.setHours(0, 0, 0, 0);
    var lastDayOfMonth = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0);
    return lastDayOfMonth;
  }

  fnSearchResuppySupply() {
    switch (this.activeTab) {
      case this.ResupplyTypes.InactiveSupplies:
        this.fnSearchInActiveSupply();
        break;
        case this.ResupplyTypes.Above90Days:
        this.fnAboveNinetyDays();
        break;
      default:
        this.fnSearchSupply();
    }
  }

  fnSearchSupply() {
    this.isLoading = true;
    this.patientName = this.suppliesForm.value.patientName;
    this.agingDays = this.activeTab===this.ResupplyTypes.CurrentSupplies? this.suppliesForm.value.drpAgingDays:null;
    this.chartNo = this.suppliesForm.value.txtChartId;
    const filter = this.suppliesForm.value.isSelectAll;

    let fromDate;
    let toDate;
    switch(this.activeTab){
      case this.ResupplyTypes.CurrentSupplies:
        fromDate = this.fromDate;
        toDate = this.toDate;
        break;
      default:
        fromDate = this.fromDateV1;
        toDate = this.toDateV1;
    }
    const filterApply: FilterResupply = {
      chartNo: this.chartNo,
      patientName: this.patientName,
      fromDate: fromDate,
      toDate: toDate,
      csrId: this.suppliesForm.value.drpCSR
    };

    if (this.mmOrderServiceSubcribtion) {
      this.mmOrderServiceSubcribtion.unsubscribe();
    }
    this.mmOrderServiceSubcribtion = this.mmOrderService.getReSupplyCurrentOrderList(this.resupplyActiveTab, this.agingDays, filter, filterApply).subscribe((response) => {
        this.isLoading = false;
        this.tableDetails = response;
        this.tableDetails = this.tableDetails.map((claim) => {
          return {
            ...claim,
            nextCallDate: this.commonService.getFormattedDateZone(
              claim.nextCallDate
            ),
            nextOrderDate: this.commonService.getFormattedDateZone(
              claim.nextOrderDate
            ),
            dos: this.commonService.getFormattedDateZone(
              claim.dos
            ),
            expireDate: this.commonService.getFormattedDateZone(
              claim.expireDate
            ),
            inActiveSuppliesDate: this.commonService.getFormattedDateZone(
              claim.inActiveSuppliesDate
            ),
            archiveDate: this.commonService.getFormattedDateZone(
              claim.archiveDate
            ),
            priVerExpireDate:this.commonService.getFormattedDateZone(
              claim.priVerExpireDate
            ),
          };
        });
      },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
        });
  }
  fnAboveNinetyDays() {
    this.isLoading = true;
    this.patientName = this.suppliesForm.value.patientName;
    // this.agingDays = this.activeTab===this.ResupplyTypes.CurrentSupplies? this.suppliesForm.value.drpAgingDays:null;
    this.chartNo = this.suppliesForm.value.txtChartId;
    // const filter = this.suppliesForm.value.isSelectAll;

    let fromDate=
    this.datepipe.transform(
      // this.orderForm.value.txtOrderDate,
      this.suppliesForm.get('txtSupplyDate').value,
      'MM/dd/yyyy'
    );
    // let toDate;
    // switch(this.activeTab){
    //   case this.ResupplyTypes.CurrentSupplies:
    //     fromDate = this.fromDate;
    //     toDate = this.toDate;
    //     break;
    //   default:
    //     fromDate = this.fromDateV1;
    //     toDate = this.toDateV1;
    // }
    // const filterApply: FilterResupply = {
    //   chartNo: this.chartNo,
    //   patientName: this.patientName,
    //   fromDate: fromDate,
    //   csrId: this.suppliesForm.value.drpCSR
    // };

    if (this.mmOrderServiceSubcribtion) {
      this.mmOrderServiceSubcribtion.unsubscribe();
    }
    this.mmOrderServiceSubcribtion = this.mmOrderService.getResupplyAboveNinetyDays(
      this.chartNo, this.patientName,fromDate,this.suppliesForm.value.drpCSR
    ).subscribe((response) => {
        this.isLoading = false;
        this.aboveNinetyDaysLst = response;
        this.aboveNinetyDaysLst = this.aboveNinetyDaysLst.map((claim) => {
          return {
            ...claim,
            nextCallDate: this.commonService.getFormattedDateZone(
              claim.nextCallDate
            ),
            nextOrderDate: this.commonService.getFormattedDateZone(
              claim.nextOrderDate
            ),
            dos: this.commonService.getFormattedDateZone(
              claim.dos
            ),
            expireDate: this.commonService.getFormattedDateZone(
              claim.expireDate
            ),
            inActiveSuppliesDate: this.commonService.getFormattedDateZone(
              claim.inActiveSuppliesDate
            ),
            archiveDate: this.commonService.getFormattedDateZone(
              claim.archiveDate
            ),
            priVerExpireDate:this.commonService.getFormattedDateZone(
              claim.priVerExpireDate
            ),
          };
        });
      },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
        });
  }

  fnSearchInActiveSupply() {
    this.isLoading = true;

    let fromDate;
    let toDate;
    switch(this.activeTab){
      case this.ResupplyTypes.CurrentSupplies:
        fromDate = this.fromDate;
        toDate = this.toDate;
        break;
      default:
        fromDate = this.fromDateV1;
        toDate = this.toDateV1;
    }

    const suppliesInactiveInput: CreateUpdateResupplyStatusInactiveSuppliesDTO = {
      fromDate:fromDate,
      toDate: toDate,
      chartID: this.suppliesForm.value.txtChartId,
      patientName: this.suppliesForm.value.patientName,
      csr:this.suppliesForm.value.drpCSR
    };

    if (this.mmOrderInActiveSuppliesSubcribtion) {
      this.mmOrderInActiveSuppliesSubcribtion.unsubscribe();
    }
    this.mmOrderInActiveSuppliesSubcribtion = this.mmOrderService.getResupplyStatusListForInactiveSuppliesByInput(suppliesInactiveInput).subscribe((response) => {
      this.isLoading = false;
      this.InactivetableDetails = response;
      this.InactivetableDetails = this.InactivetableDetails.map((claim) => {
        return {
          ...claim,
          nextCallDate: this.commonService.getFormattedDateZone(
            claim.nextCallDate
          ),
          nextOrderDate: this.commonService.getFormattedDateZone(
            claim.nextOrderDate
          ),
          dos: this.commonService.getFormattedDateZone(
            claim.dos
          ),
          suppliesInactiveDate: this.commonService.getFormattedDateZone(
            claim.suppliesInactiveDate
          ),
        };
      });
    },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
      });
  }

  // fnSearchInactiveSupply() {
  //   this.isLoading = true;
  //   this.patientId = localStorage.getItem("userId") ?? "";
  //   this.mmOrderServiceSubcribtion = this.mmOrderService.getReSupplyInactiveList(
  //     this.patientId
  //   ).subscribe((response) => {
  //     this.isLoading = false;
  //     this.InactiveListData = response;
  //   },
  //     (err) => {
  //       this.isLoading = false;
  //       const data: HttpErrorResponse = err;
  //     });
  // }
  fnResupplyPatientDetails(patientId: string) {
    this.isPatientLoading = true;
    this.mmOrderServiceSubcribtion = this.mmOrderService.getResupplyPatientDetails(
      patientId
    ).subscribe((response) => {
      this.resupplyPatientForm.patchValue({
        patientAmount: response?.patientBalance || 0,
        insAmount: response?.insuranceBalance || 0,
        totalAmount: response?.totalBalance || 0,
        overPaid: response?.insuranceRecoopAmount || 0,
        nextCallDate: this.commonService.getFormattedDateZone(response?.nextCallDate),
        newsetupDate: this.commonService.getFormattedDateZone(response?.newsetupDate),
        practitionerName: response?.practitionerName || '-',
        rxDate: this.commonService.getFormattedDateZone(response?.rxDate),
        email: response?.email || '-',
        homeNo: response?.homeNo,
      });
      this.calllogTableData = response?.callNotes;
      this.calllogTableData = this.calllogTableData.map((resupply) => {
        return {
          ...resupply,
          creationTime: this.commonService.getFormattedDateZone(
            resupply.creationTime
          ),
        };
      });
      this.isPatientLoading = false;
    },
      (err) => {
        this.isLoading = false;
        this.isPatientLoading = false;
        const data: HttpErrorResponse = err;
        this.resupplyPatientForm.patchValue({
          patientAmount: 0,
          insAmount: 0,
          totalAmount: 0,
          overPaid: 0,
          nextCallDate: "-",
          newsetupDate: "-",
          practitionerName: "-",
          rxDate: "-",
          email: '-',
          homeNo: "-",
        })
      });
  }
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  // onDateRangeChange() {
  //   const startDate = this.suppliesForm.get('txtStartDateValue').value;
  //   const endDate = this.suppliesForm.get('txtEndDateValue').value;
  //   this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
  //   this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
  //   if (startDate != null && endDate != null) {
  //   }
  // }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }

  datesUpdatedsV1(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDateV1 = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDateV1 = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    } else {
      this.fromDateV1 = null;
      this.toDateV1 = null;
    }
  }
  convertISODateStringToCustomFormat(isoDateString: any): string {
    const inputDate = new Date(isoDateString);

    // Extracting date components
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();

    // Formatted date string in MM/DD/YYYY format
    const formattedDateString = `${month}/${day}/${year}`;

    return formattedDateString;
  }

  clearNextCallDate() {
    this.suppliesForm.patchValue({
      txtNextCallDate: null,
    });
    this.fromDate = null;
    this.toDate = null;
    this.selectedRange = null;
  }

  clearNextCallDateV1() {
    this.suppliesForm.patchValue({
      txtNextCallDateV1: null,
    });
    this.fromDateV1 = null;
    this.toDateV1 = null;
    this.selectedRangeV1 = null;
  }

    //Method to load the users in CSR Drop Down
    loadCSRUsers() {
      const csrUsersList = this.userService
        .getCSRUsersV1()
        .subscribe((response) => {
          response?.forEach((element) => {
            this.drpCSR.push({
              userId: element.userId,
              name: element.name,
            });
          });
          this.lstUsers = response;
          this.filteredUsers = this.suppliesForm
            .get('txtCSRFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstUsers?.filter((option) =>
                  option?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

              if (localStorage.getItem('roleName') !== 'admin') {
              this.suppliesForm.patchValue({ drpCSR: [this.sesUserId] });
              this.isAdmin = true;
            }else{
                this.onCSRSelectionChange({ value: 'selectAll' } as MatSelectChange);
                this.isAdmin = false;
            }
            setTimeout(() => {
              this.fnSearchSupply();
            },500);

            // this.onCSRSelectionChange({ value: 'selectAll' } as MatSelectChange);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          console.warn(data?.error?.error?.message);

        });

        // if (localStorage.getItem('roleName') !== 'admin') {
        //   this.suppliesForm.patchValue({ drpCSR: [this.sesUserId] });
        //   this.isAdmin = false;
        // }else{
        //     this.onCSRSelectionChange({ value: 'selectAll' } as MatSelectChange);
        //   // this.isAdmin = true;
        // }
      this.subscription$.push(csrUsersList);
    }


    onCSRSelectionChange(event: MatSelectChange): void {
      this.selectedCSRList = event.value;
      if (this.selectedCSRList.includes('selectAll')) {
        this.CSRSelectAllOption = true;
        if (this.CSRSelectAllOption) {
          this.selectedCSRList = this.drpCSR.map(csr => csr.userId);
          this.suppliesForm.get('drpCSR').patchValue(this.selectedCSRList);
        }
      } else if (this.selectedCSRList.includes('DeselectAll')) {
        this.selectedCSRList = [];
        this.CSRSelectAllOption = false;
        this.suppliesForm
          .get('drpCSR')
          .patchValue([]);
      }
      else {
        this.CSRSelectAllOption = this.selectedCSRList.length === this.drpCSR.length;
      }
    }
}
export interface CSRDrpOptions {
  userId?: string;
  name?: string;
}
