import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { MatDatepicker } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { PatientNotesService } from '../patient-proxy/patient-optimization/patient-notes.service';
import { PatientVerificationService } from '../patient-proxy/patient-optimization/patient-verification.service';
import { DateAdapter } from '@angular/material/core';
import { PracticeManagementService } from '../patient-proxy/practice-management/practice-management.service';
import { Router } from '@angular/router';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-practice-management-list',
  templateUrl: './practice-management-list.component.html',
  styleUrls: ['./practice-management-list.component.scss']
})
export class PracticeManagementListComponent implements OnInit {

  practicelistdata: any;
  patientId: string;
  isLoading: boolean;
  dataSource: MatTableDataSource<any>;
  dtvOptions: any;
  practicelistForm: FormGroup;

  myTable: DataTables.Api;
  maxDate: Date;
  $subscription: Subscription[] = [];
  tableData$: Subscription;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtElement: DataTableDirective;

  @ViewChild('txtDateValue') txtDateValue: MatDatepicker<Date>;

  @Input() selectedNode: any;

  searchForm: FormGroup;
  dateValue: any;
  ischeck: number;
  selectedMonth: number;
  selectedYear: number;
  constructor(
    public dialog: MatDialog,
    private practiceManagementService: PracticeManagementService,
    private formBuilder: FormBuilder,
    public PatientNotesService: PatientNotesService,
    public PatientVerificationService: PatientVerificationService,
    private title: Title,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    private communicationService: CommunicationService,
    private commonService: CommonService
  ) {
    this.maxDate = this.dateAdapter.addCalendarYears(new Date(), 0);
    this.communicationService.practicelistFunctionCall$.subscribe((status) => {
      // this.getPracticeListTableData(status, this.selectedMonth, this.selectedYear);
      this.getPracticeListTableData(status,'');
    });
    // this.communicationService.practicetoogleFunctionCall$.subscribe((event) => {
    //   this.change(event);
    // });
  }


  ngOnInit(): void {
    this.title.setTitle('Qsecure | Practice Management');
    this.dateValue =
      new Date().toLocaleString('default', { month: 'short' }) +
      '/' +
      new Date().getFullYear();

    this.selectedNode =
      new Date().getMonth() + 1 + '/' + new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();
    this.ischeck = 1;
    this.practicelistForm = this.formBuilder.group({
      txtDateValue: new FormControl(''),
      txtDate: new FormControl(),
    });
    this.practicelistForm.patchValue({
      txtDateValue: this.dateValue,
    });
    this.searchForm = this.formBuilder.group({
      sSearch: new FormControl(''),
    });
    // this.getPracticeListTableData(this.ischeck, this.selectedMonth, this.selectedYear);
    this.getPracticeListTableData(this.ischeck,'');
  }

  ngAfterViewInit(): void {
    if (this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            const value = this['value'];
            if (that.search() !== value) {
              that.search(value).draw();
            }
          });
        });
      });
    }

  }

  ngOnDestroy(): void {
    if (this.$subscription) {
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.tableData$?.unsubscribe();
  }

  practiceFilter(){
    this.getPracticeListTableData(this.ischeck,this.searchForm.value.sSearch);
  }
  newPractice() {
    const url = '/#/addpracticemanagement';
    window.open(url, '_blank');
  }

  openDatePicker() {
    this.txtDateValue.open();
  }

  // chosenMonthHandler(normalizedMonth: any, datepicker: any) {
  //   datepicker.close();
  //   this.dateValue =
  //     normalizedMonth.toLocaleString('default', { month: 'short' }) +
  //     '/' +
  //     normalizedMonth.getFullYear();
  //   this.selectedNode =
  //     normalizedMonth.getMonth() + 1 + '/' + normalizedMonth.getFullYear();
  //   this.selectedMonth = normalizedMonth.getMonth() + 1;
  //   this.selectedYear = normalizedMonth.getFullYear();
  //   this.getPracticeListTableData(this.ischeck, this.selectedMonth, this.selectedYear);
  // }


  change(event: boolean) {
    if (event == false) {
      // this.getPracticeListTableData(ActiveStatus.Active, this.selectedMonth, this.selectedYear);
      this.getPracticeListTableData(ActiveStatus.Active,this.searchForm.value.sSearch);
    } else {
      // this.getPracticeListTableData(ActiveStatus.Inactive, this.selectedMonth, this.selectedYear);
      this.getPracticeListTableData(ActiveStatus.Inactive,this.searchForm.value.sSearch);
    }
  }

  // getPracticeListTableData(ischeck: number, selectedMonth: number, selectedYear: number): void {
  //   this.tableData$?.unsubscribe();
  //   this.isLoading = true;
  //   const getPracticeListTableData = this.practiceManagementService.practiceReportByStatusAndMonthAndYear(ischeck, selectedMonth, selectedYear).subscribe(
  //     (response) => {
  //       this.isLoading = false;
  //       this.practicelistdata = response;
  //     },
  //     (error) => {
  //       const data: HttpErrorResponse = error;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //       this.isLoading = false;
  //       this.practicelistdata = [];
  //     }
  //   );
  //   this.tableData$ = getPracticeListTableData;
  // }

  getPracticeListTableData(ischeck: number,sSearch:string): void {
    this.tableData$?.unsubscribe();
    this.isLoading = true;
    const getPracticeListTableData = this.practiceManagementService.practiceReportByStatus(ischeck,sSearch).subscribe(
      (response) => {
        this.isLoading = false;
        this.practicelistdata = response;
        this.practicelistdata = this.practicelistdata.map((data) => {
          return {
            ...data,
            creationTime: this.commonService.getFormattedDateTimeZone(
              data.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              data.lastModificationTime
            ),
          };
        });
      },
      (error) => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
        this.practicelistdata = [];
      }
    );
    this.tableData$ = getPracticeListTableData;
  }
}

