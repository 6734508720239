import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  Input,
} from '@angular/core';
import { Console } from 'console';
import moment from 'moment';

@Component({
  selector: 'app-slider-date-picker',
  templateUrl: './slider-date-picker.component.html',
  styleUrls: ['./slider-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderDatePickerComponent implements OnInit {
  @Input() SelectedMonthYear: string = '';
  items: any[] = [];
  currentDate = new Date();
  currentMonth = '';
  currentYear: any;
  stopDate = new Date();
  selectedItem = null;
  dateValue: any;
  dates: Date[] = [];
  currentIndex = 0;
  pageSize = 50000;
  displayedDates: any;
  selectedDateIndex: number | null = null;

  constructor(private renderer: Renderer2) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      const viewportElement = document.querySelector('.example-viewport');
      if (viewportElement) {
        const scrollPosition = viewportElement.scrollWidth - viewportElement.clientWidth;
        this.renderer.setProperty(viewportElement, 'scrollLeft', scrollPosition);
      }
    }, 0);
    this.selectedItem = new Date();

    this.checkMonth();
    this.displayDates();
  }
  checkMonth(){
    if ((parseInt(this.SelectedMonthYear.split('/')[0], 10)) === new Date().getMonth() + 1) {
      let loop = new Date();

      this.dates = [new Date(), new Date(new Date().setDate(loop.getDate() - 1))];

      for (let i = 0; i < 30; i++) {
        let newDate = loop.setDate(loop.getDate() - 1);

        loop = new Date(newDate);

        this.dates.push(loop);
      }
      this.selectedItem = new Date();
    }
     else{
   const month = parseInt(this.SelectedMonthYear.split('/')[0], 10);
   const year = parseInt(this.SelectedMonthYear.split('/')[1], 10);
   let loop = new Date(year, month , 0);
      // let loop = new Date();

      this.dates = [new Date(year, month , 0), new Date(new Date(year, month , 0).setDate(loop.getDate() - 1))];

      for (let i = 0; i < 30; i++) {
        let newDate = loop.setDate(loop.getDate() - 1);

        loop = new Date(newDate);

        this.dates.push(loop);
      }
      this.selectedItem = new Date(year, month , 0);
     }
    // Creating an array with specified date range
  }
  subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);

    return date;
  }
  // Example function to return whether a date is the selected date
  isDateSelected(item: Date): boolean {
    return item.toDateString() === this.selectedItem.toDateString();
  }
  displayDates() {
    // Calculate the end index for the current page.
    const endIndex = this.currentIndex + this.pageSize;
    this.displayedDates = this.dates.slice(this.currentIndex, endIndex);
    this.displayedDates.pop();
    this.displayedDates.reverse();
  }

  // Get the selected Date
  select(item: any) {

    this.selectedItem = item;

  }

  // Method for changing Month
  changeMonth(e: any) {

    this.currentDate = this.displayedDates[e];

    this.currentMonth = new Date(this.currentDate).toLocaleString('default', {
      month: 'short',
    });
    this.currentYear = new Date(this.currentDate).getFullYear();

  }

  // Method to get the current weekday of the date showon
  returnWeekDay(item: any) {
    return new Date(item).toLocaleDateString('default', { month: 'short' });
  }

  // nextPage() {
  //   // Move to the next page of dates if available.
  //   if (this.currentIndex + this.pageSize < this.dates.length) {
  //     this.currentIndex += this.pageSize;
  //     this.displayDates();
  //   }
  // }

  // prevPage() {
  //   // Move to the previous page of dates if available.
  //   if (this.currentIndex > 0) {
  //     this.currentIndex -= this.pageSize;
  //     this.displayDates();
  //   }
  // }
}
