<div class="card mt-2">
    <div class="row">
        <div class="col-12">
            <div class="card-body pb-0">
                <div class="row">
                    <div class="col-12">
                        <div class="table table-responsive">
                            <table id="tblClaimBatchList" datatable [dtOptions]="dtAutoPostingOptions"
                                class="tbl hover row-border hover w-100 display">
                                <thead>
                                    <tr>
                                        <th><mat-icon>apps</mat-icon></th>
                                        <!-- <th><mat-icon>apps</mat-icon></th> -->
                                        <th [matTooltip]="'Check No'">Check No</th>
                                        <th [matTooltip]="'Check Date'">Check Date</th>
                                        <th [matTooltip]="'Chart Id'">Chart Id</th>
                                        <th [matTooltip]="'Patient Name'">Patient Name</th>
                                        <th [matTooltip]="'Claim Id'">Claim Id</th>
                                        <!-- <th [matTooltip]="'ICN'">ICN</th> -->
                                        <th [matTooltip]="'Rank'">Rank</th>
                                        <th [matTooltip]="'Status'">Status</th>
                                        <th [matTooltip]="'Posting Method'">Posting Method</th>
                                        <th [matTooltip]="'Billed ($)'">Billed ($)</th>
                                        <th [matTooltip]="'Paid ($)'">Paid ($)</th>
                                        <th [matTooltip]="'Pending ($)'">Pending ($)</th>
                                        <th [matTooltip]="'Skip'">Skip</th>
                                        <th [matTooltip]="'OK'">OK</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of DataList" (click)="tblItemSelectedRow(data)"
                                        [class.row-highlighted]="data.claimId === selectedRow">

                                        <td [matTooltip]="'Open Options'">
                                            <mat-icon style="cursor: pointer !important"
                                                [matMenuTriggerFor]="menu">more_vert</mat-icon>
                                            <mat-menu class="custom-mat-menu" #menu="matMenu">
                                                <button mat-menu-item [disabled]="isEmpty(data.patientId)"
                                                    (click)="openAddNotesPopUpV1($event, data, false)">
                                                    Add Patient Notes
                                                </button>
                                                <button mat-menu-item [disabled]="isEmpty(data.patientId)" (click)="
                                                  openViewNotesPopup(data.patientId)">
                                                    View Patient Notes
                                                </button>
                                                <button mat-menu-item [disabled]="isEmpty(data.patientId)"
                                                    (click)="viewPatientLedger(data.patientId)">
                                                    Patient Ledger
                                                </button>
                                                <button mat-menu-item (click)="uploadEOB(data, true)"
                                                    [disabled]="isEmpty(data.claimId)">
                                                    Upload EOB
                                                </button>
                                                <button mat-menu-item (click)="uploadEOB(data, false)" [disabled]="
                                                  isEmpty(data.claimId)
                                                ">
                                                    View EOB
                                                </button>
                                                <button mat-menu-item [disabled]="isEmpty(data.claimId)"
                                                    (click)="insuranceRecoop(data)">
                                                    Insurance Recoup
                                                </button>
                                                <button mat-menu-item [disabled]="
                                                  isEmpty(data.claimId) || isEmpty(data.claimStatusId)
                                                " (click)="UpdateStatus(data)">
                                                    Update Status
                                                </button>
                                                <button [disabled]="
                                                  !data.isMoveSecClaim || data.isSecClaimProcessed
                                                " mat-menu-item (click)="OpenMoveToSecondaryModel(data)">
                                                    Move to Secondary
                                                </button>
                                                <button [disabled]="
                                                  !data.isPatientResp ||
                                                  data.patientRespAmount <= 0 ||
                                                  data.isPaypalMailSent === 1
                                                " mat-menu-item (click)="
                                                  OpenPatientResponsibilityModel(data.claimId, data)
                                                ">
                                                    Patient Responsibility
                                                </button>
                                                <!--  -->
                                                <button *ngIf="data.isResubmitEnabled" mat-menu-item (click)="
                                                  openClaimForm(
                                                    data.orderId,
                                                    data.patientId,
                                                    data.chartId,
                                                    data.patientName,
                                                    data.orderTicketId,
                                                    data.claimId,
                                                    false,
                                                    data.isResubmitEnabled
                                                  )
                                                ">
                                                    <!-- claimData.isResubmitEnabled -->
                                                    Resubmit Claim Form
                                                </button>
                                            </mat-menu>
                                        </td>


                                        <!-- <td [matTooltip]="'Expand Notes'" class="dt-control"
                                            (click)="handleTableCellClick($event, data.ltPatientNotes)"></td> -->

                                        <!-- <td [matTooltip]="'Open Options'">
                                            <mat-icon style="cursor: pointer !important"
                                                [matMenuTriggerFor]="menu">more_vert</mat-icon>
                                            <mat-menu class="custom-mat-menu" #menu="matMenu">
                                                <button mat-menu-item (click)="OpenMoveToSecondaryModel()">
                                                    Move to Secondary
                                                </button>
                                                <button mat-menu-item (click)="OpenPatientResponsibilityModel()">
                                                    Patient Responsibility
                                                </button>
                                            </mat-menu>
                                        </td> -->
                                        <td (dblclick)="onRowDoubleClick(data?.checkNumber || '-')"
                                            [matTooltip]="data?.checkNumber || '-'">
                                            {{ data?.checkNumber || '-'}}
                                        </td>
                                        <td (dblclick)="onRowDoubleClick(data?.checkDate || '-')" [matTooltip]="isDefaultDate (data?.checkDate)
                                            ? '-'
                                            : (data?.checkDate | date : 'MM/dd/yyyy' : 'en_US')">
                                            {{
                                            isDefaultDate (data?.checkDate)
                                            ? "-"
                                            : (data?.checkDate | date : "MM/dd/yyyy" : "en_US")
                                            }}
                                        </td>
                                        <td (dblclick)="onRowDoubleClick(data?.chartId || '-')"
                                            [matTooltip]="data?.chartId || '-'">
                                            <!-- {{ data?.chartId || '-'}} -->
                                            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
                                                [routerLink]="
                                                '/patientCreateEditTab/' + data.patientId + '/' + data.documentId
                                            " target="_blank" class="preview-link">{{ data?.chartId ? data?.chartId :
                                                "-" }}
                                            </a>
                                        </td>
                                        <td (dblclick)="onRowDoubleClick(data?.patientName || '-')"
                                            [matTooltip]="data?.patientName || '-'">{{
                                            data?.patientName || '-'}}</td>

                                        <!-- <td (dblclick)="onRowDoubleClick(data?.defaultClaimId || '-')"
                                            [matTooltip]="data?.defaultClaimId || '-'">

                                            {{ data?.defaultClaimId || '-' }}
                                        </td> -->

                                        <td class="text-right" [matTooltip]="data?.defaultClaimId || '-'">
                                            <a class="pointer preview-link"
                                                [class.disabled]="
                                                isEmpty(data?.defaultClaimId) ||
                                                isEmpty(data.patientId) ||
                                                isEmpty(data.claimId)
                                              " (click)="
                                                openClaimForm(
                                                    data.orderId,
                                                    data.patientId,
                                                    data.chartId,
                                                    data.patientName,
                                                    data?.orderTicketId,
                                                    data.claimId,
                                                  true,
                                                  data?.isResubmitEnabled
                                                )
                                              ">
                                                {{ data?.defaultClaimId || "-" }}
                                            </a>
                                        </td>


                                        <td (dblclick)="onRowDoubleClick(data?.Rank || '-')"
                                            [matTooltip]="data?.sPlanType || '-'">
                                            {{ data?.sPlanType || '-'}}
                                        </td>

                                        <td (dblclick)="onRowDoubleClick(data?.claimStatusName || '-')"
                                            [matTooltip]="data?.claimStatusName || '-'">
                                            <!-- <span class="tr_color"> -->
                                            {{ data?.claimStatusName || '-' }}
                                            <!-- </span> -->

                                        </td>

                                        <td (dblclick)="onRowDoubleClick(data?.postingMethod || '-')"
                                        [matTooltip]="data?.postingMethod || '-'">
                                        {{ data?.postingMethod || '-' }}
                                        </td>

                                        <td class="text-right" (dblclick)="onRowDoubleClick(data?.Billed || '-')"
                                            [matTooltip]="data?.billedAmount || '-'">
                                            {{ data?.billedAmount || '-' }}
                                        </td>
                                        <td class="text-right" (dblclick)="onRowDoubleClick(data?.Paid || '-')"
                                            [matTooltip]="data?.paidAmount || '-'">
                                            {{ data?.paidAmount || '-' }}
                                        </td>
                                        <td class="text-right" (dblclick)="onRowDoubleClick(data?.Pending || '-')"
                                            [matTooltip]="data?.pendingAmount || '-'">
                                            {{ data?.pendingAmount || '-' }}
                                        </td>
                                        <td>
                                            <mat-checkbox color="primary" [(ngModel)]="data.Skip"
                                                (change)="onCheckboxChangeSkip(data)">
                                            </mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox color="primary" [(ngModel)]="data.Ok"
                                                (change)="onCheckboxChangeOk(data)">
                                            </mat-checkbox>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th><mat-icon>apps</mat-icon></th>
                                        <!-- <th><mat-icon>apps</mat-icon></th> -->
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Check No" name="search-Check-No" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Check Date" name="search-Check-Date" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Chart Id" name="search-ChartId" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Patient Name" name="search-patientName" />
                                        </th>
                                        <th>
                                            <input matInput type="text" class="textbox-border-class"
                                                placeholder="Claim Id" name="search-ClaimId" />
                                        </th>
                                        <!-- <th>
                                            <input matInput type="text" class="textbox-border-class" placeholder="ICN"
                                                name="search-ICN" />
                                        </th> -->
                                        <th>
                                            <input matInput type="text" class="textbox-border-class" placeholder="Rank"
                                                name="search-Rank" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Status" name="search-Status" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Posting Method" name="search-postingMethod" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Billed" name="search-Billed" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text" placeholder="Paid"
                                                name="search-Paid" />
                                        </th>
                                        <th>
                                            <input matInput class="textbox-border-class" type="text"
                                                placeholder="Pending" name="search-Pending" />
                                        </th>
                                        <th><mat-icon>lock</mat-icon></th>
                                        <th><mat-icon>lock</mat-icon></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






<div class="card mt-2">
    <div class="row">
        <div class="col-12">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table table-responsive">
                            <div *ngIf="!isLoading">
                                <table id="tblClaimBatchChildList" datatable [dtOptions]="dtAutoPostingChildOptions"
                                    class="tbl hover row-border hover w-100 display">
                                    <thead>
                                        <tr>
                                            <th [matTooltip]="'Code'">Code</th>
                                            <th [matTooltip]="'Cpt'">Cpt</th>
                                            <th [matTooltip]="'DOS'">DOS</th>
                                            <th [matTooltip]="'M1'">M1</th>
                                            <th [matTooltip]="'M2'">M2</th>
                                            <th [matTooltip]="'M3'">M3</th>
                                            <th [matTooltip]="'M4'">M4</th>
                                            <th [matTooltip]="'Unit'">Unit</th>
                                            <th [matTooltip]="'Billed ($)'">Billed ($)</th>
                                            <th [matTooltip]="'Allowed ($)'">Allowed ($)</th>
                                            <th [matTooltip]="'Deduct ($)'">Deduct ($)</th>
                                            <th [matTooltip]="'Copay ($)'">Copay ($)</th>
                                            <th [matTooltip]="'Co-ins ($)'">Co-ins ($)</th>
                                            <th [matTooltip]="'Paid ($)'">Paid ($)</th>
                                            <th [matTooltip]="'WrtOff ($)'">WrtOff ($)</th>
                                            <th [matTooltip]="'Claim'">Claim</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of DataListChild">
                                            <td (dblclick)="onRowDoubleClick(data?.itemProcedureCode || '-')"
                                                [matTooltip]="data?.itemProcedureCode || '-'">
                                                {{ data?.itemProcedureCode || '-'}}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.itemProcedureCode || '-')"
                                                [matTooltip]="data?.itemProcedureCode || '-'">
                                                {{ data?.itemProcedureCode || '-'}}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.dtDos || '-')" [matTooltip]=" isDefaultDate(data.dtDos)
                                            ? '-'
                                            : (data.dtDos | date : 'MM/dd/yyyy' : 'en_US')">
                                                {{ isDefaultDate(data?.dtDos)
                                                ? "-"
                                                : (data?.dtDos | date : "MM/dd/yyyy" : "en_US")}}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.modifier1 || '-')"
                                                [matTooltip]="data?.modifier1 || '-'">

                                                {{ data?.modifier1 || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.modifier2 || '-')"
                                                [matTooltip]="data?.modifier2 || '-'">
                                                {{ data?.modifier2 || '-'}}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.modifier3 || '-')"
                                                [matTooltip]="data?.modifier3 || '-'">
                                                {{ data?.modifier3 || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.modifier4 || '-')"
                                                [matTooltip]="data?.modifier4 || '-'">
                                                {{ data?.modifier4 || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.itemCost || '-')"
                                                [matTooltip]="data?.itemCost || '-'" class="text-right">
                                                {{ data?.itemCost || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.billed || '-')"
                                                [matTooltip]="data?.billed || '-'" class="text-right">
                                                {{ data?.billed || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.allowed || '-')"
                                                [matTooltip]="data?.allowed || '-'" class="text-right">
                                                {{ data?.allowed || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.deductAmount || '-')"
                                                [matTooltip]="data?.deductAmount || '-'" class="text-right">
                                                {{ data?.deductAmount || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.coPayAmount || '-')"
                                                [matTooltip]="data?.coPayAmount || '-'" class="text-right">
                                                {{ data?.coPayAmount || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.coinsurance || '-')"
                                                [matTooltip]="data?.coinsurance || '-'" class="text-right">
                                                {{ data?.coinsurance || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.insurancePaid || '-')"
                                                [matTooltip]="data?.insurancePaid || '-'" class="text-right">
                                                {{ data?.insurancePaid || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.writeOffAmount || '-')"
                                                [matTooltip]="data?.writeOffAmount || '-'" class="text-right">
                                                {{ data?.writeOffAmount || '-' }}
                                            </td>
                                            <td (dblclick)="onRowDoubleClick(data?.Claim || '-')"
                                                [matTooltip]="data?.Claim || '-'">
                                                {{ data?.Claim || '-' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row" *ngIf="isLoading">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
    </div>
</div>
