import type {
  CreateUpdatePatientAddressDTO,
  PatientAddressDTO,
  PatientInfo,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientAddressDTO) =>
    this.restService.request<any, PatientAddressDTO>(
      {
        method: 'POST',
        url: '/api/app/address',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/address/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, PatientAddressDTO>(
      {
        method: 'GET',
        url: `/api/app/address/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientAddressDTO>>(
      {
        method: 'GET',
        url: '/api/app/address',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdatePatientAddressDTO) =>
    this.restService.request<any, PatientAddressDTO>(
      {
        method: 'PUT',
        url: `/api/app/address/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getAddressByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, PatientAddressDTO[]>(
      {
        method: 'GET',
        url: `/api/app/address/get-address-by-patient-id/${patientId}`,
      },
      { apiName: this.apiName }
    );
  getPayorCheckAvailablity = (payerId: string) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/app/payer-detail/check-ins-availablity/${payerId}`,
      },
      { apiName: this.apiName }
    );

  getPatientBasicDetailsByPatientId = (patientId: string) =>
    this.restService.request<any, PatientInfo>(
      {
        method: 'GET',
        url: `/api/app/address/get-patient-basic-details/${patientId}`,
      },
      { apiName: this.apiName }
    );

  createAddressForOrder = (
    input: CreateUpdatePatientAddressDTO,
    orderId: string
  ) =>
    this.restService.request<any, PatientAddressDTO>(
      {
        method: 'POST',
        url: `/api/app/address/create-address-for-order/${orderId}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
