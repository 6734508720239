import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { addBilling, getBilling, updateBilling } from './billing.action';


@State<billingStateModel>({
  name: 'billingState',
  defaults: {
    billing: [],
    selectedBilling: null
  }
})
@Injectable()
export class billingState {
  constructor(
    private billingService: ClaimInformationsService,
  ) { }

  @Action(addBilling)
  addBilling({ patchState }: StateContext<billingStateModel>, { payload }: addBilling) {
    return this.billingService.create(payload).pipe(tap((result) => {
      patchState({
        selectedBilling: result
      });
    }));
  }

  @Action(updateBilling)
  updateBilling({ patchState }: StateContext<billingStateModel>, { id, payload }: updateBilling) {
    return this.billingService.update(id, payload).pipe(tap((result) => {
      patchState({
        selectedBilling: result
      });
    }));
  }
  @Action(getBilling)
  getSelectedBillingId({ patchState }: StateContext<billingStateModel>, { id }: getBilling) {
    return this.billingService.get(id).pipe(tap((result) => {
      patchState({
        selectedBilling: result
      });
    }));
  }

}
export class billingStateModel {
  billing: ClaimDTO[];
  selectedBilling: ClaimDTO|null;
}
