import { Injectable } from '@angular/core';
import { CreateUpdateDocumentBinDTO, DocumentBinDTO, ValidateDocumentBinDTO } from './dto/models';
import {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
  RestService,
} from '@abp/ng.core';
import { ActiveStatus } from '../localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class DocumentBinService {
  apiName = 'platformManagement';

  create = (inboundDetails: CreateUpdateDocumentBinDTO) =>
    this.restService.request<any, DocumentBinDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/document-bin',
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/document-bin/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, DocumentBinDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/document-bin/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<DocumentBinDTO>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/document-bin',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, inboundDetails: CreateUpdateDocumentBinDTO) =>
    this.restService.request<any, DocumentBinDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/document-bin/${id}`,
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );

  checkDocumentBinExists = () =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/PlatformApp/document-bin/check-document-bin-exists',
      },
      { apiName: this.apiName }
    );

  documentTypeList = () =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/PlatformApp/document-bin/document-type-list`,
      },
      { apiName: this.apiName }
    );

  enableDisableDocumentBinByDocumentTypeID = (DocumentTypeID: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/PlatformApp/document-bin/enable-disable-document-bin',
      params: { documentTypeID: DocumentTypeID },
    },
      { apiName: this.apiName });

  // addDocumentBinCheckByValidate = (validate: ValidateDocumentBinDTO) =>
  // this.restService.request<any, string>({
  //   method: 'POST',
  //   responseType: 'text',
  //   url: '/api/PlatformApp/document-bin/add-document-bin-check',
  //   body: validate,
  // },
  // { apiName: this.apiName });

  addDocumentBinCheckByValidate = (validate: ValidateDocumentBinDTO) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/PlatformApp/document-bin/add-document-bin-check',
      body: validate,
    },
      { apiName: this.apiName });

  getDocumentBinBasedOnFilterByStatusFilter = (statusFilter: ActiveStatus) =>
  this.restService.request<any, DocumentBinDTO[]>({
    method: 'GET',
    url: '/api/PlatformApp/document-bin/get-document-bin-based-on-filter',
    params: { statusFilter },
  },
  { apiName: this.apiName });
  constructor(private restService: RestService) { }
}
