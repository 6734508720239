import type { CreateUpdateInitialPatientDocumentDTO, InitialPatientDocumentDTO } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FaxBinService {
  apiName = 'Default';

  deleteByDocumentId = (documentId: string) =>
    this.restService.request<any, InitialPatientDocumentDTO>({
      method: 'DELETE',
      url: '/api/app/fax-bin',
      params: { documentId },
    },
     { apiName: this.apiName, skipHandleError: true });

  assignPatientDocumentToUserByDocumentIdAndInitialPatientDocument = (documentId: string, initialPatientDocument: CreateUpdateInitialPatientDocumentDTO) =>
    this.restService.request<any, InitialPatientDocumentDTO>({
      method: 'PUT',
      url: `/api/app/fax-bin/assign-patient-document-to-user/${documentId}`,
      body: initialPatientDocument,
    },
     { apiName: this.apiName, skipHandleError: true });

  fileUploadForExtraction = () =>
    this.restService.request<any, InitialPatientDocumentDTO>({
      method: 'POST',
      url: '/api/app/fax-bin/file-upload-for-extraction',
    },
     { apiName: this.apiName, skipHandleError: true });

  getAssignedPatientDocumentByUserId = (userId: string) =>
    this.restService.request<any, InitialPatientDocumentDTO[]>({
      method: 'GET',
      url: `/api/app/fax-bin/get-assigned-patient-document/${userId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getDocumentsBasedOnReviewedStatusByIReviewedStatus = (iReviewedStatus: number) =>
    this.restService.request<any, InitialPatientDocumentDTO[]>({
      method: 'GET',
      url: '/api/app/fax-bin/get-documents-based-on-reviewed-status',
      params: { iReviewedStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  getDocumentsBasedOnTeamByIAssignedTeamType = (iAssignedTeamType: number) =>
    this.restService.request<any, PagedResultDto<InitialPatientDocumentDTO>>({
      method: 'GET',
      url: '/api/app/fax-bin/get-documents-based-on-team',
      params: { iAssignedTeamType },
    },
     { apiName: this.apiName, skipHandleError: true });

  getInitialPatientDocument = () =>
    this.restService.request<any, InitialPatientDocumentDTO[]>({
      method: 'GET',
      url: '/api/app/fax-bin/get-initial-patient-document',
    },
     { apiName: this.apiName, skipHandleError: true });

  getInitialPatientDocumentByIdByDocumentId = (documentId: string) =>
    this.restService.request<any, InitialPatientDocumentDTO>({
      method: 'GET',
      url: `/api/app/fax-bin/get-initial-patient-document-by-id/${documentId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  saveInitialPatientDocumentByFileContentAndInitialPatientDocument = (fileContent: number[], initialPatientDocument: CreateUpdateInitialPatientDocumentDTO) =>
    this.restService.request<any, InitialPatientDocumentDTO>({
      method: 'POST',
      url: '/api/app/fax-bin/save-initial-patient-document',
      body: initialPatientDocument,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
