<div *ngIf="!isRefresh">
  <table id="tblFaxFinderList" datatable [dtOptions]="dtFaxFinderOptions" class="tbl hover row-border hover w-100">
    <thead>
      <tr>
        <th [matTooltip]="'Fax Id'">Fax Id</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Chart No'">Chart No</th>
        <th [matTooltip]="'Referral Source Name'">Referral Source Name</th>
        <th [matTooltip]="'Location'">Location</th>
        <th [matTooltip]="'Folder'">Folder</th>
        <th [matTooltip]="'Insurance'">Insurance</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of faxFinderDataArray">
        <td (dblclick)="onRowDoubleClickV1(data?.faxId || '-')" [matTooltip]="data?.faxId || '-'">{{data?.faxId || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClickV1(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">{{ data?.patientName || '-' }}</td>
        <td (dblclick)="onRowDoubleClickV1(data?.chartId || '-')" [matTooltip]="data?.chartId || '-'">{{ data?.chartId || '-' }}</td>
        <td (dblclick)="onRowDoubleClickV1(data?.referalsourceName || '-')" [matTooltip]="data?.referalsourceName || '-'">{{ data?.referalsourceName || '-' }}</td>
        <td (dblclick)="onRowDoubleClickV1(data?.locationName || '-')" [matTooltip]="data?.locationName || '-'">{{ data?.locationName || '-' }}</td>
        <td (dblclick)="onRowDoubleClickV1(data?.inboundStatus || '-')" [matTooltip]="data?.inboundStatus || '-'">{{ data?.inboundStatus || '-' }}</td>
        <td (dblclick)="onRowDoubleClickV1(data?.payorName || '-')" [matTooltip]="data?.payorName || '-'">{{ data?.payorName || '-' }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th><input matInput type="text" class="textbox-border-class" placeholder="Fax Id" /></th>
        <th>
          <input matInput type="text" class="textbox-border-class" placeholder="Patient Name" />
        </th>
        <th>
          <input matInput type="text" class="textbox-border-class" placeholder="Chart No" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Referral Source Name" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Location" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Folder" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Insurance" />
        </th>
      </tr>
    </tfoot>
  </table>
</div>
