import {
  CreateUpdateRequisitionDTO,
  RequisitionItemListDTO,
} from './../item-proxy/item-management/optimization/dto/models';
import { Observable, Subject, SubscribableOrPromise, Subscription } from 'rxjs';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { ShippingTypesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/shipping-types';
import { TableService } from 'projects/shared/src/app/table.service';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  ProductDetailsService,
  VendorDetailsService,
} from '../item-proxy/item-management/optimization';
import { map, startWith } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { RequisitionService } from '../item-proxy/item-management/optimization/requisition.service';
import { RequisitionProductDTO } from '../item-proxy/item-management/optimization/dto';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-requisition-approval',
  templateUrl: './requisition-approval.component.html',
  styleUrls: ['./requisition-approval.component.scss'],
})
export class RequisitionApprovalComponent implements OnInit {
  requisitionForm: FormGroup;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  notesText = '';
  requisitionTotal: string = '';
  requisitionDate: string = '';
  ltShippingMethods: any;
  lstBranch: any;
  ltVendors: any;
  searchCtrl: string = '';
  filteredVendorDetails: Observable<string[]> | undefined;
  loadTable = true;
  reqId: string;
  ltproducts: any = [];
  filteredProductDetails: Observable<string[]> | undefined;
  userId: string = defaultGuid;
  userName: string = '';
  tenantId: string = defaultGuid;
  isSaveBtnDisabled: boolean = false;
  requisitionItemsValidator: boolean = false;
  requisitionItems: RequisitionProductDTO[] = [];
  orderTableOptions = {
    responsive: true,
    pageLength: 10,
    lengthMenu: [10, 25, 50, 100, 200],
    order: [[0, 'asc']], // Sort based on the first column (index 0)
    columnDefs: [
      { targets: [0], visible: false }, // Hide the first column (index 0)
    ],
  };
  subscription$: Subscription[] = [];
  editableRowIndex: number | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private TableService: TableService,
    private shippingMethodService: ShippingTypesService,
    private branchService: OrganizationUnitService,
    private vendorService: VendorDetailsService,
    private productDetailsService: ProductDetailsService,
    private toastr: ToastrService,
    private requisitionService: RequisitionService,
    private tableService: TableService,
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.userId = localStorage.getItem('userId') ?? '';
    // this.tableService.getRequisitionForm().subscribe(res => {
    //   this.reqId = res[0]

    //   if(this.reqId){
    //     this.requisitionViewerFormUpdate()
    //   }
    // })

    this.initializeForms();
    this.loadUserDetails();
    this.loadDropdowns();
    this.getInventoryLimitDetails();

    this.validateItemsList();
  }

  //To Load the user details patch it to the requisition form
  loadUserDetails() {
    //To patch the default fields
    this.TableService.getLoginUserName().subscribe((val) => {
      this.userName = val;
      this.requisitionDate = moment(new Date()).format('MM/DD/YYYY');
      this.requisitionForm.patchValue({
        txtRequisitionDate: this.requisitionDate,
        txtRequestBy: this.userName,
      });
    });
  }
  initializeForms() {
    this.requisitionForm = this.formBuilder.group({
      txtId: new FormControl(''),
      txtRequisitionDate: new FormControl(''),
      txtRequestBy: new FormControl(''),
      drpShippingMethod: new FormControl('', [Validators.required]),
      drpLocation: new FormControl('', [Validators.required]),
      txtInventoryLimit: new FormControl(''),
      txtOnHandTotal: new FormControl(''),
      txtOnOrder: new FormControl(''),
      txtRequisitionTotal: new FormControl(''),
      txtSpecialInstructions: new FormControl(''),
      txtNoofDays: new FormControl(''),
      txtManufacturer: new FormControl(''),
      txtManufacturerFilter: new FormControl(''),
      txtProductFilter: new FormControl(''),
    });
  }
  //To Validate the Items List
  validateItemsList() {
    this.requisitionItemsValidator =
      this.requisitionItems.filter((a) => a.productId != '').length > 1
        ? true
        : false;
  }

  get isSubmitButtonEnabled(): boolean {
    // Check both form validity and items list condition
    return (
      this.requisitionForm.valid &&
      this.requisitionItems.filter((a) => a.productId != '').length > 0 &&
      this.requisitionItems
        .filter((a) => a.productId != '')
        .every(
          (item) =>
            item.thisOrder.toString() !== '0' &&
            item.thisOrder !== null &&
            item.thisOrder.toString() !== ''
        )
    );
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  //After View In it function
  ngAfterViewInit(): void {
    this.requisitionItems.push(this.addEmptyObject());
    this.dtTrigger.next();
    //this.reloadDatatable();
  }
  //Re Initialize the Datatable
  reloadDatatable() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns.adjust().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  //To Load the Dropdowns
  loadDropdowns() {
    //Load Shipping Methods
    const shippingMethodDetails = this.shippingMethodService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {

          this.ltShippingMethods = response?.items;
        },
        (err) => {

        }
      );
    this.subscription$.push(shippingMethodDetails);

    //Branch List
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        this.lstBranch = response;
      });
    this.subscription$.push(getBranchList);
    //Manufacturer List
    const vendorDetails = this.vendorService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltVendors = response?.items;
          this.filteredVendorDetails = this.requisitionForm
            .get('txtManufacturerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltVendors?.filter((option) =>
                  option?.vendorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {

        }
      );
    this.subscription$.push(vendorDetails);

    const productsList = this.productDetailsService
      .getProductListforStock()
      .subscribe(
        (response) => {

          this.ltproducts = response;
          this.filteredProductDetails = this.requisitionForm
            .get('txtProductFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltproducts?.filter((option) =>
                  option?.productName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {

        }
      );
    this.subscription$.push(productsList);
  }


  // requisitionViewerFormUpdate(){
  //   this.requisitionService.getRequisitionListByID(this.reqId).subscribe(response => {

  //     const loadTable  = response?.['itemList']
  //     this.requisitionForm.patchValue({
  //       txtRequisitionDate: response?.requisitionDate,
  //       txtRequestBy: this.userName,
  //       drpShippingMethod: response?.shippingMethodId,
  //       drpLocation: response?.destinationLocationId,
  //       txtInventoryLimit: response?.inventoryLimit,
  //       txtOnHandTotal: response?.onHandTotal,
  //       txtOnOrder: response?.onOrder,
  //       txtRequisitionTotal: response?.requisitionTotal,
  //       txtSpecialInstructions: response?.specialInstruction,
  //       txtNoofDays: response?.noOfDays
  //     });
  //     this.requisitionItems = response?.itemList

  //   })
  // }

  // get filteredOptions(): any[] {
  //   return this.ltproducts.filter((option) =>
  //     option.productName.toLowerCase().includes(this.searchCtrl.toLowerCase())
  //   );
  // }

  //To Load the Product Details based on the dropdown selection
  loadProductDetails(data: any, productId: string, thisordervalue: string) {
    if (
      !(
        this.requisitionItems.filter((a) => a.productId === productId).length >
        1
      )
    ) {
      //Get Product Details Based on the Product Id
      this.requisitionService
        .getProductDetailsBasedonProductId(productId)
        .subscribe(
          (response) => {

            let value = response?.[0];

            if (value != null && value != undefined) {
              data.availQty = value?.availQty;
              data.category = value?.category;
              data.dailyAvgDesp = value?.dailyAvgDesp;
              data.description = value?.description;
              data.extendedCost = value?.extendedCost;
              data.manufactureId = value?.manufactureId;
              data.manufacturer = value?.manufacturer;
              data.pendPatOrder = value?.pendPatOrder;
              data.productCategoryId = value?.productCategoryId;
              data.productCode = value?.productCode;
              data.productId = value?.productId;
              data.qtyOnHand = value?.qtyOnHand;
              data.quantity = value?.quantity;
              data.recOrderQty = value?.recOrderQty;
              data.status = value?.status;
              // data.thisOrder = thisordervalue;
              data.unitCost = value?.unitCost;

              this.requisitionItems.filter((a) => a.productId === '').length ===
                0 && this.requisitionItems.push(this.addEmptyObject());
              this.reloadDatatable();
              this.updateQuantity(data, thisordervalue);
            }
          },
          (err) => {

          }
        );
    } else {
      data.productId = '';
      this.toastr.warning('Product Already Added!');
    }
  }
  //Update Product Quantity Method
  updateQuantity(data: any, thisOrderValue: any) {

    data.thisOrder = thisOrderValue;

    this.calculateRequisitionTotal();
  }
  //Get Inventory Details
  getInventoryLimitDetails() {
    this.requisitionService.getInventoryLimit().subscribe((response) => {

      this.requisitionForm.patchValue({
        txtInventoryLimit: response?.inventoryLimit,
        txtOnHandTotal: response?.onHandTotal,
        txtOnOrder: response?.onOrder,
      });
    });
  }

  //To Calculate the requistion total and patch in the field
  calculateRequisitionTotal() {
    const totalCost: number = this.requisitionItems
      .filter((a) => a.productId != '')
      .reduce((acc, item) => acc + item.thisOrder * item.unitCost, 0);
    this.requisitionTotal = totalCost.toString();
    this.requisitionForm.patchValue({
      txtRequisitionTotal: this.requisitionTotal,
    });

  }

  //Method to add the Empty Row to the Table
  addEmptyObject(): RequisitionProductDTO {
    let value: RequisitionProductDTO = {
      sortNumber: this.requisitionItems.length + 1,
      availQty: 0,
      category: '',
      dailyAvgDesp: 0,
      description: '',
      extendedCost: 0,
      manufactureId: '',
      manufacturer: '',
      pendPatOrder: 0,
      productCategoryId: '',
      productCode: '',
      productId: '',
      qtyOnHand: 0,
      quantity: 0,
      recOrderQty: 0,
      status: '',
      thisOrder: 1,
      unitCost: 0,
    };
    return value;
  }
  //Method to submit the Requisition Form
  submitRequisition() {
    //this.requisitionItems.pop();
    // this.requisitionItems.filter(
    //   (a) => a.productId !== ''
    // );

    this.isSaveBtnDisabled = true;
    let RequisitionData: CreateUpdateRequisitionDTO = {
      requisitionId: defaultGuid,
      defaultRequisitionId: this.requisitionForm?.value?.txtId,
      requisitionDate: this.requisitionDate,
      requestedUserId: this.userId,
      shippingMethodId: this.requisitionForm?.value?.drpShippingMethod,
      destinationLocationId: this.requisitionForm?.value?.drpLocation,
      requisitionTotal: Number(this.requisitionTotal),
      itemList: this.requisitionItems.filter((a) => a.productId !== ''),
      noOfDays: null,
      specialInstruction: this.requisitionForm?.value?.txtSpecialInstructions,
      inventoryLimit: this.requisitionForm?.value?.txtInventoryLimit,
      onHandTotal: this.requisitionForm?.value?.txtOnHandTotal,
      onOrder: this.requisitionForm?.value?.txtOnOrder,
      requisitionStatusDetails: null,
    };

    this.requisitionService.create(RequisitionData).subscribe(
      (response) => {

        this.toastr.success('Requisition submitted successfully!');
        this.isSaveBtnDisabled = false;
        this.resetForm();
      },
      (err) => {

        this.isSaveBtnDisabled = false;
        Swal.fire({
          icon: 'info',
          text: 'Something Went Wrong!',
        });
      }
    );
  }

  resetForm() {
    this.requisitionForm.reset();
    this.getInventoryLimitDetails();
    this.loadUserDetails();
    this.requisitionItems = [];

    this.reloadDatatable();
    this.requisitionItems.push(this.addEmptyObject());
  }
  numberOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (
        value === null ||
        value === undefined ||
        Validators.required(control) !== null
      ) {
        // If the field is required or empty, don't perform number-only validation
        return null;
      }

      // Regular expression for numbers only
      const numberRegExp = /^[0-9]+$/;

      if (!numberRegExp.test(value.toString())) {
        return { numberOnly: true };
      }

      return null;
    };
  }
}
