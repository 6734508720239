import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import {
  AuthorizationOfOrderDTO,
  ClassificationDTO,
  ComplainceUsageMetDTO,
  CushionsPillowDTO,
  DispFiltersDTO,
  HeadGearDTO,
  InvLocationDTO,
  MaskOrderDTO,
  OrderMasterDropdownDTO,
  ReusableFiltersDTO,
  ShippingCarrierDTO,
  ShippingMethodDTO,
  StatusDTO,
  StopReasonDTO,
  TubingDTO,
  WaterChamberDTO,
  WipStateDTO
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { BranchOfficeDTO, FacilityDTO, HoldReasonDTO, PatientMasterDropdownDTO, PlaceOfServiceDTO, TaxZoneDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService, PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { ProviderDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/provider-dropdown-service/provider-dropdown.service';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { PatientDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { defaultCountry, OrderBillingStatus} from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SaleorderService } from '../order-proxy/order-management/order';
import { OrdersDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { addOrder, getOrder, updateOrder } from '../order-store/order.action';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [ListService]
})
export class OrderComponent implements OnInit, OnDestroy {
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() saveOrderFormState: EventEmitter<any> = new EventEmitter<any>();

  orderFormStatus: boolean;
  orderForm: FormGroup;
  // drpCountries: CountryDTO[] = [];
  // drpCity: CityDTO[] = [];
  // drpState: StateDTO[] = [];
  // drpCounty: CountyDTO[] = [];
  drpHoldReason: HoldReasonDTO[] = [];
  drpStopReason: StopReasonDTO[] = [];
  drpBranch: BranchOfficeDTO[] = [];
  drpInvLocation: InvLocationDTO[] = [];
  drpStatus: StatusDTO[] = [];
  drpClassification: ClassificationDTO[] = [];
  drpPlace: PlaceOfServiceDTO[] = [];
  drpShippingCarrier: ShippingCarrierDTO[] = [];
  drpShippingMethod: ShippingMethodDTO[] = [];
  drpComplaince: ComplainceUsageMetDTO[] = [];
  drpAuthorization: AuthorizationOfOrderDTO[] = [];
  drpMask: MaskOrderDTO[] = [];
  drpHead: HeadGearDTO[] = [];
  drpTubing: TubingDTO[] = [];
  drpPillow: CushionsPillowDTO[] = [];
  drpDisp: DispFiltersDTO[] = [];
  drpReusable: ReusableFiltersDTO[] = [];
  drpWater: WaterChamberDTO[] = [];
  drpWipState: WipStateDTO[] = []
  drpCountry: any[] = [];
  drpStates: any[] = [];
  drpCities: any[] = [];
  //  orderId: string = '';
  @Input() orderId: string = '';
  @Input() patientId: string = '';
  show: boolean = true;
  show1: boolean;
  notesText ='';

  @Output() orderIdEmitter: EventEmitter<OrdersDTO> = new EventEmitter<OrdersDTO>(null);
  tblNotesReponse: any[] = [];
  public strPageType: string = "order";
  CountryName: string = "";
  StateName: string = "";
  viewOrEdit: string = "";
  patientemailId: string = '';
  scheduledDate: Date;
  reScheduledDate: Date;
  county: string;
  fulfillmentVendor: string;
  accountNumber: string;
  shipBy: string;
  status: string;
  facility: string;
  drpTaxZone: TaxZoneDTO[];
  zone: string;
  generalHoldReason: string;
  generalStopReason: string;
  generalBranch: string;
  generalInvLocation: string;
  generalStatus: string;
  generalClassification: string;
  generalPlaceOfService: string;
  brightShipShippingCarrier: string;
  brightShipShippingMethod: string;
  customFieldsComplianceUsage: string;
  customFieldsAuthorizationOrder: string;
  customFieldsMaskOrder: string;
  customFieldsHeadGear: string;
  customFieldsTubing: string;
  customFieldsCushionsPillow: string;
  customFieldsDispFilters: string;
  customFieldsReusableFilters: string;
  customFieldsWaterChamber: string;
  workProgressWIPState: string;
  phoneCode: string = "";
  drpFacility: FacilityDTO[];
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  orderStatus: boolean = true;
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private Table: TableService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public list: ListService,
    private region: RegionDropdownService,
    private ProviderDropdown: ProviderDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientService: PatientService,
    private toastr: ToastrService,
    private store: Store,
    private saleOrderService: SaleorderService
  ) { }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  // ! Page Initialization funtions
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(response => {
      this.viewOrEdit = response.get('editOrView')
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })

    this.orderForm = this.formBuilder.group({
      Delivery: new FormGroup({
        txtCountryFilter: new FormControl(""),
        txtCityFilter: new FormControl(""),
        txtStateFilter: new FormControl(""),
        txtScheduledDate: new FormControl("", Validators.required),
        txtScheduledTime: new FormControl(""),
        txtActualDate: new FormControl("", Validators.required),
        txtActualTime: new FormControl(""),
        txtAddress: new FormControl(""),
        drpCity: new FormControl(""),
        // chkNotValidated: new FormControl(""),
        drpState: new FormControl(""),
        drpCounty: new FormControl(""),
        drpCountry: new FormControl(""),
        txtPostalCode: new FormControl(""),
        txtPhone: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtMobile: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtFacility: new FormControl("", this.textValidators.isTextCheck),
        txtTaxzone: new FormControl(""),
        txtTaxRate: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtOrderNote: new FormControl(""),
        txtDeliveryNote: new FormControl(""),
        drpSetupMethod: new FormControl(""),
        txtDeliveryTechnician: new FormControl("", [this.textValidators.isTextCheck]),
        chkSignatureRequired: new FormControl(""),
        drpFulfillmentVendor: new FormControl(""),
        drpAccountNumber: new FormControl(""),
        drpShipBy: new FormControl(""),
        txtStatus: new FormControl(""),
        txtStatusDate: new FormControl(""),
        txtdrpShipStatus: new FormControl("", [this.textValidators.isTextCheck]),
        chkCahPayment: new FormControl(""),
        chkValidateInsuranceStatus: new FormControl(true),
      }),
      General: new FormGroup({
        chkGeneralManualHold: new FormControl(""),
        drpGeneralHoldReason: new FormControl(""),
        txtGeneralStopDate: new FormControl(""),
        drpGeneralStopReason: new FormControl(""),
        drpGeneralBranch: new FormControl(""),
        drpGeneralInvLocation: new FormControl(""),
        drpGeneralStatus: new FormControl(""),
        drpGeneralClassification: new FormControl(""),
        drpGeneralPlaceOfService: new FormControl("", Validators.required),
        txtGeneralDateOfAdmission: new FormControl(""),
        txtGeneralDateOfDischarge: new FormControl(""),
        txtGeneralDiscountPct: new FormControl(0, [Validators.min(0), Validators.max(100), this.numberValidators.isNumberCheck]),
        txtGeneralPoNumber: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtGeneralReference: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser1: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser2: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser3: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralUser4: new FormControl("", [this.textValidators.isTextCheck]),
        txtGeneralPriorSystemKey: new FormControl("", [this.textValidators.isTextCheck]),
      }),
      AutoConfirm: new FormGroup({
        chkAutoConfirmAutoConfirm: new FormControl(""),
        txtAutoConfirmInitialDate: new FormControl(""),
        PointDelivery: new FormGroup({
          txtPointDeliveryOrderStatus: new FormControl("", [this.textValidators.isTextCheck]),
          txtPointDeliveryStatusDateTime: new FormControl(""),
          txtPointDeliveryLastMessage: new FormControl("", [this.textValidators.isTextCheck]),
          txtPointDeliveryLastMessageDateTime: new FormControl(""),
        }),
        BrightShip: new FormGroup({

          txtBrightShipShippingStatus: new FormControl("", [this.textValidators.isTextCheck]),
          drpBrightShipShippingCarrier: new FormControl(""),
          drpBrightShipShippingMethod: new FormControl(""),
        }),
        WorkProgress: new FormGroup({
          txtWorkProgressDaysInState: new FormControl("", [this.textValidators.isTextCheck]),
          drpWorkProgressWIPState: new FormControl(""),
          txtWorkProgressAssignedTo: new FormControl("", [this.textValidators.isTextCheck]),
          txtWorkProgressDateNeeded: new FormControl(""),
          chkWorkProgressCompleted: new FormControl(""),
          txtRescheduleDate: new FormControl("")
        }),
      }),
      AuditTrial: new FormGroup({
        txtAuditTrialOrderNumber: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtAuditTrialType: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialDateCreated: new FormControl(""),
        txtAuditTrialCreatedBy: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialLastPrinted: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialPrintedBy: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialConfirmedBy: new FormControl("", [this.textValidators.isTextCheck]),
        txtAuditTrialConfirmedDate: new FormControl(""),
        txtAuditTrialPrevBilling: new FormControl(""),
        txtAuditTrialNextBilling: new FormControl(""),
        drpCustomFieldsComplianceUsage: new FormControl(""),
        drpCustomFieldsAuthorizationOrder: new FormControl(""),
        drpCustomFieldsMaskOrder: new FormControl(""),
        drpCustomFieldsHeadGear: new FormControl(""),
        drpCustomFieldsTubing: new FormControl(""),
        drpCustomFieldsCushionsPillow: new FormControl(""),
        drpCustomFieldsDispFilters: new FormControl(""),
        drpCustomFieldsReusableFilters: new FormControl(""),
        drpCustomFieldsWaterChamber: new FormControl("")
        // txtCustomFieldsCpapBpapSettings: new FormControl(""),
        // txtCustomFieldsO2LiterFlow: new FormControl(""),
        // txtCustomFieldsHomeHealthAgency: new FormControl(""),
        // txtCustomFieldsMcareAuditLevel: new FormControl(""),
        // drpCustomFieldsSpecialityItemsRcm: new FormControl(""),
        // drpCustomFieldsBasicHcpc: new FormControl(""),
        // drpCustomFieldsGrp3Pmdu: new FormControl(""),
      }),
      Tracking: new FormGroup({
        txtTrackingCarrier: new FormControl(""),
        txtTrackingTrackingNo: new FormControl("", [this.numberValidators.isNumberCheck]),
      }),
    });


    this.orderForm.statusChanges.subscribe(value => {
      this.orderFormStatus = value === 'INVALID' ? true : false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    if (this.viewOrEdit == "false") {
      this.orderForm.disable();
    } else {
      this.orderForm.enable();
    }
    // this.orderId != null && this.orderId != "" && this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
    //

    //   if (response?.orderState?.selectedOrder?.patients.personals.isCashPayment === 0 &&
    //     response?.orderState?.selectedOrder?.order?.isCompleted !== 1) {
    //     this.checkCompleted(response)
    //   }
    //   if (response?.orderState?.selectedOrder?.patients.personals.isCashPayment === 1 &&
    //     response?.orderState?.selectedOrder?.orders?.isCompleted !== 1) {
    //     this.show = false;
    //     this.show1 = true;
    //   }
    //   if (response?.orderState?.selectedOrder?.orders?.isCompleted === 1) {
    //     this.show = true;
    //     this.show1 = false;
    //   }
    // })

    this.getDropdown();
    this.orderId != "" && this.orderId != null && this.orderId != undefined && this.getOrderByid();
    this.countryDropdown();
    this.clearCountry();


    // if (this.orderForm.invalid || this.orderForm.dirty) {
    //   this.saveOrderFormState.emit(this.orderForm);
    // }
    this.scheduledDate = new Date();
    this.scheduledDate.setDate(this.scheduledDate.getDate() + 0);
    this.reScheduledDate = new Date();
    this.reScheduledDate.setDate(this.reScheduledDate.getDate() + 1);

    if (this.orderId != "" && this.orderId != null && this.orderId != undefined) {
      const orderGet = this.saleOrderService.get(this.orderId).subscribe(response => {
        // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
        const orderResponse = response;
        if (orderResponse.orderProcessStatus === "Ready For Order Complete Process") {
          this.show = false;
          this.show1 = true;
        } else {
          this.show = true;
          this.show1 = false;
        }
        //  else{
        //   this.show1 = true;
        // }

      }, () => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      })
      this.subscription$.push(orderGet);
    }
  }

  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  
  isCashPatientPayment() {
    const getPatient = this.patientService.get(this.patientId).subscribe(response => {
      // this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      this.orderForm.patchValue({
        Delivery: {
          chkCahPayment: response?.personals.isCashPayment,
        }
      })
    }, () => {
    })
    this.subscription$.push(getPatient)
  }


  onChangeHour() {
  }

  checkCreateEditAndGetPatientData() {
    this.activatedRoute.params.pipe(filter(value => { return value && value?.patientId !== "" && value?.patientId !== undefined && value?.patientId !== null }),
      switchMap(value => {
        this.patientId = value?.patientId;
        return this.patientService.get(this.patientId);
      })).subscribe(response => {
        const selectedPatient: PatientDTO = response;
        this.patientemailId = selectedPatient?.personals?.emailId ?? "";
        this.patchDummyData(selectedPatient);
        this.Table.setOrderCountryTable(selectedPatient?.addresses[1]);
      }, () => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      });
    this.activatedRoute.params.pipe(filter(value => { return value && value?.orderId !== "" && value?.orderId !== undefined && value?.orderId !== null }),
      switchMap(value => {
        this.orderId = value?.orderId ?? "";
        return this.saleOrderService.get(this.orderId);
      })).subscribe(response => {
        const selectedPatient: PatientDTO = response?.patients;

        this.patchDummyData(selectedPatient);
        this.Table.setOrderCountryTable(selectedPatient?.addresses[1]);

      }, () => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      });


  }
  patchDummyData(orderDetail: PatientDTO | PatientDTO) {
    this.orderForm.patchValue({
      Delivery: {
        txtAddress: orderDetail?.addresses[1]?.address,
        drpCity: orderDetail?.addresses[1]?.city === "" || undefined ? null : orderDetail?.addresses[1]?.city,
        drpCounty: orderDetail?.addresses[1]?.county === "" || undefined ? null : orderDetail?.addresses[1]?.county,
        drpCountry: orderDetail?.addresses[1]?.country === "" || undefined ? null : orderDetail?.addresses[1]?.country,
        drpState: orderDetail?.addresses[1]?.state === "" || undefined ? null : orderDetail?.addresses[1]?.state,
        txtPostalCode: orderDetail?.addresses[1]?.postalCode,
        txtPhone: orderDetail?.addresses[1]?.phone,
        txtMobile: orderDetail?.addresses[1]?.mobile,
        // chkCahPayment: orderDetail?.personals?.isCashPayment,
        txtFacility: orderDetail?.personals?.facility,
        txtTaxzone: orderDetail?.personals?.taxZone,
      },
      General: {
        drpGeneralBranch: orderDetail?.personals?.branchOffice,
        // drpGeneralPlaceOfService: orderDetail?.personals?.placeOfService,
        txtGeneralDateOfAdmission: orderDetail?.personals?.dateOfAdmission,
        txtGeneralDateOfDischarge: orderDetail?.personals?.dateOfDischarge,
        txtGeneralDiscountPct: orderDetail?.personals?.discountPercentage,
        // txtGeneralUser1: orderDetail?.personals?.user1,
        // txtGeneralUser2: orderDetail?.personals?.user2,
        // txtGeneralUser3: orderDetail?.personals?.user3,
        // txtGeneralUser4: orderDetail?.personals?.user4,
        txtGeneralPriorSystemKey: orderDetail?.personals?.priorSystemKeyFacility,
      }

    });
    if (this.orderId === "" || this.orderId === null || this.orderId === undefined) {
      this.isCashPatientPayment();

    } else {
      // this.orderId != "" && this.orderId != null && this.orderId != undefined && this.isCashOrderPayment();

    }
  }

  // ! Master Dropdown Funtion
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      // OrderDropdowns.HoldReasons,
      OrderDropdowns.StopReasons,
      OrderDropdowns.Statuses,
      OrderDropdowns.Classifications,
      // OrderDropdowns.PlaceOfServices,
      OrderDropdowns.ShippingCarriers,
      OrderDropdowns.ShippingMethods,
      OrderDropdowns.ComplainceUsageMets,
      OrderDropdowns.AuthorizationOfOrders,
      OrderDropdowns.MaskOrders,
      OrderDropdowns.HeadGears,
      OrderDropdowns.Tubings,
      OrderDropdowns.CushionsPillows,
      OrderDropdowns.DispFilters,
      OrderDropdowns.ReusableFilters,
      OrderDropdowns.WaterChambers,
      OrderDropdowns.WipStates,
      OrderDropdowns.InvLocations
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      const response: OrderMasterDropdownDTO = stateResponse;
      //  this.drpCountries = response?.countries;
      //  this.drpCity = response?.cities;
      //  this.drpState = response?.states;
      //  this.drpCounty = response?.counties;
      // this.drpHoldReason = response?.holdReasons;
      this.drpStopReason = response?.stopReasons;
      // this.drpBranch = response?.branches;
      // this.drpInvLocation = response?.invLocations;
      this.drpStatus = response?.statuses;
      this.drpClassification = response?.classifications;
      // this.drpPlace = response?.placeOfServices;
      this.drpShippingCarrier = response?.shippingCarriers;
      this.drpShippingMethod = response?.shippingMethods;
      this.drpComplaince = response?.complainceUsageMets;
      this.drpAuthorization = response?.authorizationOfOrders;
      this.drpMask = response?.maskOrders;
      this.drpHead = response?.headGears;
      this.drpTubing = response?.tubings;
      this.drpPillow = response?.cushionsPillows;
      this.drpDisp = response?.dispFilters;
      this.drpReusable = response?.reusableFilters;
      this.drpWater = response?.waterChambers;
      this.drpWipState = response?.wipStates;
      this.drpInvLocation = response?.invLocations;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(orderDropdown);
    //
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.BranchOffices,
      PatientDropdowns.Facilities,
      PatientDropdowns.TaxZones,
      PatientDropdowns.HoldReasons,
      PatientDropdowns.PlaceOfServices

    ]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.drpBranch = response?.branchOffices;
      this.drpFacility = response?.facilities;
      this.drpTaxZone = response.taxZones;
      this.drpPlace = response?.placeOfServices;
      this.drpHoldReason = response?.lstHoldReasons

      // this.drpCity = response?.cities;
      // this.drpState = response?.states;
      // this.drpCounty = response?.counties;
      // this.drpCountries = response?.countries;

      this.checkCreateEditAndGetPatientData();
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$.push(patientDropdown);
    //
    const getCountryTable = this.Table.getCountryTable().subscribe(value => {
      if (value && value?.addresses && value?.addresses[1]) {
        this.CountryName = value?.addresses[1]?.country;
        this.StateName = value?.addresses[1]?.state;
      }
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    this.subscription$.push(getCountryTable);

    this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined && this.onChangeState(this.CountryName)
    this.StateName != null && this.StateName !== "" && this.StateName != undefined && this.onChangeCity(this.StateName, this.CountryName)
  }

  public filteredCountries: Observable<RegionCountriesDTO[]>;
  public filteredStates: Observable<RegionStatesDTO[]>;
  public filteredCities: Observable<RegionCitiesDTO[]>;
  @ViewChild('singleSelect') singleSelect: MatSelect;

  // Country Dropdown
  countryDropdown() {
    this.orderForm.patchValue({ "drpCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpStates = [];
    this.drpCountry = [];
    this.drpCities = [];
    const countryDropdown = this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response?.items;
      this.filteredCountries = this.orderForm?.get("Delivery.txtCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(countryDropdown);

  }
  Country: string = '';
  State: string = '';
  City: string = '';
  clearCountry() {
    this.Country = '';
    this.phoneCode = "";
    const delivery: any = this.orderForm.controls.Delivery;
    const deliveryFormGroup: FormGroup = delivery;
    deliveryFormGroup.controls.txtMobile.disable();
    deliveryFormGroup.controls.txtPhone.disable();
    this.orderForm.patchValue({
      Delivery: {
        drpCountry: null
      }
    });
  }
  clearTaxZone() {
    this.zone = '';
    this.orderForm.patchValue({
      Delivery: {
        txtTaxZone: null
      }
    });

  }
  clearState() {
    this.State = '';
    this.orderForm.patchValue({
      Delivery: {
        drpState: null
      }
    });
  }
  clearCity() {
    this.City = '';
    this.orderForm.patchValue({
      Delivery: {
        drpCity: null
      }
    });
  }

  // city state
  onChangeState(value: string | null | undefined): boolean {
    this.orderForm.patchValue({
      Delivery: {
        drpCity: null,
        drpState: null
      }
    });
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const getState = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpStates = response?.items;
        const delivery: any = this.orderForm.controls.Delivery;
        const deliveryFormGroup: FormGroup = delivery;
        if (response?.items?.length == 0) {
          this.phoneCode = "";
          deliveryFormGroup.controls.txtMobile.disable();
          deliveryFormGroup.controls.txtPhone.disable();
        } else {
          deliveryFormGroup.controls.txtMobile.enable();
          deliveryFormGroup.controls.txtPhone.enable();
          this.phoneCode = response?.items[0]?.countryPhoneCode
        }
        this.filteredStates = this.orderForm?.get("Delivery.txtStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpStates.filter(option => option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(getState);
    }
    return this.drpStates && this.drpStates?.length !== 0;
  }

  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.orderForm.patchValue({
      Delivery: {
        drpCity: null
      }
    });
    country = (country == "") ? (this.orderForm.value.Delivery.drpCountry == (null || undefined) ? "" : (this.orderForm.value.Delivery.drpCountry)) : country;
    this.drpCities = [];
    if (state !== null && state !== undefined) {
      const getCities = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {

        this.drpCities = response?.items;
        this.filteredCities = this.orderForm?.get("Delivery.txtCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getCities);
    }
    return this.drpCities && this.drpCities?.length !== 0;
  }


  verificationcondition: boolean = true;

  saleorderId: number = 0;
  organizationUnitId = null;
  // ! Save Order Page Details
  saveOrder() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    let patients: PatientDTO;
    const getPatient = this.patientService.get(this.patientId).subscribe(response => {
      // this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      patients = response;
      // personal = response?.patientState.selectedPatient;
      // personal = { ...personal }

      // let patient: PatientDTO = response?.patientState.selectedPatient;

      // let updatePatientPersonaldata: PersonalDTO = patient?.personals;
      // updatePatientPersonaldata = { branchOffice: this.orderForm.value.General.drpGeneralBranch, ...updatePatientPersonaldata }
      // let updatePatientAddressdata: AddressDTO[] = patient?.addresses;
      // updatePatientAddressdata = [

      //   {
      //     addressType: updatePatientAddressdata[1].addressType,
      //     address: this.orderForm.value.Delivery.txtAddress,
      //     city: this.orderForm.value.Delivery.drpCity === null || this.orderForm.value.Delivery.drpCity === undefined ? "" : this.orderForm.value.Delivery.drpCity,
      //     state: this.orderForm.value.Delivery.drpState === null || this.orderForm.value.Delivery.drpState === undefined ? "" : this.orderForm.value.Delivery.drpState,
      //     county: this.orderForm.value.Delivery.drpCounty === null || this.orderForm.value.Delivery.drpCounty === undefined ? "" : this.orderForm.value.Delivery.drpCounty,
      //     country: this.orderForm.value.Delivery.drpCountry === null || this.orderForm.value.Delivery.drpCountry === undefined ? "" : this.orderForm.value.Delivery.drpCountry,
      //     postalCode: this.orderForm.value.Delivery.txtPostalCode,
      //     phone: this.orderForm.value.Delivery.txtPhone,
      //     mobile: this.orderForm.value.Delivery.txtMobile,
      //     relationship: updatePatientAddressdata[1].relationship,
      //     fax: updatePatientAddressdata[1].fax,
      //     // emailId: updatePatientAddressdata[1].emailId,
      //     zone: updatePatientAddressdata[1].zone,
      //   },
      //   updatePatientAddressdata[0]];

      // patient = {
      //   id: patient?.id,
      //   // patientId:this.patientId,
      //   personals: updatePatientPersonaldata,
      //   addresses: updatePatientAddressdata,
      //   contacts: patient?.contacts,
      //   clinicals: patient?.clinicals,
      //   insurances: patient?.insurances,
      //   notes: patient?.notes,
      //   policies: patient?.policies,
      //   patientDocuments: patient?.patientDocuments,
      //   defaultPatientId: patient?.defaultPatientId,
      //   insuranceVerifications: patient?.insuranceVerifications,
      //   authorizations: patient?.authorizations,
      //   organizationUnitId: this.organizationUnitId,
      //   tenantId: patient?.tenantId,
      //   isAutoIntake: patient?.isAutoIntake,
      //   autoIntakeDocumentDetails: patient?.autoIntakeDocumentDetails,
      //   chartId: patient?.chartId,
      //   billingDetails: patient?.billingDetails,
      //   patientLockDetails: patient?.patientLockDetails,
      //   isSorted: patient?.isSorted,
      // }

      // setTimeout(() => {
      //   // this.store.dispatch(new updatePatient(this.patientId, patient)).subscribe(response => {
      //   personal = response?.patientState.selectedPatient;
      //   personal = { ...personal }

      //   setTimeout(() => {


      if (this.saleorderId == 0 && this.orderId === "" || this.orderId === null || this.orderId === undefined) {
        // personal = { ...personal }

        const orderData: SaleorderDTO = {
          orders: {

            // saleorderId: this.saleorderId,
            scheduledDate: this.orderForm.value.Delivery.txtScheduledDate,
            scheduledTime: this.orderForm.value.Delivery.txtScheduledTime,
            actualDate: this.orderForm.value.Delivery.txtActualDate,
            actualTime: this.orderForm.value.Delivery.txtActualTime,
            address: this.orderForm.value.Delivery.txtAddress,
            city: this.orderForm.value.Delivery.drpCity === null || undefined ? "" : this.orderForm.value.Delivery.drpCity,
            state: this.orderForm.value.Delivery.drpState === null || undefined ? "" : this.orderForm.value.Delivery.drpState,
            county: this.orderForm.value.Delivery.drpCounty === null || undefined ? "" : this.orderForm.value.Delivery.drpCounty,
            country: this.orderForm.value.Delivery.drpCountry === null || undefined ? "" : this.orderForm.value.Delivery.drpCountry,
            postalCode: this.orderForm.value.Delivery.txtPostalCode,
            phone: this.orderForm.value.Delivery.txtPhone,
            mobile: this.orderForm.value.Delivery.txtMobile,
            facilityId: this.orderForm.value.Delivery.txtFacility,
            taxZoneId: this.orderForm.value.Delivery.txtTaxzone,
            taxRate: this.orderForm.value.Delivery.txtTaxRate,
            orderNote: this.orderForm.value.Delivery.txtOrderNote,
            deliveryNote: this.orderForm.value.Delivery.txtDeliveryNote,
            setupMethodId: this.orderForm.value.Delivery.drpSetupMethod,
            deliveryTechnicianId: this.orderForm.value.Delivery.txtDeliveryTechnician,
            dropShipStatus: this.orderForm.value.Delivery.txtdrpShipStatus,
            isSignatureRequired: +(this.orderForm.value.Delivery.chkSignatureRequired) | 0,
            fulfillmentVendor: this.orderForm.value.Delivery.drpFulfillmentVendor,
            accountNumber: this.orderForm.value.Delivery.drpAccountNumber,
            shipBy: this.orderForm.value.Delivery.drpShipBy,
            status: this.orderForm.value.Delivery.txtStatus,


            isManualHold: +(this.orderForm.value.General.chkGeneralManualHold) | 0,
            holdReasonId: this.orderForm.value.General.drpGeneralHoldReason,
            stopDate: this.orderForm.value.General.txtGeneralStopDate,
            stopReasonId: this.orderForm.value.General.drpGeneralStopReason,
            branchId: this.orderForm.value.General.drpGeneralBranch,
            invLocationId: this.orderForm.value.General.drpGeneralInvLocation,
            statusId: this.orderForm.value.General.drpGeneralStatus,
            classificationId: this.orderForm.value.General.drpGeneralClassification,
            placeOfServiceId: this.orderForm.value.General.drpGeneralPlaceOfService,
            dateOfAdmission: this.orderForm.value.General.txtGeneralDateOfAdmission,
            dateOfDischarge: this.orderForm.value.General.txtGeneralDateOfDischarge,
            discountPct: this.orderForm.value.General.txtGeneralDiscountPct == "" ? '0' : this.orderForm.value.General.txtGeneralDiscountPct,
            poNumber: this.orderForm.value.General.txtGeneralPoNumber,
            reference: this.orderForm.value.General.txtGeneralReference,
            user1: this.orderForm.value.General.txtGeneralUser1,
            user2: this.orderForm.value.General.txtGeneralUser2,
            user3: this.orderForm.value.General.txtGeneralUser3,
            user4: this.orderForm.value.General.txtGeneralUser4,
            priorSystemKey: this.orderForm.value.General.txtGeneralPriorSystemKey,

            orderStatus: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryOrderStatus,
            statusDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryStatusDateTime,
            lastMessage: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessage,
            lastMessageDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessageDateTime,
            orderNumber: this.orderForm.value.AuditTrial.txtAuditTrialOrderNumber,
            type: this.orderForm.value.AuditTrial.txtAuditTrialType,
            dateCreated: this.orderForm.value.AuditTrial.txtAuditTrialDateCreated,
            createdBy: this.orderForm.value.AuditTrial.txtAuditTrialCreatedBy,
            lastPrinted: this.orderForm.value.AuditTrial.txtAuditTrialLastPrinted,
            printedBy: this.orderForm.value.AuditTrial.txtAuditTrialPrintedBy,
            confirmedBy: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedBy,
            confirmedDate: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedDate,
            prevBilling: this.orderForm.value.AuditTrial.txtAuditTrialPrevBilling,
            nextBilling: this.orderForm.value.AuditTrial.txtAuditTrialNextBilling,
            shippingStatus: this.orderForm.value.AutoConfirm.BrightShip.txtBrightShipShippingStatus,
            shippingCarrierId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingCarrier,
            shippingMethodId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingMethod,
            daysInState: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDaysInState,
            wipStateId: this.orderForm.value.AutoConfirm.WorkProgress.drpWorkProgressWIPState,
            assignedToId: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressAssignedTo,
            dateNeeded: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDateNeeded,
            // :drpFulfillmentVendor
            isCompleted: this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === true || this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === 1 ? "Done" : "Pending",
            complianceUsageMetId: this.orderForm.value.AuditTrial.drpCustomFieldsComplianceUsage,
            authorizationOfOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsAuthorizationOrder,
            maskOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsMaskOrder,
            headGearId: this.orderForm.value.AuditTrial.drpCustomFieldsHeadGear,
            tubingId: this.orderForm.value.AuditTrial.drpCustomFieldsTubing,
            cushionsPillowId: this.orderForm.value.AuditTrial.drpCustomFieldsCushionsPillow,
            dispFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsDispFilters,
            reusableFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsReusableFilters,
            waterChamberId: this.orderForm.value.AuditTrial.drpCustomFieldsWaterChamber,
            carrierId: this.orderForm.value.Tracking.txtTrackingCarrier,
            trackingNo: this.orderForm.value.Tracking.txtTrackingTrackingNo,
            isValidateInsuranceStatus: this.verificationcondition,
            reScheduledDate: this.orderForm.value.AutoConfirm.WorkProgress.txtRescheduleDate
          },
          insurances: [],
          clinicals: [],
          items: [],
          // saleorderId:0,
          orderStatus: "",
          saleorderId: "",
          organizationUnitId: this.organizationUnitId,
          currentStage: "orderProcessing",
          currentStageStatus: "",
          patients,
          defaultClaimId: "",
          claimId: "00000000-0000-0000-0000-000000000000",
          patientId: this.patientId,
          isShippingApprove: 0,
          isCashPayment: +this.orderForm.value.Delivery.chkCahPayment | 0,
          orderProcessStatus: "",
          patientLedgerReason: "",
          documentReason: "",
          paymentTransactionDetails: null,
          payPalMailResendCount: 0,
          payPalPaymentMethodType: null,
          payPalPaymentStatus: null,
          shippingCheckList: null,
          deliveryTicket: null,
          orderBilling: null,
          authType: ""
          // saleorderId:0
        }
        this.saveOrUpdate(orderData);
      }
      else {
        let selectedOrder: SaleorderDTO;
        (this.orderId != "" && this.orderId != null && this.orderId != undefined) && this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
          selectedOrder = response?.orderState?.selectedOrder;


          // personal = { ...personal }
          const orderData = {
            orders: {
              // saleorderId: selectedOrder?.orders[0].saleorderId,
              // saleorderId:0,
              scheduledDate: this.orderForm.value.Delivery.txtScheduledDate,
              scheduledTime: this.orderForm.value.Delivery.txtScheduledTime,
              actualDate: this.orderForm.value.Delivery.txtActualDate,
              actualTime: this.orderForm.value.Delivery.txtActualTime,
              address: this.orderForm.value.Delivery.txtAddress,
              city: this.orderForm.value.Delivery.drpCity === null || undefined ? "" : this.orderForm.value.Delivery.drpCity,
              state: this.orderForm.value.Delivery.drpState === null || undefined ? "" : this.orderForm.value.Delivery.drpState,
              county: this.orderForm.value.Delivery.drpCounty === null || undefined ? "" : this.orderForm.value.Delivery.drpCounty,
              country: this.orderForm.value.Delivery.drpCountry === null || undefined ? "" : this.orderForm.value.Delivery.drpCountry,
              postalCode: this.orderForm.value.Delivery.txtPostalCode,
              phone: this.orderForm.value.Delivery.txtPhone,
              mobile: this.orderForm.value.Delivery.txtMobile,
              facilityId: this.orderForm.value.Delivery.txtFacility,
              taxZoneId: this.orderForm.value.Delivery.txtTaxzone,
              taxRate: this.orderForm.value.Delivery.txtTaxRate,
              orderNote: this.orderForm.value.Delivery.txtOrderNote,
              deliveryNote: this.orderForm.value.Delivery.txtDeliveryNote,
              setupMethodId: this.orderForm.value.Delivery.drpSetupMethod,
              deliveryTechnicianId: this.orderForm.value.Delivery.txtDeliveryTechnician,
              dropShipStatus: this.orderForm.value.Delivery.txtdrpShipStatus,
              isSignatureRequired: +(this.orderForm.value.Delivery.chkSignatureRequired) | 0,
              fulfillmentVendor: this.orderForm.value.Delivery.drpFulfillmentVendor,
              accountNumber: this.orderForm.value.Delivery.drpAccountNumber,
              shipBy: this.orderForm.value.Delivery.drpShipBy,
              status: this.orderForm.value.Delivery.txtStatus,
              isManualHold: +(this.orderForm.value.General.chkGeneralManualHold) | 0,
              holdReasonId: this.orderForm.value.General.drpGeneralHoldReason,
              stopDate: this.orderForm.value.General.txtGeneralStopDate,
              stopReasonId: this.orderForm.value.General.drpGeneralStopReason,
              branchId: this.orderForm.value.General.drpGeneralBranch,
              invLocationId: this.orderForm.value.General.drpGeneralInvLocation,
              statusId: this.orderForm.value.General.drpGeneralStatus,
              classificationId: this.orderForm.value.General.drpGeneralClassification,
              placeOfServiceId: this.orderForm.value.General.drpGeneralPlaceOfService,
              dateOfAdmission: this.orderForm.value.General.txtGeneralDateOfAdmission,
              dateOfDischarge: this.orderForm.value.General.txtGeneralDateOfDischarge,
              discountPct: this.orderForm.value.General.txtGeneralDiscountPct == "" ? '0' : this.orderForm.value.General.txtGeneralDiscountPct,
              poNumber: this.orderForm.value.General.txtGeneralPoNumber,
              reference: this.orderForm.value.General.txtGeneralReference,
              user1: this.orderForm.value.General.txtGeneralUser1,
              user2: this.orderForm.value.General.txtGeneralUser2,
              user3: this.orderForm.value.General.txtGeneralUser3,
              user4: this.orderForm.value.General.txtGeneralUser4,
              priorSystemKey: this.orderForm.value.General.txtGeneralPriorSystemKey,
              orderStatus: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryOrderStatus,
              statusDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryStatusDateTime,
              lastMessage: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessage,
              lastMessageDateTime: this.orderForm.value.AutoConfirm.PointDelivery.txtPointDeliveryLastMessageDateTime,
              orderNumber: this.orderForm.value.AuditTrial.txtAuditTrialOrderNumber,
              type: this.orderForm.value.AuditTrial.txtAuditTrialType,
              dateCreated: this.orderForm.value.AuditTrial.txtAuditTrialDateCreated,
              createdBy: this.orderForm.value.AuditTrial.txtAuditTrialCreatedBy,
              lastPrinted: this.orderForm.value.AuditTrial.txtAuditTrialLastPrinted,
              printedBy: this.orderForm.value.AuditTrial.txtAuditTrialPrintedBy,
              confirmedBy: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedBy,
              confirmedDate: this.orderForm.value.AuditTrial.txtAuditTrialConfirmedDate,
              prevBilling: this.orderForm.value.AuditTrial.txtAuditTrialPrevBilling,
              nextBilling: this.orderForm.value.AuditTrial.txtAuditTrialNextBilling,
              shippingStatus: this.orderForm.value.AutoConfirm.BrightShip.txtBrightShipShippingStatus,
              shippingCarrierId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingCarrier,
              shippingMethodId: this.orderForm.value.AutoConfirm.BrightShip.drpBrightShipShippingMethod,
              daysInState: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDaysInState,
              wipStateId: this.orderForm.value.AutoConfirm.WorkProgress.drpWorkProgressWIPState,
              assignedToId: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressAssignedTo,
              dateNeeded: this.orderForm.value.AutoConfirm.WorkProgress.txtWorkProgressDateNeeded,
              isCompleted: this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === true || this.orderForm.value.AutoConfirm.WorkProgress.chkWorkProgressCompleted === 1 ? "Done" : "Pending",
              complianceUsageMetId: this.orderForm.value.AuditTrial.drpCustomFieldsComplianceUsage,
              authorizationOfOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsAuthorizationOrder,
              maskOrderId: this.orderForm.value.AuditTrial.drpCustomFieldsMaskOrder,
              headGearId: this.orderForm.value.AuditTrial.drpCustomFieldsHeadGear,
              tubingId: this.orderForm.value.AuditTrial.drpCustomFieldsTubing,
              cushionsPillowId: this.orderForm.value.AuditTrial.drpCustomFieldsCushionsPillow,
              dispFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsDispFilters,
              reusableFiltersId: this.orderForm.value.AuditTrial.drpCustomFieldsReusableFilters,
              waterChamberId: this.orderForm.value.AuditTrial.drpCustomFieldsWaterChamber,
              carrierId: this.orderForm.value.Tracking.txtTrackingCarrier,
              trackingNo: this.orderForm.value.Tracking.txtTrackingTrackingNo,
              isValidateInsuranceStatus: this.verificationcondition,
              reScheduledDate: this.orderForm.value.AutoConfirm.WorkProgress.txtRescheduleDate
            },
            insurances: selectedOrder?.insurances,
            clinicals: selectedOrder?.clinicals,
            items: selectedOrder?.items,
            orderStatus: selectedOrder?.orderStatus,
            saleorderId: selectedOrder?.saleorderId,
            patients: selectedOrder?.patients,
            defaultClaimId: "",
            claimId: "00000000-0000-0000-0000-000000000000",
            organizationUnitId: this.organizationUnitId,
            // saleorderId:0
            // saleorderId:0
            currentStage: (selectedOrder?.currentStage === "orderProcessing" && selectedOrder?.orders?.isCompleted === "1") ? "orderProcessing" : "shippingApproval",
            currentStageStatus: selectedOrder?.currentStageStatus,
            patientId: selectedOrder?.patientId,
            isShippingApprove: selectedOrder?.isShippingApprove,
            isCashPayment: +this.orderForm.value.Delivery.chkCahPayment | 0,
            orderProcessStatus: selectedOrder?.orderProcessStatus,
            patientLedgerReason: selectedOrder?.patientLedgerReason,
            documentReason: selectedOrder?.documentReason,
            paymentTransactionDetails: selectedOrder?.paymentTransactionDetails,
            payPalMailResendCount: selectedOrder?.payPalMailResendCount,
            payPalPaymentMethodType: selectedOrder?.payPalPaymentMethodType,
            payPalPaymentStatus: selectedOrder?.payPalPaymentStatus,
            claimHistoryStatus: selectedOrder?.claimHistoryStatus,
            patientLedgerStatus: selectedOrder?.patientLedgerStatus,
            patientHistoryReason: selectedOrder?.patientHistoryReason,
            shippingCheckList: selectedOrder?.shippingCheckList,
            deliveryTicket: selectedOrder?.deliveryTicket,
            orderBilling: selectedOrder?.orderBilling,
            authType: selectedOrder?.authType
          }
          this.saveOrUpdate(orderData);
        });
      }
    });
    this.subscription$.push(getPatient);


  }
  stateName: string = '';
  // ! order view and edit //!
  getOrderByid() {
    if (this.orderId != "" && this.orderId != null && this.orderId != undefined) {
      const orderGet = this.saleOrderService.get(this.orderId).subscribe(response => {
        // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
        const selectedOrder: SaleorderDTO = response;
        const orderDetail: OrdersDTO = selectedOrder?.orders;
        // this.orderStatus = (selectedOrder?.orderBilling?.status === billingStatus.OrderPending || selectedOrder?.orderBilling?.status === billingStatus.ReadyToBilling) ? true : false;
        const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
        this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (selectedOrder?.orderBilling?.status)))


        this.stateName = orderDetail?.state;
        this.CountryName = orderDetail?.country;
        this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined && this.onChangeState(this.CountryName) && this.orderForm.patchValue({
          Delivery: {
            drpCountry: this.CountryName,
          }
        });
        this.stateName != null && this.stateName !== "" && this.stateName != undefined && this.onChangeCity(this.stateName, this.CountryName) && this.orderForm.patchValue({
          Delivery: {
            drpState: this.stateName,
            drpCity: orderDetail?.city
          }
        });

        orderDetail !== null && orderDetail !== undefined && this.orderForm.patchValue({
          Delivery: {
            txtScheduledDate: ((orderDetail?.scheduledDate) == null ? "" : orderDetail?.scheduledDate),
            txtScheduledTime: orderDetail?.scheduledTime,
            txtActualDate: orderDetail?.actualDate,
            txtActualTime: orderDetail?.actualTime,
            txtAddress: orderDetail?.address,
            drpCity: orderDetail?.city === "" || undefined ? null : orderDetail?.city,
            drpState: orderDetail?.state === "" || undefined ? null : orderDetail?.state,
            drpCounty: orderDetail?.county === "" || undefined ? null : orderDetail?.county,
            drpCountry: orderDetail?.country === "" || undefined ? null : orderDetail?.country,
            txtPostalCode: orderDetail?.postalCode,
            txtPhone: orderDetail?.phone,
            txtMobile: orderDetail?.mobile,
            txtFacility: orderDetail?.facilityId,
            txtTaxzone: orderDetail?.taxZoneId,
            txtTaxRate: orderDetail?.taxRate,
            txtOrderNote: orderDetail?.orderNote,
            txtDeliveryNote: orderDetail?.deliveryNote,
            drpSetupMethod: orderDetail?.setupMethodId,
            txtDeliveryTechnician: orderDetail?.deliveryTechnicianId,
            txtdrpShipStatus: orderDetail?.shippingStatus,
            chkSignatureRequired: orderDetail?.isSignatureRequired,
            drpFulfillmentVendor: orderDetail?.fulfillmentVendor,
            drpAccountNumber: orderDetail?.accountNumber,
            drpShipBy: orderDetail?.shipBy,
            txtStatus: orderDetail?.status,
            txtStatusDate: ((orderDetail?.stopDate) == null ? "" : orderDetail?.stopDate),
            chkCahPayment: + selectedOrder?.isCashPayment,

          },

          General: {
            chkGeneralManualHold: orderDetail?.isManualHold,
            drpGeneralHoldReason: orderDetail?.holdReasonId,
            txtGeneralStopDate: orderDetail?.stopDate,
            drpGeneralStopReason: orderDetail?.stopReasonId,
            drpGeneralBranch: orderDetail?.branchId,
            drpGeneralInvLocation: orderDetail?.invLocationId,
            drpGeneralStatus: orderDetail?.statusId,
            drpGeneralClassification: orderDetail?.classificationId,
            drpGeneralPlaceOfService: orderDetail?.placeOfServiceId,
            txtGeneralDateOfAdmission: orderDetail?.dateOfAdmission,
            txtGeneralDateOfDischarge: orderDetail?.dateOfDischarge,
            txtGeneralDiscountPct: ((orderDetail?.discountPct) == '0' ? '00' : orderDetail?.discountPct),
            txtGeneralPoNumber: orderDetail?.poNumber,
            txtGeneralReference: orderDetail?.reference,
            txtGeneralUser1: orderDetail?.user1,
            txtGeneralUser2: orderDetail?.user2,
            txtGeneralUser3: orderDetail?.user3,
            txtGeneralUser4: orderDetail?.user4,
            txtGeneralPriorSystemKey: orderDetail?.priorSystemKey,
          },
          AutoConfirm: {
            PointDelivery: {
              txtPointDeliveryOrderStatus: orderDetail?.orderStatus,
              txtPointDeliveryStatusDateTime: orderDetail?.statusDateTime,
              txtPointDeliveryLastMessage: orderDetail?.lastMessage,
              txtPointDeliveryLastMessageDateTime: orderDetail?.lastMessageDateTime,
            },

            BrightShip: {
              txtBrightShipShippingStatus: orderDetail?.shippingStatus,
              drpBrightShipShippingCarrier: orderDetail?.shippingCarrierId,
              drpBrightShipShippingMethod: orderDetail?.shippingMethodId,
            },
            WorkProgress: {
              txtWorkProgressDaysInState: orderDetail?.daysInState,
              drpWorkProgressWIPState: orderDetail?.wipStateId,
              txtWorkProgressAssignedTo: orderDetail?.assignedToId,
              txtWorkProgressDateNeeded: orderDetail?.dateNeeded,
              chkWorkProgressCompleted: orderDetail?.isCompleted == "Done" ? 1 : 0,
              txtRescheduleDate: orderDetail?.reScheduledDate
            },
          },


          AuditTrial: {
            txtAuditTrialOrderNumber: orderDetail?.orderNumber,
            txtAuditTrialType: orderDetail?.type,
            txtAuditTrialDateCreated: orderDetail?.dateCreated,
            txtAuditTrialLastPrinted: orderDetail?.createdBy,
            txtAuditTrialCreatedBy: orderDetail?.lastPrinted,
            txtAuditTrialPrintedBy: orderDetail?.printedBy,
            txtAuditTrialConfirmedBy: orderDetail?.confirmedBy,
            txtAuditTrialConfirmedDate: orderDetail?.confirmedDate,
            txtAuditTrialPrevBilling: orderDetail?.prevBilling,
            txtAuditTrialNextBilling: orderDetail?.nextBilling,
            drpCustomFieldsComplianceUsage: orderDetail?.complianceUsageMetId,
            drpCustomFieldsAuthorizationOrder: orderDetail?.authorizationOfOrderId,
            drpCustomFieldsMaskOrder: orderDetail?.maskOrderId,
            drpCustomFieldsHeadGear: orderDetail?.headGearId,
            drpCustomFieldsTubing: orderDetail?.tubingId,
            drpCustomFieldsCushionsPillow: orderDetail?.cushionsPillowId,
            drpCustomFieldsDispFilters: orderDetail?.dispFiltersId,
            drpCustomFieldsReusableFilters: orderDetail?.reusableFiltersId,
            drpCustomFieldsWaterChamber: orderDetail?.waterChamberId,
          },
          Tracking: {
            txtTrackingCarrier: orderDetail?.carrierId,
            txtTrackingTrackingNo: orderDetail?.trackingNo
          },

        });
      }, () => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      })
      this.subscription$.push(orderGet);
    }

  }

  saveOrUpdate(orderData) {
    this.isShowSpinner = true;
    if ((this.orderId !== "" && this.orderId !== null && this.orderId !== undefined)) {
      this.store.dispatch(new updateOrder(this.orderId, orderData)).subscribe((stateResponse) => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {

          if (result.value) {
            // !change next tab
            this.nextTabMoveOnSaveEmitter.emit("Personal");
          }
        }, () => {
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   // icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        });
        // this.getTableData(this.orderId)
        const orderDetail = stateResponse?.orderState?.selectedOrder;
        this.orderId = stateResponse?.orderState?.selectedOrder?.id;
        this.orderIdEmitter.emit(orderDetail);
        // set orderid from save api
        this.Table.setSelectedOrderId(this.orderId);
        localStorage.setItem('orderId', this.orderId);
        this.saveOrderFormState.emit(this.orderForm.value);
        if (orderDetail.orders.isCompleted === "Done") {
          this.show = true;
          this.show1 = false;
        }
        this.isShowSpinner = false;
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const errData: HttpErrorResponse = err;
        if (errData?.error?.error?.message.includes('?') === true) {
          errData?.error && errData?.error?.error &&
            Swal.fire({
              text: errData?.error?.error?.message,
              showCancelButton: true,
              cancelButtonColor: '#f46a6a',
              confirmButtonColor: '#34c38f',
              confirmButtonText: 'Yes, Confirm!'
            }).then(result => {

              if (result.value == true) {
                this.orderForm.patchValue({
                  Delivery: {
                    chkCahPayment: true,
                  }
                })
                this.verificationcondition = false

                this.saveOrder()

              } else {
                this.orderForm.patchValue({
                  Delivery: {
                    chkCahPayment: false,
                  }
                })
              }
              // this.orderForm.patchValue({
              //   Delivery: {
              //     chkValidateInsuranceStatus: result.value,}
              // })
            })
        } else {
          this.isShowSpinner = false;
          errData?.error && errData?.error?.error &&
            Swal.fire({
              icon: 'info',
              text: errData?.error?.error?.message,

            })
        }
      })
    } else {
      this.store.dispatch(new addOrder(orderData)).subscribe((stateResponse) => {
        this.saleorderId = 0;
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            // !change next tab
            this.nextTabMoveOnSaveEmitter.emit("Order");
          }
          this.isShowSpinner = false;
        }, () => { });
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const orderDetail = stateResponse?.orderState?.selectedOrder;
        this.orderId = stateResponse?.orderState?.selectedOrder?.id;
        this.orderIdEmitter.emit(orderDetail);
        // set orderid from save api
        this.Table.setSelectedOrderId(this.orderId);
        localStorage.setItem('orderId', this.orderId);
        this.saveOrderFormState.emit(this.orderForm.value);
        if (orderDetail.orders.isCompleted === "Done") {
          this.show = true;
          this.show1 = false;
        }
      }, error => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const errData: HttpErrorResponse = error;
        if (errData?.error?.error?.message.includes('?') === true) {
          errData?.error && errData?.error?.error &&
            Swal.fire({
              text: errData?.error?.error?.message,
              showCancelButton: true,
              cancelButtonColor: '#f46a6a',
              confirmButtonColor: '#34c38f',
              confirmButtonText: 'Yes, Confirm!'
            }).then(result => {

              if (result.value == true) {

                this.verificationcondition = false

                this.saveOrder()

              }
              // this.orderForm.patchValue({
              //   Delivery: {
              //     chkValidateInsuranceStatus: result.value,}
              // })
            })
        } else {
          this.isShowSpinner = false;
          errData?.error && errData?.error?.error &&
            Swal.fire({
              icon: 'info',
              text: errData?.error?.error?.message,

            })
        }
      })
    }
  }


  // cashPaymentClick popup
  cashPaymentClick() {
    if (this.orderForm.value.Delivery.chkCahPayment == 0 || this.orderForm.value.Delivery.chkCahPayment == false) {
      Swal.fire({
        title: 'Are you sure you want to Proceed With Cash Payment?',
        text: 'You Can Proceed this Order with Cash Payment',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        cancelButtonText: "No, cancel it!",
        confirmButtonText: 'Yes, Confirm!'
      }).then(result => {
        if (result?.value === true) {
          this.orderForm.patchValue({
            Delivery: {
              chkCahPayment: true,
            }
          })
        } else
          this.orderForm.patchValue({
            Delivery: {
              chkCahPayment: false,
            }
          })
      });
    }
    else if (this.orderForm.value.Delivery.chkCahPayment == true) {
      Swal.fire({
        title: 'Are you sure you want to Proceed Without Cash Payment?',
        text: 'You Can Proceed this Order without Cash Payment',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        cancelButtonText: "No, cancel it!",
        confirmButtonText: 'Yes, Confirm!'
      }).then(result => {
        if (result?.value === true) {
          this.orderForm.patchValue({
            Delivery: {
              chkCahPayment: false,
            }
          })
        } else
          this.orderForm.patchValue({
            Delivery: {
              chkCahPayment: true,
            }
          })
      });
    }
  }

  // ! Order Complete confirmation
  isCompeltedConfirmation() {
    if (this.orderId != "" && this.orderId != null && this.orderId != undefined) {
      const getOrder = this.saleOrderService.get(this.orderId).subscribe(response => {
        // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
        //
        const selectedOrder: SaleorderDTO = response;
        const orderDetail: OrdersDTO = selectedOrder?.orders;
        const selectedItem = selectedOrder?.items
        const authData = selectedOrder?.patients?.authorizations
        selectedItem.forEach(element => {
          if (element?.billingType == "Rental" && authData.primaryAuthorizations == null &&
            authData.secondaryAuthorizations == null && authData?.tertiaryAuthorizations == null) {
            // Swal.fire({
            //   icon: 'info',
            //   text: 'Authorization is Pending for Rental type of Billing',
            // });
            this.toastr.info('Authorization is Pending for Rental type of Billing')
          } else {
            Swal.fire({
              title: 'Are you sure you want to complete?',
              text: 'Order details cannot be modifed in further',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#34c38f',
              cancelButtonColor: '#f46a6a',
              confirmButtonText: 'Yes, Confirm!'
            }).then(result => {
              if (result.value) {

                this.orderForm.patchValue({
                  AutoConfirm: {
                    WorkProgress: {
                      chkWorkProgressCompleted: result.isConfirmed
                    }
                  }
                })
                this.saveOrder()
              }
            });
          }
        })
      })
    }


  }
  clearCounty() {
    this.county = '';
    const delivery: any = this.orderForm.controls.Delivery;
    const deliveryFormGroup: FormGroup = delivery;
    deliveryFormGroup.controls.txtMobile.disable();
    deliveryFormGroup.controls.txtPhone.disable();
  }
  clearFulfillmentVendor() {
    this.fulfillmentVendor = '';
    this.orderForm.patchValue({
      Delivery: {
        drpFulfillmentVendor: null
      }
    });
  }
  clearFacility() {
    this.facility = '';
    this.orderForm.patchValue({
      Delivery: {
        txtFacility: null
      }
    });

  }
  clearAccountNumber() {
    this.accountNumber = '';
    this.orderForm.patchValue({
      Delivery: {
        drpAccountNumber: null
      }
    });
  }

  clearShipBy() {
    this.shipBy == '';
    this.orderForm.patchValue({
      Delivery: {
        drpShipBy: null
      }
    });
  }

  clearStatus() {
    this.status = '';
    this.orderForm.patchValue({
      Delivery: {
        txtStatus: null
      }
    });
  }

  clearGeneralHoldReason() {

    this.generalHoldReason = '';
    this.orderForm.patchValue({
      General: {
        drpGeneralHoldReason: null
      }
    });
  }

  clearGeneralStopReason() {
    this.generalStopReason = '';
    this.orderForm.patchValue({
      General: {
        drpGeneralStopReason: null
      }
    });
  }

  clearGeneralBranch() {
    this.generalBranch = '';
    this.orderForm.patchValue({
      General: {
        drpGeneralBranch: null
      }
    });
  }
  clearGeneralInvLocation() {
    this.generalInvLocation = '';
    this.orderForm.patchValue({
      General: {
        drpGeneralInvLocation: null
      }
    });
  }

  clearGeneralStatus() {
    this.generalStatus = '';
    this.orderForm.patchValue({
      General: {
        drpGeneralStatus: null
      }
    });
  }

  clearGeneralClassification() {
    this.generalClassification = '';
    this.orderForm.patchValue({
      General: {
        drpGeneralClassification: null
      }
    });
  }
  clearGeneralPlaceOfService() {
    this.generalPlaceOfService = '';
    this.orderForm.patchValue({
      General: {
        drpGeneralPlaceOfService: null
      }
    });
  }

  clearBrightShipShippingCarrier() {
    this.brightShipShippingCarrier = '';
    this.orderForm.patchValue({
      AutoConfirm: {
        BrightShip: {
          drpBrightShipShippingCarrier: null
        }
      }
    });
  }

  clearBrightShipShippingMethod() {
    this.brightShipShippingMethod = '';
    this.orderForm.patchValue({
      AutoConfirm: {
        BrightShip: {
          drpBrightShipShippingMethod: null
        }
      }
    });
  }

  clearCustomFieldsComplianceUsage() {
    this.customFieldsComplianceUsage = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsComplianceUsage: null
      }
    });
  }

  clearCustomFieldsAuthorizationOrder() {
    this.customFieldsAuthorizationOrder = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsAuthorizationOrder: null
      }
    });
  }

  clearCustomFieldsMaskOrder() {
    this.customFieldsMaskOrder = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsMaskOrder: null
      }
    });
  }

  clearCustomFieldsHeadGear() {
    this.customFieldsHeadGear = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsHeadGear: null
      }
    });
  }

  clearCustomFieldsTubing() {
    this.customFieldsTubing = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsTubing: null
      }
    });
  }

  clearCustomFieldsCushionsPillow() {
    this.customFieldsCushionsPillow = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsCushionsPillow: null
      }
    });
  }

  clearCustomFieldsDispFilters() {
    this.customFieldsDispFilters = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsDispFilters: null
      }
    });
  }

  clearCustomFieldsReusableFilters() {
    this.customFieldsReusableFilters = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsReusableFilters: null
      }
    });
  }

  clearCustomFieldsWaterChamber() {
    this.customFieldsWaterChamber = '';
    this.orderForm.patchValue({
      AuditTrial: {
        drpCustomFieldsWaterChamber: null
      }
    });
  }

  clearWorkProgressWIPState() {
    this.workProgressWIPState = '';
    this.orderForm.patchValue({
      AutoConfirm: {
        WorkProgress: {
          drpWorkProgressWIPState: null
        }
      }
    });
  }


  // notification

  Email: boolean = true;
  Message: boolean = false;

  // notification  method change mail/message
  onChangeGetnotification($event: MatRadioChange) {
    if ($event.value === 'Email') {
      this.Email = true;
      this.Message = false;
    }
    if ($event.value === 'Message') {
      this.Email = false;
      this.Message = true;
    }
  }
}
