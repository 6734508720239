import type { CCSettlementFlagDTO, CCSettlementPaypalPaymentReportDTO, ClaimCCSettlementListDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CCSettlementService {
  apiName = 'ClaimProcessingManagement';

  getClaimCCSettlementDetailsBySelectedDate = (selectedDate: string) =>
    this.restService.request<any, ClaimCCSettlementListDTO[]>({
      method: 'GET',
      url: '/api/claimProcessingApp/c-cSettlement/claim-cCSettlement-details',
      params: { selectedDate },
    },
    { apiName: this.apiName });

  getClaimPaypalPaymentDetails = () =>
    this.restService.request<any, CCSettlementPaypalPaymentReportDTO[]>({
      method: 'GET',
      url: '/api/claimProcessingApp/c-cSettlement/claim-paypal-payment-details',
    },
    { apiName: this.apiName });

  getCompletedCCSettlementDetailsByDtFromDateAndDtToDate = (dtFromDate: string, dtToDate: string) =>
    this.restService.request<any, ClaimCCSettlementListDTO[]>({
      method: 'GET',
      url: '/api/claimProcessingApp/c-cSettlement/completed-cCSettlement-details',
      params: { dtFromDate, dtToDate },
    },
    { apiName: this.apiName });

    updateCCSettlementFlagByLstClaimId = (lstClaimId: string[]) =>
      this.restService.request<any, boolean>({
        method: 'PUT',
        url: `/api/claimProcessingApp/c-cSettlement/c-cSettlement-flag/dummy`, // ${lstClaimId}
        body: lstClaimId,
      },
      { apiName: this.apiName });

      updateCCSettlementFlagByLstClaimIdV1 = (lstClaimId: string[]) =>
        this.restService.request<any, any>({
          method: 'PUT',
          responseType: 'text',
          url: `/api/claimProcessingApp/c-cSettlement/c-cSettlement-flag`, // ${lstClaimId}
          body: lstClaimId,
        },
        { apiName: this.apiName });

  updateClaimPaypalPaymentFlagByInput = (input: CCSettlementFlagDTO) =>
    this.restService.request<any, CCSettlementFlagDTO>({
      method: 'PUT',
      url: '/api/claimProcessingApp/c-cSettlement/claim-paypal-payment-flag',
      body: input,
    },
    { apiName: this.apiName });

  updateClaimSettlementFlagByLstClaimId = (lstClaimId: string[]) =>
    this.restService.request<any, boolean>({
      method: 'PUT',
      url: `/api/claimProcessingApp/c-cSettlement/claim-settlement-flag/${lstClaimId}`,
      body: lstClaimId,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
