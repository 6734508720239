<div class=" card card-body">
<mat-accordion>
    <mat-expansion-panel [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
        <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
                <ng-container *ngIf="discountId === defaultGuid; else elseTitleTemplate">
                    <b>Add Discount Value</b>
                </ng-container>
                <ng-template #elseTitleTemplate>
                    <b>Edit Discount Value</b>
                </ng-template>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="mstDiscountForm">
            <div class="">

                <div class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Discount Value%<span class="asterisk">*</span></mat-label>
                        <input matInput formControlName="txtdiscount"step="0.01" [min]="1" [max]="100" type="number"
                        pattern="^0*(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$" autocomplete="new-txtdiscount"
                            type="text" />
                        <mat-error *ngIf="
                mstDiscountForm?.get('txtdiscount')?.touched &&
                mstDiscountForm?.get('txtdiscount')?.errors?.required
              ">
                            Discount Value is a required field!
                        </mat-error>
                        <mat-error *ngIf="(
                        mstDiscountForm?.get('txtdiscount')?.errors?.pattern)">
                                     Enter valid and only number up to 100 with up to 2 decimal point!
                                </mat-error>
                    </mat-form-field>


                    <button style="height: 35px" mat-button class="buttonColor mt-1 mr-1" (click)="saveDiscount()"
                        [disabled]="isShowSpinner || !(!mstDiscountForm?.invalid && mstDiscountForm?.dirty)||
                        mstDiscountForm?.get('txtdiscount')?.value<=0">
                        {{isSave}}
                        <mat-icon *ngIf="isShowSpinner">
                            <mat-spinner class="spinner-border spinner-border-sm" diameter="90">
                            </mat-spinner>
                        </mat-icon>
                    </button>
                    <button mat-button style="height: 35px" class="resetclr bg-danger buttonColor mt-1"
                        (click)="resetDiscountForm()">
                        Reset
                    </button>
                </div>

            </div>
        </form>
        <br />
    </mat-expansion-panel>
</mat-accordion>
<br>
<div class="card card-body">
    <div class="row">
        <div class="col-12">
            <div class="table table-responsive">
                <table id="tbl" datatable [dtOptions]="dtdisocuntOptions"
                    class="tbl hover row-border hover w-100 display">
                    <thead>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th [matTooltip]="'Discount Value %'">Discount Value %</th>
                            <th [matTooltip]="'From Date'" class="text-center">From Date</th>
                            <th [matTooltip]="'To Date'" class="text-center">To Date</th>
                            <!-- <th [matTooltip]="'Created By'">Created By</th> -->
                            <th class="text-center" [matTooltip]="'Created Date'" >Created Date</th>
                            <!-- <th [matTooltip]="'Modify By'">Modify By</th> -->
                            <!-- <th class="text-center" [matTooltip]="'Modified Date'" >Modified Date</th> -->
                            <th [matTooltip]="'Status'">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of discountDataList ">
                            <td>
                                <ng-container>
                                    <a (click)="viewDiscountById(data?.id)" matTooltip="Click To Edit Discount" [ngClass]="{'disabled-link': data?.isActive === false}">
                                        <i class="fa fa-pencil pointer editBtnColor mr-1"></i>
                                    </a>
                                </ng-container>
                            </td>
                            <td [matTooltip]="data?.discountPercentage || '-'"
                                (dblclick)="onRowDoubleClickV1(data?.discountPercentage || '-')">
                                {{ data?.discountPercentage || '-' }}
                            </td>

                            <td [matTooltip]="getFormattedDate(data?.fromDate)"
                                (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.fromDate))" class="text-center">
                                {{ getFormattedDate(data?.fromDate) }}
                            </td>

                            <td [matTooltip]="getFormattedDate(data?.toDate)"
                                (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.toDate))" class="text-center">
                                {{ getFormattedDate(data?.toDate) }}
                            </td>
                            <!-- <td [matTooltip]="data?.createdBy || '-'"
                                 (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{
                                 data?.createdBy || '-' }}</td> -->

                             <td class="text-right" [matTooltip]="getFormattedDate(data?.creationTime)"
                                 (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.creationTime))" class="text-center">
                                 {{ getFormattedDate(data?.creationTime) }}
                             </td>
                             <!-- <td [matTooltip]="data?.modifyBy|| '-'"
                                 (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{
                                 data?.modifyBy || '-' }}</td> -->

                             <!-- <td class="text-right" [matTooltip]="getFormattedDate(data?.lastModificationTime)"
                                 (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.lastModificationTime))" class="text-center">
                                 {{ getFormattedDate(data?.lastModificationTime) }}
                             </td> -->


                            <td [matTooltip]="data?.isActive ? 'Active' : 'InActive' || '-'">
                                <ng-container *ngIf="data?.isActive===true">
                                    <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true'>
                                        {{data?.status}} Active</mat-slide-toggle>
                                </ng-container>
                                <ng-container *ngIf="data?.isActive===false">
                                    <mat-slide-toggle [disabled]="true"></mat-slide-toggle> <span class="ml-2 inactive">InActive</span>
                                    <!-- <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false' [disabled]='true'>
                                        {{data?.status}} InActive</mat-slide-toggle> -->
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Discount Value %"
                                    name="search-DiscountValue" />
                            </th>
                            <th>
                                <input matInput class="textbox-border-class text-center" type="text"
                                    placeholder="From Date" name="search-FromDate" />
                            </th>
                            <th>
                                <input matInput class="textbox-border-class text-center" type="text"
                                    placeholder="To Date" name="search-ToDate" />
                            </th>
                            <!-- <th>
                              <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                                  name="search-Created By" />
                          </th> -->
                          <th class="text-right">
                              <input matInput class="textbox-border-class text-center" type="text"
                                    placeholder="Created Date" name="search-Created Date" />
                           </th>
                           <!-- <th>
                              <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                                  name="search-Modify By" />
                          </th>
                            <th class="text-right">
                                <input matInput class="textbox-border-class text-center" type="text"
                                    placeholder="Modified Date" name="search-Modified Date" />
                            </th> -->
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Status"
                                    name="search-Status" />
                            </th>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
