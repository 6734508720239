import type { InsuranceVerificationSearchRequestDTO } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VerificationSearchService {
  apiName = 'Default';

  searchVerificationByInput = (input: InsuranceVerificationSearchRequestDTO) =>
    this.restService.request<any, object>({
      method: 'POST',
      url: '/api/app/verification-search/search-verification',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
