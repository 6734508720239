import { isNullOrUndefined, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Button, ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { ActionEventArgs, AgendaService, CellClickEventArgs, DayService, DragAndDropService, EJ2Instance, EventClickArgs, EventRenderedArgs, EventSettingsModel, MonthService, ResizeService, ScheduleComponent, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CalendarService } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar.service';
import { CreateUpdateCalendarDetailsDTO, CreateUpdateCalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import Swal from 'sweetalert2';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { createElement } from '@syncfusion/ej2-base';
import { PatientsService } from 'projects/patient/src/app/patient-proxy/patient/patients.service';
import { PatientSearchService } from 'projects/patient/src/app/patient-proxy/patient/patient-search.service';
import { FormValidator } from '@syncfusion/ej2-angular-inputs';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import emailMask from 'text-mask-addons/dist/emailMask';
@Component({
  selector: 'app-appointment-schedule',
  templateUrl: './appointment-schedule.component.html',
  styleUrls: ['./appointment-schedule.component.scss'],
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    ResizeService,
    DragAndDropService
  ]
})
export class AppointmentScheduleComponent implements OnInit {
  @ViewChild("scheduleObj", { static: true })
  public scheduleObj: ScheduleComponent;
  userId: string = "";
  roleId: string = "";
  userName: string = "";
  roleName: string = "";
  calenderList: any[] = [];
  userTableData: any[] = [];
  show: boolean = true;
  scheduleForm:FormGroup;
  emailMask: any;
  minDate: Date;
  filteredBranchTypeList: OrganizationUnitDTO[];
  public selectedDate: Date = new Date();
  public eventSettings: EventSettingsModel = {
    dataSource: this.getTableData()

  };
  public roomDataSource: Object[] = this.userTableData;


  public resourcelevel2: any;

  // public timeScaleOptions: TimeScaleModel = { enable: true, slotCount: 3 };
  //   public onActionBegin(args: ActionEventArgs): void {
  //     const weekEnds: number[] = [0, 6];
  //     if(args?.requestType == 'eventCreate' && weekEnds.indexOf((args?.data[0].StartTime).getDay()) >= 0) {
  //         args?.cancel = true;
  //     }
  // }
  // public workHours: WorkHoursModel = { start: '08:00' };
  // public startHour: string = '08:00';
  // public endHour: string = '19:00';
  subscription$: Subscription[] = [];

  constructor(private calenderService: CalendarService,
    private adminService: DashboardService,
    public dialog: MatDialog,
    private branchService: OrganizationUnitService,
    private router: Router,
    private PatientService: PatientSearchService,
    private userService: UserService,
    public title: Title,
    private formBuilder: FormBuilder,
    private tableService: TableService,
    private PatientPersonalService: PatientPersonalService,
    private onTimeDropdown: OnTimeMasterDropdownService,) {
    this.adminService.userProfile().subscribe((response) => {
      this.userId = response?.userId;
      this.userName = response?.userName;

    });
    this.tableService.getCalenderPopup().subscribe(response =>{

     if(response){
     this.PatientPersonalService.getPatientForCalenderV1().subscribe(res =>{

      this.onPopupOpen(res)
     })
     }
    })
  }
  ngAfterViewInit(): void {
    // this.getTableData()

  }
  tenantId: string = "";

  ngOnInit() {
    this.adminService.userProfile().subscribe((response) => {
      this.userId = response?.userId;
      this.userName = response?.userName;

    });
    this.loadDropdown();
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.getDropDown();
    this.getPatient();
    // this.getDropDowns()
    this.getTableData()
    // this.scheduleObj?.dataModule.dataManager.refreshEvents();
    this.title.setTitle("Qsecure | AppointMent");
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
    this.scheduleForm = this.formBuilder.group({
      /// txtCompanyName: new FormControl(""),
      txtBranchName: new FormControl(""),
      txtChart:new FormControl("",),
      txtName:new FormControl(""),
      txtEmail:new FormControl(""),
      txtDate:new FormControl(""),
      txtFromTime:new FormControl(""),
      txtToTime:new FormControl(""),
      txtType:new FormControl(""),
      txtTech:new FormControl(""),
      txtResource:new FormControl(""),
      txtNote:new FormControl(""),
      txtHome:new FormControl(""),
      txtCell:new FormControl(""),
      txtWork:new FormControl(""),
      txtText:new FormControl("")
    });
    this.emailMask = emailMask;
  }
  loadDropdown() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        this.filteredBranchTypeList = response;

      });
  }

  /// dropdown datas
  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.CalendarStatus,

    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      let response: OnTimeMasterDropdownDTO = stateResponse;
      this.userTableData = [];
      response && response?.calendarStatuses.forEach(element => {
        // this.userTableData = response.calendarStatuses
        this.userTableData?.push({
          OwnerText: element?.calendarStatus,
          shortCodeId: element?.shortCodeId,
          Id: element?.calendarStatusId,
        });
      })

      this.roomDataSource = this.userTableData;
    })
    return this.userTableData

  }



  /// dropdown datas
  getDropDowns() {

    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.EditCalendar,

    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      let response: OnTimeMasterDropdownDTO = stateResponse;
      this.userTableData = [];
      response && response?.calendarStatuses.forEach(element => {
        // this.userTableData = response.calendarStatuses
        this.userTableData?.push({
          OwnerText: element?.calendarStatus,
          shortCodeId: element?.shortCodeId,
          Id: element?.calendarStatusId,
        });
      })

      this.roomDataSource = this.userTableData;
    })
    return this.userTableData

  }

  // getUserList() {
  //   let filters: IdentityUserUsingTenantIdDto = {
  //     tenantId: this.tenantId
  //   }
  //   this.userService.getUserList(filters).subscribe(response => {
  //     let resourceData: Object[]
  //     this.userTableData = [];
  //     response && response?.forEach(element => {
  //       if (element?.roleName == "Scheduler" || element?.roleName == "Respiratory Therapist") {
  //         this.userTabledata?.push({
  //           OwnerText: element?.userName,
  //           email: element?.email,
  //           role: element?.roleName,
  //           Id: element?.id,
  //         });
  //       }
  //     })
  //     this.roomDataSource = this.userTableData;

  //   })
  //   return this.userTableData
  // }
  //! calender table datas list as a view page
  public getTableData(): Record<string, any>[] {
    const eventData: Record<string, any>[] = [];
    this.userId = localStorage.getItem("userId") ?? "";
    this.roleId = localStorage.getItem("roleId") ?? "";
    this.roleName = localStorage.getItem("roleName") ?? "";

    this.calenderService.getCalendarByUserId(this.userId).subscribe(response => {
      // eventData=[];
      response && response?.items?.forEach(elements => {
        elements?.calendarDetails?.forEach(element => {
          eventData?.push({
            EndTime: new Date(element?.toTime),
            StartTime: new Date(element?.fromTime),
            // EndTime: moment(element?.toTime)?.toLocaleDateString("en-US")(),
            // StartTime: moment(element?.fromTime).toISOString(),
            Subject: element?.title,
            Id: element?.calendarId,
            QGuid: elements?.id,
            Guid: element?.guid,
            Description: element?.description,
            StartTimeZone: element?.startTimeZone,
            EndTimeZone: element?.endTimeZone,
            IsAllDay: element?.isAllDay,
            Location: element?.location,
            RecurrenceRule: element?.recurrenceRule,
            userName: elements?.userName,
            userId: elements?.userId,
            OwnerId: element?.status,
            roleId: elements?.roleId,
            calendarDetailsId: element?.calendarDetailsId,
            status: element?.status,
            colorCode: element?.colorCode,
            roleName: elements?.roleName,
            isDragAndDrop: true,
            Patient: element?.patientFullName,
            assignedById: element?.assignedById,
            assignedByName: element?.assignedByName,
            assignedToId: elements?.assignedToId,
            assignedToName: elements?.assignedToName


            // RecurrenceRule: "FREQ=WEEKLY;BYDAY=FR;INTERVAL=1;",
            // RecurrenceException: "20201106T130000Z"
          });
        })

      })
      this.scheduleObj?.refreshEvents();

      // this.scheduleObj?.dataModule.dataManager.dataSource.json =  this.calenderList;
    })
    // this.scheduleObj?.addEvent(eventData);

    return eventData;

  }


  //! reschedule color
  applyCategoryColor(args: EventRenderedArgs): void {
    let colorCode: string = args.data.colorCode as string;
    args.element.style.backgroundColor = colorCode;
    if (!args.element || !colorCode) {
      return;
    }
    if (this.scheduleObj?.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = colorCode;
    } else {
      args.element.style.backgroundColor = colorCode;
    }
  }



  //// get patient list
  patientDet: any[] = [];
  getPatient() {
    ///patient link dropdown
    // this.PatientService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
    //   this.patientDet = [];
    //   response?.items.forEach(e => {
    //     this.patientDet.push({
    //       name: e?.personals?.fullName,
    //       id: e?.id,
    //       defaultPatientId: e?.defaultPatientId
    //     })
    //   })
    // })
    ///patient link dropdown
    const patientSearchSub$ =
      of(null).pipe(
        switchMap(x => this.PatientService.getPatientForCalender()))
        .subscribe(response => {
          this.patientDet = [];
          response?.forEach(e => {
            this.patientDet?.push({
              name: e?.patientName,
              id: e?.patientId,
              defaultPatientId: e?.defaultPatientId
            })
          })
        });

    this.subscription$.push(patientSearchSub$);
  }
  //! calender popup open to add event

  QGuid: string = "";
  startEnd: string = "";
  calenderId: string = "";
  calenderDetailId: string = "";
  assignedById: string = "";
  assignedByName: string = "";
  assignedToId: string = "";
  assignedToName: string = "";
  onPopupOpen(args) {

    this.assignedById = args?.data?.assignedById;
    this.assignedByName = args?.data?.assignedByName;
    this.assignedToId = args?.data?.assignedToId;
    this.assignedToName = args?.data?.assignedToName;

    this.eventSettings = {
      dataSource: this.getTableData()

    };


    if (args?.type === 'Editor') {

      if (args.type === 'Editor') {
        let statusElement: HTMLInputElement = args.element.querySelector('#EventType') as HTMLInputElement;


        // if (!statusElement.classList.contains('e-dropdownlist')) {
        let dropDownListObject: DropDownList = new DropDownList({
          placeholder: 'Choose status', value: statusElement?.value,
          dataSource: [{ text: 'Day', value: 'Day' },
          { text: 'Week', value: 'Week' },
          { text: 'WorkWeek', value: 'WorkWeek' },
          { text: 'Month', value: 'Month' }]
        });
        dropDownListObject.appendTo(statusElement);
        // }

      }

    }

    if (args?.type === 'Editor') {

      this.getPatient();
      // Create required custom elements in initial time
      if (!args.element.querySelector('.custom-field-row')) {
        let row: HTMLElement = createElement('div', { className: 'custom-field-row' });
        let formElement: HTMLElement = args.element.querySelector('.e-schedule-form');
        formElement.firstChild.insertBefore(row, args.element.querySelector('.e-title-location-row'));
        let container: HTMLElement = createElement('div', { className: 'custom-field-container' });
        let inputEle: HTMLInputElement = createElement('input', {
          className: 'e-field', attrs: { name: 'Patient' }
        }) as HTMLInputElement;
        container.appendChild(inputEle);
        row.appendChild(container);
        let dropDownList: DropDownList = new DropDownList({
          dataSource: this.patientDet,


          fields: { text: 'name', value: 'name' },
          value: (<{ [key: string]: Object }>(args.data)).Patient as string,
          floatLabelType: 'Always', placeholder: 'Patient'
        });
        dropDownList.appendTo(inputEle);
        inputEle.setAttribute('name', 'Patient');
      }

    }




    this.startEnd = args.data.EndTime?? 'Tue Nov 28 2023 00:00:00 GMT+0530 (India Standard Time)';
    this.calenderDetailId = args.data.calendarDetailsId?? null;

    // button disable for past date and without subject
    if (args?.data?.EndTime < new Date() && (args?.data?.StartTime !== "" && args?.data?.Subject == undefined &&
      args?.data?.startTime !== "" && args?.data?.EndTime !== "")) {
      if (args.type === 'QuickInfo' || args.type === 'Editor') {
        args.cancel = true;

      }
    }

    // button disable for past date and scheduled status
    if (args?.data?.EndTime < new Date() && (args?.data?.OwnerId != "Scheduled")) {

      var buttonElement = args?.type === "QuickInfo" ? ".e-event-popup .e-save" : ".e-schedule-dialog .e-event-save";
      var saveButton = document.querySelector(buttonElement);
      if (saveButton && (saveButton as EJ2Instance)?.ej2_instances) {
        ((saveButton as EJ2Instance)?.ej2_instances[0] as Button).disabled = true;
      }

      var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-delete" : ".e-schedule-dialog .e-event-delete";
      var deleteButton = document.querySelector(buttonElement);
      if (deleteButton && (deleteButton as EJ2Instance)?.ej2_instances) {
        ((deleteButton as EJ2Instance)?.ej2_instances[0] as Button).disabled = true;
      }
    }

    /// admin assigned delte button disable
    if (args?.data?.assignedByName == 'admin' && args?.data?.assignedToName !== 'admin'
    ) {
      var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-delete" : ".e-schedule-dialog .e-event-delete";
      var deleteButton = document.querySelector(buttonElement);
      if (deleteButton && (deleteButton as EJ2Instance)?.ej2_instances) {
        ((deleteButton as EJ2Instance)?.ej2_instances[0] as Button).disabled = true;
      }
    }


    // button disable for past date and Rescheduled status
    if (args?.data?.EndTime > new Date() && args?.data?.OwnerId === "Rescheduled") {

      var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-save" : ".e-schedule-dialog .e-event-save";
      var saveButton = document.querySelector(buttonElement);
      if (saveButton && (saveButton as EJ2Instance).ej2_instances) {
        ((saveButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }

      var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-delete" : ".e-schedule-dialog .e-event-delete";
      var deleteButton = document.querySelector(buttonElement);
      if (deleteButton && (deleteButton as EJ2Instance).ej2_instances) {
        ((deleteButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }

      // if (args.type === 'Editor' || args.type === 'QuickInfo') {
      //   args.cancel = true;
      // }
    }

    if (args?.data?.QGuid !== "") {
      this.QGuid = args?.data?.QGuid
      this.calenderId = args?.data?.calendarDetailsId
    }

  }


  ///// editor popup window
  public showQuickInfo: Boolean = false;
  onCellClick(args: CellClickEventArgs): void {
    this.scheduleObj?.openEditor(args, 'Add');
  }
  onEventClick(args: EventClickArgs): void {
    if (!(args.event as any).RecurrenceRule) {
      this.scheduleObj?.openEditor(args.event, 'Save');
    }
    else {
      this.scheduleObj?.quickPopup?.openRecurrenceAlert();
    }
  }


  toggle: boolean = false;

  //! calender actions
  onActionComplete(args: ActionEventArgs): void {


    //! for save the schedule event
    if (args?.requestType === 'eventCreated') {
      // if(args?.addedRecords?.[0]?.StartTime !== args?.addedRecords?.[0]?.EndTime){
      let patient = this.patientDet?.filter(value => {
        return value && value?.name === args?.addedRecords?.[0]?.Patient
      })[0];
      let calender: CreateUpdateCalendarDTO = {
        // userId: this.userId,
        // userName: this.userName,
        roleId: this.roleId,
        roleName: this.roleName,
        assignedToId: this.userId,
        assignedToName: this.userName,
        calendarDetails: [{
          setUpInHome:false,
          userID: '',
          // tech : 'Mani',
          calendarCode: "",
          calendarId: args?.addedRecords?.[0]?.Id,
          title: args?.addedRecords?.[0]?.Subject,
          fromTime: args?.addedRecords?.[0]?.StartTime,
          toTime: args?.addedRecords?.[0]?.EndTime,
          description: "",
          startTimeZone: "",
          endTimeZone: "",
          // isAllDay: args?.addedRecords?.[0]?.IsAllDay,
          // location: "",
          // recurrenceRule: "",
          // guid: "00000000-0000-0000-0000-000000000000",
          status: args?.addedRecords?.[0]?.OwnerId,
          colorCode: '#20b2aa',
          // createdDate: "",
          // isDragAndDrop: false,
          // patientFullName: args?.addedRecords?.[0]?.Patient,
          patientId: patient?.id,
          // defaultPatientId: patient?.defaultPatientId,
          // assignedById: this.userId,
          assignedByName: this.userName
        }]
      }
      this.calenderService.create(calender).subscribe(response => {
        this.toggle = !this.toggle;
        // let currentUrl = this.router.url;
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate([currentUrl]);
        this.eventSettings = {
          dataSource: this.getTableData()
        };
        this.getTableData();
      }, err => {
        args.cancel = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.eventSettings = {
          dataSource: this.getTableData()
        };
      })
      // } else{
      //   Swal.fire({
      //     icon: 'error',
      //     text: 'Selected Start and End Time Should Not be same ',
      //   });
      // }


    }


    //!/ for update the schedule event for user assigned
    if (args?.requestType === 'eventChanged' && args?.changedRecords?.[0]?.OwnerId !== "Rescheduled" &&
      args?.changedRecords?.[0]?.userName !== 'admin') {
      let patient = this.patientDet?.filter(value => {
        return value && value?.name === args?.changedRecords?.[0]?.Patient
      })[0];
      let calender: CreateUpdateCalendarDTO = {
        // userId: args?.changedRecords?.[0]?.userId,
        // userName: args?.changedRecords?.[0]?.userName,
        roleId: args?.changedRecords?.[0]?.roleId,
        roleName: args?.changedRecords?.[0]?.roleName,
        assignedToId: args?.changedRecords?.[0]?.assignedToId,
        assignedToName: args?.changedRecords?.[0]?.assignedToName,
        calendarDetails: [{
          setUpInHome:false,
          userID: '',
          // tech : 'Mani',
          calendarCode: "",
          calendarId: args?.addedRecords?.[0]?.Id,
          title: args?.addedRecords?.[0]?.Subject,
          fromTime: args?.addedRecords?.[0]?.StartTime,
          toTime: args?.addedRecords?.[0]?.EndTime,
          description: "",
          startTimeZone: "",
          endTimeZone: "",
          // isAllDay: args?.addedRecords?.[0]?.IsAllDay,
          // location: "",
          // recurrenceRule: "",
          // guid: "00000000-0000-0000-0000-000000000000",
          status: args?.addedRecords?.[0]?.OwnerId,
          colorCode: '#20b2aa',
          // createdDate: "",
          // isDragAndDrop: false,
          // patientFullName: args?.addedRecords?.[0]?.Patient,
          patientId: patient?.id,
          // defaultPatientId: patient?.defaultPatientId,
          // assignedById: this.userId,
          assignedByName: this.userName
        }]
      }
      this.calenderService.update(this.QGuid, calender).subscribe(response => {

        /// to call datasource - table data
        this.eventSettings = {
          dataSource: this.getTableData()
        };
        this.getTableData();
      }, err => {
        args.cancel = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.eventSettings = {
          dataSource: this.getTableData()
        };
      })

    }



    //!/ for update the schedule event for admin assigned
    if (args?.requestType === 'eventChanged' && args?.changedRecords?.[0]?.OwnerId !== "Rescheduled" &&
      args?.changedRecords?.[0]?.userName == 'admin') {

      let patient = this.patientDet?.filter(value => {
        return value && value?.name === args?.changedRecords?.[0]?.Patient
      })[0];
      let calender: CreateUpdateCalendarDTO = {

        // userId: args?.changedRecords?.[0]?.userId,
        // userName: args?.changedRecords?.[0]?.userName,
        roleId: args?.changedRecords?.[0]?.roleId,
        roleName: args?.changedRecords?.[0]?.roleName,
        assignedToId: this.assignedToId,
        assignedToName: this.assignedToName,
        calendarDetails: [{
          setUpInHome:false,
          userID: '',
          // tech : 'Mani',
          calendarCode: "",
          calendarId: args?.addedRecords?.[0]?.Id,
          title: args?.addedRecords?.[0]?.Subject,
          fromTime: args?.addedRecords?.[0]?.StartTime,
          toTime: args?.addedRecords?.[0]?.EndTime,
          description: "",
          startTimeZone: "",
          endTimeZone: "",
          // isAllDay: args?.addedRecords?.[0]?.IsAllDay,
          // location: "",
          // recurrenceRule: "",
          // guid: "00000000-0000-0000-0000-000000000000",
          status: args?.addedRecords?.[0]?.OwnerId,
          colorCode: '#20b2aa',
          // createdDate: "",
          // isDragAndDrop: false,
          // patientFullName: args?.addedRecords?.[0]?.Patient,
          patientId: patient?.id,
          // defaultPatientId: patient?.defaultPatientId,
          // assignedById: this.userId,
          assignedByName: this.userName

        }]
      }
      this.calenderService.update(this.QGuid, calender).subscribe(response => {
        this.eventSettings = {
          dataSource: this.getTableData()

        };
        this.getTableData();
      }, err => {
        args.cancel = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.eventSettings = {
          dataSource: this.getTableData()

        };
      })

    }

    if (this.startEnd > args?.changedRecords?.[0]?.StartTime) {
      var buttonElement = args.requestType === "eventChanged" ? ".e-event-popup .e-save" : ".e-schedule-dialog .e-event-save";
      var saveButton = document.querySelector(buttonElement);
      if (saveButton && (saveButton as EJ2Instance).ej2_instances) {
        ((saveButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }
    }



    ////// to change reschedule status in update
    if (args?.changedRecords?.[0]?.OwnerId === "Rescheduled") {

      let patient = this.patientDet?.filter(value => {
        return value && value?.name === args?.changedRecords?.[0]?.Patient
      })[0];

      let calender: CreateUpdateCalendarDetailsDTO
      calender = {
        setUpInHome:false,
          userID: '',
          // tech : 'Mani',
          calendarCode: "",
          calendarId: args?.addedRecords?.[0]?.Id,
          title: args?.addedRecords?.[0]?.Subject,
          fromTime: args?.addedRecords?.[0]?.StartTime,
          toTime: args?.addedRecords?.[0]?.EndTime,
          description: "",
          startTimeZone: "",
          endTimeZone: "",
          // isAllDay: args?.addedRecords?.[0]?.IsAllDay,
          // location: "",
          // recurrenceRule: "",
          // guid: "00000000-0000-0000-0000-000000000000",
          status: args?.addedRecords?.[0]?.OwnerId,
          colorCode: '#20b2aa',
          // createdDate: "",
          // isDragAndDrop: false,
          // patientFullName: args?.addedRecords?.[0]?.Patient,
          patientId: patient?.id,
          // defaultPatientId: patient?.defaultPatientId,
          // assignedById: this.userId,
          assignedByName: this.userName
      };

      this.calenderService.updateRescheduleByIdAndInput(this.QGuid, calender).subscribe(response => {
        this.eventSettings = {
          dataSource: this.getTableData()

        };
        this.getTableData();
      }, err => {

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.eventSettings = {
          dataSource: this.getTableData()

        };
      })
    }
    if (args?.requestType === 'eventRemoved') {
      this.calenderService.deleteCalendarByIdAndCalendarDetailsId(this.QGuid, this.calenderId).subscribe(response => {

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.eventSettings = {
          dataSource: this.getTableData()
        };
      }
      )
    }

    //! switch case events log
    switch (args?.requestType) {
      case "viewNavigate":
      case "dateNavigate":
        this.scheduleObj?.refresh();
        break;
      case "toolBarItemRendered":

        // this.getTableData()
        // args?.requestType = "viewNavigate"
        break;
      default:
    }


  }
  ngOnDestroy(): void {
    this.subscription$.forEach(x => x.unsubscribe())
  }
  @ViewChild("addButton")
  public addButton: ButtonComponent;
  public onButtonClick(): void {
    let data: Object[] = [{
      Id: 0,
      Subject: 'Conference',
      StartTime: new Date(2018, 1, 12, 9, 0),
      EndTime: new Date(2018, 1, 12, 10, 0),
      IsAllDay: true
    }];
    // this.scheduleObj?.addEvent(data);
    // this.addButton.element?.setAttribute('disabled','true');
  }



}
function extend(arg0: undefined[], eventData: Record<string, any>[], arg2: null, arg3: boolean): { [key: string]: Date; }[] {
  throw new Error('Function not implemented.');
}
