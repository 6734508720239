import { mapEnumToOptions } from '@abp/ng.core';

export enum ComlplainceFilter {
  New = 0,
  FTFNote = 1,
  CheckList = 2,
  Billing = 3,
  ComplianceMet = 4,
  NonComplianceMet = 5,
}

export const comlplainceFilterOptions = mapEnumToOptions(ComlplainceFilter);
