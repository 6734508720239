<div class="card card-body mt-1">
  <mat-card-content>
    <div class="row">
      <div class="col-6">
        <div class="text-left">
          <h4 class="customThemeClass"><b>Notes</b></h4>
        </div>
      </div>
      <div class="col-6">
        <div class="text-right">
          <button
            mat-button
            *ngIf="isAddVisible"
            (click)="openNoteModal(null)"
            class="buttonColor font-size-14"
          >
            <i class="fa fa-plus mr-1"></i>
            Add Note
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-lg-12 table-responsive">
        <div class="mat-elevation-z4 example-container table-responsive">
          <!-- <div class="table table-responsive"> -->
          <form [formGroup]="patientNotesForm">
            <table
              mat-table
              [dataSource]="dataSource"
              matTableExporter
              #exporter="matTableExporter"
              matSort
              class="mat-elevation-z8"
            >
              <!--Default Notes Id -->
              <ng-container matColumnDef="defaultNotesId">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="defaultNotesId"
                      autocomplete="new-defaultNotesId"
                    />
                    <mat-placeholder class="center">Note Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.defaultNotesId }}
                </td>
              </ng-container>
              <!-- Note Type -->
              <ng-container matColumnDef="customNoteType">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="noteType"
                      autocomplete="new-noteType"
                    />
                    <mat-placeholder class="center">Note Type</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.customNoteType }}
                </td>
              </ng-container>
              <!-- Note Reason -->
              <ng-container matColumnDef="noteReason">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="noteReason"
                      autocomplete="new-noteReason"
                    />
                    <mat-placeholder class="center"
                      >Note Reason</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.reasonData }}
                </td>
              </ng-container>
              <!--Subject-->
              <ng-container matColumnDef="subject">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="subject"
                      autocomplete="new-subject"
                    />
                    <mat-placeholder class="center">Subject</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.subject }}
                </td>
              </ng-container>

              <!--Description-->
              <ng-container matColumnDef="description">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="description"
                      autocomplete="new-description"
                    />
                    <mat-placeholder class="center"
                      >Description</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.description }}
                </td>
              </ng-container>

              <!-- Created By -->
              <ng-container matColumnDef="createdBy">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="createdBy"
                      autocomplete="new-createdBy"
                    />
                    <mat-placeholder class="center">Created By</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.userName }}
                </td>
              </ng-container>

              <!--Status-->
              <ng-container matColumnDef="status">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="status"
                      autocomplete="new-status"
                    />
                    <mat-placeholder class="center">Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.statusData }}
                </td>
              </ng-container>

              <!-- Created Date -->
              <ng-container matColumnDef="createdDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      formControlName="createdDate"
                      autocomplete="new-createdDate"
                    />
                    <mat-placeholder class="center"
                      >Date Created</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    element?.dateCreated | date : "MM/dd/yyyy h:mm a" : "en_US"
                  }}
                </td>
              </ng-container>

              <!-- Options -->
              <ng-container matColumnDef="options">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Options
                </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <a
                    *ngIf="isShownUpdateIconForNotes"
                    (click)="openNoteModal(element?.notesId)"
                    matTooltip="Click To View Notes"
                  >
                    <i class="fa fa-eye pointer editBtnColor pl-3"></i>
                  </a>
                </td>
              </ng-container>

              <!--No Records-->
              <ng-container matColumnDef="noRecords">
                <td
                  class="text-align-center"
                  mat-footer-cell
                  *matFooterCellDef
                  colspan="9"
                >
                  <div *ngIf="!isShowProgress">
                    {{ "No Records Found" }}
                  </div>
                  <div [hidden]="!isShowProgress">
                    <mat-spinner [diameter]="150"></mat-spinner>
                  </div>
                </td>
              </ng-container>
              <!--Paginotor-->
              <ng-container matColumnDef="paginator">
                <td mat-footer-cell *matFooterCellDef colspan="9">
                  <mat-paginator
                    class="table table-responsive"
                    #MatPaginator
                    [length]="dataSource?.data?.length"
                    [pageSize]="10"
                    [pageIndex]="0"
                    showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedColumns"
              ></tr>
              <tr
                mat-footer-row
                *matFooterRowDef="['noRecords']"
                [ngClass]="{
                  active:
                    dataSource &&
                    dataSource?.data &&
                    dataSource?.data?.length !== 0
                }"
              ></tr>
              <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
          </form>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </mat-card-content>
</div>
