import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
@Component({
  selector: 'app-patient-insurance-history',
  templateUrl: './patient-insurance-history.component.html',
  styleUrls: ['./patient-insurance-history.component.scss']
})
export class PatientInsuranceHistoryComponent implements OnInit {


  @Input() patientId: any;
  inspatientId: any;
  isLoading: boolean;
  insuranceHistorydata: any;
  dataSource: MatTableDataSource<any>;

  myTable: DataTables.Api;
  maxDate: Date;
  $subscription: Subscription[] = [];
  tableData$: Subscription;

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  constructor(
    private patientpersonalservices: PatientPersonalService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
    } = {
        patientId: defaultGuid,
      }
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.patientId = this.data?.patientId ?? defaultGuid;
    }
    this.inspatientId = this.patientId;

    this.getPatientInsuranceHistory(this.inspatientId);

  }


  getPatientInsuranceHistory(inspatientId:string): void {
    this.tableData$?.unsubscribe();
    this.isLoading = true;
    const getPracticeListTableData = this.patientpersonalservices.getAllPolicyByPatientIdV1ByPatientId(inspatientId).subscribe(
      (response) => {
        this.isLoading = false;
        this.insuranceHistorydata = response;
      },
      (error) => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
        this.insuranceHistorydata = [];
      }
    );
    this.tableData$ = getPracticeListTableData;
  }

}
