<mat-card class="mr-3 mt-3">
  <!-- Header Section -->
  <div class="customThemeClass">
    <div class="row" *ngIf="!isLoading">
      <div class="col-md-6">
        <!-- <h2 class="customcls">Patient Notes</h2> -->
        <mat-card-title class="customThemeClass"><b>Patient Notes</b></mat-card-title>
      </div>
      <div class="col-md-6 text-right" *ngIf="!isAddVisible">
        <button mat-button class="buttonColor" (click)="openAddNotesPopUp(false)">
          <i class="fa fa-plus mr-1"></i> Add Note
        </button>
      </div>
    </div>
  </div>
  <!-- Add Note Button Section -->
  <!-- <div class="row" *ngIf="!isLoading">
    <div class="col-6">
      <div class="text-right">
        <button mat-button [disabled]="!editFormValues || !editOrderStatus" *ngIf="isAddVisible" (click)="NoteModal()"
          class="buttonColor font-size-14">
          <i class="fa fa-plus mr-1"></i> Add Note
        </button>
      </div>
    </div>
  </div> -->
  <!-- Table Section -->

  <div class="card card-body mt-3">
    <div class="row">
      <!-- Loader Section -->
      <div class="col-12" *ngIf="isLoading">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>

      <div class="col-lg-12" *ngIf="!isLoading">
        <app-patient-notes-modal [vchartNo]="vchartNo" [vDescription]="vDescription"
          [tableData]="PatientNotesDetailsV1"></app-patient-notes-modal>
      </div>
    </div>
  </div>
</mat-card>