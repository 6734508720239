
  <mat-card class="mr-3 mt-5">
    <div class="row">
      <div class="col-lg-6">
        <h2>Eligibility History</h2>
      </div>
    </div>
    <app-shared-table (viewClick)="viewVerificationById($event)" (deleteClick)="deleteVerificationById($event)"
      [strPageType]="strPageType" [isLoading]="isLoading" [crudPermisson]="crudPermisson"
      [arrDisplayedColumns]="arrDisplayedColumns">
    </app-shared-table>

  </mat-card>
  