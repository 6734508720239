import { Component, Input, OnInit, ViewChild } from '@angular/core';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { Observable, Subscription } from 'rxjs';
import {
  CreateUpdateDocumentBinDTO,
  DocumentBinDTO,
  ValidateDocumentBinDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/dto/models';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
@Component({
  selector: 'app-master-document-bin-table',
  templateUrl: './master-document-bin-table.component.html',
  styleUrls: ['./master-document-bin-table.component.scss']
})
export class MasterDocumentBinTableComponent implements OnInit {

  @Input() tableData: any[];
  step: boolean = false;
  documnetDataList: any;
  dtOptions: any;
  documentBinId: string = defaultGuid;
  defaultGuid: string = defaultGuid;
  mstDocumentBinForm: FormGroup;
  isShowSpinner: boolean;
  docType: string;
  subscription$: Subscription[] = [];
  saveButtonHide: boolean;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  //Accordion Toggle Methods
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }

  constructor(
    private formBuilder: FormBuilder,
    private documentBinService: DocumentBinService,
    private toaster: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
       fixedHeader: true,
      columnDefs: [
        // { targets: [2,4,5], visible: false },
        { targets: [3,5], visible: false },
      ],
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Master Document Bin',
          exportOptions: {
            columns: ':visible:not(:first-child,:eq(0)) ',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1))',
        }
      ]
    };
    this.documnetDataList = this.tableData;
    this.intializeForms();

    this.dtOptions.buttons[0].filename =
    'Master Document Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  // //After View Method
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  intializeForms() {
    this.mstDocumentBinForm = this.formBuilder.group({
      txtBinName: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ], this.checkDocumentBinUniqness()),
    });
  }

  //Save and Update Method
  saveBin() {
    this.isShowSpinner = true;
    let documentBin: CreateUpdateDocumentBinDTO = {
      documentType: this.mstDocumentBinForm.value.txtBinName.trim(),
      isDefault: false,
      TenantId: localStorage.getItem('tenantId').trim() ?? defaultGuid,
    };
    if (
      this.documentBinId === null ||
      this.documentBinId === defaultGuid ||
      this.documentBinId === ''
    ) {
      this.documentBinService.create(documentBin).subscribe(
        (response) => {
          this.isShowSpinner = true;
          this.mstDocumentBinForm.reset();
          this.toaster.success('Bin Name Saved Successfully');
          this.communicationService.triggerMasterDocumentBinGetMethodCall();
          // this.getDocumentBinList();
          this.resetBinForm();
          // this.step = false;
        },
        (err) => {
          this.isShowSpinner = true;
          this.communicationService.triggerMasterDocumentBinGetMethodCall();
          // this.getDocumentBinList();
          this.resetBinForm();
          // this.step = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.documentBinService.update(this.documentBinId, documentBin).subscribe(
        (response) => {
          this.mstDocumentBinForm.reset();
          // this.getDocumentBinList();
          this.communicationService.triggerMasterDocumentBinGetMethodCall();
          this.resetBinForm();
          this.step = false;
          this.toaster.success('Bin Name Updated Successfully');
        },
        (err) => {
          this.communicationService.triggerMasterDocumentBinGetMethodCall();
          // this.getDocumentBinList();
          this.resetBinForm();
          this.step = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  //Status Change Actice And Inactive
  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Document Bin will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.documentBinService.enableDisableDocumentBinByDocumentTypeID(data?.id).subscribe(response => {
              this.toaster.success('Activated Successfully', 'Success')
              this.isShowSpinner = false;
              this.communicationService.triggerMasterDocumentBinGetMethodCall();
              // this.getDocumentBinList();
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Document Bin will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.documentBinService.enableDisableDocumentBinByDocumentTypeID(data?.id).subscribe(response => {
                this.toaster.success('Deactivated Successfully', 'Success');
                this.communicationService.triggerMasterDocumentBinGetMethodCall();
                this.isShowSpinner = false;
                // alert(data?.id);
                // this.getDocumentBinList();
              }, err => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              });
            }

          }
          else {
            event.source.checked = true;
          }
        });
      }
  }

  //Delete Bin Method
  deleteBin(docId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.documentBinService.delete(docId).subscribe(
          (response) => {
            // this.getDocumentBinList();
            this.communicationService.triggerMasterDocumentBinGetMethodCall();
            Swal.fire({
              title: 'Success',
              html: 'Document Bin Deleted Successfully!',
              icon: 'success',
            });
          },
          (err) => {
            this.communicationService.triggerMasterDocumentBinGetMethodCall();
            // this.getDocumentBinList();
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    });
  }

  //Get the Document Bin Details by Id
  viewDocumentBinById(docId: string) {
    this.documentBinId = docId ?? '';
    const documentDetails = this.documentBinService
      .get(docId)
      .subscribe((response) => {
        this.mstDocumentBinForm.patchValue({
          txtBinName: response?.documentType ?? '',
        });
        this.documentBinId = response?.id ?? '';
        this.docType = response?.documentType ?? '';
        this.step = true;
      });
    this.subscription$.push(documentDetails);
  }

  //Reset Bin Form
  resetBinForm() {
    this.documentBinId = defaultGuid;
    // this.documentBinId = null;
    this.mstDocumentBinForm.reset();
    this.mstDocumentBinForm.patchValue({
      txtBinName: ''
    });
  }


  checkDocumentBinUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const gid = this.documentBinId ? this.documentBinId : null;
      const input: ValidateDocumentBinDTO = {
        id: gid,
        documentType:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? ''
      };
      return this.documentBinService.addDocumentBinCheckByValidate(input).pipe(
        tap((x) => control?.setErrors({ isDocumentBinExists: true })),
        debounceTime(300),
        map((response) =>
          response === true
            ? { isDocumentBinExists: true }
            : control?.setErrors(null) == null
              ? null
              : null
        )
      );
    };
  }

  //Copy Text While Duble on table Row
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
