import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientICDCodesService } from '../patient-proxy/controllers/patient-icdcodes.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DiagnosisCode10Service } from '../dropdown-proxy/dropdown-management/dropdowns/diagnosis-code10.service';
import { CreateUpdateDiagnosisCode10DTO, DiagnosisCode10DTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { delay } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-patient-icd10',
  templateUrl: './patient-icd10.component.html',
  styleUrls: ['./patient-icd10.component.scss']
})
export class PatientIcd10Component implements OnInit , OnDestroy{
  txtICDCode: string = "";
  strPageType: string = "icd10Page";
  isLoading: boolean;
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ICDCode10Form: FormGroup;
  subscription$: Subscription[] = [];
  constructor(private formBuilder: FormBuilder,
    private icd10Service: PatientICDCodesService,
    private icdSaveService: DiagnosisCode10Service,
    private toastr: ToastrService,
    private Table: TableService,
    public dialogRef: MatDialogRef<PatientIcd10Component>,
  ) { }
  ngOnInit(): void {
    this.Table.clearICD10Table();
    this.createICDCode10();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  diagnosisCodeTypeData: string;
  public icd10TableData: any[] = [];
  SearchICDCode10() {
    // this.isLoading=true;
    // let icd10Search: DiagnosisCode10DTO = {
    //   diagnosisCodeTypeDataId: "",
    //   diagnosisCode: "",
    //   diagnosisCodeTypeData: this.ICDCode10Form.value.txtICDCode,
    //   isActive: 0,
    //   isExist:0,
    // }
    //this.diagnosisCodeTypeData = this.ICDCode10Form.value.txtICDCode;

    let input: CreateUpdateDiagnosisCode10DTO = {
      diagnosisCodeTypeData: this.ICDCode10Form.value.txtICDCode
    }
    const searchICD10 = this.icd10Service.getPatientICDCode10ByInput(input).subscribe((response: DiagnosisCode10DTO[]) => {
      if (response.length != 0) {
        this.icd10TableData = [];
        response.forEach((element, index) => {
          this.icd10TableData.push({
            sno: index + 1,
            DiagnosisCode: element?.diagnosisCode,
            DiagnsoisCodeData: element?.diagnosisCodeTypeData == null || undefined ? " " : element?.diagnosisCodeTypeData,
            isExists: element?.isExist,
            isBillable: element?.isBillable
          });
        });
        this.Table.setICD10Table(this.icd10TableData);
        this.dataSource = new MatTableDataSource(this.icd10TableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        Swal.fire("Sorry!! No Records Found");
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(searchICD10);

    // this.icd10Service.getDiagnosisCode10DatasBySDiagnosisCode10(this.diagnosisCodeTypeData).subscribe((response: any) => {
    //   if (response.length != 0) {
    //     this.icd10TableData = [];
    //     this.icd10TableData.push({
    //       sno: '',
    //       DiagnosisCode: response?.diagnosisCode,
    //       DiagnsoisCodeData: response.diagnosisCodeTypeData == null || undefined ? " " : response.diagnosisCodeTypeData,
    //       isExists: response.isExist,
    //       isBillable: response.isBillable
    //     })
    //     this.Table.setICD10Table(this.icd10TableData);
    //     this.dataSource = new MatTableDataSource(this.icd10TableData);
    //     this.dataSource.sort = this.sort;
    //     this.dataSource.paginator = this.paginator;
    //   }
    //   else {
    //     Swal.fire("Sorry!! Given Data does not exists ");
    //   }
    //   this.isLoading = false;
    // }, err => {
    //   this.isLoading = false;
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
  }

  AddICD10(data) {
    if (data?.isBillable == 0) {
      Swal.fire({
        title: 'This ICDCode10 is in not Billable Status?',
        text: 'Would You Like To Save this ICDCode10 Details',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Save!'
      }).then(result => {
        if (result.value) {
          let ICDode10Datas = data;
          let diagnosisCode10DTO: DiagnosisCode10DTO = {
            diagnosisCodeTypeDataId: "00000000-0000-0000-0000-000000000000",
            diagnosisCode: ICDode10Datas.DiagnosisCode,
            diagnosisCodeTypeData: ICDode10Datas.DiagnsoisCodeData,
            isExist: 1,
            isActive: 1,
            isBillable: ICDode10Datas?.isBillable
          };
          this.icdSaveService.create(diagnosisCode10DTO).subscribe(response => {
            if (response) {
              // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
              this.toastr.success('Saved Successfully','Success')
              data.isExists=1;
              // this.dialogRef.close();
            }
            else {
            }
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
      });
    } else {
      Swal.fire({
        title: 'Are you sure you want to save?',
        text: 'Would You Like To Save ICDCode10 Details',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Save!'
      }).then(result => {
        if (result.value) {
          let ICDode10Datas = data;
          let diagnosisCode10DTO: DiagnosisCode10DTO = {
            diagnosisCodeTypeDataId: "00000000-0000-0000-0000-000000000000",
            diagnosisCode: ICDode10Datas.DiagnosisCode,
            diagnosisCodeTypeData: ICDode10Datas.DiagnsoisCodeData,
            isExist: 1,
            isActive: 1,
            isBillable: ICDode10Datas?.isBillable
          };
          this.icdSaveService.create(diagnosisCode10DTO).subscribe(response => {
            if (response) {
              // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
              this.toastr.success('Saved Successfully','Success')
              this.dialogRef.close();
            }
            else {
            }
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
      });
    }
  }
  Reset() {
    this.ICDCode10Form.reset({
      txtICDCode: "",
      txtCodeDescription: "",
    });

  }

  createICDCode10() {
    this.ICDCode10Form = this.formBuilder.group({
      txtICDCode: new FormControl(""),
      txtCodeDescription: new FormControl(""),
    });
  }
}
