import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  clsEOBERAReportDTO,
  clsEOBERAReportSearch,
  clsEOBStatusDTO,
  clsPayerDetailDTO,
  clsPayerSearch,
} from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class EOBERAReportService {
  apiName = 'billingManagement';

  getdrpPayerByIModeAndSSearch = (iMode: number, sSearch: string) =>
    this.restService.request<any, PagedResultDto<clsPayerDetailDTO>>(
      {
        method: 'GET',
        url: '/api/billingApp/e-oBERAReport/drp-payer',
        params: { iMode, sSearch },
      },
      { apiName: this.apiName }
    );
  drpSearchPayerBySearch = (search: clsPayerSearch) =>
    this.restService.request<any, PagedResultDto<clsPayerDetailDTO>>(
      {
        method: 'POST',
        url: '/api/billingApp/e-oBERAReport/drp-search-payer',
        body: search,
      },
      { apiName: this.apiName }
    );
  drpEOBStatus = () =>
    this.restService.request<any, PagedResultDto<clsEOBStatusDTO>>(
      {
        method: 'POST',
        url: '/api/billingApp/e-oBERAReport/drp-eOBStatus',
      },
      { apiName: this.apiName }
    );

  // getEOBERAReportsBySearch = (search: clsEOBERAReportSearch) =>
  //   this.restService.request<any, PagedResultDto<clsEOBERAReportDTO>>({
  //     method: 'POST',
  //     url: '/api/billingApp/e-oBERAReport/get-eOBERAReports',
  //     body: search,
  //   },
  //   { apiName: this.apiName });

  getEOBERAReportsBySearchAndDtRemitanceFromAndDtRemitanceToAndDtCreatedFromAndDtCreatedTo =
    (
      search: clsEOBERAReportSearch,
      dtRemitanceFrom: string,
      dtRemitanceTo: string,
      dtCreatedFrom: string,
      dtCreatedTo: string
    ) =>
      this.restService.request<any, PagedResultDto<clsEOBERAReportDTO>>(
        {
          method: 'POST',
          url: '/api/billingApp/e-oBERAReport/get-eOBERAReports',
          params: {
            dtRemitanceFrom,
            dtRemitanceTo,
            dtCreatedFrom,
            dtCreatedTo,
          },
          body: search,
        },
        { apiName: this.apiName }
      );

      getEOBERAReportsPageNoAndPageOffSet = (search: clsEOBERAReportSearch, dtRemitanceFrom: string, dtRemitanceTo: string, dtCreatedFrom: string, dtCreatedTo: string, pageNo: number, pageOffSet: number) =>
        this.restService.request<any, PagedResultDto<clsEOBERAReportDTO>>({
          method: 'POST',
          url: '/api/billingApp/e-oBERAReport/get-eOBERAReports',
          params: { dtRemitanceFrom, dtRemitanceTo, dtCreatedFrom, dtCreatedTo, pageNo, pageOffSet },
          body: search,
        },
        { apiName: this.apiName });

  importEOBStatus = () =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/billingApp/e-oBERAReport/import-eOBStatus',
      },
      { apiName: this.apiName }
    );

  markEOBCompleteByGEOBIdAndBComplete = (gEOBId: string, bComplete: boolean) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/billingApp/e-oBERAReport/mark-eOBComplete/${gEOBId}`,
        params: { bComplete },
      },
      { apiName: this.apiName }
    );

  updateEOBOpen = () =>
    this.restService.request<any, boolean>(
      {
        method: 'PUT',
        url: '/api/billingApp/e-oBERAReport/update-eOBOpen',
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
